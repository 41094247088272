import {
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  useMediaQuery,
  useToken,
} from "@chakra-ui/react";
import { MicroLessonDto, SkillDto } from "@headversity/contract";
import dayjs from "dayjs";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  MODAL_PRIMARY_TEXT_COLOR,
  MODAL_SECONDARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../Testing/dataTestIds";
import { HvSlideFade } from "../../Common/HvSlideFade";
import { StepPanel } from "../../Common/StepPanel";

interface LessonPreviewProps {
  lesson: MicroLessonDto;
  showBackArrow: boolean;
  onBackClick: () => void;
  skill?: SkillDto;
  lessonDate?: dayjs.Dayjs | null;
  completionState?: number;
}
export const LessonPreview = ({
  lesson,
  showBackArrow,
  onBackClick,
  skill,
  lessonDate,
  completionState,
}: LessonPreviewProps) => {
  const [isDesktop] = useMediaQuery("(min-width: 992px)");

  const imageFilter = useToken("colors", ["IMAGE_FILTER"]);

  return (
    <StepPanel
      step={0}
      totalStep={0}
      value={0}
      hideDivider={true}
      showProgressBar={false}
      showBackArrow={showBackArrow}
      onBackClick={onBackClick}
    >
      <HvSlideFade fadeIn={true}>
        <Flex
          data-testid="modal_body"
          gap={isDesktop ? "40px" : "25px"}
          m={isDesktop ? "20px" : "10px"}
          mt={0}
          flexDir={{ base: "column", md: "row" }}
          color={MODAL_PRIMARY_TEXT_COLOR}
          mb={!isDesktop ? "110px" : undefined} // room for button at bottom
        >
          <Flex flexDir={"column"} gap={"10px"}>
            <Flex className="lesson_preview_modal_title_container">
              <Heading
                size={{ base: "lg", sm: "lg", md: "xl" }}
                data-testid={HVTestId.LessonPreviewModal.lessonTitle}
                color={MODAL_PRIMARY_TEXT_COLOR}
                fontWeight="600"
                pr={4}
              >
                {lesson.name as string}
              </Heading>
            </Flex>

            {skill && (
              <HStack gap="5px" mt="-5px" mb="20px">
                <Image
                  h={"12px"}
                  src={skill.imageUrl as string}
                  filter={
                    imageFilter[0] === "none" ? "grayscale(1.0);" : imageFilter
                  }
                />
                <Text fontSize={"13px"} ml="0px !important">
                  {skill.name as string}
                </Text>
              </HStack>
            )}

            <Divider mb={5} />

            <Flex
              color={MODAL_SECONDARY_TEXT_COLOR}
              data-testid="lesson_preview_modal_lesson_description"
              w={"100%"}
              lineHeight="1.3"
            >
              <Text fontSize="15px" mr={isDesktop ? "20px" : undefined}>
                {lesson?.description as string}
              </Text>
            </Flex>
            {lessonDate && (
              <Heading
                mt={6}
                fontSize="15px"
                fontWeight={"normal"}
                data-testid="lesson_preview_modal_lesson_completed_text"
              >
                {`${
                  completionState === 1
                    ? HVLocalizeStrings.LESSON_COMPLETED_ON
                    : HVLocalizeStrings.LESSON_STARTED_ON
                }`}
                <strong>
                  {` ${lessonDate
                    .local()
                    .format(HVLocalizeStrings.DATE_FORMAT)}`}
                </strong>
                .
              </Heading>
            )}
          </Flex>

          <Image
            src={lesson?.imageUrl as string}
            borderRadius={"15px"} /* always 15px not Border_Radius */
            boxShadow={"lg"}
            boxSize={isDesktop ? "300px" : undefined}
            height={!isDesktop ? "225px" : undefined}
            objectFit="cover"
            data-testid="lesson_preview_modal_lesson_image"
            alt=""
          />
        </Flex>
      </HvSlideFade>
    </StepPanel>
  );
};
