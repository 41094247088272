import { Center, Container } from "@chakra-ui/react";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { ReachInsightsContext } from "../../State/Reach/ReachInsightsContext";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import {
  BG_REACH_BASE,
  HEADER_REACH_HOME_BACKGROUND,
} from "../../Styles/HeadversityStyle";
import { REACH_EVENTS, track } from "../../Utils/Analytics";
import { pdfExport } from "../../Utils/PDFDownloadUtil";
import { BlockSection } from "../Common/BlockSection";
import { HeaderSetter } from "../Common/HeaderSetter";
import { HvSpinner } from "../Common/HvSpinner";
import InsightsCertPanel from "./Insights/InsightsCertPanel";
import InsightsPsychographicsPanel from "./Insights/InsightsPsychographicsPanel";
import InsightsSoloAndTeamPanel from "./Insights/InsightsSoloAndTeamPanel";
import InsightsUsersPanel from "./Insights/InsightsUsersPanel";
import { PDFContainerInsightsPage } from "./PdfContainerInsightsPage";
import { ReachSearchByHierarchy } from "./SelfService/ReachSearchByHierarchy";
import { useNodesFilterSearchParams } from "./Shared/useNodesFilterSearchParams";

const ReachInsightsPage = () => {
  const pdfRef = useRef<PDFExport | null>(null);
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);
  const { getPageTitle, userHierarchyAssociations } =
    useContext<IGlobalProvider>(GlobalContext);
  const {
    fetchData,
    availableReports,
    company,
    hierarchyNodes,
    nodesFilter,
    handleNodeSelectionsChange,
  } = useContext(ReachInsightsContext);

  const [isInsightsPageLoading, setIsInsightsPageLoading] =
    useState<boolean>(true);

  useEffect(() => {
    fetchData().then(() => setIsInsightsPageLoading(false));
  }, [nodesFilter]);

  useNodesFilterSearchParams();

  const showPsychographics = availableReports.solo || availableReports.team; // hide if cert only
  const showSoloAndTeam = availableReports.solo || availableReports.team;

  return (
    <>
      <PDFContainerInsightsPage
        pdfRef={pdfRef}
        company={company}
        showPsychographics={showPsychographics}
        showSoloAndTeam={showSoloAndTeam}
        availableReports={availableReports}
      />
      <Helmet>
        <title>{getPageTitle(`${HVLocalizeStrings.REACH_INSIGHTS}`)}</title>
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.REACH_INSIGHTS}
        description={HVLocalizeStrings.REACH_INSIGHTS_DESCRIPTION}
        backgroundImage={HEADER_REACH_HOME_BACKGROUND}
        backgroundImageBase={BG_REACH_BASE}
      ></HeaderSetter>

      {isInsightsPageLoading ? (
        <BlockSection fadeIn={true} columns={1}>
          <Center>
            <HvSpinner />
          </Center>
        </BlockSection>
      ) : (
        <Container maxW={isMainContainerXL ? "container.xl" : "container.lg"}>
          {userHierarchyAssociations?.hierarchyId && (
            <ReachSearchByHierarchy
              handleSelections={(selected) => {
                handleNodeSelectionsChange(selected);
                track(REACH_EVENTS.ReachInsightsNodeFilter);
              }}
              nodes={hierarchyNodes}
              nodesFilter={nodesFilter}
            />
          )}
          {/* Users Section */}
          <InsightsUsersPanel
            downloadReport={() => {
              pdfExport(pdfRef, {
                forcePageBreak: ".page-break",
              });
              track(REACH_EVENTS.ReachInsightsReportDownloaded, {
                HV_Reach_InsightsReportType: "Overview",
              });
            }}
          />

          {/* Psychographics Section */}
          {showPsychographics && <InsightsPsychographicsPanel />}

          {/* Solo/Team Section */}
          {showSoloAndTeam && <InsightsSoloAndTeamPanel />}

          {/* Cert Section */}
          {availableReports.cert && <InsightsCertPanel />}
        </Container>
      )}
    </>
  );
};

export default ReachInsightsPage;
