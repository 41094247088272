import { Box, Button, Flex, Image, useBreakpointValue } from "@chakra-ui/react";
import { Border_Radius } from "../../../Styles/HeadversityStyle";

interface PresentationAccordionItemBlockProps {
  onClick: () => void;
  opacity: any;
  image: any;
  overlay?: any;
  icon?: any;
  content: any;
  dataTestId?: string;
}

export const PresentationAccordionItemBlock = (
  props: PresentationAccordionItemBlockProps
) => {
  const { onClick, opacity, image, overlay, icon, content, dataTestId } = props;

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  return (
    <Button
      className="button-block more-shadow"
      onClick={onClick as any}
      data-testid={dataTestId}
    >
      <Flex bgColor={"white"} w="100%" gap={[3, 3, 6]} opacity={opacity}>
        <Box position={"relative"} minW={"110px"} h={"80px"}>
          <Image
            className="team_team_lesson_card_image"
            borderRadius={Border_Radius}
            boxShadow={"lg"}
            h="100%"
            width={"110px"}
            src={image}
            alt=""
          />
          {icon && (
            <Flex
              className="team_lesson_card_image_overlay"
              position={"absolute"}
              borderRadius={Border_Radius}
              bottom={0}
              alignItems={"center"}
              justifyContent={"center"}
              background={overlay}
              w={"110px"}
              h="100%"
              opacity={1}
              color="white"
              fontSize={"44px"}
            >
              {icon}
            </Flex>
          )}
        </Box>
        <Flex
          w="100%"
          flexDir={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          pr={"3"}
        >
          {typeof content === "function" ? content() : content}
        </Flex>
      </Flex>
    </Button>
  );
};
