import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  Flex,
  Center,
  Text,
  Select,
  useBreakpointValue,
  Icon,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { ReachUsersContext } from "../../../../State/Reach/ReachUsersContext";
import {
  Border_Radius,
  Equity_Black,
  Success,
} from "../../../../Styles/HeadversityStyle";
import { FaCheckCircle } from "react-icons/fa";
import { LargeModal } from "../../../Common/LargeModal";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface UserAssignCertModalProps {
  userId: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const UserAssignCertModal = (props: UserAssignCertModalProps) => {
  const { userId, isOpen, setIsOpen } = props;
  const {
    assignCertToUserToServer,
    companyCerts,
    userCertsStats,
    triggerDataRefresh,
  } = useContext(ReachUsersContext);
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  const [selectedCert, setSelectedCert] = useState<number>(0);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined
  );

  const selectOptions = companyCerts.map((cert) => {
    const name =
      cert.name + (cert.isTrial ? HVLocalizeStrings.REACH_TRIAL : "");
    return { id: cert.id, name: name };
  });

  return (
    <LargeModal
      open={isOpen}
      setOpen={setIsOpen}
      bgColor={"white"}
      noFallback={true}
      forceLightMode={true}
      dataTestId={HVTestId.UserAssignCertModal.modal}
    >
      <Flex mt={4} flexDirection={"column"} gap={6}>
        <Text fontSize={"lg"} fontWeight={"semibold"}>
          {HVLocalizeStrings.REACH_USERS_UPLOAD_STEP_3_TITLE}
        </Text>
        <Flex
          border={`1px solid ${Equity_Black}`}
          borderRadius={Border_Radius}
          w={isMobile ? "80%" : "45%"}
        >
          <Select
            w={"100%"}
            onChange={(e) => {
              setSelectedCert(parseInt(e.target.value));
            }}
            placeholder={HVLocalizeStrings.REACH_USERS_CHOOSE_A_CERT}
            value={selectedCert}
            data-testid={HVTestId.UserAssignCertModal.select}
          >
            {selectOptions
              .filter((so) => {
                //remove already assigned certs from Select options
                const assignedCertIds = userCertsStats.map((ucs) => ucs.certId);
                return !assignedCertIds.includes(so.id);
              })
              .map((so) => (
                <option key={so.id} value={so.id}>
                  {so.name}
                </option>
              ))}
          </Select>
        </Flex>
        {successMessage && (
          <Flex gap={4} alignItems={"center"}>
            <Icon as={FaCheckCircle} color={Success} />
            <Text fontSize={"sm"} color={Success}>
              {successMessage}
            </Text>
          </Flex>
        )}
        <Center my={6}>
          <AnimatedButton
            w={"200"}
            colorSet={AnimatedButtonColorSet.Primary}
            onClick={() => {
              if (selectedCert > 0) {
                assignCertToUserToServer(userId, selectedCert).then(() => {
                  const cert = selectOptions.find(
                    (so) => so.id === selectedCert
                  );
                  setSuccessMessage(
                    `${cert?.name} ${HVLocalizeStrings.REACH_USERS_WAS_SUCCESSFULLY_ASSIGNED}`
                  );
                  setSelectedCert(0);
                  triggerDataRefresh();
                });
              }
            }}
            text={HVLocalizeStrings.REACH_USERS_ASSIGN}
            disabled={selectedCert === 0}
            dataTestId={HVTestId.UserAssignCertModal.assignButton}
          />
        </Center>
      </Flex>
    </LargeModal>
  );
};

export default UserAssignCertModal;
