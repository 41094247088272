import { Box, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { Step } from "../../Common/Stepper/Step";
import { HVTestId } from "../../../Testing/dataTestIds";

interface ScaleQuestionProps {
  scaleMax: number;
  onSelectedIndexChanged?: (val: number, index: number) => void;
  isDisabled: boolean;
}

export const ScaleQuestion = ({
  scaleMax,
  onSelectedIndexChanged,
  isDisabled,
}: ScaleQuestionProps) => {
  return (
    <RadioGroup
      onChange={(val) => {
        if (onSelectedIndexChanged) {
          // for scale questions, the selectedValue is the index
          const intVal = parseInt(val);
          onSelectedIndexChanged(intVal, intVal);
        }
      }}
    >
      <Stack
        flexDir={"row"}
        mx={{
          base: 0,
          md: scaleMax === 5 ? "100px" : 5,
        }}
      >
        {Array.from(Array(scaleMax).keys()).map((x) => {
          return (
            <Step
              key={x}
              isFirst={x === 0}
              isLast={x === scaleMax - 1}
              mt={"0px!"}
              description={
                <Box
                  mt={{
                    base: "-4px",
                    md: "-12px",
                  }}
                  textAlign={"center"}
                >
                  <Box
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                  >
                    <Radio
                      size={"lg"}
                      borderColor="#aeb4ca"
                      background={"white"}
                      value={`${x}`}
                      isDisabled={isDisabled}
                      transform={{ base: "scale(1.3)", md: "scale(1.1)" }}
                      data-testid={`${HVTestId.ScaleQuestion.scaleMark}${x}`}
                    />
                  </Box>
                </Box>
              }
              isComplete={false}
              isAllComplete={true}
              isActive={false}
              isLastStep={false}
              isFirstStep={false}
              forceHorizontal={true}
              hideCircle={true}
            />
          );
        })}
      </Stack>
    </RadioGroup>
  );
};
