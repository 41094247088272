import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Black, Error_Red } from "../../Styles/HeadversityStyle";
import { ChakraBox } from "./ChakraBox";

type TimerProps = {
  seconds: number;
  onTimeChange?: (time: number) => void;
};

export const Timer = (props: TimerProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  const { seconds, onTimeChange } = props;
  const [time, setTime] = useState<number>(seconds);
  const [animation, setAnimation] = useState<any>(undefined);

  useEffect(() => {
    if (time === 0) {
      setAnimation({
        scale: [1, 2, 2, 1],
        rotate: [0, 0, 360, 360],
      });
    }
  }, [time]);

  useEffect(() => {
    if (time > 0) {
      const intervalId = setInterval(() => {
        setTime((prevTime) => Math.max(prevTime - 1, 0));
      }, 1000);

      if (onTimeChange) {
        onTimeChange(time);
      }

      return () => clearInterval(intervalId);
    }
  }, [time]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  // When not mobile: border is red when time is 0, otherwise black
  let borderColor: string | undefined = `3px solid ${
    time > 0 ? "#0A1B57" : Error_Red
  }`;
  borderColor = isMobile ? undefined : borderColor;

  return (
    <ChakraBox
      animate={animation}
      // @ts-ignore no problem in operation, although type error appears.
      transition={{
        duration: 3,
        ease: "easeInOut",
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={4}
      fontSize={"md"}
      borderRadius={"50%"}
      border={borderColor}
      width="72px"
      height="72px"
    >
      <Flex
        align="center" // Align items in the center vertically
        justify="center" // Center horizontally
        w="100%"
        h="100%"
      >
        <Text fontWeight="600" color={time > 0 ? Black : Error_Red}>
          {formatTime(time)}
        </Text>
      </Flex>
    </ChakraBox>
  );
};
