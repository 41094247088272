import {
  Box,
  Icon,
  PlacementWithLogical,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  Border_Radius,
  Glow_Shadow_Filter,
} from "../../Styles/HeadversityStyle";

interface Props {
  icon: any;
  text?: string;
  color?: any;
  placement?: PlacementWithLogical;
  hasLeftPadding?: boolean;
  size?: string;
  content?: any;
}
export default function IconWithToolTip({
  icon,
  text,
  color,
  placement,
  hasLeftPadding,
  size,
  content,
}: Props) {
  return (
    <Tooltip
      borderRadius={Border_Radius}
      filter={Glow_Shadow_Filter}
      label={
        <Box p={3}>
          {content ? (
            content
          ) : text ? (
            <Text dangerouslySetInnerHTML={{ __html: text }} />
          ) : (
            ""
          )}
        </Box>
      }
      placement={placement}
      mx={2}
      hasArrow
    >
      <span
        style={{
          marginLeft:
            size === "smallest"
              ? "0px"
              : size === "small"
              ? "1px"
              : hasLeftPadding
              ? "2px"
              : "-2px",
        }}
      >
        <Icon
          mt={size === "smallest" ? "-8px" : "-5px"}
          as={icon}
          w={size === "smallest" ? "10px" : size === "small" ? "12px" : "14px"}
          color={color ? color : undefined}
        />
      </span>
    </Tooltip>
  );
}
