import { FocusToolInfoDto, SchedulerFrequency } from "@headversity/contract";
import dayjs from "dayjs";
import {
  getExpressionFromDate,
  getRunDate,
} from "../../../../../Utils/CronParserUtil";

export enum OccurrenceDirection {
  Next,
  Previous,
}

export const getOccurrenceDate = (
  focusPlanItem: FocusToolInfoDto,
  direction: OccurrenceDirection
) => {
  const focusPlanStartTime = dayjs
    .utc(focusPlanItem.nextOccurrence?.toString())
    .local();

  const now = dayjs();

  if (
    focusPlanItem.recurrenceType === SchedulerFrequency.DoNotRepeat ||
    focusPlanStartTime.unix() >= now.unix()
  ) {
    return focusPlanStartTime;
  }

  const pattern = getExpressionFromDate(
    focusPlanStartTime,
    focusPlanItem.recurrenceType
  );

  return getRunDate(now, pattern, direction);
};

// pass in 0 to test
export const getUpComingFocusPlans = (
  focusPlanItems: FocusToolInfoDto[],
  minuteInBetweenTrigger: number = 30
) => {
  const lastClickUnixTime = localStorage.getItem("focus_notification");

  return focusPlanItems.filter((item) => {
    const now = dayjs();
    const nextRunDate = getOccurrenceDate(item, OccurrenceDirection.Next);
    if (minuteInBetweenTrigger === 0) {
      return now.unix() < nextRunDate.unix();
    }

    const startTime = nextRunDate.subtract(minuteInBetweenTrigger, "minutes");
    const endTime = nextRunDate.add(minuteInBetweenTrigger, "minutes");

    if (!lastClickUnixTime) {
      return now.unix() >= startTime.unix() && now.unix() < endTime.unix();
    }

    const lastClickTime = dayjs.unix(parseInt(lastClickUnixTime));

    return (
      now.unix() >= startTime.unix() &&
      now.unix() < endTime.unix() &&
      !(
        lastClickTime.unix() >= startTime.unix() &&
        lastClickTime.unix() < endTime.unix()
      )
    );
  });
};
