import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { ToolList } from "../Tools/ToolList";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { shuffle } from "../../../Utils/Helpers";
import { ScrollPanelViewMoreLinkButton } from "../../Common/ScrollPanelViewMoreLinkButton";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FiTool } from "react-icons/fi";
import { EVENTS, track } from "../../../Utils/Analytics";

interface SuggestedToolsForYouPanelProps {
  from: string;
}

export const SuggestedToolsForYouPanel = ({
  from,
}: SuggestedToolsForYouPanelProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const navigate = useNavigate();

  const { tools, skills, userSkillStats } =
    useContext<IGlobalProvider>(GlobalContext);

  const [toolsToShow, setToolsToShow] = useState<any[]>([]);

  useEffect(() => {
    if (tools && skills && userSkillStats?.length > 0) {
      let allValuesAreTheSame = true;
      const userSkillStatsClone = [...userSkillStats];
      userSkillStatsClone.sort((a, b) => {
        const result = a.headscanPercentage - b.headscanPercentage;
        if (result !== 0) {
          allValuesAreTheSame = false;
        }
        return result;
      });

      if (allValuesAreTheSame) {
        let toolsClone = [...tools];
        shuffle(toolsClone);
        setToolsToShow(toolsClone.slice(0, 3));
      } else {
        let toolsClone: any[] = [];
        let toolIds: any[] = [];
        for (const userSkillStat of userSkillStatsClone) {
          const skillId = userSkillStat.skillId;
          const filteredTools = tools.filter((item) =>
            item.skillCompetencies.some(
              (skillCompetency) => skillCompetency.skillId === skillId
            )
          );

          filteredTools.forEach((tool) => {
            if (toolsClone.length < 3 && toolIds.indexOf(tool.id) === -1) {
              toolsClone.push(tool);
              toolIds.push(tool.id);
            }
          });

          if (toolsClone.length >= 3) {
            break;
          }
        }

        if (toolsClone.length < 3) {
          const availableTools = tools.filter(
            (x) => !toolsClone.flatMap((x) => x.id).includes(x.id)
          );

          const len = toolsClone.length;

          for (let x = 0; x < 3 - len; x++) {
            toolsClone.push(availableTools[x]);
          }
        }

        setToolsToShow(toolsClone);
      }
    }
  }, [tools, skills, userSkillStats]);

  return (
    <BlockSection
      fadeIn={tools?.length > 0 && skills?.length > 0}
      title={
        <BlockSectionTitle
          title={HVLocalizeStrings.SOLO_MENU_PRACTICE}
          description={HVLocalizeStrings.SOLO_PRACTICE_DESCRIPTION + "."}
        />
      }
      columns={""}
      gridClassName="suggested-tools"
    >
      <ToolList
        tools={toolsToShow}
        skills={skills}
        openToolDirectly={false}
        from={from}
      />
      <Box mt="5px">
        <ScrollPanelViewMoreLinkButton
          onClick={() => {
            navigate("/tools");
            track(EVENTS.SeeAllToolsClicked, { HV_From: from });
          }}
          isDesktop={isDesktop}
          noPullUp={true}
          iconType={FiTool}
        >
          {HVLocalizeStrings.SEE_ALL_TOOLS}
        </ScrollPanelViewMoreLinkButton>
      </Box>
    </BlockSection>
  );
};
