import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Box,
  Center,
  Flex,
  Spacer,
  Text,
  Image,
  Checkbox,
  Tooltip,
} from "@chakra-ui/react";
import { GoalDto, GoalWinThemeDto } from "@headversity/contract";
import {
  Border_Radius,
  Glow_Shadow_Filter,
  HV_SPINNER_COLOR,
  BUTTON_DISABLE_BACKGROUND_COLOR,
} from "../../../Styles/HeadversityStyle";
import { useContext } from "react";
import { GlobalContext } from "../../../State/GlobalContext";
import { ReachGoalsContext } from "../../../State/Reach/ReachGoalsContext";

interface ReachGoalsWinThemeCardProps {
  goalWinTheme: GoalWinThemeDto;
  viewOnly: boolean;
  index: number;
  maxWidth: string | undefined;
}

export const ReachGoalsWinThemeCard = (props: ReachGoalsWinThemeCardProps) => {
  const globalContext = useContext(GlobalContext);
  const goalsContext = useContext(ReachGoalsContext);

  const { content } = globalContext;
  const { goalWinThemes, setGoalWinThemes } = goalsContext;

  const isWinThemeSelected = (goalWinTheme: GoalWinThemeDto) => {
    return goalWinThemes.some(
      (goalWinThemeSelected) => goalWinThemeSelected.id === goalWinTheme.id
    );
  };

  const getWinThemeGoals = (goalWinTheme: GoalWinThemeDto) => {
    if (content?.goals) {
      return content.goals.filter(
        (goal: GoalDto) => Number(goal.winThemeId) === goalWinTheme.id
      );
    }
  };

  const removeGoalWinTheme = (goalWinTheme: GoalWinThemeDto) => {
    setGoalWinThemes(
      goalWinThemes.filter(
        (goalWinThemeSelected) => goalWinThemeSelected.id !== goalWinTheme.id
      )
    );
  };

  const addGoalWinTheme = (goalWinTheme: GoalWinThemeDto) => {
    setGoalWinThemes([...goalWinThemes, goalWinTheme]);
  };

  const { goalWinTheme, viewOnly, index, maxWidth } = props;
  const selected = isWinThemeSelected(goalWinTheme);
  const goals = getWinThemeGoals(goalWinTheme);
  let splitResults = HVLocalizeStrings.REACH_GOALS_WIN_THEME_PURSUE.split(
    /\{0\}|\{1\}/
  ).map((result) => result.trim());

  const handleGoalWinThemeSelection = () => {
    if (selected) {
      removeGoalWinTheme(goalWinTheme);
    } else {
      addGoalWinTheme(goalWinTheme);
    }
  };

  const getBorderColor = () => {
    if (selected && !viewOnly) {
      return HV_SPINNER_COLOR;
    } else {
      return "#DCDCDC";
    }
  };

  const calculateMarginLeft = (
    maxWidth: string | undefined,
    index: number
  ): number => {
    if (maxWidth === "50%") {
      if (index % 2 === 0) {
        return 0;
      } else {
        return 4;
      }
    } else {
      return 0;
    }
  };

  const calculateMarginRight = (
    maxWidth: string | undefined,
    index: number
  ): number => {
    if (maxWidth === "50%") {
      if (index % 2 === 0) {
        return 4;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  return (
    <Box w={"100%"} as={!viewOnly ? "button" : undefined}>
      <Box
        bgColor={"white"}
        w={"100%"}
        display="flex"
        flexDirection="column"
        borderWidth={"2px"}
        borderRadius={Border_Radius}
        borderColor={getBorderColor()}
        boxShadow={"lg"}
        ml={calculateMarginLeft(maxWidth, index)}
        mr={calculateMarginRight(maxWidth, index)}
        mb={10}
        p={5}
        onClick={!viewOnly ? handleGoalWinThemeSelection : () => {}}
      >
        <Flex flexDirection={"row"} alignItems="center">
          <Box mr={5}>
            <Center>
              <Image src={goalWinTheme.imageUrl.toString()} h={"35px"} />
            </Center>
          </Box>
          <Box textAlign="left">
            <Box fontSize={23} fontWeight={600}>
              {goalWinTheme.name.toString()}
            </Box>
            <Box>{goalWinTheme.description.toString()}</Box>
          </Box>
          <Spacer />
          {!viewOnly && (
            <Box alignSelf="flex-start">
              <Checkbox
                size="lg"
                tabIndex={-1}
                colorScheme="blue"
                isChecked={selected}
                pointerEvents="none"
              ></Checkbox>
            </Box>
          )}
        </Flex>
        <Box px={4}>
          <Box
            h={"1px"}
            w={"100%"}
            my={6}
            backgroundColor={BUTTON_DISABLE_BACKGROUND_COLOR}
          ></Box>
          <Box mb={4}>
            <Text
              fontSize={"15px;"}
              display="flex"
              alignItems="center"
              flexWrap="wrap"
            >
              {splitResults[0]}
              {"\u00A0"}
              <Tooltip
                borderRadius={Border_Radius}
                filter={Glow_Shadow_Filter}
                label={
                  <Text p={2}>
                    {goals ? goals[0].description.toString() : ""}
                  </Text>
                }
                hasArrow={true}
                openDelay={250}
              >
                <Text fontWeight={600}>
                  {goals ? goals[0].name.toString() : ""}
                </Text>
              </Tooltip>
              {"\u00A0"}
              {splitResults[1]}
              {"\u00A0"}
              <Tooltip
                borderRadius={Border_Radius}
                filter={Glow_Shadow_Filter}
                label={
                  <Text p={2}>
                    {goals ? goals[1].description.toString() : ""}
                  </Text>
                }
                hasArrow={true}
                openDelay={250}
              >
                <Text fontWeight={600}>
                  {goals ? goals[1].name.toString() : ""}
                </Text>
              </Tooltip>
              {"\u00A0"}
              {splitResults[2]}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
