import { LocalizationText } from "@headversity/contract";
import { languageObj } from "../App";
export const SELECTED_USER_LANGUAGE_KEY = "selected_user_language";

export const getLocalizedText = (
  text: LocalizationText | undefined,
): string | undefined => {
  if (text && ["en", "fr", "es"].includes(languageObj.selectedLanguage)) {
    const textLocalized =
      text[languageObj.selectedLanguage as keyof LocalizationText];
    if (textLocalized !== undefined && textLocalized !== null) {
      return textLocalized;
    }
  }
  return undefined;
};
