import {
  Box,
  Center,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  CreateSelfServeUserDto,
  UpdateSelfServeUserDto,
  UserRole,
} from "@headversity/contract";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  ReachUsersContext,
  UserModalOpen,
} from "../../../../State/Reach/ReachUsersContext";
import { IShellProvider, ShellContext } from "../../../../State/ShellContext";
import { Mid_Blue } from "../../../../Styles/HeadversityStyle";
import { LargeModal } from "../../../Common/LargeModal";
import { GlobalContext } from "../../../../State/GlobalContext";
import {
  ConfirmationButtons,
  ConfirmationDialog,
} from "../../../Common/ConfirmationDialog";
import ProfileTabPanel from "./ProfileTabPanel";
import CertificationsTabPanel from "./CertificationsTabPanel";
import UserAssignCertModal from "./UserAssignCertModal";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface UserViewEditModalProps {
  isOpen: boolean;
  user: UpdateSelfServeUserDto | CreateSelfServeUserDto;
  setUser: (user: UpdateSelfServeUserDto | CreateSelfServeUserDto) => void;
  language: string;
  setLanguage: (language: string) => void;
  fullName: string;
  setFullName: (fullName: string) => void;
  email: string;
  setEmail: (email: string) => void;
  activationDateTime?: Date;
  setActivationDateTime: (activationDateTime?: Date) => void;
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
  upsertReachUser: () => void;
  isMissingFields: boolean;
  handleActivationDateTimeChanged: (date: Date) => void;
  resetStates: () => void;
  startingUser: CreateSelfServeUserDto;
}

const UserViewEditModal = (props: UserViewEditModalProps) => {
  const {
    isOpen,
    user,
    setUser,
    language,
    setLanguage,
    fullName,
    setFullName,
    email,
    setEmail,
    activationDateTime,
    setActivationDateTime,
    isSaving,
    setIsSaving,
    upsertReachUser,
    isMissingFields,
    handleActivationDateTimeChanged,
    resetStates,
    startingUser,
  } = props;

  const {
    inviteCodesForReach,
    setUserCertsStats,
    isUserCertsStatsLoading,
    setUserModalOpen,
    selectedUser,
    setSelectedUser,
    companyCerts,
  } = useContext(ReachUsersContext);

  const { confirmationDialogRef } = useContext<IShellProvider>(ShellContext);
  const { self } = useContext(GlobalContext);

  const [dirtyEmail, setDirtyEmail] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [assigningCert, setAssigningCert] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const userCanEditEmail: boolean =
    (self && self.role <= UserRole.HVAdmin) ?? false;

  const setStatesFromSelectedUser = () => {
    if (selectedUser) {
      setUser({
        id: selectedUser.id,
        userFields: {
          fullName: selectedUser.fullName,
          email: selectedUser.email,
          role: selectedUser.role,
          inviteCodeId: selectedUser.inviteCodeId,
          language: selectedUser.language,
          activationEmailAt: selectedUser.activationEmailAt,
        },
        customInfo: selectedUser.customInfo,
      } as UpdateSelfServeUserDto);
    } else {
      setUser(startingUser);
    }
    setLanguage(selectedUser?.language ?? "");
    setFullName(selectedUser?.fullName ?? "");
    setEmail(selectedUser?.email ?? "");
    setActivationDateTime(
      selectedUser?.activationEmailAt
        ? new Date(selectedUser?.activationEmailAt)
        : undefined
    );
  };
  useEffect(() => {
    setStatesFromSelectedUser();
  }, [selectedUser]);

  const getInviteCodeById = (id: number): string => {
    const inviteCodeDto = inviteCodesForReach?.find(
      (inviteCode) => inviteCode.id === id
    );
    return inviteCodeDto?.code ?? "";
  };

  const saveUser = () => {
    setIsSaving(true);
    upsertReachUser();
    setIsEditing(false);
    setStatesFromSelectedUser();
    setIsSaving(false);
  };

  const handleSaveChanges = () => {
    if (dirtyEmail && selectedUser?.id) {
      confirmationDialogRef.current.confirmation(
        HVLocalizeStrings.REACH_USER_SAVE,
        HVLocalizeStrings.REACH_USERS_CHANGE_EMAIL_CONFIRMATION,
        ConfirmationButtons.YesNo,
        saveUser
      );
    } else {
      saveUser();
    }
  };

  const goToBulkUploadUsers = () => {
    setIsEditing(false);
    setUserModalOpen(UserModalOpen.BULK);
    resetStates();
  };

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  if (!selectedUser) return null;
  return (
    <LargeModal
      open={isOpen}
      setOpen={() => {}}
      onCloseTasks={() => {
        setSelectedUser(undefined);
        setUser(startingUser);
        setDirtyEmail(false);
        setIsEditing(false);
        setAssigningCert(false);
        setUserCertsStats([]);
        resetStates();
        setUserModalOpen(UserModalOpen.NONE);
      }}
      bgColor={"white"}
      noFallback={true}
      forceLightMode={true}
      dataTestId={HVTestId.UserViewEditModal.modal}
    >
      {isUserCertsStatsLoading ? (
        <Center>
          <Spinner size={"xl"} color={Mid_Blue} />
        </Center>
      ) : (
        <Tabs
          variant="line"
          mt={"-25px"}
          index={tabIndex}
          onChange={handleTabsChange}
        >
          <Box mx={4}>
            <TabList w={"100%"}>
              <UserModalTab
                text={HVLocalizeStrings.SOLO_PROFILE_TITLE}
                dataTestId={HVTestId.UserViewEditModal.profileTab}
              />
              {companyCerts.length > 0 && (
                <UserModalTab
                  text={HVLocalizeStrings.REACH_USERS_CERTIFICATIONS}
                  dataTestId={HVTestId.UserViewEditModal.certsTab}
                />
              )}
            </TabList>
          </Box>
          <TabPanels>
            <TabPanel>
              <ProfileTabPanel
                fullName={fullName}
                email={email}
                language={language}
                activationDateTime={activationDateTime}
                handleActivationDateTimeChanged={
                  handleActivationDateTimeChanged
                }
                user={user}
                setUser={setUser}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isSaving={isSaving}
                isMissingFields={isMissingFields}
                userCanEditEmail={userCanEditEmail}
                setDirtyEmail={setDirtyEmail}
                setFullName={setFullName}
                setEmail={setEmail}
                setLanguage={setLanguage}
                goToBulkUploadUsers={goToBulkUploadUsers}
                handleSaveChanges={handleSaveChanges}
                getInviteCodeById={getInviteCodeById}
                resetStates={resetStates}
              />
            </TabPanel>
            <TabPanel>
              <CertificationsTabPanel
                onButtonClick={() => setAssigningCert(true)}
                userId={selectedUser.id}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}

      {assigningCert && (
        <UserAssignCertModal
          userId={selectedUser.id}
          isOpen={assigningCert}
          setIsOpen={setAssigningCert}
        />
      )}
      <ConfirmationDialog ref={confirmationDialogRef} />
    </LargeModal>
  );
};

interface UserModalTabProps {
  text: string;
  dataTestId?: string;
}

const UserModalTab = (props: UserModalTabProps) => {
  const { text, dataTestId } = props;
  return (
    <Tab
      fontSize={"smaller"}
      _selected={{
        color: Mid_Blue,
        borderBottom: "3px solid",
      }}
      px={4}
      data-testid={dataTestId}
    >
      {text}
    </Tab>
  );
};

export default UserViewEditModal;
