import { useContext } from "react";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { BlockSection } from "../../Common/BlockSection";
import { getSkillDescription, getSkillName } from "../../../Utils/SkillsUtil";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { useBreakpointValue } from "@chakra-ui/react";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { Helmet } from "react-helmet";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { HEADER_LEARN_BACKGROUND } from "../../../Styles/HeadversityStyle";
import { FeaturedLessonsPanel } from "./FeaturedLessonsPanel";
import { SuggestedLessonsForYouPanel } from "../Home/SuggestedLessonsForYouPanel";
import { LessonsScrollPanel } from "../Shared/LessonsScrollPanel";
import { HVTestId } from "../../../Testing/dataTestIds";
import { StickyBreadcrumb } from "../../Common/StickyBreadcrumb";
import { ErrorPage } from "../../Shared/Error/ErrorPage";
import { getCustomTitle } from "../../../Utils/LessonsUtil";

export const LessonsPage = () => {
  const {
    skills,
    lessonMap,
    lessonNotFound,
    getPageTitle,
    company,
    inviteCode,
  } = useContext<IGlobalProvider>(GlobalContext);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const customTitle = getCustomTitle(inviteCode, company);
  return (
    <>
      {!lessonNotFound ? (
        <>
          <Helmet>
            <title>
              {getPageTitle(
                `${HVLocalizeStrings.SOLO_MENU_LEARN} - ${HVLocalizeStrings.HEADVERSITY}`
              )}
            </title>
          </Helmet>
          <HeaderSetter
            title={HVLocalizeStrings.SOLO_MENU_LEARN}
            description={HVLocalizeStrings.SOLO_LEARN_DESCRIPTION}
            backgroundImage={HEADER_LEARN_BACKGROUND}
          />

          <StickyBreadcrumb
            crumbs={[
              { url: "/", text: HVLocalizeStrings.SOLO_MENU_HOME },
              { url: "/explore", text: HVLocalizeStrings.EXPLORE },
            ]}
            current={HVLocalizeStrings.SOLO_MENU_LEARN}
          />

          <FadeAfterDelay>
            <FeaturedLessonsPanel
              isWide={false}
              isHigh={true}
              noSeeAll={true}
              from={"Lessons"}
            />

            {customTitle && (
              <SuggestedLessonsForYouPanel
                noSeeAll={true}
                fullDescription={true}
              />
            )}

            {Object.keys(lessonMap)
              .filter(
                (key) => skills.some((x) => x.id.toString() === key) // keep only lessons having a skill attached
              )
              .map((key) => {
                return (
                  <BlockSection
                    key={key}
                    fadeIn={true}
                    title={
                      <BlockSectionTitle
                        title={`${getSkillName(parseInt(key), skills).replace(
                          ".",
                          ""
                        )}`}
                        description={getSkillDescription(parseInt(key), skills)}
                      />
                    }
                    columns={1}
                    dataTestId={`${HVTestId.LessonsPage.lessonContainer}_${key}`}
                  >
                    <LessonsScrollPanel
                      isDesktop={isDesktop}
                      lessonsToShow={lessonMap[key]}
                      isWide={true}
                      fullDescription={true}
                      from={"Lessons on Skill"}
                    />
                  </BlockSection>
                );
              })}
          </FadeAfterDelay>
        </>
      ) : (
        <ErrorPage />
      )}
    </>
  );
};
