import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Menu,
MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ColorPicker, ColorPickerChangeEvent } from "@progress/kendo-react-inputs";

export interface CharterFontSelectorProps {
  selectorName: string;
  color: string
  onFontSelect: (font: string) => void;
  onFontColorSelect: (font: string) => void;
}

export const CharterFontSelector = ({
  selectorName,
  color,
  onFontSelect,
  onFontColorSelect,
}: CharterFontSelectorProps) => {
  const fonts = [
    { font: "Lato", text: "Lato" },
    { font: "LibreBaskerville", text: "LibreBaskerville" },
    { font: "Merriweather", text: "Merriweather" },
    { font: "Montserrat", text: "Montserrat" },
    { font: "DancingScript", text: "DancingScript" },
    { font: "Inconsolata", text: "Inconsolata" },
    { font: "Oswald", text: "Oswald" },
    { font: "PlayfairDisplay", text: "PlayfairDisplay" },
    { font: "Roboto", text: "Roboto" },
    { font: "WorkSans", text: "WorkSans" },
    { font: "Barlow", text: "Barlow" },
  ];

  const handleColorChange = (event: ColorPickerChangeEvent) => {
    onFontColorSelect(event.value);
  };

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      border="1px solid #edf1f4"
      bg={"#edf1f4"}
      borderRadius="md"
      height={"40px"}
      pr={"8px"}
    >
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          <Text>{selectorName}</Text>
        </MenuButton>
        <MenuList>
          {fonts.map((font, index) => (
            <MenuItem
              key={font.font}
              fontFamily={font.font}
              onClick={() => onFontSelect(font.font)}
            >
              {font.text}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Box position="relative">
        <ColorPicker
          value={color}
          onChange={handleColorChange}
          view="gradient"
        />
      </Box>
    </Box>
  );
};
