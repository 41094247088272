import React, { useContext, useEffect, useState } from "react";
import {
  Flex,
  useBreakpointValue,
  Text,
  useDisclosure,
  Button,
  Box,
  Divider,
  useToken,
} from "@chakra-ui/react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  DESTRUCTIVE_TEXT_COLOR,
  Mid_Blue,
  PRIMARY_TEXT_COLOR,
  TEAM_SELECTEDTEAM_BORDER,
} from "../../../Styles/HeadversityStyle";
import { TeamList } from "./TeamList";
import { PathTeamLessonBlock } from "../Path/PathTeamLessonBlock";
import { AddTeammateModal } from "./AddTeammateModal";
import { TeamCommandModals } from "./TeamCommandModals";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import dayjs from "dayjs";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { BlockAccordion } from "../../Common/BlockAccordion";
import { BlockAccordionButtonBlock } from "../../Common/BlockAccordionButtonBlock";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import {
  getLastPresentedByTimeSpan,
  isTeamLessonDone,
} from "../../../Utils/TeamUtil";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import withUpNextPresentation from "../Shared/withUpNextPresentation";
import {
  PathDto,
  PathTeamLessonDto,
  TeamLessonUserInstanceStatus,
  TeamUserDto,
  UserRole,
} from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";

export default function ManagementAccordion({ expandable, team, teamId }: any) {
  const {
    paths,
    deleteTeamFromServer,
    deleteTeamUserFromServer,
    currentTeam,
    self,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [teamMembers, setTeamMembers] = useState<TeamUserDto[]>([]);
  const [pathsComplete, setPathsComplete] = useState<number>(0);
  const [teamLessonsComplete, setTeamLessonsComplete] = useState<number>(0);
  const [numberOfTeamLessons, setNumberOfTeamLessons] = useState<number>(0);
  const [upNextTeamLesson, setUpNextTeamLesson] =
    useState<PathTeamLessonDto | null>(null);
  const [upNextTeamLessonHeader, setUpNextTeamLessonHeader] = useState<string>(
    HVLocalizeStrings.CONTINUE_WHERE_YOU_LEFT_OFF
  );
  const [lastMet, setLastMet] = useState<string>(
    HVLocalizeStrings.TEAM_NOT_YET_MET
  );
  const [showQRCode, setShowQRCode] = useState<boolean>(true);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  const {
    onClose: onCloseSelectOrAdd,
    onOpen: onOpenSelectOrAdd,
    isOpen: isOpenSelectOrAdd,
  } = useDisclosure();

  const {
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
    isOpen: isOpenEdit,
  } = useDisclosure();

  const { confirmationDialogRef, showToast } =
    useContext<IShellProvider>(ShellContext);
  const UpNextTeamLessonBlock = withUpNextPresentation(PathTeamLessonBlock);

  const handleDeleteTeam = () => {
    (confirmationDialogRef.current as any).confirmation(
      HVLocalizeStrings.TEAM_DELETE_TEAM,
      `${HVLocalizeStrings.TEAM_CONFIRM_DELETE.replace("{0}", team?.name)}`,
      ConfirmationButtons.YesNo,
      () => {
        track(TEAM_EVENTS.DeleteTeam, {
          HV_Team_Id: teamId,
          HV_Team_Name: team.name,
        });
        deleteTeamFromServer(teamId).then(() => {
          showToast(
            true,
            "team-delete-success",
            HVLocalizeStrings.TEAM_DELETE_SUCCESS_TITLE
          );
        });
      }
    );
  };

  useEffect(() => {
    setTeamMembers(team["teamUsers"]);
  }, [team]);

  useEffect(() => {
    let pathsCompleteCount = 0;
    let teamLessonsCompleteForAllPathsCount = 0;
    let teamLessonsForAllPathsCount = 0;
    let upNext: PathTeamLessonDto | null = null;
    let upNextHeader = HVLocalizeStrings.CONTINUE_WHERE_YOU_LEFT_OFF;

    let lastMetDate = null;
    let lastMetDateUnix = -1;
    if (paths[teamId]) {
      paths[teamId]?.map((teamPath: PathDto) => {
        const teamLessonsCompleteForCurrentPath =
          teamPath.pathTeamLessons?.filter(
            (pathTeamLesson: PathTeamLessonDto) =>
              pathTeamLesson.teamLessonInstanceStatus ===
                TeamLessonUserInstanceStatus.Catchup ||
              isTeamLessonDone(pathTeamLesson)
          ).length;
        teamLessonsCompleteForAllPathsCount +=
          teamLessonsCompleteForCurrentPath;
        teamLessonsForAllPathsCount += teamPath.pathTeamLessons?.length;
        if (
          teamLessonsCompleteForCurrentPath == teamPath.pathTeamLessons?.length
        ) {
          pathsCompleteCount++;
        }
        teamPath?.pathTeamLessons?.forEach((item) => {
          if (
            (!upNext?.teamLessonInstanceStatus ||
              [
                TeamLessonUserInstanceStatus.Initialized,
                TeamLessonUserInstanceStatus.InProgress,
              ].indexOf(upNext?.teamLessonInstanceStatus) < 0) &&
            item.teamLessonInstanceStatus &&
            [
              TeamLessonUserInstanceStatus.Initialized,
              TeamLessonUserInstanceStatus.InProgress,
            ].indexOf(item.teamLessonInstanceStatus) > -1 &&
            getLastPresentedByTimeSpan(item) <= 15
          ) {
            upNext = item;
            upNextHeader = HVLocalizeStrings.JOIN_TEAM_LESSON_IN_PROGRESS;
          } else if (
            !upNext &&
            item.teamLessonInstanceStatus ===
              TeamLessonUserInstanceStatus.UpNext
          ) {
            upNext = item;
          }
          if (
            item?.lastPresentedDate &&
            dayjs.utc(`${item?.lastPresentedDate}`).unix() > lastMetDateUnix
          ) {
            lastMetDate = dayjs.utc(`${item?.lastPresentedDate}`);
            lastMetDateUnix = dayjs.utc(`${item?.lastPresentedDate}`).unix();
          }
        });
      });
      setUpNextTeamLesson(upNext);
      setUpNextTeamLessonHeader(upNextHeader);

      setPathsComplete(pathsCompleteCount);
      setTeamLessonsComplete(teamLessonsCompleteForAllPathsCount);
      setNumberOfTeamLessons(teamLessonsForAllPathsCount);
      if (lastMetDate) {
        setLastMet(
          HVLocalizeStrings.TEAM_LAST_MET +
            " " +
            dayjs
              .utc(`${lastMetDate}`)
              .local()
              .format(HVLocalizeStrings.DATE_FORMAT)
        );
      }
    }
  }, [paths[teamId]]);

  const border = useToken("colors", [TEAM_SELECTEDTEAM_BORDER])[0];

  return (
    <>
      <Box mt={"8"}>
        <BlockAccordion
          bg={"white"}
          accordionButtonGap={"8"}
          accordionButtonPaddingX={isMobile ? "4" : "8"}
          accordionButtonPaddingY={"6"}
          yOffsetScrollTo={-100}
          border={team?.id === currentTeam?.id ? border : undefined}
          data-testid={`${HVTestId.ManagementAccordion.teamButton}${team?.id}`}
          buttonContent={
            <>
              <BlockAccordionButtonBlock
                title={team?.name}
                content={
                  <>
                    <Flex
                      flexDir={"column"}
                      gap={isMobile ? undefined : "1"}
                      pb={isMobile ? "2" : undefined}
                      fontSize={"15px"}
                    >
                      <Flex
                        gap={isMobile ? "0" : "3"}
                        flexDir={isMobile ? "column" : "row"}
                      >
                        <Flex
                          w={isMobile ? undefined : "200px"}
                          gap={"2"}
                          pb={isMobile ? "5px" : undefined}
                        >
                          <Text
                            fontWeight={"semibold"}
                            color={Mid_Blue}
                            textAlign={"left"}
                            data-testid={
                              HVTestId.ManagementAccordion.totalMembers
                            }
                          >
                            {team?.teamUsers ? team?.teamUsers.length : 0}
                          </Text>
                          <Text color={PRIMARY_TEXT_COLOR}>
                            {team?.teamUsers.length > 1
                              ? ` ${HVLocalizeStrings.MEMBERS}`
                              : ` ${HVLocalizeStrings.MEMBER}`}
                          </Text>
                        </Flex>
                        <Flex
                          w={isMobile ? undefined : "300px"}
                          gap={isMobile ? "2" : "1"}
                        >
                          <Text
                            fontWeight={"semibold"}
                            color={Mid_Blue}
                            textAlign={isMobile ? "left" : "center"}
                            w={isMobile ? undefined : "60px"}
                            data-testid={
                              HVTestId.ManagementAccordion.pathsCompleted
                            }
                          >
                            {pathsComplete}/{paths[teamId]?.length}
                          </Text>
                          <Text color={PRIMARY_TEXT_COLOR}>
                            {HVLocalizeStrings.TEAM_PATH_STATS_TITLE}
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex
                        gap={isMobile ? "0" : "3"}
                        flexDir={isMobile ? "column-reverse" : "row"}
                      >
                        <Flex
                          w={isMobile ? undefined : "200px"}
                          pt={isMobile ? "5px" : undefined}
                        >
                          <Text
                            color={PRIMARY_TEXT_COLOR}
                            data-testid={
                              HVTestId.ManagementAccordion.lastMetDate
                            }
                          >
                            {lastMet}
                          </Text>
                        </Flex>
                        <Flex
                          w={isMobile ? undefined : "300px"}
                          gap={isMobile ? "2" : "1"}
                        >
                          <Text
                            fontWeight={"semibold"}
                            color={Mid_Blue}
                            textAlign={isMobile ? "left" : "center"}
                            w={isMobile ? undefined : "60px"}
                            data-testid={
                              HVTestId.ManagementAccordion
                                .presentationsCompleted
                            }
                          >
                            {teamLessonsComplete}/{numberOfTeamLessons}
                          </Text>
                          <Text color={PRIMARY_TEXT_COLOR}>
                            {HVLocalizeStrings.TEAM_TEAM_LESSON_TITLE}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </>
                }
                additionalContent={
                  <Flex
                    justify={isMobile ? "center" : "end"}
                    align={"center"}
                    h={"100%"}
                    flex={"1"}
                  >
                    <AnimatedButton
                      colorSet={AnimatedButtonColorSet.Third}
                      text={HVLocalizeStrings.TEAM_TEAM_LESSON_QR}
                      w={"175"}
                      onClick={(e: MouseEvent) => {
                        e.stopPropagation();
                        track(TEAM_EVENTS.ShareTeamInvite, {
                          HV_Team_Id: team.id,
                          HV_Team_Name: team.name,
                        });
                        setShowQRCode(true);
                        onOpenSelectOrAdd();
                      }}
                      dataTestId={HVTestId.ManagementAccordion.teamInfoButton}
                    />
                  </Flex>
                }
              />
            </>
          }
          panelContent={
            <>
              <Flex
                flexDir={"column"}
                gap={"8"}
                px={[0, 0, 0, 4]}
                w={"100%"}
                align={"center"}
              >
                <TeamList
                  teamMembers={teamMembers}
                  team={team}
                  contentAbovePager={
                    <Button
                      className="button-link"
                      color={PRIMARY_TEXT_COLOR}
                      onClick={() => {
                        setShowQRCode(false);
                        onOpenSelectOrAdd();
                      }}
                      data-testid={HVTestId.ManagementAccordion.addMemberButton}
                    >
                      {HVLocalizeStrings.TEAM_ADD_MEMBER}
                    </Button>
                  }
                />

                {upNextTeamLesson ? (
                  <>
                    <UpNextTeamLessonBlock
                      pathTeamLesson={upNextTeamLesson}
                      forceOpaque
                      selectedTeam={team}
                      titleText={upNextTeamLessonHeader}
                      trackingOrigin={"Management Accordion UpNext"}
                    />
                  </>
                ) : (
                  <Divider />
                )}

                <Flex flexDir={"column"} gap={"2"} mt={"2"}>
                  <Button
                    className="button-link"
                    color={PRIMARY_TEXT_COLOR}
                    fontSize="sm"
                    onClick={() => {
                      onOpenEdit();
                    }}
                    data-testid={HVTestId.ManagementAccordion.editTeamButton}
                  >
                    {HVLocalizeStrings.TEAM_EDIT_TEAM_NAME}
                  </Button>
                  <Button
                    className="button-link"
                    color={DESTRUCTIVE_TEXT_COLOR}
                    fontSize="sm"
                    onClick={() => {
                      (confirmationDialogRef.current as any).confirmation(
                        HVLocalizeStrings.TEAM_LEAVE_CURRENT_TEAM,
                        `${HVLocalizeStrings.TEAM_LEAVE_DESCRIPTION}`,
                        ConfirmationButtons.YesNo,
                        () => {
                          track(TEAM_EVENTS.LeaveTeam, {
                            HV_Team_Id: teamId,
                            HV_Team_Name: team?.name,
                          });
                          deleteTeamUserFromServer(teamId, self?.id as number);
                        }
                      );
                    }}
                    data-testid={HVTestId.ManagementAccordion.leaveTeamButton}
                  >
                    {HVLocalizeStrings.TEAM_LEAVE_CURRENT_TEAM}
                  </Button>
                  {self && self?.role <= UserRole.Admin && (
                    <Button
                      className="button-link"
                      color={DESTRUCTIVE_TEXT_COLOR}
                      fontSize="sm"
                      onClick={() => {
                        handleDeleteTeam();
                      }}
                      data-testid={
                        HVTestId.ManagementAccordion.deleteTeamButton
                      }
                    >
                      {HVLocalizeStrings.TEAM_DELETE_TEAM}
                    </Button>
                  )}
                </Flex>
              </Flex>
            </>
          }
        />
      </Box>
      {isOpenSelectOrAdd && (
        <AddTeammateModal
          showNameInput={false}
          open={isOpenSelectOrAdd}
          close={onCloseSelectOrAdd}
          teamId={teamId}
          teamCode={team?.code}
          teamName={team?.name}
          showQRCode={showQRCode}
          memberList={teamMembers}
        />
      )}

      {isOpenEdit && (
        <TeamCommandModals
          open={isOpenEdit}
          close={onCloseEdit}
          teamName={team?.name}
          teamId={teamId}
          type={"edit"}
        />
      )}
    </>
  );
}
