import dayjs from "dayjs";
import {
  CompanyDto,
  CompletionState,
  InviteCodeDto,
  MicroLessonDto,
} from "@headversity/contract";

export const getLessonCompletionDateAndStatus = (lesson: MicroLessonDto) => {
  let state = CompletionState.notStarted;
  let date: any = null;
  if (lesson.microLessonUserInstances?.length > 0) {
    state = lesson.microLessonUserInstances[0].state;
    date = dayjs
      .utc(lesson.microLessonUserInstances[0].updatedAt.toString())
      .local();
  }
  return { state: state, updatedAt: date };
};

export const postMessageToIframe = (
  iframeId: string,
  message: string,
  extra?: {}
) => {
  const iframe = document.getElementById(iframeId) as any;

  // try again if iframe is not ready
  if (!iframe || !iframe.contentWindow) {
    setTimeout(() => {
      postMessageToIframe(iframeId, message, extra);
    }, 1000);
    return;
  }

  iframe.contentWindow.postMessage(
    {
      ...extra,
      rise360: message,
    },
    "*"
  );
};

export const getCustomTitle = (
  inviteCode: InviteCodeDto | null,
  company: CompanyDto | null
) => {
  return inviteCode &&
    inviteCode.customLessonTitle &&
    (inviteCode.customLessonTitle as string).trim().length > 0
    ? (inviteCode.customLessonTitle as string)
    : (company?.customLessonTitle as string);
};
