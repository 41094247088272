import { Box, ModalOverlay, Spinner } from "@chakra-ui/react";
import { HV_SPINNER_COLOR } from "../../Styles/HeadversityStyle";

interface HvSpinnerProps {
  pos?: any;
  withOverlay?: boolean;
  centerVertically?: boolean;
}

export const HvSpinner = ({
  pos,
  withOverlay,
  centerVertically,
}: HvSpinnerProps) => {
  const spinnerElement = (
    <>
      {withOverlay && <ModalOverlay />}
      <Spinner position={pos} color={HV_SPINNER_COLOR} />
    </>
  );

  return centerVertically ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      {spinnerElement}
    </Box>
  ) : (
    spinnerElement
  );
};
