import styled from "styled-components";
import { Box } from "@chakra-ui/react";

const LoaderDiv = styled.div`
  height: 30px;
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 30px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 0px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

interface LoadingProp {
  colorOne?: string;
  colorTwo?: string;
  colorThree?: string;
}

export const Loading = (props: LoadingProp) => {
  const { colorOne, colorTwo, colorThree } = props;
  return (
    <LoaderDiv>
      <Box className={"lds-ellipsis"}>
        <Box bg={colorOne ?? "LOADING_FIRST_BACKGROUND_COLOR"}></Box>
        <Box bg={colorTwo ?? "LOADING_SECOND_BACKGROUND_COLOR"}></Box>
        <Box bg={colorThree ?? "LOADING_THIRD_BACKGROUND_COLOR"}></Box>
        <Box bg={colorThree ?? "LOADING_THIRD_BACKGROUND_COLOR"}></Box>
      </Box>
    </LoaderDiv>
  );
};
