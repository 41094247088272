import React, { useContext, useEffect, useState } from "react";
import { CalmingToolModal } from "./CalmingToolModal";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  CalmingToolContext,
  ICalmingToolProvider,
} from "../../../../State/Solo/CalmingToolContext";
import { getKey } from "../../../../Utils/Helpers";
import { ToolLanding } from "../ToolLanding";
import { CalmingToolStat, CalmingToolStatCard } from "./CalmingToolStatCard";
import { EVENTS, track } from "../../../../Utils/Analytics";

interface CalmingToolLogProps {
  toolSkillInfo: any;
}

export const CalmingToolLanding = (props: CalmingToolLogProps) => {
  const { toolSkillInfo } = props;
  const [calmingToolOpened, setCalmingToolOpened] = useState<boolean>(false);
  const { calmingToolUserUsage, getCalmingToolUsageServer } =
    useContext<ICalmingToolProvider>(CalmingToolContext);

  useEffect(() => {
    if (getKey()) {
      getCalmingToolUsageServer();
    }
  }, [getKey()]);

  return (
    <>
      <ToolLanding
        showTopBar={false}
        actionBarTitle={HVLocalizeStrings.CALMING_TOOLS}
        actionBarContent={<></>}
        mobileToolStat={
          <CalmingToolStat
            calmingUserEvents={calmingToolUserUsage}
            isSmall={true}
          />
        }
        toolCard={
          <CalmingToolStatCard
            setCalmingToolOpened={() => {
              track(EVENTS.ToolOpened, { HV_Tool: "Calming" });
              setCalmingToolOpened(true);
            }}
            calmingUserEvents={calmingToolUserUsage}
          />
        }
        toolInfoOne={HVLocalizeStrings.CALMING_TOOLS_PAGE_INFO_ONE}
        toolBoost={HVLocalizeStrings.CALMING_TOOLS_PAGE_BOOST}
        skillInfos={toolSkillInfo}
        mobileLaunchText={HVLocalizeStrings.CALMING_TOOLS_PAGE_USE_TOOL}
        mobileLaunchFunction={() => {
          track(EVENTS.ToolOpened, { HV_Tool: "Calming" });
          setCalmingToolOpened(true);
        }}
      ></ToolLanding>
      <CalmingToolModal
        open={calmingToolOpened}
        setOpen={setCalmingToolOpened as any}
      ></CalmingToolModal>
    </>
  );
};
