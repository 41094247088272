import { useState } from "react";

export interface IModalsProvider {
  isUninterruptibleModalOpen: boolean;
  setIsUninterruptibleModalOpen: (isUninterruptibleModalOpen: boolean) => void;
  closeDialog: boolean;
  setCloseDialog: (closeDialog: boolean) => void;
}
export const useModals = (): IModalsProvider => {
  const [isUninterruptibleModalOpen, setIsUninterruptibleModalOpen] =
    useState<boolean>(false);
  const [closeDialog, setCloseDialog] = useState<boolean>(false);
  return {
    isUninterruptibleModalOpen,
    setIsUninterruptibleModalOpen,
    closeDialog,
    setCloseDialog,
  };
};
