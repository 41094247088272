import { Box, Text } from "@chakra-ui/react";

export interface InsightsNoDataProps {
  title?: string;
  description?: string;
  linkText?: string;
  linkUrl?: string;
}

const InsightsNoData = (props: InsightsNoDataProps) => {
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      bgColor="white"
      maxW="400px"
    >
      <Box m={4}>
        {props.title && (
          <Text textAlign="center" as="p" fontWeight="semibold">
            {props.title}
          </Text>
        )}
        {props.description && (
          <Text fontSize={"sm"} textAlign="center" as="p">
            {props.description}
          </Text>
        )}
        {props.linkText && props.linkUrl && <Text>{props.linkText}</Text>}
      </Box>
    </Box>
  );
};

export default InsightsNoData;
