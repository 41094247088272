import { CharterDataDto } from "@headversity/contract";
import { setTeamLessonUserInstanceCharterData } from "../../Api/Team/TeamLessonUserInstanceApi";
import { getKey } from "../../Utils/Helpers";

export interface IUseTeamCharterProvider {
  saveCharterData: (tluid: number, data: CharterDataDto) => Promise<any>;
}

export const useTeamCharter = (): IUseTeamCharterProvider => {
  const onHandleSaveCharterData = async (
    tluid: number,
    data: CharterDataDto
  ): Promise<any> => {
    return setTeamLessonUserInstanceCharterData(tluid, data, getKey());
  };

  return {
    saveCharterData: onHandleSaveCharterData,
  };
};
