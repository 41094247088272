import dayjs from "dayjs";
import Highcharts from "highcharts";
import { HVLocalizeStrings } from "../Localization/HVLocalizeStrings";

export const getPointStartFromDate = (date: Date): number => {
  return dayjs(date).startOf("day").valueOf();
};

export const parseDateFromYearMonth = (yearMonth: string): Date => {
  const year = Number(yearMonth.split("-")[0]);
  const month = Number(yearMonth.split("-")[1]) - 1; // Months are 0-indexed in JavaScript
  const dateLocal = new Date(year, month);
  return dateLocal;
};

export const dateTimeLabelFormats = {
  millisecond: "%b-%d",
  second: "%b-%d",
  minute: "%b-%d",
  hour: "%b-%d",
  day: "%b-%d",
  week: "%b-%d",
  month: "%b %Y",
};

export const buildDateTimeLabelFormats = () => {
  return {
    millisecond: HVLocalizeStrings.CHARTS_DAY_FORMAT,
    second: HVLocalizeStrings.CHARTS_DAY_FORMAT,
    minute: HVLocalizeStrings.CHARTS_DAY_FORMAT,
    hour: HVLocalizeStrings.CHARTS_DAY_FORMAT,
    day: HVLocalizeStrings.CHARTS_DAY_FORMAT,
    week: HVLocalizeStrings.CHARTS_DAY_FORMAT,
    month: "%b %Y",
  };
};

export const setInsightsChartOptions = (
  type: string,
  series: any[],
  dates: string[] | undefined | null,
  pointStart: number,
  width: number,
  height: number
) => {
  // convert each date 04-24 to timestamp
  const timestamps = dates?.map((date) =>
    parseDateFromYearMonth(date).valueOf()
  );

  let min, max;
  if (timestamps && timestamps.length > 0) {
    // using dayjs, calculate 3 days before startDate
    min = dayjs(timestamps[0]).subtract(1, "minutes").valueOf();
    max = dayjs(timestamps[timestamps.length - 1])
      .add(1, "minutes")
      .valueOf();
  }

  return {
    chart: {
      type: type,
      fontFamily: "Barlow",
      animation: false,
      height: height,
      width: width,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    series: series,
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: { ...dateTimeLabelFormats, month: "%b" },
      labels: {
        formatter: function (this: any) {
          return Highcharts.dateFormat("%b", this.value);
        },
      },
      tickPositions: timestamps,
      min,
      max,
    },
    tooltip: {
      formatter: function (this: any) {
        return this.y;
      },
    },
    plotOptions: {
      series: {
        pointStart: pointStart,
        pointIntervalUnit: "month",
      },
    },
  };
};

const getLast6MonthsLabels = (): string[] => {
  let labels = [];
  let currentDate = dayjs();
  for (let i = 5; i >= 0; i--) {
    let month = currentDate.subtract(i, "month");
    labels.push(month.format("YYYY-MM"));
  }
  return labels;
};

export const setInsightsChart6MonthsData = (
  rawData: {
    date: string;
    count: number;
  }[],
  accumulative = false
): number[] => {
  const labels = getLast6MonthsLabels();
  const mapped = labels.map((month) => {
    let dataEntry = rawData.find((entry) => entry.date === month);
    return dataEntry ? dataEntry.count : 0;
  });
  if (accumulative) {
    // if we have no datapoint, we use the last value > 0 from previous months
    let lastPositiveVal = 0;
    for (let i = 0; i < mapped.length; i++) {
      if (mapped[i] > 0) {
        // store the lastPositiveValue
        lastPositiveVal = mapped[i];
      } else {
        mapped[i] = lastPositiveVal;
      }
    }
  }
  return mapped;
};
