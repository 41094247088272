import {
  Box,
  Center,
  Divider,
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext } from "react";
import { ReachHelpResourcesContext } from "../../../State/Reach/ReachHelpResourcesContext";
import ReachHelpResourceItem from "./ReachHelpResourceItem";
import ReachHelpResourcesListToolbar from "./ReachHelpResourcesListToolbar";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";

const ReachHelpResourcesList = () => {
  const orgContext = useContext(ReachHelpResourcesContext);
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  const handleClick = () => {
    orgContext.setAddHelpResourceDialogOpen(true);
    track(REACH_EVENTS.ReachHelpResOpenAddHelpResourceForm);
  };
  return (
    <Flex flexDirection="column" pb={4}>
      <ReachHelpResourcesListToolbar
        addResourceButton={
          isMobile ? <></> : <AddResourceButton onClick={handleClick} />
        }
      />
      <Divider orientation="horizontal" px={0} />
      <Flex flexDirection="column">
        {orgContext.helpResources.map((helpResource) => (
          <ReachHelpResourceItem
            key={helpResource.id}
            resource={helpResource}
          />
        ))}
        {orgContext.helpResources.length === 0 && (
          <Text m="4">
            {HVLocalizeStrings.REACH_HELP_RESOURCE_NO_RESOURCES}
          </Text>
        )}
      </Flex>
      {isMobile && (
        <Center mt={3}>
          <AddResourceButton onClick={handleClick} />
        </Center>
      )}
    </Flex>
  );
};

interface AddResourceButtonProps {
  onClick: () => void;
}

const AddResourceButton = ({ onClick }: AddResourceButtonProps) => {
  return (
    <Box>
      <AnimatedButton
        colorSet={AnimatedButtonColorSet.Fourth}
        text={HVLocalizeStrings.REACH_HELP_RESOURCE_ADD_NEW}
        onClick={onClick}
        boxShadow={"lg"}
        w={"auto"}
      />
    </Box>
  );
};

export default ReachHelpResourcesList;
