import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { UploadType } from "@headversity/contract";
import { SectorDto } from "@headversity/contract/Dto/SectorDto";
import { Sector } from "@headversity/contract/Enums/Sector";
import { useContext, useEffect, useMemo, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { ReachMyOrganizationContext } from "../../../State/Reach/ReachMyOrganizationContext";
import {
  Border_Radius,
  DISABLED_BORDER,
  Equity_Black,
} from "../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { BlockSection } from "../../Common/BlockSection";
import { HvSelect } from "../../Common/HvSelect";
import { HvSpinner } from "../../Common/HvSpinner";
import { HvTextInput } from "../../Common/HvTextInput";
import ImageUploader from "../SelfService/ImageUploader";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";

const getComponentDimensions = (isDesktop: boolean) => {
  const labelWidth = isDesktop ? "10%" : "200px";
  const inputWidth = isDesktop ? "30%" : "98%";
  const inputHeight = "40px";
  const inputImageWidth = "225px";
  const inputImageHeight = "155px";
  const captionWidth = isDesktop ? "60%" : "100%";
  return {
    labelWidth,
    inputWidth,
    inputHeight,
    inputImageHeight,
    inputImageWidth,
    captionWidth,
  };
};
export const ReachManageMyOrganizationPanel = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true }) ?? false;
  const {
    labelWidth,
    inputWidth,
    inputHeight,
    inputImageWidth,
    inputImageHeight,
    captionWidth,
  } = getComponentDimensions(isDesktop);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [sector, setSector] = useState<Sector | undefined>(undefined);
  const [dirty, setDirty] = useState<boolean>(false);
  const [isMissingFields, setIsMissingFields] = useState<boolean>(false);
  const handleImageUrlChanged = (url: string) => {
    setDirty(true);
    setLogoUrl(url);
  };

  const handleNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setName(event.target.value);
  };

  const handleSectorChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDirty(true);
    const sectorParsed = parseInt(event.target.value);
    setSector(sectorParsed);
  };

  const orgContext = useContext(ReachMyOrganizationContext);
  useEffect(() => {
    // when company is first set
    if (orgContext.company) {
      setName(orgContext.company.name);
      setLogoUrl(orgContext.company.logo);
      const sectorValid = typeof orgContext.company.sector === "number";
      setSector(
        sectorValid ? (orgContext.company.sector as Sector) : undefined
      );
    }
  }, [orgContext.company]);

  useEffect(() => {
    setIsMissingFields(!name.trim());
  }, [name]);

  const handleSetIsUploading = (isUploading: boolean) => {
    orgContext.setIsUploading(isUploading);
  };

  const handleSaveCompany = () => {
    setIsSaving(true);
    orgContext
      .updateCompany({
        ...orgContext.company,
        name,
        logo: logoUrl,
        sector,
        helpCenterVisited: orgContext.company?.helpCenterVisited ?? false,
      })
      .then(() => {
        orgContext.triggerDataRefresh();
        setIsSaving(false);
        setDirty(false);
        track(REACH_EVENTS.ReachMyOrgEditedOrganization);
      });
  };

  const buildUploader = (isDesktop: boolean) => {
    return (
      <Flex
        pl={isDesktop ? undefined : 4}
        flexDirection="column"
        width={inputWidth}
        alignItems="center"
      >
        <Flex
          border={DISABLED_BORDER}
          borderRadius={Border_Radius}
          flexDir={"column"}
          width={"100%"}
          justifyContent="center"
          alignItems={logoUrl ? "center" : undefined}
        >
          {logoUrl ? (
            <Image
              w={inputImageWidth}
              h={inputImageHeight}
              objectFit="contain"
              src={logoUrl}
            />
          ) : (
            <Box p={2} h={inputImageHeight}>
              <ImageUploader
                uploadType={UploadType.COMPANY_LOGO}
                handleBucketUrlChanged={handleImageUrlChanged}
                handleIsUploading={handleSetIsUploading}
              />
            </Box>
          )}
        </Flex>
        <Link
          onClick={() => handleImageUrlChanged("")}
          textDecoration={"underline"}
          fontSize={"sm"}
          display={logoUrl ? "block" : "none"}
        >
          <Text>{HVLocalizeStrings.REMOVE}</Text>
        </Link>
      </Flex>
    );
  };

  const buildNameSection = (isDesktop: boolean) => {
    return (
      <Flex
        flexDir={isDesktop ? "row" : "column"}
        alignItems={isDesktop ? "center" : "left"}
      >
        <Box fontSize="md" pl={6} w={labelWidth}>
          {HVLocalizeStrings.NAME}
        </Box>
        <Box pl={isDesktop ? undefined : 4} w={inputWidth}>
          <HvTextInput
            h={inputHeight}
            value={name}
            onChange={handleNameChanged}
            bg={"white"}
            color={Equity_Black}
          />
        </Box>
        <Box fontSize="sm" w={captionWidth} px={isDesktop ? 2 : 6}>
          {HVLocalizeStrings.REACH_ORGANIZATIONS_NAME_DESCRIPTION}
        </Box>
      </Flex>
    );
  };

  const buildLogoSection = (isDesktop: boolean) => {
    return (
      <Flex
        my={"2"}
        flexDir={isDesktop ? "row" : "column"}
        alignItems={isDesktop ? "center" : "left"}
      >
        <Box fontSize="sm" pl={6} w={labelWidth}>
          {HVLocalizeStrings.REACH_ORGANIZATIONS_LOGO}
        </Box>
        {buildUploader(isDesktop)}
        <Box
          px={isDesktop ? 2 : 6}
          fontSize="sm"
          w={captionWidth}
          dangerouslySetInnerHTML={{
            __html: HVLocalizeStrings.REACH_ORGANIZATIONS_LOGO_DESCRIPTION,
          }}
        />
      </Flex>
    );
  };

  const sectorOptions = useMemo(() => {
    return orgContext.sectors ? (
      orgContext.sectors.map((sector: SectorDto) => {
        return (
          <option key={sector.id} value={sector.id}>
            {sector.name as string}
          </option>
        );
      })
    ) : (
      <></>
    );
  }, [orgContext.sectors]);

  const buildSectorSection = (isDesktop: boolean) => {
    return (
      <Flex
        my={"2"}
        flexDir={isDesktop ? "row" : "column"}
        alignItems={isDesktop ? "center" : "left"}
      >
        <Box fontSize="sm" pl={6} w={labelWidth}>
          {HVLocalizeStrings.REACH_ORGANIZATIONS_SECTOR}
        </Box>
        <Box pl={isDesktop ? undefined : 4} w={inputWidth}>
          <HvSelect
            placeholder={
              typeof sector === "number"
                ? undefined
                : HVLocalizeStrings.REACH_ORGANIZATIONS_CHOOSE_SECTOR
            }
            h={inputHeight}
            value={sector}
            bg={"white"}
            color={Equity_Black}
            onChange={handleSectorChanged}
          >
            {sectorOptions}
          </HvSelect>
        </Box>
        <Box fontSize="sm" w={captionWidth} px={isDesktop ? 2 : 6}>
          {HVLocalizeStrings.REACH_ORGANIZATIONS_SECTOR_DESCRIPTION}
        </Box>
      </Flex>
    );
  };

  return (
    <Box
      bgColor={"white"}
      w={"100%"}
      border={DISABLED_BORDER}
      borderRadius={Border_Radius}
      boxShadow={"lg"}
      mt={6}
      mb={6}
    >
      <Box fontWeight={"semibold"} p={4}>
        {HVLocalizeStrings.REACH_ORGANIZATIONS_PROPERTIES}
      </Box>
      <Divider mb={4} />
      {orgContext.orgResourcesIsLoading ? (
        <BlockSection fadeIn={true} columns={1}>
          <Center>
            <HvSpinner />
          </Center>
        </BlockSection>
      ) : (
        <>
          {buildNameSection(isDesktop)}
          {buildSectorSection(isDesktop)}
          {buildLogoSection(isDesktop)}
          <Box display="flex" justifyContent="center" w={"100%"} my={4}>
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Secondary}
              w={210}
              text={HVLocalizeStrings.SAVE}
              onClick={handleSaveCompany}
              disabled={
                isSaving || !dirty || isMissingFields || orgContext.isUploading
              }
            />
          </Box>
        </>
      )}
    </Box>
  );
};
