import { Box, Flex, Text, Heading } from "@chakra-ui/react";
import { NodeDifferenceDto, NodeDto } from "@headversity/contract";
import { useContext } from "react";
import { HierarchyContext } from "../../../../State/Reach/ReachHierarchyContext";
import { White } from "../../../../Styles/HeadversityStyle";
import { HierarchyDiffViewer } from "./HierarchyDiffViewer";

interface HierarchyCompareProps {
  hierarchy?: NodeDto[];
}

const DifferenceCard = ({
  difference,
  hierarchy,
}: {
  difference: NodeDifferenceDto;
  hierarchy?: NodeDto[];
}) => {
  const nodesMap = new Map<number, NodeDto>();
  hierarchy?.forEach((node) => nodesMap.set(node.id, node));
  const getUserChanges = (diff: NodeDifferenceDto) => {
    if (diff.type !== "users") {
      return null;
    }

    // given two arrays of users, return the differences
    const getDifference = (users1: string[], users2: string[]) => {
      const added = users1.filter((u) => !users2.includes(u));
      const removed = users2.filter((u) => !users1.includes(u));
      return { added, removed };
    };
    const { added, removed } = getDifference(diff.value1, diff.value2);

    let message = "";
    if (added.length > 0) {
      message += `Added users: ${added.join(", ")}`;
    }
    if (removed.length > 0) {
      message += `Removed users: ${removed.join(", ")}`;
    }
    return message;
  };

  const getNodeName = (id: number) => {
    if (nodesMap.has(id)) {
      return `(${id})${nodesMap.get(id)?.name}`;
    }
    return `(${id})`;
  };

  const getReason = (diff: NodeDifferenceDto) => {
    switch (diff.type) {
      case "node": {
        // did we add or remove a node?
        if (!diff.value1) {
          return `Removed node ${getNodeName(diff.id)} from hierarchy.`;
        } else {
          return `Added node ${getNodeName(diff.id)} to hierarchy.`;
        }
      }
      case "details": {
        return `Node ${getNodeName(diff.id)} details updated`;
      }
      case "name": {
        return `Node ${getNodeName(diff.id)} name changed from ${JSON.stringify(
          diff.value2
        )} to ${JSON.stringify(diff.value1)}`;
      }
      case "parentId":
        return `Node ${getNodeName(diff.id)} parent changed from ${getNodeName(
          diff.value2
        )} to ${getNodeName(diff.value1)}`;
      case "users":
        return `Node ${getNodeName(diff.id)} users updated. ${getUserChanges(
          diff
        )}`;
      default:
        return "Unknown Difference";
    }
  };

  return (
    <Box bg={White} h="100%" m={2}>
      <Text>{getReason(difference)}</Text>
    </Box>
  );
};

export const HierarchyCompare = ({ hierarchy }: HierarchyCompareProps) => {
  const { compareDifference } = useContext(HierarchyContext);

  return (
    <Box>
      {compareDifference && (
        <>
          <HierarchyDiffViewer
            current={compareDifference.hierarchy1}
            snapshot={compareDifference.hierarchy2}
          />
          <Flex flexDir="column">
            <Heading mt={4} fontSize="md" fontWeight="semibold">
              Updates
            </Heading>
            {compareDifference.differences &&
              compareDifference.differences.length > 0 &&
              compareDifference.differences.map((difference, index) => (
                <DifferenceCard
                  key={index}
                  difference={difference}
                  hierarchy={hierarchy}
                />
              ))}
          </Flex>
        </>
      )}
    </Box>
  );
};
