import { useContext } from "react";
import { ScrollPanel } from "../../Common/ScrollPanel";
import { MicroLessonDto, NanoPracticeDto } from "@headversity/contract";
import { ScrollPanelBlock } from "../../Common/ScrollPanelBlock";
import { LessonBlock20 } from "../../Solo/Lessons/LessonBlock20";
import { NanoPracticeBlock } from "../../Solo/NanoPractices/NanoPracticeBlock";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { Box } from "@chakra-ui/react";

export interface RecommendedScrollPanelProps {
  isDesktop: boolean | undefined;
  nanoPractices: NanoPracticeDto[];
  lesson?: MicroLessonDto;
  startDate?: string;
  from: string;
}

export const RecommendedRefreshersPanel = (
  props: RecommendedScrollPanelProps
) => {
  const { isDesktop, nanoPractices, lesson, startDate, from } = props;

  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  const lWidth = "280px";
  const sWidth = isDesktop ? "200px" : "215px";
  const height = isDesktop ? "215px" : "181px";

  const getPr = () => {
    if (nanoPractices.length > 2) {
      if (isMainContainerXL) {
        return "14px";
      }
      if (isDesktop) {
        return "8px";
      }
    }
    if (isMainContainerXL) {
      return "10px";
    }
  };
  const getMaxWidth = () => {
    if (nanoPractices.length > 2) {
      if (isMainContainerXL) {
        return "876px";
      } else if (isDesktop) {
        return "628px";
      }
    }
  };

  return (
    <Box
      pr={getPr()}
      minW={!isDesktop ? "103%" : undefined}
      maxW={getMaxWidth()}
    >
      <ScrollPanel
        scrollOnMobileOnly={false}
        spaceBetween={8}
        showChevronContainer={true}
        slidesPerGroup={isDesktop && nanoPractices.length <= 2 ? 3 : 1}
        slidesPerView={"auto"}
        isPaginationOnContainer={true}
      >
        {nanoPractices?.map((practice: NanoPracticeDto) => {
          return ScrollPanelBlock(
            <NanoPracticeBlock
              from={from}
              practice={practice}
              width={isMainContainerXL ? lWidth : sWidth}
              height={height}
              startDate={startDate}
              isSmall={true}
              fullDescription={true}
              showPoints={true}
            />,
            isMainContainerXL ? lWidth : sWidth,
            practice?.id
          );
        })}

        {lesson !== undefined &&
          [lesson]?.map((item) => {
            return ScrollPanelBlock(
              <LessonBlock20
                width={isMainContainerXL ? lWidth : sWidth}
                height={height}
                isSmall={true}
                key={item.id}
                lesson={item}
                fullDescription={true}
                from={from}
                showPoints={true}
              />,
              isMainContainerXL ? lWidth : sWidth,
              item.id
            );
          })}
      </ScrollPanel>
    </Box>
  );
};
