import { useCallback, useState } from "react";
import {
  getImagePresignedURL,
  getPresignedURLForImageUpload,
  uploadImageWithPresignedURL,
} from "../../Api/Reach/S3UploadApi";
import { getKey } from "../../Utils/Helpers";
import { UploadType } from "@headversity/contract";

export interface IS3PresignedURLProvider {
  uploadImageToS3UsingPresignedURL: (
    file: File,
    uploadType: UploadType
  ) => Promise<void>;
  getImagePresignedURLFromS3: (fileName: string) => Promise<string>;
  fileName?: string;
  fileBucketUrl?: string;
  setFileName: (fileName: string | null) => void;
}

export const useS3PresignedURL = () => {
  const [fileName, setFileName] = useState<string | undefined>();
  const [fileBucketUrl, setFileBucketUrl] = useState<string | undefined>();

  const uploadImageToS3UsingPresignedURL = useCallback(
    async (file: File, uploadType: UploadType): Promise<void> => {
      const fileExtension = file.name.split(".").pop() as string;
      const axiosResponse = await getPresignedURLForImageUpload(
        fileExtension,
        uploadType,
        getKey()
      );
      const uploadPresignedURL = axiosResponse.data.uploadUrl;
      if (uploadPresignedURL) {
        setFileBucketUrl(axiosResponse.data.downloadUrl);
        await uploadImageWithPresignedURL(uploadPresignedURL, file);

        // extract and store the filename from S3 presigned URL
        const parsedURL = new URL(uploadPresignedURL);
        const pathname = parsedURL.pathname;
        const filename = pathname.substring(pathname.lastIndexOf("/") + 1);
        setFileName(filename);
      }
    },
    []
  );

  const getImagePresignedURLFromS3 = useCallback(
    async (fileName: string, uploadType: UploadType): Promise<string> => {
      const axiosResponse = await getImagePresignedURL(
        fileName,
        uploadType,
        getKey()
      );
      return axiosResponse.data;
    },
    []
  );

  return {
    uploadImageToS3UsingPresignedURL,
    getImagePresignedURLFromS3,
    fileName,
    fileBucketUrl,
    setFileName,
  };
};
