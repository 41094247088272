import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Center,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Calendar } from "../../../Common/Calendar";
import {
  IOptimismToolProvider,
  OptimismToolContext,
} from "../../../../State/Solo/OptimismToolContext";
import dayjs from "dayjs";
import { OPTIMISM_THREE_GOOD_THINGS_STEP } from "./OptimismTool";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import {
  challengeIsTodayAndIncomplete,
  getCurrentChallenge,
  getDayNumber,
} from "./Utils/OptimismUtils";
import SlidingCard from "../../../Common/Cards/SlidingCard/SlidingCard";
import {
  BLOCK_SECTION_TITLE_BORDER_BOTTOM,
  Border_Radius,
  BUTTON_PRIMARY_BACKGROUND_COLOR,
  BUTTON_PRIMARY_TEXT_COLOR,
  CALENDAR_SELECTION_DAYS_BACKGROUND_COLOR,
  CARD_BACKGROUND_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { HvSelect } from "../../../Common/HvSelect";
import { HVTestId } from "../../../../Testing/dataTestIds";
import {
  OptimismChallengeInput,
  OptimismPlanChallengeDto,
  OptimismPlanDto,
  OptimismToolStatus,
} from "@headversity/contract";

const isBetweenRange = (item: OptimismPlanDto, dateUnix: any) => {
  const startDate = dayjs.utc(item.startTime).local().startOf("day").unix();
  const endDate = dayjs
    .utc(item.startTime)
    .local()
    .add(item.numberOfDays, "day")
    .startOf("day")
    .unix();

  return dateUnix >= startDate && dateUnix < endDate;
};

const shouldHaveOrangeBorder = (
  startTime: string,
  dateDiff: number
): boolean => {
  const startDate = dayjs
    .utc(startTime)
    .local()
    .add(dateDiff - 1, "day")
    .startOf("day");
  const today = dayjs().endOf("day");
  return (
    startDate.date() === today.date() &&
    startDate.month() === today.month() &&
    startDate.year() === today.year()
  );
};

interface OptimismToolStepLandingProps {
  changePage: (page: string) => void;
  setCurrentChallenge: (
    currentChallenge: OptimismPlanChallengeDto | null
  ) => void;
  selectedDay: number | undefined;
  setSelectedDay: (selectedDay: number | undefined) => void;
}

export const OptimismToolStepLogs = (props: OptimismToolStepLandingProps) => {
  const { changePage, selectedDay, setSelectedDay } = props;

  const { currentPlannedItem, optimismPlans } =
    useContext<IOptimismToolProvider>(OptimismToolContext);

  const [selectedPlannedItem, setSelectedPlannedItem] =
    useState<OptimismPlanDto | null>(null);
  const [selectedYear, setSelectedYear] = useState<any>(null);
  const [selectedMonth, setSelectedMonth] = useState<any>(null);

  useEffect(() => {
    if (selectedPlannedItem) {
      setSelectedYear(dayjs.utc(selectedPlannedItem?.startTime).local().year());
      setSelectedMonth(
        dayjs.utc(selectedPlannedItem?.startTime).local().month()
      );
      if (currentPlannedItem === selectedPlannedItem) {
        setSelectedDay(getDayNumber(currentPlannedItem));
      } else {
        setSelectedDay(1);
      }
    }
  }, [currentPlannedItem, selectedPlannedItem]);

  useEffect(() => {
    if (currentPlannedItem) {
      setSelectedPlannedItem(currentPlannedItem);
    } else if (optimismPlans && optimismPlans?.length > 0) {
      setSelectedPlannedItem(optimismPlans[optimismPlans?.length - 1]);
    }
  }, [currentPlannedItem, optimismPlans]);

  const isSmall = useBreakpointValue({
    md: false,
    lg: false,
    base: true,
  });

  return (
    <>
      {optimismPlans && optimismPlans?.length > 0 && (
        <>
          <Heading
            textAlign={"left"}
            fontSize={"2xl"}
            fontWeight={"semibold"}
            color={MODAL_PRIMARY_TEXT_COLOR}
          >
            {HVLocalizeStrings.THINKING_TOOLS_INTRO_LANDING_TITLE}
          </Heading>
          <HvSelect
            maxW={"md"}
            value={selectedPlannedItem?.id}
            onChange={(val) => {
              setSelectedPlannedItem(
                optimismPlans.find(
                  (f) => f.id === parseInt(val.currentTarget.value)
                ) as OptimismPlanDto
              );
              setSelectedDay(1);
            }}
            data-testid={HVTestId.OptimismTool.steps.logs.logsSelect}
          >
            {[...optimismPlans]?.reverse().map((item, idx) => {
              return (
                <option key={idx} value={item.id}>
                  {dayjs
                    .utc(item.startTime.toString())
                    .local()
                    .format(HVLocalizeStrings.DATE_FORMAT)}{" "}
                  {item.numberOfDays}{" "}
                  {HVLocalizeStrings.OPTIMISM_TOOLS_DAYS_CHALLENGE}
                  {currentPlannedItem?.id === item.id
                    ? ` (${HVLocalizeStrings.OPTIMISM_TOOLS_CURRENT_PLAN})`
                    : ""}
                </option>
              );
            })}
          </HvSelect>

          {!isSmall && (
            <Flex flexDir={"row"}>
              {selectedPlannedItem && (
                <Stack
                  w={"50%"}
                  overflow={"hidden"}
                  align={"center"}
                  justify={"center"}
                >
                  <Calendar
                    key={selectedPlannedItem?.id}
                    year={selectedYear}
                    month={selectedMonth}
                    dateRenderCallBack={(item: any) => {
                      return (
                        <CalendarDate
                          selectedPlannedItem={selectedPlannedItem}
                          item={item}
                          selectedDay={selectedDay as number}
                          setSelectedDay={setSelectedDay}
                        />
                      );
                    }}
                  />
                </Stack>
              )}
              <OptimismDateResult
                changePage={changePage}
                selectedPlannedItem={selectedPlannedItem}
                selectedDay={selectedDay as number}
              />
            </Flex>
          )}
          {isSmall && selectedPlannedItem && (
            <Stack align={"center"} w={"100%"}>
              {Array.from(Array(selectedPlannedItem.numberOfDays).keys()).map(
                (day) => {
                  const item = getCurrentChallenge(
                    selectedPlannedItem,
                    day + 1
                  );
                  const currentDay = getDayNumber(selectedPlannedItem);
                  let isComplete = false;
                  if (item) {
                    isComplete = true;
                  }

                  if (day + 1 > currentDay) {
                    return <></>;
                  }
                  return (
                    <SlidingCard
                      title={`${HVLocalizeStrings.OPTIMISM_TOOLS_DAY} ${
                        day + 1
                      }`}
                      subTitle={
                        !isComplete &&
                        selectedPlannedItem.status ===
                          OptimismToolStatus.Pending
                          ? HVLocalizeStrings.OPTIMISM_TOOLS_TIME_TO_CHECK_IN
                          : dayjs
                              .utc(selectedPlannedItem.startTime)
                              .local()
                              .add(day, "day")
                              .format(HVLocalizeStrings.DATE_FORMAT)
                      }
                      border={
                        isComplete ? BLOCK_SECTION_TITLE_BORDER_BOTTOM : ""
                      }
                      minWidth={"100%"}
                      bg={CARD_BACKGROUND_COLOR}
                      icon={
                        !isComplete &&
                        day + 1 === currentDay &&
                        selectedPlannedItem.status ===
                          OptimismToolStatus.Pending
                          ? "https://cdn.headversity.com/app/resources/toolIcons/orange_exclamation.svg"
                          : "https://cdn.headversity.com/app/resources/toolIcons/orange_optimism.svg"
                      }
                      iconLeft={true}
                      borderRadius={Border_Radius}
                      showEllipsis={true}
                      isMobileOverride={isSmall}
                      childHeight={"unset"}
                      opacity={
                        selectedPlannedItem.status !==
                          OptimismToolStatus.Pending && !isComplete
                          ? "0.6"
                          : "1"
                      }
                      titleColor={PRIMARY_TEXT_COLOR}
                    >
                      <OptimismDateResult
                        hideDate={true}
                        changePage={(page) => {
                          setSelectedDay(day + 1);
                          changePage(page);
                        }}
                        selectedPlannedItem={selectedPlannedItem}
                        selectedDay={day + 1}
                      />
                    </SlidingCard>
                  );
                }
              )}
            </Stack>
          )}
        </>
      )}

      {!optimismPlans ||
        (optimismPlans?.length === 0 && (
          <Center>
            <Heading
              color={PRIMARY_TEXT_COLOR}
              textAlign={"center"}
              fontSize={"xl"}
            >
              {HVLocalizeStrings.OPTIMISM_TOOLS_NO_PLANS}
            </Heading>
          </Center>
        ))}
    </>
  );
};

interface CalendarDateProps {
  selectedPlannedItem: OptimismPlanDto;
  selectedDay: number;
  setSelectedDay: (day: number) => void;
  item: any;
}

const CalendarDate = (props: CalendarDateProps) => {
  const { selectedPlannedItem, item, selectedDay, setSelectedDay } = props;
  let canSubmit = false;
  let bgColor = "transparent";
  let bgColorOutline = "transparent";
  let textColor = "black";
  let haveOrangeBorder = false;
  let opacity = "0.3";
  if (
    selectedPlannedItem &&
    isBetweenRange(selectedPlannedItem, item.dayJsObj.startOf("day").unix())
  ) {
    bgColorOutline = CALENDAR_SELECTION_DAYS_BACKGROUND_COLOR;
    opacity = "1";
  }

  const dateJsObj = dayjs
    .utc(selectedPlannedItem.startTime)
    .local()
    .startOf("day");

  const currentDate = dayjs.utc(item.key).local().startOf("day");

  const dateDiff = currentDate.diff(dateJsObj, "day") + 1;

  if (
    isBetweenRange(selectedPlannedItem, item.dayJsObj.startOf("day").unix())
  ) {
    canSubmit = true;
  }

  if (
    selectedPlannedItem?.optimismPlanChallenges.some(
      (c) => c.dayNumber === dateDiff
    )
  ) {
    bgColor = BUTTON_PRIMARY_BACKGROUND_COLOR;
    textColor = BUTTON_PRIMARY_TEXT_COLOR;
  } else if (
    (selectedPlannedItem?.optimismPlanChallenges?.some(
      (c) => c.dayNumber > dateDiff
    ) &&
      isBetweenRange(
        selectedPlannedItem,
        item.dayJsObj.startOf("day").unix()
      )) ||
    (selectedPlannedItem?.status !== OptimismToolStatus.Pending &&
      isBetweenRange(selectedPlannedItem, item.dayJsObj.startOf("day").unix()))
  ) {
    bgColor = "#8c867d";
    textColor = BUTTON_PRIMARY_TEXT_COLOR;
  } else {
    haveOrangeBorder = shouldHaveOrangeBorder(
      selectedPlannedItem?.startTime,
      dateDiff
    );
  }
  return (
    <Stack
      h={"50px"}
      w={"100%"}
      align={"center"}
      justify={"center"}
      opacity={opacity}
      onClick={() => {
        if (canSubmit) {
          setSelectedDay(dateDiff);
        }
      }}
    >
      <Flex
        bg={bgColorOutline}
        p={"2"}
        h={"35px"}
        w={"100%"}
        align={"center"}
        justify={"center"}
        color={MODAL_PRIMARY_TEXT_COLOR}
      >
        <Flex
          borderRadius={"100%"}
          color={textColor}
          bg={bgColor}
          h={"35px"}
          w={"35px"}
          align={"center"}
          justify={"center"}
          border={haveOrangeBorder ? BLOCK_SECTION_TITLE_BORDER_BOTTOM : ""}
        >
          <Button className={"button-link"} textDecoration={"none"}>
            <Text fontWeight={selectedDay === dateDiff ? "bold" : "normal"}>
              {item.date}
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Stack>
  );
};

interface OptimismDateResultProps {
  changePage: (page: string) => void;
  hideDate?: boolean;
  selectedPlannedItem: OptimismPlanDto | null;
  selectedDay: number;
}

const OptimismDateResult = (props: OptimismDateResultProps) => {
  const isSmall = useBreakpointValue({
    md: false,
    lg: false,
    base: true,
  });
  const { changePage, hideDate, selectedPlannedItem, selectedDay } = props;
  const { currentPlannedItem } =
    useContext<IOptimismToolProvider>(OptimismToolContext);
  let content = <></>;
  let button = <></>;

  let currentChallenge = undefined;
  if (
    selectedPlannedItem?.optimismPlanChallenges &&
    selectedPlannedItem?.optimismPlanChallenges.length >= selectedDay
  ) {
    currentChallenge = selectedPlannedItem?.optimismPlanChallenges.find(
      (challenge) => challenge.dayNumber === selectedDay
    );
  }

  if (selectedPlannedItem?.status === OptimismToolStatus.Pending) {
    button = (
      <AnimatedButton
        w={260}
        text={HVLocalizeStrings.OPTIMISM_TOOLS_CHALLENGE_CHECKED_IN}
        onClick={() => {
          changePage(OPTIMISM_THREE_GOOD_THINGS_STEP);
        }}
      />
    );
  }

  if (
    selectedPlannedItem?.optimismPlanChallenges.find(
      (c) => c.dayNumber === selectedDay
    )?.userInput !== undefined
  ) {
    const userInput = selectedPlannedItem?.optimismPlanChallenges.find(
      (c) => c.dayNumber === selectedDay
    )?.userInput as OptimismChallengeInput;

    content = (
      <>
        <Text
          fontSize={"lg"}
          fontWeight={"semibold"}
          color={isSmall ? PRIMARY_TEXT_COLOR : MODAL_PRIMARY_TEXT_COLOR}
          data-testid={HVTestId.OptimismTool.steps.logs.firstTitle}
        >
          1. {userInput?.firstThing}
        </Text>

        <Text
          fontSize={"md"}
          mb={"1"}
          color={isSmall ? PRIMARY_TEXT_COLOR : MODAL_PRIMARY_TEXT_COLOR}
          data-testid={HVTestId.OptimismTool.steps.logs.firstContent}
        >
          {userInput?.firstThingDescription}
        </Text>
        <Text
          color={isSmall ? PRIMARY_TEXT_COLOR : MODAL_PRIMARY_TEXT_COLOR}
          fontSize={"lg"}
          fontWeight={"semibold"}
          data-testid={HVTestId.OptimismTool.steps.logs.secondTitle}
        >
          2. {userInput?.secondThing}
        </Text>
        <Text
          fontSize={"md"}
          mb={"1"}
          color={isSmall ? PRIMARY_TEXT_COLOR : MODAL_PRIMARY_TEXT_COLOR}
          data-testid={HVTestId.OptimismTool.steps.logs.secondContent}
        >
          {userInput?.secondThingDescription}
        </Text>
        <Text
          color={isSmall ? PRIMARY_TEXT_COLOR : MODAL_PRIMARY_TEXT_COLOR}
          fontSize={"lg"}
          fontWeight={"semibold"}
          data-testid={HVTestId.OptimismTool.steps.logs.thirdTitle}
        >
          3. {userInput?.thirdThing}
        </Text>
        <Text
          fontSize={"md"}
          mb={"1"}
          color={isSmall ? PRIMARY_TEXT_COLOR : MODAL_PRIMARY_TEXT_COLOR}
          data-testid={HVTestId.OptimismTool.steps.logs.thirdContent}
        >
          {userInput?.thirdThingDescription}
        </Text>
      </>
    );
  } else if (
    selectedPlannedItem?.optimismPlanChallenges.some(
      (item) => item.dayNumber > selectedDay && item.userInput === undefined
    ) ||
    selectedPlannedItem?.status !== OptimismToolStatus.Pending
  ) {
    content = (
      <>
        <Text
          fontSize={"md"}
          mb={"1"}
          color={isSmall ? PRIMARY_TEXT_COLOR : MODAL_PRIMARY_TEXT_COLOR}
        >
          {selectedPlannedItem?.status === OptimismToolStatus.Pending
            ? HVLocalizeStrings.OPTIMISM_TOOLS_CHALLENGE_MISSED
            : HVLocalizeStrings.OPTIMISM_TOOLS_CHALLENGE_MISSED_COMPLETELY}
        </Text>
        {button}
      </>
    );
  } else if (currentChallenge?.userInput === undefined) {
    const challengeDayObj = dayjs
      .utc(currentPlannedItem?.startTime)
      .local()
      .add(selectedDay - 1, "day");
    if (challengeDayObj.startOf("day").unix() <= dayjs().endOf("day").unix()) {
      content = (
        <>
          <Text
            fontSize={"md"}
            mb={"1"}
            color={isSmall ? PRIMARY_TEXT_COLOR : MODAL_PRIMARY_TEXT_COLOR}
          >
            {HVLocalizeStrings.OPTIMISM_TOOLS_CHALLENGE_READY}
          </Text>
          {button}
        </>
      );
    } else {
      content = (
        <>
          <Text fontSize={"md"} mb={"1"} color={MODAL_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.OPTIMISM_TOOLS_CHALLENGE_READY_TOMORROW}
          </Text>
        </>
      );
    }
  }

  return (
    <Stack w={isSmall ? "100%" : "50%"} p={"10"} spacing={"2"}>
      {!hideDate && (
        <>
          <Text
            color={MODAL_PRIMARY_TEXT_COLOR}
            fontSize={"xl"}
            fontWeight={"semibold"}
            lineHeight={"0.5rem"}
          >
            {HVLocalizeStrings.OPTIMISM_TOOLS_DAY} {selectedDay}
          </Text>
          <Text
            fontSize={"md"}
            color={MODAL_PRIMARY_TEXT_COLOR}
            data-testid={HVTestId.OptimismTool.steps.logs.selectedDayDate}
          >
            {dayjs
              .utc(selectedPlannedItem?.startTime)
              .local()
              .add(selectedDay - 1, "day")
              .format(HVLocalizeStrings.DATE_FORMAT)}
          </Text>
        </>
      )}
      {content}
    </Stack>
  );
};
