import { Box, Flex } from "@chakra-ui/react";
import { TreeViewOperationDescriptor } from "@progress/kendo-react-treeview";
import { useState } from "react";
import { TreeViewDataItem } from "../../../../State/Reach/ReachHierarchyContext";
import { DISABLED_BORDER } from "../../../../Styles/HeadversityStyle";
import { NodeType } from "../../../Shared/Hierarchy/NodeType";
import { KendoTree, TreeType } from "../KendoTree";
import {
  filterTree,
  preorderSearchArray,
} from "./HierarchyHelpers";
import { HierarchyToolbar } from "./HierarchyToolbar";

interface HierarchySelectorProps {
  tree: TreeViewDataItem[];
  checkedItems?: number[];
  setCheckedItems?: (itemIds: number[]) => void;
  expandedItems?: string[];
  displayAsModal?: boolean;
  maxNumSelections: number;
}
export const HierarchySelector = ({
  tree,
  checkedItems,
  setCheckedItems,
  expandedItems,
  displayAsModal,
  maxNumSelections,
}: HierarchySelectorProps) => {
  const [expand, setExpand] = useState<TreeViewOperationDescriptor>({
    ids: [],
    idField: "text",
  });
  const [selectedNodeType] = useState<NodeType>();
  const [selectedItems] = useState<string[]>([]);
  const [filteredTree, setFilteredTree] = useState<TreeViewDataItem[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");

  let treeSize = "large";

  const onHandleSearch = (searchStr: string) => {
    setSearchFilter(searchStr);
    if (searchStr) {
      const filtered = filterTree(tree, searchStr);
      setFilteredTree([...filtered]);
      // expand the entire tree to show the search results
      const allFilteredNodeIndexes = preorderSearchArray(filtered, "");
      setExpand({ ids: allFilteredNodeIndexes });
    } else {
      setFilteredTree([]);
    }
  };

  const onHandleClearSearch = () => {
    onHandleSearch('');
  };
  return (
    <>
      <HierarchyToolbar
        onSearch={onHandleSearch}
        selectedNodeType={selectedNodeType}
        displayAsModal={displayAsModal}
        showClearSearch={!!searchFilter}
        onClearSearch={onHandleClearSearch}
      ></HierarchyToolbar>
      <Flex>
        <Box
          mt={4}
          borderRadius={4}
          border={DISABLED_BORDER}
          bgColor="white"
          maxW={"100%"}
          w={"100%"}
        >
          <KendoTree
            mode={TreeType.Selector}
            disableDrag={true}
            maxNumSelections={maxNumSelections}
            initialTree={filteredTree.length > 0 ? filteredTree : tree}
            expandedFromSearch={expand}
            selectedItems={selectedItems}
            size={treeSize as any}
            checkedItems={checkedItems}
            onCheckedItemsChanged={setCheckedItems}
            expandedItems={expandedItems}
          ></KendoTree>
        </Box>
      </Flex>
    </>
  );
};
