import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { ContentBlock20 } from "../Shared/ContentBlock20";
import { HVTestId } from "../../../Testing/dataTestIds";
import { BlogPostDto, CompletionState } from "@headversity/contract";
import { needsSkillScore } from "../../../Utils/SkillsUtil";
import { EVENTS, track } from "../../../Utils/Analytics";
import { useNavigate } from "react-router-dom";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

interface ResilienceFeedItemBlock20Props {
  post: BlogPostDto;
  postCollection: BlogPostDto[];
  width?: string;
  height?: string;
  from?: string;
  isSmall?: boolean;
  showDate?: boolean;
  description?: string;
  hideSubTitle?: boolean;
  showTitleAreaBackground?: boolean;
  containImage?: boolean;
  isLiveSession?: boolean;
  showPoints?: boolean;
}

export const ResilienceFeedItemBlock20 = ({
  post,
  postCollection,
  width,
  height,
  from,
  isSmall,
  showDate,
  description,
  hideSubTitle,
  showTitleAreaBackground = true,
  containImage = true,
  isLiveSession,
  showPoints,
}: ResilienceFeedItemBlock20Props) => {
  const {
    userSkillStats,
    setGlobalBlogPost,
    setGlobalBlogPostCollection,
    setGlobalHeadScanQuestionModalOpen,
    setSelectedSkill,
    skills,
    allResilienceFeedPosts,
    clearGlobalActivities,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    setIsDone(
      allResilienceFeedPosts?.find(
        (p) => p.id === post.id && p.blogPostUserViews?.length > 0
      ) !== undefined
    );
  }, [post, allResilienceFeedPosts]);

  const skillToUse =
    post.skillCompetencies.length > 0
      ? skills.find((x) => x.id === post.skillCompetencies[0].skillId)
      : undefined;

  const navigate = useNavigate();

  return (
    <ContentBlock20
      imageUrl={post.imageUrl as string}
      containImage={containImage}
      title={post.name as string}
      subtitle={
        hideSubTitle || !skillToUse
          ? undefined
          : skillToUse.name.toString() +
            (showDate
              ? " - " +
                dayjs(post.postDate).format(HVLocalizeStrings.DATE_FORMAT)
              : "")
      }
      subtitleImageUrl={skillToUse?.imageUrl as string}
      dontInvertSubtitleImage={true}
      description={description}
      points={showPoints ? 1 : undefined}
      width={width}
      height={height}
      showTitleAreaBackground={showTitleAreaBackground}
      onClick={() => {
        const event = {
          HV_PostId: post?.id,
          HV_PostTitle: post?.name_loc?.en,
          HV_From: from,
        } as any;

        track(EVENTS.ResilienceFeedPostOpened, event);

        clearGlobalActivities();
        setGlobalBlogPost(post);
        setGlobalBlogPostCollection(postCollection);

        // if we're in SOLO and we haven't scored for this skill, open headscan and then load item
        if (needsSkillScore(userSkillStats, skillToUse?.id)) {
          setSelectedSkill(skillToUse);
          setGlobalHeadScanQuestionModalOpen(true);
        } else {
          navigate(`?post=${post.id}`, { replace: true });
        }
      }}
      isSmall={isSmall}
      titleBg={"#f6f6f6"}
      doneState={
        isDone && !isLiveSession
          ? CompletionState.done
          : CompletionState.notStarted
      }
      titleColor={PRIMARY_TEXT_COLOR}
      subtitleColor={PRIMARY_TEXT_COLOR}
      opaqueTitle={true}
      dataTestId={`${HVTestId.ResilienceFeedItemBlock20.post}_${post?.id}`}
    />
  );
};
