import {
  Box,
  Center,
  Flex,
  useBreakpointValue,
  Image,
  Text,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../State/GlobalContext";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import { Border_Radius, DISABLED_BORDER } from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";
import { LargeModal } from "./LargeModal";
import HelpResourceCard, { HelpResourceItem } from "./HelpResourceCard";
import {
  HelpResourceViewType,
  InviteCodeForReachDto,
} from "@headversity/contract";
import {
  HVLocalizeStrings,
  HVLocalizeStringsObj,
} from "../../Localization/HVLocalizeStrings";
import { isAndroid, isWebPlatform } from "../../Utils/mobileUtils";
import {
  FamilyInviteCodeProps,
  getHelpResourcesByLanguage,
  Language,
} from "../../Utils/HelpResourcesUtils";
import { getFamilyInviteCode } from "../../Api/People/UserApi";
import { getKey } from "../../Utils/Helpers";
import { AiOutlineCopy } from "react-icons/ai";
import { EVENTS, track } from "../../Utils/Analytics";

export const FamilyInviteCode = ({
  language,
  familyInviteCode,
}: FamilyInviteCodeProps) => {
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const { showToast } = useContext<IShellProvider>(ShellContext);

  return (
    <Center
      border={DISABLED_BORDER}
      borderRadius={Border_Radius}
      pb={2}
      w={"95%"}
      bg={"white"}
      boxShadow={"lg"}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        w={"100%"}
        p={4}
        gap={4}
        maxW={"400px"}
        data-testid={HVTestId.GetHelpModal.familyShare}
      >
        <Image
          src={
            "https://cdn.headversity.com/app/resources/other/blue-main-logo.png"
          }
          alt={HVLocalizeStrings.HEADVERSITY}
          marginY={4}
          height={"30px"}
        />
        <Text
          fontSize={isMobile ? "xs" : "small"}
          dangerouslySetInnerHTML={{
            __html:
              HVLocalizeStringsObj[language]
                .REACH_HELP_RESOURCE_INVITE_CODE_DESCRIPTION,
          }}
        ></Text>
        <Flex justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
          <HelpResourceItem
            icon={<AiOutlineCopy />}
            text={
              HVLocalizeStringsObj[language]
                .REACH_HELP_RESOURCE_INVITE_CODE_SHARE_LINK
            }
            onClick={async () => {
              track(EVENTS.GetHelpClickedChannel, {
                channel: "share-family-invite-code",
              });
              const urlToCopy = `${process.env.REACT_APP_DEEP_LINK_URL}/login?invite=${familyInviteCode.code}`;
              await navigator.clipboard.writeText(urlToCopy);
              showToast(
                true,
                "help-resources-family-invite-code-copied-to-clipboard",
                HVLocalizeStrings.COPIED_TO_CLIPBOARD
              );
            }}
          />
        </Flex>
      </Flex>
    </Center>
  );
};

export const GetHelpModal = () => {
  const {
    helpInfo,
    helpResourcesForUser,
    selectedUserLanguage,
    trackHelpResourceViewFromServer,
    trackHelpInfoViewFromServer,
    inviteCode,
  } = useContext(GlobalContext);

  const { isGetHelpOpen, setGetHelpOpen } = useContext(ShellContext);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    if (isGetHelpOpen) {
      trackUserViewedResources();
    }
  }, [isGetHelpOpen]);

  const setTitle = () => {
    if (helpResourcesForUser.length > 0)
      return HVLocalizeStrings.GET_HELP_LABEL;
    if (helpInfo?.buttonTitle) return helpInfo.buttonTitle as string;
    return helpInfo?.title as string;
  };

  const trackUserViewedResources = () => {
    if (helpResourcesForUser.length > 0) {
      helpResourcesForUser.forEach((helpResource) => {
        trackHelpResourceViewFromServer(helpResource.id);
      });
    } else if (helpInfo?.id) {
      trackHelpInfoViewFromServer(helpInfo.id);
    }
  };

  const localizedHelpResources = getHelpResourcesByLanguage(
    helpResourcesForUser,
    selectedUserLanguage
  );

  const setViewType = () => {
    if (isWebPlatform()) {
      return HelpResourceViewType.Desktop;
    }
    if (isAndroid()) {
      return HelpResourceViewType.Android;
    }
    return HelpResourceViewType.iOS;
  };

  const [sharedFamilyInviteCode, setSharedFamilyInviteCode] =
    useState<InviteCodeForReachDto>();

  useEffect(() => {
    if (inviteCode) {
      if (inviteCode.isFamily) {
        return;
      }
      getFamilyInviteCode(getKey()).then((response) => {
        if (response.data) {
          setSharedFamilyInviteCode(response.data);
        }
      });
    }
  }, [inviteCode]);

  return (
    <LargeModal
      open={isGetHelpOpen}
      setOpen={setGetHelpOpen as any}
      title={setTitle()}
    >
      <Flex
        className="get_help_content_container"
        mt={helpResourcesForUser.length > 0 ? 0 : !isDesktop ? 7 : 4}
        fontSize={["16px", "19px"]}
        py={"20px"}
        px={helpResourcesForUser.length > 0 ? 0 : isDesktop ? "150px" : "20px"}
        minH="600px"
        bgColor={helpResourcesForUser.length === 0 ? "white" : undefined}
        flexDir={"column"}
        borderRadius={
          helpResourcesForUser.length === 0 ? Border_Radius : undefined
        }
        boxShadow={helpResourcesForUser.length === 0 ? "lg" : undefined}
        mb={helpResourcesForUser.length === 0 ? 8 : 0}
        data-testid={HVTestId.GetHelpButton.helpModal}
      >
        {helpResourcesForUser.length > 0 ? (
          <Flex
            mt={0}
            flexDirection={"column"}
            gap={isDesktop ? 12 : 7}
            alignItems={"center"}
          >
            {localizedHelpResources.map((helpResource) => {
              return (
                <Center
                  key={helpResource.id}
                  border={DISABLED_BORDER}
                  borderRadius={Border_Radius}
                  py={2}
                  w={"95%"}
                  bg={"white"}
                  boxShadow={"lg"}
                >
                  <HelpResourceCard
                    helpResource={helpResource}
                    language={selectedUserLanguage}
                    viewType={setViewType()}
                    hideBorder={true}
                  />
                </Center>
              );
            })}
            {sharedFamilyInviteCode && (
              <FamilyInviteCode
                language={selectedUserLanguage as Language}
                familyInviteCode={sharedFamilyInviteCode}
              />
            )}
          </Flex>
        ) : (
          <Box
            w={"100%"}
            h={"100%"}
            dangerouslySetInnerHTML={{
              __html: helpInfo?.content as string,
            }}
          />
        )}
      </Flex>
    </LargeModal>
  );
};
