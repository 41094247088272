import { Box, Text } from "@chakra-ui/react";
import { isDesktop } from "react-device-detect";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { useContext } from "react";
import {
  IShellProvider,
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../../State/ShellContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { White } from "../../../Styles/HeadversityStyle";

interface CertInsightGlossaryProps {
  hideTitle?: boolean;
}

const CertInsightGlossary = (props: CertInsightGlossaryProps) => {
  const { isMainContainerXL, theme } = useContext<IShellProvider>(ShellContext);
  return (
    <Box
      maxW={isMainContainerXL ? "container.xl" : "container.lg"}
      mx={"auto"}
      mt={isDesktop ? "40px" : "20px"}
      mb={"20px"}
      color={theme.name === NOSTALGIC_THEME_NAME ? White : undefined}
    >
      {!props.hideTitle && (
        <BlockSectionTitle title={HVLocalizeStrings.GLOSSARY}></BlockSectionTitle>
      )}
      <Box mt={"20px"}>
        <Text lineHeight={"1.5"} mb={"10px"}>
          <b style={{ fontWeight: 600 }}>
            {HVLocalizeStrings.REACH_INSIGHTS_USERS_IN_PROGRESS}:
          </b>{" "}
          {
            HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_CERT_USERS_IN_PROGRESS_DESCRIPTION
          }
        </Text>
        <Text lineHeight={"1.5"} mb={"10px"}>
          <b style={{ fontWeight: 600 }}>
            {HVLocalizeStrings.REACH_INSIGHTS_CERTIFIED_USERS}:
          </b>{" "}
          {HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_CERTIFIED_USERS_DESCRIPTION}
        </Text>
        <Text lineHeight={"1.5"} mb={"10px"}>
          <b style={{ fontWeight: 600 }}>
            {HVLocalizeStrings.REACH_INSIGHTS_QUALIFIED_USERS}:
          </b>{" "}
          {HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_QUALIFIED_USERS_DESCRIPTION}
        </Text>
      </Box>
    </Box>
  );
};

export { CertInsightGlossary };
