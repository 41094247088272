import { Box, Center, List, ListIcon, ListItem, Text } from "@chakra-ui/react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { BOOST_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { BiCircle } from "react-icons/bi";

interface PasswordRequirementsProps {
  pwdHasNumber: boolean;
  pwdHasLowerCase: boolean;
  pwdHasUpperCase: boolean;
  pwdHasMinLength: boolean;
  pwdMatch?: boolean;
}

const PasswordRequirements = ({
  pwdHasNumber,
  pwdHasLowerCase,
  pwdHasUpperCase,
  pwdHasMinLength,
  pwdMatch,
}: PasswordRequirementsProps) => {
  return (
    <Box p={2}>
      <Text mt={2}>{HVLocalizeStrings.PASSWORD_REQUIREMENTS}</Text>
      <Center>
        <List pb={2} fontSize={"sm"} w={"80%"}>
          <PasswordRequirementItem
            text={HVLocalizeStrings.PASSWORD_LENGTH_REQUIREMENT}
            isValid={pwdHasMinLength}
          />
          <PasswordRequirementItem
            text={HVLocalizeStrings.PASSWORD_NUMBER_REQUIREMENT}
            isValid={pwdHasNumber}
          />
          <PasswordRequirementItem
            text={HVLocalizeStrings.PASSWORD_UPPERCASE_REQUIREMENT}
            isValid={pwdHasUpperCase}
          />
          <PasswordRequirementItem
            text={HVLocalizeStrings.PASSWORD_LOWERCASE_REQUIREMENT}
            isValid={pwdHasLowerCase}
          />
          {pwdMatch !== undefined && (
            <PasswordRequirementItem
              text={HVLocalizeStrings.PASSWORD_MUST_MATCH}
              isValid={pwdMatch}
            />
          )}
        </List>
      </Center>
    </Box>
  );
};

interface PasswordRequirementItemProps {
  text: string;
  isValid: boolean;
}

const PasswordRequirementItem = ({
  text,
  isValid,
}: PasswordRequirementItemProps) => {
  return (
    <ListItem display={"inline-list-item"}>
      <ListIcon
        as={isValid ? AiOutlineCheckCircle : BiCircle}
        boxSize={4}
        color={isValid ? BOOST_TEXT_COLOR : undefined}
      />
      <Text pt={"2px"}>{text}</Text>
    </ListItem>
  );
};

export default PasswordRequirements;
