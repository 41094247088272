import { ResponseState } from "@headversity/contract";
import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Text,
  useEditableControls,
} from "@chakra-ui/react";
import { Border_Radius } from "../../../../Styles/HeadversityStyle";
import { FiEdit, FiSave, FiTrash2 } from "react-icons/fi";
import { FaUndo } from "react-icons/fa";
import { useSportTeam } from "../../../../Hooks/Team/useSportTeam";
import { SPORT_EVENTS } from "../../../../Utils/Analytics";

export enum QuestionUserResponseEditorIconState {
  normal,
  alwaysShowRemove,
  editOnly,
}
interface QuestionUserResponseEditorProps {
  editedPollResults: {
    id: number;
    responseText: string;
    state: ResponseState;
  }[];
  setEditedPollResults?: (
    editedPollResults: {
      id: number;
      responseText: string;
      state: ResponseState;
    }[]
  ) => void;
  iconState?: QuestionUserResponseEditorIconState;
}
export const QuestionUserResponseEditor = (
  props: QuestionUserResponseEditorProps
) => {
  const { editedPollResults, setEditedPollResults, iconState } = props;

  return (
    <Flex
      w={"100%"}
      p={"5"}
      flexDir={"column"}
      align={"start"}
      justify={"start"}
      gap={"2"}
    >
      {editedPollResults.map((response) => (
        <QuestionUserResponseEditable
          key={response.id}
          response={response}
          editedPollResults={editedPollResults}
          setEditedPollResults={setEditedPollResults}
          iconState={iconState}
          onSubmit={(newValue) => {
            const questionResult = editedPollResults.find(
              (r) => r.id === response.id
            );
            if (
              questionResult &&
              newValue &&
              newValue.trim() !== "" &&
              setEditedPollResults
            ) {
              questionResult.responseText = newValue;
              setEditedPollResults([...editedPollResults]);
            }
          }}
        />
      ))}
    </Flex>
  );
};

interface QuestionUserResponseEditableProps {
  response: {
    id: number;
    responseText: string;
    state: ResponseState;
  };
  onSubmit: (newValue: string) => void;
  setEditedPollResults?: (
    editedPollResults: {
      id: number;
      responseText: string;
      state: ResponseState;
    }[]
  ) => void;
  editedPollResults: {
    id: number;
    responseText: string;
    state: ResponseState;
  }[];
  iconState?: QuestionUserResponseEditorIconState;
}
const QuestionUserResponseEditable = (
  props: QuestionUserResponseEditableProps
) => {
  const {
    response,
    onSubmit,
    setEditedPollResults,
    editedPollResults,
    iconState,
  } = props;

  const { trackEvent } = useSportTeam();
  return (
    <Flex
      key={response.id}
      flexDir={"column"}
      w={"100%"}
      gap={"2"}
      background={"white"}
      boxShadow={"lg"}
      borderRadius={Border_Radius}
      align={"center"}
      justify={"center"}
      minH={"60px"}
    >
      <Flex flexDir={"row"} w={"100%"} align={"center"} p={"2"}>
        {setEditedPollResults ? (
          <Editable
            defaultValue={response.responseText}
            onSubmit={onSubmit}
            w={"100%"}
            p={"2"}
            pr={"0"}
            isDisabled={response.state === ResponseState.inactive}
          >
            <Flex flexDir={"row"} h={"100%"} w={"100%"} align={"center"}>
              <EditablePreview
                opacity={
                  response.state === ResponseState.inactive ? "0.3" : "1"
                }
                as={Text}
                fontSize={"16px"}
                w={"100%"}
              />
              <EditableInput
                bg="white"
                fontSize={"16px"}
                p={2}
                w={"100%"}
                outline={"none"}
                boxShadow={"none"}
                border={0}
                _focusVisible={{ boxShadow: "none" }}
                opacity={
                  response.state === ResponseState.inactive ? "0.3" : "1"
                }
                onFocus={(_) => {
                  trackEvent(SPORT_EVENTS.CharterStatementEdited);
                }}
              />
              {response && (
                <QuestionUserResponseEditableControl
                  response={response}
                  iconState={iconState}
                  deleteAndPutBackClick={() => {
                    const questionResult = editedPollResults.find(
                      (r) => r.id === response.id
                    );
                    if (questionResult) {
                      questionResult.state =
                        questionResult.state === ResponseState.active
                          ? ResponseState.inactive
                          : ResponseState.active;

                      if (questionResult.state === ResponseState.inactive) {
                        trackEvent(SPORT_EVENTS.CharterStatementDeleted);
                      }
                      if (setEditedPollResults) {
                        setEditedPollResults([...editedPollResults]);
                      }
                    }
                  }}
                />
              )}
            </Flex>
          </Editable>
        ) : (
          <Text w={"90%"} p={"2"} fontSize={"16px"}>
            {response.responseText}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

interface QuestionUserResponseEditableControlProps {
  response: {
    id: number;
    responseText: string;
    state: ResponseState;
  };
  iconState?: QuestionUserResponseEditorIconState;
  deleteAndPutBackClick: () => void;
}
const QuestionUserResponseEditableControl = (
  props: QuestionUserResponseEditableControlProps
) => {
  const { isEditing, getSubmitButtonProps, getEditButtonProps } =
    useEditableControls();

  const { response, deleteAndPutBackClick, iconState } = props;
  return (
    <>
      {isEditing ? (
        <IconButton
          minWidth={"25px"}
          aria-label={"save"}
          icon={<FiSave />}
          key={"SAVE"}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
          {...getSubmitButtonProps()}
        />
      ) : (
        <IconButton
          minWidth={"25px"}
          aria-label={"edit"}
          icon={<FiEdit />}
          key={"EDIT"}
          bg={"transparent"}
          _hover={{
            bg: "transparent",
          }}
          {...(response.state === ResponseState.active && getEditButtonProps())}
          isDisabled={response.state === ResponseState.inactive}
        />
      )}
      {iconState !== QuestionUserResponseEditorIconState.editOnly && (
        <IconButton
          minWidth={"25px"}
          aria-label={"icon button"}
          icon={
            response.state === ResponseState.active ? <FiTrash2 /> : <FaUndo />
          }
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
          onClick={deleteAndPutBackClick}
        />
      )}
    </>
  );
};
