import React, { useContext, useEffect } from "react";
import { LargeModal } from "../../../Common/LargeModal";
import { FocusTool } from "./FocusTool";
import {
  FocusToolContext,
  IFocusToolProvider,
} from "../../../../State/Solo/FocusToolContext";
import { getKey } from "../../../../Utils/Helpers";

interface FocusToolModalProps {
  open: boolean;
  setOpen: (val: boolean) => {};
  step?: string;
  plan?: any;
  isEditMode: boolean;
  setIsEditMode: (val: boolean) => void;
}

export const FocusToolModal = (props: FocusToolModalProps): JSX.Element => {
  const { open, setOpen, step, plan, isEditMode, setIsEditMode } = props;
  const { focusPlanItems } = useContext<IFocusToolProvider>(FocusToolContext);

  return (
    <>
      {focusPlanItems && (
        <LargeModal open={open} setOpen={setOpen}>
          <FocusTool
            setOpen={setOpen}
            step={step}
            plan={plan}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
          />
        </LargeModal>
      )}
    </>
  );
};
