import {
  CompanyDto,
  CompanyQuickStartDto,
  UpdateCompanyDto,
} from "@headversity/contract";
import { SectorDto } from "@headversity/contract/Dto/SectorDto";
import { DateTime } from "luxon";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
  useContext,
} from "react";
import {
  getHelpResourcesCount,
  getQuickStartProgress,
  getSectors,
  getUsersCount,
  updateCompany,
} from "../../Api/Reach/ReachOrganizationsApi";
import { getUserCompany } from "../../Api/Solo/ProfileApi";
import { getKey } from "../../Utils/Helpers";
import { GlobalContext } from "../GlobalContext";

type ReachMyOrganizationState = {
  orgResourcesIsLoading: boolean;
  company?: CompanyDto;
  sectors?: SectorDto[];
  usersCount: number;
  goalWinThemesCount: number;
  quickStart?: CompanyQuickStartDto;
  isQuickStartGuideOpen: boolean;
  toggleQuickStartGuide: (isOpen: boolean) => void;

  updateCompany: (company: UpdateCompanyDto) => Promise<void>;

  // logo is uploading
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;

  triggerDataRefresh: () => void;
  dataRefreshTimestamp: string;

  fetchData: () => Promise<void>;
  helpResourcesCount: number;
};

export const ReachMyOrganizationContext =
  createContext<ReachMyOrganizationState>({
    orgResourcesIsLoading: false,
    updateCompany: async () => {},

    isQuickStartGuideOpen: false,
    toggleQuickStartGuide: (isOpen: boolean) => {},

    isUploading: false,
    setIsUploading: (isUploading: boolean) => {},

    triggerDataRefresh: () => {},
    dataRefreshTimestamp: "",
    fetchData: async () => {},
    usersCount: 0,
    goalWinThemesCount: 0,
    helpResourcesCount: 0,
  });

export const ReachMyOrganizationContextProvider: FC<{ children: ReactNode }> = (
  props
) => {
  const [usersCount, setUsersCount] = useState<number>(0);
  const [goalWinThemesCount, setGoalWinThemesCount] = useState<number>(0);
  const [company, setCompany] = useState<CompanyDto | undefined>();
  const [sectors, setSectors] = useState<SectorDto[] | undefined>([]);
  const [quickStartGuideIsOpen, setQuickStartGuideIsOpen] =
    useState<boolean>(false);
  const [quickStart, setQuickStart] = useState<
    CompanyQuickStartDto | undefined
  >();
  const [orgResourcesIsLoading, setOrgResourcesIsLoading] =
    useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [refreshDataTimestamp, setRefreshDataTimestamp] = useState<string>("");
  const [helpResourcesCount, setHelpResourcesCount] = useState<number>(0);
  const { company: contextCompany } = useContext(GlobalContext);

  const handleUpdateCompany = useCallback(async (company: UpdateCompanyDto) => {
    await updateCompany(getKey(), company);
  }, []);

  const handleFetchData = useCallback(async () => {
    setOrgResourcesIsLoading(true);
    const sectorsResponse = await getSectors(getKey());
    setSectors(sectorsResponse.data);
    const companyResponse = await getUserCompany(getKey());
    setCompany(companyResponse.data);
    const userCount = await getUsersCount(getKey());
    setUsersCount(userCount.data);
    const goalWinThemeCount = contextCompany?.goalWinThemes.length ?? 0;
    setGoalWinThemesCount(goalWinThemeCount);
    const quickStart = await getQuickStartProgress(getKey());
    setQuickStart(quickStart.data);
    const helpResourcesCount = await getHelpResourcesCount(getKey());
    setHelpResourcesCount(helpResourcesCount.data);
    setOrgResourcesIsLoading(false);
  }, []);

  const handleTriggerDataRefresh = useCallback(() => {
    setRefreshDataTimestamp(DateTime.now().toISO() as string);
  }, []);

  const handleSetIsUploading = useCallback((isUploading: boolean) => {
    setIsUploading(isUploading);
  }, []);

  const handleOpenQuickStartGuide = useCallback((isOpen: boolean) => {
    setQuickStartGuideIsOpen(isOpen);
  }, []);

  const state: ReachMyOrganizationState = useMemo(
    () => ({
      orgResourcesIsLoading,
      company,
      usersCount,
      goalWinThemesCount,
      quickStart,
      updateCompany: handleUpdateCompany,
      triggerDataRefresh: handleTriggerDataRefresh,
      dataRefreshTimestamp: refreshDataTimestamp,
      isUploading,
      setIsUploading: handleSetIsUploading,
      fetchData: handleFetchData,
      sectors,
      isQuickStartGuideOpen: quickStartGuideIsOpen,
      toggleQuickStartGuide: handleOpenQuickStartGuide,
      helpResourcesCount,
    }),
    [
      company,
      quickStart,
      orgResourcesIsLoading,
      sectors,
      refreshDataTimestamp,
      usersCount,
      isUploading,
      quickStartGuideIsOpen,
      helpResourcesCount,
    ]
  );

  return (
    <ReachMyOrganizationContext.Provider value={state}>
      {props.children}
    </ReachMyOrganizationContext.Provider>
  );
};
