import { createContext, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { GlobalContext, IGlobalProvider } from "../GlobalContext";
import { NanoPracticeType } from "@headversity/contract";

export interface HeadzoneMeterEvent {
  value: number;
  date: dayjs.Dayjs;
}

export interface IHeadZoneStatProvider {
  headzoneMeterEvents: { [key: string]: HeadzoneMeterEvent[] };
}

export const HeadZoneStatContext = createContext<IHeadZoneStatProvider>(
  {} as any
);

export const HeadZoneStatProvider = (props: any) => {
  const [headzoneMeterEvents, setHeadzoneMeterEvents] = useState<{
    [key: string]: HeadzoneMeterEvent[];
  }>({});

  const { nanoPractices, nanoPracticeInstances } =
    useContext<IGlobalProvider>(GlobalContext);

  useEffect(() => {
    if (!nanoPractices) return;

    if (!nanoPracticeInstances) return;

    // get zone practices
    const practices = nanoPractices.filter(
      (practice) => practice.nanoPracticeType === NanoPracticeType.Headzone
    );

    const events: {
      [key: string]: HeadzoneMeterEvent[];
    } = {};
    practices.forEach((practice) => {
      const typeKey = practice.id.toString();

      // Iterate through each nano practice instance and create an array of HeadzoneMeterEvent.
      // The events key is the HeadzonePrompType enum as a string. This is determined by the nano practice id.
      // We are using the nano practice id as the key for the instances. We only care about Headzone nano practices.
      if (practice.id in nanoPracticeInstances) {
        events[typeKey] = nanoPracticeInstances[practice.id]
          .filter((response) => response.questionUserResponses.length > 0)
          .map((response) => {
            return {
              value: parseInt(response.questionUserResponses[0].responseText),
              date: dayjs
                .utc(response.questionUserResponses[0].createdAt?.toString())
                .local(),
            } as HeadzoneMeterEvent;
          });
      }
    });
    setHeadzoneMeterEvents(events);
  }, [nanoPractices, nanoPracticeInstances]);

  return (
    <HeadZoneStatContext.Provider
      value={{
        headzoneMeterEvents,
      }}
    >
      {props.children}
    </HeadZoneStatContext.Provider>
  );
};
