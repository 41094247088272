import { Box, useBreakpointValue, useToken } from "@chakra-ui/react";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { Equity_Blue } from "../../../Styles/HeadversityStyle";
import { HvTextInput } from "../HvTextInput";

const TimePickerPopupDivStyler = styled("div")`
  .k-time-footer button {
    height: 45px !important;
    width: 85px !important;
  }
  .k-time-footer .k-time-accept {
    background: ${Equity_Blue} !important;
    border-color: transparent !important;
  }
  .k-time-footer .k-time-accept:hover {
    background: ${Equity_Blue}CC !important;
  }
`;

interface NewHvTimePickerProps {
  time: string;
  onChange: (time: any) => void;
}

export const HvTimePicker = (props: NewHvTimePickerProps) => {
  const { time, onChange } = props;
  const { selectedUserLanguage, selectedUserLocale } =
    useContext<IGlobalProvider>(GlobalContext);

  const [isTimeInitialized, setIsTimeInitialized] = useState<boolean>(false);

  const bgColor = useToken("colors", ["INPUT_BACKGROUND_COLOR"]);
  const color = useToken("colors", ["INPUT_PRIMARY_TEXT_COLOR"]);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const getHoursAndMinutes = (time: string) => {
    const parts = time.split(":");
    return {
      hours: parseInt(parts[0]),
      minutes: parseInt(parts[1]),
    };
  };

  const getDefaultDateFromTimeString = (time?: string) => {
    const timeValue = time ?? "08:00";
    return new Date(
      2000,
      2,
      10,
      getHoursAndMinutes(timeValue).hours,
      getHoursAndMinutes(timeValue).minutes
    );
  };

  const wrapper = useRef(null);
  const steps = {
    hour: 1,
    minute: 5,
  };
  const defaultValue = new Date(2000, 2, 10, 8, 0);

  const [dateValue, setDateValue] = useState<Date>(
    getDefaultDateFromTimeString()
  );

  useEffect(() => {
    if (time && time.indexOf(":") > 0 && time.indexOf("NaN") === -1) {
      setIsTimeInitialized(false);
      const newDate = getDefaultDateFromTimeString(time);
      if (newDate.toUTCString() !== dateValue.toUTCString()) {
        setDateValue(newDate);
      }
      setIsTimeInitialized(true);
    }
  }, [time]);

  const formatTime = (time: string) => {
    let [hours, minutes] = time.split(":");
    // Pad hours and minutes with a leading zero if necessary
    hours = hours.length === 1 ? "0" + hours : hours;
    minutes = minutes.length === 1 ? "0" + minutes : minutes;
    return hours + ":" + minutes;
  };

  return (
    <LocalizationProvider language={selectedUserLocale}>
      <IntlProvider locale={selectedUserLanguage}>
        {isMobile ? (
          isTimeInitialized && (
            <HvTextInput
              type="time"
              h={"80px"}
              w={"200px"}
              value={formatTime(time ?? "08:00")}
              onChange={(e: any) => {
                onChange(e.target.value);
              }}
            />
          )
        ) : (
          <Box
            /* since these values are dynamic, can't use styled */
            sx={{
              ".k-timepicker input": {
                height: "50px",
                background: `${bgColor}`,
                color: `${color}`,
              },
              ".k-timepicker button": {
                background: `${bgColor}`,
                color: `${color}`,
              },
            }}
          >
            <TimePicker
              popupSettings={{ appendTo: (wrapper as any).current }}
              width={"200px"}
              onChange={(e: any) => {
                onChange(`${e.value.getHours()}:${e.value.getMinutes()}`);
              }}
              format="hh:mm a"
              size={"large"}
              steps={steps}
              value={dateValue}
              defaultValue={defaultValue}
            />
          </Box>
        )}

        <TimePickerPopupDivStyler
          id={"timePickerPopup"}
          ref={wrapper}
        ></TimePickerPopupDivStyler>
      </IntlProvider>
    </LocalizationProvider>
  );
};
