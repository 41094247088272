import { Box, Button, VStack, Text, Avatar, Flex } from "@chakra-ui/react";
import { TeamUserDto } from "@headversity/contract";
import { LargeModal } from "../../Common/LargeModal";
import {
  Border_Radius,
  MODAL_BACKGROUND_COLOR,
} from "../../../Styles/HeadversityStyle";
import { HvSpinner } from "../../Common/HvSpinner";
import { useContext, useEffect } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import { IGlobalProvider, GlobalContext } from "../../../State/GlobalContext";
import { useSportTeam } from "../../../Hooks/Team/useSportTeam";
import { SPORT_EVENTS } from "../../../Utils/Analytics";

interface SportTeamMemberProfileProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  teamUser?: TeamUserDto;
}
export const SportTeamMemberProfile = (props: SportTeamMemberProfileProps) => {
  const { currentTeam, deleteTeamUserFromServer, resendMemberActivationEmail } =
    useContext<IGlobalProvider>(GlobalContext);
  const { confirmationDialogRef, showToast } =
    useContext<IShellProvider>(ShellContext);

  const { trackEvent } = useSportTeam();

  useEffect(() => {
    if (props.open) {
      trackEvent(SPORT_EVENTS.MemberDetailShown);
    }
  }, [props.open]);

  const handleResendActivationEmail = () => {
    if (!currentTeam) {
      return;
    }
    const member = props.teamUser;
    if (!member) {
      return;
    }
    (confirmationDialogRef.current as any).confirmation(
      HVLocalizeStrings.REACH_USERS_RESEND_ACTIVATION_EMAIL,
      HVLocalizeStrings.REACH_USERS_RESEND_ACTIVATION_EMAIL + "?",
      ConfirmationButtons.YesNo,
      () => {
        trackEvent(SPORT_EVENTS.MemberResendActivationClicked);
        resendMemberActivationEmail(currentTeam?.id, member.userId).then(() => {
          showToast(
            true,
            "team-user-resend-activation-success",
            HVLocalizeStrings.TEAM_RESEND_ACTIVATION_SUCCESS
          );
        });
      }
    );
  };
  const handleRemoveMember = () => {
    if (!currentTeam) {
      return;
    }
    const member = props.teamUser;
    if (!member) {
      return;
    }
    (confirmationDialogRef.current as any).confirmation(
      HVLocalizeStrings.TEAM_DELETE_USER,
      `${HVLocalizeStrings.TEAM_USER_CONFIRM_DELETE.replace(
        "{0}",
        member.user.fullName
      ).replace("{1}", currentTeam?.name)}`,
      ConfirmationButtons.YesNo,
      () => {
        trackEvent(SPORT_EVENTS.MemberRemoveFromTeamClicked);
        deleteTeamUserFromServer(currentTeam?.id, member.userId).then(() => {
          showToast(
            true,
            "team-user-delete-success",
            HVLocalizeStrings.TEAM_USER_DELETE_SUCCESS_TITLE
          );
          props.setOpen(false);
        });
      }
    );
  };

  return (
    <LargeModal
      open={props.open}
      setOpen={props.setOpen as any}
      bgColor={MODAL_BACKGROUND_COLOR}
      bgTransitionSeconds={0.25}
      preventClose={false}
    >
      {!props?.teamUser?.user ? (
        <HvSpinner></HvSpinner>
      ) : (
        <Box p={4}>
          {/* Profile Header */}
          <Box textAlign="center" mb={8}>
            <Avatar
              size="2xl"
              name={props.teamUser.user.fullName}
              bg="blue.800"
              color="white"
              mb={4}
              mx="auto"
            />
            <Text fontSize="2xl" fontWeight="bold">
              {props.teamUser.user.fullName}
            </Text>
          </Box>

          {/* Profile Details */}
          <Flex flexDir="column" justifyContent="center" alignItems="center">
            <Flex
              bg="white"
              py={"2rem"}
              px={"2rem"}
              display="grid"
              gridTemplateColumns="8rem 1fr"
              gridTemplateRows={"auto auto auto auto auto"}
              boxShadow={"lg"}
              borderRadius={Border_Radius}
            >
              <Text fontWeight="bold">Activated?</Text>
              <Text>{props.teamUser.user.firstAccessDate ? "Yes" : "No"}</Text>

              <Box gridColumn="1 / span 2" h="2rem" />

              <Text fontWeight="bold">Full Name</Text>
              <Text>{props.teamUser.user.fullName}</Text>

              <Text fontWeight="bold">Email</Text>
              <Text>{props.teamUser.user.email}</Text>
            </Flex>
          </Flex>

          {/* Action Buttons */}
          <VStack spacing={4} mt={16}>
            {!props.teamUser.user.firstAccessDate && (
              <Button
                variant="outline"
                colorScheme="blue"
                onClick={handleResendActivationEmail}
              >
                Resend Activation Email
              </Button>
            )}

            <Button
              variant="outline"
              colorScheme="red"
              onClick={handleRemoveMember}
            >
              Remove from Team
            </Button>
          </VStack>
        </Box>
      )}
    </LargeModal>
  );
};
