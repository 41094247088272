import React, { useContext } from "react";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { ToolList } from "./ToolList";
import { BlockSection } from "../../Common/BlockSection";
import { Helmet } from "react-helmet";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { HEADER_PRACTICE_BACKGROUND } from "../../../Styles/HeadversityStyle";
import { StickyBreadcrumb } from "../../Common/StickyBreadcrumb";

export const ToolsPage = () => {
  const { tools, skills, getPageTitle } =
    useContext<IGlobalProvider>(GlobalContext);

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.SOLO_MENU_PRACTICE} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>
      {tools && tools.length > 0 && skills && skills.length > 0 && (
        <>
          <HeaderSetter
            title={<>{HVLocalizeStrings.SOLO_MENU_PRACTICE}</>}
            description={HVLocalizeStrings.SOLO_PRACTICE_DESCRIPTION}
            backgroundImage={HEADER_PRACTICE_BACKGROUND}
          />

          <StickyBreadcrumb
            crumbs={[
              { url: "/", text: HVLocalizeStrings.SOLO_MENU_HOME },
              { url: "/explore", text: HVLocalizeStrings.EXPLORE },
            ]}
            current={HVLocalizeStrings.SOLO_MENU_PRACTICE}
          />

          <FadeAfterDelay>
            <BlockSection fadeIn={true} columns={1}>
              <ToolList tools={tools} skills={skills} from={"Tools"} />
            </BlockSection>
          </FadeAfterDelay>
        </>
      )}
    </>
  );
};
