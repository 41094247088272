import { createContext, useCallback } from "react";
import {
  saveLessonSlideProgress,
  saveQuestionAnswers,
} from "../../Api/Solo/LessonCourseApi";
import { getKey } from "../../Utils/Helpers";
import { saveLessonCompleted } from "../../Api/Solo/LessonApi";
import {
  LessonQuestionAnswerDto,
  LessonCourseSlideInputDto,
} from "@headversity/contract";
import { AxiosResponse } from "axios";

export const LessonCourseContext = createContext<ILessonCourseProvider>(
  {} as any
);

export interface ILessonCourseProvider {
  saveQuestionAnswersToServer: (
    microLessonId: number,
    microLessonQuestionAnswerDto: LessonQuestionAnswerDto
  ) => Promise<AxiosResponse<void>>;
  saveLessonSlideProgressToServer: (
    lessonCourseUserInstanceId: number,
    lessonCourseUserSlideProgressDto: LessonCourseSlideInputDto
  ) => Promise<AxiosResponse<void>>;
  saveLessonCompletedToServer: (
    microLessonUserInstanceId: number
  ) => Promise<AxiosResponse<void>>;
}

export const LessonCourseProvider = (props: any) => {
  const saveLessonSlideProgressToServer = useCallback(
    async (
      lessonCourseUserInstanceId: number,
      lessonCourseUserSlideProgressDto: LessonCourseSlideInputDto
    ) => {
      return saveLessonSlideProgress(
        getKey(),
        lessonCourseUserInstanceId,
        lessonCourseUserSlideProgressDto
      );
    },
    []
  );
  const saveQuestionAnswersToServer = useCallback(
    (
      microLessonId: number,
      microLessonQuestionAnswerDto: LessonQuestionAnswerDto
    ) => {
      return saveQuestionAnswers(
        getKey(),
        microLessonId,
        microLessonQuestionAnswerDto
      );
    },
    []
  );

  const saveLessonCompletedToServer = useCallback(
    async (microLessonUserInstanceId: number) => {
      return saveLessonCompleted(getKey(), microLessonUserInstanceId).then(
        (response) => {
          return Promise.resolve(response);
        }
      );
    },
    []
  );

  return (
    <LessonCourseContext.Provider
      value={{
        saveLessonSlideProgressToServer,
        saveQuestionAnswersToServer,
        saveLessonCompletedToServer,
      }}
    >
      {props.children}
    </LessonCourseContext.Provider>
  );
};
