import { Box, Progress, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  Border_Radius,
  BUTTON_DISABLE_BACKGROUND_COLOR,
  Glow_Shadow_Filter,
  Mid_Blue,
} from "../../Styles/HeadversityStyle";

interface ProgressBarStyle {
  mt: number;
  mb: number;
  h: string;
  w: string;
  marginRight: string;
  borderRadius: number;
  bgColor: string;
  "& > div": {
    background: string;
    transition: string;
  };
  borderTopLeftRadius?: string | number;
  borderBottomLeftRadius?: string | number;
  borderTopRightRadius?: string | number;
  borderBottomRightRadius?: string | number;
  border: string;
}

interface ProgressTrackerProps {
  value: number;
  totalStep: number;
  color?: string;
  fillProgressBar?: boolean;
  tooltipSingular?: string;
  tooltipPlural?: string;
  noZeroFill?: boolean;
  textToShow?: string[];
  partialFillStep?: boolean;
}

export const ProgressTrackerSteps = ({
  value,
  totalStep,
  color,
  fillProgressBar = false,
  tooltipSingular,
  tooltipPlural,
  noZeroFill,
  textToShow,
  partialFillStep,
}: ProgressTrackerProps) => {
  const [progressValues, setProgressValues] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);

  // progressBarDelay is a function of the number of steps that need to be filled in / total number of steps
  //  if we're filling in < 40%, go slow
  //  if we're filling in >= 60%, go fast
  const progressBarDelay = value / totalStep >= 0.4 ? 125 : 250;

  const getProgressBarValue = (): number => {
    // if it's the first bar and it's empty, or if it's the partial step, fill it in a bit to show the user that it's there
    if (
      (!noZeroFill && currentStep === 0 && value === 0) ||
      (partialFillStep && currentStep === value)
    ) {
      // this function is a linear approximation for filling in the progress bar hints
      return 10 + (totalStep / 20) * 40;
    } else {
      return fillProgressBar || currentStep < value ? 100 : 0;
    }
  };

  useEffect(() => {
    if (currentStep < totalStep) {
      const timeout = setTimeout(
        () => {
          const updatedValues = [...progressValues];
          updatedValues[currentStep] = getProgressBarValue();
          setProgressValues(updatedValues);
          setCurrentStep(currentStep + 1);
        },
        fillProgressBar || currentStep < value + 1 ? progressBarDelay : 0
      );
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [
    currentStep,
    fillProgressBar,
    progressValues,
    progressBarDelay,
    totalStep,
    value,
  ]);

  const renderProgressBars = (): JSX.Element => {
    const progressBarWidth = 100 / totalStep;
    const progressBarElements = [];
    for (let i = 0; i < totalStep; i++) {
      const progressBarStyle: ProgressBarStyle = {
        mt: 4,
        mb: 10,
        h: "10px",
        w: `${progressBarWidth}%`,
        marginRight: "2px",
        borderRadius: 0,
        bgColor: BUTTON_DISABLE_BACKGROUND_COLOR,
        "& > div": {
          background: color ? `${color}AA` : Mid_Blue,
          transition: `all ${progressBarDelay / 1000}s ease-in`,
        },
        border: "solid 1px #ffffff01",
      };

      // round edges for first and last progress bar
      if (i === 0) {
        progressBarStyle.borderTopLeftRadius = 50;
        progressBarStyle.borderBottomLeftRadius = 50;
      }
      if (i === totalStep - 1) {
        progressBarStyle.borderTopRightRadius = 50;
        progressBarStyle.borderBottomRightRadius = 50;
      }

      progressBarElements.push(
        <Progress
          key={i}
          value={progressValues[i] || (fillProgressBar && i < value ? 100 : 0)}
          sx={progressBarStyle}
        />
      );
    }
    return <>{progressBarElements}</>;
  };

  const pointsMore = totalStep - value;

  return (
    <Tooltip
      borderRadius={Border_Radius}
      filter={Glow_Shadow_Filter}
      isDisabled={!tooltipSingular}
      label={
        tooltipSingular && tooltipPlural ? (
          <Text
            p={2}
            dangerouslySetInnerHTML={{
              __html:
                pointsMore === 1
                  ? tooltipSingular
                  : tooltipPlural.replace("{0}", pointsMore.toString()),
            }}
          />
        ) : (
          <></>
        )
      }
      hasArrow={true}
      openDelay={250}
      placement="top"
    >
      <>
        {textToShow && textToShow.length > 0 && (
          <Box
            left="1px"
            top="-3px"
            position="relative"
            sx={{ display: "flex" }}
            width="100%"
          >
            {[...Array(totalStep)].map((_, i) => {
              return (
                <Text
                  key={i}
                  width={`${100 / totalStep}%`}
                  height="10px"
                  borderRadius={50}
                  color="#ffffffcc"
                  fontSize={window.innerWidth >= 568 ? "12px" : "11px"}
                  textAlign={"center"}
                  mb={1}
                >
                  {textToShow[i]}
                </Text>
              );
            })}
          </Box>
        )}
        <Box
          left="1px"
          top="-3px"
          position="relative"
          sx={{ display: "flex" }}
          width="100%"
        >
          {renderProgressBars()}
        </Box>
      </>
    </Tooltip>
  );
};
