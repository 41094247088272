import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { ResilienceSkill20Panel } from "../Shared/ResilienceSkill20Panel";
import { HVTestId } from "../../../Testing/dataTestIds";
import { Box, HStack, Text } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../../State/GlobalContext";
import { ResilienceFeedPanel } from "../Shared/ResilienceFeedPanel";
import {
  isFave,
  isNanoPracticeDoneTodayOrByStartDate,
} from "../NanoPractices/NanoPracticeUtils";
import {
  CompletionState,
  MicroLessonDto,
  NanoPracticeDto,
} from "@headversity/contract";
import { getLessonCompletionDateAndStatus } from "../../../Utils/LessonsUtil";
import {
  CHIP_BACKGROUND_COLOR_OFF,
  CHIP_BACKGROUND_COLOR_ON,
  CHIP_FOREGROUND_COLOR_OFF,
  CHIP_FOREGROUND_COLOR_ON,
} from "../../../Styles/HeadversityStyle";
import _ from "lodash";
import { EVENTS, track } from "../../../Utils/Analytics";

enum ActivityType {
  Faves,
  QuickRelief,
  Reflection,
  Random,
}

export const ResilienceSkill20Home = () => {
  const {
    selectedUserLanguage,
    nanoPractices,
    favorites,
    lessons,
    nanoPracticeInstances,
  } = useContext(GlobalContext);

  const [activityType, setActivityType] = useState<ActivityType | undefined>();
  const [activities, setActivities] = useState<
    NanoPracticeDto[] | MicroLessonDto[]
  >();

  const getTextFromActivityType = (type: ActivityType) => {
    switch (type) {
      case ActivityType.Faves:
        return HVLocalizeStrings.FAVORITES;

      case ActivityType.Reflection:
        return HVLocalizeStrings.FOR_REFLECTION;

      case ActivityType.QuickRelief:
        return HVLocalizeStrings.STRESS_RELIEF;

      case ActivityType.Random:
        return HVLocalizeStrings.SOMETHING_NEW;

      default:
        return "";
    }
  };

  const shuffleArray = (array: any[]) => {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  };

  const getRandomActivities = <T extends NanoPracticeDto | MicroLessonDto>(
    available: T[],
    count = 8
  ) => {
    const shuffled = shuffleArray(available);

    return shuffled.slice(0, count);
  };

  const getIncompleteLessons = () => {
    return lessons.filter((x) => {
      const { state } = getLessonCompletionDateAndStatus(x);
      return state !== CompletionState.done;
    });
  };

  const faves = nanoPractices.filter((practice) => isFave(favorites, practice));

  useEffect(() => {
    const type =
      faves.length > 0 ? ActivityType.Faves : ActivityType.QuickRelief;
    setActivityType(type);
  }, []);

  useEffect(() => {
    if (activityType === undefined) return;

    switch (activityType) {
      case ActivityType.Faves:
        setActivities(faves);
        break;

      case ActivityType.Reflection: {
        /*
        The good, repeatable ones

        3, // Discover Your Strengths
        4, // Learning From Success
        18, // Naming Your Pillars
        28, // Processing Change
        29, // Can I Control This?
        37, // Positive Experience Journal
        38, // Kindness Journal
        */

        const act = nanoPractices.filter((x) =>
          [3, 4, 18, 28, 29, 37, 38].includes(x.id)
        );

        // good for now to sort by id
        act.sort((a, b) => a.id - b.id);

        setActivities(act);
        break;
      }

      case ActivityType.QuickRelief: {
        /*
        QuickRelief practices minus the stretching ones and Embrace Your Space

        10, // 2-Minute Mindfulness
        9, // Three Sighs
        24, // Calming Anxiety
        30, // Letting go of your tension
        41, // 5 senses
        */

        const act: NanoPracticeDto[] = [];

        for (const id of [10, 9, 24, 30, 41]) {
          act.push(nanoPractices.find((x) => x.id === id)!);
        }

        setActivities(act);
        break;
      }

      default: {
        const notDonePractices = nanoPractices.filter(
          (x) =>
            !isNanoPracticeDoneTodayOrByStartDate(x.id, nanoPracticeInstances)
        );

        const x = getRandomActivities(getIncompleteLessons(), 4);
        const y = getRandomActivities(notDonePractices, 4);
        const z = _.flatten(_.zip(x, y)).filter((element) => element);

        setActivities(z);
        break;
      }
    }
  }, [activityType]);

  const chipsScroller = useRef(null);

  const chips = [
    ActivityType.QuickRelief,
    ActivityType.Reflection,
    ActivityType.Random,
  ];

  if (faves.length > 0) {
    chips.unshift(ActivityType.Faves);
  }
  return (
    <>
      <BlockSection
        fadeIn={true}
        includeSpacing={true}
        title={<BlockSectionTitle title={HVLocalizeStrings.MORE_ACTIVITIES} />}
        columns={1}
        rows={1}
        gap={"0"}
        gridClassName={"skills-container-20"}
        dataTestId={HVTestId.ResilienceSkill20Home.container}
      >
        <HStack
          mb="10px"
          overflowX="scroll"
          whiteSpace="nowrap"
          className="no-scrollbar"
          ref={chipsScroller}
        >
          {chips.map((item, index) => {
            const text = getTextFromActivityType(item);
            return (
              <Text
                as="button"
                display="inline-block"
                py={"5px"}
                px={"12px"}
                fontSize="13px"
                bgColor={
                  activityType === item
                    ? CHIP_BACKGROUND_COLOR_ON
                    : CHIP_BACKGROUND_COLOR_OFF
                }
                color={
                  activityType === item
                    ? CHIP_FOREGROUND_COLOR_ON
                    : CHIP_FOREGROUND_COLOR_OFF
                }
                _hover={{ opacity: 0.9 }}
                borderRadius={"12px"}
                key={item}
                onClick={() => {
                  track(EVENTS.OtherActvitiesChipChanged, {
                    HV_Chip: text,
                    HV_ChipItem: item,
                  });

                  setActivityType(item);

                  if (chipsScroller?.current) {
                    (chipsScroller?.current as any)?.scrollTo({
                      left: index >= chips.length - 2 ? 9999 : 0,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                {text}
              </Text>
            );
          })}
        </HStack>
        <ResilienceSkill20Panel activities={activities} />
      </BlockSection>

      <Box>
        {selectedUserLanguage === "en" && (
          <ResilienceFeedPanel
            from={"Home Live Sessions"}
            isLiveSessions={true}
          />
        )}

        <ResilienceFeedPanel from={"Home"} showPoints={true} />
      </Box>
    </>
  );
};
