export const validateEmail = (email: string): string | boolean => {
  // Trim leading and trailing spaces
  const trimmedEmail = email.trim();

  // Check for spaces or tabs anywhere within the email
  if (/\s/.test(trimmedEmail)) {
    return false;
  }

  const atIndex = trimmedEmail.indexOf("@");
  const domainDotIndex = trimmedEmail.indexOf(".", atIndex);
  const lastChar = trimmedEmail[trimmedEmail.length - 1];

  if (
    atIndex > -1 &&
    domainDotIndex > -1 &&
    !trimmedEmail.includes("@.") &&
    !trimmedEmail.includes(".@") &&
    lastChar !== "."
  ) {
    return trimmedEmail;
  }

  return false;
};
