import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FiList } from "react-icons/fi";
import { ShellContext } from "../../../State/ShellContext";
import { postMessageToIframe } from "../../../Utils/LessonsUtil";
import { ToggleButton } from "../Navigation/ToggleButton";
import { FRAME_ID, GOTO_SLIDE_INDEX } from "./Rise360Player";

export interface Rise360NavMenuItem {
  text: string;
  index: number;
}

interface Rise360NavMenuItems {
  items: Rise360NavMenuItem[];
}

export const Rise360NavMenu = ({ items }: Rise360NavMenuItems) => {
  const [isOpen, setIsOpen] = useState(false);

  const { safeAreaInsets } = useContext(ShellContext);

  return (
    <Box
      width="50px"
      pb="13px"
      pt={`${Math.max(13, safeAreaInsets.top - 3)}px`}
      px={{ base: "4", md: "6" }}
      bg="transparent"
      pos={"fixed"}
      zIndex={200}
      top={0}
      left={0}
    >
      <Flex justify="space-between">
        <ToggleButton
          isOpen={isOpen}
          aria-label="Open Menu"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={() => {
            setIsOpen(false);
          }}
          isFullHeight
          preserveScrollBarGap
          trapFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent
            pt={`${Math.max(10, safeAreaInsets.top - 2)}px`}
            bg="#eee"
          >
            {items.map((x, idx) => {
              return (
                <NavLink
                  key={idx}
                  text={x.text}
                  onClick={() => {
                    setIsOpen(false);

                    /*
                    // TODO: post message should really be at the Rise360Player layer (see comments in PresentationPresentView.tsx)
                    */
                    postMessageToIframe(FRAME_ID, GOTO_SLIDE_INDEX, {
                      slideIndex: x.index + 1,
                    });
                  }}
                />
              );
            })}
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
};

interface NavLinkProps {
  text: string;
  onClick: () => void;
}
const NavLink = ({ text, onClick }: NavLinkProps) => {
  return (
    <Link
      onClick={() => {
        onClick();
      }}
      p={4}
      fontSize="sm"
    >
      <Flex>
        <Icon pt={"4px"} as={FiList} boxSize={"4"} />
        <Text ml={2}>{text}</Text>
      </Flex>
    </Link>
  );
};
