import Pusher, { Channel } from "pusher-js";
import { useState } from "react";

export interface IPusherProvider {
  pusherUserChannel: Channel | null;
  setPusherUserChannel: (pusherUserChannel: Channel | null) => void;
  pusherClient: Pusher | undefined;
  setPusherClient: (pusherClient: Pusher | undefined) => void;
  pusherCompanyChannel: Channel | null;
  setPusherCompanyChannel: (pusherCompanyChannel: Channel | null) => void;
  pusherConnectionTime: number | undefined;
  setPusherConnectionTime: (pusherConnectionTime: number | undefined) => void;
}
export const usePusher = (): IPusherProvider => {
  const [pusherUserChannel, setPusherUserChannel] = useState<Channel | null>(
    null
  );
  const [pusherClient, setPusherClient] = useState<Pusher>();
  const [pusherCompanyChannel, setPusherCompanyChannel] =
    useState<Channel | null>(null);
  const [pusherConnectionTime, setPusherConnectionTime] = useState<
    number | undefined
  >(undefined);

  return {
    pusherUserChannel,
    setPusherUserChannel,
    pusherClient,
    setPusherClient,
    pusherCompanyChannel,
    setPusherCompanyChannel,
    pusherConnectionTime,
    setPusherConnectionTime,
  };
};
