import {
  Box,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Slide,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
} from "../../State/GlobalContext";
import { ShellContext } from "../../State/ShellContext";
import {
  Border_Radius,
  CONTRAST_TEXT_COLOR,
  Equity_Blue,
  FULL_SCREEN_MODAL_HEADER_BACKGROUND_COLOR,
  MODAL_BACKGROUND_COLOR,
  MODAL_CLOSE_BUTTON,
  MODAL_PRIMARY_TEXT_COLOR,
  OVERLAY_BACKGROUND_COLOR,
} from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";
import { isWebPlatform } from "../../Utils/mobileUtils";
import { CrossFadeImage } from "./CrossFadeImage";

interface LargeModalProps {
  children: any;
  open: boolean;
  title?: string;
  setOpen: (val: boolean) => {} | void;
  preventClose?: boolean;
  bgImage?: any;
  bgRepeat?: string;
  bgSize?: string;
  bgPos?: string;
  bgColor?: string;
  hideHeader?: boolean;
  size?: string | null;
  backgroundOverlay?: string;
  opacity?: string;
  onCloseTasks?: () => void;
  noMotionPreset?: boolean;
  dataTestId?: string;
  noFallback?: boolean;
  extraPadHeader?: boolean;
  bgTransitionSeconds?: number;
  reducePadTopBody?: boolean;
  forceLightMode?: boolean;
  forceDarkMode?: boolean;
  noBgSmoothing?: boolean;
  fullScreen?: boolean;
  leftHeader?: any;
}

export const LargeModal = (props: LargeModalProps) => {
  const {
    open,
    setOpen,
    title,
    preventClose,
    bgRepeat,
    bgSize,
    bgPos,
    children,
    bgColor,
    hideHeader,
    size,
    backgroundOverlay,
    opacity,
    onCloseTasks,
    noMotionPreset,
    bgImage,
    dataTestId,
    noFallback,
    extraPadHeader,
    bgTransitionSeconds,
    reducePadTopBody,
    forceLightMode,
    forceDarkMode,
    noBgSmoothing,
    fullScreen,
    leftHeader,
  } = props;
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { safeAreaInsets } = useContext(ShellContext);
  const { setIsUninterruptibleModalOpen, currentApp } =
    useContext<IGlobalProvider>(GlobalContext);

  const closeButtonRef = useRef(null);

  useEffect(() => {
    if (open) {
      setIsUninterruptibleModalOpen(true);
    }
  }, [open]);

  let maxW: any = "960px";
  switch (size) {
    case "full":
      maxW = "100%";
      break;
    case "sizeOnContent":
      maxW = undefined;
      break;
    default:
      maxW = "960px";
      break;
  }

  const img = useToken("colors", [
    currentApp === AppType.CERT
      ? "CERT_MODAL_BACKGROUND"
      : currentApp === AppType.TEAM
      ? "TEAM_MODAL_BACKGROUND"
      : currentApp === AppType.REACH
      ? "REACH_MODAL_BACKGROUND"
      : "MODAL_BACKGROUND",
  ])[0];

  const fallbackBgImage =
    currentApp === AppType.SPORT
      ? "https://cdn.headversity.com/app/sport/green-main-bg.jpg"
      : img;

  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    if (bgImage) {
      if (noBgSmoothing) {
        setImgSrc(bgImage);
      } else {
        const img = new Image();
        img.onload = () => {
          setImgSrc(bgImage);
        };
        img.src = bgImage;
      }
    } else {
      setImgSrc("");
    }
  }, [bgImage]);

  /* duped in FullScreenModal */
  useEffect(() => {
    if (open) {
      // avoid close button being focused element
      setTimeout(() => {
        if (closeButtonRef.current) {
          (closeButtonRef.current as HTMLElement).blur();
        }
      }, 250);
    }

    if (open && isWebPlatform() && window.screen.width < 568) {
      // properly handle iOS Safari hiding address bar
      // https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
      // https://lukechannings.com/blog/2021-06-09-does-safari-15-fix-the-vh-bug/
      const interval = setInterval(() => {
        const container = document.querySelector(
          ".chakra-modal__content-container"
        );
        if (container) {
          let height = window.innerHeight;
          (container as any).style.height = height + "px";
        }
      }, 500);

      return () => clearInterval(interval);
    }
  }, [open]);

  return (
    <div className={`${open ? "uninterruptible-modal" : ""}`}>
      <Modal
        isOpen={open}
        onClose={() => {
          setOpen(false);
          if (onCloseTasks) onCloseTasks();
        }}
        closeOnOverlayClick={false}
        closeOnEsc={!preventClose}
        motionPreset={noMotionPreset ? "none" : "scale"}
      >
        <ModalOverlay bg={backgroundOverlay ?? OVERLAY_BACKGROUND_COLOR} />
        <Box
          as={isDesktop || size === "full" ? Box : Slide}
          in={isDesktop || size === "full" ? undefined : true}
          direction="bottom"
          style={{ zIndex: 1400, height: "100%" }}
        >
          <ModalContent
            opacity={opacity ?? undefined}
            maxW={maxW}
            overflowX={size === "full" ? "hidden" : undefined}
            width={"100%"}
            minH={
              isDesktop && size !== "full"
                ? undefined
                : !isWebPlatform()
                ? "100vh"
                : "100%"
            }
            h={size !== "full" ? undefined : "100%"}
            mx={isDesktop && size !== "full" ? "25px" : "0px"}
            my={isDesktop && size !== "full" ? undefined : "0px"}
            bgColor={
              forceDarkMode ? Equity_Blue : bgColor ?? MODAL_BACKGROUND_COLOR
            }
            data-testid={dataTestId}
          >
            <CrossFadeImage
              containerPos={size === "full" ? "fixed" : undefined}
              transitionSeconds={bgTransitionSeconds}
              bgRepeat={bgRepeat}
              bgSize={bgSize}
              bgPos={bgPos ?? undefined}
              src={imgSrc || noFallback ? imgSrc : fallbackBgImage}
              baseImage={noBgSmoothing ? imgSrc : undefined}
              borderRadius={Border_Radius}
            />

            {!hideHeader && (
              <ModalHeader
                className="large-modal-header"
                zIndex={2}
                w="100%"
                p={"0"}
                position={fullScreen ? "fixed" : undefined}
              >
                <Box
                  bgColor={
                    fullScreen
                      ? FULL_SCREEN_MODAL_HEADER_BACKGROUND_COLOR
                      : undefined
                  }
                  px={6}
                  py={4}
                >
                  {title && (
                    <Heading
                      mt={
                        isWebPlatform()
                          ? fullScreen
                            ? 0
                            : isDesktop && extraPadHeader
                            ? 5
                            : 3
                          : `${safeAreaInsets.top}px`
                      }
                      mb={"0px"}
                      pl={isDesktop && extraPadHeader ? "20px" : "2px"}
                      pr={!preventClose ? "60px" : undefined}
                      fontSize={isDesktop ? "2xl" : "lg"}
                      fontWeight={"semibold"}
                      color={
                        fullScreen
                          ? CONTRAST_TEXT_COLOR
                          : forceLightMode
                          ? undefined
                          : MODAL_PRIMARY_TEXT_COLOR
                      }
                    >
                      <Flex>
                        {leftHeader}
                        <Text dangerouslySetInnerHTML={{ __html: title }} />
                      </Flex>
                    </Heading>
                  )}
                  {!preventClose && (
                    <ModalCloseButton
                      top={
                        isWebPlatform()
                          ? fullScreen
                            ? "12px"
                            : "20px"
                          : `${safeAreaInsets.top}px`
                      }
                      right={isDesktop ? "18px" : "12px"}
                      data-testid={HVTestId.LargeModal.closeButton}
                      ref={closeButtonRef}
                      mt={isWebPlatform() ? "4px" : fullScreen ? "8px" : "4px"}
                    >
                      <Icon
                        as={AiOutlineClose}
                        w={"20px"}
                        h={"20px"}
                        color={
                          fullScreen
                            ? CONTRAST_TEXT_COLOR
                            : forceDarkMode
                            ? "white"
                            : forceLightMode
                            ? undefined
                            : MODAL_CLOSE_BUTTON
                        }
                      />
                    </ModalCloseButton>
                  )}
                </Box>
              </ModalHeader>
            )}
            <ModalBody
              pt={!hideHeader && !title && !reducePadTopBody ? 8 : 0}
              pb={fullScreen ? 0 : isDesktop ? 6 : 4}
              px={fullScreen ? 0 : isDesktop ? 6 : 4}
              transition={"all 0.5s ease-out"}
              zIndex={1}
            >
              {children}
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>
    </div>
  );
};
