import { LargeModal } from "../../Common/LargeModal";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Flex,
  Box,
  Text,
  Divider,
  useBreakpointValue,
  Link,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useContext, useState } from "react";
import { getDaysSinceOrDate } from "../../../Utils/TimeUtils";
import {
  HeadzoneStepSetting,
  MicroLessonDto,
  NanoPracticeDto,
  NanoPracticeType,
  QuestionUserResponseDto,
} from "@headversity/contract";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { DateTime } from "luxon";
import dayjs from "dayjs";
import InsightsCard from "../../Common/Cards/InsightsCard";
import { NanoPracticeBlock } from "../../Solo/NanoPractices/NanoPracticeBlock";
import { LessonBlock20 } from "../../Solo/Lessons/LessonBlock20";
import {
  getMessagesFromAiResponse,
  getNanoPracticeQuestionsFromResponses,
} from "../../Solo/NanoPractices/NanoPracticeUtils";
import { getBrainZone } from "../../Solo/Tools/HeadZone/HeadZoneToolUtil";
import { getNonFeedbackResponses } from "../../../Utils/Helpers";
import { translateLocalizationText } from "../../../Utils/TranslateUtil";

interface ActivityHistoryItem {
  name: string;
  description: string;
  tagline: string;
  updatedAt: DateTime;
  questionUserResponses?: QuestionUserResponseDto[];
  lesson?: MicroLessonDto;
  practice?: NanoPracticeDto;
  practiceType?: NanoPracticeType;
  summary?: string;
  nextStep?: string;
  response?: string;
  responseDetails?: string;
}

interface GroupedActivities {
  date: string;
  activities: ActivityHistoryItem[];
}

interface ActivityHistoryModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ActivityHistoryModal = (props: ActivityHistoryModalProps) => {
  const isMd = useBreakpointValue({ base: false, md: true });

  const { lessons, nanoPracticesWithTrashed, nanoPracticeInstances } =
    useContext<IGlobalProvider>(GlobalContext);

  const { open, setOpen } = props;
  const [activities, setActivities] = useState<ActivityHistoryItem[]>([]);
  const [groupedActivities, setGroupedActivities] = useState<
    GroupedActivities[]
  >([]);

  useEffect(() => {
    const nps: ActivityHistoryItem[] = [];

    nanoPracticesWithTrashed.forEach((practice) => {
      const instances = nanoPracticeInstances[practice.id];
      if (instances) {
        instances.forEach((instance) => {
          // use AI summary if we have it
          let summary = "";
          let nextStep: string | undefined = "";

          if (instance.aiResponse) {
            const response = getMessagesFromAiResponse(
              instance.aiResponse,
              "aiResponse",
              true
            );
            if (response) {
              summary = response;
            }

            const response2 = getMessagesFromAiResponse(
              instance.aiResponse,
              "aiResponseHistory",
              true
            );

            if (response2) {
              summary += " " + response2;
            }
            
            nextStep =
              getMessagesFromAiResponse(
                instance.aiResponse,
                "aiNextStep",
                true
              ) ?? undefined;
          }

          // get the bucket associated with the response value
          let response = "";
          let responseDetails = "";
          if (
            practice.nanoPracticeType === NanoPracticeType.Headzone &&
            instance.questionUserResponses.length > 0
          ) {
            const val = parseInt(
              instance.questionUserResponses[0].responseText
            );

            // get zone from the practice
            const hz = practice.steps[0] as HeadzoneStepSetting;
            const zone = hz.headzone.ranges[getBrainZone(val)];

            response = zone.title.toString().replace("<br />", " ");

            // filter out feedback responses
            const responses = getNonFeedbackResponses(
              instance.questionUserResponses
            );

            // first response is the headzone value, second (if any) if the reason
            if (responses.length > 1) {
              responseDetails = responses[1].responseText;
            }

            if (!summary) {
              summary = zone.description.toString();
            }
          }

          nps.push({
            practice,
            name: practice.name.toString(),
            description: practice.description.toString(),
            tagline: practice.tagline.toString(),
            response,
            responseDetails,
            summary: summary,
            nextStep: nextStep,
            practiceType: practice.nanoPracticeType,
            updatedAt: instance.createdAt,
            questionUserResponses: instance.questionUserResponses,
          });
        });
      }
    });

    const ls = lessons.flatMap((lesson) =>
      lesson.microLessonUserInstances.map(
        (instance) =>
          ({
            lesson: lesson,
            name: lesson.name.toString(),
            description: lesson.description.toString(),
            updatedAt: instance.updatedAt,
          } as ActivityHistoryItem)
      )
    );

    const activities = [...ls, ...nps];

    activities.sort((a, b) => {
      return dayjs(b.updatedAt.toString()).diff(dayjs(a.updatedAt.toString()));
    });

    setActivities(activities as ActivityHistoryItem[]);

    const grouped = new Map<string, ActivityHistoryItem[]>();
    activities.forEach((activity) => {
      const date = dayjs(activity.updatedAt.toString()).format("YYYY-MM-DD");
      if (!grouped.has(date)) {
        grouped.set(date, []);
      }

      grouped.get(date)?.push(activity);
    });

    const groupedActivities: GroupedActivities[] = Array.from(
      grouped.entries()
    ).map(([date, activities]) => ({ date, activities }));

    setGroupedActivities(groupedActivities);
  }, [lessons, nanoPracticesWithTrashed, nanoPracticeInstances]);

  return (
    <LargeModal
      open={open}
      setOpen={setOpen as any}
      title={HVLocalizeStrings.PROFILE_PAGE_HISTORY}
      reducePadTopBody={true}
    >
      <Box mt={4} mb="50px">
        {activities.length === 0 && (
          <Text>{HVLocalizeStrings.NANO_PRACTICE_NO_RESULTS}</Text>
        )}
        {groupedActivities.map((groupActivity, index) => {
          return (
            <Box mb="4" key={index}>
              <InsightsCard
                title={getDaysSinceOrDate(dayjs(groupActivity.date))}
                hasTooltip={false}
                content={
                  <Box my={4} px={5} lineHeight="1.3" w="100%">
                    {groupActivity.activities.map((activity, index) => {
                      return (
                        <Box key={index}>
                          <Flex
                            alignItems={"flex-start"}
                            justifyContent={"left"}
                            gap={5}
                            direction={{ base: "column", md: "row" }}
                            w={"100%"}
                          >
                            <Box w={isMd ? "225px" : "100%"}>
                              {activity.practice && (
                                <NanoPracticeBlock
                                  width={isMd ? "225px" : "100%"}
                                  height={isMd ? "150px" : "175px"}
                                  practice={activity.practice}
                                  isSmall={true}
                                  noHover={true}
                                  hideDone={true}
                                  from={"History"}
                                />
                              )}
                              {activity.lesson && (
                                <LessonBlock20
                                  width={isMd ? "225px" : "100%"}
                                  height={isMd ? "150px" : "175px"}
                                  lesson={activity.lesson}
                                  isSmall={true}
                                  noHover={true}
                                  hideDone={true}
                                  from={"History"}
                                />
                              )}
                            </Box>
                            <Box fontSize="15px" flex="1">
                              {activity.response ? (
                                <>
                                  <Text mb={2}>
                                    {HVLocalizeStrings.YOU_FELT}{" "}
                                    <b
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {activity.response.toLowerCase()}
                                    </b>
                                  </Text>
                                  {activity.responseDetails && (
                                    <Text mb={2}>
                                      {HVLocalizeStrings.BECAUSE_OF}{" "}
                                      <b>{activity.responseDetails}</b>
                                    </Text>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Text fontWeight={"700"}>
                                    {activity.name}
                                  </Text>
                                  <Text fontSize="14px" fontStyle={"italic"}>
                                    {activity.tagline}
                                  </Text>
                                </>
                              )}
                              <Text mt={4} fontSize="14px">
                                {activity.summary
                                  ? activity.summary
                                  : activity.description}
                              </Text>
                              {activity.nextStep && (
                                <Text mt={4} fontSize="14px">
                                  {activity.nextStep}
                                </Text>
                              )}
                              {activity.questionUserResponses &&
                                activity.practiceType ===
                                  NanoPracticeType.ShowAndTell && (
                                  <ShowAndTellResponses activity={activity} />
                                )}
                            </Box>
                          </Flex>
                          {groupActivity.activities.length - 1 !== index && (
                            <Divider my={6} />
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                }
              />
            </Box>
          );
        })}
      </Box>
    </LargeModal>
  );
};

interface ShowAndTellResponsesProps {
  activity: ActivityHistoryItem;
}
const ShowAndTellResponses = ({ activity }: ShowAndTellResponsesProps) => {
  const { nanoPracticeInstances, selectedUserLanguage } =
    useContext<IGlobalProvider>(GlobalContext);
  const { isOpen, onToggle } = useDisclosure();

  // filter out feedback responses
  const responses = getNonFeedbackResponses(activity.questionUserResponses);

  if (!responses || responses.length === 0) return <></>;

  return (
    <Box mt={4} fontSize={"14px"}>
      <Collapse in={isOpen}>
        {responses.map((r, idx) => {
          let title = r.question.questionText.toString();
          const response = r.responseText;
          const instance = activity.practice
            ? nanoPracticeInstances[activity.practice.id].find(
                (instance: { id: any }) => instance.id === r.instanceId
              )
            : undefined;
          if (instance && !instance?.aiResponse) {
            const nanoPracticeQuestions = getNanoPracticeQuestionsFromResponses(
              instance.questionUserResponses
            );
            const tellItBack = translateLocalizationText(
              nanoPracticeQuestions?.find(
                (npq) => npq.questionId === r.question.id
              )?.tellItBack_loc,
              selectedUserLanguage
            );
            title = tellItBack ?? title;
          }

          return (
            <Box key={idx}>
              <Text fontWeight={"600"}>{title}</Text>
              <Text mb={2}>{response}</Text>
            </Box>
          );
        })}
      </Collapse>
      <Link textDecoration={"underline"} onClick={onToggle} mt={2}>
        {isOpen
          ? HVLocalizeStrings.HIDE_DETAILS
          : HVLocalizeStrings.SEE_DETAILS}
      </Link>
    </Box>
  );
};
