import {
  Box,
  Center,
  Flex,
  Text,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import HelpResourceCard, {
  HelpResourceItem,
} from "../../Common/HelpResourceCard";
import {
  Border_Radius,
  DISABLED_BORDER,
  Equity_Black,
} from "../../../Styles/HeadversityStyle";
import {
  HVLocalizeStrings,
  HVLocalizeStringsObj,
} from "../../../Localization/HVLocalizeStrings";
import {
  HelpResourceDto,
  HelpResourceViewType,
  InviteCodeForReachDto,
} from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import { ReachHelpResourcesContext } from "../../../State/Reach/ReachHelpResourcesContext";
import { HvSelect } from "../../Common/HvSelect";
import { languages } from "../../../Hooks/Solo/useProfile";
import {
  FamilyInviteCodeProps,
  getSharedFamilyInviteCode,
  getHelpResourcesByLanguage,
  Language,
} from "../../../Utils/HelpResourcesUtils";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { AiOutlineCopy } from "react-icons/ai";
import { EVENTS, track } from "../../../Utils/Analytics";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";

export interface LocalizedHelpResource {
  id: number;
  logoUrl: string;
  name: string;
  phoneNumber: string;
  textNumber: string;
  iosAppUrl: string;
  androidAppUrl: string;
  websiteUrl: string;
  description: string;
}

const ReachHelpResourcesPreview = () => {
  const orgContext = useContext(ReachHelpResourcesContext);

  return (
    <Center>
      <Box
        bgColor={"white"}
        w={"100%"}
        border={DISABLED_BORDER}
        borderRadius={Border_Radius}
        mb={6}
      >
        <Box py={4} px={6} borderBottom={DISABLED_BORDER}>
          <Text fontSize={"lg"} fontWeight={"600"}>
            {HVLocalizeStrings.TEAM_BUTTON_PREVIEW}
          </Text>
        </Box>
        <HelpResourcePanel
          companyInviteCodes={orgContext.companyInviteCodes}
          helpResources={orgContext.helpResources}
        />
      </Box>
    </Center>
  );
};

interface HelpResourcePanelProps {
  companyInviteCodes: InviteCodeForReachDto[];
  helpResources: HelpResourceDto[];
}

const HelpResourcePanel = ({
  companyInviteCodes,
  helpResources,
}: HelpResourcePanelProps) => {
  const [selectedView, setSelectedView] = useState<HelpResourceViewType>(
    HelpResourceViewType.Desktop
  );
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    languages[0]
  );
  const [sharedFamilyInviteCode, setSharedFamilyInviteCode] =
    useState<InviteCodeForReachDto>();

  const viewOptions = [
    {
      label: HVLocalizeStrings.REACH_HELP_RESOURCES_DESKTOP,
      value: HelpResourceViewType.Desktop,
    },
    { label: "iOS", value: HelpResourceViewType.iOS },
    { label: "Android", value: HelpResourceViewType.Android },
  ];

  // Filter out any help resources that don't have a name in the selected language
  const localizedHelpResources = getHelpResourcesByLanguage(
    helpResources,
    selectedLanguage
  );

  const { inviteCode } = useContext<IGlobalProvider>(GlobalContext);
  const orgContext = useContext(ReachHelpResourcesContext);

  useEffect(() => {
    if (inviteCode) {
      setSharedFamilyInviteCode(
        getSharedFamilyInviteCode(inviteCode, companyInviteCodes)
      );
    }
  }, [inviteCode, companyInviteCodes]);

  return (
    <Flex flexDirection={"column"} gap={4} w={"100%"} p={4}>
      <>
        <Text fontSize={"xs"} py={2}>
          {HVLocalizeStrings.REACH_HELP_RESOURCES_PREVIEW_INSTRUCTIONS}
        </Text>
        <HvSelect
          placeholder={HVLocalizeStrings.SELECT}
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.target.value)}
          color={Equity_Black}
          bg={"white"}
          height={"40px"}
        >
          <option value={languages[0]}>
            {HVLocalizeStrings.LANGUAGE_ENGLISH}
          </option>
          <option value={languages[1]}>
            {HVLocalizeStrings.LANGUAGE_FRENCH}
          </option>
          <option value={languages[2]}>
            {HVLocalizeStrings.LANGUAGE_SPANISH}
          </option>
        </HvSelect>
        <HvSelect
          placeholder={HVLocalizeStrings.SELECT}
          value={selectedView}
          onChange={(e) =>
            setSelectedView(parseInt(e.target.value) as HelpResourceViewType)
          }
          color={Equity_Black}
          bg={"white"}
          height={"40px"}
        >
          {viewOptions.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </HvSelect>
      </>
      {localizedHelpResources.length > 0
        ? localizedHelpResources.map((item) => (
            <HelpResourceCard
              key={item.id}
              helpResource={item}
              language={selectedLanguage}
              viewType={selectedView}
              isPreview={true}
            />
          ))
        : sharedFamilyInviteCode && (
            <Text>{HVLocalizeStrings.REACH_HELP_RESOURCE_NO_RESOURCES}</Text>
          )}
      {sharedFamilyInviteCode &&
        orgContext.helpResources.length > 0 &&
        companyInviteCodes.filter(
          (code) =>
            code.id === orgContext.selectedInviteCodeId && !code.isFamily
        ).length > 0 && (
          <FamilyInviteCode
            language={selectedLanguage as Language}
            familyInviteCode={sharedFamilyInviteCode}
          />
        )}
    </Flex>
  );
};

const FamilyInviteCode = ({
  language,
  familyInviteCode,
}: FamilyInviteCodeProps) => {
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const { showToast } = useContext<IShellProvider>(ShellContext);

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      w={"100%"}
      boxShadow={"lg"}
      p={4}
      border={DISABLED_BORDER}
      borderRadius={Border_Radius}
      gap={4}
      maxW={"400px"}
    >
      <Image
        src={
          "https://cdn.headversity.com/app/resources/other/blue-main-logo.png"
        }
        alt={HVLocalizeStrings.HEADVERSITY}
        marginY={4}
        height={"30px"}
      />
      <Text
        fontSize={isMobile ? "xs" : "small"}
        dangerouslySetInnerHTML={{
          __html:
            HVLocalizeStringsObj[language]
              .REACH_HELP_RESOURCE_INVITE_CODE_DESCRIPTION,
        }}
      ></Text>
      <HelpResourceItem
        icon={<AiOutlineCopy />}
        text={
          HVLocalizeStringsObj[language]
            .REACH_HELP_RESOURCE_INVITE_CODE_SHARE_LINK
        }
        onClick={async () => {
          track(EVENTS.GetHelpClickedChannel, {
            channel: "share-family-invite-code",
          });
          const urlToCopy = `${process.env.REACT_APP_DEEP_LINK_URL}/login?invite=${familyInviteCode.code}`;
          await navigator.clipboard.writeText(urlToCopy);
          showToast(
            true,
            "help-resources-family-invite-code-copied-to-clipboard",
            HVLocalizeStrings.COPIED_TO_CLIPBOARD
          );
        }}
      />
    </Flex>
  );
};

export default ReachHelpResourcesPreview;
