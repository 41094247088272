import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Flex, Spinner } from "@chakra-ui/react";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { BlockSection } from "../../Common/BlockSection";
import {
  BG_TEAM_BASE,
  HEADER_TEAM_PATHS_BACKGROUND,
} from "../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { PathAccordion } from "./PathAccordion";
import { IGlobalProvider, GlobalContext } from "../../../State/GlobalContext";
import TeamCommandCard from "../TeamCommandCard";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPathTeamLessonByTeamId } from "../../../Utils/TeamUtil";
import { PathDto } from "@headversity/contract";
import { ErrorPage } from "../../Shared/Error/ErrorPage";
import { navigateWithSearchParams } from "../../../Utils/Helpers";

export const PathPage = () => {
  const [searchParams] = useSearchParams();
  const {
    paths,
    currentTeam,
    teamInitializing,
    setPresenterPathTeamLesson,
    setPresenterModalOpen,
    getPageTitle,
  } = useContext<IGlobalProvider>(GlobalContext);

  const navigate = useNavigate();

  const [currentPaths, setCurrentPaths] = useState<PathDto[]>([]);
  const [pathTeamLessonNotFound, setPathTeamLessonNotFound] =
    useState<boolean>(false);

  useEffect(() => {
    if (paths) {
      if (paths["0"] && currentTeam === null) {
        setCurrentPaths(paths["0"]);
      } else if (currentTeam?.id != null) {
        setCurrentPaths(paths[currentTeam.id]);
      }
    }
  }, [paths, currentTeam]);

  useEffect(() => {
    //If the URL has a pathId and teamLessonId, then show the teamLesson intro (or a 404 page if they do not exist)
    if (currentPaths?.length > 0) {
      const pathId = searchParams.get("pathId");
      const teamLessonId = searchParams.get("teamLessonId");
      let pathOrTeamLessonNotFound = false;
      if (pathId) {
        if (!currentPaths?.some((path) => path.id.toString() == pathId)) {
          //Path specified in URL could not be found
          pathOrTeamLessonNotFound = true;
        } else if (teamLessonId && currentTeam) {
          //Get the pathTeamLesson based on the teamLessonId, and if it exists, show the teamLesson intro
          const pathTeamLesson = getPathTeamLessonByTeamId(
            paths,
            currentTeam,
            undefined,
            parseInt(pathId),
            parseInt(teamLessonId)
          );
          if (pathTeamLesson) {
            setPresenterPathTeamLesson(pathTeamLesson);
            setPresenterModalOpen(true);
          } else {
            // user not on a team? go to the general paths page
            if (!currentTeam) {
              navigateWithSearchParams(
                navigate,
                `/team/paths`,
                { pathId: pathId.toString() },
                true,
                true
              );
            } else {
              pathOrTeamLessonNotFound = true;
            }
          }
        }
      } else if (teamLessonId) {
        //It is invalid to have no path but a teamLesson
        pathOrTeamLessonNotFound = true;
      }
      if (pathOrTeamLessonNotFound) {
        setPathTeamLessonNotFound(true);
      }
    }
  }, [currentPaths, searchParams]);

  return (
    <>
      {!pathTeamLessonNotFound ? (
        <>
          <Helmet>
            <title>
              {getPageTitle(
                `${HVLocalizeStrings.TEAM_PATH_TITLE} - ${HVLocalizeStrings.TEAM_HOME_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`
              )}
            </title>
          </Helmet>

          <HeaderSetter
            title={<>{HVLocalizeStrings.TEAM_PATH_TITLE}</>}
            description={HVLocalizeStrings.TEAM_PATH_DESCRIPTION}
            backgroundImage={HEADER_TEAM_PATHS_BACKGROUND}
            backgroundImageBase={BG_TEAM_BASE}
          />
          {teamInitializing ? (
            <Flex justify={"center"}>
              <Spinner />
            </Flex>
          ) : (
            <FadeAfterDelay noEase={true}>
              <BlockSection fadeIn={true} title={""} columns={1}>
                <Flex flexDir={"column"} gap={8} minH={"500px"}>
                  <TeamCommandCard />
                  {currentPaths?.map((pathItem) => {
                    return (
                      <PathAccordion
                        key={pathItem.id}
                        path={pathItem}
                      ></PathAccordion>
                    );
                  })}
                </Flex>
              </BlockSection>
            </FadeAfterDelay>
          )}
        </>
      ) : (
        <ErrorPage />
      )}
    </>
  );
};
