import { Box, Center, Heading, Stack, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { OptimismToolInputItem } from "./OptimismToolInputItem";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { OPTIMISM_OUTRO_STEP } from "./OptimismTool";
import {
  MODAL_PRIMARY_TEXT_COLOR,
  MODAL_THIRD_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface OptimismToolStepMadePossibleProps {
  firstGoodThing: string;
  secondGoodThing: string;
  thirdGoodThing: string;
  firstGoodThingCont: string | null;
  secondGoodThingCont: string | null;
  thirdGoodThingCont: string | null;
  setFirstGoodThingCont: (firstGoodThing: string) => void;
  setSecondGoodThingCont: (secondGoodThing: string) => void;
  setThirdGoodThingCont: (thirdGoodThing: string) => void;
  changePage: (page: string) => void;
  currentDay: number;
  challengeLength: number;
  disableButton?: boolean;
}

export const OptimismToolStepMadePossible = (
  props: OptimismToolStepMadePossibleProps
) => {
  const {
    firstGoodThing,
    secondGoodThing,
    thirdGoodThing,
    firstGoodThingCont,
    secondGoodThingCont,
    thirdGoodThingCont,
    setFirstGoodThingCont,
    setSecondGoodThingCont,
    setThirdGoodThingCont,
    changePage,
    currentDay,
    challengeLength,
    disableButton,
  } = props;

  const handleGoodThingsContChange = (value: any, index: any) => {
    if (index === 0) {
      setFirstGoodThingCont(value);
    } else if (index === 1) {
      setSecondGoodThingCont(value);
    } else {
      setThirdGoodThingCont(value);
    }
  };

  return (
    <>
      <Box>
        <Heading
          text-align={"left"}
          fontSize={"2xl"}
          fontWeight={"semibold"}
          color={MODAL_THIRD_TEXT_COLOR}
        >
          {`${HVLocalizeStrings.OPTIMISM_TOOLS_DAY} ${currentDay} ${HVLocalizeStrings.OPTIMISM_TOOLS_OF} ${challengeLength}`}
        </Heading>
        <Heading
          fontSize={"2xl"}
          mt={"10"}
          fontWeight={"semibold"}
          text-align={"left"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {HVLocalizeStrings.OPTIMISM_TOOLS_GOOD_THINGS}
        </Heading>
        <Text mt={"5"} fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
          {HVLocalizeStrings.OPTIMISM_TOOLS_GOOD_THINGS_POSSIBLE}
        </Text>
      </Box>
      <Stack spacing={"15px"}>
        <OptimismToolInputItem
          listNumber={1}
          example={`${HVLocalizeStrings.OPTIMISM_TOOLS_WHAT_LED_TO} ${firstGoodThing}?`}
          handleChange={handleGoodThingsContChange}
          autoFocus={window.screen.width >= 568}
          dataTestId={"0"}
        />
        <OptimismToolInputItem
          listNumber={2}
          example={`${HVLocalizeStrings.OPTIMISM_TOOLS_WHAT_LED_TO} ${secondGoodThing}?`}
          handleChange={handleGoodThingsContChange}
          dataTestId={"1"}
        />
        <OptimismToolInputItem
          listNumber={3}
          example={`${HVLocalizeStrings.OPTIMISM_TOOLS_WHAT_LED_TO} ${thirdGoodThing}?`}
          handleChange={handleGoodThingsContChange}
          onEnterKeyDown={() => {
            if (
              firstGoodThingCont &&
              secondGoodThingCont &&
              thirdGoodThingCont &&
              !disableButton
            ) {
              changePage(OPTIMISM_OUTRO_STEP);
            }
          }}
          dataTestId={"2"}
        />
      </Stack>
      <Center mt={4} mb={2}>
        <AnimatedButton
          disabled={
            !firstGoodThingCont ||
            !secondGoodThingCont ||
            !thirdGoodThingCont ||
            disableButton ||
            false
          }
          text={HVLocalizeStrings.DONE}
          onClick={() => changePage(OPTIMISM_OUTRO_STEP)}
          dataTestId={HVTestId.OptimismTool.madePossibleDone}
        />
      </Center>
    </>
  );
};
