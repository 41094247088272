import { useCallback, useState } from "react";
import {
  getReminders,
  deleteReminder,
  saveReminder,
} from "../../Api/Solo/RemindersApi";
import { getKey } from "../../Utils/Helpers";
import { SchedulerDto, SchedulerInputDto } from "@headversity/contract";

export interface IReminderProvider {
  schedulerReminders: SchedulerDto[];
  removeReminderFromServer: (schedulerId: number) => Promise<void>;
  saveReminderToServer: (scheduler: SchedulerInputDto) => Promise<void>;
  getRemindersFromServer: () => Promise<SchedulerDto[]>;
}

export const useReminders = (): IReminderProvider => {
  const [schedulerReminders, setSchedulerReminders] = useState<SchedulerDto[]>(
    []
  );

  const getRemindersFromServer = useCallback(async () => {
    return getReminders(getKey()).then((result) => {
      setSchedulerReminders(result.data);
      return Promise.resolve(result.data);
    });
  }, []);

  const removeReminderFromServer = useCallback(async (id: number) => {
    return deleteReminder(getKey(), id).then(() => {
      getRemindersFromServer().then(() => {
        return Promise.resolve();
      });
      return Promise.resolve();
    });
  }, []);

  const saveReminderToServer = useCallback(
    async (scheduler: SchedulerInputDto) => {
      return saveReminder(getKey(), scheduler).then((response: any) => {
        getRemindersFromServer().then(() => {
          return Promise.resolve();
        });
        return response;
      });
    },
    []
  );

  return {
    schedulerReminders,
    getRemindersFromServer,
    removeReminderFromServer,
    saveReminderToServer,
  };
};
