import { Box, Image, Tooltip, useBreakpointValue } from "@chakra-ui/react";
import { GoalDto } from "@headversity/contract";
import { useContext } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import { ShellContext } from "../../../State/ShellContext";
import { EVENTS, track } from "../../../Utils/Analytics";
import {
  getBgImageSource,
  getGoalMinSkillLevel,
  SOLO_GOAL_SKILL_LEVEL_TARGET,
} from "../../../Utils/SkillsUtil";

interface GoalButtonProps {
  goalToUse: GoalDto | undefined;
  size: string;
}
export const GoalButton = ({ goalToUse, size }: GoalButtonProps) => {
  const isMd = useBreakpointValue({ base: false, md: true });

  const { userSkillStats } = useContext(GlobalContext);
  const {
    setIsGoalOverviewModalOpen,
    setIsGoalOverviewSeeAllGoals,
    setIsGoalCheckInModalOpen,
  } = useContext(ShellContext);

  if (!goalToUse) return <></>;

  return (
    <Tooltip
      label={`${HVLocalizeStrings.YOUR_PURSUIT}${
        HVLocalizeStrings.COLON
      } ${goalToUse.name.toString()}`}
      isDisabled={!isMd}
    >
      <Box
        as="button"
        className="goal-button"
        onClick={() => {
          const minLevel = getGoalMinSkillLevel(userSkillStats, goalToUse);

          // wait to show the check in modal until the user is above the target level
          if (minLevel <= SOLO_GOAL_SKILL_LEVEL_TARGET) {
            track(EVENTS.GoalModalOpened, { HV_From: "GoalButton" });
            setIsGoalOverviewSeeAllGoals(false);
            setIsGoalOverviewModalOpen(true);
          } else {
            track(EVENTS.GoalCheckInModalOpened, { HV_From: "GoalButton" });
            setIsGoalCheckInModalOpen(true);
          }
        }}
      >
        <Image
          src={`${getBgImageSource(goalToUse.imageUrl.toString(), true, true)}`}
          border="solid 1px #ffffff66"
          filter={"brightness(1.5);"}
          h={size}
          w={size}
          borderRadius="50%"
        />
      </Box>
    </Tooltip>
  );
};
