import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getKey } from "../../../Utils/Helpers";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { SplashScreen } from "@capacitor/splash-screen";
import { checkIfOutdatedVersion } from "../../../Utils/mobileUtils";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { Network } from "@capacitor/network";
import { setLocalStorageAuthTokens } from "../../../Utils/LoginUtil";

let intervalId: any = null;

export const MobileLoginPage = () => {
  const { isOnline, updateApp } = useContext<IGlobalProvider>(GlobalContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (isOnline) {
      // update the app even when the user has not logged in yet
      updateApp().then(() => {
        checkIfOutdatedVersion().then((isOutdatedVersion) => {
          if (!isOutdatedVersion) {
            if (getKey()) {
              navigate("/", { replace: true });
              return;
            }
            doLogin();
          }
        });
      });
    }
  }, [isOnline]);

  const doLogin = () => {
    clearInterval(intervalId);
    const browser = InAppBrowser.create(
      process.env.REACT_APP_WEB_LOGIN_URL as any,
      "_blank",
      {
        hidden: "yes",
        location: "no",
        toolbar: "no",
      }
    );

    // ensure network is available
    Network.addListener("networkStatusChange", (status: any) => {
      if (!status.connected) {
        browser.close();
      }
    });

    browser.on("loadstart").subscribe((e: any) => {
      clearInterval(intervalId);
      intervalId = setInterval(() => {
        if (
          window.location.href.toLocaleLowerCase().indexOf("mobilelogin") < 0 &&
          browser
        ) {
          clearInterval(intervalId);
          browser.close();
        }
      }, 300);
    });

    browser.on("loadstop").subscribe((e: any) => {
      const url = new URL(e.url);
      if (url.origin === process.env.REACT_APP_DEEP_LINK_URL) {
        const dt = url.searchParams.get("dt");
        const rt = url.searchParams.get("rt");
        if (dt && rt) {
          setLocalStorageAuthTokens({
            access_token: dt,
            refresh_token: rt as any,
          }).then(() => {
            const appUrlOpenLink = sessionStorage.getItem("app-url-open-link");
            if (appUrlOpenLink) {
              sessionStorage.setItem("app-url-open-link", "");
              navigate(appUrlOpenLink);
            } else {
              navigate("/", { replace: true });
            }
            SplashScreen.show({
              autoHide: false,
            });
            clearInterval(intervalId);
            browser.close();
          });
        }
      }
      browser.show();
    });
  };

  return <></>;
};
