import { Flex, Heading, Image, ModalFooter, useToken } from "@chakra-ui/react";
import { useContext } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  DEFAULT_THEME_NAME,
  IShellProvider,
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../../State/ShellContext";
import {
  MODAL_BACKGROUND_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";

import { LargeModal } from "../../Common/LargeModal";
import ThemeSwitch from "./ThemeSwitch";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ThemeSelectModal({ open, setOpen }: Props) {
  const { changeTheme, theme } = useContext<IShellProvider>(ShellContext);
  const handleChangeThemeClick = (selectedTheme: string) => {
    changeTheme(selectedTheme);
  };

  return (
    <LargeModal
      open={open}
      setOpen={setOpen as any}
      bgColor={MODAL_BACKGROUND_COLOR}
    >
      <Flex px={["30px", "80px"]} pb={"50px"} w={"100%"} flexDir={"column"}>
        <Flex flexDir={"column"} alignItems={"center"} gap={"25px"}>
          <Heading
            fontSize={"23px"}
            color={MODAL_PRIMARY_TEXT_COLOR}
            fontWeight={"600"}
          >
            {HVLocalizeStrings.THEME_SELECT_MODAL_HEADING}
          </Heading>
        </Flex>
        <Flex flexDir={"column"} mt={"60px"} gap={"60px"}>
          <ThemeSwitch
            title={HVLocalizeStrings.THEME_SELECT_MODAL_SWITCH_LABEL_DEFAULT}
            description={
              HVLocalizeStrings.THEME_SELECT_MODAL_SWITCH_LABEL_DEFAULT_DESCRIPTION
            }
            isChecked={theme.name === DEFAULT_THEME_NAME}
            onChange={() => handleChangeThemeClick(DEFAULT_THEME_NAME)}
          />
          <ThemeSwitch
            title={HVLocalizeStrings.THEME_SELECT_MODAL_SWITCH_LABEL_NOSTALGIC}
            description={
              HVLocalizeStrings.THEME_SELECT_MODAL_SWITCH_LABEL_NOSTALGIC_DESCRIPTION
            }
            isChecked={theme.name === NOSTALGIC_THEME_NAME}
            onChange={() => handleChangeThemeClick(NOSTALGIC_THEME_NAME)}
          />
        </Flex>
      </Flex>
    </LargeModal>
  );
}
