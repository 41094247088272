import React, { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { ThinkingToolModal } from "./ThinkingToolModal";
import {
  IThinkingToolProvider,
  ThinkingToolContext,
} from "../../../../State/Solo/ThinkingToolContext";
import { getKey } from "../../../../Utils/Helpers";
import { ToolLanding } from "../ToolLanding";
import { ThinkingToolStat, ThinkingToolStatCard } from "./ThinkingToolStatCard";
import {
  THINKING_LANDING_STEP,
  THINKING_THINKING_ACCURATELY_STEP,
} from "./ThinkingTool";
import { EVENTS, track } from "../../../../Utils/Analytics";

interface ThinkingToolLandingProps {
  toolSkillInfo: any;
}

export const ThinkingToolLanding = (props: ThinkingToolLandingProps) => {
  const { toolSkillInfo } = props;
  const [thinkingToolOpened, setThinkingToolOpened] = useState<boolean>(false);
  const [thinkingToolStep, setThinkingToolStep] = useState<string>("");

  const { thinkingPlanItems, getThinkingPlanItemsFromServer } =
    useContext<IThinkingToolProvider>(ThinkingToolContext);

  useEffect(() => {
    if (getKey()) {
      getThinkingPlanItemsFromServer();
    }
  }, [getKey()]);

  const viewLogClick: any =
    thinkingPlanItems && thinkingPlanItems?.length > 0
      ? () => {
          track(EVENTS.ToolHistoryOpened, { HV_Tool: "Thinking" });
          setThinkingToolStep(THINKING_LANDING_STEP);
          setThinkingToolOpened(true);
        }
      : null;

  return (
    <>
      <ToolLanding
        showTopBar={false}
        actionBarTitle={HVLocalizeStrings.THINKING_TOOLS}
        viewLogClick={viewLogClick}
        actionBarContent={<></>}
        mobileToolStat={
          <ThinkingToolStat
            thinkingPlanItems={thinkingPlanItems}
            isSmall={true}
          />
        }
        toolCard={
          <ThinkingToolStatCard
            thinkingPlanItems={thinkingPlanItems}
            setThinkingToolOpened={() => {
              track(EVENTS.ToolOpened, { HV_Tool: "Thinking" });
              setThinkingToolStep(THINKING_THINKING_ACCURATELY_STEP);
              setThinkingToolOpened(true);
            }}
            viewLogClick={viewLogClick}
          />
        }
        toolInfoOne={HVLocalizeStrings.THINKING_TOOLS_PAGE_INFO_ONE}
        toolBoost={HVLocalizeStrings.THINKING_TOOLS_PAGE_BOOST}
        skillInfos={toolSkillInfo}
        mobileLaunchText={HVLocalizeStrings.THINKING_TOOLS_PAGE_USE_TOOL}
        mobileLaunchFunction={() => {
          track(EVENTS.ToolOpened, { HV_Tool: "Thinking" });
          setThinkingToolStep(THINKING_THINKING_ACCURATELY_STEP);
          setThinkingToolOpened(true);
        }}
      ></ToolLanding>
      <ThinkingToolModal
        open={thinkingToolOpened}
        setOpen={setThinkingToolOpened as any}
        step={thinkingToolStep}
      ></ThinkingToolModal>
    </>
  );
};
