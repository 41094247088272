import { LocalizedHelpResource } from "../Components/Reach/HelpResources/ReachHelpResourcesPreview";
import {
  HelpResourceDto,
  InviteCodeDto,
  InviteCodeForReachDto,
  LocalizationText,
} from "../@headversity/contract";

const setLocalizedHelpResource = (
  helpResource: HelpResourceDto,
  language: string
): LocalizedHelpResource => {
  const languageKey = language as keyof LocalizationText;
  return {
    id: helpResource.id,
    logoUrl: (helpResource.logoUrl as LocalizationText)[languageKey] as string,
    name: (helpResource.name as LocalizationText)[languageKey] as string,
    phoneNumber: (helpResource.phoneNumber as LocalizationText)[
      languageKey
    ] as string,
    textNumber: (helpResource.textNumber as LocalizationText)[
      languageKey
    ] as string,
    iosAppUrl: (helpResource.iosAppUrl as LocalizationText)[
      languageKey
    ] as string,
    androidAppUrl: (helpResource.androidAppUrl as LocalizationText)[
      languageKey
    ] as string,
    websiteUrl: (helpResource.websiteUrl as LocalizationText)[
      languageKey
    ] as string,
    description: (helpResource.description as LocalizationText)[
      languageKey
    ] as string,
  };
};

export type Language = "en" | "fr" | "es";

export type FamilyInviteCodeProps = {
  language: Language;
  familyInviteCode: InviteCodeForReachDto;
};

export function getSharedFamilyInviteCode(
  inviteCode: InviteCodeDto,
  companyInviteCodes: InviteCodeForReachDto[]
) {
  if (inviteCode?.isFamily) {
    return undefined;
  }
  const filteredInviteCodes = companyInviteCodes.filter(
    (code) => code.isFamily
  );
  if (filteredInviteCodes.length === 0) {
    return undefined;
  }
  return filteredInviteCodes[0];
}

export const getHelpResourcesByLanguage = (
  helpResources: HelpResourceDto[],
  language: string
): LocalizedHelpResource[] => {
  if (helpResources.length === 0) {
    return [];
  }

  // filter out help resources that do not have the desired language
  const helpResourcesForLanguage = helpResources.filter((helpResource) => {
    const name = (helpResource.name as LocalizationText)[
      language as keyof LocalizationText
    ];
    return name && name.length > 0;
  });

  // if we have at least one help resource in the desired language, we use it
  if (helpResourcesForLanguage.length > 0) {
    return helpResourcesForLanguage.map((helpResource) =>
      setLocalizedHelpResource(helpResource, language)
    );
  }

  // Otherwise, we return all help resources in English
  return helpResources.map((helpResource) =>
    setLocalizedHelpResource(helpResource, "en")
  );
};
