import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { HeaderSetter } from "../Common/HeaderSetter";
import {
  BG_REACH_BASE,
  HEADER_REACH_PROMO_BACKGROUND,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { BlockSection } from "../Common/BlockSection";
import { Box, Center, Flex, Switch, Text } from "@chakra-ui/react";
import { HvSpinner } from "../Common/HvSpinner";
import { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { FadeAfterDelay } from "../Common/FadeAfterDelay";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import ReachPromoteCalendarView from "./Promote/ReachPromoteCalendarView";
import ReachPromoteListView from "./Promote/ReachPromoteListView";
import { useSearchParams } from "react-router-dom";

enum ViewMode {
  Calendar,
  List,
}

const ReachPromotePage = () => {
  const { promoMaterialsLoading, getPageTitle } =
    useContext<IGlobalProvider>(GlobalContext);
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Calendar);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const initialPage = searchParams.get("page") ?? "1";
    const initialSkill = searchParams.get("skill") ?? "";
    const initialLanguage = searchParams.get("language") ?? "";
    const initialAssetType = searchParams.get("assetType") ?? "";
    const initialCustom = searchParams.get("custom") ?? "";
    const initialPromo = searchParams.get("promo") ?? "";
    if (
      initialPage !== "1" ||
      initialSkill !== "" ||
      initialLanguage !== "" ||
      initialAssetType !== "" ||
      initialCustom !== "" ||
      initialPromo !== ""
    ) {
      setViewMode(ViewMode.List);
    }
  }, []);

  const handleChange = () => {
    if (viewMode === ViewMode.Calendar) {
      setViewMode(ViewMode.List);
    } else {
      setViewMode(ViewMode.Calendar);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.REACH_PROMOTE} - ${HVLocalizeStrings.REACH} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.REACH_PROMOTE}
        description={HVLocalizeStrings.REACH_PROMO_DESCRIPTION}
        backgroundImage={HEADER_REACH_PROMO_BACKGROUND}
        backgroundImageBase={BG_REACH_BASE}
      ></HeaderSetter>

      {promoMaterialsLoading ? (
        <BlockSection fadeIn={true} columns={1}>
          <Center>
            <HvSpinner />
          </Center>
        </BlockSection>
      ) : (
        <FadeAfterDelay noEase={viewMode === ViewMode.Calendar}>
          <Box
            maxW={isMainContainerXL ? "container.xl" : "container.lg"}
            mx={"auto"}
            mt={"20px"}
            mb={"20px"}
            px={"20px"}
          >
            <ViewModeSwitch
              isChecked={viewMode === ViewMode.List}
              onChange={handleChange}
            />
            <Box mt={6}>
              {viewMode === ViewMode.Calendar && <ReachPromoteCalendarView />}
              {viewMode === ViewMode.List && <ReachPromoteListView />}
            </Box>
          </Box>
        </FadeAfterDelay>
      )}
    </>
  );
};

interface ViewModeSwitchProps {
  onChange?: () => void;
  isChecked?: boolean;
}

const ViewModeSwitch = (props: ViewModeSwitchProps) => {
  const { onChange, isChecked } = props;
  return (
    <Flex gap={2} alignItems={"center"}>
      <Text fontSize={"20px"} color={MODAL_PRIMARY_TEXT_COLOR}>
        {HVLocalizeStrings.REACH_CALENDAR_VIEW}
      </Text>
      <Switch size={"md"} isChecked={isChecked} onChange={onChange} />
      <Text fontSize={"20px"} color={MODAL_PRIMARY_TEXT_COLOR}>
        {HVLocalizeStrings.REACH_LIST_VIEW}
      </Text>
    </Flex>
  );
};

export default ReachPromotePage;
