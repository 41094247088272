import styled from "styled-components";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { LargeModal } from "../../Common/LargeModal";

const TermsAndPoliciesDiv = styled.div`
  margin-top: 30px;
  padding-top: 10px;
  padding: 10px;

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  background-color: white;
`;

interface PrivacyPolicyProps {
  privacyPolicyOpen: boolean;
  openPrivacyPolicy: (open: boolean) => void;
}

export const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({
  privacyPolicyOpen,
  openPrivacyPolicy,
}): JSX.Element => {
  return (
    <LargeModal open={privacyPolicyOpen} setOpen={openPrivacyPolicy}>
      <TermsAndPoliciesDiv
        dangerouslySetInnerHTML={{
          __html: HVLocalizeStrings.PRIVACY_PAGE,
        }}
      ></TermsAndPoliciesDiv>
    </LargeModal>
  );
};
