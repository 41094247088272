import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../../Styles/HeadversityStyle";
import { HvTextInput } from "../../../Common/HvTextInput";
import { FocusSteps } from "./FocusSteps";

interface FocusToolStepTitleAndSituationProps {
  changePage: (page: string) => void;
  planTitle: string;
  setPlanTitle: (title: string) => void;
  planSituation: string;
  setPlanSituation: (planSituation: string) => void;
}

export const FocusToolStepTitleAndSituation = (
  props: FocusToolStepTitleAndSituationProps
) => {
  const {
    changePage,
    planTitle,
    setPlanTitle,
    planSituation,
    setPlanSituation,
  } = props;
  return (
    <>
      <>
        <Box>
          <label htmlFor="focus_tool_title_input">
            <Heading
              fontSize={"2xl"}
              fontWeight={"semibold"}
              color={MODAL_PRIMARY_TEXT_COLOR}
            >
              {HVLocalizeStrings.FOCUS_TOOLS_PLAN_TITLE}
            </Heading>
          </label>
          <Text fontSize="md" my={"3"} color={MODAL_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.FOCUS_TOOLS_PLAN_TITLE_NAME}
          </Text>
          <HvTextInput
            value={planTitle}
            onChange={(e) => setPlanTitle(e.currentTarget.value)}
            placeholder={HVLocalizeStrings.FOCUS_TOOLS_PLAN_TITLE}
            id="focus_tool_title_input"
            autoFocus={window.screen.width >= 568}
          />

          <label htmlFor="focus_tool_situation_text_area">
            <Heading
              mt={"10"}
              fontSize={"2xl"}
              fontWeight={"semibold"}
              color={MODAL_PRIMARY_TEXT_COLOR}
            >
              {HVLocalizeStrings.FOCUS_TOOLS_PLAN_WHAT}
            </Heading>
          </label>
          <Text fontSize="md" my={"3"} color={MODAL_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.FOCUS_TOOLS_PLAN_WHAT_DESCRIPTION}
          </Text>
          <HvTextInput
            value={planSituation}
            onChange={(e) => setPlanSituation(e.currentTarget.value)}
            onEnterKeyDown={() => {
              if (planTitle && planSituation) {
                changePage(FocusSteps.FocusTodoStep);
              }
            }}
            id="focus_tool_situation_text_area"
            placeholder={HVLocalizeStrings.FOCUS_TOOLS_PLAN_WHAT}
          />
        </Box>
        <Center mt={8} mb={2}>
          <AnimatedButton
            disabled={!planTitle || !planSituation}
            onClick={() => {
              changePage(FocusSteps.FocusTodoStep);
            }}
            text={HVLocalizeStrings.NEXT}
          />
        </Center>
      </>
    </>
  );
};
