import {
  Box,
  Center,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import dayjs from "dayjs";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../../Styles/HeadversityStyle";
import { HvDateTimePicker } from "../../../Common/HvDateTimePicker/HvDateTimePicker";
import { HvSelect } from "../../../Common/HvSelect";
import { HvTextInput } from "../../../Common/HvTextInput";
import { SchedulerFrequency } from "../../../../@headversity/contract";
import { FocusSteps } from "./FocusSteps";

interface FocusToolStepTimeSelectionProps {
  changePage: (page: string) => void;
  startDate: string;
  setDateTimeInfo: (startDate: string, timeZone: string) => void;
  repeat: SchedulerFrequency;
  setRepeat: (repeat: SchedulerFrequency) => void;
  disableButton?: boolean;
}

export const FocusToolStepTimeSelection = (
  props: FocusToolStepTimeSelectionProps
): JSX.Element => {
  const {
    changePage,
    startDate,
    setDateTimeInfo,
    repeat,
    setRepeat,
    disableButton,
  } = props;
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  const now = dayjs().add(1, "day");
  return (
    <>
      <Box>
        <Heading
          fontSize={"2xl"}
          fontWeight={"semibold"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {HVLocalizeStrings.FOCUS_TOOLS_PLAN_WHEN}
        </Heading>
        <Text fontSize="md" my={"3"} color={MODAL_PRIMARY_TEXT_COLOR}>
          {HVLocalizeStrings.FOCUS_TOOLS_PLAN_WHEN_NOTIFY}
        </Text>
        <Box
          display={"flex"}
          flexDir={"column"}
          gap={"20px"}
          alignItems={"center"}
          mt={"35px"}
        >
          {isMobile ? (
            <HvTextInput
              type={"datetime-local"}
              min={now.format("YYYY-MM-DDTHH:mm")}
              w={"xs"}
              value={startDate}
              onChange={(e: { currentTarget: { value: string } }) => {
                const startDate = dayjs(e.currentTarget.value)
                  .utc()
                  .toISOString();
                const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
                if (dayjs(e.currentTarget.value).isBefore(now)) {
                  setDateTimeInfo(now.toISOString(), timeZone);
                  return;
                }
                setDateTimeInfo(startDate, timeZone);
              }}
            />
          ) : (
            <HvDateTimePicker
              onChange={setDateTimeInfo}
              value={startDate}
              isMinTomorrow={true}
            />
          )}

          <HvSelect
            w={"xs"}
            my={"2"}
            value={SchedulerFrequency[repeat]}
            onChange={(e) => {
              setRepeat(
                SchedulerFrequency[
                  e.currentTarget.value as keyof typeof SchedulerFrequency
                ]
              );
            }}
          >
            <option value={SchedulerFrequency[SchedulerFrequency.DoNotRepeat]}>
              {HVLocalizeStrings.DO_NOT_REPEAT}
            </option>
            <option value={SchedulerFrequency[SchedulerFrequency.Daily]}>
              {HVLocalizeStrings.EVERYDAY}
            </option>
            <option value={SchedulerFrequency[SchedulerFrequency.Weekdays]}>
              {HVLocalizeStrings.EVERY_WEEKDAY}
            </option>
            <option value={SchedulerFrequency[SchedulerFrequency.Weekly]}>
              {HVLocalizeStrings.WEEKLY}
            </option>
            <option value={SchedulerFrequency[SchedulerFrequency.Monthly]}>
              {HVLocalizeStrings.MONTHLY}
            </option>
            <option value={SchedulerFrequency[SchedulerFrequency.Yearly]}>
              {HVLocalizeStrings.YEARLY}
            </option>
          </HvSelect>
        </Box>
      </Box>
      <Center mt={8} mb={2}>
        <AnimatedButton
          disabled={!startDate || disableButton || false}
          onClick={() => {
            changePage(FocusSteps.FocusOutroStep);
          }}
          text={HVLocalizeStrings.SAVE}
        />
      </Center>
    </>
  );
};
