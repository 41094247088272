import { Box, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../State/GlobalContext";
import { BlockSection } from "../Common/BlockSection";
import BlockSectionTitle from "../Common/BlockSectionTitle";
import { InlineBox } from "../Solo/Shared/InlineBox";
import { CertBlock } from "./CertBlock";
import { CertViewModel } from "./CertViewModels";
import { LessonCourseProvider } from "../../State/Solo/LessonCourseContext";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { HeaderWelcomeText } from "../Common/HeaderWelcomeText";
import { getCertViewModelFromCert } from "../../Utils/CertUtil";

export const CertHome = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const { certifications, selectedUserLanguage, resourceLock } =
    useContext(GlobalContext);

  const [certs, setCerts] = useState<CertViewModel[]>([]);

  useEffect(() => {
    if (certifications.length === 0) return;

    const certs = [] as CertViewModel[];

    certifications.forEach((c) => {
      if (resourceLock && resourceLock.resourceId !== c.id) {
        return;
      }

      certs.push(getCertViewModelFromCert(c));
    });

    setCerts(certs);
  }, [certifications, selectedUserLanguage, resourceLock]);

  return (
    <>
      {window.innerWidth < 568 && (
        <BlockSection fadeIn={true} columns={1} noPaddingBottom={true}>
          <HeaderWelcomeText blockSection={true} blockSectionMb={4} />
        </BlockSection>
      )}

      <BlockSection
        fadeIn={true}
        includeSpacing={true}
        title={
          <BlockSectionTitle
            title={HVLocalizeStrings.CERT_PROGRAMS}
            description={HVLocalizeStrings.CERT_PROGRAMS_SUBHEAD}
          />
        }
        columns={1}
        rows={1}
        gap={"0"}
      >
        <Box>
          {certs.map((cert, idx) => {
            return (
              <InlineBox
                isDesktop={isDesktop}
                idx={idx}
                key={cert.certification.id}
              >
                <LessonCourseProvider>
                  <CertBlock cert={cert} />
                </LessonCourseProvider>
              </InlineBox>
            );
          })}
        </Box>
      </BlockSection>
    </>
  );
};
