import { ReachHelpResourcesContextProvider } from "../../../State/Reach/ReachHelpResourcesContext";
import { ReachHelpResourcesPage } from "./ReachHelpResourcesPage";

export const ReachHelpResourcesPageContainer = () => {
  return (
    <ReachHelpResourcesContextProvider>
      <ReachHelpResourcesPage />
    </ReachHelpResourcesContextProvider>
  );
};
