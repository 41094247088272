import { Button, Flex } from "@chakra-ui/react";
import { ColorPickerChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { HvTextInput } from "../../../Common/HvTextInput";
import { CharterFontSelector } from "./CharterFontSelector";
import { CharterDataDto } from "@headversity/contract";
import "./CharterCustomizer.css";
import { CharterBgColorSelector } from "./CharterBgColorSelector";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { White } from "../../../../Styles/HeadversityStyle";

interface CharterCustomizerProps {
  displayData: CharterDataDto;
  onPropertyChanged?: (data: CharterDataDto) => void;
  onDownload?: () => void;
}

interface ToolContainerProps {
  children: any;
}
const ToolContainer = (props: ToolContainerProps) => {
  return (
    <Flex flexDir="row" mx={2}>
      {props.children}
    </Flex>
  );
};

export const CharterCustomizer: React.FC<CharterCustomizerProps> = (props) => {
  const [currentColor, setCurrentColor] = useState<string>(
    props.displayData.background!
  );
  const handleOnColorChange = (event: ColorPickerChangeEvent) => {
    setCurrentColor(event.value);

    props.onPropertyChanged?.({
      ...props.displayData,
      background: event.value,
    });
  };

  return (
    <Flex
      mt={6}
      flexDir="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
    >
      <ToolContainer>
        <HvTextInput
          type={"text"}
          value={props.displayData.name}
          onChange={(event) =>
            props.onPropertyChanged?.({
              ...props.displayData,
              name: event.target.value,
            })
          }
        />
      </ToolContainer>

      <ToolContainer>
        <CharterBgColorSelector
          color={currentColor}
          onBgColorSelect={handleOnColorChange}
        />
      </ToolContainer>

      <ToolContainer>
        <CharterFontSelector
          selectorName={HVSportLocalizeStrings.CHARTER_FONT}
          color={
            props.displayData.fontColor ? props.displayData.fontColor : White
          }
          onFontSelect={(font: string) => {
            props.onPropertyChanged?.({
              ...props.displayData,
              font: font,
            });
          }}
          onFontColorSelect={(fontColor: string) => {
            props.onPropertyChanged?.({
              ...props.displayData,
              fontColor: fontColor,
            });
          }}
        />
      </ToolContainer>

      <ToolContainer>
        <Button onClick={props.onDownload}>Download</Button>
      </ToolContainer>
    </Flex>
  );
};
