import { useState } from "react";
import {
  Center,
  Flex,
  Spinner,
  Text,
  Link,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import FormInputField from "../Shared/FormInputField";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { ErrorText } from "../Shared/ErrorText";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { createToast } from "../../../Utils/toastUtil";
import { DONT_HAVE_INVITE_CODE_STEP, SIGN_IN_STEP } from "./LoginPage";
import { PRIMARY_TEXT_COLOR, Success, Error } from "../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../Testing/dataTestIds";
import { requestPasswordResetCode } from "../../../Api/People/PasswordApi";
import {
  InviteCodeDomainDto,
  RegistrationLoginStates,
  RequestPasswordCodeDto,
} from "@headversity/contract";
import { isMobile } from "react-device-detect";
import { getDomainFromEmail } from "../../../Utils/LoginUtil";
import { useSSOCheck } from "../../../Hooks/People/useSSOCheck";

interface ForgotPasswordStepProps {
  currentStep: string | null | undefined;
  setCurrentStep: (currentStep: string | null) => void;
  inviteCodeDomains: InviteCodeDomainDto[];
}

export const ForgotPasswordStep = (props: ForgotPasswordStepProps) => {
  const isMobileView = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  const { currentStep, setCurrentStep, inviteCodeDomains } = props;
  const [email, setEmail] = useState<string | null>(null);
  const [isInvalid, setIsInvalid] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const handleChange = (
    stateToUpdate: RegistrationLoginStates,
    stateText: string,
    isValid: boolean
  ) => {
    switch (stateToUpdate) {
      case RegistrationLoginStates.Email:
        setEmail(stateText);
        if (isValid) {
          setIsInvalid(false);
        } else {
          setIsInvalid(true);
        }
        break;
      default:
        return;
    }
  };

  const toast = useToast();
  const { singleSignOnEnabled, ssoUrl } = useSSOCheck(email);

  const submitChangePassword = async () => {
    if (!email || email.trim() === "" || isInvalid) {
      return;
    }
    setIsSubmitting(true);
    if (getDomainFromEmail(inviteCodeDomains, email)) {
      setError(HVLocalizeStrings.PASSWORD_CANT_CHANGE);
      setIsSubmitting(false);
    } else {
      requestPasswordResetCode({
        email: email,
        isMobile: sessionStorage.getItem("isMobile") === "true" || isMobile,
      } as RequestPasswordCodeDto)
        .then(
          (result) => {
            let status = Success
            let message = HVLocalizeStrings.RESET_PASSWORD_EMAIL_SENT
            if (result.data.errorCode !== undefined) {
              status = Error
              message = HVLocalizeStrings.EMAIL_HAS_PROBLEM
            }
            createToast(toast, status, "forgotPasswordEmail", message);
            setCurrentStep(SIGN_IN_STEP);
          },
          () => {}
        )
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      <Flex
        flexDir={"column"}
        gap={"5"}
        color={PRIMARY_TEXT_COLOR}
        mb={8}
        alignItems={isMobileView ? "center" : undefined}
      >
        {error && (
          <Center w={"300px"}>
            <ErrorText message={error} />
          </Center>
        )}
        {singleSignOnEnabled ? (
          <>
            <Text
              fontWeight={"500"}
              fontSize={"xl"}
              data-testid={HVTestId.ForgotPasswordStep.title}
            >
              {HVLocalizeStrings.SINGLE_SIGN_ON_ENABLED}
            </Text>
            <Text maxW={"345px"}>
              <>
                {HVLocalizeStrings.SINGLE_SIGN_ON_LINK_TEXT.split("{0}")[0]}
                <Link href={ssoUrl} color="blue.500" ml={1}>
                  {HVLocalizeStrings.SINGLE_SIGN_ON_LINK}
                </Link>
                {HVLocalizeStrings.SINGLE_SIGN_ON_LINK_TEXT.split("{0}")[1]}
              </>
            </Text>
          </>
        ) : (
          <>
            <Text
              fontWeight={"500"}
              fontSize={"xl"}
              data-testid={HVTestId.ForgotPasswordStep.title}
            >
              {currentStep === DONT_HAVE_INVITE_CODE_STEP
                ? HVLocalizeStrings.LOGIN_WELCOME_EMAIL
                : HVLocalizeStrings.LOGIN_FORGOT_PASSWORD}
            </Text>
            <Text
              maxW={"345px"}
              dangerouslySetInnerHTML={{
                __html:
                  currentStep === DONT_HAVE_INVITE_CODE_STEP
                    ? HVLocalizeStrings.LOGIN_WELCOME_DESCRIPTION
                    : HVLocalizeStrings.LOGIN_FORGOT_PASSWORD_DESCRIPTION,
              }}
            />
          </>
        )}
      </Flex>
      <Flex
        flexDirection={"column"}
        gap={"2"}
        align={"center"}
        p={"20px"}
        pt={0}
      >
        <Flex flexDir={"column"} gap={"2"}>
          <FormInputField
            id={"email"}
            imageSrc={
              "https://cdn.headversity.com/app/resources/login/envelope.png"
            }
            imageW={"18px"}
            imageH={"18px"}
            placeholder={HVLocalizeStrings.EMAIL_PLACEHOLDER}
            handleChange={handleChange}
            stateToUpdate={RegistrationLoginStates.Email}
            type={"email"}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submitChangePassword();
              }
            }}
            autoFocus
          />
        </Flex>
        <>
          {isSubmitting ? (
            <Spinner />
          ) : (
            <AnimatedButton
              text={HVLocalizeStrings.LOGIN_PAGE_SEND_EMAIL}
              disabled={
                isSubmitting ||
                !email ||
                email.trim() === "" ||
                isInvalid ||
                singleSignOnEnabled
              }
              colorSet={AnimatedButtonColorSet.Primary}
              onClick={() => {
                submitChangePassword();
              }}
              w={300}
              dataTestId={HVTestId.ForgotPasswordStep.sendEmailButton}
            />
          )}
        </>
      </Flex>
    </>
  );
};
