import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { SchedulerDto, SchedulerInputDto } from "@headversity/contract";
const REMINDER_API_URL = "/api/reminders";

export const getReminders = (token: string) => {
  return AxiosClient.get<SchedulerDto[]>(REMINDER_API_URL, getConfig(token));
};

export const deleteReminder = async (token: string, id: number) => {
  return AxiosClient.delete<void>(
    `${REMINDER_API_URL}/${id}`,
    getConfig(token)
  );
};

export const saveReminder = async (
  token: string,
  schedulerDto?: SchedulerInputDto
) => {
  return AxiosClient.post<SchedulerInputDto>(
    REMINDER_API_URL,
    schedulerDto,
    getConfig(token)
  );
};
