import { SlideFade } from "@chakra-ui/react";

interface FadeAfterDelayProps {
  children: any;
  delay?: number;
  noEase?: boolean;
}

/**
 * All pages should wrap their content with this components, which:
 * - allows the scroll position to reset
 * - gives the header a chance to position itself
 * - provides a fade-in effect and ease opt-out
 */
export const FadeAfterDelay = (props: FadeAfterDelayProps) => {
  return (
    <>
      <SlideFade
        delay={props.delay ? props.delay / 1000.0 : 0.25}
        in={true}
        className={props.noEase ? "" : "ease"}
      >
        {props.children}
      </SlideFade>
    </>
  );
};
