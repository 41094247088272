import { AxiosResponse } from "axios";
import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { S3UploadDto, UploadType } from "@headversity/contract";

export const getPresignedURLForImageUpload = (
  fileExtension: string,
  uploadType: UploadType,
  token: string
): Promise<AxiosResponse<S3UploadDto>> => {
  let url = `/api/s3upload/images/presignedUrl?fileExtension=${fileExtension}&uploadType=${uploadType}`;
  if (uploadType === UploadType.TEAM_LOGO) {
    // use team endpoint
    url = `/api/teams/images/presignedUrl?fileExtension=${fileExtension}&uploadType=${uploadType}`;
  }
  return AxiosClient.get<S3UploadDto>(url, getConfig(token));
};

export const uploadImageWithPresignedURL = (
  presignedUrl: string,
  file: File
): Promise<AxiosResponse<void>> => {
  return AxiosClient.put<void>(presignedUrl, file);
};

export const getImagePresignedURL = (
  fileName: string,
  uploadType: UploadType,
  token: string
): Promise<AxiosResponse<string>> => {
  return AxiosClient.get<string>(
    `/api/s3upload/image?fileName=${fileName}&uploadType=${uploadType}`,
    getConfig(token)
  );
};
