import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import {
  BOOST_TEXT_COLOR,
  DESTRUCTIVE_TEXT_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
  MODAL_SECONDARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";

interface ThinkingToolStepExchangeBalancedProps {
  currentInputForNegativeThought: string;
  currentInputForBalancedThought: string;
  changePage: (page: string) => void;
  nextStep: string;
  disableButton?: boolean;
}

export const ThinkingToolStepExchangeBalanced = (
  props: ThinkingToolStepExchangeBalancedProps
) => {
  const {
    currentInputForNegativeThought,
    currentInputForBalancedThought,
    changePage,
    nextStep,
    disableButton,
  } = props;
  return (
    <>
      <Box>
        <Heading
          mt={"5"}
          fontSize={"2xl"}
          fontWeight={"semibold"}
          text-align={"left"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {HVLocalizeStrings.THINKING_TOOLS_STEP_3}
        </Heading>
        <Text mt={"5"} color={MODAL_SECONDARY_TEXT_COLOR}>
          {HVLocalizeStrings.THINKING_TOOLS_EXCHANGE}
        </Text>
        <Text
          mt={"2"}
          color={DESTRUCTIVE_TEXT_COLOR}
          fontSize="2xl"
          fontWeight={"semibold"}
        >
          "{currentInputForNegativeThought}"
        </Text>
        <Text mt={"5"} color={MODAL_SECONDARY_TEXT_COLOR}>
          {" "}
          {HVLocalizeStrings.THINKING_TOOLS_NOW_EXCHANGE_FOR}
        </Text>
        <Text
          mt={"5"}
          color={BOOST_TEXT_COLOR}
          fontSize="2xl"
          fontWeight={"semibold"}
        >
          "{currentInputForBalancedThought}"
        </Text>
      </Box>
      <Center mt={8} mb={2}>
        <AnimatedButton
          disabled={disableButton || false}
          text={HVLocalizeStrings.SAVE}
          onClick={() => changePage(nextStep)}
        />
      </Center>
    </>
  );
};
