import LocalizedStrings from "react-localization";
import { termsPolicy } from "./TermsPolicy";

export const HVLocalizeStringsObj = {
  en: {
    TEAM_ERROR_USER_REGISTERED_WITH_DIFFERENT_COMPANY:
      "User is registered with a different company. They cannot be added to teams outside their registered company.",
    GLOSSARY: "Glossary",
    USER_ROLE_LEARNER: "Learner",
    USER_ROLE_ADMIN: "Admin",
    USER_ROLE_HV_ADMIN: "HV Admin",
    USER_ROLE_HV_CONTENT_ADMIN: "HV Content Admin",
    USER_ROLE_HV_CSM_ADMIN: "HV Customer Service Admin",
    USER_ROLE_HV_MKT_ADMIN: "HV Marketing Admin",
    USER_ROLE_SALES: "Sales Person",
    SINGLE_SIGN_ON_LINK_TEXT:
      "Your email is associated with an external single sign-on (SSO) service. Please {0} using the external SSO provider.",
    SINGLE_SIGN_ON_LINK: "authenticate",
    LANGUAGE_ENGLISH: "English",
    LANGUAGE_FRENCH: "Français",
    LANGUAGE_SPANISH: "Español",
    NANO_PRACTICE_NO_RESULTS: "No results found.",
    NANO_PRACTICE_TYPE_FILTER: "All Practice Types",
    NANO_PRACTICE_CATEGORY: "Collection",
    NANO_PRACTICE_CATEGORY_FLEXIBLE_THINKING: "Flexible Thinking",
    NANO_PRACTICE_CATEGORY_TAKING_ACTION: "Taking Action",
    NANO_PRACTICE_CATEGORY_TAMING_EMOTIONS: "Taming Emotions",
    NANO_PRACTICE_CATEGORY_QUICK_RELIEF: "Stress Relief",
    NANO_PRACTICE_CATEGORY_FLEXIBLE_THINKING_DESCRIPTION:
      "Learn to be open-minded and find different solutions to problems.",
    NANO_PRACTICE_CATEGORY_TAKING_ACTION_DESCRIPTION:
      "Take charge of your habits and actions to create positive outcomes and reach your goals.",
    NANO_PRACTICE_CATEGORY_TAMING_EMOTIONS_DESCRIPTION:
      "Control your emotions so they don't control you.",
    NANO_PRACTICE_CATEGORY_QUICK_RELIEF_DESCRIPTION:
      "Quickly calm your mind and body, providing instant relief from stress and tension.",
    NANO_PRACTICE_TYPE: "Type",
    NANO_PRACTICE_TYPE_VIDEO: "Video",
    NANO_PRACTICE_TYPE_SHOW_AND_TELL: "Reflection",
    NANO_PRACTICE_TYPE_HEADZONE: "Tracking",
    NANO_PRACTICE_FAVORITES_ONLY: "Favorites Only",
    SOLO_HOME_INTERNET_CONNECTION_ERROR:
      "We're sorry, but we're unable to load the app at this time. Please check your internet connection and try again later.",
    SOLO_AI_ERROR:
      "We’re sorry, an unexpected error has occurred. Please try again.",
    SOLO_UNEXPECTED_ERROR:
      "We’re sorry, an unexpected error has occurred. Please check your internet connection and try again later.",
    SPORT_MENU_HOME: "Home",
    SPORT_MENU_RESOURCES: "Resources",
    SOLO_MENU_HOME: "Home",
    SOLO_MENU_SKILLS: "Skills",
    SOLO_MENU_LEARN: "Lessons",
    SOLO_MENU_PRACTICE: "Tools",
    SOLO_MENU_RESILIENCE_FEED: "Resilience Feed",
    SOLO_MENU_LOGOUT: "Log out",
    SOLO_MENU_CHOOSE_THEME: "Theme",
    SOLO_MENU_NOTIFICATION: "Notifications",
    SOLO_MENU_NOTIFICATION_OPTIMISM_SINGLE:
      "Check in for your optimism challenge",
    PRACTICE_REMINDER_SAVED: "Challenge set!",
    PRACTICE_REMINDER_REMOVED: "Challenge disabled.",
    PRACTICE_REMINDERS: "Practice Reminders",
    PRACTICE_REMINDER_HABITS:
      "To help you remember to keep practicing, we recommend you set a ",
    PRACTICE_REMINDER: "practice reminder",
    PRACTICE_REMINDER_HABITS2:
      "Setting a reminder will allow you integrate practice into your daily routine and develop healthy habits.",
    PRACTICE_REMINDER_REMINDERS: "Enable challenge",
    PRACTICE_REMINDER_AT_THE_FOLLOWING_TIME: "At the following time",
    PRACTICE_REMINDER_AT_THE_FOLLOWING_DAYS:
      "On the following days of the week",
    PRACTICE_REMINDER_DAY_OF_WEEK_SUN: "S",
    PRACTICE_REMINDER_DAY_OF_WEEK_MON: "M",
    PRACTICE_REMINDER_DAY_OF_WEEK_TUE: "T",
    PRACTICE_REMINDER_DAY_OF_WEEK_WED: "W",
    PRACTICE_REMINDER_DAY_OF_WEEK_THUR: "T",
    PRACTICE_REMINDER_DAY_OF_WEEK_FRI: "F",
    PRACTICE_REMINDER_DAY_OF_WEEK_SAT: "S",
    PROFILE_PAGE_HISTORY: "Activity History",
    PROFILE_PAGE_PRACTICE_REMINDERS_HISTORY: "View Activity History",
    PRACTICE_REMINDER_REMOVE_FAV: "Remove from your favorite practices",
    PRACTICE_REMINDER_ADD_FAV: "Add to your favorite practices",
    PRACTICE_REMINDER_ADD_FAV_HEADLINE:
      "Do you want to add this practice to your favorites?",
    PRACTICE_ADDED_TO_FAVORITES: "Practice added to favorites!",
    PRACTICE_REMOVE_FAVORITE_TITLE: "Remove favorite?",
    PRACTICE_REMOVE_FAVORITE: "Are you sure you want to remove this favorite?",
    PRACTICE_REMINDER_SET_REMINDER: "Set Challenge",
    PRACTICE_REMINDER_MANAGE_CHALLENGE: "Manage Challenge",
    SOLO_MENU_NOTIFICATION_FOCUS_SINGLE: "You have a focus plan coming up",
    SOLO_MENU_PUSH_NOTIFICATION_PERMISSION: "Enable Push Notifications",
    SOLO_MENU_PUSH_DISABLE_NOTIFICATIONS: "Disable Push Notifications",
    SOLO_MENU_LIVE_UPDATE: "Check For Updates",
    SOLO_MENU_PROFILE: "Profile",
    SOLO_MENU_PROFILE_SETTINGS: "Profile / Settings",
    SOLO_MENU_WEBPUSH: "Enable push notifications for a better experience",
    SOLO_MENU_TERMS_OF_SERVICE: "Terms of Service",
    SOLO_MENU_INVITE_FAMILY_MEMBERS: "Invite Family Members",
    SOLO_MENU_PRIVACY_POLICY: "Privacy Policy",
    SOLO_MENU_CONTACT: "Contact Us",
    SOLO_HOME_SEE_MORE_RESILIENCE_FEED: "See All Resilience Feed Posts",
    SOLO_SEE_ALL_LIVE_SESSIONS: "See All Live Sessions",
    WELCOME_TO: "Welcome to",
    SOLO_HOME_DESCRIPTION: "Get ahead of adversity today",
    SOLO_HOME_YOUR_TITLE: "Your",
    SOLO_HOME_LIVE_SESSIONS_TITLE: "Live Sessions",
    SOLO_HOME_LIVE_SESSIONS_DESCRIPTION:
      "Attend our next live event or review one you missed.",
    SOLO_HOME_RESILIENCE_FEED_TITLE: "Resilience Feed",
    SOLO_HOME_RESILIENCE_FEED_DESCRIPTION:
      "Expand your resilience journey with weekly posts.",
    SOLO_HOME_RESILIENCE_FEED_MULTIPLE_SKILLS: "Multiple skills",
    SOLO_HOME_RESILIENCE_SKILLS_DESCRIPTION:
      "The essential skills that make you who you are, and ensure you have the ability to get ahead of adversity.",
    SOLO_HOME_ACHIEVEMENTS_DESCRIPTION: "Track your progress",
    SOLO_HOME_SUGGESTED_TITLE: "Suggested",
    SOLO_HOME_SUGGESTED_TITLE_LESSON: "Suggested Lessons",
    SOLO_HOME_SUGGESTED_TITLE_TOOLS: "Suggested Tools",
    SOLO_HOME_SUGGESTED_FOR_YOU_TITLE: "For You",
    SOLO_HOME_SUGGESTED_FOR_YOU_LESSON_DESCRIPTION:
      "A collection of lessons based on your current Resilience Skill scores.",
    SOLO_HOME_SUGGESTED_FOR_YOU_TOOL_DESCRIPTION:
      "A collection of tools based on your current Resilience Skill scores.",
    SOLO_EXPLORE_DESCRIPTION:
      "Browse all available resources to boost your resilience",
    SOLO_LEARN_DESCRIPTION: "Learn about the science behind resilience",
    SOLO_LEARN_NEXT_LESSON: "Next Lesson",
    SOLO_PRACTICE_DESCRIPTION:
      "Strengthen your resilience with comprehensive resources",
    SOLO_SETTINGS_TITLE: "Settings",
    SOLO_PROFILE_TITLE: "Profile",
    SOLO_PROFILE_DESCRIPTION:
      "Review your progress, notifications, and settings",
    SOLO_SKILL_TITLE: "Resilience Skills",
    SOLO_SKILL_DESCRIPTION:
      "The essential skills to be your most resilient self",
    SOLO_ACHIEVEMENT_TITLE: "Achievements",
    SOLO_ACHIEVEMENT_DESCRIPTION:
      "Track your progress and be proud of how far you've come!",
    SOLO_SKILL_VIEW_ALL_ACHIEVEMENTS: "See All Achievements",
    SOLO_ACHIEVEMENT_MODAL_BADGE_NOT_EARNED_TEXT:
      "You haven't earned this badge yet",
    SOLO_ACHIEVEMENT_MODAL_BADGE_EARNED_TEXT: "You've earned this badge",
    SOLO_ACHIEVEMENT_MODAL_BADGE_EARNED_DATE_TEXT:
      "You first earned this badge",
    SOLO_ACHIEVEMENT_MODAL_BADGE_EARNED_DATE_TEXT_SINGLE:
      "You earned this badge",
    SOLO_PAGE_NOT_FOUND: "Page Not Found",
    SOLO_PAGE_NOT_FOUND_DESCRIPTION:
      "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.",
    HEADSCAN_QUESTION_REDO: "Redo All Resilience Scores",
    HEADSCAN_QUESTION_WELCOME_SENTENCE: "Welcome to",
    HEADSCAN_QUESTION_FIRST_SENTENCE:
      "These are your initial Resilience Skill scores. They indicate how you are feeling &lt;em&gt;at this time&lt;/em&gt;. They can change every day and are by no means permanent.",
    HEADSCAN_QUESTION_BOLD_SENTENCE:
      "You can re-assess your scores at any time.",
    HEADSCAN_QUESTION_LAST_SENTENCE:
      "Continue to do so to see how you improve over time!",
    HEADSCAN_QUESTION_START_MY_TRAINING: "Start My Training",
    LESSON_SUCCESS_CHECK_INS: "Self check-ins",
    LESSON_SUCCESS_RELEVANT: "I found the lesson relevant",
    LESSON_SUCCESS_POSITIVELY: "I'm motivated to act positively",
    LESSON_START: "Start Lesson",
    LESSON_RESUME: "Resume Lesson",
    LESSON_RESTART: "Restart Lesson",
    LESSON_STARTED_ON: "You started this lesson on",
    LESSON_COMPLETED_ON: "You completed this lesson on",
    QUESTION: "Question",
    NEXT: "Next",
    BACK: "Back",
    PREV: "Prev",
    STEP: "Step",
    DONE: "Done",
    WE_ARE_DONE: "We're Done",
    SAVE: "Save",
    EDIT: "Edit",
    COMPLETE: "Complete",
    AND: "and",
    START_TRAINING: "Start Training!",
    CONTINUE_TRAINING: "Continue Training",
    GET_STARTED: "Get Started",
    CATCH_UP: "Catch-Up",
    IN_PROGRESS: "In progress",
    ANOTHER_IN_PROGRESS: "Another presentation is in progress",
    CLOSE: "Close",
    PAGE: "Page",
    OF: "of",
    OR: "or",
    NAME: "Name",
    EMAIL: "Email",
    REMOVE: "Remove",
    PUTBACK: "Put back",
    SKIP: "Skip",
    IN: "in",
    AND_MORE: "and more",
    AND_X_MORE: "and {0} more",
    SKILL_ENERGY_MANAGEMENT: "Energy Management",
    SKILL_PANEL_PAGE: "Page",
    SKILL_CONTENT_PAGE_YOUR_SCORE: "Your Score",
    SKILL_CONTENT_PAGE_YOUR_SCORE_WITH_FORMAT: "Your {0} Score",
    SKILL_CONTENT_PAGE_REDO_YOUR_RESILIENCE_SCORE: "Redo Score",
    SKILL_CONTENT_PAGE_LESSONS: "Lessons",
    SKILL_CONTENT_PAGE_LESSON_DESCRIPTION:
      "Lessons handpicked for you, designed to help you increase your ",
    SKILL_CONTENT_PAGE_LESSON_DESCRIPTION_SKILL: " skill.",
    RESiLIENCE_PAGE_DESCRIPTION:
      "A collection of resources designed to help you get the most out of your resilience journey",
    RESILIENCE_FEED_FEEDBACK1: "I found this post helpful",
    SKILL_CONTENT_PAGE_SUGGESTED: "Suggested",
    SKILL_CONTENT_PAGE_SUGGESTED_LESSONS_FOR_YOU: "Suggested Lessons",
    SKILL_CONTENT_PAGE_SUGGESTED_TOOLS_FOR_YOU: "Suggested Tools",
    SKILL_CONTENT_PAGE_RELATED_TOOLS: "Related Tools",
    SKILL_CONTENT_PAGE_TOOLS_DESCRIPTION:
      "Tools handpicked from our team, designed to help you increase your ",
    SKILL_CONTENT_PAGE_TOOLS_DESCRIPTION_SKILL: " skill.",
    SKILL_CONTENT_PAGE_RESILIENCE_FEED_DESCRIPTION:
      "A collection of resources related to ",
    BADGES_EARNED: "Badges",
    SHIELDS_IN_PROGRESS: "Shields In Progress",
    USE_HEADZONE_TOOL: "Use the Headzone Tool",
    USE_CALMING_TOOL: "Use the Calming Tool",
    USE_FOCUS_TOOL: "Use the Focus Tool",
    USE_THINKING_TOOL: "Use the Thinking Tool",
    USE_MINDFULNESS_TOOL: "Use the Mindfulness Tool",
    USE_MOVE_TOOL: "Use the Move Tool",
    USE_OPTIMISM_TOOL: "Use the Optimism Tool",
    EXPLORE: "Explore",
    EXPLORE_HEADZONE_TOOL: "Explore the Headzone Tool",
    EXPLORE_CALMING_TOOL: "Explore the Calming Tool",
    EXPLORE_FOCUS_TOOL: "Explore the Focus Tool",
    EXPLORE_THINKING_TOOL: "Explore the Thinking Tool",
    EXPLORE_MINDFULNESS_TOOL: "Explore the Mindfulness Tool",
    EXPLORE_MOVE_TOOL: "Explore the Move Tool",
    EXPLORE_OPTIMISM_TOOL: "Explore the Optimism Tool",
    TOOLS_PAGE_USED_TIMES: "Times",
    TOOLS_PAGE_USED_TIME: "Time",
    CALMING_TOOLS: "Calming",
    CALMING_START: "START",
    CALMING_STOP: "PAUSE",
    CALMING_IN: "BREATHE IN",
    CALMING_OUT: "BREATHE OUT",
    CALMING_MODE_TIME: "TIME",
    CALMING_MODE_CYCLE: "CYCLES",
    CALMING_INSTRUCTION_TEXT:
      "Follow the circle. Inhale as it expands. Exhale as it shrinks.",
    CALMING_INSTRUCTION_BUTTON_TEXT: "Calming",
    CALMING_TOOLS_PAGE_USE_TOOL: "Launch the Calming Tool",
    CALMING_TOOLS_PAGE_BOOST: "The Calming Tool can help boost your:",
    CALMING_TOOLS_PAGE_LAST_USED: "The last time you calmed down was",
    CALMING_TOOLS_PAGE_USED: "Calming Tool Times Used",
    CALMING_TOOLS_PAGE_INFO_ONE:
      "Take a few slow, deep breaths to reduce stress and return your attention to the present moment.",
    HEAD_ZONE_TOOLS: "Headzone",
    HEAD_ZONE_HOW_FEELING: "How are you feeling?",
    HEAD_ZONE_TOOLS_HELP_TEXT: "Click/Drag to select",
    HEAD_ZONE_TOOLS_HELP_TEXT_MOBILE: "Tap/Drag to select",
    HEAD_ZONE_TOOLS_IN_THIS: "IN THIS ",
    HEAD_ZONE_TOOLS_HEADZONE: "HEADZONE",
    HEAD_ZONE_TOOLS_DONE: "Done",
    HEAD_ZONE_TOOLS_MORE: "More",
    HEAD_ZONE_TOOLS_PAGE_INFO_ONE:
      "Tracking your headzone helps you understand where you’re at and what you can do to feel your best.",
    HEADZONE_TOOLS_PAGE_BOOST: "The Headzone Tool can help boost your:",
    HEADZONE_TOOLS_PAGE_USED: "Headzone Tool Times Used",
    HEADZONE_TOOLS_PAGE_USE_TOOL: "Launch the Headzone Tool",
    HEADZONE_TOOLS_PAGE_CHART_SECTION_TITLE: "Your Headzone History",

    HEADZONE_TOOLS_STATES_UNWELL: "Unwell",
    HEADZONE_TOOLS_STATES_BURNOUT_TITLE: "UNWELL",

    HEADZONE_TOOLS_STATES_STRESSED: "Stressed",
    HEADZONE_TOOLS_STATES_STRUGGLING: "Struggling",
    HEADZONE_TOOLS_STATES_STRESSED_TITLE: "STRUGGLING",

    HEADZONE_TOOLS_STATES_OKAY: "Okay",
    HEADZONE_TOOLS_STATES_OKAY_TITLE: "OKAY",

    HEADZONE_TOOLS_STATES_GREAT: "Good",
    HEADZONE_TOOLS_STATES_GREAT_TITLE: "GOOD",

    HEADZONE_TOOLS_STATES_THRIVING: "Thriving",
    HEADZONE_TOOLS_STATES_THRIVING_TITLE: "THRIVING",

    HEADZONE_TOOLS_CHART_OVERALL: "Overall %",
    HEADZONE_TOOLS_CHART_BOX_TITLE: "Your Headzone Numbers",
    HEAD_ZONE_TOOLS_CHART_BOX_SUBTITLE:
      "Tracking your headzone helps you understand where you’re at and what you can do to feel your best. Here's a look at your headzone numbers to date.",
    HEADZONE_DONUT_CHART_TITLE: "Your Headzone Breakdown",
    HEADZONE_LINE_CHART_TITLE: "Your Headzone Over Time",
    HEADZONE_LINE_CHART_LEGEND_TITLE: "Average Score",
    HEADZONE_LOCKED_TEXT: "Start tracking your progress.",
    HEADZONE_VERY_HIGH: "Very High",
    HEADZONE_HIGH: "High",
    HEADZONE_MEDIUM: "Medium",
    HEADZONE_LOW: "Low",
    HEADZONE_VERY_LOW: "Very Low",

    MINDFULNESS_TOOLS: "Mindfulness Library",
    MINDFULNESS_TOOLS_YOUR: "Your",
    MINDFULNESS_TOOLS_7_DAYS: "7 Days of ",
    MINDFULNESS_TOOLS_SINGLES: "Singles",
    MINDFULNESS_TOOLS_PACKAGE: "Packages.",
    MINDFULNESS_TOOLS_MINDFULNESSES: "Mindfulnesses.",
    MINDFULNESS_TOOLS_PRODUCTIVITY: "MINDFULNESS FOR PRODUCTIVITY",
    MINDFULNESS_TOOLS_GRATITUDE: "7-DAYS OF GRATITUDE",
    MINDFULNESS_TOOLS_SLEEP: "SLEEP SERIES",
    MINDFULNESS_TOOLS_TITLE: "Title",
    MINDFULNESS_JUST_BREATHE: "JUST BREATHE",
    MINDFULNESS_EMOTIONS: "MASTERING EMOTIONS",
    MINDFULNESS_TOOLS_PAGE_USED: "You’ve used the Mindfulness Tool",
    MINDFULNESS_TOOLS_PAGE_TOTAL_MINUTES: "Total Minutes",
    MINDFULNESS_TOOLS_PAGE_BOOST: "The Mindfulness Tool can help boost your:",
    MINDFULNESS_TOOLS_PAGE_USE_TOOL: "Launch the Mindfulness Tool",
    MINDFULNESS_TOOLS_PAGE_NO_STREAK: "Lets start a new streak!",
    MINDFULNESS_TOOLS_PAGE_STREAK: "Daily Streak (Day) ",
    MINDFULNESS_TOOLS_PAGE_STREAK_DAYS: "day(s)",
    MINDFULNESS_TOOLS_PAGE_INFO_ONE:
      "Take a mindful moment for greater focus and reduced stress.",
    FOCUS_TOOLS: "Focus",
    FOCUS_TOOLS_PAGE_BOOST: "The Focus Tool can help boost your:",
    FOCUS_TOOLS_PAGE_USED: "Total Focus Plans Created",
    FOCUS_TOOLS_PAGE_USE_TOOL: "Launch the Focus Tool",
    FOCUS_TOOLS_LANDING_NEW_PLAN: "Start a New Focus Plan",
    OPTIMISM_TOOLS: "Optimism",
    OPTIMISM_TOOLS_ACTIVE: "Active Optimism Challenges",
    OPTIMISM_TOOLS_PAGE_BOOST: "The Optimism Tool can help boost your",
    OPTIMISM_TOOLS_PAGE_INFO_ONE:
      "Practice gratitude to focus on the positives and develop a more optimistic perspective.",
    OPTIMISM_TOOLS_NO_PLANS: "No optimism plan has been created",
    OPTIMISM_TOOLS_PAGE_USED: "Challenges Completed",
    OPTIMISM_TOOLS_PAGE_USE_TOOL: "Launch the Optimism Tool",
    OPTIMISM_TOOLS_INTRO_TITLE: "Train Optimism",
    OPTIMISM_TOOLS_INTRO:
      "It's easy. Just set and follow your own optimism challenges. Science has proven that these challenges will help you feel more positive about regular things, even while you keep the bigger picture close to heart.",
    OPTIMISM_TOOLS_WELCOME: "Welcome to your Optimism Challenge!",
    OPTIMISM_TOOLS_LENGTH_OF_CHALLENGE: "Pick the length of your challenge",
    OPTIMISM_TOOLS_BEST_TIME: "When's best to Train Optimism?",
    OPTIMISM_TOOLS_PROVEN:
      "This is proven to work best at the end of your work day",
    OPTIMISM_TOOLS_GOOD_THINGS: " 3 Good Things Today.",
    OPTIMISM_TOOLS_GOOD_THINGS_POSSIBLE:
      "Great! Now, challenge yourself to think about what led these good things to happen.",
    OPTIMISM_TOOLS_PICK:
      "Pick anything, absolutely anything from today that is good.",
    OPTIMISM_TOOLS_GOOD_STUFF: "Good Stuff!",
    OPTIMISM_TOOLS_GOOD_STUFF_DESCRIPTION:
      " We'll do this again tomorrow. Until next time, keep trying to notice the good things. The more you train positively, the more it will come naturally.",
    OPTIMISM_TOOLS_GOT_SLEEP: "I got a good 8 hours of sleep!",
    OPTIMISM_TOOLS_BEDTIME_STORY: "I read a bedtime story to my child",
    OPTIMISM_TOOLS_GREAT_WORKOUT: "I got in a great workout",
    OPTIMISM_TOOLS_WHAT_LED_TO: "What led to",
    OPTIMISM_TOOLS_CURRENT_MENU_DONE: "End Challenge",
    OPTIMISM_TOOLS_CURRENT_MENU_PAUSE: "Pause Notification",
    OPTIMISM_TOOLS_CURRENT_MENU_START: "Start Notification",
    OPTIMISM_TOOLS_CURRENT_MENU_TIME: "Change Time",
    OPTIMISM_TOOLS_CURRENT_MENU_NEW: "Start New Challenge",
    OPTIMISM_TOOLS_CURRENT_MENU_SHOW: "Show Current Challenge",
    OPTIMISM_TOOLS_DAYS_CHALLENGE: "Days Challenge",
    OPTIMISM_TOOLS_LANDING_RESUME_CHALLENGE: "Resume Challenge",
    OPTIMISM_TOOLS_LANDING_NO_CHALLENGE: "Good Job,all caught up",
    OPTIMISM_TOOLS_CHALLENGE_READY: "Your Optimism Check-In is ready!",
    OPTIMISM_TOOLS_CHALLENGE_MISSED:
      "You missed this date, but that is ok you can still Check-In",
    OPTIMISM_TOOLS_CHALLENGE_MISSED_COMPLETELY: "You missed this date",
    OPTIMISM_TOOLS_CHALLENGE_CHECKED_IN: "Check-In",
    OPTIMISM_TOOLS_CHALLENGE_READY_TOMORROW:
      "Your Optimism Check-In isn’t scheduled til later. Come back then!",
    OPTIMISM_TOOLS_DAY: "Day",
    OPTIMISM_TOOLS_OF: "of",
    OPTIMISM_TOOLS_CURRENT_PLAN: "Current Plan",
    OPTIMISM_TOOLS_TIME_TO_CHECK_IN: "Time to check in!",
    OPTIMISM_TOOLS_START_NEW_CONFIRMATION:
      "Are you sure you want to end your current challenge and start a new one?",
    OPTIMISM_TOOLS_STOP_CONFIRMATION:
      "Are you sure you want to end your current challenge?",
    OPTIMISM_TOOLS_CHALLENGE_ALREADY_OVER: "This challenge is already over.",
    SOLO_STATS_BLOCK_TITLE: "Stats",
    THEME_SELECT_MODAL_HEADING: "Select Your Theme",
    THEME_SELECT_MODAL_SWITCH_LABEL_DEFAULT: "Light",
    THEME_SELECT_MODAL_SWITCH_LABEL_DEFAULT_DESCRIPTION:
      "Orange headers and blue highlights, like a crisp fall morning at sunrise...",
    THEME_SELECT_MODAL_SWITCH_LABEL_NOSTALGIC: "Dark",
    THEME_SELECT_MODAL_SWITCH_LABEL_NOSTALGIC_DESCRIPTION:
      "A dark blue theme with orange highlights, like the middle of the night...",
    CONTINUE: "Continue",
    THINKING_TOOLS: "Thinking",
    THINKING_TOOLS_PAGE_INFO_ONE:
      "Swap your negative thoughts with more balanced and positive ones.",
    THINKING_TOOLS_INTRO_LANDING_TITLE: "Your Logs",
    THINKING_TOOLS_YOUR_LOG_ON: "Your thoughs on",
    THINKING_TOOLS_PAGE_BOOST: "The Thinking Tool can help boost your:",
    THINKING_TOOLS_PAGE_USED: "Balanced thoughts created",
    THINKING_TOOLS_PAGE_USE_TOOL: "Launch the Thinking Tool",
    THINKING_TOOLS_INPUT_NEW_THOUGHTS: "New Thoughts",
    THINKING_TOOLS_PRACTICE: "Practice Thinking Accurately",
    THINKING_TOOLS_IDENTIFY:
      "Training your thinking helps you to feel more positive about regular things, and improves your overall mood. You'll do this by identifying a critical thought, challenging it, and exchanging it for something different.",
    THINKING_TOOLS_STEP_1: "1. Identify",
    THINKING_TOOLS_STEP_2: "2. Challenge",
    THINKING_TOOLS_STEP_2_INSTRUCTIONS:
      "Challenging your thought will help you consider things from a more objective perspective and look at the whole picture.",
    THINKING_TOOLS_STEP_3: "3. Exchange",
    THINKING_TOOLS_TAP: "Is this a thought or a fact?",
    THINKING_TOOLS_CRITICAL:
      "What is a critical or negative thought you’re having?",
    THINKING_TOOLS_PEOPLE_THINK: "e.g. People think I'm hard to deal with",
    THINKING_TOOLS_YOU_IDENTIFIED: "You identified this statement as a",
    THINKING_TOOLS_YOU_IDENTIFIED_NOT: "NOT a ",
    THINKING_TOOLS_YOU_USING_INSIGHTS:
      "Using these insights, type a more balanced statement here:",
    THINKING_TOOLS_NOT_EVERYONE: "e.g. Not everyone needs to like me",
    THINKING_TOOLS_WHAT_DOES: "What does the evidence say?",
    THINKING_TOOLS_REJECTS_STATEMENT: "More evidence REJECTS this statement",
    THINKING_TOOLS_SUPPORTS_STATEMENT: "More evidence SUPPORTS this statement",
    THINKING_TOOLS_WHAT_WOULD: "What would your best friend say?",
    THINKING_TOOLS_MY_BEST_DISAGREE: "My best friend would DISAGREE",
    THINKING_TOOLS_MY_BEST_AGREE: "My best friend would AGREE",
    THINKING_TOOLS_NOW_EXCHANGE:
      "Now you'll exchange this with a more balanced thought.",
    THINKING_TOOLS_EXCHANGE: "You identified the following thought:",
    THINKING_TOOLS_NOW_EXCHANGE_FOR: "and exchanged it for:",
    THINKING_TOOLS_THOUGHT: "THOUGHT",
    THINKING_TOOLS_FACT: "FACT",
    THINKING_TOOLS_REJECTS: "REJECTS",
    THINKING_TOOLS_SUPPORTS: "SUPPORTS",
    THINKING_TOOLS_AGREE: "AGREE",
    THINKING_TOOLS_DISAGREE: "DISAGREE",
    THINKING_TOOLS_GREAT: "Great Job!",
    THINKING_TOOLS_RESILIENT: "Resilient people think accurately.",
    THINKING_TOOLS_REMIND: "Remind yourself to use the",
    THINKING_TOOLS_REMIND_ICE: "Thinking Tool",
    THINKING_TOOLS_REMIND_WHEN: "when you have a critical or negative thought.",
    THINKING_TOOLS_REMIND_DOES: "Does your thought relate to a task?",
    THINKING_TOOLS_REMIND_DOES_CLICK: "Click",
    THINKING_TOOLS_REMIND_DOES_CREATE: "to create a FOCUS plan.",
    THINKING_TOOLS_EXCHANGE_DATE: "Your Exchange from ",
    THINKING_TOOLS_LOG_NEGATIVE: "was your negative thought.",
    THINKING_TOOLS_LOG_THOUGHT_NOT:
      "You identified this statement as a <strong>FACT</strong>, not a thought.",
    THINKING_TOOLS_LOG_THOUGHT_IS:
      "You identified this statement as a <strong>THOUGHT</strong>, not a fact.",
    THINKING_TOOLS_LOG_STATEMENT_SUPPORT:
      "There is more evidence that <strong>SUPPORTS</strong> this statement.",
    THINKING_TOOLS_LOG_STATEMENT_NOT_SUPPORT:
      "There is more evidence that <strong>REJECTS</strong> this statement.",
    THINKING_TOOL_LOG_AGREE:
      "Your best friend would <strong>AGREE</strong> with this statement. ",
    THINKING_TOOL_LOG_DISAGREE:
      "Your best friend would <strong>DISAGREE</strong> with this statement. ",
    THINKING_TOOLS_LOG_POSITIVE: "is your improved, balanced thought!",
    FOCUS_TOOLS_SET_PLAN: "Set a Focus Plan",
    FOCUS_TOOLS_ACTIVE: "Your Active Focus Plans",
    FOCUS_TOOLS_SET_PLAN_DESCRIPTION:
      "A focus plan will help you put attention where it’s needed and, very importantly, decide how you need to feel during a challenging event. Choosing an emotion will help you achieve your goals.",
    FOCUS_TOOLS_PAGE_INFO_ONE:
      "Create a plan to set yourself up for success and reach your goals.",
    FOCUS_TOOLS_PLAN_TITLE: "Plan Title",
    FOCUS_TOOLS_PLAN_TITLE_NAME: "What’s the name of this plan?",
    FOCUS_TOOLS_PLAN_WHAT: "What’s the situation?",
    FOCUS_TOOLS_PLAN_WHAT_DESCRIPTION:
      "What’s coming up that needs all your attention to get it done right?",
    FOCUS_TOOLS_PLAN_HAVE_TO_DO: "What do you have to do?",
    FOCUS_TOOLS_PLAN_HAVE_TO_DO_DESCRIPTION:
      "What do you need to do in the moment to stay focused?",
    FOCUS_TOOLS_PLAN_HAVE_TO_FEEL: "How do you have to feel?",
    FOCUS_TOOLS_PLAN_HAVE_TO_FEEL_DESCRIPTION:
      "Which feeling will help you keep focused on execution?",
    FOCUS_TOOLS_PLAN_WHEN: "When is it happening?",
    FOCUS_TOOLS_PLAN_HAPPENED: "Happened on ",
    FOCUS_TOOLS_PLAN_START: "Start on",
    FOCUS_TOOLS_PLAN_NEXT: "Next on",
    FOCUS_TOOLS_PLAN_WHEN_NOTIFY:
      "You’ll get a notification 30 minutes before this happens to remind you of your Focus plan.",
    FOCUS_TOOLS_PLAN_HAVE_TO: "I have to...",
    FOCUS_TOOLS_PLAN_NEED_TO: "I need to...",
    FOCUS_TOOLS_PLAN_IS_SET: "Your Plan is Set",
    FOCUS_TOOLS_PLAN_IS_SET_DESCRIPTION:
      "You’ll get a notification 30 minutes before this event to remind you of your focus plan. Less luck is needed when you have a plan!",
    FOCUS_TOOLS_INTRO_LANDING_TITLE_UPCOMING: "Upcoming Focus Plans",
    FOCUS_TOOLS_INTRO_LANDING_TITLE_PREVIOUS: "Previous Focus Plans",
    FOCUS_TOOLS_INTRO_LANDING_TITLE_NEW: "New Focus Plan",
    FOCUS_TOOLS_PLAN_COPY: "Copy Plan",
    FOCUS_TOOLS_PLAN_VIEW: "View Plan",
    FOCUS_TOOLS_PLAN_DELETE: "Delete Plan",
    FOCUS_TOOLS_PLAN_EDIT: "Edit Plan",
    FOCUS_TOOLS_DELETE_CONFIRMATION:
      "Are you sure you want to delete this Focus Plan?",
    MOVE_TOOLS: "Move",
    MOVE_TOOLS_PAGE_TOTAL_STEPS: "Total Steps",
    MOVE_TOOLS_PAGE_TOTAL_DISTANCE: "Total Distance (km)",
    MOVE_TOOLS_PAGE_TOTAL_STAIRS: "Total Stairs Climbed",
    MOVE_TOOLS_PAGE_TOTAL_CALORIES: "Total Calories Burnt",
    MOVE_TOOLS_PAGE_USE_TOOL: "Launch the Move Tool",
    MOVE_TOOLS_PAGE_BOOST: "The Move Tool can help boost your:",
    MOVE_TOOLS_CHART_STEPS_SERIES_NAME: "Steps",
    MOVE_TOOLS_CHART_DISTANCE_SERIES_NAME: "Distance (km)",
    MOVE_TOOLS_CHART_STAIRS_SERIES_NAME: "Stairs",
    MOVE_TOOLS_CHART_CALORIES_SERIES_NAME: "Calories",
    MOVE_TOOLS_CHART_STEP_TITLE: "Steps/Distance (km) Result",
    MOVE_TOOLS_CHART_STAIRS_TITLE: "Stairs/Calories Result",
    MOVE_TOOLS_PAGE_INFO_ONE:
      "Moving helps you to feel more positive about regular things, and improves your overall mood.",
    MOVE_TOOLS_APP_HEADLINE: "Sync Your Movement Data",
    MOVE_TOOLS_APP_INFO: "Install the mobile app to sync your movement data!",
    MOVE_TOOLS_GET_THE_APP: "Get the app",
    INTRO_BUTTON_TITLE: "View Intro Again",
    LANGUAGE_TITLE: "Language",
    LANGUAGE_MODAL_HEADING: "Select Your Language",
    CUSTOM_FIELDS_TITLE: "User Information",
    CUSTOM_FIELDS_SUBTITLE: "Please complete the question below.",
    CUSTOM_FIELDS_SELECT_PLACEHOLDER: "Select your option",
    MINUTES_TRAINED: "Minutes trained",
    LAST_ACTIVITY: "Last activity",
    STAT_DAILY_PLANS_COMPLETE: "Daily Threes completed",
    STAT_PRACTICES_COMPLETE: "Practices completed",
    STAT_LESSONS_COMPLETE: "Lessons completed",
    STAT_POSTS_VIEWED: "Resilience posts viewed",
    STAT_BALANCED_THOUGHTS: "Balanced Thoughts",
    STAT_OPTIMISIM_CHALLENGES: "Optimism Challenges",
    STAT_FOCUS_PLANS: "Focus Plans",
    STAT_MINDFULNESS_MINUTES: "Mindfulness minutes",
    STAT_CALMING_TOOL: "Calming Tool Times Used",
    NOTIFICATION_TITLE: "Notifications",
    NOTIFICATION_HEADER_STATUS: "Status",
    NOTIFICATION_HEADER_TITLE: "Title",
    NOTIFICATION_HEADER_DATE: "Date",
    NOTIFICATION_HEADER_MARK_ALL_AS_READ: "Mark all as read",
    NOTIFICATION_NONE: "You have no notifications",
    NOTIFICATION_NEW: "New!",
    TOOLS_VIEW_LOGS: "View Your History",
    WEB_NOTIFICATION_NEW: "You have a new Notification!",
    DO_NOT_REPEAT: "Do Not Repeat",
    THANK_YOU: "Thank you",
    EVERYDAY: "Everyday",
    EVERY_WEEKDAY: "Every Weekday",
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
    YEARLY: "Yearly",
    DELETE: "delete",
    SCORE: "Score",
    SKILL_SCORE: "Skill score",
    DATE_FORMAT: "MMM D, YYYY",
    DATE_FORMAT_WITH_TIME: "MMM D, YYYY h:mm A",
    DATE_FORMAT_KENDO_DATETIME: "MMM d, yyyy h:mm tt",
    DATE_FORMAT_WEEKDAY: "%A, %b %e %Y",
    CHARTS_DAY_FORMAT: "%b-%d",
    SUNDAY: "Sun",
    MONDAY: "Mon",
    TUESDAY: "Tue",
    WEDNESDAY: "Wed",
    THURSDAY: "Thu",
    FRIDAY: "Fri",
    SATURDAY: "Sat",
    GET_HELP_LABEL: "Get Help Now",
    GET_REACH_HELP_LABEL: "Visit the Help Center",
    GET_REACH_HELP_TEXT: "Help",
    CONFIRM_OK: "Ok",
    CONFIRM_YES: "Yes",
    CONFIRM_NO: "No",
    NOT_NOW: "Not now",
    INVITE: "Invite",
    PUSH_NOTIFICATION_INFO:
      "When you enable web push notifications, we'll be able to notify you when it's time for a Focus plan check-in or an Optimism challenge. Your browser may block these notifications by default, so if you have trouble enabling notifications, please check your browser settings.",
    WEB_PUSH_NOTIFICATIONS: "Push Notifications",
    HEADVERSITY: "headversity",
    REACH: "REACH",
    ENABLE: "Enable",
    NEW_APP_VERSION: "New version is now available!",
    NEW_APP_VERSION_DESCRIPTION:
      "Please click the button below to update and continue with <em>headversity</em>",
    TOOLS_COMING_SOON: "Coming Soon",
    TOOLS_COMING_SOON_DESCRIPTION:
      "New tools being introduced in future updates!",
    MINUTE_VERSION: "Minute Version",
    ZONE: "zone",
    TURN_ON_NOTIFICATIONS: "Turn on Notifications",
    ENABLE_EMAIL_NOTIFICATIONS: "Resubscribe to email communications",
    UNSUBSCRIBE_EMAIL_TITLE: "You've been unsubscribed successfully.",
    UNSUBSCRIBE_EMAIL_DESCRIPTION_1:
      "You will no longer receive emails from <em>headversity</em>. If this was a mistake or you change your mind, you can resubscribe at any time by visiting your profile settings. For any questions, please contact us at <a href='mailto:help@headversity.com'>help@headversity.com</a>.",
    WELCOME_SHOW_HIDE_OPTIONS: "Options",

    WELCOME_TAKE_CONTROL:
      "Manage stress, adapt to change, and get a<i>head</i> of ad<i>versity</i> today with our",
    WELCOME_ADJ2: "personalized",
    WELCOME_ADJ3: "confidential",
    WELCOME_ADJ_END: " training",

    WELCOME_VIDEO: "Here's a quick introductory video.",

    WELCOME_HEADZONE_SELECT_ZONE: "Select the zone",
    WELCOME_HEADZONE_SELECT_DESCRIBE: "that best describes how you’re feeling.",

    WELCOME_HEADZONE_UNWELL_HELP: "If you need immediate help, tap the",
    WELCOME_HEADZONE_UNWELL_BUTTON: "button at any time to view additional ",
    WELCOME_HEADZONE_UNWELL_VIEW: "support resources",
    WELCOME_HEADZONE_UNWELL_PROVIDED: " provided by your organization.",
    WELCOME_HEADSCAN_LOW:
      "Recognizing how you feel is the first step to feeling better. We’re here for you with resources to handle life’s challenges.",
    WELCOME_HEADZONE_HELP_SUCCEED:
      "This will allow us to send you helpful, periodic reminders.",

    WELCOME_SKILLS_STARTING: "Let's find the right",
    WELCOME_SKILLS_STARTING_POINT: "starting point",
    WELCOME_SKILLS_STARTING_JOURNEY: "for your training plan.",
    WELCOME_SKILLS_ALRIGHT_LETS_EXPLORE:
      "First, let's measure where you're at with the skill of ",
    WELCOME_SKILLS_ALRIGHT_LETS_EXPLORE_RESCORE:
      "First, let's get an updated score for the skill of ",
    WELCOME_SKILLS_PLEASE_COMPLETE:
      "Measuring your score as it changes over time helps to track your progress. Please complete these ",
    WELCOME_SKILLS_QUESTIONS: "questions",
    WELCOME_SKILLS_TO_CONTINUE: "to continue your journey.",
    WELCOME_SKILLS_QUICK_QUESTIONS:
      "To do this, you'll answer a few quick questions.",
    WELCOME_SKILLS_SCORES_OVER_TIME: "Here's a look at your score over time.",

    SCORE_HIGH:
      "A <b style='font-weight:600'>high</b> score indicates you excel in this skill, consistently applying it with ease and experiencing significant benefits as a result. Continuing to train this skill can help you maintain your high level of proficiency and adapt to new challenges.",
    SCORE_MED_HIGH:
      "A <b style='font-weight:600'>medium-high</b> score indicates you may be quite adept at this skill, applying it effectively in most scenarios. Training can help you leverage this skill even more to gain greater benefits in your personal and professional life.",
    SCORE_MED:
      "A <b style='font-weight:600'>medium</b> score indicates you may have a fair grasp of this skill and can apply it in certain situations. Training can help you refine your abilities and make you more consistent in its application.",
    SCORE_MED_LOW:
      "A <b style='font-weight:600'>medium-low</b> score indicates you may have some ability in this skill but often face challenges and inconsistencies in using it. Training can build a solid foundation.",
    SCORE_LOW:
      "A <b style='font-weight:600'>low</b> score indicates you may struggle with this skill. Training can help you overcome challenges and significantly enhance your effectiveness.",

    WELCOME_COMPLETE_SCORE_IS: "Here's your skill score in ",
    WELCOME_COMPLETE_SCORES_ARE: "Here are your skill scores, ",
    WELCOME_COMPLETE_NOW_LETS: "Now let's",

    HEADZONE_YOURE_IN_THE: "You're in the",
    HEADZONE_WELCOME_TO_THE: "Welcome to the",
    HEADZONE_YOUVE_REACHED_THE: "You've reached the",

    TOUR_MENU: "Take a Tour",
    TOUR_MENU_TITLE: "Getting Around",
    TOUR_MENU_APP_DESCRIPTION: "Toggle between SOLO and TEAM training.",
    TOUR_MENU_DESCRIPTION:
      "Everything you need to be your most resilient self, including all lessons and tools, can be found in this menu.",
    TOUR_MENU_DESCRIPTION_TEAM:
      "SOLO, TEAM, and everything you need to be your most resilient self can be found in this menu.",
    TOUR_MENU_DESCRIPTION_REACH:
      "Toggle between the administrative functions of REACH and resilience trainings.",
    TOUR_GET_HELP_DESCRIPTION:
      "Tapping this icon will connect you to the mental health resources provided by your organization, so you’re always just a click away from support.",
    TOUR_GET_REACH_HELP_DESCRIPTION:
      "Access the Help Center for detailed instructions on all things REACH.",
    TOUR_DAILY_THREE_DESCRIPTION:
      "Each day, we'll suggest three activities based on your pursuit. Complete these and level up!",
    TOUR_RECOMMENDED_DESCRIPTION:
      "Try these additional activities and continue leveling up!",
    DAILY_THREE_TITLE: "Your Daily Three",
    CERT_SECTION: "Section",
    CERT_GETTING_STARTED: "Getting Started",
    CERT_CHECKPOINT: "Checkpoint",
    CERT_REFLECTIONS: "Reflections",
    CERT_FOR_A_QUICK_REMINDER: "For a quick reminder, you can",
    CERT_REVIEW_THE_LESSON: "review the lesson",
    CERT_BEFORE_YOU_PROCEED: "before you proceed.",
    CERT_NOT_QUITE: "Not quite... please try again.",
    CERT_TIME_REMAINING: "Time remaining: {0} minutes",
    CERT_LOGO: "https://cdn.headversity.com/app/cert/cert_logo_en.png",
    TEAM_LOGO: "https://cdn.headversity.com/app/resources/other/team_en.png",
    DAILY_THREE_DESCRIPTION: "Start with these activities",
    DAILY_THREE_COMPLETE: "Crushed it!",
    DAILY_THREE_COMPLETE_EXTRA:
      "Crushed it! You've completed today's Daily Three{0}.",
    DAILY_THREE_COMPLETE_CTA_2:
      "Keep training your skills, and check back tomorrow for your next plan.",
    DAILY_THREE_TRACK_HEADZONE:
      "Track your <b style='font-weight: 600'>headzone</b>",
    DAILY_THREE_RESILIENCE_RESOURCE:
      "View a <b style='font-weight: 600'>resilience resource</b>",
    DAILY_THREE_COMPLETE_LESSON:
      "Complete a <b style='font-weight: 600'>lesson</b>",
    DAILY_THREE_UNLOCK: "Unlock",
    DAILY_THREE_UNLOCK_SKILL:
      "Start a <b style='font-weight: 600'>new skill</b>",
    DAILY_THREE_ALL_COMPLETE: "You've done it all!",
    DAILY_THREE_COMPLETE_OPTIMISM: "Get optimistic!",
    DAILY_THREE_REDO_SCORE:
      "Complete a <b style='font-weight: 600'>resilience score</b>",
    DAILY_THREE_COMPLETE_A: "Complete a ",
    DAILY_THREE_VIEW_A: "View a ",
    DAILY_THREE_MICRO_LESSON_DESCRIPTION: "Quick learning experiences",
    DAILY_THREE_NANO_PRACTICE_DESCRIPTION: "Simple, proven techniques",
    DAILY_THREE_POST_DESCRIPTION: "Weekly posts on additional topics",
    DAILY_THREE_POST_SHORT_DESCRIPTION: "Weekly posts",

    RESILIENCE_RESOURCE: "Resilience post",
    MICRO_LESSON: "Lesson",
    MICRO_LESSONS: "Lessons",
    MORE_ACTIVITIES: "Other Activities",

    NEXT_UP: "Next up",
    SEE_ALL_SKILLS: "See All Skills",
    SEE_ALL_NANO_PRACTICES: "See All Practices",
    SEE_ALL_LESSONS: "See All Lessons",
    SEE_ALL_TOOLS: "See All Tools",
    SEE_ALL_TRAININGS: "See All $ Trainings",
    OVER_TIME: "over time",
    SEE_PROGRESS_OVER_TIME:
      "You'll see your progress over time after you have scores in this skill on multiple days.",

    UNLOCK_SKILL: "Unlock Skill",
    CLICK_TO: "Click to ",
    TAP_TO: "Tap to ",
    CLICK_TO_UNLOCK: "unlock this skill",
    CLICK_TO_REDO_SCORE: "redo your score",
    CLICK_TO_SEE_DETAILS: "see more details",
    YOUR_ACTIVITY_LEVEL: "Your activity level is",
    OVER_THE_LAST: "over the last 30 days.",
    SEE_MORE_DETAILS: "See more <b>{0}</b> details",

    LOW: "Low",
    MEDIUM: "Medium",
    HIGH: "High",
    UP: "Up",
    DOWN: "Down",
    FLAT: "Flat",
    TODAY: "Today",
    MONTH: "Month",
    YESTERDAY: "Yesterday",
    DAYS_AGO: "days ago",
    MORE_THAN: "More than",
    ON: "on",
    THIS: "this",

    TRAININGS: "Trainings",
    NANO_PRACTICE: "Practice",
    NANO_PRACTICES: "Practices",
    NANO_PRACTICES_ALL: "All Practices",
    NANO_PRACTICES_DESCRIPTION: "Try out all available practices.",
    NANO_PRACTICES_FEATURED_TITLE: "Featured Practices",
    NANO_PRACTICES_FEATURED_DESCRIPTION:
      "Resilience practices handpicked for you.",
    NANO_PRACTICES_HEADER_DESCRIPTION:
      "Simple, proven techniques to build resilience",
    NANO_PRACTICES_MODAL_TIPS_TITLE: "Tips",
    NANO_PRACTICE_FEEDBACK_1: "I found this practice helpful",
    NANO_PRACTICE_FEEDBACK_2: "This practice has improved my perspective",
    NANO_PRACTICE_TRY_DIFFERENT_PROMPTS: "Try different prompts",
    NANO_PRACTICE_AI_NOTICE:
      "This practice uses AI to provide additional options and summarization. As with all data, <i>headversity</i> applies its rigorous levels of privacy and security standards to AI, and no personal information is used. If you have any questions or concerns, please contact us at help@headversity.com.",

    HOW_DOES_IT_WORK: "How it works",
    NICE_WORK: "Nice work",
    NEXT_UP_IN: "Next up in",
    NEXT_PRACTICE: "Next Practice",
    YOUVE_COMPLETED: "You've completed this practice",
    MOST_RECENTLY: "most recently",
    AUTO_PLAY: "Autoplay",

    PROFILE_YOU_HAVE: "You have",
    PROFILE_UNREAD_NOTIFICATION: "unread notification",
    PROFILE_UNREAD_NOTIFICATIONS: "unread notifications",
    PROFILE_VIEW_NOTIFICATIONS: "View notifications",
    PROFILE_CHANGE_LANGUAGE: "Change Language",
    PROFILE_CHANGE_THEME: "Change Theme",
    PROFILE_CHANGE_HIERARCHY: "Change Association",
    PROFILE_UPDATE_NAME: "Update Name",
    PROFILE_ASSOCIATION_SAVED: "Association saved",
    PROFILE_ASSOCIATION: "Association",
    PROFILE_ASSOCIATION_SEARCH: "Search",
    PROFILE_ASSOCIATION_INSTRUCTION:
      "Please select your association(s) from the list below:",
    PROFILE_CHANGE_PASSWORD: "Change Password",
    PROFILE_CHANGE_PASSWORD_EMAIL:
      "We'll email you a link with instructions to change your password.",
    PROFILE_SEND_EMAIL: "Send Email",
    NAME_UPDATED: "Your name has been updated.",

    SKILLS_DESCRIPTION_CORE:
      "The skills that make you who you are and propel you towards your goals.",
    SKILLS_DESCRIPTION_WORK:
      "The skills that help you perform at your best and reach your professional potential.",
    SKILLS_DESCRIPTION_HOME:
      "The skills that empower you to create an optimal living environment.",

    TEAM_PATH_TITLE: "Paths",
    TEAM_PATH_DESCRIPTION:
      "Train together with your Team to get ahead of any challenge",
    TEAM_PATH_STATS_TITLE: "Paths Completed",
    TEAM_TEAM_LESSON_TITLE: "Presentations Completed",
    TEAM_TEAM_LESSON_DESCRIPTION: "Please select a presentation.",
    REACH_TRIAL: " (Trial)",
    REACH_HELP_RESOURCES_PHONE: "Phone",
    REACH_HELP_RESOURCES_TEXT: "Text",
    REACH_HELP_RESOURCES_IOS: "iOS",
    REACH_HELP_RESOURCES_ANDROID: "Android",
    REACH_HELP_RESOURCES_WEB: "Web",
    REACH_CERT_SELECT: "Select Certifications",
    REACH_NODE_SELECT: "Select Nodes",
    REACH_HIDDEN_NODE: "Node (Hidden)",
    REACH_CERT_COMPLETION_STATUS: "Completion Status",
    REACH_CERT_COMPLETION_DATE: "Completion Date",
    REACH_CERT_STATUS_DONE: "Completed",
    REACH_CERT_STATUS_IN_PROGRESS: "In-Progress",
    REACH_CERT_STATUS_NOT_STARTED: "Not Started",
    REACH_CERT_STATUS_NOT_ASSIGNED: "Not Assigned",
    REACH_CERT_NAME: "Certification Name",
    REACH_CERT_STATUS: "Certification Status",
    REACH_HIERARCHY_INVALID_SORT_ORDER: "Invalid sort order",
    REACH_HIERARCHY_NODE_SORT_ORDER: "Sort Order",
    REACH_HIERARCHY_NODE_NOT_SELECTABLE: "Not Selectable",
    REACH_HIERARCHY_UNASSOCIATED: "Unassociated",
    REACH_HIERARCHIES: "Hierarchies",
    REACH_HIERARCHY_DOWNLOAD: "Download Hierarchy",
    REACH_HIERARCHY_SNAPSHOT_RESTORE_SUCCESS: "Snapshot restore successful",
    REACH_RENAME: "Rename",
    REACH_HIERARCHY_NODE_EDIT: "Edit Node",
    REACH_HIERARCHY_NEW: "New Hierarchy",
    REACH_HIERARCHY_DOWNLOAD_CSV_FILE_NAME: "hierarchy",
    REACH_PROMOTE: "Promote",
    REACH_INSIGHTS: "Insights",
    REACH_MY_ORG: "My Org",
    REACH_PROMO_DESCRIPTION:
      "Browse and search through all promotional materials",
    REACH_MORE_ASSETS_HOME: "See all available promotional materials.",
    REACH_TEAM_USER_GUIDE: "TEAM User Guide",
    REACH_SOLO_USER_GUIDE: "SOLO User Guide",
    REACH_GETTING_STARTED: "Getting Started",
    REACH_GETTING_STARTED_TOUR_DESCRIPTION:
      "Quick tutorials on how to best leverage SOLO and TEAM for your organization – hit play to get started!",
    REACH_GETTING_STARTED_DESCRIPTION:
      "Learn more and get started with <em>headversity</em> with comprehensive guides for your purchased products.",
    REACH_WELCOME_TO: "Welcome to ",
    REACH_ADDITIONAL_RESOURCES_GUIDE: "Additional Resources",
    REACH_RECOMMENDED_ASSETS: "Recommended Assets",
    REACH_RECOMMENDED_ASSETS_TOUR_DESCRIPTION:
      "Content hand-picked for you based on your goals and employee usage.",
    REACH_HOME_DESCRIPTION:
      "Explore materials to easily launch and promote <em>headversity</em> within your organization",
    REACH_HOME_INSIGHTS_DESCRIPTION:
      "A brief glimpse into how your employees are interacting with <em>headversity</em>.",
    REACH_HOME_SEE_ALL_INSIGHTS: "See All Insights",
    REACH_SEE_ALL_ASSETS: "See All Assets",
    REACH_POSTER: "Poster",
    REACH_SOCIAL_MEDIA_POST: "Social Media Post",
    REACH_EMAIL_TEMPLATE: "Email Template",
    OTHER: "Other",
    REACH_SUBJECT_LINE: "Subject Line:",
    REACH_SHARE: "Share",
    REACH_SHARE_ON_LINKEDIN: "Share on LinkedIn",
    REACH_SHARE_ON_YAMMER: "Share on Yammer",
    REACH_DOWNLOAD: "Download",
    REACH_SKILLS_ALL_SKILLS: "All Skills",
    REACH_SKILLS_GENERIC: "Generic",
    REACH_LANGUAGES_ALL_LANGUAGES: "All Languages",
    REACH_ASSET_TYPE_ALL_TYPES: "All Asset Types",
    REACH_PRODUCT_TYPE_ALL_TYPES: "All Products",
    REACH_CLEAR_FILTERS: "Clear Filters",
    REACH_CUSTOM_CONTENT: "Custom Content",
    REACH_PROMO_NOT_FOUND:
      "The resource does not exist. Please refresh the page.",
    REACH_NO_ASSETS: "No Assets",
    REACH_NEW: "New",
    REACH_CUSTOM: "Custom",
    REACH_CALENDAR_VIEW: "Calendar",
    REACH_LIST_VIEW: "List",
    REACH_CALENDAR_DESCRIPTION:
      "Plan promotional activities via a monthly calendar",
    REACH_PROFILE_DESCRIPTION: "Review your profile settings",
    REACH_USERS_DESCRIPTION:
      "Manage your organization’s users and their access",
    REACH_ORGANIZATIONS_LOGO: "Logo",
    REACH_ORGANIZATIONS_SECTOR: "Sector",
    REACH_ORGANIZATIONS_PROPERTIES: "Organization Properties",
    REACH_ORGANIZATIONS_CHOOSE_SECTOR: "Choose a Sector",
    REACH_ORGANIZATIONS_NAME_DESCRIPTION:
      "What should we call your organization?",
    REACH_ORGANIZATIONS_SECTOR_DESCRIPTION:
      "We can provide more targeted insights by knowing your specific organization sector.",
    REACH_ORGANIZATIONS_LOGO_DESCRIPTION: `Let's make your organization instantly recognizable to your members. Upload your company logo to enjoy the benefits of co-branding your member welcome experience.<br/><br/>Please upload a logo in either PNG or JPG format with dimensions of 450x310 pixels. If using PNG, ensure it has a transparent background; for JPG, the background should be white.`,
    REACH_ORGANIZATIONS_DESCRIPTION:
      "Manage your organization's information, communications and help resources",
    REACH_GOALS_SELECTION_SCREEN_SAVE: "Save",
    REACH_GOALS_WIN_THEME_PURSUE: "Users will pursue {0} and {1}",
    REACH_GOALS_SELECTION_SCREEN_TITLE: "How Can We Better Support Your Needs?",
    REACH_GOALS_SELECTION_SCREEN_DESCRIPTION:
      "What outcomes are you aiming to achieve with headversity? You have the flexibility to select multiple goals if desired, or you can opt out. Not setting a goal allows users to explore their own learning pursuits independently without any recommended areas of focus aligned with the organization's priorities.",
    REACH_GOALS_INITIAL_SCREEN_TITLE:
      "Unlocking your organization's potential just got simpler!",
    REACH_GOALS_INITIAL_SCREEN_DESCRIPTION:
      "Whether your organization is aiming for increased confidence, reduced stress, deeper connections, or much more, select organizational goals to focus on what's most important.",
    REACH_GOALS_INITIAL_SCREEN_IMAGE:
      "https://cdn.headversity.com/app/interstitial/focuses-en.png",
    REACH_SCORMS_HEADER_TITLE: "SCORM Packages",
    REACH_SCORMS_HEADER_DESCRIPTION: "Download SCORM packages for your LMS",
    REACH_SCORMS_TITLE: "LMS Integrations",
    REACH_SCORMS_SHOW_INSTRUCTIONS: "Show instructions",
    REACH_SCORMS_HIDE_INSTRUCTIONS: "Hide instructions",
    REACH_GOALS_TITLE: "Goals",
    REACH_GOALS_EDIT_GOALS: "Edit Goals",
    REACH_GOALS_SECTION_TITLE: "Organizational Goals",
    REACH_GOALS_SECTION_DESCRIPTION:
      "Fine-tune your organization's training to focus on the areas most needed.",
    REACH_GOALS_DESCRIPTION: "Manage your organization's goals",
    REACH_SCORMS_DESCRIPTION:
      "Each of our CERT programs was designed to be compatible with Sharable Content Object Reference Model (SCORM) standards. This makes it simple to provide CERT in the Learning Management System (LMS) of your choice. Click on the target program to get instructions and file access.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_1:
      "This CERT program is compatible with the Shareable Content Object Reference Model (SCORM) standards, allowing for integration into the Learning Management System (LMS) of your choice, based on the following assumptions:",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_2:
      "Your LMS and your organization infrastructure allows for outward communication. This is generally the case and may be tested by linking to any web address from inside the LMS. If you are currently able to link outside of your LMS and Network, outward communication is permitted.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3:
      "During the process of setting up the SCORM course, you should be provided the opportunity to Upload a File. Select the downloaded file obtained at step 1 to complete setup",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3_A:
      "Your LMS may require you to provide additional details. Complete as you would normally.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3_B:
      "Included code ensures the LMS browser tab remains open in addition to the program access page. The LMS browser tab must remain to facilitate return transfer of completion status.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_4:
      "Here's how to integrate the CERT program in your organization's LMS:",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_5:
      "If you have any questions or concerns, please contact us at ",
    REACH_SCORMS_INSTRUCTIONS_STEP_1:
      "Click on the link above to download a packaged SCORM file. It is not required to unzip this file. The unzipping process will be handled within your LMS in the next few steps.",
    REACH_SCORMS_INSTRUCTIONS_STEP_2:
      "Create a new SCORM course within your LMS in the next few steps.",
    REACH_SCORMS_INSTRUCTIONS_STEP_3: "Assign the SCORM package to your users.",
    REACH_SCORMS_INSTRUCTIONS_STEP_4:
      "Launch the program as a user to test the connection and flow.",
    REACH_HELP_RESOURCE_NO_RESOURCES: "No Resources.",
    REACH_HELP_RESOURCE_INVITE_CODE_DESCRIPTION:
      "<em>headversity</em> isn't just for you—your family members can benefit too! Share the gift of proactive mental health and resilience training with them. Whether it's for work or everyday life, <em>headversity</em> helps everyone think and feel their best. Share this link with your loved ones today!",
    REACH_HELP_RESOURCE_INVITE_CODE_SHARE_LINK: "Copy link to share",
    REACH_HELP_RESOURCE_ADD_NEW: "Add a New Resource",
    REACH_HELP_RESOURCE_DELETE: "Delete Resource",
    REACH_HELP_RESOURCE_MODAL_NAME: "Resource Name",
    REACH_HELP_RESOURCE_MODAL_DESCRIPTION: "Resource Description",
    REACH_HELP_RESOURCE_MODAL_LOGO: "Resource Logo",
    REACH_HELP_RESOURCE_MODAL_PHONE_NUMBER: "Phone Number",
    REACH_HELP_RESOURCE_MODAL_TEXT_NUMBER: "Text Number",
    REACH_HELP_RESOURCE_MODAL_IOS_APP_LINK: "iOS App Link",
    REACH_HELP_RESOURCE_MODAL_ANDROID_APP_LINK: "Android App Link",
    REACH_HELP_RESOURCE_MODAL_WEBSITE_LINK: "Website Link",
    REACH_HELP_RESOURCE_MODAL_SAVE: "Create Help Resource",
    REACH_HELP_RESOURCE: "Help Resource",
    REACH_HELP_RESOURCES_TITLE: "Help Resources",
    REACH_HELP_RESOURCES_GROUP_CONFIGURATION:
      "Configuration for Help Resource Group",
    REACH_HELP_RESOURCES_DESCRIPTION:
      "Support that is always accessible at members' fingertips.",
    REACH_HELP_RESOURCE_DRAG_DROP: `Drag and drop or <u>choose a file</u> to upload your Image`,
    REACH_HELP_RESOURCES_DESKTOP: "Desktop",
    REACH_HELP_RESOURCES_PREVIEW_INSTRUCTIONS:
      "Select a language and a platform to preview the help resources available to your team.",
    REACH_INSIGHTS_BEFORE_CERTIFICATION: "Before Certification",
    REACH_INSIGHTS_AFTER_CERTIFICATION: "After Certification",
    REACH_INSIGHTS_STRONG_AGREEMENT: "Strong agreement",
    REACH_INSIGHTS_NEUTRAL_AGREEMENT: "Neutral",
    REACH_INSIGHTS_LOW_AGREEMENT: "Low agreement",
    REACH_INSIGHTS_NO_DATA_DESCRIPTION:
      "This section is hidden to protect user anonymity. We'll unlock it once we have enough data. Check back later for updates.",
    REACH_INSIGHTS_NO_STRENGTHS_DATA_DESCRIPTION:
      "No questions currently exceed the benchmark",
    REACH_INSIGHTS_NO_IMPROVEMENTS_DATA_DESCRIPTION:
      "No questions currently fall below the benchmark",
    REACH_INSIGHTS_DESCRIPTION:
      "Gain insight into how your employees are interacting with <em>headversity</em> and what steps you can take next",
    REACH_USERS: "Users",
    REACH_USER_BASE: "User Base",
    REACH_VIEW_ALL_USERS: "View All Users",
    REACH_CHOOSE_GOALS: "Choose Goals",
    REACH_ORGANIZATIONS: "Organizations",
    REACH_WELCOME_DESCRIPTION: "Your starter pack",
    REACH_ADDITIONAL_RESOURCES_DESCRIPTION: "Discover all our offerings",
    REACH_FEATURED_ASSETS_DESCRIPTION: "Content hand-picked for you.",
    REACH_TEAM_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/team-user-guide-en.jpg",
    REACH_SOLO_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/solo-user-guide-en.jpg",
    REACH_WELCOME_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/welcome-guide-en.jpg",
    REACH_SUPPORT_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/and-more-en.jpg",
    REACH_WELCOME_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/A10FAB8A-7391-4E02-9C62-73BB1BD9DBAF.pdf",
    REACH_TEAM_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/A703C75C-929A-498A-B430-F1BCF6A3A2C3.pdf",
    REACH_SOLO_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/D10292DC-D801-4E42-8E93-A4345406C173.pdf",
    REACH_SUPPORT_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/F9C4DBE9-4506-4158-B467-71614B1DF42D.pdf",
    REACH_WELCOME_MODAL_FIRST_PARAGRAPH: `REACH offers a suite of administrative tools to simplify admin efforts in supporting, promoting, and reporting the impact of <em>headversity</em> on your organization. As an admin user, REACH gives you the power to foster a thriving, engaged, and resilient organization.`,
    REACH_WELCOME_MODAL_SECOND_PARAGRAPH: `
<p style="align: left;">Here's how to get started:</p>
  <ul style="list-style-type: disc; margin-left: 20px; margin-bottom: 8px; margin-top: 8px;">
    <li>Complete your Quick Start Guide to set up your organization.</li>
    <li>Explore REACH to discover intuitive features and familiarize yourself with the platform.</li>
    <li>Visit the Help Center for additional resources and support.</li>
  </ul>
<p style="align: left; margin-top: 20px;">We're here to support you in making a positive impact within your organization.</p>`,
    REACH_SOCIAL_MEDIA_INSTRUCTION:
      'Share on LinkedIn or Yammer, or click "Copy Text" and "Download" to make it your own.',
    REACH_COPY_PASSWORD_RESET_LINK: "Click here to copy password reset link",
    REACH_COPY_TEXT: "Copy text",
    REACH_USERS_ACTIONS: "Actions",
    REACH_USERS_BULK_UPLOAD: "Bulk Upload Users",
    REACH_USERS_CHANGE_FILE: "Change File",
    REACH_USERS_BULK_FULL_LIST: "All Eligible Users",
    REACH_USERS_BULK_UPLOAD_CONFIRM: "Confirm Bulk Upload",
    REACH_CLICK_OR_DRAG: "Click or drop a csv file here.",
    REACH_USER_ROLE: "Role",
    REACH_INVITE_CODE: "Invite Code",
    REACH_ACTIVATION_EMAIL_SEND_DATE: "Activation Email Send Date",
    REACH_USERS_OPTIONAL: "optional",
    REACH_USERS_EMAIL_SENT_DATE: "Email Sent Date",
    REACH_USERS_ADD_USER_TOOLTIP:
      "If not specified the user will receive an activation email immediately.",
    REACH_USER_DELETE_DESCRIPTION: "Are you sure you want to delete {0}?",
    REACH_USER_DELETE_SUCCESS: "User was successfully deleted.",
    REACH_USER_EDIT_SUCCESS: "User was successfully updated.",
    REACH_USER_ADD_SUCCESS: "User was successfully added.",
    REACH_USER_INVALID_EMAIL_ADDRESS: "Invalid email address",
    REACH_USER_INVALID_URL: "Invalid URL",
    REACH_USER_INVALID_FULL_NAME: "Full name must have at least 2 words",
    REACH_USER_NO_CERT_ASSIGNMENTS_SELECTED:
      "Please select at least one certification to assign.",
    REACH_USER_EMAIL_ALREADY_EXISTS: "Email already exists",
    REACH_USER_CERT_NOT_ASSIGNED: "Cert not assigned to company",
    REACH_USER_SEARCH: "Search for a user",
    REACH_NO_USERS_FOUND: "No users found.",
    REACH_USER: "User",
    REACH_USER_USERS_PER_PAGE: "Users per page:",
    REACH_USERS_ADD_USER: "Add User",
    REACH_USERS_DOWNLOAD_REPORT: "Download Report",
    REACH_USERS_DOWNLOAD_CSV_FILE_NAME: "users",
    REACH_USERS_UPLOAD_INSTRUCTIONS_1:
      "To import users, use the template below to create a CSV file. The template contains a few rows with sample data, so you’ll need to remove these rows when you create your file.",
    REACH_USERS_UPLOAD_INSTRUCTIONS_2:
      "Any additional columns that you specify will be added as additional data for each user.",
    REACH_USERS_DOWNLOAD_TEMPLATE: "Download Template",
    REACH_USERS_UPLOAD_YOUR_FILES: "Upload your files",
    REACH_USERS_UPLOAD_STEP_1_TITLE: "Bulk Import Users",
    REACH_USERS_UPLOAD_STEP_2_TITLE: "Upload a File",
    REACH_USERS_UPLOAD_STEP_3_TITLE: "Assign a CERT",
    REACH_USERS_IMPORT_TYPE: "Import Type",
    REACH_USERS_ASSIGN: "Assign",
    REACH_USERS_WAS_SUCCESSFULLY_ASSIGNED: "was successfully assigned.",
    REACH_USERS_CHOOSE_A_CERT: "Choose a CERT",
    REACH_USERS_ADD_ANOTHER_CERT: "Add another CERT",
    REACH_USERS_SKIP_FOR_NOW: "Skip for now",
    REACH_USERS_FILE_ERRORS: "There are errors in your file:",
    REACH_USERS_CORRECT_CSV:
      "Please open your CSV file and correct these changes to successfully process your user file.",
    REACH_USERS_FILE_READY: "Your user file is ready to be submitted.",
    REACH_USERS_USERS_ADDED_UPDATED: "Users Added/Updated",
    REACH_USERS_USERS_REMOVED: "Users Removed",
    REACH_USERS_NEW_CUSTOM_FIELDS: "New Custom Fields",
    REACH_USERS_CERTS_ASSIGNED: "CERTs Assigned",
    REACH_USERS_CERTS_COMPLETED: "CERTs Completed",
    REACH_USERS_CERTS: "certification(s)",
    REACH_USERS_BACK_TO_UPLOAD_PAGE: "Back to Upload Page",
    REACH_USERS_UPLOAD_STARTED: "File upload has started.",
    REACH_USERS_ELIGIBILITY_JOB_SUCCESS: "Eligibility file job successful.",
    REACH_USERS_ELIGIBILITY_JOB_ERROR:
      "Eligibility file job finished with error(s).",
    REACH_USERS_UPLOADING_WAIT: "Uploading - Please Wait",
    REACH_USERS_PROCESSING_STARTED: "File processing has started.",
    REACH_USERS_UPLOAD_SUCCESS: "File uploaded.",
    REACH_USERS_DRAG_DROP: `Drag and drop or <u>choose a file</u> to upload your Users`,
    REACH_USERS_ACCEPTED_EXTENSIONS: "The .csv file type is supported.",
    REACH_USERS_ELIGIBILITY_JOB: "Job",
    REACH_USERS_TOOLTIP:
      "If you need to add custom fields, please upload a CSV file instead.",
    REACH_USERS_CUSTOM_FIELDS_TEXT_1: "To add custom fields, please",
    REACH_USERS_CUSTOM_FIELDS_TEXT_2: "upload an eligibility file",
    REACH_USERS_CUSTOM_FIELDS_TEXT_3: "instead.",
    REACH_USERS_ELIGIBILITY_CUSTOM_COLUMNS: "<b>Custom Fields: </b>",
    REACH_USERS_FULL_LIST_DELETE_WARNING:
      "Caution! This process will deactivate any user already uploaded that is not in the file.",
    REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_1: "You're about to delete",
    REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_2: "user(s).",
    REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_3:
      "Are you sure you want to continue?",
    REACH_USERS_FULL_LIST_DESCRIPTION:
      "In the CSV file, specify all eligible users and have the system add/update/deactivate as needed.",
    REACH_USERS_CHANGE_FILE_DESCRIPTION: `In the CSV file, specify a set of users to add, update, or deactivate.<br/><br/>
    Adding and updating users:<ul style='list-style:circle;margin-left:1rem;margin-top:5px'>
      <li>To add or update a user, specify the user in the file</li>
      <li>New users will be added and will receive an activation email at the time specified</li>
      <li>Existing users will be updated with any new details</li>
      {0}
      </ul>
      <br/>
      Deactivating users:<ul style='list-style:circle;margin-left:1rem;margin-top:5px'>
      <li>To deactivate a user, specify <code>DELETE</code> in the import action column</li>
      </ul>`,
    REACH_USERS_CHANGE_FILE_HIERARCHY_DESCRIPTION:
      "<li>Specify association in the format ‘2|3|4’ where 2,3,4 are node ids.</li>",
    REACH_USERS_CHANGE_FILE_CERT_DESCRIPTION:
      "<li>Specify any certs to assign to the user</li>",
    REACH_USERS_ELIGIBILITY_USERS_COUNT: "<b>Users to Import: </b>",
    REACH_USERS_DELETE_USER: "Delete User",
    REACH_USERS_RESET_PASSWORD: "Reset password",
    REACH_USERS_RESEND_ACTIVATION_EMAIL: "Resend activation email",
    REACH_USER_ACTIVATION_EMAIL_RESENT: "Activation email resent",
    REACH_USERS_RESEND_ACTIVATION_EMAIL_CONFIRMATION:
      "The user password will be reset and an email will be sent to the user to complete the process. Are you sure?",
    REACH_USERS_RESET_PASSWORD_USER: "Reset password for user",
    REACH_USER_SAVE: "Save User",
    REACH_USERS_CHANGE_EMAIL_CONFIRMATION:
      "You have updated the user's email. Are you sure?",
    REACH_USERS_RESET_PASSWORD_LINK: "Reset password link",
    REACH_USERS_CLEAR_SEARCH: "Clear Search",
    REACH_USERS_RESET_FILTERS: "Reset Filters",
    REACH_USERS_INVITE_USERS: "Invite Users",
    REACH_USERS_SHARING_IS_CARING: "Sharing is Caring!",
    REACH_USERS_SHARING_IS_CARING_DESCRIPTION:
      "Copy and paste the following information into your desired communication platform to let your employees and their families know <em>headversity</em> is available for them to use.",
    REACH_USERS_SHARING_INVITE_CODE_MESSAGE:
      "We would like to introduce you to <em>headversity</em> – a proactive mental health and resilience training solution you can use in the flow of work and life so you can think and feel your best.<br/><br/>Use code {0} to join <em>headversity</em> and embark on a personalized wellbeing journey.<br/><br/>Sign up here {1}",
    REACH_USERS_SHARING_FAMILY_INVITE_CODE_MESSAGE:
      "<em>headversity</em> is also available for your family members so they can think and feel their best too! This means access to proactive mental health and resilience training in the flow of work and life.<br/><br/>Share code {0} to have them join <em>headversity</em> and embark on a personalized wellbeing journey.<br/><br/>Share this link with them {1}",
    REACH_USERS_SHOW_DETAILS: "Show Details",
    REACH_USERS_HIDE_DETAILS: "Hide Details",
    REACH_QUICK_START_PROGRESS: "Progress",
    REACH_QUICK_START_DEFINE_ORGANIZATION_TITLE:
      "Define Your Main Organization",
    REACH_QUICK_START_DEFINE_ORGANIZATION_DESCRIPTION:
      "Customize your organization's profile in <em>headversity</em> to create a tailored experience.",
    REACH_QUICK_START_DEFINE_ORGANIZATION_BUTTON: "Set Up Your Organization",
    REACH_QUICK_START_SHOWCASE_LOGO_TITLE: "Showcase Your Logo",
    REACH_QUICK_START_SHOWCASE_LOGO_DESCRIPTION:
      "Foster a sense of familiarity and engagement among users with your organization's branding.",
    REACH_QUICK_START_SHOWCASE_LOGO_BUTTON: "Add a Logo",
    REACH_QUICK_START_DEFINE_HELP_RESOURCES_TITLE: "Define Your Help Resources",
    REACH_QUICK_START_DEFINE_HELP_RESOURCES_DESCRIPTION:
      "Ensure continuous support for your members with easily accessible Help Resources in the app. Tailor these resources to include vital services like Employee and Family Assistance Programs (EFAP), providing comprehensive aid whenever needed.",
    REACH_QUICK_START_DEFINE_HELP_RESOURCES_BUTTON: "Set Up Resources",
    REACH_QUICK_START_CONFIRM_COMMS_TITLE:
      "Confirm Your Communication Settings",
    REACH_QUICK_START_CONFIRM_COMMS_DESCRIPTION:
      "Optimize your connection with <em>headversity</em> by configuring your Allow List settings, ensuring your team never misses out on crucial emails, news, and updates.",
    REACH_QUICK_START_ADD_USERS_TITLE: "Add or Upload Your First Users",
    REACH_QUICK_START_ADD_USERS_DESCRIPTION:
      "Start empowering your team by inviting individuals or importing a list of eligible users to give them access to the <em>headversity</em> experiences.",
    REACH_QUICK_START_ADD_USERS_BUTTON: "Add Users",
    REACH_QUICK_START_CHOOSE_GOALS_TITLE: "Choose Organizational Goals",
    REACH_QUICK_START_CHOOSE_GOALS_DESCRIPTION:
      "Customize your organizational goals in headversity to create a tailored experience.",
    REACH_QUICK_START_CHOOSE_GOALS_BUTTON: "Choose Goals",
    REACH_VISIT_HELP_CENTER_TEXT: "Visit the Help Center",
    REACH_QUICK_HELP_CENTER_DESCRIPTION:
      "Tips, helpful advice, and the answers to all of your questions are available 24/7 in the Help Center.",
    REACH_MY_ORGANIZATION: "My Organization",
    REACH_MANAGE_INTEGRATIONS: "Manage Integrations",
    REACH_MANAGE_SETTINGS: "Manage Settings",
    REACH_USERS_CERTIFICATIONS: "Certifications",
    REACH_USERS_CREATED: "Created",
    REACH_USERS_ACTIVATION_DATE: "Activation Date",
    REACH_USERS_EDIT: "Edit",
    REACH_USERS_CERTIFICATION: "Certification",
    REACH_USERS_DATE_ASSIGNED: "Date Assigned",
    REACH_USERS_DATE_STARTED: "Date Started",
    REACH_USERS_DATE_COMPLETED: "Date Completed",
    REACH_USERS_UN_ASSIGN: "Remove",
    REACH_USERS_ASSIGN_A_CERTIFICATION: "Assign a Certification",
    REACH_USERS_CERTIFICATION_ASSIGNMENTS: "Certification Assignments",
    REACH_USERS_UN_ASSIGN_CERTIFICATION: "Remove a Certification",
    REACH_USERS_UN_ASSIGN_CERT_CONFIRMATION_MESSAGE:
      "Are you sure you want to remove this certification?",
    REACH_USERS_UN_ASSIGN_CERT_SUCCESS: "Certification removed successfully.",
    REACH_CONTINUE_YOUR_QUICK_START_GUIDE: "Continue Your Quick Start Guide",
    REACH_ORGANIZATIONS_LMS_INTEGRATIONS: "LMS Integrations",
    REACH_ORGANIZATIONS_LMS_INTEGRATION: "LMS Integration",
    REACH_ORGANIZATIONS_LMS_DOWNLOAD_INSTRUCTION:
      "Click here to download the SCORM package",
    REACH_ORGANIZATIONS_COMMUNICATION_SETTINGS: "Communication Settings",
    REACH_ORGANIZATIONS_COMMUNICATION_SETTINGS_DESCRIPTION:
      "Tailor your communication preferences for a seamless and informed <em>headversity</em> experience.",
    REACH_ORGANIZATIONS_CONFIRMED_ALLOWED:
      "You’ve confirmed that the requested information is allowed",
    REACH_ORGANIZATIONS_NOT_CONFIRMED_ALLOWED:
      "You haven’t confirmed the requested information yet",
    REACH_ORGANIZATIONS_ALLOW_LIST_REQUEST: "Allow List Request",
    REACH_ORGANIZATIONS_ALLOW_MESSAGE:
      "The Allow List Configuration ensures that you receive important updates from <em>headversity</em>. It allows users to get emails with news, videos, and updates. Please copy and share the information below with your IT administrators.",
    REACH_ORGANIZATIONS_SENDER_EMAILS: "Sender Emails",
    REACH_ORGANIZATIONS_DOMAINS: "Domains",
    REACH_ORGANIZATIONS_QUESTIONS:
      "If you have any questions or need support, please contact our support team at <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    REACH_ORGANIZATIONS_COPY_TO_CLIPBOARD: "Copy this information to clipboard",
    REACH_ORGANIZATIONS_INFORMATION_ALLOWED:
      "The listed information has been allowed",
    REACH_ORGANIZATIONS_HELP_RESOURCES_ADDED: "Help Resources Added",
    REACH_ORGANIZATIONS_GOALS_CHOSEN: "Goals Chosen",
    REACH_ORGANIZATIONS_MANAGE_HELP_RESOURCES: "Manage Resources",
    SET_NUMBER_OF_GROUPS: "Set number of groups",
    START_TEAM_LESSON: "Start Presentation",
    SUGGESTED_REFRESHERS: "Suggested Refreshers",
    COMPLETE_REFRESHERS_FROM_THE_LIST_BELOW:
      "Complete refreshers from the list below.",
    MORE_NANO_PRACTICES: "More practices",
    EARN_POINTS_FOR_HEADER_TEXT: "Earn {0} points for each of these ",
    POINTS: "Points",
    EARN_POINTS_COMPLETE: "you complete.",
    CONGRATS_ON_STAYING_QUALIFIED: "Congrats on Staying Qualified!",
    GREAT_JOB_STAYING_QUALIFIED:
      "Great job! We’ll be back in touch next period with more activities to continue staying qualified.",
    PERIOD_BEGINS: "Period Begins",
    PERIOD_ENDS: "Period Ends",
    POINT_REMAINING: "{0} point remaining",
    POINTS_REMAINING: "{0} points remaining",
    STAY_QUALIFIED: "Stay Qualified",
    YOU_ARE_QUALIFIED: "You're Qualified!",
    COMPLETE_REFRESHERS_MAINTAIN_CERTIFICATION:
      "Complete refreshers and maintain your certification",
    MORE_MICROS_LESSONS: "More lessons",
    TEAM_CODE: "Team ID",
    TEAM_SCAN_CODE: "Scan the QR code to participate",
    TEAM_JOIN_BROWSER: "Join in your browser",
    PARTICIPANT_LIST: "Participant List",
    IS_NOW_PRESENTING: "is training together now!",
    YOU_ARE_PRESENTING: "You are presenting with",
    TEAM_MULTIPLE_PRESENTING: "Multiple teams are presenting",
    JOIN_TEAM_LESSON: "Join Presentation",
    JOIN_EXISTING_TEAM: "Join an Existing Team",
    TEAM_SWITCH_TEAMS: "Change Selected Team",
    TEAM_CREATE_A_TEAM: "Create a New Team",
    TEAM_CURRENT_TEAM: "Selected Team",
    TEAM_NOT_YET_PRESENTED: "Not yet presented",
    TEAM_LAST_PRESENTED: "Last presented",
    TEAM_TEAM_LESSON_INFO: "View Presentation Info",
    TEAM_TEAM_LESSON_QR: "Share Team Info",
    TEAM_LESSON: "Presentation",
    TEAM_LESSONS: "Presentations",
    MINUTE: "Minute",
    MINUTES: "Minutes",
    TEAM_SWITCH_TEAM_VIEW: "Select Team",
    TEAM_CURRENTLY_PART_OF_TEAM: "You're currently using TEAM as part of",
    TEAM_USE_THE_DROPDOWN:
      "Use the dropdown below to select another Team to participate with",
    TEAM_TEAM_NAME: "Team Name",
    TEAM_CHOOSE_A_TEAM: "Choose a Team",
    MEMBERS: "Members",
    MEMBER: "Member",
    ARIA_LABEL_EDIT_TEAM_NAME: "Edit-team-name",
    TEAM_NOT_YET_MET: "Not yet met",
    TEAM_LAST_MET: "Last met on:",
    TEAM_DELETE_TEAM: "Delete Team",
    TEAM_ADD_MEMBER: "Add Member",
    CONTINUE_WHERE_YOU_LEFT_OFF: "Continue Where You Left Off",
    JOIN_TEAM_LESSON_IN_PROGRESS: "Join Presentation In Progress",
    TEAM_PROGRESS: "Team Progress",
    TEAM_CONFIRM_DELETE: "Are you sure you want to delete {0}?",
    TEAM_USER_CONFIRM_DELETE: "Are you sure you want to remove {0} from {1}?",
    CANCEL: "Cancel",
    TEAM_OR_SCAN_QR_CODE: "Or Scan The QR Code",
    TEAM_MANUALLY_ENTER: "Manually Add Teammate",
    TEAM_ENTER_BY_QR: "Enter by sharing QR",
    TEAM_CREATED_SUCCESS: "Team was successfully created.",
    TEAM_NAME_UPDATED_SUCCESS: "Team name was successfully updated.",
    TEAM_EDIT_CURRENT_TEAM_NAME: "Current team name",
    TEAM_CURRENT_TEAM_NAME: "You’re currently using TEAM as part of",
    TEAM_CHANGE_TEAM_BODY1: "Selected Team:",
    TEAM_CHANGE_TEAM_BODY2:
      "Not the Team you’re training with today? Use the dropdown below to select a different team to participate with.",
    TEAM_NEW_TEAM_NAME: "New Team Name",
    TEAM_EDIT_TEAM_NAME: "Edit Team Name",
    UPDATE_BUTTON_TEXT: "Update",
    TEAM_CREATE_NEW_TEAM_DESCRIPTION:
      "First give your team a name, then invite your colleagues to join you!",
    TEAM_MANAGEMENT_TITLE: "Teams",
    TEAM_ADD_MEMBER_INSTRUCTION:
      "Enter the email address of the person you'd like to add, and we'll send an email inviting them to join.",
    TEAM_ADD_MEMBER_SUCCESS: "Invitation was successfully sent",
    TEAM_RESEND_ACTIVATION_SUCCESS: "Activation email was successfully resent",
    TEAM_MANAGEMENT_DESCRIPTION: "Manage your teams and create new experiences",
    TEAM_JOIN_SUCCESS_DESCRIPTION: "Successfully joined the team!",
    TEAM_DELETE_SUCCESS_TITLE: "Team was successfully deleted.",
    TEAM_DELETE_USER: "Remove User",
    TEAM_USER_DELETE_SUCCESS_TITLE: "Team user was successfully removed.",
    TEAM_JOIN_ALREADY_JOINED_DESCRIPTION:
      "You are already a member of this team.",
    TEAM_ADD_MEMBER_ALREADY_EXISTS_DESCRIPTION:
      "The email provided is already linked to a member of this team.",
    TEAM_ERROR_TEAM_CODE_DOES_NOT_EXIST:
      "The Team ID you entered does not exist. Please try again.",
    TEAM_ERROR_TEAM_CODE_IS_ON_WRONG_COMPANY:
      "The Team ID you entered is not usable at your company. Please contact support if the problem persists.",
    TEAM_ERROR_TEAM_IS_ON_WRONG_COMPANY:
      "The team you entered is not usable at your company. Please contact support if the problem persists.",
    TEAM_ERROR_USER_DOES_NOT_BELONG_TO_TEAM:
      "The user you entered does not belong to the team you entered. Please contact support if the problem persists.",
    EMAIL_ADDRESS: "Email Address",
    FULL_NAME: "Full Name",
    TEAM_MENU_PATHS: "Paths",
    TEAM_MENU_TEAMS: "Teams",
    TEAM_MENU_INSIGHTS: "Insights",
    TEAM_POLL_TIME: "It’s Poll Time!",
    TEAM_BACK_TO_PREVIEW: "Back to Preview",
    TEAM_END_PREVIEW: "End Preview",
    TEAM_PARTICIPATE_END: "Leave Presentation?",
    TEAM_PARTICIPATE_END_MESSAGE:
      "Are you sure you want to leave the presentation?",
    TEAM_TEAM_LESSON_END: "End Presentation?",
    TEAM_END_TEAM_LESSON: "End Presentation",
    TEAM_TEAM_LESSON_END_MESSAGE:
      "Are you sure you want to end the presentation?",
    TEAM_TEAM_GROUPING_ONLY_ONE_USER:
      "Group {1} only has one user. Are you sure you want to continue?",
    TEAM_TEAM_LESSON_INCOMPLETE_END_BLOCK_ONE:
      "All polls have <strong>not</strong> been completed.",
    SPORT_TEAM_LESSON_INCOMPLETE_END_BLOCK_ONE:
      "All activities have <strong>not</strong> been completed.",
    TEAM_TEAM_LESSON_INCOMPLETE_CONTINUE: "Continue Presentation",
    TEAM_TEAM_LESSON_INCOMPLETE_RESUME_LATER: "End and Resume Later",
    TEAM_TEAM_LESSON_INCOMPLETE_END_NOW: "This training is complete",
    TEAM_BACK_TO_POLLS: "Show Polls",
    TEAM_BACK_TO_LOBBY: "Show Lobby",
    TEAM_GREAT_WORK: "Great Work!",
    TEAM_FEEDBACK_DESCRIPTION:
      "Now that’s some good team work. You’re ready to take what you learned and put it into practice!",
    TEAM_NAILED_IT: "Nailed It!",
    TEAM_PARTICIPANT_END_TITLE: "Great Job!",
    TEAM_PRESENTER_END_MESSAGE:
      "There’s plenty more ahead for you and your Team, or you can take some time and focus on yourself!",
    TEAM_PARTICIPANT_END_MESSAGE:
      "You’ve completed this Presentation, and you and your Team are ready to continue on to the next Presentation in this Path!",
    TEAM_INSIGHT_TITLE: "Insights",
    TEAM_INSIGHT_DESCRIPTION:
      "A snapshot of your teams' engagement and progress",
    TEAM_HOME_TITLE: "TEAM",
    TEAM_HOME_HEADER_TITLE: "Welcome to TEAM",
    TEAM_HOME_DESCRIPTION: "We get further when we work together",
    TEAM_HOME_NEXT_UP: "Next Up for ",
    TEAM_HOME_UP_NEXT: "Up Next",
    TEAM_HOME_NEXT_UP_PATH:
      "Pick up where you left off in the <strong>{0}</strong> path!",
    TEAM_HOME_PATHS_COMPLETE: "All paths complete!",
    TEAM_HOME_PATH_START_PATH: "Start Path",
    TEAM_HOME_PATH_CONTINUE: "Continue Path",
    TEAM_HOME_PATH_PROGRESS: "View Progress",
    TEAM_HOME_PAGE_NEXT_UP_FOR_YOU: "While You Were Away",
    TEAM_HOME_PAGE_NEXT_UP_FOR_YOU_DESCRIPTION_1:
      "Looks like you missed a training! Catch-Up",
    TEAM_HOME_PAGE_NEXT_UP_FOR_YOU_DESCRIPTION_2: "now before the next one.",
    TEAM_HOME_PAGE_NEXT_UP_COMPLETE_DESCRIPTION_1:
      "If you miss any trainings, we'll prompt you to Catch-Up",
    TEAM_HOME_PAGE_NEXT_UP_COMPLETE_DESCRIPTION_2: "here.",
    TEAM_GROUPING_REPORTER: "Reporter",
    TEAM_TEAM_GROUPING_CATCH_UP_END: "End Grouping?",
    TEAM_TEAM_LESSON_CATCH_UP_END: "End Catch-Up?",
    TEAM_TEAM_LESSON_CATCH_UP_END_MESSAGE:
      "Are you sure you want to end the Catch-Up session?",
    TEAM_BUTTON_PRESENT: "Present",
    TEAM_BUTTON_PREVIEW: "Preview",
    TEAM_BUTTON_IN_PROGRESS: "Join",
    TEAM_BUTTON_IN_CATCH_UP: "Catch-Up",
    TEAM_BUTTON_START_OVER: "Start over",
    TEAM_BUTTON_EXPIRE_TEAM_LESSON: "In progress for more than 15 minutes",
    TEAM_PRESENT_CONFIRM_PRESENT_AGAIN:
      "This presentation was completed on {0}. Do you want to present again?",
    TEAM_PRESENT_CONFIRM_PRESENT_AGAIN_HEADER_TITLE: "Present Again",
    TEAM_BUTTON_PARTICIPATE: "Participate",
    TEAM_PRESENT_OR_PARTICIPATE: "Already Presenting?",
    TEAM_PRESENT_OR_PARTICIPATE_TEXT:
      "It looks like you may be presenting with your team in another session. Would you like to join as a participant, or take over as presenter?",
    TEAM_VOTES: "Responses",
    TEAM_VOTES_SEE_RESULT: "You need to respond to see the result",
    TEAM_NO_TEAM_DESCRIPTION: "Join or create a new team to get started!",
    TEAM_JOIN_NEW_TEAM: "Join Team",
    TEAM_JOIN_THIS_TEAM: "Need to join this team?",
    TEAM_JOIN_ANOTHER_TEAM: "Join a Different Team",
    TEAM_LEAVE_CURRENT_TEAM: "Leave team",
    TEAM_ENTER_NEW_CODE: "Enter Team ID",
    TEAM_LEAVE_DESCRIPTION: "Are you sure you want to leave this team?",
    TEAM_JOIN_NEW_TEAM_DESCRIPTION:
      "Enter the Team ID that was shared with you, or",
    TEAM_CREATE_NEW_TEAM: "create a new team",
    TEAM_JOIN_PICK_TEAM_TO_JOIN: "Please pick a Team to join",
    APP_GRID_SOLO: "SOLO",
    APP_GRID_TEAM: "TEAM",
    APP_GRID_REACH: "REACH",
    APP_GRID_SPORT: "SPORT",
    TEAM_TEAM_LESSON_CANCEL_TITLE: "Cancel Presentation",
    TEAM_TEAM_LESSON_CANCEL_DESCRIPTION:
      "Are you sure you want to cancel this presentation?",
    TEAM_TEAM_LESSON_CANCEL_MESSAGE: "This presentation was cancelled.",
    TEAM_GET_STARTED: "Here’s How to Get Started!",
    TEAM_GET_STARTED_DESCRIPTION:
      "Welcome to TEAM! If a Team ID has been shared with you, <strong>join that team now</strong>. If not, <strong>create a new team</strong> to start presenting with your colleagues.",
    TEAM_TEAM_LESSON_MIN: "min",
    TEAM_TEAM_LESSON_MINS: "mins",
    TEAM_TEAM_LESSON_INDICATOR_TRAINING_INCLUDED:
      "Take-Away Individual Training",
    TEAM_TEAM_LESSON_INDICATOR_GROUP_ACTIVITY_INCLUDED: "Group Activity",
    TEAM_TEAM_LESSON_INDICATOR_INCLUDES_SCRIPT:
      "This presentation includes a script",
    TEAM_TEAM_LESSON_DOWNLOAD_TITLE: "Download for Offline Use",
    TEAM_TEAM_LESSONS_COMPLETE: "Presentation Complete",
    TEAM_TEAM_LESSONS_COMPLETED: "Presentations Completed",
    TEAM_TOTAL_TEAM_LESSONS: "Total Presentations",
    TEAM_POLL: "Poll",
    TEAM_POLLS: "Polls",
    TEAM_NO_POLLS: "No Polls Completed",
    TEAM_PARTICIPANT: "Participant",
    TEAM_PARTICIPANTS: "Participants",
    TEAM_TEAM_LESSON_PREVIEW_TITLE: "This is only a preview.",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_TITLE: "Just a Second!",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_DESCRIPTION_BLOCK_ONE:
      "We strongly recommend presenting the trainings in the order they appear to get the most out of your  experience. But if you’d like to proceed in your order, that’s okay!",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_DESCRIPTION_BLOCK_TWO:
      "However, we know that sometimes time is a factor, and if you want to skip some for now and make progress further ahead, that’s ok!",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_UNDERSTAND: "I Understand",
    TEAM_POLL_COMPLETE: "Poll Completed",
    TEAM_POLLS_COMPLETED: "Polls Completed",
    TEAM_VIEW_POLL_RESULT: "View Poll Results",
    TEAM_YOUR_ID: "Your Team ID is:",
    JOINED: "Joined:",
    TEAM_WAITING_JOIN_MESSAGE:
      "Once everyone has joined and you're ready to present, click <strong>Start Presentation</strong>.",
    TEAM_WAITING_JOIN_GROUP_MESSAGE:
      "Once everyone has joined and you're ready to start grouping, click <strong>Start Grouping</strong>.",
    TEAM_WAITING_TEAM_MATE: "Waiting for Teammates to Join...",
    TEAM_CHARTER: "Team Charter",
    TEAM_CHARTER_DISCUSSION_IN_PROGRESS: "Discussion in Progress",
    TEAM_CATCH_UP_INFO_TEXT:
      "If your team completes a presentation without you, that's ok! You can still participate in this lesson and all the activities and polls that come with it now. To “Catch-Up” on any missed presentations, click on the presentation to get started.",
    TEAM_CATCHUP_HOMEPAGE_TOOLTIP_TEXT:
      "You can “catch up” by reviewing the presentation and completing the activities that go with it by yourself.",
    SELECT: "Select",
    TEAM_SELECT_GROUP_NUMBER: "Select the number of groups",
    TEAM_TEAM_LESSON_IN_PROGRESS: "Presentation In Progress...",
    TEAM_TEAM_LESSON_IN_PROGRESS_SUB_HEADING: "{0} is presenting…",
    ALL_SET: "All set!",
    TEAM_TEAM_LESSON_WAITING_TO_START: "Waiting for {0} to begin presenting...",
    TEAM_POLL_RESULT: "Poll Results",
    TEAM_LESSON_MINUTES_SPEND: "Minutes Spent in Lessons",
    TEAM_POLL_ENGAGEMENT: "Poll Engagement",
    TEAM_TEAMMATES_IN_ONE_SESSION: "Most Teammates in One Session",
    TEAM_COMPLETE_MORE_THAN_ONE:
      "Team Members that have completed one lesson or more",
    TEAM_TOTAL_LESSON_PARTICIPATION:
      "Total number of completed lessons across teammates since the beginning",
    TEAM_USER_INTERACTION: "User Interaction",
    TEAM_LESSON_SUCCESS_RELEVANT: "Did you find this TEAM training relevant?",
    TEAM_LESSON_SUCCESS_POSITIVELY:
      "Did the content of the training inspire you to make positive changes in your life?",
    TEAM_WAITING_FOR_VOTES:
      "Waiting for responses... Use your device to answer this poll!",
    TEAM_TRAINING: "TEAM training",
    TEAM_TRAINING_DESCRIPTION: "Interactive group presentations",
    TEAM_READY_TO_PRESENT: "Ready to present?",
    TEAM_WHEN_YOU_START: "When you start presenting, we’ll notify",
    TEAM_OF_THE: "of the",
    TEAM_TO_JOIN: "team to join you",
    TEAM_MORE_TO_EXPLORE: "More To Explore",
    TEAM_MORE_TO_EXPLORE_DESCRIPTION: "Check out these other presentations.",

    CORE: "Core",
    WORK: "Work",
    HOME: "Home",
    ENTER_YOUR_RESPONSE: "Enter your response",
    ENTER_YOUR_RESPONSE_ONE_AT_A_TIME: "Enter your response (one at a time)",
    ENTER_YOUR_RESPONSE_OPTIONAL: "Enter your response (optional)",
    LEAVE_MODAL_ARE_YOU_SURE: "Are you sure?",
    LOGIN_PAGE_TITLE: "Get Ahead of",
    LOGIN_PAGE_TITLE_ADVERSITY: "Adversity.",
    LOGIN_PAGE_FIRST_STEP_INSTRUCTION_HAVE: "Already have an account?",
    LOGIN_PAGE_FIRST_STEP_INSTRUCTION_DONT: "Don’t have an account?",
    LOGIN_PAGE_SIGN_IN: "Sign In",
    LOGIN_PAGE_SIGN_IN_WITH_EMAIL: "Sign In with magic link",
    LOGIN_PAGE_INVITE_CODE_HAVE: "I have an Invite Code",
    LOGIN_PAGE_INVITE_CODE_DONT: "I don’t have an Invite Code",
    LOGIN_PAGE_SIGN_UP: "Sign Up",
    LOGIN_PAGE_SEND_EMAIL: "Send Email",
    LOGIN_WELCOME_EMAIL: "Did you receive a welcome email?",
    LOGIN_WELCOME_DESCRIPTION:
      "Check your work account for an email from <em>headversity</em> with access instructions, or enter your work email address below and we’ll try sending it again. If you still don’t receive the email, please contact your <em>headversity</em> administrator or email us at <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    LOGIN_VALIDATION_DESCRIPTION:
      "Check your email account for an email from <em>headversity</em> with access instructions. If you don’t receive the email, please contact your <em>headversity</em> administrator or email us at <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    LOGIN_VALIDATION_PAGE_TITLE: "Re-send Activation Email",
    LOGIN_VALIDATION_PAGE_DESCRIPTION:
      "Enter your email address below and we’ll re-send an activation email if we have the email on file.",
    LOGIN_FORGOT_PASSWORD: "Forgot your password?",
    LOGIN_SAVE_PASSWORD: "Change my Password",
    LOGIN_SET_PASSWORD: "Please choose a new password.",
    LOGIN_GENERIC_ERROR:
      "There was an error trying to log you in. Please try again later.",
    LOGIN_REDIRECT_MOBILE:
      "Redirecting you to the login screen, please click the login button if you are still seeing this",
    LOGIN_FORGOT_PASSWORD_DESCRIPTION:
      "Enter your email address below and we’ll send you an email with instructions on how to reset your password.",
    SINGLE_SIGN_ON_ENABLED: "Single Sign-On enabled",
    EMAIL_IS_MANDATORY: "Email is required",
    FULL_NAME_IS_MANDATORY: "Full Name is required",
    FULL_NAME_NEEDS_CONTAIN_FIRST_LAST_NAME:
      "Full Name must contain both first and last name",
    PASSWORD_IS_MANDATORY: "Password is required",
    INVITE_CODE_IS_MANDATORY: "Invite Code is required",
    EMAIL_IS_INVALID: "The email you entered is invalid. Please try again.",
    EMAIL_HAS_PROBLEM:
      "There is a problem with the email you entered. Please contact <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    EMAIL_DOMAIN_NOT_ALLOWED:
      "The email you have entered is not valid for this invite code. Please contact your headversity admin or email us at <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    EMAIL_IS_USED_ALREADY:
      "The email you entered is already in use. Please try again.",
    PASSWORD_NEEDS_TO_RESPECT_FORMAT:
      "Password must contain an uppercase letter, a lowercase letter, a number, and be at least 8 characters in length. Please try again.",
    PASSWORD_LENGTH_REQUIREMENT: "At least 8 characters long",
    PASSWORD_UPPERCASE_REQUIREMENT: "At least one upper case letter",
    PASSWORD_LOWERCASE_REQUIREMENT: "At least one lower case letter",
    PASSWORD_NUMBER_REQUIREMENT: "At least one number",
    PASSWORD_MUST_MATCH: "Passwords must match",
    PASSWORD_REQUIREMENTS: "Password requirements:",
    INVITE_CODE_IS_INVALID:
      "The Invite Code you entered is invalid. Please try again.",
    EMAIL_CAN_ONLY_BE_USED_AS_SSO:
      "This email address can only be used via single sign-on. Please try <a style='text-decoration: underline;' href='/login'>logging in</a> instead.",
    DONT_HAVE_INVITE_CODE: "Don't have an invite code?",
    CONTACT_ADMINISTRATOR: "Please contact your administrator",
    BACK_TO_LOGIN_SIGNUP: "Back to Log In / Sign Up",
    LOGIN: "Log In",
    SIGNUP: "Sign Up",
    PASSWORD_MISMATCH: "Password Mismatch",
    SIGNUP_ERROR:
      "Sorry, an error has occurred. Please contact help@headversity.com with these error details: ",
    PLEASE_CHOOSE_NEW_PASSWORD_AND_INVITE_CODE:
      "Please choose a new password for your <em>headversity</em> account, and enter your invite code.",
    PLEASE_ENTER_INVITE_CODE: "Please enter your invite code.",
    PLEASE_CHOOSE_PASSWORD:
      "Please choose a new password for your <em>headversity</em> account.",
    YOUR_INVITE_CODE: "your invite code",
    EMAIL_PLACEHOLDER: "email@site.com",
    YOUR_NEW_PASSWORD: "your new password",
    YOUR_PASSWORD: "your password",
    YOUR_FULL_NAME: "your full name",
    VERIFY_YOUR_PASSWORD: "verify your new password",
    REGISTER: "REGISTER",
    LOG_OUT: "LOG OUT",
    INVALID_INVITE_CODE: "You invite code is invalid!",
    PRIVACY_POLICY: "By signing up you agree to our ",
    DROPDOWN_PLACEHOLDER: "Please choose a response",
    ROI_LETS_PERSONALIZE: "Let’s personalize your journey!",
    ROI_TO_PROVIDE:
      "To provide you with the best resilience tool tailored to your needs and goals, we'll need to ask you a few questions. You do not need to have any previous knowledge of mental health to answer these questions.",
    ROI_THESE_ARE:
      "These are used to optimize your <em>headversity</em> experience.",
    ROI_ALL_ANSWERS: "All your answers are ",
    ROI_COMPLETELY_CONFIDENTIAL: "completely confidential ",
    ROI_AND_WILL: "and will ",
    ROI_NOT_BE_SHARED: "not be shared ",
    ROI_WITH_YOUR_EMPLOYER: "with your employer at any time.",
    ROI_ESTIMATED_TIME: "Estimated Time: ",
    ROI_OVER_PAST_TWO_WEEKS: "Over the past two weeks...",
    ROI_WORK_MISSED_DAYS:
      "How many days of work did you miss because of illness, injury, or mental health (stress, depression, anxiety)?",
    ROI_SKIP_QUESTION: "Skip this question",
    ROI_WORK_SCHEDULED_DAYS: "How many days were you scheduled to work?",
    ROI_PERFORMANCE_AFFECTED:
      "On a scale of 0 - 10, how much did your physical and mental health negatively affect your performance <strong>while at work</strong>?",
    ROI_NOT_AFFECTED: "Not at all affected",
    ROI_EXTREMELY_AFFECTED: "Extremely affected",
    ROI_TIME_TO_CHECKIN: "Time to check in!",
    ROI_ITS_BEEN: "It's been ",
    ROI_SINCE_SIGNUP: "days since you signed up for <em>headversity</em>.",
    ROI_ANY_CHANGES:
      "We'll look at any changes in your situation, so we can optimize your <em>headversity</em> experience.",
    HEADER_WELCOME: "Welcome",
    PAGE_NOT_FOUND_TITLE: "Not found",
    RESET_PASSWORD_EMAIL_SENT:
      "Check your email! Your password reset instructions have been sent.",
    VALIDATION_EMAIL_SENT:
      "Check your email account! Your activation email has been sent.",
    INVALID_OR_ALREADY_USED_CODE:
      "Whoops, that’s an expired link. If you still need to reset your password, you can request a new reset email <a style='text-decoration: underline;' href='/forgotPassword'>here</a>.",
    INVALID_OR_ALREADY_USED_VALIDATION_CODE:
      "Whoops, that’s an expired link. You can request a new activation email <a style='text-decoration: underline;' href='/resend-activation'>here</a>.",
    INVALID_OR_ALREADY_USED_VALIDATION_LOGIN_CODE:
      "Whoops, that’s an expired link. Click <a style='text-decoration: underline;' href='/login'>here</a> to go back to the login screen.",
    INVALID_SCORM_LOGIN_CODE:
      "Whoops, there is a mismatch between your LMS profile and your <i>headversity</i> account. Please contact help@headversity.com for assistance.",
    INVALID_GENERIC_LOGIN_CODE:
      "Whoops, that’s an expired link. Please return to your LMS and request a new login link.",
    LOGIN_ERROR_INVALID_REQUEST_BODY:
      "Invalid request body. All and only of client_id, credential_type, username, otp, realm are required.",
    LOGIN_ERROR_CROSS_ORIGIN_NOT_ALLOWED: "Cross origin login not allowed.",
    LOGIN_ERROR_UNKNOWN_CREDENTIAL_TYPE_PARAMETER:
      "Unknown credential type parameter.",
    LOGIN_ERROR_UNKNOWN_REALM: "Unknown realm non-existent-connection.",
    LOGIN_ERROR_UNKNOWN_EMAIL_OR_PASSWORD: "Wrong email or password.",
    LOGIN_ERROR_TOO_MANY_ATTEMPTS:
      "We've detected unusual activity on your account. Please try again later, or contact help@headversity.com for assistance.",
    LOGIN_ERROR_AUTHENTICATION_ERROR: "Authentication error",
    LOGIN_ERROR_BLOCKED_USER: "Blocked user",
    LOGIN_ERROR_DATA_BREACH:
      "This login attempt has been blocked because the password you're using was previously disclosed through a data breach (not in this application).",
    LOGIN_ERROR_MULTIPLE_ATTEMPTS:
      "Your account has been blocked after multiple consecutive login attempts. We’ve sent you an email with instructions on how to unblock it.",
    LOGIN_ERROR_SUSPICIOUS_BEHAVIOR:
      "We have detected suspicious login behavior and further attempts will be blocked. Please contact the administrator.",
    LOGIN_ERROR_UNKNOWN_ERROR: "Unknown error from provider",
    PASSWORD_CANT_CHANGE:
      "To reset your password, contact your IT administrator.",
    INVALID_PASSWORD: "Invalid Password",
    ACCOUNT_EXPIRED: "Sorry, this account is no longer valid.",
    LOGIN_ERROR_RESET_PASSWORD:
      "Please reset your password <a style='text-decoration: underline;' href='/forgotPassword'>here</a>",
    UPDATING: "There is a new Update! Downloading ",
    FIRST_TIME_INTERSTITIAL_HEADER: "Introducing <b>Pursuits</b>",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM1:
      "Supporting your own mental health just got simpler. Whether you’re aiming for increased confidence, ways to manage stress, deeper connections, or much more, we’re here to help you grow in all aspects of life.",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM2:
      "Focus your training on what’s most important to you by selecting a pursuit today!",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM_IMAGE:
      "https://cdn.headversity.com/app/interstitial/focuses-en.png",

    FIRST_TIME_INTERSTITIAL_HEADER_CHALLENGE:
      "Take the Next Step in Your Pursuit",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM1_CHALLENGE:
      "<ul style='list-style:disc;margin-left:20px;margin-top:10px'><li style='margin-bottom:8px'>Track your progress with <b>experience points (XP)</b> as you reach new levels of resilience</li><li style='margin-bottom:8px'>Set a personal <b>challenge</b> to level up every day, week, or month - you choose</li><li style='margin-bottom:8px'>Maintain a <b>streak</b> and meet your challenge</li></ul>",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM2_CHALLENGE:
      "Set a challenge and start your streak today! 🔥",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM_IMAGE_CHALLENGE:
      "https://cdn.headversity.com/app/interstitial/interstitial-challenge-en.png",

    FIRST_TIME_INTERSTITIAL_FIRST_HEADLINE: "",
    FIRST_TIME_INTERSTITIAL_FIRST_LIST_ITEM: "",
    FIRST_TIME_INTERSTITIAL_FIRST_IMAGE: "",
    FIRST_TIME_INTERSTITIAL_SECOND_HEADLINE: "",
    FIRST_TIME_INTERSTITIAL_SECOND_LIST_ITEM: "",
    FIRST_TIME_INTERSTITIAL_SECOND_IMAGE: "",
    FIRST_TIME_INTERSTITIAL_THIRD_LIST_ITEM:
      "If you have any questions, please contact us at <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",

    FIRST_TIME_INTERSTITIAL_PUSH_NOTIFICATIONS:
      "To help you stay on track, we'll now prompt you to <b style='font-weight: 600'>enable notifications</b> for periodic reminders.",
    FIRST_TIME_INTERSTITIAL_PUSH_NOTIFICATIONS_TEAM:
      "To ensure you receive alerts when your team is training, we'll now prompt you to <b style='font-weight: 600'>enable notifications</b>.",

    FIRST_TIME_TEAM_INTERSTITIAL_HEADER: "Training with your team?",
    FIRST_TIME_TEAM_INTERSTITIAL_FIRST_PARAGRAPH:
      "With <em>headversity</em> TEAM, you'll <strong>train with your teammates</strong> and level-up your collective mental health and resilience skills. Even better, you don't need to be a mental health expert to lead a training session with TEAM. Our curated resources make it easy for anyone to facilitate a session.",
    FIRST_TIME_TEAM_INTERSTITIAL_SECOND_PARAGRAPH: "Engage with your team in:",
    FIRST_TIME_TEAM_INTERSTITIAL_FIRST_LIST_ITEM: "5-7 minute trainings",
    FIRST_TIME_TEAM_INTERSTITIAL_SECOND_LIST_ITEM:
      "Group challenges and interactive polls",
    FIRST_TIME__TEAM_INTERSTITIAL_BOTTOM_PARAGRAPH:
      'Click "Let\'s go to TEAM" to discover a new way to integrate mental health training into your workflow with your co-workers.',
    FIRST_TIME_TEAM_INTERSTITIAL_GO_TO_TEAM: "Let’s go to TEAM",
    DELETE_ACCOUNT_TITLE_1: "We’re sad to see you go.",
    DELETE_ACCOUNT_TITLE_2: "Delete Account",
    DELETE_ACCOUNT_MESSAGE:
      "Once your account is deleted, all information and progress associated with {0} will be lost. Are you sure you want to delete your account?",
    DELETE_ACCOUNT_CONFIRM_MESSAGE:
      "<strong>Are you absolutely sure?</strong> <br> If you are sure you want to delete your account, please confirm below. This action is not reversible.",
    APP_VERSION_OUTDATED_ERROR:
      "Hey! It looks like you’re using an older version of the <em>headversity</em> app. Please go to the app store on your device and update now.",
    ABOUT_TEAM_WELCOME: "Welcome to <i>headversity</i> TEAM!",
    ABOUT_TEAM_FIRST_LINE:
      "Engage with your teammates in <b style='font-weight:600'>quick</b>, <b style='font-weight:600'>interactive</b> trainings and level up your collective mental health and resilience!",
    ABOUT_TEAM_SECOND_LINE:
      "Anyone can facilitate a session - no mental health expert required.",
    VIEW_HISTORY: "View History",
    TERMS_PAGE: termsPolicy.en.termsPage,
    PRIVACY_PAGE: termsPolicy.en.privacyPolicyPage,
    UNAUTHORIZED: "Unauthorized",
    UNAUTHORIZED_DESCRIPTION:
      "You are not authorized to view or access this page.",
    SHIELD_LEVEL: "LEVEL",
    SHIELD_LAST_LEVELED_UP: "Last levelled up",
    EARN_MODAL_INITIAL_MESSAGE:
      "By completing this activity, you're strengthening this skill.",
    EARN_MODAL_INITIAL_MESSAGE_HEADLINE: "Way to go!",
    EARN_MODAL_INITIAL_MESSAGE_BODY:
      "As you complete activities, you'll earn experience points (XP) and reach new levels of resilience.",
    EARN_MODAL_INITIAL_MESSAGE_CONTINUE_TO_SEE:
      "Continue to see your stats for",
    EARN_MODAL_LEVEL_UP: "LEVEL UP!",
    EARN_MODAL_YOUR_NEW_LEVEL: "YOUR NEW LEVEL",
    EARN_TOAST_EARNED: "You earned",
    EARN_TOAST_ANOTHER_POINT: "another point",
    EARN_TOAST_MORE_POINTS: "more points",
    EARN_TOAST_KEEP_GOING: "Keep going to level up!",
    EARN_TO_LEVEL_UP: "1 more point to level up!",
    EARN_TO_LEVEL_UP_PLURAL: "{0} more points to level up!",
    SPORT_TAGLINE: "Sport tagline.",
    SPORT_LAUNCH_CHARTER: "Launch Charter Program",
    CERT_TAGLINE: "Get Certified. Stay Qualified.",
    CERT_PROGRAMS: "Programs",
    CERT_PROGRAMS_SUBHEAD: "Explore and manage your certifications.",
    CERT_WELCOME_SUBHEAD: "More than just a requirement",
    CERT_WELCOME_TEXT:
      "Welcome to CERT, your ally on the path to success! CERT combines engaging upfront certification with ongoing upskilling for continuous personal growth.",
    CERTIFICATE_IS_PRESENTED_TO: "This certification is presented to",
    CERTIFICATE_FOR_COMPLETING: "for completing the program",
    CERTIFICATE_BY_ACHIEVING: "By achieving this certification, ",
    CERTIFICATE_AWARDED_ON: "Awarded on: ",
    CERTIFICATE_DOWNLOAD: "Download",
    CERT_COMPLETE_DOWNLOAD_LINK: "View Certificate",
    CERT_COMPLETE_SHARE_LINKEDIN: "Share on LinkedIn",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_TITLE: "Celebrate on LinkedIn",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_PARAGRAPH_1:
      "Congrats again on earning your certification! We’re proud, and we know your network will be, too.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_PARAGRAPH_2:
      "Here’s how to share your accomplishment on LinkedIn:",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_1: " to easily share on LinkedIn.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_2:
      "In the “Media” section, click “+ Add Media”.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_3:
      "Click on the CERT image below to copy the URL address.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_4:
      "Paste the CERT image URL into the “Add a link” field and click “Add”.",
    CERT_LINKEDIN_INSTRUCTIONS: "<br/> Click on the image to copy the URL:",
    CERT_LINKEDIN_SHARE_IMAGE_FILENAME: "headversity_cert.png",
    CERT_BADGE_COPIED_TO_CLIP_BOARD:
      "The CERT image URL has been copied to your clipboard.",
    CERT_MODAL_QUALIFY_HEADER: "It's Time To Stay Qualified!",
    CERT_MODAL_QUALIFY_SUB_TEXT: `It’s time to continue your {0} certification journey! Click the "Stay Qualified" button below to complete activities and maintain your certification.`,
    CERT_MODAL_ALREADY_QUALIFIED_SUB_TEXT:
      "You've completed the required refreshers this period. You can always complete additional activities to continue your {0} certification journey.",
    CERT_STAY_QUALIFIED: "Stay Qualified",
    REVIEW_QUALIFICATION: "Review Qualification",
    CERT_POINTS_TO_QUALIFY: "1 more point to stay qualified!",
    CERT_POINTS_TO_QUALIFY_PLURAL: "{0} more points to stay qualified!",
    CERT_QUALIFICATION_WAY_TO_GO: "Way to go! You earned ",
    CERT_QUALIFICATION_KEEP_GOING: "Keep going to stay qualified!",
    SELECT_A_VALUE: "Select a value.",
    COPIED_TO_CLIPBOARD: "Copied to clipboard",
    CLICK_HERE: "Click here",
    TOGGLE_NAVIGATION_MENU: "Toggle Navigation Menu",
    SKIP_TO_MAIN_CONTENT: "Skip to main content",
    ALL_CATEGORIES: "All Categories",
    NEGATIVE_FEEDBACK_WHY:
      "We're sorry to hear that 😔 Anything more you can share about what you didn't like?",
    PROVIDE_FEEDBACK: "Please provide feedback here.",
    NEXT_PAGE: "Next page",
    PREVIOUS_PAGE: "Previous page",
    GO_TO_PAGE: "Go to page ",
    DAYS: "days",
    REACH_INSIGHTS_INDICATED_A: "indicated a",
    REACH_INSIGHTS_AGREEMENT_VS: "agreement vs",
    REACH_INSIGHTS_IN_OUR_REFERENCE_BENCHMARK: "in our reference benchmark",
    REACH_INSIGHTS_INCREASE: "increase",
    REACH_INSIGHTS_DECREASE: "decrease",
    REACH_INSIGHTS_IN_AGREEMENT_AFTER_COURSE:
      "in agreement after completing the course",
    REACH_INSIGHTS_ENROLLED_USERS: "Enrolled Users",
    REACH_INSIGHTS_OUT_OF: "out of",
    REACH_INSIGHTS_ELIGIBLE_USERS: "Eligible Users",
    REACH_INSIGHTS_ACTIVE_USERS: "Active Users",
    REACH_INSIGHTS_ALL_TIME: "All-Time",
    REACH_INSIGHTS_LAST_180_DAYS: "Over the Last 180 Days",
    REACH_INSIGHTS_LAST_12_MONTHS: "Over the Last 12 Months",
    REACH_INSIGHTS_SELECTED_PURSUITS: "Selected Pursuits",
    REACH_DOWNLOAD_REPORT: "Download Report",
    REACH_INSIGHTS_USERS_DESCRIPTION:
      "Insight into the total reach and accessibility of the platform, and the level of active upskilling occurring across your user base",
    REACH_INSIGHTS_PSYCHOGRAPHICS_TITLE: "Psychographics",
    REACH_INSIGHTS_PSYCHOGRAPHICS_DESCRIPTION:
      "Based on {0} User Responses over the Last 180 Days",
    REACH_INSIGHTS_PSYCHOGRAPHICS_DESCRIPTION_ALL_TIME:
      "Based on {0} User Responses, All-Time",
    REACH_INSIGHTS_SOLO_AND_TEAM_TITLE: "SOLO and TEAM",
    REACH_INSIGHTS_SOLO_AND_TEAM_DESCRIPTION:
      "Showcases the top trending content pieces based on user activity, providing visibility in the topics users are upskilling in the most",
    REACH_INSIGHTS_KEY_TAKEAWAYS_STRENGTH: "Areas of ",
    REACH_INSIGHTS_KEY_TAKEAWAYS_IMPROVEMENT: "Areas for",
    REACH_INSIGHTS_KEY_TAKEAWAYS_STRENGTHS_DESCRIPTION:
      "Responses exceed sector benchmarks in these areas. Celebrate and be proud of these strengths!",
    REACH_INSIGHTS_KEY_TAKEAWAYS_RISKS_DESCRIPTION:
      "Responses are often below sector benchmarks in these areas, highlighting potential issues and opportunities for focus and growth.",
    REACH_INSIGHTS_IMPROVEMENT: "Improvement",
    REACH_INSIGHTS_STRENGTH: "Strength",
    REACH_INSIGHTS_STRONG: "strong",
    REACH_INSIGHTS_LESSON: "Lesson",
    REACH_INSIGHTS_PRACTICE: "Practice",
    REACH_INSIGHTS_TEAM: "TEAM",
    REACH_INSIGHTS_VIEW_ALL_AREAS: "View All Areas of Strength and Risk",
    REACH_INSIGHTS_VIEW_ALL_CERTS: "View All CERT Insights",
    REACH_INSIGHTS_GET_HELP_DESCRIPTION:
      "Get Help views showcases the monthly views of Help Resources, offering users access to critical support tools and resources",
    REACH_INSIGHTS_HELP_RESOURCES_VIEWS_BY_MONTH:
      "Help Resources Views by Month",
    REACH_INSIGHTS_UNIQUE_HELP_RESOURCE_CLICKS: "Unique Help Resource Clicks",
    REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES: "Breakdown of Activities",
    REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES_DESCRIPTION_ALL_TIME:
      "% of Total Activities All Time",
    REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES_DESCRIPTION:
      "% of Total Activities Over the Last 180 Days",
    REACH_INSIGHTS_POPULAR_MICRO_LESSONS: "Popular Lessons",
    REACH_INSIGHTS_POPULAR_NANO_PRACTICES: "Popular Practices",
    REACH_INSIGHTS_POPULAR_TEAM_PRESENTATIONS: "Popular TEAM Presentations",
    REACH_INSIGHTS_ENROLMENT_TRENDS: "Enrollment Trends Over Time",
    REACH_INSIGHTS_ENROLMENT_TRENDS_LAST_180_DAYS: "Over the Last 180 Days",
    REACH_INSIGHTS_TOTAL_ENROLLED_USERS: "Total Enrolled Users",
    REACH_INSIGHTS_CERT_TITLE: "CERT",
    REACH_INSIGHTS_CERT_DESCRIPTION:
      "Indicates the overall certification progress and achievements.",
    REACH_INSIGHTS_BREAKDOWN_BY_CERT: "Breakdown by CERT",
    REACH_INSIGHTS_ACTIVE_CERTIFICATIONS: "Active Certifications",
    REACH_INSIGHTS_ACTIVE_CERTIFICATION_RATES: "Active Certification Rates",
    REACH_INSIGHTS_TO_DATE: "To Date",
    REACH_INSIGHTS_ASSIGNED_CERTIFICATIONS: "Assigned Certifications",
    REACH_INSIGHTS_CERTIFICATION_ACTIVITY_OVER_TIME:
      "Certification Activity Over Time",
    REACH_INSIGHTS_USER_FEEDBACK: "User Feedback",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_CERTIFICATIONS_TITLE:
      "What is an active certification?",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_CERTIFICATIONS_DESCRIPTION:
      "Completed and valid certifications, not including in-progress nor not started.",
    REACH_INSIGHTS_TOOLTIP_ENROLLED_USER_TITLE: "What is an enrolled user?",
    REACH_INSIGHTS_TOOLTIP_ENROLLED_USER_DESCRIPTION:
      "Eligible users who have successfully configured their accounts.",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_USER_TITLE: "What is an active user?",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_USER_DESCRIPTION:
      "Enrolled users who have used the app.",
    REACH_INSIGHTS_CERTIFIED_USERS: "Certified Users",
    REACH_INSIGHTS_USERS_IN_PROGRESS: "Users In-Progress",
    REACH_INSIGHTS_QUALIFIED_USERS: "Qualified Users",
    REACH_INSIGHTS_TOOLTIP_CERTIFIED_USERS_TITLE: "What are certified users?",
    REACH_INSIGHTS_TOOLTIP_CERTIFIED_USERS_DESCRIPTION:
      "Users who have completed a certification.",
    REACH_INSIGHTS_TOOLTIP_CERT_USERS_IN_PROGRESS_TITLE:
      "What are in-progress users?",
    REACH_INSIGHTS_TOOLTIP_CERT_USERS_IN_PROGRESS_DESCRIPTION:
      "Users who have in-progress certification.",
    REACH_INSIGHTS_TOOLTIP_QUALIFIED_USERS_TITLE: "What are qualified users?",
    REACH_INSIGHTS_TOOLTIP_QUALIFIED_USERS_DESCRIPTION:
      "Users who have obtained a certification and have completed the required activity level to remain qualified within a period.",
    REACH_INSIGHTS_BEFORE_AND_AFTER_TITLE: "Before and After",
    REACH_INSIGHTS_RELEVANT_CONTENT_DESCRIPTION:
      "Users that found the activities relevant",
    REACH_INSIGHTS_MOTIVATED_CONTENT_DESCRIPTION:
      "Users that were motivated to act positively after completing an activity",
    REACH_INSIGHTS_MEANINGFUL_CONTENT_DESCRIPTION:
      "Users that found meaning in their pursuit",
    REACH_INSIGHTS_NO_CHANGE_AFTER_COMPLETING_COURSE:
      "No change after completing the course.",
    REACH_INSIGHTS_LEGEND_AGREE: "Agree",
    REACH_INSIGHTS_LEGEND_NEUTRAL: "Neutral",
    REACH_INSIGHTS_LEGEND_DISAGREE: "Disagree",
    YOUR_PURSUIT: "Your Pursuit",
    YOUR_PURSUIT_WITH_COLON: "Your Pursuit:",
    TOUR_PURSUIT: "Focus your training on what's most important to you.",
    TOUR_PURSUIT_BOX: "Continue training in your pursuit.",
    OK: "OK!",
    EXCLAMATION: "!",
    COLON: ":",
    PURSUIT_CHECKIN0: "Here's your selected pursuit. Start training today!",
    PURSUIT_CHECKIN1: "You've almost trained in each skill. Keep it up!",
    PURSUIT_CHECKIN2:
      "You've trained in each skill. Keep going or review your progress!",
    PURSUIT_STARTING_POINT: "Here's your suggested starting point.",
    PURSUIT_SELECT: "Select a pursuit",
    PURSUIT_BUILD_SKILLS: "Build skills in:",
    PURSUIT_FOCUS: "Focus your training today!",
    PURSUIT_DIFFERENT: "Choose a different pursuit",
    PURSUIT_OWN: "Choose my own instead",
    PURSUIT_IN_THIS_PURSUIT: "in this pursuit",

    REFRESH_ACTIVITIES: "Shuffle",
    REVIEW_PROGRESS: "Review Progress",
    LETS_REVIEW_PROGRESS: "Let's review your progress in ",
    HERES_AN_OVERVIEW: "Here's an overview of the <b>{0}</b> XP you've earned ",
    SO_FAR: "so far",
    OVER_LAST_30: "over the last 30 days",
    VIEW_OVER_TIME: "View over time",
    VIEW_BY_SKILL: "View by skill",
    VIEW_LAST_30: "View last 30 days",
    VIEW_ALLTIME: "View all-time",
    CONCEPTS_LEARNED_AND_APPLIED:
      "Here's a summary of the concepts you learned and applied.",
    PRACTICE_TECHNIQUES: "<b>Practice</b> techniques included:",
    LESSON_TOPICS: "<b>Lesson</b> topics included:",
    HOW_DID_YOU_FEEL_ABOUT_TRAINING: "How did you feel about your training?",
    FOUND_PURSUIT_RELEVANT: "I found the pursuit of {0} relevant.",
    BETTER_UNDERSTAND: "I better understand how to {0}",
    PURSUE_NEXT: "Let's see what you should pursue now.",
    PURSUE_NEXT_HOW:
      "To do this, we'll ask you a few questions to help you identify a new area of focus.",
    KEEP_CURRENT_PURSUIT: "Keep my current pursuit instead",
    TRACKING: "Tracking",
    VIDEO: "Video",
    REFLECTION: "Reflection",
    WELCOME_BACK: "Welcome back",

    TIME_PICKER_SET: "Set",
    TIME_PICKER_CANCEL: "Cancel",
    TIME_PICKER_NOW: "Now",
    TIME_PICKER_SELECT_NOW: "Select Now",
    TIME_PICKER_TOGGLE_TIME_SELECTOR: "Toggle time selector",
    TIME_PICKER_TOGGLE_CLOCK: "Toggle Clock",
    HERES_A_QUICK_RECAP: "Here's a quick recap.",
    SUMMARY: "Summary",
    RESPONSES: "Responses",
    NEXT_STEP: "Next steps...",
    PAST_SUMMARY: "Looking back...",
    YOU_FELT: "You felt:",
    BECAUSE_OF: "Because of:",
    SEE_DETAILS: "See details",
    HIDE_DETAILS: "Hide details",
    OPEN_APP_BANNER_DESCRIPTION: "Open the app via Google Play",
    OPEN: "Open",
    CLOSE_THIS_BROWSER:
      "You've successfully logged in! You can now close this page and return to the page that was waiting for you to log in",
    CHECK_EMAIL_TO_LOGIN:
      "Please check your email <b>{email}</b> for a message containing the login link. Click on the link in the email to complete the login process. After clicking the link, return to this page to continue with your activities.",
    PASSWORD_LESS_LOGIN_MSG:
      "Enter your email address below, and we'll send you a login link. Click on the link in your email to access your account instantly. No need to remember a password!",

    PURSUIT_CONTINUE: "Continue your pursuit",
    PURSUIT_PICKUP: "Pick up where you left off in",
    FAVORITES: "Favorites",
    FOR_REFLECTION: "For Reflection",
    STRESS_RELIEF: "For Stress Relief",
    SOMETHING_NEW: "Something New",

    CONTINUE_TRAINING_FOR: "Let's continue training in",
    START_TRAINING_FOR: "Let's get started.",
    DO_NEXT: "What would you like to do next?",
    SIMPLE_PROVEN: "simple, proven techniques",
    LEARN: "Learn",
    THE_SCIENCE: "the science behind resilience",
    TRAINING_INTRO:
      "Like quick stretches for your mind, activities in SOLO are all bite-sized. Track your thoughts and feelings, practice proven techniques, and explore the science underlying it all.",
    LETS_COMPLETE: "Complete your first activity in {0} now!",

    STREAK: "streak",
    STREAK_CONTINUE: "Continue your {0} {1} streak!",
    STREAK_GOING: "You're on a {0} {1} streak!",

    STREAK_AT: "at",
    STREAK_DAILY_MESSAGE:
      "<b style='font-weight:600'>Daily</b> practice provides the fastest improvement and strongest habit formation. We'll send you a push notification every day if you haven't practiced.",
    STREAK_WEEKLY_MESSAGE:
      "<b style='font-weight:600'>Weekly</b> practice provides a great balance of progress and pacing. We'll send you a push notification every Wednesday and Friday if you haven't practiced.",
    STREAK_MONTHLY_MESSAGE:
      "<b style='font-weight:600'>Monthly</b> practice is ideal for gradual growth that's easy to maintain. We'll send you a push notification on the 15th and 25th of each month if you haven't practiced.",
    STREAK_DAILY: "Daily",
    STREAK_WEEKLY: "Weekly",
    STREAK_MONTHLY: "Monthly",
    STREAK_DAY: "Day",
    STREAK_DAY_PLURAL: "days",
    STREAK_WEEK: "Week",
    STREAK_WEEK_PLURAL: "weeks",
    STREAK_MONTH: "Month",
    STREAK_MONTH_PLURAL: "months",
    STREAK_DAY_SINGLE_PHRASE: "day",
    STREAK_WEEK_SINGLE_PHRASE: "week",
    STREAK_MONTH_SINGLE_PHRASE: "month",
    STREAK_YEAR: "year",
    STREAK_WEEKS: ["Week 1", "Week 2", "Week 3", "Week 4"],
    STREAK_MONTHS: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],

    COMPLETE_ACTIVITY: "Complete an activity today to keep your",
    COMPLETE_ACTIVITY_STREAK_GOING: "streak going",
    THIS_WEEK: "this week",
    THIS_MONTH: "this month",
    STREAK_KEEP_GOING: "Keep your streak going!",
    YOUR_STREAK: "Your streak",
    START_A_STREAK: "Start a streak!",
    YOURE_ON_A_STREAK: "You're on a streak!",
    START_STREAK_DESCRIPTION:
      "Set a challenge, complete an activity, and start your streak today!",
    START_STREAK_WITH_CHALLENGE_DESCRIPTION:
      "Complete an activity and start your streak today!",
    COMPLETED_ACTIVITY: "You've completed an activity",
    IN_A_ROW: "in a row",
    CONTINUE_TRAINING_EACH:
      "Continue training each {0} to maintain your streak",
    MEET_YOUR_CHALLENGE: "and meet your challenge",
    KEEP_MOMENTUM: "and keep up the momentum",

    CHALLENGE_YOURSELF: "Challenge yourself to",
    CONTINUE_LEVELING_UP: "continue leveling up",
    CHALLENGE_MESSAGE:
      "Manage stress, adapt to change, and overcome adversity. By practicing regularly, you'll enhance your mental and emotional wellbeing and be sharper, happier, and healthier.",
    PRACTICE_EVERY: "Practice every",
    REMIND_ME: "Remind me",

    LEVEL_LEVELED_UP: "Congratulations, you just Leveled UP!",
    LEVEL_ENCOURAGE: "You're on your way!",
    LEVEL_FIRST_TIME:
      "As you complete activities, you'll earn experience points (XP) and reach new levels of resilience. Keep going, all the way from <b>BEGINNER</b> to <b>CHAMPION</b>!",
    LEVEL_NOW_AT: "You're now at",
    LEVEL_KEEP_GOING: "Keep going, all the way to <b>CHAMPION</b>.",
    LEVEL: "Level",
    LEVEL_TO_GO_PRE: "",
    LEVEL_TO_GO_POST: "to go!",
    LEVEL_NAMES: [
      "BEGINNER",
      "APPRENTICE",
      "EXPLORER",
      "BUILDER",
      "ACHIEVER",
      "NAVIGATOR",
      "GUIDE",
      "MENTOR",
      "CATALYST",
      "CHAMPION",
    ],
    LEVEL_DESCRIPTIONS: [
      "You’ve taken the first step in managing challenges and laying a strong foundation for future growth.",
      "You’re improving at balancing home and work life while starting to use basic resilience techniques.",
      "You can manage greater stressors and are creating a routine that supports your mental health and resilience.",
      "You’re consistently using resilience techniques in various areas of your life and noticing improvements in handling stress.",
      "You show a high level of resilience, balancing multiple areas of life while maintaining your wellbeing.",
      "You navigate complex challenges well, showing strong resilience that enhances your productivity and relationships.",
      "You manage adversity with ease and peers often seek you out for guidance and support.",
      "You exhibit advanced resilience skills, frequently mentoring others and contributing to a resilient community.",
      "You consistently thrive in high-stress situations, leading resilience and wellbeing initiatives on a larger scale.",
      "Your resilience is second nature. You inspire and lead entire communities toward better mental health and resilience.",
      "Your dedication, skill, and perseverance continue to inspire! Keep pushing your limits and achieving greatness.",
    ],
    XP_EARNED: "XP earned",
    TAKE_A_MINUTE: "Take a minute to reflect on your responses.",
    CHARTER_BEST_VOTE_TITLE_THE: "The",
    CHARTER_BEST_VOTE_TITLE_BEST: "BEST",
    CHARTER_BEST_VOTE_TITLE_WORST: "WORST",
    CHARTER_BEST_VOTE_DESCRIPTION:
      "The things your team thinks are the most important for a team to be successful.",
    CHARTER_WORST_VOTE_DESCRIPTION:
      "The things your team thinks are the most harmful for a team.",
    YOUR_GROUP_SAID: "Your group said:",
    WHAT_ARE_SOME_OF_THE: "What are some of the",
    CHARACTERISTICS_THAT_MAKE_A_TEAM_SUCCESSFUL:
      "characteristics that make a team successful?",
    CHARACTERISTICS_THAT_CAN_HARM_A_TEAM:
      "characteristics that can harm a team and their success?",
    TEAM_POLL_WAITING_FOR_GROUP_RESULTS:
      "Waiting for all groups to submit their answers.",
    TEAM_POLL_REPORTER_DID_NOT_RESPOND:
      "Your group did not provide any answers to choose from. Waiting for other groups to submit their answers.",
    TEAM_POLL_WAITING_FOR_USER_RESULTS:
      "Waiting for everyone to submit their answers.",
    POLL_MULTI_INPUT_SUBMIT_AND_ADD_ANOTHER: "Submit and Add Another",
    POLL_MULTI_INPUT_IM_DONE: "I'm Done",
    POLL_MULTI_INPUT_WAITING: "Waiting for your response",
    FOUNDATIONAL_TRAINING: "Foundational Training",
    HOWD_IT_GO: "How'd it go?",
    LET_US_KNOW: "Let us know how you felt about this experience.",
    SPORT_RESPONSES: "Responses:",
  },

  es: {
    TEAM_ERROR_USER_REGISTERED_WITH_DIFFERENT_COMPANY:
      "El usuario está registrado con una empresa diferente. No se puede agregar a equipos fuera de su empresa registrada.",
    GLOSSARY: "Glosario",
    USER_ROLE_LEARNER: "Aprendiz",
    USER_ROLE_ADMIN: "Administrador",
    USER_ROLE_HV_ADMIN: "Administrador HV",
    USER_ROLE_HV_CONTENT_ADMIN: "Administrador de contenido HV",
    USER_ROLE_HV_CSM_ADMIN: "Administrador de servicio al cliente HV",
    USER_ROLE_HV_MKT_ADMIN: "Administrador de marketing HV",
    USER_ROLE_SALES: "Persona de ventas",
    SINGLE_SIGN_ON_LINK_TEXT:
      "Su correo electrónico está asociado con un servicio de inicio de sesión único (SSO) externo. Por favor, {0} utilizando el proveedor de SSO externo.",
    SINGLE_SIGN_ON_LINK: "autenticar",
    LANGUAGE_ENGLISH: "English",
    LANGUAGE_FRENCH: "Français",
    LANGUAGE_SPANISH: "Español",
    NANO_PRACTICE_NO_RESULTS: "No se encontraron resultados.",
    NANO_PRACTICE_TYPE_FILTER: "Todos los tipos de práctica",
    NANO_PRACTICE_CATEGORY: "Colección",
    NANO_PRACTICE_CATEGORY_FLEXIBLE_THINKING: "Pensamiento flexible",
    NANO_PRACTICE_CATEGORY_TAKING_ACTION: "Tomando acción",
    NANO_PRACTICE_CATEGORY_TAMING_EMOTIONS: "Domando emociones",
    NANO_PRACTICE_CATEGORY_QUICK_RELIEF: "Alivio del estrés",
    NANO_PRACTICE_CATEGORY_FLEXIBLE_THINKING_DESCRIPTION:
      "Aprende a ser de mente abierta y a encontrar diferentes soluciones a los problemas.",
    NANO_PRACTICE_CATEGORY_TAKING_ACTION_DESCRIPTION:
      "Toma el control de tus hábitos y acciones para crear resultados positivos y alcanzar tus metas.",
    NANO_PRACTICE_CATEGORY_TAMING_EMOTIONS_DESCRIPTION:
      "Controla tus emociones para que no te controlen a ti.",
    NANO_PRACTICE_CATEGORY_QUICK_RELIEF_DESCRIPTION:
      "Calma rápidamente tu mente y cuerpo, proporcionando alivio instantáneo del estrés y la tensión.",
    NANO_PRACTICE_TYPE: "Tipo",
    NANO_PRACTICE_TYPE_VIDEO: "Video",
    NANO_PRACTICE_TYPE_SHOW_AND_TELL: "Reflexión",
    NANO_PRACTICE_TYPE_HEADZONE: "Seguimiento",
    NANO_PRACTICE_FAVORITES_ONLY: "Solo favoritos",
    SOLO_HOME_INTERNET_CONNECTION_ERROR:
      "Lo sentimos, pero no podemos cargar la aplicación en este momento. Revisa tu conexión a Internet y vuelve a intentarlo más tarde.",
    SOLO_AI_ERROR:
      "Lo sentimos, ha ocurrido un error inesperado. Por favor, inténtelo de nuevo.",
    SOLO_UNEXPECTED_ERROR:
      "Lo sentimos, se ha producido un error inesperado. Revisa tu conexión a Internet y vuelve a intentarlo más tarde.",
    SPORT_MENU_HOME: "Página principal",
    SPORT_MENU_RESOURCES: "Recursos",
    SOLO_MENU_HOME: "Página principal",
    SOLO_MENU_SKILLS: "Habilidades",
    SUGGESTED_REFRESHERS: "Actualizaciones sugeridas",
    COMPLETE_REFRESHERS_FROM_THE_LIST_BELOW:
      "Completa las actualizaciones de la lista a continuación.",
    MORE_NANO_PRACTICES: "Más prácticas",
    EARN_POINTS_FOR_HEADER_TEXT: "Gana {0} puntos por cada una de estas ",
    POINTS: "Puntos",
    EARN_POINTS_COMPLETE: "que completes.",
    CONGRATS_ON_STAYING_QUALIFIED:
      "¡Felicidades por mantener tu cualificación!",
    GREAT_JOB_STAYING_QUALIFIED:
      "¡Gran trabajo! Estaremos en contacto en el próximo periodo con más actividades para seguir manteniendo tu calificación.",
    PERIOD_BEGINS: "Comienzo del período",
    PERIOD_ENDS: "Fin del período",
    POINT_REMAINING: "{0} punto restante",
    POINTS_REMAINING: "{0} puntos restantes",
    STAY_QUALIFIED: "Mantén tu cualificación",
    YOU_ARE_QUALIFIED: "¡Estás calificado/a!",
    COMPLETE_REFRESHERS_MAINTAIN_CERTIFICATION:
      "Completa actualizaciones y mantén tu certificación",
    MORE_MICROS_LESSONS: "Más lecciones",
    SOLO_MENU_LEARN: "Lecciones",
    SOLO_MENU_PRACTICE: "Herramientas",
    SOLO_MENU_RESILIENCE_FEED: "Recursos de resiliencia",
    SOLO_MENU_LOGOUT: "Cerrar sesión",
    SOLO_MENU_CHOOSE_THEME: "Tema",
    SOLO_MENU_NOTIFICATION: "Notificaciones",
    SOLO_MENU_NOTIFICATION_OPTIMISM_SINGLE:
      "Haz un balance para tu desafío de optimismo",
    PRACTICE_REMINDER_SAVED: "¡Desafío establecido!",
    PRACTICE_REMINDER_REMOVED: "Desafío establecido.",
    PRACTICE_REMINDERS: "Recordatorios de práctica",
    PRACTICE_REMINDER_HABITS:
      "Para ayudarte a recordar seguir practicando, te recomendamos que configures un ",
    PRACTICE_REMINDER: "recordatorio de práctica",
    PRACTICE_REMINDER_HABITS2:
      "Configurar un recordatorio te permitirá a integrar la práctica en tu rutina diaria y a desarrollar hábitos saludables.",
    PRACTICE_REMINDER_REMINDERS: "Habilitar desafío",
    PRACTICE_REMINDER_AT_THE_FOLLOWING_TIME: "En el siguiente horario",
    PRACTICE_REMINDER_AT_THE_FOLLOWING_DAYS:
      "En los siguientes días de la semana",
    PRACTICE_REMINDER_DAY_OF_WEEK_SUN: "D",
    PRACTICE_REMINDER_DAY_OF_WEEK_MON: "L",
    PRACTICE_REMINDER_DAY_OF_WEEK_TUE: "M",
    PRACTICE_REMINDER_DAY_OF_WEEK_WED: "M",
    PRACTICE_REMINDER_DAY_OF_WEEK_THUR: "J",
    PRACTICE_REMINDER_DAY_OF_WEEK_FRI: "V",
    PRACTICE_REMINDER_DAY_OF_WEEK_SAT: "S",
    PROFILE_PAGE_HISTORY: "Historial de entrenamiento",
    PROFILE_PAGE_PRACTICE_REMINDERS_HISTORY:
      "Ver el historial de entrenamiento",
    PRACTICE_REMINDER_REMOVE_FAV: "Eliminar de tus prácticas favoritas",
    PRACTICE_REMINDER_ADD_FAV: "Añadir a tus prácticas favoritas",
    PRACTICE_REMINDER_ADD_FAV_HEADLINE:
      "¿Quieres añadir esta práctica a tus favoritas?",
    PRACTICE_ADDED_TO_FAVORITES: "¡Práctica añadida a tus favoritas!",
    PRACTICE_REMOVE_FAVORITE_TITLE: "¿Eliminar de favoritos?",
    PRACTICE_REMOVE_FAVORITE:
      "¿Estás seguro de que quieres eliminar este favorito?",
    PRACTICE_REMINDER_SET_REMINDER: "Gestionar desafío",
    PRACTICE_REMINDER_MANAGE_CHALLENGE: "Gestionar desafío",
    SOLO_MENU_NOTIFICATION_FOCUS_SINGLE: "Tienes un plan de enfoque en camino",
    SOLO_MENU_PUSH_NOTIFICATION_PERMISSION:
      "Activar Notificaciones Automáticas",
    SOLO_MENU_PUSH_DISABLE_NOTIFICATIONS:
      "Desactivar Notificaciones Automáticas",
    SOLO_MENU_LIVE_UPDATE: "Buscar Actualizaciones",
    SOLO_MENU_PROFILE: "Perfil",
    SOLO_MENU_PROFILE_SETTINGS: "Perfil / Configuración",
    SOLO_MENU_WEBPUSH:
      "Habilitar notificaciones web para una mejor experiencia.",
    SOLO_MENU_TERMS_OF_SERVICE: "Términos de servicio",
    SOLO_MENU_INVITE_FAMILY_MEMBERS: "Invitar a miembros de la familia",
    SOLO_MENU_PRIVACY_POLICY: "Política de privacidad",
    SOLO_MENU_CONTACT: "Contacto",
    SOLO_HOME_SEE_MORE_RESILIENCE_FEED: "Ver más recursos de resiliencia",
    SOLO_SEE_ALL_LIVE_SESSIONS: "Ver más sesiones en vivo",

    WELCOME_TO: "Bienvenido a",
    SOLO_HOME_DESCRIPTION: "Adelantarte a la adversidad hoy",
    SOLO_HOME_YOUR_TITLE: "Tu",
    SOLO_HOME_LIVE_SESSIONS_TITLE: "Sesiones en vivo",
    SOLO_HOME_LIVE_SESSIONS_DESCRIPTION: "Eventos y presentaciones en vivo.",
    SOLO_HOME_RESILIENCE_FEED_TITLE: "Recursos de resiliencia",
    SOLO_HOME_RESILIENCE_FEED_DESCRIPTION:
      "Una colección de recursos diseñados para ayudarte a aprovechar al máximo tu camino a la resiliencia.",
    SOLO_HOME_RESILIENCE_FEED_MULTIPLE_SKILLS: "Múltiples habilidades",
    SOLO_HOME_RESILIENCE_SKILLS_DESCRIPTION:
      "Las habilidades esenciales que te hacen quien eres, y te aseguran la capacidad de salir adelantarte a la adversidad.",
    SOLO_HOME_ACHIEVEMENTS_DESCRIPTION: "Haz un seguimiento de tu progreso",
    SOLO_HOME_SUGGESTED_TITLE: "Sugerido",
    SOLO_HOME_SUGGESTED_TITLE_LESSON: "Lecciones sugeridas",
    SOLO_HOME_SUGGESTED_TITLE_TOOLS: "Herramientas sugeridas",
    SOLO_HOME_SUGGESTED_FOR_YOU_TITLE: "Para ti",
    SOLO_HOME_SUGGESTED_FOR_YOU_LESSON_DESCRIPTION:
      "Una colección de lecciones basadas en tus puntajes actuales para la habilidad de resiliencia.",
    SOLO_HOME_SUGGESTED_FOR_YOU_TOOL_DESCRIPTION:
      "Una colección de herramientas basadas en tus puntajes actuales para la habilidad de resiliencia.",
    SOLO_EXPLORE_DESCRIPTION:
      "Explora todos los recursos disponibles para aumentar tu resiliencia",
    SOLO_LEARN_DESCRIPTION:
      "Aprende sobre la ciencia detrás de la resiliencia a través de lecciones",
    SOLO_LEARN_NEXT_LESSON: "Siguiente lección",
    SOLO_PRACTICE_DESCRIPTION:
      "Fortalece tu resiliencia a través de técnicas más profundas",
    SOLO_SETTINGS_TITLE: "Ajustes",
    SOLO_PROFILE_TITLE: "Perfil",
    SOLO_PROFILE_DESCRIPTION:
      "Revisa tu progreso, las notificaciones y la configuración",
    SOLO_SKILL_TITLE: "Habilidades de resiliencia",
    SOLO_SKILL_DESCRIPTION:
      "Las habilidades esenciales para ser más resiliente",
    SOLO_ACHIEVEMENT_TITLE: "Logros",
    SOLO_ACHIEVEMENT_DESCRIPTION:
      "¡Sigue tu progreso y siéntete orgulloso de lo lejos que has llegado!",
    SOLO_SKILL_VIEW_ALL_ACHIEVEMENTS: "Ver más logros",
    SOLO_ACHIEVEMENT_MODAL_BADGE_NOT_EARNED_TEXT:
      "Aún no te has ganado esta insignia",
    SOLO_ACHIEVEMENT_MODAL_BADGE_EARNED_TEXT: "Te has ganado esta insignia.",
    SOLO_ACHIEVEMENT_MODAL_BADGE_EARNED_DATE_TEXT:
      "Primero ganaste esta insignia",
    SOLO_ACHIEVEMENT_MODAL_BADGE_EARNED_DATE_TEXT_SINGLE:
      "Ganaste esta insignia",
    SOLO_PAGE_NOT_FOUND: "Página no encontrada.",
    SOLO_PAGE_NOT_FOUND_DESCRIPTION:
      "La página que estás buscando podría haber sido eliminada, cambiado su nombre o no está disponible temporalmente.",
    HEADSCAN_QUESTION_REDO: "Repetir evaluación de resiliencia",
    HEADSCAN_QUESTION_WELCOME_SENTENCE: "Bienvenido a",
    HEADSCAN_QUESTION_FIRST_SENTENCE:
      "Estos son tus puntajes iniciales de resiliencia. Indican cómo te sientes &lt;em&gt;en este momento&lt;/em&gt;. Pueden cambiar todos los días y de ninguna manera son permanentes.",
    HEADSCAN_QUESTION_BOLD_SENTENCE:
      "Puedes volver a evaluar tus puntajes en cualquier momento.",
    HEADSCAN_QUESTION_LAST_SENTENCE:
      "¡Continúa haciéndolo para ver cómo mejoras con el tiempo!",
    HEADSCAN_QUESTION_START_MY_TRAINING: "Empezar mi entrenamiento",
    LESSON_SUCCESS_CHECK_INS: "Auto revisiones",
    LESSON_SUCCESS_RELEVANT: "La micro lección me pareció relevante.",
    LESSON_SUCCESS_POSITIVELY: "Estoy motivado para actuar positivamente.",
    LESSON_START: "Lección de inicio",
    LESSON_RESUME: "Reanudar lección",
    LESSON_RESTART: "Reiniciar lección",
    LESSON_STARTED_ON: "Empezaste esta lección en",
    LESSON_COMPLETED_ON: "Completaste esta lección en",
    QUESTION: "Pregunta",
    NEXT: "Siguiente",
    BACK: "Atrás",
    PREV: "Previo",
    STEP: "Paso",
    DONE: "Hecho",
    DONE_NEXT: "Done, Next",
    WE_ARE_DONE: "We're Done",
    SAVE: "Guardar",
    EDIT: "Editar",
    COMPLETE: "Completar",
    AND: "y",
    START_TRAINING: "Empezar",
    CONTINUE_TRAINING: "Continuar",
    GET_STARTED: "Comencemos",
    CATCH_UP: "Ponerse al día",
    IN_PROGRESS: "En progreso",
    ANOTHER_IN_PROGRESS: "Otra presentación está en progreso",
    CLOSE: "Cerrar",
    PAGE: "Página",
    OF: "de",
    OR: "o",
    NAME: "Nombre",
    EMAIL: "Correo electrónico",
    REMOVE: "Eliminar",
    PUTBACK: "Put back",
    SKIP: "Pasar",
    IN: "en",
    AND_MORE: "y más",
    AND_X_MORE: "y {0} más",
    SKILL_ENERGY_MANAGEMENT: "Gestión de la energía",
    SKILL_PANEL_PAGE: "Página",
    SKILL_CONTENT_PAGE_YOUR_SCORE: "Tu puntuación",
    SKILL_CONTENT_PAGE_YOUR_SCORE_WITH_FORMAT: "Tu {0} puntaje",
    SKILL_CONTENT_PAGE_REDO_YOUR_RESILIENCE_SCORE: "Rehacer puntuación",
    SKILL_CONTENT_PAGE_LESSONS: "Lecciones",
    SKILL_CONTENT_PAGE_LESSON_DESCRIPTION:
      "Lecciones seleccionadas para ti, diseñadas para ayudarte a aumentar tu",
    SKILL_CONTENT_PAGE_LESSON_DESCRIPTION_SKILL: "habilidad.",
    RESiLIENCE_PAGE_DESCRIPTION:
      "Una colección de recursos diseñados para ayudarte a aprovechar al máximo tu viaje hacia la resiliencia",
    RESILIENCE_FEED_FEEDBACK1: "Encontré útil este recurso",
    SKILL_CONTENT_PAGE_SUGGESTED: "Sugerido",
    SKILL_CONTENT_PAGE_SUGGESTED_LESSONS_FOR_YOU: "Lecciones sugeridas",
    SKILL_CONTENT_PAGE_SUGGESTED_TOOLS_FOR_YOU: "Herramientas sugeridas",
    SKILL_CONTENT_PAGE_RELATED_TOOLS: "Herramientas relacionadas",
    SKILL_CONTENT_PAGE_TOOLS_DESCRIPTION:
      "Herramientas seleccionadas por nuestro equipo, diseñadas para ayudarte a aumentar tu",
    SKILL_CONTENT_PAGE_TOOLS_DESCRIPTION_SKILL: " habilidad.",
    SKILL_CONTENT_PAGE_RESILIENCE_FEED_DESCRIPTION:
      "Una colección de recursos relacionados con",
    BADGES_EARNED: "Insignias",
    SHIELDS_IN_PROGRESS: "Escudos en progreso",
    USE_HEADZONE_TOOL: "Usa Estado mental",
    USE_CALMING_TOOL: "Usa Relajación",
    USE_FOCUS_TOOL: "Usa Enfoque",
    USE_THINKING_TOOL: "Usa Pensando",
    USE_MINDFULNESS_TOOL: "Usa Conciencia plena",
    USE_MOVE_TOOL: "Usa Muévete",
    USE_OPTIMISM_TOOL: "Usa Optimismo",
    EXPLORE: "Explora",
    EXPLORE_HEADZONE_TOOL: "Explora Estado mental",
    EXPLORE_CALMING_TOOL: "Explora Relajación",
    EXPLORE_FOCUS_TOOL: "Explora Enfoque",
    EXPLORE_THINKING_TOOL: "Explora Pensando",
    EXPLORE_MINDFULNESS_TOOL: "Explora Conciencia plena",
    EXPLORE_MOVE_TOOL: "Explora Muévete",
    EXPLORE_OPTIMISM_TOOL: "Explora Optimismo",
    TOOLS_PAGE_USED_TIMES: "Veces",
    TOOLS_PAGE_USED_TIME: "Tiempo",
    CALMING_TOOLS: "Relajación",
    CALMING_START: "INICIO",
    CALMING_STOP: "PAUSA",
    CALMING_IN: "INHALA",
    CALMING_OUT: "EXHALA",
    CALMING_MODE_TIME: "TIEMPO",
    CALMING_MODE_CYCLE: "CICLOS",
    CALMING_INSTRUCTION_TEXT:
      "Sigue el círculo. Inhala a medida que se expande. Exhala mientras se encoge.",
    CALMING_INSTRUCTION_BUTTON_TEXT: "Relajación",
    CALMING_TOOLS_PAGE_USE_TOOL: "Inicia la herramienta Relajación.",
    CALMING_TOOLS_PAGE_BOOST:
      "La herramienta Relajación puede ayudar a aumentar tu:",
    CALMING_TOOLS_PAGE_LAST_USED: "La última vez que te relajaste fue",
    CALMING_TOOLS_PAGE_USED: "Tiempo utilizado de la herramienta Relajación",
    CALMING_TOOLS_PAGE_INFO_ONE:
      "Dedica unas cuantas respiraciones lentas y profundas para reducir el estrés y devolver tu atención al momento presente.",
    HEAD_ZONE_TOOLS: "Estado de ánimo",
    HEAD_ZONE_HOW_FEELING: "¿Cómo te sientes?",
    HEAD_ZONE_TOOLS_HELP_TEXT: "Haz clic/arrastra para cambiar",
    HEAD_ZONE_TOOLS_HELP_TEXT_MOBILE: "Toca/arrastra para cambiar",
    HEAD_ZONE_TOOLS_IN_THIS: "EN ESTE ",
    HEAD_ZONE_TOOLS_HEADZONE: "ESTADO DE ÁNIMO",
    HEAD_ZONE_TOOLS_DONE: "Hecho",
    HEAD_ZONE_TOOLS_MORE: "Más",
    HEAD_ZONE_TOOLS_PAGE_INFO_ONE:
      "Entrenar tu estado de ánimo te ayuda a sentirte más positivo sobre las cosas habituales y mejora cómo te sientes en general.",
    HEADZONE_TOOLS_PAGE_BOOST:
      "La herramienta de Estado de ánimo puede ayudar a aumentar tu:",
    HEADZONE_TOOLS_PAGE_USED:
      "Tiempo utilizado de la herramienta Estado de ánimo",
    HEADZONE_TOOLS_PAGE_USE_TOOL: "Iniciar Estado de ánimo",
    HEADZONE_TOOLS_PAGE_CHART_SECTION_TITLE: "Tu historial de Estado de ánimo.",

    HEADZONE_TOOLS_STATES_UNWELL: "Indispuesto",
    HEADZONE_TOOLS_STATES_BURNOUT_TITLE: "INDISPUESTO",

    HEADZONE_TOOLS_STATES_STRESSED: "Estresado",
    HEADZONE_TOOLS_STATES_STRUGGLING: "Luchando",
    HEADZONE_TOOLS_STATES_STRESSED_TITLE: "LUCHANDO",

    HEADZONE_TOOLS_STATES_OKAY: "Satisfecho",
    HEADZONE_TOOLS_STATES_OKAY_TITLE: "SATISFECHO",

    HEADZONE_TOOLS_STATES_GREAT: "Bien",
    HEADZONE_TOOLS_STATES_GREAT_TITLE: "BIEN",

    HEADZONE_TOOLS_STATES_THRIVING: "Excelente",
    HEADZONE_TOOLS_STATES_THRIVING_TITLE: "EXCELENTE",

    HEADZONE_TOOLS_CHART_OVERALL: "General %",
    HEADZONE_TOOLS_CHART_BOX_TITLE: "Tus numeros de Estado de ánimo",
    HEAD_ZONE_TOOLS_CHART_BOX_SUBTITLE:
      "Entrenar tu estado de ánimo te ayuda a sentirte más positivo con respecto a las cosas habituales y mejora tu estado de ánimo en general. Aquí tienes un vistazo a los números de tu estado de ánimo hasta la fecha.",
    HEADZONE_DONUT_CHART_TITLE: "El desglose de tu Estado de ánimo",
    HEADZONE_LINE_CHART_TITLE: "Tu estado de ánimo con el tiempo",
    HEADZONE_LINE_CHART_LEGEND_TITLE: "Puntaje promedio de tu estado de ánimo",
    HEADZONE_LOCKED_TEXT: "Empieza a seguir tu progreso.",
    HEADZONE_VERY_HIGH: "Muy Alto",
    HEADZONE_HIGH: "Alto",
    HEADZONE_MEDIUM: "Medio",
    HEADZONE_LOW: "Bajo",
    HEADZONE_VERY_LOW: "Muy Bajo",

    MINDFULNESS_TOOLS: "Biblioteca de conciencia plena",
    MINDFULNESS_TOOLS_YOUR: "Tu",
    MINDFULNESS_TOOLS_7_DAYS: "7 días de",
    MINDFULNESS_TOOLS_SINGLES: "Individual",
    MINDFULNESS_TOOLS_PACKAGE: "Paquetes",
    MINDFULNESS_TOOLS_MINDFULNESSES: "Conciencia plena",
    MINDFULNESS_TOOLS_PRODUCTIVITY: "CONCIENCIA PLENA PARA LA PRODUCTIVIDAD.",
    MINDFULNESS_TOOLS_GRATITUDE: "7 DÍAS DE GRATITUD",
    MINDFULNESS_TOOLS_SLEEP: "SERIE SOBRE EL SUEÑO",
    MINDFULNESS_TOOLS_TITLE: "Título",
    MINDFULNESS_JUST_BREATHE: "SÓLO RESPIRA",
    MINDFULNESS_EMOTIONS: "DOMINA LAS EMOCIONES",
    MINDFULNESS_TOOLS_PAGE_USED:
      "Has utilizado la herramienta Conciencia plena",
    MINDFULNESS_TOOLS_PAGE_TOTAL_MINUTES: "Total de minutos",
    MINDFULNESS_TOOLS_PAGE_BOOST:
      "La herramienta Conciencia plena puede ayudar a aumentar tu:",
    MINDFULNESS_TOOLS_PAGE_USE_TOOL: "Iniciar Conciencia plena",
    MINDFULNESS_TOOLS_PAGE_NO_STREAK: "¡Empecemos una nueva carrera!",
    MINDFULNESS_TOOLS_PAGE_STREAK: "Racha diaria (día)",
    MINDFULNESS_TOOLS_PAGE_STREAK_DAYS: "día(s)",
    MINDFULNESS_TOOLS_PAGE_INFO_ONE:
      "Dedica un momento a la Conciencia Plena para mejorar el enfoque y reducir el estrés.",
    FOCUS_TOOLS: "Enfoque",
    FOCUS_TOOLS_PAGE_BOOST:
      "La herramienta Enfoque puede ayudarte a aumentar tu:",
    FOCUS_TOOLS_PAGE_USED: "Planes de enfoque total creados",
    FOCUS_TOOLS_PAGE_USE_TOOL: "Iniciar Enfoque",
    FOCUS_TOOLS_LANDING_NEW_PLAN: "Inicia un nuevo plan de enfoque.",
    OPTIMISM_TOOLS: "Optimismo",
    OPTIMISM_TOOLS_ACTIVE: "Desafíos activos de optimismo.",
    OPTIMISM_TOOLS_PAGE_BOOST:
      "La herramienta de Optimismo puede ayudar a impulsar tu",
    OPTIMISM_TOOLS_PAGE_INFO_ONE:
      "Practica la gratitud para enfocar los aspectos positivos y desarrollar una perspectiva más optimista.",
    OPTIMISM_TOOLS_NO_PLANS: "No se ha creado ningún plan de optimismo.",
    OPTIMISM_TOOLS_PAGE_USED: "Desafíos completados",
    OPTIMISM_TOOLS_PAGE_USE_TOOL: "Iniciar Optimismo",
    OPTIMISM_TOOLS_INTRO_TITLE: "Entrena el optimismo.",
    OPTIMISM_TOOLS_INTRO:
      "Es muy fácil. Sólo tienes que establecer y seguir tus propios retos de optimismo. La ciencia ha demostrado que estos retos te ayudarán a sentirte más positivo con respecto a las cosas habituales, incluso mientras mantienes el panorama general cerca del corazón.",
    OPTIMISM_TOOLS_WELCOME: "¡Bienvenido a tu reto de optimismo!",
    OPTIMISM_TOOLS_LENGTH_OF_CHALLENGE: "Elige la longitud de tu desafío.",
    OPTIMISM_TOOLS_BEST_TIME: "¿Cuándo es mejor entrenar el optimismo?",
    OPTIMISM_TOOLS_PROVEN:
      "Esto se ha demostrado que funciona mejor al final de tu día de trabajo.",
    OPTIMISM_TOOLS_GOOD_THINGS: "3 cosas buenas hoy.",
    OPTIMISM_TOOLS_GOOD_THINGS_POSSIBLE:
      "¡Excelente! Ahora, desafíate a tí mismo para pensar en lo que hizo que estas cosas buenas a sucedan.",
    OPTIMISM_TOOLS_PICK:
      "Elige cualquier cosa, absolutamente cualquier cosa de hoy que sea buena.",
    OPTIMISM_TOOLS_GOOD_STUFF: "¡Buen material!",
    OPTIMISM_TOOLS_GOOD_STUFF_DESCRIPTION:
      "Haremos esto de nuevo mañana. Hasta la próxima, sigue tratando de notar las cosas buenas. Cuanto más entrenas positivamente, más natural será.",
    OPTIMISM_TOOLS_GOT_SLEEP: "¡Dormí unas buenas 8 horas!",
    OPTIMISM_TOOLS_BEDTIME_STORY: "Leí un cuento a mi hijo antes de dormir.",
    OPTIMISM_TOOLS_GREAT_WORKOUT: "Tuve un gran entrenamiento.",
    OPTIMISM_TOOLS_WHAT_LED_TO: "¿Que llevó a",
    OPTIMISM_TOOLS_CURRENT_MENU_DONE: "Desafío final",
    OPTIMISM_TOOLS_CURRENT_MENU_PAUSE: "Notificación de pausa",
    OPTIMISM_TOOLS_CURRENT_MENU_START: "Iniciar notificación",
    OPTIMISM_TOOLS_CURRENT_MENU_TIME: "Cambia la hora",
    OPTIMISM_TOOLS_CURRENT_MENU_NEW: "Comienza un nuevo desafío",
    OPTIMISM_TOOLS_CURRENT_MENU_SHOW: "Mostrar desafío actual",
    OPTIMISM_TOOLS_DAYS_CHALLENGE: "Desafío de días",
    OPTIMISM_TOOLS_LANDING_RESUME_CHALLENGE: "Resumen del reto",
    OPTIMISM_TOOLS_LANDING_NO_CHALLENGE: "Buen trabajo, estás al día.",
    OPTIMISM_TOOLS_CHALLENGE_READY: "¡Tu registro de optimismo está listo!",
    OPTIMISM_TOOLS_CHALLENGE_MISSED:
      "Te perdiste esta fecha, pero está bien, aún puedes registrarte.",
    OPTIMISM_TOOLS_CHALLENGE_MISSED_COMPLETELY: "Te perdiste esta fecha.",
    OPTIMISM_TOOLS_CHALLENGE_CHECKED_IN: "S'inscrire",
    OPTIMISM_TOOLS_CHALLENGE_READY_TOMORROW:
      "Tu registro de optimismo está programado para más tarde. ¡Regresa entonces!",
    OPTIMISM_TOOLS_DAY: "Día",
    OPTIMISM_TOOLS_OF: "de",
    OPTIMISM_TOOLS_CURRENT_PLAN: "Plan actual",
    OPTIMISM_TOOLS_TIME_TO_CHECK_IN: "¡Hora de registrarse!",
    OPTIMISM_TOOLS_START_NEW_CONFIRMATION:
      "¿Estás seguro de que quieres terminar tu desafío actual y comenzar uno nuevo?",
    OPTIMISM_TOOLS_STOP_CONFIRMATION:
      "¿Estás seguro de que quieres terminar tu desafío actual?",
    OPTIMISM_TOOLS_CHALLENGE_ALREADY_OVER: "Este reto ya ha terminado.",
    SOLO_STATS_BLOCK_TITLE: "Estadísticas",
    THEME_SELECT_MODAL_HEADING: "Selecciona tu tema.",
    THEME_SELECT_MODAL_SWITCH_LABEL_DEFAULT: "Claro",
    THEME_SELECT_MODAL_SWITCH_LABEL_DEFAULT_DESCRIPTION:
      "Encabezados naranja y reflejos azules, como una fresca mañana de otoño al amanecer ...",
    THEME_SELECT_MODAL_SWITCH_LABEL_NOSTALGIC: "Oscuro",
    THEME_SELECT_MODAL_SWITCH_LABEL_NOSTALGIC_DESCRIPTION:
      "Un tema azul oscuro con reflejos naranja, como la mitad de la noche ...",
    CONTINUE: "Continuar",
    THINKING_TOOLS: "Pensar",
    THINKING_TOOLS_PAGE_INFO_ONE:
      "Cambia tus pensamientos negativos por otros más equilibrados y positivos.",
    THINKING_TOOLS_INTRO_LANDING_TITLE: "Tus registros",
    THINKING_TOOLS_YOUR_LOG_ON: "Tus ideas sobre",
    THINKING_TOOLS_PAGE_BOOST:
      "La herramienta Pensar puede ayudar a aumentar tu:",
    THINKING_TOOLS_PAGE_USED: "Se crean pensamientos equilibrados",
    THINKING_TOOLS_PAGE_USE_TOOL: "Iniciar Pensar",
    THINKING_TOOLS_INPUT_NEW_THOUGHTS: "Nuevos pensamientos",
    THINKING_TOOLS_PRACTICE: "Practica pensar con precisión.",
    THINKING_TOOLS_IDENTIFY:
      "Entrenar tu forma de pensar te ayuda a sentirte más positivo sobre las cosas habituales y mejora tu estado de ánimo general. Harás esto identificando un pensamiento crítico, desafiándolo e intercambiándolo por uno diferente.",
    THINKING_TOOLS_STEP_1: "1. Identificar",
    THINKING_TOOLS_STEP_2: "2. Desafíar",
    THINKING_TOOLS_STEP_2_INSTRUCTIONS:
      "Desafiar tu pensamiento te ayudará a considerar las cosas desde una perspectiva más objetiva y a ver el panorama completo.",
    THINKING_TOOLS_STEP_3: "3. Intercambiar",
    THINKING_TOOLS_TAP: "¿Es este un pensamiento o un hecho?",
    THINKING_TOOLS_CRITICAL: "¿Qué pensamiento crítico o negativo tienes?",
    THINKING_TOOLS_PEOPLE_THINK:
      "p.ej. la gente piensa que soy difícil de tratar",
    THINKING_TOOLS_YOU_IDENTIFIED: "Identificaste esta declaración como un",
    THINKING_TOOLS_YOU_IDENTIFIED_NOT: "NO un ",
    THINKING_TOOLS_YOU_USING_INSIGHTS:
      "Usando estas ideas, escriba una declaración más equilibrada aquí:",
    THINKING_TOOLS_NOT_EVERYONE: "p.ej. No necesito caerle bien a todos.",
    THINKING_TOOLS_WHAT_DOES: "¿Qué dice la evidencia?",
    THINKING_TOOLS_REJECTS_STATEMENT: "Más evidencia RECHAZA esta declaración.",
    THINKING_TOOLS_SUPPORTS_STATEMENT:
      "Más evidencia RESPALDA esta declaración.",
    THINKING_TOOLS_WHAT_WOULD: "¿Qué diría tu mejor amigo?",
    THINKING_TOOLS_MY_BEST_DISAGREE: "Mi mejor amigo estaría en DESACUERDO.",
    THINKING_TOOLS_MY_BEST_AGREE: "Mi mejor amigo estaría DE ACUERDO.",
    THINKING_TOOLS_NOW_EXCHANGE:
      "Ahora cambiarás esto por un pensamiento más equilibrado.",
    THINKING_TOOLS_EXCHANGE: "Identificaste el siguiente pensamiento:",
    THINKING_TOOLS_NOW_EXCHANGE_FOR: "y lo cambiaste por:",
    THINKING_TOOLS_THOUGHT: "PENSAMIENTO",
    THINKING_TOOLS_FACT: "HECHO",
    THINKING_TOOLS_REJECTS: "RECHAZO",
    THINKING_TOOLS_SUPPORTS: "RESPALDO",
    THINKING_TOOLS_AGREE: "DE ACUERDO",
    THINKING_TOOLS_DISAGREE: "DESACUERDO",
    THINKING_TOOLS_GREAT: "¡Gran trabajo!",
    THINKING_TOOLS_RESILIENT: "Las personas resilientes piensan con precisión.",
    THINKING_TOOLS_REMIND: "Recuerda usar el",
    THINKING_TOOLS_REMIND_ICE: "Herramienta Pensar",
    THINKING_TOOLS_REMIND_WHEN:
      "Cuando tienes un pensamiento crítico o negativo.",
    THINKING_TOOLS_REMIND_DOES: "¿Tu pensamiento se relaciona con una tarea?",
    THINKING_TOOLS_REMIND_DOES_CLICK: "Hacer clic",
    THINKING_TOOLS_REMIND_DOES_CREATE: "Para crear un plan de ENFOQUE.",
    THINKING_TOOLS_EXCHANGE_DATE: "Tu cambio de",
    THINKING_TOOLS_LOG_NEGATIVE: "fue tu pensamiento negativo.",
    THINKING_TOOLS_LOG_THOUGHT_NOT:
      "Identificaste esta declaración como un <strong>HECHO</strong>, no un pensamiento.",
    THINKING_TOOLS_LOG_THOUGHT_IS:
      "Identificaste esta declaración como un <strong>PENSAMIENTO</strong>, no un hecho.",
    THINKING_TOOLS_LOG_STATEMENT_SUPPORT:
      "Hay más evidencia de que <strong>RESPALDAS</strong> esta declaración.",
    THINKING_TOOLS_LOG_STATEMENT_NOT_SUPPORT:
      "Hay más evidencia de que <strong>RECHAZAS</strong> esta declaración.",
    THINKING_TOOL_LOG_AGREE:
      "Tu mejor amigo <strong>ESTARÍA DE ACUERDO</strong> con esta declaración.",
    THINKING_TOOL_LOG_DISAGREE:
      "Tu mejor amigo <strong>ESTARÍA EN DESACUERDO</strong> con esta declaración.",
    THINKING_TOOLS_LOG_POSITIVE: "¡Es tu pensamiento mejorado y equilibrado!",
    FOCUS_TOOLS_SET_PLAN: "Establecer un plan de enfoque.",
    FOCUS_TOOLS_ACTIVE: "Tus planes activos de enfoque.",
    FOCUS_TOOLS_SET_PLAN_DESCRIPTION:
      "Un plan de enfoque te ayudará a poner la atención donde se necesita y, muy importante, decidir cómo debes sentirte durante un evento desafiante. Elegir una emoción te ayudará a lograr tus objetivos.",
    FOCUS_TOOLS_PAGE_INFO_ONE:
      "Crea un plan para prepararte para el éxito y alcanzar tus objetivos.",
    FOCUS_TOOLS_PLAN_TITLE: "Título del plan",
    FOCUS_TOOLS_PLAN_TITLE_NAME: "¿Cuál es el nombre de este plan?",
    FOCUS_TOOLS_PLAN_WHAT: "¿Cuál es la situación?",
    FOCUS_TOOLS_PLAN_WHAT_DESCRIPTION:
      "¿Qué es lo que necesita toda tu atención para hacerlo bien?",
    FOCUS_TOOLS_PLAN_HAVE_TO_DO: "¿Que tienes que hacer?",
    FOCUS_TOOLS_PLAN_HAVE_TO_DO_DESCRIPTION:
      "¿Qué necesitas hacer en el momento para mantenerte enfocado?",
    FOCUS_TOOLS_PLAN_HAVE_TO_FEEL: "¿Cómo tienes que sentirte?",
    FOCUS_TOOLS_PLAN_HAVE_TO_FEEL_DESCRIPTION:
      "¿Qué sentimiento te ayudará a mantenerte enfocado en la ejecución?",
    FOCUS_TOOLS_PLAN_WHEN: "¿Cuándo está sucediendo?",
    FOCUS_TOOLS_PLAN_HAPPENED: "Ocurrió el",
    FOCUS_TOOLS_PLAN_START: "Comienza en",
    FOCUS_TOOLS_PLAN_NEXT: "Siguiente en",
    FOCUS_TOOLS_PLAN_WHEN_NOTIFY:
      "Recibirás una notificación 30 minutos antes para recordarte tu plan de enfoque.",
    FOCUS_TOOLS_PLAN_HAVE_TO: "Tengo que...",
    FOCUS_TOOLS_PLAN_NEED_TO: "Necesito...",
    FOCUS_TOOLS_PLAN_IS_SET: "Tu plan está configurado.",
    FOCUS_TOOLS_PLAN_IS_SET_DESCRIPTION:
      "Recibirás una notificación 30 minutos antes de este evento para recordarte tu plan de enfoque. ¡Se necesita menos suerte cuando tienes un plan!",
    FOCUS_TOOLS_INTRO_LANDING_TITLE_UPCOMING: "Principales planes de enfoque",
    FOCUS_TOOLS_INTRO_LANDING_TITLE_PREVIOUS: "Planes de enfoque anteriores",
    FOCUS_TOOLS_INTRO_LANDING_TITLE_NEW: "Nuevo plan de enfoque",
    FOCUS_TOOLS_PLAN_COPY: "Copiar plan",
    FOCUS_TOOLS_PLAN_VIEW: "Ver plan",
    FOCUS_TOOLS_PLAN_DELETE: "Eliminar el plan",
    FOCUS_TOOLS_PLAN_EDIT: "Editar plan",
    FOCUS_TOOLS_DELETE_CONFIRMATION:
      "¿Estás seguro que quieres eliminar este plan de enfoque?",
    MOVE_TOOLS: "Muévete",
    MOVE_TOOLS_PAGE_TOTAL_STEPS: "Total de pasos",
    MOVE_TOOLS_PAGE_TOTAL_DISTANCE: "Distancia total (km)",
    MOVE_TOOLS_PAGE_TOTAL_STAIRS: "Total de escaleras que subiste",
    MOVE_TOOLS_PAGE_TOTAL_CALORIES: "Total de calorías quemadas",
    MOVE_TOOLS_PAGE_USE_TOOL: "Iniciar Muévete",
    MOVE_TOOLS_PAGE_BOOST: "La herramienta Muévete puede ayudar a aumentar tu:",
    MOVE_TOOLS_CHART_STEPS_SERIES_NAME: "Pasos",
    MOVE_TOOLS_CHART_DISTANCE_SERIES_NAME: "Distancia (km)",
    MOVE_TOOLS_CHART_STAIRS_SERIES_NAME: "Escaleras",
    MOVE_TOOLS_CHART_CALORIES_SERIES_NAME: "Calorías",
    MOVE_TOOLS_CHART_STEP_TITLE: "Resultado de pasos/distancia (km)",
    MOVE_TOOLS_CHART_STAIRS_TITLE: "Resultado de escaleras/calorías",
    MOVE_TOOLS_PAGE_INFO_ONE:
      "Moverse ayuda a sentirse más positivo sobre las cosas habituales y mejora tu estado de ánimo general.",
    MOVE_TOOLS_APP_HEADLINE: "Sincronizar tus datos de movimiento",
    MOVE_TOOLS_APP_INFO:
      "¡Instala la aplicación móvil para sincronizar tus datos de movimiento!",
    MOVE_TOOLS_GET_THE_APP: "Baja la aplicación",
    INTRO_BUTTON_TITLE: "Ver introducción",
    LANGUAGE_TITLE: "Idioma",
    LANGUAGE_MODAL_HEADING: "Elige tu idioma",
    CUSTOM_FIELDS_TITLE: "Información del usuario",
    CUSTOM_FIELDS_SUBTITLE: "Completa la pregunta a continuación.",
    CUSTOM_FIELDS_SELECT_PLACEHOLDER: "Seleccione una opción",
    MINUTES_TRAINED: "Minutos trenes",
    LAST_ACTIVITY: "Última actividad",
    STAT_DAILY_PLANS_COMPLETE: "Planes diarios completados",
    STAT_PRACTICES_COMPLETE: "Prácticas completadas",
    STAT_LESSONS_COMPLETE: "Lecciones completadas",
    STAT_POSTS_VIEWED: "Recursos vistos",
    STAT_BALANCED_THOUGHTS: "Pensamientos equilibrados",
    STAT_OPTIMISIM_CHALLENGES: "Desafíos de optimismo",
    STAT_FOCUS_PLANS: "Planes de enfoque",
    STAT_MINDFULNESS_MINUTES: "Minutos de conciencia plena",
    STAT_CALMING_TOOL: "Tiempo de relajación",
    NOTIFICATION_TITLE: "Notificaciones",
    NOTIFICATION_HEADER_STATUS: "Estado",
    NOTIFICATION_HEADER_TITLE: "Título",
    NOTIFICATION_HEADER_DATE: "Fecha",
    NOTIFICATION_HEADER_MARK_ALL_AS_READ: "Marcar todo como leído",
    NOTIFICATION_NONE: "No tienes notificaciones",
    NOTIFICATION_NEW: "¡Nuevo!",
    TOOLS_VIEW_LOGS: "Ver tu historia",
    WEB_NOTIFICATION_NEW: "¡Tienes una nueva notificación!",
    DO_NOT_REPEAT: "No repetir",
    THANK_YOU: "Gracias",
    EVERYDAY: "Todos los días",
    EVERY_WEEKDAY: "Cada día de la semana",
    WEEKLY: "Semanalmente",
    MONTHLY: "Mensual",
    YEARLY: "Anual",
    DELETE: "Eliminar",
    SCORE: "Puntaje",
    SKILL_SCORE: "Puntaje de habilidad",
    DATE_FORMAT: "D MMM YYYY",
    DATE_FORMAT_WITH_TIME: "D MMM YYYY h:mm A",
    DATE_FORMAT_KENDO_DATETIME: "d MMM yyyy h:mm tt",
    DATE_FORMAT_WEEKDAY: "%A, %e %b %Y",
    CHARTS_DAY_FORMAT: "%d-%b",
    SUNDAY: "Dom",
    MONDAY: "Lun",
    TUESDAY: "Mar",
    WEDNESDAY: "Mié",
    THURSDAY: "Jue",
    FRIDAY: "Vie",
    SATURDAY: "Sáb",
    GET_HELP_LABEL: "Consigue ayuda ahora",
    GET_REACH_HELP_LABEL: "Visita el Centro de Ayuda",
    GET_REACH_HELP_TEXT: "Ayuda",
    CONFIRM_OK: "OK",
    CONFIRM_YES: "Sí",
    CONFIRM_NO: "No",
    NOT_NOW: "Ahora no",
    INVITE: "Invitar",
    PUSH_NOTIFICATION_INFO:
      "Activar las notificaciones web push nos permitirá enviarte mensajes importantes sobre tu experiencia en Headversity, como cuando recibes una insignia o es el momento de tu desafío de optimismo. Es posible que tu navegador bloquee estas notificaciones por defecto, así que si tienes problemas para activar las notificaciones, comprueba la configuración de tu navegador.",
    WEB_PUSH_NOTIFICATIONS: "Push Notificaciones",
    HEADVERSITY: "headversity",
    REACH: "REACH",
    ENABLE: "Activar",
    NEW_APP_VERSION: "¡La nueva versión ya está disponible!",
    NEW_APP_VERSION_DESCRIPTION:
      "Haga clic en el botón de abajo para actualizar y continuar con <em>headversity</em>",
    TOOLS_COMING_SOON: "Próximamente",
    TOOLS_COMING_SOON_DESCRIPTION:
      "¡Nuevas herramientas en futuras actualizaciones!",
    MINUTE_VERSION: "Versión Minuto",
    ZONE: "zona",
    TURN_ON_NOTIFICATIONS: "Activar las notificaciones",
    ENABLE_EMAIL_NOTIFICATIONS:
      "Vuelve a suscribirte a las comunicaciones por correo electrónico",
    UNSUBSCRIBE_EMAIL_TITLE: "Te has dado de baja exitosamente.",
    UNSUBSCRIBE_EMAIL_DESCRIPTION_1:
      "Ya no recibirás correos electrónicos de <em>headversity</em>. Si fue un error o cambias de opinión, puedes suscribirte nuevamente en cualquier momento visitando la configuración de tu perfil. Para cualquier pregunta, contáctanos en <a href='mailto:help@headversity.com'>help@headversity.com</a>.",
    WELCOME_SHOW_HIDE_OPTIONS: "Opciones",

    WELCOME_TAKE_CONTROL:
      "Gestiona el estrés, adáptate al cambio y adelántate a la adversidad hoy con nuestro entrenamiento",
    WELCOME_ADJ2: "personalizado",
    WELCOME_ADJ3: "confidencial",
    WELCOME_ADJ_END: "",

    WELCOME_VIDEO: "Aquí tienes un video introductorio rápido.",

    WELCOME_HEADZONE_SELECT_ZONE: "Selecciona el estado de ánimo",
    WELCOME_HEADZONE_SELECT_DESCRIBE: "que mejor describa cómo te sientes.",

    WELCOME_HEADZONE_UNWELL_HELP: "Toque el botón",
    WELCOME_HEADZONE_UNWELL_BUTTON: "en cualquier momento para ver ",
    WELCOME_HEADZONE_UNWELL_VIEW: "recursos de soporte adicionales",
    WELCOME_HEADZONE_UNWELL_PROVIDED: " proporcionados por su organización.",
    WELCOME_HEADSCAN_LOW:
      "Reconocer cómo te sientes es el primer paso para sentirte mejor. Estamos aquí para ti con recursos que pueden ayudarlo a manejar los desafíos de la vida.",
    WELCOME_HEADZONE_HELP_SUCCEED:
      "Esto nos permitirá enviarte recordatorios útiles y periódicos.",

    WELCOME_SKILLS_STARTING: "OK, busquemos el",
    WELCOME_SKILLS_STARTING_POINT: "punto de partida",
    WELCOME_SKILLS_STARTING_JOURNEY: "para tu viaje de salud mental.",
    WELCOME_SKILLS_ALRIGHT_LETS_EXPLORE:
      "Bien, obtengamos una puntuación inicial para la habilidad de ",
    WELCOME_SKILLS_ALRIGHT_LETS_EXPLORE_RESCORE:
      "Bien, obtengamos una puntuación actualizada para la habilidad de ",
    WELCOME_SKILLS_PLEASE_COMPLETE: "Por favor completa estas",
    WELCOME_SKILLS_QUESTIONS: "preguntas",
    WELCOME_SKILLS_TO_CONTINUE: "para seguir aprendiendo",
    WELCOME_SKILLS_QUICK_QUESTIONS:
      "Te haremos algunas preguntas rápidas para identificar tu punto de partida, y luego comenzaremos el entrenamiento.",
    WELCOME_SKILLS_SCORES_OVER_TIME:
      "Aquí tienes un vistazo a tu puntuación a lo largo del tiempo.",

    SCORE_HIGH:
      "Una <b style='font-weight:600'>alta</b> puntuación indica que sobresales en esta habilidad, la aplicas con facilidad y experimentas beneficios significativos como resultado. Continuar entrenando esta habilidad puede ayudarte a mantener tu alto nivel de competencia y adaptarte a nuevos desafíos.",
    SCORE_MED_HIGH:
      "Una <b style='font-weight:600'>media-alta</b> puntuación indica que puedes ser bastante hábil en esta habilidad, aplicándola efectivamente en la mayoría de los escenarios. Entrenarte puede ayudarte a aprovechar aún más esta habilidad y obtener mayores beneficios en tu vida personal y profesional.",
    SCORE_MED:
      "Una <b style='font-weight:600'>media</b> puntuación indica que puedes tener un conocimiento razonable de esta habilidad y aplicarla en ciertas situaciones. Entrenarte puede ayudarte a refinar tus habilidades y hacer que seas más consistente en su aplicación.",
    SCORE_MED_LOW:
      "Una <b style='font-weight:600'>media-baja</b> puntuación indica que puedes tener alguna habilidad en esta área, pero a menudo enfrentas desafíos e inconsistencias en su uso. Entrenarte puede ayudarte a construir una base sólida.",
    SCORE_LOW:
      "Una <b style='font-weight:600'>baja</b> puntuación indica que puedes tener dificultades con esta habilidad. Entrenarte puede ayudarte a superar desafíos y mejorar significativamente tu efectividad.",

    WELCOME_COMPLETE_SCORE_IS: "Aquí está tu puntuación en ",
    WELCOME_COMPLETE_SCORES_ARE: "Aquí están tus puntuaciones, ",
    WELCOME_COMPLETE_NOW_LETS: "Ahora vamos a",

    HEADZONE_YOURE_IN_THE: "Estás en la",
    HEADZONE_WELCOME_TO_THE: "Bienvenido/a al",
    HEADZONE_YOUVE_REACHED_THE: "Has llegado al",

    TOUR_MENU: "Ver recorrido",
    TOUR_MENU_TITLE: "Moverse",
    TOUR_MENU_APP_DESCRIPTION: "Alternar entre entrenamiento SOLO y TEAM.",
    TOUR_MENU_DESCRIPTION:
      "Todo lo que necesitas para ser su ser más resiliente, incluidas todas las lecciones y herramientas, se puede encontrar en el menú.",
    TOUR_MENU_DESCRIPTION_TEAM:
      "SOLO, TEAM, y todo lo que necesitas para ser su ser más resiliente se puede encontrar en el menú.",
    TOUR_MENU_DESCRIPTION_REACH:
      "Alternar entre las funciones administrativas de REACH y los entrenamientos de resiliencia.",
    TOUR_GET_HELP_DESCRIPTION:
      "Al tocar este icono, te conectará con los recursos de salud mental proporcionados por tu organización, para que siempre estés a un solo clic de recibir ayuda.",
    TOUR_GET_REACH_HELP_DESCRIPTION:
      "Acceda al Centro de Ayuda para obtener instrucciones detalladas sobre todo lo relacionado con REACH.",
    TOUR_DAILY_THREE_DESCRIPTION:
      "Cada día, te sugeriremos tres actividades basadas en tu búsqueda. ¡Completa estas y sube de nivel!",
    TOUR_RECOMMENDED_DESCRIPTION:
      "¡Prueba estas actividades adicionales y sigue subiendo de nivel!",
    DAILY_THREE_TITLE: "Tus tres diarios",
    CERT_SECTION: "Sección",
    CERT_GETTING_STARTED: "Empezar",
    CERT_CHECKPOINT: "Punto de control",
    CERT_REFLECTIONS: "Reflexiones",
    CERT_FOR_A_QUICK_REMINDER: "Para un recordatorio rápido, puedes",
    CERT_REVIEW_THE_LESSON: "revisar la lección",
    CERT_BEFORE_YOU_PROCEED: "antes de continuar.",
    CERT_NOT_QUITE: "No del todo... por favor, inténtalo de nuevo.",
    CERT_TIME_REMAINING: "Tiempo restante: {0} minutos",
    CERT_LOGO: "https://cdn.headversity.com/app/cert/cert_logo_es.png",
    TEAM_LOGO: "https://cdn.headversity.com/app/resources/other/team_es.png",
    DAILY_THREE_DESCRIPTION: "Empieza con estas actividades",
    DAILY_THREE_COMPLETE: "¡Lo hiciste muy bien!",
    DAILY_THREE_COMPLETE_EXTRA:
      "¡Lo hiciste muy bien! Has completado el plan diario de hoy.",
    DAILY_THREE_COMPLETE_CTA_2:
      "Seguir entrenando tus habilidades, y vuelve mañana para tu próximo plan.",
    DAILY_THREE_TRACK_HEADZONE: "Monitorea tu estado de ánimo",
    DAILY_THREE_RESILIENCE_RESOURCE: "Ver un recurso de resiliencia",
    DAILY_THREE_COMPLETE_LESSON: "Completa un micro-lección",
    DAILY_THREE_UNLOCK: "Desbloquear",
    DAILY_THREE_UNLOCK_SKILL: "Desbloquear una habilidad",
    DAILY_THREE_ALL_COMPLETE: "¡Completaste todo!",
    DAILY_THREE_COMPLETE_OPTIMISM: "¡Ponte optimista!",
    DAILY_THREE_REDO_SCORE: "Hacer una evaluación de resiliencia",
    DAILY_THREE_COMPLETE_A: "Completa una ",
    DAILY_THREE_VIEW_A: "Ver un ",
    DAILY_THREE_MICRO_LESSON_DESCRIPTION:
      "Experiencias de aprendizaje de 5 minutos",
    DAILY_THREE_NANO_PRACTICE_DESCRIPTION:
      "Prácticas de resiliencia diarias de un minuto",
    DAILY_THREE_POST_DESCRIPTION:
      "Publicaciones semanales para ayudarlo a mantenerse al día",
    DAILY_THREE_POST_SHORT_DESCRIPTION: "Publicaciones semanales",

    RESILIENCE_RESOURCE: "Entrada de resiliencia",
    MICRO_LESSON: "Leccion",
    MICRO_LESSONS: "Lecciones",
    MORE_ACTIVITIES: "Otras actividades",

    NEXT_UP: "Siguiente",
    SEE_ALL_SKILLS: "Ver todas las habilidades",
    SEE_ALL_NANO_PRACTICES: "Ver todas las prácticas",
    SEE_ALL_LESSONS: "Ver todas las Lecciones",
    SEE_ALL_TOOLS: "Ver todas las herramientas",
    SEE_ALL_TRAININGS: "Ver todos los entrenamientos de $",
    OVER_TIME: "con el tiempo",
    SEE_PROGRESS_OVER_TIME:
      "Verá su progreso a lo largo del tiempo después de obtener puntajes en esta habilidad en varios días.",

    UNLOCK_SKILL: "Desbloquear la habilidad",
    CLICK_TO: "Haz clic para ",
    TAP_TO: "Toca para ",
    CLICK_TO_UNLOCK: "desbloquear esta habilidad",
    CLICK_TO_REDO_SCORE: "rehacer tu evaluación",
    CLICK_TO_SEE_DETAILS: "ver más detalles",
    YOUR_ACTIVITY_LEVEL: "Su nivel de actividad es",
    OVER_THE_LAST: "durante los últimos 30 días.",
    SEE_MORE_DETAILS: "Ver más detalles de <b>{0}</b>",

    LOW: "Bajo",
    MEDIUM: "Medio",
    HIGH: "Alto",
    UP: "Arriba",
    DOWN: "Abajo",
    FLAT: "Plano",
    TODAY: "Hoy",
    MONTH: "Mes",
    YESTERDAY: "Ayer",
    DAYS_AGO: "días",
    MORE_THAN: "Mas que",
    ON: "en",

    TRAININGS: "Entrenamientos",
    NANO_PRACTICE: "Práctica",
    NANO_PRACTICES: "Prácticas",
    NANO_PRACTICES_ALL: "Todas las Prácticas",
    NANO_PRACTICES_DESCRIPTION:
      "Una serie de prácticas de resiliencia diarias de un minuto.",
    NANO_PRACTICES_FEATURED_TITLE: "Prácticas destacadas",
    NANO_PRACTICES_FEATURED_DESCRIPTION:
      "Prácticas de resiliencia de un minuto cuidadosamente seleccionadas para ti.",
    NANO_PRACTICES_HEADER_DESCRIPTION:
      "Desarrolla tu resiliencia con prácticas diarias de un minuto",
    NANO_PRACTICES_MODAL_TIPS_TITLE: "Consejos",
    NANO_PRACTICE_FEEDBACK_1: "Encontré útil esta práctica",
    NANO_PRACTICE_FEEDBACK_2: "Esta práctica ha mejorado mi perspectiv",
    NANO_PRACTICE_TRY_DIFFERENT_PROMPTS: "Prueba diferentes prácticas",
    NANO_PRACTICE_AI_NOTICE:
      "Esta práctica utiliza IA para proporcionar opciones adicionales y resúmenes. Como con todos los datos, <i>headversity</i> aplica sus rigurosos niveles de normas de privacidad y seguridad a la IA, y no se utiliza información personal. Si tienes alguna pregunta o inquietud, háznoslo saber en <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>",

    HOW_DOES_IT_WORK: "¿<b>Cómo</b> funciona?",
    NICE_WORK: "Bien hecho",
    NEXT_UP_IN: "Siguiente en",
    NEXT_PRACTICE: "Siguiente",
    YOUVE_COMPLETED: "Has completado esta práctica",
    MOST_RECENTLY: "la más recientemente",
    AUTO_PLAY: "Auto-reproducción",

    PROFILE_YOU_HAVE: "Tienes",
    PROFILE_UNREAD_NOTIFICATION: "notificación sin leer",
    PROFILE_UNREAD_NOTIFICATIONS: "notificaciones sin leer",
    PROFILE_VIEW_NOTIFICATIONS: "Mostrar notificaciones",
    PROFILE_CHANGE_LANGUAGE: "Cambiar idioma",
    PROFILE_CHANGE_THEME: "Cambiar de tema",
    PROFILE_CHANGE_HIERARCHY: "Asociación de cambio",
    PROFILE_UPDATE_NAME: "Actualizar nombre",
    PROFILE_ASSOCIATION_SAVED: "Asociación guardada",
    PROFILE_ASSOCIATION: "Asociación",
    PROFILE_ASSOCIATION_SEARCH: "Buscar",
    PROFILE_ASSOCIATION_INSTRUCTION:
      "Seleccione su(s) asociación(es) de la siguiente lista:",
    PROFILE_CHANGE_PASSWORD: "Cambiar contraseña",
    PROFILE_CHANGE_PASSWORD_EMAIL:
      "Te enviaremos un enlace por correo electrónico con instrucciones para cambiar tu contraseña.",
    PROFILE_SEND_EMAIL: "Enviar",
    NAME_UPDATED: "Tu nombre ha sido actualizado.",

    SKILLS_DESCRIPTION_CORE:
      "Las habilidades que te hacen ser quien eres y te impulsan hacia tus metas.",
    SKILLS_DESCRIPTION_WORK:
      "Las habilidades que te ayudan a rendir al máximo y alcanzar tu potencial profesional.",
    SKILLS_DESCRIPTION_HOME:
      "Las habilidades que te empoderan para crear un entorno de vida óptimo.",

    TEAM_PATH_TITLE: "Caminos",
    TEAM_PATH_DESCRIPTION: "Entrena con tu equipo para superar cualquier reto",
    TEAM_PATH_STATS_TITLE: "Caminos completados",
    TEAM_TEAM_LESSON_TITLE: "Presentaciones completadas",
    TEAM_TEAM_LESSON_DESCRIPTION: "Selecciona una presentación.",
    REACH_TRIAL: " (Ensayo)",
    REACH_HELP_RESOURCES_PHONE: "Teléfono",
    REACH_HELP_RESOURCES_TEXT: "Texto",
    REACH_HELP_RESOURCES_IOS: "iOS",
    REACH_HELP_RESOURCES_ANDROID: "Android",
    REACH_HELP_RESOURCES_WEB: "Web",
    REACH_CERT_SELECT: "Seleccionar Certificaciones",
    REACH_NODE_SELECT: "Seleccionar nodos",
    REACH_HIDDEN_NODE: "Nodo (oculto)",
    REACH_CERT_COMPLETION_STATUS: "Estado de Finalización",
    REACH_CERT_COMPLETION_DATE: "Fecha de Terminación",
    REACH_CERT_STATUS_DONE: "Completado",
    REACH_CERT_STATUS_IN_PROGRESS: "En Progreso",
    REACH_CERT_STATUS_NOT_STARTED: "No Comenzado",
    REACH_CERT_STATUS_NOT_ASSIGNED: "No Asignado",
    REACH_CERT_NAME: "Nombre de la Certificación",
    REACH_CERT_STATUS: "Estado de la Certificación",
    REACH_HIERARCHY_INVALID_SORT_ORDER: "Orden de clasificación no válido",
    REACH_HIERARCHY_NODE_SORT_ORDER: "Orden de clasificación",
    REACH_HIERARCHY_NODE_NOT_SELECTABLE: "No seleccionable",
    REACH_HIERARCHY_UNASSOCIATED: "no asociado",
    REACH_HIERARCHIES: "Jerarquías",
    REACH_HIERARCHY_DOWNLOAD: "Descargar jerarquía",
    REACH_HIERARCHY_SNAPSHOT_RESTORE_SUCCESS:
      "Restauración de instantánea exitosa",
    REACH_RENAME: "rebautizar",
    REACH_HIERARCHY_NODE_EDIT: "Editar nodo",
    REACH_HIERARCHY_NEW: "Nueva jerarquía",
    REACH_HIERARCHY_DOWNLOAD_CSV_FILE_NAME: "jerarquía",
    REACH_PROMOTE: "Promover",
    REACH_INSIGHTS: "Informes",
    REACH_MY_ORG: "Mi org",
    REACH_PROMO_DESCRIPTION:
      "Explora y busca todos los materiales promocionales",
    REACH_MORE_ASSETS_HOME:
      "Ver todos los materiales promocionales disponibles.",
    REACH_TEAM_USER_GUIDE: "Guía para el usuario de TEAM",
    REACH_SOLO_USER_GUIDE: "Guía para el usuario de SOLO",
    REACH_GETTING_STARTED: "Primeros pasos",
    REACH_GETTING_STARTED_TOUR_DESCRIPTION:
      "Tutoriales rápidos para aprovechar al máximo SOLO y TEAM para tu organización. ¡Presiona play para empezar!",
    REACH_GETTING_STARTED_DESCRIPTION:
      "Aprende más y comienza con <em>headversity</em> con guías completas para tus productos comprados.",
    REACH_WELCOME_TO: "Bienvenido a ",
    REACH_ADDITIONAL_RESOURCES_GUIDE: "Recursos adicionales",
    REACH_RECOMMENDED_ASSETS: "Materiales recomendados",
    REACH_RECOMMENDED_ASSETS_TOUR_DESCRIPTION:
      "Contenido seleccionado especialmente para ti según tus objetivos y el uso que haces como empleado.",
    REACH_HOME_DESCRIPTION:
      "Explora los materiales para lanzar y promocionar <em>headversity</em> fácilmente dentro de tu organización",
    REACH_HOME_INSIGHTS_DESCRIPTION:
      "Un breve vistazo a cómo interactúan tus empleados con <em>headversity</em>.",
    REACH_HOME_SEE_ALL_INSIGHTS: "Ver todos los informes",
    REACH_SEE_ALL_ASSETS: "Ver todo el material",
    REACH_POSTER: "Póster",
    REACH_SOCIAL_MEDIA_POST: "Publicación en redes sociales",
    REACH_EMAIL_TEMPLATE: "Plantilla de correo electrónico",
    OTHER: "Otro",
    REACH_SUBJECT_LINE: "Asunto:",
    REACH_SHARE: "Compartir",
    REACH_SHARE_ON_LINKEDIN: "Compartir en LinkedIn",
    REACH_SHARE_ON_YAMMER: "Compartir en Yammer",
    REACH_DOWNLOAD: "Descargar",
    REACH_SKILLS_ALL_SKILLS: "Todas las  habilidades",
    REACH_SKILLS_GENERIC: "Genérico",
    REACH_LANGUAGES_ALL_LANGUAGES: "Todos los idiomas",
    REACH_ASSET_TYPE_ALL_TYPES: "Todos los tipos de contenido",
    REACH_PRODUCT_TYPE_ALL_TYPES: "Todos los productos",
    REACH_CLEAR_FILTERS: "Borrar filtros",
    REACH_CUSTOM_CONTENT: "Contenido personalizado",
    REACH_PROMO_NOT_FOUND:
      "El recurso no existe. Por favor, actualiza la página.",
    REACH_NO_ASSETS: "Sin contenido",
    REACH_NEW: "Nuevo",
    REACH_CUSTOM: "Personalizado",
    REACH_CALENDAR_VIEW: "Calendario",
    REACH_LIST_VIEW: "Lista",
    REACH_CALENDAR_DESCRIPTION:
      "Planifica las actividades promocionales mediante un calendario mensual",
    REACH_PROFILE_DESCRIPTION: "Revisa la configuración de tu perfil",
    REACH_USERS_DESCRIPTION:
      "Administra los usuarios de tu organización y sus permisos",
    REACH_ORGANIZATIONS_LOGO: "Logo",
    REACH_ORGANIZATIONS_SECTOR: "Sector",
    REACH_ORGANIZATIONS_PROPERTIES: "Propiedades de la organización",
    REACH_ORGANIZATIONS_CHOOSE_SECTOR: "Elegir sector",
    REACH_ORGANIZATIONS_NAME_DESCRIPTION:
      "¿Cómo deberíamos llamar a su organización?",
    REACH_ORGANIZATIONS_SECTOR_DESCRIPTION:
      "Podemos proporcionar información más específica al conocer el sector específico de su organización.",
    REACH_ORGANIZATIONS_LOGO_DESCRIPTION:
      "Hagamos que su organización sea instantáneamente reconocible para sus miembros. Cargue el logotipo de su empresa para disfrutar de los beneficios de la marca compartida en su experiencia de bienvenida a miembros. <br/><br/> Cargue un logotipo en formato PNG o JPG con dimensiones de 450x310 píxeles. Si utiliza PNG, asegúrese de que tenga un fondo transparente; para JPG, el fondo debe ser blanco.",
    REACH_ORGANIZATIONS_DESCRIPTION:
      "Gestione la información, las comunicaciones y los recursos de ayuda de su organización",
    REACH_GOALS_SELECTION_SCREEN_SAVE: "Guardar",
    REACH_GOALS_WIN_THEME_PURSUE: "Los usuarios buscarán {0} y {1}",
    REACH_GOALS_SELECTION_SCREEN_TITLE:
      "¿Cómo podemos apoyar mejor sus necesidades?",
    REACH_GOALS_SELECTION_SCREEN_DESCRIPTION:
      "¿Qué resultados espera lograr con headversity? Tiene la flexibilidad de seleccionar múltiples objetivos si lo desea, o puede optar por no hacerlo. No establecer un objetivo permite a los usuarios explorar sus propios intereses de aprendizaje de manera independiente sin ninguna área recomendada de enfoque alineada con las prioridades de la organización.",
    REACH_GOALS_INITIAL_SCREEN_TITLE:
      "¡Desbloquear el potencial de tu organización acaba de volverse más sencillo!",
    REACH_GOALS_INITIAL_SCREEN_DESCRIPTION:
      "Independientemente de que tu organización tenga como objetivo aumentar la confianza, reducir el estrés, profundizar conexiones, o mucho más, seleccione metas organizacionales para enfocarse en lo más importante.",
    REACH_GOALS_INITIAL_SCREEN_IMAGE:
      "https://cdn.headversity.com/app/interstitial/focuses-es.png",
    REACH_SCORMS_HEADER_TITLE: "Paquetes SCORM",
    REACH_SCORMS_HEADER_DESCRIPTION: "Descarga paquetes SCORM para tu LMS",
    REACH_SCORMS_TITLE: "Integraciones LMS",
    REACH_SCORMS_SHOW_INSTRUCTIONS: "Mostrar instrucciones",
    REACH_SCORMS_HIDE_INSTRUCTIONS: "Ocultar instrucciones",
    REACH_GOALS_TITLE: "Metas",
    REACH_GOALS_EDIT_GOALS: "Editar Metas",
    REACH_GOALS_SECTION_TITLE: "Metas Organizacionales",
    REACH_GOALS_SECTION_DESCRIPTION:
      "Ajuste fino de la formación de tu organización para enfocarse en las áreas más necesarias.",
    REACH_GOALS_DESCRIPTION: "Gestiona los objetivos de tu organización",
    REACH_SCORMS_DESCRIPTION:
      "Cada uno de nuestros programas CERT fue diseñado para ser compatible con los estándares del Modelo de Referencia de Objetos de Contenido Compartible (SCORM). Esto facilita proporcionar CERT en el Sistema de Gestión de Aprendizaje (LMS) de su elección. Haga clic en el programa objetivo para obtener instrucciones y acceso a archivos.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_1:
      "Este programa CERT es compatible con los estándares del Modelo de Referencia de Objeto de Contenido Compartible (SCORM) para integrarse en el Sistema de Gestión de Aprendizaje de su elección basado en las siguientes suposiciones:",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_2:
      "Su LMS y la infraestructura de su organización permiten la comunicación hacia el exterior. Esto es generalmente el caso y puede ser probado enlazando a cualquier dirección web desde dentro del LMS. Si actualmente puede enlazar fuera de su LMS y Red, se permite la comunicación hacia el exterior.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3:
      "Durante el proceso de configuración del curso SCORM, se le debería proporcionar la oportunidad de Subir un Archivo. Seleccione el archivo descargado obtenido en el paso 1 para completar la configuración",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3_A:
      "Es posible que su LMS le pida que proporcione detalles adicionales. Complete como lo haría normalmente.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3_B:
      "El código incluido asegura que la pestaña del navegador LMS permanezca abierta además de la página de acceso al programa. La pestaña del navegador LMS debe permanecer para facilitar la transferencia de retorno del estado de finalización.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_4:
      "Así es como puede integrar el programa CERT en el LMS de su organización:",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_5:
      "Si tiene alguna pregunta o inquietud, contáctenos en ",
    REACH_SCORMS_INSTRUCTIONS_STEP_1:
      "Haga clic en el enlace de arriba para descargar un archivo SCORM empaquetado. No es necesario descomprimir este archivo. El proceso de descompresión se manejará dentro de su LMS en los próximos pasos.",
    REACH_SCORMS_INSTRUCTIONS_STEP_2:
      "Cree un nuevo curso SCORM dentro de su LMS en los próximos pasos.",
    REACH_SCORMS_INSTRUCTIONS_STEP_3: "Asigne el paquete SCORM a sus usuarios.",
    REACH_SCORMS_INSTRUCTIONS_STEP_4:
      "Inicie el programa como usuario para probar la conexión y el flujo.",
    REACH_HELP_RESOURCE_NO_RESOURCES: "No has añadido ningún recurso todavía.",
    REACH_HELP_RESOURCE_INVITE_CODE_DESCRIPTION:
      "<em>headversity</em> no es solo para ti—¡tus familiares también pueden beneficiarse! Comparte el regalo de la salud mental proactiva y el entrenamiento en resiliencia con ellos. Ya sea para el trabajo o la vida cotidiana, <em>headversity</em> ayuda a todos a pensar y sentirse lo mejor posible. ¡Comparte este enlace con tus seres queridos hoy!",
    REACH_HELP_RESOURCE_INVITE_CODE_SHARE_LINK: "Copiar enlace para compartir",
    REACH_HELP_RESOURCE_ADD_NEW: "Añadir nuevo recurso",
    REACH_HELP_RESOURCE_DELETE: "Eliminar recurso",
    REACH_HELP_RESOURCE_MODAL_NAME: "Nombre del recurso",
    REACH_HELP_RESOURCE_MODAL_DESCRIPTION: "Descripción del recurso",
    REACH_HELP_RESOURCE_MODAL_LOGO: "Logo del recurso",
    REACH_HELP_RESOURCE_MODAL_PHONE_NUMBER: "Número de teléfono",
    REACH_HELP_RESOURCE_MODAL_TEXT_NUMBER: "Número de texto",
    REACH_HELP_RESOURCE_MODAL_IOS_APP_LINK: "Enlace de la aplicación IOS",
    REACH_HELP_RESOURCE_MODAL_ANDROID_APP_LINK:
      "Enlace de la aplicación de Android",
    REACH_HELP_RESOURCE_MODAL_WEBSITE_LINK: "Enlace de página web",
    REACH_HELP_RESOURCE_MODAL_SAVE: "Crear recurso de ayuda",
    REACH_HELP_RESOURCE: "Recurso de ayuda",
    REACH_HELP_RESOURCES_TITLE: "Recursos de ayuda",
    REACH_HELP_RESOURCES_GROUP_CONFIGURATION:
      "Configuración para el grupo de recursos de ayuda",
    REACH_HELP_RESOURCES_DESCRIPTION:
      "Apoyo siempre accesible al alcance de los miembros.",
    REACH_HELP_RESOURCE_DRAG_DROP: `Arrastre y suelte o <u>elija un archivo</u> para cargar su imagen`,
    REACH_HELP_RESOURCES_DESKTOP: "Escritorio",
    REACH_HELP_RESOURCES_PREVIEW_INSTRUCTIONS:
      "Seleccione un idioma y una plataforma para obtener una vista previa de los recursos de ayuda disponibles para su equipo.",
    REACH_INSIGHTS_BEFORE_CERTIFICATION: "Antes de la Certificación",
    REACH_INSIGHTS_AFTER_CERTIFICATION: "Después de la Certificación",
    REACH_INSIGHTS_STRONG_AGREEMENT: "Fuerte acuerdo",
    REACH_INSIGHTS_NEUTRAL_AGREEMENT: "Acuerdo neutral",
    REACH_INSIGHTS_LOW_AGREEMENT: "Acuerdo bajo",
    REACH_INSIGHTS_NO_DATA_DESCRIPTION:
      "Esta sección está oculta para proteger el anonimato de los usuarios. La desbloquearemos una vez que tengamos suficientes datos. Vuelve más tarde para ver las actualizaciones.",
    REACH_INSIGHTS_NO_STRENGTHS_DATA_DESCRIPTION:
      "Actualmente ninguna pregunta supera el benchmark de referencia",
    REACH_INSIGHTS_NO_IMPROVEMENTS_DATA_DESCRIPTION:
      "Actualmente ninguna pregunta está por debajo del benchmark de referencia",
    REACH_INSIGHTS_DESCRIPTION:
      "Obtenga información sobre cómo interactúan sus empleados con <em>headversidad</em> y qué pasos puede tomar a continuación",
    REACH_USERS: "Usuarios",
    REACH_USER_BASE: "Base de usuarios",
    REACH_VIEW_ALL_USERS: "Ver todos los usuarios",
    REACH_CHOOSE_GOALS: "Elija Metas",
    REACH_ORGANIZATIONS: "Organizaciones",
    REACH_WELCOME_DESCRIPTION: "Tu paquete de bienvenida",
    REACH_ADDITIONAL_RESOURCES_DESCRIPTION: "Todos nuestros servicios",
    REACH_FEATURED_ASSETS_DESCRIPTION:
      "Contenido seleccionado especialmente para ti.",
    REACH_TEAM_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/team-user-guide-es.jpg",
    REACH_SOLO_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/solo-user-guide-es.jpg",
    REACH_WELCOME_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/welcome-guide-es.jpg",
    REACH_SUPPORT_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/and-more-es.jpg",
    REACH_WELCOME_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/082FEC4D-DCB6-43B4-8982-AE6532CD110C.pdf",
    REACH_TEAM_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/00891999-B1B9-46DD-9C05-10CC79AE0EF6.pdf",
    REACH_SOLO_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/3568742A-D669-4E5D-9DC8-33B9FACF351B.pdf",
    REACH_SUPPORT_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/C9C0B353-C678-4A63-B4E9-DE21DC892DC4.pdf",
    REACH_WELCOME_MODAL_FIRST_PARAGRAPH: `REACH ofrece un conjunto de herramientas administrativas para simplificar los esfuerzos administrativos en apoyar, promover y reportar el impacto de <em>headversity</em> en tu organización. Como usuario administrador, REACH te da el poder de fomentar una organización próspera, comprometida y resiliente.`,
    REACH_WELCOME_MODAL_SECOND_PARAGRAPH: `
<p style="align: left;">Aquí está cómo empezar:</p>
<ul style="list-style-type: disc; margin-left: 20px; margin-bottom: 8px; margin-top: 8px;">
    <li>Completa tu guía de inicio rápido para configurar tu organización.</li>
    <li>Explora REACH para descubrir funciones intuitivas y familiarizarte con la plataforma.</li>
    <li>Visita el Centro de Ayuda para recursos adicionales y soporte.</li>
</ul>
<p style="align: left; margin-top: 20px;">Estamos aquí para apoyarte en hacer un impacto positivo dentro de tu organización.</p>
`,
    REACH_SOCIAL_MEDIA_INSTRUCTION:
      'Comparta en LinkedIn o Yammer, o haz clic en "Copiar texto" y "Descargar" para que sea tuyo.',
    REACH_COPY_PASSWORD_RESET_LINK:
      "Haz clic aquí para copiar el enlace de restablecimiento de contraseña",
    REACH_COPY_TEXT: "Copiar texto",
    REACH_USERS_ACTIONS: "Acciones",
    REACH_USERS_BULK_UPLOAD: "Carga masiva de usuarios",
    REACH_USERS_CHANGE_FILE: "Lista de cambios para los usuarios",
    REACH_USERS_BULK_FULL_LIST: "Lista completa de usuarios",
    REACH_USERS_BULK_UPLOAD_CONFIRM: "Confirmar carga masiva de usuarios",
    REACH_CLICK_OR_DRAG: "Haga clic o suelte un archivo csv aquí.",
    REACH_USER_ROLE: "Rol",
    REACH_INVITE_CODE: "Código de invitación",
    REACH_ACTIVATION_EMAIL_SEND_DATE: "Fecha de envío del correo de activación",
    REACH_USERS_OPTIONAL: "opcional",
    REACH_USERS_EMAIL_SENT_DATE: "Fecha de envío del correo electrónico",
    REACH_USERS_ADD_USER_TOOLTIP:
      "Si no se especifica, el usuario recibirá un correo electrónico de activación inmediatamente.",
    REACH_USER_DELETE_DESCRIPTION: "¿Estás seguro de que quieres eliminar {0}?",
    REACH_USER_DELETE_SUCCESS: "El usuario fue eliminado con éxito.",
    REACH_USER_EDIT_SUCCESS: "El usuario se actualizó con éxito.",
    REACH_USER_ADD_SUCCESS: "El usuario fue creado con éxito.",
    REACH_USER_INVALID_EMAIL_ADDRESS: "Correo electrónico no válido",
    REACH_USER_INVALID_URL: "URL no válida",
    REACH_USER_INVALID_FULL_NAME:
      "El nombre completo debe tener al menos 2 palabras.",
    REACH_USER_NO_CERT_ASSIGNMENTS_SELECTED:
      "Por favor, seleccione al menos una certificación para asignar.",
    REACH_USER_EMAIL_ALREADY_EXISTS: "El correo electrónico ya existe",
    REACH_USER_CERT_NOT_ASSIGNED: "Certificado no asignado a la empresa",
    REACH_USER_SEARCH: "Buscar un usuario",
    REACH_NO_USERS_FOUND: "No se encontraron usuarios.",
    REACH_USER: "Usuario",
    REACH_USER_USERS_PER_PAGE: "Usuarios por página:",
    REACH_USERS_ADD_USER: "Añadir usuario",
    REACH_USERS_DOWNLOAD_REPORT: "Descargar Informe",
    REACH_USERS_DOWNLOAD_CSV_FILE_NAME: "usuarios",
    REACH_USERS_UPLOAD_INSTRUCTIONS_1:
      "Para importar usuarios, utiliza la plantilla siguiente para crear un archivo CSV. La plantilla contiene algunas filas con datos de ejemplo, así que deberás eliminar estas filas cuando crees tu archivo.",
    REACH_USERS_UPLOAD_INSTRUCTIONS_2:
      "Cualquier columna adicional que especifiques se añadirá como datos adicionales para cada usuario.",
    REACH_USERS_DOWNLOAD_TEMPLATE: "Descargar plantilla",
    REACH_USERS_UPLOAD_YOUR_FILES: "Sube tus archivos",
    REACH_USERS_UPLOAD_STEP_1_TITLE: "Importación masiva de usuarios",
    REACH_USERS_UPLOAD_STEP_2_TITLE: "Cargar el Archivo",
    REACH_USERS_UPLOAD_STEP_3_TITLE: "Asignar una CERT",
    REACH_USERS_IMPORT_TYPE: "Tipo de importación",
    REACH_USERS_ASSIGN: "Asignar",
    REACH_USERS_WAS_SUCCESSFULLY_ASSIGNED: "fue asignado con éxito.",
    REACH_USERS_CHOOSE_A_CERT: "Elija una CERT",
    REACH_USERS_ADD_ANOTHER_CERT: "Añadir otro CERT",
    REACH_USERS_SKIP_FOR_NOW: "Omitir por ahora",
    REACH_USERS_FILE_ERRORS: "Hay errores en tu archivo:",
    REACH_USERS_CORRECT_CSV:
      "Abra su archivo CSV y corrija estos cambios para procesar exitosamente su archivo de usuario.",
    REACH_USERS_FILE_READY:
      "Su archivo de usuario está listo para ser enviado.",
    REACH_USERS_USERS_ADDED_UPDATED: "Usuarios agregados/actualizados",
    REACH_USERS_USERS_REMOVED: "Usuarios eliminados",
    REACH_USERS_NEW_CUSTOM_FIELDS: "Nuevos campos personalizados",
    REACH_USERS_CERTS_ASSIGNED: "CERTs asignadas",
    REACH_USERS_CERTS_COMPLETED: "CERTs Completadas",
    REACH_USERS_CERTS: "certificación(es)",
    REACH_USERS_BACK_TO_UPLOAD_PAGE: "Volver a la página de carga",
    REACH_USERS_UPLOAD_STARTED: "La carga del archivo ha comenzado.",
    REACH_USERS_ELIGIBILITY_JOB_SUCCESS:
      "Trabajo de archivo de elegibilidad exitoso.",
    REACH_USERS_ELIGIBILITY_JOB_ERROR:
      "El trabajo del archivo de elegibilidad finalizó con errores.",
    REACH_USERS_UPLOADING_WAIT: "Cargando - Por favor espere",
    REACH_USERS_PROCESSING_STARTED:
      "El procesamiento del archivo ha comenzado.",
    REACH_USERS_UPLOAD_SUCCESS: "Archivo subido.",
    REACH_USERS_DRAG_DROP: `Arrastre y suelte o <u>elija un archivo</u> para cargar sus Usuarios`,
    REACH_USERS_ACCEPTED_EXTENSIONS: "Se admite el tipo de archivo .csv. ",
    REACH_USERS_ELIGIBILITY_JOB: "Trabajo",
    REACH_USERS_TOOLTIP:
      "Si necesita agregar campos personalizados, cargue un archivo CSV.",
    REACH_USERS_CUSTOM_FIELDS_TEXT_1:
      "Para agregar campos personalizados, por favor",
    REACH_USERS_CUSTOM_FIELDS_TEXT_2: "cargue un archivo de elegibilidad",
    REACH_USERS_CUSTOM_FIELDS_TEXT_3: "en su lugar.",
    REACH_USERS_ELIGIBILITY_CUSTOM_COLUMNS: "<b>Columnas personalizadas: </b>",
    REACH_USERS_FULL_LIST_DELETE_WARNING:
      "¡Cuidado! Este proceso desactivará a cualquier usuario ya cargado que no esté en el archivo.",
    REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_1: "Estás a punto de eliminar",
    REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_2: "usuario(s).",
    REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_3:
      "Estás seguro de que quieres continuar?",
    REACH_USERS_FULL_LIST_DESCRIPTION:
      "En el archivo CSV, especifica todos los usuarios elegibles y haz que el sistema agregue/actualice/desactive según sea necesario.",
    REACH_USERS_CHANGE_FILE_DESCRIPTION: `En el archivo CSV, especifica un conjunto de usuarios para agregar, actualizar o desactivar.<br/><br/>
      Agregar y actualizar usuarios:<ul style='list-style:circle;margin-left:1rem;margin-top:5px'>
        <li>Para agregar o actualizar un usuario, especifica el usuario en el archivo</li>
        <li>Los nuevos usuarios serán agregados y recibirán un correo electrónico de activación en el momento especificado</li>
        <li>Los usuarios existentes serán actualizados con cualquier detalle nuevo</li>
        {0}
        </ul>
        <br/>
        Desactivación de usuarios:<ul style='list-style:circle;margin-left:1rem;margin-top:5px'>
        <li>Para desactivar un usuario, especifica <code>DELETE</code> en la columna de acción de importación</li>
        </ul>`,
    REACH_USERS_CHANGE_FILE_HIERARCHY_DESCRIPTION:
      "<li>Especifique la asociación en el formato '2|3|4' donde 2,3,4 son identificadores de nodo.</li>",
    REACH_USERS_CHANGE_FILE_CERT_DESCRIPTION:
      "<li>Especifica cualquier certificado para asignar al usuario</li>",
    REACH_USERS_ELIGIBILITY_USERS_COUNT: "<b>Usuarios: </b>",
    REACH_USERS_DELETE_USER: "Borrar usuario",
    REACH_USERS_RESET_PASSWORD: "Restablecer la contraseña",
    REACH_USERS_RESEND_ACTIVATION_EMAIL: "Reenviar correo de activación",
    REACH_USER_ACTIVATION_EMAIL_RESENT: "Correo de activación reenviado",
    REACH_USERS_RESEND_ACTIVATION_EMAIL_CONFIRMATION:
      "La contraseña del usuario se restablecerá y se enviará un correo electrónico al usuario para completar el proceso. ¿Estás seguro?",
    REACH_USER_SAVE: "Guardar usuario",
    REACH_USERS_CHANGE_EMAIL_CONFIRMATION:
      "Has actualizado el correo electrónico del usuario. ¿Estás seguro?",
    REACH_USERS_RESET_PASSWORD_USER: "Restablecer contraseña del usuario",
    REACH_USERS_RESET_PASSWORD_LINK: "Enlace para restablecer la contraseña",
    REACH_USERS_CLEAR_SEARCH: "Borrar búsqueda",
    REACH_USERS_RESET_FILTERS: "Restablecer filtros",
    REACH_USERS_INVITE_USERS: "Invitar usuarios",
    REACH_USERS_SHARING_IS_CARING: "¡Compartir es cuidar!",
    REACH_USERS_SHARING_IS_CARING_DESCRIPTION:
      "Copia y pega la siguiente información en la plataforma de comunicación deseada para informar a tus empleados y sus familias que <em>headversity</em> está disponible para su uso.",
    REACH_USERS_SHARING_INVITE_CODE_MESSAGE:
      "Nos gustaría presentarte <em>headversity</em> – una solución proactiva de formación en salud mental y resiliencia que puedes utilizar en el flujo de trabajo y vida para que puedas pensar y sentirte lo mejor posible.<br/><br/>Utiliza el código {0} para unirte a <em>headversity</em> y emprender un viaje de bienestar personalizado.<br/><br/>Regístrate aquí {1}",
    REACH_USERS_SHARING_FAMILY_INVITE_CODE_MESSAGE:
      "<em>headversity</em> también está disponible para los miembros de tu familia para que puedan pensar y sentirse lo mejor posible. ¡Esto significa acceso a una formación proactiva en salud mental y resiliencia en el flujo de trabajo y vida.<br/><br/>Comparte el código {0} para que se unan a <em>headversity</em> y emprendan un viaje de bienestar personalizado.<br/><br/>Comparte este enlace con ellos {1}",
    REACH_USERS_SHOW_DETAILS: "Mostrar detalles",
    REACH_USERS_HIDE_DETAILS: "Ocultar detalles",
    REACH_QUICK_START_PROGRESS: "Progreso",
    REACH_QUICK_START_DEFINE_ORGANIZATION_TITLE:
      "Defina su organización principal",
    REACH_QUICK_START_DEFINE_ORGANIZATION_DESCRIPTION:
      "Personalice el perfil de su organización en <em>headversity</em> para crear una experiencia personalizada.",
    REACH_QUICK_START_DEFINE_ORGANIZATION_BUTTON: "Configure su organización",
    REACH_QUICK_START_SHOWCASE_LOGO_TITLE: "Muestre su logotipo",
    REACH_QUICK_START_SHOWCASE_LOGO_DESCRIPTION:
      "Fomente una sensación de familiaridad y compromiso entre los usuarios con la marca de su organización.",
    REACH_QUICK_START_SHOWCASE_LOGO_BUTTON: "Agregar un logotipo",
    REACH_QUICK_START_DEFINE_HELP_RESOURCES_TITLE:
      "Defina sus recursos de ayuda",
    REACH_QUICK_START_DEFINE_HELP_RESOURCES_DESCRIPTION:
      "Garantice un soporte continuo para sus miembros con recursos de ayuda de fácil acceso en la aplicación. Adapte estos recursos para incluir servicios vitales como los Programas de asistencia para empleados y familias (EFAP), brindando ayuda integral cuando sea necesario.",
    REACH_QUICK_START_DEFINE_HELP_RESOURCES_BUTTON: "Configurar recursos",
    REACH_QUICK_START_CONFIRM_COMMS_TITLE:
      "Confirme su configuración de comunicación",
    REACH_QUICK_START_CONFIRM_COMMS_DESCRIPTION:
      "Optimice su conexión con <em>headversity</em> configurando los ajustes de su Lista de permitidos, asegurando que su equipo nunca se pierda correos electrónicos, noticias y actualizaciones cruciales.",
    REACH_QUICK_START_ADD_USERS_TITLE: "Agregar o subir tus primeras usuarios",
    REACH_QUICK_START_ADD_USERS_DESCRIPTION:
      "Comience a empoderar a su equipo invitando a personas o importando una lista de usuarios elegibles para darles acceso a las experiencias de <em>headversity</em>.",
    REACH_QUICK_START_ADD_USERS_BUTTON: "Agregar usuarias",
    REACH_QUICK_START_CHOOSE_GOALS_TITLE:
      "Elija los objetivos organizacionales",
    REACH_QUICK_START_CHOOSE_GOALS_DESCRIPTION:
      "Personaliza tus metas organizacionales en headversity para crear una experiencia a medida.",
    REACH_QUICK_START_CHOOSE_GOALS_BUTTON: "Elegir objetivos",
    REACH_VISIT_HELP_CENTER_TEXT: "Visita el Centro de ayuda",
    REACH_QUICK_HELP_CENTER_DESCRIPTION:
      "Consejos útiles y respuestas a todas tus preguntas están disponibles las 24 horas del día, los 7 días de la semana en el Centro de ayuda.",
    REACH_MY_ORGANIZATION: "Mi organización",
    REACH_MANAGE_INTEGRATIONS: "Gestionar Integraciones",
    REACH_MANAGE_SETTINGS: "Administrar configuración",
    REACH_USERS_CERTIFICATIONS: "Certificaciones",
    REACH_USERS_CREATED: "Creado",
    REACH_USERS_ACTIVATION_DATE: "Fecha de activación",
    REACH_USERS_EDIT: "Editar",
    REACH_USERS_CERTIFICATION: "Certificación",
    REACH_USERS_DATE_ASSIGNED: "Fecha de asignación",
    REACH_USERS_DATE_STARTED: "Fecha de inicio",
    REACH_USERS_DATE_COMPLETED: "Fecha de finalización",
    REACH_USERS_UN_ASSIGN: "Eliminar",
    REACH_USERS_ASSIGN_A_CERTIFICATION: "Asignar una certificación",
    REACH_USERS_CERTIFICATION_ASSIGNMENTS: "Asignaciones de Certificación",
    REACH_USERS_UN_ASSIGN_CERTIFICATION: "Eliminar una certificación",
    REACH_USERS_UN_ASSIGN_CERT_CONFIRMATION_MESSAGE:
      "¿Está seguro de que desea eliminar esta certificación?",
    REACH_USERS_UN_ASSIGN_CERT_SUCCESS: "Certificación eliminada con éxito.",
    REACH_CONTINUE_YOUR_QUICK_START_GUIDE: "Continúa tu guía de inicio rápido",
    REACH_ORGANIZATIONS_LMS_INTEGRATIONS: "Integraciones LMS",
    REACH_ORGANIZATIONS_LMS_INTEGRATION: "Integración de LMS",
    REACH_ORGANIZATIONS_LMS_DOWNLOAD_INSTRUCTION:
      "Haz clic aquí para descargar el paquete SCORM",
    REACH_ORGANIZATIONS_COMMUNICATION_SETTINGS: "Configuración de comunicación",
    REACH_ORGANIZATIONS_COMMUNICATION_SETTINGS_DESCRIPTION:
      "Adapta tus preferencias de comunicación para una experiencia <em>headversity</em> fluida e informada.",
    REACH_ORGANIZATIONS_CONFIRMED_ALLOWED:
      "Has confirmado que la información solicitada está permitida",
    REACH_ORGANIZATIONS_NOT_CONFIRMED_ALLOWED:
      "Aún no has confirmado la información solicitada",
    REACH_ORGANIZATIONS_ALLOW_LIST_REQUEST: "Solicitar lista de permitidos",
    REACH_ORGANIZATIONS_ALLOW_MESSAGE:
      "La Configuración de la Lista de Permitidos asegura que recibas actualizaciones importantes de <em>headversity</em>. Permite a los usuarios recibir correos electrónicos con noticias, videos y actualizaciones. Por favor, copia y comparte la información a continuación con tus administradores de TI.",
    REACH_ORGANIZATIONS_SENDER_EMAILS: "Correos electrónicos de remitentes",
    REACH_ORGANIZATIONS_DOMAINS: "Dominios",
    REACH_ORGANIZATIONS_QUESTIONS:
      "Si tiene alguna pregunta o necesita ayuda, comuníquese con nuestro equipo de soporte en <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    REACH_ORGANIZATIONS_COPY_TO_CLIPBOARD:
      "Copie esta información al portapapeles",
    REACH_ORGANIZATIONS_INFORMATION_ALLOWED:
      "La información listada ha sido permitida.",
    REACH_ORGANIZATIONS_HELP_RESOURCES_ADDED: "Recursos de ayuda añadidos",
    REACH_ORGANIZATIONS_GOALS_CHOSEN: "Metas Elegidas",
    REACH_ORGANIZATIONS_MANAGE_HELP_RESOURCES: "Administrar recursos",
    SET_NUMBER_OF_GROUPS: "Establecer número de grupos",
    START_TEAM_LESSON: "Iniciar presentación",
    TEAM_CODE: "ID del equipo",
    TEAM_SCAN_CODE: "Escanea el código QR para participar",
    TEAM_JOIN_BROWSER: "Únete desde tu navegador",
    PARTICIPANT_LIST: "Lista de participantes",
    IS_NOW_PRESENTING: "es entrenar juntos ahora!",
    YOU_ARE_PRESENTING: "(Tú eres el presentador)",
    TEAM_MULTIPLE_PRESENTING: "Múltiples equipos están presentando",
    JOIN_TEAM_LESSON: "Únete a mi equipo",
    JOIN_EXISTING_TEAM: "Únete a un equipo",
    TEAM_SWITCH_TEAMS: "Cambiar equipo seleccionado",
    TEAM_CREATE_A_TEAM: "Crea un nuevo equipo",
    TEAM_CURRENT_TEAM: "Equipo seleccionado",
    TEAM_NOT_YET_PRESENTED: "Todavía no se ha presentado",
    TEAM_LAST_PRESENTED: "Presentado por última vez",
    TEAM_TEAM_LESSON_INFO: "Información de presentación",
    TEAM_TEAM_LESSON_QR: "Compartir",
    TEAM_LESSON: "Presentación",
    TEAM_LESSONS: "Presentaciones",
    MINUTE: "Minuto",
    MINUTES: "Minutos",
    TEAM_SWITCH_TEAM_VIEW: "Selecciona un equipo",
    TEAM_CURRENTLY_PART_OF_TEAM: "Selected Team:",
    TEAM_USE_THE_DROPDOWN:
      "Usa el menú desplegable a continuación para seleccionar otro equipo con el cual participar",
    TEAM_TEAM_NAME: "Nombre del equipo",
    TEAM_CHOOSE_A_TEAM: "Elige un equipo",
    MEMBERS: "Miembros",
    MEMBER: "Miembro",
    ARIA_LABEL_EDIT_TEAM_NAME: "Editar el nombre del equipo",
    TEAM_NOT_YET_MET: "Todavía no se ha reunido",
    TEAM_LAST_MET: "Última reunión:",
    TEAM_DELETE_TEAM: "Eliminar equipo",
    TEAM_ADD_MEMBER: "Añadir miembro",
    CONTINUE_WHERE_YOU_LEFT_OFF: "Continúa donde lo dejaste",
    JOIN_TEAM_LESSON_IN_PROGRESS: "Unirse a la presentación en progreso",
    TEAM_PROGRESS: "Progreso del equipo",
    TEAM_CONFIRM_DELETE: "¿Estás seguro de que quieres eliminar {0}?",
    TEAM_USER_CONFIRM_DELETE:
      "¿Estás seguro de que quieres eliminar {0} de {1}?",
    CANCEL: "Cancelar",
    TEAM_OR_SCAN_QR_CODE: "O escanear el código QR",
    TEAM_MANUALLY_ENTER: "Agrega manualmente a un compañero de equipo",
    TEAM_ENTER_BY_QR: "Ingresa compartiendo QR",
    TEAM_CREATED_SUCCESS: "El equipo fue creado con éxito.",
    TEAM_NAME_UPDATED_SUCCESS: "El nombre del equipo se actualizó con éxito.",
    TEAM_EDIT_CURRENT_TEAM_NAME: "Nombre del equipo actual",
    TEAM_CURRENT_TEAM_NAME: "Actualmente estás usando TEAM como parte de",
    TEAM_CHANGE_TEAM_BODY1: "Equipo seleccionado:",
    TEAM_CHANGE_TEAM_BODY2:
      "¿No es el equipo con el que entrenas hoy? Utiliza el menú desplegable para seleccionar otro equipo con el que participar.",
    TEAM_NEW_TEAM_NAME: "Nombre del nuevo equipo",
    TEAM_EDIT_TEAM_NAME: "Editar nombre del equipo",
    UPDATE_BUTTON_TEXT: "Actualizar",
    TEAM_CREATE_NEW_TEAM_DESCRIPTION:
      "¡Primero dale un nombre a tu equipo y luego invita a tus colegas a unirse a ti!",
    TEAM_MANAGEMENT_TITLE: "Equipos",
    TEAM_ADD_MEMBER_INSTRUCTION:
      "Ingresa la dirección de correo electrónico de la persona que deseas agregar, y enviaremos un correo electrónico invitándolo a unirse.",
    TEAM_ADD_MEMBER_SUCCESS: "La invitación fue enviada con éxito.",
    TEAM_RESEND_ACTIVATION_SUCCESS: "Activation email was successfully resent",
    TEAM_MANAGEMENT_DESCRIPTION:
      "Administra todos tus equipos y el progreso del equipo",
    TEAM_JOIN_SUCCESS_DESCRIPTION: "¡Te uniste con éxito al equipo!",
    TEAM_DELETE_SUCCESS_TITLE: "El equipo fue eliminado con éxito.",
    TEAM_DELETE_USER: "Borrar usuario",
    TEAM_USER_DELETE_SUCCESS_TITLE:
      "El usuario del equipo fue eliminado con éxito.",
    TEAM_JOIN_ALREADY_JOINED_DESCRIPTION: "Ya eres miembro de este equipo.",
    TEAM_ADD_MEMBER_ALREADY_EXISTS_DESCRIPTION:
      "El correo electrónico proporcionado ya está vinculado a un miembro de este equipo",
    TEAM_ERROR_TEAM_CODE_DOES_NOT_EXIST:
      "El ID del equipo que ingresaste no existe. Inténtalo de nuevo.",
    TEAM_ERROR_TEAM_CODE_IS_ON_WRONG_COMPANY:
      "El ID del equipo que ingresaste no se puede usar en tu organización. Ponte en contacto con soporte si el problema persiste.",
    TEAM_ERROR_TEAM_IS_ON_WRONG_COMPANY:
      "El equipo que ingresaste no se puede usar en tu organización. Ponte en contacto con soporte si el problema persiste.",
    TEAM_ERROR_USER_DOES_NOT_BELONG_TO_TEAM:
      "El usuario ingresado no pertenece al equipo especificado. Ponte en contacto con soporte si el problema persiste.",
    EMAIL_ADDRESS: "Correo electrónico",
    FULL_NAME: "Nombre Completo",
    TEAM_MENU_PATHS: "Caminos",
    TEAM_MENU_TEAMS: "Equipos",
    TEAM_MENU_INSIGHTS: "Estadísticas",
    TEAM_POLL_TIME: "¡Es hora de votar!",
    TEAM_BACK_TO_PREVIEW: "Volver a la vista previa",
    TEAM_END_PREVIEW: "Terminar la vista previa",
    TEAM_PARTICIPATE_END: "¿Salir de la presentación?",
    TEAM_PARTICIPATE_END_MESSAGE:
      "¿Estás seguro de que quieres salir de la presentación?",
    TEAM_TEAM_LESSON_END: "¿Terminar la presentación?",
    TEAM_END_TEAM_LESSON: "Terminar la presentación",
    TEAM_TEAM_LESSON_END_MESSAGE:
      "¿Estás seguro que quieres terminar la presentación?",
    TEAM_TEAM_GROUPING_ONLY_ONE_USER:
      "Group {1} solo tiene un usuario, ¿estás seguro de que quieres continuar?",
    TEAM_TEAM_LESSON_INCOMPLETE_END_BLOCK_ONE:
      "Todas las encuestas no se <strong> han </strong> completado.",
    SPORT_TEAM_LESSON_INCOMPLETE_END_BLOCK_ONE:
      "Todas las actividades no se <strong> han </strong> completado.",
    TEAM_TEAM_LESSON_INCOMPLETE_CONTINUE: "Continuar Presentación",
    TEAM_TEAM_LESSON_INCOMPLETE_RESUME_LATER: "Reanudar más tarde",
    TEAM_TEAM_LESSON_INCOMPLETE_END_NOW: "Esta capacitación está completa",
    TEAM_BACK_TO_POLLS: "Mostrar encuestas",
    TEAM_BACK_TO_LOBBY: "Mostrar Lobby",
    TEAM_GREAT_WORK: "¡Buen trabajo!",
    TEAM_FEEDBACK_DESCRIPTION:
      "Ese es un buen trabajo de equipo. ¡Estás listo para tomar lo que aprendiste y ponerlo en práctica!",
    TEAM_NAILED_IT: "¡Lo conseguiste!",
    TEAM_PARTICIPANT_END_TITLE: "¡Gran trabajo!",
    TEAM_PRESENTER_END_MESSAGE:
      "¡Hay mucho más por delante para ti y tu equipo, o puedes tomarte un tiempo y concentrarte en ti!",
    TEAM_PARTICIPANT_END_MESSAGE:
      "¡Has completado esta presentación, y tú y tu equipo están listos para continuar con la próxima presentación en este camino!",
    TEAM_INSIGHT_TITLE: "Estadísticas",
    TEAM_INSIGHT_DESCRIPTION: "Tu visión general de cómo interactúa tu equipo.",
    TEAM_HOME_TITLE: "TEAM",
    TEAM_HOME_HEADER_TITLE: "Bienvenido a TEAM",
    TEAM_HOME_DESCRIPTION: "Llegamos más lejos cuando trabajamos juntos",
    TEAM_HOME_NEXT_UP: "Siguiente para ",
    TEAM_HOME_UP_NEXT: "Próxima",
    TEAM_HOME_NEXT_UP_PATH:
      "¡Continúa donde lo dejaste en el <strong>{0}</strong> camino!",
    TEAM_HOME_PATHS_COMPLETE: "¡Todas los caminos completados!",
    TEAM_HOME_PATH_START_PATH: "Comenzar el camino",
    TEAM_HOME_PATH_CONTINUE: "Continuar el camino",
    TEAM_HOME_PATH_PROGRESS: "Ver el progreso",
    TEAM_HOME_PAGE_NEXT_UP_FOR_YOU: "Mientras no estabas",
    TEAM_HOME_PAGE_NEXT_UP_FOR_YOU_DESCRIPTION_1:
      "¡Parece que te perdiste un entrenamiento! Ponte al día",
    TEAM_HOME_PAGE_NEXT_UP_FOR_YOU_DESCRIPTION_2:
      "ahora antes de la siguiente.",
    TEAM_HOME_PAGE_NEXT_UP_COMPLETE_DESCRIPTION_1:
      "Si pierdes algún entrenamiento, te pediremos que te pongas al día",
    TEAM_HOME_PAGE_NEXT_UP_COMPLETE_DESCRIPTION_2: "aquí.",
    TEAM_GROUPING_REPORTER: "Reportero",
    TEAM_TEAM_GROUPING_CATCH_UP_END: "¿Terminar agrupación?",
    TEAM_TEAM_LESSON_CATCH_UP_END: "¿Poner fin a la actualización?",
    TEAM_TEAM_LESSON_CATCH_UP_END_MESSAGE:
      "¿Estás seguro de que quieres terminar la sesión de actualización?",
    TEAM_BUTTON_PRESENT: "Presentar  ",
    TEAM_BUTTON_PREVIEW: "Avance",
    TEAM_BUTTON_IN_PROGRESS: "Entrar",
    TEAM_BUTTON_IN_CATCH_UP: "Ponerse al día",
    TEAM_BUTTON_START_OVER: "Comenzar de nuevo",
    TEAM_BUTTON_EXPIRE_TEAM_LESSON: "En progreso por más de 15 minutos",
    TEAM_PRESENT_CONFIRM_PRESENT_AGAIN:
      "Esta presentación se completó el {0}. ¿Quieres presentar de nuevo?",
    TEAM_PRESENT_CONFIRM_PRESENT_AGAIN_HEADER_TITLE: "Presente de Nuevo",
    TEAM_BUTTON_PARTICIPATE: "Participar",
    TEAM_PRESENT_OR_PARTICIPATE: "¿Ya presentando?",
    TEAM_PRESENT_OR_PARTICIPATE_TEXT:
      "Parece que puede estar presentando con su equipo en otra sesión. ¿Te gustaría unirse como participante o asumir el cargo de presentador?",
    TEAM_VOTES: "Respuestas",
    TEAM_VOTES_SEE_RESULT: "Necesitas responder para ver el resultado",
    TEAM_NO_TEAM_DESCRIPTION:
      "Actualmente no perteneces a un equipo. Únete a un equipo o crea uno.",
    TEAM_JOIN_NEW_TEAM: "Unirse al equipo",
    TEAM_JOIN_THIS_TEAM: "Únete a este equipo",
    TEAM_JOIN_ANOTHER_TEAM: "Únete a un equipo",
    TEAM_LEAVE_CURRENT_TEAM: "Dejar el equipo",
    TEAM_ENTER_NEW_CODE: "Ingresa el ID del equipo",
    TEAM_LEAVE_DESCRIPTION: "¿Estás seguro de que quieres dejar este equipo?",
    TEAM_JOIN_NEW_TEAM_DESCRIPTION:
      "Ingresa el ID del equipo que se compartió con usted, o",
    TEAM_CREATE_NEW_TEAM: "crea un nuevo equipo",
    TEAM_JOIN_PICK_TEAM_TO_JOIN: "Por favor elige un equipo para unirte",
    APP_GRID_SOLO: "SOLO",
    APP_GRID_TEAM: "TEAM",
    APP_GRID_REACH: "REACH",
    APP_GRID_SPORT: "SPORT",
    TEAM_TEAM_LESSON_CANCEL_TITLE: "Cancelar presentación",
    TEAM_TEAM_LESSON_CANCEL_DESCRIPTION:
      "¿Estás seguro de que quieres cancelar esta presentación?",
    TEAM_TEAM_LESSON_CANCEL_MESSAGE: "Esta presentación fue cancelada.",
    TEAM_GET_STARTED: "¡Aquí está cómo comenzar!",
    TEAM_GET_STARTED_DESCRIPTION:
      "¡Bienvenido TEAM! Si te han compartido el ID de un equipo, <strong> Únete a ese equipo ahora </strong>. Si no, <strong> crea un nuevo equipo </strong> para comenzar a presentar con tus colegas.",
    TEAM_TEAM_LESSON_MIN: "min",
    TEAM_TEAM_LESSON_MINS: "mins",
    TEAM_TEAM_LESSON_INDICATOR_TRAINING_INCLUDED:
      "Entrenamiento individual para llevar",
    TEAM_TEAM_LESSON_INDICATOR_GROUP_ACTIVITY_INCLUDED: "Actividad de grupo",
    TEAM_TEAM_LESSON_INDICATOR_INCLUDES_SCRIPT:
      "Esta presentación incluye un guión",
    TEAM_TEAM_LESSON_DOWNLOAD_TITLE: "Descargar para usar sin conexión",
    TEAM_TEAM_LESSONS_COMPLETE: "Presentación finalizada",
    TEAM_TEAM_LESSONS_COMPLETED: "Presentaciones finalizadas",
    TEAM_TOTAL_TEAM_LESSONS: "Presentaciones totales",
    TEAM_POLL: "Encuesta",
    TEAM_POLLS: "Encuestas",
    TEAM_NO_POLLS: "No hay encuestas completadas",
    TEAM_PARTICIPANT: "Participante",
    TEAM_PARTICIPANTS: "Participantes",
    TEAM_TEAM_LESSON_PREVIEW_TITLE: "Esta es solo una vista previa.",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_TITLE: "¡Sólo un segundo!",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_DESCRIPTION_BLOCK_ONE:
      "Recomendamos tomar los entrenamientos en el orden en que aparecen para aprovechar al máximo tu experiencia. Pero si quieres continuar en este orden, ¡está bien!",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_DESCRIPTION_BLOCK_TWO:
      "Sin embargo, sabemos que a veces el tiempo es un factor, y si quieres omitir algunos por ahora y avanzar más adelante, ¡está bien!",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_UNDERSTAND: "Entiendo",
    TEAM_POLL_COMPLETE: "Encuesta completada",
    TEAM_POLLS_COMPLETED: "Encuestas completadas",
    TEAM_VIEW_POLL_RESULT: "Ver resultados",
    TEAM_YOUR_ID: "ID del equipo:",
    JOINED: "Se unió:",
    TEAM_WAITING_JOIN_MESSAGE:
      "Una vez que todos se hayan unido y estés listo para presentar, haz clic en <strong>Iniciar presentación</strong>.",
    TEAM_WAITING_JOIN_GROUP_MESSAGE:
      "Once everyone has joined and you're ready to start grouping, click <strong>Start Grouping</strong>.",
    TEAM_WAITING_TEAM_MATE:
      "Esperando que tus compañeros de equipo se unan ...",
    TEAM_WAITING_GROUPING: "Creating breakout groups...",
    TEAM_CHARTER: "Carta del equipo",
    TEAM_CHARTER_DISCUSSION_IN_PROGRESS: "Discusión en progreso",
    TEAM_CATCH_UP_INFO_TEXT:
      'Si tu equipo completa una presentación sin ti, ¡está bien! Todavía puedes participar en esta lección y en todas las actividades y encuestas ahora. Para "ponerte al día" en cualquier presentación en la que no hayas estado presente, haz clic en la presentación para comenzar.',
    TEAM_CATCHUP_HOMEPAGE_TOOLTIP_TEXT:
      'Puedes "ponerte al día" revisando la presentación y completando las actividades tu mismo.',
    SELECT: "Selecciona",
    TEAM_SELECT_GROUP_NUMBER: "Seleccione el número de grupos",
    TEAM_TEAM_LESSON_IN_PROGRESS: "Presentación en curso...",
    TEAM_TEAM_LESSON_IN_PROGRESS_SUB_HEADING: "{0} está presentando...",
    ALL_SET: "¡Estás listo!",
    TEAM_TEAM_LESSON_WAITING_TO_START:
      "Esperando a que {0} comience la presentación...",
    TEAM_POLL_RESULT: "Resultados de la encuesta",
    TEAM_LESSON_MINUTES_SPEND: "Minutos dedicados a las lecciones",
    TEAM_POLL_ENGAGEMENT: "Participación en encuestas",
    TEAM_TEAMMATES_IN_ONE_SESSION:
      "El mayor número de miembros del equipo en una sesión",
    TEAM_COMPLETE_MORE_THAN_ONE:
      "Miembros del equipo que han completado una lección o más",
    TEAM_TOTAL_LESSON_PARTICIPATION:
      "Número total de lecciones completadas entre los compañeros de equipo desde el principio",
    TEAM_USER_INTERACTION: "Interacción del usuario",
    TEAM_LESSON_SUCCESS_RELEVANT:
      "¿Encontraste relevante esta capacitación TEAM?",
    TEAM_LESSON_SUCCESS_POSITIVELY:
      "¿El contenido de la capacitación te inspiró a hacer cambios positivos en tu vida?",
    TEAM_WAITING_FOR_VOTES:
      "Esperando respuestas... ¡Usa tu dispositivo para responder a esta encuesta!",
    TEAM_TRAINING: "Entrenamiento TEAM",
    TEAM_TRAINING_DESCRIPTION: "Presentaciones interactivas en grupo",
    TEAM_READY_TO_PRESENT: "¿Continuar con la presentación?",
    TEAM_WHEN_YOU_START: "Cuando comience a presentar, notificaremos a",
    TEAM_OF_THE: "del equipo",
    TEAM_TO_JOIN: "para que se pueden unirse a ti",
    TEAM_MORE_TO_EXPLORE: "Más para explorar",
    TEAM_MORE_TO_EXPLORE_DESCRIPTION: "Mira estas otras presentaciones.",

    CORE: "Escencial",
    WORK: "Profesional",
    HOME: "Para el hogar",
    ENTER_YOUR_RESPONSE: "Enter your response",
    ENTER_YOUR_RESPONSE_ONE_AT_A_TIME: "Enter your response (one at a time)",
    ENTER_YOUR_RESPONSE_OPTIONAL: "Ingresa tu respuesta (opcional)",
    LEAVE_MODAL_ARE_YOU_SURE: "¿Estás seguro?",
    LOGIN_PAGE_TITLE: "Adelántate a la",
    LOGIN_PAGE_TITLE_ADVERSITY: "adversidad.",
    LOGIN_PAGE_FIRST_STEP_INSTRUCTION_HAVE: "¿Ya tienes una cuenta?",
    LOGIN_PAGE_FIRST_STEP_INSTRUCTION_DONT: "¿No tienes cuenta?",
    LOGIN_PAGE_SIGN_IN: "Iniciar sesión",
    LOGIN_PAGE_SIGN_IN_WITH_EMAIL: "Sign In with magic link",
    LOGIN_PAGE_INVITE_CODE_HAVE: "Tengo un código de invitación",
    LOGIN_PAGE_INVITE_CODE_DONT: "No tengo un código de invitación",
    LOGIN_PAGE_SIGN_UP: "Regístrate",
    LOGIN_PAGE_SEND_EMAIL: "Enviar correo electrónico",
    LOGIN_WELCOME_EMAIL: "¿Recibiste un correo electrónico de bienvenida?",
    LOGIN_WELCOME_DESCRIPTION:
      "Verifique su correo electrónico de trabajo de <em>headversity</em> con instrucciones de acceso; de lo contrario, ingrese su dirección de correo electrónico de trabajo a continuación e intentaremos enviárselo nuevamente. Si aún no lo recibe, comuníquese con su administrateur de <em>headversity</em> o envíenos un correo electrónico a <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    LOGIN_VALIDATION_DESCRIPTION:
      "Revisa tu cuenta de correo electrónico para un correo de <em>headversity</em> con instrucciones de acceso. Si no recibes el correo electrónico, por favor contacta a tu administrador de <em>headversity</em> o envíanos un correo electrónico a <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    LOGIN_VALIDATION_PAGE_TITLE: "Reenviar correo de activación",
    LOGIN_VALIDATION_PAGE_DESCRIPTION:
      "Ingresa tu dirección de correo electrónico abajo y te reenviaremos un correo de activación si tenemos tu correo electrónico registrado.",
    LOGIN_FORGOT_PASSWORD: "¿Olvidaste tu contraseña?",
    LOGIN_SAVE_PASSWORD: "Cambiar mi contraseña",
    LOGIN_SET_PASSWORD:
      "Estás utilizando una contraseña temporal, por favor crea una nueva contraseña",
    LOGIN_GENERIC_ERROR:
      "Se ha producido un error al  intentar iniciar sesión. Vuelve a intentarlo más tarde",
    LOGIN_REDIRECT_MOBILE:
      "Te redirigimos a la pantalla de inicio de sesión, haz clic en el botón inicio de sesión si sigues viendo esto",
    LOGIN_FORGOT_PASSWORD_DESCRIPTION:
      "Ingresa tu correo electrónico y recibirás instrucciones para restablecer tu contraseña.",
    SINGLE_SIGN_ON_ENABLED: "Inicio de sesión único activado",
    EMAIL_IS_MANDATORY: "El correo electrónico es obligatorio",
    FULL_NAME_IS_MANDATORY: "El nombre completo es obligatorio",
    FULL_NAME_NEEDS_CONTAIN_FIRST_LAST_NAME:
      "El nombre completo debe contener tanto el nombre como el apellido",
    PASSWORD_IS_MANDATORY: "La contraseña es obligatoria",
    INVITE_CODE_IS_MANDATORY: "El código de invitación es obligatorio",
    EMAIL_IS_INVALID: "El correo electrónico es invalido",
    EMAIL_HAS_PROBLEM:
      "Hay un problema con el correo electrónico que ingresaste. Por favor, contacta a <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    EMAIL_DOMAIN_NOT_ALLOWED:
      "La dirección de correo electrónico que has ingresado no es válida para este código de invitación. Por favor, contacta a tu administrador de headversity o envíanos un correo electrónico a <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    EMAIL_IS_USED_ALREADY: "El correo electrónico ya se usa",
    PASSWORD_NEEDS_TO_RESPECT_FORMAT:
      "La contraseña debe contener una letra mayúscula, una letra minúscula y un número, y tener al menos 8 caracteres de longitud.",
    PASSWORD_LENGTH_REQUIREMENT: "Al menos 8 caracteres",
    PASSWORD_UPPERCASE_REQUIREMENT: "Al menos una letra mayúscula",
    PASSWORD_LOWERCASE_REQUIREMENT: "Al menos una letra minúscula",
    PASSWORD_NUMBER_REQUIREMENT: "Al menos un número",
    PASSWORD_MUST_MATCH: "Las contraseñas deben coincidir",
    PASSWORD_REQUIREMENTS: "Requisitos de contraseña:",
    INVITE_CODE_IS_INVALID:
      "El código de invitación que ingresaste no es válido",
    EMAIL_CAN_ONLY_BE_USED_AS_SSO:
      "Esta dirección de correo electrónico solo se puede utilizar a través del inicio de sesión único. Intente <a style='text-decoration: underline;' href='/login'>iniciar sesión</a> en su lugar.",
    DONT_HAVE_INVITE_CODE: "¿No tienes un código de invitación?",
    CONTACT_ADMINISTRATOR: "Póngase en contacto con su administrador",
    BACK_TO_LOGIN_SIGNUP: "Volver a la página Iniciar sesión/Registrarse",
    LOGIN: "Iniciar sesión",
    SIGNUP: "Registrarse",
    PASSWORD_MISMATCH: "Contraseña no coincide",
    SIGNUP_ERROR:
      "Disculpe, ocurrió un error. Póngase en contacto con help@headversity.com con estos detalles de error: ",
    PLEASE_CHOOSE_NEW_PASSWORD_AND_INVITE_CODE:
      "Elija una nueva contraseña para su cuenta de <em>headversity</em> e ingrese su código de invitación.",
    PLEASE_ENTER_INVITE_CODE: "Por favor, introduzca su código de invitación.",
    PLEASE_CHOOSE_PASSWORD:
      "Elija una nueva contraseña para su cuenta de <em>headversity</em>.",
    YOUR_INVITE_CODE: "tu código de invitación",
    EMAIL_PLACEHOLDER: "email@sitio.com",
    YOUR_NEW_PASSWORD: "tu nueva contraseña",
    YOUR_PASSWORD: "tu contraseña",
    YOUR_FULL_NAME: "tu nombre completo",
    VERIFY_YOUR_PASSWORD: "verifica tu nueva contraseña",
    REGISTER: "REGISTRARSE",
    LOG_OUT: "CERRAR SESIÓN",
    INVALID_INVITE_CODE: "¡Tu código de invitación no es válido!",
    PRIVACY_POLICY: "Al registrarte, aceptas nuestro ",
    DROPDOWN_PLACEHOLDER: "Por favor elige una respuesta",
    ROI_LETS_PERSONALIZE: "Personalicemos tu viaje!",
    ROI_TO_PROVIDE:
      "Con el fin de brindarte la mejor herramienta de resiliencia, a medida para tus necesidades y objetivos, necesitaremos hacerte algunas preguntas. No es necesario tener conocimientos previos sobre salud mental para responder estas preguntas.",
    ROI_THESE_ARE:
      "Estas solo se utilizan para optimizar tu experiencia en <em>headversity</em>.",
    ROI_ALL_ANSWERS: "Todas tus respuestas son ",
    ROI_COMPLETELY_CONFIDENTIAL: "completamente confidenciales ",
    ROI_AND_WILL: "y ",
    ROI_NOT_BE_SHARED: "no se compartirán ",
    ROI_WITH_YOUR_EMPLOYER: "con tu empleador en ningún momento.",
    ROI_ESTIMATED_TIME: "Tiempo estimado: ",
    ROI_OVER_PAST_TWO_WEEKS: "Durante las últimas dos semanas...",
    ROI_WORK_MISSED_DAYS:
      "¿Cuántos días de trabajo te has perdido debido a enfermedades, lesiónes o problemas de salud mental (estrés, depresión, ansiedad)?",
    ROI_SKIP_QUESTION: "Saltar esta pregunta",
    ROI_WORK_SCHEDULED_DAYS: "¿Cuántos días tenías programados para trabajar?",
    ROI_PERFORMANCE_AFFECTED:
      "En una escala del 0 al 10, ¿en qué medida tu salud física y mental afectó negativamente tu rendimiento <strong>en el trabajo</strong>?",
    ROI_NOT_AFFECTED: "En absoluto afectado",
    ROI_EXTREMELY_AFFECTED: "Extremadamente afectado",
    ROI_TIME_TO_CHECKIN: "Es hora de hacer un seguimiento!",
    ROI_ITS_BEEN: "Ha pasado ",
    ROI_SINCE_SIGNUP: "días desde que te registraste en <em>headversity</em>.",
    ROI_ANY_CHANGES:
      "Vamos a revisar cualquier cambio en tu situación para poder optimizar tu experiencia <em>headversity</em>.",
    HEADER_WELCOME: "¡Bienvenido",
    PAGE_NOT_FOUND_TITLE: "No encontrada",
    RESET_PASSWORD_EMAIL_SENT:
      "¡Comprueba tu correo electrónico! Se han enviado las instrucciones para restablecer tu contraseña.",
    VALIDATION_EMAIL_SENT:
      "¡Revisa tu cuenta de correo electrónico! Tu correo de activación ha sido enviado.",
    INVALID_OR_ALREADY_USED_CODE:
      "Ups, este enlace ha caducado. Si todavía necesitas restablecer tu contraseña, puedes solicitar un nuevo correo electrónico de restablecimiento <a style='text-decoration: underline;' href='/forgotPassword'>aquí</a>.",
    INVALID_OR_ALREADY_USED_VALIDATION_CODE:
      "¡Ups, ese es un enlace expirado! Puedes solicitar un nuevo correo de activación <a style='text-decoration: underline;' href='/resend-activation'>aquí</a>.",
    INVALID_OR_ALREADY_USED_VALIDATION_LOGIN_CODE:
      "¡Vaya! Ese enlace ha expirado. Haz clic <a style='text-decoration: underline;' href='/login'>aquí</a> para volver a la pantalla de inicio de sesión.",
    INVALID_SCORM_LOGIN_CODE:
      "Vaya, hay una discrepancia entre tu perfil de LMS y tu cuenta de <i>headversity</i>. Por favor, contacta a help@headversity.com para obtener ayuda.",
    INVALID_GENERIC_LOGIN_CODE:
      "¡Vaya! Ese enlace ha expirado. Por favor, regrese a su LMS y solicite un nuevo enlace de inicio de sesión.",
    LOGIN_ERROR_INVALID_REQUEST_BODY:
      "Cuerpo de solicitud no válido. Todos y sólo de client_id, credential_type, username, otp, realm son necesarios.",
    LOGIN_ERROR_CROSS_ORIGIN_NOT_ALLOWED:
      "No se permite el inicio de sesión de origen cruzado.",
    LOGIN_ERROR_UNKNOWN_CREDENTIAL_TYPE_PARAMETER:
      "Parámetro del tipo de credencial desconocido.",
    LOGIN_ERROR_UNKNOWN_REALM: "Dominio desconocido conexión no existente.",
    LOGIN_ERROR_UNKNOWN_EMAIL_OR_PASSWORD:
      "Correo electrónico o contraseña incorrectos.",
    LOGIN_ERROR_TOO_MANY_ATTEMPTS:
      "Hemos detectado actividad inusual en tu cuenta. Por favor, inténtalo de nuevo más tarde o contacta a help@headversity.com para obtener asistencia.",
    LOGIN_ERROR_AUTHENTICATION_ERROR: "Error de autenticación",
    LOGIN_ERROR_BLOCKED_USER: "Usuario bloqueado",
    LOGIN_ERROR_DATA_BREACH:
      "Este intento de inicio de sesión ha sido bloqueado porque la contraseña que estás utilizando fue revelada anteriormente a través de una filtración de datos (no en esta aplicación).",
    LOGIN_ERROR_MULTIPLE_ATTEMPTS:
      "Tu cuenta ha sido bloqueada tras varios intentos consecutivos de inicio de sesión. Te hemos enviado un correo electrónico con instrucciones para desbloquearla.",
    LOGIN_ERROR_SUSPICIOUS_BEHAVIOR:
      "Hemos detectado un comportamiento sospechoso en el inicio de sesión y se bloquearán nuevos intentos. Por favor, ponte en contacto con el administrador.",
    LOGIN_ERROR_UNKNOWN_ERROR: "Error desconocido del proveedor",
    PASSWORD_CANT_CHANGE:
      "Para restablecer tu contraseña, ponte en contacto con tu administrador de TI.",
    INVALID_PASSWORD: "Contraseña invalida",
    ACCOUNT_EXPIRED: "Lo sentimos, esta cuenta ya no es válida.",
    LOGIN_ERROR_RESET_PASSWORD:
      "Por favor restablezca su contraseña <a style='text-decoration: underline;' href='/forgotPassword'>aquí</a>",
    UPDATING: "¡Hay una nueva actualización! Descargando ",
    FIRST_TIME_INTERSTITIAL_HEADER: "Presentando <b>las búsquedas</b>",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM1:
      "¡Apoyar tu propia salud mental ahora es más simple! Ya sea que estés buscando aumentar la confianza, manejar el estrés, establecer conexiones más profundas o mucho más, estamos aquí para ayudarte a crecer en todos los aspectos de la vida.",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM2:
      "¡Enfoca tu entrenamiento en lo que más te importa seleccionando una búsqueda hoy mismo!",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM_IMAGE:
      "https://cdn.headversity.com/app/interstitial/focuses-es.png",

    FIRST_TIME_INTERSTITIAL_HEADER_CHALLENGE:
      "¡Da el siguiente paso en tu búsqueda!",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM1_CHALLENGE:
      "Ahora es más fácil que nunca estar a<i>delante</i> de la ad<i>versidad</i>.<ul style='list-style:disc;margin-left:20px;margin-top:10px'><li>Rastrea tu progreso con <b>puntos de experiencia (XP)</b> a medida que alcanzas nuevos niveles de resiliencia.</li><li>Establece un <b>desafío</b> personal para subir de nivel cada día, semana o mes - tú eliges.</li><li>Mantén una <b>racha</b> a medida que cumples tu desafío.</li></ul>",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM2_CHALLENGE:
      "¡Establece un desafío y comienza una racha hoy! 🔥",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM_IMAGE_CHALLENGE:
      "https://cdn.headversity.com/app/interstitial/interstitial-challenge-es.png",

    FIRST_TIME_INTERSTITIAL_FIRST_HEADLINE: "",
    FIRST_TIME_INTERSTITIAL_FIRST_LIST_ITEM: "",
    FIRST_TIME_INTERSTITIAL_FIRST_IMAGE: "",
    FIRST_TIME_INTERSTITIAL_SECOND_HEADLINE: "",
    FIRST_TIME_INTERSTITIAL_SECOND_LIST_ITEM: "",
    FIRST_TIME_INTERSTITIAL_SECOND_IMAGE: "",
    FIRST_TIME_INTERSTITIAL_THIRD_LIST_ITEM:
      "Si tienes alguna pregunta, contáctanos en <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",

    FIRST_TIME_INTERSTITIAL_PUSH_NOTIFICATIONS:
      "Para ayudarte a mantenerte en el buen camino, ahora te pediremos que <b style='font-weight: 600'>actives las notificaciones</b> para recordatorios periódicos.",
    FIRST_TIME_INTERSTITIAL_PUSH_NOTIFICATIONS_TEAM:
      "Para asegurarte de recibir alertas cuando tu equipo esté entrenando, ahora te pediremos que <b style='font-weight: 600'>actives las notificaciones</b>.",

    FIRST_TIME_TEAM_INTERSTITIAL_HEADER: "¿Entrenamos en equipo?",
    FIRST_TIME_TEAM_INTERSTITIAL_FIRST_PARAGRAPH:
      "Con <em>headversity</em> TEAM, <strong>entrenarás con tus compañeros de equipo</strong> y juntos mejorarán sus habilidades colectivas en salud mental y resiliencia. Mejor aún, no necesitas ser un experto en salud mental para liderar una sesión de entrenamiento con el equipo. Nuestros recursos están diseñados para facilitar la tarea a cualquiera que quiera facilitar una sesión.",
    FIRST_TIME_TEAM_INTERSTITIAL_SECOND_PARAGRAPH:
      "Participa con tu equipo en:",
    FIRST_TIME_TEAM_INTERSTITIAL_FIRST_LIST_ITEM:
      "Entrenamientos de 5 a 7 minutos",
    FIRST_TIME_TEAM_INTERSTITIAL_SECOND_LIST_ITEM:
      "Desafíos en grupo y encuestas interactivas",
    FIRST_TIME__TEAM_INTERSTITIAL_BOTTOM_PARAGRAPH:
      'Haz clic en "Vamos con TEAM" para descubrir una nueva forma de integrar el entrenamiento en salud mental en tu flujo de trabajo con tus colegas.',
    FIRST_TIME_TEAM_INTERSTITIAL_GO_TO_TEAM: "Vamos con TEAM",
    DELETE_ACCOUNT_TITLE_1: "Nos entristece verte marchar.",
    DELETE_ACCOUNT_TITLE_2: "Eliminar cuenta",
    DELETE_ACCOUNT_MESSAGE:
      "Una vez eliminada tu cuenta, toda tu información y progreso se perderá. ¿Estás seguro de que quieres eliminar tu cuenta?",
    DELETE_ACCOUNT_CONFIRM_MESSAGE:
      "<strong>¿Estás absolutamente seguro?</strong><br>Si estás seguro de que quieres eliminar tu cuenta, confírmalo a continuación. Esta acción no es reversible.",
    APP_VERSION_OUTDATED_ERROR:
      "Parece que estás usando una versión anterior de la aplicación. Vaya a la tienda de aplicaciones en su dispositivo y actualice ahora.",
    ABOUT_TEAM_WELCOME: "¡Bienvenido a <em>headversity</em> TEAM!",
    ABOUT_TEAM_FIRST_LINE:
      "¡Participa con tu equipo en entrenamientos <b>rápidos</b> e <b>interactivos</b> y mejorarás tu salud mental y tu resiliencia colectiva!",
    ABOUT_TEAM_SECOND_LINE:
      "Cualquier persona puede facilitar una sesión - no se requiere un experto en salud mental.",
    VIEW_HISTORY: "Ver historial",
    TERMS_PAGE: termsPolicy.es.termsPage,
    PRIVACY_PAGE: termsPolicy.es.privacyPolicyPage,
    UNAUTHORIZED: "No autorizado",
    UNAUTHORIZED_DESCRIPTION:
      "No tienes autorización para ver o acceder a esta página.",
    SHIELD_LEVEL: "NIVEL",
    SHIELD_LAST_LEVELED_UP: "Último avance de nivel",
    EARN_MODAL_INITIAL_MESSAGE:
      "Al completar esta actividad, estás fortaleciendo esta habilidad.",
    SHIELD_CONFIDENCE: "Campeón de la Confianza ",
    SHIELD_PURPOSE: "Pionero del Propósito ",
    EARN_MODAL_INITIAL_MESSAGE_HEADLINE: "¡Bien hecho!",
    EARN_MODAL_INITIAL_MESSAGE_BODY:
      "A medida que completes actividades, ganarás puntos de habilidad. ¡Sigue acumulando puntos y subiendo de nivel, hasta llegar al nivel 50!",
    EARN_MODAL_INITIAL_MESSAGE_CONTINUE_TO_SEE:
      "Sigue para ver tus estadísticas de",
    EARN_MODAL_LEVEL_UP: "¡SIGUIENTE NIVEL!",
    EARN_MODAL_YOUR_NEW_LEVEL: "TU NUEVO NIVEL",
    EARN_TOAST_EARNED: "Ganaste",
    EARN_TOAST_ANOTHER_POINT: "otro punto",
    EARN_TOAST_MORE_POINTS: "más puntos",
    EARN_TOAST_KEEP_GOING: "¡Sigue adelante para subir de nivel!",
    EARN_TO_LEVEL_UP: "¡Un punto más para subir de nivel!",
    EARN_TO_LEVEL_UP_PLURAL: "¡{0} más puntos para subir de nivel!",
    SPORT_TAGLINE: "Sport tagline.",
    SPORT_LAUNCH_CHARTER: "Launch Charter Program",
    CERT_TAGLINE: "Obtén tu certificación. Mantén tu cualificación.",
    CERT_PROGRAMS: "Programas",
    CERT_PROGRAMS_SUBHEAD: "Explora y gestiona tus certificaciones.",
    CERT_WELCOME_SUBHEAD: "Más que un requisito",
    CERT_WELCOME_TEXT:
      "Bienvenido a CERT, tu aliado en el camino hacia el éxito. CERT combina una certificación atractiva desde el principio con una mejora continua para un crecimiento personal constante.",
    CERTIFICATE_IS_PRESENTED_TO: "Esta certificación se otorga a",
    CERTIFICATE_FOR_COMPLETING: "por completar el programa",
    CERTIFICATE_BY_ACHIEVING: "Al lograr esta certificación, ",
    CERTIFICATE_AWARDED_ON: "Otorgado el: ",
    CERTIFICATE_DOWNLOAD: "Descargar",
    CERT_COMPLETE_DOWNLOAD_LINK: "Ver certificado",
    CERT_COMPLETE_SHARE_LINKEDIN: "Compartir en LinkedIn",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_TITLE: "Celébralo en LinkedIn",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_PARAGRAPH_1:
      "¡Enhorabuena de nuevo por haber obtenido tu certificación! Estamos orgullosos, y sabemos que tu red también lo estará.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_PARAGRAPH_2:
      "Aquí tienes cómo compartir tu logro en LinkedIn:",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_1:
      " para compartir fácilmente en LinkedIn.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_2:
      "En la sección “Media”, haz clic en “+ Añadir Media”.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_3:
      "Haz clic en la imagen CERT de abajo para copiar la dirección URL.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_4:
      "Pega la URL de la imagen CERT en el campo “Añadir un enlace” y haz clic en “Añadir”.",
    CERT_LINKEDIN_INSTRUCTIONS:
      "<br/> Haz clic en la imagen para copiar la URL:",
    CERT_LINKEDIN_SHARE_IMAGE_FILENAME: "headversity_cert_es.png",
    CERT_BADGE_COPIED_TO_CLIP_BOARD:
      "La URL de la imagen del CERT se ha copiado en tu portapapeles.",
    CERT_MODAL_QUALIFY_HEADER:
      "¡Ahora es el momento de mantener tu calificación!",
    CERT_MODAL_QUALIFY_SUB_TEXT:
      'Es hora de continuar tu viaje de certificación {0}. Haz clic en el botón "Mantén tu cualificación" abajo para completar las actividades y mantener tu certificación.',
    CERT_MODAL_ALREADY_QUALIFIED_SUB_TEXT:
      "Has completado los refrescamientos requeridos en este período. Siempre puedes completar actividades adicionales para continuar tu viaje de certificación {0}.",
    CERT_STAY_QUALIFIED: "Mantén tu cualificación",
    REVIEW_QUALIFICATION: "Revisar la calificación",
    CERT_POINTS_TO_QUALIFY: "¡1 punto más para mantener tu cualificación!",
    CERT_POINTS_TO_QUALIFY_PLURAL:
      "¡{0} puntos más para mantener tu cualificación!",
    CERT_QUALIFICATION_WAY_TO_GO: "¡Bien hecho! Has ganado ",
    CERT_QUALIFICATION_KEEP_GOING: "¡Sigue así para mantener tu cualificación!",
    SELECT_A_VALUE: "Selecciona una opción.",
    COPIED_TO_CLIPBOARD: "Copiado al portapapeles",
    CLICK_HERE: "Haz clic aquí",
    TOGGLE_NAVIGATION_MENU: "Alternar menú de navegación",
    SKIP_TO_MAIN_CONTENT: "Saltar al contenido principal",
    ALL_CATEGORIES: "Todas las categorías",
    NEGATIVE_FEEDBACK_WHY:
      "Sentimos escuchar eso 😔 ¿Hay algo más que puedas compartir sobre lo que no te gustó?",
    PROVIDE_FEEDBACK: "Por favor, proporciona tus comentarios aquí.",
    NEXT_PAGE: "Siguiente página",
    PREVIOUS_PAGE: "Página anterior",
    GO_TO_PAGE: "Ir a la página ",
    DAYS: "días",
    REACH_INSIGHTS_INDICATED_A: "indicó un",
    REACH_INSIGHTS_AGREEMENT_VS: "acuerdo versus",
    REACH_INSIGHTS_IN_OUR_REFERENCE_BENCHMARK:
      "en nuestro benchmark de referencia",
    REACH_INSIGHTS_INCREASE: "de aumento",
    REACH_INSIGHTS_DECREASE: "de disminución",
    REACH_INSIGHTS_IN_AGREEMENT_AFTER_COURSE: "de acuerdo después del curso",
    REACH_INSIGHTS_ENROLLED_USERS: "Usuarios inscritos",
    REACH_INSIGHTS_OUT_OF: "de",
    REACH_INSIGHTS_ELIGIBLE_USERS: "Usuarios elegibles",
    REACH_INSIGHTS_ACTIVE_USERS: "Usuarias activas",
    REACH_INSIGHTS_ALL_TIME: "Todo el tiempo",
    REACH_INSIGHTS_LAST_180_DAYS: "Durante los últimos 180 días",
    REACH_INSIGHTS_LAST_12_MONTHS: "Durante los últimos 12 meses",
    REACH_INSIGHTS_SELECTED_PURSUITS: "Búsqueda seleccionada",
    REACH_DOWNLOAD_REPORT: "Descargar Informe",
    REACH_INSIGHTS_USERS_DESCRIPTION:
      "Información sobre el alcance total y la accesibilidad de la plataforma, y ​​el nivel de mejora activa de habilidades que se produce en toda su base de usuarios.",
    REACH_INSIGHTS_PSYCHOGRAPHICS_TITLE: "Psicografía",
    REACH_INSIGHTS_PSYCHOGRAPHICS_DESCRIPTION:
      "Basado en {0} respuestas de usuarios durante los últimos 180 días",
    REACH_INSIGHTS_PSYCHOGRAPHICS_DESCRIPTION_ALL_TIME:
      "Basado en {0} respuestas de usuarios de todos los tiempos",
    REACH_INSIGHTS_SOLO_AND_TEAM_TITLE: "SOLO and TEAM",
    REACH_INSIGHTS_SOLO_AND_TEAM_DESCRIPTION:
      "Muestra las piezas de contenido de mayor tendencia basadas en la actividad del usuario, brindando visibilidad en los temas en los que los usuarios están mejorando sus habilidades.",
    REACH_INSIGHTS_KEY_TAKEAWAYS_STRENGTH: "Áreas de ",
    REACH_INSIGHTS_KEY_TAKEAWAYS_IMPROVEMENT: "Áreas para",
    REACH_INSIGHTS_KEY_TAKEAWAYS_STRENGTHS_DESCRIPTION:
      "Las respuestas superan los puntos de referencia del sector en estas áreas. ¡Celebra y siéntete orgulloso de estas fortalezas!",
    REACH_INSIGHTS_KEY_TAKEAWAYS_RISKS_DESCRIPTION:
      "Las respuestas están a menudo por debajo de los puntos de referencia del sector en estas áreas, destacando problemas potenciales y oportunidades de enfoque y crecimiento.",
    REACH_INSIGHTS_IMPROVEMENT: "mejora",
    REACH_INSIGHTS_STRENGTH: "fortaleza",
    REACH_INSIGHTS_STRONG: "fuerte",
    REACH_INSIGHTS_LESSON: "Lección",
    REACH_INSIGHTS_PRACTICE: "Práctica",
    REACH_INSIGHTS_TEAM: "TEAM",
    REACH_INSIGHTS_VIEW_ALL_AREAS: "Ver todas las áreas de fortaleza y riesgo",
    REACH_INSIGHTS_VIEW_ALL_CERTS: "Ver todos los informes CERT",
    REACH_INSIGHTS_GET_HELP_DESCRIPTION:
      "Obtener vistas de ayuda muestra las vistas mensuales de los recursos de ayuda y ofrece a los usuarios acceso a herramientas y recursos de soporte críticos.",
    REACH_INSIGHTS_HELP_RESOURCES_VIEWS_BY_MONTH:
      "Recursos de ayuda Vistas por mes",
    REACH_INSIGHTS_UNIQUE_HELP_RESOURCE_CLICKS:
      "Clics únicos en recursos de ayuda",
    REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES: "Desglose de actividades",
    REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES_DESCRIPTION_ALL_TIME:
      "% del total de actividades de todos los tiempos",
    REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES_DESCRIPTION:
      "% del total de actividades en los últimos 180 días",
    REACH_INSIGHTS_POPULAR_MICRO_LESSONS: "Lecciones populares",
    REACH_INSIGHTS_POPULAR_NANO_PRACTICES: "Prácticas populares",
    REACH_INSIGHTS_POPULAR_TEAM_PRESENTATIONS: "Presentaciones TEAM populares",
    REACH_INSIGHTS_ENROLMENT_TRENDS:
      "Tendencias de inscripción a lo largo del tiempo",
    REACH_INSIGHTS_ENROLMENT_TRENDS_LAST_180_DAYS:
      "Durante los últimos 180 días",
    REACH_INSIGHTS_TOTAL_ENROLLED_USERS: "Total de usuarios inscritos",
    REACH_INSIGHTS_CERT_TITLE: "CERT",
    REACH_INSIGHTS_CERT_DESCRIPTION:
      "Indica el progreso y los logros generales de la certificación.",
    REACH_INSIGHTS_BREAKDOWN_BY_CERT: "Desglose por CERT",
    REACH_INSIGHTS_ACTIVE_CERTIFICATIONS: "Certificaciones activas",
    REACH_INSIGHTS_ACTIVE_CERTIFICATION_RATES: "Tasa de certificación",
    REACH_INSIGHTS_TO_DATE: "Hasta la fecha",
    REACH_INSIGHTS_ASSIGNED_CERTIFICATIONS: "Certificaciones asignadas",
    REACH_INSIGHTS_CERTIFICATION_ACTIVITY_OVER_TIME:
      "Actividad de certificación a lo largo del tiempo",
    REACH_INSIGHTS_USER_FEEDBACK: "Comentarios del usuario",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_CERTIFICATIONS_TITLE:
      "¿Qué es una certificación activa?",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_CERTIFICATIONS_DESCRIPTION:
      "Certificaciones completadas y vigentes, sin incluir en curso ni no iniciadas.",
    REACH_INSIGHTS_TOOLTIP_ENROLLED_USER_TITLE: "¿Qué es un usuario inscrito?",
    REACH_INSIGHTS_TOOLTIP_ENROLLED_USER_DESCRIPTION:
      "Usuarios elegibles que hayan configurado correctamente sus cuentas.",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_USER_TITLE: "¿Qué es un usuario activo?",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_USER_DESCRIPTION:
      "Usuarios inscritos que han utilizado la aplicación..",
    REACH_INSIGHTS_CERTIFIED_USERS: "Usuarios certificados",
    REACH_INSIGHTS_USERS_IN_PROGRESS: "Usuarios en progreso",
    REACH_INSIGHTS_QUALIFIED_USERS: "Usuarios calificados",
    REACH_INSIGHTS_TOOLTIP_CERTIFIED_USERS_TITLE:
      "¿Qué son las usuarias certificadas?",
    REACH_INSIGHTS_TOOLTIP_CERTIFIED_USERS_DESCRIPTION:
      "Usuarios que hayan completado una certificación.",
    REACH_INSIGHTS_TOOLTIP_CERT_USERS_IN_PROGRESS_TITLE:
      "¿Qué son las usuarias en progreso?",
    REACH_INSIGHTS_TOOLTIP_CERT_USERS_IN_PROGRESS_DESCRIPTION:
      "Usuarios que tengan certificación en curso.",
    REACH_INSIGHTS_TOOLTIP_QUALIFIED_USERS_TITLE:
      "¿Qué son las usuarias calificadas?",
    REACH_INSIGHTS_TOOLTIP_QUALIFIED_USERS_DESCRIPTION:
      "Usuarios que han obtenido una certificación y han completado el nivel de actividad requerido para mantenerse calificados dentro de un período.",
    REACH_INSIGHTS_BEFORE_AND_AFTER_TITLE: "Antes y después de",
    REACH_INSIGHTS_RELEVANT_CONTENT_DESCRIPTION:
      "Usuarios que encontraron el contenido relevante",
    REACH_INSIGHTS_MOTIVATED_CONTENT_DESCRIPTION:
      "Usuarios que se sintieron motivados a actuar positivamente después de participar en el contenido.",
    REACH_INSIGHTS_MEANINGFUL_CONTENT_DESCRIPTION:
      "Usuarios que encontraron significado en su búsqueda",
    REACH_INSIGHTS_NO_CHANGE_AFTER_COMPLETING_COURSE:
      "Sin cambio después de completar el curso.",
    REACH_INSIGHTS_LEGEND_AGREE: "De acuerdo",
    REACH_INSIGHTS_LEGEND_NEUTRAL: "Acuerdo neutral",
    REACH_INSIGHTS_LEGEND_DISAGREE: "Desacuerdo",
    YOUR_PURSUIT: "Tu búsqueda",
    YOUR_PURSUIT_WITH_COLON: "Tu búsqueda:",
    TOUR_PURSUIT:
      "Enfoca tu entrenamiento en lo que es más importante para ti.",
    TOUR_PURSUIT_BOX: "Continúa entrenando en tu búsqueda.",
    OK: "¡OK!",
    EXCLAMATION: "!",
    COLON: ":",
    PURSUIT_CHECKIN0:
      "Aquí tienes tu búsqueda seleccionada. ¡Empieza a entrenar hoy mismo!",
    PURSUIT_CHECKIN1: "Casi has entrenado en cada habilidad. ¡Sigue así!",
    PURSUIT_CHECKIN2:
      "Has entrenado en cada habilidad. ¡Sigue adelante o revisa tu progreso!",
    PURSUIT_STARTING_POINT: "Aquí está tu punto de partida sugerido.",
    PURSUIT_BUILD_SKILLS: "Desarrolla habilidades en:",
    PURSUIT_SELECT: "Selecciona una búsqueda",
    PURSUIT_FOCUS: "¡Enfoca tu entrenamiento hoy!",
    PURSUIT_DIFFERENT: "Elige una búsqueda diferente",
    PURSUIT_OWN: "Elige la mía en su lugar",
    PURSUIT_IN_THIS_PURSUIT: "en esta búsqueda",

    REFRESH_ACTIVITIES: "Refresca las actividades",
    REVIEW_PROGRESS: "Revisar progreso",
    LETS_REVIEW_PROGRESS: "Revisemos tu progreso en ",
    HERES_AN_OVERVIEW:
      "Aquí tienes un resumen de los <b>{0}</b> XP que has ganado ",
    SO_FAR: "hasta ahora",
    OVER_LAST_30: "en los últimos 30 días",
    VIEW_OVER_TIME: "Ver a lo largo del tiempo",
    VIEW_BY_SKILL: "Ver por habilidad",
    VIEW_LAST_30: "Ver los últimos 30 días",
    VIEW_ALLTIME: "Ver todo el tiempo",
    CONCEPTS_LEARNED_AND_APPLIED:
      "Aquí tienes un resumen de los conceptos que has aprendido y aplicado.",
    PRACTICE_TECHNIQUES: "<b>Técnicas de práctica</b> incluidas:",
    LESSON_TOPICS: "<b>Temas de las lecciones</b> incluidos:",
    HOW_DID_YOU_FEEL_ABOUT_TRAINING:
      "¿Cómo te sentiste acerca de tu entrenamiento?",
    FOUND_PURSUIT_RELEVANT: "Encontré relevante la búsqueda de {0}.",
    BETTER_UNDERSTAND: "Ahora entiendo mejor cómo {0}",
    PURSUE_NEXT: "Veamos qué deberías perseguir ahora.",
    PURSUE_NEXT_HOW:
      "Para hacer esto, te haremos algunas preguntas para ayudarte a identificar un nuevo área de enfoque.",
    KEEP_CURRENT_PURSUIT: "Mantén mi búsqueda actual en su lugar",
    TRACKING: "Seguimiento",
    VIDEO: "Video",
    REFLECTION: "Reflexión",
    WELCOME_BACK: "¡Bienvenido de nuevo",

    TIME_PICKER_SET: "Establecer",
    TIME_PICKER_CANCEL: "Cancelar",
    TIME_PICKER_NOW: "Ahora",
    TIME_PICKER_SELECT_NOW: "Seleccionar ahora",
    TIME_PICKER_TOGGLE_TIME_SELECTOR: "Alternar selector de hora",
    TIME_PICKER_TOGGLE_CLOCK: "Alternar reloj",
    HERES_A_QUICK_RECAP: "Aquí tienes un breve resumen.",
    SUMMARY: "Resumen",
    RESPONSES: "Respuestas",
    NEXT_STEP: "Próximos pasos...",
    PAST_SUMMARY: "Mirando hacia atrás...",
    YOU_FELT: "Te sentiste:",
    BECAUSE_OF: "Debido a:",
    SEE_DETAILS: "Ver detalles",
    HIDE_DETAILS: "Ocultar detalles",
    OPEN_APP_BANNER_DESCRIPTION: "Abre la aplicación a través de Google Play",
    OPEN: "Abrir",
    CLOSE_THIS_BROWSER:
      "¡Has iniciado sesión con éxito! Ahora puedes cerrar esta página y volver a la página que estaba esperando a que inicies sesión.",
    CHECK_EMAIL_TO_LOGIN:
      "Por favor, revisa tu correo electrónico <b>{email}</b> para un mensaje que contiene el enlace de inicio de sesión. Haz clic en el enlace en el correo electrónico para completar el proceso de inicio de sesión. Después de hacer clic en el enlace, vuelve a esta página para continuar con tus actividades.",
    PASSWORD_LESS_LOGIN_MSG:
      "Ingresa tu dirección de correo electrónico a continuación y te enviaremos un enlace de inicio de sesión. Haz clic en el enlace en tu correo electrónico para acceder a tu cuenta al instante. ¡No necesitas recordar una contraseña!",

    PURSUIT_CONTINUE: "Continúa tu búsqueda",
    PURSUIT_PICKUP: "Retoma desde donde lo dejaste en",
    FAVORITES: "Favoritos",
    FOR_REFLECTION: "Para reflexionar",
    STRESS_RELIEF: "Alivio del estrés",
    SOMETHING_NEW: "Algo nuevo",

    CONTINUE_TRAINING_FOR: "Continuemos el entrenamiento en",
    START_TRAINING_FOR: "Empecemos.",
    DO_NEXT: "¿Qué te gustaría hacer a continuación?",
    SIMPLE_PROVEN: "técnicas simples y comprobadas",
    LEARN: "Aprender",
    THE_SCIENCE: "la ciencia detrás de la resiliencia",
    TRAINING_INTRO:
      "Como estiramientos rápidos para tu mente, las actividades en SOLO son todas de tamaño reducido. Registra tus pensamientos y sentimientos, practica técnicas comprobadas, y explora la ciencia que lo sustenta todo.",
    LETS_COMPLETE: "¡Completa tu primera actividad en {0} ahora!",

    STREAK: "racha",
    STREAK_CONTINUE: "¡Continúa tu racha de {0} {1}!",
    STREAK_GOING: "¡Estás en una racha de {0} {1}!",

    STREAK_AT: "a",
    STREAK_DAILY_MESSAGE:
      "<b style='font-weight:600'>La práctica diaria</b> proporciona la mejora más rápida y la formación de hábitos más fuerte. Te enviaremos una notificación push diariamente si no has practicado.",
    STREAK_WEEKLY_MESSAGE:
      "<b style='font-weight:600'>La práctica semanal</b> ofrece un gran equilibrio entre progreso y ritmo. Te enviaremos una notificación push cada miércoles y viernes si no has practicado.",
    STREAK_MONTHLY_MESSAGE:
      "<b style='font-weight:600'>La práctica mensual</b> es ideal para un crecimiento gradual que es fácil de mantener. Te enviaremos una notificación push el 15 y 25 de cada mes si no has practicado.",
    STREAK_DAILY: "Diariamente",
    STREAK_WEEKLY: "Semanalmente",
    STREAK_MONTHLY: "Mensualmente",
    STREAK_DAY: "Día",
    STREAK_DAY_PLURAL: "días",
    STREAK_WEEK: "Semana",
    STREAK_WEEK_PLURAL: "semanas",
    STREAK_MONTH: "Mes",
    STREAK_MONTH_PLURAL: "meses",
    STREAK_DAY_SINGLE_PHRASE: "días",
    STREAK_WEEK_SINGLE_PHRASE: "semanas",
    STREAK_MONTH_SINGLE_PHRASE: "meses",
    STREAK_YEAR: "año",
    STREAK_WEEKS: ["Semana 1", "Semana 2", "Semana 3", "Semana 4"],
    STREAK_MONTHS: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],

    COMPLETE_ACTIVITY: "Completa una actividad hoy para mantener tu racha de",
    COMPLETE_ACTIVITY_STREAK_GOING: "",
    THIS_WEEK: "esta semana",
    THIS_MONTH: "este mes",
    STREAK_KEEP_GOING: "¡Mantén tu racha!",
    YOUR_STREAK: "Tu racha",
    START_A_STREAK: "¡Comienza una racha!",
    YOURE_ON_A_STREAK: "¡Estás en una racha!",
    START_STREAK_DESCRIPTION:
      "¡Establece un desafío, completa una actividad y comienza una racha hoy!",
    START_STREAK_WITH_CHALLENGE_DESCRIPTION:
      "¡Completa una actividad y comienza una racha hoy!",
    COMPLETED_ACTIVITY: "Has completado una actividad",
    IN_A_ROW: "seguidas",
    CONTINUE_TRAINING_EACH:
      "Continúa entrenando cada {0} para mantener tu racha y",
    MEET_YOUR_CHALLENGE: "cumplir tu desafío",
    KEEP_MOMENTUM: "mantener el impulso",

    CHALLENGE_YOURSELF: "Desafíate a seguir",
    CONTINUE_LEVELING_UP: "subiendo de nivel",
    CHALLENGE_MESSAGE:
      "Gestiona el estrés, adáptate al cambio y supera la adversidad. Al practicar regularmente, mejorarás tu bienestar mental y emocional y estarás más agudo, feliz y saludable.",
    PRACTICE_EVERY: "Practica cada",
    REMIND_ME: "Recuérdame",

    LEVEL_LEVELED_UP: "¡Felicidades, acabas de subir de nivel!",
    LEVEL_ENCOURAGE: "¡Vas por buen camino!",
    LEVEL_FIRST_TIME:
      "A medida que completes actividades, ganarás puntos de experiencia (XP) y alcanzarás nuevos niveles de resiliencia. ¡Sigue adelante, desde <b>PRINCIPIANTE</b> hasta <b>CAMPEÓN</b>!",
    LEVEL_NOW_AT: "Ahora estás en el",
    LEVEL_KEEP_GOING: "Sigue adelante, todo el camino hasta <b>CAMPEÓN</b>.",
    LEVEL: "Nivel",
    LEVEL_TO_GO_PRE: "Faltan<br />",
    LEVEL_TO_GO_POST: "",
    LEVEL_NAMES: [
      "PRINCIPIANTE",
      "APRENDIZ",
      "EXPLORADOR",
      "CONSTRUCTOR",
      "REALIZADOR",
      "NAVEGANTE",
      "GUÍA",
      "MENTOR",
      "CATALIZADOR",
      "CAMPEÓN",
    ],
    LEVEL_DESCRIPTIONS: [
      "Has dado el primer paso en la gestión de desafíos y estás sentando una base sólida para tu crecimiento futuro.",
      "Estás mejorando en equilibrar la vida en el hogar y el trabajo mientras comienzas a utilizar técnicas básicas de resiliencia.",
      "Puedes manejar mayores factores de estrés y estás creando una rutina que apoya tu salud mental y resiliencia.",
      "Utilizas constantemente técnicas de resiliencia en varias áreas de tu vida y notas mejoras en la gestión del estrés.",
      "Muestras un alto nivel de resiliencia, equilibrando múltiples áreas de la vida mientras mantienes tu bienestar.",
      "Navegas bien por desafíos complejos, mostrando una fuerte resiliencia que mejora tu productividad y relaciones.",
      "Manejas la adversidad con facilidad y tus compañeros a menudo te buscan para obtener orientación y apoyo.",
      "Exhibes habilidades avanzadas de resiliencia, frecuentemente mentorizando a otros y contribuyendo a una comunidad resiliente.",
      "Constantemente prosperas en situaciones de alto estrés, liderando iniciativas de resiliencia y bienestar a mayor escala.",
      "Tu resiliencia es una segunda naturaleza. Inspiras y guías a comunidades enteras hacia una mejor salud mental y resiliencia.",
      "¡Tu dedicación, habilidad y perseverancia continúan inspirando! Sigue empujando tus límites y alcanzando la grandeza.",
    ],
    XP_EARNED: "XP ganados",
    TAKE_A_MINUTE: "Tómate un minuto para reflexionar sobre tus respuestas.",
    CHARTER_BEST_VOTE_TITLE_THE: "The",
    CHARTER_BEST_VOTE_TITLE_BEST: "BEST",
    CHARTER_BEST_VOTE_TITLE_WORST: "WORST",
    CHARTER_BEST_VOTE_DESCRIPTION:
      "The things your team thinks are the most important for a team to be successful.",
    CHARTER_WORST_VOTE_DESCRIPTION:
      "The things your team thinks are the most harmful for a team.",
    YOUR_GROUP_SAID: "Your group said:",
    WHAT_ARE_SOME_OF_THE: "What are some of the",
    CHARACTERISTICS_THAT_MAKE_A_TEAM_SUCCESSFUL:
      "characteristics that make a team successful?",
    CHARACTERISTICS_THAT_CAN_HARM_A_TEAM:
      "characteristics that can harm a team and their success?",
    TEAM_POLL_WAITING_FOR_GROUP_RESULTS:
      "Waiting for all groups to submit their answers.",
    TEAM_POLL_REPORTER_DID_NOT_RESPOND:
      "Your group did not provide any answers to choose from. Waiting for other groups to submit their answers.",
    TEAM_POLL_WAITING_FOR_USER_RESULTS:
      "Waiting for everyone to submit their answers.",
    POLL_MULTI_INPUT_SUBMIT_AND_ADD_ANOTHER: "Submit and Add Another",
    POLL_MULTI_INPUT_IM_DONE: "I'm Done",
    POLL_MULTI_INPUT_WAITING: "Waiting for your response",
    FOUNDATIONAL_TRAINING: "Entrenamiento Fundamental",
    HOWD_IT_GO: "¿Cómo te fue?",
    LET_US_KNOW: "Cuéntanos cómo te sentiste con esta experiencia.",
    SPORT_RESPONSES: "Respuestas:",
  },

  fr: {
    TEAM_ERROR_USER_REGISTERED_WITH_DIFFERENT_COMPANY:
      "L'utilisateur est enregistré avec une autre compagnie. Il ne peut pas être ajouté à des équipes en dehors de sa compagnie enregistrée.",
    GLOSSARY: "Glossaire",
    USER_ROLE_LEARNER: "Apprenant",
    USER_ROLE_ADMIN: "Admin",
    USER_ROLE_HV_ADMIN: "Admin HV",
    USER_ROLE_HV_CONTENT_ADMIN: "Admin de contenu HV",
    USER_ROLE_HV_CSM_ADMIN: "Admin du service clientèle HV",
    USER_ROLE_HV_MKT_ADMIN: "Admin marketing HV",
    USER_ROLE_SALES: "Personne de ventes",
    SINGLE_SIGN_ON_LINK_TEXT:
      "Votre email est associé à un service de single sign-on (SSO) externe. Veuillez {0} en utilisant le fournisseur de SSO externe.",
    SINGLE_SIGN_ON_LINK: "authentifier",
    LANGUAGE_ENGLISH: "English",
    LANGUAGE_FRENCH: "Français",
    LANGUAGE_SPANISH: "Español",
    NANO_PRACTICE_NO_RESULTS: "Aucun résultat trouvé.",
    NANO_PRACTICE_TYPE_FILTER: "Tous les types de pratique",
    NANO_PRACTICE_CATEGORY: "Collection",
    NANO_PRACTICE_CATEGORY_FLEXIBLE_THINKING: "Pensée flexible",
    NANO_PRACTICE_CATEGORY_TAKING_ACTION: "Passer à l'action",
    NANO_PRACTICE_CATEGORY_TAMING_EMOTIONS: "Dompter les émotions",
    NANO_PRACTICE_CATEGORY_QUICK_RELIEF: "Soulagement du stress",
    NANO_PRACTICE_CATEGORY_FLEXIBLE_THINKING_DESCRIPTION:
      "Apprenez à être ouvert d'esprit et à trouver différentes solutions aux problèmes.",
    NANO_PRACTICE_CATEGORY_TAKING_ACTION_DESCRIPTION:
      "Prenez en charge vos habitudes et actions pour créer des résultats positifs et atteindre vos objectifs.",
    NANO_PRACTICE_CATEGORY_TAMING_EMOTIONS_DESCRIPTION:
      "Contrôlez vos émotions pour qu'elles ne vous contrôlent pas.",
    NANO_PRACTICE_CATEGORY_QUICK_RELIEF_DESCRIPTION:
      "Calmez rapidement votre esprit et votre corps, offrant un soulagement instantané du stress et de la tension.",
    NANO_PRACTICE_TYPE: "Type",
    NANO_PRACTICE_TYPE_VIDEO: "Vidéo",
    NANO_PRACTICE_TYPE_SHOW_AND_TELL: "Réflexion",
    NANO_PRACTICE_TYPE_HEADZONE: "Suivi",
    NANO_PRACTICE_FAVORITES_ONLY: "Favoris seulement",
    SOLO_HOME_INTERNET_CONNECTION_ERROR:
      "Nous sommes désolés, il est impossible de télécharger l'appli en ce moment. Vérifie ta connexion Internet et réessaye plus tard.",
    SOLO_AI_ERROR:
      "Nous sommes désolés, une erreur inattendue s'est produite. Veuillez réessayer.",
    SOLO_UNEXPECTED_ERROR:
      "Nous sommes désolés, une erreur inattendue s'est produite. Vérifie ta connexion Internet et réessaye plus tard.",
    SPORT_MENU_HOME: "Accueil",
    SPORT_MENU_RESOURCES: "Ressources",
    SOLO_MENU_HOME: "Accueil",
    SOLO_MENU_SKILLS: "Compétences",
    SOLO_MENU_LEARN: "Leçons",
    SOLO_MENU_PRACTICE: "Outils",
    SOLO_MENU_RESILIENCE_FEED: "Fil de résilience",
    SOLO_MENU_LOGOUT: "Déconnexion",
    SOLO_MENU_CHOOSE_THEME: "Thème",
    SOLO_MENU_NOTIFICATION: "Notifications",
    SOLO_MENU_NOTIFICATION_OPTIMISM_SINGLE:
      "Fais un bilan pour ton défi Optimisme",
    PRACTICE_REMINDER_SAVED: "Défi établi!",
    PRACTICE_REMINDER_REMOVED: "Défi établi.",
    PRACTICE_REMINDERS: "Rappels de pratique",
    PRACTICE_REMINDER_HABITS:
      "Pour t'aider à te rappeler de continuer à pratiquer, nous te recommandons de définir un ",
    PRACTICE_REMINDER: "rappel de pratique",
    PRACTICE_REMINDER_HABITS2:
      "Définir un rappel te permettra d'intégrer la pratique dans ta routine quotidienne et à développer des habitudes saines.",
    PRACTICE_REMINDER_REMINDERS: "Activer le défi",
    PRACTICE_REMINDER_AT_THE_FOLLOWING_TIME: "À l'heure suivante",
    PRACTICE_REMINDER_AT_THE_FOLLOWING_DAYS: "Les jours suivants de la semaine",
    PRACTICE_REMINDER_DAY_OF_WEEK_SUN: "D",
    PRACTICE_REMINDER_DAY_OF_WEEK_MON: "L",
    PRACTICE_REMINDER_DAY_OF_WEEK_TUE: "M",
    PRACTICE_REMINDER_DAY_OF_WEEK_WED: "M",
    PRACTICE_REMINDER_DAY_OF_WEEK_THUR: "J",
    PRACTICE_REMINDER_DAY_OF_WEEK_FRI: "V",
    PRACTICE_REMINDER_DAY_OF_WEEK_SAT: "S",
    PROFILE_PAGE_HISTORY: "Historique de formation",
    PROFILE_PAGE_PRACTICE_REMINDERS_HISTORY: "Voir l'historique de formation",
    PRACTICE_REMINDER_REMOVE_FAV: "Retire de tes pratiques préférées",
    PRACTICE_REMINDER_ADD_FAV: "Ajoute à tes pratiques préférées",
    PRACTICE_REMINDER_ADD_FAV_HEADLINE:
      "Veux-tu ajouter cette pratique à tes préférées?",
    PRACTICE_ADDED_TO_FAVORITES: "Pratique ajoutée à tes favoris!",
    PRACTICE_REMOVE_FAVORITE_TITLE: "Retirer des favoris?",
    PRACTICE_REMOVE_FAVORITE: "Es-tu sûr de vouloir retirer ce favori?",
    PRACTICE_REMINDER_SET_REMINDER: "Gérer le défi",
    PRACTICE_REMINDER_MANAGE_CHALLENGE: "Gérer le défi",
    SOLO_MENU_NOTIFICATION_FOCUS_SINGLE:
      "Tu as un plan de Concentration à venir",
    SOLO_MENU_PUSH_NOTIFICATION_PERMISSION: "Activer les Notifications Push",
    SOLO_MENU_PUSH_DISABLE_NOTIFICATIONS: "Désactiver les Notifications Push",
    SOLO_MENU_LIVE_UPDATE: "Vérifier les Mises à Jour",
    SOLO_MENU_PROFILE: "Profil",
    SOLO_MENU_PROFILE_SETTINGS: "Profil / Paramètres",
    SOLO_MENU_WEBPUSH:
      "Activer les notifications pour une meilleure expérience",
    SOLO_MENU_TERMS_OF_SERVICE: "Conditions d'utilisation",
    SOLO_MENU_INVITE_FAMILY_MEMBERS: "Inviter des membres de la famille",
    SOLO_MENU_PRIVACY_POLICY: "Politique de renseignements personnels",
    SOLO_MENU_CONTACT: "Contact",
    SOLO_HOME_SEE_MORE_RESILIENCE_FEED: "Voir tout le fil de résilience",
    SOLO_SEE_ALL_LIVE_SESSIONS: "Voir toutes les sessions en direct",

    WELCOME_TO: "Bienvenue à",
    SOLO_HOME_DESCRIPTION: "Prends de l’avance sur l’adversité aujourd’hui",
    SOLO_HOME_YOUR_TITLE: "Tes",
    SOLO_HOME_LIVE_SESSIONS_TITLE: "Sessions en direct",
    SOLO_HOME_LIVE_SESSIONS_DESCRIPTION:
      "Événements et présentations en direct.",
    SOLO_HOME_RESILIENCE_FEED_TITLE: "Fil de Résilience",
    SOLO_HOME_RESILIENCE_FEED_DESCRIPTION:
      "Une collection de ressources conçues pour t’aider à tirer le meilleur parti de ton cheminement vers la résilience.",
    SOLO_HOME_RESILIENCE_FEED_MULTIPLE_SKILLS: "Plusieurs compétences",
    SOLO_HOME_RESILIENCE_SKILLS_DESCRIPTION:
      "Les compétences essentielles qui font de toi qui tu es, et qui te permettent de faire face à l'adversité.",
    SOLO_HOME_ACHIEVEMENTS_DESCRIPTION: "Suis tes progrès",
    SOLO_HOME_SUGGESTED_TITLE: "Suggéré",
    SOLO_HOME_SUGGESTED_TITLE_LESSON: "Leçons suggérées",
    SOLO_HOME_SUGGESTED_TITLE_TOOLS: "Outils suggérés",
    SOLO_HOME_SUGGESTED_FOR_YOU_TITLE: "Pour toi",
    SOLO_HOME_SUGGESTED_FOR_YOU_LESSON_DESCRIPTION:
      "Une collection de leçons basées sur tes scores actuels de compétences en Résilience.",
    SOLO_HOME_SUGGESTED_FOR_YOU_TOOL_DESCRIPTION:
      "Une collection d'outils basés sur tes scores actuels de compétences en Résilience.",
    SOLO_EXPLORE_DESCRIPTION:
      "Parcours toutes les ressources disponibles pour renforcer ta résilience",
    SOLO_LEARN_DESCRIPTION:
      "Découvre la science derrière la résilience grâce à des leçons",
    SOLO_LEARN_NEXT_LESSON: "Prochaine leçon",
    SOLO_PRACTICE_DESCRIPTION:
      "Renforce ta résilience grâce à des techniques approfondies",
    SOLO_SETTINGS_TITLE: "Paramètres",
    SOLO_PROFILE_TITLE: "Profil",
    SOLO_PROFILE_DESCRIPTION:
      "Consulte ton progrès, tes notifications et tes paramètres",
    SOLO_SKILL_TITLE: "Compétences en résilience",
    SOLO_SKILL_DESCRIPTION:
      "Les compétences essentielles pour devenir le plus résilient possible",
    SOLO_ACHIEVEMENT_TITLE: "Accomplissements",
    SOLO_ACHIEVEMENT_DESCRIPTION:
      "Suis tes progrès et sois fier du chemin parcouru!",
    SOLO_SKILL_VIEW_ALL_ACHIEVEMENTS: "Voir tous les accomplissements",
    SOLO_ACHIEVEMENT_MODAL_BADGE_NOT_EARNED_TEXT:
      "Tu n’as pas encore gagné ce badge.",
    SOLO_ACHIEVEMENT_MODAL_BADGE_EARNED_TEXT: "Tu as gagné ce badge",
    SOLO_ACHIEVEMENT_MODAL_BADGE_EARNED_DATE_TEXT:
      "Tu as gagné ce badge pour la première fois",
    SOLO_ACHIEVEMENT_MODAL_BADGE_EARNED_DATE_TEXT_SINGLE:
      "Tu as gagné ce badge",
    SOLO_PAGE_NOT_FOUND: "Page introuvable",
    SOLO_PAGE_NOT_FOUND_DESCRIPTION:
      "La page que tu recherches a peut-être été supprimée, son nom a été modifié ou elle est temporairement indisponible.",
    HEADSCAN_QUESTION_REDO: "Recalculer les scores de résilience",
    HEADSCAN_QUESTION_WELCOME_SENTENCE: "Bienvenue à",
    HEADSCAN_QUESTION_FIRST_SENTENCE:
      "Voici tes scores initiaux de compétence en Résilience. Ils indiquent comment tu te sens &lt;em&gt;en ce moment&lt;/em&gt;. Ils peuvent changer chaque jour et ne sont en aucun cas permanents.",
    HEADSCAN_QUESTION_BOLD_SENTENCE:
      "Tu peux réévaluer tes résultats à tout moment.",
    HEADSCAN_QUESTION_LAST_SENTENCE:
      "Continue à le faire pour observer comment tu progresses au fil du temps!",
    HEADSCAN_QUESTION_START_MY_TRAINING: "Commencer ma formation",
    LESSON_SUCCESS_CHECK_INS: "Vérification état personnel",
    LESSON_SUCCESS_RELEVANT: "J'ai trouvé la leçon pertinente",
    LESSON_SUCCESS_POSITIVELY: "Je suis motivé(e) à agir positivement",
    LESSON_START: "Commencer la leçon",
    LESSON_RESUME: "Reprendre la leçon",
    LESSON_RESTART: "Recommencer la leçon",
    LESSON_STARTED_ON: "Tu as commencé cette leçon le",
    LESSON_COMPLETED_ON: "Tu as terminé cette leçon le",
    QUESTION: "Question",
    NEXT: "Suivant",
    BACK: "Retour",
    PREV: "Suiv",
    STEP: "Étape",
    DONE: "Terminé",
    DONE_NEXT: "Done, Next",
    WE_ARE_DONE: "We're Done",
    SAVE: "Enregistrer",
    EDIT: "Éditer",
    COMPLETE: "Complété",
    AND: "et",
    START_TRAINING: "Commence",
    CONTINUE_TRAINING: "Continuer",
    GET_STARTED: "Commençons",
    CATCH_UP: "Rattraper",
    IN_PROGRESS: "En cours",
    ANOTHER_IN_PROGRESS: "Une autre présentation est en cours",
    CLOSE: "Fermer",
    PAGE: "Page",
    OF: "de",
    OR: "ou",
    NAME: "Nom",
    EMAIL: "E-mail",
    REMOVE: "Éliminer",
    PUTBACK: "Put back",
    SKIP: "Passer",
    IN: "dans",
    AND_MORE: "et plus",
    AND_X_MORE: "et {0} de plus",
    SKILL_ENERGY_MANAGEMENT: "Gestion de l'énergie",
    SKILL_PANEL_PAGE: "Page",
    SKILL_CONTENT_PAGE_YOUR_SCORE: "Ton score",
    SKILL_CONTENT_PAGE_YOUR_SCORE_WITH_FORMAT: "Ton Score {0}",
    SKILL_CONTENT_PAGE_REDO_YOUR_RESILIENCE_SCORE: "Recalculer le score",
    SKILL_CONTENT_PAGE_LESSONS: "Leçons",
    SKILL_CONTENT_PAGE_LESSON_DESCRIPTION:
      "Des leçons triées sur le volet pour toi, conçues pour t’aider à améliorer tes",
    SKILL_CONTENT_PAGE_LESSON_DESCRIPTION_SKILL: "compétences.",
    RESiLIENCE_PAGE_DESCRIPTION:
      "Une collection de ressources conçues pour t’aider à tirer le meilleur parti de ton parcours de résilience",
    RESILIENCE_FEED_FEEDBACK1: "J'ai trouvé cette ressource utile",
    SKILL_CONTENT_PAGE_SUGGESTED: "Suggéré",
    SKILL_CONTENT_PAGE_SUGGESTED_LESSONS_FOR_YOU: "Leçons suggérées",
    SKILL_CONTENT_PAGE_SUGGESTED_TOOLS_FOR_YOU: "Outils suggérés",
    SKILL_CONTENT_PAGE_RELATED_TOOLS: "Outils associés",
    SKILL_CONTENT_PAGE_TOOLS_DESCRIPTION:
      "Des outils triés sur le volet par notre équipe, conçus pour t’aider à améliorer tes",
    SKILL_CONTENT_PAGE_TOOLS_DESCRIPTION_SKILL: " compétences.",
    SKILL_CONTENT_PAGE_RESILIENCE_FEED_DESCRIPTION:
      "Une collection de ressources liées à",
    BADGES_EARNED: "Badges",
    SHIELDS_IN_PROGRESS: "Boucliers en cours d'obtention",
    USE_HEADZONE_TOOL: "Utiliser État d'esprit",
    USE_CALMING_TOOL: "Utiliser Relaxation",
    USE_FOCUS_TOOL: "Utiliser Concentration",
    USE_THINKING_TOOL: "Utiliser Pensée I.E.M.",
    USE_MINDFULNESS_TOOL: "Utiliser Pleine conscience",
    USE_MOVE_TOOL: "Utiliser Activité",
    USE_OPTIMISM_TOOL: "Utiliser Optimisme",
    EXPLORE: "Explorer",
    EXPLORE_HEADZONE_TOOL: "Explorer État d'esprit",
    EXPLORE_CALMING_TOOL: "Explorer Relaxation",
    EXPLORE_FOCUS_TOOL: "Explorer Concentration",
    EXPLORE_THINKING_TOOL: "Explorer Pensée I.E.M.",
    EXPLORE_MINDFULNESS_TOOL: "Explorer Pleine conscience",
    EXPLORE_MOVE_TOOL: "Explorer Activité",
    EXPLORE_OPTIMISM_TOOL: "Explorer Optimisme",
    TOOLS_PAGE_USED_TIMES: "Nombre de fois",
    TOOLS_PAGE_USED_TIME: "Durée",
    CALMING_TOOLS: "Relaxation",
    CALMING_START: "COMMENCER",
    CALMING_STOP: "FAIRE UNE PAUSE",
    CALMING_IN: "INSPIRE",
    CALMING_OUT: "EXPIRE",
    CALMING_MODE_TIME: "Durée",
    CALMING_MODE_CYCLE: "Cycles",
    CALMING_INSTRUCTION_TEXT:
      "Suis le cercle. Inspire quand il s'agrandit. Expire quand il se rétrécit.",
    CALMING_INSTRUCTION_BUTTON_TEXT: "Relaxation",
    CALMING_TOOLS_PAGE_USE_TOOL: "Lancer l'outil Relaxation",
    CALMING_TOOLS_PAGE_BOOST:
      "L'outil Relaxation peut aider à augmenter ton/ta",
    CALMING_TOOLS_PAGE_LAST_USED:
      "La dernière fois que tu t’es senti(e) calme, c'était",
    CALMING_TOOLS_PAGE_USED: "Nombre de fois utilisé",
    CALMING_TOOLS_PAGE_INFO_ONE:
      "Prends quelques respirations lentes et profondes pour réduire ton niveau de stress et pour ramener ton attention sur le moment présent. ",
    HEAD_ZONE_TOOLS: "État d'esprit",
    HEAD_ZONE_HOW_FEELING: "Comment te sens-tu?",
    HEAD_ZONE_TOOLS_HELP_TEXT: "Clique/glisse pour modifier",
    HEAD_ZONE_TOOLS_HELP_TEXT_MOBILE: "Tape/glisse pour modifier",
    HEAD_ZONE_TOOLS_IN_THIS: "DANS CET ",
    HEAD_ZONE_TOOLS_HEADZONE: "ÉTAT D'ESPRIT",
    HEAD_ZONE_TOOLS_DONE: "Terminé",
    HEAD_ZONE_TOOLS_MORE: "Plus",
    HEAD_ZONE_TOOLS_PAGE_INFO_ONE:
      "L'entraînement de ton État d'esprit t’aide à te sentir plus positif face aux événements du quotidien et améliore ton humeur générale.",
    HEADZONE_TOOLS_PAGE_BOOST:
      "L'outil État d'esprit peut t’aider à augmenter ton/ta",
    HEADZONE_TOOLS_PAGE_USED: "Outil État d'esprit nombre de fois utilisé",
    HEADZONE_TOOLS_PAGE_USE_TOOL: "Lancer l'outil État d'esprit",
    HEADZONE_TOOLS_PAGE_CHART_SECTION_TITLE: "Ton historique État d'esprit",

    HEADZONE_TOOLS_STATES_UNWELL: "Au plus bas",
    HEADZONE_TOOLS_STATES_BURNOUT_TITLE: "AU PLUS BAS",

    SUGGESTED_REFRESHERS: "Mises à jour suggérées",
    COMPLETE_REFRESHERS_FROM_THE_LIST_BELOW:
      "Complète les mises à jour de la liste ci-dessous.",
    MORE_NANO_PRACTICES: "Plus de pratiques",
    EARN_POINTS_FOR_HEADER_TEXT: "Gagne {0} pour chaque ",
    POINTS: "Points",
    EARN_POINTS_COMPLETE: "tu complètes.",
    CONGRATS_ON_STAYING_QUALIFIED:
      "Félicitations pour maintenir ta qualification!",
    GREAT_JOB_STAYING_QUALIFIED:
      "Excellent travail! Nous serons de retour lors du prochain cycle avec de nouvelles activités pour continuer à maintenir ta qualification.",
    PERIOD_BEGINS: "Début de la période",
    PERIOD_ENDS: "Fin de la période",
    POINT_REMAINING: "{0} point restant",
    POINTS_REMAINING: "{0} points restants",
    STAY_QUALIFIED: "Maintiens ta qualification",
    YOU_ARE_QUALIFIED: "Tu es qualifié(e)!",
    COMPLETE_REFRESHERS_MAINTAIN_CERTIFICATION:
      "Complète les mises à jour et maintiens ta certification",
    MORE_MICROS_LESSONS: "Plus de leçons",

    HEADZONE_SEEK_PROFESSIONAL_SUPPORT: "obtiens de l'aide professionnelle",
    HEADZONE_TOOLS_STATES_STRESSED: "Stressé(e)",
    HEADZONE_TOOLS_STATES_STRUGGLING: "En Difficulte",
    HEADZONE_TOOLS_STATES_STRESSED_TITLE: "EN DIFFICULTÉ",

    HEADZONE_TOOLS_STATES_OKAY: "Passable",
    HEADZONE_TOOLS_STATES_OKAY_TITLE: "PASSABLE",

    HEADZONE_TOOLS_STATES_GREAT: "Bon",
    HEADZONE_TOOLS_STATES_GREAT_TITLE: "BIEN",

    HEADZONE_TOOLS_STATES_THRIVING: "Excellent",
    HEADZONE_TOOLS_STATES_THRIVING_TITLE: "EXCELLENT",

    HEADZONE_TOOLS_CHART_OVERALL: "Globalement %",
    HEADZONE_TOOLS_CHART_BOX_TITLE: "Tes résultats État d'esprit",
    HEAD_ZONE_TOOLS_CHART_BOX_SUBTITLE:
      "L'entraînement de ton État d'esprit t’aide à te sentir plus positif face aux événements quotidiens et améliore ton humeur générale. Voici un aperçu des résultats État d'esprit jusqu'à présent.",
    HEADZONE_DONUT_CHART_TITLE: "Le détails de tes résultats État d'esprit",
    HEADZONE_LINE_CHART_TITLE: "Tes résultats État d'esprit au fil du temps",
    HEADZONE_LINE_CHART_LEGEND_TITLE: "Score moyen de l'État d'esprit",
    HEADZONE_LOCKED_TEXT: "Commence à suivre ton progrès.",
    HEADZONE_VERY_HIGH: "Très élevé",
    HEADZONE_HIGH: "Élevé",
    HEADZONE_MEDIUM: "Moyen",
    HEADZONE_LOW: "Faible",
    HEADZONE_VERY_LOW: "Très faible",

    MINDFULNESS_TOOLS: "Librairie de Pleine conscience",
    MINDFULNESS_TOOLS_YOUR: "Tes",
    MINDFULNESS_TOOLS_7_DAYS: "7 jours de",
    MINDFULNESS_TOOLS_SINGLES: "méditations individuelles",
    MINDFULNESS_TOOLS_PACKAGE: "Solutions",
    MINDFULNESS_TOOLS_MINDFULNESSES: "Pleine conscience",
    MINDFULNESS_TOOLS_PRODUCTIVITY: "LA PLEINE CONSCIENCE POUR LA PRODUCTIVITÉ",
    MINDFULNESS_TOOLS_GRATITUDE: "7 JOURS DE GRATITUDE",
    MINDFULNESS_TOOLS_SLEEP: "SÉRIE SUR LE SOMMEIL",
    MINDFULNESS_TOOLS_TITLE: "Titre",
    MINDFULNESS_JUST_BREATHE: "RESPIRE TOUT SIMPLEMENT",
    MINDFULNESS_EMOTIONS: "MAÎTRISER SES ÉMOTIONS",
    MINDFULNESS_TOOLS_PAGE_USED: "Tu as utilisé l'outil Pleine conscience",
    MINDFULNESS_TOOLS_PAGE_TOTAL_MINUTES: "Nombre total de minutes",
    MINDFULNESS_TOOLS_PAGE_BOOST:
      "L'outil Pleine conscience peut t’aider à améliorer:",
    MINDFULNESS_TOOLS_PAGE_USE_TOOL: "Lancer l'outil Pleine conscience",
    MINDFULNESS_TOOLS_PAGE_NO_STREAK: "Commençons une nouvelle séquence!",
    MINDFULNESS_TOOLS_PAGE_STREAK: "Séquence quotidienne (jour)",
    MINDFULNESS_TOOLS_PAGE_STREAK_DAYS: "jour(s)",
    MINDFULNESS_TOOLS_PAGE_INFO_ONE:
      "Prends un moment de pleine conscience pour mieux te concentrer et pour réduire ton niveau de stress.",
    FOCUS_TOOLS: "Concentration",
    FOCUS_TOOLS_PAGE_BOOST: "L'outil Concentration peut t’ aider à améliorer:",
    FOCUS_TOOLS_PAGE_USED: "Nombre de plans de Concentration créés :",
    FOCUS_TOOLS_PAGE_USE_TOOL: "Lancer l'outil Concentration",
    FOCUS_TOOLS_LANDING_NEW_PLAN: "Commencer un nouveau plan de Concentration",
    OPTIMISM_TOOLS: "Optimisme",
    OPTIMISM_TOOLS_ACTIVE: "Défis Optimisme actifs",
    OPTIMISM_TOOLS_PAGE_BOOST: "L'outil Optimisme peut t’aider à améliorer:",
    OPTIMISM_TOOLS_PAGE_INFO_ONE:
      "Pratique la gratitude en te concentrant sur les aspects positifs de ta vie et pour développer une perspective plus optimiste.",
    OPTIMISM_TOOLS_NO_PLANS: "Aucun plan Optimisme n'a été créé",
    OPTIMISM_TOOLS_PAGE_USED: "Défis complétés",
    OPTIMISM_TOOLS_PAGE_USE_TOOL: "Lancer l'outil Optimisme",
    OPTIMISM_TOOLS_INTRO_TITLE: "Entraîner l'Optimisme",
    OPTIMISM_TOOLS_INTRO:
      "C'est très simple. Il suffit de définir et de relever tes propres défis Optimisme. La science a prouvé que ces défis t’aideront à te sentir plus positif face aux événements quotidiens, même lorsque tu gardes une vue d'ensemble à l'esprit.",
    OPTIMISM_TOOLS_WELCOME: "Bienvenue à ton défi Optimisme!",
    OPTIMISM_TOOLS_LENGTH_OF_CHALLENGE: "Choisis la durée de ton défi.",
    OPTIMISM_TOOLS_BEST_TIME:
      "Quand est-il plus approprié de pratiquer l'Optimisme?",
    OPTIMISM_TOOLS_PROVEN:
      "Il est prouvé que cela fonctionne mieux à la fin de ta journée de travail.",
    OPTIMISM_TOOLS_GOOD_THINGS: "Nomme 3 choses positives aujourd'hui.",
    OPTIMISM_TOOLS_GOOD_THINGS_POSSIBLE:
      "Génial! Maintenant, mets-toi au défi de réfléchir à ce qui a amené ces bonnes choses à se manifester.",
    OPTIMISM_TOOLS_PICK:
      "Choisis n'importe quoi, absolument n'importe quoi d'aujourd'hui qui soit positif.",
    OPTIMISM_TOOLS_GOOD_STUFF: "C'est bon ça!",
    OPTIMISM_TOOLS_GOOD_STUFF_DESCRIPTION:
      "Nous referons ça demain. Jusqu'à la prochaine fois, continue à essayer de remarquer les choses positives. Plus tu t’entraînes à remarquer ces choses positives, plus cela viendra naturellement.",
    OPTIMISM_TOOLS_GOT_SLEEP: "J'ai eu 8 bonnes heures de sommeil!",
    OPTIMISM_TOOLS_BEDTIME_STORY:
      "J'ai lu une histoire au coucher à mon enfant.",
    OPTIMISM_TOOLS_GREAT_WORKOUT: "J'ai fait un super entraînement.",
    OPTIMISM_TOOLS_WHAT_LED_TO: "Ce qui a mené à",
    OPTIMISM_TOOLS_CURRENT_MENU_DONE: "Terminer le défi",
    OPTIMISM_TOOLS_CURRENT_MENU_PAUSE: "Suspendre les notifications",
    OPTIMISM_TOOLS_CURRENT_MENU_START: "Activer les notifications",
    OPTIMISM_TOOLS_CURRENT_MENU_TIME: "Changer la durée",
    OPTIMISM_TOOLS_CURRENT_MENU_NEW: "Débuter un nouveau défi",
    OPTIMISM_TOOLS_CURRENT_MENU_SHOW: "Afficher le défi actuel",
    OPTIMISM_TOOLS_DAYS_CHALLENGE: "Jours de défi",
    OPTIMISM_TOOLS_LANDING_RESUME_CHALLENGE: "Reprendre le défi",
    OPTIMISM_TOOLS_LANDING_NO_CHALLENGE: "Bon travail, tu as tout rattrapé.",
    OPTIMISM_TOOLS_CHALLENGE_READY:
      "Ta vérification de l'état d'Optimisme est prête",
    OPTIMISM_TOOLS_CHALLENGE_MISSED:
      "Tu as manqué cette date, mais tu peux toujours vérifier ton état personnel.",
    OPTIMISM_TOOLS_CHALLENGE_MISSED_COMPLETELY: "Tu as manqué cette date.",
    OPTIMISM_TOOLS_CHALLENGE_CHECKED_IN: "Vérification de l'état personnel",
    OPTIMISM_TOOLS_CHALLENGE_READY_TOMORROW:
      "Ta vérification de l'état d'Optimisme n'est pas planifié maintenant! Reviens au moment requis!",
    OPTIMISM_TOOLS_DAY: "Jour",
    OPTIMISM_TOOLS_OF: "du",
    OPTIMISM_TOOLS_CURRENT_PLAN: "Plan actuel",
    OPTIMISM_TOOLS_TIME_TO_CHECK_IN:
      "Il est temps de vérifier ton état personnel!",
    OPTIMISM_TOOLS_START_NEW_CONFIRMATION:
      "Es-tu sûr de vouloir mettre fin à ton défi actuel et d'en commencer un nouveau?",
    OPTIMISM_TOOLS_STOP_CONFIRMATION:
      "Es-tu sûr de vouloir mettre fin à ton défi actuel?",
    OPTIMISM_TOOLS_CHALLENGE_ALREADY_OVER: "Ce défi est déjà terminé.",
    SOLO_STATS_BLOCK_TITLE: "Statistiques",
    THEME_SELECT_MODAL_HEADING: "Sélectionner ton thème",
    THEME_SELECT_MODAL_SWITCH_LABEL_DEFAULT: "Clair",
    THEME_SELECT_MODAL_SWITCH_LABEL_DEFAULT_DESCRIPTION:
      "Des en-têtes orange et des reflets bleus, comme un matin d'automne au lever du soleil …",
    THEME_SELECT_MODAL_SWITCH_LABEL_NOSTALGIC: "Foncé",
    THEME_SELECT_MODAL_SWITCH_LABEL_NOSTALGIC_DESCRIPTION:
      "Un thème bleu foncé avec des reflets orange, comme au milieu de la nuit …",
    CONTINUE: "Continuer",
    THINKING_TOOLS: "Pensée I.E.M.",
    THINKING_TOOLS_PAGE_INFO_ONE:
      "Remplace tes pensées négatives par des pensées plus positives et équilibrées. ",
    THINKING_TOOLS_INTRO_LANDING_TITLE: "Tes journaux de bord",
    THINKING_TOOLS_YOUR_LOG_ON: "Ton avis sur",
    THINKING_TOOLS_PAGE_BOOST:
      "L'outil Pensée I.E.M. peut t’aider à stimuler ton/ta",
    THINKING_TOOLS_PAGE_USED: "Création de pensées équilibrées",
    THINKING_TOOLS_PAGE_USE_TOOL: "Lancement de l'outil Pensée I.E.M.",
    THINKING_TOOLS_INPUT_NEW_THOUGHTS: "Nouvelles pensées",
    THINKING_TOOLS_PRACTICE: "S'entraîner à penser avec précision",
    THINKING_TOOLS_IDENTIFY:
      "L'entraînement de tes pensées t’aide à te sentir plus positif face aux événements quotidiens et améliore ton humeur générale. Tu y parviendras en identifiant une pensée critique, en la remettant en question et en la remplaçant par quelque chose de différent.",
    THINKING_TOOLS_STEP_1: "1. Identifier",
    THINKING_TOOLS_STEP_2: "2. Remettre en question",
    THINKING_TOOLS_STEP_2_INSTRUCTIONS:
      "Remettre en question ta façon de penser t’aidera à considérer les choses d'un point de vue plus objectif et à avoir une vue d'ensemble.",
    THINKING_TOOLS_STEP_3: "3. Remplacer (ta pensée)",
    THINKING_TOOLS_TAP: "Est-ce une pensée ou un fait?",
    THINKING_TOOLS_CRITICAL:
      "Peux-tu identifier une pensée critique ou négative que tu as?",
    THINKING_TOOLS_PEOPLE_THINK:
      "Par ex. les gens pensent que je suis difficile",
    THINKING_TOOLS_YOU_IDENTIFIED:
      "Tu as identifié cette affirmation comme étant",
    THINKING_TOOLS_YOU_IDENTIFIED_NOT: "PAS UN ",
    THINKING_TOOLS_YOU_USING_INSIGHTS:
      "En t’appuyant sur ces informations, écris ici une affirmation plus équilibrée :",
    THINKING_TOOLS_NOT_EVERYONE: "Par ex. tout le monde n'est pas comme moi",
    THINKING_TOOLS_WHAT_DOES: "Que disent les preuves?",
    THINKING_TOOLS_REJECTS_STATEMENT:
      "Plus de preuves REJETTENT cette affirmation",
    THINKING_TOOLS_SUPPORTS_STATEMENT:
      "Plus de preuves CONFIRMENT cette affirmation",
    THINKING_TOOLS_WHAT_WOULD: "Que dirait ton meilleur ami?",
    THINKING_TOOLS_MY_BEST_DISAGREE: "Mon meilleur ami serait en DÉSACCORD",
    THINKING_TOOLS_MY_BEST_AGREE: "Mon meilleur ami serait D'ACCORD",
    THINKING_TOOLS_NOW_EXCHANGE:
      "Maintenant, tu vas remplacer cette affirmation par une pensée plus équilibrée.",
    THINKING_TOOLS_EXCHANGE: "Tu as identifié la pensée suivante:",
    THINKING_TOOLS_NOW_EXCHANGE_FOR: "et l'as remplacé par:",
    THINKING_TOOLS_THOUGHT: "PENSÉE",
    THINKING_TOOLS_FACT: "FAIT",
    THINKING_TOOLS_REJECTS: "REJETTE",
    THINKING_TOOLS_SUPPORTS: "APPUIE",
    THINKING_TOOLS_AGREE: "EST D'ACCORD",
    THINKING_TOOLS_DISAGREE: "EST EN DÉSACCORD",
    THINKING_TOOLS_GREAT: "Excellent travail!",
    THINKING_TOOLS_RESILIENT:
      "Les personnes résilientes pensent avec exactitude.",
    THINKING_TOOLS_REMIND: "Rappelle-toi d'utiliser",
    THINKING_TOOLS_REMIND_ICE: "l'Outil de Pensée I.E.M.",
    THINKING_TOOLS_REMIND_WHEN:
      "lorsque tu as une pensée critique ou négative.",
    THINKING_TOOLS_REMIND_DOES: "Ta pensée se rapporte-t-elle à une tâche?",
    THINKING_TOOLS_REMIND_DOES_CLICK: "Cliquer",
    THINKING_TOOLS_REMIND_DOES_CREATE: "pour créer un plan de CONCENTRATION",
    THINKING_TOOLS_EXCHANGE_DATE: "Le remplacement de ta pensée le",
    THINKING_TOOLS_LOG_NEGATIVE: "était ta pensée négative.",
    THINKING_TOOLS_LOG_THOUGHT_NOT:
      "Tu as identifié cette affirmation comme un <strong>FAIT</strong> et non pas une pensée.",
    THINKING_TOOLS_LOG_THOUGHT_IS:
      "Tu as identifié cette affirmation comme une <strong>PENSÉE</strong> et non pas un fait.",
    THINKING_TOOLS_LOG_STATEMENT_SUPPORT:
      "Il y a plus de preuves qui <strong>SOUTIENNENT</strong> cette affirmation.",
    THINKING_TOOLS_LOG_STATEMENT_NOT_SUPPORT:
      "Il y a plus de preuves qui <strong>REJETTENT</strong> cette affirmation.",
    THINKING_TOOL_LOG_AGREE:
      "Ton meilleur ami serait <strong>D'ACCORD</strong> avec cette affirmation.",
    THINKING_TOOL_LOG_DISAGREE:
      "Ton meilleur ami serait en <strong>DÉSACCORD</strong> avec cette affirmation.",
    THINKING_TOOLS_LOG_POSITIVE: "est ta pensée améliorée et équilibrée!",
    FOCUS_TOOLS_SET_PLAN: "Établir un plan de Concentration",
    FOCUS_TOOLS_ACTIVE: "Tes plans de Concentration actifs",
    FOCUS_TOOLS_SET_PLAN_DESCRIPTION:
      "Un plan de Concentration t’aidera à porter ton attention là où elle est nécessaire et, surtout, à décider comment tu dois te sentir pendant un événement difficile. Le choix d'une émotion t’aidera à atteindre tes objectifs.",
    FOCUS_TOOLS_PAGE_INFO_ONE:
      "Crée un plan pour te donner les moyens d'atteindre tes objectifs.",
    FOCUS_TOOLS_PLAN_TITLE: "Titre du Plan",
    FOCUS_TOOLS_PLAN_TITLE_NAME: "Quel est le nom de ce plan?",
    FOCUS_TOOLS_PLAN_WHAT: "Qu'est-ce qui se passe?",
    FOCUS_TOOLS_PLAN_WHAT_DESCRIPTION:
      "Qu'est-ce qui s'en vient et qui nécessite toute ton attention pour que ce soit bien fait?",
    FOCUS_TOOLS_PLAN_HAVE_TO_DO: "Que dois-tu faire?",
    FOCUS_TOOLS_PLAN_HAVE_TO_DO_DESCRIPTION:
      "Que dois-tu faire en ce moment pour rester concentré?",
    FOCUS_TOOLS_PLAN_HAVE_TO_FEEL: "Comment dois-tu te sentir?",
    FOCUS_TOOLS_PLAN_HAVE_TO_FEEL_DESCRIPTION:
      "Quel sentiment va t’aider à rester concentré sur le travail à accomplir?",
    FOCUS_TOOLS_PLAN_WHEN: "Quand est-ce que ça se passe?",
    FOCUS_TOOLS_PLAN_HAPPENED: "A commencé le",
    FOCUS_TOOLS_PLAN_START: "Commence le",
    FOCUS_TOOLS_PLAN_NEXT: "Suivant le",
    FOCUS_TOOLS_PLAN_WHEN_NOTIFY:
      "Tu recevras une notification 30 minutes avant que cela ne se produise pour te rappeler ton plan Concentration.",
    FOCUS_TOOLS_PLAN_HAVE_TO: "Je dois …",
    FOCUS_TOOLS_PLAN_NEED_TO: "J'ai besoin de …",
    FOCUS_TOOLS_PLAN_IS_SET: "Ton Plan est défini",
    FOCUS_TOOLS_PLAN_IS_SET_DESCRIPTION:
      "Tu recevras une notification 30 minutes avant cet événement pour te rappeler ton plan de Concentration. Quand on a un plan, on a moins besoin de chance!",
    FOCUS_TOOLS_INTRO_LANDING_TITLE_UPCOMING: "Plans de Concentration à venir",
    FOCUS_TOOLS_INTRO_LANDING_TITLE_PREVIOUS:
      "Plans de Concentration précédents",
    FOCUS_TOOLS_INTRO_LANDING_TITLE_NEW: "Nouveau Plan de Concentration",
    FOCUS_TOOLS_PLAN_COPY: "Copier le Plan",
    FOCUS_TOOLS_PLAN_VIEW: "Voir le Plan",
    FOCUS_TOOLS_PLAN_DELETE: "Supprimer le Plan",
    FOCUS_TOOLS_PLAN_EDIT: "Modifier le Plan",
    FOCUS_TOOLS_DELETE_CONFIRMATION:
      "Es-tu sûr de vouloir supprimer le Plan de Concentration?",
    MOVE_TOOLS: "Activité",
    MOVE_TOOLS_PAGE_TOTAL_STEPS: "Nombre total de pas",
    MOVE_TOOLS_PAGE_TOTAL_DISTANCE: "Distance totale (km)",
    MOVE_TOOLS_PAGE_TOTAL_STAIRS: "Nombre de total de marches montées",
    MOVE_TOOLS_PAGE_TOTAL_CALORIES: "Nombre de calories brûlées au total",
    MOVE_TOOLS_PAGE_USE_TOOL: "Lancer l'outil Activité",
    MOVE_TOOLS_PAGE_BOOST: "L'outil Activité peut t’aider à améliorer :",
    MOVE_TOOLS_CHART_STEPS_SERIES_NAME: "Le nombre de pas",
    MOVE_TOOLS_CHART_DISTANCE_SERIES_NAME: "La distance (km)",
    MOVE_TOOLS_CHART_STAIRS_SERIES_NAME: "Marches",
    MOVE_TOOLS_CHART_CALORIES_SERIES_NAME: "Calories",
    MOVE_TOOLS_CHART_STEP_TITLE: "Résultat pas/distance (km) ",
    MOVE_TOOLS_CHART_STAIRS_TITLE: "Résultat marches/calories",
    MOVE_TOOLS_PAGE_INFO_ONE:
      "Le fait de bouger t’aide à te sentir plus positif face aux événements du quotidien et améliore ton humeur générale.",
    MOVE_TOOLS_APP_HEADLINE: "Synchronise tes données d'activité",
    MOVE_TOOLS_APP_INFO:
      "Installe l'appli mobile pour synchroniser tes données d'activité!",
    MOVE_TOOLS_GET_THE_APP: "Obtenir l'appli",
    INTRO_BUTTON_TITLE: "Voir l'introduction à nouveau",
    LANGUAGE_TITLE: "Langue",
    LANGUAGE_MODAL_HEADING: "Sélectionne ta langue",
    CUSTOM_FIELDS_TITLE: "Information utilisateur",
    CUSTOM_FIELDS_SUBTITLE: "Réponds à la question ci-bas.",
    CUSTOM_FIELDS_SELECT_PLACEHOLDER: "Sélectionner une option",
    MINUTES_TRAINED: "Minutes d’entraînement",
    LAST_ACTIVITY: "Dernière activité",
    STAT_DAILY_PLANS_COMPLETE: "Plan journalier complété",
    STAT_PRACTICES_COMPLETE: "Pratiques complétées",
    STAT_LESSONS_COMPLETE: "Leçons complétées",
    STAT_POSTS_VIEWED: "Articles consultés",
    STAT_BALANCED_THOUGHTS: "Pensées équilibréess",
    STAT_OPTIMISIM_CHALLENGES: "Défis Optimisme",
    STAT_FOCUS_PLANS: "Plans de Concentration",
    STAT_MINDFULNESS_MINUTES: "Minutes de pleine conscience",
    STAT_CALMING_TOOL: "Outil Relaxation utilisé",
    NOTIFICATION_TITLE: "Notifications",
    NOTIFICATION_HEADER_STATUS: "Statut",
    NOTIFICATION_HEADER_TITLE: "Titre",
    NOTIFICATION_HEADER_DATE: "Date",
    NOTIFICATION_HEADER_MARK_ALL_AS_READ: "Marqué comme lu",
    NOTIFICATION_NONE: "Tu n'as pas de notifications",
    NOTIFICATION_NEW: "Nouveau!",
    TOOLS_VIEW_LOGS: "Voir ton historique",
    WEB_NOTIFICATION_NEW: "Tu as une nouvelle notification!",
    DO_NOT_REPEAT: "Ne pas répéter",
    THANK_YOU: "Merci",
    EVERYDAY: "Chaque jour",
    EVERY_WEEKDAY: "Chaque jour de la semaine",
    WEEKLY: "Hebdomadaire",
    MONTHLY: "Mensuel",
    YEARLY: "Annuel",
    DELETE: "supprimer",
    SCORE: "Score",
    SKILL_SCORE: "Score de compétence",
    DATE_FORMAT: "D MMM YYYY",
    DATE_FORMAT_WITH_TIME: "D MMM YYYY h:mm A",
    DATE_FORMAT_KENDO_DATETIME: "d MMM yyyy h:mm tt",
    DATE_FORMAT_WEEKDAY: "%A, %e %b %Y",
    CHARTS_DAY_FORMAT: "%d-%b",
    SUNDAY: "dim.",
    MONDAY: "lun.",
    TUESDAY: "mar.",
    WEDNESDAY: "mer.",
    THURSDAY: "jeu",
    FRIDAY: "ven.",
    SATURDAY: "sam.",
    GET_HELP_LABEL: "Obtenir de l'aide maintenant",
    GET_REACH_HELP_LABEL: "Visitez le Centre d'aide",
    GET_REACH_HELP_TEXT: "Aide",
    CONFIRM_OK: "OK",
    CONFIRM_YES: "Oui",
    CONFIRM_NO: "Non",
    NOT_NOW: "Pas maintenant",
    INVITE: "Inviter",
    PUSH_NOTIFICATION_INFO:
      "L'activation des notifications push web nous permettra de t’envoyer des messages importants concernant ton expérience <em>headversity</em>, comme lorsque tu reçois un badge ou lorsqu'il est temps de relever ton défi d'Optimisme. Ton navigateur peut bloquer ces notifications par défaut. Si tu as des difficultés à activer les notifications, vérifie les paramètres de ton navigateur.",
    WEB_PUSH_NOTIFICATIONS: "Push",
    HEADVERSITY: "headversity",
    REACH: "REACH",
    ENABLE: "Activer",
    NEW_APP_VERSION: "Nouvelle version maintenant disponible!",
    NEW_APP_VERSION_DESCRIPTION:
      "Clique sur le bouton ci-dessous pour te mettre à jour et continuer avec <em>headversity</em>",
    TOOLS_COMING_SOON: "Bientôt disponible",
    TOOLS_COMING_SOON_DESCRIPTION:
      "De nouveaux outils seront introduits dans les futures mises à jour!",
    MINUTE_VERSION: "Version minute",
    ZONE: "zone",
    TURN_ON_NOTIFICATIONS: "Activer les notifications",
    ENABLE_EMAIL_NOTIFICATIONS: "Réabonne-toi aux communications par courriel",
    UNSUBSCRIBE_EMAIL_TITLE: "Tu t'es désabonné avec succès.",
    UNSUBSCRIBE_EMAIL_DESCRIPTION_1:
      "Tu ne recevras plus de courriels de <em>headversity</em>. Si c'était une erreur ou si tu changes d'avis, tu peux te réabonner à tout moment en visitant tes paramètres de profil. Pour toute question, contacte-nous à <a href='mailto:help@headversity.com'>help@headversity.com</a>.",
    WELCOME_SHOW_HIDE_OPTIONS: "Options",

    WELCOME_TAKE_CONTROL:
      "Gère le stress, adapte-toi au changement et prends une longueur d'avance sur l'adversité aujourd'hui avec notre formation",
    WELCOME_ADJ2: "personnalisée",
    WELCOME_ADJ3: "confidentielle",
    WELCOME_ADJ_END: "",

    WELCOME_VIDEO: "Voici une vidéo introductive rapide.",

    WELCOME_HEADZONE_SELECT_ZONE: "Sélectionne l'état d'esprit",
    WELCOME_HEADZONE_SELECT_DESCRIBE: "qui décrit le mieux ce que tu ressens.",

    WELCOME_HEADZONE_UNWELL_HELP: "Appuie sur le bouton",
    WELCOME_HEADZONE_UNWELL_BUTTON: "à tout moment pour afficher ",
    WELCOME_HEADZONE_UNWELL_VIEW: "les ressources d'assistance supplémentaires",
    WELCOME_HEADZONE_UNWELL_PROVIDED: " fournies par ton organisation.",
    WELCOME_HEADSCAN_LOW:
      "Reconnaître ce que tu ressens est la première étape pour te sentir mieux. Nous sommes là pour toi avec des ressources qui peuvent t'aider à relever les défis de la vie.",
    WELCOME_HEADZONE_HELP_SUCCEED:
      "Cela nous permettra de t'envoyer des rappels périodiques utiles.",

    WELCOME_SKILLS_STARTING: "OK, trouvons le bon",
    WELCOME_SKILLS_STARTING_POINT: "point de départ",
    WELCOME_SKILLS_STARTING_JOURNEY: "pour ton parcours de santé mentale.",
    WELCOME_SKILLS_ALRIGHT_LETS_EXPLORE:
      "D'accord, obtenons une note initiale pour la compétence de ",
    WELCOME_SKILLS_ALRIGHT_LETS_EXPLORE_RESCORE:
      "D'accord, obtenons une note mise à jour pour la compétence de ",
    WELCOME_SKILLS_PLEASE_COMPLETE: "Complète ces",
    WELCOME_SKILLS_QUESTIONS: "questions",
    WELCOME_SKILLS_TO_CONTINUE: "pour continuer ton parcours d'apprentissage",
    WELCOME_SKILLS_QUICK_QUESTIONS:
      "On va te poser quelques questions rapides pour déterminer ton point de départ, puis on commencera l'entraînement.",
    WELCOME_SKILLS_SCORES_OVER_TIME:
      "Voici un aperçu de ton score au fil du temps.",

    SCORE_HIGH:
      "Un <b style='font-weight:600'>score élevé</b> indique que tu excelles dans cette compétence, l'appliques facilement et en tires des bénéfices significatifs. Continuer à t'entraîner dans cette compétence peut t'aider à maintenir ton haut niveau de compétence et à t'adapter à de nouveaux défis.",
    SCORE_MED_HIGH:
      "Un <b style='font-weight:600'>score moyen-élevé</b> indique que tu es assez habile dans cette compétence, l'appliquant efficacement dans la plupart des situations. L'entraînement peut t'aider à tirer encore plus parti de cette compétence et à obtenir de plus grands bénéfices dans ta vie personnelle et professionnelle.",
    SCORE_MED:
      "Un <b style='font-weight:600'>score moyen</b> indique que tu as peut-être une bonne maîtrise de cette compétence et que tu peux l'appliquer dans certaines situations. L'entraînement peut t'aider à affiner tes capacités et à être plus cohérent dans son application.",
    SCORE_MED_LOW:
      "Un <b style='font-weight:600'>score moyen-faible</b> indique que tu as peut-être certaines compétences dans ce domaine, mais que tu rencontres souvent des défis et des incohérences dans son utilisation. L'entraînement peut t'aider à construire une base solide.",
    SCORE_LOW:
      "Un <b style='font-weight:600'>score faible</b> indique que tu peux avoir des difficultés avec cette compétence. L'entraînement peut t'aider à surmonter les défis et à améliorer considérablement ton efficacité.",

    WELCOME_COMPLETE_SCORE_IS: "Voici ton score en ",
    WELCOME_COMPLETE_SCORES_ARE: "Voici tes scores, ",
    WELCOME_COMPLETE_NOW_LETS: "Maintenant, allons-y",

    HEADZONE_YOURE_IN_THE: "Tu es dans la",
    HEADZONE_WELCOME_TO_THE: "Bienvenue dans la",
    HEADZONE_YOUVE_REACHED_THE: "Tu as atteint la",

    TOUR_MENU: "Faire un tour",
    TOUR_MENU_TITLE: "Se déplacer",
    TOUR_MENU_APP_DESCRIPTION: "Basculer entre la formation SOLO et TEAM.",
    TOUR_MENU_DESCRIPTION:
      "Tout ce dont tu as besoin pour être le plus résilient possible, y compris toutes les leçons et tous les outils, se trouve dans le menu.",
    TOUR_MENU_DESCRIPTION_TEAM:
      "SOLO, TEAM et tout ce dont tu as besoin pour être le plus résilient possible se trouve dans le menu.",
    TOUR_MENU_DESCRIPTION_REACH:
      "Bascule entre les fonctions administratives de REACH et les formations à la résilience.",
    TOUR_GET_HELP_DESCRIPTION:
      "Appuyer sur cette icône te connectera aux ressources de santé mentale fournies par ton organisation, de sorte que tu sois toujours à un clic du soutien.",
    TOUR_GET_REACH_HELP_DESCRIPTION:
      "Accédez au Centre d'aide pour des instructions détaillées sur tout ce qui concerne REACH.",
    TOUR_DAILY_THREE_DESCRIPTION:
      "Chaque jour, nous te proposerons trois activités basées sur ta quête. Complète-les et passe au niveau supérieur!",
    TOUR_RECOMMENDED_DESCRIPTION:
      "Essaie ces activités supplémentaires et continue de monter de niveau!",
    DAILY_THREE_TITLE: "Ton Trio Quotidien",
    CERT_SECTION: "Section",
    CERT_GETTING_STARTED: "Démarrage",
    CERT_CHECKPOINT: "Point de contrôle",
    CERT_REFLECTIONS: "Réflexions",
    CERT_FOR_A_QUICK_REMINDER: "Pour un petit rappel, tu peux",
    CERT_REVIEW_THE_LESSON: "revoir la leçon",
    CERT_BEFORE_YOU_PROCEED: "avant de continuer.",
    CERT_NOT_QUITE: "Pas tout à fait... essaie encore.",
    CERT_TIME_REMAINING: "Temps restant: {0} minutes",
    CERT_LOGO: "https://cdn.headversity.com/app/cert/cert_logo_fr.png",
    TEAM_LOGO: "https://cdn.headversity.com/app/resources/other/team_fr.png",
    DAILY_THREE_DESCRIPTION: "Commence avec ces activités",
    DAILY_THREE_COMPLETE: "Tu as bien réussi!",
    DAILY_THREE_COMPLETE_EXTRA:
      "Tu as bien réussi! Tu as terminé le plan quotidien d'aujourd'hui.",
    DAILY_THREE_COMPLETE_CTA_2:
      "Continuer à former tes compétences, et reviens demain pour ton prochain plan.",
    DAILY_THREE_TRACK_HEADZONE: "Suivre ton état d'esprit",
    DAILY_THREE_RESILIENCE_RESOURCE: "Voir une ressource de résilience",
    DAILY_THREE_COMPLETE_LESSON: "Compléter une leçon",
    DAILY_THREE_UNLOCK: "Ouvrir",
    DAILY_THREE_UNLOCK_SKILL: "Déverrouiller une compétence",
    DAILY_THREE_ALL_COMPLETE: "Tu as tout fait!",
    DAILY_THREE_COMPLETE_OPTIMISM: "Sois optimiste!",
    DAILY_THREE_REDO_SCORE: "Refaire un score de résilience",
    DAILY_THREE_COMPLETE_A: "Compléter une ",
    DAILY_THREE_VIEW_A: "Voir un ",
    DAILY_THREE_MICRO_LESSON_DESCRIPTION:
      "Expériences d'apprentissage de 5 minutes",
    DAILY_THREE_NANO_PRACTICE_DESCRIPTION:
      "Pratiques quotidiennes de résilience d'une minute",
    DAILY_THREE_POST_DESCRIPTION:
      "Publications hebdomadaires pour vous aider à rester sur la bonne voie",
    DAILY_THREE_POST_SHORT_DESCRIPTION: "Publications hebdomadaires",

    RESILIENCE_RESOURCE: "Article de résilience",
    MICRO_LESSON: "Leçon",
    MICRO_LESSONS: "Leçons",
    MORE_ACTIVITIES: "Autres activités",

    NEXT_UP: "Suivant",
    SEE_ALL_SKILLS: "Voir Toutes les compétences",
    SEE_ALL_NANO_PRACTICES: "Voir toutes les pratiques",
    SEE_ALL_LESSONS: "Voir toutes les leçons",
    SEE_ALL_TOOLS: "Voir tous les outils",
    SEE_ALL_TRAININGS: "Voir Toutes les Formations $",
    OVER_TIME: "au fil du temps",
    SEE_PROGRESS_OVER_TIME:
      "Vous verrez vos progrès au fil du temps après avoir obtenu des scores dans cette compétence sur plusieurs jours.",

    UNLOCK_SKILL: "Débloquer la compétence",
    CLICK_TO: "Cliquez pour ",
    TAP_TO: "Tape pour ",
    CLICK_TO_UNLOCK: "débloquer cette compétence",
    CLICK_TO_REDO_SCORE: "refaire votre score",
    CLICK_TO_SEE_DETAILS: "voir plus de détails",
    YOUR_ACTIVITY_LEVEL: "Votre niveau d'activité est en",
    OVER_THE_LAST: "au cours des 30 derniers jours.",
    SEE_MORE_DETAILS: "Voir plus de détails de <b>{0}</b>",

    LOW: "Faible",
    MEDIUM: "Moyenne",
    HIGH: "Elevé",
    UP: "Hausse",
    DOWN: "Baisse",
    FLAT: "Plat",
    TODAY: "Aujourd'hui",
    MONTH: "Mois",
    YESTERDAY: "Hier",
    DAYS_AGO: "jours",
    MORE_THAN: "Plus de",
    ON: "sur",
    THIS: "ça",

    TRAININGS: "Formations",
    NANO_PRACTICE: "Pratique",
    NANO_PRACTICES: "Pratiques",
    NANO_PRACTICES_ALL: "Toutes les Pratiques",
    NANO_PRACTICES_DESCRIPTION:
      "Une série de pratiques de résilience quotidiennes d'une minute.",
    NANO_PRACTICES_FEATURED_TITLE: "Pratiques en vedette",
    NANO_PRACTICES_FEATURED_DESCRIPTION:
      "Pratiques de résilience d'une minute triées sur le volet pour vous.",
    NANO_PRACTICES_HEADER_DESCRIPTION:
      "Développe ta résilience grâce à des pratiques quotidiennes d'une minute",
    NANO_PRACTICES_MODAL_TIPS_TITLE: "Conseils",
    NANO_PRACTICE_FEEDBACK_1: "J'ai trouvé cette pratique utile",
    NANO_PRACTICE_FEEDBACK_2: "Cette pratique a amélioré ma perspective",
    NANO_PRACTICE_TRY_DIFFERENT_PROMPTS: "Essaie différents déclencheurs",
    NANO_PRACTICE_AI_NOTICE:
      "Cette pratique utilise l'IA pour fournir des options supplémentaires et des résumés. Comme pour toutes les données, <i>headversity</i> applique ses niveaux rigoureux de normes de confidentialité et de sécurité à l'IA, et aucune information personnelle n'est utilisée. Si tu as des questions ou des préoccupations, fais-le-nous savoir à <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>",

    HOW_DOES_IT_WORK: "<b>Comment</b> ça marche?",
    NICE_WORK: "Bien fait",
    NEXT_UP_IN: "La suite dans",
    NEXT_PRACTICE: "Suivant",
    YOUVE_COMPLETED: "Tu as complété cette pratique",
    MOST_RECENTLY: "la plus récemment",
    AUTO_PLAY: "Lecture automatique",

    PROFILE_YOU_HAVE: "Vous avez",
    PROFILE_UNREAD_NOTIFICATION: "notification non lue",
    PROFILE_UNREAD_NOTIFICATIONS: "notifications non lues",
    PROFILE_VIEW_NOTIFICATIONS: "Afficher les notifications",
    PROFILE_CHANGE_LANGUAGE: "Changer de langue",
    PROFILE_CHANGE_THEME: "Change le thème",
    PROFILE_CHANGE_HIERARCHY: "Changer d'association",
    PROFILE_UPDATE_NAME: "Mettre à jour le nom",
    PROFILE_ASSOCIATION_SAVED: "Association enregistrée",
    PROFILE_ASSOCIATION: "Association",
    PROFILE_ASSOCIATION_SEARCH: "Recherche",
    PROFILE_ASSOCIATION_INSTRUCTION:
      "Veuillez sélectionner votre (vos) association(s) dans la liste ci-dessous :",
    PROFILE_CHANGE_PASSWORD: "Changer de mot de passe",
    PROFILE_CHANGE_PASSWORD_EMAIL:
      "Nous t'enverrons un lien par e-mail avec des instructions pour changer ton mot de passe.",
    PROFILE_SEND_EMAIL: "Envoyer l'email",
    NAME_UPDATED: "Ton nom a été mis à jour.",

    SKILLS_DESCRIPTION_CORE:
      "Les compétences qui font de toi qui tu es et te propulsent vers tes objectifs.",
    SKILLS_DESCRIPTION_WORK:
      "Les compétences qui t'aident à performer à ton meilleur et à atteindre ton potentiel professionnel.",
    SKILLS_DESCRIPTION_HOME:
      "Les compétences qui te permettent de créer un environnement de vie optimal.",

    TEAM_PATH_TITLE: "Parcours",
    TEAM_PATH_DESCRIPTION:
      "Entraîne-toi avec ton équipe pour relever tous les défis",
    TEAM_PATH_STATS_TITLE: "Parcours complétés",
    TEAM_TEAM_LESSON_TITLE: "Présentations complétées",
    TEAM_TEAM_LESSON_DESCRIPTION: "Sélectionne une présentation.",
    REACH_TRIAL: " (Procès)",
    REACH_HELP_RESOURCES_PHONE: "Téléphone",
    REACH_HELP_RESOURCES_TEXT: "Texte",
    REACH_HELP_RESOURCES_IOS: "iOS",
    REACH_HELP_RESOURCES_ANDROID: "Android",
    REACH_HELP_RESOURCES_WEB: "Web",
    REACH_CERT_SELECT: "Sélectionner les certifications",
    REACH_NODE_SELECT: "Sélectionner des nœuds",
    REACH_HIDDEN_NODE: "Nœud (caché)",
    REACH_CERT_COMPLETION_STATUS: "Statut d'achèvement",
    REACH_CERT_COMPLETION_DATE: "Date d'achèvement",
    REACH_CERT_STATUS_DONE: "Terminé",
    REACH_CERT_STATUS_IN_PROGRESS: "En Cours",
    REACH_CERT_STATUS_NOT_STARTED: "Non Commencé",
    REACH_CERT_STATUS_NOT_ASSIGNED: "Non Attribué",
    REACH_CERT_NAME: "Nom de la Certification",
    REACH_CERT_STATUS: "Statut de la Certification",
    REACH_HIERARCHY_INVALID_SORT_ORDER: "Ordre de tri invalide",
    REACH_HIERARCHY_NODE_SORT_ORDER: "Ordre de tri",
    REACH_HIERARCHY_NODE_NOT_SELECTABLE: "Non sélectionnable",
    REACH_HIERARCHY_UNASSOCIATED: "Non associé",
    REACH_HIERARCHIES: "Hiérarchies",
    REACH_HIERARCHY_DOWNLOAD: "Télécharger la hiérarchie",
    REACH_HIERARCHY_SNAPSHOT_RESTORE_SUCCESS:
      "Restauration d'instantané réussie",
    REACH_RENAME: "Renommer",
    REACH_HIERARCHY_NODE_EDIT: "Modifier le nœud",
    REACH_HIERARCHY_NEW: "Nouvelle hiérarchie",
    REACH_HIERARCHY_DOWNLOAD_CSV_FILE_NAME: "hiérarchie",
    REACH_PROMOTE: "Promouvoir",
    REACH_INSIGHTS: "Rapports",
    REACH_MY_ORG: "Mon org",
    REACH_PROMO_DESCRIPTION:
      "Parcours et recherche toutes les ressources promotionnelles",
    REACH_MORE_ASSETS_HOME:
      "Voir toutes les ressources promotionnelles disponibles.",
    REACH_TEAM_USER_GUIDE: "Guide d'utilisation de TEAM",
    REACH_SOLO_USER_GUIDE: "Guide d'utilisation de SOLO",
    REACH_GETTING_STARTED: "Mise en route",
    REACH_GETTING_STARTED_TOUR_DESCRIPTION:
      "Des tutoriels rapides sur la façon de tirer le meilleur parti de SOLO et TEAM pour ton organisation - appuie sur le bouton « play » pour commencer!",
    REACH_GETTING_STARTED_DESCRIPTION:
      "Apprends-en davantage et démarre avec <em>headversity</em> grâce à des guides complets pour tes produits achetés.",
    REACH_WELCOME_TO: "Bienvenue à ",
    REACH_ADDITIONAL_RESOURCES_GUIDE: "Ressources supplémentaires",
    REACH_RECOMMENDED_ASSETS: "Ressources recommandées",
    REACH_RECOMMENDED_ASSETS_TOUR_DESCRIPTION:
      "Contenu choisi spécialement pour toi en fonction de tes objectifs et de ton utilisation en tant qu'employé.",
    REACH_HOME_DESCRIPTION:
      "Explore les ressources pour lancer et promouvoir <em>headversity</em> facilement au sein de ton organisation",
    REACH_HOME_INSIGHTS_DESCRIPTION:
      "Un petit aperçu de la façon dont tes employés interagissent avec <em>headversity</em>.",
    REACH_HOME_SEE_ALL_INSIGHTS: "Voir tous les rapports",
    REACH_SEE_ALL_ASSETS: "Voir tout le ressources",
    REACH_POSTER: "Affiche",
    REACH_SOCIAL_MEDIA_POST: "Post de réseau social",
    REACH_EMAIL_TEMPLATE: "Modèle de courriel",
    OTHER: "Autre",
    REACH_SUBJECT_LINE: "L’objet:",
    REACH_SHARE: "Partager",
    REACH_SHARE_ON_LINKEDIN: "Partager sur LinkedIn",
    REACH_SHARE_ON_YAMMER: "Partager sur Yammer",
    REACH_DOWNLOAD: "Télécharger",
    REACH_SKILLS_ALL_SKILLS: "Toutes les compétences",
    REACH_SKILLS_GENERIC: "Générique",
    REACH_LANGUAGES_ALL_LANGUAGES: "Toutes les langues",
    REACH_ASSET_TYPE_ALL_TYPES: "Tous les types de contenu",
    REACH_PRODUCT_TYPE_ALL_TYPES: "Tous les produits",
    REACH_CLEAR_FILTERS: "Supprimer les filtres",
    REACH_CUSTOM_CONTENT: "Contenu personnalisé",
    REACH_PROMO_NOT_FOUND:
      "La ressource n'existe pas. S'il te plaît, rafraîchis la page",
    REACH_NO_ASSETS: "Aucun contenu",
    REACH_NEW: "Nouveau",
    REACH_CUSTOM: "Personnalisé",
    REACH_CALENDAR_VIEW: "Calendrier",
    REACH_LIST_VIEW: "Liste",
    REACH_CALENDAR_DESCRIPTION:
      "Planifie les activités promotionnelles à l'aide d'un calendrier mensuel",
    REACH_PROFILE_DESCRIPTION: "Consulte tes paramètres de profil",
    REACH_USERS_DESCRIPTION:
      "Gère les utilisateurs et les équipes de ton organisation",
    REACH_ORGANIZATIONS_LOGO: "Logo",
    REACH_ORGANIZATIONS_SECTOR: "Secteur",
    REACH_ORGANIZATIONS_PROPERTIES: "Propriétés de l'organisation",
    REACH_ORGANIZATIONS_CHOOSE_SECTOR: "Choisissez un secteur",
    REACH_ORGANIZATIONS_NAME_DESCRIPTION:
      "Comment devrions-nous appeler ton organisation?",
    REACH_ORGANIZATIONS_SECTOR_DESCRIPTION:
      "Nous pouvons fournir des informations plus ciblées en connaissant le secteur organisationnel spécifique.",
    REACH_ORGANIZATIONS_LOGO_DESCRIPTION:
      "Rendons ton organisation instantanément reconnaissable par tes membres. Télécharge le logo de ton organisation pour profiter des avantages du co-branding de ton expérience d'accueil de membre.<br/><br/>Télécharge un logo au format PNG ou JPG avec des dimensions de 450 x 310 pixels. Si tu utilises PNG, assure-toi qu'il a un arrière-plan transparent ; pour JPG, l'arrière-plan doit être blanc.",
    REACH_ORGANIZATIONS_DESCRIPTION:
      "Gère les informations, les communications et les ressources d'aide de ton organisation",
    REACH_GOALS_SELECTION_SCREEN_SAVE: "Enregistrer",
    REACH_GOALS_WIN_THEME_PURSUE: "Les utilisateurs vont poursuivre {0} et {1}",
    REACH_GOALS_SELECTION_SCREEN_TITLE:
      "Comment pouvons-nous mieux répondre à vos besoins?",
    REACH_GOALS_SELECTION_SCREEN_DESCRIPTION:
      "Quels résultats espérez-vous obtenir avec headversity? Vous avez la flexibilité de sélectionner plusieurs objectifs si vous le souhaitez, ou vous pouvez choisir de ne pas le faire. Ne pas fixer d'objectif permet aux utilisateurs d'explorer leurs propres poursuites d'apprentissage de manière indépendante sans aucun domaine recommandé d'alignement avec les priorités de l'organisation.",
    REACH_GOALS_INITIAL_SCREEN_TITLE:
      "Débloquer le potentiel de ton organisation est maintenant plus simple!",
    REACH_GOALS_INITIAL_SCREEN_DESCRIPTION:
      "Que ton organisation vise à augmenter la confiance, à réduire le stress, à approfondir les connexions, ou bien plus, sélectionne des objectifs organisationnels pour concentrer les efforts sur ce qui est le plus important.",
    REACH_GOALS_INITIAL_SCREEN_IMAGE:
      "https://cdn.headversity.com/app/interstitial/focuses-fr.png",
    REACH_SCORMS_HEADER_TITLE: "Paquets SCORM",
    REACH_SCORMS_HEADER_DESCRIPTION: "Descarga paquetes SCORM para tu LMS",
    REACH_SCORMS_TITLE: "Intégrations LMS",
    REACH_SCORMS_SHOW_INSTRUCTIONS: "Montrer les instructions",
    REACH_SCORMS_HIDE_INSTRUCTIONS: "Cacher les instructions",
    REACH_GOALS_TITLE: "Objectifs",
    REACH_GOALS_EDIT_GOALS: "Modifier les Objectifs",
    REACH_GOALS_SECTION_TITLE: "Objectifs Organisationnels",
    REACH_GOALS_SECTION_DESCRIPTION:
      "Affine la formation de ton organisation pour vous concentrer sur les domaines les plus critiques.",
    REACH_GOALS_DESCRIPTION: "Gère les objectifs de ton organisation",
    REACH_SCORMS_DESCRIPTION:
      "Chacun de nos programmes CERT a été conçu pour être compatible avec les normes du Modèle de Référence d'Objets de Contenu Partageable (SCORM). Cela rend simple la fourniture de CERT dans le Système de Gestion de l'Apprentissage (LMS) de votre choix. Cliquez sur le programme cible pour obtenir des instructions et accéder aux fichiers.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_1:
      "Ce programme CERT est compatible avec les normes du Modèle de Référence d'Objet de Contenu Partageable (SCORM) pour s'intégrer dans le Système de Gestion de l'Apprentissage de votre choix basé sur les hypothèses suivantes:",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_2:
      "Votre LMS et l'infrastructure de votre organisation permettent une communication vers l'extérieur. C'est généralement le cas et peut être testé en liant à n'importe quelle adresse web depuis l'intérieur du LMS. Si vous êtes actuellement capable de lier à l'extérieur de votre LMS et Réseau, la communication vers l'extérieur est autorisée.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3:
      "Pendant le processus de configuration du cours SCORM, vous devriez avoir l'opportunité de Télécharger un Fichier. Sélectionnez le fichier téléchargé obtenu à l'étape 1 pour terminer la configuration",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3_A:
      "Votre LMS peut vous demander de fournir des détails supplémentaires. Complétez comme vous le feriez normalement.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3_B:
      "Le code inclus assure que l'onglet du navigateur LMS reste ouvert en plus de la page d'accès au programme. L'onglet du navigateur LMS doit rester ouvert pour faciliter le transfert de retour du statut de complétion.",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_4:
      "Voici comment intégrer le programme CERT dans le LMS de votre organisation:",
    REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_5:
      "Si vous avez des questions ou des préoccupations, veuillez nous contacter à ",
    REACH_SCORMS_INSTRUCTIONS_STEP_1:
      "Cliquez sur le lien ci-dessus pour télécharger un fichier SCORM emballé. Il n'est pas nécessaire de décompresser ce fichier. Le processus de décompression sera géré dans votre LMS dans les prochaines étapes.",
    REACH_SCORMS_INSTRUCTIONS_STEP_2:
      "Créez un nouveau cours SCORM dans votre LMS lors des prochaines étapes.",
    REACH_SCORMS_INSTRUCTIONS_STEP_3:
      "Attribuez le package SCORM à vos utilisateurs.",
    REACH_SCORMS_INSTRUCTIONS_STEP_4:
      "Lancez le programme en tant qu'utilisateur pour tester la connexion et le flux.",
    REACH_HELP_RESOURCE_NO_RESOURCES: "Aucune ressource d'aide",
    REACH_HELP_RESOURCE_INVITE_CODE_DESCRIPTION:
      "<em>headversity</em> n'est pas seulement pour vous—vos membres de la famille peuvent en bénéficier aussi ! Partagez le cadeau de la santé mentale proactive et de la formation à la résilience avec eux. Que ce soit pour le travail ou la vie quotidienne, <em>headversity</em> aide tout le monde à penser et à se sentir au mieux. Partagez ce lien avec vos proches aujourd'hui !",
    REACH_HELP_RESOURCE_INVITE_CODE_SHARE_LINK:
      "Copier le lien pour le partager",
    REACH_HELP_RESOURCE_ADD_NEW: "Ajouter une nouvelle ressource d'aide",
    REACH_HELP_RESOURCE_DELETE: "Supprimer la ressource d'aide",
    REACH_HELP_RESOURCE_MODAL_NAME: "Nom de la ressource d'aide",
    REACH_HELP_RESOURCE_MODAL_DESCRIPTION: "Description de la ressource d'aide",
    REACH_HELP_RESOURCE_MODAL_LOGO: "Logo de la ressource d'aide",
    REACH_HELP_RESOURCE_MODAL_PHONE_NUMBER: "Numéro de téléphone",
    REACH_HELP_RESOURCE_MODAL_TEXT_NUMBER: "Numéro de texte",
    REACH_HELP_RESOURCE_MODAL_IOS_APP_LINK: "Lien vers l'application iOS",
    REACH_HELP_RESOURCE_MODAL_ANDROID_APP_LINK:
      "Lien vers l'application Android",
    REACH_HELP_RESOURCE_MODAL_WEBSITE_LINK: "Lien vers le site web",
    REACH_HELP_RESOURCE_MODAL_SAVE: "Créer ressource",
    REACH_HELP_RESOURCE: "Ressource d'aide",
    REACH_HELP_RESOURCES_TITLE: "Ressources d'aide",
    REACH_HELP_RESOURCES_GROUP_CONFIGURATION:
      "Configuration du groupe de ressources d'aide",
    REACH_HELP_RESOURCES_DESCRIPTION:
      "Un soutien toujours accessible à portée de main des membres.",
    REACH_HELP_RESOURCE_DRAG_DROP: `Glisse-dépose ou <u>choisis un fichier</u> pour télécharger ton image`,
    REACH_HELP_RESOURCES_DESKTOP: "Bureau",
    REACH_HELP_RESOURCES_PREVIEW_INSTRUCTIONS:
      "Sélectionne une langue et une plateforme pour prévisualiser les ressources d'aide disponibles pour les membres du groupe.",
    REACH_INSIGHTS_BEFORE_CERTIFICATION: "Avant la certification",
    REACH_INSIGHTS_AFTER_CERTIFICATION: "Après la certification",
    REACH_INSIGHTS_STRONG_AGREEMENT: "Accord fort",
    REACH_INSIGHTS_NEUTRAL_AGREEMENT: "Accord neutre",
    REACH_INSIGHTS_LOW_AGREEMENT: "Faible accord",
    REACH_INSIGHTS_NO_DATA_DESCRIPTION:
      "Cette section est cachée pour protéger l'anonymat des utilisateurs. Nous la débloquerons dès que nous aurons suffisamment de données. Reviens plus tard pour une mise à jour.",
    REACH_INSIGHTS_NO_STRENGTHS_DATA_DESCRIPTION:
      "Aucune question ne dépasse actuellement le benchmark de référence",
    REACH_INSIGHTS_NO_IMPROVEMENTS_DATA_DESCRIPTION:
      "Aucune question n'est actuellement en dessous du benchmark de référence",
    REACH_INSIGHTS_DESCRIPTION:
      "Obtiens un aperçu de la façon dont tes employés interagissent avec <em>headversity</em et des mesures que tu peux prendre ensuite",
    REACH_USERS: "Utilisateurs",
    REACH_USER_BASE: "Base d'utilisateurs",
    REACH_VIEW_ALL_USERS: "Voir tous les utilisateurs",
    REACH_CHOOSE_GOALS: "Choisissez des Objectifs",
    REACH_ORGANIZATIONS: "Organisations",
    REACH_WELCOME_DESCRIPTION: "Ta trousse de démarrage",
    REACH_ADDITIONAL_RESOURCES_DESCRIPTION: "Toutes nos offres de services",
    REACH_FEATURED_ASSETS_DESCRIPTION: "Contenu choisi spécialement pour toi.",
    REACH_TEAM_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/team-user-guide-fr.jpg",
    REACH_SOLO_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/solo-user-guide-fr.jpg",
    REACH_WELCOME_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/welcome-guide-fr.jpg",
    REACH_SUPPORT_GUIDE_THUMBNAIL:
      "https://cdn.headversity.com/app/resources/reach/and-more-fr.jpg",
    REACH_WELCOME_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/F61F565A-14E2-48A2-9EE6-E66F310A5A53.pdf",
    REACH_TEAM_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/F8DE8193-BD76-4937-B81A-A1A36A3144B1.pdf",
    REACH_SOLO_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/6A908898-860D-4D32-B074-75B2CA4B232B.pdf",
    REACH_SUPPORT_GUIDE:
      "https://headversity-reach-production.s3.ca-central-1.amazonaws.com/Guides/822B33F8-6601-4833-90B4-BE73A1D3B39A.pdf",
    REACH_WELCOME_MODAL_FIRST_PARAGRAPH: `REACH offre une gamme d'outils administratifs pour simplifier les efforts d'administration dans le soutien, la promotion et le suivi de l'impact de <em>headversity</em> sur leur organisation. En tant qu'administrateur, REACH te donne le pouvoir de favoriser une organisation épanouie, engagée et résiliente.`,
    REACH_WELCOME_MODAL_SECOND_PARAGRAPH: `
<p style="align: left;">Voici comment commencer :</p>
<ul style="list-style-type: disc; margin-left: 20px; margin-bottom: 8px; margin-top: 8px;">
    <li>Complète ton guide de démarrage rapide pour configurer ton organisation.</li>
    <li>Explore REACH pour découvrir les fonctionnalités intuitives et te familiariser avec la plateforme.</li>
    <li>Visite le Centre d'aide pour du soutien et des ressources supplémentaires.</li>
</ul>
<p style="align: left; margin-top: 20px;">Nous sommes là pour t'aider à avoir un impact positif au sein de ton organisation.</p>`,
    REACH_SOCIAL_MEDIA_INSTRUCTION:
      'Partagez sur LinkedIn ou Yammer, ou cliquez sur "Copier le texte" et "Télécharger" pour vous l\'approprier.',
    REACH_COPY_PASSWORD_RESET_LINK:
      "Clique ici pour copier le lien de réinitialisation du mot de passe",
    REACH_COPY_TEXT: "Copier le texte",
    REACH_USERS_ACTIONS: "Actions",
    REACH_USERS_BULK_UPLOAD: "Importer des utilisateurs",
    REACH_USERS_CHANGE_FILE: "Liste des changements aux utilisateurs",
    REACH_USERS_BULK_FULL_LIST: "Liste complète des utilisateurs",
    REACH_USERS_BULK_UPLOAD_CONFIRM: "Confirmer le téléchargement en vrac",
    REACH_CLICK_OR_DRAG: "Cliquez ou déposez un fichier csv ici.",
    REACH_USER_ROLE: "Rôle",
    REACH_INVITE_CODE: "Code d'invitation",
    REACH_ACTIVATION_EMAIL_SEND_DATE: "Date d'envoi de l'email d'activation",
    REACH_USERS_OPTIONAL: "optionnel",
    REACH_USERS_EMAIL_SENT_DATE: "Date d'envoi de l'email",
    REACH_USERS_ADD_USER_TOOLTIP:
      "Si non spécifié, l'utilisateur recevra un e-mail d'activation immédiatement.",
    REACH_USER_DELETE_DESCRIPTION: "Es-tu sûr de vouloir supprimer {0}?",
    REACH_USER_DELETE_SUCCESS: "L'utilisateur a été supprimé avec succès.",
    REACH_USER_EDIT_SUCCESS: "L'utilisateur a été mis à jour avec succès.",
    REACH_USER_ADD_SUCCESS: "L'utilisateur a été ajouté avec succès.",
    REACH_USER_INVALID_EMAIL_ADDRESS: "Adresse e-mail invalide",
    REACH_USER_INVALID_URL: "URL invalide",
    REACH_USER_INVALID_FULL_NAME:
      "Le nom complet doit comporter au moins 2 mots.",
    REACH_USER_NO_CERT_ASSIGNMENTS_SELECTED:
      "Veuillez sélectionner au moins une certification à attribuer.",
    REACH_USER_EMAIL_ALREADY_EXISTS: "L'adresse e-mail existe déjà.",
    REACH_USER_CERT_NOT_ASSIGNED: "Certificat non attribué à l'entreprise",
    REACH_USER_SEARCH: "Rechercher un utilisateur",
    REACH_NO_USERS_FOUND: "Aucun utilisateur trouvé.",
    REACH_USER: "Utilisateur",
    REACH_USER_USERS_PER_PAGE: "Utilisateurs par page:",
    REACH_USERS_ADD_USER: "Ajouter un utilisateur",
    REACH_USERS_DOWNLOAD_REPORT: "Télécharger le rapport",
    REACH_USERS_DOWNLOAD_CSV_FILE_NAME: "utilisateurs",
    REACH_USERS_UPLOAD_INSTRUCTIONS_1:
      "Pour importer des utilisateurs, utilise le modèle ci-dessous pour créer un fichier CSV. Le modèle contient quelques lignes avec des données d'exemple, alors tu devras enlever ces lignes lorsque tu créeras ton fichier.",
    REACH_USERS_UPLOAD_INSTRUCTIONS_2:
      "Les colonnes supplémentaires que tu spécifies seront ajoutées comme données supplémentaires pour chaque utilisateur.",
    REACH_USERS_DOWNLOAD_TEMPLATE: "Télécharger le modèle",
    REACH_USERS_UPLOAD_YOUR_FILES: "Téléchargez tus fichiers",
    REACH_USERS_IMPORT_USERS: "Importer des utilisateurs",
    REACH_USERS_UPLOAD_STEP_1_TITLE: "Importation en vrac d'utilisateurs",
    REACH_USERS_UPLOAD_STEP_2_TITLE: "Télécharger le fichier",
    REACH_USERS_UPLOAD_STEP_3_TITLE: "Attribuer une CERT",
    REACH_USERS_IMPORT_TYPE: "Type d'importation",
    REACH_USERS_ASSIGN: "Attribuer",
    REACH_USERS_WAS_SUCCESSFULLY_ASSIGNED: "a été attribué avec succès.",
    REACH_USERS_CHOOSE_A_CERT: "Choisis une CERT",
    REACH_USERS_ADD_ANOTHER_CERT: "Ajouter un autre CERT",
    REACH_USERS_SKIP_FOR_NOW: "Passer pour le moment",
    REACH_USERS_FILE_ERRORS: "Il y a des erreurs dans tous fichier:",
    REACH_USERS_CORRECT_CSV:
      "Veuille ouvrir tous fichier CSV et corriger ces modifications pour traiter avec succès tous fichier utilisateur.",
    REACH_USERS_FILE_READY: "Tous fichier utilisateur est prêt à être soumis.",
    REACH_USERS_USERS_ADDED_UPDATED: "Utilisateurs ajoutés/mis à jour",
    REACH_USERS_USERS_REMOVED: "Utilisateurs supprimés",
    REACH_USERS_NEW_CUSTOM_FIELDS: "Nouveaux champs personnalisés",
    REACH_USERS_CERTS_ASSIGNED: "CERTs attribuées",
    REACH_USERS_CERTS_COMPLETED: "CERTs Complétées",
    REACH_USERS_CERTS: "certification(s)",
    REACH_USERS_BACK_TO_UPLOAD_PAGE: "Retour à la page de téléchargement",
    REACH_USERS_UPLOAD_STARTED: "Le téléchargement du fichier a commencé.",
    REACH_USERS_ELIGIBILITY_JOB_SUCCESS:
      "Travail du fichier d’éligibilité réussi.",
    REACH_USERS_ELIGIBILITY_JOB_ERROR:
      "Le travail du fichier d’éligibilité s’est terminé avec des erreurs.",
    REACH_USERS_UPLOADING_WAIT: "Téléchargement - Veuille patienter",
    REACH_USERS_PROCESSING_STARTED: "Le traitement du fichier a commencé.",
    REACH_USERS_UPLOAD_SUCCESS: "Fichier téléchargé.",
    REACH_USERS_DRAG_DROP:
      "Glisse-dépose ou <u>choisis un fichier</u> pour télécharger tes utilisateurs.",
    REACH_USERS_ELIGIBILITY_JOB: "Emploi",
    REACH_USERS_TOOLTIP:
      "Si vous devez ajouter des champs personnalisés, veuillez plutôt télécharger un fichier CSV.",
    REACH_USERS_CUSTOM_FIELDS_TEXT_1:
      "Pour ajouter des champs personnalisés, veuillez",
    REACH_USERS_CUSTOM_FIELDS_TEXT_2: "télécharger un dossier d'éligibilité",
    REACH_USERS_CUSTOM_FIELDS_TEXT_3: "plutôt.",
    REACH_USERS_ACCEPTED_EXTENSIONS:
      "Le type de fichier .csv est pris en charge.",
    REACH_USERS_ELIGIBILITY_CUSTOM_COLUMNS: "<b>Colonnes personnalisées: </b>",
    REACH_USERS_FULL_LIST_DELETE_WARNING:
      "Attention! Ce processus désactivera tout utilisateur déjà téléchargé qui ne figure pas dans le fichier.",
    REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_1:
      "Tu es sur le point de supprimer",
    REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_2: "utilisateur(s).",
    REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_3:
      "Es-tu sur de vouloir continuer?",
    REACH_USERS_FULL_LIST_DESCRIPTION:
      "Dans le fichier CSV, spécifiez tous les utilisateurs admissibles et laissez le système ajouter/mettre à jour/désactiver au besoin.",
    REACH_USERS_CHANGE_FILE_DESCRIPTION: `Dans le fichier CSV, spécifie un ensemble d'utilisateurs à ajouter, mettre à jour ou désactiver.<br/><br/>
      Ajouter et mettre à jour les utilisateurs:<ul style='list-style:circle;margin-left:1rem;margin-top:5px'>
        <li>Pour ajouter ou mettre à jour un utilisateur, spécifie l'utilisateur dans le fichier</li>
        <li>Les nouveaux utilisateurs seront ajoutés et recevront un e-mail d'activation au moment spécifié</li>
        <li>Les utilisateurs existants seront mis à jour avec tout nouveau détail</li>
        {0}
        </ul>
        <br/>
        Désactivation des utilisateurs:<ul style='list-style:circle;margin-left:1rem;margin-top:5px'>
        <li>Pour désactiver un utilisateur, spécifie <code>DELETE</code> dans la colonne d'action d'importation</li>
        </ul>`,
    REACH_USERS_CHANGE_FILE_HIERARCHY_DESCRIPTION:
      "<li>Spécifiez l'association au format « 2|3|4 » où 2,3,4 sont les identifiants de nœud.</li>",
    REACH_USERS_CHANGE_FILE_CERT_DESCRIPTION:
      "<li>Spécifiez tout certificat à attribuer à l'utilisateur</li>",
    REACH_USERS_ELIGIBILITY_USERS_COUNT: "<b>Nombre d'utilisateurs: </b>",
    REACH_USERS_DELETE_USER: "Supprimer l'utilisateur",
    REACH_USERS_RESET_PASSWORD: "Réinitialiser le mot de passe",
    REACH_USERS_RESEND_ACTIVATION_EMAIL: "Renvoyer l'email d'activation",
    REACH_USER_ACTIVATION_EMAIL_RESENT: "L'email d'activation a été renvoyé",
    REACH_USERS_RESEND_ACTIVATION_EMAIL_CONFIRMATION:
      "Le mot de passe de l'utilisateur sera réinitialisé et un e-mail sera envoyé à l'utilisateur pour finaliser le processus. Êtes-vous sûr ?",
    REACH_USER_SAVE: "Sauvegarder l'utilisateur",
    REACH_USERS_CHANGE_EMAIL_CONFIRMATION:
      "Tu as mis à jour l'email de l'utilisateur. Es-tu sûr?",
    REACH_USERS_RESET_PASSWORD_USER: "Réinitialiser le mot de passe",
    REACH_USERS_RESET_PASSWORD_LINK: "Lien de réinitialisation du mot de passe",
    REACH_USERS_CLEAR_SEARCH: "Effacer la recherche",
    REACH_USERS_RESET_FILTERS: "Réinitialiser les filtres",
    REACH_USERS_INVITE_USERS: "Inviter des utilisateurs",
    REACH_USERS_SHARING_IS_CARING: "Partager, c'est prendre soin!",
    REACH_USERS_SHARING_IS_CARING_DESCRIPTION:
      "Colle le texte suivant dans la plateforme de communication de ton choix pour informer tes employés et leur famille que <em>headversity</em> est disponible pour eux.",
    REACH_USERS_SHARING_INVITE_CODE_MESSAGE:
      "On aimerait te présenter <em>headversity</em> – une solution proactive de formation à la santé mentale et à la résilience que tu peux utiliser dans le flux de travail et de la vie quotidienne pour penser et te sentir au top.<br/><br/>Utilise le code {0} pour rejoindre <em>headversity</em> et te lancer dans un parcours de bien-être personnalisé.<br/><br/>Inscris-toi ici {1}",
    REACH_USERS_SHARING_FAMILY_INVITE_CODE_MESSAGE:
      "<em>headversity</em> est également disponible pour les membres de ta famille afin qu'ils puissent penser et se sentir au mieux eux aussi ! Cela signifie un accès à une formation proactive en santé mentale et en résilience dans le cadre du travail et de la vie quotidienne.<br/><br/>Partage le code {0} pour qu'ils puissent rejoindre <em>headversity</em> et se lancer dans un parcours de bien-être personnalisé.<br/><br/>Partage ce lien avec eux {1}",
    REACH_USERS_SHOW_DETAILS: "Afficher les détails",
    REACH_USERS_HIDE_DETAILS: "Masquer les détails",
    REACH_QUICK_START_PROGRESS: "Progression",
    REACH_QUICK_START_DEFINE_ORGANIZATION_TITLE:
      "Définis ton organisation principale",
    REACH_QUICK_START_DEFINE_ORGANIZATION_DESCRIPTION:
      "Personnalise ton profil d'organisation dans <em>headversity</em> pour créer une expérience sur mesure.",
    REACH_QUICK_START_DEFINE_ORGANIZATION_BUTTON:
      "Configurer votre organisation",
    REACH_QUICK_START_SHOWCASE_LOGO_TITLE: "Montre ton logo",
    REACH_QUICK_START_SHOWCASE_LOGO_DESCRIPTION:
      "Favorise un sentiment de familiarité et d'engagement parmi les membres avec l'image de marque de ton organisation.",
    REACH_QUICK_START_SHOWCASE_LOGO_BUTTON: "Ajouter un logo",
    REACH_QUICK_START_DEFINE_HELP_RESOURCES_TITLE:
      "Définis les ressources d’aide",
    REACH_QUICK_START_DEFINE_HELP_RESOURCES_DESCRIPTION:
      "Assurez un soutien continu à tes membres grâce à des ressources d'aide facilement accessibles dans l'application. Adapte ces ressources pour inclure des services essentiels tels que les programmes d’aide aux employés et à leurs familles (PAEF), fournissant une aide complète en cas de besoin.",
    REACH_QUICK_START_DEFINE_HELP_RESOURCES_BUTTON: "Configurer les ressources",
    REACH_QUICK_START_CONFIRM_COMMS_TITLE:
      "Confirme les paramètres de communication",
    REACH_QUICK_START_CONFIRM_COMMS_DESCRIPTION:
      "Optimise ta connexion avec <em>headversity</em> en configurant tes paramètres de liste autorisée, assurant que ton équipe ne manque jamais d'emails, de nouvelles et de mises à jour cruciales.",
    REACH_QUICK_START_ADD_USERS_TITLE:
      "Ajoute ou importe les premiers utilisateurs",
    REACH_QUICK_START_ADD_USERS_DESCRIPTION:
      "Donne accès aux expériences de <em>headversity</em> en invitant des individus ou en important une liste d'utilisateurs éligibles.",
    REACH_QUICK_START_ADD_USERS_BUTTON: "Ajouter des utilisateurs",
    REACH_QUICK_START_CHOOSE_GOALS_TITLE:
      "Choisissez des objectifs organisationnels",
    REACH_QUICK_START_CHOOSE_GOALS_DESCRIPTION:
      "Personnalisez vos objectifs organisationnels dans headversity pour créer une expérience sur mesure.",
    REACH_QUICK_START_CHOOSE_GOALS_BUTTON: "Choisir des objectifs",
    REACH_VISIT_HELP_CENTER_TEXT: "Visite le Centre d'aide",
    REACH_QUICK_HELP_CENTER_DESCRIPTION:
      "Des conseils utiles et les réponses à toutes tes questions sont disponibles 24h/24 et 7j/7 dans le Centre d'aide.",
    REACH_MY_ORGANIZATION: "Mon organisation",
    REACH_MANAGE_INTEGRATIONS: "Gérer les intégrations",
    REACH_MANAGE_SETTINGS: "Gérer les paramètres",
    REACH_USERS_CERTIFICATIONS: "Certifications",
    REACH_USERS_CREATED: "Créé",
    REACH_USERS_ACTIVATION_DATE: "Date d'activation",
    REACH_USERS_EDIT: "Modifier",
    REACH_USERS_CERTIFICATION: "Certification",
    REACH_USERS_DATE_ASSIGNED: "Date d'attribution",
    REACH_USERS_DATE_STARTED: "Date de début",
    REACH_USERS_DATE_COMPLETED: "Date de fin",
    REACH_USERS_UN_ASSIGN: "Éliminer",
    REACH_USERS_ASSIGN_A_CERTIFICATION: "Attribuer une certification",
    REACH_USERS_CERTIFICATION_ASSIGNMENTS: "Assignations de Certification",
    REACH_USERS_UN_ASSIGN_CERTIFICATION: "Éliminer une certification",
    REACH_USERS_UN_ASSIGN_CERT_CONFIRMATION_MESSAGE:
      "Êtes-vous sûr de vouloir éliminer cette certification ?",
    REACH_USERS_UN_ASSIGN_CERT_SUCCESS: "Certification éliminée avec succès.",
    REACH_CONTINUE_YOUR_QUICK_START_GUIDE:
      "Continuer le guide de démarrage rapide",
    REACH_ORGANIZATIONS_LMS_INTEGRATIONS: "Intégrations LMS",
    REACH_ORGANIZATIONS_LMS_INTEGRATION: "Intégration de LMS",
    REACH_ORGANIZATIONS_LMS_DOWNLOAD_INSTRUCTION:
      "Cliquez ici pour télécharger le package SCORM",
    REACH_ORGANIZATIONS_COMMUNICATION_SETTINGS: "Paramètres de communication",
    REACH_ORGANIZATIONS_COMMUNICATION_SETTINGS_DESCRIPTION:
      "Personnalise tes préférences de communication pour une expérience <em>headversity</em> fluide et informée.",
    REACH_ORGANIZATIONS_CONFIRMED_ALLOWED:
      "Tu as confirmé que les informations demandées sont autorisées",
    REACH_ORGANIZATIONS_NOT_CONFIRMED_ALLOWED:
      "Tu n'as pas encore confirmé les informations demandées",
    REACH_ORGANIZATIONS_ALLOW_LIST_REQUEST: "Demande de liste autorisée",
    REACH_ORGANIZATIONS_ALLOW_MESSAGE:
      "La configuration de la liste autorisée garantit que tu reçois les mises à jour importantes de <em>headversity</em>. Elle permet aux utilisateurs de recevoir des e-mails avec des actualités, des vidéos et des mises à jour. Copie et partage les informations ci-dessous avec tes administrateurs <informatiques class=''></informatiques>",
    REACH_ORGANIZATIONS_SENDER_EMAILS: "E-mails des expéditeurs",
    REACH_ORGANIZATIONS_DOMAINS: "Domaines",
    REACH_ORGANIZATIONS_QUESTIONS:
      "Si tu as des questions ou as besoin d'aide, veuille contacter notre équipe d'assistance à <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    REACH_ORGANIZATIONS_COPY_TO_CLIPBOARD:
      "Copier ces informations dans le presse-papiers",
    REACH_ORGANIZATIONS_INFORMATION_ALLOWED:
      "Les informations répertoriées ont été autorisées",
    REACH_ORGANIZATIONS_HELP_RESOURCES_ADDED: "Ressources d'aide ajoutées",
    REACH_ORGANIZATIONS_GOALS_CHOSEN: "Objectifs Choisis",
    REACH_ORGANIZATIONS_MANAGE_HELP_RESOURCES: "Gérer les ressources",
    SET_NUMBER_OF_GROUPS: "Définir le nombre de groupes",
    START_TEAM_LESSON: "Commencer",
    TEAM_CODE: "Identifiant de l'équipe",
    TEAM_SCAN_CODE: "Scanne le code QR pour participer",
    TEAM_JOIN_BROWSER: "Rejoins-nous dans ton navigateur",
    PARTICIPANT_LIST: "Liste des participants",
    IS_NOW_PRESENTING: "s'entraîne ensemble maintenant!",
    YOU_ARE_PRESENTING: "(Tu es le présentateur)",
    TEAM_MULTIPLE_PRESENTING: "Plusieurs équipes présentent",
    JOIN_TEAM_LESSON: "Rejoins mon équipe",
    JOIN_EXISTING_TEAM: "Rejoins une équipe",
    TEAM_SWITCH_TEAMS: "Modifier l'équipe sélectionnée",
    TEAM_CREATE_A_TEAM: "Créer une équipe",
    TEAM_CURRENT_TEAM: "Équipe sélectionnée",
    TEAM_NOT_YET_PRESENTED: "Pas encore présenté",
    TEAM_LAST_PRESENTED: "Dernière présentation",
    TEAM_TEAM_LESSON_INFO: "Informations sur la présentation",
    TEAM_TEAM_LESSON_QR: "Partager",
    TEAM_LESSON: "Présentation",
    TEAM_LESSONS: "Présentations",
    MINUTE: "Minute",
    MINUTES: "Minutes",
    TEAM_SWITCH_TEAM_VIEW: "Sélectionne une équipe",
    TEAM_CURRENTLY_PART_OF_TEAM: "Selected Team:",
    TEAM_USE_THE_DROPDOWN:
      "Utilise le menu déroulant ci-dessous pour sélectionner une autre équipe avec laquelle tu souhaites participer",
    TEAM_TEAM_NAME: "Nom de l'équipe",
    TEAM_CHOOSE_A_TEAM: "Choisis une équipe",
    MEMBERS: "Membres",
    MEMBER: "Membre",
    ARIA_LABEL_EDIT_TEAM_NAME: "Modifier le nom de l'équipe",
    TEAM_NOT_YET_MET: "Pas encore rencontré",
    TEAM_LAST_MET: "Dernière réunion:",
    TEAM_DELETE_TEAM: "Supprimer l'équipe",
    TEAM_ADD_MEMBER: "Ajouter un membre",
    CONTINUE_WHERE_YOU_LEFT_OFF: "Continue là où tu t'es arrêté",
    JOIN_TEAM_LESSON_IN_PROGRESS: "Rejoins la présentation en cours",
    TEAM_PROGRESS: "Progrès de l'équipe",
    TEAM_CONFIRM_DELETE: "Es-tu sûr de vouloir supprimer {0}?",
    TEAM_USER_CONFIRM_DELETE: "Es-tu sûr de vouloir supprimer {0} de {1}?",
    CANCEL: "Annuler",
    TEAM_OR_SCAN_QR_CODE: "Ou scanner le code QR",
    TEAM_MANUALLY_ENTER: "Ajouter manuellement ton coéquipier",
    TEAM_ENTER_BY_QR: "Entrer en partageant un QR",
    TEAM_CREATED_SUCCESS: "L'équipe a été créée avec succès.",
    TEAM_NAME_UPDATED_SUCCESS:
      "Le nom de l'équipe a été mis à jour avec succès.",
    TEAM_EDIT_CURRENT_TEAM_NAME: "Nom de l'équipe actuelle",
    TEAM_CURRENT_TEAM_NAME: "Tu utilises actuellement TEAM dans le cadre de",
    TEAM_CHANGE_TEAM_BODY1: "Équipe sélectionnée:",
    TEAM_CHANGE_TEAM_BODY2:
      "Ce n'est pas l'équipe avec laquelle tu t’entraînes aujourd'hui? Utilise le menu déroulant ci-dessous pour sélectionner une autre équipe avec laquelle participer.",
    TEAM_NEW_TEAM_NAME: "Nouveau nom d'équipe",
    TEAM_EDIT_TEAM_NAME: "Modifier le nom de l'équipe",
    UPDATE_BUTTON_TEXT: "Actualiser",
    TEAM_CREATE_NEW_TEAM_DESCRIPTION:
      "Donne d'abord un nom à ton équipe, puis invite tes collègues à te rejoindre!",
    TEAM_MANAGEMENT_TITLE: "Équipes",
    TEAM_ADD_MEMBER_INSTRUCTION:
      "Entre l'adresse e-mail de la personne que tu souhaite ajouter, et nous lui enverrons un e-mail pour l'inviter à se joindre à nous.",
    TEAM_ADD_MEMBER_SUCCESS: "L'invitation a été envoyée avec succès.",
    TEAM_RESEND_ACTIVATION_SUCCESS: "Activation email was successfully resent",
    TEAM_MANAGEMENT_DESCRIPTION: "Gère toutes tes équipes et leur progression",
    TEAM_JOIN_SUCCESS_DESCRIPTION: "Tu as rejoint l'équipe avec succès!",
    TEAM_DELETE_SUCCESS_TITLE: "L'équipe a été supprimée avec succès.",
    TEAM_DELETE_USER: "Supprimer l'utilisateur",
    TEAM_USER_DELETE_SUCCESS_TITLE:
      "L'utilisateur de l'équipe a été supprimé avec succès.",
    TEAM_JOIN_ALREADY_JOINED_DESCRIPTION:
      "Vous êtes déjà membre de cette équipe.",
    TEAM_ADD_MEMBER_ALREADY_EXISTS_DESCRIPTION:
      "L'email fourni est déjà associé à un membre de cette équipe.",
    TEAM_ERROR_TEAM_CODE_DOES_NOT_EXIST:
      "L'identifiant d'équipe que tu as entrée n'existe pas. Réessaye.",
    TEAM_ERROR_TEAM_CODE_IS_ON_WRONG_COMPANY:
      "L'identifiant d'équipe que tu as saisi n'est pas utilisable dans ton entreprise. Contacte le support technique si le problème persiste.",
    TEAM_ERROR_TEAM_IS_ON_WRONG_COMPANY:
      "L'équipe dans laquelle tu es entré n'est pas utilisable dans ton entreprise. Contacter le support technique si le problème persiste.",
    TEAM_ERROR_USER_DOES_NOT_BELONG_TO_TEAM:
      "L'utilisateur spécifié n'appartient pas à l'équipe que tu as entrée. Contacte le support technique si le problème persiste.",
    EMAIL_ADDRESS: "Adresse e-mail",
    FULL_NAME: "Nom Complet",
    TEAM_MENU_PATHS: "Parcours",
    TEAM_MENU_TEAMS: "Équipes",
    TEAM_MENU_INSIGHTS: "Données",
    TEAM_POLL_TIME: "C'est l'heure du sondage!",
    TEAM_BACK_TO_PREVIEW: "Retour à l'aperçu",
    TEAM_END_PREVIEW: "Terminer l'aperçu",
    TEAM_PARTICIPATE_END: "Quitter la présentation?",
    TEAM_PARTICIPATE_END_MESSAGE:
      "Es-tu sûr de vouloir quitter la présentation?",
    TEAM_TEAM_LESSON_END: "Terminer la présentation?",
    TEAM_END_TEAM_LESSON: "Terminer la présentation",
    TEAM_TEAM_LESSON_END_MESSAGE:
      "Es-tu sûr de vouloir terminer la présentation?",
    TEAM_TEAM_GROUPING_ONLY_ONE_USER:
      "Le groupe {1} n'a qu'un seul utilisateur, êtes-vous sûr de vouloir continuer?",
    TEAM_TEAM_LESSON_INCOMPLETE_END_BLOCK_ONE:
      "Tous les sondages n'ont <strong> pas </strong> été complétés.",
    SPORT_TEAM_LESSON_INCOMPLETE_END_BLOCK_ONE:
      "Tous les activités n'ont <strong> pas </strong> été complétés.",
    TEAM_TEAM_LESSON_INCOMPLETE_CONTINUE: "Continuer la présentation",
    TEAM_TEAM_LESSON_INCOMPLETE_RESUME_LATER: "Reprendre plus tard",
    TEAM_TEAM_LESSON_INCOMPLETE_END_NOW: "Cette formation est terminée",
    TEAM_BACK_TO_POLLS: "Montrer les sondages",
    TEAM_BACK_TO_LOBBY: "Hall d'exposition / Montrer le lobby? ",
    TEAM_GREAT_WORK: "Bon travail!",
    TEAM_FEEDBACK_DESCRIPTION:
      "Ça, c'est du bon travail d'équipe! Tu es prêt à prendre ce que tu as appris et à le mettre en pratique!",
    TEAM_NAILED_IT: "Tu l'as fait!",
    TEAM_PARTICIPANT_END_TITLE: "Excellent travail!",
    TEAM_PRESENTER_END_MESSAGE:
      "Il y a beaucoup plus à venir pour toi et ton équipe, ou tu peux prendre du temps et te concentrer sur toi-même!",
    TEAM_PARTICIPANT_END_MESSAGE:
      "Tu as terminé cette présentation, et toi et ton équipe sont prêts à passer à la prochaine présentation dans ce parcours!",
    TEAM_INSIGHT_TITLE: "Aperçu",
    TEAM_INSIGHT_DESCRIPTION:
      "Un résumé de l'engagement et de la progression de tes équipes.",
    TEAM_HOME_TITLE: "TEAM",
    TEAM_HOME_HEADER_TITLE: "Bienvenue à TEAM",
    TEAM_HOME_DESCRIPTION:
      "Nous allons plus loin lorsque nous travaillons ensemble",
    TEAM_HOME_NEXT_UP: "Suivant pour ",
    TEAM_HOME_UP_NEXT: "Suivant",
    TEAM_HOME_NEXT_UP_PATH:
      "Reprends là où tu t'es arrêté dans le parcours <strong>{0}</strong>!",
    TEAM_HOME_PATHS_COMPLETE: "Tous les parcours sont terminés!",
    TEAM_HOME_PATH_START_PATH: "Commencer le parcours",
    TEAM_HOME_PATH_CONTINUE: "Continuer le parcours",
    TEAM_HOME_PATH_PROGRESS: "Voir les progrès",
    TEAM_HOME_PAGE_NEXT_UP_FOR_YOU: "Pendant que tu étais absent ...",
    TEAM_HOME_PAGE_NEXT_UP_FOR_YOU_DESCRIPTION_1:
      "Il semble que tu as raté une formation! Rattraper",
    TEAM_HOME_PAGE_NEXT_UP_FOR_YOU_DESCRIPTION_2:
      "maintenant avant le prochain.",
    TEAM_HOME_PAGE_NEXT_UP_COMPLETE_DESCRIPTION_1:
      "Si tu manques des formations, nous t’inviterons à les rattraper",
    TEAM_HOME_PAGE_NEXT_UP_COMPLETE_DESCRIPTION_2: "ici.",
    TEAM_GROUPING_REPORTER: "Reporter",
    TEAM_TEAM_GROUPING_CATCH_UP_END: "Fin du regroupement ?",
    TEAM_TEAM_LESSON_CATCH_UP_END: "Terminer le rattrapage?",
    TEAM_TEAM_LESSON_CATCH_UP_END_MESSAGE:
      "Es-tu sûr de vouloir mettre fin à la session de rattrapage?",
    TEAM_BUTTON_PRESENT: "Présenter",
    TEAM_BUTTON_PREVIEW: "Aperçu",
    TEAM_BUTTON_IN_PROGRESS: "Rejoindre",
    TEAM_BUTTON_IN_CATCH_UP: "Rattraper",
    TEAM_BUTTON_START_OVER: "Recommencer",
    TEAM_BUTTON_EXPIRE_TEAM_LESSON: "En cours pendant plus de 15 minutes",
    TEAM_PRESENT_CONFIRM_PRESENT_AGAIN:
      "Cette présentation a été complétée le {0}. Veux-tu la présenter à nouveau?",
    TEAM_PRESENT_CONFIRM_PRESENT_AGAIN_HEADER_TITLE: "Présenter à Nouveau",
    TEAM_BUTTON_PARTICIPATE: "Participer",
    TEAM_PRESENT_OR_PARTICIPATE: "Déjà en présentation?",
    TEAM_PRESENT_OR_PARTICIPATE_TEXT:
      "Il semble que tu sois déjà en train de présenter avec ton équipe dans une autre session. Souhaites-tu te joindre en tant que participant ou prendre le contrôle en tant que présentateur?",
    TEAM_VOTES: "Réponses",
    TEAM_VOTES_SEE_RESULT: "Tu dois répondre pour voir le résultat",
    TEAM_NO_TEAM_DESCRIPTION:
      "Tu n'appartiens pas actuellement à une équipe. Rejoins une équipe ou crées-en une.",
    TEAM_JOIN_NEW_TEAM: "Rejoindre une équipe",
    TEAM_JOIN_THIS_TEAM: "Rejoindre cette équipe",
    TEAM_JOIN_ANOTHER_TEAM: "Rejoindre une équipe",
    TEAM_LEAVE_CURRENT_TEAM: "Quitter l'équipe",
    TEAM_ENTER_NEW_CODE: "Entre l'identifiant d'équipe",
    TEAM_LEAVE_DESCRIPTION: "Es-tu sûr de vouloir quitter cette équipe?",
    TEAM_JOIN_NEW_TEAM_DESCRIPTION:
      "Entre l'identifiant d'équipe qui t’a été partagé, ou",
    TEAM_CREATE_NEW_TEAM: "créer une nouvelle équipe",
    TEAM_JOIN_PICK_TEAM_TO_JOIN: "Choisis une équipe à rejoindre",
    APP_GRID_SOLO: "SOLO",
    APP_GRID_TEAM: "TEAM",
    APP_GRID_REACH: "REACH",
    APP_GRID_SPORT: "SPORT",
    TEAM_TEAM_LESSON_CANCEL_TITLE: "Annuler la présentation",
    TEAM_TEAM_LESSON_CANCEL_DESCRIPTION:
      "Es-tu sûr que tu souhaites annuler cette présentation?",
    TEAM_TEAM_LESSON_CANCEL_MESSAGE: "Cette présentation a été annulée.",
    TEAM_GET_STARTED: "Voici comment commencer!",
    TEAM_GET_STARTED_DESCRIPTION:
      "Bienvenue à TEAM! Si un identifiant d'équipe a été partagé avec toi, <strong> rejoins cette équipe maintenant.</strong> Sinon, <strong> Crée une nouvelle équipe </strong> pour commencer à présenter avec tes collègues.",
    TEAM_TEAM_LESSON_MIN: "min",
    TEAM_TEAM_LESSON_MINS: "mins",
    TEAM_TEAM_LESSON_INDICATOR_TRAINING_INCLUDED:
      "Formation individuelle à prendre",
    TEAM_TEAM_LESSON_INDICATOR_GROUP_ACTIVITY_INCLUDED: "Activité de groupe",
    TEAM_TEAM_LESSON_INDICATOR_INCLUDES_SCRIPT:
      "Cette présentation comprend un script",
    TEAM_TEAM_LESSON_DOWNLOAD_TITLE: "Télécharger la présentation",
    TEAM_TEAM_LESSONS_COMPLETE: "Présentation terminée",
    TEAM_TEAM_LESSONS_COMPLETED: "Présentations terminées",
    TEAM_TOTAL_TEAM_LESSONS: "Présentations totales",
    TEAM_POLL: "Sondage",
    TEAM_POLLS: "Sondages",
    TEAM_NO_POLLS: "Pas de sondages complétés",
    TEAM_PARTICIPANT: "Participant",
    TEAM_PARTICIPANTS: "Participants",
    TEAM_TEAM_LESSON_PREVIEW_TITLE: "Ce n'est qu'un aperçu.",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_TITLE: "Juste une seconde!",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_DESCRIPTION_BLOCK_ONE:
      "Nous te recommandons fortement de présenter les formations dans l'ordre où elles apparaissent pour mieux profiter de ton expérience. Mais si tu souhaites procéder dans ton ordre, ça va!",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_DESCRIPTION_BLOCK_TWO:
      "Cependant, nous savons que parfois le temps est un facteur, et si tu veux en sauter pour le moment et progresser plus loin, c'est OK!",
    TEAM_TEAM_LESSON_OUT_OF_ORDER_UNDERSTAND: "Je comprends",
    TEAM_POLL_COMPLETE: "Sondage complété",
    TEAM_POLLS_COMPLETED: "Les sondages complétés",
    TEAM_VIEW_POLL_RESULT: "Voir les résultats",
    TEAM_YOUR_ID: "Identifiant d'équipe:",
    JOINED: "Participants:",
    TEAM_WAITING_JOIN_MESSAGE:
      "Une fois que tout le monde s'est joint et que tu es prêt à présenter, clique sur <strong>Commencer</strong>.",
    TEAM_WAITING_JOIN_GROUP_MESSAGE:
      "Once everyone has joined and you're ready to start grouping, click <strong>Start Grouping</strong>.",
    TEAM_WAITING_TEAM_MATE: "On attend que les coéquipiers se joignent ...",
    TEAM_WAITING_GROUPING: "Creating breakout groups...",
    TEAM_CHARTER: "Charte d'équipe",
    TEAM_CHARTER_DISCUSSION_IN_PROGRESS: "Discussion en cours",
    TEAM_CATCH_UP_INFO_TEXT:
      "Si ton équipe termine une présentation sans toi, c'est OK! Tu peux toujours participer à cette leçon et à toutes les activités et sondages qui l'accompagnent maintenant. Pour « rattraper » toutes les présentations manquées, clique sur la présentation pour commencer.",
    TEAM_CATCHUP_HOMEPAGE_TOOLTIP_TEXT:
      "Tu eux « rattraper » en examinant la présentation et en complétant les activités qui l'accompagnent par toi-même.",
    SELECT: "Sélectionner",
    TEAM_SELECT_GROUP_NUMBER: "Sélectionnez le nombre de groupes",
    TEAM_TEAM_LESSON_IN_PROGRESS: "Présentation en cours ...",
    TEAM_TEAM_LESSON_IN_PROGRESS_SUB_HEADING:
      "{0} est en train de présenter ...",
    ALL_SET: "Tu es prêt!",
    TEAM_TEAM_LESSON_WAITING_TO_START:
      "En attente de {0} pour commencer la présentation ...",
    TEAM_POLL_RESULT: "Résultats du sondage",
    TEAM_LESSON_MINUTES_SPEND: "Minutes passées dans les leçons",
    TEAM_POLL_ENGAGEMENT: "Participation aux sondages",
    TEAM_TEAMMATES_IN_ONE_SESSION: "Record d'équipiers en une session",
    TEAM_COMPLETE_MORE_THAN_ONE:
      "Les membres de l'équipe qui ont complété une leçon ou plus",
    TEAM_TOTAL_LESSON_PARTICIPATION:
      "Nombre total de leçons complétées à travers les coéquipiers depuis le début",
    TEAM_USER_INTERACTION: "Interactions des utilisateurs",
    TEAM_LESSON_SUCCESS_RELEVANT:
      "As-tu trouvé cette formation TEAM pertinente?",
    TEAM_LESSON_SUCCESS_POSITIVELY:
      "Le contenu de la formation t’a-t-il inspiré à apporter des changements positifs dans ta vie?",
    TEAM_WAITING_FOR_VOTES:
      "En attente des réponses ... Utilise ton appareil pour répondre au sondage!",
    TEAM_TRAINING: "Formation TEAM",
    TEAM_TRAINING_DESCRIPTION: "Présentations interactives en groupe",
    TEAM_READY_TO_PRESENT: "Prêt à présenter?",
    TEAM_WHEN_YOU_START: "Lorsque tu commences la présentation, nous aviserons",
    TEAM_OF_THE: "de l'équipe",
    TEAM_TO_JOIN: "pour te rejoindre",
    TEAM_MORE_TO_EXPLORE: "Plus à explorer",
    TEAM_MORE_TO_EXPLORE_DESCRIPTION: "Découvrez ces autres présentations.",

    CORE: "De base",
    WORK: "Au travail",
    HOME: "À la maison",
    ENTER_YOUR_RESPONSE: "Enter your response",
    ENTER_YOUR_RESPONSE_ONE_AT_A_TIME: "Enter your response (one at a time)",
    ENTER_YOUR_RESPONSE_OPTIONAL: "Entre ta réponse (facultatif)",
    LEAVE_MODAL_ARE_YOU_SURE: "Tu es sûr?",
    LOGIN_PAGE_TITLE: "Prends de l'avance sur",
    LOGIN_PAGE_TITLE_ADVERSITY: "l'adversité.",
    LOGIN_PAGE_FIRST_STEP_INSTRUCTION_HAVE: "As-tu déjà un compte ?",
    LOGIN_PAGE_FIRST_STEP_INSTRUCTION_DONT: "Tu n'as pas de compte ?",
    LOGIN_PAGE_SIGN_IN: "Connexion",
    LOGIN_PAGE_SIGN_IN_WITH_EMAIL: "Sign In with magic link",
    LOGIN_PAGE_INVITE_CODE_HAVE: "J'ai un code d'invitation",
    LOGIN_PAGE_INVITE_CODE_DONT: "Je n'ai pas de code d'invitation",
    LOGIN_PAGE_SIGN_UP: "S'inscrire",
    LOGIN_PAGE_SEND_EMAIL: "Envoyer l'email",
    LOGIN_WELCOME_EMAIL: "As-tu reçu ton e-mail de bienvenue?",
    LOGIN_WELCOME_DESCRIPTION:
      "Vérifie que tu as reçu les instructions d'accès pour <em>headversity</em>, sinon entre ton e-mail professionnel et nous te l'enverrons à nouveau. Si tu ne le reçois toujours pas, contacte tu administrador de <em>headversity</em> ou envoie-nous un e-mail à <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    LOGIN_VALIDATION_DESCRIPTION:
      "Vérifie ton compte de courriel pour un message de <em>headversity</em> avec les instructions d'accès. Si tu ne reçois pas le courriel, contacte ton administrateur de <em>headversity</em> ou envoie-nous un courriel à <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    LOGIN_VALIDATION_PAGE_TITLE: "Renvoyer le courriel d'activation",
    LOGIN_VALIDATION_PAGE_DESCRIPTION:
      "Entre ton adresse courriel ci-dessous et nous te renverrons un courriel d'activation si nous avons l'adresse courriel enregistrée.",
    LOGIN_FORGOT_PASSWORD: "Mot de passe oublié ?",
    LOGIN_SAVE_PASSWORD: "Changer mon mot de passe",
    LOGIN_SET_PASSWORD: "Tu as un mot de passe temporaire, créé-en un nouveau.",
    LOGIN_GENERIC_ERROR:
      "Une erreur s'est produite lors de la tentative de connexion. Réessaye plus tard",
    LOGIN_REDIRECT_MOBILE:
      "Tu seras redirigé vers l'écran de connexion. Si tu vois toujours ce message, clique sur le bouton de connexion.",
    LOGIN_FORGOT_PASSWORD_DESCRIPTION:
      "Entre ton e-mail et tu recevras les instructions pour réinitialiser ton mot de passe.",
    SINGLE_SIGN_ON_ENABLED: "Authentification unique activée",
    EMAIL_IS_MANDATORY: "Le courriel est obligatoire",
    FULL_NAME_IS_MANDATORY: "Le nom complet est obligatoire",
    FULL_NAME_NEEDS_CONTAIN_FIRST_LAST_NAME:
      "Le nom complet doit contenir à la fois le prénom et le nom",
    PASSWORD_IS_MANDATORY: "Le mot de passe est obligatoire",
    INVITE_CODE_IS_MANDATORY: "Le code d'invitation est obligatoire",
    EMAIL_IS_INVALID: "Le courriel est invalide",
    EMAIL_HAS_PROBLEM:
      "Il y a un problème avec l'adresse e-mail que tu as saisie. Merci de contacter <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    EMAIL_DOMAIN_NOT_ALLOWED:
      "L'adresse courriel spécifiée n'est pas valide pour ce code d'invitation. Contacte ton administrateur headversity ou envoie-nous un courriel à <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",
    EMAIL_IS_USED_ALREADY: "Le courriel est déjà utilisé",
    PASSWORD_NEEDS_TO_RESPECT_FORMAT:
      "Le mot de passe doit contenir une lettre majuscule, une lettre minuscule et un chiffre, et comporter au moins 8 caractères.",
    PASSWORD_LENGTH_REQUIREMENT: "Au moins 8 caractères",
    PASSWORD_UPPERCASE_REQUIREMENT: "Au moins une lettre majuscule",
    PASSWORD_LOWERCASE_REQUIREMENT: "Au moins une lettre minuscule",
    PASSWORD_NUMBER_REQUIREMENT: "Au moins un chiffre",
    PASSWORD_MUST_MATCH: "Les mots de passe doivent correspondre",
    PASSWORD_REQUIREMENTS: "Exigences de mot de passe:",
    INVITE_CODE_IS_INVALID:
      "Le code d'invitation que tu as saisi n'est pas valide",
    EMAIL_CAN_ONLY_BE_USED_AS_SSO:
      "Cette adresse e-mail ne peut être utilisée que via une authentification unique. Essaye de <a style='text-decoration: underline;' href='/login'>te connecter</a> à la place.",
    DONT_HAVE_INVITE_CODE: "Tu n'as pas de code d'invitation ?",
    CONTACT_ADMINISTRATOR: "Contacte ton administrateur",
    BACK_TO_LOGIN_SIGNUP: "Retour à la page: Connexion / Inscription",
    LOGIN: "Connexion",
    SIGNUP: "Inscription ",
    PASSWORD_MISMATCH: "Les mots de passe ne correspondent pas",
    SIGNUP_ERROR:
      "Désolé, une erreur s'est produite. Contacte help@headversity.com avec ces détails d'erreur: ",
    PLEASE_CHOOSE_NEW_PASSWORD_AND_INVITE_CODE:
      "Choisis un nouveau mot de passe pour ton compte <em>headversity</em> et entre ton code d'invitation.",
    PLEASE_ENTER_INVITE_CODE: "Entre ton code d'invitation.",
    PLEASE_CHOOSE_PASSWORD:
      "Choisis un nouveau mot de passe pour ton compte <em>headversity</em>.",
    YOUR_INVITE_CODE: "code d'invitation",
    EMAIL_PLACEHOLDER: "email@site.com",
    YOUR_NEW_PASSWORD: "nouveau mot de passe",
    YOUR_PASSWORD: "mot de passe",
    YOUR_FULL_NAME: "nom complet",
    VERIFY_YOUR_PASSWORD: "vérifie ton mot de passe",
    REGISTER: "S'ENREGISTRER",
    LOG_OUT: "DÉCONNEXION",
    INVALID_INVITE_CODE: "Ton code d'invitation n'est pas valide!",
    PRIVACY_POLICY: "En t'inscrivant, tu acceptes notre ",
    DROPDOWN_PLACEHOLDER: "Choisis une option",
    ROI_LETS_PERSONALIZE: "Personnalisons ton parcours!",
    ROI_TO_PROVIDE:
      "Pour t’offrir le meilleur 'outil de résilience, adapté à tes besoins et objectifs, nous devrons te poser quelques questions. Tu n'as pas besoin d'avoir une connaissance antérieure en santé mentale pour répondre à ces questions.",
    ROI_THESE_ARE:
      "Elles ne sont utilisées que pour optimiser ton expérience <em>headversity</em>.",
    ROI_ALL_ANSWERS: "Toutes tes réponses sont ",
    ROI_COMPLETELY_CONFIDENTIAL: "complètement confidentielles ",
    ROI_AND_WILL: "et ",
    ROI_NOT_BE_SHARED: "ne seront pas partagées ",
    ROI_WITH_YOUR_EMPLOYER: "avec ton employeur en aucun moment.",
    ROI_ESTIMATED_TIME: "Durée estimée: ",
    ROI_OVER_PAST_TWO_WEEKS: "Au cours des deux dernières semaines ...",
    ROI_WORK_MISSED_DAYS:
      "Combien de journées de travail as-tu manqué en raison de maladies, blessures ou problèmes de santé mentale (stress, dépression, anxiété)?",
    ROI_SKIP_QUESTION: "Sauter cette question",
    ROI_WORK_SCHEDULED_DAYS:
      "Pour combien de journées as-tu planifié de travailler?",
    ROI_PERFORMANCE_AFFECTED:
      "Sur une échelle de 0 à 10, dans quelle mesure ta santé physique et mentale a-t-elle négativement affecté ta performance <strong>au travail</strong>?",
    ROI_NOT_AFFECTED: "Pas du tout affectée",
    ROI_EXTREMELY_AFFECTED: "Extrêmement affectée",
    ROI_TIME_TO_CHECKIN: "Il est temps de faire un suivi!",
    ROI_ITS_BEEN: "Il s'est écoulé ",
    ROI_SINCE_SIGNUP:
      "jours depuis que tu t’es inscrit à <em>headversity</em>.",
    ROI_ANY_CHANGES:
      "Nous allons examiner les derniers changements dans ta situation afin de pouvoir optimiser ton expérience <em>headversity</em>.",
    HEADER_WELCOME: "Bienvenue",
    PAGE_NOT_FOUND_TITLE: "Non trouvée",
    RESET_PASSWORD_EMAIL_SENT:
      "Vérifie ton e-mail! Tes instructions de réinitialisation de mot de passe ont été envoyées.",
    VALIDATION_EMAIL_SENT:
      "Vérifie compte de courriel! Ton courriel d'activation a été envoyé.",
    INVALID_OR_ALREADY_USED_CODE:
      "Oups, ce lien a expiré. Si tu as toujours besoin de réinitialiser ton mot de passe, tu peux demander un nouvel e-mail de réinitialisation <a style='text-decoration: underline;' href='/forgotPassword'>ici</a>.",
    INVALID_OR_ALREADY_USED_VALIDATION_CODE:
      "Oups, c'est un lien expiré. Tu peux demander un nouveau courriel d'activation <a style='text-decoration: underline;' href='/resend-activation'>ici</a>.",
    INVALID_OR_ALREADY_USED_VALIDATION_LOGIN_CODE:
      "Oups, ce lien a expiré. Cliquez <a style='text-decoration: underline;' href='/login'>ici</a> pour revenir à l'écran de connexion.",
    INVALID_SCORM_LOGIN_CODE:
      "Oups, il y a une discordance entre votre profil LMS et votre compte <i>headversity</i>. Veuillez contacter help@headversity.com pour obtenir de l'aide.",
    INVALID_GENERIC_LOGIN_CODE:
      "Oups, ce lien a expiré. Veuillez retourner sur votre LMS et demander un nouveau lien de connexion.",
    LOGIN_ERROR_INVALID_REQUEST_BODY:
      "Corps de demande invalide. Tous et uniquement client_id, credential_type, username, otp, realm sont requis.",
    LOGIN_ERROR_CROSS_ORIGIN_NOT_ALLOWED:
      "Connexion entre origines non autorisée.",
    LOGIN_ERROR_UNKNOWN_CREDENTIAL_TYPE_PARAMETER:
      "Paramètre de type de connection inconnu.",
    LOGIN_ERROR_UNKNOWN_REALM: "Realm inconnu connexion inexistante.",
    LOGIN_ERROR_UNKNOWN_EMAIL_OR_PASSWORD: "Mauvais e-mail ou mot de passe.",
    LOGIN_ERROR_TOO_MANY_ATTEMPTS:
      "Nous avons détecté une activité inhabituelle sur ton compte. Réessaie plus tard, ou contacte help@headversity.com pour obtenir de l'aide.",
    LOGIN_ERROR_AUTHENTICATION_ERROR: "Erreur d'authentification",
    LOGIN_ERROR_BLOCKED_USER: "Utilisateur bloqué.",
    LOGIN_ERROR_DATA_BREACH:
      "Cette tentative de connexion a été bloquée car le mot de passe que tu as utilisé a été précédemment divulgué via une fuite de données (pas dans cette application).",
    LOGIN_ERROR_MULTIPLE_ATTEMPTS:
      "Ton compte a été bloqué après plusieurs tentatives de connexion consécutives. Nous t'avons envoyé un e-mail avec des instructions sur la façon de le débloquer.",
    LOGIN_ERROR_SUSPICIOUS_BEHAVIOR:
      "Nous avons détecté un comportement de connexion suspect et de nouvelles tentatives seront bloquées. Contacte ton administrateur.",
    LOGIN_ERROR_UNKNOWN_ERROR: "Erreur inconnue de la part du fournisseur",
    PASSWORD_CANT_CHANGE:
      "Pour réinitialiser ton mot de passe, contacte ton administrateur informatique.",
    INVALID_PASSWORD: "Mot de passe incorrect",
    ACCOUNT_EXPIRED: "Désolé, ce compte n'est plus valide.",
    LOGIN_ERROR_RESET_PASSWORD:
      "Réinitialise ton mot de passe <a style='text-decoration: underline;' href='/forgotPassword'>ici</a>",
    UPDATING: "Il y a une nouvelle mise à jour! Téléchargement en cours ",
    FIRST_TIME_INTERSTITIAL_HEADER: "Découvre <b>les poursuites</b>",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM1:
      "Soutenir ta propre santé mentale devient plus simple! Que tu vises à augmenter ta confiance, gérer ton stress, approfondir tes liens ou bien plus encore, on est là pour t'aider à évoluer dans tous les aspects de ta vie.",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM2:
      "Concentre-toi sur ce qui compte le plus pour toi en choisissant une poursuite dès aujourd'hui!",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM_IMAGE:
      "https://cdn.headversity.com/app/interstitial/focuses-fr.png",

    FIRST_TIME_INTERSTITIAL_HEADER_CHALLENGE:
      "Fais le prochain pas dans ta quête!",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM1_CHALLENGE:
      "C'est maintenant plus facile que jamais de prendre de l'<i>avance</i> sur l'ad<i>versité</i>.<ul style='list-style:disc;margin-left:20px;margin-top:10px'><li>Suis tes progrès avec des <b>points d'expérience (XP)</b> en atteignant de nouveaux niveaux de résilience.</li><li>Fixe-toi un <b>défi</b> personnel pour monter de niveau chaque jour, semaine ou mois - c'est toi qui choisis.</li><li>Maintiens une <b>série</b> en relevant ton défi.</li></ul>",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM2_CHALLENGE:
      "Fixe-toi un défi et commence une série aujourd'hui! 🔥",
    FIRST_TIME_INTERSTITIAL_SINGLE_ITEM_IMAGE_CHALLENGE:
      "https://cdn.headversity.com/app/interstitial/interstitial-challenge-fr.png",

    FIRST_TIME_INTERSTITIAL_FIRST_HEADLINE: "",
    FIRST_TIME_INTERSTITIAL_FIRST_LIST_ITEM: "",
    FIRST_TIME_INTERSTITIAL_FIRST_IMAGE: "",
    FIRST_TIME_INTERSTITIAL_SECOND_HEADLINE: "",
    FIRST_TIME_INTERSTITIAL_SECOND_LIST_ITEM: "",
    FIRST_TIME_INTERSTITIAL_SECOND_IMAGE: "",
    FIRST_TIME_INTERSTITIAL_THIRD_LIST_ITEM:
      "Si vous avez des questions, contactez-nous à l'adresse suivante: <a style='text-decoration: underline;' href='mailto:help@headversity.com'>help@headversity.com</a>.",

    FIRST_TIME_INTERSTITIAL_PUSH_NOTIFICATIONS:
      "Pour t'aider à rester sur la bonne voie, nous allons maintenant te demander d'<b style='font-weight: 600'>activer les notifications</b> pour des rappels périodiques.",
    FIRST_TIME_INTERSTITIAL_PUSH_NOTIFICATIONS_TEAM:
      "Pour être informé quand ton équipe s'entraîne, nous allons maintenant te demander d'<b style='font-weight: 600'>activer les notifications</b>.",

    FIRST_TIME_TEAM_INTERSTITIAL_HEADER: "On s’entraîne en équipe?",
    FIRST_TIME_TEAM_INTERSTITIAL_FIRST_PARAGRAPH:
      "Avec <em>headversity</em> TEAM, <strong>entraîne-toi avec tes co-équipiers</strong> pour améliorer vos compétences en santé mentale et en résilience. Pas besoin d'être un expert de la santé mentale pour diriger une séance de formation avec TEAM. Nos ressources sélectionnées facilitent la tâche pour n'importe qui!",
    FIRST_TIME_TEAM_INTERSTITIAL_SECOND_PARAGRAPH:
      "Implique-toi avec ton équipe dans des",
    FIRST_TIME_TEAM_INTERSTITIAL_FIRST_LIST_ITEM:
      "Entraînements de 5 à 7 minutes",
    FIRST_TIME_TEAM_INTERSTITIAL_SECOND_LIST_ITEM:
      "Défis de groupe et des sondages interactifs",
    FIRST_TIME__TEAM_INTERSTITIAL_BOTTOM_PARAGRAPH:
      "Clique sur 'Aller sur TEAM' pour intégrer l'entraînement en santé mentale dans ton travail avec tes collègues.",
    FIRST_TIME_TEAM_INTERSTITIAL_GO_TO_TEAM: "Aller sur TEAM",
    DELETE_ACCOUNT_TITLE_1: "Nous sommes tristes de te voir partir",
    DELETE_ACCOUNT_TITLE_2: "Supprimer le compte",
    DELETE_ACCOUNT_MESSAGE:
      "Une fois ton compte supprimé, toutes tes informations et tes progrès seront perdus. Es-tu certain de vouloir supprimer ton compte?",
    DELETE_ACCOUNT_CONFIRM_MESSAGE:
      "<strong>Es-tu vraiment sûr ?</strong>Si tu es certain de vouloir supprimer ton compte, confirme ci-dessous. Cette action n'est pas réversible.",
    APP_VERSION_OUTDATED_ERROR:
      "On dirait que utilises une ancienne version de l’application. Accède à la boutique d’applications de ton appareil et fais une mise à jour maintenant.",
    ABOUT_TEAM_WELCOME: "Bienvenue sur <em>headversity</em> TEAM!",
    ABOUT_TEAM_FIRST_LINE:
      "Participe avec ton équipe à des entraînements <b>rapides</b> et <b>interactifs</b> et améliore ta santé mentale et ta résilience collective!",
    ABOUT_TEAM_SECOND_LINE:
      "Tout le monde peut animer une session - nul besoin d'être expert en santé mentale.",
    VIEW_HISTORY: "Voir l'historique",
    TERMS_PAGE: termsPolicy.fr.termsPage,
    PRIVACY_PAGE: termsPolicy.fr.privacyPolicyPage,
    UNAUTHORIZED: "Non autorisé",
    UNAUTHORIZED_DESCRIPTION:
      "Tu n'es pas autorisé à afficher ou accéder à cette page.",
    SHIELD_LEVEL: "NIVEAU",
    SHIELD_LAST_LEVELED_UP: "Dernière avancée",
    EARN_MODAL_INITIAL_MESSAGE:
      "En complétant cette activité, tu renforces cette compétence.",
    EARN_MODAL_INITIAL_MESSAGE_HEADLINE: "Bravo!",
    EARN_MODAL_INITIAL_MESSAGE_BODY:
      "En complétant des activités, tu gagneras des points de compétence. Continue à gagner des points et à monter de niveau, jusqu'au niveau 50!",
    EARN_MODAL_INITIAL_MESSAGE_CONTINUE_TO_SEE:
      "Continue pour voir tes statistiques de",
    EARN_MODAL_LEVEL_UP: "NIVEAU SUIVANT!",
    EARN_MODAL_YOUR_NEW_LEVEL: "TON NOUVEAU NIVEAU",
    EARN_TOAST_EARNED: "Tu as gagné",
    EARN_TOAST_ANOTHER_POINT: "un autre point",
    EARN_TOAST_MORE_POINTS: "points de plus",
    EARN_TOAST_KEEP_GOING: "Continue pour passer au niveau supérieur!",
    EARN_TO_LEVEL_UP: "Un point de plus pour passer au niveau suivant!",
    EARN_TO_LEVEL_UP_PLURAL:
      "{0} points de plus pour passer au niveau supérieur!",
    SPORT_TAGLINE: "Sport tagline.",
    SPORT_LAUNCH_CHARTER: "Launch Charter Program",
    CERT_TAGLINE: "Obtiens ta certification. Maintiens ta qualification.",
    CERT_PROGRAMS: "Programmes",
    CERT_PROGRAMS_SUBHEAD: "Explore et gère tes certifications.",
    CERT_WELCOME_SUBHEAD: "Bien plus qu'une simple exigence",
    CERT_WELCOME_TEXT:
      "Bienvenue sur CERT, ton compagnon sur le chemin de la réussite. CERT combine une certification engageante dès le départ avec une amélioration continue pour une progression personnelle constante.",
    CERTIFICATE_IS_PRESENTED_TO: "Cette certification est décernée à",
    CERTIFICATE_FOR_COMPLETING: "pour avoir complété le programme",
    CERTIFICATE_BY_ACHIEVING: "En obtenant cette certification, ",
    CERTIFICATE_AWARDED_ON: "Décernée le : ",
    CERTIFICATE_DOWNLOAD: "Télécharger",
    CERT_COMPLETE_DOWNLOAD_LINK: "Voir le certificat",
    CERT_COMPLETE_SHARE_LINKEDIN: "Partager sur LinkedIn",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_TITLE: "Célébrons ensemble sur LinkedIn ",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_PARAGRAPH_1:
      "Félicitations encore une fois pour avoir obtenu ta certification! Nous sommes fiers de toi, et nous savons que ton réseau le sera aussi.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_PARAGRAPH_2:
      "Voici comment partager ton achèvement sur LinkedIn:",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_1:
      " pour partager facilement sur LinkedIn.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_2:
      "Dans la section “Média”, clique sur “+ Ajouter un média”.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_3:
      "Clique sur l'icône CERT ci-dessous pour copier l'adresse URL.",
    CERT_LINKEDIN_SHARE_INSTRUCTIONS_STEP_4:
      "Colle l'URL de l'image CERT dans le champ “Ajouter un lien” et clique sur “Ajouter”.",
    CERT_LINKEDIN_INSTRUCTIONS:
      "<br/> Clique sur l'image pour copier le lien URL:",
    CERT_LINKEDIN_SHARE_IMAGE_FILENAME: "headversity_cert_fr.png",
    CERT_BADGE_COPIED_TO_CLIP_BOARD:
      "Le lien URL de l'image CERT a été copié dans ton presse-papiers.",
    CERT_MODAL_QUALIFY_HEADER: "C'est le moment de maintenir ta qualification!",
    CERT_MODAL_QUALIFY_SUB_TEXT: `Il est temps de poursuivre ton parcours de certification {0}! Clique sur le bouton "Maintiens ta qualification" ci-dessous pour compléter les activités et maintenir ta certification.`,
    CERT_MODAL_ALREADY_QUALIFIED_SUB_TEXT:
      "Tu as terminé les rappels requis pour cette période. Tu peux toujours compléter des activités supplémentaires pour poursuivre ton parcours de certification {0}.",
    CERT_STAY_QUALIFIED: "Maintiens ta qualification",
    REVIEW_QUALIFICATION: "Réviser la qualification",
    CERT_POINTS_TO_QUALIFY: "1 point de plus pour maintenir ta qualification!",
    CERT_POINTS_TO_QUALIFY_PLURAL:
      "{0} points de plus pour maintenir ta qualification!",
    CERT_QUALIFICATION_WAY_TO_GO: "Bravo! Tu as gagné ",
    CERT_QUALIFICATION_KEEP_GOING:
      "Continue ainsi pour maintenir ta qualification!",
    SELECT_A_VALUE: "Sélectionner une option.",
    COPIED_TO_CLIPBOARD: "Copié dans le presse-papiers",
    CLICK_HERE: "Clique ici",
    TOGGLE_NAVIGATION_MENU: "Basculer le menu de navigation",
    SKIP_TO_MAIN_CONTENT: "Passer au contenu principal",
    ALL_CATEGORIES: "Toutes les catégories",
    NEGATIVE_FEEDBACK_WHY:
      "Désolé d'apprendre ça 😔 Est-ce que tu pourrais nous en dire plus sur ce que tu n'as pas apprécié?",
    PROVIDE_FEEDBACK: "Merci de fournir ton retour ici.",
    NEXT_PAGE: "Page suivante",
    PREVIOUS_PAGE: "Page précédente",
    GO_TO_PAGE: "Aller à la page ",
    DAYS: "jours",
    REACH_INSIGHTS_INDICATED_A: "a indiqué un",
    REACH_INSIGHTS_AGREEMENT_VS: "accord contre",
    REACH_INSIGHTS_IN_OUR_REFERENCE_BENCHMARK:
      "dans notre benchmark de référence",
    REACH_INSIGHTS_INCREASE: "d’augmentation",
    REACH_INSIGHTS_DECREASE: "de diminution",
    REACH_INSIGHTS_IN_AGREEMENT_AFTER_COURSE: "de leur accord après le cours",
    REACH_INSIGHTS_ENROLLED_USERS: "Utilisateurs inscrits",
    REACH_INSIGHTS_OUT_OF: "sur",
    REACH_INSIGHTS_ELIGIBLE_USERS: "Utilisateurs éligibles",
    REACH_INSIGHTS_ACTIVE_USERS: "Utilisateurs actifs",
    REACH_INSIGHTS_ALL_TIME: "Tout le temps",
    REACH_INSIGHTS_LAST_180_DAYS: "Au cours des 180 derniers jours",
    REACH_INSIGHTS_LAST_12_MONTHS: "Au cours des 12 derniers mois",
    REACH_INSIGHTS_SELECTED_PURSUITS: "Poursuite sélectionnée",
    REACH_DOWNLOAD_REPORT: "Télécharger le rapport",
    REACH_INSIGHTS_USERS_DESCRIPTION:
      "Un aperçu de la portée totale et de l'accessibilité de la plate-forme, ainsi que du niveau de perfectionnement actif au sein de la base d'utilisateurs",
    REACH_INSIGHTS_PSYCHOGRAPHICS_TITLE: "Psychographie",
    REACH_INSIGHTS_PSYCHOGRAPHICS_DESCRIPTION:
      "Basé sur {0} réponses des utilisateurs au cours des 180 derniers jours",
    REACH_INSIGHTS_PSYCHOGRAPHICS_DESCRIPTION_ALL_TIME:
      "Basé sur {0} réponses d'utilisateurs de tous les temps",
    REACH_INSIGHTS_SOLO_AND_TEAM_TITLE: "SOLO and TEAM",
    REACH_INSIGHTS_SOLO_AND_TEAM_DESCRIPTION:
      "Présente les éléments de contenu les plus populaires en fonction de l'activité des utilisateurs, offrant ainsi une visibilité sur les sujets dans lesquels les utilisateurs améliorent le plus leurs compétences.",
    REACH_INSIGHTS_KEY_TAKEAWAYS_STRENGTH: "Domaines de ",
    REACH_INSIGHTS_KEY_TAKEAWAYS_IMPROVEMENT: "Aspects à",
    REACH_INSIGHTS_KEY_TAKEAWAYS_STRENGTHS_DESCRIPTION:
      "Les réponses dépassent les références du secteur dans ces domaines. Célèbre et sois fier de ces forces!",
    REACH_INSIGHTS_KEY_TAKEAWAYS_RISKS_DESCRIPTION:
      "Les réponses sont souvent en dessous des références du secteur dans ces domaines, soulignant des problèmes potentiels et des opportunités de concentration et de croissance.",
    REACH_INSIGHTS_IMPROVEMENT: "d'amélioration",
    REACH_INSIGHTS_STRENGTH: "force",
    REACH_INSIGHTS_STRONG: "fort",
    REACH_INSIGHTS_LESSON: "Leçon",
    REACH_INSIGHTS_PRACTICE: "Pratique",
    REACH_INSIGHTS_TEAM: "TEAM",
    REACH_INSIGHTS_VIEW_ALL_AREAS:
      "Voir tous les domaines de force et de risque",
    REACH_INSIGHTS_VIEW_ALL_CERTS: "Afficher toutes les informations CERT",
    REACH_INSIGHTS_GET_HELP_DESCRIPTION:
      "Les vues Obtenir de l'aide présentent les vues mensuelles des ressources d'aide, offrant aux utilisateurs un accès à des outils et ressources d'assistance critiques",
    REACH_INSIGHTS_HELP_RESOURCES_VIEWS_BY_MONTH:
      "Consultations des ressources d'aide par mois",
    REACH_INSIGHTS_UNIQUE_HELP_RESOURCE_CLICKS:
      "Clics uniques sur les ressources d'aide",
    REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES: "Répartition des activités",
    REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES_DESCRIPTION_ALL_TIME:
      "% du total des activités de tous les temps",
    REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES_DESCRIPTION:
      "% du total des activités au cours des 180 derniers jours",
    REACH_INSIGHTS_POPULAR_MICRO_LESSONS: "Leçons populaires",
    REACH_INSIGHTS_POPULAR_NANO_PRACTICES: "Pratiques populaires",
    REACH_INSIGHTS_POPULAR_TEAM_PRESENTATIONS: "Présentations TEAM populaires",
    REACH_INSIGHTS_ENROLMENT_TRENDS:
      "Tendances des inscriptions au fil du temps",
    REACH_INSIGHTS_ENROLMENT_TRENDS_LAST_180_DAYS:
      "Au cours des 180 derniers jours",
    REACH_INSIGHTS_TOTAL_ENROLLED_USERS: "Nombre total d'utilisateurs inscrits",
    REACH_INSIGHTS_CERT_TITLE: "CERT",
    REACH_INSIGHTS_CERT_DESCRIPTION:
      "Indique la progression et les réalisations globales de la certification.",
    REACH_INSIGHTS_BREAKDOWN_BY_CERT: "Répartition par CERT",
    REACH_INSIGHTS_ACTIVE_CERTIFICATIONS: "Certifications actives",
    REACH_INSIGHTS_ACTIVE_CERTIFICATION_RATES: "Taux de certification",
    REACH_INSIGHTS_TO_DATE: "À ce jour",
    REACH_INSIGHTS_ASSIGNED_CERTIFICATIONS: "Certifications attribuées",
    REACH_INSIGHTS_CERTIFICATION_ACTIVITY_OVER_TIME:
      "Activité de certification au fil du temps",
    REACH_INSIGHTS_USER_FEEDBACK: "Commentaires des utilisateurs",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_CERTIFICATIONS_TITLE:
      "Qu'est-ce qu'une certification active ?",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_CERTIFICATIONS_DESCRIPTION:
      "Certifications complétées et valides, hors en cours ou non commencées.",
    REACH_INSIGHTS_TOOLTIP_ENROLLED_USER_TITLE:
      "Qu'est-ce qu'un utilisateur inscrit ?",
    REACH_INSIGHTS_TOOLTIP_ENROLLED_USER_DESCRIPTION:
      "Utilisateurs éligibles qui ont configuré avec succès leurs comptes.",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_USER_TITLE:
      "Qu'est-ce qu'un utilisateur actif ?",
    REACH_INSIGHTS_TOOLTIP_ACTIVE_USER_DESCRIPTION:
      "Utilisateurs inscrits qui ont utilisé l'application.",
    REACH_INSIGHTS_CERTIFIED_USERS: "Utilisateurs certifiés",
    REACH_INSIGHTS_USERS_IN_PROGRESS: "Utilisateurs en cours",
    REACH_INSIGHTS_QUALIFIED_USERS: "Utilisateurs qualifiés",
    REACH_INSIGHTS_TOOLTIP_CERTIFIED_USERS_TITLE:
      "Que sont les utilisateurs certifiés ?",
    REACH_INSIGHTS_TOOLTIP_CERTIFIED_USERS_DESCRIPTION:
      "Utilisateurs ayant complété une certification.",
    REACH_INSIGHTS_TOOLTIP_CERT_USERS_IN_PROGRESS_TITLE:
      "Que sont les utilisateurs en cours ?",
    REACH_INSIGHTS_TOOLTIP_CERT_USERS_IN_PROGRESS_DESCRIPTION:
      "Utilisateurs ayant une certification en cours.",
    REACH_INSIGHTS_TOOLTIP_QUALIFIED_USERS_TITLE:
      "Que sont les utilisateurs qualifiés ?",
    REACH_INSIGHTS_TOOLTIP_QUALIFIED_USERS_DESCRIPTION:
      "Utilisateurs ayant obtenu une certification et ayant complété le niveau d’activité requis pour rester qualifiés dans une période.",
    REACH_INSIGHTS_BEFORE_AND_AFTER_TITLE: "Avant et après",
    REACH_INSIGHTS_RELEVANT_CONTENT_DESCRIPTION:
      "Utilisateurs qui ont trouvé le contenu pertinent",
    REACH_INSIGHTS_MOTIVATED_CONTENT_DESCRIPTION:
      "Utilisateurs motivés à agir positivement après avoir participé au contenu",
    REACH_INSIGHTS_MEANINGFUL_CONTENT_DESCRIPTION:
      "Les utilisateurs qui ont trouvé du sens dans leur quête",
    REACH_INSIGHTS_NO_CHANGE_AFTER_COMPLETING_COURSE:
      "Pas de changement après avoir terminé le cours.",
    REACH_INSIGHTS_LEGEND_AGREE: "Est d'accord",
    REACH_INSIGHTS_LEGEND_NEUTRAL: "Accord neutre",
    REACH_INSIGHTS_LEGEND_DISAGREE: "Est en désaccord",
    YOUR_PURSUIT: "Poursuite",
    YOUR_PURSUIT_WITH_COLON: "Poursuite :",
    TOUR_PURSUIT:
      "Concentre ton entraînement sur ce qui est le plus important pour toi.",
    TOUR_PURSUIT_BOX: "Continue à t'entraîner dans ta quête.",
    OK: "D'accord!",
    EXCLAMATION: "!",
    COLON: " :",
    PURSUIT_CHECKIN0:
      "Voici ta quête sélectionnée. Commence à t'entraîner dès aujourd'hui!",
    PURSUIT_CHECKIN1:
      "Tu as presque formé chaque compétence. Continue comme ça!",
    PURSUIT_CHECKIN2:
      "Tu as formé chaque compétence. Continue ou révise tes progrès!",
    PURSUIT_STARTING_POINT: "Voici ton point de départ suggéré.",
    PURSUIT_SELECT: "Sélectionne une poursuite",
    PURSUIT_BUILD_SKILLS: "Développe des compétences en :",
    PURSUIT_FOCUS: "Concentre-toi ton entraînement aujourd'hui!",
    PURSUIT_DIFFERENT: "Choisis une autre poursuite",
    PURSUIT_OWN: "Choisis la mienne à la place",
    PURSUIT_IN_THIS_PURSUIT: "dans cette poursuite",

    REFRESH_ACTIVITIES: "Rafraîchis les activités",
    REVIEW_PROGRESS: "Revoir les progrès",
    LETS_REVIEW_PROGRESS: "Révisons tes progrès en ",
    HERES_AN_OVERVIEW: "Voici un aperçu des <b>{0}</b> XP que tu as gagnés ",
    SO_FAR: "jusqu'à présent",
    OVER_LAST_30: "au cours des 30 derniers jours",
    VIEW_OVER_TIME: "Vue au fil du temps",
    VIEW_BY_SKILL: "Vue par compétence",
    VIEW_LAST_30: "Vue des 30 derniers jours",
    VIEW_ALLTIME: "Vue de tout le temps",
    CONCEPTS_LEARNED_AND_APPLIED:
      "Voici un résumé des concepts que tu as appris et appliqués.",
    PRACTICE_TECHNIQUES: "<b>Techniques de pratique</b> incluses :",
    LESSON_TOPICS: "<b>Sujets des leçons</b> inclus :",
    HOW_DID_YOU_FEEL_ABOUT_TRAINING: "Comment as-tu ressenti ton entraînement?",
    FOUND_PURSUIT_RELEVANT: "J'ai trouvé la recherche de {0} pertinente.",
    BETTER_UNDERSTAND: "Je comprends mieux comment {0}",
    PURSUE_NEXT: "Voyons ce que tu devrais poursuivre maintenant.",
    PURSUE_NEXT_HOW:
      "Pour ce faire, nous allons te poser quelques questions pour t'aider à identifier un nouveau domaine de concentration.",
    KEEP_CURRENT_PURSUIT: "Garde ma quête actuelle à la place",
    TRACKING: "Suivi",
    VIDEO: "Vidéo",
    REFLECTION: "Réflexion",
    WELCOME_BACK: "Bon retour",

    TIME_PICKER_SET: "Définir",
    TIME_PICKER_CANCEL: "Annuler",
    TIME_PICKER_NOW: "Maintenant",
    TIME_PICKER_SELECT_NOW: "Sélectionner maintenant",
    TIME_PICKER_TOGGLE_TIME_SELECTOR: "Basculer le sélecteur de l'heure",
    TIME_PICKER_TOGGLE_CLOCK: "Basculer l'horloge",
    HERES_A_QUICK_RECAP: "Voici un bref récapitulatif.",
    SUMMARY: "Résumé",
    RESPONSES: "Réponses",
    NEXT_STEP: "Prochaines étapes...",
    PAST_SUMMARY: "Regard en arrière...",
    YOU_FELT: "Tu as ressenti :",
    BECAUSE_OF: "En raison de :",
    SEE_DETAILS: "Voir les détails",
    HIDE_DETAILS: "Masquer les détails",
    OPEN_APP_BANNER_DESCRIPTION: "Ouvrez l'application via Google Play",
    OPEN: "Ouvrir",
    CLOSE_THIS_BROWSER:
      "Vous vous êtes connecté avec succès ! Vous pouvez maintenant fermer cette page et revenir à la page qui attendait votre connexion.",
    CHECK_EMAIL_TO_LOGIN:
      "Veuillez vérifier votre e-mail <b>{email}</b> pour un message contenant le lien de connexion. Cliquez sur le lien dans l'e-mail pour compléter le processus de connexion. Après avoir cliqué sur le lien, revenez à cette page pour continuer vos activités.",
    PASSWORD_LESS_LOGIN_MSG:
      "Entrez votre adresse e-mail ci-dessous, et nous vous enverrons un lien de connexion. Cliquez sur le lien dans votre e-mail pour accéder instantanément à votre compte. Pas besoin de se souvenir d'un mot de passe !",

    PURSUIT_CONTINUE: "Continue ta poursuite",
    PURSUIT_PICKUP: "Reprends là où tu t'es arrêté dans",
    FAVORITES: "Favoris",
    FOR_REFLECTION: "Pour réfléchir",
    STRESS_RELIEF: "Soulagement du stress",
    SOMETHING_NEW: "Quelque chose de nouveau",

    CONTINUE_TRAINING_FOR: "Continuons l'entraînement dans",
    START_TRAINING_FOR: "Commençons.",
    DO_NEXT: "Que veux-tu faire ensuite?",
    SIMPLE_PROVEN: "techniques simples et éprouvées",
    LEARN: "Apprendre",
    THE_SCIENCE: "la science derrière la résilience",
    TRAINING_INTRO:
      "Comme des étirements rapides pour ton esprit, les activités dans SOLO sont toutes courtes et efficaces. Suis tes pensées et tes émotions, pratique des techniques éprouvées, et explore la science qui se cache derrière tout ça.",
    LETS_COMPLETE: "Complète ta première activité dans {0} maintenant!",

    STREAK: "série",
    STREAK_CONTINUE: "Continue ta série de {0} {1}!",
    STREAK_GOING: "Tu es sur une série de {0} {1}!",

    STREAK_AT: "à",
    STREAK_DAILY_MESSAGE:
      "<b style='font-weight:600'>La pratique quotidienne</b> offre la progression la plus rapide et la formation d'habitudes la plus forte. Nous t'enverrons une notification push tous les jours si tu n'as pas pratiqué.",
    STREAK_WEEKLY_MESSAGE:
      "<b style='font-weight:600'>La pratique hebdomadaire</b> offre un excellent équilibre entre progrès et rythme. Nous t'enverrons une notification push chaque mercredi et vendredi si tu n'as pas pratiqué.",
    STREAK_MONTHLY_MESSAGE:
      "<b style='font-weight:600'>La pratique mensuelle</b> est idéale pour une croissance progressive facile à maintenir. Nous t'enverrons une notification push le 15 et 25 de chaque mois si tu n'as pas pratiqué.",
    STREAK_DAILY: "Tous les jours",
    STREAK_WEEKLY: "Chaque semaine",
    STREAK_MONTHLY: "Chaque mois",
    STREAK_DAY: "Jour",
    STREAK_DAY_PLURAL: "jours",
    STREAK_WEEK: "Semaine",
    STREAK_WEEK_PLURAL: "semaines",
    STREAK_MONTH: "Mois",
    STREAK_MONTH_PLURAL: "mois",
    STREAK_DAY_SINGLE_PHRASE: "jours",
    STREAK_WEEK_SINGLE_PHRASE: "semaines",
    STREAK_MONTH_SINGLE_PHRASE: "mois",
    STREAK_YEAR: "année",
    STREAK_WEEKS: ["Semaine 1", "Semaine 2", "Semaine 3", "Semaine 4"],
    STREAK_MONTHS: [
      "jan.",
      "fév.",
      "mars",
      "avr.",
      "mai",
      "juin",
      "juil.",
      "août",
      "sept.",
      "oct.",
      "nov.",
      "déc.",
    ],

    COMPLETE_ACTIVITY:
      "Complète une activité aujourd'hui pour maintenir ta série de",
    COMPLETE_ACTIVITY_STREAK_GOING: "",
    THIS_WEEK: "cette semaine",
    THIS_MONTH: "ce mois-ci",
    STREAK_KEEP_GOING: "Continue ta série!",
    YOUR_STREAK: "Ta série",
    START_A_STREAK: "Commence une série!",
    YOURE_ON_A_STREAK: "Tu es sur une série!",
    START_STREAK_DESCRIPTION:
      "Fixe un défi, termine une activité et commence une série aujourd'hui!",
    START_STREAK_WITH_CHALLENGE_DESCRIPTION:
      "Termine une activité et commence une série aujourd'hui!",
    COMPLETED_ACTIVITY: "Tu as complété une activité",
    IN_A_ROW: "d'affilée",
    CONTINUE_TRAINING_EACH:
      "Continue à t'entraîner chaque {0} pour maintenir ta série et",
    MEET_YOUR_CHALLENGE: "relever ton défi",
    KEEP_MOMENTUM: "maintenir l'élan",

    CHALLENGE_YOURSELF: "Défie-toi de continuer à",
    CONTINUE_LEVELING_UP: "monter de niveau",
    CHALLENGE_MESSAGE:
      "Gère le stress, adapte-toi au changement et surmonte l'adversité. En pratiquant régulièrement, tu amélioreras ton bien-être mental et émotionnel et tu seras plus alerte, heureux et en bonne santé.",
    PRACTICE_EVERY: "Pratique chaque",
    REMIND_ME: "Rappelle-moi",

    LEVEL_LEVELED_UP: "Félicitations, tu viens de monter de niveau!",
    LEVEL_ENCOURAGE: "Tu es sur la bonne voie!",
    LEVEL_FIRST_TIME:
      "En complétant des activités, tu gagneras des points d'expérience (XP) et atteindras de nouveaux niveaux de résilience. Continue, de <b>DÉBUTANT</b> à <b>CHAMPION</b>!",
    LEVEL_NOW_AT: "Tu es maintenant au",
    LEVEL_KEEP_GOING: "Continue, jusqu'à <b>CHAMPION</b>.",
    LEVEL: "Niveau",
    LEVEL_TO_GO_PRE: "Plus que<br />",
    LEVEL_TO_GO_POST: "",
    LEVEL_NAMES: [
      "DÉBUTANT",
      "APPRENTI",
      "EXPLORATEUR",
      "CONSTRUCTEUR",
      "RÉALISATEUR",
      "NAVIGATEUR",
      "GUIDE",
      "MENTOR",
      "CATALYSEUR",
      "CHAMPION",
    ],
    LEVEL_DESCRIPTIONS: [
      "Tu as fait le premier pas pour gérer les défis et poser une base solide pour ta croissance future.",
      "Tu t'améliores à équilibrer la vie à la maison et au travail tout en commençant à utiliser des techniques de résilience de base.",
      "Tu peux gérer de plus grands stress et crées une routine qui soutient ta santé mentale et ta résilience.",
      "Tu utilises constamment des techniques de résilience dans divers domaines de ta vie et tu remarques des améliorations dans la gestion du stress.",
      "Tu montres un haut niveau de résilience, équilibrant plusieurs domaines de ta vie tout en maintenant ton bien-être.",
      "Tu navigues bien à travers des défis complexes, montrant une forte résilience qui améliore ta productivité et tes relations.",
      "Tu gères l'adversité avec aisance et tes pairs te recherchent souvent pour des conseils et du soutien.",
      "Tu montres des compétences avancées en résilience, mentorant fréquemment les autres et contribuant à une communauté résiliente.",
      "Tu prospères constamment dans des situations de stress élevé, dirigeant des initiatives de résilience et de bien-être à plus grande échelle.",
      "Ta résilience est une seconde nature. Tu inspires et guides des communautés entières vers une meilleure santé mentale et résilience.",
      "Ton dévouement, tes compétences et ta persévérance continuent d'inspirer! Continue de repousser tes limites et d'atteindre la grandeur.",
    ],
    XP_EARNED: "XP gagnés",
    TAKE_A_MINUTE: "Prends une minute pour réfléchir à tes réponses.",
    CHARTER_BEST_VOTE_TITLE_THE: "The",
    CHARTER_BEST_VOTE_TITLE_BEST: "BEST",
    CHARTER_BEST_VOTE_TITLE_WORST: "WORST",
    CHARTER_BEST_VOTE_DESCRIPTION:
      "The things your team thinks are the most important for a team to be successful.",
    CHARTER_WORST_VOTE_DESCRIPTION:
      "The things your team thinks are the most harmful for a team.",
    YOUR_GROUP_SAID: "Your group said:",
    WHAT_ARE_SOME_OF_THE: "What are some of the",
    CHARACTERISTICS_THAT_MAKE_A_TEAM_SUCCESSFUL:
      "characteristics that make a team successful?",
    CHARACTERISTICS_THAT_CAN_HARM_A_TEAM:
      "characteristics that can harm a team and their success?",
    TEAM_POLL_WAITING_FOR_GROUP_RESULTS:
      "Waiting for all groups to submit their answers.",
    TEAM_POLL_REPORTER_DID_NOT_RESPOND:
      "Your group did not provide any answers to choose from. Waiting for other groups to submit their answers.",
    TEAM_POLL_WAITING_FOR_USER_RESULTS:
      "Waiting for everyone to submit their answers.",
    POLL_MULTI_INPUT_SUBMIT_AND_ADD_ANOTHER: "Submit and Add Another",
    POLL_MULTI_INPUT_IM_DONE: "I'm Done",
    POLL_MULTI_INPUT_WAITING: "Waiting for your response",
    FOUNDATIONAL_TRAINING: "Formation de base",
    HOWD_IT_GO: "Comment ça s'est passé?",
    LET_US_KNOW:
      "Faites-nous savoir comment vous avez ressenti cette expérience.",
    SPORT_RESPONSES: "Réponses:",
  },
};

export const HVLocalizeStrings = new LocalizedStrings(HVLocalizeStringsObj);
