import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HierarchyAssociationStatusDto } from "@headversity/contract";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  associateUserToNodes,
  getHierarchy,
  getUserNodes,
} from "../../../Api/Reach/ReachApi";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { TreeViewDataItem } from "../../../State/Reach/ReachHierarchyContext";
import {
  HVDefaultTheme,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../Utils/Analytics";
import { getKey } from "../../../Utils/Helpers";
import { AnimatedButton } from "../../Common/AnimatedButton";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { HvSpinner } from "../../Common/HvSpinner";
import { LargeModal } from "../../Common/LargeModal";
import {
  mapDataToKendoTreeView,
  preorderSearchArrayById,
  unionIndexes,
} from "../../Reach/SelfService/Hierarchy/HierarchyHelpers";
import { HierarchySelector } from "../../Reach/SelfService/Hierarchy/HierarchySelector";
import { NodeType } from "./NodeType";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  hierarchyId: number;
  isChangeHierarchy: boolean;
  displayAsModal?: boolean;
}

export default function HierarchyModal({
  open,
  setOpen,
  hierarchyId,
  isChangeHierarchy,
  displayAsModal = false,
}: Props) {
  useEffect(() => {
    // fetch hierarchy when the component first loads (or when the modal closes so that the data is reset for the next time the user opens it)
    if (hierarchyId || !open) {
      handleFetchHierarchy();
    }
  }, [hierarchyId, open]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [expandedItems, setExpandedItems] = useState<string[]>(["0"]);
  const [hierarchyTree, setHierarchyTree] = useState<TreeViewDataItem[]>([]);
  const [maxNumSelections, setMaxNumSelections] = useState<number>(3);

  const { showToast } = useContext<IShellProvider>(ShellContext);

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: false,
    md: false,
    lg: false,
  });

  const handleFetchHierarchy = useCallback(async () => {
    let mappedHierarchyTree: TreeViewDataItem[];

    const hierarchyResponse = await getHierarchy(
      getKey(),
      hierarchyId,
      "Hierarchy"
    );
    const userAssociationsResp = await getUserNodes(getKey());
    const associations =
      userAssociationsResp.data as HierarchyAssociationStatusDto;

    setMaxNumSelections(associations.options.maxSelection);

    mappedHierarchyTree = mapDataToKendoTreeView(
      hierarchyResponse.data.result!,
      userAssociationsResp.data.options.canAssociateRoot
    );
    let tempCheckedItems: string[] = [];
   
    associations.relations!.forEach((un) => {
      const checkedItemsForNode = preorderSearchArrayById(
        mappedHierarchyTree!,
        NodeType.Node,
        un.nodeId
      );
      if (checkedItemsForNode.length > 0) {
        tempCheckedItems.push(...checkedItemsForNode);
      }
    });

    const checkedIds = associations.relations!.map(un => un.nodeId)
    setCheckedItems(checkedIds);

    const unionExpanded = unionIndexes(expandedItems, tempCheckedItems);
    setExpandedItems(unionExpanded);
    setHierarchyTree(mappedHierarchyTree);
  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    track(EVENTS.HierarchyUserAssociationSaved);
    if (checkedItems.length > 0 && hierarchyTree) {
      await associateUserToNodes(getKey(), checkedItems);
      showToast(
        true,
        "hierarchy-association-saved",
        HVLocalizeStrings.PROFILE_ASSOCIATION_SAVED
      );
      await handleFetchHierarchy();
    }

    setIsSaving(false);
    setOpen(false);
  };

  const isLoading = !(hierarchyTree && hierarchyTree.length > 0);

  const content = (
    <Flex
      w={"100%"}
      flexDir={"column"}
      px={displayAsModal ? (isMobile ? 2 : 16) : 0}
      pb={displayAsModal ? 12 : 0}
      pt={0}
    >
      <Flex
        flexDir={"column"}
        alignItems={"center"}
        gap={"10px"}
        color={
          displayAsModal
            ? MODAL_PRIMARY_TEXT_COLOR
            : HVDefaultTheme.colors[MODAL_PRIMARY_TEXT_COLOR]
        }
      >
        <Heading fontSize={"23px"} fontWeight={"600"}>
          {isChangeHierarchy
            ? HVLocalizeStrings.PROFILE_CHANGE_HIERARCHY
            : HVLocalizeStrings.PROFILE_ASSOCIATION}
        </Heading>
        <Text fontSize={"16px"}>
          {HVLocalizeStrings.PROFILE_ASSOCIATION_INSTRUCTION}
        </Text>
      </Flex>
      <Flex flexDir={"column"} mt={"20px"} gap={"10px"} align={"center"}>
        <Box maxW={"100%"} w={"100%"} mb={2}>
          {!isLoading && !isSaving ? (
            <FadeAfterDelay>
              <HierarchySelector
                tree={hierarchyTree}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                expandedItems={expandedItems}
                displayAsModal={displayAsModal}
                maxNumSelections={maxNumSelections}
              />
            </FadeAfterDelay>
          ) : (
            <Center>
              <HvSpinner></HvSpinner>
            </Center>
          )}
        </Box>
        {displayAsModal ? (
          <BottomActionBar
            isNextButtonDisabled={() => {
              return isSaving || checkedItems.length === 0;
            }}
            nextButtonText={HVLocalizeStrings.SAVE}
            onNextButtonClick={handleSave}
          />
        ) : (
          <AnimatedButton
            disabled={isSaving || checkedItems.length === 0}
            text={HVLocalizeStrings.SAVE}
            onClick={handleSave}
          />
        )}
      </Flex>
    </Flex>
  );

  return displayAsModal ? (
    <LargeModal
      open={open}
      setOpen={setOpen as any}
      bgColor={"MODAL_BACKGROUND_COLOR"}
    >
      {content}
    </LargeModal>
  ) : (
    content
  );
}
