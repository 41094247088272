import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HelpResourceDto, LocalizationText } from "@headversity/contract";
import { useContext } from "react";
import { FiArrowDown, FiArrowUp, FiEdit, FiXCircle } from "react-icons/fi";
import { languages } from "../../../Hooks/Solo/useProfile";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { ReachHelpResourcesContext } from "../../../State/Reach/ReachHelpResourcesContext";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import {
  DESTRUCTIVE_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import { FaRegImage } from "react-icons/fa";
import { HVTestId } from "../../../Testing/dataTestIds";

interface ReachHelpResourceItemProps {
  resource: HelpResourceDto;
}

const ReachHelpResourceItem = ({ resource }: ReachHelpResourceItemProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  const mobileLayout = !isMobile;

  const getDefinedLocalization = (loc: LocalizationText): string => {
    // return en if exists
    if (loc.en) {
      return loc.en;
    }
    return HVLocalizeStrings.REACH_HELP_RESOURCE;
  };

  const localizationTextHasValue = (loc: LocalizationText): boolean => {
    return loc && Object.values(loc).length > 0;
  };
  const getResourceInfoString = (resource: HelpResourceDto) => {
    const keys = [
      HVLocalizeStrings.REACH_HELP_RESOURCES_PHONE,
      HVLocalizeStrings.REACH_HELP_RESOURCES_TEXT,
      HVLocalizeStrings.REACH_HELP_RESOURCES_IOS,
      HVLocalizeStrings.REACH_HELP_RESOURCES_ANDROID,
      HVLocalizeStrings.REACH_HELP_RESOURCES_WEB,
    ];
    const infoDataMap = new Map([
      [
        HVLocalizeStrings.REACH_HELP_RESOURCES_PHONE,
        localizationTextHasValue(resource.phoneNumber as LocalizationText),
      ],
      [
        HVLocalizeStrings.REACH_HELP_RESOURCES_TEXT,
        localizationTextHasValue(resource.textNumber as LocalizationText),
      ],
      [
        HVLocalizeStrings.REACH_HELP_RESOURCES_IOS,
        localizationTextHasValue(resource.iosAppUrl as LocalizationText),
      ],
      [
        HVLocalizeStrings.REACH_HELP_RESOURCES_ANDROID,
        localizationTextHasValue(resource.androidAppUrl as LocalizationText),
      ],
      [
        HVLocalizeStrings.REACH_HELP_RESOURCES_WEB,
        localizationTextHasValue(resource.websiteUrl as LocalizationText),
      ],
    ]);

    let resultStr = "";
    for (let key of keys) {
      const val = infoDataMap.get(key);
      if (val) {
        resultStr += resultStr.length === 0 ? key : `, ${key}`;
      }
    }
    return resultStr;
  };

  const getResourceName = (resource: HelpResourceDto): string => {
    if ((resource.name as LocalizationText).en) {
      return (resource.name as LocalizationText).en as string;
    }
    return HVLocalizeStrings.REACH_HELP_RESOURCE;
  };

  const getImageUrl = (resource: HelpResourceDto): string | undefined => {
    for (const lang of languages) {
      const localizedUrl =
        resource.logoUrl[lang as keyof typeof resource.logoUrl];
      if (localizedUrl) {
        return localizedUrl;
      }
    }

    return undefined;
  };

  const { confirmationDialogRef } = useContext<IShellProvider>(ShellContext);
  const orgContext = useContext(ReachHelpResourcesContext);

  const imageUrl = getImageUrl(resource);
  return (
    <Box
      maxW={mobileLayout ? undefined : "358px"}
      maxH={mobileLayout ? undefined : "90px"}
      width={"100%"}
      boxShadow="lg"
      rounded="md"
      m="1"
      p="4"
      data-testid={`${HVTestId.ReachHelpResourceItem.item}${resource.id}`}
    >
      <Flex justifyContent="flex-start" width={"100%"}>
        {mobileLayout && (
          <Box
            boxShadow="lg"
            rounded="md"
            m="4"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="135px"
            height="90px"
          >
            {imageUrl ? (
              <Image maxW={"60px"} maxH={"60px"} src={imageUrl} alt="" />
            ) : (
              <Icon as={FaRegImage} boxSize={10} />
            )}
          </Box>
        )}

        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
        >
          <Flex flexDirection="column">
            <Text fontWeight="semibold" noOfLines={2}>
              {getDefinedLocalization(resource.name as LocalizationText)}
            </Text>
            <Text noOfLines={1}>{getResourceInfoString(resource)}</Text>
          </Flex>
          <Spacer />
          <Flex>
            <Flex gap={1} fontSize={"sm"} justifyContent={"end"}>
              <IconButton
                aria-label="Edit help resource"
                bg={"none"}
                sx={{ _hover: {} }}
                icon={<FiEdit />}
                color={PRIMARY_TEXT_COLOR}
                onClick={() => {
                  orgContext.setSelectedHelpResource(resource);
                  orgContext.setAddHelpResourceDialogOpen(true);
                  track(REACH_EVENTS.ReachHelpResOpenEditHelpResourceForm);
                }}
              />
              <IconButton
                aria-label="Sort order up"
                bg={"none"}
                sx={{ _hover: {} }}
                icon={<FiArrowUp />}
                color={PRIMARY_TEXT_COLOR}
                disabled={
                  !orgContext.resourceCanUpdateSortOrder(resource.id, "up")
                }
                onClick={() => {
                  orgContext.updateSortOrder(resource.id, "up");
                  track(REACH_EVENTS.ReachHelpResMovedUpHelpResource);
                }}
              />
              <IconButton
                aria-label="Sort order down"
                bg={"none"}
                sx={{ _hover: {} }}
                icon={<FiArrowDown />}
                color={PRIMARY_TEXT_COLOR}
                disabled={
                  !orgContext.resourceCanUpdateSortOrder(resource.id, "down")
                }
                onClick={() => {
                  orgContext.updateSortOrder(resource.id, "down");
                  track(REACH_EVENTS.ReachHelpResMovedDownHelpResource);
                }}
              />
              <IconButton
                aria-label="Delete help resource"
                bg={"none"}
                sx={{ _hover: {} }}
                icon={<FiXCircle />}
                color={DESTRUCTIVE_TEXT_COLOR}
                onClick={() => {
                  (confirmationDialogRef.current as any).confirmation(
                    HVLocalizeStrings.REACH_HELP_RESOURCE_DELETE,
                    `${HVLocalizeStrings.REACH_USER_DELETE_DESCRIPTION.replace(
                      "{0}",
                      getResourceName(resource)
                    )}`,
                    ConfirmationButtons.YesNo,
                    () => {
                      orgContext
                        .deleteHelpResource(resource.id)
                        .then((_) => orgContext.refreshHelpResources());
                      track(REACH_EVENTS.ReachHelpResDeletedHelpResource);
                    }
                  );
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ReachHelpResourceItem;
