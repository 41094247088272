import { useContext, useEffect, useState } from "react";
import {
  Button,
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AiOutlineClockCircle } from "react-icons/ai";
import {
  MdSettingsApplications,
  MdMouse,
  MdDownloadForOffline,
} from "react-icons/md";
import { HiChartSquareBar } from "react-icons/hi";
import { IoPeople } from "react-icons/io5";
import {
  Border_Radius,
  Burnt_Orange,
  LESSON_COMPLETED_TEXT,
  MODAL_BACKGROUND_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
  MODAL_THIRD_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import dayjs from "dayjs";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  TEAM_LESSON_INITIALIZE_STEP,
  PresentationStepProps,
} from "./PresentationModal";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  getLastPresentedByTimeSpan,
  getPathTeamLessonByTeamId,
  getScrubbedTeamLessonDescription,
  isAnotherTeamLessonInProgressForTeam,
  isPresentationExpired,
  isTeamLessonDoneOrCatchup,
  trackTeamLesson,
} from "../../../Utils/TeamUtil";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import {
  PARTICIPANT_CATCH_UP_STEP,
  PARTICIPANT_END_STEP,
  ParticipantModal,
} from "../Participant/ParticipantModal";

import { PollResultModal } from "../Polls/PollResultModal";
import { TEAM_EVENTS } from "../../../Utils/Analytics";
import { HVTestId } from "../../../Testing/dataTestIds";
import { TeamLessonUserInstanceStatus } from "../../../Types/HVTypes";
import {
  PathTeamLessonDto,
  PollQuestionUserInstanceDto,
} from "@headversity/contract";
import { HvSpinner } from "../../Common/HvSpinner";

interface PresentationIntroStepProps extends PresentationStepProps {
  pathTeamLessonId?: number;
  closeModal: () => void;
  setOpacity: (opacity: string | undefined) => void;
}

export const PresentationIntroStep = (props: PresentationIntroStepProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const {
    selectedUserLanguage,
    isCatchupDone,
    setIsCatchupDone,
    setCurrentPathTeamLessonPreview,
    setPreviewModalOpen,
    updateParticipantTeamLesson,
    startPresentOrParticipate,
    sportEnabled,
    getPollInstancesFromServer,
    getPathsFromServer,
  } = useContext<IGlobalProvider>(GlobalContext);
  const {
    teamLesson,
    setCurrentStep,
    pathTeamLessonId,
    closeModal,
    setOpacity,
  } = props;
  const [loadedLatestPresentationData, setLoadedLatestPresentationData] =
    useState<boolean>(false);
  const [presentButtonText, setPresentButtonText] = useState<string | null>(
    null
  );
  const [isAnotherTeamLessonInProgress, setIsAnotherTeamLessonInProgress] =
    useState<boolean>(false);
  const [catchupOpen, setCatchupOpen] = useState<boolean>(false);
  const [pathTeamLesson, setPathTeamLesson] = useState<
    PathTeamLessonDto | undefined
  >();
  const [pollResultOpen, setPollResultOpen] = useState<boolean>(false);
  const [currentPollInstances, setCurrentPollInstances] = useState<
    PollQuestionUserInstanceDto[] | null
  >(null);
  const [isPresentButtonDisabled, setIsPresentButtonDisabled] =
    useState<boolean>(false);
  const [presentButtonMode, setPresentButtonMode] = useState<string | null>(
    null
  );
  const [teamLessonExpired, setTeamLessonExpired] = useState<boolean>(false);

  const [scrubbedDescription, setScrubbedDescription] = useState<string>("");

  const {
    setTeamLessonUserInstanceInitializeToServer,
    paths,
    currentTeam,
    self,
    setCurrentTeamLessonUserInstanceId,
    setParticipateForTeamLessonUserInstanceToServer,
    setParticipantModalOpen,
    pollInstances,
  } = useContext<IGlobalProvider>(GlobalContext);
  const [presentedDateString, setPresentedDateString] = useState<string>(
    HVLocalizeStrings.TEAM_NOT_YET_PRESENTED
  );

  const { confirmationDialogRef, safeAreaInsets } =
    useContext<IShellProvider>(ShellContext);

  const translatedImage = teamLesson?.imageUrl;

  const onViewPollClick = () => {
    trackTeamLesson(TEAM_EVENTS.ViewPollResults, currentTeam, pathTeamLesson);
    setCurrentPollInstances(getPollInstances(pathTeamLesson?.teamLessonId));
    setOpacity("0 !important");
    setPollResultOpen(true);
  };

  const invokeNextStep = () => {
    if (
      pathTeamLesson?.teamLessonInstanceStatus ===
        TeamLessonUserInstanceStatus.Initialized ||
      pathTeamLesson?.teamLessonInstanceStatus ===
        TeamLessonUserInstanceStatus.InProgress
    ) {
      const differenceInMinutes = getLastPresentedByTimeSpan(pathTeamLesson);
      if (
        !isPresentationExpired(differenceInMinutes, pathTeamLesson?.teamLesson)
      ) {
        if (pathTeamLesson?.presenterUserId === self?.id) {
          startPresentOrParticipate(
            pathTeamLesson,
            confirmationDialogRef,
            setCurrentStep,
            closeModal,
            sportEnabled ? true : undefined
          );
        } else {
          startParticipation(pathTeamLesson);
        }
        return;
      }
    }

    confirmationDialogRef.current.confirmation(
      HVLocalizeStrings.TEAM_READY_TO_PRESENT,
      `${HVLocalizeStrings.TEAM_WHEN_YOU_START} <b>${
        currentTeam?.teamUsers?.length
      }</b> ${
        currentTeam?.teamUsers?.length === 1
          ? HVLocalizeStrings.MEMBER.toLowerCase()
          : HVLocalizeStrings.MEMBERS.toLowerCase()
      } ${HVLocalizeStrings.TEAM_OF_THE} <b>${currentTeam?.name}</b> ${
        HVLocalizeStrings.TEAM_TO_JOIN
      }.`,
      ConfirmationButtons.Customs,
      (button: string) => {
        if (button === HVLocalizeStrings.CONTINUE) {
          setTeamLessonUserInstanceInitializeToServer(
            pathTeamLessonId as number,
            currentTeam?.id as number
          ).then(() => {
            setCurrentStep(TEAM_LESSON_INITIALIZE_STEP);
          });
        } else {
          setIsPresentButtonDisabled(false);
        }
      },
      [HVLocalizeStrings.CONTINUE, HVLocalizeStrings.CANCEL]
    );
  };

  const startParticipation = (pathTeamLesson: PathTeamLessonDto) => {
    setCurrentTeamLessonUserInstanceId(pathTeamLesson.teamLessonUserInstanceId);
    setTimeout(() => {
      trackTeamLesson(
        TEAM_EVENTS.JoinPresentation,
        currentTeam,
        pathTeamLesson
      );
      setParticipateForTeamLessonUserInstanceToServer(
        pathTeamLesson.teamLessonUserInstanceId as number
      ).then(() => {
        setParticipantModalOpen(true);
        updateParticipantTeamLesson(pathTeamLesson);
        closeModal();
      });
    }, 300);
  };

  const getPollInstances = (teamLessonId?: number) => {
    if (teamLessonId && pollInstances && currentTeam) {
      const result = pollInstances.filter(
        (pollInstance) =>
          pollInstance?.teamLessonUserInstance?.teamLessonId === teamLessonId &&
          pollInstance?.teamLessonUserInstance?.teamId === currentTeam.id &&
          pollInstance?.questionUserResponses &&
          pollInstance?.questionUserResponses?.length > 0
      );
      return result.length > 0 ? result : [];
    }
    return [];
  };

  const getPollCount = (pathId: number, teamLessonId: number) => {
    const result = getPollInstances(teamLessonId);
    return result.length;
  };

  const getPollCountText = (teamLessonId?: number) => {
    const result = getPollInstances(teamLessonId);
    const pollCompleteText =
      result.length > 1 || result.length === 0
        ? HVLocalizeStrings.TEAM_POLLS_COMPLETED
        : HVLocalizeStrings.TEAM_POLL_COMPLETE;
    return (
      <>
        <Icon
          as={HiChartSquareBar}
          boxSize={"6"}
          color={MODAL_THIRD_TEXT_COLOR}
        />
        <Text
          ml={1}
          fontSize={"sm"}
          fontWeight={"bold"}
          color={MODAL_THIRD_TEXT_COLOR}
        >
          &nbsp;
          {result.length}
        </Text>
        <Text fontSize={"sm"}>
          &nbsp;
          {pollCompleteText}
        </Text>
      </>
    );
  };

  const onDownloadTeamLessonClick = () => {
    trackTeamLesson(
      TEAM_EVENTS.DownloadForOfflineUse,
      currentTeam,
      pathTeamLesson
    );
    if (teamLesson?.offlinePdfUrl) {
      const link = document.createElement("a");
      link.href = teamLesson.offlinePdfUrl as string;
      window.open(link.href, "_blank");
    }
  };

  useEffect(() => {
    setScrubbedDescription(
      getScrubbedTeamLessonDescription(selectedUserLanguage, teamLesson)
    );
  }, [teamLesson, selectedUserLanguage]);

  useEffect(() => {
    getPathsFromServer().then(() => {
      getPollInstancesFromServer().then(() => {
        setLoadedLatestPresentationData(true);
      });
    });
  }, []);

  const description = (
    <Flex flexDir={"column"} gap={4}>
      <Text
        fontSize={["lg", "2xl"]}
        lineHeight={"1.3"}
        fontWeight={"semibold"}
        color={MODAL_PRIMARY_TEXT_COLOR}
        alignSelf={"flex-start"}
        data-testid={HVTestId.PresentationIntroStep.presTitle}
      >
        {teamLesson?.name as string}
      </Text>
      <Box
        color={MODAL_PRIMARY_TEXT_COLOR}
        lineHeight={"1.3"}
        alignSelf={"flex-start"}
        dangerouslySetInnerHTML={{
          __html: scrubbedDescription,
        }}
      ></Box>
      <Flex flexDir={"column"} gap={3} mt={4}>
        <Flex
          gap={2}
          alignItems={"center"}
          color={MODAL_PRIMARY_TEXT_COLOR}
          data-testid={HVTestId.PresentationIntroStep.duration}
        >
          <Icon as={AiOutlineClockCircle} w={"17px"} h={"17px"} />
          <Text fontSize={"sm"}>{`${teamLesson?.duration} ${
            teamLesson?.duration != 1
              ? HVLocalizeStrings.TEAM_TEAM_LESSON_MINS
              : HVLocalizeStrings.TEAM_TEAM_LESSON_MIN
          }`}</Text>
        </Flex>
        {!!teamLesson?.individualTrainingIncluded && (
          <Flex
            gap={2}
            alignItems={"center"}
            color={MODAL_PRIMARY_TEXT_COLOR}
            data-testid={HVTestId.PresentationIntroStep.training}
          >
            <Icon as={MdSettingsApplications} w={"17px"} h={"17px"} />
            <Text fontSize={"sm"}>
              {HVLocalizeStrings.TEAM_TEAM_LESSON_INDICATOR_TRAINING_INCLUDED}
            </Text>
          </Flex>
        )}
        {!!teamLesson?.groupActivityIncluded && (
          <Flex
            gap={2}
            alignItems={"center"}
            color={MODAL_PRIMARY_TEXT_COLOR}
            data-testid={HVTestId.PresentationIntroStep.interactive}
          >
            <Icon as={MdMouse} w={"17px"} h={"17px"} />
            <Text fontSize={"sm"}>
              {
                HVLocalizeStrings.TEAM_TEAM_LESSON_INDICATOR_GROUP_ACTIVITY_INCLUDED
              }
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex flexDir={"column"} mt={4}>
        {isTeamLessonDoneOrCatchup(pathTeamLesson) && (
          <Flex flexDir={"column"} gap={"3"} mb={isMobile ? "8" : undefined}>
            <Text
              fontSize={"sm"}
              color={LESSON_COMPLETED_TEXT}
              dangerouslySetInnerHTML={{ __html: `${presentedDateString}` }}
              data-testid={HVTestId.PresentationIntroStep.completedText}
            />
            {loadedLatestPresentationData ? (
              <>
                <Flex
                  align={"center"}
                  color={MODAL_PRIMARY_TEXT_COLOR}
                  data-testid={HVTestId.PresentationIntroStep.pollText}
                >
                  {getPollCountText(pathTeamLesson?.teamLessonId)}
                </Flex>
                <Flex
                  align={"center"}
                  color={MODAL_PRIMARY_TEXT_COLOR}
                  data-testid={HVTestId.PresentationIntroStep.participantsText}
                >
                  <Icon
                    as={IoPeople}
                    boxSize={"6"}
                    color={MODAL_THIRD_TEXT_COLOR}
                  />
                  <Text
                    ml={1}
                    fontSize={"sm"}
                    fontWeight={"bold"}
                    color={MODAL_THIRD_TEXT_COLOR}
                  >
                    &nbsp;
                    {pathTeamLesson?.participantCountForLastTeamLessonUserInstance
                      ? pathTeamLesson.participantCountForLastTeamLessonUserInstance
                      : 0}
                  </Text>
                  <Text fontSize={"sm"}>
                    &nbsp;
                    {!pathTeamLesson?.participantCountForLastTeamLessonUserInstance ||
                    pathTeamLesson.participantCountForLastTeamLessonUserInstance >
                      1
                      ? HVLocalizeStrings.TEAM_PARTICIPANTS
                      : HVLocalizeStrings.TEAM_PARTICIPANT}
                  </Text>
                </Flex>
                <Flex mt={2}>
                  {pathTeamLesson &&
                    getPollCount(
                      pathTeamLesson.pathId,
                      pathTeamLesson.teamLessonId
                    ) > 0 &&
                    (isMobile ? (
                      <Button
                        as={"button"}
                        className="button-link"
                        color={MODAL_THIRD_TEXT_COLOR}
                        onClick={onViewPollClick}
                      >
                        {HVLocalizeStrings.TEAM_VIEW_POLL_RESULT}
                      </Button>
                    ) : (
                      <AnimatedButton
                        w={180}
                        colorSet={AnimatedButtonColorSet.Third}
                        text={HVLocalizeStrings.TEAM_VIEW_POLL_RESULT}
                        onClick={onViewPollClick}
                        dataTestId={HVTestId.PresentationIntroStep.viewPoll}
                      />
                    ))}
                </Flex>
              </>
            ) : (
              <Flex
                justifyContent={"flex-start"}
                alignItems={"center"}
                width={"100%"}
                height={"100%"}
              >
                <HvSpinner />
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );

  const buttons = (
    <>
      {presentButtonText && (
        <Text fontSize={"xs"} color={Burnt_Orange}>
          {presentButtonText}
        </Text>
      )}
      {currentTeam && !isAnotherTeamLessonInProgress && (
        <AnimatedButton
          w={250}
          text={presentButtonMode}
          disabled={isPresentButtonDisabled}
          dataTestId={HVTestId.PresentationIntroStep.topButton}
          onClick={() => {
            setIsPresentButtonDisabled(true);
            let event = TEAM_EVENTS.StartTeamLessonUserInstance;
            if (teamLessonExpired) {
              event = TEAM_EVENTS.StartTeamLessonUserInstanceOver;
            }
            if (
              pathTeamLesson?.teamLessonInstanceStatus ===
              TeamLessonUserInstanceStatus.Incomplete
            ) {
              confirmationDialogRef.current.confirmation(
                HVLocalizeStrings.TEAM_TEAM_LESSON_OUT_OF_ORDER_TITLE,
                HVLocalizeStrings.TEAM_TEAM_LESSON_OUT_OF_ORDER_DESCRIPTION_BLOCK_ONE,
                ConfirmationButtons.Customs,
                () => {
                  // startTeamLesson() shows another confirmation, so setTimeout to allow open confirmation to close
                  setTimeout(() => {
                    startTeamLesson(event);
                  }, 100);
                },
                [HVLocalizeStrings.TEAM_TEAM_LESSON_OUT_OF_ORDER_UNDERSTAND],
                true,
                "2xl",
                () => {
                  setIsPresentButtonDisabled(false);
                }
              );
            } else if (
              pathTeamLesson?.teamLessonInstanceStatus !==
                TeamLessonUserInstanceStatus.Complete &&
              pathTeamLesson?.teamLessonInstanceStatus !==
                TeamLessonUserInstanceStatus.ForcedComplete
            ) {
              if (
                pathTeamLesson?.teamLessonInstanceStatus ==
                TeamLessonUserInstanceStatus.Catchup
              ) {
                event = TEAM_EVENTS.CatchUpPresentation;
              }
              startTeamLesson(event);
            } else {
              confirmationDialogRef.current.confirmation(
                HVLocalizeStrings.TEAM_PRESENT_CONFIRM_PRESENT_AGAIN_HEADER_TITLE,
                HVLocalizeStrings.TEAM_PRESENT_CONFIRM_PRESENT_AGAIN.replace(
                  "{0}",
                  "<strong>" +
                    dayjs
                      .utc(pathTeamLesson.lastPresentedDate?.toString())
                      .local()
                      .format(HVLocalizeStrings.DATE_FORMAT) +
                    "</strong>"
                ),
                ConfirmationButtons.YesNo,
                () => {
                  // startTeamLesson() shows another confirmation, so setTimeout to allow open confirmation to close
                  setTimeout(() => {
                    startTeamLesson(event);
                  }, 100);
                },
                null,
                null,
                null,
                () => {
                  setIsPresentButtonDisabled(false);
                }
              );
            }
          }}
        />
      )}
      {currentTeam && isAnotherTeamLessonInProgress && (
        <Text fontSize={"xs"} pr="10px" color={Burnt_Orange}>
          {HVLocalizeStrings.ANOTHER_IN_PROGRESS}
        </Text>
      )}
      <AnimatedButton
        colorSet={AnimatedButtonColorSet.Third}
        w={250}
        text={HVLocalizeStrings.TEAM_BUTTON_PREVIEW}
        dataTestId={HVTestId.PresentationIntroStep.bottomButton}
        onClick={() => {
          setCurrentPathTeamLessonPreview(pathTeamLesson || null);
          setPreviewModalOpen(true);
          trackTeamLesson(
            TEAM_EVENTS.PreviewPresentation,
            currentTeam,
            pathTeamLesson
          );
        }}
      />
      {catchupOpen && pathTeamLesson?.teamLessonUserInstanceId && (
        <ParticipantModal
          open={catchupOpen}
          setOpen={setCatchupOpen}
          forceCurrentStep={PARTICIPANT_CATCH_UP_STEP}
          teamLessonUserInstanceId={pathTeamLesson?.teamLessonUserInstanceId}
          catchUpCallBack={() => {
            setCatchupOpen(false);
          }}
          closeIntroModalCallback={() => {
            closeModal();
          }}
        />
      )}
      {isCatchupDone && pathTeamLesson?.teamLessonUserInstanceId && (
        <ParticipantModal
          open={isCatchupDone}
          setOpen={() => {
            setIsCatchupDone(false);
            closeModal();
          }}
          forceCurrentStep={PARTICIPANT_END_STEP}
          teamLessonUserInstanceId={pathTeamLesson?.teamLessonUserInstanceId}
          catchUpCallBack={() => {
            setIsCatchupDone(false);
            closeModal();
          }}
        />
      )}

      {pollResultOpen && (
        <PollResultModal
          pollInstances={currentPollInstances}
          open={pollResultOpen}
          setOpen={(value: boolean) => {
            if (!value) {
              setCurrentPollInstances(null);
              setOpacity(undefined);
            }
            setPollResultOpen(value);
          }}
          showWaitingOnVotes={true}
        />
      )}
    </>
  );

  const startTeamLesson = (event: string) => {
    trackTeamLesson(event, currentTeam, pathTeamLesson);
    if (
      pathTeamLesson?.teamLessonInstanceStatus ==
      TeamLessonUserInstanceStatus.Catchup
    ) {
      setCurrentTeamLessonUserInstanceId(
        pathTeamLesson.teamLessonUserInstanceId
      );
      setCatchupOpen(true);
    } else {
      invokeNextStep();
    }
  };

  useEffect(() => {
    const anotherInProgress = isAnotherTeamLessonInProgressForTeam(
      paths,
      currentTeam,
      pathTeamLessonId
    );
    setIsAnotherTeamLessonInProgress(anotherInProgress);
    setPresentButtonMode(HVLocalizeStrings.TEAM_BUTTON_PRESENT);
    setPresentButtonText(null);
    if (!self) {
      return;
    }
    const result = getPathTeamLessonByTeamId(
      paths,
      currentTeam,
      pathTeamLessonId
    );
    setPathTeamLesson(result);
    if (result && result.lastPresentedDate) {
      setPresentedDateString(
        HVLocalizeStrings.TEAM_LAST_PRESENTED +
          " <strong>" +
          dayjs
            .utc(result?.lastPresentedDate?.toString())
            .local()
            .format(HVLocalizeStrings.DATE_FORMAT) +
          "</strong>" ?? HVLocalizeStrings.TEAM_NOT_YET_PRESENTED
      );

      const differenceInMinutes = getLastPresentedByTimeSpan(result);
      if (
        result.teamLessonInstanceStatus ===
          TeamLessonUserInstanceStatus.Initialized ||
        result.teamLessonInstanceStatus ===
          TeamLessonUserInstanceStatus.InProgress
      ) {
        if (
          isPresentationExpired(
            differenceInMinutes,
            pathTeamLesson?.teamLesson
          ) &&
          !anotherInProgress
        ) {
          setTeamLessonExpired(true);
          setPresentButtonMode(HVLocalizeStrings.TEAM_BUTTON_START_OVER);
          setPresentButtonText(
            HVLocalizeStrings.TEAM_BUTTON_EXPIRE_TEAM_LESSON
          );
          return;
        }
        setPresentButtonMode(HVLocalizeStrings.TEAM_BUTTON_IN_PROGRESS);
      } else if (
        result.teamLessonInstanceStatus === TeamLessonUserInstanceStatus.Catchup
      ) {
        setPresentButtonMode(HVLocalizeStrings.TEAM_BUTTON_IN_CATCH_UP);
      }
    }
  }, [pathTeamLessonId, pathTeamLesson?.teamLesson, currentTeam, paths, self]);

  if (!isMobile) {
    return (
      <Flex px={8} mb={5}>
        <Flex flexDir="column" flexBasis={"50%"} pr={4}>
          {description}
        </Flex>
        <Flex flexBasis={"50%"} justifyContent={"center"}>
          <Flex
            w={"350px"}
            borderRadius={Border_Radius}
            borderTopRadius={"15px"}
            flexDir="column"
            boxShadow={"lg"}
            bgColor={"white"}
          >
            <Image
              src={translatedImage as string}
              alignSelf={"center"}
              objectFit="cover"
              borderRadius={"15px"}
              alt=""
            />
            <Flex
              h="220px"
              bgColor={"white"}
              flexDir={"column"}
              p={8}
              gap={4}
              borderRadius={Border_Radius}
            >
              <Flex flexDir={"column"} align={"center"} gap={"2"}>
                {buttons}
              </Flex>
              {teamLesson?.offlinePdfUrl &&
                pathTeamLesson?.teamLessonInstanceStatus !==
                  TeamLessonUserInstanceStatus.Catchup && (
                  <Flex alignItems={"center"} w="70%" alignSelf={"center"}>
                    <Icon
                      as={MdDownloadForOffline}
                      w={"17px"}
                      h={"17px"}
                      mr={1}
                    />
                    <Button
                      className="button-link"
                      onClick={onDownloadTeamLessonClick}
                      fontSize={"sm"}
                      data-testid={HVTestId.PresentationIntroStep.downloadPDF}
                    >
                      {HVLocalizeStrings.TEAM_TEAM_LESSON_DOWNLOAD_TITLE}
                    </Button>
                  </Flex>
                )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  } else {
    return (
      <>
        <Flex
          flexDir={"column"}
          mb={
            120 +
            (presentButtonText ? 35 : 0) +
            (teamLesson?.offlinePdfUrl ? 35 : 0) +
            "px"
          }
        >
          <Flex flexDirection={"column"} gap={5} mb={8}>
            <Image
              src={translatedImage as string}
              borderRadius={"15px"}
              w="280px"
              h="210px"
              alignSelf={"center"}
              objectFit="cover"
              alt=""
            />
          </Flex>
          <Flex px={15}>{description}</Flex>
        </Flex>
        <Flex
          flexDir={"column"}
          pos={"fixed"}
          bottom={0}
          left={0}
          w={"100%"}
          align={"center"}
          justify={
            presentButtonText || teamLesson?.offlinePdfUrl
              ? undefined
              : "center"
          }
          bgColor={MODAL_BACKGROUND_COLOR}
          zIndex={2}
          gap={4}
          p={4}
          pb={`${0 + safeAreaInsets.bottom}px`}
          boxShadow={"0px -3px 3px rgba(10, 50, 150, 0.1)"}
          h={"auto"}
        >
          {buttons}
          {teamLesson?.offlinePdfUrl &&
            pathTeamLesson?.teamLessonInstanceStatus !==
              TeamLessonUserInstanceStatus.Catchup && (
              <Flex
                gap={2}
                alignItems={"center"}
                color={MODAL_PRIMARY_TEXT_COLOR}
              >
                <Icon as={MdDownloadForOffline} w={"17px"} h={"17px"} mr={1} />
                <Button
                  className="button-link button-link--contained"
                  onClick={onDownloadTeamLessonClick}
                  fontSize={"sm"}
                >
                  {HVLocalizeStrings.TEAM_TEAM_LESSON_DOWNLOAD_TITLE}
                </Button>
              </Flex>
            )}
        </Flex>
      </>
    );
  }
};
