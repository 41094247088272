import {
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { EligibilityFileJobDto } from "@headversity/contract";
import dayjs from "dayjs";
import React, { useContext, useEffect } from "react";
import {
  EligibilityImportType,
  ReachUsersContext,
} from "../../../State/Reach/ReachUsersContext";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

const getJobErrorStr = (job: EligibilityFileJobDto): string => {
  if (!job.errors || job.errors.length <= 0) return "0";
  else return `${job.errors.length} (line ${job.errors[0].error})`;
};
const EligibilityFileJobList: React.FC<{}> = (props) => {
  const jobContext = useContext(ReachUsersContext);
  useEffect(() => {
    jobContext.fetchJobs();
  }, [jobContext.dataRefreshTimestamp]);
  return (
    <>
      <Heading color={MODAL_PRIMARY_TEXT_COLOR}>Jobs</Heading>
      <TableContainer pt={3}>
        <Table color={MODAL_PRIMARY_TEXT_COLOR}>
          <Thead>
            <Tr>
              <Th
                color={MODAL_PRIMARY_TEXT_COLOR}
                p={"0"}
                fontWeight={"normal"}
                fontSize={"xs"}
              >
                Id
              </Th>
              <Th
                color={MODAL_PRIMARY_TEXT_COLOR}
                p={"0"}
                fontWeight={"normal"}
                fontSize={"xs"}
              >
                Type
              </Th>
              <Th
                color={MODAL_PRIMARY_TEXT_COLOR}
                p={"0"}
                fontWeight={"normal"}
                fontSize={"xs"}
              >
                Status
              </Th>
              <Th
                color={MODAL_PRIMARY_TEXT_COLOR}
                p={"0"}
                fontWeight={"normal"}
                fontSize={"xs"}
              >
                Errors
              </Th>
              <Th
                color={MODAL_PRIMARY_TEXT_COLOR}
                p={"0"}
                fontWeight={"normal"}
                fontSize={"xs"}
              >
                Last Updated
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {jobContext.jobs.map((job: EligibilityFileJobDto) => (
              <Tr key={job.id}>
                <Td pb={"0"} pl={"0"} pr={"0"} pt={"1"}>
                  <Text fontSize={"xs"}>{job.id}</Text>
                </Td>
                <Td pb={"0"} pl={"0"} pr={"0"} pt={"1"}>
                  <Text fontSize={"xs"}>
                    {EligibilityImportType[job.importType]}
                  </Text>
                </Td>
                <Td pb={"0"} pl={"0"} pr={"0"} pt={"1"}>
                  <Text fontSize={"xs"}>
                    {job.isFinished ? "Finished" : "Pending"}
                  </Text>
                </Td>
                <Td pb={"0"} pl={"0"} pr={"0"} pt={"1"} w={"35%"}>
                  <Text sx={{ "text-wrap": "balance" }} fontSize={"xs"}>
                    {getJobErrorStr(job)}
                  </Text>
                </Td>
                <Td pb={"0"} pl={"0"} pr={"0"} pt={"1"}>
                  <Text fontSize={"xs"}>
                    {dayjs
                      .utc(job.updatedAt as string)
                      .local()
                      .format(HVLocalizeStrings.DATE_FORMAT_WITH_TIME)}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EligibilityFileJobList;
