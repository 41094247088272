import { useEffect, useState, useCallback } from "react";
import { getDomainFromEmail } from "../../Utils/LoginUtil";

export interface ISSOProvider {
    singleSignOnEnabled: boolean;
    passwordEnabled: boolean;
    ssoType: string | null;
    currentProvider: string | null;
    ssoUrl: string;
}

export const useSSOCheck = (email: string | null): ISSOProvider => {
    const [singleSignOnEnabled, setSingleSignOnEnabled] = useState<boolean>(false);
    const [ssoType, setSsoType] = useState<string | null>(null);
    const [currentProvider, setCurrentProvider] = useState<string | null>(null);
    const [passwordEnabled, setPasswordEnabled] = useState<boolean>(true);
    const [ssoUrl, setSsoUrl] = useState<string>("");

    const constructSSOUrl = useCallback((ssoType: string, providerName: string) => {
        let url = "";
        if (ssoType === "CognitoOauth") {
            url =
                `${process.env.REACT_APP_COGNITO_URL_OAUTH}/oauth2/authorize?` +
                `identity_provider=${providerName}` +
                `&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}` +
                `&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}` +
                `&scope=aws.cognito.signin.user.admin email openid phone profile`;
        } else {
            url =
                `${process.env.REACT_APP_API_AUTH0_URL}/authorize?` +
                `client_id=${process.env.REACT_APP_CLIENT_ID as any}&response_type=token` +
                `&redirect_uri=${encodeURIComponent(process.env.REACT_APP_REDIRECT_URL as any)}` +
                `&connection=${providerName}` +
                `&scope=openid%20profile%20email&audience=${encodeURIComponent(process.env.REACT_APP_AUDIENCE as any)}`;
        }
        setSsoUrl(url);
    }, []);

    useEffect(() => {
        if (email && (window as any).inviteCodeDomains) {
            const ssoDomain = getDomainFromEmail((window as any).inviteCodeDomains, email);
            if (ssoDomain?.providerName && ssoDomain?.providerName?.length > 0) {
                setSingleSignOnEnabled(true);
                setPasswordEnabled(false);
                setSsoType(ssoDomain.ssoType);
                setCurrentProvider(ssoDomain.providerName);
                constructSSOUrl(ssoDomain.ssoType, ssoDomain.providerName);
            } else {
                setSingleSignOnEnabled(false);
                setPasswordEnabled(true);
                setSsoType(null);
                setCurrentProvider(null);
                setSsoUrl("");
            }
        }
    }, [email, constructSSOUrl]);

    return { singleSignOnEnabled, passwordEnabled, ssoType, currentProvider, ssoUrl };
};