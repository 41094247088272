import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import { HeadscanInputDto, HeadscanQuestionDto } from "@headversity/contract";

export const getHeadScanQuestionsAndAnswers = (
  token: string
): Promise<AxiosResponse<HeadscanQuestionDto[]>> => {
  return AxiosClient.get("api/headscanQuestions", getConfig(token));
};

export const saveHeadScanQuestionAnswer = (
  token: string,
  headscanInputDto: HeadscanInputDto
) => {
  return AxiosClient.post(
    "api/headscanQuestion",
    headscanInputDto,
    getConfig(token)
  );
};
