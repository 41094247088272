import {
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { MdInfo } from "react-icons/md";
import {
  Border_Radius,
  DISABLED_BORDER,
  Equity_Black,
  Equity_Blue,
  Mid_Blue,
  White,
} from "../../../Styles/HeadversityStyle";

interface InsightsCardTooltipProps {
  title?: string;
  content?: string;
}

const InsightsCardTooltip = (props: InsightsCardTooltipProps) => {
  const { title, content } = props;
  return (
    <Box
      width="200px"
      maxH="146px"
      borderRadius="10px"
      boxShadow="lg"
      border="solid 1px #cccccc33"
    >
      <Flex borderBottom={DISABLED_BORDER} px={3} py={3} alignItems="center">
        <Icon as={MdInfo} color={Mid_Blue} boxSize={3} mr={2} />
        <Heading
          fontSize="12px"
          fontWeight="semibold"
          as="div"
          color={Mid_Blue}
        >
          {title}
        </Heading>
      </Flex>
      <Box>
        <Text fontSize="12px" px={3} py={2} color={Equity_Black}>
          {content}
        </Text>
      </Box>
    </Box>
  );
};

interface InsightsCardHeaderProps {
  title: string;
  subtitle?: string;
  hasTooltip?: boolean;
  tooltipTitle?: string;
  tooltipContent?: string;
}

const InsightsCardHeader = (props: InsightsCardHeaderProps) => {
  const { title, subtitle, hasTooltip, tooltipTitle, tooltipContent } = props;
  return (
    <Flex justify="space-between">
      <Flex flexDirection="column" justifyContent="center" gap={1}>
        <Heading fontSize="lg" fontWeight="semibold" as="div">
          {title}
        </Heading>
        <Text fontSize="small">{subtitle}</Text>
      </Flex>
      {hasTooltip && (
        <Flex w="25px" alignItems="center" justifyContent="end">
          <Tooltip
            label={
              <InsightsCardTooltip
                title={tooltipTitle}
                content={tooltipContent}
              />
            }
            placement="bottom"
            bg={White}
            borderRadius="10px"
            px={0}
          >
            <span>
              <Icon as={MdInfo} color={Equity_Blue} boxSize={5} />
            </span>
          </Tooltip>
        </Flex>
      )}
    </Flex>
  );
};

interface InsightsCardProps {
  title: string;
  subtitle?: string;
  width?: string;
  content: JSX.Element;
  hideHeader?: boolean;
  hasTooltip?: boolean;
  tooltipTitle?: string;
  tooltipContent?: string;
  showFooter?: boolean;
  footerContent?: JSX.Element;
  minH?: string;
}

const InsightsCard = (props: InsightsCardProps) => {
  const {
    title,
    subtitle,
    width,
    content,
    hideHeader,
    hasTooltip,
    tooltipTitle,
    tooltipContent,
    showFooter,
    footerContent,
    minH,
  } = props;
  return (
    <Flex direction="column">
      <Box
        border={DISABLED_BORDER}
        borderRadius={Border_Radius}
        bg={White}
        boxShadow="lg"
        maxW={width}
        h={minH ? undefined : "100%"}
        minH={minH}
      >
        {!hideHeader && (
          <Box borderBottom={DISABLED_BORDER} px={5} py={3}>
            <InsightsCardHeader
              title={title}
              subtitle={subtitle}
              hasTooltip={hasTooltip}
              tooltipTitle={tooltipTitle}
              tooltipContent={tooltipContent}
            />
          </Box>
        )}
        <Center py={2} height={hideHeader && !showFooter ? "100%" : undefined}>
          {content}
        </Center>
        {showFooter && (
          <Box borderTop={DISABLED_BORDER}>
            <InsightsCardFooter footerContent={footerContent} />
          </Box>
        )}
      </Box>
    </Flex>
  );
};

interface InsightsCardFooterProps {
  footerContent?: JSX.Element;
}

const InsightsCardFooter = (props: InsightsCardFooterProps) => {
  const { footerContent } = props;
  return (
    <Flex px={5} py={3}>
      <Text fontSize="small" fontWeight="400">
        {footerContent}
      </Text>
    </Flex>
  );
};

export default InsightsCard;
