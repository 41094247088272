import React from "react";
import { Flex } from "@chakra-ui/react";

interface PresenterContainerProps {
  children: any;
  containerPt?: string;
}
export const PresentationContainer = (props: PresenterContainerProps) => {
  const { children, containerPt } = props;
  return (
    <Flex
      flexDir={"column"}
      align={"center"}
      justify={"flex-start"}
      py={"5"}
      w={"100%"}
      h={"100%"}
      mb={"30"}
      position={"fixed"}
      overflow={"auto"}
      top={"0px"}
      left={"0px"}
      zIndex={"2"}
    >
      <Flex
        flexDir={"column"}
        w={"100%"}
        align={"center"}
        gap={"5"}
        pt={containerPt ?? "16"}
        position={"relative"}
      >
        {children}
      </Flex>
    </Flex>
  );
};
