import { Box, Center, Image } from "@chakra-ui/react";
import { UploadType } from "@headversity/contract";
import { useState } from "react";
import ImageUploader from "../../Reach/SelfService/ImageUploader";

export interface TeamLogoUploaderProps {
  imageUrlChanged: (url: string) => void;
  previewImageMb?: string;
  startUrl?: string;
  handleIsUploading?: (isUploading: boolean) => void;
}
export const TeamLogoUploader = ({
  imageUrlChanged,
  previewImageMb,
  startUrl,
  handleIsUploading,
}: TeamLogoUploaderProps) => {
  const [logoUrl, setLogoUrl] = useState<string>("");
  const inputImageWidth = "170px";
  const inputImageHeight = "100px";

  const handleImageUrlChanged = (url: string) => {
    imageUrlChanged(url);
    setLogoUrl(url);
  };
  const handleSetIsUploading = (isUploading: boolean) => {
    handleIsUploading?.(isUploading);
  };

  if (startUrl && startUrl !== logoUrl) {
    setLogoUrl(startUrl);
  }
  return (
    <Box>
      {logoUrl && (
        <Center>
          <Image
            mb={previewImageMb}
            w={inputImageWidth}
            h={inputImageHeight}
            objectFit="contain"
            src={logoUrl}
          ></Image>
        </Center>
      )}
      <ImageUploader
        uploadType={UploadType.TEAM_LOGO}
        handleBucketUrlChanged={handleImageUrlChanged}
        handleIsUploading={handleSetIsUploading}
      />
    </Box>
  );
};
