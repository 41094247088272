import React from "react";
import { ScrollPanel } from "../../Common/ScrollPanel";
import { ScrollPanelBlock } from "../../Common/ScrollPanelBlock";
import { TeamTrainingBlock } from "./TeamTrainingBlock";
import { PathTeamLessonDto } from "@headversity/contract";
import { SkillDto } from "@headversity/contract";

interface TeamTrainingsScrollPanelProps {
  isDesktop: boolean | undefined;
  pathTeamLessons: PathTeamLessonDto[];
  from: string;
  skill?: SkillDto;
  showPath?: boolean;
}

export const TeamTrainingsScrollPanel = ({
  isDesktop,
  pathTeamLessons,
  from,
  skill,
  showPath,
}: TeamTrainingsScrollPanelProps) => {
  return (
    <ScrollPanel
      scrollOnMobileOnly={false}
      spaceBetween={8}
      showChevronContainer={true}
      slidesPerGroup={isDesktop ? 3 : 1}
      slidesPerView={"auto"}
      isPaginationOnContainer={true}
    >
      {pathTeamLessons.map((pathTeamLesson: PathTeamLessonDto) => {
        const id = `${pathTeamLesson?.pathId}-${pathTeamLesson?.teamLesson?.id}`;

        return ScrollPanelBlock(
          <TeamTrainingBlock
            key={id}
            teamLesson={pathTeamLesson.teamLesson}
            width={"241px"}
            height={"175px"}
            from={from}
            skill={skill}
            showPath={showPath}
            showDescription={true}
            hideIcon={true}
          />,
          "241px",
          id
        );
      })}
    </ScrollPanel>
  );
};
