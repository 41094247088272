export interface S3UploadDto {
  uploadUrl?: string;
  downloadUrl: string;
}

export enum UploadType {
  HELP_RESOURCE_LOGO = "HELP_RESOURCE_LOGO",
  COMPANY_LOGO = "COMPANY_LOGO",
  TEAM_LOGO = "TEAM_LOGO",
}
