import { AxiosResponse } from "axios";
import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { FocusToolInfoDto, FocusToolInfoInputDto } from "@headversity/contract";

export const getFocusPlans = (
  token: string
): Promise<AxiosResponse<FocusToolInfoDto[]>> => {
  return AxiosClient.get("api/focusTool/getFocusToolInfo", getConfig(token));
};

export const saveFocusPlan = (
  token: string,
  focusPlan: FocusToolInfoInputDto
): Promise<AxiosResponse<FocusToolInfoDto>> => {
  return AxiosClient.post(
    "api/focusTool/saveFocusToolInfo",
    focusPlan,
    getConfig(token)
  );
};

export const deleteFocusPlan = (
  token: string,
  id: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.delete(
    `api/focusTool/deleteFocusToolInfo/${id}`,
    getConfig(token)
  );
};

export const updateFocusPlan = (
  token: string,
  focusPlan: FocusToolInfoDto
): Promise<AxiosResponse<FocusToolInfoDto>> => {
  return AxiosClient.put(
    `api/focusTool/updateFocusToolInfo/${focusPlan.id}`,
    focusPlan,
    getConfig(token)
  );
};
