import { Link } from "react-router-dom";
import React from "react";

interface BadgeSkillLinkProps {
  skillUrl?: string;
  children: JSX.Element;
  onClick?: () => void;
}

/** pass undefined for onClick to render no link */
export const BadgeSkillLink = (props: BadgeSkillLinkProps) => {
  const { skillUrl, children, onClick } = props;
  if (skillUrl && onClick) {
    return (
      <Link onClick={onClick} to={skillUrl}>
        {children}
      </Link>
    );
  }
  return children;
};
