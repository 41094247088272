import { Box, Button, Divider, Flex, Icon, Stack } from "@chakra-ui/react";
import { ProgressTracker } from "./ProgressTracker";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { FiArrowLeft } from "react-icons/fi";
import {
  HVDefaultTheme,
  HVNostalgicTheme,
  MODAL_CLOSE_BUTTON,
} from "../../Styles/HeadversityStyle";
import { isWebPlatform } from "../../Utils/mobileUtils";
import { useContext } from "react";
import { ShellContext } from "../../State/ShellContext";

interface StepPanelProps {
  step: number;
  totalStep: number;
  value: number;
  showProgressBar?: boolean;
  showBackArrow?: boolean;
  onBackClick?: () => void;
  children: any;
  noMarginTop?: boolean;
  paddingBottom?: string;
  hideDivider?: boolean;
  questionHeader?: boolean;
  forceLightMode?: boolean;
  forceDarkMode?: boolean;
}

export const StepPanel = (props: StepPanelProps) => {
  const {
    children,
    step,
    value,
    showProgressBar,
    totalStep,
    showBackArrow,
    onBackClick,
    noMarginTop,
    paddingBottom,
    hideDivider,
    questionHeader,
    forceLightMode,
    forceDarkMode,
  } = props;

  const { safeAreaInsets } = useContext(ShellContext);

  return (
    <>
      {showBackArrow && (
        <Box
          position={"absolute"}
          top={isWebPlatform() ? "20px" : `${safeAreaInsets.top}px`}
          ml="-5px"
        >
          <Button
            onClick={() => {
              if (onBackClick) {
                onBackClick();
              }
            }}
            minW="auto"
            w={"30px"}
            h={"30px"}
            className={"button-link"}
            textAlign="left"
            zIndex={2}
            _hover={{
              bgColor: "var(--chakra-colors-blackAlpha-100) !important",
            }}
            mt="4px"
          >
            <Icon
              pl="5px"
              as={FiArrowLeft}
              w={"25px"}
              h={"25px"}
              color={
                forceLightMode
                  ? HVDefaultTheme.colors[MODAL_CLOSE_BUTTON]
                  : forceDarkMode
                  ? HVNostalgicTheme.colors[MODAL_CLOSE_BUTTON]
                  : MODAL_CLOSE_BUTTON
              }
            />
          </Button>
        </Box>
      )}
      <Stack w={"100%"} justify={"center"} spacing={"4"}>
        {showProgressBar && (
          <ProgressTracker
            title={
              questionHeader
                ? HVLocalizeStrings.QUESTION
                : HVLocalizeStrings.STEP
            }
            step={step}
            value={value}
            totalStep={totalStep}
            forceLightMode={forceLightMode}
            forceDarkMode={forceDarkMode}
          />
        )}
        {
          !showProgressBar &&
            showBackArrow &&
            (hideDivider ? (
              <Box h="10px" /> /* spacer */
            ) : (
              <Divider
                mt={
                  isWebPlatform()
                    ? "15px"
                    : `${Math.max(15, safeAreaInsets.top - 15)}px`
                }
              />
            )) // see note in ProgressTracker... also fix this when we fix the weird margin top pull-up
        }
      </Stack>

      <Stack
        minH={"100px"}
        w={"100%"}
        justify={"center"}
        mt={noMarginTop ? undefined : 6}
      >
        <Flex
          flexDir={"column"}
          justify={"center"}
          gap={children ? "15" : "0"}
          position={"relative"}
          pb={paddingBottom}
        >
          {children}
        </Flex>
      </Stack>
    </>
  );
};
