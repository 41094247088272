import {
  Box,
  Center,
  Image,
  useBreakpointValue,
  Text,
  Flex,
  useToken,
  Divider,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import { ShellContext } from "../../../State/ShellContext";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { FeatureFlags } from "../../../Utils/FeatureFlags";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { LargeModal } from "../../Common/LargeModal";

interface AboutTeamModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const BASE_VIDEO_URL = "https://player.vimeo.com/video/821784047?h=3f36cc4398";
const BASE_VIDEO_URL_FR =
  "https://player.vimeo.com/video/833821211?h=35d1ba8d2a";

export const AboutTeamModal = ({ open, setOpen }: AboutTeamModalProps) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const {
    company,
    getFeatureVersion,
    selectedUserLanguage,
    pushDisabled,
    promptForPushPermission,
  } = useContext(GlobalContext);
  const { doOSThemeCheck } = useContext(ShellContext);

  const [themeReady, setThemeReady] = useState(false);
  const [videoUrl, setVideoUrl] = useState(BASE_VIDEO_URL);
  const [customLogoUrl, setCustomLogoUrl] = useState("");

  /* duped from WelcomeModal */
  useEffect(() => {
    if (!open) return;

    doOSThemeCheck(() => {
      setThemeReady(true);
    });
  }, [open]);

  useEffect(() => {
    let videoUrl = BASE_VIDEO_URL;

    if (selectedUserLanguage === "fr") {
      videoUrl = BASE_VIDEO_URL_FR;
    } else if (selectedUserLanguage === "es") {
      videoUrl += "&texttrack=es-419";
    }

    setVideoUrl(videoUrl);
  }, [selectedUserLanguage]);

  /* duped from WelcomeModal */
  useEffect(() => {
    const featureDetails = getFeatureVersion(FeatureFlags.WELCOME_2023) as any;

    // check if we have a feature override
    if (featureDetails && featureDetails.customLogoUrl !== undefined) {
      setCustomLogoUrl(featureDetails.customLogoUrl);
    }

    // prefer company logo
    if (company?.logo) {
      setCustomLogoUrl(company?.logo);
    }
  }, [selectedUserLanguage]);

  const imageFilter = useToken("colors", ["IMAGE_FILTER"]);

  return !themeReady ? (
    <></>
  ) : (
    <LargeModal open={open} setOpen={setOpen as any} extraPadHeader={true}>
      <Center>
        <Flex
          gap={isDesktop ? 10 : 4}
          flexDir={isDesktop ? "row" : "column"}
          mb="30px"
        >
          <Image
            src={HVLocalizeStrings.TEAM_LOGO}
            filter={imageFilter}
            h="80px"
            alt=""
          />
          {customLogoUrl && (
            <Image
              src={customLogoUrl}
              maxH="100px"
              maxW="250px"
              m="auto"
              alt=""
            />
          )}
        </Flex>
      </Center>
      <Box mb={"80px"}>
        <Box mt={2} mx={isDesktop ? "20px" : "10px"}>
          <InterstitialModalText
            html={
              HVLocalizeStrings.ABOUT_TEAM_FIRST_LINE +
              " " +
              HVLocalizeStrings.ABOUT_TEAM_SECOND_LINE
            }
          />

          <Center mt={isDesktop ? 8 : 4}>
            <iframe
              src={videoUrl}
              width={isDesktop ? "350px" : "360px"}
              height={isDesktop ? "200px" : "210px"}
              title="video"
              allowFullScreen={true}
            ></iframe>
          </Center>

          <Box mt={4}>
            <InterstitialModalText
              html={HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_THIRD_LIST_ITEM}
            />
          </Box>

          {pushDisabled && !isWebPlatform() && (
            <>
              <Divider mt={8} mb={6} />
              <InterstitialModalText
                html={
                  HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_PUSH_NOTIFICATIONS_TEAM
                }
              />
            </>
          )}
        </Box>
      </Box>
      <BottomActionBar
        showOptions={true}
        nextButtonText={HVLocalizeStrings.START_TRAINING}
        onNextButtonClick={() => {
          setOpen(false);

          if (pushDisabled && !isWebPlatform()) {
            promptForPushPermission();
          }
        }}
      />
    </LargeModal>
  );
};

interface InterstitialModalTextProps {
  html: string;
}

const InterstitialModalText = ({ html }: InterstitialModalTextProps) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Text
      mb={5}
      fontSize={isDesktop ? "16px" : "15px"}
      color={MODAL_PRIMARY_TEXT_COLOR}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
