import { ForwardedRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  Box,
  Divider,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  BUTTON_THIRD_BORDER,
  PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { FiFile } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";

interface StepUploadFileProps {
  ref: ForwardedRef<any>;
  handleFileChange: (file: any) => void;
  uploadFile: any;
}

const StepUploadFile = ({
  ref,
  handleFileChange,
  uploadFile,
}: StepUploadFileProps) => {
  const fileTypes = ["csv"];
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  return (
    <>
      <Text fontSize={"xl"} fontWeight={"semibold"}>
        {HVLocalizeStrings.REACH_USERS_UPLOAD_STEP_2_TITLE}
      </Text>

      <FileUploader
        ref={ref}
        handleChange={handleFileChange}
        name="file"
        types={fileTypes}
        children={
          <Box
            bg={"white"}
            w={isMobile ? "90%" : "510px"}
            borderRadius={"10px"}
            cursor={"pointer"}
            border={BUTTON_THIRD_BORDER}
            color={PRIMARY_TEXT_COLOR}
            onClick={() => {
              (ref as any)?.click();
            }}
            boxShadow={"lg"}
          >
            <Flex gap={4} p={4}>
              <Icon as={FiFile} boxSize={10} />

              <Box width={isMobile ? `calc(90% - 40px)` : "100%"}>
                <Text
                  fontSize={"sm"}
                  dangerouslySetInnerHTML={{
                    __html: HVLocalizeStrings.REACH_USERS_DRAG_DROP,
                  }}
                />

                <Text fontSize={"sm"}>
                  {HVLocalizeStrings.REACH_USERS_ACCEPTED_EXTENSIONS}
                </Text>
                {uploadFile && (
                  <>
                    <Divider my={2} />
                    <Flex gap={2} alignItems={"center"}>
                      <FaCheckCircle color={"green"} />
                      <Text fontSize={"sm"} wordBreak={"break-all"}>
                        {uploadFile.name}
                      </Text>
                    </Flex>
                  </>
                )}
              </Box>
            </Flex>
          </Box>
        }
      />
    </>
  );
};

export default StepUploadFile;
