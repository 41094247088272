import { HVLocalizeStrings, HVLocalizeStringsObj } from "./HVLocalizeStrings";
import React, { useEffect, useState } from "react";
import { HStack, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { HeaderSetter } from "../Components/Common/HeaderSetter";
import { HEADER_HOME_BACKGROUND } from "../Styles/HeadversityStyle";
import { AnimatedButton } from "../Components/Common/AnimatedButton";
import Papa from "papaparse";
import { LargeModal } from "../Components/Common/LargeModal";
import { HvTextarea } from "../Components/Common/HvTextarea";

export const UnLocalizeOutputPage = () => {
  const [needTranslationArr, setNeedTranslationArr] = useState<any[]>([]);
  const [parsedImportedContent, setParsedImportedContent] = useState<any>(null);
  const [jsonOutput, setJsonOutput] = useState<string>("");
  const [uploadModalOpened, setUploadModalOpened] = useState<boolean>(false);

  const handleOnTextImportChange = (e: any) => {
    e.preventDefault();

    const inputValue = e.target.value as string;
    if (inputValue) {
      const csvParsed = Papa.parse(inputValue, {
        header: true,
        skipEmptyLines: true,
      })?.data;
      setParsedImportedContent(csvParsed);
    } else {
      setParsedImportedContent("");
      setJsonOutput("");
    }
  };
  useEffect(() => {
    const needTranslations: any = [];
    const allKeys = Object.keys(HVLocalizeStringsObj.en);
    allKeys.forEach((key) => {
      if (
        ["DATE_FORMAT", "DATE_FORMAT_WITH_TIME", "HEADVERSITY"].indexOf(key) >
        -1
      ) {
        return;
      }
      if (
        (HVLocalizeStringsObj.en as any)[key] ===
        (HVLocalizeStringsObj.es as any)[key]
      ) {
        needTranslations.push({
          key,
          en: (HVLocalizeStringsObj.en as any)[key],
          fr: "",
          es: "",
        });
      }
    });

    setNeedTranslationArr(needTranslations);
  }, []);
  useEffect(() => {
    if (parsedImportedContent?.length > 0) {
      if (
        !parsedImportedContent.some(
          (item: any) => item.key && (item.es || item.fr)
        )
      ) {
        setJsonOutput(
          "Invalid CSV input. Please try again and ensure it contains a key column and at least one of an es and fr column."
        );
        return;
      }
      const translations: any[] = [];
      translations[0] = { en: {} };
      translations[1] = { es: {} };
      translations[2] = { fr: {} };
      let esResults: any[] = [];
      let frResults: any[] = [];
      if (parsedImportedContent) {
        parsedImportedContent.forEach((element: any) => {
          esResults[element.key] = element.es;
          frResults[element.key] = element.fr;
        });
      }
      const allKeys = Object.keys(HVLocalizeStringsObj.en);
      allKeys.forEach((key) => {
        translations[0].en[key] = (HVLocalizeStringsObj.en as any)[key];
        translations[1].es[key] =
          (esResults as any)[key]?.length > 0
            ? (esResults as any)[key]
            : (HVLocalizeStringsObj.es as any)[key];
        translations[2].fr[key] =
          (frResults as any)[key]?.length > 0
            ? (frResults as any)[key]
            : (HVLocalizeStringsObj.fr as any)[key];
      });
      let jsonResultEn =
        JSON.stringify(translations[0], null, 2)
          .slice(1)
          .slice(0, -1)
          .trimEnd() + ",\n";
      let jsonResultEs =
        JSON.stringify(translations[1], null, 2)
          .slice(1)
          .slice(0, -1)
          .trimEnd() + ",\n";
      let jsonResultFr = JSON.stringify(translations[2], null, 2)
        .slice(1)
        .slice(0, -1)
        .trimEnd();
      let jsonResult =
        'import LocalizedStrings from "react-localization";\n' +
        "\n" +
        "export const HVLocalizeStringsObj = {" +
        jsonResultEn +
        jsonResultEs +
        jsonResultFr +
        "};\n" +
        "\n" +
        "export const HVLocalizeStrings = new LocalizedStrings(HVLocalizeStringsObj);\n";
      setJsonOutput(jsonResult);
    } else {
      setJsonOutput("");
    }
  }, [parsedImportedContent]);

  useEffect(() => {
    setJsonOutput("");
  }, [uploadModalOpened]);

  return (
    <>
      <Helmet>
        <title>{HVLocalizeStrings.HEADVERSITY}</title>
      </Helmet>
      <HeaderSetter
        title={"TRANSLATION"}
        description={
          "Need our great translation team to take care of these translation please :) Talya you the best!"
        }
        backgroundImage={HEADER_HOME_BACKGROUND}
      ></HeaderSetter>
      <HStack margin={"5"} justify={"start"} spacing={"5"}>
        <AnimatedButton
          text={"Download Untranslated CSV File"}
          w={"auto"}
          onClick={() => {
            const csv = Papa.unparse(needTranslationArr);

            const csvData = new Blob([csv], {
              type: "text/csv;charset=utf-8;",
            });
            let csvURL = null;
            if ((navigator as any).msSaveBlob) {
              csvURL = (navigator as any).msSaveBlob(csvData, "download.csv");
            } else {
              csvURL = window.URL.createObjectURL(csvData);
            }

            const tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", "download.csv");
            tempLink.click();
          }}
        />
        <AnimatedButton
          text={"Import Translated CSV File"}
          w={"auto"}
          onClick={() => setUploadModalOpened(true)}
        />
      </HStack>
      <Table>
        <Thead>
          <Tr>
            <Th w={"25%"}>key</Th>
            <Th w={"25%"}>en</Th>
            <Th w={"25%"}>fr</Th>
            <Th w={"25%"}>es</Th>
          </Tr>
        </Thead>
        <Tbody>
          {needTranslationArr.map((item: any) => (
            <Tr>
              <Td>{item.key}</Td>
              <Td>{item.en}</Td>
              <Td>{item.fr}</Td>
              <Td>{item.es}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <LargeModal
        open={uploadModalOpened}
        setOpen={setUploadModalOpened as any}
        title={
          "Import Translated CSV (UTF-8!) And Convert To HVLocalizeStrings"
        }
      >
        <>
          <HStack>
            <HvTextarea
              onChange={handleOnTextImportChange}
              placeholder="Insert CSV content here"
              size="md"
              height={"600"}
              maxLength={999999}
            />
            <HvTextarea
              value={jsonOutput}
              placeholder="Output content will go here"
              readOnly
              size="md"
              height={"600"}
              maxLength={999999}
            />
          </HStack>
        </>
      </LargeModal>
    </>
  );
};
