import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Box, Center, Flex, useBreakpointValue } from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { GoalWinThemeDto } from "@headversity/contract";
import { ReachGoalsWinThemeCard } from "./ReachGoalsWinThemeCard";
import { useContext } from "react";
import {
  ReachGoalsContext,
  ReachGoalsScreen,
} from "../../../State/Reach/ReachGoalsContext";

export const ReachGoalsViewScreen = () => {
  const goalsContext = useContext(ReachGoalsContext);

  const { goalWinThemes, setScreen } = goalsContext;

  const flexBasis = useBreakpointValue({ base: "100%", md: "50%" });
  const maxWidth = useBreakpointValue({ base: "100%", md: "50%" });

  return (
    <>
      <Flex wrap="wrap">
        {goalWinThemes.map((goalWinTheme: GoalWinThemeDto, index: number) => (
          <Flex
            key={goalWinTheme.id}
            flexBasis={flexBasis}
            maxWidth={maxWidth}
            flexGrow={1}
            flexShrink={0}
          >
            <ReachGoalsWinThemeCard
              viewOnly={true}
              goalWinTheme={goalWinTheme}
              index={index}
              maxWidth={maxWidth}
            />
          </Flex>
        ))}
      </Flex>
      <Box mt="4">
        <Center>
          <AnimatedButton
            colorSet={AnimatedButtonColorSet.Fourth}
            text={HVLocalizeStrings.REACH_GOALS_EDIT_GOALS}
            onClick={() => {
              setScreen(ReachGoalsScreen.SELECTION);
            }}
            mr="5px"
            boxShadow={"lg"}
            w={"auto"}
          />
        </Center>
      </Box>
    </>
  );
};
