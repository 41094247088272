import { Box, Flex, Text } from "@chakra-ui/react";
import { Loading } from "../../../Common/Loading";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import React from "react";
import {
  Evening_Blue,
  Light_Blue,
  Mid_Blue,
} from "../../../../Styles/HeadversityStyle";

interface ParticipantWaitingScreenProps {
  messageBold?: string;
  message?: string;
  boxShadow?: string;
}
export const ParticipantWaitingScreen = (
  props: ParticipantWaitingScreenProps
) => {
  const { messageBold, message, boxShadow } = props;
  return (
    <Flex
      flexDir={"column"}
      align={"center"}
      justify={"center"}
      gap={"5"}
      my={"3"}
      w={"100%"}
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        p={"10px"}
        w={"300px"}
        h={"300px"}
        bg={"white"}
        borderRadius={"md"}
        boxShadow={boxShadow ?? "lg"}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        lineHeight={"1.3"}
        gap={"3"}
      >
        <Text fontWeight={"bold"}>
          {messageBold ?? HVLocalizeStrings.THANK_YOU}
        </Text>
        <Text mb={"10px"}>
          {message ?? HVLocalizeStrings.TEAM_POLL_WAITING_FOR_USER_RESULTS}
        </Text>
        <Loading
          colorOne={Evening_Blue}
          colorTwo={Mid_Blue}
          colorThree={Light_Blue}
        />
      </Box>
    </Flex>
  );
};
