import { AxiosResponse } from "axios";
import {
  HelpResourceChannelType,
  HelpResourceDto,
} from "@headversity/contract";
import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";

export const getHelpResourcesForUser = (
  token: string
): Promise<AxiosResponse<HelpResourceDto[]>> => {
  return AxiosClient.get("api/helpResources", getConfig(token));
};

export const trackHelpResourceView = (
  token: string,
  helpResourceId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/helpResources/${helpResourceId}`,
    null,
    getConfig(token)
  );
};

export const trackHelpResourceChannelClick = (
  token: string,
  helpResourceId: number,
  channelType: HelpResourceChannelType
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/helpResources/${helpResourceId}/${channelType}/click`,
    null,
    getConfig(token)
  );
};
