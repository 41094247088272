import { Capacitor } from "@capacitor/core";
import { Clipboard } from "@capacitor/clipboard";
import { App as CapApp } from "@capacitor/app";
import { DeviceInfo } from "@capacitor/device";

// these variables need to be updated for every
// new native build.
export const ANDROID_VERSION = "3.0.6";
export const IOS_VERSION = "3.0.6";
export const CHANNEL_VERSION = "p6";

export const isSimulator = () => {
  return process.env.REACT_APP_UPDATE_CHANNEL === "FakeChannel";
};

export const isWebPlatform = () => {
  return Capacitor.getPlatform() === "web";
};

export const isAndroid = () => {
  return Capacitor.getPlatform() === "android";
};

export const isAndroidBrowser = () => {
  return isWebPlatform() && /android/i.test(navigator.userAgent);
};
export const isIOS = () => {
  return Capacitor.getPlatform() === "ios";
};

export const writeToClipboard = async (text?: string) => {
  try {
    if (text) {
      if (isAndroid()) {
        await Clipboard.write({ string: text });
      } else {
        // works for web and ios
        await navigator.clipboard.writeText(text);
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const checkIfOutdatedVersion = async () => {
  if (!isWebPlatform() && !isSimulator()) {
    return await CapApp.getInfo().then((appInfo) => {
      const version = isAndroid() ? ANDROID_VERSION : IOS_VERSION;
      const compare = appInfo.version.localeCompare(version, undefined, {
        numeric: true,
        sensitivity: "base",
      });
      return compare !== 0;
    });
  }
  return false;
};

export const getIsPixel678 = (deviceInfo?: DeviceInfo) => {
  if (deviceInfo && deviceInfo.model) {
    // - /pixel\s[678]/i: Matches the string "pixel", followed by a space, and then either 6, 7, or 8 in a case-insensitive manner.
    const pattern = /pixel\s[678]/i;
    return pattern.test(deviceInfo.model);
  }
  return false;
};

export const getNewRelicToken = () => {
  if (Capacitor.getPlatform() === "ios") {
    return process.env.REACT_APP_NEW_RELIC_IOS_TOKEN as string;
  } else {
    return process.env.REACT_APP_NEW_RELIC_ANDROID_TOKEN as string;
  }
};
