import { DayPicker } from "react-day-picker";
import { Flex } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { HvTimePicker } from "./HvTimePicker";
import {
  INPUT_BACKGROUND_COLOR,
  INPUT_PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import "react-day-picker/dist/style.css";
import styled from "styled-components";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";

const DayPickerDivStyler = styled("div")`
  .my-selected:not([disabled]) {
    font-weight: 600;
    border: 1px solid orange;
    background: orange;
    color: white;
  }

  .my-selected:hover:not([disabled]) {
    color: black;
  }

  .my-today {
    font-weight: bold;
    border: 1px dashed currentColor;
  }
`;

interface HvDateTimePickerProps {
  value?: string;
  onChange: (startDate: string, timeZone: string) => void;
  isMinTomorrow?: boolean;
}

export const HvDateTimePicker = (props: HvDateTimePickerProps) => {
  const { value, onChange, isMinTomorrow } = props;
  const { selectedUserLanguage } = useContext<IGlobalProvider>(GlobalContext);

  const DEFAULT_TIME = "08:00:00";

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [date, setDate] = useState<Date | undefined>();
  const [time, setTime] = useState<string>();
  const [local, setLocal] = useState<any>(null);

  useEffect(() => {
    switch (selectedUserLanguage) {
      default:
        setLocal(null);
        break;
      case "es":
        setLocal(es);
        break;
      case "fr":
        setLocal(fr);
        break;
    }
  }, [selectedUserLanguage]);

  const handleTimeUpdate = (value: any) => {
    setTime(value);
  };

  useEffect(() => {
    if (value) {
      const dateTimeParts = value.split("T");
      const datePart = dateTimeParts[0];
      const timePart = dateTimeParts[1].substring(0, 8);
      let date = new Date(datePart);
      date.setDate(date.getDate() + 1);
      setDate(date);
      setTime(timePart);
    } else {
      const dte = new Date();
      if (isMinTomorrow) {
        dte.setDate(dte.getDate() + 1);
      }

      setDate(dte);

      setTime(DEFAULT_TIME);
    }
  }, []);

  useEffect(() => {
    if (date && time) {
      const startDate = date?.toISOString().split("T")[0] + "T" + time;
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      onChange(startDate, timeZone);
    }
  }, [date, time]);

  return (
    <Flex flexDir={"column"} gap={4}>
      {time && <HvTimePicker onChange={handleTimeUpdate} time={time} />}
      <Flex
        bgColor={INPUT_BACKGROUND_COLOR}
        borderRadius={"5px"}
        color={INPUT_PRIMARY_TEXT_COLOR}
        border={"1px solid lightGrey"}
        w={"320px"}
        gap={4}
      >
        <DayPickerDivStyler>
          <DayPicker
            mode="single"
            locale={local}
            selected={date}
            onSelect={setDate}
            disabled={{ before: isMinTomorrow ? tomorrow : today }}
            modifiersClassNames={{
              selected: "my-selected",
              today: "my-today",
            }}
            modifiersStyles={{
              disabled: { fontSize: "75%" },
            }}
          />
        </DayPickerDivStyler>
      </Flex>
    </Flex>
  );
};
