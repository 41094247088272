import { Box, SlideFade, Text, useToken } from "@chakra-ui/react";
import { useEffect } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { SlideFade_Offset } from "../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../Testing/dataTestIds";
import { HeadZoneStateRanges } from "../../../Utils/HeadzoneUtil";
import {
  getBrainZone,
  getBrainZoneInfo,
} from "../../Solo/Tools/HeadZone/HeadZoneToolUtil";
import { HeadZoneToolRoundSlider } from "../../Solo/Tools/HeadZone/HeadZoneToolRoundSlider";
import { WelcomeModalHeader } from "./WelcomeModalHeader";
import { WelcomeModalSubheader } from "./WelcomeModalSubheader";
import {
  HeadzoneStepDetails,
  NanoPracticeDto,
  QuestionAnswerDto,
} from "@headversity/contract";
import AIFollowUpPanel from "../../Solo/NanoPractices/AIFollowUpPanel";
import {
  StepInput,
  StepInputProps,
} from "../../Solo/NanoPractices/NanoPracticeModal";
import { getHeadZoneTextA } from "../../Solo/NanoPractices/NanoPracticeUtils";
import { ImmediateAssistance } from "./ImmediateAssistance";

interface StepHeadzoneProps {
  isHeadzoneDirty: any;
  headZoneValue: number;
  onHeadzoneValueChange: (headZoneValue: number) => void;
  headZoneColorValue: any;
  setHeadZoneColorValue: any;
  headzoneStepDetails: HeadzoneStepDetails;
  useTheme?: boolean;
  aiPrompt?: string;
  inputQuestionAnswers?: QuestionAnswerDto[];
  setAiResponse?: (aiResponse: string) => void;
  aiResponse?: string;
  practice: NanoPracticeDto;
  setIsNextButtonDisabled?: (disabled: boolean) => void;
  stepInputs?: StepInputProps;
  ignoreAIResponse?: boolean;
  setIgnoreAIResponse?: (ignore: boolean) => void;
}

export const StepHeadzone = ({
  isHeadzoneDirty,
  headZoneValue,
  onHeadzoneValueChange,
  headZoneColorValue,
  setHeadZoneColorValue,
  headzoneStepDetails,
  useTheme,
  aiPrompt,
  inputQuestionAnswers,
  setAiResponse,
  aiResponse,
  practice,
  setIsNextButtonDisabled,
  stepInputs,
  ignoreAIResponse,
  setIgnoreAIResponse,
}: StepHeadzoneProps) => {
  const [fourthTextColor] = useToken("colors", ["FOURTH_TEXT_COLOR"]);

  const resultTextContent = (description: string) => {
    return (
      <Text fontWeight={400}>
        {" " + description}

        {headZoneValue < HeadZoneStateRanges.burnOut && (
          <ImmediateAssistance from="Headzone" />
        )}
      </Text>
    );
  };

  const getRangeFromValue = (value: number) => {
    const pos = getBrainZone(value);
    return headzoneStepDetails.ranges[pos];
  };

  useEffect(() => {
    if (setIsNextButtonDisabled && !isHeadzoneDirty) {
      setIsNextButtonDisabled(true);
    }
  }, [isHeadzoneDirty]);

  return (
    <>
      {!isHeadzoneDirty && (
        <SlideFade in={true} offsetY={SlideFade_Offset}>
          <Box userSelect={"none"} mb={8}>
            <WelcomeModalHeader useTheme={useTheme}>
              <>{headzoneStepDetails.prompt.toString()}</>
            </WelcomeModalHeader>

            <WelcomeModalSubheader useTheme={useTheme} mt={5}>
              <Text
                as="span"
                fontWeight={"600"}
                dangerouslySetInnerHTML={{
                  __html: HVLocalizeStrings.WELCOME_HEADZONE_SELECT_ZONE,
                }}
              />{" "}
              <Text
                as="span"
                dangerouslySetInnerHTML={{
                  __html: HVLocalizeStrings.WELCOME_HEADZONE_SELECT_DESCRIBE,
                }}
              />
            </WelcomeModalSubheader>
          </Box>

          <HeadZoneToolRoundSlider
            setHeadZoneValue={(val: any) => {
              if (!val) return;

              onHeadzoneValueChange(val);
              setHeadZoneColorValue(getBrainZoneInfo(val).stateColor);
            }}
            getBrainImage={(val: number) => {
              return getBrainZoneInfo(val).brainImage;
            }}
            getHeadZoneStateText={(val: number) => {
              return getRangeFromValue(val).title.toString();
            }}
            headZoneColorValue={headZoneColorValue}
            dataTestId={HVTestId.WelcomeModal.steps.StepHeadzone.headzoneCircle}
            practiceId={practice.id}
            useTheme={useTheme}
          />
        </SlideFade>
      )}

      {isHeadzoneDirty && (
        <SlideFade in={true} offsetY={SlideFade_Offset}>
          <Box m={0}>
            <WelcomeModalHeader useTheme={useTheme}>
              {getHeadZoneTextA(headZoneValue) + " "}
              <strong
                style={{ color: fourthTextColor }}
                data-testid={HVTestId.WelcomeModal.steps.StepHeadzone.zoneText}
              >
                {getRangeFromValue(headZoneValue).zone.toString()}
              </strong>{" "}
              {HVLocalizeStrings.ZONE}
              {getBrainZoneInfo(headZoneValue).punctuation}
            </WelcomeModalHeader>
            <WelcomeModalSubheader mt={8} useTheme={useTheme}>
              {stepInputs ? (
                <>
                  {headZoneValue < HeadZoneStateRanges.burnOut &&
                    resultTextContent(
                      getRangeFromValue(headZoneValue).description.toString()
                    )}
                  <Box fontSize={"16px"} mt={"8"}>
                    <StepInput
                      step={stepInputs.step}
                      setIsNextButtonDisabled={
                        stepInputs.setIsNextButtonDisabled
                      }
                      onEnterKeyDown={stepInputs.onEnterKeyDown}
                      setInputQuestionAnswers={
                        stepInputs.setInputQuestionAnswers
                      }
                      inputQuestionAnswers={stepInputs.inputQuestionAnswers}
                      inputs={stepInputs.inputs}
                      setInputs={stepInputs.setInputs}
                    />
                  </Box>
                </>
              ) : aiPrompt && !ignoreAIResponse ? (
                <Box fontWeight={400} mt={"-5"}>
                  <AIFollowUpPanel
                    setAiResponse={setAiResponse as any}
                    setIgnoreAIResponse={setIgnoreAIResponse}
                    aiResponse={aiResponse as string}
                    inputQuestionAnswers={
                      inputQuestionAnswers as QuestionAnswerDto[]
                    }
                    setIsNextButtonDisabled={setIsNextButtonDisabled}
                    aiPrompt={aiPrompt}
                    practice={practice}
                  />
                </Box>
              ) : (
                resultTextContent(
                  getRangeFromValue(headZoneValue).description.toString()
                )
              )}
            </WelcomeModalSubheader>
          </Box>
        </SlideFade>
      )}
    </>
  );
};
