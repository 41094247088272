import {
  ItemRenderProps,
  TreeView,
  TreeViewCheckChangeEvent,
  TreeViewCheckChangeSettings,
  TreeViewCheckDescriptor,
  TreeViewDragAnalyzer,
  TreeViewDragClue,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
  TreeViewItemDragEndEvent,
  TreeViewItemDragOverEvent,
  TreeViewOperationDescriptor,
  handleTreeViewCheckChange,
  processTreeViewItems,
} from "@progress/kendo-react-treeview";
import styled from "styled-components";

import { Box, Checkbox, Flex, Text, useToken } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  HierarchyContext,
  TreeViewDataItem,
} from "../../../State/Reach/ReachHierarchyContext";
import {
  expandedItemsToHierarchicalIndex,
  filterCheckToSameType,
  findItemByHierarchicalIndex,
  mapFromHierarchyIndexToExpandedItems,
  preorderSearchArray,
  preorderSearchArrayById,
  toggleCheckCustom,
  toggleCheckId,
  unionIndexes,
} from "./Hierarchy/HierarchyHelpers";
import { NodeType } from "../../Shared/Hierarchy/NodeType";

export enum TreeType {
  Builder,
  Selector,
}

export interface KendoTreeProps {
  mode: TreeType;
  disableDrag: boolean;
  initialTree: TreeViewDataItem[];
  maxNumSelections?: number;
  expandedFromSearch?: TreeViewOperationDescriptor; // expanded items from outside (such as searching)
  selectedItems?: string[];
  checkedItems?: number[];
  onCheckedItemsChanged?: (items: number[]) => void;
  onSelectedNodesChanged?: (items: TreeViewDataItem[]) => void;
  handleDragItemApiCall?: (
    draggedItem: TreeViewDataItem,
    targetItem: TreeViewDataItem
  ) => boolean;
  expandedItems?: string[];
  size?: "small" | "medium" | "large";
}

export const KendoTree = ({
  mode,
  disableDrag,
  initialTree,
  expandedFromSearch,
  maxNumSelections,
  selectedItems,
  checkedItems,
  onCheckedItemsChanged,
  onSelectedNodesChanged,
  handleDragItemApiCall,
  expandedItems,
  size,
}: KendoTreeProps) => {
  const { setSelectedNodes, selectedNodes } = useContext(HierarchyContext);

  const hierarchySelectedColor = useToken("colors", [
    "HIERARCHY_SELECTED_COLOR",
  ]);

  const StyledKendoTree = styled(TreeView)`
    && .k-treeview-leaf.k-selected {
      background-color: ${hierarchySelectedColor};
    }
    && .k-checkbox:checked {
      background: #3182ce;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27white%27 stroke-linecap=%27square%27 stroke-linejoin=%27square%27 stroke-width=%272%27 d=%27M3,8 l3,3 l7-7%27/%3e%3c/svg%3e");
      background-size: 70% 70%;
      background-repeat: no-repeat;
      background-position: center;
    }
    && .k-checkbox {
      border: 2px solid #e2e8f0;
    }
    && .k-animation-container {
      overflow: visible;
    }
    && .k-treeview-leaf {
      padding: 0.1rem 0.1rem;
    }
    && .k-treeview-item {
      margin: 0.1rem 0.1rem;
    }

    && .k-icon {
      font-size: 24px;
    }
  `;

  const dragClue = useRef<any>();
  const dragOverCnt = useRef<number>(0);
  const isDragDrop = useRef<boolean>(false);

  const [refresh, setRefresh] = useState(0); // State variable to trigger re-render
  const [tree, setTree] = useState<TreeViewDataItem[]>([]);
  const [expand, setExpand] = useState<TreeViewOperationDescriptor>({
    ids: [],
  });

  // checked nodes
  const [check, setCheck] = useState<string[] | TreeViewCheckDescriptor>([]);

  useEffect(() => {
    // expanded should be a union of checked and expanded items, and initial expanded list
    const searchAndInitial = unionIndexes(
      expandedFromSearch?.ids ? expandedFromSearch.ids : [],
      expandedItems?.slice() || []
    );
    let union = unionIndexes(expand.ids!, searchAndInitial);
    setExpand({ ids: union });
  }, [expandedFromSearch, expandedItems]);

  useEffect(() => {
    // selected nodes should be sync'd with check boxes
    if (selectedNodes && selectedNodes.length > 0) {
      const selectedIndexes = selectedNodes.map((node: TreeViewDataItem) =>
        preorderSearchArrayById(tree, node.nodeType, node.resourceId)
      );
      const selectedIndexesFlat = selectedIndexes.flat();
      setCheck(selectedIndexesFlat);
    } else {
      setCheck([]);
    }
  }, [selectedNodes]);

  useEffect(() => {
    if (checkedItems && checkedItems.length > 0 && initialTree) {
      // given a list of node ids, find the hierarchical indexes
      const checkedIndexes = checkedItems
        .map((nodeId) =>
          preorderSearchArrayById(initialTree, NodeType.Node, nodeId)
        )
        .flat();
      setCheck(checkedIndexes);

      // expanded items should always show checked items
      const expandFromChecked = mapFromHierarchyIndexToExpandedItems(
        initialTree!,
        checkedIndexes
      );
      const expandIndexesFromChecked = expandedItemsToHierarchicalIndex(
        initialTree!,
        expandFromChecked
      );
      // expanded should be a union of checked and expanded items
      const union = unionIndexes(
        expandIndexesFromChecked,
        expand.ids ? expand.ids : []
      );
      const finalExpanded = { ids: union };
      setExpand(finalExpanded);
    }
  }, [checkedItems]);

  useEffect(() => {}, [expandedItems]);

  useEffect(() => {
    setTree(initialTree);

    // when initial tree is updated, we also update the 'checked' items
    if (checkedItems && checkedItems.length > 0) {
      const checkedInInitialTree = checkedItems
        .map((nodeId) =>
          preorderSearchArrayById(initialTree, NodeType.Node, nodeId)
        )
        .flat();
      setCheck(checkedInInitialTree);
    }

    // get the hierarchy indexes of all items in the tree to expand them all
    const allIndexes = preorderSearchArray(initialTree, "");
    setExpand({ ids: allIndexes });
  }, [initialTree]);

  const onCheckChangeCustom = (item: TreeViewDataItem) => {
    const itemSearchResults = preorderSearchArrayById(
      tree,
      item.nodeType,
      item.resourceId
    );

    if (itemSearchResults.length !== 1) {
      throw new Error(`Expected only one item to be found: ${item.resourceId}`);
    }

    if (item.notSelectable) {
      return;
    }

    const itemHierarchicalIndex = itemSearchResults[0];
    // determine if we have checked up to the max number of selections
    const checkIndexArr = check as string[];
    const isUncheck = checkIndexArr.some(
      (index) => index === itemHierarchicalIndex
    );
    if (
      !isUncheck &&
      maxNumSelections &&
      checkIndexArr.length >= maxNumSelections
    ) {
      setRefresh((prev) => prev + 1); // Update state to trigger re-render
      return;
    }

    // if the item is not in the list, add it
    // if the item is in the list, remove it
    const newChecked = toggleCheckCustom(checkIndexArr, itemHierarchicalIndex);
    if (checkedItems && onCheckedItemsChanged) {
      const newCheckedIds = toggleCheckId(checkedItems, item.resourceId);
      onCheckedItemsChanged(newCheckedIds);
    }

    setCheck(newChecked);

    // convert filtered to selected nodes
    const filteredItems = newChecked.map((index) =>
      findItemByHierarchicalIndex(tree, index)
    );
    if (onSelectedNodesChanged) {
      onSelectedNodesChanged(filteredItems);
    }

    setSelectedNodes(filteredItems);

    // nudge refresh
    setRefresh((prev) => prev + 1); // Update state to trigger re-render
  };

  const CustomItem = (props: ItemRenderProps) => {
    return (
      <Flex flexDir="row">
        {mode === TreeType.Selector && !props.item.notSelectable && (
          <Checkbox
            isChecked={props.item.checked}
            onChange={(e) => {
              onCheckChangeCustom(props.item);
            }}
            size="md"
            mr={2}
          ></Checkbox>
        )}
        <Text>{props.item.text}</Text>
      </Flex>
    );
  };

  const onItemClick = (event: TreeViewItemClickEvent) => {};

  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    if (!tree) {
      return;
    }
    const indexes: string[] = expand.ids ? expand.ids.slice() : [];

    // find hierarchy index of item
    const hIndex = preorderSearchArrayById(tree, NodeType.Node, event.item.id);
    const index: number = indexes.indexOf(hIndex[0]);
    // if the item is not in the list, add it
    index === -1 ? indexes.push(hIndex[0]) : indexes.splice(index, 1);
    const expandSelection = { ids: indexes }; // indexes by default
    setExpand(expandSelection);
  };

  const onCheckChange = (event: TreeViewCheckChangeEvent) => {
    const settings: TreeViewCheckChangeSettings = {
      singleMode: false,
      checkChildren: false,
      checkParents: false,
    };

    // determine what type of node is checked, we want to un-check all nodes of different types
    const item = findItemByHierarchicalIndex(tree, event.itemHierarchicalIndex);

    // determine if we have checked up to the max number of selections
    const checkIndexArr = check as string[];
    const isUncheck = checkIndexArr.some(
      (index) => index === event.itemHierarchicalIndex
    );
    if (
      !isUncheck &&
      maxNumSelections &&
      checkIndexArr.length >= maxNumSelections
    ) {
      return;
    }

    const handleBeforeFiltering = handleTreeViewCheckChange(
      event,
      check,
      [tree!],
      settings
    ) as any[];
    const filtered = filterCheckToSameType(
      handleBeforeFiltering as string[],
      item.nodeType,
      tree
    );

    setCheck(filtered);

    if (checkedItems && onCheckedItemsChanged) {
      const newCheckedIds = toggleCheckId(checkedItems, item.resourceId);
      onCheckedItemsChanged(newCheckedIds);
    }

    // convert filtered to selected nodes
    const filteredItems = filtered.map((index) =>
      findItemByHierarchicalIndex(tree, index)
    );
    if (onSelectedNodesChanged) {
      onSelectedNodesChanged(filteredItems);
    }

    setSelectedNodes(filteredItems);
  };

  const isDragAllowed = (params: {
    sourceIndex: string;
    destIndex: string;
  }): boolean => {
    const destItem = findItemByHierarchicalIndex(tree, params.destIndex);
    const allowedResult = destItem.nodeType === NodeType.Node;
    return allowedResult;
  };

  const onItemDragOver = (event: TreeViewItemDragOverEvent) => {
    const eventAnalyzer = new TreeViewDragAnalyzer(event).init();

    const iconDragAllowed = "k-i-plus";
    const iconDragNotAllowed = "k-i-cancel";

    const destIndex = eventAnalyzer.destinationMeta?.itemHierarchicalIndex;
    const sourceIndex = event.itemHierarchicalIndex;

    let dragAllowed = false;
    if (destIndex && sourceIndex) {
      dragAllowed = isDragAllowed({ sourceIndex, destIndex });
    }
    dragOverCnt.current++;
    dragClue.current.show(
      event.pageY + 10,
      event.pageX,
      event.item.text,
      dragAllowed ? iconDragAllowed : iconDragNotAllowed
    );
  };

  const onItemDragEnd = (event: TreeViewItemDragEndEvent) => {
    isDragDrop.current = dragOverCnt.current > 0;
    dragOverCnt.current = 0;
    dragClue.current.hide();

    const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
    const dbgDestinationMeta = eventAnalyzer.destinationMeta;

    const targetItem = findItemByHierarchicalIndex(
      tree,
      dbgDestinationMeta.itemHierarchicalIndex
    );

    const draggedItem = event.item;
    handleDragItemApiCall?.(draggedItem, targetItem);
  };

  const processedItems = processTreeViewItems(tree, {
    select: selectedItems || [],
    check: check,
    expand: expand,
  });

  return (
    <Box p={2}>
      <StyledKendoTree
        data={processedItems}
        expandIcons={true}
        onExpandChange={onExpandChange}
        aria-multiselectable={true}
        onItemClick={onItemClick}
        checkboxes={mode === TreeType.Builder} // checkboxes are only used during Builder
        onCheckChange={onCheckChange}
        size={size || undefined}
        item={CustomItem} // custom item renderer
        // drag and drop
        draggable={!disableDrag}
        onItemDragOver={onItemDragOver}
        onItemDragEnd={onItemDragEnd}
      />
      <TreeViewDragClue ref={dragClue} />
    </Box>
  );
};
