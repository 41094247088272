import {
  AvailableReportsDto,
  CertDto,
  CompanyDto,
  NodeDto,
} from "@headversity/contract";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ICertReportProvider,
  useCertReport,
} from "../../Components/Reach/Insights/useCertReport";
import {
  IHelpResourceReportProvider,
  useHelpResourceReport,
} from "../../Components/Reach/Insights/useHelpResourcesReport";
import {
  IPsychographicsReportProvider,
  usePsychographicsReport,
} from "../../Components/Reach/Insights/usePsychographicsReport";
import {
  ISoloAndTeamReportProvider,
  useSoloAndTeamReport,
} from "../../Components/Reach/Insights/useSoloAndTeamReport";
import {
  IUsersReportProvider,
  useUsersReport,
} from "../../Components/Reach/Insights/useUsersReport";

import {
  getAvailableReports,
  getCompanyCertsForReach,
} from "../../Api/Reach/ReachApi";
import { getUserCompany } from "../../Api/Solo/ProfileApi";
import { getKey } from "../../Utils/Helpers";
import { GlobalContext, IGlobalProvider } from "../GlobalContext";
import { HvSelectOption } from "../../Components/Common/HvSelect";
import { useHierarchy } from "../../Components/Reach/Shared/useHierarchy";

type InsightsContextState = {
  fetchData: () => Promise<void>;
  hierarchyNodes: NodeDto[];
  nodesFilter: NodeDto[];
  setNodesFilter: (filter: NodeDto[]) => void;
  handleNodeSelectionsChange: (selected: HvSelectOption[]) => void;
  availableReports: AvailableReportsDto;
  usersReport: IUsersReportProvider;
  psychographicsReport: IPsychographicsReportProvider;
  helpResourceReport: IHelpResourceReportProvider;
  certReport: ICertReportProvider;
  soloAndTeamReport: ISoloAndTeamReportProvider;
  companyCerts: CertDto[];
  company?: CompanyDto;
};

export const Interval180Days = 180;

export const ReachInsightsContext = createContext<InsightsContextState>({
  fetchData: async () => {},
  availableReports: {
    solo: false,
    team: false,
    cert: false,
  },
  hierarchyNodes: [],
  nodesFilter: [],
  setNodesFilter: () => {},
  handleNodeSelectionsChange: () => {},
  usersReport: {
    fetchReport: async () => {},
    userPercentageData: {
      enrolledUserHeading: "",
      enrolledUsersDescription: "",
      activeUsersHeading: "",
      activeUsersDescription: "",
    },
    lowData: false,
    isLoading: true,
    intervalDays: Interval180Days,
  },
  psychographicsReport: {
    fetchReport: async () => {},
    responseCounts: 0,
    psychographicsStrengths: [],
    psychographicsRisks: [],
    lowData: false,
    isLoading: true,
    intervalDays: Interval180Days,
  },
  helpResourceReport: {
    fetchReport: async () => {},
    helpResourcesViewsData: [],
    intervalDays: Interval180Days,
    lowData: false,
  },
  certReport: {
    fetchReport: async () => {},
    certSurveyGrowthData: [],
    allTimeCertCountsData: {
      assignedCertifications: 0,
      activeCertifications: 0,
    },
    activeCertsBreakdownData: [],
    certActivitySeriesData: [],
    certUserFeedbackReport: [],
    certActivityReport: { noData: true, report: [] },
    certSurveyGrowth: { noData: true, report: [] },
    certQualifiedUsers: { noData: true, report: [] },
    perCertUserFeedback: { noData: true, report: [] },
    lowDataSurveyGrowth: false,
    lowDataUserFeedback: false,
    lowDataRunningCounts: false,
    lowDataActiveCertsBreakdown: false,
    isLoading: true,
    intervalDays: Interval180Days,
  },
  soloAndTeamReport: {
    fetchReport: async () => {},
    popularNanoPractice: [],
    popularTeamLessons: [],
    popularMicroLessons: [],
    userFeedback: [],
    selectedPursuits: [],
    activitiesBreakdown: [],
    lowDataSoloActivityUserFeedback: false,
    lowDataSoloActivityBreakdown: false,
    lowDataTeamLessonCount: false,
    lowDataSoloMicroLessonCounts: false,
    lowDataSoloNanoPracticeCounts: false,
    lowDataSoloSelectedPursuits: false,
    isLoading: true,
    intervalDays: Interval180Days,
  },
  companyCerts: [],
});

export const ReachInsightsContextProvider: FC<{ children: ReactNode }> = (
  props
) => {
  const { self, selectedUserLanguage, userHierarchyAssociations, isReachAdminEnabled } =
    useContext<IGlobalProvider>(GlobalContext);
  const usersReport = useUsersReport();
  const psychographicsReport = usePsychographicsReport();
  const helpResourceReport = useHelpResourceReport();
  const certReport = useCertReport();
  const soloAndTeamReport = useSoloAndTeamReport();
  const hierarchy = useHierarchy();
  const [availableReports, setAvailableReports] = useState<AvailableReportsDto>(
    {
      solo: false,
      team: false,
      cert: false,
    }
  );
  const [companyCerts, setCompanyCerts] = useState<CertDto[]>([]);
  const [company, setCompany] = useState<CompanyDto | undefined>();

  useEffect(() => {
    if (isReachAdminEnabled && userHierarchyAssociations?.hierarchyId) {
      hierarchy.handleFetchHierarchy();
    }
  }, [userHierarchyAssociations, isReachAdminEnabled]);

  const handleFetchData = useCallback(async () => {
    let hierarchyFilter =
      hierarchy.nodesFilter.length > 0
        ? {
            nodesFilter: hierarchy.nodesFilter.map((node) => node.id),
          }
        : undefined;
    usersReport.fetchReport(hierarchyFilter);
    psychographicsReport.fetchReport(hierarchyFilter);
    helpResourceReport.fetchReport(hierarchyFilter);
    certReport.fetchReport(hierarchyFilter);
    soloAndTeamReport.fetchReport(hierarchyFilter);
    if (self !== null) {
      const companyCertsResponse = await getCompanyCertsForReach(
        self.companyId,
        getKey()
      );
      setCompanyCerts(companyCertsResponse.data);
    }

    const available = await getAvailableReports(getKey());

    setAvailableReports(available.data);

    const companyResponse = await getUserCompany(getKey());
    setCompany(companyResponse.data);
  }, [selectedUserLanguage, hierarchy.nodesFilter]);

  const state: InsightsContextState = useMemo(
    () => ({
      hierarchyNodes: hierarchy.hierarchyNodes,
      nodesFilter: hierarchy.nodesFilter,
      setNodesFilter: hierarchy.setNodesFilter,
      handleNodeSelectionsChange: hierarchy.handleNodeSelectionsChange,
      usersReport,
      psychographicsReport,
      helpResourceReport,
      certReport,
      soloAndTeamReport,
      fetchData: handleFetchData,
      companyCerts,
      availableReports,
      company,
    }),
    [
      hierarchy.hierarchyNodes,
      hierarchy.nodesFilter,
      hierarchy.handleNodeSelectionsChange,
      usersReport,
      psychographicsReport,
      helpResourceReport,
      certReport,
      soloAndTeamReport,
      handleFetchData,
      companyCerts,
      availableReports,
      company,
    ]
  );
  return (
    <ReachInsightsContext.Provider value={state}>
      {props.children}
    </ReachInsightsContext.Provider>
  );
};
