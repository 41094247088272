import { Box, IconButton, Menu, MenuButton } from "@chakra-ui/react";
import { CONTRAST_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { AppGridMenuList } from "./AppGridMenuList";
import { useContext } from "react";
import { ShellContext } from "../../../State/ShellContext";
import { BiCaretDown } from "react-icons/bi";

export const AppGridMenuMobileApp = ({ setIsOpen }: any) => {
  const { safeAreaInsets } = useContext(ShellContext);

  return (
    <Box pos="fixed" top={`${safeAreaInsets.top}px`} left="0px" zIndex={999}>
      <Menu>
        <MenuButton
          as={IconButton}
          minW="32px"
          w={"112px"}
          h={"32px"}
          bg={"transparent"}
          color={CONTRAST_TEXT_COLOR}
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          icon={<BiCaretDown style={{ width: "15px", height: "15px" }} />}
          className="open-menu-button-app"
          justifyContent={"flex-end"}
        />
        <AppGridMenuList setIsOpen={setIsOpen} />
      </Menu>
    </Box>
  );
};
