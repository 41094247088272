import {
  Box,
  Center,
  Flex,
  Image,
  ResponsiveValue,
  Text,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { AppType, GlobalContext } from "../../../State/GlobalContext";
import { getTotalPoints } from "../../../Utils/SkillsUtil";
import { PointsMap } from "./PointsMap";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { LargeModal } from "../../Common/LargeModal";
import { EVENTS, track } from "../../../Utils/Analytics";
import { ChakraBox } from "../../Common/ChakraBox";

interface PointsButtonProps {
  top: string;
  pos?: ResponsiveValue<"fixed" | "absolute" | "relative" | "static">;
  points?: number;
  currentPoints?: number;
  isInModal?: boolean;
}
export const PointsButton = ({
  top,
  pos,
  points,
  currentPoints,
  isInModal,
}: PointsButtonProps) => {
  const isMd = useBreakpointValue({ base: false, md: true });

  const {
    currentApp,
    userSkillStats,
    selectedGoal,
    selectedGoalBgImage,
    showPointsModal,
    setShowPointsModal,
  } = useContext(GlobalContext);

  const [animation, setAnimation] = useState<any>(undefined);
  const [pointsToShow, setPointsToShow] = useState("");

  useEffect(() => {
    const pointsToShow =
      points && currentPoints && points !== currentPoints
        ? `+${currentPoints - points}`
        : points
        ? points.toFixed(0).toString()
        : getTotalPoints(userSkillStats).toString();

    setPointsToShow(pointsToShow);

    if (!currentPoints || points === currentPoints) return;

    setAnimation({
      scale: [1, 2, 2, 1],
      rotate: [0, 0, 360, 360],
    });

    setTimeout(() => {
      setPointsToShow(currentPoints.toString());
    }, 3000);
  }, [points, currentPoints, userSkillStats]);

  return (
    <>
      {currentApp === AppType.SOLO && (
        <Tooltip label={`${pointsToShow} XP`} isDisabled={!isMd}>
          <ChakraBox
            animate={animation}
            // @ts-ignore no problem in operation, although type error appears.
            transition={{
              duration: 3,
              ease: "easeInOut",
            }}
            pos={pos ? pos : "fixed"}
            top={top}
            right={isInModal ? "45px" : "49px"}
            zIndex={999}
            className="points-button"
          >
            <Flex
              as={isInModal ? undefined : "button"}
              onClick={
                isInModal
                  ? undefined
                  : () => {
                      track(EVENTS.PointsMapModalShown, {
                        HV_From: "PointsButton",
                      });

                      setShowPointsModal(true);
                    }
              }
              h={"30px"}
              w={"30px"}
              filter={"drop-shadow(0 0 0.1rem #ffffff)"}
            >
              <Image
                w="30px"
                h="30px"
                src={`https://cdn.headversity.com/app/resources/shield/core-shield.svg`}
              />
              <Center pos="absolute" w="30px" h="30px" color="white">
                <Text
                  fontSize={
                    pointsToShow.length < 3
                      ? "12px"
                      : pointsToShow.length < 4
                      ? "10px"
                      : pointsToShow.length < 5
                      ? "9px"
                      : "8px"
                  }
                  fontWeight={"500"}
                >
                  {pointsToShow}
                </Text>
              </Center>
            </Flex>
          </ChakraBox>
        </Tooltip>
      )}

      {showPointsModal && (
        <LargeModal
          open={showPointsModal}
          setOpen={setShowPointsModal}
          bgImage={selectedGoal ? selectedGoalBgImage : undefined}
          forceDarkMode={true}
        >
          <Box minH="550px">
            <PointsMap />
            <BottomActionBar
              nextButtonText={HVLocalizeStrings.CLOSE}
              onNextButtonClick={() => setShowPointsModal(false)}
            />
          </Box>
        </LargeModal>
      )}
    </>
  );
};
