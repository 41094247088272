import { useContext, useState } from "react";
import styled from "styled-components";
import { Flex, Link, Spinner } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { FORGOT_PASSWORD_STEP } from "./LoginPage";
import { Equity_Black } from "../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { loginAndGetAccessToken } from "../../../Api/People/AccessTokenApi";
import { useNavigate } from "react-router-dom";
import { HVTestId } from "../../../Testing/dataTestIds";
import { peekRedirectPath } from "../../../Utils/NavigationUtils";
import { generateErrorFromErrorName } from "../Shared/ErrorCodes";
import { UserDto, RegistrationLoginStates } from "@headversity/contract";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  setLocalStorageAuthTokens,
  processHubspotRedirect,
} from "../../../Utils/LoginUtil";
import FormInputField from "../Shared/FormInputField";
import { HvReCaptcha } from "../../Common/HvReCaptcha";
import PasswordInput from "../Shared/PasswordInput";
import { useSSOCheck } from "../../../Hooks/People/useSSOCheck";
import {
  LocalStorageKeys,
  LocalStorageWrapper,
} from "../../../Utils/StorageUtil";
import { SELECTED_USER_LANGUAGE_KEY } from "../../../Utils/LanguageUtil";

const SingleSignOnContainer = styled.div`
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${Equity_Black} !important;
  background: transparent !important;
  padding: 0 0 2px !important;
  margin: 0 !important;
`;

interface SignInStepProps {
  show: boolean;
  setCurrentStep: (currentStep: string | null) => void;
  setError: (error: string | null) => void;
}

export const SignInStep = (props: SignInStepProps) => {
  const { show, setCurrentStep, setError } = props;
  const { updateSelectedUserLanguageToServer, getRedirectUrlFromServer } =
    useContext<IGlobalProvider>(GlobalContext);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>("");
  const [password, setPassword] = useState<string | null>("");

  const [isShowingReCaptcha, setIsShowingReCaptcha] = useState<boolean>(false);
  const [signUpFailureCounter, setSignUpFailureCounter] = useState<number>(0);

  const navigate = useNavigate();
  const { singleSignOnEnabled, passwordEnabled, ssoUrl } = useSSOCheck(email);

  const onCaptchaChange = () => {
    setIsShowingReCaptcha(false);
  };

  const isSubmissionDisabled = () => {
    return (
      (!(email?.trim() && password?.trim()) && !singleSignOnEnabled) ||
      isShowingReCaptcha ||
      isSubmitting
    );
  };

  const doSubmission = () => {
    setIsSubmitting(true);
    // SSO login, redirect to cognito oauth.
    if (singleSignOnEnabled) {
      window.location.href = ssoUrl;
    } else {
      if (!email || !password) {
        return;
      }
      setSignUpFailureCounter(signUpFailureCounter + 1);
      loginAndGetAccessToken(email, password)
        .then((response) => {
          if (response?.data?.error_message) {
            setError(generateErrorFromErrorName(response?.data?.error_message));
            if (signUpFailureCounter >= 2) {
              setIsShowingReCaptcha(true);
            }
          } else if (response?.data?.access_token) {
            if (processHubspotRedirect(response, getRedirectUrlFromServer)) {
              return;
            }
            const mobileLogin = sessionStorage.getItem("isMobile");
            if (mobileLogin) {
              // Setting the mobile login access token and refresh token
              // in local storage instead.  It will be sent back to the
              // mobile add view the mobilePostLogin component.  We open
              // a browser to login via mobile, storing this in local storage
              // can create issues with getKey, it will think is login and end
              // up in the headversity site.
              sessionStorage.setItem("dt", response.data.access_token);
              const refreshToken = response.data.refresh_token;
              LocalStorageWrapper.setItem(
                LocalStorageKeys.RT,
                refreshToken
              ).then(() => {
                navigate("/mobilePostLogin");
              });
            } else {
              setLocalStorageAuthTokens(response?.data).then(() => {
                const path = peekRedirectPath();
                navigate(path, { replace: true });
              });
              updateSelectedUserLanguageToServer(
                response?.data as UserDto,
                localStorage.getItem(SELECTED_USER_LANGUAGE_KEY) ??
                  HVLocalizeStrings.getLanguage()
              );
            }
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const handleForgotPassword = () => {
    setError(null);
    setCurrentStep(FORGOT_PASSWORD_STEP);
  };

  const handleChange = (
    stateToUpdate: RegistrationLoginStates,
    stateText: string
  ) => {
    setError(null);
    switch (stateToUpdate) {
      case RegistrationLoginStates.Password:
        setPassword(stateText.trim());
        break;
      case RegistrationLoginStates.Email:
        setEmail(stateText?.trim());
        break;
      default:
        return;
    }
  };

  return (
    <Flex flexDir={"column"} align={"center"} gap={"2"}>
      {singleSignOnEnabled && (
        <SingleSignOnContainer>
          <span>{HVLocalizeStrings.SINGLE_SIGN_ON_ENABLED}</span>
        </SingleSignOnContainer>
      )}
      <Flex flexDir={"column"} w={"300px"} gap={"2"}>
        <FormInputField
          id={"email"}
          errorMessage={""}
          imageSrc={
            "https://cdn.headversity.com/app/resources/login/envelope.png"
          }
          imageW={"18px"}
          imageH={"18px"}
          placeholder={HVLocalizeStrings.EMAIL_PLACEHOLDER}
          handleChange={handleChange}
          stateToUpdate={RegistrationLoginStates.Email}
          type={"email"}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isSubmissionDisabled()) {
              doSubmission();
            }
          }}
          autoFocus
        />
        {passwordEnabled && (
          <PasswordInput
            passwordError={""}
            hideToolTip={true}
            pwdHasNumber={true}
            pwdHasLowerCase={true}
            pwdHasUpperCase={true}
            pwdHasMinLength={true}
            handleChange={handleChange}
            onKeyDown={(e: any) => {
              if (e.key === "Enter" && !isSubmissionDisabled()) {
                doSubmission();
              }
            }}
          />
        )}
      </Flex>
      {isShowingReCaptcha && <HvReCaptcha onChange={onCaptchaChange} />}
      {show && (
        <>
          {isSubmitting ? (
            <Spinner />
          ) : (
            <AnimatedButton
              disabled={isSubmissionDisabled()}
              text={HVLocalizeStrings.LOGIN_PAGE_SIGN_IN}
              colorSet={AnimatedButtonColorSet.Primary}
              onClick={doSubmission}
              w={300}
              dataTestId={HVTestId.SignInStep.loginButton}
            />
          )}
        </>
      )}
      <Link
        textDecoration={"underline"}
        mt={2}
        fontSize={"sm"}
        style={{ display: show ? "block" : "none" }}
        onClick={() => handleForgotPassword()}
        data-testid={HVTestId.SignInStep.forgotPasswordLink}
      >
        {HVLocalizeStrings.LOGIN_FORGOT_PASSWORD}
      </Link>
    </Flex>
  );
};
