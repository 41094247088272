import { LargeModal } from "../../Common/LargeModal";
import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Border_Radius,
  Equity_Black,
  INPUT_BACKGROUND_COLOR,
  INPUT_PRIMARY_TEXT_COLOR,
  MODAL_SECONDARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import {
  HVLocalizeStrings,
  HVLocalizeStringsObj,
} from "../../../Localization/HVLocalizeStrings";
import ReachInviteCodeSelector from "../Shared/ReachInviteCodeSelector";
import { useContext, useEffect, useState } from "react";
import { ReachUsersContext } from "../../../State/Reach/ReachUsersContext";
import { LanguageSelector } from "../../Common/LanguageSelector";
import ReachCopyToClipboard from "../Shared/ReachCopyToClipboard";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";
import { ShellContext } from "../../../State/ShellContext";
import { GlobalContext } from "../../../State/GlobalContext";

interface ShareInviteCodeModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onCloseTasks?: () => void;
}

const ShareInviteCodeModal = (props: ShareInviteCodeModalProps) => {
  const { isOpen, setIsOpen, onCloseTasks } = props;
  const { showToast } = useContext(ShellContext);
  const { selectedUserLanguage } = useContext(GlobalContext);
  const { inviteCodesForReach } = useContext(ReachUsersContext);
  const [selectedInviteCodeId, setSelectedInviteCodeId] = useState<
    number | undefined
  >();
  const [selectedLanguage, setSelectedLanguage] =
    useState<string>(selectedUserLanguage);
  const [sharingMessage, setSharingMessage] = useState<string>("");

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const originUrl = process.env.REACT_APP_ORIGIN as string;

  useEffect(() => {
    if (selectedInviteCodeId === undefined || selectedInviteCodeId === 0) {
      setSharingMessage("");
      return;
    }
    const inviteCode = inviteCodesForReach.find(
      (ic) => ic.id === selectedInviteCodeId
    );
    if (inviteCode === undefined) {
      setSharingMessage("");
      return;
    }

    const message = inviteCode.isFamily
      ? {
          en: HVLocalizeStringsObj.en
            .REACH_USERS_SHARING_FAMILY_INVITE_CODE_MESSAGE,
          fr: HVLocalizeStringsObj.fr
            .REACH_USERS_SHARING_FAMILY_INVITE_CODE_MESSAGE,
          es: HVLocalizeStringsObj.es
            .REACH_USERS_SHARING_FAMILY_INVITE_CODE_MESSAGE,
        }
      : {
          en: HVLocalizeStringsObj.en.REACH_USERS_SHARING_INVITE_CODE_MESSAGE,
          fr: HVLocalizeStringsObj.fr.REACH_USERS_SHARING_INVITE_CODE_MESSAGE,
          es: HVLocalizeStringsObj.es.REACH_USERS_SHARING_INVITE_CODE_MESSAGE,
        };

    setSharingMessage(
      (message as { [key: string]: string })[selectedLanguage]
        .replace("{0}", inviteCode.code)
        .replace("{1}", `https://${originUrl}/login?invite=${inviteCode.code}`)
    );
  }, [selectedInviteCodeId, selectedLanguage, inviteCodesForReach]);

  const copyToClipboard = async (inviteCodeId: number, language: string) => {
    const cleanedSharingMessage = sharingMessage
      .replace(/<br\/>/g, "\n")
      .replace(/<em>/g, "")
      .replace(/<\/em>/g, "");
    await navigator.clipboard.writeText(cleanedSharingMessage);
    showToast(
      true,
      "sharing-invite-code-copied-to-clipboard",
      HVLocalizeStrings.COPIED_TO_CLIPBOARD
    );
    track(REACH_EVENTS.ReachUsersSharedInviteCode, {
      HV_Invite_Code_Id: inviteCodeId,
      HV_Language: language,
    });
  };

  return (
    <LargeModal open={isOpen} setOpen={setIsOpen} onCloseTasks={onCloseTasks}>
      <Flex flexDirection={"column"} gap={4} mx={4}>
        <Heading
          fontSize={"xl"}
          color={MODAL_SECONDARY_TEXT_COLOR}
          fontWeight={"semibold"}
        >
          {HVLocalizeStrings.REACH_USERS_SHARING_IS_CARING}
        </Heading>
        <Text
          fontSize={"md"}
          color={MODAL_SECONDARY_TEXT_COLOR}
          dangerouslySetInnerHTML={{
            __html: HVLocalizeStrings.REACH_USERS_SHARING_IS_CARING_DESCRIPTION,
          }}
        ></Text>
        <Flex flexDirection={isMobile ? "column" : "row"} gap={3} mt={4}>
          <ReachInviteCodeSelector
            setSelectedInviteCodeId={setSelectedInviteCodeId}
            inviteCodes={inviteCodesForReach}
            selectedInviteCodeId={selectedInviteCodeId}
            hideLabel={true}
            mt={"0px"}
            width={"265px"}
          />
          <LanguageSelector
            onLanguageChanged={setSelectedLanguage}
            height={"40px"}
            width={"265px"}
            hasBorder={true}
            color={INPUT_PRIMARY_TEXT_COLOR}
            backgroundColor={INPUT_BACKGROUND_COLOR}
          />
        </Flex>
        {selectedInviteCodeId !== undefined && selectedInviteCodeId !== 0 && (
          <Center mt={4} pb={6}>
            <Box
              borderRadius={Border_Radius}
              boxShadow={"lg"}
              w={isMobile ? "95%" : "650px"}
              bg={"white"}
              pb={4}
            >
              <Text
                px={4}
                py={5}
                fontSize={"small"}
                color={Equity_Black}
                dangerouslySetInnerHTML={{ __html: sharingMessage }}
              />
              <ReachCopyToClipboard
                copyToClipboard={() =>
                  copyToClipboard(selectedInviteCodeId, selectedLanguage)
                }
                fontSize={"small"}
              />
            </Box>
          </Center>
        )}
      </Flex>
    </LargeModal>
  );
};

export default ShareInviteCodeModal;
