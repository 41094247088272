import {
  CertQualificationDto,
  SkillDto,
  UserSkillV2Dto,
  UserStatsResultsDto,
} from "@headversity/contract";
import { useCallback, useEffect, useState } from "react";
import {
  getBadgeData,
  getCertQualificationData,
  getUserSkillBySkillId,
} from "../../Utils/SkillsUtil";
import { BadgeData } from "../../Components/Common/BadgesAndShields/BadgeCard";
import dayjs from "dayjs";
import { setUserSkills } from "../../Api/Solo/SkillsApi";
import { getKey, parseJSON } from "../../Utils/Helpers";
import { CertQualificationData } from "../../Components/Cert/Shared/QualificationProgressCard";

export interface IUserSkillProvider {
  badgeData: BadgeData[];
  badgeDataForPoints: BadgeData[];
  certQualificationData: CertQualificationData[];
  certQualificationDataForPoints: CertQualificationData[];
  updateLocalUserScores: () => void;
  updateLocalCertificationQualificationScores: () => void;
  setUserStatsToServer: (useCache?: boolean) => Promise<UserStatsResultsDto>;
  userSkillStats: UserSkillV2Dto[];
  certQualifications: CertQualificationDto[];
}
export const useUserSkill = (skills: SkillDto[]): IUserSkillProvider => {
  const [badgeData, setBadgeData] = useState<BadgeData[]>([]);
  const [badgeDataForPoints, setBadgeDataForPoints] = useState<BadgeData[]>([]);
  const [userSkillStats, setUserSkillStats] = useState<UserSkillV2Dto[]>([]);
  const [certQualifications, setCertQualifications] = useState<
    CertQualificationDto[]
  >([]);
  const [certQualificationData, setCertQualificationData] = useState<
    CertQualificationData[]
  >([]);
  const [certQualificationDataForPoints, setCertQualificationDataForPoints] =
    useState<CertQualificationData[]>([]);

  useEffect(() => {
    if (userSkillStats.length === 0 || skills.length === 0) {
      return;
    }

    const localData = localStorage.getItem("user_scores");
    const hasSeenScoresModal = localStorage.getItem("has_user_points");
    const updatedLevels: BadgeData[] = [];
    const updatedPoints: BadgeData[] = [];

    if (localData) {
      const localSkillsStats: UserSkillV2Dto[] = parseJSON(localData);
      skills.forEach((skill) => {
        const userSkill = getUserSkillBySkillId(userSkillStats, skill.id);
        // if we don't have a userSkill, it means the that user has not unlocked the skill yet,
        // meaning there is no need to show a badge for this skill
        if (!userSkill || userSkill.level === 0) {
          return;
        }
        const localSkillStat = localSkillsStats.find(
          (local) => skill.id === local.skillId
        );
        if (
          localSkillStat === undefined ||
          localSkillStat?.points !== userSkill?.points
        ) {
          const badge = getBadgeData(skill, localSkillStat, userSkill);

          // separate out level increases
          if (badge.levelledUp) {
            updatedLevels.push(badge);
          } else {
            updatedPoints.push(badge);
          }
        }
      });

      // the first time we tell the user about points, we'll do it levelled-up style
      if (!hasSeenScoresModal) {
        setBadgeData(
          addInitialPointsScreen(updatedLevels.concat(updatedPoints)) ?? []
        );
      } else {
        setBadgeData(updatedLevels);
        setBadgeDataForPoints(updatedPoints);
      }
    }
  }, [skills, userSkillStats]);

  useEffect(() => {
    if (certQualifications.length === 0) {
      return;
    }

    const localData = localStorage.getItem("cert_qualifications");
    const updatedPeriods: CertQualificationData[] = [];
    const updatedPoints: CertQualificationData[] = [];

    if (localData) {
      const localCertQualificationsStats: CertQualificationDto[] =
        parseJSON(localData);
      certQualifications.forEach((certQualification) => {
        const localCertQualificationStat = localCertQualificationsStats.find(
          (local) => certQualification.certId === local.certId
        );
        if (
          localCertQualificationStat === undefined ||
          localCertQualificationStat?.pointsEarned !==
            certQualification?.pointsEarned
        ) {
          const certQualificationData = getCertQualificationData(
            localCertQualificationStat,
            certQualification
          );

          // separate out period increases
          if (certQualificationData.completedQualification) {
            updatedPeriods.push(certQualificationData);
          } else {
            updatedPoints.push(certQualificationData);
          }
        }
      });

      setCertQualificationData(updatedPeriods);
      setCertQualificationDataForPoints(updatedPoints);
    }
  }, [certQualifications]);

  const setUserStatsToServer = useCallback((useCache?: boolean) => {
    const day = dayjs.utc().local().format("YYYY-MM-DD");
    return setUserSkills(getKey(), { day, useCache }).then((response) => {
      const localSkillStats = localStorage.getItem("user_scores");
      if (localSkillStats === null) {
        localStorage.setItem(
          "user_scores",
          JSON.stringify(response.data.userSkillStats)
        );
      }
      const localCertQualificationStats = localStorage.getItem(
        "cert_qualifications"
      );
      if (localCertQualificationStats === null) {
        localStorage.setItem(
          "cert_qualifications",
          JSON.stringify(response.data.certQualifications)
        );
      }
      /*
      * simulate level 10 for all skills
      *
      for (const x of response.data.userSkillStats) {
        x.level = 10;
      }
      */

      setUserSkillStats(response.data.userSkillStats);
      setCertQualifications(response.data.certQualifications);
      return response.data;
    });
  }, []);

  const updateLocalUserScores = () => {
    const scores = JSON.stringify(userSkillStats);
    if (scores) {
      localStorage.setItem("user_scores", scores);
      localStorage.setItem("has_user_points", JSON.stringify(true));
    }

    // flush state
    setBadgeData([]);
    setBadgeDataForPoints([]);
  };

  const updateLocalCertificationQualificationScores = () => {
    const scores = JSON.stringify(certQualifications);
    localStorage.setItem("cert_qualifications", scores);

    // flush state
    setCertQualificationData([]);
    setCertQualificationDataForPoints([]);
  };

  const addInitialPointsScreen = (
    badgeData: BadgeData[]
  ): BadgeData[] | undefined => {
    if (badgeData.length > 0) {
      const initialData = {
        skillId: -1,
        iconUrl: badgeData[0]?.iconUrl,
        shieldName: badgeData[0]?.shieldName,
        shieldColor: badgeData[0]?.shieldColor,
        shieldOutlineUrl: badgeData[0]?.shieldOutlineUrl,
        levelledUp: false,
        previousPointsOnLevel: 0,
        newPointsOnLevel: 0,
        newLevel: badgeData[0]?.newLevel,
        totalLevels: 50,
        lastActivity: "",
        minutesTrained: 0,
        lessonsCompleted: 0,
        practicesCompleted: 0,
        postsCompleted: 0,
        lastLevelledUp: "",
      } as BadgeData;

      return [initialData, ...badgeData];
    }
  };

  return {
    badgeData,
    badgeDataForPoints,
    certQualificationData,
    certQualificationDataForPoints,
    updateLocalUserScores,
    updateLocalCertificationQualificationScores,
    setUserStatsToServer,
    userSkillStats,
    certQualifications,
  };
};
