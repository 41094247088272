import { Box, Center, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { AnimatedButton } from "./AnimatedButton";
import { HvTextarea } from "./HvTextarea";
import { LikeAndDislikeButton } from "./LikeAndDislikeButton";

interface Props {
  onResponse: (englishText: string, result: string) => void;
  onFeedback: (text: string) => void;
  onComplete: (wasLiked: boolean) => void;
  items: string[];
  items_eng: string[];
}

export const FeedbackPanel = ({
  onResponse,
  onComplete,
  onFeedback,
  items,
  items_eng,
}: Props) => {
  const [feedback, setFeedback] = useState<string[]>([]);
  const [feedbackText, setFeedbackText] = useState("");
  const [showFeedbackText, setShowFeedbackText] = useState(false);
  const [showFeedbackThanks, setShowFeedbackThanks] = useState(false);

  useEffect(() => {
    // don't re-render if we already have feedback
    if (feedback.length > 0 && feedback.some((x) => x !== "")) {
      return;
    }

    setFeedback(new Array(items.length).fill(""));
  }, [items]);

  return !showFeedbackText ? (
    <Box>
      {items.map((item, index) => {
        return (
          <Box key={index} mb="10px">
            <LikeAndDislikeButton
              result={feedback[index]}
              setResult={(result: string) => {
                feedback[index] = result;
                setFeedback([...feedback]);

                const englishText = items_eng[index];
                onResponse(englishText, result);

                if (feedback.every((f) => f !== "")) {
                  // any negative?
                  if (feedback.every((f) => f === "like")) {
                    onComplete(true);
                  } else {
                    setShowFeedbackText(true);
                  }
                }
              }}
              description={item}
            />
          </Box>
        );
      })}
    </Box>
  ) : (
    <Box w="100%">
      {showFeedbackThanks ? (
        <Center mt="10px">
          <Text mb={1}>{HVLocalizeStrings.THANK_YOU}.</Text>
        </Center>
      ) : (
        <Box>
          <Text mb={"5px"}>{HVLocalizeStrings.NEGATIVE_FEEDBACK_WHY}</Text>

          <HvTextarea
            placeholder={HVLocalizeStrings.PROVIDE_FEEDBACK}
            onChange={(e) => {
              setFeedbackText(e.target.value);
            }}
          />

          <Center mt="10px">
            <AnimatedButton
              disabled={feedbackText.length === 0}
              text={HVLocalizeStrings.SAVE}
              onClick={() => {
                onFeedback(feedbackText);

                setShowFeedbackThanks(true);

                setTimeout(() => {
                  onComplete(false);
                }, 2000);
              }}
            />
          </Center>
        </Box>
      )}
    </Box>
  );
};
