import { useContext, useEffect } from "react";
import { needsSkillScore } from "../../../Utils/SkillsUtil";
import { LessonCourseProvider } from "../../../State/Solo/LessonCourseContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
} from "../../../State/GlobalContext";

import { LessonModal } from "./LessonModal";
import { EVENTS, track } from "../../../Utils/Analytics";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

let firstLoad = true;

/**
 *
 * This modal relies on global state and processes url params; should only be used once per app
 */
export default function LessonPreviewModal({ open, setOpen }: Props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    lessons,
    setLessonNotFound,
    setGlobalLesson,
    lesson,
    skills,
    userSkillStats,
    setSelectedSkill,
    currentApp,
    globalHeadScanQuestionModalOpen,
    setGlobalHeadScanQuestionModalOpen,
    getLessonsFromServer,
    clearGlobalActivities,
  } = useContext<IGlobalProvider>(GlobalContext);

  useEffect(() => {
    if (
      open ||
      !searchParams.get("lesson") ||
      !lessons ||
      lessons.length === 0 ||
      globalHeadScanQuestionModalOpen ||
      !currentApp ||
      !userSkillStats ||
      (skills.length === 0 && currentApp !== AppType.CERT) ||
      (userSkillStats.length === 0 && currentApp !== AppType.CERT)
    ) {
      return;
    }

    let isLocked = false;
    const lesson = lessons.find(
      (item) => item.id === parseInt(searchParams.get("lesson") as string)
    );

    if (lesson) {
      const skill = skills.find(
        (x) => x.id === lesson?.skillCompetencies[0]?.skillId
      );

      // if we're in SOLO and we haven't scored for this skill, open headscan and then load item
      if (
        currentApp !== AppType.CERT &&
        currentApp !== AppType.SPORT &&
        needsSkillScore(userSkillStats, skill?.id)
      ) {
        navigate(window.location.pathname, { replace: true });
        setSelectedSkill(skill);
        setGlobalHeadScanQuestionModalOpen(true);
        setOpen(false);
        isLocked = true;
      }
    } else {
      setLessonNotFound(true);
      return;
    }

    clearGlobalActivities();
    setGlobalLesson(lesson);

    // this code handles opening the lesson if we hit the non-preview url directly
    if (
      (!searchParams.get("preview") || searchParams.get("preview") !== "1") &&
      searchParams.get("lesson") &&
      lessons?.length > 0 &&
      firstLoad &&
      !isLocked
    ) {
      firstLoad = false;

      setOpen(true);

      track(EVENTS.LessonStarted, { HV_Lesson: lesson?.name_loc?.en });
    }

    // this code handles opening the lesson preview if we hit the url directly (refactored from removed useLessonPreviewLauncher)
    if (
      !open &&
      searchParams.get("preview") &&
      searchParams.get("preview") === "1" &&
      !isLocked
    ) {
      setOpen(true);
    }
  }, [searchParams, lessons, userSkillStats, currentApp]);

  useEffect(() => {
    if (!lesson || !open) return;

    // if the modal was opened without search parameters (the user did not directly navigate to this modal with a URL), then set the search parameters automatically
    if (
      !searchParams.get("lesson") ||
      searchParams.get("lesson") !== lesson?.id.toString()
    ) {
      navigate(window.location.pathname + `?lesson=${lesson?.id}&preview=1`, {
        replace: true,
      });
    }
  }, [lesson, open]);

  useEffect(() => {
    if (open) {
      track(EVENTS.LessonModalOpened, {
        HV_LessonId: lesson?.id,
        HV_LessonTitle: lesson?.name_loc?.en,
      });
    }
  }, [open]);

  return (
    <>
      <LessonCourseProvider>
        {lesson && (
          <LessonModal
            lesson={lesson}
            title={lesson.name}
            open={open}
            setOpen={(open: boolean) => {
              if (!open) {
                // refresh when we close to get any new user instance
                getLessonsFromServer(skills);

                setGlobalLesson(null);

                // clear url
                navigate(window.location.pathname, { replace: true });
              }
              setOpen(open);
            }}
            data-testid="lesson_full_screen_modal"
          />
        )}
      </LessonCourseProvider>
    </>
  );
}
