import { Textarea } from "@chakra-ui/react";
import {
  INPUT_BACKGROUND_COLOR,
  INPUT_PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { ComponentProps } from "react";

export const HvTextarea = (props: ComponentProps<typeof Textarea>) => {
  return (
    <>
      <Textarea
        bg={INPUT_BACKGROUND_COLOR}
        color={INPUT_PRIMARY_TEXT_COLOR}
        maxLength={props.maxLength ?? 1000}
        {...props}
      />
    </>
  );
};
