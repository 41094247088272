import { useCallback, useState } from "react";
import {
  getFavorites,
  deleteFavorite,
  saveFavorite,
} from "../../Api/Solo/FavoritesApi";
import { getKey } from "../../Utils/Helpers";
import { FavoriteDto } from "@headversity/contract";

export interface IFavoriteProvider {
  favorites: FavoriteDto[];
  removeFavoriteFromServer: (favoriteId: number) => Promise<void>;
  saveFavoriteToServer: (favorite: FavoriteDto) => Promise<void>;
  getFavoritesFromServer: () => Promise<FavoriteDto[]>;
}

export const useFavorites = (): IFavoriteProvider => {
  const [favorites, setFavorites] = useState<FavoriteDto[]>([]);

  const getFavoritesFromServer = useCallback(async () => {
    return getFavorites(getKey()).then((result) => {
      setFavorites(result.data);
      return Promise.resolve(result.data);
    });
  }, []);

  const removeFavoriteFromServer = useCallback(async (favoriteId: number) => {
    return deleteFavorite(getKey(), favoriteId).then(() => {
      getFavoritesFromServer().then(() => {
        return Promise.resolve();
      });
      return Promise.resolve();
    });
  }, []);

  const saveFavoriteToServer = useCallback(async (favorite: FavoriteDto) => {
    return saveFavorite(getKey(), favorite).then((response: any) => {
      getFavoritesFromServer().then(() => {
        return Promise.resolve();
      });
      return response;
    });
  }, []);

  return {
    favorites,
    getFavoritesFromServer,
    removeFavoriteFromServer,
    saveFavoriteToServer,
  };
};
