export enum ErrorCodeType {
  EmailInvalid,
  EmailAlreadyExists,
  FullNameInvalid,
  PasswordInvalid,
  InviteCodeInvalid,
  TeamCodeDoesNotExist,
  TeamCodeIsOnWrongCompany,
  TeamOnWrongCompany,
  UserDoesNotBelongToTeam,
  InvalidOrAlreadyUsedCode = 10,
  InviteCodeIsExpired = 11,
  EmailHasProblem = 12,
  EmailDomainNotAllowed = 15,
  UserScormMatchingInvalid = 16,
  UserNotInCompany = 17,
}
