import { Box } from "@chakra-ui/react";
import { CompanyDto } from "@headversity/contract";
import { PDFExport } from "@progress/kendo-react-pdf";
import { FC, ReactNode, RefObject, useContext } from "react";
import {
  IShellProvider,
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../State/ShellContext";
import { Equity_Blue_80 } from "../../Styles/HeadversityStyle";
import PDFHeader from "../Shared/PDFDownload/PDFHeader";
import PDFView from "../Shared/PDFDownload/PDFView";
import PDFFooter from "../Shared/PDFDownload/PDFFooter";
import { CertInsightGlossary } from "../Reach/Insights/CertInsightGlossary";

export const PDFContainerCertInsightsPage: FC<{
  children: ReactNode[];
  company?: CompanyDto;
  pdfRef: RefObject<PDFExport>;
}> = ({ children, company, pdfRef }) => {
  const { theme } = useContext<IShellProvider>(ShellContext);
  return (
    <PDFView pdfRef={pdfRef}>
      <Box
        bgColor={
          theme.name === NOSTALGIC_THEME_NAME ? Equity_Blue_80 : undefined
        }
        minH="3508px"
      >
        {children.map((section, idx) => (
          <Box key={idx}>
            <PDFHeader
              skipBreak={idx === 0}
              logo={company?.logo}
              companyName={company?.name || ""}
            />
            {section}
          </Box>
        ))}
        {/* Glossary Section */}
        <Box>
          <PDFHeader logo={company?.logo} companyName={company?.name || ""} />
          <CertInsightGlossary hideTitle={false} />
          <PDFFooter />
        </Box>
      </Box>
    </PDFView>
  );
};
