import { useContext, useEffect, useState } from "react";
import { shuffle } from "../../../Utils/Helpers";
import { ProgressTracker } from "../../Common/ProgressTracker";
import { PagerState } from "../../Common/Pager";
import { AnimatedButton } from "../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { EVENTS, track } from "../../../Utils/Analytics";
import { HVTestId } from "../../../Testing/dataTestIds";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import {
  Border_Radius,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { ShellContext } from "../../../State/ShellContext";

import { Question } from "../../Shared/Questionnaire/Question";
import {
  HeadscanQuestionDto,
  QuestionAlternativeDto,
  QuestionType,
} from "../../../@headversity/contract";
import _ from "lodash";
interface HeadScanQuestionContentProps {
  headScanQuestion: HeadscanQuestionDto;
  saving: boolean;
  setSelectedResponse: (selectedResponse: {
    questionId: number;
    alternativeId: number;
  }) => void;
  selectedResponse: any;
  pager: PagerState;
  showNextQuestion: boolean;
  setShowNextQuestion: (showNextQuestion: boolean) => void;
  saveAnswer: () => void;
  showProgressTracker: boolean;
  forceDarkMode?: boolean;
}

export interface HeadScanResponse {
  questionId: number;
  alternativeId: number;
  scale: number;
}

export const HeadScanQuestionContent = (
  props: HeadScanQuestionContentProps
) => {
  const {
    headScanQuestion,
    setSelectedResponse,
    selectedResponse,
    pager,
    showNextQuestion,
    setShowNextQuestion,
    saveAnswer,
    saving,
    showProgressTracker,
    forceDarkMode,
  } = props;

  const { safeAreaInsets } = useContext(ShellContext);

  const isMd = useBreakpointValue({ base: false, md: true });

  const [alternatives, setAlternatives] = useState<QuestionAlternativeDto[]>(
    []
  );
  useEffect(() => {
    if (headScanQuestion?.question?.questionAlternatives && !saving) {
      track(EVENTS.HeadscanQuestionShown, {
        HV_HeadscanQuestionId: headScanQuestion.id,
      });

      if (headScanQuestion.question.questionType === QuestionType.Single) {
        shuffle(headScanQuestion.question.questionAlternatives);
      } else {
        headScanQuestion.question.questionAlternatives = _.sortBy(
          headScanQuestion.question.questionAlternatives,
          (questionAlternative: QuestionAlternativeDto) =>
            questionAlternative.scale
        );
      }
      setAlternatives(headScanQuestion.question.questionAlternatives);
    }
  }, [headScanQuestion]);

  return (
    <>
      {showProgressTracker && (
        <ProgressTracker
          step={pager.currentPage + 1}
          value={((pager.currentPage + 1) / pager.totalPage) * 100}
          totalStep={pager.totalPage}
          title={HVLocalizeStrings.QUESTION}
          forceDarkMode={forceDarkMode}
        />
      )}
      <Box h={isMd ? "490px" : undefined}>
        <Question
          question={headScanQuestion?.question}
          textColor={forceDarkMode ? "white" : MODAL_PRIMARY_TEXT_COLOR}
          isDisabled={saving}
          onSelectedResponseChanged={(val, index) => {
            setSelectedResponse({
              questionId: headScanQuestion.id,
              alternativeId: alternatives[index].id,
              scale: alternatives[index].scale,
            } as HeadScanResponse);
          }}
        />
      </Box>
      <Box
        pos={isMd ? "absolute" : "fixed"}
        left={0}
        bottom={0}
        width={"100%"}
        bgColor={`#ffffff${isMd ? "aa" : "dd"}`}
        borderBottomRadius={isMd ? Border_Radius : undefined}
        p={"10px"}
        pb={`${10 + safeAreaInsets.bottom}px`}
        textAlign="center"
      >
        <AnimatedButton
          disabled={!showNextQuestion}
          w={150}
          text={HVLocalizeStrings.NEXT}
          onClick={() => {
            track(EVENTS.HeadscanQuestionAnswered, {
              HV_HeadscanQuestionId: selectedResponse.questionId,
            });
            saveAnswer();
            setShowNextQuestion(false);
          }}
          dataTestId={HVTestId.HeadScanQuestionContent.nextButton}
        />
      </Box>
    </>
  );
};
