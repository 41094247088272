import { LargeModal } from "../../Common/LargeModal";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { useContext, useEffect, useState } from "react";
import { ShellContext } from "../../../State/ShellContext";
import { HVTestId } from "../../../Testing/dataTestIds";
import { SOLO_PRODUCT_ANNOUNCEMENT } from "../../../App";
import { GlobalContext } from "../../../State/GlobalContext";

interface FirstTimeInterstitialModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const FirstTimeInterstitialModal = ({
  open,
  setOpen,
}: FirstTimeInterstitialModalProps): JSX.Element => {
  const { selectedGoal, schedulerReminders } = useContext(GlobalContext);
  const {
    doOSThemeCheck,
    setIsGoalCheckInNewPursuitModalOpen,
    setIsPracticeReminderModalOpen,
  } = useContext(ShellContext);
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const [themeReady, setThemeReady] = useState(false);

  const onClose = () => {
    localStorage.setItem(SOLO_PRODUCT_ANNOUNCEMENT, "true");

    setOpen(false);
  };

  /* duped from WelcomeModal */
  useEffect(() => {
    if (!open) return;

    doOSThemeCheck(() => {
      setThemeReady(true);
    });
  }, [open]);

  return themeReady ? (
    <LargeModal
      open={open}
      setOpen={onClose}
      dataTestId={HVTestId.FirstTimeInterstitialModal.modal}
    >
      <Box mx={isDesktop ? "40px" : "10px"} mb={"80px"} mt={"10px"}>
        <Text
          textAlign={isDesktop ? "center" : undefined}
          mt={isDesktop ? "-10px" : undefined}
          mb={isDesktop ? 8 : 6}
          color={MODAL_PRIMARY_TEXT_COLOR}
          fontSize={isDesktop ? "30px" : "24px"}
          lineHeight="1.2"
          mr={isDesktop ? undefined : "40px"}
          dangerouslySetInnerHTML={{
            __html: !selectedGoal
              ? HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_HEADER
              : HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_HEADER_CHALLENGE,
          }}
        />

        {HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SINGLE_ITEM1 && (
          <FirstTimeInterstitialModalText
            html={
              !selectedGoal
                ? HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SINGLE_ITEM1
                : HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SINGLE_ITEM1_CHALLENGE
            }
          />
        )}

        {HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SINGLE_ITEM2 && (
          <FirstTimeInterstitialModalText
            mt={6}
            isCentered={!!selectedGoal}
            html={
              !selectedGoal
                ? HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SINGLE_ITEM2
                : HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SINGLE_ITEM2_CHALLENGE
            }
          />
        )}

        {HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SINGLE_ITEM_IMAGE && (
          <Center>
            <Image
              my={4}
              maxW={isDesktop ? "300px" : undefined}
              src={
                !selectedGoal
                  ? HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SINGLE_ITEM_IMAGE
                  : HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SINGLE_ITEM_IMAGE_CHALLENGE
              }
              alt=""
            />
          </Center>
        )}

        {HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_FIRST_HEADLINE && (
          <Flex
            mb={isDesktop ? 10 : 6}
            direction={{ base: "column", lg: "row" }}
            gap={2}
          >
            <Box>
              <FirstTimeInterstitialModalText
                isHeadline={true}
                html={HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_FIRST_HEADLINE}
              />
              <FirstTimeInterstitialModalText
                html={HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_FIRST_LIST_ITEM}
              />
            </Box>
            <Center>
              <Image
                maxW={isDesktop ? "420px" : undefined}
                src={HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_FIRST_IMAGE}
                alt=""
              />
            </Center>
          </Flex>
        )}

        {HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SECOND_HEADLINE && (
          <Flex direction={{ base: "column-reverse", lg: "row" }} gap={4}>
            <Center>
              <Image
                maxW={isDesktop ? "420px" : undefined}
                src={HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SECOND_IMAGE}
                alt=""
              />
            </Center>
            <Box>
              <FirstTimeInterstitialModalText
                isHeadline={true}
                html={HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SECOND_HEADLINE}
              />
              <FirstTimeInterstitialModalText
                html={
                  HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_SECOND_LIST_ITEM
                }
              />
            </Box>
          </Flex>
        )}

        <Divider mt={2} mb={6} />

        <FirstTimeInterstitialModalText
          html={HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_THIRD_LIST_ITEM}
        />
      </Box>
      <BottomActionBar
        showOptions={true}
        nextButtonText={
          !selectedGoal
            ? HVLocalizeStrings.CONTINUE
            : schedulerReminders.length === 0
            ? HVLocalizeStrings.PRACTICE_REMINDER_SET_REMINDER
            : HVLocalizeStrings.CLOSE
        }
        onNextButtonClick={() => {
          if (!selectedGoal) {
            // open the welcome modal
            setIsGoalCheckInNewPursuitModalOpen(true);
          } else if (schedulerReminders.length === 0) {
            setIsPracticeReminderModalOpen(true);
          }

          onClose();
        }}
      />
    </LargeModal>
  ) : (
    <></>
  );
};

interface FirstTimeInterstitialModalTextProps {
  html: string;
  isHeadline?: boolean;
  mt?: number;
  isCentered?: boolean;
}

const FirstTimeInterstitialModalText = (
  props: FirstTimeInterstitialModalTextProps
) => {
  const { html, isCentered } = props;
  const isDesktop = useBreakpointValue({ base: false, md: true });
  return (
    <Text
      mt={props.mt ? props.mt : 1}
      mb={props.isHeadline ? 2 : undefined}
      fontWeight={props.isHeadline ? "600" : undefined}
      fontSize={props.isHeadline ? "xl" : isDesktop ? "16px" : "14px"}
      lineHeight={"1.3rem"}
      textAlign={isCentered ? "center" : undefined}
      color={MODAL_PRIMARY_TEXT_COLOR}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
