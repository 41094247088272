import { Flex, Box, Button } from "@chakra-ui/react";
import { HvSelect, HvSelectOption } from "../../Common/HvSelect";
import { useReachGlobal } from "../../../Hooks/Reach/useReachGlobal";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { ReachUsersContext } from "../../../State/Reach/ReachUsersContext";
import { useContext } from "react";
import { BLOCK_SECTION_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";
import { GlobalContext } from "../../../State/GlobalContext";
import useCompanyReachAdmin from "../../../Hooks/Reach/useCompanyReachAdmin";

interface ReachUsersSearchSelectProps {
  readonly items: HvSelectOption[];
  readonly initialItems: HvSelectOption[];
  readonly handleSelections: (selected: HvSelectOption[]) => void;
  readonly placeholder: string;
}

export const ReachUsersSearchSelects = () => {
  const { userHierarchyAssociations } = useContext(GlobalContext);
  const reachUsersContext = useContext(ReachUsersContext);
  return (
    <Flex direction="column" alignItems="top" gap={5} py={2}>
      <Flex rowGap={2} flexWrap="wrap">
        <Flex gap={2} flexWrap="wrap">
          {reachUsersContext.companyCerts.length > 0 && (
            <>
              <ReachUsersSearchByCerts />
              <ReachUsersSearchByCompletionStatuses />
            </>
          )}
        </Flex>
        {userHierarchyAssociations?.hierarchyId && (
          <Flex gap={2} flexWrap="wrap" alignItems="top">
            <ReachUsersSearchByHierarchy />
          </Flex>
        )}
        {(reachUsersContext.certsFilter.length > 0 ||
          reachUsersContext.completionStatusFilter.length > 0 ||
          reachUsersContext.nodesFilter.length > 0) && (
          <Button
            color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
            colorScheme="orange"
            variant="link"
            size="sm"
            onClick={() => {
              reachUsersContext.setCertsFilter([]);
              reachUsersContext.setCompletionStatusFilter([]);
              reachUsersContext.setNodesFilter([]);
              track(REACH_EVENTS.ReachUsersClearCertFilters);
            }}
            textDecoration={"underline"}
            ml={1}
            fontWeight={"normal"}
          >
            {HVLocalizeStrings.REACH_USERS_RESET_FILTERS}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export const ReachUsersSearchByHierarchy = () => {
  const reachUsersContext = useContext(ReachUsersContext);
  const companyReachAdmin = useCompanyReachAdmin();
  const { getHierarchyOptions } = useReachGlobal();
  const hierarchyOptions = getHierarchyOptions(
    reachUsersContext.hierarchyNodes, companyReachAdmin.isCompanyReachAdmin
  );
  const initialHierarchyOptions = getHierarchyOptions(
    reachUsersContext.nodesFilter, false
  );

  return (
    <ReachUsersSearchSelect
      items={hierarchyOptions}
      initialItems={initialHierarchyOptions}
      handleSelections={reachUsersContext.handleNodeSelectionsChange}
      placeholder={HVLocalizeStrings.REACH_NODE_SELECT}
    />
  );
};

export const ReachUsersSearchByCerts = () => {
  const reachUsersContext = useContext(ReachUsersContext);
  const { getCertOptions } = useReachGlobal();
  const certOptions = getCertOptions(reachUsersContext.companyCerts);
  const initialCertOptions = getCertOptions(reachUsersContext.certsFilter);

  return (
    <ReachUsersSearchSelect
      items={certOptions}
      initialItems={initialCertOptions}
      handleSelections={reachUsersContext.handleCertSelectionsChange}
      placeholder={HVLocalizeStrings.REACH_CERT_SELECT}
    />
  );
};

export const ReachUsersSearchByCompletionStatuses = () => {
  const reachUsersContext = useContext(ReachUsersContext);
  const { getCompletionStatusOptions, getAllCompletionStatusOptions } =
    useReachGlobal();
  const completionStatusOptions = getAllCompletionStatusOptions();
  const initialCompletionStatusOptions = getCompletionStatusOptions(
    reachUsersContext.completionStatusFilter
  );

  return (
    <ReachUsersSearchSelect
      items={completionStatusOptions}
      initialItems={initialCompletionStatusOptions}
      handleSelections={
        reachUsersContext.handleCompletionStatusSelectionsChange
      }
      placeholder={HVLocalizeStrings.REACH_CERT_COMPLETION_STATUS}
    />
  );
};

export function ReachUsersSearchSelect({
  items,
  initialItems,
  handleSelections,
  placeholder,
}: ReachUsersSearchSelectProps) {
  return (
    <Box w="300px">
      <HvSelect
        isMultiSelection={true}
        options={items}
        selectedValues={initialItems}
        onMultiSelectChange={(value) =>
          handleSelections(value as HvSelectOption[])
        }
        placeHolder={placeholder}
        hasBlackBorder={true}
      />
    </Box>
  );
}
