import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { Flex, Text } from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { getLatestPollInstanceByPollQuestionId } from "../../../../Utils/TeamUtil";
import { HVTestId } from "../../../../Testing/dataTestIds";
import { BestWorstResult } from "../Shared/BestWorstResult";
import { CharterTimer } from "../Shared/CharterTimer";
import { PresentationContent } from "../Shared/PresentationContent";
import { usePollQuestionInformation } from "../../../../Hooks/Sport/usePollQuestionInformation";
import { CharterActivity, PathTeamLessonDto } from "@headversity/contract";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { useBestWorstInitQuestion } from "../../../../Hooks/Sport/useBestWorstInitQuestion";
import { PresentationContainer } from "../Shared/PresentationContainer";
import { DiscussText } from "../Shared/DiscussText";

interface PresentationBestWorstStepTwoProps {
  pathTeamLesson: PathTeamLessonDto | undefined;
  setActivity: (activity: CharterActivity | undefined) => void;
}
export const PresentationBestWorstStepTwo = (
  props: PresentationBestWorstStepTwoProps
) => {
  const { pathTeamLesson, setActivity } = props;
  const {
    setTeamLessonInProgressStepToServer,
    currentTeamLessonUserInstanceId,
    pollInstances,
    createPollInstancesOnServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [instanceCreated, setInstanceCreated] = useState<boolean>(false);

  const { bestPollQuestion, worstPollQuestion } = useBestWorstInitQuestion({
    bestPollQuestionInternalTitle: "CH_4_PQ",
    worstPollQuestionInternalTitle: "CH_5_PQ",
  });

  const {
    bestPollQuestion: parentBestPollQuestion,
    worstPollQuestion: parentWorstPollQuestion,
  } = useBestWorstInitQuestion({
    bestPollQuestionInternalTitle: "CH_2_PQ",
    worstPollQuestionInternalTitle: "CH_3_PQ",
  });

  const {
    count: bestCount,
    total,
    setDisableSubmit: setBestDisableSubmit,
    disableSubmit: bestDisableSubmit,
    pollQuestionResults: bestQuestionResults,
  } = usePollQuestionInformation({
    pollQuestion: bestPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const {
    count: worstCount,
    setDisableSubmit: setWorstDisableSubmit,
    disableSubmit: worstDisableSubmit,
    pollQuestionResults: worstQuestionResults,
  } = usePollQuestionInformation({
    pollQuestion: worstPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const { pollQuestionResults: parentAllUsersBestQuestionResults } =
    usePollQuestionInformation({
      pollQuestion: parentBestPollQuestion,
      byGroup: true,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
    });

  const { pollQuestionResults: parentAllUsersWorstQuestionResults } =
    usePollQuestionInformation({
      pollQuestion: parentWorstPollQuestion,
      byGroup: true,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
    });

  useEffect(() => {
    if (
      bestPollQuestion &&
      worstPollQuestion &&
      currentTeamLessonUserInstanceId &&
      pollInstances &&
      !instanceCreated
    ) {
      setInstanceCreated(true);
      const bestPollQuestionInstance = getLatestPollInstanceByPollQuestionId(
        bestPollQuestion.id,
        pollInstances,
        currentTeamLessonUserInstanceId
      );

      if (bestPollQuestionInstance) {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.BestWorstStepTwo
        );
        return;
      }

      const worstPollQuestionInstance = getLatestPollInstanceByPollQuestionId(
        worstPollQuestion.id,
        pollInstances,
        currentTeamLessonUserInstanceId
      );

      if (worstPollQuestionInstance) {
        return;
      }

      createPollInstancesOnServer(
        [
          { id: bestPollQuestion.id } as any,
          { id: worstPollQuestion.id } as any,
        ],
        currentTeamLessonUserInstanceId
      ).then(() => {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.BestWorstStepTwo
        );
      });
    }
  }, [
    bestPollQuestion,
    worstPollQuestion,
    currentTeamLessonUserInstanceId,
    pollInstances,
    instanceCreated,
  ]);

  useEffect(() => {
    if (
      pathTeamLesson &&
      pathTeamLesson.inProgressStep === CharterActivity.BestWorstStepTwoResults
    ) {
      setLoading(false);
      setStep(1);
    }
  }, [pathTeamLesson]);

  return (
    <PresentationContainer>
      {step === 0 ? (
        <PresentationContent
          title={HVSportLocalizeStrings.CHARTER_GROUP_ACTIVITY}
          description={
            HVSportLocalizeStrings.CHARTER_BEST_WORST_TWO_DESCRIPTION
          }
          listItems={[
            {
              icon: "https://cdn.headversity.com/app/sport/group-reporter-device.svg",
              text: HVSportLocalizeStrings.CHARTER_ONLY_USE_GROUP_REPORTER_DEVICE,
            },
            {
              icon: "https://cdn.headversity.com/app/sport/discussion.svg",
              text: HVSportLocalizeStrings.CHARTER_TAKE_SOME_TIME_TO_DISCUSS,
            },
          ]}
          hrBorder={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
        >
          {bestPollQuestion && (
            <CharterTimer
              pollQuestion={bestPollQuestion}
              setDisableSubmit={(val) => {
                setBestDisableSubmit(false);
                setWorstDisableSubmit(false);
              }}
            />
          )}
        </PresentationContent>
      ) : (
        <>
          <BestWorstResult
            bestQuestionResults={bestQuestionResults}
            worstQuestionResults={worstQuestionResults}
            parentBestQuestionResults={parentAllUsersBestQuestionResults}
            parentWorstQuestionResults={parentAllUsersWorstQuestionResults}
          />
          <DiscussText />
        </>
      )}

      <Flex
        flexDir={"column"}
        align={"center"}
        data-testid={HVTestId.PollResult.totalVotes}
      >
        <Text color={PRIMARY_TEXT_COLOR} fontWeight={"semibold"}>
          {HVSportLocalizeStrings.CHARTER_STATISTICS}
        </Text>
        <Text
          color={PRIMARY_TEXT_COLOR}
          fontWeight={"semibold"}
          fontSize={"xl"}
        >
          {Math.floor((worstCount + bestCount) / 2)} / {total}
        </Text>
      </Flex>

      <AnimatedButton
        disabled={
          bestDisableSubmit ||
          worstDisableSubmit ||
          Math.floor((worstCount + bestCount) / 2) < 1 ||
          loading
        }
        w={150}
        colorSet={AnimatedButtonColorSet.Primary}
        text={HVLocalizeStrings.CONTINUE}
        onClick={async () => {
          if (currentTeamLessonUserInstanceId) {
            if (step === 0) {
              setLoading(true);
              setTeamLessonInProgressStepToServer(
                currentTeamLessonUserInstanceId as number,
                CharterActivity.BestWorstStepTwoResults
              );
            } else {
              setLoading(true);
              setActivity(undefined);
              setTeamLessonInProgressStepToServer(
                currentTeamLessonUserInstanceId as number,
                ""
              );
            }
          }
        }}
      />
    </PresentationContainer>
  );
};
