import { useCallback } from "react";

import { register } from "../../Api/People/RegistrationApi";
import { AxiosResponse } from "axios";
import { RegistrationDto } from "@headversity/contract";

export interface IPeopleProvider {
  signUpToServer: (signUpForm: RegistrationDto) => Promise<AxiosResponse>;
}

export const usePeopleGlobal = (): IPeopleProvider => {
  const signUpToServer = useCallback((signUpForm: RegistrationDto) => {
    return register(signUpForm);
  }, []);

  return {
    signUpToServer,
  };
};
