import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Border_Radius,
  MODAL_PRIMARY_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface BlockAccordionProps {
  buttonContent: any;
  panelContent: any;
  borderRadius?: any;
  buttonBorderRadius?: any;
  panelBorderRadius?: any;
  border?: string;
  h?: any;
  w?: any;
  bg?: any;
  accordionButtonGap?: any;
  accordionButtonPadding?: any;
  accordionButtonPaddingX?: any;
  accordionButtonPaddingY?: any;
  buttonClassName?: any; // actual class name of the accordion button to invoke a click
  onExpandUrl?: string | undefined;
  onCollapseUrl?: string | undefined;
  yOffsetScrollTo?: number;
}

export const BlockAccordion = (props: BlockAccordionProps) => {
  const {
    buttonContent,
    panelContent,
    borderRadius,
    buttonBorderRadius,
    panelBorderRadius,
    border,
    accordionButtonGap,
    accordionButtonPadding,
    accordionButtonPaddingX,
    accordionButtonPaddingY,
    buttonClassName,
    onExpandUrl,
    onCollapseUrl,
    yOffsetScrollTo,
    ...otherProps
  } = props;

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const scrollToRef = useRef<any>(null);
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isCollapse) {
      setTimeout(() => {
        const y =
          scrollToRef.current?.getBoundingClientRect().top +
          window.scrollY +
          (yOffsetScrollTo ?? 0);
        window.scrollTo({ top: y, behavior: "smooth" });
      }, 500);
    }
  }, [isCollapse]);

  return (
    <Accordion
      ref={scrollToRef}
      {...otherProps}
      border={border}
      borderRadius={borderRadius ?? Border_Radius}
      allowMultiple={true}
    >
      <AccordionItem border={0} boxShadow={"lg"}>
        {({ isExpanded }: any) => {
          setIsCollapse(isExpanded);
          return (
            <>
              <AccordionButton
                borderRadius={buttonBorderRadius ?? Border_Radius}
                p={"0"}
                className={buttonClassName ?? undefined}
                onClick={(e) => {
                  if (onExpandUrl && onCollapseUrl) {
                    if (isExpanded) {
                      navigate(onCollapseUrl, {
                        replace: true,
                      });
                    } else {
                      navigate(onExpandUrl, {
                        replace: true,
                      });
                    }
                  }
                }}
              >
                <Flex
                  w={"100%"}
                  gap={isMobile ? undefined : accordionButtonGap ?? "25px"}
                  p={accordionButtonPadding ?? "5"}
                  px={accordionButtonPaddingX ?? undefined}
                  py={accordionButtonPaddingY ?? undefined}
                  align={"center"}
                  color={PRIMARY_TEXT_COLOR}
                >
                  <Flex flex={"1"}>
                    {typeof buttonContent === "function"
                      ? buttonContent()
                      : buttonContent}
                  </Flex>

                  {isExpanded ? (
                    <Icon
                      as={isMobile ? FiMoreVertical : AiOutlineUpCircle}
                      w={isMobile ? "16px" : "32px"}
                      h={isMobile ? "16px" : "32px"}
                    ></Icon>
                  ) : (
                    <Icon
                      as={isMobile ? FiMoreVertical : AiOutlineDownCircle}
                      w={isMobile ? "16px" : "32px"}
                      h={isMobile ? "16px" : "32px"}
                    ></Icon>
                  )}
                </Flex>
              </AccordionButton>
              <AccordionPanel pb={"28px"}>
                {typeof panelContent === "function"
                  ? panelContent()
                  : panelContent}
              </AccordionPanel>
            </>
          );
        }}
      </AccordionItem>
    </Accordion>
  );
};
