import {
  Box,
  SlideFade,
  useBreakpointValue,
  Image,
  Text,
  Center,
  SimpleGrid,
  Link,
  Flex,
} from "@chakra-ui/react";
import { GoalDto } from "@headversity/contract/Dto/GoalDto";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import {
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../../State/ShellContext";
import {
  Invert_Filter,
  SlideFade_Offset,
} from "../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../Utils/Analytics";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import {
  getBgImageSource,
  getGoalMinSkillLevel,
  getUserSkillBySkillId,
  SOLO_GOAL_SKILL_LEVEL_TARGET,
} from "../../../Utils/SkillsUtil";
import { HvSlideFade } from "../../Common/HvSlideFade";
import { ScrollPanel } from "../../Common/ScrollPanel";
import { ScrollPanelBlock } from "../../Common/ScrollPanelBlock";
import { WelcomeModalHeader } from "./WelcomeModalHeader";
import { HVTestId } from "../../../Testing/dataTestIds";

interface StepSelectGoalProps {
  onGoalChanged: (goal: GoalDto) => void;
  suggestedGoal?: GoalDto;
  isCheckIn?: boolean;
  seeAllGoals?: boolean;
}
export const StepSelectGoal = ({
  onGoalChanged,
  suggestedGoal,
  isCheckIn,
  seeAllGoals,
}: StepSelectGoalProps) => {
  const { goals, selectedGoal, userSkillStats } = useContext(GlobalContext);
  const [showSelectedGoal, setShowSelectedGoal] = useState(false);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    if (isCheckIn) {
      setShowSelectedGoal(true);

      if (selectedGoal) {
        setTimeout(() => {
          onGoalChanged(selectedGoal);
        }, 1);
      }
    } else if (seeAllGoals && !selectedGoal) {
      setTimeout(() => {
        onGoalChanged(goals[0]);
      }, 1);
    } else {
      // slight delay for the big reveal...
      setTimeout(() => {
        setShowSelectedGoal(true);
      }, 1500);
    }
  }, []);

  const goalToUse = suggestedGoal ?? selectedGoal;

  const minLevel = getGoalMinSkillLevel(userSkillStats, selectedGoal);
  const allSkillsLevel0 = !userSkillStats
    .filter((x) => selectedGoal?.skillIds.includes(x.skillId))
    .some((x) => x.level > 0);

  return (
    <SlideFade in={true} offsetY={SlideFade_Offset}>
      <Box
        mt={isWebPlatform() ? "-40px" : "-5px"}
        mb={!isWebPlatform() ? "125px" : undefined}
      >
        {!seeAllGoals && goalToUse ? (
          <>
            <WelcomeModalHeader
              fontSize={isDesktop ? "26px" : "24px"}
              mx={isDesktop ? "25px" : undefined}
              mr="30px"
            >
              <Text
                as="span"
                dangerouslySetInnerHTML={{
                  __html:
                    isCheckIn && selectedGoal
                      ? allSkillsLevel0
                        ? HVLocalizeStrings.PURSUIT_CHECKIN0
                        : minLevel < SOLO_GOAL_SKILL_LEVEL_TARGET
                        ? HVLocalizeStrings.PURSUIT_CHECKIN1.replace(
                            "{0}",
                            (minLevel + 1).toString()
                          )
                        : HVLocalizeStrings.PURSUIT_CHECKIN2.replace(
                            "{0}",
                            minLevel.toString()
                          )
                      : `
                      <b style="fontWeight: 600">${HVLocalizeStrings.OK}</b>
                      ${HVLocalizeStrings.PURSUIT_STARTING_POINT}
                    `,
                }}
              />
            </WelcomeModalHeader>

            <SlideFade in={showSelectedGoal} offsetY={SlideFade_Offset}>
              <Box mt={6}>
                <GoalCard
                  width={getWidthForGoalCard(isDesktop)}
                  item={goalToUse}
                  isDesktop={isDesktop}
                  isCheckIn={isCheckIn}
                  px={isDesktop ? "25px" : undefined}
                />
              </Box>
            </SlideFade>
          </>
        ) : (
          <>
            <WelcomeModalHeader
              fontSize={isDesktop ? "22px" : "20px"}
              mr="35px"
              mx={isDesktop ? "35px" : undefined}
            >
              <b style={{ fontWeight: 600 }}>{HVLocalizeStrings.OK}</b>{" "}
              {HVLocalizeStrings.PURSUIT_SELECT}.
            </WelcomeModalHeader>
            <Box mt={6}>
              <GoalScrollPanel
                goals={goals}
                selectedGoal={goalToUse}
                onGoalChanged={onGoalChanged}
              />
            </Box>
          </>
        )}
      </Box>
    </SlideFade>
  );
};

const getWidthForGoalCard = (isDesktop: boolean | undefined) => {
  return isDesktop ? "891px" : window.innerWidth - 30 + "px";
};

interface GoalScrollPanelProps {
  goals: GoalDto[];
  onGoalChanged: (goal: GoalDto) => void;
  selectedGoal: any;
}
const GoalScrollPanel = ({
  goals,
  onGoalChanged,
  selectedGoal,
}: GoalScrollPanelProps) => {
  const { userSkillStats } = useContext(GlobalContext);
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const [show, setShow] = useState(false);
  const [showExtra, setShowExtra] = useState(false);

  const getGoalIndex = (selectedGoal: GoalDto): number => {
    return goals.findIndex((x) => x.id === selectedGoal.id);
  };

  const width = getWidthForGoalCard(isDesktop);

  // pin the dots on the bottom
  const height = !isDesktop && window.innerHeight > 800 ? "550px" : undefined;

  // fixes a strange ScrollPanel behavior where the chevrons don't work to change slide until after the slide is changed...
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    });
  }, []);

  useEffect(() => {
    setShowExtra(
      userSkillStats.length >= 18 && !userSkillStats.some((x) => x.level < 50)
    );
  }, []);

  return !show ? (
    <></>
  ) : (
    <ScrollPanel
      customBulletClass="white"
      spaceBetween={20}
      showChevronContainer={isDesktop}
      noChevronBg={true}
      slidesPerGroup={1}
      onSlideChangeCallBack={(idx: number) => {
        const goal = goals[idx];

        onGoalChanged(goal);

        track(EVENTS.GoalViewed, {
          HV_Goal: goal.name.toString(),
          HV_GoalId: goal.id,
        });
      }}
      currentIndex={selectedGoal ? getGoalIndex(selectedGoal) : undefined}
    >
      {goals.map((item) => {
        return ScrollPanelBlock(
          <GoalCard
            key={item.id}
            item={item}
            isDesktop={isDesktop}
            width={width}
            height={height}
            px={isDesktop ? "35px" : undefined}
          />,
          width,
          item.id
        );
      })}

      {showExtra &&
        ScrollPanelBlock(
          <Center>
            <Image
              src="https://cdn.headversity.com/app/bg-goals/mobile/perfection.jpg"
              height="350"
            />
          </Center>,
          width,
          -1
        )}
    </ScrollPanel>
  );
};

interface GoalCardInterface {
  item: GoalDto;
  isCheckIn?: boolean;
  isDesktop?: boolean;
  width: string;
  height?: string;
  px?: string;
}
const GoalCard = ({
  item,
  isCheckIn,
  isDesktop,
  width,
  height,
  px,
}: GoalCardInterface) => {
  const isMd = useBreakpointValue({ base: false, md: true });

  const { skills, userSkillStats } = useContext(GlobalContext);
  const { theme, setIsGoalOverviewModalOpen } = useContext(ShellContext);

  const navigate = useNavigate();

  const isLight = theme.name !== NOSTALGIC_THEME_NAME;

  const isAllZero = item.skillIds.every((skillId) => {
    const skill = skills.filter((x) => x.id === skillId)[0];
    const userStats = getUserSkillBySkillId(userSkillStats, skill.id);

    return !userStats || userStats.level === 0;
  });

  return (
    <Box px={px} w={width} minH={height} transition={"all 0.2s"}>
      <Box>
        <Box
          color="white"
          bg="#00000044"
          borderTop="solid 1px #ffffff66"
          borderBottom="solid 1px #ffffff66"
          px={2}
          py={8}
        >
          <Center mb={isDesktop ? 4 : 6}>
            <Image
              src={getBgImageSource(item.imageUrl.toString(), true, true)}
              border="solid 1px #ffffff66"
              filter={isLight ? "brightness(1);" : "brightness(2);"}
              h={"75px"}
              w={"75px"}
              borderRadius="50%"
            />
          </Center>
          <Text
            fontSize={isDesktop ? "24px" : "22px"}
            fontWeight="600"
            lineHeight={"1.2"}
            textAlign="center"
            data-testid={HVTestId.StepSelectGoal.goalName}
          >
            {item.name.toString()}
          </Text>
          <Text
            fontSize="16px"
            lineHeight={"1.4"}
            textAlign={isMd ? "center" : "left"}
            mt={5}
            dangerouslySetInnerHTML={{
              __html: item.description.toString(),
            }}
            px={4}
          />
        </Box>
        <Box color="white" px={1} pt={0} pb={4}>
          <HvSlideFade fadeIn={true} delay={1}>
            <Box textAlign={"left"} mt={6}>
              {!isCheckIn && (
                <Text fontSize="13px">
                  {HVLocalizeStrings.PURSUIT_BUILD_SKILLS}
                </Text>
              )}
              <SimpleGrid columns={isDesktop ? 4 : 2} gap={5} mt={3}>
                {item.skillIds.map((skillId) => {
                  const skill = skills.filter((x) => x.id === skillId)[0];

                  let pointsInSkill = "0";
                  const userStats = userSkillStats.filter(
                    (x) => x.skillId === skillId
                  );

                  if (userStats.length > 0) {
                    pointsInSkill = userStats
                      .map((x) => x.points)
                      .reduce((a, b) => a + b, 0)
                      .toFixed(0);
                  }

                  return (
                    <Box key={skillId} w="100%">
                      <Flex gap="8px">
                        <Box>
                          <Image
                            display="inline"
                            mt="-2px"
                            w="14px"
                            h="14px"
                            filter={Invert_Filter}
                            src={skill.imageUrl.toString()}
                          />
                        </Box>
                        <Box>
                          <Link
                            textDecoration={
                              !isCheckIn ? "none !important" : undefined
                            }
                            cursor={
                              !isCheckIn ? "default !important" : undefined
                            }
                            fontSize="15px"
                            fontWeight="600"
                            onClick={
                              isCheckIn
                                ? () => {
                                    navigate(`/skills/${skill.slug}`);
                                    setIsGoalOverviewModalOpen(false);
                                  }
                                : undefined
                            }
                            data-testid={`${HVTestId.StepSelectGoal.goalSkill}${skillId}`}
                          >
                            {skill.name.toString()}
                          </Link>
                          {!isAllZero && (
                            <Text fontWeight={"400"} fontSize="12px" mt={1}>
                              <span style={{ fontWeight: 500 }}>
                                {pointsInSkill}
                              </span>{" "}
                              XP
                            </Text>
                          )}
                          <Box
                            fontSize={"12px"}
                            fontWeight="300"
                            lineHeight="1.2"
                            mt={2}
                          >
                            {skill.description.toString()}
                          </Box>
                        </Box>
                      </Flex>
                    </Box>
                  );
                })}
              </SimpleGrid>
            </Box>
          </HvSlideFade>
        </Box>
      </Box>
    </Box>
  );
};

export interface UserGoal {
  goal: GoalDto;
  levels: number[];
  points: number[];
}
