import { Box, Button, SimpleGrid, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  IGlobalProvider,
  GlobalContext,
} from "../../../../State/GlobalContext";
import {
  SECONDARY_TEXT_COLOR,
  STATS_NUMBER_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../../Utils/Analytics";
import {
  calculateMinutesTrained,
  getStreakInfo,
  getStreakNameFromType,
  getTotalPoints,
  StreakType,
} from "../../../../Utils/SkillsUtil";

import { BlockSection } from "../../BlockSection";
import BlockSectionTitle from "../../BlockSectionTitle";
import { FadeAfterDelay } from "../../FadeAfterDelay";

export default function StatCardPanel() {
  const {
    nanoPracticeInstances,
    lessonCompletedCount,
    userSkillStats,
    activityCompletionDates,
    schedulerReminders,
    setShowPointsModal,
    setShowStreakModal,
    selectedGoal,
  } = useContext<IGlobalProvider>(GlobalContext);

  let nanoPracticeCompleted = 0;
  for (var key in nanoPracticeInstances) {
    nanoPracticeCompleted += nanoPracticeInstances[key]?.length;
  }
  const navigate = useNavigate();

  const streakInfo = getStreakInfo(activityCompletionDates, schedulerReminders);

  // show "0 day streak" if there's no streak
  const streakName = getStreakNameFromType(
    streakInfo.type === StreakType.None ? StreakType.Day : streakInfo.type,
    streakInfo.length,
    true
  );
  const streakText =
    streakName.charAt(0).toUpperCase() + streakName.substring(1).toLowerCase();

  // sum up completedPosts from each item in userSkillStats
  const completedPosts = userSkillStats.reduce(
    (acc, item) => acc + (item.completedPosts ? item.completedPosts : 0),
    0
  );

  const minutesTrained = calculateMinutesTrained(
    nanoPracticeCompleted,
    lessonCompletedCount,
    completedPosts
  );

  return (
    <FadeAfterDelay>
      <BlockSection
        columns={0}
        fadeIn={true}
        title={
          <BlockSectionTitle title={HVLocalizeStrings.SOLO_STATS_BLOCK_TITLE} />
        }
      >
        <SimpleGrid minChildWidth="120px" gap={8}>
          {selectedGoal && (
            <>
              <StatsCard
                title={HVLocalizeStrings.XP_EARNED}
                total={getTotalPoints(userSkillStats)}
                onClick={() => {
                  track(EVENTS.PointsMapModalShown, {
                    HV_From: "ProfilePage",
                  });
                  setShowPointsModal(true);
                }}
              />

              <StatsCard
                title={streakText + " " + HVLocalizeStrings.STREAK}
                total={Math.max(
                  0,
                  streakInfo.length - (streakInfo.isIncomplete ? 1 : 0)
                )}
                onClick={() => {
                  track(EVENTS.StreakModalShown, {
                    HV_From: "ProfilePage",
                  });
                  setShowStreakModal(true);
                }}
              />
            </>
          )}

          <StatsCard
            title={HVLocalizeStrings.MINUTES_TRAINED}
            total={minutesTrained}
            onClick={() => {
              navigate("/solo");
            }}
          />

          <StatsCard
            title={HVLocalizeStrings.STAT_PRACTICES_COMPLETE}
            total={nanoPracticeCompleted}
            onClick={() => {
              navigate("/nano-practices");
            }}
          />

          <StatsCard
            title={HVLocalizeStrings.STAT_LESSONS_COMPLETE}
            total={lessonCompletedCount}
            onClick={() => {
              navigate("/lessons");
            }}
          />

          <StatsCard
            title={HVLocalizeStrings.STAT_POSTS_VIEWED}
            total={completedPosts}
            onClick={() => {
              navigate("/resilience-feed");
            }}
          />
        </SimpleGrid>
      </BlockSection>
    </FadeAfterDelay>
  );
}

const StatsCard = ({ total, title, onClick }: any) => {
  // if there's a single space in title, change it to a non-breaking space
  if (title.match(/ /g).length === 1) {
    title = title.replace(" ", "<br />");
  }

  return (
    <Button bg="white" height="100%" p={0} boxShadow="lg" onClick={onClick}>
      <Box p={4}>
        <Text
          color={STATS_NUMBER_COLOR}
          fontWeight="600"
          fontSize="40px"
          data-testid="achievement_stat_card_count"
        >
          {total}
        </Text>
        <Text
          color={SECONDARY_TEXT_COLOR}
          mb={1}
          fontSize="13px"
          fontWeight="600"
          whiteSpace="normal"
          data-testid="achievement_stat_card_title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </Box>
    </Button>
  );
};
