import { Box, Center, SlideFade, useBreakpointValue } from "@chakra-ui/react";
import { SkillDto, UserSkillV2Dto } from "@headversity/contract";
import { SlideFade_Offset } from "../../../Styles/HeadversityStyle";
import { DonutSize } from "./ResilienceSkill20Donut";
import { ResilienceSkill20Item } from "./ResilienceSkill20Item";

interface ResilienceSkill20ItemWithChartProps {
  skill: SkillDto;
  userSkill: UserSkillV2Dto;
  forceChart?: boolean;
  mt?: string;
}

export const ResilienceSkill20ItemWithChart = ({
  skill,
  userSkill,
  forceChart,
  mt,
}: ResilienceSkill20ItemWithChartProps) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const showChart = forceChart || userSkill.headscanHistory.length > 1;

  return (
    <Center mt={mt ? mt : "30px"} mb={"40px"}>
      <SlideFade in={true} offsetY={SlideFade_Offset}>
        <Box
          w={window.innerWidth < 992 ? window.innerWidth - 30 : "875px"}
          h={!showChart ? (isDesktop ? "165px" : "175px") : undefined}
          color="white"
        >
          <ResilienceSkill20Item
            showChart={showChart}
            showEmptyChartExplanation={true}
            hideDonut={showChart}
            hideDescriptionInFooter={true}
            showDescriptionSmall={true}
            skill={skill}
            userSkill={userSkill}
            donutMarginTop={isDesktop ? "0" : "10px"}
            donutSize={DonutSize.Large}
            isSmallChart={true}
          />
        </Box>
      </SlideFade>
    </Center>
  );
};
