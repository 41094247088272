import { Button, Center, Flex, Icon } from "@chakra-ui/react";
import { SignInStep } from "./SignInStep";
import { MODAL_CLOSE_BUTTON } from "../../../Styles/HeadversityStyle";
import { useContext, useEffect, useState } from "react";
import { IntroStep } from "./IntroStep";
import { ForgotPasswordStep } from "./ForgotPasswordStep";
import { FiArrowLeftCircle } from "react-icons/fi";
import { getKey } from "../../../Utils/Helpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LandingContainer } from "../Shared/LandingContainer";
import {
  DEFAULT_THEME_NAME,
  IShellProvider,
  ShellContext,
} from "../../../State/ShellContext";
import PasswordRequirementsContainer from "../Shared/PasswordRequirementsContainer";
import { PasswordContainerChildComponent } from "@headversity/contract";
import { InviteCodeDomainDto } from "@headversity/contract";
import { getSSODomains } from "../../../Api/People/AccessTokenApi";
import { ErrorText } from "../Shared/ErrorText";
import { ResendActivationPage } from "./ResendActivationPage";
import { isAndroidBrowser } from "../../../Utils/mobileUtils";
import GooglePlayBanner from "../../Common/GooglePlayBanner";
import { SignInWithEmailStep } from "./SignInWithEmailStep";

export const INTRO_STEP = "Intro Step";
export const SIGN_IN_STEP = "Sign In";
export const SIGN_IN_WITH_EMAIL_STEP = "Sign In With Email";
export const SIGN_IN_WITH_EMAIL_STEP_NO_BACK = "Sign In With Email No Back";
export const REGISTER_STEP = "Register";
export const FORGOT_PASSWORD_STEP = "Forgot Password Step";
export const RESEND_ACTIVATION_STEP = "Resend Activation Step";
export const DONT_HAVE_INVITE_CODE_STEP = "Dont Have Invite Code Step";

export const LoginPage = () => {
  const [currentStep, setCurrentStep] = useState<string | null>(INTRO_STEP);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setThemeHelper } = useContext<IShellProvider>(ShellContext);
  const [error, setError] = useState<string | null>(null);
  const [inviteCodeDomains, setInviteCodeDomains] = useState<
    InviteCodeDomainDto[]
  >([]);

  let currentStepContent = <IntroStep setCurrentStep={setCurrentStep} />;

  const inviteCodeFromQueryString = searchParams.get("invite");

  useEffect(() => {
    getSSODomains().then((response) => {
      // Hack to set the inviteCodeDomains in a global
      // variable for the window object.  Needed due to
      // the hack of the SignInStep of using auth0 lock
      // component and dom checks Should transition out
      // of this when we transfer everyone from auth0 to
      // cognito
      (window as any).inviteCodeDomains = response.data;

      // Should use this state in the SignInStep when we
      // remove auth0 lock
      setInviteCodeDomains(response.data);
    });
  }, []);

  useEffect(() => {
    setThemeHelper(DEFAULT_THEME_NAME);
    if (searchParams.get("certKey") && searchParams.get("userId")) {
      sessionStorage.setItem("certKey", searchParams.get("certKey") as string);
      sessionStorage.setItem("userId", searchParams.get("userId") as string);
      setCurrentStep(SIGN_IN_WITH_EMAIL_STEP_NO_BACK);
      return;
    }

    if (searchParams.get("isMobile") === "true") {
      sessionStorage.setItem("isMobile", "true");
    } else if (getKey() && sessionStorage.getItem("isMobile") !== "true") {
      navigate("/");
    }

    const forgotPassword = sessionStorage.getItem("goToForgotPassword");
    if (forgotPassword) {
      sessionStorage.removeItem("goToForgotPassword");
      setCurrentStep(FORGOT_PASSWORD_STEP);
    }
    const resendActivation = sessionStorage.getItem("goToResendActivation");
    if (resendActivation) {
      sessionStorage.removeItem("goToResendActivation");
      setCurrentStep(RESEND_ACTIVATION_STEP);
    }
    if (inviteCodeFromQueryString) {
      setCurrentStep(REGISTER_STEP);
    }
  }, []);

  switch (currentStep) {
    case SIGN_IN_STEP:
      currentStepContent = (
        <SignInStep
          show={currentStep === SIGN_IN_STEP}
          setCurrentStep={setCurrentStep}
          setError={setError}
        />
      );
      break;
    case SIGN_IN_WITH_EMAIL_STEP:
    case SIGN_IN_WITH_EMAIL_STEP_NO_BACK:
      currentStepContent = <SignInWithEmailStep />;
      break;
    case REGISTER_STEP:
      currentStepContent = (
        <PasswordRequirementsContainer
          childComponent={PasswordContainerChildComponent.RegistrationStep}
          setError={setError}
          inviteCodeDomains={inviteCodeDomains}
        />
      );
      break;
    case FORGOT_PASSWORD_STEP:
    case DONT_HAVE_INVITE_CODE_STEP:
      currentStepContent = (
        <ForgotPasswordStep
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          inviteCodeDomains={inviteCodeDomains}
        />
      );
      break;
    case RESEND_ACTIVATION_STEP:
      currentStepContent = (
        <ResendActivationPage
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      );
      break;
  }

  const goToPreviousStep = () => {
    switch (currentStep) {
      case SIGN_IN_STEP:
      case SIGN_IN_WITH_EMAIL_STEP:
      case REGISTER_STEP:
      case DONT_HAVE_INVITE_CODE_STEP:
        setCurrentStep(INTRO_STEP);
        break;
      case FORGOT_PASSWORD_STEP:
        setCurrentStep(SIGN_IN_STEP);
        break;
    }
  };

  let otherComponent = <></>;
  if (
    ![
      INTRO_STEP,
      RESEND_ACTIVATION_STEP,
      SIGN_IN_WITH_EMAIL_STEP_NO_BACK,
    ].includes(currentStep as string)
  ) {
    otherComponent = (
      <Button
        position={"absolute"}
        left={"20px !important"}
        top={"20px !important"}
        onClick={() => {
          setError(null);
          goToPreviousStep();
        }}
        w={"30px !important"}
        h={"30px !important"}
        className={"button-link"}
        textAlign="left"
        zIndex={2}
        _hover={{
          bgColor: "var(--chakra-colors-blackAlpha-100) !important",
        }}
      >
        <Icon
          as={FiArrowLeftCircle}
          w={"30px"}
          h={"30px"}
          color={MODAL_CLOSE_BUTTON}
        />
      </Button>
    );
  }

  window.parent.postMessage({ type: "page-loaded" }, "*");
  return (
    <>
      <LandingContainer other={otherComponent}>
        {error && (
          <Flex flexDir={"column"} align={"center"} gap={"2"}>
            <Center pb={"3"} w={"300px"}>
              <ErrorText message={error} />
            </Center>
          </Flex>
        )}
        {currentStepContent}
      </LandingContainer>

      {/* Google Play Banner */}
      {isAndroidBrowser() &&
        !searchParams.get("isMobile") &&
        !sessionStorage.getItem("isMobile") && <GooglePlayBanner />}
    </>
  );
};
