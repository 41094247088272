import {
  Flex,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../State/GlobalContext";
import {
  Burnt_Orange,
  BUTTON_THIRD_BORDER,
  Equity_Orange,
} from "../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../Common/AnimatedButton";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  getLastPresentedByTimeSpan,
  isPresentationExpired,
  trackTeamLesson,
} from "../../Utils/TeamUtil";
import { ScrollPanel } from "../Common/ScrollPanel";
import { ScrollPanelBlock } from "../Common/ScrollPanelBlock";
import { RiTeamFill } from "react-icons/ri";
import dayjs from "dayjs";
import { TEAM_EVENTS, track } from "../../Utils/Analytics";
import { useLocation } from "react-router-dom";
import { TeamLessonUserInstanceStatus } from "../../Types/HVTypes";
import { HVTestId } from "../../Testing/dataTestIds";
import {
  CharterActivity,
  PathDto,
  PathTeamLessonDto,
  TeamDto,
} from "@headversity/contract";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import {
  TEAM_LESSON_INITIALIZE_STEP,
  TEAM_LESSON_PROGRESS_STEP,
} from "./Presentation/PresentationModal";

export const TeamActivityNotification = () => {
  const {
    paths,
    teams,
    participantModalOpen,
    presenterModalOpen,
    setParticipateForTeamLessonUserInstanceToServer,
    setParticipantModalOpen,
    setCurrentTeamLessonUserInstanceId,
    setPresenterModalOpen,
    setPresenterPathTeamLesson,
    setCurrentTeam,
    self,
    teamDataLoading,
    updateParticipantTeamLesson,
    sportEnabled,
    startPresentOrParticipate,
    setPresentationStep,
  } = useContext(GlobalContext);

  const { confirmationDialogRef } = useContext<IShellProvider>(ShellContext);

  const [currentActivePathTeamLessons, setCurrentActivePathTeamLessons] =
    useState<PathTeamLessonDto[] | null>(null);

  const [time, setTime] = useState<number | null>(null);
  const location = useLocation();
  const [dismissed, setDismissed] = useState<boolean>(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(dayjs.utc().unix());
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  });

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  useEffect(() => {
    if (paths && teams) {
      const keys = Object.keys(paths);
      const pathTeamLessons: PathTeamLessonDto[] = [];
      keys.forEach((key) => {
        if (key !== "0") {
          paths[key]?.forEach((path: PathDto) => {
            path.pathTeamLessons?.forEach(
              (pathTeamLesson: PathTeamLessonDto) => {
                if (pathTeamLesson.inProgressStep === CharterActivity.Paused) {
                  return;
                }
                const minutes = getLastPresentedByTimeSpan(pathTeamLesson);
                if (
                  (pathTeamLesson.teamLessonInstanceStatus ===
                    TeamLessonUserInstanceStatus.Initialized ||
                    pathTeamLesson.teamLessonInstanceStatus ===
                      TeamLessonUserInstanceStatus.InProgress) &&
                  !isPresentationExpired(minutes, pathTeamLesson.teamLesson) &&
                  pathTeamLessons.find(
                    (item) =>
                      item.teamLessonUserInstanceId ===
                      pathTeamLesson.teamLessonUserInstanceId
                  ) === undefined
                ) {
                  pathTeamLessons.push(pathTeamLesson);
                }
              }
            );
          });
        }
      });
      setCurrentActivePathTeamLessons(pathTeamLessons);
      setDismissed(false);
    }
  }, [paths, teams, time]);

  const getTeamName = (teamId: number | undefined) => {
    const result = teams?.filter((item) => item.id == teamId);
    return result && result?.length > 0 ? result[0].name : "";
  };

  if (dismissed) {
    if (
      !participantModalOpen &&
      !presenterModalOpen &&
      currentActivePathTeamLessons &&
      currentActivePathTeamLessons.length > 0
    ) {
      return (
        <>
          <IconButton
            border={BUTTON_THIRD_BORDER}
            bg={"white"}
            _hover={{ bg: "white" }}
            borderRadius={"1000px"}
            aria-label={"Lesson in progress"}
            w={"50px"}
            h={"50px"}
            pos={"fixed"}
            bottom={"5px"}
            right={"5px"}
            icon={
              <Icon
                as={RiTeamFill}
                w={"32px"}
                h={"32px"}
                color={Burnt_Orange}
              />
            }
            onClick={() => {
              setDismissed(false);
            }}
            zIndex={999}
          ></IconButton>
        </>
      );
    }
    return <></>;
  }

  const invokeAction = (item: PathTeamLessonDto) => {
    const teamLessonUserInstanceId = item.teamLessonUserInstanceId;

    setCurrentTeamLessonUserInstanceId(teamLessonUserInstanceId);
    const teamResults = teams?.filter(
      (team: TeamDto) => team.id == item.teamLessonUserInstance?.teamId
    );

    if (teamResults && teamResults.length > 0) {
      setCurrentTeam(teamResults[0]);
    }
    trackTeamLesson(
      TEAM_EVENTS.JoinPresentation,
      teamResults && teamResults.length > 0 ? teamResults[0] : undefined,
      item
    );

    if (item.presenterUserId === self?.id) {
      setPresenterPathTeamLesson(item);
      if (sportEnabled) {
        startPresentOrParticipate(
          item,
          confirmationDialogRef,
          (_currentStep: string) => {
            setPresentationStep(
              [
                TeamLessonUserInstanceStatus.Initialized,
                TeamLessonUserInstanceStatus.Scheduled,
              ].includes(
                item.teamLessonInstanceStatus as TeamLessonUserInstanceStatus
              )
                ? TEAM_LESSON_INITIALIZE_STEP
                : TEAM_LESSON_PROGRESS_STEP
            );
            setPresenterModalOpen(true);
          },
          () => {
            setPresenterModalOpen(false);
          },
          true
        );
      } else {
        setPresenterModalOpen(true);
      }
    } else {
      setTimeout(() => {
        setParticipateForTeamLessonUserInstanceToServer(
          teamLessonUserInstanceId as number
        ).then(() => {
          setParticipantModalOpen(true);
          updateParticipantTeamLesson(item);
        });
      }, 300);
    }
  };

  return (
    <>
      {location.pathname.indexOf("/join") === -1 &&
        !participantModalOpen &&
        !presenterModalOpen &&
        currentActivePathTeamLessons &&
        currentActivePathTeamLessons?.length > 0 && (
          <Flex
            pos={"fixed"}
            zIndex={"1000"}
            w={"100%"}
            p={"15px"}
            bottom={"0"}
            bg={Equity_Orange}
            borderTop={"solid 1px #ccc"}
            align={"center"}
            data-testid={
              HVTestId.TeamActivityNotification.teamPresentationBanner
            }
          >
            <Flex
              w={
                currentActivePathTeamLessons.length > 1 && isMobile
                  ? "90%"
                  : "100%"
              }
            >
              <ScrollPanel isPaginationOnContainer={true}>
                {currentActivePathTeamLessons.map((item) => {
                  const label = (
                    <Flex
                      display={isMobile ? "block" : "flex"}
                      align={"center"}
                      justify={isMobile ? "center" : undefined}
                      flexDir={isMobile ? "column" : undefined}
                    >
                      {item.presenterUserId === self?.id ? (
                        <>
                          <Text>{HVLocalizeStrings.YOU_ARE_PRESENTING}</Text>
                          &nbsp;
                          <strong>
                            {getTeamName(item.teamLessonUserInstance?.teamId)}
                          </strong>
                        </>
                      ) : (
                        <Text>
                          <strong>
                            {getTeamName(item.teamLessonUserInstance?.teamId)}
                          </strong>
                          &nbsp;{HVLocalizeStrings.IS_NOW_PRESENTING}{" "}
                        </Text>
                      )}
                    </Flex>
                  );
                  return ScrollPanelBlock(
                    <Flex
                      flexBasis={"97%"}
                      align={"center"}
                      justify={"center"}
                      h={"100%"}
                      gap={"3"}
                      lineHeight="1.3"
                    >
                      <>
                        {isMobile && (
                          <>
                            <VStack textAlign="center" spacing={"15px"}>
                              {label}
                              <AnimatedButton
                                colorSet={AnimatedButtonColorSet.Primary}
                                text={HVLocalizeStrings.JOIN_TEAM_LESSON}
                                w={"auto"}
                                onClick={() => {
                                  invokeAction(item);
                                }}
                                disabled={teamDataLoading}
                              />
                            </VStack>
                          </>
                        )}
                        {!isMobile && (
                          <>
                            {label}
                            <AnimatedButton
                              colorSet={AnimatedButtonColorSet.Primary}
                              text={HVLocalizeStrings.JOIN_TEAM_LESSON}
                              w={"auto"}
                              onClick={() => {
                                invokeAction(item);
                              }}
                              disabled={teamDataLoading}
                              dataTestId={
                                HVTestId.TeamActivityNotification
                                  .joinPresentationButton
                              }
                            />
                          </>
                        )}
                      </>
                    </Flex>,
                    "100%",
                    item.id
                  );
                })}
              </ScrollPanel>
              {isMobile && (
                <IconButton
                  onClick={() => {
                    setDismissed(true);
                  }}
                  aria-label={"close"}
                  bg={"transparent"}
                  icon={
                    <Icon as={AiOutlineCloseCircle} w={"32px"} h={"32px"} />
                  }
                  w={"32px"}
                  h={"32px"}
                  _hover={{}}
                />
              )}
            </Flex>
            {!isMobile && (
              <IconButton
                onClick={() => {
                  setDismissed(true);
                }}
                aria-label={"close"}
                bg={"transparent"}
                icon={<Icon as={AiOutlineCloseCircle} w={"32px"} h={"32px"} />}
                w={"32px"}
                h={"32px"}
                alignSelf={"start"}
                _hover={{}}
              />
            )}
          </Flex>
        )}
    </>
  );
};
