import { AxiosClient } from "../AxiosClient";
import { AxiosResponse } from "axios";
import { InviteCodeDomainDto } from "@headversity/contract";
import { AuthTokensDto } from "@headversity/contract/Dto/AuthTokensDto";
import { getConfig } from "../Utils";

export const requestAccessToken = (accessToken: string, language: string) => {
  return AxiosClient.post(
    `login/accesstoken`,
    {
      accessToken: accessToken,
      language,
    },
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const loginWithMsTeamsToken = (token: string) => {
  return AxiosClient.post(
    `login/msTeams`,
    {
      token,
    },
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const getNewAccessToken = (
  token: string
): Promise<AxiosResponse<AuthTokensDto>> => {
  const config = getConfig(token);
  config.withCredentials = true;
  return AxiosClient.post(`api/self/accesstoken`, {}, config);
};

export const requestAccessTokenByCode = (code: string, language: string) => {
  return AxiosClient.post(
    `login/accesscode`,
    {
      code,
      language,
    },
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const loginAndGetAccessToken = (email: string, password: string) => {
  return AxiosClient.post(
    `login`,
    {
      email: email,
      password: password,
    },
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const logoutFromApi = () => {
  return AxiosClient.post(
    `logout`,
    {},
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const getSSODomains = (): Promise<
  AxiosResponse<InviteCodeDomainDto[]>
> => {
  return AxiosClient.get(`login/ssoDomains`);
};

export const getRefreshToken = (
  authTokensDto: AuthTokensDto
): Promise<AxiosResponse<AuthTokensDto> | string> => {
  return AxiosClient.post(
    `login/refresh`,
    {
      authTokensDto: authTokensDto,
    },
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};
