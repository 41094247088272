import { useCallback, useState } from "react";
import {
  getNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from "../../Api/Solo/NotificationApi";
import { getKey } from "../../Utils/Helpers";
import {
  FocusToolInfoDto,
  NotificationDto,
  OptimismPlanDto,
} from "@headversity/contract";
import { challengeIsTodayAndIncomplete } from "../../Components/Solo/Tools/Optimism/Utils/OptimismUtils";
import { getUpComingFocusPlans } from "../../Components/Solo/Tools/Focus/Utils/FocusUtils";

export interface INotificationProvider {
  notifications: NotificationDto[];
  notificationsUnreadCount: number;
  getNotificationsFromServer: () => void;
  markNotificationAsReadToServer: (notificationId: number) => void;
  markAllNotificationsAsReadToServer: () => void;
  getNotificationCountForTools: (
    currentPlannedItem: OptimismPlanDto | null,
    focusPlanItems: FocusToolInfoDto[] | null
  ) => number;
}

export const FOCUS_BEFORE_AFTER_MINUTE_TRIGGER = 30;

export const useNotification = (): INotificationProvider => {
  const [notifications, setNotifications] = useState<NotificationDto[]>([]);
  const [notificationsUnreadCount, setNotificationsUnreadCount] =
    useState<number>(0);

  const getNotificationsFromServer = useCallback(async () => {
    return getNotifications(getKey()).then((response) => {
      setNotifications(response.data);
      setNotificationsUnreadCount(
        response.data.filter((n: any) => !n.dateRead).length
      );
    });
  }, []);

  const markNotificationAsReadToServer = useCallback(
    async (notificationId: number) => {
      return markNotificationAsRead(getKey(), notificationId).then(
        (response) => {
          return getNotificationsFromServer();
        }
      );
    },
    []
  );

  const markAllNotificationsAsReadToServer = useCallback(async () => {
    return markAllNotificationsAsRead(getKey()).then((response) => {
      return getNotificationsFromServer();
    });
  }, []);

  const getNotificationCountForTools = useCallback(
    (
      currentPlannedItem: OptimismPlanDto | null,
      focusPlanItems: FocusToolInfoDto[] | null
    ) => {
      const optimismCompletedCount =
        currentPlannedItem && challengeIsTodayAndIncomplete(currentPlannedItem)
          ? 1
          : 0;
      const focusToolCount =
        focusPlanItems &&
        getUpComingFocusPlans(focusPlanItems, FOCUS_BEFORE_AFTER_MINUTE_TRIGGER)
          .length > 0
          ? 1
          : 0;
      return optimismCompletedCount + focusToolCount;
    },
    []
  );

  return {
    notifications,
    notificationsUnreadCount,
    getNotificationsFromServer,
    markNotificationAsReadToServer,
    markAllNotificationsAsReadToServer,
    getNotificationCountForTools,
  };
};
