import {
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Text,
  Textarea,
} from "@chakra-ui/react";
import {
  PollQuestionUserInstanceDto,
  QuestionUserResponseDto,
  ResponseState,
  CharterActivity,
} from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
} from "../../../../Styles/HeadversityStyle";
import { HvSelect } from "../../../Common/HvSelect";
import { ParticipantTeamLessonStepProps } from "../../../Team/Participant/ParticipantModal";
import { getLatestPollInstanceResultByPollQuestionId } from "../../../../Utils/TeamUtil";
import BlockSectionTitle from "../../../Common/BlockSectionTitle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { QuestionSettings } from "@headversity/contract/Common/QuestionSettings";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { useGroupInformation } from "../../../../Hooks/Sport/useGroupInformation";
import {
  ParticipantActivityLabel,
  ParticipantContainer,
  ParticipantGroupLabel,
} from "../../../Shared/Container/ParticipantContainer";
import { ParticipantWaitingScreen } from "../Shared/ParticipantWaitingScreen";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { performHapticImpact } from "../../../../Api/Utils";
import { HvSpinner } from "../../../Common/HvSpinner";
import { useSportTeam } from "../../../../Hooks/Team/useSportTeam";
import { SPORT_EVENTS } from "../../../../Utils/Analytics";

interface ParticipantScenarioProps extends ParticipantTeamLessonStepProps {}

export const ParticipantScenario = (props: ParticipantScenarioProps) => {
  const { pathTeamLesson } = props;
  const {
    pollQuestions,
    pollInstances,
    self,
    currentParticipantList,
    pollInstanceResults,
    currentTeamLessonUserInstanceId,
    setPollInstanceResultToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { isGroupLead, currentGroup } = useGroupInformation();
  const { trackEvent, lessonName } = useSportTeam();

  const [pollInstance, setPollInstance] = useState<
    PollQuestionUserInstanceDto | undefined
  >(undefined);

  const [reporterScreenStep, setReporterScreenStep] = useState<number>(1);
  const [isViolation, setIsViolation] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [weStatementPollResult, setWeStatementPollResult] = useState<
    QuestionUserResponseDto[]
  >([]);
  const [weStatementSelected, setWeStatementSelected] = useState<string>();
  const [scenarioTitle, setScenarioTitle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [hasPerformedHapticImpact, setHasPerformedHapticImpact] =
    useState(false);

  const handleViolationClick = (value: string) => {
    if (value === HVSportLocalizeStrings.CHARTER_SCENARIO_YES) {
      setIsViolation("1");
    } else {
      setIsViolation("0");
    }
  };

  const handleSubmit = async () => {
    if (pollInstance) {
      const json = {
        reason: reason,
        weStatement: weStatementSelected,
        isViolation: isViolation,
      };
      setLoading(true);
      trackEvent(
        isGroupLead
          ? SPORT_EVENTS.GroupInputSubmitted
          : SPORT_EVENTS.IndividualInputSubmitted,
        {
          HV_Presentation: lessonName,
          HV_Question_Id: pollInstance.pollQuestionId,
        }
      );
      await setPollInstanceResultToServer(pollInstance.id, {
        questionAlternativeIds: [],
        feedback: "",
        questionUserResponseIds: [],
        freeTexts: [JSON.stringify(json)],
      });
      setReporterScreenStep(3);
    }
  };

  useEffect(() => {
    if (pollInstances && currentParticipantList && self && currentGroup) {
      const currentPollInstance = pollInstances.find(
        (pi) =>
          pi.group === currentGroup &&
          pi.pollQuestion.internalTitle?.startsWith("SCENARIO_") &&
          pi.teamLessonUserInstanceId === currentTeamLessonUserInstanceId
      );
      if (currentPollInstance) {
        setPollInstance(currentPollInstance);
      }
    }
  }, [pollInstances, currentTeamLessonUserInstanceId, self, currentGroup]);

  useEffect(() => {
    if (
      currentParticipantList &&
      pollInstanceResults &&
      pollInstances &&
      pollQuestions &&
      pollQuestions.length > 0
    ) {
      const weStatementPollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_6_PQ"
      );
      const weStatementPollInstanceResult =
        getLatestPollInstanceResultByPollQuestionId(
          weStatementPollQuestion!.id,
          pollInstances,
          currentTeamLessonUserInstanceId,
          pollInstanceResults
        )?.filter((r) => r.state === ResponseState.active);

      if (!weStatementPollInstanceResult) {
        return;
      }

      setWeStatementPollResult(weStatementPollInstanceResult);
    }
  }, [
    pollInstanceResults,
    pollInstances,
    currentParticipantList,
    pollQuestions,
  ]);

  useEffect(() => {
    if (
      pollInstance &&
      pollInstances &&
      pollInstanceResults &&
      currentTeamLessonUserInstanceId
    ) {
      const settings = pollInstance.pollQuestion.question
        .settings as QuestionSettings;
      if (settings?.title?.en) {
        setScenarioTitle(settings.title.en);
      }

      const result = getLatestPollInstanceResultByPollQuestionId(
        pollInstance.pollQuestionId,
        pollInstances,
        currentTeamLessonUserInstanceId,
        pollInstanceResults
      );
      if (result && result.length > 0) {
        setReporterScreenStep(3);
      }
    }
  }, [
    pollInstance,
    pollInstances,
    pollInstanceResults,
    currentTeamLessonUserInstanceId,
  ]);

  let content;
  if (pathTeamLesson!.inProgressStep === CharterActivity.ScenarioReviews) {
    content = (
      <ParticipantWaitingScreen
        message={HVLocalizeStrings.TEAM_CHARTER_DISCUSSION_IN_PROGRESS}
      />
    );
  } else if (!isGroupLead) {
    content = (
      <Flex
        flexDir={"column"}
        align={"center"}
        justify={"center"}
        gap={"5"}
        my={"3"}
        w={"100%"}
        p={"5"}
      >
        {pollInstance?.pollQuestion ? (
          <Flex flexDir={"column"} align={"start"} justify={"start"} w={"100%"}>
            <BlockSectionTitle
              title={`${HVSportLocalizeStrings.CHARTER_SCENARIO_YOUR_SCENARIO} ${scenarioTitle}`}
              primaryTextColor={Black}
              borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
            />
            <Text mt={5} mb={"20px"} color={Black} lineHeight={1.3}>
              {pollInstance.pollQuestion?.question?.questionText as string}
            </Text>
          </Flex>
        ) : (
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
          >
            <HvSpinner />
          </Flex>
        )}
      </Flex>
    );
  } else if (reporterScreenStep === 3) {
    content = (
      <ParticipantWaitingScreen
        message={HVLocalizeStrings.TEAM_POLL_WAITING_FOR_USER_RESULTS}
      />
    );
  } else {
    if (reporterScreenStep === 1 && !hasPerformedHapticImpact) {
      performHapticImpact();
      setHasPerformedHapticImpact(true);
    }
    content = (
      <Flex
        flexDir={"column"}
        align={"center"}
        justify={"start"}
        gap={"5"}
        w={"100%"}
      >
        <Flex
          flexDir={"column"}
          align={"start"}
          justify={"start"}
          minH={"350px"}
          gap={"5"}
          my={"5"}
          w={"100%"}
          maxW={"900px"}
          p={"5"}
          bg={pollInstance?.pollQuestion ? "white" : "transparent"}
        >
          {!pollInstance?.pollQuestion && (
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              height={"100%"}
            >
              <HvSpinner />
            </Flex>
          )}
          {pollInstance?.pollQuestion && reporterScreenStep === 1 && (
            <Flex
              flexDir={"column"}
              align={"center"}
              justify={"center"}
              w={"100%"}
            >
              <Flex
                flexDir={"column"}
                align={"start"}
                justify={"start"}
                w={"100%"}
              >
                <BlockSectionTitle
                  title={`${HVSportLocalizeStrings.CHARTER_SCENARIO_YOUR_SCENARIO} ${scenarioTitle}`}
                  primaryTextColor={Black}
                  borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
                />
                <Text mt={5} mb={"20px"} color={Black} lineHeight={1.3}>
                  {pollInstance.pollQuestion?.question?.questionText as string}
                </Text>
              </Flex>
              <AnimatedButton
                mt={5}
                colorSet={AnimatedButtonColorSet.Primary}
                text={HVLocalizeStrings.NEXT}
                w={300}
                onClick={() => {
                  setReporterScreenStep(2);
                }}
              ></AnimatedButton>
            </Flex>
          )}
          {pollInstance?.pollQuestion && reporterScreenStep === 2 && (
            <Flex
              flexDir={"column"}
              align={"start"}
              justify={"start"}
              gap={2}
              w={"100%"}
            >
              <BlockSectionTitle
                title={`${HVSportLocalizeStrings.CHARTER_SCENARIO_YOUR_SCENARIO} ${scenarioTitle}`}
                primaryTextColor={Black}
                borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
              />
              <Text mt={5}>
                {
                  HVSportLocalizeStrings.CHARTER_SCENARIO_QUESTION_INSTRUCTION_ONE
                }
              </Text>
              <RadioGroup onChange={handleViolationClick}>
                <HStack spacing={5}>
                  <HStack spacing={2}>
                    <Radio
                      size="lg"
                      borderColor="#aeb4ca"
                      background="white"
                      value={`${HVSportLocalizeStrings.CHARTER_SCENARIO_YES}`}
                    />
                    <Text>{HVSportLocalizeStrings.CHARTER_SCENARIO_YES}</Text>
                  </HStack>
                  <HStack spacing={2}>
                    <Radio
                      size="lg"
                      borderColor="#aeb4ca"
                      background="white"
                      value={`${HVSportLocalizeStrings.CHARTER_SCENARIO_NO}`}
                    />
                    <Text>{HVSportLocalizeStrings.CHARTER_SCENARIO_NO}</Text>
                  </HStack>
                </HStack>
              </RadioGroup>
              <Text mt={5}>
                {
                  HVSportLocalizeStrings.CHARTER_SCENARIO_QUESTION_INSTRUCTION_TWO
                }
              </Text>
              <Textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder={
                  HVSportLocalizeStrings.CHARTER_SCENARIO_ENTER_RESPONSE
                }
                width="100%"
                minH={"84px"}
              />
              <Text mt={5}>
                {
                  HVSportLocalizeStrings.CHARTER_SCENARIO_QUESTION_INSTRUCTION_THREE
                }
              </Text>
              <HvSelect
                placeholder={HVSportLocalizeStrings.CHARTER_SCENARIO_SELECT_WE}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  setWeStatementSelected(selectedValue);
                }}
                maxW="300px"
                bg="white"
                color={Black}
              >
                {weStatementPollResult.map((questionUserResponse) => (
                  <option
                    key={questionUserResponse.id}
                    value={questionUserResponse.id}
                  >
                    {questionUserResponse.responseText}
                  </option>
                ))}
              </HvSelect>
              {loading ? (
                <Flex justify="center" align="center" width="300px">
                  <HvSpinner />
                </Flex>
              ) : (
                <AnimatedButton
                  mt={8}
                  disabled={!weStatementSelected || !isViolation || loading}
                  colorSet={AnimatedButtonColorSet.Primary}
                  text={HVLocalizeStrings.WE_ARE_DONE}
                  w={300}
                  onClick={handleSubmit}
                ></AnimatedButton>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  }

  return (
    <ParticipantContainer
      leftContent={<ParticipantActivityLabel groupActivity={true} />}
      rightContent={<ParticipantGroupLabel />}
    >
      {content}
    </ParticipantContainer>
  );
};
