import { Center, Heading, IconButton, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { AddIcon } from "@chakra-ui/icons";
import { OPTIMISM_LENGTH_SELECTION_STEP } from "./OptimismTool";
import {
  BUTTON_PRIMARY_HOVER_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
  TOOL_START_BUTTON_BACKGROUND,
  TOOL_START_BUTTON_TEXT,
} from "../../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface OptimismToolIntroProps {
  changePage: (page: string) => void;
}

export const OptimismToolStepIntro = (props: OptimismToolIntroProps) => {
  const { changePage } = props;
  return (
    <>
      <Heading
        fontSize={"2xl"}
        fontWeight={"semibold"}
        color={MODAL_PRIMARY_TEXT_COLOR}
        data-testid={HVTestId.OptimismTool.steps.intro.header}
      >
        {HVLocalizeStrings.OPTIMISM_TOOLS_INTRO_TITLE}
      </Heading>
      <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
        {HVLocalizeStrings.OPTIMISM_TOOLS_INTRO}
      </Text>
      <Center mb={2}>
        <IconButton
          mt={"10"}
          h={"44px"}
          w={"44px"}
          borderRadius="100px"
          boxShadow={"lg"}
          bgColor={TOOL_START_BUTTON_BACKGROUND}
          color={TOOL_START_BUTTON_TEXT}
          _hover={{ bg: BUTTON_PRIMARY_HOVER_COLOR }}
          aria-label="Start a challenge"
          icon={<AddIcon />}
          onClick={() => changePage(OPTIMISM_LENGTH_SELECTION_STEP)}
          data-testid={HVTestId.OptimismTool.steps.intro.plusButton}
        />
      </Center>
    </>
  );
};
