import React, { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { PollQuestionDto, ResponseState } from "@headversity/contract";
import {
  Box,
  Checkbox,
  Flex,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  Border_Radius,
} from "../../../../Styles/HeadversityStyle";
import { ScrollPanel } from "../../../Common/ScrollPanel";
import { ScrollPanelBlock } from "../../../Common/ScrollPanelBlock";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { useGroupInformation } from "../../../../Hooks/Sport/useGroupInformation";
import { ParticipantWaitingScreen } from "../Shared/ParticipantWaitingScreen";
import {
  ParticipantActivityLabel,
  ParticipantContainer,
  ParticipantGroupLabel,
} from "../../../Shared/Container/ParticipantContainer";
import { usePollQuestionInformation } from "../../../../Hooks/Sport/usePollQuestionInformation";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { performHapticImpact } from "../../../../Api/Utils";
import BlockSectionTitle from "../../../Common/BlockSectionTitle";
import { HvSpinner } from "../../../Common/HvSpinner";
import { useSportTeam } from "../../../../Hooks/Team/useSportTeam";
import { SPORT_EVENTS } from "../../../../Utils/Analytics";

export const ParticipantWeStatementStepTwo = () => {
  const {
    pollQuestions,
    currentTeamLessonUserInstanceId,
    setPollInstanceResultToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { isGroupLead } = useGroupInformation();
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const [weStatementPollQuestion, setWeStatementPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const [discussionPollQuestion, setDiscussionPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const { trackEvent, lessonName } = useSportTeam();

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [finish, setFinish] = useState<boolean>(false);

  const [needDiscussionIds, setNeedDiscussionIds] = useState<number[]>([]);
  const [hasPerformedHapticImpact, setHasPerformedHapticImpact] =
    useState(false);

  const paddingForQuestionText = isDesktop ? "20px" : undefined;

  useEffect(() => {
    if (pollQuestions) {
      const weStatementPollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_6_PQ"
      );
      setWeStatementPollQuestion(weStatementPollQuestion);

      const discussionPollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_8_PQ"
      );
      setDiscussionPollQuestion(discussionPollQuestion);
    }
  }, [pollQuestions]);

  const { pollQuestionResults: weStatementPollResult } =
    usePollQuestionInformation({
      pollQuestion: weStatementPollQuestion,
      byGroup: true,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
    });

  useEffect(() => {
    if (
      weStatementPollResult?.filter((w) => w.state === ResponseState.active)
        .length === 1
    ) {
      setDisableSubmit(false);
    }
  }, [weStatementPollResult]);

  const {
    groupPollQuestionResults: discussionPollResults,
    currentPollInstanceId,
  } = usePollQuestionInformation({
    pollQuestion: discussionPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  if (!currentPollInstanceId) {
    return <HvSpinner centerVertically />;
  }
  let content;
  if (isGroupLead && discussionPollResults?.length < 1 && !finish) {
    if (!hasPerformedHapticImpact) {
      performHapticImpact();
      setHasPerformedHapticImpact(true);
    }
    content = (
      <>
        <Flex alignSelf={"start"}>
          <BlockSectionTitle
            title={HVSportLocalizeStrings.CHARTER_WE_STATEMENTS}
            primaryTextColor={Black}
            borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
          />
        </Flex>
        <Box maxW={"360px"}>
          <ScrollPanel
            onSlideChangeCallBack={(number) => {
              if (
                number ===
                weStatementPollResult?.filter(
                  (w) => w.state === ResponseState.active
                ).length -
                  1
              ) {
                setDisableSubmit(false);
              }
            }}
            spaceBetween={8}
            showChevronContainer={
              isDesktop &&
              weStatementPollResult?.filter(
                (w) => w.state === ResponseState.active
              ).length > 1
            }
            slidesPerGroup={1}
            slidesPerView={"auto"}
            isPaginationOnContainer={true}
            customBulletClass={"Sport-Charter-Scroll-Bullet"}
          >
            {weStatementPollResult
              ?.filter((p) => p.state === ResponseState.active)
              .map((item) => {
                return ScrollPanelBlock(
                  <Flex
                    w={"360px"}
                    height={"300px"}
                    flexDir={"column"}
                    align={"center"}
                    justify={"center"}
                    p={"3"}
                  >
                    <Flex
                      h={"300px"}
                      w={"350px"}
                      flexDir={"column"}
                      align={"center"}
                      justify={"center"}
                      bg={"white"}
                      borderRadius={Border_Radius}
                      boxShadow={"lg"}
                    >
                      <Flex
                        h={"300px"}
                        w={"350px"}
                        flexDir={"column"}
                        align={"center"}
                        justify={"center"}
                        px={paddingForQuestionText}
                      >
                        <Text w={"100%"} textAlign={"center"}>
                          {item.responseText}
                        </Text>
                      </Flex>
                      <Flex
                        flexDir={"row"}
                        align={"center"}
                        justify={"center"}
                        gap={"2"}
                        pb={"5"}
                      >
                        <Checkbox
                          colorScheme="blue"
                          disabled={loading}
                          isChecked={needDiscussionIds.indexOf(item.id) > -1}
                          onChange={(e) => {
                            const index = needDiscussionIds.indexOf(item.id);
                            if (index > -1) {
                              needDiscussionIds.splice(index, 1);
                            } else {
                              trackEvent(
                                SPORT_EVENTS.GroupCharterStatementFlagged
                              );
                              needDiscussionIds.push(item.id);
                            }
                            setNeedDiscussionIds([...needDiscussionIds]);
                          }}
                        >
                          <Text fontSize={"sm"}>
                            {
                              HVSportLocalizeStrings.CHARTER_WE_STATEMENT_TWO_INSTRUCTION_DISCUSS
                            }
                          </Text>
                        </Checkbox>
                      </Flex>
                    </Flex>
                  </Flex>,
                  "360px",
                  item.id
                );
              })}
          </ScrollPanel>
        </Box>

        <Stack spacing={2}>
          {" "}
          <AnimatedButton
            disabled={disableSubmit || loading}
            w={300}
            text={HVLocalizeStrings.WE_ARE_DONE}
            onClick={async () => {
              if (currentPollInstanceId) {
                setLoading(true);
                setFinish(true);

                trackEvent(
                  isGroupLead
                    ? SPORT_EVENTS.GroupInputSubmitted
                    : SPORT_EVENTS.IndividualInputSubmitted,
                  {
                    HV_Presentation: lessonName,
                    HV_Question_Id: discussionPollQuestion?.id,
                  }
                );
                setPollInstanceResultToServer(currentPollInstanceId, {
                  questionAlternativeIds: [],
                  feedback: "",
                  questionUserResponseIds: [],
                  freeTexts: [needDiscussionIds.join(",")],
                });
              }
            }}
            colorSet={AnimatedButtonColorSet.Third}
          />
        </Stack>
      </>
    );
  } else {
    content = (
      <ParticipantWaitingScreen
        messageBold={HVSportLocalizeStrings.CHARTER_GROUP_ACTIVITY}
        message={
          discussionPollResults?.length
            ? HVSportLocalizeStrings.CHARTER_WAITING_FOR_PRESENTER_TO_CONTINUE
            : HVSportLocalizeStrings.CHARTER_WAITING_FOR_GROUP_LEADER
        }
      />
    );
  }

  return (
    <ParticipantContainer
      leftContent={<ParticipantActivityLabel groupActivity={true} />}
      rightContent={<ParticipantGroupLabel />}
    >
      {content}
    </ParticipantContainer>
  );
};
