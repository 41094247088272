import { Flex, Icon, Text, useBreakpointValue } from "@chakra-ui/react";
import { AiOutlineClockCircle } from "react-icons/ai";
import {
  BLOCK_TITLE_GRADIENT,
  BOOST_TEXT_COLOR,
  Burnt_Orange,
  TEAM_LESSON_TODO_GRADIENT,
  TEAM_LESSON_TODO_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { useContext } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  MdCheckCircleOutline,
  MdInfo,
  MdOutlineErrorOutline,
} from "react-icons/md";
import {
  getScrubbedTeamLessonDescription,
  isAnotherTeamLessonInProgressForTeam,
  trackTeamLesson,
} from "../../../Utils/TeamUtil";
import { PresentationAccordionItemBlock } from "../Shared/PresentationAccordionItemBlock";
import { TEAM_EVENTS } from "../../../Utils/Analytics";
import IconWithToolTip from "../../Common/IconWithToolTip";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { HVTestId } from "../../../Testing/dataTestIds";
import { navigateWithSearchParams, truncate } from "../../../Utils/Helpers";
import { TeamLessonUserInstanceStatus } from "../../../Types/HVTypes";
import { PathDto, PathTeamLessonDto, TeamDto } from "@headversity/contract";
import { Dictionary } from "highcharts";

interface PathTeamLessonBlockProps {
  pathTeamLesson: PathTeamLessonDto;
  forceOpaque?: boolean;
  selectedTeam?: TeamDto;
  customAsyncCallback?: () => void;
  trackingOrigin?: string;
  skipNavigation?: boolean;
}

export const PathTeamLessonBlock = (props: PathTeamLessonBlockProps) => {
  const {
    pathTeamLesson,
    forceOpaque,
    selectedTeam,
    customAsyncCallback,
    trackingOrigin,
    skipNavigation,
  } = props;
  const { teamLesson } = pathTeamLesson;
  const { selectedUserLanguage, paths, currentTeam, setCurrentTeam } =
    useContext<IGlobalProvider>(GlobalContext);
  const navigate = useNavigate();

  const { confirmationDialogRef } = useContext<IShellProvider>(ShellContext);

  const currentState = setTeamCompletionState(
    pathTeamLesson,
    paths,
    selectedTeam ?? currentTeam,
    forceOpaque
  );
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  return (
    <PresentationAccordionItemBlock
      onClick={async () => {
        let currentlySelectedTeam = selectedTeam;
        if (selectedTeam) {
          setCurrentTeam(selectedTeam);
        } else {
          currentlySelectedTeam = currentTeam;
        }
        trackTeamLesson(
          TEAM_EVENTS.ViewPresentationIntroNoTeam,
          currentlySelectedTeam,
          pathTeamLesson,
          trackingOrigin
        );
        if (!currentlySelectedTeam) {
          (confirmationDialogRef.current as any).confirmation(
            HVLocalizeStrings.TEAM_JOIN_NEW_TEAM,
            HVLocalizeStrings.TEAM_NO_TEAM_DESCRIPTION,
            ConfirmationButtons.Ok,
            () => {}
          );
          return;
        }
        if (customAsyncCallback) {
          await customAsyncCallback();
        }
        if (!skipNavigation) {
          navigateWithSearchParams(
            navigate,
            `/team/paths`,
            {
              pathId: pathTeamLesson?.pathId.toString(),
              teamLessonId: pathTeamLesson?.teamLesson.id.toString(),
            },
            true,
            true
          );
        }
      }}
      opacity={currentState.opacity}
      overlay={currentState.overlay}
      image={teamLesson?.imageUrl}
      icon={currentState.icon}
      dataTestId={`${HVTestId.PathPresentationBlock.presentationButton}${pathTeamLesson?.teamLesson.id}`}
      content={
        <Flex
          w="100%"
          flexDir={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          align={"center"}
          p={isMobile ? "2" : undefined}
          h={"100%"}
        >
          <Flex
            flexDir={"column"}
            textAlign={"left"}
            w={isMobile ? "100%" : undefined}
          >
            <Text
              display={isMobile ? "block" : undefined}
              color={SECONDARY_TEXT_COLOR}
              fontWeight={"500"}
              fontSize={isMobile ? "sm" : undefined}
              lineHeight={isMobile ? "1.2" : "1.3"}
              overflow={isMobile ? "hidden" : undefined}
              textOverflow={"ellipsis"}
              w={isMobile ? "100%" : undefined}
              data-testid={HVTestId.PathPresentationBlock.presentationName}
            >
              {teamLesson.name as string}
            </Text>
            {!isMobile && (
              <Text
                display={isMobile ? "block" : undefined}
                fontSize={isMobile ? "xs" : "sm"}
                maxH={isMobile ? "15px" : undefined}
                overflow={isMobile ? "hidden" : undefined}
                textOverflow={"ellipsis"}
                w={isMobile ? "100%" : undefined}
                dangerouslySetInnerHTML={{
                  __html: teamLesson.shortDescription
                    ? (teamLesson.shortDescription as string)
                    : truncate(
                        getScrubbedTeamLessonDescription(
                          selectedUserLanguage,
                          teamLesson
                        ),
                        200
                      ),
                }}
              />
            )}
          </Flex>
          <Flex
            gap={2}
            alignSelf={["flex-start", "flex-start", "center"]}
            color={SECONDARY_TEXT_COLOR}
            flexDir={isMobile ? "row" : "column-reverse"}
            ml={isMobile ? "" : 8}
            minW={["100%", "75px"]}
          >
            {isMobile ? null : (
              <Flex gap={1} alignItems={"center"} w={"100%"}>
                <Icon as={AiOutlineClockCircle} w={"17px"} h={"17px"} />
                <Text
                  data-testid={
                    HVTestId.PathPresentationBlock.presentationDuration
                  }
                >{`${teamLesson?.duration}:00`}</Text>
              </Flex>
            )}
            <Text
              w={"100%"}
              textAlign={"left"}
              data-testid={HVTestId.PathPresentationBlock.presentationState}
            >
              {currentState.stateText}
            </Text>
          </Flex>
        </Flex>
      }
    />
  );
};

const CompleteTeamLessonText = () => {
  return (
    <Text fontSize={"xs"} pr="10px" color={BOOST_TEXT_COLOR}>
      {HVLocalizeStrings.COMPLETE}
    </Text>
  );
};

const InProgressTeamLessonText = () => {
  return (
    <Text fontSize={"xs"} pr="10px" color={Burnt_Orange}>
      {HVLocalizeStrings.IN_PROGRESS}
    </Text>
  );
};

const TodoTeamLessonText = () => {
  return (
    <>
      <Flex gap={1} alignItems={"center"} color={TEAM_LESSON_TODO_TEXT_COLOR}>
        <IconWithToolTip
          icon={MdInfo}
          text={HVLocalizeStrings.TEAM_CATCH_UP_INFO_TEXT}
          placement="bottom"
          color={TEAM_LESSON_TODO_TEXT_COLOR}
        />
        <Text fontSize={"xs"} pr="10px">
          {HVLocalizeStrings.CATCH_UP}
        </Text>
      </Flex>
    </>
  );
};

export const setTeamCompletionState = (
  pathTeamLesson: PathTeamLessonDto,
  paths: Dictionary<PathDto[]>,
  currentTeam: TeamDto | undefined,
  forceOpaque?: boolean
) => {
  let state = pathTeamLesson.teamLessonInstanceStatus;
  switch (state) {
    case TeamLessonUserInstanceStatus.Complete:
    case TeamLessonUserInstanceStatus.ForcedComplete:
      return {
        stateText: <CompleteTeamLessonText />,
        icon: <MdCheckCircleOutline />,
        overlay: BLOCK_TITLE_GRADIENT,
        opacity: "",
      };
    case TeamLessonUserInstanceStatus.Initialized:
    case TeamLessonUserInstanceStatus.InProgress:
      return {
        stateText: <InProgressTeamLessonText />,
        icon: null,
        overlay: "",
        opacity: "1",
      };
    case TeamLessonUserInstanceStatus.Catchup:
      return {
        stateText: <TodoTeamLessonText />,
        icon: <MdOutlineErrorOutline />,
        overlay: TEAM_LESSON_TODO_GRADIENT,
        opacity: "",
      };
    case TeamLessonUserInstanceStatus.UpNext:
      const isAnotherTeamLessonInProgress =
        isAnotherTeamLessonInProgressForTeam(
          paths,
          currentTeam,
          pathTeamLesson.id,
          pathTeamLesson.pathId,
          false
        );
      return {
        stateText: null,
        icon: null,
        overlay: "",
        opacity: isAnotherTeamLessonInProgress && !forceOpaque ? "0.3" : "1",
      };
  }
  return {
    stateText: null,
    icon: null,
    overlay: "",
    opacity: "0.3",
  };
};
