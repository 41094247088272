import { Box, Text } from "@chakra-ui/react";
import { isDesktop } from "react-device-detect";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { useContext } from "react";
import {
  IShellProvider,
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../../State/ShellContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { White } from "../../../Styles/HeadversityStyle";
import { ReachInsightsContext } from "../../../State/Reach/ReachInsightsContext";
import InsightsPsychographicsLegend from "./InsightsPsychographicsLegend";

interface GlossarySectionProps {
  hideTitle?: boolean;
  showPsychographics: boolean;
}

const GlossarySection = (props: GlossarySectionProps) => {
  const { isMainContainerXL, theme } = useContext<IShellProvider>(ShellContext);
  const { companyCerts } = useContext(ReachInsightsContext);
  return (
    <Box
      maxW={isMainContainerXL ? "container.xl" : "container.lg"}
      mx={"auto"}
      mt={isDesktop ? "40px" : "20px"}
      mb={"20px"}
      color={theme.name === NOSTALGIC_THEME_NAME ? White : undefined}
    >
      {!props.hideTitle && (
        <BlockSectionTitle
          title={HVLocalizeStrings.GLOSSARY}
        ></BlockSectionTitle>
      )}
      <Box mt={"20px"}>
        <Text lineHeight={"1.5"} mb={"10px"}>
          <b style={{ fontWeight: 600 }}>
            {HVLocalizeStrings.REACH_INSIGHTS_ENROLLED_USERS}:
          </b>{" "}
          {HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_ENROLLED_USER_DESCRIPTION}
        </Text>
        <Text lineHeight={"1.5"} mb={"10px"}>
          <b style={{ fontWeight: 600 }}>
            {HVLocalizeStrings.REACH_INSIGHTS_ACTIVE_USERS}:
          </b>{" "}
          {HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_ACTIVE_USER_DESCRIPTION}
        </Text>
        {props.showPsychographics && (
          <InsightsPsychographicsLegend pdfExport={true} />
        )}
        {companyCerts.length > 0 && (
          <Text lineHeight={"1.5"} mb={"10px"}>
            <b style={{ fontWeight: 600 }}>
              {HVLocalizeStrings.REACH_INSIGHTS_ACTIVE_CERTIFICATIONS}:
            </b>{" "}
            {
              HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_ACTIVE_CERTIFICATIONS_DESCRIPTION
            }
          </Text>
        )}
      </Box>
    </Box>
  );
};

export { GlossarySection };
