import {
  HierarchyAssociationStatusDto,
  UserDto,
  UserRole,
} from "@headversity/contract";
import { useState, useEffect, useContext } from "react";
import { IGlobalProvider, GlobalContext } from "../../State/GlobalContext";

const determineIfCompanyAdmin = (
  userHierarchyAssociations: HierarchyAssociationStatusDto | undefined,
  isReachAdminEnabled: boolean | null,
  user: UserDto | null
): boolean => {
  if (!user || !isReachAdminEnabled) {
    return false;
  }
  const isHvAdmin = user.role <= UserRole.HVAdmin;
  // headversity staff always sees everything
  if (isHvAdmin && isReachAdminEnabled) {
    return true;
  }
  const isCompanyReachAdmin = (
    !userHierarchyAssociations?.hierarchyId
      ? true
      : userHierarchyAssociations?.isRoot && isReachAdminEnabled
  ) as boolean;
  return isCompanyReachAdmin;
};

export interface ICompanyReachAdmin {
  isCompanyReachAdmin: boolean;
  isHvAdmin: boolean;
}

const useCompanyReachAdmin = (): ICompanyReachAdmin => {
  const { self, isReachAdminEnabled, userHierarchyAssociations, isHvAdmin } =
    useContext<IGlobalProvider>(GlobalContext);

  const [isCompanyReachAdmin, setIsCompanyReachAdmin] =
    useState<boolean>(false);

  useEffect(() => {
    setIsCompanyReachAdmin(
      determineIfCompanyAdmin(
        userHierarchyAssociations,
        isReachAdminEnabled,
        self
      )
    );
  }, [userHierarchyAssociations, isReachAdminEnabled, self]);

  return { isCompanyReachAdmin, isHvAdmin: (!!isHvAdmin) };
};

export default useCompanyReachAdmin;
