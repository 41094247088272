import { TeamDto, TeamUserRole, UserDto } from "@headversity/contract";
import { saveTeam } from "../../Api/Team/TeamApi";
import { getKey } from "../../Utils/Helpers";
import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../State/GlobalContext";
import { track } from "../../Utils/Analytics";

export interface IUseSportTeammProvider {
  saveTeam: (teamId: number, name: string, logo: string) => void;
  isLeader: boolean;
  role?: TeamUserRole;
  lessonName?: string;
  team: TeamDto;
  trackEvent: (eventName: string, properties?: any) => void;
}

const trackSportEvent = (
  eventName: string,
  teamId: number,
  teamName: string,
  sportRole: TeamUserRole,
  properties?: any
) => {
  track(eventName, {
    sport_team_id: teamId,
    sport_role: sportRole,
    sport_team_name: teamName,
    ...properties,
  });
};

const getIsLeader = (team: TeamDto, self: UserDto) => {
  const isLeader =
    team.teamUsers.filter(
      (tu) => tu.userId === self.id && tu.role === TeamUserRole.Leader
    ).length > 0;
  return isLeader;
};

export const useSportTeam = () => {
  const {
    currentTeam,
    self,
    getTeamsFromServer,
    presenterPathTeamLesson,
    participantPathTeamLesson,
  } = useContext(GlobalContext);

  const [isLeader, setIsLeader] = useState(false);
  const [userRole, setUserRole] = useState<undefined | TeamUserRole>();
  const [lessonName, setLessonName] = useState<string>();

  useEffect(() => {
    if (currentTeam && self) {
      setIsLeader(getIsLeader(currentTeam, self));
      setUserRole(
        getIsLeader(currentTeam, self)
          ? TeamUserRole.Leader
          : TeamUserRole.Player
      );
    }
  }, [currentTeam, self]);

  useEffect(() => {
    if (presenterPathTeamLesson) {
      setLessonName(presenterPathTeamLesson.teamLesson.name_loc?.en as string);
    }
    if (participantPathTeamLesson) {
      setLessonName(
        participantPathTeamLesson.teamLesson.name_loc?.en as string
      );
    }
  }, [presenterPathTeamLesson, participantPathTeamLesson]);

  const editTeamHandler = useCallback(
    async (teamId: number, name: string, logo: string) => {
      const saveRes = await saveTeam(getKey(), {
        id: teamId,
        name: name,
        logo,
      } as unknown as TeamDto);

      await getTeamsFromServer(saveRes.data.code);
    },
    []
  );

  const trackEvent = useCallback(
    (eventName: string, properties?: any) => {
      if (!currentTeam || !self) {
        console.debug(
          "No current user or team found, skipping event tracking until initialized"
        );
        return;
      }
      trackSportEvent(
        eventName,
        currentTeam.id,
        currentTeam.name,
        getIsLeader(currentTeam, self)
          ? TeamUserRole.Leader
          : TeamUserRole.Player,
        properties
      );
    },
    [currentTeam, self]
  );

  return {
    saveTeam: editTeamHandler,
    isLeader,
    role: userRole,
    currentTeam,
    trackEvent,
    lessonName,
  };
};
