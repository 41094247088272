export enum CharterActivity {
  TeamValues = "Activity: Team Values",
  Grouping = "Activity: Grouping",
  BestWorstStepOne = "Activity: Best Worst Step One",
  BestWorstStepTwo = "Activity: Best Worst Step Two",
  BestWorstStepTwoResults = "Activity: Best Worst Step Two Results",
  WeStatementStepOne = "Activity: WE Statement Step One",
  WeStatementStepOneBreak = "Activity: WE Statement Step One - Break Time",
  WeStatementStepTwo = "Activity: WE Statement Step Two",
  CharterCreation = "Activity: Charter Creation",
  Scenarios = "Activity: Scenarios",
  ScenarioReviews = "Activity: Scenario Reviews",
  SelectingCharterChampions = "Activity: Selecting Charter Champions",
  CharterChampionsOnly = "Activity: Charter Champions Only",
  Paused = "Activity: Paused",
}
