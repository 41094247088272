import {
  Box,
  Button,
  Divider,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  NanoPracticeUserInstanceDto,
  QuestionType,
} from "@headversity/contract";
import {
  getMessagesFromAiResponse,
  getNanoPracticeHistory,
  getNanoPracticeQuestionsFromResponses,
} from "./NanoPracticeUtils";
import { DateTime } from "luxon";
import { ScrollPanel } from "../../Common/ScrollPanel";
import { ScrollPanelBlock } from "../../Common/ScrollPanelBlock";
import {
  Border_Radius,
  Equity_Orange,
  MODAL_BACKGROUND,
  MODAL_THIRD_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import dayjs from "dayjs";
import { truncate } from "../../../Utils/Helpers";
import { InputQuestionAnswer } from "./NanoPracticeModal";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  NanoPracticeSummaryContent,
  useSummaryParser,
} from "./NanoPracticeSummaryContent";
import { NanoPracticeResponseList } from "./NanoPracticeResponseList";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { translateLocalizationText } from "../../../Utils/TranslateUtil";

export interface InputHistory {
  responseText: string;
  date: string | DateTime | undefined;
  tellItBack?: string;
  instanceId?: number;
  isAiResponse?: boolean;
}

export interface NanoPracticeHistoryProps {
  nanoPracticeUserInstances: NanoPracticeUserInstanceDto[];
}

export const NanoPracticeHistory = ({
  nanoPracticeUserInstances,
}: NanoPracticeHistoryProps) => {
  const { selectedUserLanguage } = useContext<IGlobalProvider>(GlobalContext);
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const [historyValues, setHistoryValues] = useState<InputHistory[]>([]);
  const [selectedInstanceId, setSelectedInstanceId] = useState<number>(0);
  const [currentInputs, setCurrentInputs] = useState<InputQuestionAnswer[]>([]);
  const [aiResponse, setAiResponse] = useState<string>("");
  const [tabIndex, setTabIndex] = useState(0);

  const { summaryContents } = useSummaryParser({
    aiResponse: aiResponse,
    aiResponseTitle: HVLocalizeStrings.HERES_A_QUICK_RECAP,
    aiHistoryTitle: "",
    aiNextStepTitle: HVLocalizeStrings.NEXT_STEP,
  });

  useEffect(() => {
    if (!nanoPracticeUserInstances) {
      return;
    }
    const instance = nanoPracticeUserInstances.find(
      (instance) => instance.id === selectedInstanceId
    );

    const inputs = instance?.questionUserResponses
      .filter(
        (response) => response.question.questionType !== QuestionType.Feedback
      )
      .map((currInput) => {
        const nanoPracticeQuestions = getNanoPracticeQuestionsFromResponses(
          instance.questionUserResponses
        );
        const title = !instance?.aiResponse
          ? translateLocalizationText(
              nanoPracticeQuestions?.find(
                (npq) => npq.questionId === currInput?.question.id
              )?.tellItBack_loc,
              selectedUserLanguage
            )
          : undefined;
        return {
          title: title?.length > 0 ? title : currInput?.question.questionText,
          value: currInput?.responseText,
        };
      }) as InputQuestionAnswer[];
    if (instance?.aiResponse) {
      setAiResponse(instance?.aiResponse);
    } else {
      setAiResponse("");
    }
    setCurrentInputs(inputs);
    setTabIndex(0);
  }, [selectedInstanceId, nanoPracticeUserInstances]);

  // Get the history values from the nano practice instances
  useEffect(() => {
    const history = getNanoPracticeHistory(
      nanoPracticeUserInstances,
      selectedUserLanguage
    );
    setHistoryValues(history);
    if (history[0]?.instanceId) {
      setSelectedInstanceId?.(history[0].instanceId);
    }
  }, [nanoPracticeUserInstances]);

  return !currentInputs ? (
    <></>
  ) : (
    <>
      {aiResponse && summaryContents ? (
        <Tabs
          variant="line"
          mt={"-10px"}
          index={tabIndex}
          onChange={(index) => {
            setTabIndex(index);
          }}
        >
          <Box mx={2}>
            <TabList w={"100%"}>
              <Tab
                fontSize={"smaller"}
                _selected={{
                  color: MODAL_THIRD_TEXT_COLOR,
                  borderBottom: "3px solid",
                }}
              >
                {HVLocalizeStrings.SUMMARY}
              </Tab>
              <Tab
                fontSize={"smaller"}
                _selected={{
                  color: MODAL_THIRD_TEXT_COLOR,
                  borderBottom: "3px solid",
                }}
              >
                {HVLocalizeStrings.RESPONSES}
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel p={0} pt={3}>
                <Box
                  h={isDesktop ? "268px" : `${window.innerHeight - 400}px`}
                  pr="20px"
                  overflow={"auto"}
                >
                  <NanoPracticeSummaryContent
                    summaryContents={summaryContents}
                  />
                </Box>
              </TabPanel>
              <TabPanel p={0} pt={3}>
                <Box
                  h={isDesktop ? "268px" : `${window.innerHeight - 400}px`}
                  pr="20px"
                  overflow={"auto"}
                >
                  <NanoPracticeResponseList currentInputs={currentInputs} />
                </Box>
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
      ) : (
        <>
          <Box
            h={isDesktop ? "350px" : `${window.innerHeight - 350}px`}
            pr="20px"
            overflow={"auto"}
          >
            <NanoPracticeResponseList currentInputs={currentInputs} />
          </Box>
          <Divider />
        </>
      )}
      <NanoPracticeHistoryScrollPanel
        setSelectedInstanceId={setSelectedInstanceId}
        selectedInstanceId={selectedInstanceId}
        historyValues={historyValues}
        isDesktop={isDesktop}
      />
    </>
  );
};

interface NanoPracticeHistoryScrollPanelProps {
  setSelectedInstanceId?: (answer: number) => void;
  selectedInstanceId: number;
  historyValues: InputHistory[];
  isDesktop?: boolean;
}
const NanoPracticeHistoryScrollPanel = ({
  setSelectedInstanceId,
  selectedInstanceId,
  historyValues,
  isDesktop,
}: NanoPracticeHistoryScrollPanelProps) => {
  const smallHistoryWidth = Math.min(250, window.innerWidth - 80);

  return (
    <Box pt={10}>
      <ScrollPanel
        spaceBetween={8}
        showChevronContainer={true}
        slidesPerGroup={isDesktop ? 3 : 1}
        slidesPerView={"auto"}
        isPaginationOnContainer={true}
        centeredSlides={!isDesktop}
        onSlideChangeCallBack={(idx: number) => {
          if (!isDesktop) {
            const answer = historyValues[idx];
            if (answer.instanceId) {
              setSelectedInstanceId?.(answer.instanceId);
            }
          }
        }}
        currentIndex={historyValues.findIndex(
          (x) => x.instanceId === selectedInstanceId
        )}
      >
        {historyValues.map((answer, idx) => {
          return ScrollPanelBlock(
            <Button
              borderRadius={Border_Radius}
              boxShadow={"lg"}
              className={`button-block ${
                selectedInstanceId === answer.instanceId ? "no-hover" : ""
              }`}
              display={"flex"}
              key={answer.instanceId}
              alignItems={"center"}
              justifyContent={"center"}
              width={`${smallHistoryWidth + 10}px`}
              height={"120px !important"}
              borderStyle={"solid !important"}
              borderWidth={`2px !important`}
              borderColor={`${
                selectedInstanceId === answer.instanceId
                  ? Equity_Orange
                  : "transparent"
              } !important`}
              onClick={() => {
                if (answer.instanceId) {
                  setSelectedInstanceId?.(answer.instanceId);
                }
              }}
              as={selectedInstanceId === answer.instanceId ? "div" : undefined}
            >
              <Stack w={"100%"} h={"100%"} spacing={0}>
                <Heading
                  size={"sm"}
                  textAlign={"left"}
                  pl={4}
                  pt={2}
                  pb={2}
                  bg={MODAL_BACKGROUND}
                  borderTopRadius={Border_Radius}
                  fontWeight="600"
                >
                  {dayjs(answer?.date?.toString())
                    .format(HVLocalizeStrings.DATE_FORMAT)
                    .toString()}
                </Heading>
                <Divider />
                <Box
                  backgroundColor={"white"}
                  h={"inherit"}
                  borderBottomRadius={Border_Radius}
                >
                  <Text
                    color={"black"}
                    h={"100%"}
                    textAlign={"left"}
                    px={4}
                    fontSize={"13px"}
                    mt={2}
                  >
                    {truncate(
                      `${answer.tellItBack?.toString()} ${getMessagesFromAiResponse(
                        answer?.responseText,
                        "aiResponse"
                      )}`,
                      90
                    )}
                  </Text>
                </Box>
              </Stack>
            </Button>,
            `${smallHistoryWidth + 10}px`,
            idx
          );
        })}
      </ScrollPanel>
    </Box>
  );
};
