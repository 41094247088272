import { useContext, useState } from "react";
import { Center, Flex, Spinner, useDisclosure } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { IGlobalProvider, GlobalContext } from "../../../State/GlobalContext";
import {
  BG_TEAM_BASE,
  HEADER_TEAM_MANAGEMENT_BACKGROUND,
} from "../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { BlockSection } from "../../Common/BlockSection";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { TeamCommandModals } from "./TeamCommandModals";
import ManagementAccordion from "./ManagementAccordion";
import { HvSpinner } from "../../Common/HvSpinner";
import TeamCommandCard from "../TeamCommandCard";
import { JoinTeamButton } from "../JoinTeamButton";
import { TeamDto } from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";

export default function ManagementPage() {
  const { teams, teamInitializing, teamDataLoading, getPageTitle } =
    useContext<IGlobalProvider>(GlobalContext);
  const { onClose, onOpen, isOpen } = useDisclosure();
  const [commandMode, setCommandMode] = useState<
    "join" | "select" | "add" | "edit" | null
  >(null);

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.TEAM_MANAGEMENT_TITLE} - ${HVLocalizeStrings.TEAM_HOME_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.TEAM_MANAGEMENT_TITLE}
        description={HVLocalizeStrings.TEAM_MANAGEMENT_DESCRIPTION}
        backgroundImage={HEADER_TEAM_MANAGEMENT_BACKGROUND}
        backgroundImageBase={BG_TEAM_BASE}
      />
      {teamInitializing ? (
        <Flex justify={"center"}>
          <Spinner />
        </Flex>
      ) : (
        <>
          {teamDataLoading && (
            <Center>
              <HvSpinner pos={"fixed"} />
            </Center>
          )}
          <FadeAfterDelay noEase={true}>
            <BlockSection fadeIn={true} title={""} columns={1}>
              <Flex flexDir={"column"}>
                {teams && teams.length === 0 && <TeamCommandCard />}
                {teams?.map((team: TeamDto) => {
                  if (!team) return;
                  const id = team?.id;
                  return (
                    <ManagementAccordion
                      key={id}
                      team={team}
                      teamId={id}
                      expandable
                    ></ManagementAccordion>
                  );
                })}
                {teams && teams.length > 0 && (
                  <Flex
                    flexDir={"column"}
                    align={"center"}
                    justify={"center"}
                    gap={4}
                    mt={"30px"}
                  >
                    <AnimatedButton
                      colorSet={AnimatedButtonColorSet.Secondary}
                      w={210}
                      text={HVLocalizeStrings.TEAM_CREATE_A_TEAM}
                      onClick={() => {
                        setCommandMode("add");
                        onOpen();
                      }}
                      dataTestId={HVTestId.ManagementPage.createTeam}
                    />
                    <JoinTeamButton
                      w={210}
                      setCommandMode={setCommandMode}
                      onOpenCommand={onOpen}
                    />
                  </Flex>
                )}
              </Flex>
            </BlockSection>
          </FadeAfterDelay>
        </>
      )}
      {isOpen && (
        <TeamCommandModals open={isOpen} close={onClose} type={commandMode} />
      )}
    </>
  );
}
