import { useContext, useEffect, useState } from "react";
import { Box, Flex, Text, useToken } from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { HVLocalizeStrings } from "../../../../../Localization/HVLocalizeStrings";
import {
  HeadZoneStatContext,
  IHeadZoneStatProvider,
} from "../../../../../State/Solo/HeadZoneStatContext";
import {
  Light_Blue,
  Mid_Blue,
  Disabled_Color,
  PRIMARY_TEXT_COLOR,
} from "../../../../../Styles/HeadversityStyle";
import { FadeAfterDelay } from "../../../../Common/FadeAfterDelay";

interface HeadzoneBarChartProps {
  height?: any;
  type: string;
}

export default function HeadzoneBarChart({
  height,
  type,
}: HeadzoneBarChartProps) {
  const { headzoneMeterEvents } =
    useContext<IHeadZoneStatProvider>(HeadZoneStatContext);

  const [options, setOptions] = useState<any>();

  const axisFontColor = useToken("colors", ["CHART_AXIS_COLOR"]);

  useEffect(() => {
    if (!headzoneMeterEvents || !headzoneMeterEvents[type]) return;

    const options = {
      chart: {
        type: "column",
        height: height ?? "200px",
        animation: {
          duration: 200,
        },
        backgroundColor: "transparent",
      },
      title: {
        align: "left",
        text: null,
      },
      credits: {
        enabled: false,
      },
      subtitle: {
        align: "left",
        text: null,
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: "category",
        labels: {
          style: {
            color: axisFontColor,
          },
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          enabled: false,
        },
      },
      legend: {
        enabled: false,
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: `{point.y:1f}`,
          },
        },
      },

      tooltip: {
        headerFormat: null,
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>:<strong>{point.y:1f}</strong>',
      },

      series: [
        {
          name: "Headzone",
          colorByPoint: true,
          data: [
            {
              name: type
                ? HVLocalizeStrings.HEADZONE_VERY_HIGH
                : HVLocalizeStrings.HEADZONE_TOOLS_STATES_THRIVING,
              y: headzoneMeterEvents[type].filter((x) => x.value >= 80).length,
              className: "thriving_donut_series",
              color: Light_Blue,
            },
            {
              name: type
                ? HVLocalizeStrings.HEADZONE_HIGH
                : HVLocalizeStrings.HEADZONE_TOOLS_STATES_GREAT,
              y: headzoneMeterEvents[type].filter(
                (x) => x.value >= 60 && x.value < 80
              ).length,
              className: "great_donut_series",
              color: Mid_Blue,
            },
            {
              name: type
                ? HVLocalizeStrings.HEADZONE_MEDIUM
                : HVLocalizeStrings.HEADZONE_TOOLS_STATES_OKAY,
              y: headzoneMeterEvents[type].filter(
                (x) => x.value >= 40 && x.value < 60
              ).length,
              className: "ok_donut_series",
              color: Disabled_Color,
            },
            {
              name: type
                ? HVLocalizeStrings.HEADZONE_LOW
                : HVLocalizeStrings.HEADZONE_TOOLS_STATES_STRUGGLING,
              y: headzoneMeterEvents[type].filter(
                (x) => x.value >= 20 && x.value < 40
              ).length,
              className: "struggling_donut_series",
              color: "#ec9c9c",
            },
            {
              name: type
                ? HVLocalizeStrings.HEADZONE_VERY_LOW
                : HVLocalizeStrings.HEADZONE_TOOLS_STATES_UNWELL,
              y: headzoneMeterEvents[type].filter((x) => x.value < 20).length,
              className: "unwell_donut_series",
              color: "#f25a75",
            },
          ],
        },
      ],
    };

    setOptions(options);
  }, [headzoneMeterEvents]);

  return (
    <FadeAfterDelay>
      {headzoneMeterEvents[type] && options ? (
        <Box w={"100%"} h={"200px"}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
      ) : (
        <Flex
          bgImage={
            "url(https://cdn.headversity.com/app/resources/other/barChartPlaceHolder.png)"
          }
          bgRepeat={"no-repeat"}
          bgSize={"cover"}
          w={"100%"}
          h={"200px"}
          justifyContent={"center"}
        >
          <Text
            alignSelf={"center"}
            textAlign={"center"}
            fontSize={"20px"}
            fontWeight={"semibold"}
            color={PRIMARY_TEXT_COLOR}
            textShadow={"0px 0px 20px white"}
          >
            {HVLocalizeStrings.HEADZONE_LOCKED_TEXT}
          </Text>
        </Flex>
      )}
    </FadeAfterDelay>
  );
}
