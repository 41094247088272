import { Box, Heading } from "@chakra-ui/react";
import { NodeDiffDto } from "@headversity/contract";
import ReactDiffViewer from "react-diff-viewer-continued";

interface HierarchyDiffViewerProps {
  current: NodeDiffDto[];
  snapshot: NodeDiffDto[];
}

export const HierarchyDiffViewer = (props: HierarchyDiffViewerProps) => {
  const oldValue = JSON.stringify(props.snapshot, null, 2);
  const newValue = JSON.stringify(props.current, null, 2);
  return (
    <Box>
      <Heading fontSize="md" fontWeight="semibold">
        Hierarchy Diff Viewer
      </Heading>
      <ReactDiffViewer
        oldValue={oldValue}
        newValue={newValue}
        splitView={true}
      />
    </Box>
  );
};
