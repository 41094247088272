import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { BlockSection } from "../../Common/BlockSection";
import { useBreakpointValue } from "@chakra-ui/react";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { LessonsScrollPanel } from "../Shared/LessonsScrollPanel";
import { useNavigate } from "react-router-dom";
import { ScrollPanelViewMoreLinkButton } from "../../Common/ScrollPanelViewMoreLinkButton";
import { MicroLessonDto } from "@headversity/contract";
import { getSuggestedLessonsForUser } from "../../../Utils/SkillsUtil";
import { VscBook } from "react-icons/vsc";

interface SuggestedLessonsForYouPanelProps {
  noSeeAll?: boolean;
  fullDescription?: boolean;
}
export const SuggestedLessonsForYouPanel = ({
  noSeeAll,
  fullDescription,
}: SuggestedLessonsForYouPanelProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { getSuggestedLessons, lessons, skills, userSkillStats, selectedGoal } =
    useContext<IGlobalProvider>(GlobalContext);
  const [suggestedLessons, setSuggestedLessons] = useState<MicroLessonDto[]>(
    []
  );

  useEffect(() => {
    if (
      skills &&
      userSkillStats.length > 0 &&
      lessons &&
      suggestedLessons.length === 0
    ) {
      setSuggestedLessons(
        getSuggestedLessonsForUser(
          getSuggestedLessons,
          skills,
          userSkillStats,
          selectedGoal,
          true
        ).slice(0, 5)
      );
    } else {
      // pull latest lesson data, using existing order
      const newLessons: MicroLessonDto[] = [];

      for (const l of suggestedLessons) {
        const lesson = lessons.find((x) => x.id === l.id);

        if (lesson) {
          newLessons.push(lesson);
        }
      }

      setSuggestedLessons(newLessons);
    }
  }, [lessons, userSkillStats, skills]);

  const navigate = useNavigate();

  return (
    <>
      <BlockSection
        fadeIn={suggestedLessons.length > 0}
        title={
          <BlockSectionTitle
            title={
              HVLocalizeStrings.SKILL_CONTENT_PAGE_SUGGESTED_LESSONS_FOR_YOU
            }
            description={
              HVLocalizeStrings.SOLO_HOME_SUGGESTED_FOR_YOU_LESSON_DESCRIPTION
            }
          />
        }
        columns={1}
        gridClassName={"suggested-lessons"}
      >
        <LessonsScrollPanel
          isDesktop={isDesktop}
          lessonsToShow={suggestedLessons}
          isWide={true}
          from={"Suggested Lessons For You"}
          fullDescription={fullDescription}
        />
        {!noSeeAll && (
          <ScrollPanelViewMoreLinkButton
            onClick={() => navigate("/lessons")}
            isDesktop={isDesktop}
            iconType={VscBook}
          >
            {HVLocalizeStrings.SEE_ALL_LESSONS}
          </ScrollPanelViewMoreLinkButton>
        )}
      </BlockSection>
    </>
  );
};
