import { Flex } from "@chakra-ui/react";
import {
  HeadzoneStepSetting,
  MessageDto,
  NanoPracticeDto,
  NanoPracticeType,
  QuestionAnswerDto,
  QuestionType,
} from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import { generateAiResponse } from "../../../Api/Solo/NanoPracticeApi";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { getKey } from "../../../Utils/Helpers";
import { HvSpinner } from "../../Common/HvSpinner";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { AiRole } from "@headversity/contract/Enums/AiRole";
import { getBrainZone } from "../Tools/HeadZone/HeadZoneToolUtil";
import {
  NanoPracticeSummaryContent,
  useSummaryParser,
} from "./NanoPracticeSummaryContent";
import { getDaysSince } from "../../../Utils/TimeUtils";
import dayjs from "dayjs";

interface AiFollowUpPanelProps {
  aiPrompt: string;
  inputQuestionAnswers: QuestionAnswerDto[];
  setAiResponse: (aiResponse: string) => void;
  aiResponse: string;
  setIsNextButtonDisabled?: (disabled: boolean) => void;
  practice: NanoPracticeDto;
  setIgnoreAIResponse?: (ignore: boolean) => void;
}

const AIFollowUpPanel = (props: AiFollowUpPanelProps) => {
  const {
    aiPrompt,
    inputQuestionAnswers,
    setAiResponse,
    aiResponse,
    setIsNextButtonDisabled,
    practice,
    setIgnoreAIResponse,
  } = props;

  const { selectedUserLanguage, nanoPracticeInstances } =
    useContext<IGlobalProvider>(GlobalContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [haveHistory, setHaveHistory] = useState<boolean>(false);

  const { summaryContents } = useSummaryParser({
    aiResponse: aiResponse,
    aiResponseTitle:
      practice.nanoPracticeType !== NanoPracticeType.Headzone
        ? HVLocalizeStrings.HERES_A_QUICK_RECAP
        : "",
    aiHistoryTitle:
      practice.nanoPracticeType === NanoPracticeType.Headzone
        ? HVLocalizeStrings.PAST_SUMMARY
        : "",
    aiNextStepTitle: HVLocalizeStrings.NEXT_STEP,
    haveHistory: haveHistory,
  });

  useEffect(() => {
    if (aiResponse) {
      setIsLoading(false);
      return;
    }
    const practiceAiMessages: MessageDto[] = [];
    const userMessage: MessageDto = { role: AiRole.User, content: "" };

    userMessage.content += buildUserMessage(practice, inputQuestionAnswers);

    if (practice.nanoPracticeType === NanoPracticeType.Headzone) {
      buildHistoryMessage(userMessage);
    }

    practiceAiMessages.push({
      role: AiRole.System,
      content: aiPrompt,
    });

    practiceAiMessages.push(userMessage);

    setIsLoading(true);
    generateAiResponse(
      getKey(),
      {
        messages: practiceAiMessages,
        lang: selectedUserLanguage,
      },
      15000
    )
      .then((result) => {
        const aiMessagesFromServer: any[] = result?.data;
        if (aiMessagesFromServer && aiMessagesFromServer.length > 0) {
          const assistantMessages = aiMessagesFromServer.filter(
            (am) => am.role === AiRole.Assistant
          );
          if (assistantMessages.length > 0) {
            // Get the last AI message and extract it's response within the aiResponse tags
            let content =
              assistantMessages[assistantMessages.length - 1].content;

            if (setAiResponse) {
              setAiResponse(content ?? "");
            }

            setIsLoading(false);
          }
        }
      })
      .catch(() => {
        if (setIgnoreAIResponse) setIgnoreAIResponse(true);
        if (setIsNextButtonDisabled) setIsNextButtonDisabled(false);
      });
  }, []);

  useEffect(() => {
    if (setIsNextButtonDisabled) {
      setIsNextButtonDisabled(!aiResponse);
    }
  }, [aiResponse]);

  const buildHistoryMessage = (userMessage: MessageDto) => {
    if (
      !practice?.id ||
      practice.nanoPracticeType !== NanoPracticeType.Headzone
    )
      return;

    const practiceInstances = nanoPracticeInstances[practice.id.toString()];
    if (!practiceInstances) return;

    // skip headzone nano practice that do not have the biggest impact question
    //  this includes old instances and the first headzone instance (in the welcome flow)
    const instancesToUse = practiceInstances.filter(
      (x) => x.questionUserResponses?.length > 0
    );

    if (instancesToUse.length >= 2) {
      let content = "";
      for (let i = 0; i < instancesToUse.length && i < 5; i++) {
        const dayJsValue = dayjs.utc(instancesToUse[i].createdAt as any);
        const days = getDaysSince(dayJsValue);
        content += buildUserMessage(
          practice,
          instancesToUse[i].questionUserResponses.map((ur) => {
            return {
              question: ur.question.questionText_loc,
              questionType: ur.question.questionType,
              responseText: ur.responseText,
            } as QuestionAnswerDto;
          }),
          days
        );
      }
      if (content !== "") {
        userMessage.content += "<userResponseHistory>";
        userMessage.content += content;
        userMessage.content += "</userResponseHistory>";
        setHaveHistory(true);
      } else {
        setHaveHistory(false);
      }
    }
  };

  const buildUserMessage = (
    practice: NanoPracticeDto,
    inputQuestionAnswers: QuestionAnswerDto[],
    daysAgo?: number
  ) => {
    let content = "<userResponse>";
    for (const obj of inputQuestionAnswers) {
      if (daysAgo) {
        content += `<daysAgo>${daysAgo}</daysAgo>`;
      }
      if (obj.questionType === QuestionType.Headzone) {
        const val = parseInt(obj.responseText as string);
        const idx = getBrainZone(val);

        // get zone from the practice
        const hz = practice.steps[0] as HeadzoneStepSetting;
        const zone = hz.headzone.ranges[idx];

        content += `<cannedText>${zone.description.toString()}</cannedText>`;
        content += `<zoneIndex>${idx}</zoneIndex>`;
      } else {
        content += `<question>${
          (obj.question as any)[selectedUserLanguage]
        }</question>`;
        content += `<answer>${obj.responseText}</answer>`;
      }
    }
    content += "</userResponse>";
    return content;
  };

  return isLoading ? (
    <Flex
      w={"100%"}
      h={"100%"}
      minH={"350px"}
      align={"center"}
      justify={"center"}
    >
      <HvSpinner />
    </Flex>
  ) : (
    <NanoPracticeSummaryContent
      summaryContents={summaryContents}
    ></NanoPracticeSummaryContent>
  );
};

export default AIFollowUpPanel;
