import {
  Center,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import SlidingCard from "../../Common/Cards/SlidingCard/SlidingCard";
import { BsFillArrowUpRightSquareFill } from "react-icons/bs";
import { ToolSkillInfo } from "../../../Utils/SkillsUtil";
import {
  BOOST_TEXT_COLOR,
  CONTRAST_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../Testing/dataTestIds";

interface ToolPanelProps {
  backgroundImage: string;
  toolSkillInfo: ToolSkillInfo;
  clickHandler: () => void;
  tool?: any;
  gradientIcon?: string;
  minWidth?: string;
}

export const ToolPanel = (props: ToolPanelProps) => {
  const { toolSkillInfo, clickHandler, backgroundImage, minWidth } = props;
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: false,
  });
  return (
    <>
      <SlidingCard
        title={toolSkillInfo?.name}
        subTitle={toolSkillInfo?.description}
        bgImage={backgroundImage}
        titleColor={CONTRAST_TEXT_COLOR}
        subTitleColor={CONTRAST_TEXT_COLOR}
        ellipsisColor={CONTRAST_TEXT_COLOR}
        childHeight={!toolSkillInfo.skill1 ? "170px" : undefined}
        minWidth={minWidth}
      >
        <Flex flexDir={"column"} p={"20px"} gap={!isMobile ? 3 : 0}>
          {!isMobile && (
            // don't show title and subtitle in SlidingCardHeader if on desktop
            <Flex
              className="tool_panel_typography_container"
              flexDir={"column"}
              width="100%"
              lineHeight={"24px"}
              minHeight={"72px"}
            >
              <Heading
                fontSize={"24px"}
                fontWeight="600"
                className="tool_panel_title"
                color={PRIMARY_TEXT_COLOR}
                as="div"
              >
                {toolSkillInfo?.name}
              </Heading>
              <Text
                fontSize={"16px"}
                fontWeight="400"
                className="tool_panel_sub_title"
                color={SECONDARY_TEXT_COLOR}
              >
                {toolSkillInfo?.description}
              </Text>
            </Flex>
          )}
          {toolSkillInfo.skill1 && (
            <List color={BOOST_TEXT_COLOR} mb={2} mt={isMobile ? 3 : 0}>
              <ListItem color={BOOST_TEXT_COLOR}>
                <Flex alignItems={"center"} gap={"6px"}>
                  {<BsFillArrowUpRightSquareFill />}
                  {toolSkillInfo.skill1}
                </Flex>
              </ListItem>
              <ListItem color={BOOST_TEXT_COLOR}>
                <Flex alignItems={"center"} gap={"6px"}>
                  {<BsFillArrowUpRightSquareFill />}
                  {toolSkillInfo.skill2}
                </Flex>
              </ListItem>
              <ListItem color={BOOST_TEXT_COLOR}>
                <Flex alignItems={"center"} gap={"6px"}>
                  {<BsFillArrowUpRightSquareFill />}
                  {toolSkillInfo.skill3}
                </Flex>
              </ListItem>
            </List>
          )}

          <Center mt={isMobile ? 8 : 0}>
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Primary}
              w={999} // forcing max-width
              text={toolSkillInfo.buttonText}
              onClick={clickHandler as any}
              dataTestId={`${
                HVTestId.ToolPanel.toolPanelButton
              }${toolSkillInfo.name.toLocaleLowerCase()}`}
            />
          </Center>
        </Flex>
      </SlidingCard>
    </>
  );
};
