import { FC, useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  DESTRUCTIVE_SECONDARY_TEXT_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { HvTextInput } from "../../Common/HvTextInput";
import { HvTextarea } from "../../Common/HvTextarea";
import ImageUploader from "../SelfService/ImageUploader";
import { ReachHelpResourcesContext } from "../../../State/Reach/ReachHelpResourcesContext";
import { UploadType } from "@headversity/contract";
import { FaRegImage } from "react-icons/fa";

export type ModalTabProps = {
  name: string;
  description: string;
  logoUrl: string;
  phoneNumber: string;
  textNumber: string;
  iosAppLink: string;
  androidAppLink: string;
  websiteLink: string;
  isValidAndroidAppLink?: boolean;
  isValidIosAppLink?: boolean;
  isValidWebsiteAppLink?: boolean;
};
type ReachHelpResourcesModalTabProps = {
  tabProps: ModalTabProps;
  onInputChanged: (field: string, value: string | boolean) => void;
};

const ReachHelpResourcesModalTab: FC<ReachHelpResourcesModalTabProps> = (
  props: ReachHelpResourcesModalTabProps
) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true }) ?? false;
  const urlExpression =
    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
  const orgContext = useContext(ReachHelpResourcesContext);
  const [isValidIosAppLink, setIsValidIosAppLink] = useState<boolean>(true);
  const [isValidAndroidAppLink, setIsValidAndroidAppLink] =
    useState<boolean>(true);
  const [isValidWebsiteAppLink, setIsValidWebsiteAppLink] =
    useState<boolean>(true);

  useEffect(() => {
    if (props.tabProps.isValidAndroidAppLink !== isValidAndroidAppLink) {
      setDataForField("isValidAndroidAppLink", isValidAndroidAppLink);
    } else if (props.tabProps.isValidIosAppLink !== isValidIosAppLink) {
      setDataForField("isValidIosAppLink", isValidIosAppLink);
    } else if (props.tabProps.isValidWebsiteAppLink !== isValidWebsiteAppLink) {
      setDataForField("isValidWebsiteAppLink", isValidWebsiteAppLink);
    }
  }, [
    props.tabProps,
    isValidAndroidAppLink,
    isValidIosAppLink,
    isValidWebsiteAppLink,
  ]);

  const setDataForField = (fieldName: string, value: string | boolean) => {
    props.onInputChanged(fieldName, value);
  };

  const handleImageUrlChanged = (url: string) => {
    setDataForField("logoUrl", url);
  };

  const handleSetIsUploading = (isUploading: boolean) => {
    orgContext.setIsUploading(isUploading);
  };

  const elementWidth = isDesktop ? "377px" : "100%";

  const buildField = (
    fieldLabel: string,
    fieldName: string,
    fieldValue: string,
    isDesktop: boolean,
    inputTextType: "text" | "longtext" | "textarea" = "text",
    isValid?: boolean,
    setIsValid?: (isValid: boolean) => void,
    validationExpression?: RegExp,
    validationErrorMessage?: string
  ) => {
    const isTextArea = inputTextType === "textarea";
    return (
      <Flex
        mt={isDesktop ? 0 : 2}
        flexDir={"column"}
        gap={1}
        minWidth={elementWidth}
        maxWidth={elementWidth}
      >
        <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
          {fieldLabel}
        </Text>
        {isTextArea && (
          <HvTextarea
            onChange={(event) => {
              setDataForField(fieldName, event.target.value);
            }}
            value={fieldValue}
          />
        )}
        {!isTextArea && (
          <HvTextInput
            value={fieldValue.replace(/[\n\t]+/g, " ")}
            onChange={(event) => {
              setDataForField(fieldName, event.target.value);
              if (validationExpression && setIsValid) {
                const isValid =
                  event.target.value === "" ||
                  new RegExp(validationExpression).test(event.target.value);
                setIsValid(isValid);
              }
            }}
            maxLength={inputTextType === "longtext" ? 2000 : undefined}
          />
        )}
        {isValid === false && validationErrorMessage && (
          <Text fontSize={"xs"} color={DESTRUCTIVE_SECONDARY_TEXT_COLOR}>
            {validationErrorMessage}
          </Text>
        )}
      </Flex>
    );
  };

  const buildUploader = (isDesktop: boolean) => {
    const imageHeight = "78px";
    return (
      <Flex
        flexDir={"column"}
        gap={1}
        textAlign="left"
        width={elementWidth}
        mt={isDesktop ? 0 : 2}
      >
        {props.tabProps.logoUrl ? (
          <Flex gap={2} alignItems="end">
            <Box>
              <Image
                h={imageHeight}
                objectFit="contain"
                objectPosition="left top"
                src={props.tabProps.logoUrl}
                alt=""
              />
            </Box>
            <Link
              onClick={() => handleImageUrlChanged("")}
              textDecoration={"underline"}
              color={MODAL_PRIMARY_TEXT_COLOR}
            >
              <Text fontSize="sm">{HVLocalizeStrings.REMOVE}</Text>
            </Link>
          </Flex>
        ) : (
          <Box
            backgroundColor="white"
            h={imageHeight}
            w={imageHeight}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon as={FaRegImage} boxSize={10} />
          </Box>
        )}
        <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
          {HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_LOGO}
        </Text>
        <ImageUploader
          uploadType={UploadType.HELP_RESOURCE_LOGO}
          handleBucketUrlChanged={handleImageUrlChanged}
          handleIsUploading={handleSetIsUploading}
        />
      </Flex>
    );
  };
  return (
    <Flex justifyContent="center" alignItems="center">
      <Box borderRadius="10px" border={`4px solid ${MODAL_PRIMARY_TEXT_COLOR}`}>
        {!isDesktop && (
          <Flex flexDirection="column">
            {buildField(
              HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_NAME,
              "name",
              props.tabProps.name,
              isDesktop
            )}
            {buildField(
              HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_DESCRIPTION,
              "description",
              props.tabProps.description,
              isDesktop,
              "longtext"
            )}
            {buildUploader(isDesktop)}
            {buildField(
              HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_PHONE_NUMBER,
              "phoneNumber",
              props.tabProps.phoneNumber,
              isDesktop
            )}
            {buildField(
              HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_TEXT_NUMBER,
              "textNumber",
              props.tabProps.textNumber,
              isDesktop
            )}
            {buildField(
              HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_IOS_APP_LINK,
              "iosAppLink",
              props.tabProps.iosAppLink,
              isDesktop,
              "text",
              isValidIosAppLink,
              setIsValidIosAppLink,
              urlExpression,
              HVLocalizeStrings.REACH_USER_INVALID_URL
            )}
            {buildField(
              HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_ANDROID_APP_LINK,
              "androidAppLink",
              props.tabProps.androidAppLink,
              isDesktop,
              "text",
              isValidAndroidAppLink,
              setIsValidAndroidAppLink,
              urlExpression,
              HVLocalizeStrings.REACH_USER_INVALID_URL
            )}
            {buildField(
              HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_WEBSITE_LINK,
              "websiteLink",
              props.tabProps.websiteLink,
              isDesktop,
              "text",
              isValidWebsiteAppLink,
              setIsValidWebsiteAppLink,
              urlExpression,
              HVLocalizeStrings.REACH_USER_INVALID_URL
            )}
          </Flex>
        )}
        {isDesktop && (
          <Flex flexDirection="column">
            <Flex
              flexDirection="column"
              flexWrap="wrap"
              alignContent="space-between"
              maxH={"195px"}
            >
              {buildField(
                HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_NAME,
                "name",
                props.tabProps.name,
                isDesktop
              )}
              {buildField(
                HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_DESCRIPTION,
                "description",
                props.tabProps.description,
                isDesktop,
                "longtext"
              )}

              <Flex
                flexDirection="column"
                maxH={isDesktop ? "200px" : undefined}
                width={elementWidth}
              >
                {buildUploader(isDesktop)}
              </Flex>
            </Flex>
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              alignItems="space-between"
              mt={8}
            >
              {buildField(
                HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_PHONE_NUMBER,
                "phoneNumber",
                props.tabProps.phoneNumber,
                isDesktop
              )}
              <Spacer></Spacer>
              {buildField(
                HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_IOS_APP_LINK,
                "iosAppLink",
                props.tabProps.iosAppLink,
                isDesktop,
                "text",
                isValidIosAppLink,
                setIsValidIosAppLink,
                urlExpression,
                HVLocalizeStrings.REACH_USER_INVALID_URL
              )}
              {buildField(
                HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_TEXT_NUMBER,
                "textNumber",
                props.tabProps.textNumber,
                isDesktop
              )}
              <Spacer></Spacer>
              {buildField(
                HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_ANDROID_APP_LINK,
                "androidAppLink",
                props.tabProps.androidAppLink,
                isDesktop,
                "text",
                isValidAndroidAppLink,
                setIsValidAndroidAppLink,
                urlExpression,
                HVLocalizeStrings.REACH_USER_INVALID_URL
              )}
            </Flex>
            <Flex justifyContent={"end"}>
              {buildField(
                HVLocalizeStrings.REACH_HELP_RESOURCE_MODAL_WEBSITE_LINK,
                "websiteLink",
                props.tabProps.websiteLink,
                isDesktop,
                "text",
                isValidWebsiteAppLink,
                setIsValidWebsiteAppLink,
                urlExpression,
                HVLocalizeStrings.REACH_USER_INVALID_URL
              )}
            </Flex>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default ReachHelpResourcesModalTab;
