import { Flex, Spacer, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

interface ReachHelpResourcesListToolbarProps {
  addResourceButton: JSX.Element;
}

const ReachHelpResourcesListToolbar = ({
  addResourceButton,
}: ReachHelpResourcesListToolbarProps) => {
  return (
    <Flex alignItems="center" justifyContent={"space-between"} py={2}>
      <Text fontWeight="semibold" py={2}>
        {HVLocalizeStrings.REACH_HELP_RESOURCES_TITLE}
      </Text>
      <Spacer />
      {addResourceButton}
    </Flex>
  );
};

export default ReachHelpResourcesListToolbar;
