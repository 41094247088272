import { createContext, useCallback, useEffect, useState } from "react";
import { getKey } from "../../Utils/Helpers";
import {
  getOptimismPlan,
  saveOptimismChallenge,
  saveOptimismPlan,
  stopCurrentOptimismChallenges,
} from "../../Api/Solo/OptimismApi";
import {
  OptimismPlanInputDto,
  OptimismPlanDto,
  OptimismPlanChallengeDto,
  OptimismToolStatus,
} from "@headversity/contract";
import { isCurrentOptimismPlan } from "../../Components/Solo/Tools/Optimism/Utils/OptimismUtils";
import dayjs from "dayjs";

export interface IOptimismToolProvider {
  getOptimismPlanFromServer: () => void;
  saveOptimismPlanToServer: (plan: OptimismPlanInputDto) => Promise<void>;
  saveOptimismChallengeToServer: (
    challenge: OptimismPlanChallengeDto
  ) => Promise<void>;
  stopCurrentOptimismChallengesToServer: (id: number) => Promise<void>;
  optimismPlanLoaded: boolean;
  optimismPlans: OptimismPlanDto[] | null;
  currentPlannedItemLoaded: boolean;
  currentPlannedItem: OptimismPlanDto | null;
  previousPlannedItems: OptimismPlanDto[];
}

export const OptimismToolContext = createContext<IOptimismToolProvider>(
  {} as any
);

export const OptimismToolProvider = (props: any) => {
  const [currentPlannedItem, setCurrentPlannedItem] =
    useState<OptimismPlanDto | null>(null);
  const [previousPlannedItems, setPreviousPlannedItems] = useState<
    OptimismPlanDto[]
  >([]);
  const [currentPlannedItemLoaded, setCurrentPlannedItemLoaded] =
    useState(false);

  const [optimismPlans, setOptimismPlans] = useState<OptimismPlanDto[] | null>(
    null
  );
  const [optimismPlanLoaded, setOptimismPlanLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (optimismPlans === null) return;

    let prevItems: OptimismPlanDto[] = [];
    let currentItem = null;
    optimismPlans?.forEach((item) => {
      if (
        isCurrentOptimismPlan(item) &&
        item?.status === OptimismToolStatus.Pending
      ) {
        currentItem = item;
      } else {
        prevItems.push(item);
      }
    });

    if (currentItem !== null) {
      const item = currentItem as OptimismPlanDto;
      const todayUnix = dayjs().startOf("day").unix();
      const endDateUnix = dayjs
        .utc(item.startTime)
        .local()
        .add(item.numberOfDays - 1, "day")
        .endOf("day")
        .unix();
      if (todayUnix > endDateUnix) {
        stopCurrentOptimismChallengesToServer(item.id);
        currentItem = null;
        return;
      }
    }

    setCurrentPlannedItem(currentItem);
    setPreviousPlannedItems(prevItems);
    setCurrentPlannedItemLoaded(true);
  }, [optimismPlans]);

  const getOptimismPlanFromServer = useCallback(async () => {
    return getOptimismPlan(getKey()).then((response) => {
      setOptimismPlans(response.data);
      setOptimismPlanLoaded(true);
    });
  }, []);

  const saveOptimismPlanToServer = useCallback(
    async (plan: OptimismPlanInputDto) => {
      return saveOptimismPlan(plan, getKey()).then((response) => {
        getOptimismPlanFromServer();
      });
    },
    []
  );

  const saveOptimismChallengeToServer = useCallback(
    async (challenge: OptimismPlanChallengeDto) => {
      return saveOptimismChallenge(challenge, getKey()).then((response) => {
        getOptimismPlanFromServer();
      });
    },
    []
  );

  const stopCurrentOptimismChallengesToServer = useCallback(
    async (id: number) => {
      return stopCurrentOptimismChallenges(getKey(), id).then((response) => {
        getOptimismPlanFromServer();
      });
    },
    []
  );

  return (
    <OptimismToolContext.Provider
      value={{
        getOptimismPlanFromServer,
        saveOptimismPlanToServer,
        saveOptimismChallengeToServer,
        optimismPlanLoaded,
        optimismPlans,
        currentPlannedItem,
        previousPlannedItems,
        currentPlannedItemLoaded,
        stopCurrentOptimismChallengesToServer,
      }}
    >
      {props.children}
    </OptimismToolContext.Provider>
  );
};
