import { BADGE_MODAL_BACKGROUND } from "../Styles/HeadversityStyle";
import { EarnPointsToastContent } from "../Components/Common/EarnPointsToastContent";
import {
  CertQualificationData,
  QualificationProgressHeader,
} from "../Components/Cert/Shared/QualificationProgressCard";
import { BadgeCardHeader } from "../Components/Common/BadgesAndShields/BadgeCardHeader";
import React from "react";
import { CertDto } from "../@headversity/contract";
import { BadgeData } from "../Components/Common/BadgesAndShields/BadgeCard";
import { HVLocalizeStrings } from "../Localization/HVLocalizeStrings";
import { ScrollPanel } from "../Components/Common/ScrollPanel";
import { ScrollPanelBlock } from "../Components/Common/ScrollPanelBlock";
import { Box } from "@chakra-ui/react";

export const showEarnPointsToast = (
  pointsData: CertQualificationData[] | BadgeData[],
  showToast: (
    isSuccess: boolean,
    id: string,
    message: any,
    position: string,
    color?: string,
    border?: string,
    bg?: string,
    noPadding?: boolean,
    extraDelayMs?: number
  ) => void,
  certifications?: CertDto[]
) => {
  const isDesktop = window.innerWidth >= 568;
  const width = isDesktop ? "400px" : window.innerWidth - 50 + "px";

  showToast(
    true,
    "earnPointsToast",
    <Box w={width}>
      <ScrollPanel
        spaceBetween={8}
        showChevronContainer={true}
        slidesPerGroup={1}
        pagination={pointsData.length === 1 ? "remove" : undefined}
      >
        {pointsData.map((item) => {
          return ScrollPanelBlock(
            <Box
              px={isDesktop && pointsData.length > 1 ? "40px" : "20px"}
              pb={isDesktop && pointsData.length === 1 ? "10px" : undefined}
            >
              <EarnPointsToastContent
                header={
                  certifications ? (
                    <QualificationProgressHeader
                      title={
                        certifications.find(
                          (cert) =>
                            cert.id === (item as CertQualificationData).certId
                        )?.name as string
                      }
                      certQualificationData={item as CertQualificationData}
                      showPointsAsSubtitle={true}
                      showTitle={true}
                    />
                  ) : (
                    <BadgeCardHeader
                      badge={item as BadgeData}
                      onClick={() => {}}
                    />
                  )
                }
                previousPoints={
                  certifications
                    ? (item as CertQualificationData).previousPointsEarned
                    : (item as BadgeData).previousPointsOnLevel
                }
                newPoints={
                  certifications
                    ? (item as CertQualificationData).pointsEarned
                    : (item as BadgeData).newPointsOnLevel
                }
                headline={
                  certifications
                    ? undefined
                    : HVLocalizeStrings.EARN_MODAL_INITIAL_MESSAGE_HEADLINE
                }
                subHeadline={
                  certifications
                    ? HVLocalizeStrings.CERT_QUALIFICATION_WAY_TO_GO
                    : HVLocalizeStrings.EARN_TOAST_EARNED
                }
                bottomText={
                  certifications
                    ? HVLocalizeStrings.CERT_QUALIFICATION_KEEP_GOING
                    : HVLocalizeStrings.EARN_TOAST_KEEP_GOING
                }
              />
            </Box>,
            width,
            certifications
              ? (item as CertQualificationData).certId
              : (item as BadgeData).skillId
          );
        })}
      </ScrollPanel>
    </Box>,
    "bottom",
    "",
    certifications
      ? `solid #ddd 2px`
      : `solid ${(pointsData[0] as BadgeData).shieldColor} 3px`,
    BADGE_MODAL_BACKGROUND,
    true,
    pointsData.length > 1 ? 5000 : 0
  );
};
