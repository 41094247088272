import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { TreeViewOperationDescriptor } from "@progress/kendo-react-treeview";
import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  HierarchyContext,
  HierarchyContextProvider,
  TreeViewDataItem,
} from "../../../State/Reach/ReachHierarchyContext";
import { DISABLED_BORDER } from "../../../Styles/HeadversityStyle";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import {
  mapDataToKendoTreeView,
  preorderSearchArray,
} from "./Hierarchy/HierarchyHelpers";
import { KendoTree, TreeType } from "./KendoTree";
import { getHierarchy } from "../../../Api/Reach/ReachApi";
import { getKey } from "../../../Utils/Helpers";
import { NodeType } from "../../Shared/Hierarchy/NodeType";
import { NodeDto } from "@headversity/contract";
import { HierarchyNodeRenameModal } from "./Hierarchy/HierarchyNodeRenameModal";
import { HierarchyToolbar } from "./Hierarchy/HierarchyToolbar";
import { HierarchySnapshots } from "./Hierarchy/HierarchySnapshots";

export const HierarchyPage = () => {
  const { company, userHierarchyAssociations } =
    useContext<IGlobalProvider>(GlobalContext);
  const {
    selectedNodeType,
    moveUser,
    moveUsers,
    moveNode,
    onAddUser,
    onAddNode,
    onCreateHierarchy,
    onDeleteUser,
    onDeleteNode,
    notifyHierarchyUpdated,
    onDownloadCsv,
    onCompareSnapshot,
    compareDifference,
    revision,
    setRevision,
    onRestoreSnapshot,
    selectedNodes,
    setSelectedNodes,
  } = useContext(HierarchyContext);

  // for drag and drop
  const handleDragItemApiCall = (
    draggedItem: TreeViewDataItem,
    targetItem: TreeViewDataItem
  ): boolean => {
    if (targetItem.nodeType !== NodeType.Node) {
      return false;
    }

    const isDraggedItemUser = draggedItem.nodeType === NodeType.User;
    const isDraggedItemNode = draggedItem.nodeType === NodeType.Node;

    if (isDraggedItemUser) {
      handleUserDrag(draggedItem, targetItem);
      return true;
    } else if (isDraggedItemNode) {
      handleNodeDrag(draggedItem, targetItem);
      return true;
    }

    return false;
  };

  const handleUserDrag = (
    draggedItem: TreeViewDataItem,
    targetItem: TreeViewDataItem
  ) => {
    if (selectedNodes.length > 1) {
      // move multiple users
      moveUsers({
        targetNodeId: targetItem.id,
        selectedNodes: selectedNodes,
      });
    } else {
      // move single user
      moveUser({
        sourceNodeId: draggedItem.parentNodeId!,
        targetNodeId: targetItem.id,
        userId: draggedItem.resourceId,
      });
    }
  };

  const handleNodeDrag = (
    draggedItem: TreeViewDataItem,
    targetItem: TreeViewDataItem
  ) => {
    moveNode({
      nodeId: draggedItem.id,
      newParentNodeId: targetItem.id,
      revision: revision!,
    });
  };

  const [usersTree, setUsersTree] = useState<TreeViewDataItem[]>([]);

  const [hierarchyNodes, setHierarchyNodes] = useState<NodeDto[]>();

  const [rootNodeSelected, setRootNodeSelected] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<NodeDto>();
  const [hasHierarchy, setHasHierarchy] = useState<boolean>(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (selectedNodes && selectedNodes.length > 0 && hierarchyNodes) {
      setSelectedNode(
        hierarchyNodes.find((node) => node.id === selectedNodes[0].resourceId)
      );
      const rootNode = hierarchyNodes[0];
      selectedNodes.filter((node) => node.resourceId === rootNode.id).length > 0
        ? setRootNodeSelected(true)
        : setRootNodeSelected(false);
    } else {
      setSelectedNode(undefined);
      setRootNodeSelected(false);
    }
  }, [selectedNodes, hierarchyNodes]);

  const [expand, setExpand] = useState<TreeViewOperationDescriptor>({
    ids: [],
    idField: "text",
  });
  const [highlightedSelect, setHighlightedSelect] = useState<string[]>([]);

  useEffect(() => {
    fetchHierarchy();
  }, [notifyHierarchyUpdated, activeTabIndex]);

  const fetchHierarchy = useCallback(async () => {
    // get node id from associations
    const nodeId = userHierarchyAssociations?.hierarchyId;

    if (!nodeId) return;

    if (activeTabIndex === 0) {
      // first fetch users tree
      const withUsersResponse = await getHierarchy(getKey(), nodeId, "Users");
      if (withUsersResponse.data.result) {
        const usersTree = mapDataToKendoTreeView(
          withUsersResponse.data.result,
          true,
          true
        );
        setUsersTree(usersTree);
      }

      // fetch revision for users hierarchy
      if (withUsersResponse.data.revision) {
        setRevision(withUsersResponse.data.revision);
      }

      // fetch hierarchy tree
      const hierarchyResponse = await getHierarchy(
        getKey(),
        nodeId,
        "Hierarchy"
      );
      setHierarchyNodes(hierarchyResponse.data.result);
    }

    setSelectedNodes([]);
    setSelectedNode(undefined);
  }, [userHierarchyAssociations, activeTabIndex]);

  useEffect(() => {
    fetchHierarchy();
  }, [company]);

  const addResource = (addResourceText: string, createNodeType: NodeType) => {
    if (selectedNodeType !== NodeType.Node) {
      return;
    }
    if (createNodeType === NodeType.User) {
      onAddUser(Number(addResourceText));
    } else if (createNodeType === NodeType.Node) {
      onAddNode(addResourceText);
    }
  };

  const downloadCsv = () => {
    onDownloadCsv();
  };

  const onHandleSearch = (searchStr: string) => {
    if (usersTree) {
      const searchResults = preorderSearchArray(usersTree, searchStr);
      // search results are already indexes
      setExpand({ ids: searchResults });
      setHighlightedSelect(searchResults);
    }
  };

  useEffect(() => {
    setHasHierarchy(!!userHierarchyAssociations?.hierarchyId);
  }, [userHierarchyAssociations]);

  const onDelete = () => {
    if (selectedNodeType === NodeType.Node) {
      onDeleteNode();
    } else if (selectedNodeType === NodeType.User) {
      onDeleteUser();
    }
  };

  const handleCreateHierarchy = () => {
    onCreateHierarchy({ en: "New Hierarchy" });
  };

  const handleClearSearch = () => {
    setHighlightedSelect([]);
  };

  const [renameModalOpen, setRenameModalOpen] = useState<boolean>(false);
  const handleRenameNode = () => {
    setRenameModalOpen(true);
  };

  const handleTabChange = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <>
      <HierarchyNodeRenameModal
        selectedNode={selectedNode}
        open={renameModalOpen}
        setOpen={setRenameModalOpen}
      />

      <Flex flexDir="column" m={2} borderRadius={4}>
        <BlockSectionTitle
          title={"Edit Hierarchy (Preview)"}
          description={
            "Build company hierarchy by adding, editing, or deleting nodes and users."
          }
          descriptionMarginTop={3}
        />
        <Box>
          <HierarchyToolbar
            onSearch={onHandleSearch}
            onDelete={onDelete}
            selectedNodeType={selectedNodeType}
            onAddResource={addResource}
            onDownloadCsv={downloadCsv}
            onRename={handleRenameNode}
            onCreateHierarchy={handleCreateHierarchy}
            isRootNodeSelected={rootNodeSelected}
            hasHierarchy={hasHierarchy}
            onClearSearch={handleClearSearch}
            showClearSearch={highlightedSelect.length > 0}
          ></HierarchyToolbar>
          <Box m={2} borderRadius={4} bgColor="white" border={DISABLED_BORDER}>
            <Tabs index={activeTabIndex} onChange={handleTabChange}>
              <TabList>
                <Tab>Live Hierarchy</Tab>
                <Tab>Snapshots</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {usersTree && (
                    <Box
                      m={2}
                      border={DISABLED_BORDER}
                      borderRadius={4}
                      bgColor="white"
                    >
                      <KendoTree
                        mode={TreeType.Builder}
                        disableDrag={false}
                        initialTree={usersTree}
                        expandedFromSearch={expand}
                        selectedItems={highlightedSelect}
                        handleDragItemApiCall={handleDragItemApiCall}
                      ></KendoTree>
                    </Box>
                  )}
                </TabPanel>
                <TabPanel>
                  {activeTabIndex === 1 && hierarchyNodes && (
                    <HierarchySnapshots hierarchyNodes={hierarchyNodes} />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export const HierarchyPageContainer = () => {
  return (
    <HierarchyContextProvider>
      <HierarchyPage />
    </HierarchyContextProvider>
  );
};
