import { createContext, useCallback } from "react";
import { saveAudioTrackUsage } from "../../Api/Solo/MindfulnessApi";
import { getKey } from "../../Utils/Helpers";
import { useMindfulnessToolStat } from "../../Hooks/Solo/useMindfulnessToolStat";
import {
  AudioTrackVersionUsageDto,
  AudioTrackVersionUsageInputDto,
} from "@headversity/contract";

export interface IMindfulnessToolProvider {
  mindfulnessAudioUsage: AudioTrackVersionUsageDto[];
  getMindfulnessAudioUsageFromServer: () => void;
  saveMindfulnessAudioUsageToServer: (
    audioTrackVersionId: number,
    inputDto: AudioTrackVersionUsageInputDto
  ) => void;
}

export const MindfulnessToolContext = createContext<IMindfulnessToolProvider>(
  {} as any
);

export const MindfulnessToolProvider = (props: any) => {
  const { mindfulnessAudioUsage, getMindfulnessAudioUsageFromServer } =
    useMindfulnessToolStat();

  const saveMindfulnessAudioUsageToServer = useCallback(
    async (
      audioTrackVersionId: number,
      inputDto: AudioTrackVersionUsageInputDto
    ) => {
      return saveAudioTrackUsage(getKey(), audioTrackVersionId, inputDto).then(
        (response) => {
          getMindfulnessAudioUsageFromServer();
        }
      );
    },
    []
  );

  return (
    <MindfulnessToolContext.Provider
      value={{
        mindfulnessAudioUsage,
        getMindfulnessAudioUsageFromServer,
        saveMindfulnessAudioUsageToServer,
      }}
    >
      {props.children}
    </MindfulnessToolContext.Provider>
  );
};
