import {
  Box,
  Fade,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext } from "react";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import { BLOCK_SECTION_PRIMARY_TEXT_COLOR } from "../../Styles/HeadversityStyle";

interface IBlockSection {
  fadeIn: boolean;
  height?: string;
  title?: any;
  columns: any;
  rows?: any;
  children: any;
  maxContainerWidth?: string;
  noPadding?: boolean;
  noPaddingBottom?: boolean;
  shouldPulse?: boolean;
  includeSpacing?: boolean;
  gridClassName?: string;
  gap?: string;
  dataTestId?: string;
  mx?: string;
}

export const BlockSection = ({
  fadeIn,
  title,
  columns,
  rows,
  height,
  children,
  maxContainerWidth,
  noPadding,
  noPaddingBottom,
  shouldPulse,
  includeSpacing,
  gridClassName,
  gap,
  dataTestId,
  mx,
}: IBlockSection) => {
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Box
      maxW={
        maxContainerWidth ?? isMainContainerXL ? "container.xl" : "container.lg"
      }
      mx={mx ?? "auto"}
      p={noPadding ? "0" : "5"}
      pb={noPadding || noPaddingBottom ? "0" : "30px"}
      pt={0}
      className="block_section_container"
      flex={"0 1 100%"}
      data-testid={dataTestId}
    >
      {title && (
        <Box>
          <Text
            fontWeight={600}
            color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
            fontSize={isDesktop ? "3xl" : "xl"}
            as="div"
          >
            {title}
          </Text>
        </Box>
      )}
      <Fade in={fadeIn}>
        <SimpleGrid
          mt={"10px"}
          h={height ?? "unset"}
          spacing={includeSpacing ? "24px" : undefined}
          maxW={
            maxContainerWidth ?? isMainContainerXL
              ? "container.xl"
              : "container.lg"
          }
          columns={columns}
          row={rows}
          className={`block_section_grid ${
            gridClassName ? gridClassName : ""
          } ${shouldPulse ? "pulse" : ""}`}
          gap={gap ? gap : undefined}
        >
          {children}
        </SimpleGrid>
      </Fade>
    </Box>
  );
};
