import React from "react";
import { Link } from "@chakra-ui/react";

interface CertSiteLinkProps {
  url: string;
  children: React.ReactNode;
}

export const PublicSiteLink = ({ url, children }: CertSiteLinkProps) => {
  return (
    <Link
      onMouseOver={(event) => {
        event.currentTarget.style.textDecoration = "none";
      }}
      onClick={() => {
        window.open(url, "_blank");
      }}
    >
      {children}
    </Link>
  );
};
