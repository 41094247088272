export enum UserRole {
  HVImpactAdmin = -5,
  HVSalesAdmin = -4,
  HVMktAdmin = -3,
  HVCSMAdmin = -2,
  HVContentAdmin = -1,
  HVAdmin = 0,
  Admin = 1,
  Learner = 3,
}
