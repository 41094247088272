import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  BLOCK_SECTION_DIVIDER_BORDER,
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  BLOCK_SECTION_TITLE_BORDER_BOTTOM,
  MODAL_BACKGROUND_COLOR,
} from "../../../Styles/HeadversityStyle";
import { useContext } from "react";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { HVTestId } from "../../../Testing/dataTestIds";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import { BOTTOM_MENU_HEIGHT } from "../../../App";

const SPACING = "10";
const MOBILE_SPACING = "8";

interface ToolLandingProps {
  showTopBar: boolean;
  actionBarTitle: string;
  actionBarContent: any;
  toolCard: any;
  toolInfoOne: string;
  toolBoost: string;
  skillInfos: any;
  mobileLaunchText: string;
  mobileLaunchFunction: (mobileLaunch: boolean) => void;
  viewLogClick?: () => void;
  mobileToolStat?: any;
  boxShadow?: string;
  borderRadius?: string | number;
  useCustomContentContainer?: Boolean;
}

export const ToolLanding = (props: ToolLandingProps) => {
  const {
    showTopBar,
    actionBarTitle,
    actionBarContent,
    toolCard,
    toolInfoOne,
    toolBoost,
    skillInfos,
    viewLogClick,
    mobileToolStat,
    mobileLaunchText,
    mobileLaunchFunction,
    boxShadow,
    borderRadius,
    useCustomContentContainer,
  } = props;

  const isSmall = useBreakpointValue({
    md: false,
    lg: false,
    base: true,
  });

  const { audio, safeAreaInsets } = useContext<IShellProvider>(ShellContext);

  return (
    <>
      <Flex
        flexDir={"column"}
        maxW={"100%"}
        w={"100%"}
        mb={isSmall ? (!isWebPlatform() ? "48" : "28") : SPACING}
      >
        {showTopBar && (
          <>
            <Flex flexDir={"column"} w={"100%"}>
              <Flex flexDir={"row"}>
                <Heading
                  fontWeight={"semibold"}
                  fontSize={isSmall ? "xl" : "2xl"}
                  my={"2"}
                  color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
                >
                  {actionBarTitle}
                </Heading>
              </Flex>
              {!useCustomContentContainer && (
                <Flex
                  gap={"2"}
                  my={"5"}
                  pb={isSmall ? "1" : ""}
                  w={"100%"}
                  overflowX={"auto"}
                  justify={"flex-start"}
                  align={"center"}
                  boxShadow={boxShadow}
                  borderRadius={borderRadius}
                >
                  {actionBarContent}
                </Flex>
              )}
              {/* Allows for specific container renders */}
              {useCustomContentContainer && <>{actionBarContent}</>}
            </Flex>
            <Divider borderBottom={BLOCK_SECTION_DIVIDER_BORDER} mt={4} />
          </>
        )}
        <Flex
          my={!showTopBar ? 0 : isSmall ? 8 : SPACING}
          flexDir={isSmall ? "column" : "row"}
          align={isSmall ? "center" : "flex-start"}
          w={"100%"}
        >
          <Flex flexDir={"column"} align={isSmall ? "center" : "flex-start"}>
            <Box mr={isSmall ? 0 : "50px"}>
              <Text
                mb={isSmall ? MOBILE_SPACING : "6"}
                color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
                fontSize={isSmall ? "md" : "xl"}
                pr={4}
              >
                {toolInfoOne}
              </Text>
              <Divider
                borderBottom={BLOCK_SECTION_DIVIDER_BORDER}
                my={{ base: 6, sm: 2 }}
              />
              <Heading
                fontWeight={"semibold"}
                fontSize={"2xl"}
                mt={isSmall ? MOBILE_SPACING : "6"}
                mb={isSmall ? MOBILE_SPACING : "0"}
                color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
              >
                {toolBoost}
              </Heading>
              <Box mt={isSmall ? MOBILE_SPACING : SPACING}>
                <Table w={"100%"}>
                  <Tbody>
                    <Tr>
                      <Td p={"0"} border={"0"}>
                        <Heading
                          fontWeight={"semibold"}
                          fontSize={"xl"}
                          color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
                        >
                          {skillInfos?.skill1}
                          <Image
                            display={"inline-block"}
                            ml={"2"}
                            src={
                              "https://cdn.headversity.com/app/resources/other/trend.svg"
                            }
                            alt=""
                          />
                        </Heading>
                        <Text color={BLOCK_SECTION_PRIMARY_TEXT_COLOR} mt={"1"}>
                          {skillInfos?.skill1Description}
                        </Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td p={"0"} border={"0"}>
                        <Heading
                          mt={isSmall ? MOBILE_SPACING : SPACING}
                          fontWeight={"semibold"}
                          fontSize={"xl"}
                          color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
                        >
                          {skillInfos?.skill2}
                          <Image
                            display={"inline-block"}
                            ml={"2"}
                            src={
                              "https://cdn.headversity.com/app/resources/other/trend.svg"
                            }
                            alt=""
                          />
                        </Heading>
                        <Text color={BLOCK_SECTION_PRIMARY_TEXT_COLOR} mt={"1"}>
                          {skillInfos?.skill2Description}
                        </Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td p={"0"} border={"0"}>
                        <Heading
                          mt={isSmall ? MOBILE_SPACING : SPACING}
                          fontWeight={"semibold"}
                          fontSize={"xl"}
                          color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
                        >
                          {skillInfos?.skill3}
                          <Image
                            display={"inline-block"}
                            ml={"2"}
                            src={
                              "https://cdn.headversity.com/app/resources/other/trend.svg"
                            }
                            alt=""
                          />
                        </Heading>
                        <Text mt={"1"} color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}>
                          {skillInfos?.skill3Description}
                        </Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Box>

            {isSmall && (
              <>
                <Divider
                  my={MOBILE_SPACING}
                  borderBottom={BLOCK_SECTION_TITLE_BORDER_BOTTOM}
                />
                <Flex w={"100%"}>{mobileToolStat}</Flex>
              </>
            )}
          </Flex>

          {!isSmall && toolCard}
        </Flex>
      </Flex>
      {isSmall && (
        <Flex
          flexDir={"column"}
          pos={"fixed"}
          bottom={
            !isWebPlatform()
              ? `${safeAreaInsets.bottom + BOTTOM_MENU_HEIGHT}px`
              : "0px"
          }
          left={0}
          w={"100%"}
          minH={"80px"}
          align={"center"}
          justify={"center"}
          bgColor={MODAL_BACKGROUND_COLOR}
          zIndex={2}
          gap={4}
          p={4}
          pb={audio ? 24 : undefined}
          boxShadow={"0px 4px 12px 3px rgba(10, 50, 150, 0.15)"}
        >
          <AnimatedButton
            w={330}
            text={mobileLaunchText}
            onClick={() => {
              if (mobileLaunchFunction) {
                mobileLaunchFunction(true);
              }
            }}
            dataTestId={HVTestId.ToolLanding.launchButton}
          />
          {viewLogClick && (
            <AnimatedButton
              text={HVLocalizeStrings.TOOLS_VIEW_LOGS}
              colorSet={AnimatedButtonColorSet.Third}
              w={330}
              onClick={() => {
                viewLogClick();
              }}
              dataTestId={HVTestId.ToolLanding.viewHistoryButton}
            />
          )}
        </Flex>
      )}
    </>
  );
};
