import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { MicroLessonResultDto } from "@headversity/contract";
import { AxiosResponse } from "axios";

export const getLessons = (
  token: string
): Promise<AxiosResponse<MicroLessonResultDto>> => {
  return AxiosClient.get("api/microLessons", getConfig(token));
};

export const saveLessonCompleted = (
  token: string,
  microLessonUserInstanceId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/microLessonUserInstance/${microLessonUserInstanceId}/complete`,
    {},
    getConfig(token)
  );
};
