import { useContext, useEffect, useState } from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { BlockSection } from "../../Common/BlockSection";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { useNavigate } from "react-router-dom";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { ResilienceFeedSlider } from "../ResilienceFeed/ResilienceFeedSlider";
import { ScrollPanelViewMoreLinkButton } from "../../Common/ScrollPanelViewMoreLinkButton";
import { FiRadio, FiRss } from "react-icons/fi";
import { EVENTS, track } from "../../../Utils/Analytics";
import { BlogPostDto } from "@headversity/contract";
import { getScoredSkillIds } from "../../../Utils/SkillsUtil";
import { HVTestId } from "../../../Testing/dataTestIds";

interface ResilienceFeedPanelProps {
  from: string;
  isLiveSessions?: boolean;
  showPoints?: boolean;
}

export const ResilienceFeedPanel = ({
  from,
  isLiveSessions,
  showPoints,
}: ResilienceFeedPanelProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);
  const navigate = useNavigate();

  const { allResilienceFeedPosts, userSkillStats } =
    useContext<IGlobalProvider>(GlobalContext);

  const [filteredPosts, setFilteredPosts] = useState<BlogPostDto[]>([]);

  useEffect(() => {
    if (isLiveSessions) {
      setFilteredPosts(
        allResilienceFeedPosts.filter((item) => item.isLiveSession)
      );
    } else {
      // get resilience posts associated with one of our unlocked skills
      const filteredPosts = allResilienceFeedPosts.filter(
        (item) =>
          !item.isLiveSession &&
          item.skillCompetencies?.filter((skillCompetency) =>
            getScoredSkillIds(userSkillStats).includes(skillCompetency?.skillId)
          ).length > 0
      );
      setFilteredPosts(filteredPosts);
    }
  }, [allResilienceFeedPosts, userSkillStats]);

  return filteredPosts.length === 0 ? (
    <></>
  ) : (
    <Box
      maxW={isMainContainerXL ? "container.xl" : "container.lg"}
      mx={"auto"}
      mt={isDesktop ? "20px" : "0px"}
    >
      <BlockSection
        fadeIn={filteredPosts?.length > 0}
        height={"unset"}
        dataTestId={
          isLiveSessions
            ? HVTestId.ResilienceFeedPanel.liveSessionsPanel
            : HVTestId.ResilienceFeedPanel.blogPanel
        }
        title={
          <BlockSectionTitle
            title={
              isLiveSessions
                ? HVLocalizeStrings.SOLO_HOME_LIVE_SESSIONS_TITLE
                : HVLocalizeStrings.SOLO_HOME_RESILIENCE_FEED_TITLE
            }
            description={
              isLiveSessions
                ? HVLocalizeStrings.SOLO_HOME_LIVE_SESSIONS_DESCRIPTION
                : HVLocalizeStrings.SOLO_HOME_RESILIENCE_FEED_DESCRIPTION
            }
          />
        }
        columns={1}
        rows={1}
        gridClassName={isLiveSessions ? "live-sessions" : "resilience-feed"}
      >
        <ResilienceFeedSlider
          resilienceFeedPosts={filteredPosts}
          hideSubTitle={isLiveSessions}
          from={from}
          isLiveSessions={isLiveSessions}
          showPoints={showPoints}
        />

        <ScrollPanelViewMoreLinkButton
          onClick={() => {
            if (isLiveSessions) {
              navigate("/live-sessions");
            } else {
              navigate("/resilience-feed");
            }

            track(EVENTS.SeeAllResilienceFeedClicked, { HV_From: from });
          }}
          isDesktop={isDesktop}
          iconType={isLiveSessions ? FiRadio : FiRss}
        >
          {isLiveSessions
            ? HVLocalizeStrings.SOLO_SEE_ALL_LIVE_SESSIONS
            : HVLocalizeStrings.SOLO_HOME_SEE_MORE_RESILIENCE_FEED}
        </ScrollPanelViewMoreLinkButton>
      </BlockSection>
    </Box>
  );
};
