import { SurveyResponseDto } from "@headversity/contract";
import { useCallback } from "react";
import { saveSurveyResponse } from "../../Api/Cert/SurveyApi";
import { getKey } from "../../Utils/Helpers";

export interface ISurveyProvider {
  saveSurveyResponseToServer: (
    surveyInstanceId: number,
    surveyQuestionId: number,
    surveyResponse: SurveyResponseDto
  ) => Promise<SurveyResponse>;
}

export interface SurveyResponse {
  isCorrect: boolean;
}

export const useSurvey = (): ISurveyProvider => {
  const saveSurveyResponseToServer = useCallback(
    async (
      surveyInstanceId: number,
      surveyQuestionId: number,
      surveyResponse: SurveyResponseDto
    ) => {
      return saveSurveyResponse(
        surveyInstanceId,
        surveyQuestionId,
        surveyResponse,
        getKey()
      ).then((response) => {
        return { isCorrect: response.data };
      }) as Promise<SurveyResponse>;
    },
    []
  );

  return {
    saveSurveyResponseToServer,
  };
};
