import {
  CustomQuestionDto,
  QuestionUserResponseDto,
} from "@headversity/contract";
import _ from "lodash";

export const isWaitingForCustomFieldResponses = (
  customFields: CustomQuestionDto[] | undefined,
  userCustomFieldResponses: QuestionUserResponseDto[] | undefined
): boolean => {
  const numberOfResponses =
    Object.keys(
      _.groupBy(userCustomFieldResponses, (item) => {
        return item.questionId;
      })
    )?.length ?? 0;
  return (
    customFields !== undefined &&
    userCustomFieldResponses !== undefined &&
    numberOfResponses < customFields.length
  );
};
