import { Flex, FormLabel, Heading, Switch } from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";

interface Props {
  title: string;
  onChange?: () => void;
  isChecked?: boolean;
}

export default function LanguageSwitch({
   title,
   onChange,
   isChecked,
 }: Props) {
  return (
    <Flex
      className="language_switch_container"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Flex flexDir={"column"} gap={"12px"}>
        <FormLabel htmlFor={title}>
          <Heading
            fontSize={"20px"}
            color={MODAL_PRIMARY_TEXT_COLOR}
            fontWeight={"500"}
          >
            {title}
          </Heading>
        </FormLabel>
      </Flex>
      <Switch
        size={"lg"}
        isChecked={isChecked}
        onChange={onChange}
        id={title}
      />
    </Flex>
  );
}
