import { Box, Center, useBreakpointValue } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { useContext } from "react";
import { GlobalContext } from "../../../State/GlobalContext";
import { useNavigate } from "react-router-dom";
import { LanguageSelector } from "../../Common/LanguageSelector";
import { useLoginLanguageDetector } from "../../../Hooks/People/useLoginLanguageDetector";

interface LoginLanguageSelectorProps {
  navigateToLogin: boolean;
}
export const LoginLanguageSelector = ({
  navigateToLogin,
}: LoginLanguageSelectorProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const navigate = useNavigate();

  const { setSelectedUserLanguage } = useContext(GlobalContext);
  useLoginLanguageDetector();

  return (
    <Center
      mt={!isMobile || window.innerHeight < 750 ? 4 : undefined}
      pos={isMobile && window.innerHeight >= 750 ? "fixed" : undefined}
      bottom={isMobile && window.innerHeight >= 750 ? "50px" : undefined}
    >
      <Box w="150px">
        <LanguageSelector
          onLanguageChanged={(selectedLanguage: string) => {
            setSelectedUserLanguage(selectedLanguage);
            HVLocalizeStrings.setLanguage(selectedLanguage);
            if (navigateToLogin) {
              navigate("/login");
            }
          }}
        />
      </Box>
    </Center>
  );
};
