import { useCallback, useState } from "react";
import { getAudioTrackUsages } from "../../Api/Solo/MindfulnessApi";
import { getKey } from "../../Utils/Helpers";
import { AudioTrackVersionUsageDto } from "@headversity/contract";

export interface IMindfulnessToolStatProvider {
  mindfulnessAudioUsage: AudioTrackVersionUsageDto[];
  getMindfulnessAudioUsageFromServer: () => void;
}

export const useMindfulnessToolStat = (): IMindfulnessToolStatProvider => {
  const [mindfulnessAudioUsage, setMindfulnessAudioUsage] = useState<
    AudioTrackVersionUsageDto[]
  >([]);
  const getMindfulnessAudioUsageFromServer = useCallback(async () => {
    return getAudioTrackUsages(getKey()).then((response) => {
      setMindfulnessAudioUsage(response.data);
    });
  }, []);

  return { mindfulnessAudioUsage, getMindfulnessAudioUsageFromServer };
};
