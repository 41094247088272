import {
  EligibilityFileJobDto,
  PreprocessDetails,
} from "@headversity/contract";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  BOOST_TEXT_COLOR,
  DESTRUCTIVE_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { Flex, Text } from "@chakra-ui/react";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface StepInfoErrorsProps {
  pendingJob?: EligibilityFileJobDto;
  showCertStats: boolean;
}

const StepInfoErrors = ({ pendingJob, showCertStats }: StepInfoErrorsProps) => {
  const preProcessErrors = pendingJob?.fileInfo.preProcessErrors;
  const stats = pendingJob?.fileInfo.details;
  return (
    <>
      {preProcessErrors && Object.keys(preProcessErrors).length > 0 && (
        <ImportErrors preProcessErrors={preProcessErrors} />
      )}
      {(!preProcessErrors || Object.keys(preProcessErrors).length === 0) && (
        <ImportStats stats={stats} showCertStats={showCertStats} />
      )}
    </>
  );
};

const ImportErrors = (preProcessErrors: {
  preProcessErrors: undefined | { [key: string]: string[] };
}) => {
  if (!preProcessErrors) {
    return null;
  }
  return (
    <Flex flexDirection={"column"} gap={5} mt={5}>
      <Text
        fontSize="lg"
        fontWeight={"semibold"}
        color={DESTRUCTIVE_TEXT_COLOR}
        data-testid={HVTestId.StepInfoErrors.errors}
      >
        {HVLocalizeStrings.REACH_USERS_FILE_ERRORS}
      </Text>
      <Flex flexDirection={"column"} gap={3}>
        {Object.keys(preProcessErrors?.preProcessErrors || {}).map((line) => {
          return (
            <Flex key={line} fontSize="md">
              <Text>{`Line ${line}`}</Text>
              <Text ms={6} fontWeight="semibold">
                {preProcessErrors?.preProcessErrors &&
                  preProcessErrors.preProcessErrors[line]?.join(", ")}
              </Text>
            </Flex>
          );
        })}
      </Flex>
      <Text mt={8}>{HVLocalizeStrings.REACH_USERS_CORRECT_CSV}</Text>
    </Flex>
  );
};

interface ImportStatsProps {
  stats?: PreprocessDetails;
  showCertStats: boolean;
}

const ImportStats = ({ stats, showCertStats }: ImportStatsProps) => {
  return (
    <Flex flexDirection={"column"} gap={5} mt={5}>
      <Text
        fontSize="lg"
        fontWeight={"semibold"}
        color={BOOST_TEXT_COLOR}
        data-testid={HVTestId.StepInfoErrors.stats}
      >
        {HVLocalizeStrings.REACH_USERS_FILE_READY}
      </Text>
      {stats && (
        <Flex flexDirection={"column"} gap={3} fontSize="md">
          <StatItem
            label={HVLocalizeStrings.REACH_USERS_USERS_ADDED_UPDATED}
            value={stats.usersAddedOrUpdated}
            dataTestId={HVTestId.StepInfoErrors.added}
          />
          <StatItem
            label={HVLocalizeStrings.REACH_USERS_USERS_REMOVED}
            value={
              stats.usersRemoved ??
              HVLocalizeStrings.REACH_USERS_FULL_LIST_DELETE_WARNING
            }
            dataTestId={HVTestId.StepInfoErrors.removed}
          />
          {showCertStats && (
            <StatItem
              label={HVLocalizeStrings.REACH_USERS_CERTS_ASSIGNED}
              value={stats.certsAssigned}
              dataTestId={HVTestId.StepInfoErrors.certs}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};

interface StatItemProps {
  label: string;
  value: number | string;
  dataTestId?: string;
}

const StatItem = ({ label, value, dataTestId }: StatItemProps) => {
  return (
    <Flex>
      <Text w={"280px"}>{label}</Text>
      <Text fontWeight="semibold" textAlign={"left"} data-testid={dataTestId}>
        {value}
      </Text>
    </Flex>
  );
};
export default StepInfoErrors;
