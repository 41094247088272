import {
  OptimismPlanChallengeDto,
  OptimismPlanDto,
} from "@headversity/contract";
import dayjs from "dayjs";
import { HVLocalizeStrings } from "../../../../../Localization/HVLocalizeStrings";

export const getDateRangeString = (item: OptimismPlanDto) => {
  const startDate = dayjs
    .utc(item.startTime)
    .local()
    .format(HVLocalizeStrings.DATE_FORMAT);
  const endDate = dayjs
    .utc(item.startTime)
    .local()
    .add(item.numberOfDays - 1, "day")
    .format(HVLocalizeStrings.DATE_FORMAT);
  return `${startDate} - ${endDate}`;
};

export const completedToday = (plan: OptimismPlanDto | null): boolean => {
  const current = getCurrentChallenge(plan);

  if (current) {
    return true;
  }

  return false;
};

export const getDayNumber = (plan: OptimismPlanDto | null): number => {
  const date = dayjs();
  const startDate = dayjs.utc(plan?.startTime).local();
  return date.startOf("day").diff(startDate.startOf("day"), "day") + 1;
};

export const getCurrentChallenge = (
  plan: OptimismPlanDto | null,
  selectedDay?: number
): OptimismPlanChallengeDto | null => {
  const current = selectedDay ?? getDayNumber(plan);
  const challenge = plan?.optimismPlanChallenges.find(
    (challenge) => challenge.dayNumber === current
  );

  if (challenge) {
    return challenge;
  }

  return null;
};

export const challengeIsTodayAndIncomplete = (
  plan: OptimismPlanDto
): boolean => {
  return !completedToday(plan);
};

export const getMissingDayChallenge = (plan: OptimismPlanDto) => {
  const currentDay = getDayNumber(plan);
  const missingDateChallenge: number[] = [];
  for (let i = 1; i <= currentDay; i++) {
    if (
      !plan?.optimismPlanChallenges?.find(
        (challenge) => challenge.dayNumber === i
      )
    ) {
      missingDateChallenge.push(i);
    }
  }
  return missingDateChallenge;
};

export const isCurrentOptimismPlan = (plan: OptimismPlanDto): boolean => {
  const startDate = dayjs
    .utc(plan.startTime)
    .local()
    .format(HVLocalizeStrings.DATE_FORMAT);

  const endDate = dayjs
    .utc()
    .local()
    .add(plan.numberOfDays - 1, "day")
    .format(HVLocalizeStrings.DATE_FORMAT);

  const date = dayjs();
  return dayjs(date).isBetween(startDate, endDate, "day", "[]");
};

export const getOptimismChallengeUsage = (plans: OptimismPlanDto[]) => {
  let count = 0;
  for (let i = 0; i < plans?.length; i++) {
    count += plans[i].optimismPlanChallenges.length;
  }
  return count;
};

export const getNumberOfCompletedChallenges = (
  plans: OptimismPlanDto[]
): number => {
  return plans.reduce(
    (acc, curr) => acc + curr.optimismPlanChallenges.length,
    0
  );
};
