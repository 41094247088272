import { useCallback, useEffect, useState } from "react";
import {
  GoalDto,
  GoalWinThemeDto,
  QuestionAnswerDto,
  SkillDto,
} from "@headversity/contract";
import { getBgImageSource, getOrderedSkills } from "../../Utils/SkillsUtil";
import { getKey } from "../../Utils/Helpers";
import {
  getGoalIdForUser,
  saveGoalQuestionAnswers,
  setGoalForUser,
} from "../../Api/Solo/GoalApi";

export interface ISkillProvider {
  skills: SkillDto[];
  coreSkills: SkillDto[];
  workSkills: SkillDto[];
  homeSkills: SkillDto[];
  selectedSkill: SkillDto | undefined;
  setSelectedSkill: (skillDto: SkillDto | undefined) => void;
  setSkills: (skills: SkillDto[]) => void;
  selectedGoal: GoalDto | undefined;
  goals: GoalDto[];
  goalWinThemes: GoalWinThemeDto[];
  setGoalsAndWinThemes: (
    goals: GoalDto[],
    goalWinThemes: GoalWinThemeDto[]
  ) => void;
  getUserGoalFromServer: (goals: GoalDto[]) => void;
  setGoalToServer: (goal: GoalDto) => void;
  saveGoalQuestionAnswersToServer: (
    goalId: number,
    questionAnswers: QuestionAnswerDto[]
  ) => void;
  selectedGoalBgImage: string;
}

export const useSkills = (): ISkillProvider => {
  const [skills, setSkills] = useState<SkillDto[]>([]);
  const [coreSkills, setCoreSkills] = useState<SkillDto[]>([]);
  const [workSkills, setWorkSkills] = useState<SkillDto[]>([]);
  const [homeSkills, setHomeSkills] = useState<SkillDto[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<SkillDto | undefined>();

  const [goals, setGoals] = useState<GoalDto[]>([]);
  const [goalWinThemes, setGoalWinThemes] = useState<GoalWinThemeDto[]>([]);
  const [selectedGoal, setSelectedGoal] = useState<GoalDto>();
  const [selectedGoalBgImage, setSelectedGoalBgImage] = useState("");

  const setGoalsAndWinThemes = useCallback(
    (goals: GoalDto[], goalWinThemes: GoalWinThemeDto[]) => {
      setGoals(goals);
      setGoalWinThemes(goalWinThemes);
    },
    []
  );

  const setGoalToServer = useCallback(async (goal: GoalDto) => {
    setSelectedGoal(goal);

    await setGoalForUser(getKey(), goal.id);
  }, []);

  useEffect(() => {
    if (!selectedGoal) {
      setSelectedGoalBgImage("");
    } else {
      setSelectedGoalBgImage(
        getBgImageSource(
          selectedGoal.imageUrl.toString(),
          window.innerWidth >= 768
        )
      );
    }
  }, [selectedGoal]);

  useEffect(() => {
    if (skills?.length > 0) {
      const skillsOrdered = getOrderedSkills(skills);

      setCoreSkills(skillsOrdered.filter((skill) => skill.context === "Core"));
      setWorkSkills(skillsOrdered.filter((skill) => skill.context === "Work"));
      setHomeSkills(skillsOrdered.filter((skill) => skill.context === "Home"));
    }
  }, [skills]);

  const getUserGoalFromServer = useCallback(async (goals: GoalDto[]) => {
    const response = await getGoalIdForUser(getKey());

    if (response.data?.goalId) {
      const goal = goals.find((goal) => goal.id === response.data?.goalId);
      if (goal) {
        setSelectedGoal(goal);
      }
    }
  }, []);

  const saveGoalQuestionAnswersToServer = useCallback(
    async (goalId: number, questionAnswers: QuestionAnswerDto[]) => {
      await saveGoalQuestionAnswers(getKey(), goalId, questionAnswers);
    },
    []
  );

  return {
    skills,
    coreSkills,
    workSkills,
    homeSkills,
    selectedSkill,
    setSelectedSkill,
    setSkills,
    setGoalsAndWinThemes,
    selectedGoal,
    goals,
    goalWinThemes,
    getUserGoalFromServer,
    setGoalToServer,
    saveGoalQuestionAnswersToServer,
    selectedGoalBgImage,
  };
};
