import { Box, Button, Icon } from "@chakra-ui/react";
import { IconType } from "react-icons/lib";
import { BLOCK_SECTION_SECONDARY_TEXT_COLOR } from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";

interface ScrollPanelViewMoreLinkButtonProps {
  onClick: () => void;
  isDesktop?: boolean;
  noPullUp?: boolean;
  children: any;
  iconType?: IconType;
}

export const ScrollPanelViewMoreLinkButton = ({
  onClick,
  isDesktop,
  noPullUp,
  children,
  iconType,
}: ScrollPanelViewMoreLinkButtonProps) => {
  return (
    <Box mt={isDesktop && !noPullUp ? "-35px" : undefined}>
      <Button
        className="button-link"
        color={BLOCK_SECTION_SECONDARY_TEXT_COLOR}
        onClick={onClick}
        fontSize={"14px"}
        ml="auto"
        mr={isDesktop ? undefined : "auto"}
        zIndex={1}
        data-testid={HVTestId.ResilienceSkill20Panel.allTrainingButton}
      >
        <>
          {iconType && <Icon p="1px" mr={1} as={iconType} />}
          {children}
        </>
      </Button>
    </Box>
  );
};
