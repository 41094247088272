import { useContext, useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Helmet } from "react-helmet";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { HEADER_HOME_BACKGROUND } from "../../../Styles/HeadversityStyle";
import { GlobalContext } from "../../../State/GlobalContext";
import { ErrorPage } from "../../Shared/Error/ErrorPage";
import { CustomFieldModal } from "../CustomFields/CustomFieldModal";
import { isWaitingForCustomFieldResponses } from "../../../Utils/CustomQuestionUtil";
import { HeaderWelcomeText } from "../../Common/HeaderWelcomeText";
import { ResilienceSkill20Home } from "./ResilienceSkill20Home";
import { GoalBox } from "../Shared/GoalBox";

export const HomePage = () => {
  const {
    lessonNotFound,
    getPageTitle,
    userSkillStats,
    isUninterruptibleModalOpen,
    customFields,
    userCustomFieldResponses,
  } = useContext(GlobalContext);

  const [customFieldsModalOpen, setCustomFieldsModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (isUninterruptibleModalOpen) return;

    if (!customFields || !userCustomFieldResponses) {
      return;
    }

    setCustomFieldsModalOpen(
      isWaitingForCustomFieldResponses(customFields, userCustomFieldResponses)
    );
  }, [customFields, userCustomFieldResponses, isUninterruptibleModalOpen]);

  return (
    <>
      {!lessonNotFound ? (
        <>
          <Helmet>
            <title>{getPageTitle(`${HVLocalizeStrings.HEADVERSITY}`)}</title>
          </Helmet>
          <HeaderSetter
            title={<HeaderWelcomeText />}
            description={HVLocalizeStrings.SOLO_HOME_DESCRIPTION}
            backgroundImage={HEADER_HOME_BACKGROUND}
            //showLogo={true}
          />
          {userSkillStats.length > 0 ? (
            <FadeAfterDelay>
              <GoalBox />
              <ResilienceSkill20Home />
            </FadeAfterDelay>
          ) : (
            <Box
              height="100vh"
              my="60px"
              maxW={"container.xl"}
              mx={"auto"}
            ></Box>
          )}

          {/* spacer */}
          <Box h="20px" />

          {customFieldsModalOpen && (
            <CustomFieldModal
              open={customFieldsModalOpen}
              setOpen={setCustomFieldsModalOpen}
              preventClose={true}
            ></CustomFieldModal>
          )}
        </>
      ) : (
        <ErrorPage />
      )}
    </>
  );
};
