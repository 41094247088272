import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HVSportLocalizeStrings } from "../../../Localization/HVSportLocalizeString";
import { GlobalContext } from "../../../State/GlobalContext";
import { FeatureFlags } from "../../../Utils/FeatureFlags";
import { getIntroVideoUrl } from "../../../Utils/Helpers";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { HeaderWelcomeText } from "../../Common/HeaderWelcomeText";

export interface SportWelcomeProps {
  isLeader: boolean;
  onContinue: () => void;
}
export const SportWelcome = ({ isLeader, onContinue }: SportWelcomeProps) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const [videoUrl, setVideoUrl] = useState("");
  const [customLogoUrl, setCustomLogoUrl] = useState("");
  /*
   * simulate company logo
   *
  customLogoUrl =
    "https://cdn.headversity.com/companyLogo/36ba7cee-ed0f-4377-9b61-841c55ed7a64.png";
  */

  const { selectedUserLanguage, getFeatureVersion, company } =
    useContext(GlobalContext);

  /* duped from WelcomeModal */
  useEffect(() => {
    const featureDetails = getFeatureVersion(FeatureFlags.WELCOME_2023) as any;

    const introVideoUrl = getIntroVideoUrl(
      featureDetails,
      selectedUserLanguage,
      "https://player.vimeo.com/video/999923478?h=fcbb38e61e"
    );

    setVideoUrl(introVideoUrl);

    // check if we have a feature override
    if (featureDetails && featureDetails.customLogoUrl !== undefined) {
      setCustomLogoUrl(featureDetails.customLogoUrl);
    }

    // prefer company logo
    if (company?.logo) {
      setCustomLogoUrl(company?.logo);
    }
  }, [selectedUserLanguage]);

  return (
    <>
      <Box
        height={isDesktop ? "475px" : undefined}
        mx={isDesktop ? "40px" : "10px"}
        mb={"80px"}
        my="30px"
      >
        <Center>
          <Flex gap={isDesktop ? 10 : 5} mb="25px">
            <Center>
              <Image
                ml="-5px"
                src={"https://cdn.headversity.com/app/sport/sport-logo.png"}
                maxH="80px"
                maxW="150px"
                alt=""
              />
            </Center>
            {customLogoUrl && (
              <Image
                src={customLogoUrl}
                maxH="80px"
                maxW="175px"
                m="auto"
                alt=""
              />
            )}
          </Flex>
        </Center>

        <Box>
          <HeaderWelcomeText
            blockSection={true}
            blockSectionMb={0}
            largeFont={isDesktop}
            color="white"
          />

          <FirstTimeInterstitialModalText
            html={
              isLeader
                ? HVSportLocalizeStrings.CHARTER_INTERSTITIAL_TEXT_LEADER
                : HVSportLocalizeStrings.CHARTER_INTERSTITIAL_TEXT_PLAYER
            }
          />
        </Box>

        {videoUrl && (
          <Center my={4}>
            <Box
              width={isDesktop ? "400px" : "360px"}
              height={isDesktop ? "230px" : "210px"}
              boxShadow="lg"
            >
              <iframe
                src={videoUrl}
                width={isDesktop ? "400px" : "360px"}
                height={isDesktop ? "230px" : "210px"}
                title="video"
                allowFullScreen={true}
              ></iframe>
            </Box>
          </Center>
        )}
      </Box>
      <BottomActionBar
        showOptions={true}
        hideTheme={true}
        nextButtonText={HVLocalizeStrings.CONTINUE}
        onNextButtonClick={() => {
          onContinue();
        }}
      />
    </>
  );
};

interface FirstTimeInterstitialModalTextProps {
  html: string;
  isHeadline?: boolean;
}

const FirstTimeInterstitialModalText = (
  props: FirstTimeInterstitialModalTextProps
) => {
  const { html } = props;
  const isDesktop = useBreakpointValue({ base: false, md: true });
  return (
    <Box mt={1} color="white">
      {props.isHeadline ? (
        <Heading
          fontSize="21px"
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      ) : (
        <Text
          fontSize={isDesktop ? "16px" : "15px"}
          lineHeight={"1.3"}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      )}
    </Box>
  );
};
