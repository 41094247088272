import { useContext, useEffect, useState } from "react";
import { onPusherEvent } from "../../Utils/PusherChannelNotifications";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import {
  CharterActivity,
  TeamLessonSlideProgressDto,
} from "@headversity/contract";

export const useTeamPusherSubs = () => {
  const {
    pusherClient,
    pusherTeamChannels,
    reloadPathsForAllTeamsFromServer,
    getPollInstancesFromServer,
    currentTeamLessonUserInstanceId,
    setCurrentSlideIndex,
    getTeamLessonUserInstanceParticipantsFromServer,
    getPollInstanceResultFromServer,
    getPollInstancesResultFromServer,
    participantModalOpen,
    updateTeamLessonUserInstanceInProgressStep,
    paths,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [inProgressUpdate, setInProgressUpdate] = useState<
    | {
        inProgressStep: CharterActivity | undefined;
        teamLessonUserInstanceId: number;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (pusherTeamChannels && pusherClient) {
      let timeoutId: any = null;
      return onPusherEvent(
        pusherClient,
        "path-refresh",
        pusherTeamChannels,
        (data: any, channelName: string) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = null;
          }
          timeoutId = setTimeout(() => {
            if (data?.inProgressStepOnly) {
              setInProgressUpdate({
                inProgressStep: data.inProgressStep,
                teamLessonUserInstanceId: data.teamLessonUserInstanceId,
              });
            } else {
              reloadPathsForAllTeamsFromServer();
            }
            timeoutId = null;
          }, 1000);
        },
        1
      );
    }
  }, [pusherClient, pusherTeamChannels]);

  useEffect(() => {
    if (inProgressUpdate && paths) {
      updateTeamLessonUserInstanceInProgressStep(
        inProgressUpdate.inProgressStep as any,
        inProgressUpdate.teamLessonUserInstanceId,
        paths
      );
      setInProgressUpdate(undefined);
    }
  }, [inProgressUpdate, paths]);

  useEffect(() => {
    if (pusherTeamChannels && pusherClient && currentTeamLessonUserInstanceId) {
      let timeoutId: any = null;
      return onPusherEvent(
        pusherClient,
        "NewPollRequest",
        pusherTeamChannels,
        (data: any, channelName: string) => {
          if (
            data.teamLessonUserInstanceId === currentTeamLessonUserInstanceId
          ) {
            if (timeoutId) {
              clearTimeout(timeoutId);
              timeoutId = null;
            }
            timeoutId = setTimeout(() => {
              getPollInstancesFromServer();
              timeoutId = null;
            }, 1000);
          }
        },
        1
      );
    }
  }, [pusherClient, pusherTeamChannels, currentTeamLessonUserInstanceId]);

  useEffect(() => {
    if (pusherTeamChannels && pusherClient && currentTeamLessonUserInstanceId) {
      let timeoutId: any = null;
      return onPusherEvent(
        pusherClient,
        "participants-changed",
        pusherTeamChannels,
        (data: any, channelName: string) => {
          if (
            data.TeamLessonUserInstanceId === currentTeamLessonUserInstanceId
          ) {
            if (timeoutId) {
              clearTimeout(timeoutId);
              timeoutId = null;
            }
            timeoutId = setTimeout(() => {
              getTeamLessonUserInstanceParticipantsFromServer(
                currentTeamLessonUserInstanceId
              );
              timeoutId = null;
            }, 1000);
          }
        },
        1
      );
    }
  }, [pusherClient, pusherTeamChannels, currentTeamLessonUserInstanceId]);

  useEffect(() => {
    if (pusherTeamChannels && pusherClient && currentTeamLessonUserInstanceId) {
      let timeoutIds: any = {};
      return onPusherEvent(
        pusherClient,
        "PollRequestAnswer",
        pusherTeamChannels,
        (data: any, channelName: string) => {
          const pollInstanceId = data.pollQuestionUserInstanceId;
          const pollInstanceIds = data.pollQuestionUserInstanceIds;
          if (pollInstanceId) {
            if (timeoutIds[pollInstanceId]) {
              clearTimeout(timeoutIds[pollInstanceId]);
              timeoutIds[pollInstanceId] = null;
            }
            timeoutIds[pollInstanceId] = setTimeout(() => {
              getPollInstanceResultFromServer(pollInstanceId);
              timeoutIds[pollInstanceId] = null;
            }, 1000);
          } else if (pollInstanceIds) {
            const key = pollInstanceIds.join(",");
            if (timeoutIds[key]) {
              clearTimeout(timeoutIds[key]);
              timeoutIds[key] = null;
            }
            timeoutIds[key] = setTimeout(() => {
              getPollInstancesResultFromServer(pollInstanceIds);
              timeoutIds[key] = null;
            }, 1000);
          }
        },
        1
      );
    }
  }, [pusherClient, pusherTeamChannels, currentTeamLessonUserInstanceId]);

  useEffect(() => {
    if (
      pusherTeamChannels &&
      pusherClient &&
      participantModalOpen &&
      currentTeamLessonUserInstanceId
    ) {
      return onPusherEvent(
        pusherClient,
        "team-lesson-slide-progress",
        pusherTeamChannels,
        (data: TeamLessonSlideProgressDto, channelName: string) => {
          if (
            data.teamLessonUserInstanceId !== currentTeamLessonUserInstanceId
          ) {
            return;
          }

          setCurrentSlideIndex(data.index);
        },
        1
      );
    }
  }, [
    pusherClient,
    pusherTeamChannels,
    participantModalOpen,
    currentTeamLessonUserInstanceId,
  ]);

  return null;
};
