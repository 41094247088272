import { Flex, Image, Text } from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { Border_Radius } from "../../../../Styles/HeadversityStyle";

interface Props {
  title?: string;
  subTitle?: string;
  icon?: string;
  onClick?(): void;
  isCardOpen?: Boolean;
  showEllipsis?: Boolean;
  bgImage?: string;
  ellipsisColor?: string;
  titleColor?: string;
  subTitleColor?: string;
  iconWidth?: string;
  iconHeight?: string;
  iconLeft?: boolean;
  bgPos?: string;
}

export default function SlidingCardHeader({
  title,
  subTitle,
  icon,
  onClick,
  isCardOpen,
  showEllipsis,
  bgImage,
  ellipsisColor,
  titleColor,
  subTitleColor,
  iconWidth,
  iconHeight,
  iconLeft,
  bgPos,
}: Props) {
  return (
    <Flex
      className="sliding_card_header_container"
      w="100%"
      h={"130px"}
      borderBottomRadius={`${
        isCardOpen || !showEllipsis ? "0px" : Border_Radius
      }`}
      borderTopRadius={Border_Radius}
      boxShadow={"lg"}
      justifyContent={"space-between"}
      alignItems={"center"}
      p={3}
      onClick={onClick}
      bgImage={bgImage}
      bgRepeat={"no-repeat"}
      bgSize={"cover"}
      bgPos={bgPos}
      gap={"20px"}
      aria-hidden={"true"}
    >
      {iconLeft && icon && (
        <Flex
          className="sliding_card_header_icon_container"
          alignItems={"center"}
          mr={`${!showEllipsis && "28px"}`}
        >
          <Image
            w={iconWidth ?? "95px"}
            h={iconHeight ?? "95px"}
            src={icon}
            alt=""
          />
        </Flex>
      )}
      <Flex
        className="sliding_card_header_typography_container"
        flexDir={"column"}
        width="100%"
      >
        <Text
          fontSize={"lg"}
          fontWeight="600"
          className="sliding_card_header_title"
          color={titleColor}
        >
          {title}
        </Text>
        <Text
          fontSize={"md"}
          fontWeight="400"
          className="sliding_card_header_sub_title"
          color={subTitleColor}
        >
          {subTitle}
        </Text>
      </Flex>
      {!iconLeft && icon && (
        <Flex
          className="sliding_card_header_icon_container"
          alignItems={"center"}
          mr={`${!showEllipsis && "23px"}`}
        >
          <Image
            w={iconWidth ?? "95px"}
            h={iconHeight ?? "95px"}
            src={icon}
            alt=""
          />
        </Flex>
      )}
      {showEllipsis && (
        <Flex
          className="sliding_card_header_ellipsis"
          color={ellipsisColor}
          fontSize={"xl"}
        >
          <FiMoreVertical />
        </Flex>
      )}
    </Flex>
  );
}
