import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { CalmingToolInfoDto, CalmingToolInput } from "@headversity/contract";
import { AxiosResponse } from "axios";

export const getCalmingToolUsages = (
  token: string
): Promise<AxiosResponse<CalmingToolInfoDto[]>> => {
  return AxiosClient.get("api/calmingTool/usage", getConfig(token));
};

export const saveCalmingToolUsage = (
  token: string,
  input: CalmingToolInput
) => {
  return AxiosClient.post("api/calmingTool/usage", input, getConfig(token));
};
