import {
  CreateHelpResourceDto,
  HelpResourceDto,
  InviteCodeForReachDto,
} from "@headversity/contract";
import {
  ReactNode,
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  createGetHelpResource,
  deleteHelpResource,
  getHelpResources,
  saveGetHelpResource,
} from "../../Api/Reach/ReachOrganizationsApi";
import { getKey } from "../../Utils/Helpers";
import { getCompanyInviteCodesForReach } from "../../Api/Reach/ReachApi";
import { GlobalContext } from "../GlobalContext";

type ReachOrganizationsState = {
  helpResourcesIsLoading: boolean;
  // Help Resource Creation
  addHelpResourceDialogOpen: boolean;
  setAddHelpResourceDialogOpen: (isOpen: boolean) => void;
  createHelpResource: (
    createHelpResourceDto: CreateHelpResourceDto
  ) => Promise<void>;

  // editing
  setSelectedHelpResource: (resource: HelpResourceDto | undefined) => void;
  selectedHelpResource?: HelpResourceDto;

  // listing
  fetchHelpResources: () => Promise<void>;
  helpResources: HelpResourceDto[];
  refreshHelpResourcesTimestamp: string;
  refreshHelpResources: () => void;

  deleteHelpResource: (resourceId: number) => Promise<void>;

  // uploading
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;

  selectedInviteCodeId: number | undefined;
  setSelectedInviteCodeId: (id?: number) => void;
  fetchCompanyInviteCodes: () => Promise<void>;
  companyInviteCodes: InviteCodeForReachDto[];

  updateSortOrder(resourceId: number, direction: "up" | "down"): Promise<void>;
  resourceCanUpdateSortOrder(
    resourceId: number,
    direction: "up" | "down"
  ): boolean;
};

export const ReachHelpResourcesContext = createContext<ReachOrganizationsState>(
  {
    helpResourcesIsLoading: false,
    addHelpResourceDialogOpen: false,
    setAddHelpResourceDialogOpen: (isOpen: boolean) => {},
    setSelectedHelpResource: (resource: HelpResourceDto | undefined) => {},
    selectedHelpResource: undefined,
    createHelpResource: async () => {},
    fetchHelpResources: async () => {},
    helpResources: [],
    refreshHelpResourcesTimestamp: "",
    refreshHelpResources: () => {},
    fetchCompanyInviteCodes: async () => {},
    companyInviteCodes: [],
    selectedInviteCodeId: undefined,
    setSelectedInviteCodeId: (id?: number) => {},
    deleteHelpResource: async () => {},
    isUploading: false,
    setIsUploading: (isUploading: boolean) => {},
    updateSortOrder: async (resourceId, dir) => {},
    resourceCanUpdateSortOrder: (resourceId: number, direction: string) =>
      false,
  }
);

export const ReachHelpResourcesContextProvider: FC<{
  children: ReactNode;
}> = (props) => {
  const [helpResourcesIsLoading, setHelpResourcesIsLoading] =
    useState<boolean>(false);
  const [addHelpResourceDialogOpen, setAddHelpResourceDialogOpen] =
    useState<boolean>(false);
  const [helpResources, setHelpResources] = useState<HelpResourceDto[]>([]);
  const [inviteCodes, setInviteCodes] = useState<InviteCodeForReachDto[]>([]);
  const [selectedInviteCodeId, setSelectedInviteCodeId] = useState<
    number | undefined
  >();
  const [editHelpResource, setEditHelpResource] = useState<
    HelpResourceDto | undefined
  >();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [refreshHelpResourcesTimestamp, setRefreshHelpResourcesTimestamp] =
    useState<string>("");

  const { self, getHelpResourcesForUserFromServer } = useContext(GlobalContext);

  const postCreateHelpResource = useCallback(
    async (createHelpResourceDto: CreateHelpResourceDto) => {
      await createGetHelpResource(getKey(), createHelpResourceDto);
    },
    []
  );

  const handleSetIsUploading = useCallback(
    (isUploading: boolean) => {
      setIsUploading(isUploading);
    },
    [setIsUploading]
  );

  const setAddHelpResourceDialogOpenCallback = useCallback(
    (isOpen: boolean) => {
      setAddHelpResourceDialogOpen(isOpen);
    },
    []
  );

  const fetchHelpResourcesCallback = useCallback(async () => {
    setHelpResourcesIsLoading(true);
    const response = await getHelpResources(
      getKey(),
      selectedInviteCodeId || self!.inviteCodeId
    );
    setHelpResources(response.data);
    setHelpResourcesIsLoading(false);
  }, [self, selectedInviteCodeId]);

  const fetchCompanyInviteCodesCallback = useCallback(async () => {
    const response = await getCompanyInviteCodesForReach(getKey());
    setInviteCodes(response.data);
  }, []);

  const refreshHelpResourcesListCallback = useCallback(() => {
    setRefreshHelpResourcesTimestamp(new Date().toISOString());
    getHelpResourcesForUserFromServer();
  }, []);

  const setSelectedInviteCodeIdCallback = useCallback(
    (inviteCodeId?: number) => {
      setSelectedInviteCodeId(inviteCodeId);
    },
    []
  );

  const updateSortOrderCallback = useCallback(
    async (resourceId: number, dir: "up" | "down") => {
      const swapIndexOffset = dir === "up" ? -1 : 1;
      const resourceIndex = helpResources.findIndex(
        (resource) => resource.id === resourceId
      );

      // swap the sortOrders
      const resource = helpResources[resourceIndex];
      const swapResource = helpResources[resourceIndex + swapIndexOffset];

      const orderTmp = resource.sortOrder;

      resource.sortOrder = swapResource.sortOrder;
      swapResource.sortOrder = orderTmp;
      await saveGetHelpResource(getKey(), resourceId, resource);
      await saveGetHelpResource(getKey(), swapResource.id, swapResource);
      refreshHelpResourcesListCallback();
    },
    [helpResources]
  );

  const deleteHelpResourceCallback = useCallback(async (resourceId: number) => {
    await deleteHelpResource(getKey(), resourceId);
  }, []);

  const resourceCanUpdateSortOrderCallback = useCallback(
    (resourceId: number, direction: string): boolean => {
      // if there is only 1 item, we can't update sort order
      if (helpResources.length <= 1) return false;

      const resourceIndex = helpResources.findIndex(
        (resource) => resource.id === resourceId
      );
      if (resourceIndex === 0 && direction === "up") return false;

      if (resourceIndex === helpResources.length - 1 && direction === "down")
        return false;
      return true;
    },
    [helpResources]
  );

  const state: ReachOrganizationsState = useMemo(
    () => ({
      helpResourcesIsLoading,
      addHelpResourceDialogOpen,
      createHelpResource: postCreateHelpResource,
      setAddHelpResourceDialogOpen: setAddHelpResourceDialogOpenCallback,
      fetchHelpResources: fetchHelpResourcesCallback,
      helpResources,
      selectedHelpResource: editHelpResource,
      setSelectedHelpResource: setEditHelpResource,
      refreshHelpResourcesTimestamp,
      refreshHelpResources: refreshHelpResourcesListCallback,
      fetchCompanyInviteCodes: fetchCompanyInviteCodesCallback,
      companyInviteCodes: inviteCodes,
      selectedInviteCodeId,
      setSelectedInviteCodeId: setSelectedInviteCodeIdCallback,
      deleteHelpResource: deleteHelpResourceCallback,
      isUploading,
      setIsUploading: handleSetIsUploading,
      updateSortOrder: updateSortOrderCallback,
      resourceCanUpdateSortOrder: resourceCanUpdateSortOrderCallback,
    }),
    [
      helpResourcesIsLoading,
      addHelpResourceDialogOpen,
      postCreateHelpResource,
      setAddHelpResourceDialogOpenCallback,
      fetchHelpResourcesCallback,
      helpResources,
      editHelpResource,
      setEditHelpResource,
      refreshHelpResourcesTimestamp,
      refreshHelpResourcesListCallback,
      fetchCompanyInviteCodesCallback,
      inviteCodes,
      selectedInviteCodeId,
      setSelectedInviteCodeIdCallback,
      deleteHelpResourceCallback,
      isUploading,
      handleSetIsUploading,
      updateSortOrderCallback,
      resourceCanUpdateSortOrderCallback,
    ]
  );

  return (
    <ReachHelpResourcesContext.Provider value={state}>
      {props.children}
    </ReachHelpResourcesContext.Provider>
  );
};
