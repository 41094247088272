import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { HeaderSetter } from "../../Common/HeaderSetter";
import {
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  HEADER_HOME_BACKGROUND,
} from "../../../Styles/HeadversityStyle";
import {
  Box,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HttpErrorCodes } from "../../../Types/HVTypes";
import UnauthorizedSvg from "./Assets/401-outlined.svg";
import NotFoundSvg from "./Assets/404-outlined.svg";

interface ErrorInfo {
  errorCode?: HttpErrorCodes;
}

export const ErrorPage = ({
  errorCode = HttpErrorCodes.NotFound,
}: ErrorInfo): JSX.Element => {
  const [showSvg, setShowSvg] = useState<string>("");
  const [pageTitle, setTitle] = useState<string>("");
  const [header, setHeader] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();
  const imageFilter = useToken("colors", ["IMAGE_FILTER"]);
  const [errorType, setErrorType] = useState<HttpErrorCodes>(errorCode);
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const errorPageData = {
    NotFound: {
      title: `${HVLocalizeStrings.PAGE_NOT_FOUND_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`,
      header: HVLocalizeStrings.SOLO_PAGE_NOT_FOUND,
      errorMessage: HVLocalizeStrings.SOLO_PAGE_NOT_FOUND_DESCRIPTION,
      svg: NotFoundSvg,
    },
    Unauthorized: {
      title: `${HVLocalizeStrings.UNAUTHORIZED} - ${HVLocalizeStrings.HEADVERSITY}`,
      header: HVLocalizeStrings.UNAUTHORIZED,
      errorMessage: HVLocalizeStrings.UNAUTHORIZED_DESCRIPTION,
      svg: UnauthorizedSvg,
    },
  };

  useEffect(() => {
    if (location?.pathname?.toLocaleLowerCase().indexOf("index.html") > -1) {
      navigate("/", { replace: true });
    }
    if (
      location?.pathname?.toLocaleLowerCase().includes("team") ||
      location?.pathname?.toLocaleLowerCase().includes("reach")
    ) {
      setErrorType(HttpErrorCodes.Unauthorized);
    }

    let errorInfo;
    switch (errorType) {
      case HttpErrorCodes.Unauthorized:
        errorInfo = errorPageData.Unauthorized;
        break;
      case HttpErrorCodes.NotFound:
        errorInfo = errorPageData.NotFound;
        break;
      default:
        errorInfo = errorPageData.NotFound;
        break;
    }

    setTitle(errorInfo.title);
    setHeader(errorInfo.header);
    setErrorMessage(errorInfo.errorMessage);
    setShowSvg(errorInfo.svg);
  }, [errorType, location, navigate, header]);

  return (
    <>
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {header && (
          <HeaderSetter
            title={header}
            description={""}
            backgroundImage={HEADER_HOME_BACKGROUND}
          />
        )}
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          bgImage={
            "url(https://cdn.headversity.com/app/resources/modal/modalBG_lg.png)"
          }
          bgPos={"0 0"}
          bgRepeat={"repeat-y"}
          bgSize={"cover"}
        >
          <Box w={"100px"} filter={imageFilter}>
            <img src={showSvg} alt="error-svg" />
          </Box>
          <Heading
            mt={"8"}
            fontSize={"3xl"}
            color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
            px={"3"}
          >
            {header}
          </Heading>
          <Text
            fontSize={isDesktop ? "lg" : "md"}
            color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
            mt={"12"}
            px={"3"}
          >
            {errorMessage}
          </Text>
        </Flex>
      </>
    </>
  );
};
