import { useContext } from "react";
import { Box, Flex, Tooltip, useBreakpointValue } from "@chakra-ui/react";
import {
  GlobalContext,
  IGlobalProvider,
  AppType,
} from "../../State/GlobalContext";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { EVENTS, track } from "../../Utils/Analytics";
import { HVTestId } from "../../Testing/dataTestIds";
import { isWebPlatform } from "../../Utils/mobileUtils";
import { ShellContext } from "../../State/ShellContext";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { PointsButton } from "../Solo/Shared/PointsButton";
import { GoalButton } from "../Solo/Shared/GoalButton";
import { StreakButton } from "../Solo/Shared/StreakButton";
import { getStreakInfo } from "../../Utils/SkillsUtil";

interface Props {
  setOpen: (isOpen: boolean) => void;
}
export default function TopRightButtons({ setOpen }: Props) {
  const {
    helpInfo,
    currentApp,
    getRedirectUrlFromServer,
    isReachAdminEnabled,
    selectedUserLanguage,
    selectedGoal,
    schedulerReminders,
    activityCompletionDates,
  } = useContext<IGlobalProvider>(GlobalContext);
  const { safeAreaInsets } = useContext(ShellContext);

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const isMd = useBreakpointValue({ base: false, md: true });

  const getBgImage = () => {
    if (currentApp === AppType.REACH && isReachAdminEnabled) {
      return "https://cdn.headversity.com/app/resources/other/HelpImage2.png";
    }
    const defaultIcon =
      "https://cdn.headversity.com/app/resources/other/HelpImage.png";
    return helpInfo?.buttonIcon ? (helpInfo.buttonIcon as string) : defaultIcon;
  };

  const handleGetHelpClick = () => {
    track(EVENTS.GetHelpButtonClicked);
    if (currentApp === AppType.REACH && isReachAdminEnabled) {
      const redirectUrl =
        process.env.REACT_APP_HUBSPOT_KNOWLEDGE_BASE_URL!.replace(
          "{0}",
          selectedUserLanguage
        );
      getRedirectUrlFromServer(
        redirectUrl,
        process.env.REACT_APP_HUBSPOT_DOMAIN!
      ).then((path) => {
        InAppBrowser.create(path, "_system");
      });
    } else {
      setOpen(true);
    }
  };

  const top = isWebPlatform() ? "16px" : `${safeAreaInsets.top + 2}px`;

  const streakInfo = getStreakInfo(activityCompletionDates, schedulerReminders);

  return (
    <>
      {/* GETHELP BUTTON */}
      <Tooltip
        label={
          currentApp === AppType.REACH && isReachAdminEnabled
            ? HVLocalizeStrings.GET_REACH_HELP_TEXT
            : (helpInfo?.buttonTitle as string)
            ? (helpInfo?.buttonTitle as string)
            : HVLocalizeStrings.GET_HELP_LABEL
        }
        isDisabled={!isDesktop && !isMd}
      >
        <Flex
          bgSize="cover"
          bgRepeat={"no-repeat"}
          bgImage={getBgImage()}
          as="button"
          h={"30px"}
          w={"30px"}
          onClick={handleGetHelpClick}
          borderRadius={"50%"}
          p={0}
          position={"fixed"}
          top={top}
          right={"5px"}
          mr={2}
          zIndex={999}
          filter={" drop-shadow(0 0 0.8rem #41bbfc)"}
          className="get-help-button"
          data-testid={HVTestId.GetHelpButton.getHelpButton}
          aria-label={HVLocalizeStrings.GET_HELP_LABEL}
        />
      </Tooltip>

      {currentApp === AppType.SOLO && selectedGoal && (
        <>
          <PointsButton top={top} />

          <Box pos="fixed" top={top} right="86px">
            <GoalButton goalToUse={selectedGoal} size="30px" />
          </Box>

          <Box pos="fixed" top={top} right="117px">
            <StreakButton streakInfo={streakInfo} />
          </Box>
        </>
      )}
    </>
  );
}
