import { Image, Text, Link } from "@chakra-ui/react";

import { useContext } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import { ShellContext } from "../../../State/ShellContext";
import { EVENTS, track } from "../../../Utils/Analytics";

interface ImmediateAssistanceProps {
  from: string;
}
export const ImmediateAssistance = ({ from }: ImmediateAssistanceProps) => {
  const { helpInfo } = useContext(GlobalContext);
  const { setGetHelpOpen } = useContext(ShellContext);

  return (
    <Text fontSize="16px" mt={4}>
      {HVLocalizeStrings.WELCOME_HEADZONE_UNWELL_HELP}
      <Image
        src={
          helpInfo?.buttonIcon
            ? (helpInfo?.buttonIcon as string)
            : "https://cdn.headversity.com/app/resources/other/HelpImage.png"
        }
        w="26px"
        mx="8px"
        mt="-4px"
        display="inline"
        filter={" drop-shadow(0 0 0.25rem #41bbfc)"}
        borderRadius={"50%"}
        onClick={() => {
          track(EVENTS.GetHelpMenuClicked, {
            HV_From: from,
          });
          setGetHelpOpen(true);
        }}
        cursor="pointer"
        alt=""
      />
      {HVLocalizeStrings.WELCOME_HEADZONE_UNWELL_BUTTON}
      <Link
        href="#"
        textDecoration={"underline"}
        onClick={(e) => {
          track(EVENTS.GetHelpMenuClicked, {
            HV_From: from,
          });
          setGetHelpOpen(true);
          e.preventDefault();
        }}
      >
        {HVLocalizeStrings.WELCOME_HEADZONE_UNWELL_VIEW}
      </Link>
      {HVLocalizeStrings.WELCOME_HEADZONE_UNWELL_PROVIDED}
    </Text>
  );
};
