import { Box, Center, SlideFade, Text, useToken } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { getListingCommaString } from "../../../Utils/StringUtils";
import { ResilienceSkill20ItemWithChart } from "../../Solo/Shared/ResilienceSkill20ItemWithChart";
import { WelcomeModalHeader } from "./WelcomeModalHeader";
import { WelcomeModalSubheader } from "./WelcomeModalSubheader";
import { getUserSkillBySkillId } from "../../../Utils/SkillsUtil";
import {
  Border_Radius,
  SlideFade_Offset,
} from "../../../Styles/HeadversityStyle";

interface StepConfirmSkillsProps {
  selectedSkillIds: number[];
  isPostWelcome?: boolean;
  useTheme?: boolean;
}

export const StepConfirmSkills = ({
  selectedSkillIds,
  isPostWelcome,
  useTheme,
}: StepConfirmSkillsProps) => {
  const { skills, userSkillStats } = useContext<IGlobalProvider>(GlobalContext);

  const [skillNames, setSkillNames] = useState<string | undefined>("");
  const [fourthTextColor] = useToken("colors", ["FOURTH_TEXT_COLOR"]);

  useEffect(() => {
    const names = selectedSkillIds
      .filter((skillId) => skills.some((x) => x.id === skillId))
      .map((skillId) => {
        return skills.find((x) => x.id === skillId)?.name;
      });

    const namesBolded = names.map(
      (x) => `<b style="color: ${fourthTextColor}">${x}</b>`
    );

    setSkillNames(
      getListingCommaString(
        namesBolded,
        HVLocalizeStrings.AND,
        6,
        HVLocalizeStrings.AND_MORE
      )
    );
  }, [selectedSkillIds, skills]);

  const skill = skills.find((x) => x.id === selectedSkillIds[0]);
  const userSkill = getUserSkillBySkillId(userSkillStats, selectedSkillIds[0]);

  return (
    <SlideFade in={true} offsetY={SlideFade_Offset}>
      <Box mb="75px">
        <WelcomeModalHeader useTheme={useTheme}>
          {selectedSkillIds.length > 0 ? (
            <>
              {userSkill && isPostWelcome
                ? HVLocalizeStrings.WELCOME_SKILLS_ALRIGHT_LETS_EXPLORE_RESCORE
                : HVLocalizeStrings.WELCOME_SKILLS_ALRIGHT_LETS_EXPLORE}{" "}
              <>
                <span
                  dangerouslySetInnerHTML={{ __html: skillNames ?? "" }}
                ></span>
                <span>.</span>
              </>
            </>
          ) : (
            <>
              {HVLocalizeStrings.WELCOME_SKILLS_STARTING}{" "}
              <b style={{ color: fourthTextColor }}>
                {HVLocalizeStrings.WELCOME_SKILLS_STARTING_POINT}
              </b>{" "}
              {HVLocalizeStrings.WELCOME_SKILLS_STARTING_JOURNEY}
            </>
          )}
        </WelcomeModalHeader>

        {skill && (
          <WelcomeModalSubheader useTheme={useTheme}>
            <Box
              my="20px"
              p={useTheme ? undefined : "18px"}
              bgColor={useTheme ? undefined : "#ffffff22"}
              borderRadius={Border_Radius}
              fontSize={"16px"}
            >
              <Text
                dangerouslySetInnerHTML={{
                  __html: skill.shieldName.toString(),
                }}
              />
            </Box>
          </WelcomeModalSubheader>
        )}

        {isPostWelcome && userSkill && (
          <WelcomeModalSubheader mt="30px" useTheme={useTheme}>
            <Text fontSize={"16px"}>
              {HVLocalizeStrings.WELCOME_SKILLS_SCORES_OVER_TIME}
            </Text>
          </WelcomeModalSubheader>
        )}

        {!userSkill && !isPostWelcome && (
          <WelcomeModalSubheader useTheme={useTheme}>
            {HVLocalizeStrings.WELCOME_SKILLS_QUICK_QUESTIONS}
          </WelcomeModalSubheader>
        )}

        {isPostWelcome && skill && userSkill && (
          <Center>
            <ResilienceSkill20ItemWithChart
              mt={"10px"}
              skill={skill}
              userSkill={userSkill}
              forceChart={true}
            />
          </Center>
        )}
      </Box>
    </SlideFade>
  );
};
