import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import {
  ErrorCodeType,
  InviteNewTeamUserDto,
  NewTeamUserDto,
  TeamDto,
  TeamUserDto,
} from "@headversity/contract";
import { CertsCompletedByTeamDto } from "@headversity/contract/Dto/CertsCompletedByTeamDto";

export const saveTeam = (token: string, teamDto: TeamDto) => {
  return AxiosClient.post("api/teams", teamDto, getConfig(token));
};

export const resendTeamUserActivationEmail = (
  token: string,
  teamId: number,
  userId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/teams/${teamId}/users/${userId}/resendActivationEmail`,
    {},
    getConfig(token)
  );
};

export const saveTeamUser = (
  token: string,
  newTeamUserDto: NewTeamUserDto
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post("api/teamUser", newTeamUserDto, getConfig(token));
};

export const inviteNewTeamUser = (
  token: string,
  teamId: number,
  newTeamUserDto: InviteNewTeamUserDto
): Promise<AxiosResponse<TeamUserDto>> => {
  return AxiosClient.post(
    `api/teams/${teamId}/invite`,
    newTeamUserDto,
    getConfig(token)
  );
};

export const deleteTeam = (
  id: number,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.delete(`api/teams/${id}`, getConfig(token));
};
export const deleteTeamUser = (
  teamId: number,
  userId: number,
  token: string
): Promise<AxiosResponse<ErrorCodeType | string>> => {
  return AxiosClient.delete(
    `api/teamUser/${teamId}/${userId}`,
    getConfig(token)
  );
};

export const getTeams = (token: string): Promise<AxiosResponse<TeamDto[]>> => {
  return AxiosClient.get("api/teams", getConfig(token));
};

export const getCertsCompletedByTeam = (
  teamId: number,
  token: string
): Promise<AxiosResponse<CertsCompletedByTeamDto>> => {
  return AxiosClient.get(`api/sport/team/${teamId}/insights`, getConfig(token));
};

export const joinTeam = (
  token: string,
  teamCode: string
): Promise<AxiosResponse<ErrorCodeType | TeamUserDto>> => {
  return AxiosClient.post(
    `api/teamUser/join/${teamCode}`,
    {},
    getConfig(token)
  );
};
