import {
  Button,
  Center,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  Border_Radius,
  Equity_Black,
  Equity_Blue,
  Equity_Light_Gray,
} from "../../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import dayjs from "dayjs";
import { useContext } from "react";
import { ReachUsersContext } from "../../../../State/Reach/ReachUsersContext";
import { IShellProvider, ShellContext } from "../../../../State/ShellContext";
import { ConfirmationButtons } from "../../../Common/ConfirmationDialog";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface CertificationsTabPanelProps {
  onButtonClick: () => void;
  userId: number;
}

const CertificationsTabPanel = (props: CertificationsTabPanelProps) => {
  const { onButtonClick, userId } = props;

  const {
    unAssignCertFromUserFromServer,
    userCertsStats,
    companyCerts,
    triggerDataRefresh,
  } = useContext(ReachUsersContext);
  const { confirmationDialogRef, showToast } =
    useContext<IShellProvider>(ShellContext);

  const headers: { id: number; header: string }[] = [
    { id: 1, header: HVLocalizeStrings.REACH_USERS_CERTIFICATION },
    { id: 2, header: HVLocalizeStrings.REACH_USERS_DATE_ASSIGNED },
    { id: 3, header: HVLocalizeStrings.REACH_USERS_DATE_STARTED },
    { id: 4, header: HVLocalizeStrings.REACH_USERS_DATE_COMPLETED },
    { id: 5, header: "" },
  ];

  return (
    <>
      <TableContainer
        borderRadius={Border_Radius}
        border={`1px solid ${Equity_Light_Gray}`}
        boxShadow={"lg"}
        mt={6}
      >
        <Table variant={"simple"} size="sm">
          <Thead>
            <Tr>
              {headers.map((row) => {
                return (
                  <Th key={row.id} py={4}>
                    <Text
                      fontSize={"xs"}
                      fontWeight={"semibold"}
                      color={Equity_Black}
                    >
                      {row.header}
                    </Text>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {userCertsStats.map((row) => {
              return (
                <Tr
                  key={row.certId}
                  data-testid={`${HVTestId.CertificationsTabPanel.certRow}${row.certId}`}
                >
                  <Td py={4}>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"semibold"}
                      color={Equity_Black}
                      whiteSpace={"break-spaces"}
                      data-testid={HVTestId.CertificationsTabPanel.certName}
                    >
                      {row.certName as string}
                    </Text>
                  </Td>
                  <Td py={4}>
                    <Text
                      fontSize={"xs"}
                      color={Equity_Blue}
                      data-testid={HVTestId.CertificationsTabPanel.certAssign}
                    >
                      {row.dateAssigned
                        ? dayjs
                            .utc(row.dateAssigned.toString())
                            .format(HVLocalizeStrings.DATE_FORMAT)
                        : ""}
                    </Text>
                  </Td>
                  <Td py={4}>
                    <Text
                      fontSize={"xs"}
                      color={Equity_Blue}
                      data-testid={HVTestId.CertificationsTabPanel.certStart}
                    >
                      {row.dateStarted
                        ? dayjs
                            .utc(row.dateStarted.toString())
                            .format(HVLocalizeStrings.DATE_FORMAT)
                        : ""}
                    </Text>
                  </Td>
                  <Td py={4}>
                    <Text
                      fontSize={"xs"}
                      color={Equity_Blue}
                      data-testid={HVTestId.CertificationsTabPanel.certComplete}
                    >
                      {row.dateCompleted
                        ? dayjs
                            .utc(row.dateCompleted.toString())
                            .format(HVLocalizeStrings.DATE_FORMAT)
                        : ""}
                    </Text>
                  </Td>
                  <Td py={4}>
                    {!row.dateStarted && !row.dateCompleted && (
                      <Button
                        className={"button-link"}
                        fontSize={"xs"}
                        color={Equity_Blue}
                        height={"auto"}
                        onClick={() => {
                          (confirmationDialogRef.current as any).confirmation(
                            HVLocalizeStrings.REACH_USERS_UN_ASSIGN_CERTIFICATION,
                            HVLocalizeStrings.REACH_USERS_UN_ASSIGN_CERT_CONFIRMATION_MESSAGE,
                            ConfirmationButtons.YesNo,
                            () => {
                              unAssignCertFromUserFromServer(
                                userId,
                                row.certId
                              ).then(() => {
                                triggerDataRefresh();
                                showToast(
                                  true,
                                  "self-serve-cert-unassigned-success",
                                  HVLocalizeStrings.REACH_USERS_UN_ASSIGN_CERT_SUCCESS
                                );
                              });
                            }
                          );
                        }}
                        data-testid={
                          HVTestId.CertificationsTabPanel.removeButton
                        }
                      >
                        {HVLocalizeStrings.REACH_USERS_UN_ASSIGN}
                      </Button>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Center mt={12} mb={2}>
        <AnimatedButton
          text={HVLocalizeStrings.REACH_USERS_ASSIGN_A_CERTIFICATION}
          onClick={onButtonClick}
          colorSet={AnimatedButtonColorSet.Third}
          w={"auto"}
          disabled={
            companyCerts.length === 0 ||
            companyCerts.length === userCertsStats.length
          }
          dataTestId={HVTestId.CertificationsTabPanel.assignButton}
        />
      </Center>
    </>
  );
};

export default CertificationsTabPanel;
