import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  REACH_USERS_FILTER_BORDER,
} from "../../../Styles/HeadversityStyle";
import { HvSelect } from "../../Common/HvSelect";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";
import { InviteCodeForReachDto } from "../../../@headversity/contract";

interface ReachInviteCodeSelectorProps {
  setSelectedInviteCodeId: (id?: number) => void;
  selectedInviteCodeId?: number;
  inviteCodes: InviteCodeForReachDto[];
  hideLabel?: boolean;
  mt?: string;
  width?: string;
}

const ReachInviteCodeSelector = (props: ReachInviteCodeSelectorProps) => {
  const {
    inviteCodes,
    selectedInviteCodeId,
    setSelectedInviteCodeId,
    hideLabel,
    mt,
    width,
  } = props;

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const handleInviteCodeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedInviteCodeId(parseInt(e.target.value));
    if (!hideLabel) {
      track(REACH_EVENTS.ReachHelpResGroupSelected);
    }
  };
  return (
    <Flex
      alignItems={"center"}
      mt={mt ?? "4"}
      flexDirection={isMobile ? "column" : "row"}
      gap={isMobile ? 2 : 0}
    >
      {!hideLabel && (
        <Text color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}>
          {HVLocalizeStrings.REACH_HELP_RESOURCES_GROUP_CONFIGURATION}
        </Text>
      )}
      <HvSelect
        ml={isMobile || hideLabel ? 0 : "4"}
        h={"40px"}
        w={width ?? "fit-content"}
        maxW={isMobile ? "100%" : undefined}
        borderRadius={"5px"}
        fontSize={"13px"}
        value={selectedInviteCodeId}
        onChange={handleInviteCodeChange}
        border={`${REACH_USERS_FILTER_BORDER} !important`}
      >
        {hideLabel && (
          <option key={0} value={0}>
            {HVLocalizeStrings.SELECT_A_VALUE}
          </option>
        )}
        {inviteCodes.map((inviteCode) => (
          <option key={inviteCode.id} value={inviteCode.id}>
            {`${inviteCode.name} [${inviteCode.code}]`}
          </option>
        ))}
      </HvSelect>
    </Flex>
  );
};
export default ReachInviteCodeSelector;
