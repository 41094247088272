import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  ListItem,
  Text,
  UnorderedList,
  useBreakpointValue,
} from "@chakra-ui/react";
import { CompanyDto } from "@headversity/contract";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineStop } from "react-icons/ai";
import { FaCheckCircle, FaCheckSquare, FaRegSquare } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import {
  BG_REACH_BASE,
  Border_Radius,
  DESTRUCTIVE_TEXT_COLOR,
  Disabled_Bg,
  HEADER_REACH_HOME_BACKGROUND,
  Success,
} from "../../Styles/HeadversityStyle";
import { REACH_EVENTS, track } from "../../Utils/Analytics";
import { BlockSection } from "../Common/BlockSection";
import BlockSectionTitle from "../Common/BlockSectionTitle";
import { FadeAfterDelay } from "../Common/FadeAfterDelay";
import { HeaderSetter } from "../Common/HeaderSetter";
import ReachCopyToClipboard from "./Shared/ReachCopyToClipboard";

interface ReachCommunicationStatusProps {
  company: CompanyDto | null;
  hasBorder: boolean;
  p?: number;
}
export const ReachCommunicationStatus = (
  props: ReachCommunicationStatusProps
) => {
  return (
    <Box
      my={4}
      p={props.p === undefined ? 6 : props.p}
      bgColor={"white"}
      boxShadow={props.hasBorder ? "lg" : undefined}
      borderRadius={Border_Radius}
      border={props.hasBorder ? `1px solid ${Disabled_Bg}` : undefined}
    >
      {props.company?.communicationSettings ? (
        <ConfirmationMessage
          iconColor={Success}
          icon={FaCheckCircle}
          text={HVLocalizeStrings.REACH_ORGANIZATIONS_CONFIRMED_ALLOWED}
        />
      ) : (
        <ConfirmationMessage
          iconColor={DESTRUCTIVE_TEXT_COLOR}
          icon={AiOutlineStop}
          text={HVLocalizeStrings.REACH_ORGANIZATIONS_NOT_CONFIRMED_ALLOWED}
        />
      )}
    </Box>
  );
};
const ReachCommunicationSettingsPage = () => {
  const { company, getPageTitle } = useContext<IGlobalProvider>(GlobalContext);
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.REACH_ORGANIZATIONS_COMMUNICATION_SETTINGS} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.REACH_ORGANIZATIONS_COMMUNICATION_SETTINGS}
        description={
          HVLocalizeStrings.REACH_ORGANIZATIONS_COMMUNICATION_SETTINGS_DESCRIPTION
        }
        backgroundImage={HEADER_REACH_HOME_BACKGROUND}
        backgroundImageBase={BG_REACH_BASE}
      />

      <FadeAfterDelay>
        <Box
          maxW={isMainContainerXL ? "container.xl" : "container.lg"}
          mx={"auto"}
          mt={isDesktop ? "40px" : "20px"}
          mb={"20px"}
        >
          <BlockSection
            gridClassName={"communication-settings"}
            columns={1}
            fadeIn={true}
            title={
              <BlockSectionTitle
                title={
                  HVLocalizeStrings.REACH_ORGANIZATIONS_COMMUNICATION_SETTINGS
                }
              ></BlockSectionTitle>
            }
          >
            <ReachCommunicationStatus hasBorder={true} company={company} />
            <ListRequest />
          </BlockSection>
        </Box>
      </FadeAfterDelay>
    </>
  );
};

interface ConfirmationMessageProps {
  icon: IconType;
  iconColor: string;
  text: string;
}

const ConfirmationMessage = (props: ConfirmationMessageProps) => {
  const { text, icon, iconColor } = props;
  return (
    <Flex alignItems={"center"} gap={4}>
      <Icon as={icon} color={iconColor} boxSize={"24px"} />
      <Text>{text}</Text>
    </Flex>
  );
};

const ListRequest = () => {
  const EMAILS = [
    "info@headversity.com",
    "help@headversity.com",
    "noreply@headversity.com",
  ];
  const DOMAINS = [
    "*.headversity.com",
    "*.pusher.com",
    "fonts.gstatic.com",
    "*.vimeo.com",
    "*.vimeocdn.com",
    "*.onesignal.com",
  ];
  const { company, self, updateCommunicationSettingsAllowedDomainsToServer } =
    useContext<IGlobalProvider>(GlobalContext);
  const { showToast } = useContext<IShellProvider>(ShellContext);

  const copyToClipboard = async () => {
    const emailsAndDomains = [...EMAILS, ...DOMAINS];
    await navigator.clipboard.writeText(emailsAndDomains.join("\n"));
    showToast(
      true,
      "communication-settings-copied-to-clipboard",
      HVLocalizeStrings.COPIED_TO_CLIPBOARD
    );
    track(REACH_EVENTS.ReachCommSettingsCopiedInfo);
  };

  return (
    <Box
      bgColor={"white"}
      my={4}
      borderRadius={Border_Radius}
      boxShadow={"lg"}
      border={`1px solid ${Disabled_Bg}`}
    >
      <Box
        fontWeight={"semibold"}
        borderBottom={`1px solid ${Disabled_Bg}`}
        p={6}
      >
        {HVLocalizeStrings.REACH_ORGANIZATIONS_ALLOW_LIST_REQUEST}
      </Box>
      <Flex flexDirection={"column"} gap={6} w={"100%"} py={6} px={12}>
        <Text
          dangerouslySetInnerHTML={{
            __html: HVLocalizeStrings.REACH_ORGANIZATIONS_ALLOW_MESSAGE,
          }}
        ></Text>
        <Text textDecoration={"underline"} fontWeight={"semibold"}>
          {HVLocalizeStrings.REACH_ORGANIZATIONS_SENDER_EMAILS}
        </Text>
        <UnorderedList ps={3}>
          {EMAILS.map((email) => (
            <ListItem key={email}>{email}</ListItem>
          ))}
        </UnorderedList>
        <Text textDecoration={"underline"} fontWeight={"semibold"}>
          {HVLocalizeStrings.REACH_ORGANIZATIONS_DOMAINS}
        </Text>
        <UnorderedList ps={3}>
          {DOMAINS.map((domain) => (
            <ListItem key={domain}>{domain}</ListItem>
          ))}
        </UnorderedList>
        <Text
          dangerouslySetInnerHTML={{
            __html: HVLocalizeStrings.REACH_ORGANIZATIONS_QUESTIONS,
          }}
        />
        <ReachCopyToClipboard copyToClipboard={copyToClipboard} />
        <Center>
          <Button
            className={"button-link"}
            onClick={() => {
              updateCommunicationSettingsAllowedDomainsToServer(
                self,
                !company?.communicationSettings
              );
              track(REACH_EVENTS.ReachCommSettingsClickedConfirm);
            }}
            textDecoration={"none"}
          >
            <Flex alignItems={"center"} gap={2}>
              <>
                {company?.communicationSettings ? (
                  <Icon as={FaCheckSquare} boxSize={"24px"} color={Success} />
                ) : (
                  <Icon as={FaRegSquare} boxSize={"24px"} />
                )}
                <Text
                  color={company?.communicationSettings ? Success : undefined}
                  whiteSpace={"break-spaces"}
                  textAlign={"left"}
                >
                  {HVLocalizeStrings.REACH_ORGANIZATIONS_INFORMATION_ALLOWED}
                </Text>
              </>
            </Flex>
          </Button>
        </Center>
      </Flex>
    </Box>
  );
};

export default ReachCommunicationSettingsPage;
