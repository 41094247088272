import { useCallback, useState } from "react";
import {
  getPolls,
  setPollInstance,
  getPollInstanceResult,
  getPollInstances,
  setPollInstanceResult,
  updatePollUserResponse,
  updatePollUserResponses,
  createPollInstances,
  setPollInstancesResult,
  getPollInstancesResult,
} from "../../Api/Team/PollsApi";
import { getKey } from "../../Utils/Helpers";
import { retryWithDelay } from "../../Api/Utils";
import {
  PollQuestionDto,
  PollQuestionSessionInputDto,
  PollQuestionUserInstanceDto,
  QuestionUserResponseDto,
  ResponseInputDto,
} from "@headversity/contract";
import { Dictionary } from "highcharts";
import { AxiosResponse } from "axios";

export interface IPollProvider {
  pollQuestions: PollQuestionDto[];
  pollInstances: PollQuestionUserInstanceDto[];
  pollInstanceResults: Dictionary<QuestionUserResponseDto[] | undefined>;
  pollDataLoading: boolean;
  getPollsFromServer: (
    teamLessonId: number
  ) => Promise<AxiosResponse<PollQuestionDto[]>>;
  getPollInstanceResultFromServer: (
    pollInstanceId: number
  ) => Promise<AxiosResponse<QuestionUserResponseDto[]>>;
  getPollInstancesResultFromServer: (
    ids: number[]
  ) => Promise<AxiosResponse<PollQuestionUserInstanceDto[]>>;
  getPollInstancesFromServer: () => Promise<void>;
  setPollInstanceToServer: (
    pollQuestionId: number,
    teamLessonUserInstanceId: number
  ) => Promise<AxiosResponse<PollQuestionUserInstanceDto>>;
  createPollInstancesOnServer: (
    pollQuestions: PollQuestionDto[],
    teamLessonUserInstanceId: number
  ) => Promise<void>;
  setPollInstanceResultToServer: (
    pollInstanceId: number,
    pollQuestionSessionInputDto: PollQuestionSessionInputDto
  ) => Promise<AxiosResponse<void>>;
  setPollInstancesResultToServer: (
    pollQuestionSessionInputDtos: PollQuestionSessionInputDto[]
  ) => Promise<AxiosResponse<void>>;
  clearCurrentPollsAndSessions: () => void;
  setPollDataLoading: (pollDataLoading: boolean) => void;
  updatePollUserResponseToServer: (
    questionUserResponseId: number,
    responseInputDto: ResponseInputDto
  ) => Promise<AxiosResponse<void>>;
  updatePollUserResponsesToServer: (
    pollQuestionUserInstanceId: number,
    responseInputDtos: ResponseInputDto[]
  ) => Promise<AxiosResponse<void>>;
}

export const usePoll = (): IPollProvider => {
  const [pollQuestions, setPollQuestions] = useState<PollQuestionDto[]>([]);
  const [pollInstanceResults, setPollInstanceResults] = useState<
    Dictionary<QuestionUserResponseDto[] | undefined>
  >({});
  const [pollInstances, setPollInstances] = useState<
    PollQuestionUserInstanceDto[]
  >([]);
  const [pollDataLoading, setPollDataLoading] = useState<boolean>(false);

  const getPollsFromServer = useCallback(async (teamLessonId: number) => {
    setPollDataLoading(true);
    return getPolls(teamLessonId, getKey()).then((result) => {
      setPollDataLoading(false);
      setPollQuestions(result.data);
      return Promise.resolve(result);
    });
  }, []);

  const getPollInstanceResultFromServer = useCallback(
    async (pollInstanceId: number) => {
      setPollDataLoading(true);
      return getPollInstanceResult(pollInstanceId, getKey()).then((result) => {
        setPollDataLoading(false);
        pollInstanceResults[pollInstanceId] = result.data;
        setPollInstanceResults({ ...pollInstanceResults });
        return Promise.resolve(result);
      });
    },
    [pollInstanceResults]
  );

  const getPollInstancesResultFromServer = useCallback(
    async (ids: number[]) => {
      setPollDataLoading(true);
      return getPollInstancesResult(ids, getKey()).then((result) => {
        setPollDataLoading(false);
        if (result.data) {
          for (const pollInstance of result.data) {
            pollInstanceResults[pollInstance.id] =
              pollInstance.questionUserResponses;
          }
          setPollInstanceResults({ ...pollInstanceResults });
        }
        return Promise.resolve(result);
      });
    },
    [pollInstanceResults]
  );

  const setPollInstanceToServer = useCallback(
    async (pollQuestionId: number, teamLessonUserInstanceId: number) => {
      setPollDataLoading(true);
      return setPollInstance(
        pollQuestionId,
        teamLessonUserInstanceId,
        getKey()
      ).then((result) => {
        setPollDataLoading(false);
        pollInstanceResults[result.data.id] = result.data.questionUserResponses;
        setPollInstanceResults({ ...pollInstanceResults });
        return Promise.resolve(result);
      });
    },
    []
  );

  const createPollInstancesOnServer = useCallback(
    async (
      pollQuestions: PollQuestionDto[],
      teamLessonUserInstanceId: number
    ) => {
      setPollDataLoading(true);
      await createPollInstances(
        pollQuestions,
        teamLessonUserInstanceId,
        getKey()
      );
      setPollDataLoading(false);
    },
    []
  );

  // Note this is meant to be void, due to it being misused for people to check on stuff.
  // This function is not meant to be used for validation for pollInstance existence. If you
  // want to check if a pollInstance exist.  Create a backend function and for it.
  const getPollInstancesFromServer = useCallback(async (): Promise<void> => {
    setPollDataLoading(true);
    return retryWithDelay(
      () => {
        return getPollInstances(getKey()).then((result) => {
          setPollDataLoading(false);
          if (result && result.data) {
            setPollInstances(result.data);
          }
        });
      },
      20,
      500,
      (errorMessage: string | null) => {
        return errorMessage === "Network Error";
      }
    );
  }, []);

  const setPollInstanceResultToServer = useCallback(
    async (
      pollInstanceId: number,
      pollQuestionSessionInputDto: PollQuestionSessionInputDto
    ) => {
      setPollDataLoading(true);
      return setPollInstanceResult(
        pollInstanceId,
        pollQuestionSessionInputDto,
        getKey()
      ).then((result) => {
        setPollDataLoading(false);
        return Promise.resolve(result);
      });
    },
    []
  );

  const setPollInstancesResultToServer = useCallback(
    async (pollQuestionSessionInputDtos: PollQuestionSessionInputDto[]) => {
      setPollDataLoading(true);
      return setPollInstancesResult(
        pollQuestionSessionInputDtos,
        getKey()
      ).then((result) => {
        setPollDataLoading(false);
        getPollInstancesFromServer();
        return Promise.resolve(result);
      });
    },
    []
  );

  const updatePollUserResponseToServer = useCallback(
    async (
      questionUserResponseId: number,
      responseInputDto: ResponseInputDto
    ) => {
      setPollDataLoading(true);
      return updatePollUserResponse(
        questionUserResponseId,
        responseInputDto,
        getKey()
      ).then((result) => {
        setPollDataLoading(false);
        getPollInstancesFromServer();
        return Promise.resolve(result);
      });
    },
    []
  );

  const updatePollUserResponsesToServer = useCallback(
    async (
      pollQuestionUserInstanceId: number,
      responseInputDtos: ResponseInputDto[]
    ) => {
      setPollDataLoading(true);
      return updatePollUserResponses(
        pollQuestionUserInstanceId,
        responseInputDtos,
        getKey()
      ).then((result) => {
        setPollDataLoading(false);
        getPollInstancesFromServer();
        return Promise.resolve(result);
      });
    },
    []
  );

  const clearCurrentPollsAndSessions = useCallback(() => {
    setPollQuestions([]);
    setPollInstanceResults({});
  }, []);

  return {
    pollQuestions,
    pollInstances,
    pollInstanceResults,
    getPollsFromServer,
    getPollInstanceResultFromServer,
    getPollInstancesResultFromServer,
    getPollInstancesFromServer,
    setPollInstanceToServer,
    createPollInstancesOnServer,
    setPollInstanceResultToServer,
    setPollInstancesResultToServer,
    clearCurrentPollsAndSessions,
    pollDataLoading,
    setPollDataLoading,
    updatePollUserResponseToServer,
    updatePollUserResponsesToServer,
  };
};
