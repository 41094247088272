import { useContext, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { ReachInsightsContext } from "../../../State/Reach/ReachInsightsContext";

export const useNodesFilterSearchParams = () => {
  const { hierarchyNodes, nodesFilter, setNodesFilter } =
    useContext(ReachInsightsContext);

  const location = useLocation();
  const currentPath = location.pathname;

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (hierarchyNodes && hierarchyNodes.length > 0) {
      const paramsNodesFilter = searchParams.get("nodesFilter");
      if (paramsNodesFilter === null) {
        // initial navigation, apply no filters
        setNodesFilter([]);
      } else if (paramsNodesFilter) {
        const filtered = hierarchyNodes.filter((node) =>
          paramsNodesFilter.includes(node.id.toString())
        );
        if (paramsNodesFilter.includes("-1")) {
          // special case, -1 means unassociated
          filtered.push({ id: -1, name: "Unassociated" });
        }
        setNodesFilter(filtered);
      }
    }
  }, [hierarchyNodes]);

  useEffect(() => {
    if (hierarchyNodes.length > 0) {
      // ensure we are in the /insights page
      if (currentPath.includes("/insights")) {
        let queryParams = new URLSearchParams();
        queryParams.append(
          "nodesFilter",
          nodesFilter.map((node) => node.id).join(",")
        );
        setSearchParams(queryParams);
      }
    }
  }, [hierarchyNodes, nodesFilter]);
};
