import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { Flex, Text } from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { CharterTimer } from "../Shared/CharterTimer";
import { PresentationContent } from "../Shared/PresentationContent";
import { getLatestPollInstanceByPollQuestionId } from "../../../../Utils/TeamUtil";
import { usePollQuestionInformation } from "../../../../Hooks/Sport/usePollQuestionInformation";
import { HVTestId } from "../../../../Testing/dataTestIds";
import {
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { CharterActivity } from "@headversity/contract";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { PresentationContainer } from "../Shared/PresentationContainer";
import { useBestWorstInitQuestion } from "../../../../Hooks/Sport/useBestWorstInitQuestion";

interface PresentationBestWorstStepOneProps {
  setActivity: (activity: CharterActivity | undefined) => void;
}

export const PresentationBestWorstStepOne = (
  props: PresentationBestWorstStepOneProps
) => {
  const { setActivity } = props;
  const {
    pollInstances,
    createPollInstancesOnServer,
    currentTeamLessonUserInstanceId,
    setTeamLessonInProgressStepToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [instanceCreated, setInstanceCreated] = useState<boolean>(false);
  const { bestPollQuestion, worstPollQuestion } = useBestWorstInitQuestion({
    bestPollQuestionInternalTitle: "CH_2_PQ",
    worstPollQuestionInternalTitle: "CH_3_PQ",
  });

  const {
    count: bestCount,
    total,
    setDisableSubmit: setBestDisableSubmit,
    disableSubmit: bestDisableSubmit,
  } = usePollQuestionInformation({
    pollQuestion: bestPollQuestion,
    byGroup: false,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });
  const {
    count: worstCount,
    setDisableSubmit: setWorstDisableSubmit,
    disableSubmit: worstDisableSubmit,
  } = usePollQuestionInformation({
    pollQuestion: worstPollQuestion,
    byGroup: false,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  useEffect(() => {
    if (
      bestPollQuestion &&
      worstPollQuestion &&
      currentTeamLessonUserInstanceId &&
      pollInstances &&
      !instanceCreated
    ) {
      setInstanceCreated(true);
      const bestPollQuestionInstance = getLatestPollInstanceByPollQuestionId(
        bestPollQuestion.id,
        pollInstances,
        currentTeamLessonUserInstanceId
      );

      if (bestPollQuestionInstance) {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.BestWorstStepOne
        );
        return;
      }

      const worstPollQuestionInstance = getLatestPollInstanceByPollQuestionId(
        worstPollQuestion.id,
        pollInstances,
        currentTeamLessonUserInstanceId
      );

      if (worstPollQuestionInstance) {
        return;
      }

      createPollInstancesOnServer(
        [
          { id: bestPollQuestion.id } as any,
          { id: worstPollQuestion.id } as any,
        ],
        currentTeamLessonUserInstanceId
      ).then(() => {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.BestWorstStepOne
        );
      });
    }
  }, [
    bestPollQuestion,
    worstPollQuestion,
    currentTeamLessonUserInstanceId,
    pollInstances,
    instanceCreated,
  ]);

  return (
    <PresentationContainer>
      <PresentationContent
        title={HVSportLocalizeStrings.CHARTER_INDIVIDUAL_ACTIVITY}
        description={HVSportLocalizeStrings.CHARTER_BEST_WORST_DESCRIPTION}
        listItems={[
          {
            icon: "https://cdn.headversity.com/app/sport/individual-devices.svg",
            text: HVSportLocalizeStrings.CHARTER_EVERYONE_WILL_PARTICIPATE,
          },
          {
            icon: 3,
            text: HVSportLocalizeStrings.CHARTER_BEST_WORST_ANSWER_INSTRUCTION,
          },
          {
            icon: "https://cdn.headversity.com/app/sport/discussion.svg",
            text: HVSportLocalizeStrings.CHARTER_TAKE_SOME_TIME_TO_DISCUSS,
          },
        ]}
        hrBorder={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
      >
        {bestPollQuestion && (
          <CharterTimer
            pollQuestion={bestPollQuestion}
            setDisableSubmit={(val) => {
              setBestDisableSubmit(false);
              setWorstDisableSubmit(false);
            }}
          />
        )}
      </PresentationContent>

      <Flex
        flexDir={"column"}
        align={"center"}
        data-testid={HVTestId.PollResult.totalVotes}
      >
        <Text color={PRIMARY_TEXT_COLOR} fontWeight={"semibold"}>
          {HVLocalizeStrings.SPORT_RESPONSES}
        </Text>
        <Text
          color={PRIMARY_TEXT_COLOR}
          fontWeight={"semibold"}
          fontSize={"xl"}
        >
          {Math.floor((worstCount + bestCount) / 2)} / {total}
        </Text>
      </Flex>

      <AnimatedButton
        disabled={
          bestDisableSubmit ||
          worstDisableSubmit ||
          Math.floor((worstCount + bestCount) / 2) < 1 ||
          loading
        }
        colorSet={AnimatedButtonColorSet.Primary}
        w={150}
        text={HVLocalizeStrings.CONTINUE}
        onClick={async () => {
          if (currentTeamLessonUserInstanceId) {
            setLoading(true);
            await setTeamLessonInProgressStepToServer(
              currentTeamLessonUserInstanceId,
              ""
            );
            setActivity(undefined);
          }
        }}
      />
    </PresentationContainer>
  );
};
