import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Heading,
  useBreakpointValue,
  Text,
  Image,
  Button,
  Tooltip,
  useToken,
  Flex,
  Center,
  Divider,
} from "@chakra-ui/react";
import {
  IShellProvider,
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../../State/ShellContext";
import {
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  Border_Radius,
  Burnt_Orange,
  Glow_Shadow_Filter,
} from "../../../Styles/HeadversityStyle";
import {
  DonutSize,
  getWidthFromDonutSize,
  ResilienceSkill20Donut,
} from "./ResilienceSkill20Donut";
import { GlobalContext } from "../../../State/GlobalContext";
import { HVTestId } from "../../../Testing/dataTestIds";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { SkillDto, UserSkillV2Dto } from "@headversity/contract";
import { SystemProps } from "@chakra-ui/system";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import dayjs from "dayjs";
import { getDaysSinceOrDate } from "../../../Utils/TimeUtils";
import {
  getScoreFromHeadscanPercentage,
  getSkillDarkModeBgColor,
} from "../../../Utils/SkillsUtil";
import { buildDateTimeLabelFormats } from "../../../Utils/ChartsUtil";

interface ResilienceSkill20ItemProps {
  skill?: SkillDto;
  userSkill?: UserSkillV2Dto;
  onClick?: (val?: boolean) => void;
  showDescription?: boolean;
  showDescriptionSmall?: boolean;
  isSelected?: boolean;
  noUnlockedTooltip?: boolean;
  reduceOpacityForLocked?: boolean;
  donutMarginTop?: string;
  fixedDescription?: boolean;
  customDescription?: JSX.Element | string;
  additionalContent?: JSX.Element;
  textAlign?: SystemProps["textAlign"];
  hideSkillImage?: boolean;
  hideLabel?: boolean;
  children?: ReactNode;
  showChart?: boolean;
  forceDonutAnimation?: boolean;
  isHighlighted?: boolean;
  donutSize?: DonutSize;
  hideDescriptionInFooter?: boolean;
  hideDonut?: boolean;
  highlightColor?: string;
  showEmptyChartExplanation?: boolean;
  fullDescription?: boolean;
  isSmallChart?: boolean;
}
export const ResilienceSkill20Item = (props: ResilienceSkill20ItemProps) => {
  const {
    skill,
    userSkill,
    onClick,
    showDescription,
    showDescriptionSmall,
    reduceOpacityForLocked,
    textAlign,
    hideSkillImage,
    showChart,
    children,
    forceDonutAnimation,
    donutSize = DonutSize.Small,
    isSelected,
    isHighlighted,
    hideDescriptionInFooter,
    hideDonut,
    highlightColor,
    showEmptyChartExplanation,
    fullDescription,
    isSmallChart,
  } = props;
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const imageFilter = useToken("colors", ["IMAGE_FILTER"]);

  const { selectedGoal } = useContext(GlobalContext);

  const { theme, isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  const [lineChartOptions, setLineChartOptions] = useState<any>({});
  const [lastActivity, setLastActivity] = useState<string>("");

  useEffect(() => {
    if (!userSkill) return;

    setWasClicked(false);

    if (!userSkill?.lastActivityDate) {
      setLastActivity("");
    } else {
      setLastActivity(getDaysSinceOrDate(dayjs(userSkill?.lastActivityDate)));
    }
  }, [userSkill?.lastActivityDate]);

  const [isInView, setIsInView] = useState(false);

  const [wasClicked, setWasClicked] = useState(false);

  /* observe element to start animation when it comes into view */
  const buttonRef = useRef(null);

  const observeButton = () => {
    if (!buttonRef.current) {
      // try again in a bit
      setTimeout(() => {
        observeButton();
      }, 250);

      return;
    }

    const observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting) {
          setIsInView(true);

          if (buttonRef.current) {
            observer.unobserve(buttonRef.current);
          }
        }
      },
      { threshold: [1] }
    );

    observer.observe(buttonRef.current);
  };

  const getStatsBoxHeight = (
    showChart: boolean | undefined,
    isDesktop: boolean | undefined,
    showDescription: boolean | undefined
  ): string => {
    let heightValue = "100%";
    if (showDescription && isDesktop && !showChart) {
      heightValue = "250px";
    } else if (showChart && isDesktop && !hideDonut) {
      heightValue = "270px";
    }
    return heightValue;
  };

  const getStatsBoxWidth = (
    showChart: boolean | undefined,
    isMainContainerXl: boolean | undefined
  ): string | undefined => {
    let widthValue: string | undefined = undefined;
    if (isMainContainerXl && showChart && !hideDonut) {
      widthValue = "845px";
    }
    return widthValue;
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      observeButton();
    }, 250);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (forceDonutAnimation) {
      setIsInView(true);
    }
  }, [forceDonutAnimation]);

  const axisFontColor = useToken("colors", ["CHART_AXIS_COLOR"]);

  useEffect(() => {
    const headscanHistory = userSkill?.headscanHistory ?? [];

    /*
    * to simulate data -- RG todo: does this still work?
    *
    const history = [
      {
        chartedData: {
          "2022-09-05T00:00:00": Math.floor(Math.random() * 6),
          "2022-09-07T00:00:00": Math.floor(Math.random() * 6),
          "2022-09-08T00:00:00": Math.floor(Math.random() * 6),
          "2022-09-13T00:00:00": Math.floor(Math.random() * 6),
          "2022-09-15T00:00:00": Math.floor(Math.random() * 6),
          "2022-09-16T00:00:00": Math.floor(Math.random() * 6),
          "2022-10-08T00:00:00": Math.floor(Math.random() * 6),
          "2022-10-14T00:00:00": Math.floor(Math.random() * 6),
          "2022-10-31T00:00:00": Math.floor(Math.random() * 6),
          "2022-11-01T00:00:00": Math.floor(Math.random() * 6),
          "2022-11-02T00:00:00": Math.floor(Math.random() * 6),
          "2022-11-04T00:00:00": Math.floor(Math.random() * 6),
          "2022-11-11T00:00:00": Math.floor(Math.random() * 6),
        },
      },
      {
        chartedData: {
          "2022-11-05T00:00:00": Math.floor(Math.random() * 6),
          "2022-11-07T00:00:00": Math.floor(Math.random() * 6),
          "2022-11-16T00:00:00": Math.floor(Math.random() * 6),
          "2022-11-17T00:00:00": Math.floor(Math.random() * 6),
          "2022-12-05T00:00:00": Math.floor(Math.random() * 6),
          "2022-12-14T00:00:00": Math.floor(Math.random() * 6),
          "2023-01-31T00:00:00": Math.floor(Math.random() * 6),
        },
      },
    ];

    const obj = history.sort(() => 0.5 - Math.random()).slice(0, 1)[0];
    */

    const adjustedDateHeadscanHistory = headscanHistory.map((dataPoint) => [
      dayjs.utc(dataPoint.date).local().unix() * 1000,
      dataPoint.headscanPercentage,
    ]);

    if (adjustedDateHeadscanHistory.length === 0) {
      setLineChartOptions(undefined);
    } else {
      setLineChartOptions({
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter: function (e: any) {
            return `${dayjs
              .utc((this as any).x)
              .format(HVLocalizeStrings.DATE_FORMAT)}`;
          },
        },
        chart: {
          type: "spline",
          height: isSmallChart ? 125 : 150,
          // auto-width does not leave enough right padding for the right-most dot to show...
          //  note: these magic numbers are based on the amount of space the donut takes up
          width: isMainContainerXL
            ? showEmptyChartExplanation
              ? 350
              : 420
            : window.innerWidth >= 992
            ? Math.min(370, 280 + (window.innerWidth - 992))
            : window.innerWidth >= 767
            ? Math.max(220, 140 + (window.innerWidth - 767))
            : window.innerWidth - 60,
          animation: false,
          backgroundColor: "transparent",
          style: {
            fontFamily: "Barlow",
          },
        },
        title: {
          text: "",
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: buildDateTimeLabelFormats(),
          labels: {
            style: {
              color: axisFontColor,
              fontSize: 9,
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          labels: {
            formatter: function (e: any) {
              if (e.value === 100)
                return `<span fill="${axisFontColor}">${HVLocalizeStrings.HIGH}</span>`;
              if (e.value === 50)
                return `<span fill="${axisFontColor}">${HVLocalizeStrings.MEDIUM}</span>`;
              if (e.value === 0)
                return `<span fill="${axisFontColor}">${HVLocalizeStrings.LOW}</span>`;
            },
          },
          tickInterval: 50,
          min: 0,
          max: 100,
        },
        series: [
          {
            name: skill?.name,
            data: adjustedDateHeadscanHistory,
          },
        ],
        plotOptions: {
          series: {
            borderWidth: 0,
            color: Burnt_Orange,
          },
        },
      });
    }
  }, [skill, userSkill, window.innerWidth]);

  return !skill ? (
    <></>
  ) : (
    <Button
      className={`button-block ${
        !onClick
          ? "no-hover no-shadow"
          : isHighlighted && isDesktop
          ? "no-shadow"
          : isHighlighted
          ? "no-border"
          : ""
      }`}
      onClick={onClick ? () => onClick() : undefined}
      textAlign="left"
      tabIndex={!onClick ? -1 : undefined}
      data-testid={
        !userSkill
          ? `${HVTestId.ResilienceSkill20Item.lockedSkill}_${skill.id}`
          : `${HVTestId.ResilienceSkill20Item.skillCard}_${userSkill.skillId}`
      }
      ref={buttonRef}
    >
      <Box
        h={getStatsBoxHeight(showChart, isDesktop, showDescription)}
        w={getStatsBoxWidth(showChart, isMainContainerXL)}
        border={`1px solid ${highlightColor ? highlightColor : skill.color}${
          highlightColor ? "cc" : "66"
        }`}
        borderBottom={isSelected && isDesktop ? "none" : undefined}
        borderRadius={Border_Radius}
        borderBottomRadius={isSelected && isDesktop ? "none" : undefined}
        minH={showDescription ? "240px" : undefined}
        position={showDescription ? "relative" : undefined}
        bg={
          theme.name === NOSTALGIC_THEME_NAME
            ? getSkillDarkModeBgColor(skill)
            : "white"
        }
        opacity={
          reduceOpacityForLocked &&
          !userSkill &&
          !selectedGoal?.skillIds.includes(skill.id)
            ? 0.6
            : undefined
        }
      >
        <Box>
          <Box
            bg={`${skill.color}${isHighlighted ? "5F" : "55"}`}
            p={2}
            px={showDescription ? 3 : 2}
            borderBottom="solid 1px #FFFFFF66"
            borderTopRadius={"4px"}
          >
            <Flex gap={"10px"} alignItems="center">
              <Box w="100%">
                <Heading
                  fontWeight={600}
                  color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
                  fontSize={
                    showDescription ? "lg" : isHighlighted ? "13px" : "14px"
                  }
                  data-testid={HVTestId.ResilienceSkill20Item.title}
                  textAlign={textAlign}
                  as="div"
                >
                  {skill.name as string}
                </Heading>

                <Text
                  fontWeight={400}
                  color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
                  fontSize={showDescription ? "14px" : "12px"}
                  fontStyle="italic"
                  align={textAlign}
                >
                  {`${skill.tagLine.toString()}${
                    fullDescription
                      ? `${HVLocalizeStrings.COLON} ${skill.description
                          .toString()
                          .toLowerCase()}`
                      : ""
                  }`}
                </Text>
              </Box>
              {!hideSkillImage && (
                <Image
                  h={showDescriptionSmall ? "13px" : "22px"}
                  src={skill.imageUrl as string}
                  filter={imageFilter}
                  alt=""
                />
              )}
            </Flex>
          </Box>
          {!showChart ? (
            <MainContent
              resilienceSkill20ItemProps={props}
              lastActivity={lastActivity}
              isInView={isInView}
              setWasClicked={setWasClicked}
              wasClicked={wasClicked}
            />
          ) : (
            <Flex
              flexDirection={!isDesktop ? "column" : undefined}
              alignItems={isSmallChart ? "center" : undefined}
              justifyContent={isSmallChart ? "center" : undefined}
            >
              {!hideDonut && (
                <Box
                  minH={
                    !isDesktop && !hideDescriptionInFooter ? "300px" : undefined
                  }
                >
                  <MainContent
                    resilienceSkill20ItemProps={props}
                    lastActivity={lastActivity}
                    isInView={isInView}
                    setWasClicked={setWasClicked}
                    wasClicked={wasClicked}
                    isMainContainerXL={isMainContainerXL}
                  />
                </Box>
              )}

              {showChart && (
                <>
                  {!hideDonut && !isDesktop && (
                    <Box my={2} mx={"20px"}>
                      <Divider />
                    </Box>
                  )}

                  <Box
                    mt={6}
                    px={isDesktop ? 6 : 0}
                    py={0}
                    mr={!isDesktop ? 2 : undefined}
                  >
                    <Box
                      data-testid={
                        HVTestId.ResilienceSkill20Panel.byTheNumbersContainer
                      }
                    >
                      {!hideDonut && (
                        <Box ml={6} mb={isDesktop ? 2 : 6} fontSize="14px">
                          <Text as="span" fontWeight={600}>
                            {skill.name as string}
                          </Text>
                          <Text as="span"> {HVLocalizeStrings.OVER_TIME}</Text>
                        </Box>
                      )}

                      {!lineChartOptions && (
                        <Tooltip
                          borderRadius={Border_Radius}
                          filter={Glow_Shadow_Filter}
                          hasArrow={true}
                          isDisabled={showEmptyChartExplanation}
                          label={
                            <Box p={2}>
                              <Text>
                                {HVLocalizeStrings.SEE_PROGRESS_OVER_TIME}
                              </Text>
                              {!showEmptyChartExplanation && (
                                <Text mt={4}>
                                  {HVLocalizeStrings.CLICK_TO}
                                  {userSkill
                                    ? HVLocalizeStrings.CLICK_TO_REDO_SCORE
                                    : HVLocalizeStrings.CLICK_TO_UNLOCK}
                                </Text>
                              )}
                            </Box>
                          }
                        >
                          {showEmptyChartExplanation ? (
                            <>
                              <Center>
                                <Image
                                  h={isDesktop ? "130px" : "150px"}
                                  src="https://cdn.headversity.com/app/resources/other/over-time-placeholder.png"
                                  alt=""
                                />
                              </Center>

                              <Text
                                fontSize={"11px"}
                                color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
                                py={4}
                                px={isDesktop ? undefined : 4}
                              >
                                {HVLocalizeStrings.SEE_PROGRESS_OVER_TIME}
                              </Text>
                            </>
                          ) : (
                            <Box mt={"-20px"} h="230px !important" ml={4}>
                              <Image
                                w={isDesktop ? "350px" : undefined}
                                h={isDesktop ? "160px" : "175px"}
                                src="https://cdn.headversity.com/app/resources/other/over-time-placeholder.png"
                                alt=""
                              />
                            </Box>
                          )}
                        </Tooltip>
                      )}
                      {lineChartOptions && (
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          mt={hideDonut ? "10px" : "15px"}
                          mb={hideDonut ? "15px" : ""}
                        >
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={lineChartOptions}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </>
              )}
            </Flex>
          )}

          {!showChart && (
            <Box
              p={showDescription ? 3 : 2}
              pt={donutSize === DonutSize.Small ? 3 : 1}
              mt={children ? "35px" : undefined}
            >
              {!hideDescriptionInFooter && (
                <DescriptionText resilienceSkill20ItemProps={props} />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Button>
  );
};

interface DescriptionProps {
  resilienceSkill20ItemProps: ResilienceSkill20ItemProps;
  mx?: string;
}
const DescriptionText = ({
  resilienceSkill20ItemProps,
  mx,
}: DescriptionProps) => {
  const {
    showDescription,
    showDescriptionSmall,
    customDescription,
    textAlign,
    fixedDescription,
    showChart,
    skill,
  } = resilienceSkill20ItemProps;

  const getDescriptionMarginTop = (
    showDescription: boolean | undefined,
    showChart: boolean | undefined
  ): string | undefined => {
    let heightValue: string | undefined = "10px";
    if (showDescription && !showChart) {
      heightValue = undefined;
    } else if (showChart) {
      heightValue = "-50px";
    }
    return heightValue;
  };

  return (
    <Box
      color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
      fontSize={showDescription ? "xs" : "11px"}
      lineHeight={showDescription ? "1rem" : "14px"}
      textAlign={textAlign}
      fontStyle="italic"
      pos={
        (showDescription || fixedDescription) && !showChart
          ? "absolute"
          : undefined
      }
      bottom={
        (showDescription || fixedDescription) && !showChart ? "12px" : undefined
      }
      mx={mx ?? (showDescription ? 4 : showDescriptionSmall ? 1 : undefined)}
      mt={getDescriptionMarginTop(showDescription, showChart)}
    >
      {customDescription ?? (skill?.description as string)}
    </Box>
  );
};

interface MainContentProps extends DescriptionProps {
  lastActivity: string;
  isInView: boolean;
  setWasClicked: (val: boolean) => void;
  wasClicked?: boolean;
  isMainContainerXL?: boolean;
}
const MainContent = ({
  resilienceSkill20ItemProps,
  isInView,
  isMainContainerXL,
}: MainContentProps) => {
  const {
    showDescription,
    additionalContent,
    donutSize,
    showChart,
    skill,
    userSkill,
    donutMarginTop,
    isHighlighted,
    children,
    hideDescriptionInFooter,
    hideDonut,
  } = resilienceSkill20ItemProps;
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  let description = "";
  if (userSkill) {
    const score = getScoreFromHeadscanPercentage(userSkill.headscanPercentage);
    const roundedScore = Math.floor(score);
    if (roundedScore <= 33) {
      description = HVLocalizeStrings.LOW;
    } else if (roundedScore <= 67) {
      description = HVLocalizeStrings.MEDIUM.slice(0, 3);
    } else {
      description = HVLocalizeStrings.HIGH;
    }
    description = description.toUpperCase();
  }

  return (
    <>
      {skill && (
        <Box
          mt={donutMarginTop}
          h={
            showDescription
              ? "225px"
              : donutSize === DonutSize.Medium
              ? "70px"
              : "40px"
          }
          w={showChart && isMainContainerXL ? "325px" : undefined}
          p={showDescription ? 6 : isDesktop ? "4" : "3"}
          transition={"margin .2s ease-in-out"}
        >
          {showChart && !hideDonut && (
            <Box mb={isDesktop ? "20px" : 6} fontSize="14px">
              <Text as="span" fontWeight={600}>
                {skill.name as string}
              </Text>
              <Text as="span"> {HVLocalizeStrings.TODAY.toLowerCase()}</Text>
            </Box>
          )}

          <Box>
            <Box mt={showDescription || isHighlighted ? 1 : undefined}>
              {children === undefined && !hideDonut && (
                <ResilienceSkill20Donut
                  userSkill={userSkill as UserSkillV2Dto}
                  size={donutSize ?? DonutSize.Small}
                  isInView={isInView}
                  color={`url(#skillGradient${skill.id})`}
                  description={description}
                />
              )}
              {showChart && (
                <>
                  <Center mt="-50px">
                    {!hideDescriptionInFooter && (
                      <Box w={getWidthFromDonutSize(donutSize)}>
                        <DescriptionText
                          mx={"0px"}
                          resilienceSkill20ItemProps={
                            resilienceSkill20ItemProps
                          }
                        />
                      </Box>
                    )}
                  </Center>

                  <Center mt={2}>{additionalContent}</Center>
                </>
              )}
              {children !== undefined && <>{children}</>}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
