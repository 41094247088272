import {
  Box,
  Checkbox,
  SimpleGrid,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import { MicroLessonDto, NanoPracticeDto } from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, TrainingType } from "../../../State/GlobalContext";
import { Border_Radius } from "../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../Utils/Analytics";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import { HvSlideFade } from "../../Common/HvSlideFade";
import { ScrollPanel } from "../../Common/ScrollPanel";
import { ScrollPanelBlock } from "../../Common/ScrollPanelBlock";
import { LessonBlock20 } from "../../Solo/Lessons/LessonBlock20";
import { NanoPracticeBlock } from "../../Solo/NanoPractices/NanoPracticeBlock";
import { WelcomeModalHeader } from "./WelcomeModalHeader";
import { WelcomeModalSubheader } from "./WelcomeModalSubheader";
import { HVTestId } from "../../../Testing/dataTestIds";

interface StepStartTrainingProps {
  selectedTrainingType: TrainingType;
  setSelectedTrainingType: (selectedTrainingType: TrainingType) => void;
  selectedPractice: NanoPracticeDto | undefined;
  setSelectedPractice: (selectedPractice: NanoPracticeDto) => void;
  selectedLesson: MicroLessonDto | undefined;
  setSelectedLesson: (selectedLesson: MicroLessonDto) => void;
  isPostWelcome?: boolean;
}
export const StepStartTraining = ({
  selectedTrainingType,
  setSelectedTrainingType,
  selectedPractice,
  setSelectedPractice,
  selectedLesson,
  setSelectedLesson,
  isPostWelcome,
}: StepStartTrainingProps) => {
  const {
    selectedGoal,
    getNextSuggestedPractices,
    getNextSuggestedLessons,
    userSkillStats,
    nanoPracticeInstances,
  } = useContext(GlobalContext);

  const [suggestedPractices, setSuggestedPractices] =
    useState<NanoPracticeDto[]>();
  const [suggestedLessons, setSuggestedLessons] = useState<MicroLessonDto[]>();

  const onSelectedTrainingTypeChange = (
    type: TrainingType,
    activityId: number | undefined,
    activityName: string | null | undefined,
    fromSlider?: boolean
  ) => {
    const event = {
      HV_Type: type === TrainingType.Practice ? "Practice" : "Lesson",
    } as any;

    if (activityId) {
      event["HV_ActivityId"] = activityId;
    }

    if (activityName) {
      event["HV_ActivityName"] = activityName;
    }

    track(
      fromSlider
        ? EVENTS.StartTrainingActivityChanged
        : EVENTS.StartTrainingTypeChanged,
      event
    );

    setSelectedTrainingType(type);
  };

  useEffect(() => {
    // for a first-time user, we'll try to find an unlocked skill in the selected pursuit so they don't have to answer questions
    let handled = false;

    if (!hasDoneSomethingInGoal && selectedGoal) {
      const unlockedSkill = userSkillStats.find((x) =>
        selectedGoal.skillIds.includes(x.skillId)
      );

      if (unlockedSkill) {
        // find the first practice in this skill
        const p = getNextSuggestedPractices(true).filter((x) =>
          x.skillCompetencies.some((y) => y.skillId === unlockedSkill.skillId)
        );

        if (p.length > 0) {
          handled = true;

          setSelectedPractice(p[0]);

          setSuggestedPractices(p);
          setSuggestedLessons([]);
        }
      }
    }

    if (!handled) {
      const p = getNextSuggestedPractices(true).splice(0, 4);
      const l = getNextSuggestedLessons(true).splice(0, 4);

      setSelectedPractice(p[0]);
      setSelectedLesson(l[0]);

      setSuggestedPractices(p);
      setSuggestedLessons(l);
    }
  }, [userSkillStats, nanoPracticeInstances, selectedGoal]);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const [fourthTextColor] = useToken("colors", ["FOURTH_TEXT_COLOR"]);

  const hasDoneSomethingInGoal =
    selectedGoal &&
    userSkillStats.some(
      (x) => selectedGoal.skillIds.includes(x.skillId) && x.points > 0
    );

  const width = isDesktop ? "370px" : `${(window.innerWidth - 80) / 2}px`;
  const notSelectedOpacity = 0.6;

  return (
    <HvSlideFade fadeIn={true}>
      <Box
        mx={isDesktop ? "20px" : undefined}
        pt={!isWebPlatform() ? "40px" : undefined}
      >
        <WelcomeModalHeader useTheme={!selectedGoal}>
          <Text
            as="span"
            dangerouslySetInnerHTML={{
              __html: hasDoneSomethingInGoal
                ? HVLocalizeStrings.CONTINUE_TRAINING_FOR +
                  " " +
                  `<b style='color:${fourthTextColor}'>${selectedGoal?.name.toString()}</b>.`
                : HVLocalizeStrings.OK +
                  " " +
                  HVLocalizeStrings.START_TRAINING_FOR,
            }}
          />
        </WelcomeModalHeader>
        {isPostWelcome && hasDoneSomethingInGoal ? (
          <WelcomeModalSubheader useTheme={!selectedGoal}>
            {HVLocalizeStrings.DO_NEXT}

            <HvSlideFade fadeIn={true} delay={1}>
              <Box
                mt={"20px"}
                color={selectedGoal ? "#ffffff" : undefined}
                bgColor="#ffffff22"
                borderRadius={Border_Radius}
                p={isDesktop ? "30px" : "15px"}
                px={isDesktop ? "40px" : undefined}
                pb="5px"
              >
                <SimpleGrid
                  columns={2}
                  gap={!isDesktop ? "10px" : "50px"}
                  rowGap={"10px"}
                  fontSize={isDesktop ? "15px" : "14px"}
                  lineHeight={"1.2"}
                  mb={2}
                >
                  <Box
                    w="100%"
                    ml={"5px"}
                    opacity={
                      selectedTrainingType === TrainingType.Practice
                        ? 1
                        : notSelectedOpacity
                    }
                  >
                    <Checkbox
                      size={"sm"}
                      isChecked={selectedTrainingType === TrainingType.Practice}
                      onChange={() => {
                        onSelectedTrainingTypeChange(
                          TrainingType.Practice,
                          selectedPractice?.id,
                          selectedPractice?.name_loc?.en
                        );
                      }}
                      data-testid={HVTestId.StepStartTraining.practiceCheckbox}
                    >
                      <Text fontSize={isDesktop ? "15px" : "14px"}>
                        <b style={{ fontWeight: 600 }}>
                          {HVLocalizeStrings.NANO_PRACTICE}
                        </b>
                        {HVLocalizeStrings.COLON}{" "}
                        {HVLocalizeStrings.SIMPLE_PROVEN}
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box
                    w="100%"
                    ml={"5px"}
                    opacity={
                      selectedTrainingType === TrainingType.Lesson
                        ? 1
                        : notSelectedOpacity
                    }
                  >
                    <Checkbox
                      size={"sm"}
                      isChecked={selectedTrainingType === TrainingType.Lesson}
                      onChange={() => {
                        onSelectedTrainingTypeChange(
                          TrainingType.Lesson,
                          selectedLesson?.id,
                          selectedLesson?.name_loc?.en
                        );
                      }}
                      data-testid={HVTestId.StepStartTraining.lessonCheckbox}
                    >
                      <Text fontSize={isDesktop ? "15px" : "14px"}>
                        <b style={{ fontWeight: 600 }}>
                          {HVLocalizeStrings.LEARN}
                        </b>
                        {HVLocalizeStrings.COLON}{" "}
                        {HVLocalizeStrings.THE_SCIENCE}
                      </Text>
                    </Checkbox>
                  </Box>

                  <Box
                    w={width}
                    opacity={
                      selectedTrainingType === TrainingType.Practice
                        ? 1
                        : notSelectedOpacity
                    }
                  >
                    {suggestedPractices && (
                      <ScrollPanel
                        spaceBetween={8}
                        showChevronContainer={isDesktop}
                        slidesPerGroup={1}
                        slidesPerView={"auto"}
                        isPaginationOnContainer={true}
                        centeredSlides={true}
                        noChevronBg={true}
                        customBulletClass="white"
                        onSlideChangeCallBack={(idx) => {
                          onSelectedTrainingTypeChange(
                            TrainingType.Practice,
                            suggestedPractices[idx].id,
                            suggestedPractices[idx].name_loc?.en,
                            true
                          );

                          setSelectedPractice(suggestedPractices[idx]);
                        }}
                      >
                        {suggestedPractices?.map((item: any) => {
                          return ScrollPanelBlock(
                            <NanoPracticeBlock
                              practice={item}
                              isSmall={!isDesktop}
                              fullDescription={true}
                              showPoints={true}
                              from={"StartTraining"}
                              width={width}
                              height={!isDesktop ? "200px" : undefined}
                              descriptionColor="white"
                              isPortrait={!isDesktop}
                              onClickOverride={
                                selectedTrainingType === TrainingType.Lesson
                                  ? () => {
                                      onSelectedTrainingTypeChange(
                                        TrainingType.Practice,
                                        item?.id,
                                        item.name_loc?.en
                                      );

                                      setSelectedPractice(item);
                                    }
                                  : undefined
                              }
                            />,
                            width,
                            item?.id
                          );
                        })}
                      </ScrollPanel>
                    )}
                  </Box>
                  <Box
                    w={width}
                    opacity={
                      selectedTrainingType === TrainingType.Lesson
                        ? 1
                        : notSelectedOpacity
                    }
                  >
                    {suggestedLessons && (
                      <ScrollPanel
                        spaceBetween={8}
                        showChevronContainer={isDesktop}
                        slidesPerGroup={1}
                        slidesPerView={"auto"}
                        isPaginationOnContainer={true}
                        centeredSlides={true}
                        noChevronBg={true}
                        customBulletClass="white"
                        onSlideChangeCallBack={(idx) => {
                          onSelectedTrainingTypeChange(
                            TrainingType.Lesson,
                            suggestedLessons[idx].id,
                            suggestedLessons[idx].name_loc?.en,
                            true
                          );

                          setSelectedLesson(suggestedLessons[idx]);
                        }}
                      >
                        {suggestedLessons?.map((item: any) => {
                          return ScrollPanelBlock(
                            <LessonBlock20
                              lesson={item}
                              isSmall={!isDesktop}
                              fullDescription={true}
                              showPoints={true}
                              from={"StartTraining"}
                              width={width}
                              height={!isDesktop ? "200px" : undefined}
                              descriptionColor="white"
                              isPortrait={!isDesktop}
                              onClickOverride={
                                selectedTrainingType === TrainingType.Practice
                                  ? () => {
                                      onSelectedTrainingTypeChange(
                                        TrainingType.Lesson,
                                        item?.id,
                                        item.name_loc?.en
                                      );

                                      setSelectedLesson(item);
                                    }
                                  : undefined
                              }
                            />,
                            width,
                            item?.id
                          );
                        })}
                      </ScrollPanel>
                    )}
                  </Box>
                </SimpleGrid>
              </Box>
            </HvSlideFade>
          </WelcomeModalSubheader>
        ) : (
          <WelcomeModalSubheader useTheme={!selectedGoal}>
            <HvSlideFade fadeIn={true} delay={1}>
              <Box
                my="20px"
                p="18px"
                bgColor={"#ffffff22"}
                borderRadius={Border_Radius}
                fontSize={"16px"}
              >
                {HVLocalizeStrings.TRAINING_INTRO}
              </Box>

              <Text
                dangerouslySetInnerHTML={{
                  __html: HVLocalizeStrings.LETS_COMPLETE.replace(
                    "{0}",
                    `<b style='color:${fourthTextColor}'>${selectedGoal?.name.toString()}</b>`
                  ),
                }}
              />
            </HvSlideFade>
          </WelcomeModalSubheader>
        )}
      </Box>
    </HvSlideFade>
  );
};
