import { Flex, Tooltip } from "@chakra-ui/react";
import { RegistrationLoginStates } from "@headversity/contract";
import React, { useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HVTestId } from "../../../Testing/dataTestIds";
import FormInputField from "./FormInputField";
import PasswordRequirements from "./PasswordRequirements";

interface PasswordInputProps {
  showVerifyField?: boolean;
  passwordError: string | null;
  handleChange: (
    stateToUpdate: RegistrationLoginStates,
    stateText: string,
    isValid: boolean
  ) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  pwdHasNumber: boolean;
  pwdHasLowerCase: boolean;
  pwdHasUpperCase: boolean;
  pwdHasMinLength: boolean;
  pwdMatch?: boolean;
  isValidPassword?: boolean;
  hideToolTip?: boolean;
  border?: string;
  width?: string;
}
const PasswordInput = (props: PasswordInputProps) => {
  const [passwordHasFocus, setPasswordHasFocus] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <Tooltip
      hasArrow
      label={
        <>
          <PasswordRequirements
            pwdHasNumber={props.pwdHasNumber}
            pwdHasLowerCase={props.pwdHasLowerCase}
            pwdHasUpperCase={props.pwdHasUpperCase}
            pwdHasMinLength={props.pwdHasMinLength}
            pwdMatch={props.pwdMatch}
          />
        </>
      }
      placement="auto"
      isOpen={passwordHasFocus}
      hidden={props.hideToolTip}
    >
      <Flex flexDirection={"column"} gap={2}>
        <FormInputField
          id={"password"}
          imageSrc={
            "https://cdn.headversity.com/app/resources/login/padlock.png"
          }
          errorMessage={props.passwordError as any}
          imageW={"13px"}
          imageH={"18px"}
          border={props.border}
          placeholder={HVLocalizeStrings.YOUR_PASSWORD}
          handleChange={props.handleChange}
          onFocus={() => setPasswordHasFocus(true)}
          onBlur={() => setPasswordHasFocus(false)}
          stateToUpdate={RegistrationLoginStates.Password}
          type={"password"}
          onKeyDown={props.onKeyDown}
          setShowPassword={setShowPassword}
          showPassword={showPassword}
          width={props.width}
          dataTestId={HVTestId.RegistrationStep.passwordInput}
        />
        {props.showVerifyField && (
          <FormInputField
            id={"passwordConfirmation"}
            imageSrc={
              "https://cdn.headversity.com/app/resources/login/padlock.png"
            }
            imageW={"13px"}
            imageH={"18px"}
            border={props.border}
            placeholder={HVLocalizeStrings.VERIFY_YOUR_PASSWORD}
            handleChange={props.handleChange}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
            stateToUpdate={RegistrationLoginStates.PasswordConfirmation}
            type={"password"}
            onKeyDown={props.onKeyDown}
            onFocus={() => setPasswordHasFocus(true)}
            onBlur={() => setPasswordHasFocus(false)}
            width={props.width}
          />
        )}
      </Flex>
    </Tooltip>
  );
};

export default PasswordInput;
