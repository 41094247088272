import { useCallback, useState } from "react";
import { getThinkingPlanItems } from "../../Api/Solo/ThinkingApi";
import { getKey } from "../../Utils/Helpers";
import { ThinkingToolInfoDto } from "@headversity/contract";

export const useThinkingToolStat = () => {
  const [thinkingPlanItems, setThinkingPlanItems] = useState<
    ThinkingToolInfoDto[] | undefined
  >();

  const getThinkingPlanItemsFromServer = useCallback(async () => {
    return getThinkingPlanItems(getKey()).then((response) => {
      setThinkingPlanItems(response.data);
    });
  }, []);

  return { thinkingPlanItems, getThinkingPlanItemsFromServer };
};
