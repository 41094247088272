import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { DISABLED_BORDER } from "../../../Styles/HeadversityStyle";
import InsightsCard from "../../Common/Cards/InsightsCard";
import { PsychographicBobMessage } from "./usePsychographicsReport";
require("highcharts/modules/annotations")(Highcharts);

const HC_rounded = require("highcharts-rounded-corners");
HC_rounded(Highcharts);

export interface PsychographicBarChartDataPoint {
  y: number;
  name: string;
  colorStart: string;
  colorEnd: string;
}

export interface PsychographicBarChartProps {
  data: PsychographicBarChartDataPoint[];
  title: string;
  bobMessage?: PsychographicBobMessage;
  pdfExport?: boolean;
}

const PsychographicBarChart = (props: PsychographicBarChartProps) => {
  const { data, title, bobMessage } = props;

  let widthByBreakpoint = useBreakpointValue({
    base: 300,
    sm: 400,
    md: 400,
    lg: 240, // 3 columns when >= lg
    xl: 300,
    "2xl": 380,
  });
  if (props.pdfExport) {
    widthByBreakpoint = 300; // xl size for pdf
  }

  const referenceMarkerHeight = 35;

  const checkOverlapAndSetWidth = (
    referenceY: number | undefined,
    pointY: number
  ) => {
    if (referenceY === undefined || isNaN(referenceY)) {
      return 1;
    }
    const comparison = referenceY - pointY;
    const shouldGrayPositive = comparison >= 0 && comparison < 10;
    const shouldGrayNegative = comparison < 0 && comparison > -3;
    return shouldGrayPositive || shouldGrayNegative ? 0.3 : 1;
  };

  const pointY = data[0].y;

  const options = {
    title: {
      text: "",
    },
    chart: {
      type: "bar",
      reflow: true,
      width: widthByBreakpoint,
      height: 200,
    },
    plotOptions: {
      series: {
        borderRadiusTopLeft: "100px",
        borderRadiusTopRight: "100px",
      },
    },
    legend: { enabled: false },
    xAxis: {
      tickLength: 0,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      minorTickColor: "#000000",
      title: {
        text: null,
      },
      min: 0,
      max: 100,
      tickLength: 0,
      gridLineWidth: 0,
      labels: {
        format: "{text}%",
        style: {
          fontSize: "0.6em",
        },
      },
    },
    series: [
      {
        showInLegend: false,
        data: data.map((dataPoint) => ({
          name: dataPoint.name,
          y: dataPoint.y,
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, dataPoint.colorStart],
              [1, dataPoint.colorEnd],
            ],
          },
        })),
        dataLabels: {
          enabled: true,
          format: "{y}%",
        },
      },
    ],
    tooltip: {
      formatter: function (this: any) {
        return this.point.name + ": <b>" + this.y + "%</b>";
      },
    },
    credits: {
      enabled: false,
    },
    annotations: [
      {
        shapes: [
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: 0,
              y: bobMessage?.bob_ref_p,
            },
            dashStyle: "ShortDash",
            type: "rect",
            stroke: "rgba(0, 0, 0, 0.1)",
            strokeWidth: checkOverlapAndSetWidth(bobMessage?.bob_ref_p, pointY),
            width: checkOverlapAndSetWidth(bobMessage?.bob_ref_p, pointY),
            height: referenceMarkerHeight,
            y: -referenceMarkerHeight / 2.0,
          },
        ],
        draggable: null,
      },
    ],
  };

  return (
    <InsightsCard
      title={""}
      hideHeader={true}
      showFooter={false}
      content={
        <Flex
          flexDir="column"
          alignItems="flex-start"
          bgColor="white"
          h={"100%"}
        >
          <Text px={3} width={"100%"} minH={"50px"}>
            {title}
          </Text>
          <Box pl={1} h={"200px"}>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </Box>
          <Box borderTop={DISABLED_BORDER}>
            <Text p={3}>
              <Text fontWeight="semibold" as="span">
                {bobMessage?.compare_p}%
              </Text>{" "}
              {HVLocalizeStrings.REACH_INSIGHTS_INDICATED_A}{" "}
              <Text fontWeight="semibold" textTransform="uppercase" as="span">
                {bobMessage?.agreement}
              </Text>{" "}
              {HVLocalizeStrings.REACH_INSIGHTS_AGREEMENT_VS}{" "}
              <Text fontWeight="semibold" as="span">
                {bobMessage?.bob_ref_p}%
              </Text>{" "}
              {HVLocalizeStrings.REACH_INSIGHTS_IN_OUR_REFERENCE_BENCHMARK}
            </Text>
          </Box>
        </Flex>
      }
    />
  );
};

export default PsychographicBarChart;
