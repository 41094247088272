import {
  Box,
  Image,
  Link,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import { ShellContext } from "../../../State/ShellContext";
import { EVENTS, track } from "../../../Utils/Analytics";
import { getStreakNameFromType, StreakType } from "../../../Utils/SkillsUtil";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { LargeModal } from "../../Common/LargeModal";
import { StreakInfo } from "./StreakInfo";

interface StreakButtonProps {
  streakInfo: {
    type: StreakType;
    length: number;
    isIncomplete: boolean;
  };
}
export const StreakButton = ({ streakInfo }: StreakButtonProps) => {
  const isMd = useBreakpointValue({ base: false, md: true });

  const {
    selectedGoal,
    selectedGoalBgImage,
    showStreakModal,
    setShowStreakModal,
    schedulerReminders,
  } = useContext(GlobalContext);

  const { setIsPracticeReminderModalOpen } = useContext(ShellContext);

  const streakTip =
    streakInfo.length === 0
      ? HVLocalizeStrings.START_A_STREAK
      : streakInfo.isIncomplete
      ? HVLocalizeStrings.STREAK_CONTINUE
      : HVLocalizeStrings.STREAK_GOING;
  const streakLen = streakInfo.isIncomplete
    ? streakInfo.length - 1
    : streakInfo.length;
  const streakName = getStreakNameFromType(
    streakInfo.type,
    streakInfo.length,
    true
  );

  return (
    <>
      <Tooltip
        label={streakTip
          .replace("{0}", streakLen.toString())
          .replace("{1}", streakName)}
        isDisabled={!isMd}
      >
        <Box
          as="button"
          className="streak-button"
          onClick={() => {
            track(EVENTS.StreakModalShown, {
              HV_From: "StreakButton",
            });

            setShowStreakModal(true);
          }}
        >
          <Image
            src="/fire.svg"
            h="38px"
            mt="-4px"
            filter={`drop-shadow(0 0 0.1rem #ffffff) brightness(1.1) ${
              streakInfo.isIncomplete ? "grayscale(1)" : ""
            }`}
          />
        </Box>
      </Tooltip>
      {showStreakModal && selectedGoal && (
        <LargeModal
          open={showStreakModal}
          setOpen={setShowStreakModal}
          bgImage={selectedGoalBgImage}
          forceDarkMode={true}
        >
          <Box minH="400px">
            <StreakInfo />
          </Box>
          <BottomActionBar
            nextButtonText={HVLocalizeStrings.CLOSE}
            onNextButtonClick={() => setShowStreakModal(false)}
            actionAreaExtra={
              <Link
                textDecoration={"underline"}
                fontSize="14px"
                onClick={(e) => {
                  track(EVENTS.ManageChallenge, {
                    HV_From: "StreakInfo",
                  });

                  setShowStreakModal(false);
                  setIsPracticeReminderModalOpen(true);
                }}
              >
                {schedulerReminders.length > 0
                  ? HVLocalizeStrings.PRACTICE_REMINDER_MANAGE_CHALLENGE
                  : HVLocalizeStrings.PRACTICE_REMINDER_SET_REMINDER}
              </Link>
            }
          />
        </LargeModal>
      )}
    </>
  );
};
