import { SelfServeUserDto } from "@headversity/contract";
import { Text } from "@chakra-ui/react";
import { HVTestId } from "../../../Testing/dataTestIds";

interface SelfServeUserCertsProps {
  user: SelfServeUserDto;
  type: string;
}

const SelfServeUserCerts = ({ user, type }: SelfServeUserCertsProps) => {
  let assigned = user.certsAssigned ?? 0;
  let completed = user.certsCompleted ?? 0;
  let count;
  if (assigned === 0) {
    count = "-";
  } else if (type === "assigned") {
    count = assigned;
  } else {
    count = completed;
  }

  return (
    <Text
      fontSize="sm"
      data-testid={`${HVTestId.SelfServeUserCerts.cert}${type}_${user.id}`}
    >
      {count}
    </Text>
  );
};

export default SelfServeUserCerts;
