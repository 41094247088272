import {
  FavoriteDto,
  HeadzoneFollowUpStepSetting,
  HeadzoneStepSetting,
  InputStepSetting,
  LocalizationText,
  LocalizationTextArray,
  NanoPracticeDto,
  NanoPracticeQuestionDto,
  NanoPracticeUserInstanceDto,
  QuestionUserResponseDto,
  TellMeBackStepSetting,
  VideoStepSetting,
} from "@headversity/contract";
import dayjs from "dayjs";
import { Dictionary } from "lodash";
import { InputHistory } from "./NanoPracticeHistory";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { getBrainZoneInfo } from "../Tools/HeadZone/HeadZoneToolUtil";
import { HeadZoneStateRanges } from "../../../Utils/HeadzoneUtil";
import ResourceType from "../../../Utils/ResourceType";
import { translateLocalizationText } from "../../../Utils/TranslateUtil";

export const HEADZONE_ID = 8;
export const SELF_ESTEEM_CHECK_ID = 19;
export const CONFIDENCE_ZONE_ID = 25;
export const UNCERTAINTY_ZONE_ID = 43;

export type NanoPracticeStep =
  | InputStepSetting
  | VideoStepSetting
  | HeadzoneStepSetting
  | TellMeBackStepSetting
  | HeadzoneFollowUpStepSetting;

export const isLocalizationTextArray = (
  localizationTextArray: LocalizationTextArray | string[]
): localizationTextArray is string[] => {
  return (localizationTextArray as string[]) !== undefined;
};

export const getHeadZoneNanoPracticeQuestionName = (
  id: number
): LocalizationText | undefined => {
  switch (id) {
    // leaving for backward compatibility
    case HEADZONE_ID:
      return { en: "HEADZONE" };
    case SELF_ESTEEM_CHECK_ID:
      return { en: "SELF-ESTEEM" };
    case CONFIDENCE_ZONE_ID:
      return { en: "CONFIDENCE" };
    case UNCERTAINTY_ZONE_ID:
      return { en: "UNCERTAINTY" };
    default:
      return { en: "ZONE-" + id };
  }
};

export const getHeadZoneTextA = (value: number): any => {
  switch (getBrainZoneInfo(value).stateRange) {
    case HeadZoneStateRanges.burnOut:
      return HVLocalizeStrings.HEADZONE_YOURE_IN_THE;

    case HeadZoneStateRanges.stressed:
      return HVLocalizeStrings.HEADZONE_YOURE_IN_THE;

    case HeadZoneStateRanges.okay:
      return HVLocalizeStrings.HEADZONE_WELCOME_TO_THE;

    case HeadZoneStateRanges.great:
      return HVLocalizeStrings.HEADZONE_YOURE_IN_THE;

    case HeadZoneStateRanges.thriving:
      return HVLocalizeStrings.HEADZONE_YOUVE_REACHED_THE;
  }
};

export const sortByNanoPracticeDoneToday = (
  nanoPractices: NanoPracticeDto[],
  completedNanoPractices: Dictionary<NanoPracticeUserInstanceDto[]>
): NanoPracticeDto[] => {
  return [...nanoPractices].sort((practice1, practice2) => {
    const isP1DoneToday = isNanoPracticeDoneTodayOrByStartDate(
      practice1.id,
      completedNanoPractices
    );
    const isP2DoneToday = isNanoPracticeDoneTodayOrByStartDate(
      practice2.id,
      completedNanoPractices
    );

    if (isP1DoneToday && !isP2DoneToday) {
      return 1;
    } else if (!isP1DoneToday && isP2DoneToday) {
      return -1;
    } else {
      return 0;
    }
  });
};

export const isNanoPracticeDoneTodayOrByStartDate = (
  practiceId: number,
  completedNanoPractices: Dictionary<NanoPracticeUserInstanceDto[]>,
  startDate?: string
): boolean => {
  const nanoPracticeInstances = completedNanoPractices[practiceId];

  return isNanoPracticeDoneTodayOrByStartDateByList(
    nanoPracticeInstances,
    startDate
  );
};

export const isNanoPracticeDoneTodayOrByStartDateByList = (
  nanoPracticeInstances: NanoPracticeUserInstanceDto[],
  startDate?: string
): boolean => {
  if (!nanoPracticeInstances) return false;

  if (startDate) {
    return nanoPracticeInstances.some(
      (nanoPracticeUserInstance: NanoPracticeUserInstanceDto) => {
        const startDateDayjs = dayjs.utc(startDate).local();
        const createdAtDayJs = dayjs
          .utc(nanoPracticeUserInstance.createdAt.toString())
          .local();
        return (
          createdAtDayJs.isSame(startDateDayjs) ||
          createdAtDayJs.isAfter(startDateDayjs)
        );
      }
    );
  }

  return (
    nanoPracticeInstances.some((a) =>
      dayjs().isSame(dayjs.utc(a.createdAt.toString()).local(), "day")
    ) ?? false
  );
};

/**
 * Get the first answer of each nano practice user instance
 *
 * @returns {InputHistory[]} Returns the InputHistory Array
 */
export const getNanoPracticeHistory = (
  nanoPracticeUserInstances: NanoPracticeUserInstanceDto[],
  selectedUserLanguage: string
): InputHistory[] => {
  return nanoPracticeUserInstances.map((instance) => {
    let title = "";
    if (!instance.aiResponse) {
      let tellItBack: string | undefined;
      const nanoPracticeQuestions = getNanoPracticeQuestionsFromResponses(
        instance.questionUserResponses
      );
      if (nanoPracticeQuestions && nanoPracticeQuestions?.length > 0) {
        tellItBack = translateLocalizationText(
          nanoPracticeQuestions?.find(
            (npq) =>
              npq.questionId === instance.questionUserResponses[0]?.question.id
          )?.tellItBack_loc,
          selectedUserLanguage
        );
      }
      title =
        tellItBack ??
        instance.questionUserResponses[0]?.question.questionText.toString();
      title = `${title} ${instance.questionUserResponses[0]?.responseText}`;
    }
    return {
      responseText:
        instance.aiResponse ?? instance.questionUserResponses[0]?.responseText,
      date: instance?.createdAt,
      tellItBack: title,
      instanceId: instance.id,
    };
  });
};

export const getNanoPracticeQuestionsFromResponses = (
  questionUserResponses: QuestionUserResponseDto[]
): NanoPracticeQuestionDto[] => {
  const uniqueQuestions = new Map<number, NanoPracticeQuestionDto>();

  questionUserResponses.forEach((response) => {
    const question = response.nanoPracticeQuestion;
    if (question?.tellItBack_loc) {
      uniqueQuestions.set(question.id, question);
    }
  });

  return Array.from(uniqueQuestions.values());
};

export const getMessagesFromAiResponse = (
  response: string,
  type: string,
  emptyIfNotFound: boolean = false
) => {
  const divElement = document.createElement("div");
  divElement.innerHTML = response;
  const elements = divElement.getElementsByTagName(type);
  if (elements.length > 0) {
    let textContent = "";
    for (let i = 0; i < elements[0].childNodes.length; i++) {
      if (elements[0].childNodes[i].nodeType === Node.TEXT_NODE) {
        textContent += elements[0].childNodes[i].textContent;
      }
    }
    if (textContent) {
      return textContent;
    }
  }

  if (emptyIfNotFound) {
    return "";
  }
  return divElement.textContent;
};

export const isFave = (favorites: FavoriteDto[], practice: NanoPracticeDto) => {
  return favorites.some(
    (favorite) =>
      favorite.resourceId === practice.id &&
      favorite.resourceType === ResourceType.nanoPractice
  );
};
