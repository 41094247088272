import { Box, Flex, Text } from "@chakra-ui/react";

export interface InsightsPopularDisplayProps {
  items: PopularDisplayData[];
  colorPercentage?: string;
  colorBorder?: string;
  colorBackground?: string;
}

export interface PopularDisplayData {
  name: string;
  description: string;
  percentage: number;
}

export interface PercentDisplayData {
  name: string;
  percentage: number;
}

export const InsightsPopularDisplay = (props: InsightsPopularDisplayProps) => {
  const { items, colorPercentage, colorBorder, colorBackground } = props;
  return (
    <Flex flexDir="column" flexWrap={"wrap"} width="100%">
      {items.map((item, index) => (
        <Flex
          key={item.name}
          minH={"110px"}
          flexDir="column"
          alignItems="start"
          justifyContent="center"
          borderRadius="md"
          borderColor={colorBorder}
          borderWidth="1px"
          backgroundColor={colorBackground}
          m={2}
        >
          <Flex
            flexDir="row"
            alignItems="center"
            justifyContent="center"
            gap={4}
          >
            <Box
              ml={4}
              boxSize={"60px"}
              fontSize="1.8rem"
              fontWeight="bold"
              color={colorPercentage}
              display="flex"
              alignItems="center"
              justifyContent="start"
            >
              {item.percentage}%
            </Box>
            <Box
              display="flex"
              alignItems="start"
              justifyContent="center"
              flexDir="column"
              pr={4}
            >
              <Text fontSize="0.8rem" fontWeight="semibold">
                {item.name}
              </Text>
              {item.description && (
                <Text noOfLines={3} fontSize="0.8rem" textOverflow="ellipsis">
                  {item.description}
                </Text>
              )}
            </Box>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};
