import { useContext, useEffect, useState } from "react";
import {
  CertDto,
  CreateSelfServeUserDto,
  UpdateSelfServeUserDto,
} from "@headversity/contract";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";
import {
  ReachUsersContext,
  UserModalOpen,
} from "../../../State/Reach/ReachUsersContext";
import { ShellContext } from "../../../State/ShellContext";
import UserAddModal from "./UserModal/UserAddModal";
import UserViewEditModal from "./UserModal/UserViewEditModal";

const ReachUserModalContainer = () => {
  const startingUser = {
    userFields: {},
    customInfo: {},
  } as CreateSelfServeUserDto;

  const { showToast } = useContext(ShellContext);
  const { upsertReachUserToServer, userModalOpen } =
    useContext(ReachUsersContext);

  const [user, setUser] = useState<
    UpdateSelfServeUserDto | CreateSelfServeUserDto
  >(startingUser);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isMissingFields, setIsMissingFields] = useState<boolean>(true);

  const [activationDateTime, setActivationDateTime] = useState<
    Date | undefined
  >();
  const [language, setLanguage] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    const userFields = user?.userFields;
    setIsMissingFields(
      !(
        userFields?.fullName &&
        userFields?.email &&
        userFields?.role &&
        userFields?.inviteCodeId
      )
    );
  }, [user?.userFields]);

  const handleActivationDateTimeChanged = (date: Date) => {
    setActivationDateTime(date);
    setUser({
      ...user,
      userFields: {
        ...user?.userFields,
        activationEmailAt: date.toISOString(),
      },
    });
  };

  const upsertReachUser = async (certsSelected?: CertDto[]) => {
    const updatedUser = await upsertReachUserToServer(
      user as UpdateSelfServeUserDto,
      certsSelected
    );
    setUser({
      ...user,
      userFields: {
        ...updatedUser
      }});
    // if there's an id, it's an edit, otherwise it's an add
    if ("id" in user && user.id > 0) {
      showToast(
        true,
        "self-serve-user-edit-success",
        HVLocalizeStrings.REACH_USER_EDIT_SUCCESS
      );
      track(REACH_EVENTS.ReachUsersEditedSingleUser);
    } else {
      showToast(
        true,
        "self-serve-user-add-success",
        HVLocalizeStrings.REACH_USER_ADD_SUCCESS
      );
      track(REACH_EVENTS.ReachUsersAddedSingleUser);
    }
  };

  const resetStates = () => {
    setUser(startingUser);
    setLanguage("");
    setFullName("");
    setEmail("");
    setActivationDateTime(undefined);
    setIsSaving(false);
    setIsMissingFields(true);
  };

  return (
    <>
      {userModalOpen === UserModalOpen.ADD && (
        <UserAddModal
          isOpen={userModalOpen === UserModalOpen.ADD}
          user={user}
          setUser={setUser}
          fullName={fullName}
          setFullName={setFullName}
          email={email}
          setEmail={setEmail}
          language={language}
          setLanguage={setLanguage}
          handleActivationDateTimeChanged={handleActivationDateTimeChanged}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          isMissingFields={isMissingFields}
          upsertReachUser={upsertReachUser}
          resetStates={resetStates}
          startingUser={startingUser}
        />
      )}
      {userModalOpen === UserModalOpen.VIEW_EDIT && (
        <UserViewEditModal
          isOpen={userModalOpen === UserModalOpen.VIEW_EDIT}
          user={user}
          setUser={setUser}
          language={language}
          setLanguage={setLanguage}
          fullName={fullName}
          setFullName={setFullName}
          email={email}
          setEmail={setEmail}
          activationDateTime={activationDateTime}
          setActivationDateTime={setActivationDateTime}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          upsertReachUser={upsertReachUser}
          isMissingFields={isMissingFields}
          handleActivationDateTimeChanged={handleActivationDateTimeChanged}
          resetStates={resetStates}
          startingUser={startingUser}
        />
      )}
    </>
  );
};

export default ReachUserModalContainer;
