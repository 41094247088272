import { ParticipantTeamLessonStepProps } from "../../../Team/Participant/ParticipantModal";
import {
  Box,
  Divider,
  Flex,
  Icon,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import BlockSectionTitle from "../../../Common/BlockSectionTitle";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  Border_Radius,
  Sport_Green,
} from "../../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";

import { GoDotFill } from "react-icons/go";
import { ParticipantContainer } from "../../../Shared/Container/ParticipantContainer";
import { useGroupInformation } from "../../../../Hooks/Sport/useGroupInformation";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { ParticipantWaitingScreen } from "../Shared/ParticipantWaitingScreen";

interface ParticipantGroupStepProps extends ParticipantTeamLessonStepProps {}

export const ParticipantGroupStep = (props: ParticipantGroupStepProps) => {
  const {
    isGroupLead,
    currentGroup,
    currentGroupUsers,
    currentGroupReporterId,
  } = useGroupInformation();

  if (currentGroup) {
    return (
      <ParticipantContainer
        leftContent={
          <Text color={"white"}>
            {HVSportLocalizeStrings.CHARTER_GROUPING_ACTIVITY}
          </Text>
        }
      >
        <>
          <Flex flexDir={"column"} gap={"5"} align={"center"} w={"70%"}>
            <Box>
              <BlockSectionTitle
                primaryTextColor={Black}
                title={currentGroup}
                borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
              />
            </Box>
            <List
              bg={"white"}
              w={"300px"}
              overflowY={"auto"}
              borderRadius={Border_Radius}
              boxShadow={"lg"}
              p={5}
              spacing={"2"}
            >
              {currentGroupUsers.map((item) => {
                return (
                  <ListItem key={item.id}>
                    <Flex align={"center"} gap={"2"}>
                      <Icon
                        as={GoDotFill}
                        color={Black}
                        fontSize={"13px"}
                      ></Icon>
                      <Text color={Black} fontSize={"16px"}>
                        {item.fullName}
                        {item.id === currentGroupReporterId
                          ? ` (${HVLocalizeStrings.TEAM_GROUPING_REPORTER})`
                          : ""}
                      </Text>
                    </Flex>
                  </ListItem>
                );
              })}
            </List>
          </Flex>
          {isGroupLead ? (
            <>
              <Divider
                my={"3"}
                borderBottom={`solid 1px ${Sport_Green} !important`}
              ></Divider>
              <Flex flexDir={"column"} gap={"5"} align={"center"} w={"70%"}>
                <Text fontWeight={"bold"}>
                  {HVSportLocalizeStrings.CHARTER_GROUP_YOU_ARE_GROUP_REPORTER}
                </Text>
                <Text>
                  {HVSportLocalizeStrings.CHARTER_GROUP_REPORT_INSTRUCTION}
                </Text>
              </Flex>
            </>
          ) : (
            <></>
          )}
        </>
      </ParticipantContainer>
    );
  } else {
    return (
      <ParticipantWaitingScreen
        message={HVSportLocalizeStrings.CHARTER_GROUPING_WAITING}
      />
    );
  }
};
