import { Box, Center, Heading, Stack, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { OptimismToolInputItem } from "./OptimismToolInputItem";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { OPTIMISM_MADE_POSSIBLE_STEP } from "./OptimismTool";
import {
  MODAL_PRIMARY_TEXT_COLOR,
  MODAL_THIRD_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface OptimismToolStepThreeGoodThingsProps {
  firstGoodThing: string;
  secondGoodThing: string;
  thirdGoodThing: string;
  setFirstGoodThing: (firstGoodThing: string) => void;
  setSecondGoodThing: (secondGoodThing: string) => void;
  setThirdGoodThing: (thirdGoodThing: string) => void;
  currentDay: number;
  challengeLength: number;
  changePage: (page: string) => void;
}

export const OptimismToolStepThreeGoodThings = (
  props: OptimismToolStepThreeGoodThingsProps
) => {
  const {
    firstGoodThing,
    secondGoodThing,
    thirdGoodThing,
    setFirstGoodThing,
    setSecondGoodThing,
    setThirdGoodThing,
    changePage,
  } = props;

  const handleGoodThingsChange = (value: string, index: number) => {
    if (index === 0) {
      setFirstGoodThing(value);
    } else if (index === 1) {
      setSecondGoodThing(value);
    } else {
      setThirdGoodThing(value);
    }
  };

  const challengeNotOver = (
    <>
      <Box>
        <Heading
          text-align={"left"}
          fontSize={"2xl"}
          fontWeight={"semibold"}
          color={MODAL_THIRD_TEXT_COLOR}
        >
          {HVLocalizeStrings.OPTIMISM_TOOLS_DAY} {props.currentDay}{" "}
          {HVLocalizeStrings.OPTIMISM_TOOLS_OF} {props.challengeLength}
        </Heading>
        <Heading
          fontSize={"2xl"}
          mt={"10"}
          fontWeight={"semibold"}
          text-align={"left"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {HVLocalizeStrings.OPTIMISM_TOOLS_GOOD_THINGS}
        </Heading>
        <Text mt={"5"} fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
          {HVLocalizeStrings.OPTIMISM_TOOLS_PICK}
        </Text>
      </Box>
      <Stack spacing={"15px"}>
        <OptimismToolInputItem
          listNumber={1}
          example={HVLocalizeStrings.OPTIMISM_TOOLS_GOT_SLEEP}
          defaultValue={firstGoodThing}
          handleChange={handleGoodThingsChange}
          autoFocus={window.screen.width >= 568}
          dataTestId={"0"}
        />
        <OptimismToolInputItem
          listNumber={2}
          example={HVLocalizeStrings.OPTIMISM_TOOLS_BEDTIME_STORY}
          defaultValue={secondGoodThing}
          handleChange={handleGoodThingsChange}
          dataTestId={"1"}
        />
        <OptimismToolInputItem
          listNumber={3}
          example={HVLocalizeStrings.OPTIMISM_TOOLS_GREAT_WORKOUT}
          defaultValue={thirdGoodThing}
          handleChange={handleGoodThingsChange}
          onEnterKeyDown={() => {
            if (firstGoodThing && secondGoodThing && thirdGoodThing) {
              changePage(OPTIMISM_MADE_POSSIBLE_STEP);
            }
          }}
          dataTestId={"2"}
        />
      </Stack>
      <Center mt={4} mb={2}>
        <AnimatedButton
          disabled={!firstGoodThing || !secondGoodThing || !thirdGoodThing}
          text={HVLocalizeStrings.NEXT}
          onClick={() => changePage(OPTIMISM_MADE_POSSIBLE_STEP)}
          dataTestId={HVTestId.OptimismTool.goodThingsNext}
        />
      </Center>
    </>
  );

  const challengeOver = (
    <>
      <Box>
        <Heading
          text-align={"center"}
          fontSize={"2xl"}
          fontWeight={"semibold"}
          color={MODAL_THIRD_TEXT_COLOR}
        >
          {HVLocalizeStrings.OPTIMISM_TOOLS_CHALLENGE_ALREADY_OVER}
        </Heading>
      </Box>
    </>
  );

  return (
    <>
      {props.challengeLength > 0 && challengeNotOver}
      {!props.challengeLength && challengeOver}
    </>
  );
};
