import React, { useContext, useEffect } from "react";
import { LargeModal } from "../../../Common/LargeModal";
import { OptimismTool } from "./OptimismTool";
import {
  IOptimismToolProvider,
  OptimismToolContext,
} from "../../../../State/Solo/OptimismToolContext";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface OptimismToolModalProps {
  open: boolean;
  setOpen: (val: boolean) => {};
  step?: string;
}

export const OptimismToolModal = (props: OptimismToolModalProps) => {
  const { open, setOpen, step } = props;

  return (
    <>
      {open && (
        <LargeModal
          open={open}
          setOpen={setOpen}
          dataTestId={HVTestId.OptimismTool.OptimismToolModal.modal}
        >
          <>
            <OptimismTool setOpen={setOpen} step={step} />
          </>
        </LargeModal>
      )}
    </>
  );
};
