import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Icon,
  ModalCloseButton,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Fragment,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { AnimatedButton, AnimatedButtonColorSet } from "./AnimatedButton";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import {
  MODAL_BACKGROUND_COLOR,
  MODAL_CLOSE_BUTTON,
  MODAL_PRIMARY_TEXT_COLOR,
  OVERLAY_BACKGROUND_COLOR,
} from "../../Styles/HeadversityStyle";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../State/GlobalContext";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { HVTestId } from "../../Testing/dataTestIds";
import { removeSearchParam } from "../../Utils/Helpers";
import { useNavigate } from "react-router-dom";

let localCallback: any = null;
let localNoButtonCallback: any = null;

export enum ConfirmationButtons {
  YesNo,
  Ok,
  Customs,
  JSX,
}

export const ConfirmationDialog = forwardRef((props, ref) => {
  const { closeDialog, setCloseDialog } =
    useContext<IGlobalProvider>(GlobalContext);
  const { onClose, onOpen, isOpen } = useDisclosure();
  const [title, setTitle] = useState<string>("");
  const [confirmationType, setConfirmationType] =
    useState<ConfirmationButtons>();
  const [message, setMessage] = useState<any>(null);
  const [buttons, setButtons] = useState<any[]>([]);
  const [showCloseButton, setShowCloseButton] = useState<boolean>(false);
  const [size, setSize] = useState<string>("md");
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [yesButtonClose, setYesButtonClose] = useState<boolean>(true);

  useEffect(() => {
    if (closeDialog) {
      onClose();
      setCloseDialog(false);
    }
  }, [closeDialog]);

  const cancelRef = useRef();
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    confirmation(
      title: string,
      message: any,
      confirmationType: ConfirmationButtons,
      callback: (item?: any) => void,
      buttons?: any[],
      showCloseButton?: boolean,
      dialogSize?: string,
      noButtonCallback?: () => void,
      useWarning?: boolean,
      closeOnYes?: boolean
    ) {
      setTitle(title);
      setMessage(message);
      setConfirmationType(confirmationType);
      if (buttons) {
        setButtons(buttons);
      }
      setShowCloseButton(showCloseButton === true);
      localCallback = callback;
      localNoButtonCallback = noButtonCallback;
      if (dialogSize) {
        setSize(dialogSize);
      } else {
        setSize("md");
      }
      onOpen();
      if (useWarning) {
        setIsWarning(useWarning);
      }
      if (closeOnYes != null) {
        setYesButtonClose(closeOnYes);
      }
    },
    close() {
      onClose();
    },
  }));

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef as any}
      isOpen={isOpen}
      onClose={() => {
        if (confirmationType === ConfirmationButtons.Ok) {
          localCallback();
        }
        if (localNoButtonCallback) {
          localNoButtonCallback();
        }
        onClose();
      }}
      size={size}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay bg={OVERLAY_BACKGROUND_COLOR}>
        <AlertDialogContent
          p={2}
          bg={MODAL_BACKGROUND_COLOR}
          data-testid={HVTestId.ConfirmationDialog.dialog}
        >
          {(title || showCloseButton) && (
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              color={MODAL_PRIMARY_TEXT_COLOR}
              whiteSpace={"nowrap"}
            >
              {title}
              {isWarning && (
                <Icon
                  w={"18px"}
                  h={"18px"}
                  as={WarningTwoIcon}
                  color={"red"}
                  ml={"8px"}
                  mb={"4px"}
                />
              )}
              {showCloseButton && (
                <ModalCloseButton
                  w={"40px"}
                  h={"40px"}
                  fontSize={"md"}
                  top={"20px"}
                  right={isDesktop ? "18px" : "12px"}
                  zIndex={2}
                >
                  <Icon
                    as={AiOutlineCloseCircle}
                    w={"32px"}
                    h={"32px"}
                    color={MODAL_CLOSE_BUTTON}
                  />
                </ModalCloseButton>
              )}
            </AlertDialogHeader>
          )}

          {(typeof message === "string" || message instanceof String) && (
            <Flex>
              <AlertDialogBody
                color={MODAL_PRIMARY_TEXT_COLOR}
                dangerouslySetInnerHTML={{ __html: `${message}` }}
              />
            </Flex>
          )}
          <AlertDialogBody>
            {typeof message !== "string" && !(message instanceof String) && (
              <>{message} </>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Flex w={"100%"} justify={"center"}>
              {confirmationType === ConfirmationButtons.YesNo && (
                <>
                  <AnimatedButton
                    text={HVLocalizeStrings.CONFIRM_YES}
                    onClick={() => {
                      const searchParams = removeSearchParam(
                        "teamLessonUserInstanceId"
                      );
                      navigate(
                        {
                          search: searchParams.toString(),
                        },
                        { replace: true }
                      );
                      if (localCallback) {
                        localCallback();
                      }
                      localNoButtonCallback = null;
                      if (yesButtonClose) {
                        localCallback = null;
                        onClose();
                      }
                    }}
                    dataTestId={HVTestId.ConfirmationDialog.yes}
                    colorSet={
                      isWarning
                        ? AnimatedButtonColorSet.Fifth
                        : AnimatedButtonColorSet.Secondary
                    }
                  ></AnimatedButton>
                  &nbsp;&nbsp;
                  <AnimatedButton
                    colorSet={AnimatedButtonColorSet.Third}
                    text={HVLocalizeStrings.CONFIRM_NO as any}
                    onClick={() => {
                      if (localNoButtonCallback) {
                        localNoButtonCallback();
                      }
                      localNoButtonCallback = null;
                      localCallback = null;
                      onClose();
                    }}
                    dataTestId={HVTestId.ConfirmationDialog.no}
                  ></AnimatedButton>
                </>
              )}
              {confirmationType === ConfirmationButtons.Ok && (
                <>
                  <AnimatedButton
                    text={HVLocalizeStrings.CONFIRM_OK}
                    onClick={() => {
                      if (localCallback) {
                        localCallback();
                      }
                      localCallback = null;
                      onClose();
                    }}
                  ></AnimatedButton>
                </>
              )}

              {confirmationType === ConfirmationButtons.Customs && (
                <>
                  {buttons?.map((item, idx) => {
                    return (
                      <Fragment key={idx}>
                        {typeof item === "string" && (
                          <>
                            <AnimatedButton
                              colorSet={
                                idx === 0
                                  ? undefined
                                  : AnimatedButtonColorSet.Third
                              }
                              text={item}
                              onClick={() => {
                                if (localCallback) {
                                  localCallback(item);
                                }
                                localCallback = null;
                                onClose();
                              }}
                            ></AnimatedButton>
                            &nbsp;&nbsp;
                          </>
                        )}
                      </Fragment>
                    );
                  })}
                </>
              )}
              {confirmationType === ConfirmationButtons.JSX &&
                buttons?.map((item, idx) => {
                  return (
                    <Fragment key={idx}>
                      {typeof item !== "string" && <>{item}</>}
                    </Fragment>
                  );
                })}
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
});
