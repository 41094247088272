import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import { Equity_Blue } from "../../../Styles/HeadversityStyle";
import styled from "@emotion/styled";
import { HVTestId } from "../../../Testing/dataTestIds";
import { useState } from "react";

const HVSliderBox = styled(Box)`
  .chakra-slider__marker {
    pointer-events: auto !important;
  }
`;

interface SliderQuestionProps {
  alternativeCount: number;
  scaleMax: number;
  onSelectedIndexChanged?: (val: number, index: number) => void;
  isDisabled: boolean;
  textColor: string;
}

/**
 * Slider scale is from 0 to "scaleMax"; text shown will be interpolated from # of alternative options
 *
 * In the future, this will let us do a 100 point scale with just a handful of alternatives
 *   e.g. a 100-point "scaleMax" with "alternativeCount" of 7 will mimic the iOS mood tracker
 */
export const SliderQuestion = ({
  alternativeCount,
  scaleMax,
  onSelectedIndexChanged,
  isDisabled,
  textColor,
}: SliderQuestionProps) => {
  const [hasStarted, setHasStarted] = useState(false);

  const getScaleQuestionValue = (val: number, len: number) => {
    const adjust = scaleMax / len;

    const index = (val + adjust / 2) / scaleMax;

    return Math.min(len - 1, Math.max(0, Math.round(index * len) - 1));
  };

  let changeInterval: NodeJS.Timeout | undefined;
  const handleOnChange = (val: number) => {
    // wait until we get a little off the middle
    if (!hasStarted && val >= 49 && val <= 51) return;

    setHasStarted(true);

    // debounce a bit
    if (changeInterval) {
      clearTimeout(changeInterval);
    }

    changeInterval = setTimeout(() => {
      if (onSelectedIndexChanged) {
        // for slider question, the index is the closest response
        const index = getScaleQuestionValue(val, alternativeCount);

        onSelectedIndexChanged(val, index);
      }
    }, 10);
  };

  return (
    <HVSliderBox mx={{ base: "20px", md: "80px" }}>
      <Slider
        defaultValue={scaleMax / 2}
        min={0}
        max={scaleMax}
        step={1}
        onClick={(e) => {
          // allow click on middle to start
          if (!hasStarted) {
            handleOnChange(scaleMax / 2);
          }
        }}
        onChange={(val: number) => {
          handleOnChange(val);
        }}
        isDisabled={isDisabled}
        data-testid={HVTestId.SliderQuestion.slider}
      >
        <SliderTrack bgGradient={"linear(to-l, #00b1ff, #c5bdcc, #ee414b)"}>
          <Box position="relative" right={10} />
          <SliderFilledTrack bg="#ffffff00" />
        </SliderTrack>
        <SliderThumb boxSize={6} borderColor={Equity_Blue} />

        {scaleMax <= 10 &&
          Array.from(Array(scaleMax + 1).keys()).map((idx) => {
            return (
              <SliderMark
                key={idx}
                value={idx}
                my="15px"
                ml={{ base: "-4px", md: "-4px" }}
                fontSize={{ base: "12px", md: "14px" }}
                color={textColor}
                cursor={"pointer"}
                onClick={() => {
                  handleOnChange(idx);
                }}
                data-testid={`${HVTestId.SliderQuestion.sliderMark}${idx}`}
              >
                {idx}
              </SliderMark>
            );
          })}
      </Slider>
    </HVSliderBox>
  );
};
