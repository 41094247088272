import React from "react";
import { UserCertificateDto } from "../../@headversity/contract";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import dayjs from "dayjs";
import { Box, Flex, Text } from "@chakra-ui/react";
import styled from "styled-components";
import { PublicSiteLink } from "./Shared/PublicSiteLink";

interface CertificateInterface {
  certificate: UserCertificateDto;
  cssClassName?: string;
}

const CertContainer = styled.div`
  position: relative;
  height: 890px;
  width: 1152px;
  * {
    font-family: "Barlow" !important;
  }
`;

export const Certificate = ({
  certificate,
  cssClassName,
}: CertificateInterface) => {
  const { fullName, certName, certDescription, completedDate } = certificate;

  return (
    <CertContainer className={cssClassName ?? "certificate"}>
      <div style={{ position: "absolute", height: "890", width: "1152px" }}>
        <img
          style={{
            position: "absolute",
            top: "0.00in",
            left: "0.00in",
            width: "12.00in",
            height: "9.27in",
          }}
          src="https://cdn.headversity.com/app/cert/vi_1.png"
          alt={""}
        />
        <img
          style={{
            position: "absolute",
            top: "0.00in",
            left: "0.00in",
            width: "12.00in",
            height: "9.27in",
          }}
          src="https://cdn.headversity.com/app/cert/vi_2.png"
          alt={""}
        />
        <img
          style={{
            position: "absolute",
            top: "0.00in",
            left: "4.67in",
            width: "7.33in",
            height: "5.81in",
          }}
          src="https://cdn.headversity.com/app/cert/ri_1.png"
          alt={""}
        />
        <img
          style={{
            position: "absolute",
            top: "2.14in",
            left: "0.00in",
            width: "4.83in",
            height: "7.14in",
          }}
          src="https://cdn.headversity.com/app/cert/ri_2.png"
          alt={""}
        />
        <img
          style={{
            position: "absolute",
            top: "0.51in",
            left: "0.69in",
            width: "10.63in",
            height: "8.25in",
          }}
          src="https://cdn.headversity.com/app/cert/ri_3.png"
          alt={""}
        />
        <PublicSiteLink
          url={`https://headversity.com/cert/?utm_source=certificate_page&utm_medium=cert_logo&utm_campaign=${encodeURIComponent(
            certificate.certName as string
          )}`}
        >
          <img
            style={{
              position: "absolute",
              top: "1.19in",
              left: "4.82in",
              width: "2.35in",
              height: "1.41in",
            }}
            src="https://cdn.headversity.com/app/cert/certificate_logo.png"
            alt={""}
          />
        </PublicSiteLink>
        <Flex
          pos={"absolute"}
          h={"100%"}
          w={"100%"}
          left={"0"}
          top={"260px"}
          flexDir={"column"}
          align={"center"}
          gap={"3"}
        >
          <Box>
            <span
              style={{
                fontSize: "16px",
                color: "#4c5b69",
                textAlign: "center",
              }}
            >
              {HVLocalizeStrings.CERTIFICATE_IS_PRESENTED_TO}
            </span>
            <br />
          </Box>
          <Box>
            <span
              style={{
                fontSize: "28px",
                color: "#660b36",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {fullName}
            </span>
            <br />
          </Box>
          <Box mt={"30"}>
            <span
              style={{
                fontSize: "16px",
                color: "#4c5b69",
                textAlign: "center",
              }}
            >
              {HVLocalizeStrings.CERTIFICATE_FOR_COMPLETING}
            </span>
            <br />
          </Box>
          <Box>
            <span
              style={{
                fontSize: "28px",
                color: "#660b36",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <PublicSiteLink
                url={`https://headversity.com/cert/?utm_source=certificate_page&utm_medium=certificate_link&utm_campaign=${encodeURIComponent(
                  certificate.certName as string
                )}`}
              >
                {certName as string}
              </PublicSiteLink>
            </span>
          </Box>
          <br />
          <Box w={"100%"}>
            <Box maxW={"80%"}>
              <Text fontSize={"13px"} color={"#4c5b69"} ml={"260px"}>
                {HVLocalizeStrings.CERTIFICATE_BY_ACHIEVING}
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {`${fullName}`}
                </span>
                {` ${certDescription}`}
              </Text>
              <br />
            </Box>
            <br />
          </Box>
          <Box maxW={"100%"} mt={certificate.companyLogoUrl ? undefined : "25"}>
            <span
              style={{
                fontSize: "11px",
                color: "#4c5b69",
              }}
            >
              {`${HVLocalizeStrings.CERTIFICATE_AWARDED_ON} ${dayjs(
                completedDate.toString()
              )
                .local()
                .locale(HVLocalizeStrings.getLanguage())
                .format(HVLocalizeStrings.DATE_FORMAT)}`}
            </span>
          </Box>
          {certificate.companyLogoUrl && (
            <img
              style={{
                maxHeight: "150px",
                maxWidth: "375px",
              }}
              src={certificate.companyLogoUrl}
              alt={""}
            />
          )}
        </Flex>
      </div>
    </CertContainer>
  );
};
