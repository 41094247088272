import dayjs from "dayjs";
import { HVLocalizeStrings } from "../Localization/HVLocalizeStrings";

export const convert24hoursTo12AmPmHours = (hours: number) => {
  return ((hours + 11) % 12) + 1;
};

export const convert12AmPmHoursTo24hours = (
  hours: number,
  timeOfTheDay: string
) => {
  timeOfTheDay = timeOfTheDay.toUpperCase();
  if (timeOfTheDay == "PM" && hours < 12) {
    return hours + 12;
  }
  if (timeOfTheDay == "AM" && hours == 12) {
    return hours - 12;
  }
  return hours;
};

export const getDaysSince = (date: dayjs.Dayjs) => {
  // strip time and compare date to today
  const today = dayjs().format("YYYY-MM-DD");
  return dayjs(today).diff(date.local().format("YYYY-MM-DD"), "day");
};

export const getDaysSinceOrDate = (date: dayjs.Dayjs, lowerCase?: boolean) => {
  const daysSince = getDaysSince(date);

  // return colloquial string for recent dates
  if (daysSince === 0) {
    return lowerCase
      ? HVLocalizeStrings.TODAY.toLowerCase()
      : HVLocalizeStrings.TODAY;
  } else if (daysSince === 1) {
    return lowerCase
      ? HVLocalizeStrings.YESTERDAY.toLowerCase()
      : HVLocalizeStrings.YESTERDAY;
  } else if (daysSince < 30) {
    return `${daysSince} ${HVLocalizeStrings.DAYS_AGO}`;
  } else {
    return date.format(HVLocalizeStrings.DATE_FORMAT);
  }
};
