import { Select, SelectProps, forwardRef } from "@chakra-ui/react";
import {
  ChakraStylesConfig,
  CSSObjectWithLabel,
  Select as ChakraMultiSelect,
} from "chakra-react-select";
import {
  Black,
  chakraSelectBorderColorFocused,
  darkBluishGray,
  INPUT_BACKGROUND_COLOR,
  INPUT_PRIMARY_TEXT_COLOR,
  REACH_USERS_FILTER_BORDER,
  SELECT_BORDER,
  SELECT_BORDER_FOCUSED,
  SELECT_VALUE_BACKGROUND,
  White,
} from "../../Styles/HeadversityStyle";
import { LocalizationText } from "@headversity/contract/Common/LocalizationText";

const getOptionBackgroundColor = (isFocused: boolean, isSelected: boolean) => {
  if (isFocused) {
    return `${chakraSelectBorderColorFocused} !important`;
  } else if (isSelected) {
    return chakraSelectBorderColorFocused;
  } else {
    return darkBluishGray;
  }
};

interface HVSelectProps extends SelectProps {
  isMultiSelection?: boolean;
  options?: React.ComponentProps<typeof ChakraMultiSelect>["options"];
  onMultiSelectChange?: React.ComponentProps<
    typeof ChakraMultiSelect
  >["onChange"];
  selectedValues?: React.ComponentProps<typeof ChakraMultiSelect>["value"];
  placeHolder?: React.ComponentProps<typeof ChakraMultiSelect>["placeholder"];
  hasBlackBorder?: boolean;
  zIndex?: number;
  usePrimaryColorForPlaceholder?: boolean;
  asLightTheme?: boolean;
}

export const HvSelect = forwardRef<HVSelectProps, "div">((props, ref) => {
  const isGray =
    (props.value === undefined || props.value === "") &&
    (props.defaultValue === undefined || props.defaultValue === "");
  const {
    isMultiSelection,
    options,
    selectedValues,
    onMultiSelectChange,
    placeHolder,
    hasBlackBorder,
    zIndex,
    usePrimaryColorForPlaceholder,
    asLightTheme,
  } = props;
  let colorProp = {};
  if (usePrimaryColorForPlaceholder) {
    colorProp = {
      color: INPUT_PRIMARY_TEXT_COLOR,
    };
  }

  const overrideBackgroundColor = asLightTheme ? White : INPUT_BACKGROUND_COLOR;
  const multiselectStyles: ChakraStylesConfig = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: getOptionBackgroundColor(isFocused, isSelected),
      color: White,
      fontWeight: "500",
      whiteSpace: 'nowrap',
    }),
    container: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: overrideBackgroundColor,
      borderRadius: "md",
      border: isFocused ? SELECT_BORDER_FOCUSED : SELECT_BORDER,
    }),
    placeholder: (provided) => ({
      ...provided,
      backgroundColor: overrideBackgroundColor,
      color: INPUT_PRIMARY_TEXT_COLOR,
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: `${darkBluishGray} !important`,
      border: "1px solid black",
      padding: "2px",
      boxShadow: "0 0 0 1px darkgray inset",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 5,
    }),
    downChevron: (provided) => ({
      ...provided,
      color: asLightTheme ? Black : INPUT_PRIMARY_TEXT_COLOR,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: SELECT_VALUE_BACKGROUND,
      color: INPUT_PRIMARY_TEXT_COLOR,
    }),
    multiValueRemove: (provided, state) => {
      return (state.data as any).isFixed
        ? {
            ...provided,
            display: "none",
          }
        : provided;
    },
    clearIndicator: (provided) => ({
      ...provided,
      color: asLightTheme ? Black : INPUT_PRIMARY_TEXT_COLOR,
    }),
  };

  multiselectStyles.placeholder = (provided) => ({
    ...provided,
    backgroundColor: overrideBackgroundColor,
    ...colorProp,
  });
  const fallBackColor = isGray ? "gray.400" : INPUT_PRIMARY_TEXT_COLOR;
  if (hasBlackBorder) {
    multiselectStyles.container = (provided) => ({
      ...provided,
      backgroundColor: overrideBackgroundColor,
      borderRadius: "md",
      border: REACH_USERS_FILTER_BORDER,
    });
  }
  multiselectStyles.control = (provided) => ({
    ...provided,
    backgroundColor: overrideBackgroundColor,
    borderRadius: "md",
    "&:hover": {
      backgroundColor: overrideBackgroundColor,
    },
    maxHeight: "66px",
    // make the container scrollable when lots of items are selected
    overflow: "overlay",
    scrollbarWidth: "thin",
  });
  let menuPortalProps = {};
  if (zIndex) {
    menuPortalProps = {
      menuPortalTarget: document.body,
      styles: {
        menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex }),
      },
    };
  }
  let zIndexProp = {};
  if (zIndex) {
    zIndexProp = {
      zIndex: zIndex,
    };
  }

  const formatOptionLabel = ({ label }: any, { context }: any): string => {
    if (context === "value") {
      const hierarchyCharsToRemove = ["\u2003", "\u2517", "\u2501", "\u00A0"];
      const regex = new RegExp(`[${hierarchyCharsToRemove.join("")}]`, "g");

      // remove characters in hierarchyCharsToRemove from the label using regex
      const replaced = label.replace(regex, "");
      return replaced;
    }
    return label;
  };
  return (
    <>
      {isMultiSelection ? (
        <ChakraMultiSelect
          isMulti
          value={selectedValues}
          onChange={onMultiSelectChange}
          variant="filled"
          useBasicStyles
          name="certs"
          options={options}
          placeholder={placeHolder}
          closeMenuOnSelect={false}
          chakraStyles={multiselectStyles}
          selectedOptionStyle="check"
          hideSelectedOptions={false}
          {...menuPortalProps}
          formatOptionLabel={formatOptionLabel}
        />
      ) : (
        <Select
          bg={props.bg ?? INPUT_BACKGROUND_COLOR}
          color={props.color ?? fallBackColor}
          ref={ref}
          height={props.height ?? "50px"}
          {...props}
          {...zIndexProp}
        />
      )}
    </>
  );
});

export interface HvSelectOption {
  value: number;
  label: string | LocalizationText;
  isFixed?: boolean;
}
