import React, { ReactNode } from "react";
import { PDFExport } from "@progress/kendo-react-pdf";

interface PDFViewProps {
  children: ReactNode;
  pdfRef: React.RefObject<PDFExport>;
  landscape?: boolean;
}

const PDFView: React.FC<PDFViewProps> = ({ children, pdfRef, landscape }) => {
  let paperSize = "A4";
  let scale = 0.4;
  if (landscape) {
    paperSize = "auto";
    scale = 1;
  }
  return (
    <div
      style={{
        margin: "40px",
        // left: "0px", for debugging
        left: "-5000px",
        position: "absolute",
        top: "-3508px",
        width: "2480px",
        height: "3508px",
        // zIndex: "20000", for debugging
      }}
      className={"pdfExport"}
    >
      <PDFExport
        ref={pdfRef}
        paperSize={paperSize}
        scale={scale}
        forcePageBreak=".page-break"
        fileName={`download.pdf`}
        landscape={landscape}
      >
        {children}
      </PDFExport>
    </div>
  );
};

export default PDFView;
