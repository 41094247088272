import { useContext } from "react";
import { GlobalContext } from "../../../State/GlobalContext";
import { LargeModal } from "../../Common/LargeModal";
import { PracticeReminder } from "./PracticeReminder";

interface PracticeReminderModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
  startEnabled?: boolean;
}

export const PracticeReminderModal = ({
  open,
  setOpen,
  onClose,
  startEnabled,
}: PracticeReminderModalProps) => {
  const { selectedGoalBgImage } = useContext(GlobalContext);

  return (
    <LargeModal
      open={open}
      setOpen={(value: boolean) => {
        setOpen(value);
        if (onClose) {
          onClose();
        }
      }}
      bgImage={selectedGoalBgImage}
      forceDarkMode={true}
    >
      <PracticeReminder onClose={onClose} startEnabled={startEnabled} />
    </LargeModal>
  );
};
