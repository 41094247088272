import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../State/GlobalContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Text,
  Switch,
  Box,
  useToken,
  Link,
  FormControl,
  FormLabel,
  Flex,
  useBreakpointValue,
  Divider,
} from "@chakra-ui/react";
import {
  Border_Radius,
  Equity_Black,
  MODAL_SECONDARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { SchedulerInputDto } from "@headversity/contract/Dto/SchedulerInputDto";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { EVENTS, track } from "../../../Utils/Analytics";
import ResourceType from "../../../Utils/ResourceType";
import { SchedulerFrequency } from "@headversity/contract/Enums/SchedulerFrequency";
import dayjs from "dayjs";
import { WelcomeModalHeader } from "../Welcome/WelcomeModalHeader";
import { WelcomeModalSubheader } from "../Welcome/WelcomeModalSubheader";
import { ShellContext } from "../../../State/ShellContext";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import { HvTimePicker } from "../../Common/HvDateTimePicker/HvTimePicker";
import { HvSelect } from "../../Common/HvSelect";
import { getFrequencyFromScheduleExpression } from "../../../Utils/CronParserUtil";

interface PracticeReminderProps {
  isHidingGetRemindersSwitch?: boolean;
  onClose?: () => void;
  marginAdjust?: number;
  startEnabled?: boolean;
}

export const PracticeReminder = ({
  isHidingGetRemindersSwitch,
  onClose,
  marginAdjust,
  startEnabled,
}: PracticeReminderProps) => {
  const {
    schedulerReminders,
    saveReminderToServer,
    removeReminderFromServer,
    selectedGoal,
    pushDisabled,
    promptForPushPermission,
  } = useContext(GlobalContext);

  const { showToast } = useContext(ShellContext);

  const [hour, setHour] = useState<number>(8);
  const [minute, setMinute] = useState<number>(0);
  const [frequency, setFrequency] = useState<SchedulerFrequency>(
    SchedulerFrequency.Weekly
  );

  const [getReminders, setGetReminders] = useState<boolean>(true);

  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  useEffect(() => {
    if (schedulerReminders.length > 0) {
      setGetReminders(true);

      const reminder = schedulerReminders[0];

      setHour(dayjs(reminder.start as string).hour());
      setMinute(dayjs(reminder.start as string).minute());

      // parse out cron-like thing
      setFrequency(
        getFrequencyFromScheduleExpression(reminder.scheduleExpression)
      );
    } else if (!isHidingGetRemindersSwitch && !startEnabled) {
      setGetReminders(false);
    }
  }, [schedulerReminders, isHidingGetRemindersSwitch, startEnabled]);

  const saveSchedulerReminder = async () => {
    setIsNextButtonDisabled(true);

    for (const schedulerReminder of schedulerReminders) {
      await removeReminderFromServer(schedulerReminder.id);
    }

    if (!getReminders) {
      if (schedulerReminders.length > 0 && schedulerReminders[0]?.id) {
        track(EVENTS.PracticeReminderRemoved);
      }

      if (!isHidingGetRemindersSwitch) {
        showToast(
          true,
          "reminder-removed",
          HVLocalizeStrings.PRACTICE_REMINDER_REMOVED,
          "bottom"
        );
      }

      return;
    }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const start = [
      dayjs()
        .set("hour", hour)
        .set("minute", minute)
        .set("second", 0)
        .set("millisecond", 0),
    ];

    // weekly on wednesday; monthly on the 15th of each month
    if (frequency === SchedulerFrequency.Weekly) {
      // remind the user on Wednesday (and also on Friday if they still have not done an activity)
      start[0] = start[0].day(3);
      start.push(start[0].day(5));
    } else if (frequency === SchedulerFrequency.Monthly) {
      // remind the user on the 15th (and also on the 25th if they still have not done an activity)
      start[0] = start[0].date(15);
      start.push(start[0].date(25));
    }

    for (let i = 0; i < start.length; i++) {
      const reminder: SchedulerInputDto = {
        resourceId: i + 1, // set resourceId for each reminder so they're uniquely created on backend instead of updating the same one
        resourceType: ResourceType.practiceReminder,
        frequency: frequency,
        repeat: true,
        timeZone: timeZone,
        start: start[i].utc().format("YYYY-MM-DDTHH:mm"),
      };

      await saveReminderToServer(reminder);
    }
    
    track(EVENTS.PracticeReminderSet);

    if (pushDisabled) {
      promptForPushPermission();
    }

    if (!isHidingGetRemindersSwitch) {
      showToast(
        true,
        "reminder-set",
        HVLocalizeStrings.PRACTICE_REMINDER_SAVED,
        "bottom"
      );
    }
  };

  const [fourthTextColor] = useToken("colors", ["FOURTH_TEXT_COLOR"]);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <>
      <Box
        mx={2}
        minH="460px"
        mt={!isWebPlatform() ? "40px" : undefined}
        mb="75px"
      >
        <WelcomeModalHeader useTheme={!selectedGoal}>
          {HVLocalizeStrings.CHALLENGE_YOURSELF}{" "}
          <b style={{ color: fourthTextColor }}>
            {HVLocalizeStrings.CONTINUE_LEVELING_UP}
          </b>
          .
        </WelcomeModalHeader>
        <WelcomeModalSubheader useTheme={!selectedGoal}>
          <Text fontSize={isDesktop ? undefined : "md"}>
            {HVLocalizeStrings.CHALLENGE_MESSAGE}
          </Text>
          <Divider mt={6} mb={2} />
          {!isHidingGetRemindersSwitch && (
            <FormControl display="flex" alignItems={"flex-end"} gap="5px">
              <FormLabel htmlFor="challenge-enabled" mb="0" fontSize="16px">
                {HVLocalizeStrings.PRACTICE_REMINDER_REMINDERS}
              </FormLabel>
              <Switch
                mt="5"
                size={"md"}
                isChecked={getReminders}
                colorScheme={"blue"}
                onChange={(e: any) => {
                  const c = Boolean(e.target.checked);

                  track(EVENTS.PracticeReminderEnableToggle, {
                    HV_Enabled: c,
                  });

                  setGetReminders(c);
                }}
                id="challenge-enabled"
              />
            </FormControl>
          )}
        </WelcomeModalSubheader>

        {getReminders && (
          <Box color="white">
            <Flex flexDir={"column"} mt={"5"}>
              <Text
                color={selectedGoal ? "white" : MODAL_SECONDARY_TEXT_COLOR}
                mb={2}
              >
                {HVLocalizeStrings.PRACTICE_EVERY}
                {HVLocalizeStrings.COLON}
              </Text>

              <HvSelect
                w="200px"
                value={frequency}
                onChange={(x) => {
                  const f = parseInt(x.target.value) as SchedulerFrequency;

                  track(EVENTS.PracticeReminderFrequencyChanged, {
                    HV_Frequency: f,
                  });

                  setFrequency(f);
                }}
              >
                {[
                  SchedulerFrequency.Daily,
                  SchedulerFrequency.Weekly,
                  SchedulerFrequency.Monthly,
                ].map((freq) => {
                  return (
                    <option
                      key={freq}
                      value={freq}
                      selected={frequency === freq}
                    >
                      {freq === SchedulerFrequency.Daily
                        ? HVLocalizeStrings.STREAK_DAY
                        : freq === SchedulerFrequency.Weekly
                        ? HVLocalizeStrings.STREAK_WEEK
                        : HVLocalizeStrings.STREAK_MONTH}
                    </option>
                  );
                })}
              </HvSelect>

              <Text
                color={selectedGoal ? "white" : MODAL_SECONDARY_TEXT_COLOR}
                mb={2}
                mt={5}
              >
                {HVLocalizeStrings.REMIND_ME}{" "}
                {frequency === SchedulerFrequency.Daily
                  ? HVLocalizeStrings.STREAK_DAILY.toLowerCase()
                  : frequency === SchedulerFrequency.Weekly
                  ? HVLocalizeStrings.STREAK_WEEKLY.toLowerCase()
                  : HVLocalizeStrings.STREAK_MONTHLY.toLowerCase()}{" "}
                {HVLocalizeStrings.STREAK_AT}
                {HVLocalizeStrings.COLON}
              </Text>

              <HvTimePicker
                onChange={(time: string) => {
                  const parts = time.split(":");
                  setHour(parseInt(parts[0]));
                  setMinute(parseInt(parts[1]));
                }}
                time={hour + ":" + minute}
              />
            </Flex>
            <WelcomeModalSubheader useTheme={!selectedGoal}>
              <Box
                mt="30px"
                p="20px"
                bgColor={"#ffffff22"}
                borderRadius={Border_Radius}
              >
                <Text
                  fontSize={isDesktop ? "15px" : "14px"}
                  dangerouslySetInnerHTML={{
                    __html:
                      frequency === SchedulerFrequency.Daily
                        ? HVLocalizeStrings.STREAK_DAILY_MESSAGE
                        : frequency === SchedulerFrequency.Weekly
                        ? HVLocalizeStrings.STREAK_WEEKLY_MESSAGE
                        : HVLocalizeStrings.STREAK_MONTHLY_MESSAGE,
                  }}
                />
              </Box>
            </WelcomeModalSubheader>
          </Box>
        )}
      </Box>

      <BottomActionBar
        showOptions={false}
        marginAdjust={marginAdjust}
        isNextButtonDisabled={() => isNextButtonDisabled}
        nextButtonText={HVLocalizeStrings.SAVE}
        onNextButtonClick={() => {
          saveSchedulerReminder();

          if (onClose) {
            onClose();
          }
        }}
        actionAreaExtra={
          isHidingGetRemindersSwitch ? (
            <Link
              textDecoration={"underline"}
              fontSize="14px"
              color={Equity_Black}
              onClick={(e) => {
                track(EVENTS.PracticeReminderSkip);

                if (onClose) {
                  onClose();
                }
              }}
            >
              {HVLocalizeStrings.SKIP}
            </Link>
          ) : undefined
        }
      />
    </>
  );
};
