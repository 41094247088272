import {
  Box,
  Center,
  Divider,
  Image,
  Link,
  List,
  ListItem,
  Spinner,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import {
  GoalDto,
  MicroLessonDto,
  NanoPracticeDto,
  UserDto,
  UserScoreDto,
} from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import { getSkillsHistory } from "../../../Api/Solo/SkillsApi";
import { GlobalContext, TrainingType } from "../../../State/GlobalContext";
import { getFeedbackQuestionAnswers, getKey } from "../../../Utils/Helpers";
import {
  getBgImageSource,
  getSuggestedGoalFromHeadscanResponses,
  saveSelectedGoal,
} from "../../../Utils/SkillsUtil";
import { getDaysSince } from "../../../Utils/TimeUtils";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { LargeModal } from "../../Common/LargeModal";
import { StepPanel } from "../../Common/StepPanel";
import { GoalCheckInChart } from "./GoalCheckInChart";
import dayjs from "dayjs";
import { HeadScanQuestionModal } from "../HeadScan/HeadScanQuestionModal";
import { StepSelectGoal } from "../../Shared/Welcome/StepSelectGoal";
import {
  HVLocalizeStrings,
  HVLocalizeStringsObj,
} from "../../../Localization/HVLocalizeStrings";
import { WelcomeModalHeader } from "../../Shared/Welcome/WelcomeModalHeader";
import { WelcomeModalSubheader } from "../../Shared/Welcome/WelcomeModalSubheader";
import { HvSlideFade } from "../../Common/HvSlideFade";
import { FeedbackPanel } from "../../Common/FeedbackPanel";
import { EVENTS, track } from "../../../Utils/Analytics";
import _ from "lodash";
import { getFirstNameFromSelf } from "../../../Utils/LoginUtil";
import { ShellContext } from "../../../State/ShellContext";
import { StepStartTraining } from "../../Shared/Welcome/StepStartTraining";
import { Invert_Filter } from "../../../Styles/HeadversityStyle";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  isWelcomeBack?: boolean;
  skipGoalReview?: boolean;
}
export const GoalCheckInModal = ({
  open,
  setOpen,
  isWelcomeBack,
  skipGoalReview,
}: Props) => {
  const {
    company,
    goals,
    selectedGoal,
    setGoalToServer,
    self,
    userSkillStats,
    saveGoalQuestionAnswersToServer,
    selectedUserLanguage,
    openNextActivity,
  } = useContext(GlobalContext);

  const {
    setIsGoalCheckInModalOpen,
    setIsGoalOverviewModalOpen,
    setIsGoalOverviewSeeAllGoals,
  } = useContext(ShellContext);

  const [curStep, setCurStep] = useState(skipGoalReview ? 4 : 1);

  const [userScoreHistory, setUserScoreHistory] = useState<UserScoreDto[]>([]);
  const [isAllTime, setIsAllTime] = useState(true);
  const [isHeadscanModalOpen, setIsHeadscanModalOpen] = useState(false);
  const [hasAnsweredHeadscan, setHasAnsweredHeadscan] = useState(false);

  const [suggestedGoal, setSuggestedGoal] = useState<GoalDto>();
  const [seeAllGoals, setSeeAllGoals] = useState(false);

  const [selectedTrainingType, setSelectedTrainingType] =
    useState<TrainingType>(TrainingType.Practice);
  const [selectedPractice, setSelectedPractice] = useState<NanoPracticeDto>();
  const [selectedLesson, setSelectedLesson] = useState<MicroLessonDto>();

  const [showNextUp, setShowNextUp] = useState(false);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const totalSteps = 4;
  const totalStepsToCount = totalSteps - 1;

  const getScoresBySkill = (
    userScoreHistory: UserScoreDto[],
    last30: boolean
  ) => {
    if (userScoreHistory.length === 0) {
      return {
        scoresBySkillId: {},
        totalPoints: 0,
      };
    }

    // subtract the previous day's points from the current day's points
    const bySkill = _.groupBy(userScoreHistory, (item) => item.skillId);

    for (const x of Object.keys(bySkill)) {
      bySkill[x] = bySkill[x].map((item, index) => {
        if (index === 0) {
          return { ...item, points: item.points };
        } else {
          const p = item.points - bySkill[x][index - 1].points;
          return {
            ...item,
            points: p < 0 ? 0 : p,
          };
        }
      });
    }

    // remove 0s
    for (const x of Object.keys(bySkill)) {
      bySkill[x] = bySkill[x].filter((item) => item.points > 0);
    }

    if (last30) {
      // remove data from each skill that is older than 30 days
      for (const x of Object.keys(bySkill)) {
        bySkill[x] = bySkill[x].filter(
          (item) => getDaysSince(dayjs(item.day)) <= 30
        );
      }
    }

    let total = 0;
    for (const x of Object.keys(bySkill)) {
      for (const dp of bySkill[x]) {
        total += dp.points;
      }
    }

    return {
      scoresBySkillId: bySkill,
      totalPoints: total,
    };
  };

  useEffect(() => {
    if (!selectedGoal) return;

    // get goal history
    getSkillsHistory(getKey()).then((response) => {
      const userScoreHistory = response.data;

      setUserScoreHistory(userScoreHistory);
    });
  }, [selectedGoal]);

  useEffect(() => {
    switch (curStep) {
      case 1:
        track(EVENTS.GoalCheckInModalOpened);
        break;
      case 2:
        track(EVENTS.GoalCheckInModalSummaryStepReached);
        break;
      case 3:
        track(EVENTS.GoalCheckInModalFeedbackStepReached);
        break;
      case 4:
        if (skipGoalReview) {
          track(EVENTS.GoalModalNewPursuitOpened, {
            HV_IsWelcomeBack: isWelcomeBack,
          });
        } else {
          track(EVENTS.GoalCheckInModalNextGoalStepReached);
        }
        break;
    }
  }, [curStep]);

  const formatGoalDescription = (desc: string | undefined | null) => {
    if (!desc) return "";

    return desc.toString().toLowerCase().replace("your", "my");
  };

  const feedbackItems = !selectedGoal
    ? []
    : [
        HVLocalizeStrings.FOUND_PURSUIT_RELEVANT.replace(
          "{0}",
          selectedGoal.name.toString()
        ),
        HVLocalizeStrings.BETTER_UNDERSTAND.replace(
          "{0}",
          formatGoalDescription(selectedGoal.description.toString())
        ),
      ];

  const feedbackItemsEng = !selectedGoal
    ? []
    : [
        HVLocalizeStringsObj.en.FOUND_PURSUIT_RELEVANT.replace(
          "{0}",
          selectedGoal.name_loc?.en ?? ""
        ),
        HVLocalizeStringsObj.en.BETTER_UNDERSTAND.replace(
          "{0}",
          formatGoalDescription(selectedGoal.description_loc?.en)
        ),
      ];

  const goalToUse = suggestedGoal ? suggestedGoal : selectedGoal;

  const { scoresBySkillId, totalPoints } = getScoresBySkill(
    userScoreHistory,
    !isAllTime
  );

  const [fourthTextColor] = useToken("colors", ["FOURTH_TEXT_COLOR"]);

  return !skipGoalReview && (!selectedGoal || !goalToUse) ? (
    <></>
  ) : (
    <>
      <LargeModal
        open={open}
        setOpen={setOpen as any}
        bgImage={
          !goalToUse
            ? getBgImageSource(goals[0].imageUrl.toString(), isDesktop)
            : getBgImageSource(goalToUse.imageUrl.toString(), isDesktop)
        }
        forceDarkMode={true}
      >
        <Box>
          {!skipGoalReview && selectedGoal && curStep <= 3 ? (
            <StepPanel
              step={curStep - 1}
              totalStep={totalStepsToCount - 1}
              value={(curStep - 1) * (100 / (totalStepsToCount - 1))}
              showProgressBar={curStep > 1}
              showBackArrow={curStep > 1}
              forceDarkMode={true}
              onBackClick={() => {
                setCurStep(curStep - 1);
              }}
              noMarginTop={curStep === 1}
            >
              <Box m={2} h={isDesktop ? "500px" : undefined} color="white">
                {curStep === 1 && (
                  <WelcomeModalHeader>
                    {isWelcomeBack && (
                      <WelcomeBackMessage isDesktop={isDesktop} self={self} />
                    )}
                    {HVLocalizeStrings.LETS_REVIEW_PROGRESS}
                    <b style={{ color: fourthTextColor }}>
                      {selectedGoal.name.toString()}
                    </b>
                    .
                  </WelcomeModalHeader>
                )}

                {userScoreHistory.length === 0 ? (
                  <Center mt={8}>
                    <Spinner color="white" />
                  </Center>
                ) : (
                  <>
                    <HvSlideFade fadeIn={curStep === 1} delay={1}>
                      <WelcomeModalSubheader mt={4}>
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: `${HVLocalizeStrings.HERES_AN_OVERVIEW.replace(
                              "{0}",
                              totalPoints.toString()
                            )}
                          ${
                            isAllTime
                              ? HVLocalizeStrings.SO_FAR
                              : HVLocalizeStrings.OVER_LAST_30
                          } ${HVLocalizeStrings.PURSUIT_IN_THIS_PURSUIT}.`,
                          }}
                        />
                      </WelcomeModalSubheader>
                      <Box my={6} mx={isDesktop ? "50px" : undefined}>
                        <GoalCheckInChart
                          goal={selectedGoal}
                          scoresBySkillId={scoresBySkillId}
                          onToggleRange={(isAllTime) => {
                            setIsAllTime(isAllTime);
                          }}
                        />
                      </Box>
                    </HvSlideFade>

                    <HvSlideFade fadeIn={curStep === 2}>
                      <WelcomeModalSubheader mt={0}>
                        {HVLocalizeStrings.CONCEPTS_LEARNED_AND_APPLIED}
                      </WelcomeModalSubheader>
                      <Box>
                        <Text mt={6}>
                          <Text
                            as="span"
                            dangerouslySetInnerHTML={{
                              __html: HVLocalizeStrings.PRACTICE_TECHNIQUES,
                            }}
                          />
                        </Text>
                        <List
                          listStyleType={"disc"}
                          m={"30px"}
                          mt="15px"
                          fontSize="14px"
                        >
                          {selectedGoal.summaries.practices.map((item, idx) => {
                            return (
                              <ListItem key={idx}>
                                {(item as any)[selectedUserLanguage]}
                              </ListItem>
                            );
                          })}
                        </List>
                        <Divider my={6} />
                        <Text mt={4}>
                          <Text
                            as="span"
                            dangerouslySetInnerHTML={{
                              __html: HVLocalizeStrings.LESSON_TOPICS,
                            }}
                          />
                        </Text>
                        <List
                          listStyleType={"disc"}
                          m={"30px"}
                          mt="15px"
                          fontSize="14px"
                        >
                          {selectedGoal.summaries.lessons.map((item, idx) => {
                            return (
                              <ListItem key={idx}>
                                {(item as any)[selectedUserLanguage]}
                              </ListItem>
                            );
                          })}
                        </List>
                      </Box>
                    </HvSlideFade>

                    <HvSlideFade fadeIn={curStep === 3}>
                      <WelcomeModalSubheader mt={0}>
                        {HVLocalizeStrings.HOW_DID_YOU_FEEL_ABOUT_TRAINING}
                      </WelcomeModalSubheader>
                      <Center mt="20px">
                        <FeedbackPanel
                          items={feedbackItems}
                          items_eng={feedbackItemsEng}
                          onResponse={(englishText: string, result: string) => {
                            // track
                            track(EVENTS.GoalCheckInFeedback, {
                              HV_Goal: selectedGoal.name,
                              HV_GoalId: selectedGoal.id,
                              HV_Question: englishText,
                              HV_Response: result === "like" ? "up" : "down",
                            });

                            // save
                            saveGoalQuestionAnswersToServer(
                              selectedGoal.id,
                              getFeedbackQuestionAnswers(englishText, result)
                            );
                          }}
                          onFeedback={(text: string) => {
                            // track
                            track(EVENTS.GoalCheckInFeedbackComments, {
                              HV_Goal: selectedGoal.name,
                              HV_GoalId: selectedGoal.id,
                            });

                            // save
                            saveGoalQuestionAnswersToServer(
                              selectedGoal.id,
                              getFeedbackQuestionAnswers(
                                "FeedbackComments",
                                text
                              )
                            );
                          }}
                          onComplete={() => {
                            setCurStep(curStep + 1);
                          }}
                        />
                      </Center>
                    </HvSlideFade>
                  </>
                )}
              </Box>
            </StepPanel>
          ) : (
            <>
              {showNextUp ? (
                <Box h={isDesktop ? "575px" : undefined}>
                  <HvSlideFade fadeIn={true}>
                    <StepStartTraining
                      isPostWelcome={true}
                      selectedTrainingType={selectedTrainingType}
                      setSelectedTrainingType={setSelectedTrainingType}
                      selectedPractice={selectedPractice}
                      setSelectedPractice={setSelectedPractice}
                      selectedLesson={selectedLesson}
                      setSelectedLesson={setSelectedLesson}
                    />
                  </HvSlideFade>
                </Box>
              ) : (
                <Box mt={"40px"} h={isDesktop ? "575px" : undefined}>
                  <HvSlideFade
                    fadeIn={
                      (skipGoalReview || curStep === totalSteps) &&
                      !hasAnsweredHeadscan
                    }
                  >
                    <WelcomeModalHeader>
                      {isWelcomeBack ? (
                        <>
                          <Center mb="40px">
                            <Image
                              mx={2}
                              mb={1}
                              display={"inline"}
                              src={
                                selectedUserLanguage === "fr"
                                  ? "https://cdn.headversity.com/app/resources/other/solo-logo-fr.png"
                                  : selectedUserLanguage === "es"
                                  ? "https://cdn.headversity.com/app/resources/other/solo-logo-es.png"
                                  : "https://cdn.headversity.com/app/resources/other/solo-logo.png"
                              }
                              h={"90px"}
                              filter={Invert_Filter}
                            />
                          </Center>
                          <Text>
                            <WelcomeBackMessage
                              isDesktop={isDesktop}
                              self={self}
                              asSpan={true}
                            />
                            {HVLocalizeStrings.PURSUE_NEXT}
                          </Text>
                        </>
                      ) : (
                        <>
                          <b>{HVLocalizeStrings.OK}</b>{" "}
                          {HVLocalizeStrings.PURSUE_NEXT}
                        </>
                      )}
                    </WelcomeModalHeader>
                    <WelcomeModalSubheader>
                      {HVLocalizeStrings.PURSUE_NEXT_HOW}
                    </WelcomeModalSubheader>
                  </HvSlideFade>

                  <HvSlideFade
                    fadeIn={curStep === totalSteps && hasAnsweredHeadscan}
                  >
                    <StepSelectGoal
                      suggestedGoal={suggestedGoal}
                      onGoalChanged={(goal) => {
                        setSuggestedGoal(goal);
                      }}
                      seeAllGoals={seeAllGoals}
                    />
                  </HvSlideFade>
                </Box>
              )}
            </>
          )}

          <BottomActionBar
            nextButtonText={
              hasAnsweredHeadscan
                ? HVLocalizeStrings.START_TRAINING
                : HVLocalizeStrings.NEXT
            }
            onNextButtonClick={() => {
              if (showNextUp) {
                openNextActivity(
                  selectedTrainingType,
                  selectedPractice,
                  selectedLesson
                );

                setOpen(false);
              } else if (hasAnsweredHeadscan) {
                if (suggestedGoal) {
                  saveSelectedGoal(
                    setGoalToServer,
                    userSkillStats,
                    suggestedGoal,
                    selectedGoal
                  );
                }

                setShowNextUp(true);
              } else if (curStep === totalSteps) {
                setIsHeadscanModalOpen(true);
              } else {
                setCurStep(curStep + 1);
              }
            }}
            isNextButtonDisabled={() =>
              (!skipGoalReview && userScoreHistory.length === 0) ||
              curStep === 3
            }
            actionAreaExtra={
              curStep === 1 && !isWelcomeBack ? (
                <Link
                  textDecoration={"underline"}
                  fontSize="14px"
                  onClick={(e) => {
                    track(EVENTS.GoalChooseDifferent, {
                      HV_From: "CheckInStart",
                    });

                    setIsGoalOverviewSeeAllGoals(true);
                    setIsGoalOverviewModalOpen(true);
                    setIsGoalCheckInModalOpen(false);
                  }}
                >
                  {HVLocalizeStrings.PURSUIT_DIFFERENT}
                </Link>
              ) : curStep === totalSteps &&
                selectedGoal &&
                !hasAnsweredHeadscan &&
                !showNextUp ? (
                <Link
                  textDecoration={"underline"}
                  fontSize="14px"
                  onClick={(e) => {
                    track(EVENTS.GoalCheckInModalKeepCurrentPursuit);

                    setShowNextUp(true);
                  }}
                >
                  {HVLocalizeStrings.KEEP_CURRENT_PURSUIT}
                </Link>
              ) : hasAnsweredHeadscan && !seeAllGoals && !showNextUp ? (
                <Link
                  textDecoration={"underline"}
                  fontSize="14px"
                  onClick={(e) => {
                    track(EVENTS.GoalChooseDifferent, {
                      HV_From: "CheckInEnd",
                    });

                    setSeeAllGoals(true);
                  }}
                >
                  {selectedGoal
                    ? HVLocalizeStrings.PURSUIT_DIFFERENT
                    : HVLocalizeStrings.PURSUIT_OWN}
                </Link>
              ) : undefined
            }
          />
        </Box>
      </LargeModal>
      <HeadScanQuestionModal
        open={isHeadscanModalOpen}
        setOpen={() => {
          setIsHeadscanModalOpen(false);
        }}
        skillIds={[]}
        isWelcomeModal={true}
        onSaveComplete={(responses) => {
          const goal = getSuggestedGoalFromHeadscanResponses(
            responses,
            goals,
            company,
            selectedGoal
          );

          setSuggestedGoal(goal);

          setHasAnsweredHeadscan(true);
        }}
      />
    </>
  );
};

interface WelcomeBackMessageProps {
  isDesktop: boolean | undefined;
  self: UserDto | null;
  asSpan?: boolean;
}
const WelcomeBackMessage = ({
  isDesktop,
  self,
  asSpan,
}: WelcomeBackMessageProps) => {
  return (
    <>
      <Text
        as={isDesktop || asSpan ? "span" : undefined}
        mb={2}
        dangerouslySetInnerHTML={{
          __html: `<b>${
            HVLocalizeStrings.WELCOME_BACK
          }</b>${getFirstNameFromSelf(self)}!`,
        }}
      />{" "}
    </>
  );
};
