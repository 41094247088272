import {
  Box,
  Flex,
  Spinner,
  Text,
  forwardRef,
  VStack,
  Button,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  EligibilityImportType,
  ReachUsersContext,
  UserModalOpen,
} from "../../../../State/Reach/ReachUsersContext";
import { IShellProvider, ShellContext } from "../../../../State/ShellContext";
import {
  Mid_Blue,
  MODAL_SECONDARY_TEXT_COLOR,
  MODAL_THIRD_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { REACH_EVENTS, track } from "../../../../Utils/Analytics";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { LargeModal } from "../../../Common/LargeModal";
import { StepPanel } from "../../../Common/StepPanel";
import StepUploadType from "../UserImportSteps/StepUploadType";
import StepUploadFile from "../UserImportSteps/StepUploadFile";
import StepAssignCert from "../UserImportSteps/StepAssignCert";
import { CertDto } from "../../../../@headversity/contract";
import StepInfoErrors from "../UserImportSteps/StepInfoErrors";
import {
  ConfirmationButtons,
  ConfirmationDialog,
} from "../../../Common/ConfirmationDialog";
import { GlobalContext } from "../../../../State/GlobalContext";
import { HVTestId } from "../../../../Testing/dataTestIds";

enum Step {
  uploadType = 1,
  uploadFile = 2,
  cert = 3,
  infoErrors = 4,
}

interface ReachBulkUserModalProps {
  isOpen: boolean;
}

const UserBulkModal = forwardRef((props: ReachBulkUserModalProps, ref) => {
  const { isOpen } = props;

  const { soloEnabled, teamEnabled } = useContext(GlobalContext);
  const { confirmationDialogRef, showToast } =
    useContext<IShellProvider>(ShellContext);
  const {
    setIsUploadingFile,
    isUploadingFile,
    uploadEligibilityFile,
    confirmEligibilityFileJob,
    pendingJob,
    clearBulkModal,
    companyCerts,
    setUserModalOpen,
  } = useContext(ReachUsersContext);
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [importType, setImportType] = useState<EligibilityImportType>(
    EligibilityImportType.CHANGE_FILE
  );
  const [step, setStep] = useState<number>(Step.uploadType);
  const [disabledAnimatedButton, setDisabledAnimatedButton] =
    useState<boolean>(false);
  const [selectedCerts, setSelectedCerts] = useState<CertDto[]>([]);
  const [showSkipForNow, setShowSkipForNow] = useState<boolean>(false);

  useEffect(() => {
    switch (step) {
      case Step.uploadType:
        setDisabledAnimatedButton(false);
        break;
      case Step.uploadFile:
        setDisabledAnimatedButton(uploadFile === null || isUploadingFile);
        break;
      case Step.cert:
        setDisabledAnimatedButton(selectedCerts.length === 0);
        break;
      case Step.infoErrors:
        const preProcessErrors = pendingJob?.fileInfo.preProcessErrors;
        setDisabledAnimatedButton(
          preProcessErrors !== undefined &&
            Object.keys(preProcessErrors).length > 0
        );
        break;
    }
  }, [step, uploadFile, isUploadingFile, pendingJob, selectedCerts]);

  const checkCertOnlyUsers = (file: any) => {
    if (soloEnabled || teamEnabled || companyCerts.length === 0) {
      setShowSkipForNow(true);
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      const result = e.target?.result;
      if (result) {
        const lines = (result as string).trim().split("\n");
        const roleIndex = 2;
        for (let i = 1; i < lines.length; i++) {
          const user = lines[i].split(",");
          const role = user[roleIndex].trim().toLowerCase();
          if (role !== "admin") {
            setShowSkipForNow(false);
            return;
          }
        }
        setShowSkipForNow(true);
      }
    };
    reader.readAsText(file);
  };

  const handleFileChange = async (file: any) => {
    setUploadFile(file);
    checkCertOnlyUsers(file);
  };

  const handleUploadFile = async (file: any) => {
    setIsUploadingFile(true);
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const certAssignments = selectedCerts.map((cert) => cert.id);
      await uploadEligibilityFile(file, importType, certAssignments, timezone);
    } catch (e) {
      clearBulkModal();
      setUploadFile(null);
    } finally {
      setIsUploadingFile(false);
    }
  };

  const resetStates = () => {
    setUploadFile(null);
    setSelectedCerts([]);
    setStep(Step.uploadType);
  };

  const handleBulkUploadConfirm = async () => {
    setIsUploadingFile(true);
    if (pendingJob) {
      const details = pendingJob.fileInfo.details;
      track(REACH_EVENTS.ReachUsersUploadedCSVFile, details);
      showToast(
        true,
        "self-serve-user-file-process-started",
        HVLocalizeStrings.REACH_USERS_PROCESSING_STARTED
      );
      await confirmEligibilityFileJob(pendingJob.id);
      clearBulkModal();
      setIsUploadingFile(false);
      setUserModalOpen(UserModalOpen.NONE);
      resetStates();
    }
  };

  const handleOnClose = () => {
    clearBulkModal();
    setIsUploadingFile(false);
    resetStates();
    setUserModalOpen(UserModalOpen.NONE);
  };

  const handleAnimatedButtonClick = () => {
    switch (step) {
      case Step.uploadType:
        setStep(Step.uploadFile);
        break;
      case Step.uploadFile:
        if (companyCerts.length > 0) {
          setStep(Step.cert);
        } else {
          handleUploadFile(uploadFile).then(() => setStep(Step.infoErrors));
        }
        break;
      case Step.cert:
        handleUploadFile(uploadFile).then(() => setStep(Step.infoErrors));
        break;
      case Step.infoErrors:
        const importStats = pendingJob?.fileInfo.details;
        if (
          importType === EligibilityImportType.FULL_LIST ||
          (importType === EligibilityImportType.CHANGE_FILE &&
            importStats?.usersRemoved)
        ) {
          const message =
            importType === EligibilityImportType.FULL_LIST
              ? `${HVLocalizeStrings.REACH_USERS_FULL_LIST_DELETE_WARNING} ${HVLocalizeStrings.REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_3}`
              : `${HVLocalizeStrings.REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_1} ${importStats?.usersRemoved} ${HVLocalizeStrings.REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_2} ${HVLocalizeStrings.REACH_USERS_FULL_LIST_DELETE_CONFIRMATION_3}`;
          confirmationDialogRef.current.confirmation(
            HVLocalizeStrings.REACH_USERS_BULK_UPLOAD,
            message,
            ConfirmationButtons.YesNo,
            handleBulkUploadConfirm
          );
        } else {
          handleBulkUploadConfirm();
        }
        break;
    }
  };

  const handleCertSelections = (certIds: number[]) => {
    const assignedCerts = companyCerts.filter((cert) =>
      certIds.includes(cert.id)
    );
    setSelectedCerts(assignedCerts);
  };

  const handleBackClick = () => {
    if (step === Step.infoErrors && companyCerts.length === 0) {
      setStep((step) => step - 2);
    } else {
      setStep((step) => step - 1);
    }
  };

  const totalSteps = companyCerts.length > 0 ? 4 : 3;

  return (
    <LargeModal
      open={isOpen}
      setOpen={() => {}}
      onCloseTasks={handleOnClose}
      dataTestId={HVTestId.UserBulkModal.modal}
    >
      <Box>
        <StepPanel
          step={step}
          totalStep={totalSteps}
          value={(step / totalSteps) * 100}
          showProgressBar={true}
          showBackArrow={step > Step.uploadType}
          onBackClick={handleBackClick}
        >
          <Flex
            flexDirection={"column"}
            gap={6}
            mx={6}
            color={MODAL_SECONDARY_TEXT_COLOR}
          >
            {step === Step.uploadType && (
              <StepUploadType
                importType={importType}
                setImportType={setImportType}
              />
            )}
            {step === Step.uploadFile && (
              <StepUploadFile
                ref={ref}
                handleFileChange={handleFileChange}
                uploadFile={uploadFile}
              />
            )}
            {step === Step.cert && companyCerts.length > 0 && (
              <StepAssignCert
                companyCerts={companyCerts}
                handleCertSelections={handleCertSelections}
              />
            )}
            {step === Step.infoErrors && (
              <StepInfoErrors
                pendingJob={pendingJob}
                showCertStats={companyCerts.length > 0}
              />
            )}
            <Flex my={3} flexDirection={"column"} gap={4} alignItems={"center"}>
              <AnimatedButton
                colorSet={AnimatedButtonColorSet.Fourth}
                w={210}
                text={HVLocalizeStrings.CONTINUE}
                onClick={() => handleAnimatedButtonClick()}
                disabled={disabledAnimatedButton}
                dataTestId={HVTestId.UserBulkModal.continue}
              />
              {isUploadingFile && (
                <VStack pt={4}>
                  <Spinner size={"xl"} color={Mid_Blue} />
                  <Text fontSize={"lg"} fontWeight={600}>
                    {HVLocalizeStrings.REACH_USERS_UPLOADING_WAIT}
                  </Text>
                </VStack>
              )}
              {step === Step.cert && showSkipForNow && (
                <Button
                  className="button-link"
                  color={MODAL_THIRD_TEXT_COLOR}
                  fontSize="sm"
                  mt={2}
                  onClick={() => {
                    setSelectedCerts([]);
                    handleUploadFile(uploadFile).then(() =>
                      setStep(Step.infoErrors)
                    );
                  }}
                  data-testid={HVTestId.UserBulkModal.skipCerts}
                >
                  {HVLocalizeStrings.REACH_USERS_SKIP_FOR_NOW}
                </Button>
              )}
              {step === Step.infoErrors && pendingJob && (
                <Button
                  className="button-link"
                  color={MODAL_THIRD_TEXT_COLOR}
                  fontSize="sm"
                  mt={2}
                  onClick={() => {
                    setStep(Step.uploadFile);
                  }}
                >
                  {HVLocalizeStrings.REACH_USERS_BACK_TO_UPLOAD_PAGE}
                </Button>
              )}
            </Flex>
          </Flex>
        </StepPanel>
      </Box>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </LargeModal>
  );
});

export default UserBulkModal;
