import React, { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { LargeModal } from "../../Common/LargeModal";
import {
  HeadScanQuestionContent,
  HeadScanResponse,
} from "./HeadScanQuestionContent";
import { PagerState } from "../../Common/Pager";
import { Box, Center, useBreakpointValue } from "@chakra-ui/react";
import {
  Border_Radius,
  MODAL_BACKGROUND_COLOR,
} from "../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../Utils/Analytics";
import { HvSpinner } from "../../Common/HvSpinner";
import { StepConfirmSkills } from "../../Shared/Welcome/StepConfirmSkills";
import { AnimatedButton } from "../../Common/AnimatedButton";
import { StepHeadscanComplete } from "../../Shared/Welcome/StepHeadscanComplete";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { HVTestId } from "../../../Testing/dataTestIds";
import { HeadscanQuestionDto } from "../../../@headversity/contract";
import dayjs from "dayjs";
import _ from "lodash";
import { getGoalsToUse, getScoredSkillIds } from "../../../Utils/SkillsUtil";

interface HeadScanQuestionModalProps {
  skillIds?: number[] | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSaveComplete?: (responses: HeadScanResponse[]) => void;
  onStartTraining?: () => void;
  showIntro?: boolean;
  isWelcomeModal?: boolean;
}

export const HeadScanQuestionModal = (props: HeadScanQuestionModalProps) => {
  const {
    skillIds,
    open,
    setOpen,
    onSaveComplete,
    onStartTraining,
    showIntro,
    isWelcomeModal,
  } = props;
  const isMd = useBreakpointValue({ base: false, md: true });

  const {
    headScanQuestions,
    saveHeadScanQuestionAnswerToServer,
    getHeadScanQuestionsAndAnswersFromServer,
    goals,
    selectedGoal,
    setUserStatsToServer,
    userSkillStats,
    company,
    selectedGoalBgImage,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { safeAreaInsets } = useContext<IShellProvider>(ShellContext);

  const [questionPager, setQuestionPager] = useState<PagerState>({
    currentPage: 0,
    totalPage: 1,
    pagePerRow: 1,
    showPageInput: false,
  });

  const [currentQuestion, setCurrentQuestion] = useState<any>(null);
  const [filteredQuestions, setFilteredQuestions] = useState<
    HeadscanQuestionDto[] | null
  >(null);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forceQuestion, setForceQuestion] = useState(false);
  const [showNextQuestion, setShowNextQuestion] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState<HeadScanResponse>();
  const [showOutro, setShowOutro] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [responses, setResponses] = useState<HeadScanResponse[]>([]);

  const fetchDataAndCloseModal = async (saved: boolean) => {
    setLoading(true);

    if (saved) {
      // although points/level won't change, we call setUserStatsToServer to get headscan history and any newly unlocked skill;
      // we also refresh local question responses
      // NOTE:
      //  if we're in the welcome flow, we need to wait for the question responses call (since we use that data for goal suggestion) but we don't need to wait for the stats call
      //  if we're not in the welcome flow (unlocking or re'doing a score), we need to wait for the stats call (which includes headscan history data used for the post-completion chart), but we don't need to wait for the question responses
      if (isWelcomeModal) {
        setUserStatsToServer();
        await getHeadScanQuestionsAndAnswersFromServer();
      } else {
        await setUserStatsToServer();
        getHeadScanQuestionsAndAnswersFromServer();
      }

      if (onSaveComplete) {
        onSaveComplete(responses);
      }
    }

    setLoading(false);

    setForceQuestion(false);

    if (showIntro && saved) {
      track(EVENTS.UnlockSkillCompleted);
      setShowOutro(true);
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      track(EVENTS.HeadscanQuestionModalShown);
    }
  }, [open]);

  useEffect(() => {
    if (selectedResponse) {
      setShowNextQuestion(true);
    }
  }, [selectedResponse]);

  useEffect(() => {
    if (
      skillIds &&
      headScanQuestions?.length > 0 &&
      filteredQuestions === null
    ) {
      let filteredHeadScanQuestions = headScanQuestions.filter((item: any) =>
        skillIds.includes(item.skillCompetency.skillId)
      );

      // if no skills were passed in, we're doing the welcome flow assessment
      if (skillIds.length === 0) {
        // get the initial goals, ordered by priority
        const questionIds = goals
          .filter((x) => x.priority <= 9)
          .sort((a, b) => a.priority - b.priority);

        // find the questions associated with these goals, in order of priority
        for (const q of questionIds) {
          filteredHeadScanQuestions.push(
            headScanQuestions.filter((x) => x.id === q.headscanQuestionId)[0]
          );
        }

        // add a question from Focus and Purpose
        // I feel my life has purpose.
        filteredHeadScanQuestions.push(
          headScanQuestions.filter((x) => x.id === 58)[0]
        );
        // I am able to stay focused.
        filteredHeadScanQuestions.push(
          headScanQuestions.filter((x) => x.id === 67)[0]
        );

        // two place-holders
        filteredHeadScanQuestions.push(headScanQuestions[0]);
        filteredHeadScanQuestions.push(headScanQuestions[0]);
      } else {
        filteredHeadScanQuestions = _.sortBy(
          filteredHeadScanQuestions,
          (item) => {
            const answeredDate =
              item.questionUserResponses.length > 0
                ? dayjs
                    .utc(item.questionUserResponses[0].createdAt as string)
                    .unix()
                : 0;
            return [
              skillIds.indexOf(item.skillCompetencyId),
              answeredDate,
              item.questionOrder,
            ];
          }
        );

        // if this is our first time scoring the skill, don't re-ask any questions we already answered in the last 24 hours
        if (
          skillIds.length === 1 &&
          !getScoredSkillIds(userSkillStats).includes(skillIds[0])
        ) {
          const yesterday = dayjs().utc().add(-24, "hours");

          filteredHeadScanQuestions = filteredHeadScanQuestions.filter(
            (x) =>
              x.questionUserResponses.length === 0 ||
              yesterday.isAfter(
                dayjs.utc(x.questionUserResponses[0].createdAt as string)
              )
          );
        }
      }

      questionPager.totalPage = filteredHeadScanQuestions.length;
      setQuestionPager({ ...questionPager });
      setFilteredQuestions(filteredHeadScanQuestions);
    }
  }, [skillIds, headScanQuestions, filteredQuestions]);

  useEffect(() => {
    if (headScanQuestions?.length > 0) {
      if (skillIds) {
        if (!filteredQuestions) {
          return;
        }
        if (questionPager.currentPage !== filteredQuestions.length) {
          setCurrentQuestion(filteredQuestions[questionPager.currentPage]);
        }
      }
    }
  }, [skillIds, questionPager, filteredQuestions, headScanQuestions]);

  const saveAnswer = useCallback(() => {
    if (!selectedResponse) return;

    setSaving(true);
    responses.push(selectedResponse);

    saveHeadScanQuestionAnswerToServer({
      headscanQuestionId: selectedResponse.questionId,
      questionAlternativeId: selectedResponse.alternativeId,
    }).then((response: any) => {
      setSaving(false);
      if (response?.status === 200) {
        questionPager.currentPage++;
        setQuestionPager({ ...questionPager });

        // did we complete the 4 initial questions?
        if (questionPager.currentPage === 4) {
          // find the lowest response
          const lowest = responses.reduce((prev, current) =>
            prev.scale < current.scale ? prev : current
          );

          // get the goal that matches the lowest response
          const lowestGoal = goals.find(
            (x) => x.headscanQuestionId === lowest.questionId
          );

          if (!lowestGoal) return;

          // if the lowest matches a win theme goal, use it (if no win themes, all goals will apply)
          const goalsToUse = getGoalsToUse(
            goals,
            company?.goalWinThemes,
            selectedGoal
          );

          let goalToUse = goalsToUse.find((x) => x.id === lowestGoal.id);

          // if it doesn't, try to find a closely matching goal
          if (!goalToUse) {
            goalToUse = goalsToUse.find((x) => x.domain === lowestGoal.domain);
          }

          // if still none, use the first org goal
          if (!goalToUse) {
            goalToUse = goalsToUse[0];
          }

          // get the other questions in this goal's domain
          const otherQuestionIds = goals
            .filter((x) => goalToUse && x.domain === goalToUse.domain)
            .map((x) => x.headscanQuestionId);

          const answered = responses.map((x) => x.questionId);

          let filteredHeadScanQuestions = headScanQuestions.filter(
            (x: any) =>
              otherQuestionIds.includes(x.id) && !answered.includes(x.id)
          );

          if (filteredQuestions) {
            filteredQuestions[filteredQuestions.length - 2] =
              filteredHeadScanQuestions[0];
            filteredQuestions[filteredQuestions.length - 1] =
              filteredHeadScanQuestions[1];
          }
        }

        // did we hit the end?
        else if (skillIds && filteredQuestions) {
          if (questionPager.currentPage === filteredQuestions.length) {
            if (skillIds.length > 0) {
              track(EVENTS.RedoAllScoresCompleted);
            }

            skillIds.forEach((x: any) => {
              track(EVENTS.RedoScoreCompleted, { HV_Skill: x });
            });

            fetchDataAndCloseModal(true);
          }
        }
      } else {
        setShowNextQuestion(true);
      }
    });
  }, [questionPager, selectedResponse, filteredQuestions]);

  const shouldShowIntro = showIntro && !forceQuestion && skillIds;

  const isSkillInSelectedGoal =
    skillIds && selectedGoal
      ? selectedGoal.skillIds.some((x) => skillIds.includes(x))
      : false;

  const showGoalImage =
    (shouldShowIntro || forceQuestion) && isSkillInSelectedGoal;

  return (
    <LargeModal
      open={open}
      setOpen={
        (async (val: boolean) => {
          if (!val) {
            fetchDataAndCloseModal(false);
            setShowOutro(false);
          }
        }) as any
      }
      bgColor={MODAL_BACKGROUND_COLOR}
      bgImage={showGoalImage && selectedGoal ? selectedGoalBgImage : undefined}
      title={""}
      dataTestId={HVTestId.HeadScanQuestionModal.modal}
      forceDarkMode={!!showGoalImage}
    >
      <Box minH="550px">
        {showOutro && skillIds ? (
          <Box
            pt={isMd ? undefined : "30px"}
            px={isMd ? "20px" : undefined}
            pb="50px"
          >
            <StepHeadscanComplete
              selectedSkillIds={skillIds}
              useTheme={!isSkillInSelectedGoal}
            />
            <Box
              pos={isMd ? "absolute" : "fixed"}
              left={0}
              bottom={0}
              width={"100%"}
              bgColor={`#ffffff${isMd ? "aa" : "dd"}`}
              p={"10px"}
              pb={`${10 + safeAreaInsets.bottom}px`}
              textAlign="center"
            >
              <AnimatedButton
                w="175"
                text={HVLocalizeStrings.CONTINUE_TRAINING}
                onClick={() => {
                  setOpen(false);

                  if (onStartTraining) {
                    onStartTraining();
                  }
                }}
                dataTestId={HVTestId.HeadScanQuestionModal.startButton}
              />
            </Box>
          </Box>
        ) : (
          <>
            {loading && (
              <Center>
                <HvSpinner pos="absolute" withOverlay={true} />
              </Center>
            )}

            {shouldShowIntro && (
              <Box
                minH={isWelcomeModal ? "545px" : "450px"}
                pt={isMd ? undefined : "30px"}
                px={isMd ? "20px" : undefined}
              >
                <StepConfirmSkills
                  selectedSkillIds={skillIds}
                  isPostWelcome={true}
                  useTheme={!isSkillInSelectedGoal}
                />

                <Box
                  pos={isMd ? "absolute" : "fixed"}
                  left={0}
                  bottom={0}
                  width={"100%"}
                  bgColor={`#ffffff${isMd ? "aa" : "dd"}`}
                  borderBottomRadius={isMd ? Border_Radius : undefined}
                  p={"10px"}
                  pb={`${10 + safeAreaInsets.bottom}px`}
                  textAlign="center"
                >
                  <AnimatedButton
                    text={HVLocalizeStrings.NEXT}
                    onClick={() => {
                      setForceQuestion(true);
                    }}
                    dataTestId={HVTestId.HeadScanQuestionModal.nextButton}
                  />
                </Box>
              </Box>
            )}

            <>
              {!(showIntro && !forceQuestion) && currentQuestion && (
                <HeadScanQuestionContent
                  saving={saving}
                  saveAnswer={saveAnswer}
                  showNextQuestion={showNextQuestion}
                  selectedResponse={selectedResponse}
                  setShowNextQuestion={setShowNextQuestion}
                  pager={questionPager}
                  headScanQuestion={currentQuestion}
                  setSelectedResponse={(obj: any) => {
                    setSelectedResponse(obj);
                  }}
                  showProgressTracker={true}
                  forceDarkMode={!!showGoalImage}
                />
              )}
            </>
          </>
        )}
      </Box>
    </LargeModal>
  );
};
