import { useContext, useEffect, useState } from "react";
import { Box, useToken } from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { HVLocalizeStrings } from "../../../../../Localization/HVLocalizeStrings";
import {
  HeadZoneStatContext,
  IHeadZoneStatProvider,
} from "../../../../../State/Solo/HeadZoneStatContext";
import { Equity_Orange } from "../../../../../Styles/HeadversityStyle";
import { dateTimeLabelFormats } from "../../../../../Utils/ChartsUtil";
import { HVTestId } from "../../../../../Testing/dataTestIds";
import dayjs from "dayjs";
import { FadeAfterDelay } from "../../../../Common/FadeAfterDelay";

interface HeadZoneLineChartProps {
  width?: number;
  height?: number;
  type: string;
}
export default function HeadZoneLineChart({
  width,
  height,
  type,
}: HeadZoneLineChartProps) {
  const { headzoneMeterEvents } =
    useContext<IHeadZoneStatProvider>(HeadZoneStatContext);
  const [data, setData] = useState<any>([]);

  const axisFontColor = useToken("colors", "CHART_AXIS_COLOR");
  const lineColor = useToken("colors", "CHART_LINE_COLOR");

  const getLabelFromValue = (value: any, type?: string) => {
    if (type) {
      if (value >= 80) return HVLocalizeStrings.HEADZONE_VERY_HIGH;
      if (value >= 60 && value < 80) return HVLocalizeStrings.HEADZONE_HIGH;
      if (value >= 40 && value < 60) return HVLocalizeStrings.HEADZONE_MEDIUM;
      if (value >= 20 && value < 40) return HVLocalizeStrings.HEADZONE_LOW;
      if (value < 20) return HVLocalizeStrings.HEADZONE_VERY_LOW;
    } else {
      switch (true) {
        case value > 80 && value <= 100:
          return HVLocalizeStrings.HEADZONE_TOOLS_STATES_THRIVING;
        case value > 60 && value <= 80:
          return HVLocalizeStrings.HEADZONE_TOOLS_STATES_GREAT;
        case value > 40 && value <= 60:
          return HVLocalizeStrings.HEADZONE_TOOLS_STATES_OKAY;
        case value > 19 && value <= 40:
          return HVLocalizeStrings.HEADZONE_TOOLS_STATES_STRESSED;
        case value <= 19:
          return HVLocalizeStrings.HEADZONE_TOOLS_STATES_UNWELL;
      }
    }
  };

  useEffect(() => {
    if (!(type in headzoneMeterEvents)) return;

    let objToUse = headzoneMeterEvents[type].reduce(
      (prev: { [key: number]: [number] }, item) => {
        const ticks = item.date.startOf("day").unix() * 1000;

        if (ticks in prev) {
          prev[ticks].push(item.value);
        } else {
          prev[ticks] = [item.value];
        }

        return prev;
      },
      {}
    );

    let holder: any = [];
    Object.keys(objToUse).forEach((item: any) => {
      let sum = objToUse[item].reduce(function (
        previousValue: number,
        currentValue: number
      ) {
        return previousValue + currentValue;
      });
      let average = Math.round(sum / objToUse[item].length);

      holder.push([parseInt(item), average]);
    });
    setData([...holder].reverse());
  }, [headzoneMeterEvents, type]);

  const options = {
    chart: {
      type: "spline",
      fontFamily: "Barlow",
      accessibility: false,
      height: height,
      width: width,
      backgroundColor: "transparent",
      scrollablePlotArea: {
        scrollPositionX: 1,
      },
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: dateTimeLabelFormats,
      labels: {
        style: {
          color: axisFontColor,
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        formatter: function (e: any) {
          const value = e.value;
          return `<span fill="${axisFontColor}">${getLabelFromValue(
            value,
            type
          )}</span>`;
        },
      },
      tickInterval: 25,
      min: 0,
      max: 100,
    },
    series: [
      {
        data: data,
        name: HVLocalizeStrings.HEADZONE_LINE_CHART_LEGEND_TITLE,
        marker: {
          fillColor: Equity_Orange,
          lineWidth: 1,
          enabledThreshold: 1,
          lineColor: Equity_Orange,
        },
        color: lineColor,
        tooltip: {
          xDateFormat: "%b-%d",
        },
      },
    ],
    tooltip: {
      formatter: function (e: any) {
        return `${dayjs((this as any).x).format(
          HVLocalizeStrings.DATE_FORMAT
        )}<br /><strong>${getLabelFromValue(
          (this as any).y?.toPrecision(2),
          type
        )}</strong>`;
      },
    },
  } as any;

  return (
    <FadeAfterDelay>
      <Box data-testid={HVTestId.HeadzoneLineChart.chart}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Box>
    </FadeAfterDelay>
  );
}
