import { Flex, Text } from "@chakra-ui/react";
import {
  cumulativePointThresholds,
  getTotalStepsFromLevel,
} from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import { HVTestId } from "../../../Testing/dataTestIds";
import { ProgressTrackerSteps } from "../ProgressTrackerSteps";
import { BadgeData } from "./BadgeCard";
import { BadgeSkillLink } from "./BadgeSkillLink";

interface BadgeCardHeaderProps {
  badge: BadgeData;
  onClick?: () => void;
}

export const BadgeCardHeader = ({ badge, onClick }: BadgeCardHeaderProps) => {
  const { skills } = useContext(GlobalContext);
  const [slug, setSlug] = useState<string | undefined>();

  const color = skills.find((x) => x.id === badge.skillId)?.color;

  useEffect(() => {
    setSlug(skills.find((x) => x.id === badge.skillId)?.slug);
  }, [skills, badge]);

  return (
    <>
      <Flex
        align={"center"}
        flexDir={"column"}
        gap={2}
        mt={"10px"}
        mx={"10px"}
        fontSize={"13px"}
        fontWeight={"600"}
      >
        <BadgeSkillLink
          onClick={onClick}
          skillUrl={slug ? `/skills/${slug}` : undefined}
        >
          <Text
            fontSize={"16px"}
            data-testid={`${HVTestId.EarnBadgeCard.shieldName}_${badge.skillId}`}
          >
            {badge.shieldName}
          </Text>
        </BadgeSkillLink>

        <Flex
          data-testid={`${HVTestId.EarnBadgeCard.currentLevelHeader}_${badge.skillId}`}
        >
          {HVLocalizeStrings.SHIELD_LEVEL} {badge.newLevel}
        </Flex>
      </Flex>

      <Flex flexDir={"row"} gap={1} mb={-4} mt={"10px"}>
        <LevelIcon
          level={badge.newLevel}
          color={color}
          dataTestId={`${HVTestId.EarnBadgeCard.currentLevelByPoints}_${badge.skillId}`}
        />
        <ProgressTrackerSteps
          value={Math.floor(
            badge.newLevel === 0
              ? 0
              : badge.newPointsOnLevel -
                  cumulativePointThresholds[badge.newLevel - 1]
          )}
          totalStep={getTotalStepsFromLevel(badge.newLevel)}
          color={color}
          tooltipSingular={HVLocalizeStrings.EARN_TO_LEVEL_UP}
          tooltipPlural={HVLocalizeStrings.EARN_TO_LEVEL_UP_PLURAL}
          noZeroFill={badge.newLevel === 0}
        />
        <LevelIcon
          level={badge.newLevel + 1}
          color={color}
          dataTestId={`${HVTestId.EarnBadgeCard.nextLevelByPoints}_${badge.skillId}`}
        />
      </Flex>
    </>
  );
};

interface LevelIconProps {
  level: number;
  color?: string;
  dataTestId?: string;
}

const LevelIcon = (props: LevelIconProps) => {
  const { level, color, dataTestId } = props;
  return (
    <Flex
      align={"center"}
      justify={"center"}
      border={`1px solid ${color}66`}
      borderRadius={"50%"}
      bgColor={`${color}55`}
      height={"26px"}
      width={"26px"}
      fontWeight={"500"}
      fontSize={"13px"}
      px={"2"}
      mt={"5px"}
      data-testid={dataTestId}
    >
      {level}
    </Flex>
  );
};
