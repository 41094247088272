import {
  Flex,
  useDisclosure,
  Text,
  Icon,
  useBreakpointValue,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../Common/AnimatedButton";
import { TeamCommandModals } from "./TeamManagement/TeamCommandModals";
import { MdGroups } from "react-icons/md";
import {
  Border_Radius,
  PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { useContext, useState } from "react";
import { IGlobalProvider, GlobalContext } from "../../State/GlobalContext";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { FiMoreVertical } from "react-icons/fi";
import { JoinTeamButton } from "./JoinTeamButton";
import { HVTestId } from "../../Testing/dataTestIds";

interface TeamCommandCardProps {
  noTopBorderRadius?: boolean;
}
export default function TeamCommandCard({
  noTopBorderRadius,
}: TeamCommandCardProps) {
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { currentTeam, teams } = useContext<IGlobalProvider>(GlobalContext);
  const [commandMode, setCommandMode] = useState<string | null>(null);
  const [showButtons, setShowButtons] = useState(false);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  return (
    <>
      <Flex
        justifyContent={isMobile ? undefined : "space-between"}
        flexDir={["column", "row"]}
        align={"center"}
        bgImage={
          "url(https://cdn.headversity.com/app/resources/modal/modal-bg-light-team.jpg)"
        }
        backgroundColor={"white"}
        gap={isMobile ? "2" : undefined}
        p={isMobile ? 2 : 4}
        pl={!isMobile ? "30px" : undefined}
        borderRadius={Border_Radius}
        borderTopRadius={noTopBorderRadius ? 0 : undefined}
        boxShadow={noTopBorderRadius ? undefined : "lg"}
        position={"relative"}
        onClick={() => {
          if (currentTeam) {
            setShowButtons(!showButtons);
          }
        }}
        data-testid={HVTestId.TeamCommandCard.teamCard}
      >
        <Flex
          flexDir="column"
          color={PRIMARY_TEXT_COLOR}
          align={isMobile ? "center" : "start"}
          pt={4}
        >
          {currentTeam && (
            <Flex alignItems={"flex-end"} gap={2}>
              <Text alignSelf={"left"}>
                {HVLocalizeStrings.TEAM_CURRENT_TEAM}
              </Text>
              <Icon as={MdGroups} boxSize={6} />
            </Flex>
          )}
          <Text
            align={isMobile ? "center" : undefined}
            fontSize={"18px"}
            lineHeight="1rem"
            fontWeight={"semibold"}
            mr={5}
            mb={4}
          >
            {currentTeam ? (
              currentTeam.name
            ) : (
              <>
                <HStack>
                  <Icon as={MdGroups} boxSize={6} mr={2} />
                  <Text>{HVLocalizeStrings.TEAM_NO_TEAM_DESCRIPTION}</Text>
                </HStack>
              </>
            )}
          </Text>
        </Flex>
        <Flex
          flexDir={"column"}
          gap={"2"}
          display={isMobile && currentTeam && !showButtons ? "none" : undefined}
        >
          {teams?.length > 1 && (
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Third}
              w={250}
              text={HVLocalizeStrings.TEAM_SWITCH_TEAMS}
              onClick={() => {
                setCommandMode("select");
                onOpen();
              }}
              dataTestId={HVTestId.TeamCommandCard.switchTeamButton}
            />
          )}
          {(teams === null || teams?.length === 0) && (
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Secondary}
              w={250}
              text={HVLocalizeStrings.TEAM_CREATE_A_TEAM}
              onClick={() => {
                setCommandMode("add");

                onOpen();
              }}
            />
          )}
          <JoinTeamButton
            setCommandMode={setCommandMode}
            onOpenCommand={onOpen}
            w={250}
          />
        </Flex>
        {isMobile && currentTeam && (
          <IconButton
            pos="absolute"
            right="4px"
            top="calc(50% - 8px)"
            bg="none"
            _hover={{}}
            h="16px"
            as={FiMoreVertical}
            aria-label={"Toggle"}
          />
        )}
      </Flex>
      {isOpen && (
        <TeamCommandModals
          open={isOpen}
          close={onClose}
          type={commandMode as any}
        />
      )}
    </>
  );
}
