import { useContext, useEffect, useState } from "react";
import {
  HVLocalizeStrings,
  HVLocalizeStringsObj,
} from "../../../Localization/HVLocalizeStrings";
import { Box, Collapse, Flex, useBreakpointValue } from "@chakra-ui/react";
import {
  ILessonCourseProvider,
  LessonCourseContext,
} from "../../../State/Solo/LessonCourseContext";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../Utils/Analytics";
import { getFeedbackQuestionAnswers } from "../../../Utils/Helpers";
import {
  MicroLessonDto,
  LessonQuestionAnswerDto,
  MicroLessonUserInstanceDto,
} from "@headversity/contract";
import { FeedbackPanel } from "../../Common/FeedbackPanel";

interface LessonFeedbackProps {
  lesson: MicroLessonDto;
  microLessonUserInstance: MicroLessonUserInstanceDto;
  onComplete: () => void;
}

export const LessonFeedback = (props: LessonFeedbackProps) => {
  const { onComplete, lesson, microLessonUserInstance } = props;

  const [successMessage, setSuccessMessage] = useState<any>(null);

  const isSm = useBreakpointValue({ base: false, sm: true });

  const { saveQuestionAnswersToServer } =
    useContext<ILessonCourseProvider>(LessonCourseContext);

  useEffect(() => {
    if (lesson) {
      const successMessage = ((lesson.closingText as string) ?? "")
        .replace(/font-family:.*?;/gm, "")
        .replace(/color:.*?;/gm, "");
      setSuccessMessage(successMessage);
    }
  }, [lesson]);

  const saveFeedback = (
    microLessonUserInstance: MicroLessonUserInstanceDto | null,
    questionText: string,
    result: string
  ) => {
    if (!microLessonUserInstance) return;

    const questionAndAnswers = getFeedbackQuestionAnswers(questionText, result);

    saveQuestionAnswersToServer(microLessonUserInstance.id, {
      questionAnswerDtos: questionAndAnswers,
    } as LessonQuestionAnswerDto);
  };

  const items = [
    HVLocalizeStrings.LESSON_SUCCESS_RELEVANT,
    HVLocalizeStrings.LESSON_SUCCESS_POSITIVELY,
  ];

  const items_eng = [
    HVLocalizeStringsObj.en.LESSON_SUCCESS_RELEVANT,
    HVLocalizeStringsObj.en.LESSON_SUCCESS_POSITIVELY,
  ];

  return (
    <Box color={MODAL_PRIMARY_TEXT_COLOR} pt={"20px"}>
      <Collapse in={true}>
        <Box
          px={"20px"}
          maxW={isSm ? "600px" : "100%"}
          mx={"auto"}
          textAlign={"left"}
          lineHeight="1.3"
          dangerouslySetInnerHTML={{ __html: successMessage }}
        />

        <Flex
          maxW={"xl"}
          mx={"auto"}
          px={{ base: 4, sm: 2 }}
          flexDir={"column"}
          my={"5"}
          gap={"5"}
        >
          <FeedbackPanel
            items={items}
            items_eng={items_eng}
            onResponse={(englishText: string, result: string) => {
              // track
              track(EVENTS.LessonFeedback, {
                HV_Question: englishText,
                HV_LessonId: lesson.id,
                HV_LessonTitle: lesson.name_loc?.en,
                HV_Response: result === "like" ? "up" : "down",
              });

              // backward compatible on Mixpanel events
              if (
                englishText === HVLocalizeStringsObj.en.LESSON_SUCCESS_RELEVANT
              ) {
                track(EVENTS.LessonThumbsQuestionRelevant, {
                  HV_Response: result === "like" ? "up" : "down",
                });
              } else if (
                englishText ===
                HVLocalizeStringsObj.en.LESSON_SUCCESS_POSITIVELY
              ) {
                track(EVENTS.LessonThumbsQuestionMotivated, {
                  HV_Response: result === "like" ? "up" : "down",
                });
              }

              // save
              saveFeedback(microLessonUserInstance, englishText, result);
            }}
            onFeedback={(text: string) => {
              // track
              track(EVENTS.LessonFeedbackComments, {
                HV_LessonId: lesson.id,
                HV_LessonTitle: lesson.name_loc?.en,
              });

              // save
              saveFeedback(microLessonUserInstance, "FeedbackComments", text);
            }}
            onComplete={() => {
              onComplete();
            }}
          />
        </Flex>
      </Collapse>
    </Box>
  );
};
