import { SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  IShellProvider,
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../../State/ShellContext";
import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { ResilienceSkill20Item } from "../Shared/ResilienceSkill20Item";
import { SkillDto, UserSkillV2Dto } from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";
import { DonutSize } from "../Shared/ResilienceSkill20Donut";
import { getGoalColor, getUserSkillBySkillId } from "../../../Utils/SkillsUtil";

export const SkillsByFamily = () => {
  const { coreSkills, workSkills, homeSkills, userSkillStats } =
    useContext<IGlobalProvider>(GlobalContext);

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  return (
    <>
      <SkillsSection
        isMainContainerXL={isMainContainerXL}
        isDesktop={isDesktop}
        title={HVLocalizeStrings.CORE}
        description={HVLocalizeStrings.SKILLS_DESCRIPTION_CORE}
        skills={coreSkills}
        userSkills={userSkillStats}
      />
      {workSkills.length > 0 && (
        <SkillsSection
          isMainContainerXL={isMainContainerXL}
          isDesktop={isDesktop}
          title={HVLocalizeStrings.WORK}
          description={HVLocalizeStrings.SKILLS_DESCRIPTION_WORK}
          skills={workSkills}
          userSkills={userSkillStats}
        />
      )}
      {homeSkills.length > 0 && (
        <SkillsSection
          isMainContainerXL={isMainContainerXL}
          isDesktop={isDesktop}
          title={HVLocalizeStrings.HOME}
          description={HVLocalizeStrings.SKILLS_DESCRIPTION_HOME}
          skills={homeSkills}
          userSkills={userSkillStats}
        />
      )}
    </>
  );
};

interface SkillsSectionProps {
  isMainContainerXL: boolean;
  isDesktop: boolean | undefined;
  title: string;
  description: string;
  skills: SkillDto[];
  userSkills: UserSkillV2Dto[];
}

const SkillsSection = ({
  isMainContainerXL,
  isDesktop,
  title,
  description,
  skills,
  userSkills,
}: SkillsSectionProps) => {
  const navigate = useNavigate();

  const { selectedGoal } = useContext(GlobalContext);
  const { theme } = useContext(ShellContext);

  return (
    <BlockSection
      fadeIn={true}
      includeSpacing={true}
      title={<BlockSectionTitle title={title} description={description} />}
      columns={1}
      rows={1}
      dataTestId={HVTestId.SkillsByFamily.skillsSectionContainer}
    >
      <SimpleGrid
        columns={isMainContainerXL ? 6 : isDesktop ? 3 : 2}
        spacing={5}
      >
        {skills.map((skill) => {
          const userSkill = getUserSkillBySkillId(userSkills, skill.id);
          const isGoalSklll = selectedGoal?.skillIds.includes(skill.id);

          return (
            <ResilienceSkill20Item
              key={skill.id}
              skill={skill}
              userSkill={userSkill}
              onClick={() => {
                navigate(`/skills/${skill.slug}`);
              }}
              showDescriptionSmall={true}
              noUnlockedTooltip={true}
              reduceOpacityForLocked={true}
              donutSize={DonutSize.Medium}
              hideDescriptionInFooter={true}
              donutMarginTop={isDesktop ? undefined : "10px"}
              highlightColor={
                isGoalSklll
                  ? theme.name === NOSTALGIC_THEME_NAME
                    ? "#ffffff"
                    : getGoalColor(selectedGoal)
                  : ""
              }
            />
          );
        })}
      </SimpleGrid>
    </BlockSection>
  );
};
