import { Box } from "@chakra-ui/react";

interface InlineBoxProps {
  isDesktop: boolean | undefined;
  idx: number;
  mr?: string;
  children: any;
}

export const InlineBox = ({ isDesktop, idx, mr, children }: InlineBoxProps) => {
  return (
    <Box
      display={"inline-block"}
      verticalAlign="top"
      mr={
        mr !== undefined
          ? mr
          : !isDesktop && idx % 2 === 1
          ? undefined
          : isDesktop
          ? "30px"
          : "16px"
      }
      mb={"30px"}
    >
      {children}
    </Box>
  );
};
