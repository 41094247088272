import { Box, SlideFade } from "@chakra-ui/react";
import { SlideFade_Offset } from "../../Styles/HeadversityStyle";

interface Props {
  fadeIn: boolean;
  delay?: number;
  children: any;
}
export const HvSlideFade = ({ fadeIn, delay, children }: Props) => {
  return (
    <Box display={fadeIn ? "block" : "none"}>
      <SlideFade in={fadeIn} offsetY={SlideFade_Offset} delay={delay}>
        {children}
      </SlideFade>
    </Box>
  );
};
