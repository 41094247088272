import { ReachGoalsContextProvider } from "../../../State/Reach/ReachGoalsContext";
import { ReachGoalsPage } from "./ReachGoalsPage";

export const ReachGoalsPageContainer = () => {
  return (
    <ReachGoalsContextProvider>
      <ReachGoalsPage />
    </ReachGoalsContextProvider>
  );
};
