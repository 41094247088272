import { Toast } from "../Components/Common/Toast";

export const createToast = (
  toast: any,
  color: string,
  id: string,
  message: any,
  position?: string,
  marginY?: number,
  border?: string,
  bg?: string,
  noPadding?: boolean,
  extraDelayMs?: number
) => {
  if (!toast.isActive(id)) {
    toast({
      id: id,
      duration: 4000 + (extraDelayMs ?? 0),
      position: position ?? "top",
      containerStyle: {
        marginTop: marginY ? `${marginY}px` : undefined,
        marginBottom: marginY ? `${marginY}px` : undefined,
        maxWidth: "700px",
      },
      render: () => (
        <Toast
          id={id}
          message={message}
          color={color}
          border={border}
          bg={bg}
          noPadding={noPadding}
        />
      ),
    });
  }
};
