import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../../Styles/HeadversityStyle";
import { HvTextInput } from "../../../Common/HvTextInput";

interface FocusToolStepGeneralInput {
  title: string;
  description: string;
  inputValue: string;
  setInputValue: (inputValue: string) => void;
  changePage: (page: string) => void;
  nextStep: string;
}

export const FocusToolStepGeneralInput = (props: FocusToolStepGeneralInput) => {
  const {
    title,
    description,
    inputValue,
    setInputValue,
    changePage,
    nextStep,
  } = props;
  return (
    <>
      <Box>
        <label htmlFor={`focus_tool_${title}_text_area`}></label>
        <Heading
          fontSize={"2xl"}
          fontWeight={"semibold"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {title}
        </Heading>
        <Text fontSize="md" my={"3"} color={MODAL_PRIMARY_TEXT_COLOR}>
          {description}
        </Text>
        <HvTextInput
          value={inputValue}
          onChange={(e) => setInputValue(e.currentTarget.value)}
          onEnterKeyDown={() => {
            if (inputValue) {
              changePage(nextStep);
            }
          }}
          id={`focus_tool_${title}_text_area`}
          placeholder={title}
          autoFocus={window.screen.width >= 568}
        />
      </Box>
      <Center mt={8} mb={2}>
        <AnimatedButton
          disabled={!inputValue}
          w={83}
          onClick={() => {
            changePage(nextStep);
          }}
          text={HVLocalizeStrings.NEXT}
        />
      </Center>
    </>
  );
};
