import { Box, Image, Spacer, Text, Flex } from "@chakra-ui/react";
import { useContext } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  Equity_Blue,
  White,
  White_Stroke_Filter,
} from "../../../Styles/HeadversityStyle";
import { ReachInsightsContext } from "../../../State/Reach/ReachInsightsContext";
import { NodesFilterTags } from "../../Reach/Insights/NodesFilterTags";

interface PDFHeaderProps {
  skipBreak?: boolean;
  companyName: string;
  logo?: string;
}

const PDFHeader = (props: PDFHeaderProps) => {
  const { skipBreak, companyName, logo } = props;
  const { selectedUserLanguage } = useContext<IGlobalProvider>(GlobalContext);
  const { nodesFilter } = useContext(ReachInsightsContext);
  return (
    <div
      style={{
        backgroundColor: Equity_Blue,
        height: "160px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "1490px",
      }}
      className={skipBreak ? "" : "page-break"}
    >
      <Box marginLeft={105} justifyContent={"center"}>
        <Image
          src={"https://cdn.headversity.com/app/resources/other/logo.svg"}
          width={"250px"}
          filter="drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))"
          overflow={"visible"}
        />
      </Box>
      {logo && (
        <>
          <Spacer />
          <Box
            justifyContent={"center"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <Image
              src={logo}
              align={"center"}
              alignContent={"center"}
              width={"100px"}
              filter={White_Stroke_Filter}
              overflow={"visible"}
            />
          </Box>
        </>
      )}
      <Spacer />
      <Box>
        <Flex marginRight={105} align={"flex-end"} flexDir="column">
          <Text
            color={White}
            fontSize={"xl"}
            fontStyle={"italic"}
            fontWeight={"semibold"}
          >
            {companyName}
          </Text>
          <NodesFilterTags nodesFilter={nodesFilter}/>
          <Text color={White} fontSize={"lg"} pt={"10px"}>
            {new Date().toLocaleDateString(selectedUserLanguage, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Text>
        </Flex>
      </Box>
    </div>
  );
};

export default PDFHeader;
