import { Fade, Icon, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";
import {
  BACK_BUTTON_COLOR,
  MODAL_SECONDARY_BACKGROUND_COLOR,
} from "../../Styles/HeadversityStyle";
import { useContext } from "react";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
} from "../../State/GlobalContext";

interface BackButtonProps {
  testId?: string;
  isStaticPosition?: boolean;
  onButtonClick: () => void;
  bgColor?: string;
}

const BackButton = ({
  testId,
  isStaticPosition,
  onButtonClick,
  bgColor,
}: BackButtonProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const { currentApp } = useContext<IGlobalProvider>(GlobalContext);

  return (
    <Fade in={true}>
      <IconButton
        aria-label={"Go Back"}
        position={
          isStaticPosition ? "static" : isDesktop ? "absolute" : "relative"
        }
        mr={"16px"}
        mt={isStaticPosition ? "-2px" : undefined}
        ml={isStaticPosition ? "-10px" : undefined}
        left={isStaticPosition ? undefined : isDesktop ? "8px" : "0px"}
        top={
          isStaticPosition ? undefined : isDesktop ? "calc(50% - 12px)" : "-1px"
        }
        bg={"none"}
        size={"xxs"}
        icon={
          <Icon
            as={FiArrowLeft}
            w={"20px"}
            h={"20px"}
            color={
              bgColor
                ? bgColor
                : currentApp === AppType.CERT
                ? MODAL_SECONDARY_BACKGROUND_COLOR
                : currentApp === AppType.SOLO
                ? MODAL_SECONDARY_BACKGROUND_COLOR
                : BACK_BUTTON_COLOR
            }
          />
        }
        _hover={{ background: "none" }}
        onClick={() => {
          onButtonClick();
        }}
        data-testid={testId}
      />
    </Fade>
  );
};

export default BackButton;
