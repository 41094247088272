import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import { Dictionary, PathDto } from "@headversity/contract";

export const getPaths = (
  token: string
): Promise<AxiosResponse<Dictionary<PathDto[]>>> => {
  const config = getConfig(token);
  return AxiosClient.get("api/paths", config);
};
