import React, { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { FocusToolModal } from "./FocusToolModal";
import {
  FocusToolContext,
  IFocusToolProvider,
} from "../../../../State/Solo/FocusToolContext";
import { ToolLanding } from "../ToolLanding";
import { FocusToolStat, FocusToolStatCard } from "./FocusToolStatCard";
import dayjs from "dayjs";
import { ActionButton } from "../../../Common/ActionButton";
import {
  BLOCK_SECTION_TITLE_BORDER_BOTTOM,
  BUTTON_THIRD_BACKGROUND_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { IShellProvider, ShellContext } from "../../../../State/ShellContext";
import { EVENTS, track } from "../../../../Utils/Analytics";
import { ConfirmationButtons } from "../../../Common/ConfirmationDialog";
import { HvSpinner } from "../../../Common/HvSpinner";
import { FocusSteps } from "./FocusSteps";
import {
  FocusToolInfoDto,
  FocusToolInfoInputDto,
  FocusToolPlanInput,
  SchedulerFrequency,
} from "@headversity/contract";
import { LargeModal } from "../../../Common/LargeModal";
import { Box, Text } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFocusToolStat } from "../../../../Hooks/Solo/useFocusToolStat";
import { getOccurrenceDate, OccurrenceDirection } from "./Utils/FocusUtils";

interface FocusToolLogProps {
  toolSkillInfo: any;
}

export const FocusToolLanding = (props: FocusToolLogProps) => {
  const { toolSkillInfo } = props;
  const [focusToolOpened, setFocusToolOpened] = useState<boolean>(false);
  const [currentPlan, setCurrentPlan] = useState<
    FocusToolInfoDto | FocusToolInfoInputDto | null
  >(null);
  const [upcomingFocusPlanItems, setUpcomingFocusPlanItems] = useState<
    FocusToolInfoDto[]
  >([]);

  const [step, setStep] = useState<string>("");
  const [showPlanView, setShowPlanView] = useState<boolean>(false);

  const {
    focusPlanItems,
    deleteFocusPlanItemsFromServer,
    focusPlanItemsLoaded,
  } = useContext<IFocusToolProvider>(FocusToolContext);

  const { confirmationDialogRef } = useContext<IShellProvider>(ShellContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setIsEditMode, isEditMode } = useFocusToolStat();

  useEffect(() => {
    const now = dayjs().unix();
    if (focusPlanItems) {
      const upcomingItems = focusPlanItems.filter((item: FocusToolInfoDto) => {
        return (
          (item.recurrenceType > 0 ||
            dayjs.utc(item.nextOccurrence?.toString()).local().unix() >= now) &&
          !item.completed
        );
      });
      setUpcomingFocusPlanItems(upcomingItems);
    }
  }, [focusPlanItems]);

  useEffect(() => {
    const focusPlanId = searchParams.get("focusPlan");
    if (
      showPlanView ||
      !focusPlanId ||
      !focusPlanItems ||
      focusPlanItems.length === 0
    ) {
      return;
    }
    // this code handles opening the focus plan if we hit the url directly
    const focusPlan = focusPlanItems.find(
      (fp) => fp.id === parseInt(focusPlanId)
    );
    if (focusPlan) {
      setCurrentPlan(focusPlan);
      track(EVENTS.ToolFocusPlanViewed, { HV_FocusPlan: focusPlanId });
      setShowPlanView(true);
    }
  }, [searchParams, focusPlanItems, showPlanView]);

  const viewLogClick: any =
    (focusPlanItems as FocusToolInfoDto[])?.length > 0
      ? () => {
          track(EVENTS.ToolHistoryOpened, { HV_Tool: "Focus" });
          setCurrentPlan(null);
          setStep(FocusSteps.FocusLogStep);
          setFocusToolOpened(true);
        }
      : null;

  return (
    <>
      <ToolLanding
        showTopBar={
          !focusPlanItemsLoaded ||
          (upcomingFocusPlanItems && upcomingFocusPlanItems?.length > 0)
        }
        actionBarTitle={HVLocalizeStrings.FOCUS_TOOLS_ACTIVE}
        mobileToolStat={
          <FocusToolStat focusPlanItems={focusPlanItems} isSmall={true} />
        }
        actionBarContent={
          <>
            {!focusPlanItemsLoaded && <HvSpinner />}
            {focusPlanItemsLoaded &&
              upcomingFocusPlanItems &&
              upcomingFocusPlanItems?.length > 0 &&
              upcomingFocusPlanItems?.map((focusPlanItem: FocusToolInfoDto) => {
                const dateHappening = getOccurrenceDate(
                  focusPlanItem,
                  OccurrenceDirection.Next
                );
                const happening = dateHappening.format(
                  HVLocalizeStrings.DATE_FORMAT_WITH_TIME
                );
                const userInput = focusPlanItem.userInput as FocusToolPlanInput;
                return (
                  <ActionButton
                    border={BLOCK_SECTION_TITLE_BORDER_BOTTOM}
                    key={focusPlanItem.id}
                    image={
                      "https://cdn.headversity.com/app/resources/toolIcons/orange_focus.svg"
                    }
                    title={
                      (focusPlanItem.userInput as FocusToolPlanInput).title
                    }
                    description={
                      focusPlanItem.recurrenceType ===
                      SchedulerFrequency.DoNotRepeat
                        ? `${HVLocalizeStrings.FOCUS_TOOLS_PLAN_START} ${happening}`
                        : `${HVLocalizeStrings.FOCUS_TOOLS_PLAN_NEXT} ${happening}`
                    }
                    bgColor={BUTTON_THIRD_BACKGROUND_COLOR}
                    actions={[
                      HVLocalizeStrings.FOCUS_TOOLS_PLAN_VIEW,
                      HVLocalizeStrings.FOCUS_TOOLS_PLAN_COPY,
                      HVLocalizeStrings.FOCUS_TOOLS_PLAN_EDIT,
                      HVLocalizeStrings.FOCUS_TOOLS_PLAN_DELETE,
                    ]}
                    actionCallBack={(action) => {
                      track(EVENTS.ToolFocusActionClicked, {
                        HV_Action: action,
                      });

                      switch (action) {
                        case HVLocalizeStrings.FOCUS_TOOLS_PLAN_VIEW:
                          setCurrentPlan(focusPlanItem);
                          navigate(
                            window.location.pathname +
                              `?focusPlan=${focusPlanItem.id}`,
                            {
                              replace: true,
                            }
                          );
                          track(EVENTS.ToolFocusPlanViewed, {
                            HV_FocusPlan: focusPlanItem.id,
                          });
                          setShowPlanView(true);
                          break;
                        case HVLocalizeStrings.FOCUS_TOOLS_PLAN_COPY:
                          setCurrentPlan({
                            userInput,
                            nextOccurrence: focusPlanItem.nextOccurrence,
                            recurrenceType: focusPlanItem.recurrenceType,
                          } as FocusToolInfoInputDto);
                          setStep(FocusSteps.FocusSituationStep);
                          setFocusToolOpened(true);
                          break;
                        case HVLocalizeStrings.FOCUS_TOOLS_PLAN_EDIT:
                          setCurrentPlan(focusPlanItem);
                          setStep(FocusSteps.FocusSituationStep);
                          setFocusToolOpened(true);
                          setIsEditMode(true);
                          break;
                        case HVLocalizeStrings.FOCUS_TOOLS_PLAN_DELETE:
                          (confirmationDialogRef.current as any).confirmation(
                            HVLocalizeStrings.FOCUS_TOOLS_PLAN_DELETE,
                            HVLocalizeStrings.FOCUS_TOOLS_DELETE_CONFIRMATION,
                            ConfirmationButtons.YesNo,
                            () => {
                              deleteFocusPlanItemsFromServer(focusPlanItem.id);
                            }
                          );

                          break;
                      }
                    }}
                  />
                );
              })}
          </>
        }
        viewLogClick={viewLogClick}
        toolCard={
          <FocusToolStatCard
            focusPlanItems={focusPlanItems}
            setFocusPlanToolOpened={() => {
              track(EVENTS.ToolOpened, { HV_Tool: "Focus" });

              setCurrentPlan(null);
              setStep(FocusSteps.FocusIntroStep);
              setFocusToolOpened(true);
            }}
            viewLogClick={viewLogClick}
          />
        }
        toolInfoOne={HVLocalizeStrings.FOCUS_TOOLS_PAGE_INFO_ONE}
        toolBoost={HVLocalizeStrings.FOCUS_TOOLS_PAGE_BOOST}
        skillInfos={toolSkillInfo}
        mobileLaunchText={HVLocalizeStrings.FOCUS_TOOLS_PAGE_USE_TOOL}
        mobileLaunchFunction={() => {
          track(EVENTS.ToolOpened, { HV_Tool: "Focus" });

          setCurrentPlan(null);
          setStep(FocusSteps.FocusIntroStep);
          setFocusToolOpened(true);
        }}
      ></ToolLanding>
      <FocusToolModal
        plan={currentPlan}
        step={step}
        open={focusToolOpened}
        setOpen={setFocusToolOpened as any}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      ></FocusToolModal>
      <LargeModal
        open={showPlanView}
        setOpen={async () => {
          navigate(window.location.pathname, { replace: true });
          setShowPlanView(false);
        }}
        title={(currentPlan?.userInput as FocusToolPlanInput)?.title}
      >
        <Box color={MODAL_PRIMARY_TEXT_COLOR}>
          <Text fontSize={"lg"} fontWeight={"semibold"}>
            {HVLocalizeStrings.FOCUS_TOOLS_PLAN_WHAT}
          </Text>
          <Text fontSize={"md"} mb={4}>
            {(currentPlan?.userInput as FocusToolPlanInput)?.situation}
          </Text>
          <Text fontSize={"lg"} fontWeight={"semibold"}>
            {HVLocalizeStrings.FOCUS_TOOLS_PLAN_HAVE_TO_DO}
          </Text>
          <Text fontSize={"md"} mb={4}>
            {(currentPlan?.userInput as FocusToolPlanInput)?.toDo}
          </Text>
          <Text fontSize={"lg"} fontWeight={"semibold"}>
            {HVLocalizeStrings.FOCUS_TOOLS_PLAN_HAVE_TO_FEEL}
          </Text>
          <Text fontSize={"md"} mb={4}>
            {(currentPlan?.userInput as FocusToolPlanInput)?.feel}
          </Text>
        </Box>
      </LargeModal>
    </>
  );
};
