import {
  Box,
  Center,
  Container,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { ReachHelpResourcesContext } from "../../../State/Reach/ReachHelpResourcesContext";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import {
  BG_REACH_BASE,
  Border_Radius,
  DISABLED_BORDER,
  HEADER_REACH_HOME_BACKGROUND,
  PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { BlockSection } from "../../Common/BlockSection";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { HvSpinner } from "../../Common/HvSpinner";
import ReachInviteCodeSelector from "../Shared/ReachInviteCodeSelector";
import ReachOrganizationsHelpResourcesList from "./ReachHelpResourcesList";
import ReachHelpResourcesModal from "./ReachHelpResourcesModal";
import ReachHelpResourcesPreview from "./ReachHelpResourcesPreview";

export const ReachHelpResourcesPage = () => {
  const { getPageTitle } = useContext<IGlobalProvider>(GlobalContext);

  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  const orgContext = useContext(ReachHelpResourcesContext);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  useEffect(() => {
    orgContext.fetchHelpResources();
    orgContext.fetchCompanyInviteCodes();
  }, [
    orgContext.refreshHelpResourcesTimestamp,
    orgContext.selectedInviteCodeId,
  ]);

  useEffect(() => {
    if (
      orgContext.companyInviteCodes?.length > 0 &&
      typeof orgContext.selectedInviteCodeId !== "number"
    ) {
      orgContext.setSelectedInviteCodeId(orgContext.companyInviteCodes[0].id);
    }
  }, [orgContext.companyInviteCodes]);

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(`${HVLocalizeStrings.REACH_HELP_RESOURCES_TITLE}`)}
        </title>
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.REACH_HELP_RESOURCES_TITLE}
        description={HVLocalizeStrings.REACH_HELP_RESOURCES_DESCRIPTION}
        backgroundImage={HEADER_REACH_HOME_BACKGROUND}
        backgroundImageBase={BG_REACH_BASE}
      ></HeaderSetter>
      {orgContext.helpResourcesIsLoading ? (
        <BlockSection fadeIn={true} columns={1}>
          <Center>
            <HvSpinner />
          </Center>
        </BlockSection>
      ) : (
        <FadeAfterDelay>
          <Container maxW={isMainContainerXL ? "container.xl" : "container.lg"}>
            <Flex flexDirection="column">
              <ReachInviteCodeSelector
                setSelectedInviteCodeId={orgContext.setSelectedInviteCodeId}
                inviteCodes={orgContext.companyInviteCodes}
                selectedInviteCodeId={orgContext.selectedInviteCodeId}
              />
              <Flex
                flexDirection={isMobile ? "column" : "row"}
                mt={4}
                gap={4}
                w={"100%"}
                alignItems={isMobile ? "center" : undefined}
              >
                <Box
                  bg={"white"}
                  mb={"20px"}
                  px={"20px"}
                  color={PRIMARY_TEXT_COLOR}
                  border={DISABLED_BORDER}
                  borderRadius={Border_Radius}
                  maxW={isMobile ? "400px" : undefined}
                  w={isMobile ? "100%" : "67%"}
                  height={"fit-content"}
                >
                  <ReachOrganizationsHelpResourcesList />
                </Box>
                <Box
                  w={isMobile ? "100%" : "33%"}
                  maxW={isMobile ? "400px" : undefined}
                >
                  <ReachHelpResourcesPreview />
                </Box>
              </Flex>
              {orgContext.addHelpResourceDialogOpen && (
                <ReachHelpResourcesModal
                  setIsOpen={orgContext.setAddHelpResourceDialogOpen}
                  isOpen={orgContext.addHelpResourceDialogOpen}
                ></ReachHelpResourcesModal>
              )}
            </Flex>
          </Container>
        </FadeAfterDelay>
      )}
    </>
  );
};
