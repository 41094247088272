import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Progress,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FC, useContext, useEffect } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { ReachMyOrganizationContext } from "../../../State/Reach/ReachMyOrganizationContext";
import {
  BLOCK_SECTION_TITLE_BORDER_BOTTOM,
  Border_Radius,
  HVDefaultTheme,
  LINEAR_PROGRESS_BAR,
} from "../../../Styles/HeadversityStyle";
import { FaCheckCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { IGlobalProvider, GlobalContext } from "../../../State/GlobalContext";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";

export type QuickStartSectionProps = {
  id: number;
  title: string;
  description: string;
  buttonLabel: string;
  link?: string;
  onClick?: () => void;
  isComplete: boolean;
};
const getIncompleteSections = (
  sectionProps: QuickStartSectionProps[]
): number[] => {
  let results = [] as number[];
  sectionProps.forEach((section, index) => {
    if (!section.isComplete) {
      results.push(index);
    }
  });
  return results;
};

type QuickStartPanelProps = {
  showDetails?: boolean;
  from?: string;
};

export const ReachQuickStartPanel: FC<QuickStartPanelProps> = (
  props: QuickStartPanelProps
) => {
  const orgContext = useContext(ReachMyOrganizationContext);
  const isDesktop = useBreakpointValue({ base: false, lg: true }) ?? false;
  const forceLightMode = true;
  const navigate = useNavigate();

  const { soloEnabled, getRedirectUrlFromServer, selectedUserLanguage } =
    useContext<IGlobalProvider>(GlobalContext);

  useEffect(() => {
    orgContext.fetchData();
  }, [orgContext.dataRefreshTimestamp]);

  let idCounter = 0;
  const sectionProps: QuickStartSectionProps[] = [
    {
      id: idCounter++,
      title: HVLocalizeStrings.REACH_QUICK_START_DEFINE_ORGANIZATION_TITLE,
      description:
        HVLocalizeStrings.REACH_QUICK_START_DEFINE_ORGANIZATION_DESCRIPTION,
      buttonLabel:
        HVLocalizeStrings.REACH_QUICK_START_DEFINE_ORGANIZATION_BUTTON,
      link: "/reach/organizations",
      isComplete: orgContext.quickStart?.organizationInfo as boolean,
    },
    {
      id: idCounter++,
      title: HVLocalizeStrings.REACH_QUICK_START_SHOWCASE_LOGO_TITLE,
      description:
        HVLocalizeStrings.REACH_QUICK_START_SHOWCASE_LOGO_DESCRIPTION,
      buttonLabel: HVLocalizeStrings.REACH_QUICK_START_SHOWCASE_LOGO_BUTTON,
      link: "/reach/organizations",
      isComplete: orgContext.quickStart?.organizationLogo as boolean,
    },
    ...(soloEnabled
      ? [
          {
            id: idCounter++,
            title: HVLocalizeStrings.REACH_QUICK_START_CHOOSE_GOALS_TITLE,
            description:
              HVLocalizeStrings.REACH_QUICK_START_CHOOSE_GOALS_DESCRIPTION,
            buttonLabel:
              HVLocalizeStrings.REACH_QUICK_START_CHOOSE_GOALS_BUTTON,
            link: "/reach/organizations/goals",
            isComplete: orgContext.quickStart?.goalWinThemes as boolean,
          },
        ]
      : []),
    {
      id: idCounter++,
      title: HVLocalizeStrings.REACH_QUICK_START_ADD_USERS_TITLE,
      description: HVLocalizeStrings.REACH_QUICK_START_ADD_USERS_DESCRIPTION,
      buttonLabel: HVLocalizeStrings.REACH_QUICK_START_ADD_USERS_BUTTON,
      link: "/reach/organizations/users",
      isComplete: orgContext.quickStart?.users as boolean,
    },
    {
      id: idCounter++,
      title: HVLocalizeStrings.REACH_QUICK_START_DEFINE_HELP_RESOURCES_TITLE,
      description:
        HVLocalizeStrings.REACH_QUICK_START_DEFINE_HELP_RESOURCES_DESCRIPTION,
      buttonLabel:
        HVLocalizeStrings.REACH_QUICK_START_DEFINE_HELP_RESOURCES_BUTTON,
      link: "/reach/organizations/help-resources",
      isComplete: orgContext.quickStart?.helpResources as boolean,
    },
    {
      id: idCounter++,
      title: HVLocalizeStrings.REACH_QUICK_START_CONFIRM_COMMS_TITLE,
      description:
        HVLocalizeStrings.REACH_QUICK_START_CONFIRM_COMMS_DESCRIPTION,
      buttonLabel: HVLocalizeStrings.REACH_MANAGE_SETTINGS,
      link: "/reach/organizations/communication-settings",
      isComplete: orgContext.quickStart?.communicationSettings as boolean,
    },
    {
      id: idCounter++,
      title: HVLocalizeStrings.REACH_VISIT_HELP_CENTER_TEXT,
      description: HVLocalizeStrings.REACH_QUICK_HELP_CENTER_DESCRIPTION,
      buttonLabel: HVLocalizeStrings.REACH_VISIT_HELP_CENTER_TEXT,
      onClick: () => {
        const redirectUrl =
          process.env.REACT_APP_HUBSPOT_KNOWLEDGE_BASE_URL!.replace(
            "{0}",
            selectedUserLanguage
          );
        getRedirectUrlFromServer(
          redirectUrl,
          process.env.REACT_APP_HUBSPOT_DOMAIN!
        ).then((path) => {
          InAppBrowser.create(path, "_system");
          if (orgContext?.company) {
            orgContext
              .updateCompany({
                ...orgContext.company,
                helpCenterVisited: true,
              })
              .then(() => {
                orgContext.triggerDataRefresh();
              });
          }
        });
      },
      isComplete: orgContext.quickStart?.helpCenterVisited as boolean,
    },
  ];

  const buildProgressSection = (isDesktop: boolean) => {
    const flexComponent = (
      <Flex
        px={props.showDetails ? 6 : undefined}
        pt={props.showDetails ? 4 : undefined}
        mb={props.showDetails ? 6 : undefined}
        gap={4}
        flexDirection={isDesktop ? "row" : "column"}
        alignItems="center"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Icon
            as={props.showDetails ? FaChevronUp : FaChevronDown}
            boxSize={"16px"}
          />
          <Text
            pl={4}
            fontWeight={"semibold"}
            fontSize={props.showDetails ? "sm" : undefined}
            flex="1"
            whiteSpace={"break-spaces"}
            wordBreak={"break-word"}
          >
            {HVLocalizeStrings.REACH_CONTINUE_YOUR_QUICK_START_GUIDE}
          </Text>
        </Box>
        {isDesktop && <Spacer />}
        <Text>
          {HVLocalizeStrings.REACH_QUICK_START_PROGRESS}
          <Box ml={2} as="span" fontWeight="semibold">
            {orgContext.quickStart?.progress}%
          </Box>
        </Text>
        <Progress
          borderRadius={"5px"}
          w={isDesktop ? "30%" : "100%"}
          value={orgContext.quickStart?.progress}
          sx={{
            "& > div": {
              background: forceLightMode
                ? HVDefaultTheme.colors[LINEAR_PROGRESS_BAR]
                : LINEAR_PROGRESS_BAR,
              transition: "all 0.5s ease-in",
            },
          }}
        />
      </Flex>
    );

    return props.showDetails ? (
      flexComponent
    ) : (
      <Button variant="unstyled" fontWeight="normal" textAlign="left" h="auto">
        {flexComponent}
      </Button>
    );
  };

  return (
    <Box
      bgColor={"white"}
      w={"100%"}
      display="flex"
      flexDirection="column"
      border={BLOCK_SECTION_TITLE_BORDER_BOTTOM}
      borderRadius={Border_Radius}
      boxShadow={"lg"}
      p={props.showDetails ? undefined : 4}
      onClick={() => {
        if (!props.showDetails) {
          orgContext.toggleQuickStartGuide(true);
          track(REACH_EVENTS.ReachQuickStartGuideOpened, {
            HV_From: props.from,
          });
        }
      }}
    >
      {buildProgressSection(isDesktop)}
      {props.showDetails && (
        <Accordion
          allowMultiple
          defaultIndex={getIncompleteSections(sectionProps)}
        >
          {sectionProps.map((section) => {
            return (
              <AccordionItem key={section.id}>
                <AccordionButton>
                  <AccordionIcon />
                  <Box
                    ml={2}
                    fontSize={"sm"}
                    fontWeight={"semibold"}
                    textDecoration={
                      section.isComplete ? "line-through" : undefined
                    }
                  >
                    {section.title}
                  </Box>
                  <Spacer></Spacer>
                  {section.isComplete && (
                    <Icon as={FaCheckCircle} color={"green"} boxSize={"16px"} />
                  )}
                </AccordionButton>

                <AccordionPanel pb={4}>
                  <Flex ml={7} flexDirection="column">
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize={"sm"}
                      dangerouslySetInnerHTML={{
                        __html: section.description,
                      }}
                    />
                    <Box mt={6} mb={2}>
                      <AnimatedButton
                        colorSet={AnimatedButtonColorSet.Fourth}
                        text={section.buttonLabel}
                        onClick={() => {
                          orgContext.toggleQuickStartGuide(false);
                          if (section.link) {
                            navigate(section.link);
                          } else {
                            section.onClick?.();
                          }
                        }}
                        w={267}
                      />
                    </Box>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      )}
    </Box>
  );
};
