import React, { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { useNavigate, useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import {
  getPathTeamLessonInProgress,
  trackTeamLesson,
} from "../../Utils/TeamUtil";
import { Helmet } from "react-helmet";
import { HeaderSetter } from "../Common/HeaderSetter";
import { HEADER_TEAM_HOME_BACKGROUND } from "../../Styles/HeadversityStyle";
import { Loading } from "../Common/Loading";
import { BlockSection } from "../Common/BlockSection";
import { TEAM_EVENTS, track } from "../../Utils/Analytics";
import { PathTeamLessonDto, TeamDto } from "@headversity/contract";
import { ShellContext } from "../../State/ShellContext";

export const JoinTeamPage = (props: any) => {
  const {
    joinTeamToServer,
    teams,
    paths,
    setPaths,
    setCurrentTeamLessonUserInstanceId,
    setParticipantModalOpen,
    getTeamsFromServer,
    setParticipateForTeamLessonUserInstanceToServer,
    currentTeam,
    setCurrentTeam,
    getPageTitle,
    updateParticipantTeamLesson,
    getPathsFromServer,
    getPollInstancesFromServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { showToast } = useContext(ShellContext);

  const [joinedTeamCode, setJoinedTeamCode] = useState<string | null>(null);
  const [pathTeamLesson, setPathTeamLesson] =
    useState<PathTeamLessonDto | null>(null);

  const { teamCode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (teamCode != null && teams && !joinedTeamCode) {
      const teamToJoin = teams?.filter(
        (item: TeamDto) => item.code == teamCode
      );
      // user is already in team.  just set the team as the
      // current Team ane set the joinedTeamCode.
      // The currentTeam and joinedTeamCode useEffect
      // will take care of the rest.
      if (teamToJoin?.length > 0) {
        // Setting [GotTeam] will skip the joinTeamCode
        // useEffect.
        setJoinedTeamCode("[GotTeam]");
        setCurrentTeam(teamToJoin[0]);
        // clear out paths, in case old data
        // still exist.  Mostly for mobile app
        setPaths({});
        return;
      }
      // user not in team, so join team.
      track(TEAM_EVENTS.JoinTeam, {
        HV_TeamCode: teamCode,
      });

      joinTeamToServer(teamCode).then(
        () => {
          // set the JoinedTeamCode.  useEffect for
          // joined team code will get all the teams back.
          // and set the current team.
          setJoinedTeamCode(teamCode);
          setCurrentTeam(undefined);
          getPollInstancesFromServer();
          // clear out paths, in case old data
          // still exist.  Mostly for mobile app
          setPaths({});
        },
        (error: any) => {
          // can't join team, show error and navigate to
          // team homepage
          showToast(false, "team-join-error", error || "");
          navigate("/team");
        }
      );
    }
  }, [teamCode, teams]);

  useEffect(() => {
    if (joinedTeamCode && joinedTeamCode !== "[GotTeam]") {
      // get teams back and set the current team
      // base on team code.
      getTeamsFromServer(joinedTeamCode);
    } else {
      getPathsFromServer();
    }
  }, [joinedTeamCode]);

  useEffect(() => {
    // joined team code is set and current team is set.
    // Means this user joined a new team
    if (
      currentTeam &&
      joinedTeamCode &&
      paths &&
      paths[currentTeam?.id?.toString()]
    ) {
      // check if there is a teamLesson in progress.
      const pathTeamLessonInProgress = getPathTeamLessonInProgress(
        paths,
        currentTeam
      );
      // there is a teamLesson in progress
      if (pathTeamLessonInProgress?.teamLessonUserInstanceId) {
        // set the current teamLesson id, will trigger useEffect
        // to join teamLesson in progress and pop up the dialog.
        setPathTeamLesson(pathTeamLessonInProgress);
        setCurrentTeamLessonUserInstanceId(
          pathTeamLessonInProgress?.teamLessonUserInstanceId
        );
      } else {
        // no teamLesson in progress, pop up a toast
        showToast(
          true,
          "join-already-on-team",
          joinedTeamCode === "[GotTeam]"
            ? HVLocalizeStrings.TEAM_JOIN_ALREADY_JOINED_DESCRIPTION
            : HVLocalizeStrings.TEAM_JOIN_SUCCESS_DESCRIPTION
        );

        navigate("/team");
      }

      // navigate to the team home page.
    }
  }, [currentTeam, joinedTeamCode, paths]);

  useEffect(() => {
    if (pathTeamLesson?.teamLessonUserInstanceId) {
      trackTeamLesson(
        TEAM_EVENTS.JoinPresentation,
        currentTeam,
        pathTeamLesson
      );
      setParticipateForTeamLessonUserInstanceToServer(
        pathTeamLesson?.teamLessonUserInstanceId
      ).then(() => {
        setParticipantModalOpen(true);
        updateParticipantTeamLesson(pathTeamLesson);
        navigate("/team");
      });
    }
  }, [pathTeamLesson?.teamLessonUserInstanceId]);

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.TEAM_HOME_TITLE} - ${HVLocalizeStrings.TEAM_HOME_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>
      <HeaderSetter
        title={<>{HVLocalizeStrings.TEAM_HOME_HEADER_TITLE}</>}
        description={HVLocalizeStrings.TEAM_HOME_DESCRIPTION}
        backgroundImage={HEADER_TEAM_HOME_BACKGROUND}
      />
      <BlockSection fadeIn={true} title={""} columns={1}>
        <Flex
          w={"100%"}
          flexDir={"column"}
          align={"center"}
          justify={"center"}
          minH={"500px"}
        >
          <Loading></Loading>
        </Flex>
      </BlockSection>
    </>
  );
};
