import React, { useContext, useEffect, useState } from "react";
import { StepPanel } from "../../../Common/StepPanel";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { Center, Heading, Stack, Text } from "@chakra-ui/react";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import {
  FocusToolContext,
  IFocusToolProvider,
} from "../../../../State/Solo/FocusToolContext";
import dayjs from "dayjs";
import { ActionButton } from "../../../Common/ActionButton";
import {
  BUTTON_THIRD_BACKGROUND_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { FocusSteps } from "./FocusSteps";
import {
  FocusToolInfoDto,
  FocusToolInfoInputDto,
  FocusToolPlanInput,
} from "@headversity/contract";
import { getOccurrenceDate, OccurrenceDirection } from "./Utils/FocusUtils";

interface FocusToolStepLogsProps {
  changePage: (page: string) => void;
  setCurrentPlan: (
    currentPlan: FocusToolInfoDto | FocusToolInfoInputDto
  ) => void;
}

export const FocusToolStepLogs = (props: FocusToolStepLogsProps) => {
  const { focusPlanItems, deleteFocusPlanItemsFromServer } =
    useContext<IFocusToolProvider>(FocusToolContext);
  const [previousFocusPlans, setPreviousFocusPlans] = useState<
    FocusToolInfoDto[]
  >([]);
  const { changePage, setCurrentPlan } = props;

  useEffect(() => {
    if (focusPlanItems) {
      if (focusPlanItems.length === 0) {
        changePage(FocusSteps.FocusIntroStep);
      }
      const today = dayjs().startOf("day");
      setPreviousFocusPlans(
        focusPlanItems.filter((focusPlanItem: FocusToolInfoDto) => {
          const startTime = dayjs
            .utc(focusPlanItem.nextOccurrence?.toString())
            .local();
          return focusPlanItem.completed || startTime.unix() < today.unix();
        })
      );
    }
  }, [focusPlanItems]);

  return (
    <StepPanel
      step={0}
      totalStep={0}
      value={0}
      showProgressBar={false}
      noMarginTop
    >
      <>
        <Heading
          fontSize={"xl"}
          fontWeight={"semibold"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {HVLocalizeStrings.FOCUS_TOOLS_INTRO_LANDING_TITLE_PREVIOUS}
        </Heading>
        <Stack spacing={"3"} w={"100%"} align={"center"} mb={"10"}>
          {previousFocusPlans
            ?.sort((one: any, two: any) => {
              return (
                dayjs.utc(two?.nextOccurrence).unix() -
                dayjs.utc(one?.nextOccurrence).unix()
              );
            })
            .map((focusItem) => {
              const userInput = focusItem.userInput as FocusToolPlanInput;
              return (
                <ActionButton
                  key={focusItem.id}
                  image={
                    "https://cdn.headversity.com/app/resources/toolIcons/orange_focus.svg"
                  }
                  imagePadding={"1"}
                  imageMarginLeft={"5"}
                  bgColor={BUTTON_THIRD_BACKGROUND_COLOR}
                  width={"100%"}
                  title={userInput.title}
                  description={
                    <>
                      {userInput.situation}
                      <Text
                        as="span"
                        display={"block"}
                        fontSize={"xs"}
                        my={"2"}
                      >
                        {HVLocalizeStrings.FOCUS_TOOLS_PLAN_HAPPENED}{" "}
                        {getOccurrenceDate(
                          focusItem,
                          OccurrenceDirection.Previous
                        )
                          .local()
                          .format(HVLocalizeStrings.DATE_FORMAT_WITH_TIME)}
                      </Text>
                    </>
                  }
                  actions={[]}
                  actionCallBack={(action: any) => {
                    switch (action) {
                      case HVLocalizeStrings.FOCUS_TOOLS_PLAN_COPY:
                        setCurrentPlan({
                          userInput,
                          nextOccurrence: focusItem.nextOccurrence,
                          recurrenceType: focusItem.recurrenceType,
                        } as FocusToolInfoInputDto);
                        changePage(FocusSteps.FocusSituationStep);
                        break;
                      case HVLocalizeStrings.FOCUS_TOOLS_PLAN_EDIT:
                        setCurrentPlan(focusItem);
                        changePage(FocusSteps.FocusSituationStep);
                        break;
                      case HVLocalizeStrings.FOCUS_TOOLS_PLAN_DELETE:
                        deleteFocusPlanItemsFromServer(focusItem.id);
                        break;
                    }
                  }}
                />
              );
            })}
        </Stack>
        <Center mt={8} mb={2}>
          <AnimatedButton
            w={280}
            text={HVLocalizeStrings.FOCUS_TOOLS_INTRO_LANDING_TITLE_NEW}
            onClick={() => changePage(FocusSteps.FocusIntroStep)}
          />
        </Center>
      </>
    </StepPanel>
  );
};
