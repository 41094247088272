import { SurveyResponseDto } from "@headversity/contract";
import { AxiosResponse } from "axios";
import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";

export const saveSurveyResponse = (
  surveyInstanceId: number,
  surveyQuestionId: number,
  surveyResponse: SurveyResponseDto,
  token: string
): Promise<AxiosResponse<boolean>> => {
  return AxiosClient.post(
    `api/survey/${surveyInstanceId}/question/${surveyQuestionId}/response`,
    surveyResponse,
    getConfig(token)
  );
};
