import { Flex, Spinner, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { HVTestId } from "../../../Testing/dataTestIds";
import {
  PEOPLE_EVENTS,
  PEOPLE_EVENTS_PARAMS,
  track,
} from "../../../Utils/Analytics";
import { peekRedirectPath } from "../../../Utils/NavigationUtils";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { generateErrorFromBackend } from "../Shared/ErrorCodes";
import FormInputField from "../Shared/FormInputField";
import PasswordInput from "../Shared/PasswordInput";
import { TermsOfUsePrivacyPolicy } from "./TermsOfUsePrivacyPolicy";
import {
  InviteCodeDomainDto,
  RegistrationDto,
  RegistrationLoginStates,
  UserDto,
} from "@headversity/contract";
import {
  getDomainFromEmail,
  setLocalStorageAuthTokens,
} from "../../../Utils/LoginUtil";
import { AuthTokensDto } from "../../../@headversity/contract/Dto/AuthTokensDto";
import { HvReCaptcha } from "../../Common/HvReCaptcha";
import { SELECTED_USER_LANGUAGE_KEY } from "../../../Utils/LanguageUtil";

interface RegistrationStepProps {
  setError: (error: string | null) => void;
  pwdHasNumber: boolean;
  pwdHasLowerCase: boolean;
  pwdHasUpperCase: boolean;
  pwdHasMinLength: boolean;
  isValidPassword: boolean;
  validatePassword: (password: string) => void;
  resetPasswordValidation: () => void;
  inviteCodeDomains: InviteCodeDomainDto[];
}

export const RegistrationStep = (props: RegistrationStepProps) => {
  const {
    setError,
    pwdHasNumber,
    pwdHasLowerCase,
    pwdHasUpperCase,
    pwdHasMinLength,
    validatePassword,
    resetPasswordValidation,
    isValidPassword,
    inviteCodeDomains,
  } = props;
  const [email, setEmail] = useState<string | null>(null);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [inviteCode, setInviteCode] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isShowingReCaptcha, setIsShowingReCaptcha] = useState<boolean>(false);
  const [signUpFailureCounter, setSignUpFailureCounter] = useState<number>(0);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [fullNameError, setFullNameError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [inviteCodeError, setInviteCodeError] = useState<string | null>(null);
  const [showValidationInstructions, setShowValidationInstructions] =
    useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const inviteCodeFromQueryString = searchParams.get("invite");

  useEffect(() => {
    if (inviteCodeFromQueryString) {
      setInviteCode(inviteCodeFromQueryString);
    }
  }, []);

  const {
    signUpToServer,
    selectedUserLanguage,
    updateSelectedUserLanguageToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const navigate = useNavigate();
  const isFormComplete = () =>
    isEmailValid && email && fullName && password && inviteCode ? true : false;

  const handleChange = (
    stateToUpdate: RegistrationLoginStates,
    stateText: string,
    isValid: boolean
  ) => {
    setError(null);
    switch (stateToUpdate) {
      case RegistrationLoginStates.InviteCode:
        setInviteCode(stateText?.trim());
        break;
      case RegistrationLoginStates.Password:
        setPassword(stateText);
        validatePassword(stateText);
        break;
      case RegistrationLoginStates.Email:
        setIsEmailValid(isValid);
        setEmail(stateText?.trim());
        break;
      case RegistrationLoginStates.FullName:
        setFullName(stateText?.trim());
        break;
      default:
        return;
    }
  };

  const validateForm = () => {
    let haveError = false;
    setEmailError(null);
    setFullNameError(null);
    setPasswordError(null);
    setInviteCodeError(null);

    if (!email) {
      setEmailError(HVLocalizeStrings.EMAIL_IS_MANDATORY);
      haveError = true;
    }
    if (!fullName) {
      setFullNameError(HVLocalizeStrings.FULL_NAME_IS_MANDATORY);
      haveError = true;
    }
    if (fullName && !fullName?.trim().includes(" ")) {
      setFullNameError(
        HVLocalizeStrings.FULL_NAME_NEEDS_CONTAIN_FIRST_LAST_NAME
      );
      haveError = true;
    }
    if (!password) {
      setPasswordError(HVLocalizeStrings.PASSWORD_IS_MANDATORY);
      haveError = true;
    }
    if (!inviteCode) {
      setInviteCodeError(HVLocalizeStrings.INVITE_CODE_IS_MANDATORY);
      haveError = true;
    }
    return haveError;
  };

  const onSignUpClick = () => {
    if (!isFormComplete()) return;

    setHasSubmitted(true);
    setSignUpFailureCounter(signUpFailureCounter + 1);
    const formError = validateForm();
    if (formError) {
      return;
    }

    setIsSubmitting(true);
    let encryptedBase64Password: string | boolean = false;
    if (password) {
      encryptedBase64Password = password;
    }

    track(PEOPLE_EVENTS.SignUp, {
      [PEOPLE_EVENTS_PARAMS.InviteCode]: inviteCode,
    });

    if (getDomainFromEmail(inviteCodeDomains, email)) {
      setError(HVLocalizeStrings.EMAIL_CAN_ONLY_BE_USED_AS_SSO);
      setIsSubmitting(false);
    } else {
      signUpToServer({
        email: email,
        fullName: fullName,
        password: encryptedBase64Password ? encryptedBase64Password : null,
        inviteCode: inviteCode,
        language: selectedUserLanguage,
      } as RegistrationDto)
        .then(
          (response) => {
            const result = response.data;
            if (result.userNeedsToValidate) {
              setShowValidationInstructions(true);
            } else {
              if (result.access_token) {
                setError(null);
                resetPasswordValidation();
                loginRedirect(result);
                updateSelectedUserLanguageToServer(
                  response?.data as UserDto,
                  localStorage.getItem(SELECTED_USER_LANGUAGE_KEY) ??
                    HVLocalizeStrings.getLanguage()
                );
              }
              handleSignUpErrors(result.errorCode);

              if (result.errorCode && signUpFailureCounter >= 2) {
                setIsShowingReCaptcha(true);
              }
            }
          },
          (error: string) => {
            setError(`${HVLocalizeStrings.SIGNUP_ERROR}: ${error}`);
          }
        )
        .then(() => setIsSubmitting(false));
    }
  };

  const loginRedirect = (authTokensDto: AuthTokensDto) => {
    const mobileLogin = sessionStorage.getItem("isMobile");
    if (mobileLogin) {
      window.location.replace(
        `${process.env.REACT_APP_DEEP_LINK_URL}/waiting?dt=${authTokensDto.access_token}&rt=${authTokensDto.refresh_token}`
      );
    } else {
      setLocalStorageAuthTokens(authTokensDto).then(() => {
        const path = peekRedirectPath();
        navigate(path, { replace: true });
      });
    }
  };

  const handleSignUpErrors = (errorCode: number) => {
    if (errorCode !== undefined) {
      const errorText = generateErrorFromBackend(errorCode, inviteCode);
      track(PEOPLE_EVENTS.SignUpError, {
        [PEOPLE_EVENTS_PARAMS.Error]: errorText,
      });
      setError(errorText);
    }
  };

  const onCaptchaChange = () => {
    setIsShowingReCaptcha(false);
  };

  useEffect(() => {
    if (hasSubmitted) {
      validateForm();
    }
  }, [email, fullName, password, inviteCode, hasSubmitted]);

  return (
    <>
      {showValidationInstructions && (
        <Text
          align={"left"}
          p={"20px"}
          pt={0}
          dangerouslySetInnerHTML={{
            __html: HVLocalizeStrings.LOGIN_VALIDATION_DESCRIPTION,
          }}
        />
      )}
      {!showValidationInstructions && (
        <Flex
          flexDirection={"column"}
          gap={"2"}
          align={"center"}
          p={"20px"}
          pt={0}
        >
          <Flex flexDir={"column"} w={"300px"} gap={"2"}>
            <FormInputField
              id={"email"}
              errorMessage={emailError as any}
              imageSrc={
                "https://cdn.headversity.com/app/resources/login/envelope.png"
              }
              imageW={"18px"}
              imageH={"18px"}
              placeholder={HVLocalizeStrings.EMAIL_PLACEHOLDER}
              handleChange={handleChange}
              stateToUpdate={RegistrationLoginStates.Email}
              type={"email"}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSignUpClick();
                }
              }}
              autoFocus
              dataTestId={HVTestId.RegistrationStep.emailInput}
            />
            <FormInputField
              id={"fullName"}
              imageSrc={
                "https://cdn.headversity.com/app/resources/login/person.png"
              }
              errorMessage={fullNameError as any}
              imageW={"18px"}
              imageH={"18px"}
              placeholder={HVLocalizeStrings.YOUR_FULL_NAME}
              handleChange={handleChange}
              stateToUpdate={RegistrationLoginStates.FullName}
              type={"text"}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  onSignUpClick();
                }
              }}
              dataTestId={HVTestId.RegistrationStep.fullNameInput}
            />
            <PasswordInput
              passwordError={passwordError}
              pwdHasNumber={pwdHasNumber}
              pwdHasLowerCase={pwdHasLowerCase}
              pwdHasUpperCase={pwdHasUpperCase}
              pwdHasMinLength={pwdHasMinLength}
              handleChange={handleChange}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  onSignUpClick();
                }
              }}
            />
            <FormInputField
              id={"inviteCode"}
              placeholder={HVLocalizeStrings.YOUR_INVITE_CODE}
              errorMessage={inviteCodeError as any}
              handleChange={handleChange}
              stateToUpdate={RegistrationLoginStates.InviteCode}
              imageSrc={
                "https://cdn.headversity.com/app/resources/login/invite.png"
              }
              imageW={"16px"}
              imageH={"16px"}
              type={"text"}
              value={inviteCode ?? ""}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  onSignUpClick();
                }
              }}
              dataTestId={HVTestId.RegistrationStep.inviteCodeInput}
            />
          </Flex>
          {isShowingReCaptcha && <HvReCaptcha onChange={onCaptchaChange} />}
          <>
            {isSubmitting ? (
              <Spinner />
            ) : (
              <AnimatedButton
                text={HVLocalizeStrings.LOGIN_PAGE_SIGN_UP}
                disabled={
                  isSubmitting ||
                  !isFormComplete() ||
                  !isValidPassword ||
                  isShowingReCaptcha
                }
                colorSet={AnimatedButtonColorSet.Primary}
                onClick={() => {
                  onSignUpClick();
                }}
                w={300}
                dataTestId={HVTestId.RegistrationStep.signUpButton}
              />
            )}
          </>
          <TermsOfUsePrivacyPolicy />
        </Flex>
      )}
    </>
  );
};
