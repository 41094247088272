import { Flex, Spinner, useBreakpointValue, Text } from "@chakra-ui/react";
import { HVTestId } from "../../../Testing/dataTestIds";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import FormInputField from "../Shared/FormInputField";
import {
  RegistrationLoginStates,
  RequestPasswordCodeDto,
} from "../../../@headversity/contract";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { useContext, useEffect, useState } from "react";
import { resendValidation } from "../../../Api/People/RegistrationApi";
import { uuidV4 } from "../../../Utils/StringUtils";
import { useNavigate } from "react-router-dom";
import { PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { LoginLanguageSelector } from "../Shared/LoginLanguageSelector";
import { GlobalContext } from "../../../State/GlobalContext";
export const SignInWithEmailStep = () => {
  const isMobileView = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  const navigate = useNavigate();

  const [email, setEmail] = useState<string | null>(null);
  const [isInvalid, setIsInvalid] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { selectedUserLanguage } = useContext(GlobalContext);

  useEffect(() => {
    const userId = sessionStorage.getItem("userId");
    if (userId && userId?.indexOf("@") > -1) {
      setEmail(userId);
      // TODO FormInputField is not a good component. Does
      //  not following react bindings correctly....should
      //  refactor all the FormInput stuff or get rid of it
      //  and use the Kendo stuff
      const element = document.getElementById("email");
      (element as any).value = userId;
      setIsInvalid(false);
    }
  }, []);
  const handleChange = (
    stateToUpdate: RegistrationLoginStates,
    stateText: string,
    isValid: boolean
  ) => {
    switch (stateToUpdate) {
      case RegistrationLoginStates.Email:
        setEmail(stateText);
        if (isValid) {
          setIsInvalid(false);
        } else {
          setIsInvalid(true);
        }
        break;
      default:
        return;
    }
  };

  const submitEmailLogin = () => {
    if (isInvalid) {
      return;
    }
    setIsSubmitting(true);
    const channelName = uuidV4();
    resendValidation({
      email: email as string,
      mode: "LoginViaPush",
      channelName: channelName,
      certKey: sessionStorage.getItem("certKey") as string,
      scormUserId: sessionStorage.getItem("userId") as string,
      language: selectedUserLanguage,
    } as RequestPasswordCodeDto)
      .then((result) => {
        navigate(`/waitingTologin?channelName=${channelName}&email=${email}`, {
          replace: sessionStorage.getItem("certKey") ? true : undefined,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Flex
      flexDirection={"column"}
      gap={"2"}
      align={"center"}
      p={"20px"}
      pt={0}
      color={PRIMARY_TEXT_COLOR}
      alignItems={isMobileView ? "center" : undefined}
    >
      <Text maxW={"345px"}>{HVLocalizeStrings.PASSWORD_LESS_LOGIN_MSG}</Text>
      <Flex flexDir={"column"} gap={"2"}>
        <FormInputField
          id={"email"}
          imageSrc={
            "https://cdn.headversity.com/app/resources/login/envelope.png"
          }
          imageW={"18px"}
          imageH={"18px"}
          placeholder={HVLocalizeStrings.EMAIL_PLACEHOLDER}
          handleChange={handleChange}
          stateToUpdate={RegistrationLoginStates.Email}
          type={"email"}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              submitEmailLogin();
            }
          }}
          autoFocus
        />
      </Flex>

      <>
        {isSubmitting ? (
          <Spinner />
        ) : (
          <AnimatedButton
            text={HVLocalizeStrings.LOGIN_PAGE_SEND_EMAIL}
            disabled={
              isSubmitting || !email || email.trim() === "" || isInvalid
            }
            colorSet={AnimatedButtonColorSet.Primary}
            onClick={() => {
              submitEmailLogin();
            }}
            w={300}
            dataTestId={HVTestId.ForgotPasswordStep.sendEmailButton}
          />
        )}
      </>
      {sessionStorage.getItem("certKey") && (
        <LoginLanguageSelector navigateToLogin={false} />
      )}
    </Flex>
  );
};
