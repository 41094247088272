import React, { useContext, useState } from "react";
import { StepPanel } from "../../../Common/StepPanel";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  IThinkingToolProvider,
  ThinkingToolContext,
} from "../../../../State/Solo/ThinkingToolContext";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { THINKING_THINKING_ACCURATELY_STEP } from "./ThinkingTool";
import dayjs from "dayjs";
import {
  BOOST_TEXT_COLOR,
  Border_Radius,
  CARD_BACKGROUND_COLOR,
  DESTRUCTIVE_TEXT_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../../Utils/Analytics";
import { ThinkingToolInput, ThinkingToolInfoDto } from "@headversity/contract";

interface ThinkingToolStepLogsProps {
  changePage: (page: string) => void;
}

export const ThinkingToolStepLogs = (props: ThinkingToolStepLogsProps) => {
  const { changePage } = props;
  const { thinkingPlanItems } =
    useContext<IThinkingToolProvider>(ThinkingToolContext);
  const [currentThinkingPlanItem, setCurrentThinkingPlanItem] = useState<
    ThinkingToolInfoDto | undefined
  >();
  return (
    <StepPanel
      step={0}
      totalStep={0}
      value={0}
      showProgressBar={false}
      showBackArrow={currentThinkingPlanItem !== undefined}
      noMarginTop={currentThinkingPlanItem === undefined}
      onBackClick={() => {
        setCurrentThinkingPlanItem(undefined);
      }}
    >
      {!currentThinkingPlanItem && (
        <>
          <Heading
            textAlign={"left"}
            fontSize={"2xl"}
            fontWeight={"semibold"}
            color={MODAL_PRIMARY_TEXT_COLOR}
          >
            {HVLocalizeStrings.THINKING_TOOLS_INTRO_LANDING_TITLE}
          </Heading>
          {thinkingPlanItems && (
            <Stack spacing={"3"} align={"center"} mb={"10"}>
              {[...(thinkingPlanItems as any)]?.reverse().map((item) => {
                return (
                  <Button
                    key={item.id}
                    boxShadow={"lg"}
                    w={"100%"}
                    bg={CARD_BACKGROUND_COLOR}
                    borderRadius={Border_Radius}
                    onClick={() => {
                      track(EVENTS.ToolThinkingHistoryItemViewed);
                      setCurrentThinkingPlanItem(item);
                    }}
                    height="auto"
                    py={2}
                  >
                    <Flex w={"100%"} alignItems={"center"}>
                      <Image
                        src={
                          "https://cdn.headversity.com/app/resources/toolIcons/thinkingLogIcon.svg"
                        }
                        p={"5"}
                        alt=""
                      ></Image>
                      <Stack w={"50%"} textAlign={"left"}>
                        <Text
                          fontWeight={"bold"}
                          color={DESTRUCTIVE_TEXT_COLOR}
                          whiteSpace="normal"
                        >
                          - {item?.userInput?.identify}
                        </Text>
                        <Text
                          fontWeight={"bold"}
                          color={BOOST_TEXT_COLOR}
                          whiteSpace="normal"
                        >
                          + {item?.userInput?.statement}
                        </Text>
                      </Stack>
                      <Text textAlign={"right"} w={"50%"} pr={"5"}>
                        {dayjs
                          .utc(item?.createdAt)
                          .local()
                          .format(HVLocalizeStrings.DATE_FORMAT)}
                      </Text>
                    </Flex>
                  </Button>
                );
              })}
            </Stack>
          )}
        </>
      )}
      {currentThinkingPlanItem && (
        <Box color={PRIMARY_TEXT_COLOR} mb={"10"}>
          <Heading
            fontSize={"2xl"}
            fontWeight={"semibold"}
            color={MODAL_PRIMARY_TEXT_COLOR}
          >
            {HVLocalizeStrings.THINKING_TOOLS_EXCHANGE_DATE}{" "}
            {dayjs
              .utc(currentThinkingPlanItem?.createdAt.toString())
              .local()
              .format(HVLocalizeStrings.DATE_FORMAT)}
          </Heading>
          <Box mt={"10"}>
            <Text
              as="span"
              fontSize={"xl"}
              fontWeight={"semibold"}
              color={DESTRUCTIVE_TEXT_COLOR}
            >
              {
                (currentThinkingPlanItem?.userInput as ThinkingToolInput)
                  .identify
              }
            </Text>
            &nbsp;
            <Text as="span" fontSize={"lg"} color={MODAL_PRIMARY_TEXT_COLOR}>
              {HVLocalizeStrings.THINKING_TOOLS_LOG_NEGATIVE}
            </Text>
          </Box>

          <Divider mt={8} />

          <Text
            fontSize={"lg"}
            mt={"10"}
            dangerouslySetInnerHTML={{
              __html:
                (currentThinkingPlanItem?.userInput as ThinkingToolInput)
                  .thoughtOrFact === "thought"
                  ? HVLocalizeStrings.THINKING_TOOLS_LOG_THOUGHT_IS
                  : HVLocalizeStrings.THINKING_TOOLS_LOG_THOUGHT_NOT,
            }}
            color={MODAL_PRIMARY_TEXT_COLOR}
          />

          <Text
            fontSize={"lg"}
            mt={"7"}
            dangerouslySetInnerHTML={{
              __html:
                (currentThinkingPlanItem?.userInput as ThinkingToolInput)
                  .evidence === "supports"
                  ? HVLocalizeStrings.THINKING_TOOLS_LOG_STATEMENT_SUPPORT
                  : HVLocalizeStrings.THINKING_TOOLS_LOG_STATEMENT_NOT_SUPPORT,
            }}
            color={MODAL_PRIMARY_TEXT_COLOR}
          />

          <Text
            fontSize={"lg"}
            mt={"7"}
            dangerouslySetInnerHTML={{
              __html:
                (currentThinkingPlanItem?.userInput as ThinkingToolInput)
                  .bestFriendSay === "disagree"
                  ? HVLocalizeStrings.THINKING_TOOL_LOG_DISAGREE
                  : HVLocalizeStrings.THINKING_TOOL_LOG_AGREE,
            }}
            color={MODAL_PRIMARY_TEXT_COLOR}
          />

          <Divider mt={8} />

          <Box mt={8}>
            <Text
              as="span"
              fontSize={"xl"}
              fontWeight={"semibold"}
              color={BOOST_TEXT_COLOR}
            >
              {
                (currentThinkingPlanItem?.userInput as ThinkingToolInput)
                  ?.statement
              }
            </Text>
            &nbsp;
            <Text as="span" fontSize={"lg"} color={MODAL_PRIMARY_TEXT_COLOR}>
              {HVLocalizeStrings.THINKING_TOOLS_LOG_POSITIVE}
            </Text>
          </Box>
        </Box>
      )}
      <Center mt={8} mb={2}>
        <AnimatedButton
          w={200}
          text={HVLocalizeStrings.THINKING_TOOLS_INPUT_NEW_THOUGHTS}
          onClick={() => changePage(THINKING_THINKING_ACCURATELY_STEP)}
        />
      </Center>
    </StepPanel>
  );
};
