import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  requestAccessToken,
  requestAccessTokenByCode,
} from "../../../Api/People/AccessTokenApi";
import { createToast } from "../../../Utils/toastUtil";
import { useToast } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { peekRedirectPath } from "../../../Utils/NavigationUtils";
import { Error } from "../../../Styles/HeadversityStyle";
import { GlobalContext } from "../../../State/GlobalContext";
import { AxiosResponse } from "axios";
import {
  setLocalStorageAuthTokens,
  processHubspotRedirect,
} from "../../../Utils/LoginUtil";
import { generateErrorFromErrorName } from "../Shared/ErrorCodes";

export const AuthenticatedPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { selectedUserLanguage, getRedirectUrlFromServer } =
    useContext(GlobalContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // the vendor_access_token gets set in index.tsx
    const vendorAccessToken = sessionStorage.getItem("vendor_access_token");
    const vendorAccessCode = searchParams.get("code");
    const language = selectedUserLanguage !== "" ? selectedUserLanguage : "en";
    const mobileLogin = sessionStorage.getItem("isMobile");

    const successCallback = (response: AxiosResponse<any>) => {
      if (response?.data?.access_token) {
        if (processHubspotRedirect(response, getRedirectUrlFromServer)) {
          return;
        }
        if (mobileLogin) {
          window.location.replace(
            `${process.env.REACT_APP_DEEP_LINK_URL}/waiting?dt=${response?.data?.access_token}&rt=${response?.data?.refresh_token}`
          );
        } else {
          setLocalStorageAuthTokens(response.data).then(() => {
            const path = peekRedirectPath();
            navigate(path, { replace: true });
          });
        }
      } else {
        if (mobileLogin) {
          window.location.replace(
            `${process.env.REACT_APP_DEEP_LINK_URL}/waiting?error=true`
          );
        } else {
          let errorMessage = HVLocalizeStrings.LOGIN_GENERIC_ERROR;
          if (response?.data?.error_message) {
            errorMessage =
              generateErrorFromErrorName(response?.data?.error_message) ??
              errorMessage;
          }
          createToast(toast, Error, "login-generic-error", errorMessage);
          navigate("/login", { replace: true });
        }
      }
    };
    const failCallback = () => {
      createToast(
        toast,
        Error,
        "login-generic-error",
        HVLocalizeStrings.LOGIN_GENERIC_ERROR
      );
      navigate("/login", { replace: true });
    };

    if (vendorAccessToken) {
      sessionStorage.removeItem("vendor_access_token");
      requestAccessToken(vendorAccessToken as any, language).then(
        successCallback,
        failCallback
      );
    } else if (vendorAccessCode) {
      requestAccessTokenByCode(vendorAccessCode as any, language).then(
        successCallback,
        failCallback
      );
    } else {
      createToast(
        toast,
        Error,
        "login-generic-error",
        HVLocalizeStrings.LOGIN_GENERIC_ERROR
      );
      navigate("/login", { replace: true });
    }
  }, []);
  return <></>;
};
