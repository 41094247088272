import {
  BUTTON_DISABLE_BACKGROUND_COLOR,
  HVDefaultTheme,
  HVNostalgicTheme,
  LINEAR_PROGRESS_BAR,
  MODAL_PROGRESS_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { HStack, Progress, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { HVTestId } from "../../Testing/dataTestIds";
import { ShellContext } from "../../State/ShellContext";
import { isWebPlatform } from "../../Utils/mobileUtils";

interface ProgressTrackerProps {
  step: number;
  value: number;
  totalStep: number;
  title: string;
  forceLightMode?: boolean;
  forceDarkMode?: boolean;
}

export const ProgressTracker = (props: ProgressTrackerProps) => {
  const { step, value, totalStep, title, forceLightMode, forceDarkMode } =
    props;

  const { safeAreaInsets } = useContext(ShellContext);

  return (
    <>
      <HStack
        justifyContent={"center"}
        mx={"50px"}
        mt={`${safeAreaInsets.top - 37 - (!isWebPlatform() ? 20 : 0)}px`} // TODO: this is obviously weird; rewrite this html
        userSelect="none"
        zIndex={1}
      >
        <Text
          fontWeight={"500"}
          fontSize={"lg"}
          line-height={"24px"}
          color={
            forceLightMode
              ? HVDefaultTheme.colors[MODAL_PROGRESS_TEXT_COLOR]
              : forceDarkMode
              ? HVNostalgicTheme.colors[MODAL_PROGRESS_TEXT_COLOR]
              : MODAL_PROGRESS_TEXT_COLOR
          }
          data-testid={HVTestId.ProgressTracker.step}
        >
          {title.toUpperCase()} &nbsp;{step > totalStep ? totalStep : step}
        </Text>
        <Text
          fontWeight={"500"}
          fontSize={"lg"}
          line-height={"24px"}
          color={
            forceLightMode
              ? HVDefaultTheme.colors[MODAL_PROGRESS_TEXT_COLOR]
              : forceDarkMode
              ? HVNostalgicTheme.colors[MODAL_PROGRESS_TEXT_COLOR]
              : MODAL_PROGRESS_TEXT_COLOR
          }
          data-testid={HVTestId.ProgressTracker.total}
        >
          / {totalStep}
        </Text>
      </HStack>
      <Progress
        mt={4}
        mb={10}
        h={"10px"}
        w={"100%"}
        borderRadius={50}
        bgColor={BUTTON_DISABLE_BACKGROUND_COLOR}
        sx={{
          "& > div": {
            background: forceLightMode
              ? HVDefaultTheme.colors[LINEAR_PROGRESS_BAR]
              : forceDarkMode
              ? HVNostalgicTheme.colors[LINEAR_PROGRESS_BAR]
              : LINEAR_PROGRESS_BAR,
            transition: "all 0.5s ease-in",
          },
        }}
        value={value}
      />
    </>
  );
};
