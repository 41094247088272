import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { AnimatedButton, AnimatedButtonColorSet } from "./AnimatedButton";

const GooglePlayBanner = () => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  const [showGooglePlayBanner, setShowGooglePlayBanner] = useState<
    string | null
  >(null);

  useEffect(() => {
    let storedValue = sessionStorage.getItem("showGooglePlayBanner");
    if (!storedValue) {
      storedValue = "true";
    }

    setShowGooglePlayBanner(storedValue);
  }, []);

  const hideGooglePlayBanner = () => {
    setShowGooglePlayBanner("false");
    sessionStorage.setItem("showGooglePlayBanner", "false");
  };

  const openGooglePlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.headversity.mobile",
      "_blank",
      "noopener, noreferrer"
    );
  };

  return (
    <Box
      position={"fixed"}
      display={showGooglePlayBanner === "true" ? "flex" : "none"}
      width={"100%"}
      backgroundColor={"white"}
      border={"1px solid #e0e0e0"}
      boxShadow={"0 4px 8px rgba(0, 0, 0, 0.1)"}
      bottom={0}
      zIndex={1000}
      minHeight={"90px"}
      justifyContent={"center"}
    >
      <Flex
        alignItems={"center"}
        padding={"20px"}
        justifyContent={"space-evenly"}
        width={!isMobile ? "400px" : "100%"}
        px={1}
        py={2}
      >
        <Icon
          fontSize={"10px"}
          as={CloseIcon}
          color={"gray.700"}
          onClick={hideGooglePlayBanner}
        />
        <Flex flexDirection={"column"} maxWidth={"200px"}>
          <Image
            src={
              "https://cdn.headversity.com/app/resources/other/headversityBlueLogo.png"
            }
            w={"120px"}
            h={"auto"}
            alt={HVLocalizeStrings.HEADVERSITY}
          />
          <Text fontSize={"12px"}>
            {HVLocalizeStrings.OPEN_APP_BANNER_DESCRIPTION}
          </Text>
        </Flex>
        <AnimatedButton
          text={HVLocalizeStrings.OPEN}
          colorSet={AnimatedButtonColorSet.Primary}
          onClick={openGooglePlayStore}
          w={80}
          h={38}
        />
      </Flex>
    </Box>
  );
};

export default GooglePlayBanner;
