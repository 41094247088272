import { useContext } from "react";
import { ReachMyOrganizationContext } from "../../../State/Reach/ReachMyOrganizationContext";
import { LargeModal } from "../../Common/LargeModal";
import { ReachQuickStartPanel } from "./ReachQuickStartPanel";

export const ReachQuickStartModal = () => {
  const orgContext = useContext(ReachMyOrganizationContext);

  return (
    <LargeModal
      open={orgContext.isQuickStartGuideOpen}
      setOpen={orgContext.toggleQuickStartGuide}
    >
      <ReachQuickStartPanel showDetails />
    </LargeModal>
  );
};
