import { AxiosClient } from "../AxiosClient";
import {
  RegistrationDto,
  RegistrationValidationDto,
  RequestPasswordCodeDto,
  UnsubscribeEmailDto,
  UnsubscribeEmailResultDto,
} from "@headversity/contract";
import { AxiosResponse } from "axios";
import { AuthTokensDto } from "@headversity/contract/Dto/AuthTokensDto";

export const register = (
  registrationDto: RegistrationDto
): Promise<AxiosResponse<AuthTokensDto>> => {
  return AxiosClient.post(`api/registration/register`, registrationDto, {
    withCredentials: true,
    headers: {
      "Content-type": "application/json",
    },
  });
};

export const validate = (
  registrationValidationDto: RegistrationValidationDto
): Promise<AxiosResponse<AuthTokensDto>> => {
  return AxiosClient.post(
    `api/registration/validate`,
    registrationValidationDto,
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const resendValidation = (
  requestPasswordCodeDto: RequestPasswordCodeDto
): Promise<AxiosResponse<AuthTokensDto>> => {
  return AxiosClient.post(
    `api/registration/resendValidation`,
    requestPasswordCodeDto,
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const unsubscribeEmail = (
  unsubscribeEmailDto: UnsubscribeEmailDto
): Promise<AxiosResponse<UnsubscribeEmailResultDto>> => {
  return AxiosClient.post(`api/unsubscribe`, unsubscribeEmailDto, {
    headers: {
      "Content-type": "application/json",
    },
  });
};
