import LocalizedStrings from "react-localization";

const CHARTER_EN = {
  // Grouping Activity
  CHARTER_GROUP_TITLE: "Groups and Group Reporters",
  CHARTER_GROUP_DESCRIPTION:
    "Time to get into our small groups! We’ll randomly assign everyone to a group. Feel free to move and sit with your group to discuss together.",
  CHARTER_GROUP_REPORT_INSTRUCTION:
    "During each Group Activity, we’ll use your device to enter the group’s answers.",
  CHARTER_GROUP_YOU_ARE_GROUP_REPORTER:
    "You have been chosen as the Group Reporter",
  CHARTER_GROUPING_ACTIVITY: "Grouping Activity",
  CHARTER_GROUPING_WAITING: "Waiting to be grouped",
  CHARTER_GROUP: "Group",
  CHARTER_GROUPS: "Groups",

  // Team Value Activity
  CHARTER_TEAM_VALUES_TITLE: "Team Values",
  CHARTER_TEAM_VALUES_DESCRIPTION:
    "Keeping in mind what we talked about, what behaviors, values, or attitudes do you and your teammates always expect from each other?",
  CHARTER_TEAM_VALUES_QUESTION:
    "What behavior, value, or attitude do you and your teammates always expect from each other?",

  // Best Worst Activity one
  CHARTER_BEST_WORST_DESCRIPTION:
    "What are some of the <span style='color: #2E7D32;'><b>BEST</b></span> and <span style='color: #d1534f'><b>WORST</b></span> team characteristics you’ve experienced or can imagine?",
  CHARTER_BEST_WORST_ANSWER_INSTRUCTION: "One to three answers per team member",
  CHARTER_BEST_QUESTION:
    "What are some of the <span style='color: #2E7D32;'><b>BEST</b></span> characteristics that make a team culture successful?",
  CHARTER_WORST_QUESTION:
    "What are some of the <span style='color: #d1534f'><b>WORST</b></span> characteristics that can hurt a team and its chances for success?",

  // Best Worst Activity two
  CHARTER_BEST_WORST_TWO_DESCRIPTION:
    "In your groups, discuss what you entered and pick the three top examples of the <span style='color: #2E7D32;'><b>BEST</b></span> things you’ve experienced and the three <span style='color: #d1534f'><b>WORST</b></span> things you’ve experienced.",
  CHARTER_BEST_WORST_TWO_INSTRUCTION:
    "Choose up to five answers from the list provided",
  CHARTER_BEST_WORST_TWO_GROUP_REPORTER_INSTRUCTION: "Choose up to three.",
  CHARTER_BEST_TWO_QUESTION:
    "Which are the best examples of characteristics that make a team successful?",
  CHARTER_WORST_TWO_QUESTION:
    "What are the most serious examples of characteristics that can harm a team?",

  // We Statement Activity one
  CHARTER_WE_STATEMENT_ONE_INTRO_ONE:
    "Time to create some WE statements of our own! What are some commitments we want to live by as a team? For example:",
  CHARTER_WE_STATEMENT_ONE_INTRO_TWO:
    "<b>WE WILL</b> uplift and motivate each other to stay positive",
  CHARTER_WE_STATEMENT_ONE_INTRO_THREE:
    "<b>WE ARE</b> committed to supporting each other both on and off the field",
  CHARTER_WE_STATEMENT_ONE_INSTRUCTION:
    "Try to come up with two WE statements as a group",
  CHARTER_WE_STATEMENT_ONE_REVIEW_INSTRUCTION: `OK, it's break time! Take a few minutes to recharge.
  <br/>
  <br/>
  During the break, your presenter will review the WE statements to remove duplicates and correct spelling mistakes. Keep statements that may need clarification, as we'll have the chance to ask questions and discuss them as a team.
  <br/>
  <br/>
  Aim to have five to ten statements after editing.`,
  CHARTER_WE_STATEMENT_ONE_ENTER_UP_TO_TWO: "Enter at least two WE statements.",
  CHARTER_WE_STATEMENT_ONE_PICK_YOUR_FAVOURITE:
    "Which WE statements best represent your team values?",
  CHARTER_WE_STATEMENT_ONE_FOUR_TO_FIVE: "Select 4 to 5 statements.",
  CHARTER_WE_STATEMENT_ONE_THREE_TO_FOUR: "Select 3 to 4 statements.",
  CHARTER_WE_STATEMENT_ONE_TWO_TO_THREE: "Select 2 to 3 statements.",
  CHARTER_WE_STATEMENT_ONE_AT_LEAST_FOUR: "Enter at least 4 statements.",
  CHARTER_WE_STATEMENT_ONE_AT_LEAST_THREE: "Enter at least 3 statements.",
  CHARTER_WE_STATEMENT_ONE_AT_LEAST_TWO: "Enter at least 2 statements.",
  CHARTER_WE_STATEMENT_ONE_GROUP_SAID: "Your group said:",
  CHARTER_WE_STATEMENT_ONE_EDITOR_TITLE: "WE Statements",
  CHARTER_WE_STATEMENT_EDITOR_DESCRIPTION:
    "Edit or remove a WE statement by clicking the icons.",
  CHARTER_WE_STATEMENT_QUESTION:
    "Discuss with your group and think of some WE statements you think the team should live by.",

  // We Statement Activity two
  CHARTER_WE_STATEMENT_TWO_DESCRIPTION:
    "Over the break, all of your WE statements were reviewed for duplicates and spelling. Now, in your small groups, you’ll review each statement. If a statement seems good as-is, simply move on to the next one. If a statement isn’t sounding quite right or you’d like to discuss it further, check the box to flag it for additional conversation with the whole group.",
  CHARTER_WE_STATEMENT_TWO_FINAL_TITLE: "Final WE Statements",
  CHARTER_WE_STATEMENT_TWO_INSTRUCTION_ONE:
    "If any statements were flagged for discussion, take some time to talk them through and decide if they should be left as-is, edited, or removed.",

  CHARTER_WE_STATEMENT_TWO_INSTRUCTION_DISCUSS: "Flag for discussion",

  // Charter Edit Activity
  CHARTER_EDIT_TITLE: "Customize Charter",
  CHARTER_EDIT_DESCRIPTION: "Customize the look of your Team Charter.",
  CHARTER_WE_STATEMENTS: "WE Statements",
  CHARTER_Title: "Charter",

  // Scenario Activity
  CHARTER_SCENARIO_TITLE: "Scenario Review",
  CHARTER_SCENARIO_LABEL: "Scenario: ",
  CHARTER_SCENARIO_DOES_IT_VIOLATE:
    "Would the charter support us in this scenario?",
  CHARTER_SCENARIO_YES: "Yes",
  CHARTER_SCENARIO_NO: "No",
  CHARTER_SCENARIO_INSTRUCTION_ONE: "What did you think about each scenario?",
  CHARTER_SCENARIO_INSTRUCTION_TWO: `Let’s go through each group’s scenario and review their thoughts.`,
  CHARTER_SCENARIO_INSTRUCTION_THREE:
    "Read the scenario as a group and answer the following questions about how you would approach the situation.",
  CHARTER_SCENARIO_INSTRUCTION_FOUR:
    "Then, we’ll go through each scenario and discuss some appropriate reactions.",
  CHARTER_SCENARIO_QUESTION_INSTRUCTION_ONE:
    "Would your Team Charter be helpful in this scenario?",
  CHARTER_SCENARIO_QUESTION_INSTRUCTION_TWO: "Why or why not?",
  CHARTER_SCENARIO_QUESTION_INSTRUCTION_THREE:
    "Which WE statement would help you address this scenario?",
  CHARTER_SCENARIO_QUESTION_CHOOSE_WE:
    "Which WE statement is most aligned to handling this scenario?",
  CHARTER_SCENARIO_VIEW_CHARTER: "View/edit charter",
  CHARTER_SCENARIO_SELECT_WE: "Select a WE statement",
  CHARTER_SCENARIO_ENTER_RESPONSE: "Enter your response",
  CHARTER_SCENARIO_YOUR_SCENARIO: "Your Scenario: ",

  // Ambassador Activity
  CHARTER_AMBASSADOR_DESCRIPTION: `We’ll now vote on our Charter Champions! As mentioned, these are the team members who will promote and support our team values, expectations, and commitments, both in and out of competition.`,
  CHARTER_AMBASSADOR_DESCRIPTION_RESULTS: `Here are your team’s Charter Champions!`,

  CHARTER_AMBASSADOR_INSTRUCTION: "Vote for your top three choices",
  CHARTER_AMBASSADOR_VOTE_QUESTION: "Vote for Charter Champions",
  CHARTER_AMBASSADOR_VOTE_INSTRUCTIONS:
    "Select one to three team members to represent your Team Charter.",

  CHARTER_OUR_AMBASSADOR_TITLE: `Our Charter Champions`,
  CHARTER_OUR_AMBASSADOR_DESCRIPTION: `Here are our three Charter Champions that we all chosen as a group.  They be expected to uphold the charter on behalf of us all time.`,

  CHARTER_STATISTICS: "Total Group Responses:",
  CHARTER_CLICK_PROCEED: "Click the button when you’re ready to proceed. ",
  CHARTER_EVERYONE_WILL_PARTICIPATE:
    "Everyone will participate on their individual devices",
  CHARTER_INDIVIDUAL: "Individual",
  CHARTER_ACTIVITY: "Activity",
  CHARTER_INDIVIDUAL_ACTIVITY: "Individual Activity",
  CHARTER_GROUP_ACTIVITY: "Group Activity",
  CHARTER_ONLY_USE_GROUP_REPORTER_DEVICE:
    "We will be using only Group Reporter devices",
  CHARTER_TAKE_SOME_TIME_TO_DISCUSS:
    "Take some time to discuss the results out loud",
  CHARTER_MAX_INPUTS_ONE_SENTENCE: "One answer per team member",
  CHARTER_MAX_INPUTS_THREE_SENTENCE: "One to three answers per team member",
  CHARTER_MAX_INPUTS_FIVE_SENTENCE:
    "Choose up to five answers from the list provided",
  CHARTER_BREAK_TIME_AND_WE_STATEMENTS_REVIEW: "Break and WE Statement Editing",
  CHARTER_CLICK_THE_BUTTON_WHEN_YOU_ARE_READY_TO_PROCEED:
    "Click the button when you are ready to proceed",
  CHARTER_WAITING_FOR_GROUP_LEADER:
    "Waiting for your group leader to submit your answers.",
  CHARTER_WAITING_FOR_PRESENTER_TO_CONTINUE:
    "Waiting for the presenter to continue.",
  CHARTER_ITS_BREAK_TIME: "It's break time!",
  CHARTER_FONT: "Font",
  CHARTER_BACKGROUND_COLOR: "Background Color",
  CHARTER_APPROACHING: "Your Team Charter workshop is approaching!",
  CHARTER_START_PRESENTATION: "Start Workshop",
  CHARTER_RESUME_PRESENTATION: "Resume Workshop",
  CHARTER_JOIN_PRESENTATION: "Join Workshop",
  CHARTER_VIEW_CHARTER: "View Team Charter",
  CHARTER_SCHEDULE: "Schedule your Team Charter workshop",
  CHARTER_SCHEDULE_DATE: "EEEE, MMM dd '@' h:mm a",
  CHARTER_SCHEDULE_ANOTHER: "Schedule another Team Charter workshop",
  CHARTER_ITS_TIME: "It's time for your Team Charter!",
  CHARTER_COMING_SOON: "Your Team Charter workshop is coming soon!",
  CHARTER_SCHEDULE_MODAL_PARAGRAPH_1:
    "Championship teams understand that there’s a real connection between culture and success, and the Team Charter workshop will help your team to define its culture.",
  CHARTER_SCHEDULE_MODAL_PARAGRAPH_2:
    "You'll need access to a room and a screen to broadcast the presentation, and your players will need their phones to participate in group activities. Expect the workshop to take about an hour.",
  CHARTER_SCHEDULE_MODAL_PARAGRAPH_SHORT:
    "You'll need access to a room and a screen, and your players will need their phones.",
  CHARTER_SCHEDULE_INVALID_DATE:
    "Please choose a valid date that is not in the past.",

  // Rating View
  CHARTER_BETTER_TEAM:
    "This workshop helped us understand how to be a better team",
  CHARTER_RECOMMEND: "I would recommend this workshop to other teams",
  CHARTER_DEFAULT_NAME: "My Team Charter Default",
  CHARTER_FOUNDATIONAL_TRAINING_COMPLETE: "Foundational Training Complete?",
  CHARTER_COMPLETED_FOUNDATIONAL_TRAINING: "Completed foundational training:",
  CHARTER_NO_TRAININGS: "There are no specific trainings to display.",
  CHARTER_VIEW_DETAILS: "View Details",
  CHARTER_COMPLETED_TRAINING: "Completed training:",
  CHARTER_YOUR_TEAM: "Your team:",
  CHARTER_SPORT_TEAM_TITLE: "Team - Respect in Sport+",
  CHARTER_SPORT_RESOURCES_TITLE: "Resources - Respect in Sport+",
  CHARTER_RESOURCE_ONE: "How to run a Well-Better-How meeting",
  CHARTER_RESOURCE_TWO: "Resource #2",
  CHARTER_RESOURCE_ONE_HEADER: "Well-Better-How Guide",
  CHARTER_RESOURCE_ONE_STEP_ONE_HEADER: "Step 1: Gather the Team",
  CHARTER_RESOURCE_ONE_STEP_ONE_DESCRIPTION:
    "Schedule a dedicated time for your meeting. Ensure that all team members are present and prepared to participate. This process is most effective when everyone contributes.",
  CHARTER_RESOURCE_ONE_STEP_TWO_HEADER: "Step 2: Ask the Three Key Questions",
  CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_ONE: "What are we doing WELL?",
  CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_ONE:
    'Encourage the team to highlight specific successes, both individual and collective. This helps reinforce positive behavior and build morale. For example, ask "How have we recently demonstrated strong teamwork?".',
  CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_TWO: "What could we do BETTER?",
  CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_TWO:
    'Are there areas where we, as individuals or as a team, could step up our commitment? Encourage honest, solution-oriented feedback without assigning blame. For example, ask "Where can we improve to better meet our goals?".',
  CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_THREE:
    "HOW can we be more moving forward?",
  CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_THREE:
    'Here, collaborate on actionable steps to improve performance and set goals for future check-ins. For example, ask "What specific changes or actions can we take to strengthen our team culture?".',
  CHARTER_RESOURCE_ONE_STEP_THREE_HEADER:
    "Step 3: Summarize and Assign Actions",
  CHARTER_RESOURCE_ONE_STEP_THREE_DESCRIPTION:
    "After discussing all three questions, summarize the key takeaways. Ensure that action items are clearly assigned to individuals or groups and establish a timeline for follow-up.",
  CHARTER_RESOURCE_ONE_STEP_FOUR_HEADER: "Step 4: Follow-Up",
  CHARTER_RESOURCE_ONE_STEP_FOUR_DESCRIPTION:
    "At your next team meeting, review the agreed-upon actions. Celebrate progress and revisit the Well-Better-How process to keep the team aligned and continuously improving.",
  CHARTER_RESOURCE_ONE_TIPS_HEADER: "Tips for Effective Check-ins",
  CHARTER_RESOURCE_ONE_TIPS_DESCRIPTION_ONE:
    "Foster Open Dialogue: Create a safe space for honest feedback.",
  CHARTER_RESOURCE_ONE_DESCRIPTION_TWO:
    "Stay Focused: Keep the conversation aligned with the Team Charter and team goals.",
  CHARTER_RESOURCE_ONE_DESCRIPTION_THREE:
    "Follow-Up: Consistently track progress and revisit key points at future meetings.",
  CHARTER_TEAM_RESOURCES: "Team Resources",
  CHARTER_SPORT_PAGE_TITLE: "Respect in Sport+",
  CHARTER_FOUNDATIONAL_TRAINING_DESCRIPTION:
    "Complete this short training to get started.",
  CHARTER_TEAM_CHARTER_DESCRIPTION:
    "Meet with your team and define your culture.",
  CHARTER_TEAM_CHECK_IN_LEADER: "Team Check-In",
  CHARTER_TEAM_CHECK_IN_PLAYER: "Check-In",
  CHARTER_TEAM_CHECK_IN_DESCRIPTION:
    "Get a quick pulse on how things are going.",
  CHARTER_TEAM_CHECK_IN_TEAM_PROGRESS: "Team Progress",
  CHARTER_TEAM_CHECK_IN_TEAM_PROGRESS_DESCRIPTION:
    "Keep tabs on who still needs to complete their training.",
  CHARTER_INTERSTITIAL_TEXT_LEADER:
    "This program will help you and your team build a respectful and resilient team culture. You'll complete a short foundational training for leaders, and you'll organize a group workshop where your team will build a Team Charter. But first, let's collect some details about your team.",
  CHARTER_INTERSTITIAL_TEXT_PLAYER:
    "This program will help you and your team build a respectful and resilient team culture. You'll complete a short foundational training program, and later participate in a group workshop where you'll help to build your Team Charter. Let's get started!",
  CHARTER_TEAM_EDIT_WHAT_IS_NAME: "What's your team's name?",
  CHARTER_TEAM_NAME_INVALID: "Name Invalid",
  CHARTER_TEAM_ADD_TEAM_LOGO: "Add your team logo",
  CHARTER_TEAM_ADD_TEAM_LOGO_INSTRUCTIONS:
    "Please upload a 450x310 transparent PNG.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_HEADER:
    "How useful has the charter been so far?",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH: "Areas of Strength",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_TITLE_ONE:
    "Inclusive communication",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_DESCRIPTION_ONE:
    "Team members made sure everyone had a chance to speak during team huddles.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_TITLE_TWO:
    "Supporting interaction",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_DESCRIPTION_TWO:
    "The team demonstrated strong support for each other during drills and practices, contributing to a positive and encouraging environment.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_TITLE_THREE:
    "Effective teamwork",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_DESCRIPTION_THREE:
    "During games, players worked together without placing blame on individuals for mistakes, which strengthened team cohesion and trust.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT: "Areas for Improvement",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_TITLE_ONE:
    "Interruptions during discussions",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_DESCRIPTION_ONE:
    "Players reported frequent interruptions and talking over each other during strategic discussions and explanations, hindering effective communication.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_TITLE_TWO:
    "Conflict during intense moments",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_DESCRIPTION_TWO:
    "There were instances of arguing and conflict during high-stress situations, which affected team harmony and respect.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_TITLE_THREE:
    "Feedback reception",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_DESCRIPTION_THREE:
    "Some players felt that feedback was not always received constructively, impacting overall team development and respect for each other’s perspectives.",
  CHARTER_TEAM_CHECK_IN_HEADER:
    "Available after your Respect Charter is complete",
  CHARTER_TEAM_CHECK_IN_SUBHEADER: "It's time to tell us how it's going!",
  CHARTER_TEAM_CHECK_IN_COMPLETE: "Check-In has been completed",
  CHARTER_TEAM_CHECK_IN_NOW: "Check-In Now",

  MODERATOR_GUIDE: "Moderator Guide",
};

const CHARTER_FR = {
  CHARTER_GROUP_TITLE: "Groups and Group Reporters",
  CHARTER_GROUP_DESCRIPTION:
    "Time to get into our small groups! We’ll randomly assign everyone to a group. Feel free to move and sit with your group to discuss together.",
  CHARTER_GROUP_REPORT_INSTRUCTION:
    "During each Group Activity, we’ll use your device to enter the group’s answers.",
  CHARTER_GROUP_YOU_ARE_GROUP_REPORTER:
    "You have been chosen as the Group Reporter",
  CHARTER_GROUPING_ACTIVITY: "Grouping Activity",
  CHARTER_GROUPING_WAITING: "Waiting to be grouped",
  CHARTER_GROUP: "Group",
  CHARTER_GROUPS: "Groups",

  // Team Value Activity
  CHARTER_TEAM_VALUES_TITLE: "Team Values",
  CHARTER_TEAM_VALUES_DESCRIPTION:
    "Keeping in mind what we talked about, what behaviors, values, or attitudes do you and your teammates always expect from each other?",
  CHARTER_TEAM_VALUES_QUESTION:
    "What behavior, value, or attitude do you and your teammates always expect from each other?",

  // Best Worst Activity one
  CHARTER_BEST_WORST_DESCRIPTION:
    "What are some of the <span style='color: #2E7D32;'><b>BEST</b></span> and <span style='color: #d1534f'><b>WORST</b></span> team characteristics you’ve experienced or can imagine?",
  CHARTER_BEST_WORST_ANSWER_INSTRUCTION: "One to three answers per team member",
  CHARTER_BEST_QUESTION:
    "What are some of the <span style='color: #2E7D32;'><b>BEST</b></span> characteristics that make a team culture successful?",
  CHARTER_WORST_QUESTION:
    "What are some of the <span style='color: #d1534f'><b>WORST</b></span> characteristics that can hurt a team and its chances for success?",

  // Best Worst Activity two
  CHARTER_BEST_WORST_TWO_DESCRIPTION:
    "In your groups, discuss what you entered and pick the three top examples of the <span style='color: #2E7D32;'><b>BEST</b></span> things you’ve experienced and the three <span style='color: #d1534f'><b>WORST</b></span> things you’ve experienced.",
  CHARTER_BEST_WORST_TWO_INSTRUCTION:
    "Choose up to five answers from the list provided",
  CHARTER_BEST_WORST_TWO_GROUP_REPORTER_INSTRUCTION: "Choose up to three.",
  CHARTER_BEST_TWO_QUESTION:
    "Which are the best examples of characteristics that make a team successful?",
  CHARTER_WORST_TWO_QUESTION:
    "What are the most serious examples of characteristics that can harm a team?",

  // We Statement Activity one
  CHARTER_WE_STATEMENT_ONE_INTRO_ONE:
    "Time to create some WE statements of our own! What are some commitments we want to live by as a team? For example:",
  CHARTER_WE_STATEMENT_ONE_INTRO_TWO:
    "<b>WE WILL</b> uplift and motivate each other to stay positive",
  CHARTER_WE_STATEMENT_ONE_INTRO_THREE:
    "<b>WE ARE</b> committed to supporting each other both on and off the field",
  CHARTER_WE_STATEMENT_ONE_INSTRUCTION:
    "Try to come up with two WE statements as a group",
  CHARTER_WE_STATEMENT_ONE_REVIEW_INSTRUCTION: `OK, it's break time! Take a few minutes to recharge.
  <br/>
  <br/>
  During the break, your presenter will review the WE statements to remove duplicates and correct spelling mistakes. Keep statements that may need clarification, as we'll have the chance to ask questions and discuss them as a team.
  <br/>
  <br/>
  Aim to have five to ten statements after editing.`,
  CHARTER_WE_STATEMENT_ONE_ENTER_UP_TO_TWO: "Enter at least two WE statements.",
  CHARTER_WE_STATEMENT_ONE_PICK_YOUR_FAVOURITE:
    "Which WE statements best represent your team values?",
  CHARTER_WE_STATEMENT_ONE_FOUR_TO_FIVE: "Select 4 to 5 statements.",
  CHARTER_WE_STATEMENT_ONE_THREE_TO_FOUR: "Select 3 to 4 statements.",
  CHARTER_WE_STATEMENT_ONE_TWO_TO_THREE: "Select 2 to 3 statements.",
  CHARTER_WE_STATEMENT_ONE_AT_LEAST_FOUR: "Enter at least 4 statements.",
  CHARTER_WE_STATEMENT_ONE_AT_LEAST_THREE: "Enter at least 3 statements.",
  CHARTER_WE_STATEMENT_ONE_AT_LEAST_TWO: "Enter at least 2 statements.",
  CHARTER_WE_STATEMENT_ONE_GROUP_SAID: "Your group said:",
  CHARTER_WE_STATEMENT_ONE_EDITOR_TITLE: "WE Statements",
  CHARTER_WE_STATEMENT_EDITOR_DESCRIPTION:
    "Edit or remove a WE statement by clicking the icons.",
  CHARTER_WE_STATEMENT_QUESTION:
    "Discuss with your group and think of some WE statements you think the team should live by.",

  // We Statement Activity two
  CHARTER_WE_STATEMENT_TWO_DESCRIPTION:
    "Over the break, all of your WE statements were reviewed for duplicates and spelling. Now, in your small groups, you’ll review each statement. If a statement seems good as-is, simply move on to the next one. If a statement isn’t sounding quite right or you’d like to discuss it further, check the box to flag it for additional conversation with the whole group.",
  CHARTER_WE_STATEMENT_TWO_FINAL_TITLE: "Final WE Statements",
  CHARTER_WE_STATEMENT_TWO_INSTRUCTION_ONE:
    "If any statements were flagged for discussion, take some time to talk them through and decide if they should be left as-is, edited, or removed.",
  CHARTER_WE_STATEMENT_TWO_INSTRUCTION_DISCUSS: "Flag for discussion",

  // Charter Edit Activity
  CHARTER_EDIT_TITLE: "Customize Charter",
  CHARTER_EDIT_DESCRIPTION: "Customize the look of your Team Charter.",
  CHARTER_WE_STATEMENTS: "Déclarations NOUS",
  CHARTER_Title: "Charte",

  // Scenario Activity
  CHARTER_SCENARIO_TITLE: "Scenario Review",
  CHARTER_SCENARIO_LABEL: "Scenario: ",
  CHARTER_SCENARIO_DOES_IT_VIOLATE:
    "Would the charter support us in this scenario?",
  CHARTER_SCENARIO_YES: "Yes",
  CHARTER_SCENARIO_NO: "No",
  CHARTER_SCENARIO_INSTRUCTION_ONE: "What did you think about each scenario?",
  CHARTER_SCENARIO_INSTRUCTION_TWO: `Let’s go through each group’s scenario and review their thoughts.`,
  CHARTER_SCENARIO_INSTRUCTION_THREE:
    "Read the scenario as a group and answer the following questions about how you would approach the situation.",
  CHARTER_SCENARIO_QUESTION_CHOOSE_WE:
    "Which WE statement is most aligned to handling this scenario?",
  CHARTER_SCENARIO_INSTRUCTION_FOUR:
    "Then, we’ll go through each scenario and discuss some appropriate reactions.",
  CHARTER_SCENARIO_QUESTION_INSTRUCTION_ONE:
    "Would your Team Charter be helpful in this scenario?",
  CHARTER_SCENARIO_QUESTION_INSTRUCTION_TWO: "Why or why not?",
  CHARTER_SCENARIO_QUESTION_INSTRUCTION_THREE:
    "Which WE statement would help you address this scenario?",
  CHARTER_SCENARIO_VIEW_CHARTER: "Voir/modifier la charte",
  CHARTER_SCENARIO_SELECT_WE: "Select a WE statement",
  CHARTER_SCENARIO_ENTER_RESPONSE: "Enter your response",
  CHARTER_SCENARIO_YOUR_SCENARIO: "Your Scenario: ",

  // Ambassador Activity
  CHARTER_AMBASSADOR_DESCRIPTION: `We’ll now vote on our Charter Champions! As mentioned, these are the team members who will promote and support our team values, expectations, and commitments, both in and out of competition.`,
  CHARTER_AMBASSADOR_DESCRIPTION_RESULTS: `Here are your team’s Charter Champions!`,

  CHARTER_AMBASSADOR_INSTRUCTION: "Vote for your top three choices",
  CHARTER_AMBASSADOR_VOTE_QUESTION: "Vote for Charter Champions",
  CHARTER_AMBASSADOR_VOTE_INSTRUCTIONS:
    "Select one to three team members to represent your Team Charter.",
  CHARTER_OUR_AMBASSADOR_TITLE: `Our Charter Champions`,
  CHARTER_OUR_AMBASSADOR_DESCRIPTION: `Here are our three Charter Champions that we all chosen as a group.  They be expected to uphold the charter on behalf of us all time.`,

  CHARTER_STATISTICS: "Total des réponses du groupe:",
  CHARTER_CLICK_PROCEED: "Click the button when you’re ready to proceed. ",
  CHARTER_EVERYONE_WILL_PARTICIPATE:
    "Everyone will participate on their individual devices",
  CHARTER_INDIVIDUAL: "Individual",
  CHARTER_ACTIVITY: "Activity",
  CHARTER_INDIVIDUAL_ACTIVITY: "Individual Activity",
  CHARTER_GROUP_ACTIVITY: "Group Activity",
  CHARTER_ONLY_USE_GROUP_REPORTER_DEVICE:
    "Nous utiliserons uniquement l'appareil du rapporteur de groupe",
  CHARTER_TAKE_SOME_TIME_TO_DISCUSS:
    "Prenez un moment pour discuter des résultats à voix haute",
  CHARTER_MAX_INPUTS_ONE_SENTENCE: "Une réponse par membre de l'équipe",
  CHARTER_MAX_INPUTS_THREE_SENTENCE: "One to three answers per team member",
  CHARTER_MAX_INPUTS_FIVE_SENTENCE:
    "Choisissez jusqu'à cinq réponses dans la liste fournie",
  CHARTER_BREAK_TIME_AND_WE_STATEMENTS_REVIEW: "Break and WE Statement Editing",
  CHARTER_CLICK_THE_BUTTON_WHEN_YOU_ARE_READY_TO_PROCEED:
    "Click the button when you are ready to proceed",
  CHARTER_WAITING_FOR_GROUP_LEADER:
    "Waiting for your group leader to submit your answers.",
  CHARTER_WAITING_FOR_PRESENTER_TO_CONTINUE:
    "Waiting for the presenter to continue.",
  CHARTER_ITS_BREAK_TIME: "It's break time!",
  CHARTER_FONT: "Font",
  CHARTER_BACKGROUND_COLOR: "Background Color",
  CHARTER_APPROACHING: "Your Team Charter workshop is approaching!",
  CHARTER_START_PRESENTATION: "Start Workshop",
  CHARTER_RESUME_PRESENTATION: "Resume Workshop",
  CHARTER_JOIN_PRESENTATION: "Join Workshop",
  CHARTER_VIEW_CHARTER: "View Team Charter",
  CHARTER_SCHEDULE: "Schedule your Team Charter workshop",
  CHARTER_SCHEDULE_DATE: "dddd, MMM DD '@' h:mm a",
  CHARTER_SCHEDULE_ANOTHER: "Schedule another Team Charter workshop",
  CHARTER_ITS_TIME: "It's time for your Team Charter!",
  CHARTER_COMING_SOON: "Your Team Charter workshop is coming soon!",
  CHARTER_SCHEDULE_MODAL_PARAGRAPH_1:
    "Championship teams understand that there’s a real connection between culture and success, and the Team Charter workshop will help your team to define its culture.",
  CHARTER_SCHEDULE_MODAL_PARAGRAPH_2:
    "You'll need access to a room and a screen to broadcast the presentation, and your players will need their phones to participate in group activities. Expect the workshop to take about an hour.",
  CHARTER_SCHEDULE_MODAL_PARAGRAPH_SHORT:
    "You'll need access to a room and a screen, and your players will need their phones.",
  CHARTER_SCHEDULE_INVALID_DATE:
    "Please choose a valid date that is not in the past.",

  // Rating View
  CHARTER_BETTER_TEAM:
    "This workshop helped us understand how to be a better team",
  CHARTER_RECOMMEND: "I would recommend this workshop to other teams",
  CHARTER_DEFAULT_NAME: "My Team Charter Default",
  CHARTER_FOUNDATIONAL_TRAINING_COMPLETE: "Foundational Training Complete?",
  CHARTER_COMPLETED_FOUNDATIONAL_TRAINING: "Completed foundational training:",
  CHARTER_NO_TRAININGS: "There are no specific trainings to display.",
  CHARTER_VIEW_DETAILS: "View Details",
  CHARTER_COMPLETED_TRAINING: "Completed training:",
  CHARTER_YOUR_TEAM: "Your team:",
  CHARTER_SPORT_TEAM_TITLE: "Team - Respect in Sport+",
  CHARTER_SPORT_RESOURCES_TITLE: "Resources - Respect in Sport+",
  CHARTER_RESOURCE_ONE: "How to run a Well-Better-How meeting",
  CHARTER_RESOURCE_TWO: "Resource #2",
  CHARTER_RESOURCE_ONE_HEADER: "Well-Better-How Guide",
  CHARTER_RESOURCE_ONE_STEP_ONE_HEADER: "Step 1: Gather the Team",
  CHARTER_RESOURCE_ONE_STEP_ONE_DESCRIPTION:
    "Schedule a dedicated time for your meeting. Ensure that all team members are present and prepared to participate. This process is most effective when everyone contributes.",
  CHARTER_RESOURCE_ONE_STEP_TWO_HEADER: "Step 2: Ask the Three Key Questions",
  CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_ONE: "What are we doing WELL?",
  CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_ONE:
    'Encourage the team to highlight specific successes, both individual and collective. This helps reinforce positive behavior and build morale. For example, ask "How have we recently demonstrated strong teamwork?".',
  CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_TWO: "What could we do BETTER?",
  CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_TWO:
    'Are there areas where we, as individuals or as a team, could step up our commitment? Encourage honest, solution-oriented feedback without assigning blame. For example, ask "Where can we improve to better meet our goals?".',
  CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_THREE:
    "HOW can we be more moving forward?",
  CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_THREE:
    'Here, collaborate on actionable steps to improve performance and set goals for future check-ins. For example, ask "What specific changes or actions can we take to strengthen our team culture?".',
  CHARTER_RESOURCE_ONE_STEP_THREE_HEADER:
    "Step 3: Summarize and Assign Actions",
  CHARTER_RESOURCE_ONE_STEP_THREE_DESCRIPTION:
    "After discussing all three questions, summarize the key takeaways. Ensure that action items are clearly assigned to individuals or groups and establish a timeline for follow-up.",
  CHARTER_RESOURCE_ONE_STEP_FOUR_HEADER: "Step 4: Follow-Up",
  CHARTER_RESOURCE_ONE_STEP_FOUR_DESCRIPTION:
    "At your next team meeting, review the agreed-upon actions. Celebrate progress and revisit the Well-Better-How process to keep the team aligned and continuously improving.",
  CHARTER_RESOURCE_ONE_TIPS_HEADER: "Tips for Effective Check-ins",
  CHARTER_RESOURCE_ONE_TIPS_DESCRIPTION_ONE:
    "Foster Open Dialogue: Create a safe space for honest feedback.",
  CHARTER_RESOURCE_ONE_DESCRIPTION_TWO:
    "Stay Focused: Keep the conversation aligned with the Team Charter and team goals.",
  CHARTER_RESOURCE_ONE_DESCRIPTION_THREE:
    "Follow-Up: Consistently track progress and revisit key points at future meetings.",
  CHARTER_TEAM_RESOURCES: "Team Resources",
  CHARTER_SPORT_PAGE_TITLE: "Respect in Sport+",
  CHARTER_FOUNDATIONAL_TRAINING_DESCRIPTION:
    "Complete this short training to get started.",
  CHARTER_TEAM_CHARTER_DESCRIPTION:
    "Meet with your team and define your culture.",
  CHARTER_TEAM_CHECK_IN_LEADER: "Team Check-In",
  CHARTER_TEAM_CHECK_IN_PLAYER: "Check-In",
  CHARTER_TEAM_CHECK_IN_DESCRIPTION:
    "Get a quick pulse on how things are going.",
  CHARTER_TEAM_CHECK_IN_TEAM_PROGRESS: "Team Progress",
  CHARTER_TEAM_CHECK_IN_TEAM_PROGRESS_DESCRIPTION:
    "Keep tabs on who still needs to complete their training.",
  CHARTER_INTERSTITIAL_TEXT_LEADER:
    "This program will help you and your team build a respectful and resilient team culture. You'll complete a short foundational training for leaders, and you'll organize a group workshop where your team will build a Team Charter. But first, let's collect some details about your team.",
  CHARTER_INTERSTITIAL_TEXT_PLAYER:
    "This program will help you and your team build a respectful and resilient team culture. You'll complete a short foundational training program, and later participate in a group workshop where you'll help to build your Team Charter. Let's get started!",
  CHARTER_TEAM_EDIT_WHAT_IS_NAME: "What's your team's name?",
  CHARTER_TEAM_NAME_INVALID: "Name Invalid",
  CHARTER_TEAM_ADD_TEAM_LOGO: "Add your team logo",
  CHARTER_TEAM_ADD_TEAM_LOGO_INSTRUCTIONS:
    "Please upload a 450x310 transparent PNG.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_HEADER:
    "How useful has the charter been so far?",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH: "Areas of Strength",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_TITLE_ONE:
    "Inclusive communication",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_DESCRIPTION_ONE:
    "Team members made sure everyone had a chance to speak during team huddles.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_TITLE_TWO:
    "Supporting interaction",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_DESCRIPTION_TWO:
    "The team demonstrated strong support for each other during drills and practices, contributing to a positive and encouraging environment.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_TITLE_THREE:
    "Effective teamwork",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_DESCRIPTION_THREE:
    "During games, players worked together without placing blame on individuals for mistakes, which strengthened team cohesion and trust.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT: "Areas for Improvement",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_TITLE_ONE:
    "Interruptions during discussions",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_DESCRIPTION_ONE:
    "Players reported frequent interruptions and talking over each other during strategic discussions and explanations, hindering effective communication.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_TITLE_TWO:
    "Conflict during intense moments",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_DESCRIPTION_TWO:
    "There were instances of arguing and conflict during high-stress situations, which affected team harmony and respect.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_TITLE_THREE:
    "Feedback reception",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_DESCRIPTION_THREE:
    "Some players felt that feedback was not always received constructively, impacting overall team development and respect for each other’s perspectives.",
  CHARTER_TEAM_CHECK_IN_HEADER:
    "Available after your Respect Charter is complete",
  CHARTER_TEAM_CHECK_IN_SUBHEADER: "It's time to tell us how it's going!",
  CHARTER_TEAM_CHECK_IN_COMPLETE: "Check-In has been completed",
  CHARTER_TEAM_CHECK_IN_NOW: "Check-In Now",

  MODERATOR_GUIDE: "Moderator Guide",
};

const CHARTER_ES = {
  CHARTER_GROUP_TITLE: "Groups and Group Reporters",
  CHARTER_GROUP_DESCRIPTION:
    "Time to get into our small groups! We’ll randomly assign everyone to a group. Feel free to move and sit with your group to discuss together.",
  CHARTER_GROUP_REPORT_INSTRUCTION:
    "During each Group Activity, we’ll use your device to enter the group’s answers.",
  CHARTER_GROUP_YOU_ARE_GROUP_REPORTER:
    "You have been chosen as the Group Reporter",
  CHARTER_GROUPING_ACTIVITY: "Grouping Activity",
  CHARTER_GROUPING_WAITING: "Waiting to be grouped",
  CHARTER_GROUP: "Group",
  CHARTER_GROUPS: "Groups",

  // Team Value Activity
  CHARTER_TEAM_VALUES_TITLE: "Team Values",
  CHARTER_TEAM_VALUES_DESCRIPTION:
    "Keeping in mind what we talked about, what behaviors, values, or attitudes do you and your teammates always expect from each other?",
  CHARTER_TEAM_VALUES_QUESTION:
    "What behavior, value, or attitude do you and your teammates always expect from each other?",

  // Best Worst Activity one
  CHARTER_BEST_WORST_DESCRIPTION:
    "What are some of the <span style='color: #2E7D32;'><b>BEST</b></span> and <span style='color: #d1534f'><b>WORST</b></span> team characteristics you’ve experienced or can imagine?",
  CHARTER_BEST_WORST_ANSWER_INSTRUCTION: "One to three answers per team member",
  CHARTER_BEST_QUESTION:
    "What are some of the <span style='color: #2E7D32;'><b>BEST</b></span> characteristics that make a team culture successful?",
  CHARTER_WORST_QUESTION:
    "What are some of the <span style='color: #d1534f'><b>WORST</b></span> characteristics that can hurt a team and its chances for success?",

  // Best Worst Activity two
  CHARTER_BEST_WORST_TWO_DESCRIPTION:
    "In your groups, discuss what you entered and pick the three top examples of the <span style='color: #2E7D32;'><b>BEST</b></span> things you’ve experienced and the three <span style='color: #d1534f'><b>WORST</b></span> things you’ve experienced.",
  CHARTER_BEST_WORST_TWO_INSTRUCTION:
    "Choose up to five answers from the list provided",
  CHARTER_BEST_WORST_TWO_GROUP_REPORTER_INSTRUCTION: "Choose up to three.",
  CHARTER_BEST_TWO_QUESTION:
    "Which are the best examples of characteristics that make a team successful?",
  CHARTER_WORST_TWO_QUESTION:
    "What are the most serious examples of characteristics that can harm a team?",

  // We Statement Activity one
  CHARTER_WE_STATEMENT_ONE_INTRO_ONE:
    "Time to create some WE statements of our own! What are some commitments we want to live by as a team? For example:",
  CHARTER_WE_STATEMENT_ONE_INTRO_TWO:
    "<b>WE WILL</b> uplift and motivate each other to stay positive",
  CHARTER_WE_STATEMENT_ONE_INTRO_THREE:
    "<b>WE ARE</b> committed to supporting each other both on and off the field",
  CHARTER_WE_STATEMENT_ONE_INSTRUCTION:
    "Try to come up with two WE statements as a group",
  CHARTER_WE_STATEMENT_ONE_REVIEW_INSTRUCTION: `OK, it's break time! Take a few minutes to recharge.
  <br/>
  <br/>
  During the break, your presenter will review the WE statements to remove duplicates and correct spelling mistakes. Keep statements that may need clarification, as we'll have the chance to ask questions and discuss them as a team.
  <br/>
  <br/>
  Aim to have five to ten statements after editing.`,
  CHARTER_WE_STATEMENT_ONE_ENTER_UP_TO_TWO: "Enter at least two WE statements.",
  CHARTER_WE_STATEMENT_ONE_PICK_YOUR_FAVOURITE:
    "Which WE statements best represent your team values?",
  CHARTER_WE_STATEMENT_ONE_FOUR_TO_FIVE: "Select 4 to 5 statements.",
  CHARTER_WE_STATEMENT_ONE_THREE_TO_FOUR: "Select 3 to 4 statements.",
  CHARTER_WE_STATEMENT_ONE_TWO_TO_THREE: "Select 2 to 3 statements.",
  CHARTER_WE_STATEMENT_ONE_AT_LEAST_FOUR: "Enter at least 4 statements.",
  CHARTER_WE_STATEMENT_ONE_AT_LEAST_THREE: "Enter at least 3 statements.",
  CHARTER_WE_STATEMENT_ONE_AT_LEAST_TWO: "Enter at least 2 statements.",
  CHARTER_WE_STATEMENT_ONE_GROUP_SAID: "Your group said:",
  CHARTER_WE_STATEMENT_ONE_EDITOR_TITLE: "WE Statements",
  CHARTER_WE_STATEMENT_EDITOR_DESCRIPTION:
    "Edit or remove a WE statement by clicking the icons.",
  CHARTER_WE_STATEMENT_QUESTION:
    "Discuss with your group and think of some WE statements you think the team should live by.",

  // We Statement Activity two
  CHARTER_WE_STATEMENT_TWO_DESCRIPTION:
    "Over the break, all of your WE statements were reviewed for duplicates and spelling. Now, in your small groups, you’ll review each statement. If a statement seems good as-is, simply move on to the next one. If a statement isn’t sounding quite right or you’d like to discuss it further, check the box to flag it for additional conversation with the whole group.",
  CHARTER_WE_STATEMENT_TWO_FINAL_TITLE: "Final WE Statements",
  CHARTER_WE_STATEMENT_TWO_INSTRUCTION_ONE:
    "If any statements were flagged for discussion, take some time to talk them through and decide if they should be left as-is, edited, or removed.",
  CHARTER_WE_STATEMENT_TWO_INSTRUCTION_DISCUSS: "Flag for discussion",

  // Charter Edit Activity
  CHARTER_EDIT_TITLE: "Customize Charter",
  CHARTER_EDIT_DESCRIPTION: "Customize the look of your Team Charter.",
  CHARTER_WE_STATEMENTS: "Declaraciones NOSOTROS",
  CHARTER_Title: "Carta",

  // Scenario Activity
  CHARTER_SCENARIO_TITLE: "Scenario Review",
  CHARTER_SCENARIO_LABEL: "Scenario: ",
  CHARTER_SCENARIO_DOES_IT_VIOLATE:
    "Would the charter support us in this scenario?",
  CHARTER_SCENARIO_YES: "Yes",
  CHARTER_SCENARIO_NO: "No",
  CHARTER_SCENARIO_INSTRUCTION_ONE: "What did you think about each scenario?",
  CHARTER_SCENARIO_INSTRUCTION_TWO: `Let’s go through each group’s scenario and review their thoughts.`,
  CHARTER_SCENARIO_INSTRUCTION_THREE:
    "Read the scenario as a group and answer the following questions about how you would approach the situation.",
  CHARTER_SCENARIO_INSTRUCTION_FOUR:
    "Then, we’ll go through each scenario and discuss some appropriate reactions.",
  CHARTER_SCENARIO_QUESTION_INSTRUCTION_ONE:
    "Would your Team Charter be helpful in this scenario?",
  CHARTER_SCENARIO_QUESTION_INSTRUCTION_TWO: "Why or why not?",
  CHARTER_SCENARIO_QUESTION_INSTRUCTION_THREE:
    "Which WE statement would help you address this scenario?",
  CHARTER_SCENARIO_QUESTION_CHOOSE_WE:
    "Which WE statement is most aligned to handling this scenario?",
  CHARTER_SCENARIO_VIEW_CHARTER: "Ver/editar la carta",
  CHARTER_SCENARIO_SELECT_WE: "Select a WE statement",
  CHARTER_SCENARIO_ENTER_RESPONSE: "Enter your response",
  CHARTER_SCENARIO_YOUR_SCENARIO: "Your Scenario: ",

  // Ambassador Activity
  CHARTER_AMBASSADOR_DESCRIPTION: `We’ll now vote on our Charter Champions! As mentioned, these are the team members who will promote and support our team values, expectations, and commitments, both in and out of competition.`,
  CHARTER_AMBASSADOR_DESCRIPTION_RESULTS: `Here are your team’s Charter Champions!`,

  CHARTER_AMBASSADOR_INSTRUCTION: "Vote for your top three choices",
  CHARTER_AMBASSADOR_VOTE_QUESTION: "Vote for Charter Champions",
  CHARTER_AMBASSADOR_VOTE_INSTRUCTIONS:
    "Select one to three team members to represent your Team Charter.",
  CHARTER_OUR_AMBASSADOR_TITLE: `Our Charter Champions`,
  CHARTER_OUR_AMBASSADOR_DESCRIPTION: `Here are our three Charter Champions that we all chosen as a group.  They be expected to uphold the charter on behalf of us all time.`,

  CHARTER_STATISTICS: "Total de respuestas del grupo:",
  CHARTER_CLICK_PROCEED: "Click the button when you’re ready to proceed. ",
  CHARTER_EVERYONE_WILL_PARTICIPATE:
    "Everyone will participate on their individual devices",
  CHARTER_INDIVIDUAL: "Individual",
  CHARTER_ACTIVITY: "Activity",
  CHARTER_INDIVIDUAL_ACTIVITY: "Actividad individual",
  CHARTER_GROUP_ACTIVITY: "Actividad grupal",
  CHARTER_ONLY_USE_GROUP_REPORTER_DEVICE:
    "Usaremos solo el dispositivo del reportero del grupo",
  CHARTER_TAKE_SOME_TIME_TO_DISCUSS:
    "Tómense un tiempo para discutir los resultados en voz alta",
  CHARTER_MAX_INPUTS_ONE_SENTENCE: "Una respuesta por miembro del equipo",
  CHARTER_MAX_INPUTS_THREE_SENTENCE: "One to three answers per team member",
  CHARTER_MAX_INPUTS_FIVE_SENTENCE:
    "Elige hasta cinco respuestas de la lista proporcionada",
  CHARTER_BREAK_TIME_AND_WE_STATEMENTS_REVIEW: "Break and WE Statement Editing",
  CHARTER_CLICK_THE_BUTTON_WHEN_YOU_ARE_READY_TO_PROCEED:
    "Click the button when you are ready to proceed",
  CHARTER_WAITING_FOR_GROUP_LEADER:
    "Waiting for your group leader to submit your answers.",
  CHARTER_WAITING_FOR_PRESENTER_TO_CONTINUE:
    "Waiting for the presenter to continue.",
  CHARTER_ITS_BREAK_TIME: "It's break time!",
  CHARTER_FONT: "Font",
  CHARTER_BACKGROUND_COLOR: "Background Color",
  CHARTER_APPROACHING: "Your Team Charter workshop is approaching!",
  CHARTER_START_PRESENTATION: "Start Workshop",
  CHARTER_RESUME_PRESENTATION: "Resume Workshop",
  CHARTER_JOIN_PRESENTATION: "Join Workshop",
  CHARTER_VIEW_CHARTER: "View Team Charter",
  CHARTER_SCHEDULE: "Schedule your Team Charter workshop",
  CHARTER_SCHEDULE_DATE: "dddd, MMM DD '@' h:mm a",
  CHARTER_SCHEDULE_ANOTHER: "Schedule another Team Charter workshop",
  CHARTER_ITS_TIME: "It's time for your Team Charter!",
  CHARTER_COMING_SOON: "Your Team Charter workshop is coming soon!",
  CHARTER_SCHEDULE_MODAL_PARAGRAPH_1:
    "Championship teams understand that there’s a real connection between culture and success, and the Team Charter workshop will help your team to define its culture.",
  CHARTER_SCHEDULE_MODAL_PARAGRAPH_2:
    "You'll need access to a room and a screen to broadcast the presentation, and your players will need their phones to participate in group activities. Expect the workshop to take about an hour.",
  CHARTER_SCHEDULE_MODAL_PARAGRAPH_SHORT:
    "You'll need access to a room and a screen, and your players will need their phones.",
  CHARTER_SCHEDULE_INVALID_DATE:
    "Please choose a valid date that is not in the past.",

  // Rating View
  CHARTER_BETTER_TEAM:
    "This workshop helped us understand how to be a better team",
  CHARTER_RECOMMEND: "I would recommend this workshop to other teams",
  CHARTER_DEFAULT_NAME: "My Team Charter Default",
  CHARTER_FOUNDATIONAL_TRAINING_COMPLETE: "Foundational Training Complete?",
  CHARTER_COMPLETED_FOUNDATIONAL_TRAINING: "Completed foundational training:",
  CHARTER_NO_TRAININGS: "There are no specific trainings to display.",
  CHARTER_VIEW_DETAILS: "View Details",
  CHARTER_COMPLETED_TRAINING: "Completed training:",
  CHARTER_YOUR_TEAM: "Your team:",
  CHARTER_SPORT_TEAM_TITLE: "Team - Respect in Sport+",
  CHARTER_SPORT_RESOURCES_TITLE: "Resources - Respect in Sport+",
  CHARTER_RESOURCE_ONE: "How to run a Well-Better-How meeting",
  CHARTER_RESOURCE_TWO: "Resource #2",
  CHARTER_RESOURCE_ONE_HEADER: "Well-Better-How Guide",
  CHARTER_RESOURCE_ONE_STEP_ONE_HEADER: "Step 1: Gather the Team",
  CHARTER_RESOURCE_ONE_STEP_ONE_DESCRIPTION:
    "Schedule a dedicated time for your meeting. Ensure that all team members are present and prepared to participate. This process is most effective when everyone contributes.",
  CHARTER_RESOURCE_ONE_STEP_TWO_HEADER: "Step 2: Ask the Three Key Questions",
  CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_ONE: "What are we doing WELL?",
  CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_ONE:
    'Encourage the team to highlight specific successes, both individual and collective. This helps reinforce positive behavior and build morale. For example, ask "How have we recently demonstrated strong teamwork?".',
  CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_TWO: "What could we do BETTER?",
  CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_TWO:
    'Are there areas where we, as individuals or as a team, could step up our commitment? Encourage honest, solution-oriented feedback without assigning blame. For example, ask "Where can we improve to better meet our goals?".',
  CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_THREE:
    "HOW can we be more moving forward?",
  CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_THREE:
    'Here, collaborate on actionable steps to improve performance and set goals for future check-ins. For example, ask "What specific changes or actions can we take to strengthen our team culture?".',
  CHARTER_RESOURCE_ONE_STEP_THREE_HEADER:
    "Step 3: Summarize and Assign Actions",
  CHARTER_RESOURCE_ONE_STEP_THREE_DESCRIPTION:
    "After discussing all three questions, summarize the key takeaways. Ensure that action items are clearly assigned to individuals or groups and establish a timeline for follow-up.",
  CHARTER_RESOURCE_ONE_STEP_FOUR_HEADER: "Step 4: Follow-Up",
  CHARTER_RESOURCE_ONE_STEP_FOUR_DESCRIPTION:
    "At your next team meeting, review the agreed-upon actions. Celebrate progress and revisit the Well-Better-How process to keep the team aligned and continuously improving.",
  CHARTER_RESOURCE_ONE_TIPS_HEADER: "Tips for Effective Check-ins",
  CHARTER_RESOURCE_ONE_TIPS_DESCRIPTION_ONE:
    "Foster Open Dialogue: Create a safe space for honest feedback.",
  CHARTER_RESOURCE_ONE_DESCRIPTION_TWO:
    "Stay Focused: Keep the conversation aligned with the Team Charter and team goals.",
  CHARTER_RESOURCE_ONE_DESCRIPTION_THREE:
    "Follow-Up: Consistently track progress and revisit key points at future meetings.",
  CHARTER_TEAM_RESOURCES: "Team Resources",
  CHARTER_SPORT_PAGE_TITLE: "Respect in Sport+",
  CHARTER_FOUNDATIONAL_TRAINING_DESCRIPTION:
    "Complete this short training to get started.",
  CHARTER_TEAM_CHARTER_DESCRIPTION:
    "Meet with your team and define your culture.",
  CHARTER_TEAM_CHECK_IN_LEADER: "Team Check-In",
  CHARTER_TEAM_CHECK_IN_PLAYER: "Check-In",
  CHARTER_TEAM_CHECK_IN_DESCRIPTION:
    "Get a quick pulse on how things are going.",
  CHARTER_TEAM_CHECK_IN_TEAM_PROGRESS: "Team Progress",
  CHARTER_TEAM_CHECK_IN_TEAM_PROGRESS_DESCRIPTION:
    "Keep tabs on who still needs to complete their training.",
  CHARTER_INTERSTITIAL_TEXT_LEADER:
    "This program will help you and your team build a respectful and resilient team culture. You'll complete a short foundational training for leaders, and you'll organize a group workshop where your team will build a Team Charter. But first, let's collect some details about your team.",
  CHARTER_INTERSTITIAL_TEXT_PLAYER:
    "This program will help you and your team build a respectful and resilient team culture. You'll complete a short foundational training program, and later participate in a group workshop where you'll help to build your Team Charter. Let's get started!",
  CHARTER_TEAM_EDIT_WHAT_IS_NAME: "What's your team's name?",
  CHARTER_TEAM_NAME_INVALID: "Name Invalid",
  CHARTER_TEAM_ADD_TEAM_LOGO: "Add your team logo",
  CHARTER_TEAM_ADD_TEAM_LOGO_INSTRUCTIONS:
    "Please upload a 450x310 transparent PNG.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_HEADER:
    "How useful has the charter been so far?",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH: "Areas of Strength",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_TITLE_ONE:
    "Inclusive communication",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_DESCRIPTION_ONE:
    "Team members made sure everyone had a chance to speak during team huddles.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_TITLE_TWO:
    "Supporting interaction",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_DESCRIPTION_TWO:
    "The team demonstrated strong support for each other during drills and practices, contributing to a positive and encouraging environment.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_TITLE_THREE:
    "Effective teamwork",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_STRENGTH_DESCRIPTION_THREE:
    "During games, players worked together without placing blame on individuals for mistakes, which strengthened team cohesion and trust.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT: "Areas for Improvement",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_TITLE_ONE:
    "Interruptions during discussions",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_DESCRIPTION_ONE:
    "Players reported frequent interruptions and talking over each other during strategic discussions and explanations, hindering effective communication.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_TITLE_TWO:
    "Conflict during intense moments",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_DESCRIPTION_TWO:
    "There were instances of arguing and conflict during high-stress situations, which affected team harmony and respect.",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_TITLE_THREE:
    "Feedback reception",
  CHARTER_TEAM_CHECK_IN_SUMMARY_AREAS_IMPROVEMENT_DESCRIPTION_THREE:
    "Some players felt that feedback was not always received constructively, impacting overall team development and respect for each other’s perspectives.",
  CHARTER_TEAM_CHECK_IN_HEADER:
    "Available after your Respect Charter is complete",
  CHARTER_TEAM_CHECK_IN_SUBHEADER: "It's time to tell us how it's going!",
  CHARTER_TEAM_CHECK_IN_COMPLETE: "Check-In has been completed",
  CHARTER_TEAM_CHECK_IN_NOW: "Check-In Now",

  MODERATOR_GUIDE: "Moderator Guide",
};
export const HVSportsLocalizeStringsObj = {
  en: {
    ...CHARTER_EN,
  },
  fr: {
    ...CHARTER_FR,
  },
  es: {
    ...CHARTER_ES,
  },
};

export const HVSportLocalizeStrings = new LocalizedStrings(
  HVSportsLocalizeStringsObj
);
