import { HVLocalizeStrings } from "../Localization/HVLocalizeStrings";
import {
  LocalizationText,
  QuestionAnswerDto,
  QuestionType,
  QuestionUserResponseDto,
  UserRole,
} from "@headversity/contract";
import Papa from "papaparse";
import { getBlobUrlAndDownload } from "../Api/Utils";
import { isWebPlatform } from "./mobileUtils";
import { NewRelicCapacitorPlugin } from "@newrelic/newrelic-capacitor-plugin";
import { NavigateOptions, To } from "react-router";

export const getEnumArray = (enumObject: any): any[] => {
  return Object.keys(enumObject)
    .filter((key) => isNaN(Number(key)))
    .map((key) => enumObject[key])
    .sort((a, b) => String(enumObject[a]).localeCompare(String(enumObject[b])));
};

export const shuffle = (array: any[]) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const getKey = (): string => {
  let dt: string | null = null;
  if (sessionStorage.getItem("useSessionStorageToken")) {
    dt = sessionStorage.getItem("dt");
  } else {
    dt = localStorage.getItem("dt");
  }
  return dt ? dt : "";
};

export const truncate = (
  text: string | LocalizationText,
  len: number,
  atSentenceEnd: boolean = false
): string => {
  if (!text) return text;

  const textString = text.toString();

  // cut to len chars max
  if (textString.length < len) return textString;

  // try to cut at a sentence break
  if (atSentenceEnd) {
    let lastSentenceEnd = Number.MAX_VALUE;
    for (const x of [". ", "? ", "! "]) {
      const index = textString.indexOf(x);

      if (
        index > -1 &&
        index < lastSentenceEnd && // take the first occurence
        index <= len && // not end of string
        index > 35 // at least 35 chars
      ) {
        lastSentenceEnd = index;
      }
    }

    if (lastSentenceEnd < Number.MAX_VALUE) {
      return textString.substring(0, lastSentenceEnd + 1);
    }
  }

  let truncated = textString.substring(0, len);

  // find the last space and truncate at the last word
  const lastIndex = truncated.lastIndexOf(" ");

  if (lastIndex !== -1) {
    truncated = truncated.substring(0, lastIndex);
  }

  return truncated + "...";
};

export const getUserRoleTranslated = (role: UserRole) => {
  switch (role) {
    case UserRole.Learner:
      return HVLocalizeStrings.USER_ROLE_LEARNER;
    case UserRole.Admin:
      return HVLocalizeStrings.USER_ROLE_ADMIN;
    case UserRole.HVAdmin:
      return HVLocalizeStrings.USER_ROLE_HV_ADMIN;
    case UserRole.HVContentAdmin:
      return HVLocalizeStrings.USER_ROLE_HV_CONTENT_ADMIN;
    case UserRole.HVCSMAdmin:
      return HVLocalizeStrings.USER_ROLE_HV_CSM_ADMIN;
    case UserRole.HVMktAdmin:
      return HVLocalizeStrings.USER_ROLE_HV_MKT_ADMIN;
    default:
      return HVLocalizeStrings.USER_ROLE_SALES;
  }
};

export const getLanguageName = (lang: string) => {
  switch (lang) {
    case "en":
      return HVLocalizeStrings.LANGUAGE_ENGLISH;
    case "fr":
      return HVLocalizeStrings.LANGUAGE_FRENCH;
    case "es":
      return HVLocalizeStrings.LANGUAGE_SPANISH;
  }
  return "";
};

export const getBrowserLocale = () => {
  let userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  return userLocale.split("-")[0];
};

export const generateGuid = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return "x" + S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4();
};

export const getLocalStorageCounter = (key: string) => {
  return parseInt(localStorage.getItem(key) ?? "1");
};

export const incrementLocalStorageCounter = (key: string) => {
  // have the key already?
  const count = localStorage.getItem(key);

  // increment or set to 1
  const newCount = count ? parseInt(count) + 1 : 1;

  // set
  localStorage.setItem(key, newCount.toString());

  return newCount;
};

export const getIntroVideoUrl = (
  featureDetails: any,
  lang: string,
  defaultUrl: string
) => {
  // if there's no feature defined, use the default
  if (!featureDetails || featureDetails.videoUrl === undefined) {
    return getVideoUrlWithCaptioning(lang, defaultUrl);
  }

  // otherwise, check if the feature flag says to:
  //  shut off the video completely,
  else if (featureDetails.videoUrl === null) {
    return null;
  }

  //  use a single url for all languages,
  else if (typeof featureDetails.videoUrl === "string") {
    return getVideoUrlWithCaptioning(lang, featureDetails.videoUrl);
  }

  //  or use specific urls for each language
  else {
    return featureDetails.videoUrl[lang];
  }
};

export const getVideoUrlWithCaptioning = (lang: string, videoUrl: string) => {
  switch (lang) {
    case "fr":
      return videoUrl + "&texttrack=fr";
    case "es":
      return videoUrl + "&texttrack=es-419";
    default:
      return videoUrl;
  }
};

export function sortByProperty<T>(
  array: T[],
  propertyFn: (item: T) => number | undefined,
  descending = true
): T[] {
  return array.slice().sort((a, b) => {
    const valueA = propertyFn(a) ?? 0;
    const valueB = propertyFn(b) ?? 0;
    return descending ? valueB - valueA : valueA - valueB;
  });
}

export const appendToUrl = (
  baseUrl: string | URL,
  valuesToAppend: { name: string; value: string }[]
): string => {
  const url = new URL(baseUrl);
  for (const valueToAppend of valuesToAppend) {
    url.searchParams.append(valueToAppend.name, valueToAppend.value);
  }
  return url.toString();
};

export const getFeedbackQuestionAnswers = (
  questionText: string,
  result: string
): QuestionAnswerDto[] => {
  return [
    {
      question: {
        en: questionText,
      },
      questionType: QuestionType.Feedback,
      questionAlternatives: [],
      responseText: result,
      responses: [],
      type: "",
    },
  ];
};

export const getEnabledAppsCount = (
  soloEnabled: boolean | null,
  teamEnabled: boolean | null,
  reachEnabled: boolean | null,
  certEnabled: boolean | null,
  sportEnabled: boolean | null
) => {
  return [
    soloEnabled,
    teamEnabled,
    reachEnabled,
    certEnabled,
    sportEnabled,
  ].filter(Boolean).length;
};

export const createAndDownloadCSV = (data: any[], fileName: string) => {
  const csv = Papa.unparse(data);
  let csvData;

  if (data.length === 0) {
    csvData = new Blob([csv]);
  } else {
    const BOM = "\uFEFF";
    csvData = new Blob([BOM + csv]);
  }

  getBlobUrlAndDownload(csvData, fileName + ".csv");
};

export const getNonFeedbackResponses = (
  questionUserResponses?: QuestionUserResponseDto[]
) => {
  if (!questionUserResponses) return [];

  return questionUserResponses.filter(
    (x) =>
      x.responseText !== "like" &&
      x.responseText !== "dislike" &&
      x.question.questionText.toString() !== "FeedbackComments"
  );
};

export const sortWitLocaleCompare = (
  aVal: string | LocalizationText,
  bVal: string | LocalizationText
) => {
  const a = aVal as string;
  const b = bVal as string;

  return a.localeCompare(b);
};

// Add search params to the search parameters; if clearIfChanged is true, remove all search params if any of the params have a changed value
export const addSearchParams = (
  params: Record<string, string>,
  clearIfChanged?: boolean
): URLSearchParams => {
  const searchParams = new URLSearchParams(window.location.search);

  // Flag to check if we need to remove all search params
  let hasDifferentValue = false;

  // Check if any search param exists with a different value than provided in `params`
  Array.from(searchParams.keys()).forEach((key) => {
    if (key in params && searchParams.get(key) !== params[key]) {
      hasDifferentValue = true;
    }
  });

  // If there is a param with a different value, remove all current search params
  if (clearIfChanged && hasDifferentValue) {
    Array.from(searchParams.keys()).forEach((key) => {
      searchParams.delete(key);
    });
  }

  // Add or update the params in the current search params
  Object.entries(params).forEach(([key, value]) => {
    if (searchParams.get(key) !== value) {
      searchParams.set(key, value);
    }
  });

  return searchParams;
};

export const navigateWithSearchParams = (
  navigate: (to: To, options?: NavigateOptions | undefined) => void,
  path?: string,
  params?: Record<string, string>,
  replace: boolean = true,
  clearIfChanged?: boolean
): void => {
  const updatedSearchParams = params
    ? addSearchParams(params, clearIfChanged)
    : undefined;

  // Check if there are any differences between the current and updated search params
  const currentSearchParams = new URLSearchParams(window.location.search);
  const needsNavigation = updatedSearchParams
    ? JSON.stringify(Array.from(updatedSearchParams.entries()).sort()) !==
      JSON.stringify(Array.from(currentSearchParams.entries()).sort())
    : false;

  if (needsNavigation) {
    // Use the provided path or fallback to the current path
    navigate(
      {
        pathname: path ?? window.location.pathname,
        search: updatedSearchParams?.toString(),
      },
      { replace }
    );
  }
};

export const removeSearchParam = (paramToRemove: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(paramToRemove);

  return searchParams;
};

export const parseJSON = (json: string | undefined | null) => {
  try {
    if (!json) {
      return {};
    }
    return JSON.parse(json);
  } catch (error) {
    sendToNewRelic(error as Error);
  }
};

export const sendToNewRelic = (error: Error) => {
  const errorNamePrefix = `[App-Triggered] ${error.name}`;
  if (!isWebPlatform()) {
    NewRelicCapacitorPlugin.recordError({
      name: errorNamePrefix,
      message: error.message,
      stack: error.stack ?? "",
      isFatal: false,
    });
  } else if ((window as any).newrelic) {
    (window as any).newrelic.noticeError({
      name: errorNamePrefix,
      message: error.message,
      stack: error.stack ?? "",
    });
  }
};
