import {
  Flex,
  IconButton,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaPhoneAlt } from "react-icons/fa";
import { FiSmartphone } from "react-icons/fi";
import { MdMessage } from "react-icons/md";
import { VscGlobe } from "react-icons/vsc";
import {
  Border_Radius,
  DISABLED_BORDER,
  LENGTH_BUTTON_BORDER,
} from "../../Styles/HeadversityStyle";
import { LocalizedHelpResource } from "../Reach/HelpResources/ReachHelpResourcesPreview";
import {
  HelpResourceChannelType,
  HelpResourceViewType,
} from "@headversity/contract";
import { EVENTS, track } from "../../Utils/Analytics";
import { useContext } from "react";
import { GlobalContext } from "../../State/GlobalContext";
import { HVTestId } from "../../Testing/dataTestIds";

interface LocalizedField {
  en: string;
  fr: string;
  es: string;
}

interface LocalizedCardFields {
  call: LocalizedField;
  text: LocalizedField;
  downloadApp: LocalizedField;
  visitWebsite: LocalizedField;
}

interface HelpResourceCardProps {
  helpResource: LocalizedHelpResource;
  language: string;
  viewType: HelpResourceViewType;
  hideBorder?: boolean;
  isPreview?: boolean;
}

const HelpResourceCard = ({
  helpResource,
  language,
  viewType,
  hideBorder,
  isPreview,
}: HelpResourceCardProps) => {
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const { trackHelpResourceChannelClickFromServer } = useContext(GlobalContext);

  const {
    logoUrl,
    name,
    phoneNumber,
    textNumber,
    iosAppUrl,
    androidAppUrl,
    websiteUrl,
    description,
  } = helpResource;

  const localizedCardFields: LocalizedCardFields = {
    call: {
      en: "Call",
      fr: "Appeler",
      es: "Llamar",
    },
    text: {
      en: "Text",
      fr: "Message au",
      es: "Mensaje al",
    },
    downloadApp: {
      en: "Download App",
      fr: "Télécharger l'application",
      es: "Descargar la aplicación",
    },
    visitWebsite: {
      en: "Visit Website",
      fr: "Visitez le site Web",
      es: "Visitar sitio web",
    },
  };

  const getFieldsForLanguage = (
    cardFields: LocalizedCardFields,
    language: string
  ) => {
    return {
      call: cardFields.call[language as keyof LocalizedField],
      text: cardFields.text[language as keyof LocalizedField],
      downloadApp: cardFields.downloadApp[language as keyof LocalizedField],
      visitWebsite: cardFields.visitWebsite[language as keyof LocalizedField],
    };
  };

  const { call, text, downloadApp, visitWebsite } = getFieldsForLanguage(
    localizedCardFields,
    language
  );

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      w={"100%"}
      boxShadow={!hideBorder ? "lg" : undefined}
      p={4}
      border={!hideBorder ? DISABLED_BORDER : undefined}
      borderRadius={Border_Radius}
      gap={4}
      maxW={"400px"}
      data-testid={`${HVTestId.HelpResourceCard.resource}${helpResource.id}`}
    >
      {logoUrl ? (
        <Image src={logoUrl} alt={name} height={"63px"} />
      ) : (
        <Text>{name}</Text>
      )}
      <Text fontSize={isMobile ? "xs" : "small"}>{description}</Text>
      {phoneNumber && (
        <HelpResourceItem
          icon={<FaPhoneAlt />}
          text={`${call} ${phoneNumber}`}
          onClick={() => {
            if (!isPreview) {
              track(EVENTS.GetHelpClickedChannel, {
                helpResourceId: helpResource.id,
                channel: "phone",
              });
              trackHelpResourceChannelClickFromServer(
                helpResource.id,
                HelpResourceChannelType.Phone
              );
            }
            window.location.href = `tel:${phoneNumber}`;
          }}
        />
      )}
      {textNumber && (
        <HelpResourceItem
          icon={<MdMessage />}
          text={`${text} ${textNumber}`}
          onClick={() => {
            if (!isPreview) {
              track(EVENTS.GetHelpClickedChannel, {
                helpResourceId: helpResource.id,
                channel: "sms",
              });
              trackHelpResourceChannelClickFromServer(
                helpResource.id,
                HelpResourceChannelType.Text
              );
            }
            window.location.href = `sms:${textNumber}`;
          }}
        />
      )}
      {viewType === HelpResourceViewType.Android && androidAppUrl && (
        <HelpResourceItem
          icon={<FiSmartphone />}
          text={downloadApp}
          onClick={() => {
            if (!isPreview) {
              track(EVENTS.GetHelpClickedChannel, {
                helpResourceId: helpResource.id,
                channel: "android app",
              });
              trackHelpResourceChannelClickFromServer(
                helpResource.id,
                HelpResourceChannelType.App
              );
            }
            window.open(androidAppUrl, "_blank");
          }}
        />
      )}
      {viewType === HelpResourceViewType.iOS && iosAppUrl && (
        <HelpResourceItem
          icon={<FiSmartphone />}
          text={downloadApp}
          onClick={() => {
            if (!isPreview) {
              track(EVENTS.GetHelpClickedChannel, {
                helpResourceId: helpResource.id,
                channel: "iOS app",
              });
              trackHelpResourceChannelClickFromServer(
                helpResource.id,
                HelpResourceChannelType.App
              );
            }
            window.open(iosAppUrl, "_blank");
          }}
        />
      )}
      {websiteUrl && (
        <HelpResourceItem
          icon={<VscGlobe />}
          text={visitWebsite}
          onClick={() => {
            if (!isPreview) {
              track(EVENTS.GetHelpClickedChannel, {
                helpResourceId: helpResource.id,
                channel: "website",
              });
              trackHelpResourceChannelClickFromServer(
                helpResource.id,
                HelpResourceChannelType.Website
              );
            }
            window.open(websiteUrl, "_blank");
          }}
        />
      )}
    </Flex>
  );
};

interface HelpResourceItemProps {
  onClick: () => void;
  text: string;
  icon: JSX.Element;
}

export const HelpResourceItem = ({
  onClick,
  icon,
  text,
}: HelpResourceItemProps) => {
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
      <Text fontSize={"md"} fontWeight={"semibold"}>
        {text}
      </Text>
      <IconButton
        aria-label={text}
        icon={icon}
        size="md"
        isRound={true}
        border={LENGTH_BUTTON_BORDER}
        onClick={onClick}
        bgColor={"white"}
        fontSize={"24px"}
      />
    </Flex>
  );
};

export default HelpResourceCard;
