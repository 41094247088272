import { Flex, useBreakpointValue } from "@chakra-ui/react";
import SlidingCardHeader from "./SlidingCardHeader";
import { useEffect, useRef, useState } from "react";
import {
  Border_Radius,
  CARD_BACKGROUND_COLOR,
} from "../../../../Styles/HeadversityStyle";

interface Props {
  icon?: string;
  iconLeft?: boolean;
  title?: string;
  subTitle?: string;
  children?: JSX.Element;
  bgImage?: string;
  bg?: string;
  ellipsisColor?: string;
  titleColor?: string;
  subTitleColor?: string;
  minWidth?: string;
  borderRadius?: string;
  showEllipsis?: boolean;
  isMobileOverride?: boolean;
  opacity?: string;
  childHeight?: string;
  border?: string;
}

export default function SlidingCard({
  icon,
  iconLeft,
  title,
  subTitle,
  children,
  bgImage,
  ellipsisColor,
  titleColor,
  subTitleColor,
  minWidth,
  bg,
  borderRadius,
  showEllipsis,
  isMobileOverride,
  opacity,
  childHeight,
  border,
}: Props) {
  const [showContent, setShowContent] = useState(false);
  const slideToggle = () => setShowContent(!showContent);
  const activeCardRef = useRef<null | HTMLDivElement>(null);
  const breakPointMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: false,
  });
  const isMd = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  useEffect(() => {
    if (showContent && activeCardRef.current) {
      activeCardRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [showContent]);
  const isMobile = isMobileOverride ?? breakPointMobile;

  return (
    <Flex
      flexDirection="column"
      borderRadius={borderRadius ?? Border_Radius}
      boxShadow={"lg"}
      bg={bg ?? CARD_BACKGROUND_COLOR}
      minWidth={minWidth}
      maxWidth={isMd ? `${window.innerWidth - 40}px` : "400px"}
      opacity={opacity ?? "1"}
      border={border}
      ref={activeCardRef}
    >
      <SlidingCardHeader
        title={`${isMobile ? title : ""}`}
        icon={icon}
        subTitle={`${isMobile ? subTitle : ""}`}
        onClick={slideToggle}
        isCardOpen={showContent}
        showEllipsis={showEllipsis ?? isMobile}
        bgImage={bgImage}
        ellipsisColor={ellipsisColor}
        titleColor={titleColor}
        subTitleColor={subTitleColor}
        iconLeft={iconLeft}
      />
      <div
        className="skills_slider_container"
        style={{
          height: `${
            showContent || !isMobile
              ? `${isMobile ? childHeight ?? "210px" : childHeight ?? "258px"}`
              : "0px"
          }`,
          overflow: "hidden",
          display: "flex",
          width: "100%",
          margin: "0px",
          flexDirection: "column",
          transition: "height 0.3s ease-out",
          WebkitTransition: "height 0.3s ease-out",
          MozTransition: "height 0.3s ease-out",
          OTransition: "height 0.3s ease-out",
        }}
      >
        {children}
      </div>
    </Flex>
  );
}
