import { Box, Button, Flex, useBreakpointValue } from "@chakra-ui/react";
import {
  Border_Radius,
  BUTTON_PRIMARY_BACKGROUND_COLOR,
  BUTTON_PRIMARY_TEXT_COLOR,
  BUTTON_THIRD_HOVER_COLOR_ALT,
} from "../../Styles/HeadversityStyle";
import { LocalizationText } from "@headversity/contract";

interface AlternativeButtonProps {
  questionId: number;
  alternativeId: number;
  selected: boolean;
  disabled: boolean;
  alternativeName: string | LocalizationText;
  setSelectedResponse: (selectedResponse: {
    questionId: number;
    alternativeId: number;
  }) => void;
  dataTestId?: string;
  minH?: string;
  index?: number;
  defaultBorderColor?: string;
  selectedColor?: string;
  mode?: string;
}

export const AlternativeButton = (props: AlternativeButtonProps) => {
  const {
    questionId,
    alternativeId,
    selected,
    setSelectedResponse,
    alternativeName,
    disabled,
    dataTestId,
    minH,
    index,
    defaultBorderColor,
    selectedColor,
    mode,
  } = props;

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const useColorIndicator = mode !== "checker";

  const getHoverStylesWithColorIndicator = (
    selected: boolean,
    disabled: boolean,
    index: number | undefined
  ) => {
    if (selected || disabled) {
      return {};
    }

    return {
      bgColor: index === undefined ? BUTTON_THIRD_HOVER_COLOR_ALT : "#eee",
      color: index === undefined ? BUTTON_PRIMARY_TEXT_COLOR : undefined,
    };
  };

  const getHoverStylesWithCheckerIndicator = (
    isMobile: boolean | undefined,
    index: number | undefined
  ) => {
    if (isMobile) {
      return {};
    }
    return {
      bgColor: index === undefined ? BUTTON_THIRD_HOVER_COLOR_ALT : "#eee",
      color: BUTTON_PRIMARY_TEXT_COLOR,
    };
  };

  const getHoverStyles = (
    useColorIndicator: boolean,
    isMobile: boolean | undefined,
    selected: boolean,
    disabled: boolean,
    index: number | undefined
  ) => {
    if (useColorIndicator) {
      return getHoverStylesWithColorIndicator(selected, disabled, index);
    } else {
      return getHoverStylesWithCheckerIndicator(isMobile, index);
    }
  };

  return (
    <>
      <Button
        disabled={disabled}
        w="100%"
        maxW={"550px"}
        minH={minH ?? undefined}
        height={"auto"}
        whiteSpace={"pre-wrap"}
        bg={
          !useColorIndicator
            ? "white"
            : index === undefined && selected
            ? BUTTON_PRIMARY_BACKGROUND_COLOR
            : "white"
        }
        color={
          !useColorIndicator
            ? undefined
            : index === undefined && selected
            ? BUTTON_PRIMARY_TEXT_COLOR
            : undefined
        }
        border={index !== undefined ? "1px" : undefined}
        borderColor={
          useColorIndicator && selected ? selectedColor : defaultBorderColor
        }
        boxShadow={"lg"}
        borderRadius={Border_Radius}
        _hover={getHoverStyles(
          useColorIndicator,
          isMobile,
          selected,
          disabled,
          index
        )}
        _active={{
          bg: !useColorIndicator ? "white" : undefined,
        }}
        onClick={() => setSelectedResponse({ questionId, alternativeId })}
        key={alternativeId}
        fontWeight={"400"}
        data-testid={dataTestId}
        justifyContent={index !== undefined ? "flex-start" : undefined}
        textAlign={index !== undefined ? "left" : undefined}
        aria-selected={selected}
      >
        <>
          {index !== undefined && (
            <Flex
              h={"100%"}
              bg={selected ? selectedColor : undefined}
              color={selected ? "white" : undefined}
              align={"center"}
              justify={"center"}
              position={"absolute"}
              left={0}
              fontWeight={"600"}
              fontSize={"xl"}
              w={"65px"}
            >
              {String.fromCharCode(index + 65).toUpperCase()}
            </Flex>
          )}
          {useColorIndicator ? (
            <Flex
              h={"100%"}
              textAlign={index !== undefined ? "left" : undefined}
              w={index !== undefined ? "100%" : undefined}
              flexDir={"row"}
              pl={index !== undefined ? "63px" : undefined}
              py={"20px"}
            >
              {alternativeName
                ? (alternativeName as string)
                    .replace(index !== undefined ? /^\((\w)\)/ : "", "")
                    .trim()
                : ""}
            </Flex>
          ) : (
            <Flex
              h={"100%"}
              textAlign={index !== undefined ? "left" : "center"}
              w={"100%"}
              flexDir={"row"}
              pl={index !== undefined ? "63px" : undefined}
              py={"20px"}
              justifyContent={index !== undefined ? "flex-start" : "center"}
              alignItems={"center"}
              position={"relative"}
            >
              <Box pl="5px" pr="25px" flex="1" textAlign={"left"} maxW={"100%"}>
                {alternativeName
                  ? (alternativeName as string)
                      .replace(index !== undefined ? /^\((\w)\)/ : "", "")
                      .trim()
                  : ""}
              </Box>
              {selected && (
                <Box position="absolute" right="5px">
                  ✔
                </Box>
              )}
            </Flex>
          )}
        </>
      </Button>
    </>
  );
};
