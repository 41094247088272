import {
  Box,
  Flex,
  Text,
  Icon,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";

import {
  BADGE_MODAL_BACKGROUND,
  Border_Radius,
  Respect_Cherry,
  SHIELD_MODAL_PRIMARY_TEXT_COLOR,
  SHIELD_MODAL_SECONDARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { ProgressTrackerSteps } from "../../Common/ProgressTrackerSteps";
import { BiCalendar } from "react-icons/bi";
import { useContext } from "react";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import dayjs from "dayjs";

export interface CertQualificationData {
  certId: number;
  completedQualification: boolean;
  previousPointsEarned: number;
  pointsEarned: number;
  pointsNeeded: number;
  startDate: string;
  endDate: string;
}

export interface QualificationProgressCardProps {
  title: string;
  showTitle?: boolean;
  certQualificationData: CertQualificationData;
  showPointsAsSubtitle?: boolean;
}

export const QualificationProgressHeader = ({
  title,
  certQualificationData,
  showTitle,
  showPointsAsSubtitle,
}: QualificationProgressCardProps) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const { pointsEarned, pointsNeeded } = certQualificationData;
  const pointsRemainingCount = Math.max(pointsNeeded - pointsEarned, 0);
  let pointsRemainingString = HVLocalizeStrings.POINTS_REMAINING;
  if (pointsRemainingCount === 1) {
    pointsRemainingString = HVLocalizeStrings.POINT_REMAINING;
  }

  const pointsRemaining = (
    <Flex fontWeight={"normal"}>
      <span
        dangerouslySetInnerHTML={{
          __html: pointsRemainingString.replace(
            "{0}",
            `<strong>${pointsRemainingCount}</strong>`
          ),
        }}
      ></span>
    </Flex>
  );
  return (
    <Flex
      align={"center"}
      flexDir={"column"}
      gap={2}
      mt={"5px"}
      mb={"30px"}
      fontSize={"md"}
      fontWeight={"600"}
    >
      {(isDesktop || showTitle) && (
        <Text fontSize={"16px"} align={"center"} mb={2}>
          {title}
        </Text>
      )}

      {!showPointsAsSubtitle && pointsEarned < pointsNeeded && (
        <>{pointsRemaining}</>
      )}

      {pointsEarned >= pointsNeeded && (
        <Text fontSize={"14px"} fontWeight={"normal"} mb={2}>
          {HVLocalizeStrings.YOU_ARE_QUALIFIED}
        </Text>
      )}

      <Box h="20px" minW={"100%"}>
        <ProgressTrackerSteps
          value={Math.min(pointsEarned, pointsNeeded)}
          totalStep={pointsNeeded}
          color={Respect_Cherry}
          tooltipSingular={HVLocalizeStrings.CERT_POINTS_TO_QUALIFY}
          tooltipPlural={HVLocalizeStrings.CERT_POINTS_TO_QUALIFY_PLURAL}
          key={Math.min(pointsEarned, pointsNeeded)}
        />
      </Box>
    </Flex>
  );
};

export interface StatItemProps {
  statName: string;
  statValue: string;
}

const StatItem = (props: StatItemProps) => {
  const { statName, statValue } = props;
  return (
    <Flex mt={3} gap={2} fontWeight={"600"} fontSize={"13px"}>
      <Icon
        w={"21px"}
        h={"21px"}
        aria-label={"like"}
        color={Respect_Cherry}
        as={BiCalendar}
      />
      <SimpleGrid columns={2} gap={14}>
        <Text align={"start"} width={"200px"}>
          {statName}
        </Text>
        <Text color={SHIELD_MODAL_SECONDARY_TEXT_COLOR} align={"end"}>
          {statValue}
        </Text>
      </SimpleGrid>
    </Flex>
  );
};

export const QualificationProgressCard = (
  props: QualificationProgressCardProps
) => {
  const { title, certQualificationData } = props;
  const { startDate, endDate } = certQualificationData;
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  return (
    <Box
      border={`solid 777777 1px`}
      borderRadius={Border_Radius}
      px={8}
      pt={8}
      w={isMainContainerXL ? 365 : ["100%", "100%", "100%", 298]}
      h={["100%", "100%", "100%", "100%"]}
      mb={"30px"}
      color={SHIELD_MODAL_PRIMARY_TEXT_COLOR}
      bg={BADGE_MODAL_BACKGROUND}
      bgColor={"white"}
      bgSize={"cover"}
    >
      <QualificationProgressHeader
        title={title}
        certQualificationData={certQualificationData}
      />

      <StatItem
        statName={HVLocalizeStrings.PERIOD_BEGINS}
        statValue={dayjs.utc(startDate).format(HVLocalizeStrings.DATE_FORMAT)}
      />
      <StatItem
        statName={HVLocalizeStrings.PERIOD_ENDS}
        statValue={dayjs.utc(endDate).format(HVLocalizeStrings.DATE_FORMAT)}
      />
    </Box>
  );
};
