import { Box, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FiZap } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import { ShellContext } from "../../../State/ShellContext";
import { HEADER_EXPLORE_BACKGROUND } from "../../../Styles/HeadversityStyle";
import {
  getSortedSkillsForUser,
  getSuggestedPracticesForUser,
} from "../../../Utils/SkillsUtil";
import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { ScrollPanelViewMoreLinkButton } from "../../Common/ScrollPanelViewMoreLinkButton";
import { ResilienceFeedPanel } from "../Shared/ResilienceFeedPanel";
import { SuggestedToolsForYouPanel } from "../Home/SuggestedToolsForYouPanel";
import { FeaturedLessonsPanel } from "../Lessons/FeaturedLessonsPanel";
import { NanoPracticesScrollPanel } from "../Shared/NanoPracticesScrollPanel";
import { EVENTS, track } from "../../../Utils/Analytics";
import { NanoPracticeDto } from "@headversity/contract";
import { GoalDto } from "@headversity/contract/Dto/GoalDto";

export const ExplorePage = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isMainContainerXL } = useContext(ShellContext);

  const {
    getPageTitle,
    skills,
    nanoPractices,
    nanoPracticeInstances,
    userSkillStats,
    selectedGoal,
    selectedUserLanguage,
  } = useContext(GlobalContext);

  const navigate = useNavigate();

  const [practicesToShow, setPracticesToShow] = useState<NanoPracticeDto[]>([]);
  const [lastGoal, setLastGoal] = useState<GoalDto | undefined>();

  useEffect(() => {
    if (
      !skills ||
      !nanoPractices ||
      skills.length === 0 ||
      nanoPractices.length === 0
    )
      return;

    let finalPractices: NanoPracticeDto[] = [];

    if (
      practicesToShow.length > 0 &&
      (!selectedGoal || selectedGoal === lastGoal)
    ) {
      practicesToShow.forEach((p) => {
        finalPractices.push(nanoPractices.find((np) => np.id === p.id)!);
      });
    } else {
      finalPractices = getSuggestedPracticesForUser(
        getSortedSkillsForUser(skills, userSkillStats, selectedGoal),
        nanoPractices,
        nanoPracticeInstances
      ).splice(0, 5);
    }

    setPracticesToShow(finalPractices);
    setLastGoal(selectedGoal);
  }, [skills, nanoPractices, selectedGoal]);

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.EXPLORE} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.EXPLORE}
        description={HVLocalizeStrings.SOLO_EXPLORE_DESCRIPTION}
        backgroundImage={HEADER_EXPLORE_BACKGROUND}
      />
      <FadeAfterDelay>
        <Box
          maxW={isMainContainerXL ? "container.xl" : "container.lg"}
          mx={"auto"}
        >
          <BlockSection
            fadeIn={true}
            title={
              <BlockSectionTitle
                title={HVLocalizeStrings.NANO_PRACTICES}
                description={
                  HVLocalizeStrings.NANO_PRACTICES_HEADER_DESCRIPTION + "."
                }
              />
            }
            columns={1}
          >
            <NanoPracticesScrollPanel
              isDesktop={isDesktop}
              practices={practicesToShow}
              from="Explore"
              showPoints={true}
            />
            <ScrollPanelViewMoreLinkButton
              onClick={() => {
                navigate("/nano-practices");
                track(EVENTS.SeeAllNanoPracticesClicked, {
                  HV_From: "Explore",
                });
              }}
              isDesktop={isDesktop}
              iconType={FiZap}
            >
              {`${HVLocalizeStrings.SEE_ALL_NANO_PRACTICES}`}
            </ScrollPanelViewMoreLinkButton>
          </BlockSection>

          <FeaturedLessonsPanel isWide={true} isHigh={false} from={"Explore"} />

          <SuggestedToolsForYouPanel from={"Explore"} />

          {selectedUserLanguage === "en" && (
            <ResilienceFeedPanel
              from={"Explore Live Sessions"}
              isLiveSessions={true}
            />
          )}

          <ResilienceFeedPanel from={"Explore"} showPoints={true} />
        </Box>
      </FadeAfterDelay>
    </>
  );
};
