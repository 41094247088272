import { useContext, useEffect, useState, useCallback } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { BLOCK_SECTION_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { ReachPromoMaterialCard } from "../ReachPromoMaterialCard";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { Pager, PagerState } from "../../Common/Pager";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";
import { HvSelect, HvSelectOption } from "../../Common/HvSelect";
import {
  ProductType,
  PromotionalMaterialDto,
  ReachAssetType,
} from "@headversity/contract";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import { useReachGlobal } from "../../../Hooks/Reach/useReachGlobal";

const ReachPromoteListView = () => {
  const { getCompanyProductTypes, getProductTypeOptions } = useReachGlobal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const initialPage = parseInt(searchParams.get("page") ?? "1");
  const isMd = useBreakpointValue({ base: false, md: true });
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);
  const {
    promoMaterials,
    setPromoMaterialModalChangeUrl,
    setSelectedPromoMaterial,
    getPromoMaterialAssetsFromServer,
    selectedPromoMaterial,
    adminProductTypes,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [filteredPromoMaterials, setFilteredPromoMaterials] = useState<
    PromotionalMaterialDto[]
  >([]);

  const [filteredType, setFilteredType] = useState<number>(-1);
  const [filteredProductTypes, setFilteredProductTypes] = useState<
    ProductType[]
  >([]);
  const [companyProductTypes, setCompanyProductTypes] = useState<ProductType[]>(
    []
  );
  const [reachFeedPager, setReachFeedPager] = useState<PagerState>({
    currentPage: 0,
    totalPage: 0,
    pagePerRow: 9,
    showPageInput: true,
  });

  const setInitialValues = () => {
    const initialAssetType = searchParams.get("assetType") ?? "";
    const initialProductTypes =
      searchParams.get("productTypes")?.split(",").map(Number) ?? [];
    if (initialAssetType) {
      setFilteredType(parseInt(initialAssetType));
    }
    if (initialProductTypes) {
      setFilteredProductTypes(initialProductTypes);
    }
    if (initialPage) {
      setReachFeedPager((prevState) => ({
        ...prevState,
        currentPage: initialPage - 1,
      }));
    }
  };

  const setInitialPromo = () => {
    const initialPromo = searchParams.get("promo") ?? "";
    if (initialPromo) {
      const promo = promoMaterials.find(
        (pm) => pm.id === parseInt(initialPromo)
      );
      if (promo) {
        setSelectedPromoMaterial(promo);
      }
    }
  };

  const handleSelections = (selectedOptions: HvSelectOption[]) => {
    setFilteredProductTypes(
      selectedOptions.map((option) => option.value as ProductType)
    );
    track(REACH_EVENTS.AllProductsFilter, {
      HV_Reach_FilterType: "ProductTypes",
      HV_Reach_FilterValue: selectedOptions.map((option) => option.value),
    });
  };

  useEffect(() => {
    setPromoMaterialModalChangeUrl(false);

    return () => {
      setPromoMaterialModalChangeUrl(true);
    };
  }, []);

  useEffect(() => {
    if (isInitialLoad && promoMaterials && promoMaterials.length > 0) {
      const companyProductTypes = getCompanyProductTypes(promoMaterials);
      const filteredProductTypes = companyProductTypes.filter((productType) =>
        adminProductTypes.includes(productType)
      );
      setCompanyProductTypes(filteredProductTypes);
      setInitialValues();
      setInitialPromo();
    }
    return () => {
      window.history.replaceState({}, "", window.location.pathname);
    };
  }, [promoMaterials, adminProductTypes]);

  useEffect(() => {
    if (companyProductTypes && companyProductTypes.length > 0) {
      const updateProductType = filteredProductTypes.filter((productType) =>
        companyProductTypes.includes(productType)
      );
      setFilteredProductTypes(updateProductType);
    }
  }, [companyProductTypes]);

  const debouncedSetIsFirstLoad = useCallback(
    debounce(setIsInitialLoad, 300, {
      leading: false,
      trailing: true,
    }),
    []
  );

  useEffect(() => {
    if (promoMaterials.length > 0) {
      debouncedSetIsFirstLoad(false);
    }
  }, [filteredPromoMaterials]);

  useEffect(() => {
    if (promoMaterials && promoMaterials.length > 0) {
      handleFilter();
    }
  }, [
    filteredType,
    filteredProductTypes,
    promoMaterials,
    reachFeedPager.pagePerRow,
  ]);

  const debouncedSetSearchParams = useCallback(
    debounce(setSearchParams, 50, {
      leading: false,
      trailing: true,
    }),
    []
  );

  useEffect(() => {
    if (promoMaterials.length === 0) {
      return;
    }
    let queryParams = new URLSearchParams();
    queryParams.append("page", (reachFeedPager.currentPage + 1).toString());
    queryParams.append("assetType", filteredType.toString());
    if (filteredProductTypes.length > 0) {
      const productTypesString = filteredProductTypes.join(",");
      queryParams.append("productTypes", productTypesString);
    }
    if (selectedPromoMaterial?.id) {
      queryParams.append("promo", selectedPromoMaterial.id.toString());
    }
    if (promoMaterialClicked) {
      queryParams.append("from", "ReachPromoteListView");
    }
    debouncedSetSearchParams(queryParams);
  }, [
    filteredType,
    filteredProductTypes,
    promoMaterials,
    reachFeedPager,
    debouncedSetSearchParams,
    selectedPromoMaterial,
  ]);

  useEffect(() => {
    const pagePerRow = isMainContainerXL ? 12 : 9;
    setReachFeedPager((prevState) => ({
      ...prevState,
      pagePerRow: pagePerRow,
    }));
  }, [isMainContainerXL]);

  const handleResetFilter = () => {
    setFilteredType(-1);
    setFilteredProductTypes([]);
    track(REACH_EVENTS.AllAssetsFilter, {
      HV_Reach_FilterType: "ResetFilter",
    });
  };

  const handleFilter = () => {
    let filterResult: PromotionalMaterialDto[] = [];

    if (promoMaterials && promoMaterials.length > 0) {
      filterResult = promoMaterials
        .filter((pm) => {
          // filter by company product types
          if (filteredProductTypes.length === 0) return true;
          return pm.productTypes?.some((productType) =>
            filteredProductTypes.includes(productType)
          );
        })
        .filter((pm) => {
          // filter by admin product types
          return pm.productTypes?.some((productType) =>
            adminProductTypes.includes(productType)
          );
        })
        .filter(
          (pm) =>
            // filter by asset type
            pm.assetType !== null &&
            (filteredType === -1 || pm.assetType === filteredType)
        );
    }

    const totalPage = Math.max(
      1,
      Math.ceil(filterResult.length / reachFeedPager.pagePerRow)
    );
    if (reachFeedPager.currentPage >= totalPage) {
      setReachFeedPager((prevState) => ({
        ...prevState,
        currentPage: totalPage - 1,
      }));
    } else {
      setReachFeedPager((prevState) => ({
        ...prevState,
        currentPage: isInitialLoad ? initialPage - 1 : 0,
      }));
    }

    setReachFeedPager((prevState) => ({
      ...prevState,
      totalPage: totalPage,
    }));

    setFilteredPromoMaterials(filterResult);
  };

  const [promoMaterialClicked, setPromoMaterialClicked] = useState(false);

  const handleClickPromoMaterial = async (
    promoMaterial: PromotionalMaterialDto
  ) => {
    await getPromoMaterialAssetsFromServer(promoMaterial.id);
    setSelectedPromoMaterial(promoMaterial);
    setPromoMaterialClicked(true);
  };

  return (
    <>
      <VStack
        spacing={isDesktop ? "20px" : "15px"}
        isInline={isDesktop}
        align="stretch"
        pb={"25px"}
      >
        <HvSelect
          w={isDesktop ? "" : "full"}
          value={filteredType}
          onChange={(e) => {
            setFilteredType(parseInt(e.target.value));
            track(REACH_EVENTS.AllAssetsFilter, {
              HV_Reach_FilterType: "AssetType",
              HV_Reach_FilterValue: parseInt(e.target.value),
            });
          }}
        >
          <option value="-1">
            {HVLocalizeStrings.REACH_ASSET_TYPE_ALL_TYPES}
          </option>
          <option value={ReachAssetType.SocialMedia}>
            {HVLocalizeStrings.REACH_SOCIAL_MEDIA_POST}
          </option>
          <option value={ReachAssetType.Poster}>
            {HVLocalizeStrings.REACH_POSTER}
          </option>
          <option value={ReachAssetType.Email}>
            {HVLocalizeStrings.REACH_EMAIL_TEMPLATE}
          </option>
          <option value={ReachAssetType.Other}>
            {HVLocalizeStrings.OTHER}
          </option>
        </HvSelect>
        <HvSelect
          w={isDesktop ? "" : "full"}
          isMultiSelection={true}
          usePrimaryColorForPlaceholder={true}
          options={getProductTypeOptions(companyProductTypes)}
          selectedValues={getProductTypeOptions(filteredProductTypes)}
          onMultiSelectChange={(value) => {
            handleSelections(value as HvSelectOption[]);
          }}
          placeHolder={HVLocalizeStrings.REACH_PRODUCT_TYPE_ALL_TYPES}
          zIndex={999}
        />
        {(filteredType !== -1 || filteredProductTypes.length > 0) && (
          <Flex alignItems={"center"} ps={!isDesktop ? 4 : undefined}>
            <Button
              color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
              colorScheme="orange"
              variant="link"
              size="sm"
              onClick={handleResetFilter}
            >
              {HVLocalizeStrings.REACH_CLEAR_FILTERS}
            </Button>
          </Flex>
        )}
      </VStack>

      <SimpleGrid
        maxW={isMainContainerXL ? "container.xl" : "container.lg"}
        columns={isMainContainerXL ? 4 : isMd ? 3 : 1}
        spacing={"24px"}
        mx="auto"
      >
        {filteredPromoMaterials
          .slice(
            reachFeedPager.currentPage * reachFeedPager.pagePerRow,
            reachFeedPager.currentPage * reachFeedPager.pagePerRow +
              reachFeedPager.pagePerRow
          )
          .map((promo) => {
            return (
              <Box
                key={promo.id}
                onClick={() => handleClickPromoMaterial(promo)}
              >
                <ReachPromoMaterialCard reachPromoItem={promo} />
              </Box>
            );
          })}
      </SimpleGrid>
      <Flex w={"100%"} justify={"center"} align={"center"} mt={16} mb={8}>
        <Pager
          pagerState={reachFeedPager}
          setPagerState={setReachFeedPager}
          withArrows={true}
        />
      </Flex>
    </>
  );
};

export default ReachPromoteListView;
