import { Circle, Icon, SquareProps } from "@chakra-ui/react";
import { BiCheckCircle, BiSolidCircle } from "react-icons/bi";
import { HVTestId } from "../../../Testing/dataTestIds";

interface RadioCircleProps extends SquareProps {
  isComplete: boolean;
  isActive: boolean;
  showCompleteAsCircle?: boolean;
}

export const StepCircle = ({
  isComplete,
  isActive,
  showCompleteAsCircle,
}: RadioCircleProps) => {
  return (
    <Circle
      size="6"
      bg={isComplete ? "accent" : "inherit"}
      borderWidth={isComplete ? "0" : "2px"}
      borderColor={isActive ? "accent" : "inherit"}
    >
      {isComplete ? (
        <Icon
          as={showCompleteAsCircle ? BiSolidCircle : BiCheckCircle}
          color={showCompleteAsCircle ? "#ffffff99" : "green"}
          boxSize="6"
          data-testid={HVTestId.StepCircle.complete}
        />
      ) : (
        <Circle
          bg={isActive ? "accent" : "border"}
          size="3"
          data-testid={HVTestId.StepCircle.incomplete}
        />
      )}
    </Circle>
  );
};
