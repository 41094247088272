import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useContext } from "react";
import { isDesktop } from "react-device-detect";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Interval180Days,
  ReachInsightsContext,
} from "../../../State/Reach/ReachInsightsContext";
import { REACH_INSIGHTS_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { setInsightsChartOptions } from "../../../Utils/ChartsUtil";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import InsightsCard from "../../Common/Cards/InsightsCard";
import InsightsNoData from "./InsightsNoData";
import PsychographicBarChart from "./PsychographicBarChart";
import InsightsLoadingSpinner from "./InsightsLoadingSpinner";
import InsightsPsychographicsLegend from "./InsightsPsychographicsLegend";

interface InsightsPsychographicsPanelProps {
  pdfExport?: boolean;
}

const InsightsPsychographicsPanel = (
  props: InsightsPsychographicsPanelProps
) => {
  let containerSize = useBreakpointValue({
    base: "container.lg",
    xl: "container.xl",
  });

  let graphDimensionsByBreakpoint = useBreakpointValue({
    base: { width: 300, height: 200 },
    sm: { width: 400, height: 200 },
    md: { width: 700, height: 200 },
    lg: { width: 880, height: 200 },
    xl: { width: 900, height: 200 },
    "2xl": { width: 1000, height: 200 },
  }) as { width: number; height: number };
  if (props.pdfExport) {
    graphDimensionsByBreakpoint = { width: 900, height: 200 }; // use xl size for pdf
    containerSize = "container.xl";
  }

  const { psychographicsReport, helpResourceReport } =
    useContext(ReachInsightsContext);

  const renderStrengths = () => {
    if (psychographicsReport.psychographicsStrengths.length === 0) {
      return (
        <>
          <Text fontSize={"small"} color={REACH_INSIGHTS_TEXT_COLOR} mb={1}>
            {
              HVLocalizeStrings.REACH_INSIGHTS_KEY_TAKEAWAYS_STRENGTHS_DESCRIPTION
            }
          </Text>
          <InsightsCard
            title={""}
            hideHeader={true}
            content={
              <InsightsNoData
                description={
                  HVLocalizeStrings.REACH_INSIGHTS_NO_STRENGTHS_DATA_DESCRIPTION
                }
              />
            }
          />
        </>
      );
    }

    return (
      <>
        <Text fontSize={"small"} color={REACH_INSIGHTS_TEXT_COLOR}>
          {HVLocalizeStrings.REACH_INSIGHTS_KEY_TAKEAWAYS_STRENGTHS_DESCRIPTION}
        </Text>
        <SimpleGrid
          mt={2}
          gap={6}
          columns={{ base: props.pdfExport ? 3 : 1, lg: 3 }}
        >
          {psychographicsReport.psychographicsStrengths.map((p) => (
            <PsychographicBarChart
              key={p.id}
              data={p.points}
              title={p.message}
              bobMessage={p.bobMessage}
              pdfExport={props.pdfExport}
            ></PsychographicBarChart>
          ))}
        </SimpleGrid>
      </>
    );
  };

  const renderRisks = () => {
    if (psychographicsReport.psychographicsRisks.length === 0) {
      return (
        <>
          <Text fontSize={"small"} color={REACH_INSIGHTS_TEXT_COLOR} mb={1}>
            {HVLocalizeStrings.REACH_INSIGHTS_KEY_TAKEAWAYS_RISKS_DESCRIPTION}
          </Text>
          <InsightsCard
            title={""}
            hideHeader={true}
            content={
              <InsightsNoData
                description={
                  HVLocalizeStrings.REACH_INSIGHTS_NO_IMPROVEMENTS_DATA_DESCRIPTION
                }
              />
            }
          />
        </>
      );
    }

    return (
      <>
        <Text fontSize={"small"} color={REACH_INSIGHTS_TEXT_COLOR}>
          {HVLocalizeStrings.REACH_INSIGHTS_KEY_TAKEAWAYS_RISKS_DESCRIPTION}
        </Text>
        <SimpleGrid
          mt={2}
          gap={6}
          columns={{ base: props.pdfExport ? 3 : 1, lg: 3 }}
        >
          {psychographicsReport.psychographicsRisks.map((p) => (
            <PsychographicBarChart
              key={p.id}
              data={p.points}
              title={p.message}
              bobMessage={p.bobMessage}
              pdfExport={props.pdfExport}
            ></PsychographicBarChart>
          ))}
        </SimpleGrid>
        {!props.pdfExport && <InsightsPsychographicsLegend />}
      </>
    );
  };

  const pyschographicBody = () => {
    return (
      <>
        {psychographicsReport.lowData ? (
          <InsightsCard
            title={""}
            hideHeader={true}
            content={
              <InsightsNoData
                description={
                  HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                }
              />
            }
          />
        ) : (
          <Box>
            <Flex
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="row"
              flexWrap="wrap"
            >
              <Text
                fontSize="lg"
                fontWeight="semibold"
                color={REACH_INSIGHTS_TEXT_COLOR}
                marginRight={1}
              >
                {HVLocalizeStrings.REACH_INSIGHTS_KEY_TAKEAWAYS_STRENGTH}{" "}
                {HVLocalizeStrings.REACH_INSIGHTS_STRENGTH}
              </Text>
            </Flex>
            {renderStrengths()}
            <Box mt={8}>
              <Flex
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection="row"
                flexWrap="wrap"
              >
                <Text
                  fontSize="lg"
                  fontWeight="semibold"
                  color={REACH_INSIGHTS_TEXT_COLOR}
                  marginRight={1}
                >
                  {HVLocalizeStrings.REACH_INSIGHTS_KEY_TAKEAWAYS_IMPROVEMENT}{" "}
                  {HVLocalizeStrings.REACH_INSIGHTS_IMPROVEMENT}
                </Text>
              </Flex>

              {renderRisks()}

              {/* Box adds spacing between help resource and psychographics */}
              <Box p={4}></Box>
            </Box>
            {/*<Button*/}
            {/*  className="button-link"*/}
            {/*  onClick={() => {}}*/}
            {/*  fontSize={"lg"}*/}
            {/*  fontWeight={"semibold !important"}*/}
            {/*  ml="auto"*/}
            {/*  mr={isDesktop ? undefined : "auto"}*/}
            {/*  mt={6}*/}
            {/*  zIndex={1}*/}
            {/*  color={MODAL_THIRD_TEXT_COLOR}*/}
            {/*  textDecoration={"underline"}*/}
            {/*>*/}
            {/*  {HVLocalizeStrings.REACH_INSIGHTS_VIEW_ALL_AREAS}*/}
            {/*</Button>*/}
          </Box>
        )}
        <Text fontSize={"sm"} mb={4} color={REACH_INSIGHTS_TEXT_COLOR}>
          {HVLocalizeStrings.REACH_INSIGHTS_GET_HELP_DESCRIPTION}
        </Text>
        {helpResourceReport.lowData ||
        !helpResourceReport.helpResourcesViewsData ? (
          <InsightsCard
            title={""}
            hideHeader={true}
            content={
              <InsightsNoData
                description={
                  HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                }
              />
            }
          />
        ) : (
          <InsightsCard
            title={
              HVLocalizeStrings.REACH_INSIGHTS_HELP_RESOURCES_VIEWS_BY_MONTH
            }
            subtitle={
              helpResourceReport.intervalDays > Interval180Days
                ? HVLocalizeStrings.REACH_INSIGHTS_ALL_TIME
                : HVLocalizeStrings.REACH_INSIGHTS_LAST_180_DAYS
            }
            content={
              <HighchartsReact
                highcharts={Highcharts}
                options={setInsightsChartOptions(
                  "area",
                  helpResourceReport.helpResourcesViewsData,
                  helpResourceReport.helpResourcesViewsData[0]?.dates,
                  helpResourceReport.helpResourcesViewsData[0]?.graphStartDate,
                  graphDimensionsByBreakpoint?.width,
                  graphDimensionsByBreakpoint?.height
                )}
              />
            }
            width={"100%"}
          />
        )}
      </>
    );
  };

  let description: string | undefined;
  if (!psychographicsReport.lowData) {
    description =
      psychographicsReport.intervalDays > Interval180Days
        ? HVLocalizeStrings.REACH_INSIGHTS_PSYCHOGRAPHICS_DESCRIPTION_ALL_TIME.replace(
            "{0}",
            psychographicsReport?.responseCounts?.toString()
          )
        : HVLocalizeStrings.REACH_INSIGHTS_PSYCHOGRAPHICS_DESCRIPTION.replace(
            "{0}",
            psychographicsReport?.responseCounts?.toString()
          );
  }

  return (
    <Box
      maxW={containerSize}
      mx={"auto"}
      mt={isDesktop ? "40px" : "20px"}
      mb={"20px"}
    >
      <BlockSectionTitle
        title={HVLocalizeStrings.REACH_INSIGHTS_PSYCHOGRAPHICS_TITLE}
        description={description}
        descriptionMarginTop={3}
      ></BlockSectionTitle>
      {psychographicsReport.isLoading ? (
        <InsightsLoadingSpinner containerSize={containerSize} />
      ) : (
        pyschographicBody()
      )}
    </Box>
  );
};

export default InsightsPsychographicsPanel;
