import { useContext, useEffect, useState } from "react";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Box, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { StickyBreadcrumb } from "../../Common/StickyBreadcrumb";
import { Helmet } from "react-helmet";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import {
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  HEADER_BASE_BACKGROUND_PATH,
} from "../../../Styles/HeadversityStyle";
import { ResilienceFeedSlider } from "../ResilienceFeed/ResilienceFeedSlider";
import { ResilienceSkill20Panel } from "../Shared/ResilienceSkill20Panel";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { SkillDto } from "@headversity/contract";
import dayjs from "dayjs";
import { HVTestId } from "../../../Testing/dataTestIds";
import { ErrorPage } from "../../Shared/Error/ErrorPage";

export const SkillContentPage = () => {
  const { slug } = useParams();
  const { allResilienceFeedPosts, skills, getPageTitle } =
    useContext<IGlobalProvider>(GlobalContext);

  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  const [currentSkill, setCurrentSkill] = useState<SkillDto | undefined>();
  const [skillBlogs, setSkillBlogs] = useState<any[]>([]);
  const [haveSkill, setHaveSkill] = useState<boolean>(true);

  useEffect(() => {
    const skill = skills.find((x) => x.slug === slug);

    if (!skill) {
      setHaveSkill(false);
    } else {
      setCurrentSkill(skill);

      const filteredBlogs = allResilienceFeedPosts?.filter(
        (item) =>
          item.skillCompetencies?.filter(
            (category) => category?.skillId === skill.id
          ).length > 0
      );

      if (filteredBlogs.length > 0) {
        const shuffled = filteredBlogs.sort((item) =>
          dayjs.utc(item.createdAt as string).unix()
        );

        setSkillBlogs(shuffled);
      }
    }
  }, [slug, skills, allResilienceFeedPosts]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 510);
  }, []);

  return (
    <>
      {haveSkill && !currentSkill ? (
        <></>
      ) : haveSkill && currentSkill ? (
        <>
          <Helmet>
            <title>
              {getPageTitle(
                `${currentSkill?.name} - ${HVLocalizeStrings.HEADVERSITY}`
              )}
            </title>
          </Helmet>

          <HeaderSetter
            title={currentSkill.name}
            description={currentSkill.description as string}
            backgroundImage={`${HEADER_BASE_BACKGROUND_PATH}/${currentSkill.context.toLowerCase()}/${
              currentSkill.slug
            }.png`}
          />

          <StickyBreadcrumb
            crumbs={[
              { url: "/", text: HVLocalizeStrings.SOLO_MENU_HOME },
              { url: "/skills", text: HVLocalizeStrings.SOLO_MENU_SKILLS },
            ]}
            current={currentSkill?.name as string}
          />
          <FadeAfterDelay delay={400}>
            <Box
              maxW={isMainContainerXL ? "container.xl" : "container.lg"}
              mx={"auto"}
              p={5}
              pt={0}
              color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
            >
              <ResilienceSkill20Panel skill={currentSkill} />

              {skillBlogs.length > 0 && (
                <Box
                  mt={"50px"}
                  data-testid={HVTestId.SkillContentPage.resFeedContainer}
                >
                  <Text as="span" fontWeight={600}>
                    {currentSkill.name as string}
                  </Text>{" "}
                  <Text as="span">
                    {HVLocalizeStrings.SOLO_MENU_RESILIENCE_FEED.toLowerCase()}
                  </Text>
                  <Box mt={1}>
                    <ResilienceFeedSlider
                      resilienceFeedPosts={skillBlogs}
                      from={"Skill Page"}
                      showPoints={true}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </FadeAfterDelay>
        </>
      ) : (
        !haveSkill && <ErrorPage />
      )}
    </>
  );
};
