import { translateLocalizationText } from "./TranslateUtil";
import { languageObj } from "../App";
import { cloneDeep } from "lodash";

export const cloneAndTranslate = (obj: any) => {
  const x = cloneDeep(obj);
  translateObj(x);

  return x;
};

export const translateObj = (obj: any) => {
  if (!obj) {
    return;
  }
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      return;
    }
    if (typeof obj[key] === "object") {
      if (key.indexOf("_loc") > -1) {
        const parts = key.split("_");

        Object.defineProperty(obj, parts[0], {
          get() {
            // handle an array of language objects
            if (Array.isArray(obj[key])) {
              return obj[key].map((item: any) => {
                return translateLocalizationText(
                  item,
                  languageObj.selectedLanguage
                );
              });
            } else {
              return translateLocalizationText(
                obj[key],
                languageObj.selectedLanguage
              );
            }
          },
        });
      } else {
        translateObj(obj[key]);
      }
    } else if (Array.isArray(obj[key])) {
      translateArray(obj[key]);
    }
  });
};

export const translateArray = (objs: any[]) => {
  if (!objs) {
    return;
  }
  objs.forEach((item) => {
    if (typeof item === "object") {
      translateObj(item);
    }
  });
};
