import { ScrollPanelBlock } from "../../Common/ScrollPanelBlock";
import { ScrollPanel } from "../../Common/ScrollPanel";
import { LessonBlock20 } from "../Lessons/LessonBlock20";
import { MicroLessonDto } from "@headversity/contract";

export interface LessonsScrollPanelProps {
  isDesktop: boolean | undefined;
  lessonsToShow: MicroLessonDto[];
  isWide?: boolean;
  isHigh?: boolean;
  from: string;
  fullDescription?: boolean;
  showPoints?: boolean;
  width?: string;
  height?: string;
}

export const LessonsScrollPanel = (props: LessonsScrollPanelProps) => {
  const {
    lessonsToShow,
    isDesktop,
    isWide,
    isHigh,
    from,
    fullDescription,
    showPoints,
    width,
    height,
  } = props;

  return (
    <ScrollPanel
      scrollOnMobileOnly={false}
      spaceBetween={8}
      showChevronContainer={true}
      slidesPerGroup={isDesktop ? 3 : 1}
      slidesPerView={"auto"}
      isPaginationOnContainer={true}
    >
      {lessonsToShow.map((item) => {
        return ScrollPanelBlock(
          <LessonBlock20
            width={width ? width : isWide ? "240px" : "290px"}
            height={height ? height : !isHigh ? "175px" : "225px"}
            isSmall={!isHigh}
            key={item.id}
            lesson={item}
            fullDescription={fullDescription}
            from={from}
            showPoints={showPoints}
          />,
          isWide ? "240px" : "290px",
          item.id
        );
      })}
    </ScrollPanel>
  );
};
