import { HeadscanInputDto, HeadscanQuestionDto } from "@headversity/contract";
import { useCallback, useState } from "react";
import {
  getHeadScanQuestionsAndAnswers,
  saveHeadScanQuestionAnswer,
} from "../../Api/Solo/HeadscanQuestionApi";
import { getKey } from "../../Utils/Helpers";
import { AxiosResponse } from "axios";

export interface IHeadscanQuestionProvider {
  getHeadScanQuestionsAndAnswersFromServer: () => Promise<
    HeadscanQuestionDto[]
  >;
  saveHeadScanQuestionAnswerToServer: (
    headscanInputDto: HeadscanInputDto
  ) => Promise<AxiosResponse<any>>;
  headScanQuestions: HeadscanQuestionDto[];
  globalHeadScanQuestionModalOpen: boolean;
  setGlobalHeadScanQuestionModalOpen: (
    globalHeadScanQuestionModalOpen: boolean
  ) => void;
}

export const useHeadscanQuestion = (): IHeadscanQuestionProvider => {
  const [headScanQuestions, setHeadScanQuestions] = useState<
    HeadscanQuestionDto[]
  >([]);
  const [globalHeadScanQuestionModalOpen, setGlobalHeadScanQuestionModalOpen] =
    useState<boolean>(false);

  const getHeadScanQuestionsAndAnswersFromServer =
    useCallback(async (): Promise<HeadscanQuestionDto[]> => {
      return getHeadScanQuestionsAndAnswers(getKey()).then((response) => {
        setHeadScanQuestions(response.data);
        return Promise.resolve(response.data);
      }) as Promise<HeadscanQuestionDto[]>;
    }, []);

  const saveHeadScanQuestionAnswerToServer = useCallback(
    async (headscanInputDto: HeadscanInputDto) => {
      return saveHeadScanQuestionAnswer(getKey(), headscanInputDto);
    },
    []
  );

  return {
    getHeadScanQuestionsAndAnswersFromServer,
    saveHeadScanQuestionAnswerToServer,
    headScanQuestions,
    globalHeadScanQuestionModalOpen,
    setGlobalHeadScanQuestionModalOpen,
  };
};
