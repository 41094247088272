export const cumulativePointThresholds = [
  1, 4, 9, 14, 22, 30, 38, 46, 54, 62, 73, 84, 95, 106, 117, 128, 139, 150, 161,
  172, 186, 200, 214, 228, 242, 256, 270, 284, 298, 312, 329, 346, 363, 380,
  397, 414, 431, 448, 465, 482, 502, 522, 542, 562, 582, 602, 622, 642, 662,
  682,
];

export const getTotalStepsFromLevel = (currentLevel: number): number => {
  return currentLevel === 0
    ? cumulativePointThresholds[0]
    : cumulativePointThresholds[currentLevel] -
        cumulativePointThresholds[currentLevel - 1];
};
