import {
    ReachMyOrganizationContextProvider
} from "../../../State/Reach/ReachMyOrganizationContext";
import { ReachMyOrganizationPage } from "./ReachMyOrganizationPage";

export const ReachMyOrganizationPageContainer = () => {
  return (
    <ReachMyOrganizationContextProvider>
      <ReachMyOrganizationPage />
    </ReachMyOrganizationContextProvider>
  );
};
