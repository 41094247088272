import {
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  useBreakpointValue,
} from "@chakra-ui/react";
import { NanoPracticeType } from "@headversity/contract";
import { useContext } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import {
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  INPUT_PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../Utils/Analytics";
import { getEnumArray } from "../../../Utils/Helpers";
import { SearchFilter } from "./NanoPracticesPage";
import { HVTestId } from "../../../Testing/dataTestIds";
import { HvSelect } from "../../Common/HvSelect";

interface ToolbarProps {
  searchFilter: SearchFilter;
  setSearchFilter: React.Dispatch<React.SetStateAction<SearchFilter>>;
}

const TypeDropdown = ({ searchFilter, setSearchFilter }: ToolbarProps) => {
  const prefix = "NANO_PRACTICE_TYPE";
  const types = getEnumArray(NanoPracticeType);
  const handleSelections = (selectedType: number) => {
    if (selectedType === -1) {
      setSearchFilter({ ...searchFilter, type: undefined });
    } else {
      setSearchFilter({ ...searchFilter, type: selectedType });
    }
    track(EVENTS.NanoPracticePageTypeSelect, {
      type: selectedType === -1 ? "All" : NanoPracticeType[selectedType],
    });
  };
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const getTypeTitle = (category: NanoPracticeType) => {
    return (HVLocalizeStrings as any)[
      prefix +
        NanoPracticeType[category].replace(/([A-Z])/g, "_$1").toUpperCase()
    ];
  };

  const sortTypes = (types: NanoPracticeType[]) => {
    return types.sort((a, b) => getTypeTitle(a).localeCompare(getTypeTitle(b)));
  };

  const sortedTypes = sortTypes(types);

  return (
    <HvSelect
      width={isDesktop ? "310px" : "100%"}
      value={searchFilter.type}
      color={INPUT_PRIMARY_TEXT_COLOR}
      onChange={(e) => {
        handleSelections(parseInt(e.target.value));
      }}
      data-testid={HVTestId.NanoPracticeToolBar.typeSelect}
    >
      <option key="All" value="-1">
        {HVLocalizeStrings.NANO_PRACTICE_TYPE_FILTER}
      </option>
      {sortedTypes.map((type) => (
        <option key={type} value={type}>
          {getTypeTitle(type)}
        </option>
      ))}
    </HvSelect>
  );
};

const FavoriteToggle = ({ searchFilter, setSearchFilter }: ToolbarProps) => {
  const handleSelection = (value: boolean) => {
    setSearchFilter({ ...searchFilter, favorites: value });
    track(EVENTS.NanoPracticePageFavoriteToggle, { favorite: value });
  };

  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel
        htmlFor="favorite-toggle"
        mb="0"
        fontSize={"13px"}
        lineHeight={"1.1"}
        color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
      >
        {HVLocalizeStrings.NANO_PRACTICE_FAVORITES_ONLY}
      </FormLabel>
      <Switch
        id="favorite-toggle"
        isChecked={searchFilter.favorites}
        onChange={(e) => handleSelection(e.target.checked)}
      />
    </FormControl>
  );
};

const Toolbar = ({ searchFilter, setSearchFilter }: ToolbarProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Flex direction="row" wrap="wrap" w={"100%"}>
      <Box w={isDesktop ? undefined : "65%"}>
        <TypeDropdown
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
      </Box>
      <Box
        pl={5}
        w={isDesktop ? undefined : "35%"}
        display="flex"
        alignItems="center"
      >
        <FavoriteToggle
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
      </Box>
    </Flex>
  );
};

const NanoPracticeToolbar = ({
  searchFilter,
  setSearchFilter,
}: ToolbarProps) => {
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  return (
    <Container maxW={isMainContainerXL ? "container.xl" : "container.lg"}>
      <Flex direction="column" w={"100%"} mb={6} p={"1"}>
        <Toolbar
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
      </Flex>
    </Container>
  );
};

export default NanoPracticeToolbar;
