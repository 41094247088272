import { Box, Collapse, Flex, HStack, Stack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  FiHome,
  FiRss,
  FiTool,
  FiUser,
  FiZap,
  FiMap,
  FiGrid,
  FiRadio,
} from "react-icons/fi";
import { TbHierarchy } from "react-icons/tb";
import { FaHeart, FaUsers, FaBullseye } from "react-icons/fa";
import { NavButton } from "./NavButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Link, useLocation } from "react-router-dom";
import { ToggleButton } from "./ToggleButton";
import styled from "@emotion/styled";
import {
  CONTRAST_TEXT_COLOR,
  NOTIFICATION_BACKGROUND_COLOR,
  SIDEBAR_BACKGROUND_COLOR,
} from "../../../Styles/HeadversityStyle";
import { SiFuturelearn } from "react-icons/si";
import { MdDomain, MdEmojiObjects, MdGroups } from "react-icons/md";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AppGridMenu } from "./AppGridMenu";
import { AppType, GlobalContext } from "../../../State/GlobalContext";
import { OptimismToolContext } from "../../../State/Solo/OptimismToolContext";
import { FocusToolContext } from "../../../State/Solo/FocusToolContext";
import { IoRocketOutline } from "react-icons/io5";
import { VscBook } from "react-icons/vsc";
import useCompanyReachAdmin from "../../../Hooks/Reach/useCompanyReachAdmin";
import { useSportTeam } from "../../../Hooks/Team/useSportTeam";

const HVSideBarCollapse = styled(Collapse)`
  width: 100%;
`;

interface SidebarProps {
  showToggle?: boolean;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  closeOnClick?: boolean;
  setUseFunnyBg?: any;
}

interface ProfileLinkProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  closeOnClick?: boolean;
  location: any;
  notificationsUnreadCount: number;
  extra: any;
}

const ProfileLink = ({
  isOpen,
  setIsOpen,
  closeOnClick,
  location,
  notificationsUnreadCount,
  extra,
}: ProfileLinkProps) => {
  return (
    <Link
      tabIndex={-1}
      to={`/profile${extra}`}
      onClick={() => {
        if (closeOnClick) {
          setIsOpen(false);
        }
      }}
    >
      <NavButton
        toolTipOnly={!isOpen}
        label={HVLocalizeStrings.SOLO_PROFILE_TITLE}
        icon={FiUser}
        selected={location.pathname?.indexOf("/profile") > -1}
        extra={
          notificationsUnreadCount > 0 && (
            <Box
              pos={"absolute"}
              bottom={"2px"}
              left={"17px"}
              fontSize={"10px"}
              fontWeight={"bold"}
              h={"18px"}
              w={"18px"}
              p={"3px"}
              borderRadius={"100%"}
              bg={NOTIFICATION_BACKGROUND_COLOR}
              color={CONTRAST_TEXT_COLOR}
            >
              {Math.min(notificationsUnreadCount, 99)}
            </Box>
          )
        }
      />
    </Link>
  );
};

/**
 * NOTE: there are 3 nav bars
 *  SidebarDesktop.tsx: desktop
 *  SidebarMobileWeb.tsx: mobile web
 *  appBarMenuItems in App.tsx
 *
 */
export const SidebarMobileWeb = (props: SidebarProps) => {
  const { showToggle, isOpen, setIsOpen, closeOnClick, setUseFunnyBg } = props;
  const {
    currentApp,
    getNotificationCountForTools,
    isReachAdminEnabled,
    selectedUserLanguage,
    isReachHierarchyAdminEnabled,
  } = useContext(GlobalContext);

  const { isLeader } = useSportTeam();
  const companyReachAdmin = useCompanyReachAdmin();

  const { currentPlannedItem } = useContext(OptimismToolContext);
  const { focusPlanItems } = useContext(FocusToolContext);
  const { soloEnabled } = useContext(GlobalContext);

  const location = useLocation();
  const [height, setHeight] = useState(window.innerHeight);

  function handleResize() {
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let navItems = (
    <Stack spacing="1" width={"100%"}>
      <Link
        tabIndex={-1}
        to={"/"}
        onClick={() => {
          if (closeOnClick) {
            setIsOpen(false);
          }
        }}
      >
        <NavButton
          label={HVLocalizeStrings.SOLO_MENU_HOME}
          icon={FiHome}
          selected={location.pathname === "/" || location.pathname === "/solo"}
        />
      </Link>

      <Link
        tabIndex={-1}
        to={"/skills"}
        onClick={() => {
          if (closeOnClick) {
            setIsOpen(false);
          }
        }}
      >
        <NavButton
          label={HVLocalizeStrings.SOLO_MENU_SKILLS}
          icon={FiGrid}
          selected={location.pathname?.indexOf("/skills") > -1}
        />
      </Link>

      <Link
        tabIndex={-1}
        to={"/explore"}
        onClick={() => {
          if (closeOnClick) {
            setIsOpen(false);
          }
        }}
      >
        <NavButton
          toolTipOnly={!isOpen}
          label={"Explore"}
          icon={FiMap}
          reducedWidth={true}
          selected={location.pathname?.indexOf("/explore") > -1}
        />
      </Link>
      <Link
        tabIndex={-1}
        to={"/nano-practices"}
        onClick={() => {
          if (closeOnClick) {
            setIsOpen(false);
          }
        }}
      >
        <NavButton
          toolTipOnly={!isOpen}
          label={HVLocalizeStrings.NANO_PRACTICES}
          icon={FiZap}
          selected={location.pathname?.indexOf("/nano-practices") > -1}
        />
      </Link>

      <Link
        tabIndex={-1}
        to={"/lessons"}
        onClick={() => {
          if (closeOnClick) {
            setIsOpen(false);
          }
        }}
      >
        <NavButton
          label={HVLocalizeStrings.SOLO_MENU_LEARN}
          icon={VscBook}
          selected={location.pathname?.indexOf("/lessons") > -1}
        />
      </Link>
      <Link
        tabIndex={-1}
        to={"/tools"}
        onClick={() => {
          if (closeOnClick) {
            setIsOpen(false);
          }
        }}
      >
        <NavButton
          label={HVLocalizeStrings.SOLO_MENU_PRACTICE}
          icon={FiTool}
          selected={location.pathname?.indexOf("/tools") > -1}
        />
      </Link>
      {selectedUserLanguage === "en" && (
        <Link
          tabIndex={-1}
          to={"/live-sessions"}
          onClick={() => {
            if (closeOnClick) {
              setIsOpen(false);
            }
          }}
        >
          <NavButton
            label={HVLocalizeStrings.SOLO_HOME_LIVE_SESSIONS_TITLE}
            icon={FiRadio}
            selected={location.pathname === "/live-sessions"}
          />
        </Link>
      )}
      <Link
        tabIndex={-1}
        to={"/resilience-feed"}
        onClick={() => {
          if (closeOnClick) {
            setIsOpen(false);
          }
        }}
      >
        <NavButton
          label={HVLocalizeStrings.SOLO_MENU_RESILIENCE_FEED}
          icon={FiRss}
          selected={location.pathname === "/resilience-feed"}
        />
      </Link>

      <ProfileLink
        closeOnClick={closeOnClick}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        location={location}
        notificationsUnreadCount={getNotificationCountForTools(
          currentPlannedItem,
          focusPlanItems
        )}
        extra=""
      />
    </Stack>
  );

  if (currentApp === AppType.REACH) {
    navItems = (
      <Stack spacing="1" width={"100%"}>
        <>
          <Link
            tabIndex={-1}
            to={"/reach"}
            onClick={() => {
              if (closeOnClick) {
                setIsOpen(false);
              }
            }}
          >
            <NavButton
              label={HVLocalizeStrings.SOLO_MENU_HOME}
              icon={FiHome}
              selected={location.pathname === "/reach"}
            />
          </Link>
          {isReachAdminEnabled && (
            <Link
              tabIndex={-1}
              to={"/reach/promote"}
              onClick={() => {
                if (closeOnClick) {
                  setIsOpen(false);
                }
              }}
            >
              <NavButton
                label={HVLocalizeStrings.REACH_PROMOTE}
                icon={IoRocketOutline}
                selected={location.pathname === "/reach/promote"}
              />
            </Link>
          )}
        </>
        {isReachAdminEnabled && (
          <>
            {companyReachAdmin.isCompanyReachAdmin && (
              <Link
                tabIndex={-1}
                to={"/reach/organizations"}
                onClick={() => {
                  if (closeOnClick) {
                    setIsOpen(false);
                  }
                }}
              >
                <NavButton
                  label={HVLocalizeStrings.REACH_MY_ORGANIZATION}
                  icon={MdDomain}
                  selected={location.pathname === "/reach/organizations"}
                />
              </Link>
            )}
            <Link
              tabIndex={-1}
              to={"/reach/organizations/users"}
              onClick={() => {
                if (closeOnClick) {
                  setIsOpen(false);
                }
              }}
            >
              <NavButton
                label={HVLocalizeStrings.REACH_USERS}
                icon={FaUsers}
                selected={location.pathname === "/reach/organizations/users"}
              />
            </Link>
            <Link
              tabIndex={-1}
              to={"/reach/insights"}
              onClick={() => {
                if (closeOnClick) {
                  setIsOpen(false);
                }
              }}
            >
              <NavButton
                label={HVLocalizeStrings.REACH_INSIGHTS}
                icon={MdEmojiObjects}
                selected={location.pathname.includes("/reach/insights")}
              />
            </Link>
            {isReachHierarchyAdminEnabled && companyReachAdmin.isHvAdmin && (
              <Link
                tabIndex={-1}
                to={"/reach/organizations/hierarchies"}
                onClick={() => {
                  if (closeOnClick) {
                    setIsOpen(false);
                  }
                }}
              >
                <NavButton
                  label={HVLocalizeStrings.REACH_HIERARCHIES}
                  icon={TbHierarchy}
                  selected={location.pathname.includes(
                    "/reach/organizations/hierarchies"
                  )}
                />
              </Link>
            )}
          </>
        )}
        {isReachAdminEnabled && soloEnabled && companyReachAdmin.isCompanyReachAdmin && (
          <>
            <Link
              tabIndex={-1}
              to={"/reach/organizations/goals"}
              onClick={() => {
                if (closeOnClick) {
                  setIsOpen(false);
                }
              }}
            >
              <NavButton
                label={HVLocalizeStrings.REACH_GOALS_TITLE}
                icon={FaBullseye}
                selected={location.pathname === "/reach/organizations/goals"}
              />
            </Link>
            <Link
              tabIndex={-1}
              to={"/reach/organizations/help-resources"}
              onClick={() => {
                if (closeOnClick) {
                  setIsOpen(false);
                }
              }}
            >
              <NavButton
                label={HVLocalizeStrings.REACH_HELP_RESOURCES_TITLE}
                icon={FaHeart}
                selected={
                  location.pathname === "/reach/organizations/help-resources"
                }
              />
            </Link>
          </>
        )}
        <ProfileLink
          closeOnClick={closeOnClick}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          location={location}
          notificationsUnreadCount={getNotificationCountForTools(
            currentPlannedItem,
            focusPlanItems
          )}
          extra="?app=reach"
        />
      </Stack>
    );
  } else if (currentApp === AppType.TEAM) {
    navItems = (
      <Stack spacing="1" width={"100%"}>
        <Link
          tabIndex={-1}
          to={"/team"}
          onClick={() => {
            if (closeOnClick) {
              setIsOpen(false);
            }
          }}
        >
          <NavButton
            label={HVLocalizeStrings.SOLO_MENU_HOME}
            icon={FiHome}
            selected={location.pathname === "/team"}
          />
        </Link>
        <Link
          tabIndex={-1}
          to={"/team/paths"}
          onClick={() => {
            if (closeOnClick) {
              setIsOpen(false);
            }
          }}
        >
          <NavButton
            label={HVLocalizeStrings.TEAM_MENU_PATHS}
            icon={SiFuturelearn}
            selected={location.pathname === "/team/paths"}
          />
        </Link>
        <Link
          tabIndex={-1}
          to={"/team/management"}
          onClick={() => {
            if (closeOnClick) {
              setIsOpen(false);
            }
          }}
        >
          <NavButton
            label={HVLocalizeStrings.TEAM_MENU_TEAMS}
            icon={MdGroups}
            selected={location.pathname === "/team/management"}
          />
        </Link>
        <Link
          tabIndex={-1}
          to={"/team/insights"}
          onClick={() => {
            if (closeOnClick) {
              setIsOpen(false);
            }
          }}
        >
          <NavButton
            label={HVLocalizeStrings.TEAM_MENU_INSIGHTS}
            icon={HiOutlineLightBulb}
            selected={location.pathname.includes("/team/insights")}
          />
        </Link>
        <ProfileLink
          closeOnClick={closeOnClick}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          location={location}
          notificationsUnreadCount={getNotificationCountForTools(
            currentPlannedItem,
            focusPlanItems
          )}
          extra="?app=team"
        />
      </Stack>
    );
  } else if (currentApp === AppType.CERT) {
    navItems = (
      <Stack spacing="1" width={"100%"}>
        <Link
          tabIndex={-1}
          to={"/cert"}
          onClick={() => {
            if (closeOnClick) {
              setIsOpen(false);
            }
          }}
        >
          <NavButton
            label={HVLocalizeStrings.SOLO_MENU_HOME}
            icon={FiHome}
            selected={location.pathname.startsWith("/cert")}
          />
        </Link>
        <ProfileLink
          closeOnClick={closeOnClick}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          location={location}
          notificationsUnreadCount={getNotificationCountForTools(
            currentPlannedItem,
            focusPlanItems
          )}
          extra="?app=cert"
        />
      </Stack>
    );
  } else if (currentApp === AppType.SPORT) {
    navItems = (
      <Stack spacing="1" width={"100%"}>
        <Link
          tabIndex={-1}
          to={"/sport"}
          onClick={() => {
            if (closeOnClick) {
              setIsOpen(false);
            }
          }}
        >
          <NavButton
            label={HVLocalizeStrings.SPORT_MENU_HOME}
            icon={FiHome}
            selected={location.pathname === "/sport"}
          />
        </Link>

        {isLeader && (
          <Link
            tabIndex={-1}
            to={"/sport/management"}
            onClick={() => {
              if (closeOnClick) {
                setIsOpen(false);
              }
            }}
          >
            <NavButton
              label={HVLocalizeStrings.TEAM_MENU_TEAMS}
              icon={MdGroups}
              selected={location.pathname === "/sport/management"}
            />
          </Link>
        )}

        <Link
          tabIndex={-1}
          to={"/sport/resources"}
          onClick={() => {
            if (closeOnClick) {
              setIsOpen(false);
            }
          }}
        >
          <NavButton
            label={HVLocalizeStrings.SPORT_MENU_RESOURCES}
            icon={FiGrid}
            selected={location.pathname === "/sport/resources"}
          />
        </Link>
        <ProfileLink
          closeOnClick={closeOnClick}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          location={location}
          notificationsUnreadCount={getNotificationCountForTools(
            currentPlannedItem,
            focusPlanItems
          )}
          extra="?app=sport"
        />
      </Stack>
    );
  }

  return (
    <Flex
      flex={isOpen ? "1" : "0"}
      flexDir={"column"}
      bg={currentApp === AppType.SPORT ? "#333" : SIDEBAR_BACKGROUND_COLOR}
      color={CONTRAST_TEXT_COLOR}
      overflowY="auto"
      maxW={{ base: "full", sm: "xs" }}
      px={{ base: "0", sm: "0" }}
      transition={"all 0.5s ease"}
      boxShadow={isOpen ? "lg" : ""}
      zIndex={200}
      h="100vh"
    >
      <Box
        w="320px"
        h="100%"
        pos={isOpen ? "fixed" : undefined}
        py={{ base: "4" }}
      >
        <HStack
          justify={currentApp === AppType.SPORT ? undefined : "center"}
          pl={currentApp === AppType.SPORT ? "25px" : undefined}
          pr={{ base: "4", sm: "6" }}
          mb={"10"}
        >
          {showToggle && (
            <ToggleButton
              isOpen={false}
              aria-label={HVLocalizeStrings.TOGGLE_NAVIGATION_MENU}
              className="open-menu-button"
              onClick={() => {
                setIsOpen(false);
              }}
            />
          )}

          <AppGridMenu setIsOpen={setIsOpen} />
        </HStack>

        <HVSideBarCollapse in={isOpen} animateOpacity>
          <Stack
            pl={{ base: isOpen ? "4" : "0", sm: isOpen ? "6" : 0 }}
            justify="space-between"
            spacing="1"
            width={"100%"}
          >
            <Stack spacing={{ base: "5", sm: "6" }} shouldWrapChildren>
              {navItems}
            </Stack>
          </Stack>
        </HVSideBarCollapse>

        <Box
          position={"fixed"}
          bottom="0px"
          w={"20px"}
          h={"20px"}
          zIndex="999"
          onClick={() => {
            setUseFunnyBg(true);
          }}
        />
        <Flex
          align={"center"}
          justify={"center"}
          pos={"fixed"}
          top={height - 140}
          zIndex="999"
          w={"100%"}
        ></Flex>
      </Box>
    </Flex>
  );
};
