import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export interface InsightsColumnChartDataPoint {
  name: string;
  y: number;
  color?: string;
}

export interface InsightsColumnChartProps {
  yAxisTitle: string;
  data: InsightsColumnChartDataPoint[];
  width: number;
  height: number;
}

const InsightsColumnChart = (props: InsightsColumnChartProps) => {
  const { height, width, yAxisTitle, data } = props;
  const options = {
    chart: {
      type: "column",
      reflow: true,
      height: height,
      width: width,
    },
    title: {
      text: undefined,
    },
    tooltip: "",
    plotOptions: {
      series: {
        allowPointSelect: false,
        dataLabels: {
          style: {
            fontSize: "16px",
          },
        },
      },
    },
    xAxis: {
      categories: data.map((dataPoint) => {
        return dataPoint.name;
      }),
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
      min: 0,
      max: 100,
      tickInterval: 20,
      gridLineWidth: 0,
      labels: {
        format: "{text}%",
      },
    },
    series: [
      {
        type: "column",
        data: data.map((dataPoint) => {
          return { name: dataPoint.name, y: dataPoint.y, color: dataPoint.color };
        }),
        showInLegend: false,
        dataLabels: {
          enabled: true,
          format: "{y}%",
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default InsightsColumnChart;
