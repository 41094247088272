import { Flex, Image } from "@chakra-ui/react";
import { useEffect } from "react";
import { CharterActivity } from "../../../../@headversity/contract";

export const CharterAutoTestPage = () => {
  useEffect(() => {
    let index = 1;
    const handleMessage = (event: MessageEvent) => {
      if (!event.data.rise360) {
        return;
      }
      switch (index) {
        case 1:
          window.parent.postMessage(
            {
              rise360: "activity",
              activity: CharterActivity.Grouping,
            },
            "*"
          );
          break;
        case 2:
          window.parent.postMessage(
            {
              rise360: "activity",
              activity: CharterActivity.BestWorstStepOne,
            },
            "*"
          );
          break;
        case 3:
          window.parent.postMessage(
            {
              rise360: "activity",
              activity: CharterActivity.BestWorstStepTwo,
            },
            "*"
          );
          break;
        case 4:
          window.parent.postMessage(
            {
              rise360: "activity",
              activity: CharterActivity.WeStatementStepOne,
            },
            "*"
          );
          break;
        case 5:
          window.parent.postMessage(
            {
              rise360: "activity",
              activity: CharterActivity.WeStatementStepTwo,
            },
            "*"
          );
          break;
        case 6:
          window.parent.postMessage(
            {
              rise360: "activity",
              activity: CharterActivity.CharterCreation,
            },
            "*"
          );
          break;
        case 7:
          window.parent.postMessage(
            {
              rise360: "activity",
              activity: CharterActivity.Scenarios,
            },
            "*"
          );
          break;
        case 8:
          window.parent.postMessage(
            {
              rise360: "activity",
              activity: CharterActivity.SelectingCharterChampions,
            },
            "*"
          );
          break;
        case 9:
          window.parent.postMessage(
            {
              rise360: "slideProgress",
              isLessonDone: true,
            },
            "*"
          );
          break;
      }
      index++;
    };

    window.parent.postMessage(
      {
        rise360: "activity",
        activity: CharterActivity.TeamValues,
      },
      "*"
    );

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <Flex
      position={"fixed"}
      zIndex={"21321939012372109378210"}
      h={"100%"}
      w={"100%"}
      left={"0px"}
      top={"0px"}
      background={"white"}
      flexDir={"column"}
      align={"center"}
      justify={"center"}
    >
      <Image src={"https://public-staging.headversitytest.com/hairbio.jpg"} />
    </Flex>
  );
};
