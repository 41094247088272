import { SchedulerFrequency } from "../@headversity/contract";
import dayjs, { Dayjs } from "dayjs";
import { CronExpression, parseExpression } from "cron-parser";
import { OccurrenceDirection } from "../Components/Solo/Tools/Focus/Utils/FocusUtils";

// The function below should only be used with date that is
// in the local timezone. This is a copy of the functions that
// is found in the backend for ScheduleService.ts and Queue.ts

export const getExpressionFromDate = (
  date: Dayjs,
  frequency: SchedulerFrequency
): string => {
  const hour = date.hour();
  const minute = date.minute();
  const dayOfMonth = date.date();
  const dayOfWeek = date.day() + 1;
  const month = date.month() + 1;

  switch (frequency) {
    case SchedulerFrequency.DoNotRepeat:
      return "";
    case SchedulerFrequency.Daily:
      return `0 ${minute} ${hour} * * ?`;
    case SchedulerFrequency.Weekdays:
      return `0 ${minute} ${hour} ? * MON-FRI`;
    case SchedulerFrequency.Weekly:
      return `0 ${minute} ${hour} * * ${dayOfWeek}`;
    case SchedulerFrequency.Monthly:
      return `0 ${minute} ${hour} ${dayOfMonth} * ? ||MONTHLY||`;
    case SchedulerFrequency.Yearly:
      return `0 ${minute} ${hour} ${dayOfMonth} ${month} ? ||YEARLY||`;
  }
};

export const getRunDate = (
  currentDate: Dayjs,
  pattern: string,
  direction: OccurrenceDirection
) => {
  let expression: CronExpression<false> | null = null;
  if (pattern.indexOf("||YEARLY||") > -1) {
    expression = getExpression(
      pattern.replace("||YEARLY||", ""),
      currentDate,
      SchedulerFrequency.Yearly,
      direction
    );
  } else if (pattern.indexOf("||MONTHLY||") > -1) {
    expression = getExpression(
      pattern.replace("||MONTHLY||", ""),
      currentDate,
      SchedulerFrequency.Monthly,
      direction
    );
  } else {
    expression = parseExpression(pattern, {
      currentDate: currentDate.toDate(),
    });
  }

  return direction === OccurrenceDirection.Next
    ? dayjs(expression.next().toDate())
    : dayjs(expression.prev().toDate());
};

const getExpression = (
  pattern: string,
  currentDate: Dayjs,
  type: SchedulerFrequency,
  direction: OccurrenceDirection
) => {
  while (true) {
    const expression = parseExpression(pattern, {
      currentDate: currentDate.toDate(),
    });

    const targetDate =
      direction === OccurrenceDirection.Next
        ? dayjs(expression.next().toDate())
        : dayjs(expression.prev().toDate());
    const cronParts = pattern.split(" ");
    const dayOfMonth = parseInt(cronParts[3]);
    const monthOfYear = parseInt(cronParts[4]);
    if (
      (type === SchedulerFrequency.Monthly &&
        monthlyCheck(currentDate, targetDate, dayOfMonth, direction)) ||
      (type === SchedulerFrequency.Yearly &&
        monthOfYear === 2 &&
        dayOfMonth === 29 &&
        targetDate.year() !==
          (direction === OccurrenceDirection.Next
            ? currentDate.year() + 1
            : currentDate.year() - 1))
    ) {
      pattern = `${cronParts[0]} ${cronParts[1]} ${cronParts[2]} ${
        dayOfMonth - 1
      } ${cronParts[4]} ${cronParts[5]}`;
    } else {
      return parseExpression(pattern, {
        currentDate: currentDate.toDate(),
      });
    }
  }
};

const monthlyCheck = (
  currentDate: Dayjs,
  nextDate: Dayjs,
  dayOfMonth: number,
  direction: OccurrenceDirection
) => {
  if (
    currentDate.date() < dayOfMonth &&
    currentDate.month() !== nextDate.month()
  ) {
    return true;
  } else if (
    currentDate.date() >= dayOfMonth &&
    ((direction === OccurrenceDirection.Next &&
      currentDate.add(1, "month").month() !== nextDate.month()) ||
      (direction === OccurrenceDirection.Previous &&
        currentDate.subtract(1, "month").month() === nextDate.month()))
  ) {
    return true;
  }
  return false;
};

/*
  Parse the cron-like thing for the frequency
*/
export const getFrequencyFromScheduleExpression = (
  scheduleExpression: string
) => {
  return scheduleExpression.indexOf("MONTHLY") > -1
    ? SchedulerFrequency.Monthly
    : scheduleExpression.endsWith("?")
    ? SchedulerFrequency.Daily
    : SchedulerFrequency.Weekly;
};
