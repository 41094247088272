import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Black, White } from "../../../Styles/HeadversityStyle";

export interface PieChartDataPoint extends Highcharts.PointOptionsObject {
  name: string;
  y: number;
  color?: string;
  sliced?: boolean;
  selected?: boolean;
}

export interface InsightsPieChartProps {
  data: PieChartDataPoint[];
  width: number;
  height: number;
}

export const InsightsPieChart: React.FC<InsightsPieChartProps> = ({
  data,
  width,
  height,
}) => {
  const processedData: Highcharts.PointOptionsObject[] = data.map((point) => ({
    ...point,
    dataLabels: {
      format: "{point.percentage:.0f}%",
      style: {
        color:
          point.name === HVLocalizeStrings.REACH_INSIGHTS_TEAM ||
          point.name === HVLocalizeStrings.REACH_INSIGHTS_LESSON
            ? White
            : Black,
        fontSize: "1.2em",
        textOutline: "none",
        opacity: 1,
      },
    } as Highcharts.SeriesPieDataLabelsOptionsObject,
  }));

  const options: Highcharts.Options = {
    chart: {
      type: "pie",
      height: width,
      width: height,
    },
    title: {
      text: undefined,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: true,
          distance: -30,
          style: {
            fontSize: "1.2em",
            textOutline: "none",
            opacity: 1,
          },
        },
      },
      series: {
        allowPointSelect: false,
        cursor: "default",
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        data: processedData,
        borderWidth: 3,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "20px" }}>
        {data.map((point, index) => (
          <span
            key={index}
            style={{ marginRight: "15px", display: "inline-block" }}
          >
            <span
              style={{
                display: "inline-block",
                width: "16px",
                height: "16px",
                backgroundColor: point.color,
                borderRadius: "50%",
                marginRight: "7px",
              }}
            ></span>
            {point.name}
          </span>
        ))}
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
