import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import {
  ErrorCodeType,
  PostLoginDto,
  RequestPasswordCodeDto,
  ResetPasswordDto,
  UpdatePasswordDto,
} from "@headversity/contract";
import { AxiosResponse } from "axios";
import { AuthTokensDto } from "@headversity/contract/Dto/AuthTokensDto";

export const postLogin = (
  token: string
): Promise<AxiosResponse<PostLoginDto>> => {
  const config = getConfig(token);
  return AxiosClient.post(`api/password/postLogin`, {}, config);
};

export const requestPasswordResetCode = (
  requestPasswordResetCodeDto: RequestPasswordCodeDto
): Promise<AxiosResponse<AuthTokensDto>> => {
  return AxiosClient.post(
    "api/password/requestResetCode",
    requestPasswordResetCodeDto
  );
};

export const requestPasswordResetCodeForLoggedUser = (
  token: string,
  isMobile: boolean
): Promise<AxiosResponse<void>> => {
  let url = "api/password/requestResetCodeForLoggedUser";
  if (isMobile) {
    url += "?isMobile=true";
  }
  return AxiosClient.post(url, {}, getConfig(token));
};

export const resetPassword = (
  resetPasswordDto: ResetPasswordDto
): Promise<AxiosResponse<AuthTokensDto>> => {
  return AxiosClient.post("api/password/reset", resetPasswordDto, {
    withCredentials: true,
    headers: {
      "Content-type": "application/json",
    },
  });
};

export const updatePassword = (
  token: string,
  updatePasswordDto: UpdatePasswordDto
): Promise<AxiosResponse<{ errorCode?: ErrorCodeType }>> => {
  return AxiosClient.post(
    "api/password/update",
    updatePasswordDto,
    getConfig(token)
  );
};

export const requestPasswordResetCodeLink = (
  token: string,
  requestPasswordResetCodeDto: RequestPasswordCodeDto
): Promise<AxiosResponse<string>> => {
  return AxiosClient.post(
    "api/password/requestResetCodeLink",
    requestPasswordResetCodeDto,
    getConfig(token)
  );
};
