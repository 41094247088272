import { useContext, useEffect, useState } from "react";
import { LargeModal } from "../../Common/LargeModal";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Flex, Heading } from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import LanguageSwitch from "./LanguageSwitch";
import { getLanguageName } from "../../../Utils/Helpers";
import { EVENTS, track } from "../../../Utils/Analytics";
import { UserDto } from "@headversity/contract";

interface LanguageModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const LanguageModal = (props: LanguageModalProps) => {
  const {
    self,
    languages,
    selectedUserLanguage,
    updateSelectedUserLanguageToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { open, setOpen } = props;

  const [currentSelectedLanguage, setCurrentSelectedLanguage] =
    useState<string>(selectedUserLanguage);

  const handleSaveLanguageClick = (selectedLanguage: string) => {
    setCurrentSelectedLanguage(selectedLanguage);
    updateSelectedUserLanguageToServer(self as UserDto, selectedLanguage);
    track(EVENTS.LanguageChanged, { HV_Language: selectedLanguage });
  };

  useEffect(() => {
    if (currentSelectedLanguage !== selectedUserLanguage) {
      setCurrentSelectedLanguage(selectedUserLanguage);
    }
  }, [selectedUserLanguage]);

  return (
    <LargeModal open={open} setOpen={setOpen as any}>
      <Flex flexDir={"column"} alignItems={"center"} gap={"25px"}>
        <Heading
          fontSize={"23px"}
          color={MODAL_PRIMARY_TEXT_COLOR}
          fontWeight={"600"}
        >
          {HVLocalizeStrings.LANGUAGE_MODAL_HEADING}
        </Heading>
      </Flex>
      <Flex w={"100%"} flexDir={"column"} mt={4} mb="50px">
        {languages?.map((item, index) => {
          return (
            <Flex flexDir={"column"} mt={"30px"} gap={"30px"} key={index}>
              <LanguageSwitch
                title={getLanguageName(item)}
                isChecked={currentSelectedLanguage === item}
                onChange={() => handleSaveLanguageClick(item)}
              />
            </Flex>
          );
        })}
      </Flex>
    </LargeModal>
  );
};
