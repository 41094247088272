import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HeaderSetter } from "../../Common/HeaderSetter";
import {
  BG_REACH_BASE,
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  Equity_Blue,
  HEADER_REACH_HOME_BACKGROUND,
} from "../../../Styles/HeadversityStyle";
import { getScormUrl } from "../../../Api/Reach/ReachApi";
import { getKey } from "../../../Utils/Helpers";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import {
  Box,
  Flex,
  Link,
  ListItem,
  OrderedList,
  useBreakpointValue,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../State/GlobalContext";
import { CertDto, CompletionState } from "@headversity/contract";
import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { InlineBox } from "../../Solo/Shared/InlineBox";
import { HVTestId } from "../../../Testing/dataTestIds";
import { ContentBlock20 } from "../../Solo/Shared/ContentBlock20";
import { AnimatedButton } from "../../Common/AnimatedButton";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import { ShellContext } from "../../../State/ShellContext";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";

export const ReachScormsPage = () => {
  const globalContext = useContext(GlobalContext);

  const { getPageTitle } = globalContext;

  useEffect(() => {}, []);

  return (
    <>
      <Helmet>
        {getPageTitle(`${HVLocalizeStrings.REACH_SCORMS_HEADER_TITLE}`)}
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.REACH_SCORMS_HEADER_TITLE}
        description={HVLocalizeStrings.REACH_SCORMS_HEADER_DESCRIPTION}
        backgroundImage={HEADER_REACH_HOME_BACKGROUND}
        backgroundImageBase={BG_REACH_BASE}
      ></HeaderSetter>
      <FadeAfterDelay noEase={true}>
        <CertScorms />
      </FadeAfterDelay>
    </>
  );
};

const InstructionAccordion = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Accordion allowToggle borderColor={"transparent"} onChange={handleToggle}>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box
              as="span"
              flex="1"
              textAlign="left"
              color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
            >
              {isOpen
                ? HVLocalizeStrings.REACH_SCORMS_HIDE_INSTRUCTIONS
                : HVLocalizeStrings.REACH_SCORMS_SHOW_INSTRUCTIONS}
            </Box>
            <AccordionIcon color={BLOCK_SECTION_PRIMARY_TEXT_COLOR} />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}>
          <Box>{HVLocalizeStrings.REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_1}</Box>
          <Box ml={5}>
            <OrderedList style={{ listStyleType: "disc" }}>
              <ListItem>
                {HVLocalizeStrings.REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_2}
              </ListItem>
              <ListItem>
                {HVLocalizeStrings.REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3}
              </ListItem>
            </OrderedList>
          </Box>
          <Box mt={5}>
            {HVLocalizeStrings.REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_4}
          </Box>
          <Box ml={5}>
            <OrderedList>
              <ListItem>
                {HVLocalizeStrings.REACH_SCORMS_INSTRUCTIONS_STEP_1}
              </ListItem>
              <ListItem>
                {HVLocalizeStrings.REACH_SCORMS_INSTRUCTIONS_STEP_2}
              </ListItem>
              <ListItem>
                {HVLocalizeStrings.REACH_SCORMS_INSTRUCTIONS_STEP_3}
                <Box ml={5}>
                  <OrderedList style={{ listStyleType: "lower-alpha" }}>
                    <ListItem>
                      {
                        HVLocalizeStrings.REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3_A
                      }
                    </ListItem>
                    <ListItem>
                      {
                        HVLocalizeStrings.REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_3_B
                      }
                    </ListItem>
                  </OrderedList>
                </Box>
              </ListItem>
              <ListItem>
                {HVLocalizeStrings.REACH_SCORMS_INSTRUCTIONS_STEP_4}
              </ListItem>
            </OrderedList>
          </Box>
          <Box mt={5}>
            {HVLocalizeStrings.REACH_SCORMS_INSTRUCTIONS_PARAGRAPH_5}
            <Link
              textDecoration={"underline"}
              href="#"
              onClick={(e) => {
                window.open("mailto:help@headversity.com");
                e.preventDefault();
              }}
            >
              help@headversity.com
            </Link>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const CertScorms = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { certsWithScorms, setCloseDialog } = useContext(GlobalContext);
  const { confirmationDialogRef } = useContext(ShellContext);
  const handleImageClick = async (cert: CertDto) => {
    const response = await getScormUrl(cert.id, getKey());
    const url = response.data;
    InAppBrowser.create(url, "_system");
    track(REACH_EVENTS.ReachScormsPackageDownloaded, {
      HV_Reach_ProgramID: cert.id,
      HV_Reach_ProgramTitle: cert.name_loc?.en,
    });
  };

  const handleCertClick = (cert: CertDto) => {
    (confirmationDialogRef.current as any).confirmation(
      <Box whiteSpace={"break-spaces"} wordBreak={"break-word"} mr={1}>
        {cert.name +
          " - " +
          HVLocalizeStrings.REACH_ORGANIZATIONS_LMS_INTEGRATION}
      </Box>,
      <Flex
        px={"6"}
        pt={"2"}
        w={"100%"}
        flexDirection={"column"}
        justify={"center"}
        justifyContent={"center"}
        align={"center"}
      >
        <Box color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}>
          <Button
            onClick={() => {
              handleImageClick(cert);
            }}
            className="button-link"
            textDecoration={"underline"}
          >
            {HVLocalizeStrings.REACH_ORGANIZATIONS_LMS_DOWNLOAD_INSTRUCTION}
          </Button>
        </Box>
        <Box
          w="100%"
          mt={5}
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <InstructionAccordion />
        </Box>
      </Flex>,
      ConfirmationButtons.JSX,
      () => {},
      [
        <Flex
          key={"close"}
          gap={"5"}
          w={"100%"}
          flexDirection={isDesktop ? "row" : "column"}
          justify={"center"}
          justifyContent={"center"}
          align={"center"}
        >
          <AnimatedButton
            text={HVLocalizeStrings.CLOSE}
            onClick={() => {
              setCloseDialog(true);
            }}
          ></AnimatedButton>
        </Flex>,
      ],
      true,
      "5xl"
    );
    track(REACH_EVENTS.ReachScormsPackageOpened, {
      HV_Reach_ProgramID: cert.id,
      HV_Reach_ProgramTitle: cert.name_loc?.en,
    });
  };

  return (
    <BlockSection
      fadeIn={true}
      includeSpacing={true}
      title={
        <BlockSectionTitle
          title={HVLocalizeStrings.REACH_ORGANIZATIONS_LMS_INTEGRATIONS}
          description={HVLocalizeStrings.REACH_SCORMS_DESCRIPTION}
        />
      }
      columns={1}
      rows={1}
      gap={"0"}
    >
      <Box>
        {certsWithScorms.map((cert, idx) => {
          return (
            <InlineBox isDesktop={isDesktop} idx={idx} key={cert.id}>
              <ContentBlock20
                width={!isDesktop ? `${window.innerWidth - 40}px` : undefined}
                height="225px"
                imageUrl={cert.imageThumbnailUrl as string}
                title={cert.name as string}
                subtitle={cert.shortDescription as string}
                progressValue={undefined}
                onClick={() => handleCertClick(cert)}
                doneState={CompletionState.notStarted}
                titleAreaBg={"#ffffffee"}
                titleColor={Equity_Blue}
                subtitleColor={Equity_Blue}
                dataTestId={`${HVTestId.CertBlock.block}${cert.id}`}
                icon={undefined}
                progressIcon={undefined}
              />
            </InlineBox>
          );
        })}
      </Box>
    </BlockSection>
  );
};
