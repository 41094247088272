import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { CharterDisplay } from "../CharterEditor/CharterDisplay";
import { PresentationContainer } from "../Shared/PresentationContainer";
import { PresentationContent } from "../Shared/PresentationContent";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { CharterActivity } from "@headversity/contract";
import {
  QuestionUserResponseEditor,
  QuestionUserResponseEditorIconState,
} from "../Shared/QuestionUserResponseEditor";
import {
  PollQuestionDto,
  ResponseState,
} from "../../../../@headversity/contract";
import { usePollQuestionInformation } from "../../../../Hooks/Sport/usePollQuestionInformation";

interface PresentationCharterEditorProps {
  setActivity: (activity: CharterActivity | undefined) => void;
}

export const PresentationCharterEditor = (
  props: PresentationCharterEditorProps
) => {
  const { setActivity } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const {
    currentTeamLessonUserInstanceId,
    setTeamLessonInProgressStepToServer,
    updatePollUserResponsesToServer,
    pollQuestions,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [currentPollQuestion, setCurrentPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const {
    currentPollInstanceId,
    activePollQuestionResults: weStatementPollResult,
  } = usePollQuestionInformation({
    pollQuestion: currentPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const handleDoneCharterCustomize = () => {
    if (currentTeamLessonUserInstanceId) {
      setLoading(true);
      setTeamLessonInProgressStepToServer(currentTeamLessonUserInstanceId, "");
      setActivity(undefined);
    }
  };

  const handleEditedPollResults = async (
    editedPollResults: {
      id: number;
      responseText: string;
      state: ResponseState;
    }[]
  ) => {
    await updatePollUserResponsesToServer(
      currentPollInstanceId!,
      editedPollResults.map((e) => {
        return {
          responseText: e.responseText,
          state: e.state,
          questionUserResponseId: e.id,
        };
      })
    );
  };

  useEffect(() => {
    if (pollQuestions) {
      const pollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_6_PQ"
      );
      setCurrentPollQuestion(pollQuestion);
    }
  }, [pollQuestions]);

  return (
    <PresentationContainer containerPt={"0"}>
      <PresentationContent
        title={HVSportLocalizeStrings.CHARTER_EDIT_TITLE}
        description={HVSportLocalizeStrings.CHARTER_EDIT_DESCRIPTION}
        hr={false}
        pt={5}
        w={"950px"}
        descriptionMb={"0"}
        descriptionMt={"0"}
        titleMl={"5"}
      >
        <Flex w="100%" alignItems={"center"} justifyContent={"center"} pt={"5"}>
          <Tabs w={"100%"}>
            <TabList mx={"27px"}>
              <Tab>{HVSportLocalizeStrings.CHARTER_Title}</Tab>
              <Tab>{HVSportLocalizeStrings.CHARTER_WE_STATEMENTS}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {currentTeamLessonUserInstanceId && (
                  <CharterDisplay
                    isEditor={true}
                    teamLessonUserInstanceId={currentTeamLessonUserInstanceId}
                    loading={loading}
                    setLoading={setLoading}
                    willBroadcast={true}
                  />
                )}
              </TabPanel>
              <TabPanel h={"560px"}>
                <QuestionUserResponseEditor
                  editedPollResults={
                    weStatementPollResult as {
                      id: number;
                      responseText: string;
                      state: ResponseState;
                    }[]
                  }
                  setEditedPollResults={handleEditedPollResults}
                  iconState={QuestionUserResponseEditorIconState.editOnly}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </PresentationContent>
      <AnimatedButton
        text={HVLocalizeStrings.DONE}
        colorSet={AnimatedButtonColorSet.Primary}
        w={219}
        onClick={() => {
          handleDoneCharterCustomize();
        }}
        disabled={loading}
      ></AnimatedButton>
    </PresentationContainer>
  );
};
