import { Box, Flex, Image, Text, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { FeatureFlags } from "../../../Utils/FeatureFlags";

const PDFFooter = () => {
  const { selectedUserLanguage, getFeatureVersion } =
    useContext<IGlobalProvider>(GlobalContext);
  const customFooter = getFeatureVersion(FeatureFlags.CUSTOM_FOOTER) as any;

  return (
    customFooter && (
      <Flex
        position="absolute"
        left="0"
        top="2004px"
        bg={"white"}
        h="100px"
        w="100%"
        alignItems="flex-start"
      >
        <Box position="relative" left="75px" top="25px">
          <VStack>
            {customFooter.tagline && (
              <Text fontSize={"sm"}>
                {customFooter.tagline[selectedUserLanguage]}
              </Text>
            )}
            {customFooter.logo && (
              <Image
                width="200px"
                src={customFooter.logo[selectedUserLanguage]}
                alt=""
              />
            )}
          </VStack>
        </Box>
      </Flex>
    )
  );
};

export default PDFFooter;
