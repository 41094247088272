import { Icon } from "@chakra-ui/react";
import { RiChatPollLine } from "react-icons/ri";
import { MODAL_CLOSE_BUTTON } from "../../../Styles/HeadversityStyle";
import { BiBrain, BiMessageRoundedDetail } from "react-icons/bi";
import React from "react";
import { QuestionType } from "../../../Utils/PollsUtil";
import { PollQuestionDto } from "@headversity/contract";

interface PollIconProps {
  poll: PollQuestionDto;
  isTransparent?: boolean;
}

export const PollIcon = (props: PollIconProps) => {
  const { poll, isTransparent } = props;
  switch (poll?.question?.questionType) {
    default:
      return (
        <Icon
          as={RiChatPollLine}
          w={"32px"}
          h={"32px"}
          opacity={isTransparent ? "30%" : ""}
          color={MODAL_CLOSE_BUTTON}
        />
      );
    case QuestionType.FreeText:
      return (
        <Icon
          as={BiMessageRoundedDetail}
          w={"32px"}
          h={"32px"}
          opacity={isTransparent ? "30%" : ""}
          color={MODAL_CLOSE_BUTTON}
        />
      );
    case QuestionType.Headzone:
      return (
        <Icon
          as={BiBrain}
          w={"32px"}
          h={"32px"}
          opacity={isTransparent ? "30%" : ""}
          color={MODAL_CLOSE_BUTTON}
        />
      );
  }
};
