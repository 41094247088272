import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { UserLanguageInputDto } from "@headversity/contract";
import { AxiosResponse } from "axios";

export const updateSelectedUserLanguage = (
  token: string,
  userLanguageInputDto: UserLanguageInputDto
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/self/language`,
    userLanguageInputDto,
    getConfig(token)
  );
};
