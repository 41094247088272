import {
  AuthTokensDto,
  InviteCodeDomainDto,
  UserAppAccessInputDto,
  UserDto,
} from "@headversity/contract";
import dayjs from "dayjs";
import { getBrowserLocale } from "./Helpers";
import { isWebPlatform } from "./mobileUtils";
import { Device, DeviceInfo } from "@capacitor/device";
import { App as CapApp } from "@capacitor/app";
import { LocalStorageKeys, LocalStorageWrapper } from "./StorageUtil";
import { AxiosResponse } from "axios";
import { IProfileProvider } from "../Hooks/Solo/useProfile";
import {
  SOLO_GOAL_CHALLENGE_KEY,
  SOLO_GOAL_STREAK_KEY,
  SOLO_GOAL_WELCOME_BACK_KEY,
} from "./SkillsUtil";

export const HUBSPOT_REDIRECT_URL_KEY = "hubspot_redirect_url";
export const HUBSPOT_REDIRECT_URL_SLUG = "hubspot";
export const TEAMS_REDIRECT_URL_SLUG = "ms-teams";

export const processHubspotRedirect = (
  response: AxiosResponse<any>,
  getRedirectUrlFromServer: IProfileProvider["getRedirectUrlFromServer"]
) => {
  const hubspotRedirectUrl = localStorage.getItem(HUBSPOT_REDIRECT_URL_KEY);
  if (hubspotRedirectUrl) {
    setLocalStorageAuthTokens(response.data).then(() => {
      getRedirectUrlFromServer(
        hubspotRedirectUrl,
        process.env.REACT_APP_HUBSPOT_DOMAIN!
      ).then((path: string) => {
        localStorage.removeItem(HUBSPOT_REDIRECT_URL_KEY);
        window.location.href = path;
      });
    });
    return true;
  }
  return false;
};

export const getDomainFromEmail = (
  inviteCodeDomains: InviteCodeDomainDto[],
  email?: string | null
): InviteCodeDomainDto | undefined => {
  if (email) {
    return inviteCodeDomains.find((item) => {
      const parts = email.toLocaleLowerCase().split("@");
      if (parts.length > 1) {
        return parts[1] === item.domain;
      }
      return false;
    });
  }
  return undefined;
};

export const flushLocalStorage = () => {
  localStorage.removeItem("user_scores");
  localStorage.removeItem("has_user_points");
  localStorage.removeItem("cert_qualifications");
  localStorage.removeItem(SOLO_GOAL_WELCOME_BACK_KEY);
  localStorage.removeItem(SOLO_GOAL_CHALLENGE_KEY);
  localStorage.removeItem(SOLO_GOAL_STREAK_KEY);
  setLocalStorageAuthTokens();
};

export const setLocalStorageAuthTokens = async (
  authTokensDto?: AuthTokensDto
) => {
  if (!authTokensDto) {
    localStorage.removeItem("impersonatingUserId");
    localStorage.removeItem("dt");
    await LocalStorageWrapper.removeItem(LocalStorageKeys.REFRESH_TOKEN);
    return;
  }
  if (authTokensDto.access_token) {
    localStorage.setItem("dt", authTokensDto.access_token);
  }

  !isRefreshTokenInCookie() && authTokensDto.refresh_token
    ? await LocalStorageWrapper.setItem(
        LocalStorageKeys.REFRESH_TOKEN,
        authTokensDto.refresh_token
      )
    : await LocalStorageWrapper.removeItem(LocalStorageKeys.REFRESH_TOKEN);
};

const getBrowserDetails = () => {
  let platform;
  let os = "Unknown";
  let userAgent = navigator.userAgent ?? "Unknown";

  if ("userAgentData" in navigator) {
    platform = (
      navigator as Navigator & { userAgentData?: { platform: string } }
    ).userAgentData!.platform;
  } else {
    platform = navigator.platform;
  }

  const osMap = {
    Windows: "Windows",
    Mac: "Mac",
    Linux: "Linux",
    Android: "Android",
    iPhone: "iOS",
  };

  for (let key in osMap) {
    if (userAgent.includes(key)) {
      os = osMap[key as keyof typeof osMap];
      break;
    }
  }

  return { platform, os, userAgent };
};

export const getUserAppInfo = async (
  setDeviceInfo: ((deviceInfo: DeviceInfo) => void) | null = null
): Promise<UserAppAccessInputDto> => {
  const { platform, os, userAgent } = getBrowserDetails();

  let channel = undefined;
  if (sessionStorage.getItem("certKey")) {
    channel = "scorm";
  } else if (sessionStorage.getItem("channel")) {
    channel = sessionStorage.getItem("channel")!;
  }
  let useAppInfo: UserAppAccessInputDto = {
    userLocalTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    model: userAgent,
    uuid: userAgent,
    platform: platform,
    os: os,
    appVersion: "3.0",
    deviceLanguage: getBrowserLocale(),
    fromLMS: sessionStorage.getItem("certKey") !== null,
    channel: channel,
  };
  const deviceInfo = await Device.getInfo();
  if (setDeviceInfo) {
    setDeviceInfo(deviceInfo);
  }
  if (!isWebPlatform()) {
    const deviceId = await Device.getId();
    const appInfo = await CapApp.getInfo();

    useAppInfo.uuid = deviceId.identifier;
    useAppInfo.platform = deviceInfo.platform;
    useAppInfo.os = deviceInfo.osVersion;
    useAppInfo.model = deviceInfo.model;
    useAppInfo.appVersion = appInfo.version;
  }

  return useAppInfo;
};

// We use localStorage if REACT_APP_LOCAL_STORAGE_FOR_TOKEN is set to true, otherwise we use cookies.
export const isRefreshTokenInCookie = (): boolean => {
  return (
    isWebPlatform() &&
    process.env.REACT_APP_LOCAL_STORAGE_FOR_TOKEN === "false" &&
    !sessionStorage.getItem("certKey")
  );
};

export const getFirstNameFromSelf = (self: UserDto | null) => {
  return self && self.fullName && self.fullName.indexOf(" ") > -1
    ? ", " + self.fullName.split(" ")[0]
    : "";
};
