import { As, Button, HStack, Icon, Text, Tooltip } from "@chakra-ui/react";
import * as React from "react";
import {
  NAV_BUTTON_SELECTED_COLOR,
  NAV_SELECTED_BORDER,
} from "../../../Styles/HeadversityStyle";

interface NavButtonProps {
  icon?: As;
  selected?: boolean;
  label: string;
  toolTipOnly?: boolean;
  extra?: any;
  isSmall?: boolean;
  reducedWidth?: boolean;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, toolTipOnly, selected, extra, isSmall, reducedWidth } =
    props;

  return (
    <Tooltip label={toolTipOnly ? label : undefined} placement="right" hasArrow>
      <Button
        pl={selected ? "12px" : "16px"}
        w={"100%"}
        minH={isSmall ? "30px" : "50px"}
        borderRadius={"0"}
        variant="ghost-on-accent"
        justifyContent="start"
        borderLeft={selected ? NAV_SELECTED_BORDER : undefined}
        ml={isSmall ? "20px" : reducedWidth ? "2px" : "0px"}
        aria-label={label}
      >
        <HStack spacing="3">
          {icon && (
            <Icon
              as={icon}
              boxSize={isSmall || reducedWidth ? "5" : "6"}
              color={selected ? NAV_BUTTON_SELECTED_COLOR : "white"}
              aria-hidden="true"
            />
          )}
          {extra}
          {!toolTipOnly && (
            <Text
              pl={1}
              fontSize={isSmall ? "14px" : "15px"}
              color="white"
              whiteSpace={"break-spaces"}
              textAlign={"left"}
              fontWeight={selected ? "600" : "500"}
            >
              {label}
            </Text>
          )}
        </HStack>
      </Button>
    </Tooltip>
  );
};
