import ReCAPTCHA from "react-google-recaptcha";

interface HvReCaptchaProps {
  onChange: () => void;
}

export const HvReCaptcha = (props: HvReCaptchaProps) => {
  const { onChange } = props;
  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
      onChange={onChange}
    />
  );
};
