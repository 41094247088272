import { useEffect, useState } from "react";
import { postLogin } from "../../../Api/People/PasswordApi";
import { getKey } from "../../../Utils/Helpers";
import {
  PasswordContainerChildComponent,
  PostLoginDto,
} from "@headversity/contract";
import PasswordRequirementsContainer from "../Shared/PasswordRequirementsContainer";

export const PostLoginGuard = (props: any) => {
  const { children } = props;
  const [isReady, setIsReady] = useState(false);
  const [isPasswordRequested, setIsPasswordRequested] =
    useState<boolean>(false);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState<boolean>(false);

  useEffect(() => {
    postLogin(getKey())
      .then(
        (response) => {
          const result = response.data as PostLoginDto;
          setIsPasswordRequested(result.passwordRequested);
          setIsFirstTimeUser(result.isFirstTimeUser || false);
        },
        (response) => {}
      )
      .finally(() => {
        setIsReady(true);
      });
  }, []);

  let content = <></>;
  if (isPasswordRequested) {
    content = (
      <PasswordRequirementsContainer
        childComponent={PasswordContainerChildComponent.SetPasswordStep}
        showTermsAndPolicyModal={isFirstTimeUser}
      />
    );
  } else if (isReady) {
    content = children;
  }
  return <>{isReady && content}</>;
};
