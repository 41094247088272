import mixpanel from "mixpanel-browser";

export enum EVENTS {
  ActivityHistoryOpened = "HV_ActivityHistoryOpened",
  BaseDataLoaded = "HV_BaseDataLoaded",
  CertAnswerSaved = "HV_CertAnswerSaved",
  CertComplete = "HV_CertComplete",
  CertDownload = "HV_CertDownload",
  CertFirstTimeInterstitialShown = "HV_CertFirstTimeInterstitialShown",
  CertOpened = "HV_CertOpened",
  CertQualificationDoneModalShown = "HV_CertQualificationDoneModalShown",
  CertReviewingLesson = "HV_CertReviewingLesson",
  CertRiseBack = "HV_CertRiseBack",
  CertSectionLessonComplete = "HV_CertSectionLessonComplete",
  CertSectionLessonOpened = "HV_CertSectionLessonOpened",
  CertShareOnLinkedIn = "HV_CertShareOnLinkedIn",
  CertShareOnLinkedInClickHere = "HV_CertShareOnLinkedInClickHere",
  CertStarted = "HV_CertStarted",
  CertStepComplete = "HV_CertStepComplete",
  ChangePasswordEmailRequested = "HV_ChangePasswordEmailRequested",
  DailyPlanComplete = "HV_DailyPlanComplete",
  DailyPlanItemComplete = "HV_DailyPlanItemComplete",
  FavoriteSuggestionShown = "HV_FavoriteSuggestionShown",
  FavoritedSuggestionPracticeFavorited = "HV_FavoritedSuggestionPracticeFavorited",
  FirstTimeInterstitialShown = "HV_FirstTimeInterstitialShown",
  FirstTimeTeamInterstitialGoToTeam = "HV_FirstTimeTeamInterstitialGoToTeam",
  FullNameChanged = "HV_FullNameChanged",
  GetHelpButtonClicked = "HV_GetHelpButtonClicked",
  GetHelpClickedChannel = "HV_GetHelp_ClickedChannel",
  GetHelpMenuClicked = "HV_GetHelpMenuClicked",
  GoalCheckInFeedback = "HV_GoalCheckInFeedback",
  GoalCheckInFeedbackComments = "HV_GoalCheckInFeedbackComments",
  GoalCheckInModalKeepCurrentPursuit = "HV_GoalCheckInModalKeepCurrentPursuit",
  GoalCheckInModalFeedbackStepReached = "HV_GoalCheckInModalFeedbackStepReached",
  GoalCheckInModalOpened = "HV_GoalCheckInModalOpened",
  GoalCheckInModalNextGoalStepReached = "HV_GoalCheckInModalNextGoalStepReached",
  GoalCheckInModalSummaryStepReached = "HV_GoalCheckInModalSummaryStepReached",
  GoalChooseDifferent = "HV_GoalChooseDifferent",
  GoalModalOpened = "HV_GoalModalOpened",
  GoalModalNewPursuitOpened = "HV_GoalModalNewPursuitOpened",
  GoalSelected = "HV_GoalSelected",
  GoalViewed = "HV_GoalViewed",
  HeadscanQuestionAnswered = "HV_HeadscanQuestionAnswered",
  HeadscanQuestionModalShown = "HV_HeadscanQuestionModalShown",
  HeadscanQuestionShown = "HV_HeadscanQuestionShown",
  ImmediateHelpShown = "HV_ImmediateHelpShown",
  HierarchyUserAssociationSaved = "HV_HierarchyUserAssociationSaved",
  Identified = "HV_Identified",
  LanguageChanged = "HV_LanguageChanged",
  LessonCompleted = "HV_LessonCompleted",
  LessonCompletedForParticipants = "HV_LessonCompletedForParticipants",
  LessonFeedback = "HV_LessonFeedback",
  LessonFeedbackComments = "HV_LessonFeedbackComments",
  LessonModalOpened = "HV_LessonModalOpened",
  LessonOpened = "HV_LessonOpened",
  LessonProgressed = "HV_LessonProgressed",
  LessonStarted = "HV_LessonStarted",
  LessonThumbsQuestionMotivated = "HV_LessonThumbsQuestionMotivated",
  LessonThumbsQuestionRelevant = "HV_LessonThumbsQuestionRelevant",
  LevelUpModalShown = "HV_LevelUpModalShown",
  Loaded = "HV_Loaded",
  ManageChallenge = "HV_ManageChallenge",
  MindfulnessTrackSelected = "HV_MindfulnessTrackSelected",
  NanoPracticeAdvancedToNextOne = "HV_NanoPracticeAdvancedToNextOne",
  NanoPracticeAutoplayToggled = "HV_NanoPracticeAutoplayToggled",
  NanoPracticeBackStep = "HV_NanoPracticeBackStep",
  NanoPracticeEndReached = "HV_NanoPracticeEndReached",
  NanoPracticeFeedback = "HV_NanoPracticeFeedback",
  NanoPracticeFeedbackComments = "NanoPracticeFeedbackComments",
  NanoPracticeFeedbackReached = "HV_NanoPracticeFeedbackReached",
  NanoPracticeModalOpened = "HV_NanoPracticeModalOpened",
  NanoPracticeNextStep = "HV_NanoPracticeNextStep",
  NanoPracticeOpened = "HV_NanoPracticeOpened",
  NanoPracticePageTypeSelect = "HV_NanoPracticePageTypeSelect",
  NanoPracticePageFavoriteToggle = "HV_NanoPracticePageFavoriteToggle",
  NanoPracticeRegenerateQuestions = "HV_NanoPracticeRegenerateQuestions",
  NanoPracticeRegenerateQuestionsComplete = "HV_NanoPracticeRegenerateQuestionsComplete",
  NanoPracticeSummaryReached = "HV_NanoPracticeSummaryReached",
  NanoPracticeViewHistory = "HV_NanoPracticeViewHistory",
  Navigated = "HV_Navigated",
  NextActvitityOpened = "HV_NextActvitityOpened",
  OtherActvitiesChipChanged = "HV_OtherActvitiesChipChanged",
  PointsMapModalShown = "HV_PointsMapModalShown",
  PostActivityLaunchTraining = "HV_PostActivityLaunchTraining",
  PostActivityLevelUpShown = "HV_PostActivityLevelUpShown",
  PostActivityReminderShown = "HV_PostActivityReminderShown",
  PostActivityStreakShown = "HV_PostActivityStreakShown",
  PracticeFavoriteRemoved = "HV_PracticeFavoriteRemoved",
  PracticeReminderEnableToggle = "HV_PracticeReminderEnableToggle",
  PracticeReminderFrequencyChanged = "HV_PracticeReminderFrequencyChanged",
  PracticeReminderRemoved = "HV_PracticeReminderRemoved",
  PracticeReminderSkip = "HV_PracticeReminderSkip",
  PracticeFavorited = "HV_PracticeFavorited",
  PracticeReminderSet = "HV_PracticeReminderSet",
  RedoAllScoresCompleted = "HV_RedoAllScoresCompleted",
  RedoScoreCompleted = "HV_RedoScoreCompleted",
  RedoScoreStarted = "HV_RedoScoreStarted",
  ReminderSuggestionShown = "HV_ReminderSuggestionShown",
  ResilienceFeedCategoryChanged = "HV_ResilienceFeedCategoryChanged",
  ResilienceFeedPostOpened = "HV_ResilienceFeedPostOpened",
  ResiliencePostFeedback = "HV_ResiliencePostFeedback",
  ResiliencePostFeedbackComments = "HV_ResiliencePostFeedbackComments",
  SeeAllLessonsClicked = "HV_SeeAllLessonsClicked",
  SeeAllNanoPracticesClicked = "HV_SeeAllNanoPracticesClicked",
  SeeAllResilienceFeedClicked = "HV_SeeAllResilienceFeedClicked",
  SeeAllToolsClicked = "HV_SeeAllToolsClicked",
  SkillShieldBoxClicked = "HV_SkillShieldBoxClicked",
  StartTrainingActivityChanged = "HV_StartTrainingActivityChanged",
  StartTrainingShown = "HV_StartTrainingShown",
  StartTrainingTypeChanged = "HV_StartTrainingTypeChanged",
  StreakModalShown = "HV_StreakModalShown",
  TeamFirstTimeInterstitialShown = "HV_TeamFirstTimeInterstitialShown",
  TeamTrainingOpened = "HV_TeamTrainingOpened",
  ToolCalmingModeToggled = "HV_ToolCalmingModeToggled",
  ToolCalmingTriggered = "HV_ToolCalmingTriggered",
  ToolExplored = "HV_ToolExplored",
  ToolFocusActionClicked = "HV_ToolFocusActionClicked",
  ToolFocusPlanViewed = "HV_ToolFocusPlanViewed",
  ToolFocusReachedStep = "HV_ToolFocusReachedStep",
  ToolHeadzoneOptionSelected = "HV_ToolHeadzoneOptionSelected",
  ToolHeadzoneSelected = "HV_ToolHeadzoneSelected",
  ToolHistoryOpened = "HV_ToolHistoryOpened",
  ToolOpened = "HV_ToolOpened",
  ToolOptimismActionClicked = "HV_ToolOptimismActionClicked",
  ToolOptimismReachedStep = "HV_ToolOptimismReachedStep",
  ToolThinkingHistoryItemViewed = "HV_ToolThinkingHistoryItemViewed",
  ToolThinkingReachedStep = "HV_ToolThinkingReachedStep",
  UnlockSkillCompleted = "HV_UnlockSkillCompleted",
  UnlockSkillStarted = "HV_UnlockSkillStarted",
  WelcomeConfirmSkillsShown = "HV_WelcomeConfirmSkillsShown",
  WelcomeFlowCompleted = "HV_WelcomeFlowCompleted",
  WelcomeHeadScanShown = "HV_WelcomeHeadScanShown",
  WelcomeHeadzoneShown = "HV_WelcomeHeadzoneShown",
  WelcomeModalBackClicked = "HV_WelcomeModalBackClicked",
  WelcomeModalShown = "HV_WelcomeModalShown",
  WelcomeModalShownProfilePage = "HV_WelcomeModalShownProfilePage",
  WelcomeThemeChanged = "HV_WelcomeThemeChanged",
  WelcomeVideoProgressComplete = "HV_WelcomeVideoProgressComplete",
  WelcomeVideoProgressMidway = "HV_WelcomeVideoProgressMidway",
  WelcomeVideoProgressStart = "HV_WelcomeVideoProgressStart",
  WelcomeVideoShown = "HV_WelcomeVideoShown",
  WelcomeWhereStartShown = "HV_WelcomeWhereStartShown",
}

export enum TEAM_EVENTS {
  AddTeamMember = "HV_TEAM_AddTeamMember",
  BackToPreviewPresentation = "HV_TEAM_BackToPreviewPresentation",
  CancelPresentation = "HV_TEAM_CancelPresentation",
  CatchUpPresentation = "HV_TEAM_CatchUpPresentation",
  ChangeSelectedTeam = "HV_TEAM_ChangeSelectedTeam",
  CompleteTeamLessonUserInstance = "HV_TEAM_CompleteTeamLessonUserInstance",
  CompleteTeamLessonUserInstanceForParticipants = "HV_TEAM_CompleteTeamLessonUserInstanceForParticipants",
  ContinuePath = "HV_TEAM_ContinuePath",
  ContinuePresentationFromPoll = "HV_TEAM_ContinuePresentationFromPoll",
  CreateTeam = "HV_TEAM_CreateTeam",
  DeleteTeam = "HV_TEAM_DeleteTeam",
  DeleteTeamUser = "HV_TEAM_DeleteTeamUser",
  DownloadForOfflineUse = "HV_TEAM_DownloadForOfflineUse",
  EditTeamName = "HV_TEAM_EditTeamName",
  EndAndResumeCatchUpLater = "HV_TEAM_EndAndResumeCatchUpLater",
  EndAndResumePresentationLater = "HV_TEAM_EndAndResumePresentationLater",
  EndTeamLessonUserInstance = "HV_TEAM_EndTeamLessonUserInstance",
  EndPresentationCatchUp = "HV_TEAM_EndPresentationCatchUp",
  EndIncompleteCatchUp = "HV_TEAM_EndIncompleteCatchUp",
  EndIncompleteTeamLessonUserInstance = "HV_TEAM_EndIncompleteTeamLessonUserInstance",
  JoinPresentation = "HV_TEAM_JoinPresentation",
  JoinTeam = "HV_TEAM_JoinTeam",
  LeaveTeam = "HV_TEAM_LeaveTeam",
  LeavePresentation = "HV_TEAM_LeavePresentation",
  ParticipatePresentation = "HV_TEAM_ParticipatePresentation",
  PresentationThumbsRelevant = "HV_TEAM_PresentationThumbsRelevant",
  PresentationThumbsMotivated = "HV_TEAM_PresentationThumbsMotivated",
  PreviewPresentation = "HV_TEAM_PreviewPresentation",
  PreviewPresentationPoll = "HV_TEAM_PreviewPresentationPoll",
  ShareTeamInvite = "HV_TEAM_ShareTeamInvite",
  StartPresentation = "HV_TEAM_StartPresentation",
  StartPresentationPoll = "HV_TEAM_StartPresentationPoll",
  StartTeamLessonUserInstance = "HV_TEAM_StartTeamLessonUserInstance",
  StartTeamLessonUserInstanceOver = "HV_TEAM_StartTeamLessonUserInstanceOver",
  SubmitPollResponse = "HV_TEAM_SubmitPollResponse",
  ViewPollResults = "HV_TEAM_ViewPollResults",
  ViewPresentationIntro = "HV_TEAM_ViewPresentationIntro",
  ViewPresentationIntroNoTeam = "HV_TEAM_ViewPresentationIntroNoTeam",
  ViewCharter = "HV_TEAM_ViewCharter",
  ScheduleCharterInstance = "HV_TEAM_scheduleCharterInstance",
  InitializeScheduledCharter = "HV_TEAM_initializeScheduledCharter",
  TeamLessonFeedback = "HV_TEAM_TeamLessonFeedback",
  TeamLessonFeedbackComments = "HV_TEAM_TeamLessonFeedbackComments",
}

export enum REACH_EVENTS {
  OpenGuide = "HV_REACH_OpenGuide",
  HomePageSeeAllAssets = "HV_REACH_HomePageSeeAllAssets",
  CalendarMonthChange = "HV_REACH_MonthChange",
  AllAssetsFilter = "HV_REACH_AllAssetsFilter",
  AllProductsFilter = "HV_REACH_AllProductsFilter",
  SharedAsset = "HV_REACH_SharedAsset",
  DownloadedAsset = "HV_REACH_DownloadedAsset",
  WelcomeShown = "HV_REACH_WelcomeShown",
  CopyToClipboard = "HV_REACH_CopyToClipboard",
  ReachPromoMaterialOpened = "HV_REACH_PromoMaterialOpened",
  ReachCommSettingsCopiedInfo = "HV_REACH_CommSettings_CopiedInfo",
  ReachCommSettingsClickedConfirm = "HV_REACH_CommSettings_ClickedConfirm",
  ReachMyOrgEditedOrganization = "HV_REACH_MyOrg_EditedOrganization",
  ReachHelpResGroupSelected = "HV_REACH_HelpRes_GroupSelected",
  ReachHelpResOpenAddHelpResourceForm = "HV_REACH_HelpRes_OpenAddHelpResourceForm",
  ReachHelpResAddedHelpResource = "HV_REACH_HelpRes_AddedHelpResource",
  ReachHelpResOpenEditHelpResourceForm = "HV_REACH_HelpRes_OpenEditHelpResourceForm",
  ReachHelpResEditedHelpResource = "HV_REACH_HelpRes_EditedHelpResource",
  ReachHelpResDeletedHelpResource = "HV_REACH_HelpRes_DeletedHelpResource",
  ReachHelpResMovedUpHelpResource = "HV_REACH_HelpRes_MovedUpHelpResource",
  ReachHelpResMovedDownHelpResource = "HV_REACH_HelpRes_MovedDownHelpResource",
  ReachUsersOpenAddSingleUserForm = "HV_REACH_Users_OpenAddSingleUserForm",
  ReachUsersAddedSingleUser = "HV_REACH_Users_AddedSingleUser",
  ReachUsersOpenEditSingleUserForm = "HV_REACH_Users_OpenEditSingleUserForm",
  ReachUsersEditedSingleUser = "HV_REACH_Users_EditedSingleUser",
  ReachUsersSelfServeDeleteSingleUser = "HV_REACH_Users_DeleteSingleUser",
  ReachUsersOpenViewUsersModal = "HV_REACH_Users_OpenViewUsersModal",
  ReachUsersOpenHistoryModal = "HV_REACH_Users_OpenHistoryModal",
  ReachUsersOpenBulkUserModal = "HV_REACH_Users_OpenBulkUserModal",
  ReachUsersOpenShareInviteCodeModal = "HV_REACH_Users_OpenShareInviteCodeModal",
  ReachUsersSharedInviteCode = "HV_REACH_Users_SharedInviteCode",
  ReachUsersUploadedCSVFile = "HV_REACH_Users_UploadedCSVFile",
  ReachUsersSearchUsers = "HV_REACH_Users_SearchUsers",
  ReachUsersClearUserFilter = "HV_REACH_Users_ClearUserFilter",
  ReachInsightsNodeFilter = "HV_REACH_Insights_NodeFilter",
  ReachUsersClearCertFilters = "HV_REACH_Users_ClearCertFilters",
  ReachUsersReportDownloaded = "HV_REACH_Users_ReportDownloaded",
  ReachOrgGoalsSaved = "HV_REACH_OrgGoalsSaved",
  ReachInsightsReportDownloaded = "HV_REACH_InsightsReportDownloaded",
  ReachQuickStartGuideOpened = "HV_REACH_QuickStartGuideOpened",
  ReachScormsPackageOpened = "HV_REACH_SCORMS_PackageOpened",
  ReachScormsPackageDownloaded = "HV_REACH_SCORMS_PackageDownloaded",
}

export enum SPORT_EVENTS {
  FirstTimeInterstitialShown = "HV_SPORT_FirstTimeInterstitialShown",
  EditTeamShown = "HV_SPORT_EditTeamShown",
  EditTeamSaved = "HV_SPORT_EditTeamSaved",
  AddMemberShown = "HV_SPORT_AddMemberShown",
  MemberDetailShown = "HV_SPORT_MemberDetailShown",
  MemberResendActivationClicked = "HV_SPORT_MemberResendActivationClicked",
  MemberRemoveFromTeamClicked = "HV_SPORT_MemberRemoveFromTeamClicked",
  ScheduleCharterModalOpened = "HV_SPORT_ScheduleCharterModalOpened",
  ScheduleCharterSaved = "HV_SPORT_ScheduleCharterSaved",
  WorkshopInitialized = "HV_SPORT_WorkshopInitialized",
  PresenterWorkshopProgressed = "HV_SPORT_PresenterWorkshopProgressed",
  IndividualWorkshopProgressed = "HV_SPORT_IndividualWorkshopProgressed",
  IndividualAnswerAdded = "HV_SPORT_IndividualAnswerAdded",
  IndividualInputSubmitted = "HV_SPORT_IndividualInputSubmitted",
  PresenterGroupsChanged = "HV_SPORT_PresenterGroupsChanged",
  PresenterGroupsEdited = "HV_SPORT_PresenterGroupsEdited",
  GroupInputSubmitted = "HV_SPORT_GroupInputSubmitted",
  GroupAnswerAdded = "HV_SPORT_GroupAnswerAdded",
  CharterStatementEdited = "HV_SPORT_CharterStatementEdited",
  CharterStatementDeleted = "HV_SPORT_CharterStatementDeleted",
  GroupCharterStatementFlagged = "HV_SPORT_GroupCharterStatementFlagged",
  CharterDownloaded = "HV_SPORT_CharterDownloaded",
  CharterModalShown = "HV_SPORT_CharterModalShown",
  CharterCompleted = "HV_SPORT_CharterCompleted",
}

export enum PEOPLE_EVENTS {
  UpdateAccountPassword = "HV_People_Update_Account_Password",
  SignUp = "HV_People_Sign_Up",
  UpdateAccountError = "HV_People_UpdateAccount_Error",
  SignUpError = "HV_People_Sign_Up_Error",
  IntroSignUpStepClick = "HV_People_Intro_Sign_Up",
  IntroSignUpWithEmailStepClick = "HV_People_Intro_Sign_Up_With_Email",
  IntroHaveInviteCodeStepClick = "HV_People_Intro_Invite_Code",
  IntroNoHaveInviteCodeStepClick = "HV_People_No_Intro_Invite_Code",
  ResetPassword = "HV_People_Reset_Password",
}

export enum PEOPLE_EVENTS_PARAMS {
  InviteCode = "HV_Invite_Code",
  Error = "HV_Error",
}

// https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelset_config

export const init = () => {
  try {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as any, {
      api_host: "https://mixpanel.headversity.com",
      debug: true,
      persistence: "localStorage",
    });
  } catch {}

  /*
  // assign Company A and Company B to a user
  mixpanel.set_group('company', ['Company A', 'Company B'])

  // If user has a property named "Plan" in their profile, the old value will be overwritten with "Premium".
  mixpanel.people.set({ "Plan": "Premium" });

  // If you tell us just once that these properties are important, we will automatically include them with all events sent. Super properties are stored in a browser cookie, and will persist between visits to your site.
  mixpanel.register({
    "age": 28,
    "gender": "male",
    "source": "facebook"
  });

  // doesn't override super properties you've already saved
  mixpanel.register_once({
    "ad_campaign": "fb-01"
  });

  // other stuff to consider
  mixpanel.people.set_once - set properties if they don't exist
  mixpanel.people.append - append to a list property
  mixpanel.people.union - append to a list property, excluding duplicates
  mixpanel.people.increment - change the current value of numeric properties

  // probably not helpful for us
  mixpanel.track_links("a", "navigated", {
    "referrer": document.referrer
  });
  */
};

export const identify = (
  id: any,
  companyId: any,
  inviteCodeId: any,
  lang: any,
  role: any,
  isTest?: boolean
) => {
  const impersonatingUserId = localStorage.getItem("impersonatingUserId");

  // if the user is currently being impersonated by an admin, do not allow mixpanel events to trigger
  if (impersonatingUserId) {
    return;
  }

  console.debug("IDENTIFY", companyId, lang, isTest);

  try {
    mixpanel.identify(id);
    mixpanel.set_group("company", [companyId]);
    mixpanel.set_group("invite_code", [inviteCodeId]);
    mixpanel.set_group("role", [role]);
    mixpanel.set_group("language", [lang]);

    if (isTest !== undefined) {
      mixpanel.set_group("is_test", [isTest.toString()]);
    }
  } catch {}
};

interface TrackProperties {
  [key: string]: any;
}

export const track = (
  event: any,
  properties: TrackProperties | undefined = undefined
) => {
  const impersonatingUserId = localStorage.getItem("impersonatingUserId");

  // if the user is currently being impersonated by an admin, do not allow mixpanel events to trigger
  if (impersonatingUserId) {
    return;
  }

  if (!event) {
    console.error("MISSING EVENT", properties);
    return;
  }

  console.debug("EVENT", event, properties);
  try {
    if (sessionStorage.getItem("channel")) {
      properties = properties ?? {};
      properties.msTeams = true;
    }
    if (sessionStorage.getItem("certKey")) {
      properties = properties ?? {};
      properties.scorm = true;
    }
    mixpanel.track(event, properties);
  } catch {}
};
