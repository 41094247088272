import { AxiosResponse } from "axios";
import { QuestionAnswerDto, UserGoalDto } from "../../@headversity/contract";
import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";

export const getGoalIdForUser = (
  token: string
): Promise<AxiosResponse<UserGoalDto | null>> => {
  return AxiosClient.get("api/goal", getConfig(token));
};

export const setGoalForUser = (
  token: string,
  goalId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(`api/goal/${goalId}`, null, getConfig(token));
};

export const saveGoalQuestionAnswers = (
  token: string,
  goalId: number,
  questionAnswers: QuestionAnswerDto[]
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/goal/${goalId}/questionAnswers`,
    questionAnswers,
    getConfig(token)
  );
};
