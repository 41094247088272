import { ToolLandingCard } from "../ToolLandingCard";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import {
  STAT_CARD_SECONDARY_COLOR,
  SECONDARY_TEXT_COLOR,
  THIRD_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";

interface CalmingToolStatCardProps {
  calmingUserEvents: any[];
  setCalmingToolOpened: (calmingToolOpen: boolean) => void;
}

export const CalmingToolStatCard = (props: CalmingToolStatCardProps) => {
  const { calmingUserEvents, setCalmingToolOpened } = props;
  return (
    <ToolLandingCard
      toolImage={
        "https://cdn.headversity.com/app/resources/headerImages/tool-header-calming.jpg"
      }
      toolStatContent={
        <>
          <CalmingToolStat calmingUserEvents={calmingUserEvents} />
          <AnimatedButton
            w={330}
            text={HVLocalizeStrings.CALMING_TOOLS_PAGE_USE_TOOL}
            onClick={() => {
              setCalmingToolOpened(true);
            }}
          />
        </>
      }
    />
  );
};

interface CalmingToolStatProps {
  calmingUserEvents: any[];
  isSmall?: boolean;
}

export const CalmingToolStat = (props: CalmingToolStatProps) => {
  const { calmingUserEvents, isSmall } = props;
  return (
    <Table mt={isSmall ? 8 : 4} mb={isSmall ? "5" : "10"}>
      <Tbody>
        <Tr>
          <Td p={"0"} border={"0"}>
            <Text
              fontSize={isSmall ? "lg" : "xl"}
              color={isSmall ? STAT_CARD_SECONDARY_COLOR : SECONDARY_TEXT_COLOR}
            >
              {HVLocalizeStrings.CALMING_TOOLS_PAGE_USED}
            </Text>
          </Td>
          <Td p={"0"} border={"0"} textAlign={"right"}>
            <Text
              fontWeight={"semibold"}
              fontSize={isSmall ? "lg" : "xl"}
              color={THIRD_TEXT_COLOR}
            >
              {calmingUserEvents?.length}
            </Text>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
