import React, { useContext } from "react";
import { Box, Button, forwardRef } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import {
  BUTTON_DISABLE_BACKGROUND_COLOR,
  BUTTON_DISABLE_TEXT_COLOR,
  BUTTON_FIVE_BACKGROUND_COLOR,
  BUTTON_FIVE_TEXT_COLOR,
  BUTTON_FIVE_HOVER_COLOR,
  BUTTON_FOUR_BACKGROUND_COLOR,
  BUTTON_FOUR_HOVER_COLOR,
  BUTTON_FOUR_TEXT_COLOR,
  BUTTON_PRIMARY_BACKGROUND_COLOR,
  BUTTON_PRIMARY_HOVER_COLOR,
  BUTTON_PRIMARY_TEXT_COLOR,
  BUTTON_SECONDARY_BACKGROUND_COLOR,
  BUTTON_SECONDARY_HOVER_COLOR,
  BUTTON_SECONDARY_TEXT_COLOR,
  BUTTON_THIRD_BACKGROUND_COLOR,
  BUTTON_THIRD_HOVER_COLOR,
  BUTTON_THIRD_TEXT_COLOR,
  LENGTH_BUTTON_BORDER,
  Respect_Maroon,
  Equity_Black_85,
  Equity_Black_90,
} from "../../Styles/HeadversityStyle";
import { AppType, GlobalContext } from "../../State/GlobalContext";

interface animatedBtnProps {
  disabled?: boolean;
  opacity?: any;
  colorSet?: AnimatedButtonColorSet;
  h?: any;
  w?: any;
  fontSize?: any;
  text: any;
  onClick: any;
  selected?: boolean;
  boxShadow?: string;
  dataTestId?: string;
}

export const enum AnimatedButtonColorSet {
  Primary = 1,
  Secondary,
  Third,
  Fourth,
  Fifth,
  Cert,
}

export const AnimatedButton = forwardRef((props: animatedBtnProps, ref) => {
  const {
    disabled,
    opacity,
    colorSet,
    h,
    w,
    text,
    onClick,
    selected,
    dataTestId,
    ...rest
  } = props;

  const { currentApp } = useContext(GlobalContext);

  // default dimensions and colorset
  // TODO: px dimensions on a button is not right and not going to work w/ translations
  // TODO: "Secondary" is actually our primarily used button
  const heightToUse = h ?? 44;
  const widthToUse = w ?? 175;
  const colorSetToUse = colorSet ?? AnimatedButtonColorSet.Secondary;

  let colorScheme = "";
  let height = heightToUse + "px";
  let width = widthToUse + "px";
  let expandHeight = widthToUse / 45;

  // Remeber to add to the "Selected switch"  if adding colors sets
  const colorSetValues = [
    {
      colorSet: AnimatedButtonColorSet.Primary,
      bgColor: BUTTON_PRIMARY_BACKGROUND_COLOR,
      color: BUTTON_PRIMARY_TEXT_COLOR,
      hoverColor: BUTTON_PRIMARY_HOVER_COLOR,
    },
    {
      colorSet: AnimatedButtonColorSet.Secondary,
      bgColor:
        currentApp === AppType.SPORT
          ? Equity_Black_90
          : BUTTON_SECONDARY_BACKGROUND_COLOR,
      color:
        currentApp === AppType.SPORT ? "white" : BUTTON_SECONDARY_TEXT_COLOR,
      hoverColor:
        currentApp === AppType.SPORT
          ? Equity_Black_85
          : BUTTON_SECONDARY_HOVER_COLOR,
    },
    {
      colorSet: AnimatedButtonColorSet.Third,
      bgColor: BUTTON_THIRD_BACKGROUND_COLOR,
      color: BUTTON_THIRD_TEXT_COLOR,
      hoverColor: BUTTON_THIRD_HOVER_COLOR,
      border: LENGTH_BUTTON_BORDER,
    },
    {
      colorSet: AnimatedButtonColorSet.Fourth,
      bgColor:
        currentApp === AppType.SPORT
          ? Equity_Black_90
          : BUTTON_FOUR_BACKGROUND_COLOR,
      color: BUTTON_FOUR_TEXT_COLOR,
      hoverColor:
        currentApp === AppType.SPORT
          ? Equity_Black_85
          : BUTTON_FOUR_HOVER_COLOR,
    },
    {
      colorSet: AnimatedButtonColorSet.Fifth,
      bgColor: BUTTON_FIVE_BACKGROUND_COLOR,
      color: BUTTON_FIVE_TEXT_COLOR,
      hoverColor: BUTTON_FIVE_HOVER_COLOR,
    },
    {
      colorSet: AnimatedButtonColorSet.Cert,
      bgColor: currentApp === AppType.SPORT ? Equity_Black_90 : Respect_Maroon,
      color: "White",
      hoverColor: currentApp === AppType.SPORT ? Equity_Black_85 : "#750e3d",
    },
  ];

  let colorValues = colorSetValues.filter(
    (cs) => cs.colorSet === colorSetToUse
  )[0];
  // Selected Switch
  if (selected) {
    let selectedColorSet: AnimatedButtonColorSet | null = null;
    switch (colorSetToUse) {
      case AnimatedButtonColorSet.Primary:
        selectedColorSet = AnimatedButtonColorSet.Third;
        break;
      case AnimatedButtonColorSet.Secondary:
        selectedColorSet = AnimatedButtonColorSet.Secondary;
        break;
      case AnimatedButtonColorSet.Third:
        selectedColorSet = AnimatedButtonColorSet.Fourth;
        break;
      case AnimatedButtonColorSet.Fourth:
        selectedColorSet = AnimatedButtonColorSet.Third;
        break;
      case AnimatedButtonColorSet.Fifth:
        selectedColorSet = AnimatedButtonColorSet.Fifth;
        break;
    }
    colorValues = colorSetValues.filter(
      (cs) => cs.colorSet === selectedColorSet
    )[0];
  }

  const btnStyle: any = {
    backgroundColor: colorValues.bgColor,
    color: colorValues.color,
    opacity: opacity,
    borderRadius: "5px" /* always 5px not Border_Radius */,
    overflow: "hidden",
    "::before": {
      content: '""',
      position: "absolute",
      top: 0,
      w: "50px",
      h: "50px",
      backgroundColor: colorValues.hoverColor,
      borderRadius: "50%",
      transform: "scale(0.1)",
      opacity: 0,
      transition: "transform 0.3s, opacity 0.3s, background-color 0.3s",
    },
  };

  if (!isMobile && !disabled) {
    btnStyle[":hover::before"] = {
      opacity: 1,
      transform: "scale(" + expandHeight + ")",
    };
  }

  if (disabled) {
    btnStyle[":disabled"] = {
      backgroundColor: BUTTON_DISABLE_BACKGROUND_COLOR,
      color: BUTTON_DISABLE_TEXT_COLOR,
      opacity: 1,
      pointerEvents: "none",
    };
  }

  return (
    <>
      <Button
        disabled={disabled}
        bg={selected ? BUTTON_PRIMARY_BACKGROUND_COLOR : ""}
        colorScheme={colorScheme}
        fontWeight={"regular"}
        h={height}
        w={width}
        sx={btnStyle}
        boxShadow={"lg"}
        border={colorValues.border ?? "none"}
        onClick={onClick}
        data-testid={dataTestId}
        ref={ref}
        {...rest}
      >
        <Box zIndex={1}>{text}</Box>
      </Button>
    </>
  );
});
