import {
  Grid,
  GridItem,
  Checkbox,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import {
  LocalizationText,
  NodeDto,
  UpdateNodeDto,
} from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { HierarchyContext } from "../../../../State/Reach/ReachHierarchyContext";
import {
  DESTRUCTIVE_TEXT_COLOR,
  Equity_Black,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { HvTextInput } from "../../../Common/HvTextInput";
import { LargeModal } from "../../../Common/LargeModal";
import { LanguageTab } from "../../HelpResources/ReachHelpResourcesModal";

interface HierarchyNodeRenameModalProps {
  open: boolean;
  selectedNode?: NodeDto;
  setOpen(isOpen: boolean): void;
}

export const HierarchyNodeRenameModal = (
  props: HierarchyNodeRenameModalProps
) => {
  const [localizedNames, setLocalizedNames] = useState<LocalizationText>({});
  const [sortOrder, setSortOrder] = useState<string>();
  const [notSelectable, setNotSelectable] = useState<boolean>(false);

  const [sortOrderInvalid, setSortOrderInvalid] = useState<boolean>();

  useEffect(() => {
    if (props.selectedNode) {
      const namesLoc = props.selectedNode?.name_loc as LocalizationText;
      setLocalizedNames(namesLoc);
    } else {
      setLocalizedNames({});
    }
    setSortOrder(props.selectedNode?.details?.sortOrder?.toString());
    setNotSelectable(props.selectedNode?.details?.unselectable || false);
  }, [props.selectedNode]);

  const { onEditNode } = useContext(HierarchyContext);

  const handleSortOrderChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSortOrder(e.target.value);
    if (!e.target.value) {
      // empty string is valid
      setSortOrderInvalid(false);
    } else if (isNaN(parseInt(e.target.value))) {
      setSortOrderInvalid(true);
    } else {
      setSortOrderInvalid(false);
    }
  };

  const handleSave = () => {
    if (props.selectedNode) {
      const updates: UpdateNodeDto = {
        name: localizedNames,
        details: {
          sortOrder: sortOrder ? parseInt(sortOrder) : undefined,
          unselectable: notSelectable,
        },
      };
      onEditNode(props.selectedNode.id, updates).finally(() => {
        props.setOpen(false);
      });
    }
  };

  return (
    <LargeModal
      open={props.open}
      setOpen={props.setOpen}
      title={HVLocalizeStrings.REACH_HIERARCHY_NODE_EDIT}
      noBgSmoothing={true}
      size={"sizeOnContent"}
    >
      <Flex gap={6} alignItems={"center"}>
        <Flex flexDirection={"column"} width={"100%"}>
          <Tabs variant="line" m={2}>
            <TabList>
              <LanguageTab language={HVLocalizeStrings.LANGUAGE_ENGLISH} />
              <LanguageTab language={HVLocalizeStrings.LANGUAGE_FRENCH} />
              <LanguageTab language={HVLocalizeStrings.LANGUAGE_SPANISH} />
            </TabList>
            <TabPanels>
              <TabPanel>
                <HvTextInput
                  value={localizedNames?.en ? localizedNames?.en : ""}
                  onChange={(e) => {
                    setLocalizedNames({
                      ...localizedNames,
                      en: e.target.value,
                    });
                  }}
                  bg={"white"}
                  color={Equity_Black}
                  disabled={false}
                  boxShadow={"lg"}
                />
              </TabPanel>
              <TabPanel>
                <HvTextInput
                  value={localizedNames.fr!}
                  onChange={(e) => {
                    setLocalizedNames({
                      ...localizedNames,
                      fr: e.target.value,
                    });
                  }}
                  bg={"white"}
                  color={Equity_Black}
                  disabled={false}
                  boxShadow={"lg"}
                />
              </TabPanel>
              <TabPanel>
                <HvTextInput
                  value={localizedNames.es!}
                  onChange={(e) => {
                    setLocalizedNames({
                      ...localizedNames,
                      es: e.target.value,
                    });
                  }}
                  bg={"white"}
                  color={Equity_Black}
                  disabled={false}
                  boxShadow={"lg"}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Grid
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(10, 1fr)"
            gap={4}
          >
            <GridItem colSpan={4}>
              <Text
                fontSize="sm"
                color={MODAL_PRIMARY_TEXT_COLOR}
                width={"150px"}
              >
                {HVLocalizeStrings.REACH_HIERARCHY_NODE_SORT_ORDER}
              </Text>
            </GridItem>
            <GridItem colSpan={6}>
              <Flex flexDirection={"column"} width={"100%"}>
                <HvTextInput
                  type={"text"}
                  value={sortOrder ? sortOrder.toString() : ""}
                  onChange={handleSortOrderChanged}
                />
                {sortOrderInvalid && (
                  <Text fontSize={"xs"} color={DESTRUCTIVE_TEXT_COLOR}>
                    {HVLocalizeStrings.REACH_HIERARCHY_INVALID_SORT_ORDER}
                  </Text>
                )}
              </Flex>
            </GridItem>
            <GridItem colSpan={4}>
              <Text
                fontSize="sm"
                color={MODAL_PRIMARY_TEXT_COLOR}
                width={"150px"}
              >
                {HVLocalizeStrings.REACH_HIERARCHY_NODE_NOT_SELECTABLE}
              </Text>
            </GridItem>
            <GridItem colSpan={6}>
              <Checkbox
                size="lg"
                colorScheme="blue"
                onChange={(e) => {
                  setNotSelectable(e.target.checked);
                }}
                isChecked={notSelectable}
              ></Checkbox>
            </GridItem>
          </Grid>

          <Flex gap={2} justifyContent="flex-end">
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Third}
              w={120}
              text={HVLocalizeStrings.CANCEL}
              onClick={() => {
                props.setOpen(false);
              }}
              disabled={false}
            />
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Primary}
              w={160}
              text={HVLocalizeStrings.SAVE}
              onClick={handleSave}
              disabled={!props.selectedNode || sortOrderInvalid}
            />
          </Flex>
        </Flex>
      </Flex>
    </LargeModal>
  );
};
