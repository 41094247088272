import { ToolLandingCard } from "../ToolLandingCard";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { Flex, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import {
  STAT_CARD_SECONDARY_COLOR,
  SECONDARY_TEXT_COLOR,
  THIRD_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { ThinkingToolInfoDto } from "@headversity/contract";

interface ThinkingToolStatCardProps {
  thinkingPlanItems: ThinkingToolInfoDto[] | undefined;
  setThinkingToolOpened: (open: boolean) => void;
  viewLogClick?: () => void;
}

export const ThinkingToolStatCard = (props: ThinkingToolStatCardProps) => {
  const { thinkingPlanItems, setThinkingToolOpened, viewLogClick } = props;
  return (
    <ToolLandingCard
      toolImage={
        "https://cdn.headversity.com/app/resources/headerImages/tool-header-thinking.jpg"
      }
      toolStatContent={
        <>
          <ThinkingToolStat thinkingPlanItems={thinkingPlanItems} />
          <Flex flexDir={"column"} align={"center"} justify={"center"} gap={4}>
            <AnimatedButton
              w={330}
              text={HVLocalizeStrings.THINKING_TOOLS_PAGE_USE_TOOL}
              onClick={() => {
                setThinkingToolOpened(true);
              }}
            />
            {viewLogClick && (
              <AnimatedButton
                text={HVLocalizeStrings.TOOLS_VIEW_LOGS}
                colorSet={AnimatedButtonColorSet.Third}
                w={330}
                onClick={() => {
                  if (viewLogClick) {
                    viewLogClick();
                  }
                }}
              />
            )}
          </Flex>
        </>
      }
    />
  );
};

interface ThinkingToolStatProps {
  thinkingPlanItems: ThinkingToolInfoDto[] | undefined;
  isSmall?: boolean;
}

export const ThinkingToolStat = (props: ThinkingToolStatProps) => {
  const { thinkingPlanItems, isSmall } = props;
  return (
    <Table mt={4} mb={isSmall ? "5" : "10"}>
      <Tbody>
        <Tr>
          <Td p={"0"} border={"0"}>
            <Text
              fontSize={isSmall ? "lg" : "xl"}
              color={isSmall ? STAT_CARD_SECONDARY_COLOR : SECONDARY_TEXT_COLOR}
            >
              {HVLocalizeStrings.THINKING_TOOLS_PAGE_USED}
            </Text>
          </Td>
          <Td p={"0"} border={"0"} textAlign={"right"}>
            <Text
              fontWeight={"semibold"}
              fontSize={isSmall ? "lg" : "xl"}
              color={THIRD_TEXT_COLOR}
            >
              {thinkingPlanItems?.length}
            </Text>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
