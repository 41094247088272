import {
  Box,
  Flex,
  Icon,
  List,
  ListItem,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { QuestionUserResponseDto } from "@headversity/contract";
import { useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { PresentationContent } from "./PresentationContent";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
} from "../../../../Styles/HeadversityStyle";
import { GoDotFill } from "react-icons/go";

interface BestWorstResultProps {
  bestQuestionResults: QuestionUserResponseDto[];
  worstQuestionResults: QuestionUserResponseDto[];
  parentBestQuestionResults: QuestionUserResponseDto[];
  parentWorstQuestionResults: QuestionUserResponseDto[];
}

export const BestWorstResult = (props: BestWorstResultProps) => {
  const {
    bestQuestionResults,
    worstQuestionResults,
    parentBestQuestionResults,
    parentWorstQuestionResults,
  } = props;

  const [bestResults, setBestResults] = useState<string[]>([]);
  const [worstResults, setWorstResults] = useState<string[]>([]);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  useEffect(() => {
    if (bestQuestionResults && worstQuestionResults) {
      setBestResults(
        bestQuestionResults.map(
          (q) =>
            parentBestQuestionResults.find(
              (p) => p.id === q.questionUserResponseId
            )?.responseText as any
        )
      );
      setWorstResults(
        worstQuestionResults.map(
          (q) =>
            parentWorstQuestionResults.find(
              (p) => p.id === q.questionUserResponseId
            )?.responseText as any
        )
      );
    }
  }, [
    bestQuestionResults,
    worstQuestionResults,
    parentBestQuestionResults,
    parentWorstQuestionResults,
  ]);

  return (
    <>
      {bestResults.length > 0 && worstResults.length > 0 ? (
        <Flex
          flexDir={isMobile ? "column" : "row"}
          justifyContent="space-evenly"
          gap={4}
        >
          <ResultsContainer
            title={
              HVLocalizeStrings.CHARTER_BEST_VOTE_TITLE_THE +
              " " +
              HVLocalizeStrings.CHARTER_BEST_VOTE_TITLE_BEST
            }
            description={HVLocalizeStrings.CHARTER_BEST_VOTE_DESCRIPTION}
            results={bestResults}
          ></ResultsContainer>
          <ResultsContainer
            title={
              HVLocalizeStrings.CHARTER_BEST_VOTE_TITLE_THE +
              " " +
              HVLocalizeStrings.CHARTER_BEST_VOTE_TITLE_WORST
            }
            description={HVLocalizeStrings.CHARTER_WORST_VOTE_DESCRIPTION}
            results={worstResults}
          ></ResultsContainer>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};

interface ResultsContainerProps {
  title: string;
  description: string | JSX.Element;
  results: string[];
}

const ResultsContainer = (props: ResultsContainerProps) => {
  const { title, description, results } = props;

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  return (
    <PresentationContent
      title={title}
      description={description}
      maxW={"475px"}
      hrBorder={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
      descriptionHeight={"42px"}
    >
      <Box
        fontSize={"18px"}
        maxH={isMobile ? undefined : "263px"}
        p={2}
        overflow={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "2px",
          },
          "&::-webkit-scrollbar-track": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#0F0F1E",
            borderRadius: "24px",
          },
        }}
      >
        <List>
          {results.map((result, index) => (
            <ListItem key={`${result}-${index}`} pt={1}>
              <Flex align={"center"} gap={"2"} maxW={"100%"}>
                <Icon as={GoDotFill} color={Black} fontSize={"13px"} />
                <Text fontSize={"16px"} maxW={"95%"}>
                  {result}
                </Text>
              </Flex>
            </ListItem>
          ))}
        </List>
      </Box>
    </PresentationContent>
  );
};
