import {
  HeadZoneIcons,
  HeadZoneStateRanges,
} from "../../../../Utils/HeadzoneUtil";

export const getBrainZone = (value: number) => {
  if (value < 20) {
    return 0;
  } else if (value < 40) {
    return 1;
  } else if (value < 60) {
    return 2;
  } else if (value < 80) {
    return 3;
  } else {
    return 4;
  }
};

export const getBrainZoneInfo = (value: number) => {
  const headZoneRanges = [
    {
      stateRange: HeadZoneStateRanges.burnOut,
      brainImage: HeadZoneIcons.burnOutBrain,
      stateColor: "#e23e48",
      punctuation: ".",
    },
    {
      stateRange: HeadZoneStateRanges.stressed,
      brainImage: HeadZoneIcons.stressedBrain,
      stateColor: "#cb4e58",
      punctuation: ".",
    },
    {
      stateRange: HeadZoneStateRanges.okay,
      brainImage: HeadZoneIcons.okayBrain,
      stateColor: "#A4ACB0",
      punctuation: ".",
    },
    {
      stateRange: HeadZoneStateRanges.great,
      brainImage: HeadZoneIcons.greatBrain,
      stateColor: "#60c4f2",
      punctuation: "!",
    },
    {
      stateRange: HeadZoneStateRanges.thriving,
      brainImage: HeadZoneIcons.thrivingBrain,
      stateColor: "#26bdff",
      punctuation: "!",
    },
  ];

  return headZoneRanges[getBrainZone(value)];
};

export const getBrainImage = (value: number) => {
  return getBrainZoneInfo(value).brainImage;
};

export const getHeadZoneStateColor = (value: number): string => {
  return getBrainZoneInfo(value).stateColor;
};
