export const HVTestId = {
  App: {
    customFooter: "custom_footer_content",
  },
  ConfirmationDialog: {
    dialog: "confirmation_dialog",
    yes: "confirmation_dialog_yes",
    no: "confirmation_dialog_no",
  },
  BlockContent: {
    titleText: "block_content_title_text",
    descriptionText: "block_content_description_text",
  },
  FullScreenModalDialog: {
    content: "full_screen_modal_dialog_content",
    headingTitle: "full_screen_modal_dialog_heading_title",
    closeButton: "full_screen_modal_dialog_close_button",
    body: "full_screen_modal_dialog_body",
  },
  Header: {
    container: "header_container",
    title: "header_title",
  },
  QuestionLayout: {
    question: "question_layout_question",
  },
  IntroStep: {
    signInButton: "intro_step_sign_in_button",
    hasInviteCodeButton: "intro_step_has_invite_code_button",
    noInviteCodeButton: "intro_step_no_invite_code_button",
  },
  RegistrationStep: {
    emailInput: "registration_step_email_input",
    fullNameInput: "registration_step_full_name_input",
    passwordInput: "registration_step_password_input",
    inviteCodeInput: "registration_step_invite_code_input",
    signUpButton: "registration_step_sign_up_button",
  },
  SignInStep: {
    loginButton: "sign_in_step_login_button",
    forgotPasswordLink: "sign_in_step_forgot_password",
  },
  ForgotPasswordStep: {
    title: "forgot_password_step_title",
    sendEmailButton: "forgot_password_step_button",
  },
  ResetPassword: {
    submitButton: "reset_password_submit_button",
  },
  LandingContainer: {
    logo: "landing_container_logo",
  },
  HeadScanQuestionContent: {
    nextButton: "headscan_question_content_next_button",
  },
  Question: {
    alternative: "question_alternative",
  },
  SliderQuestion: {
    slider: "slider_question",
    sliderMark: "slider_mark_",
  },
  ScaleQuestion: {
    scaleMark: "scale_mark_",
  },
  LessonBlock: {
    container: "lesson_block_container",
    playButtonImage: "lesson_block_play_button_play_image",
  },
  FeaturedLessonsPanel: {
    container: "featured_lessons_panel_container",
  },
  CustomLessonsPanel: {
    container: "custom_lessons_panel_container",
  },
  LessonModal: {
    backButton: "lesson_modal_back_button",
  },
  LessonsScrollPanel: {
    lesson: "lesson_scroll_panel_lesson",
  },
  LessonsPage: {
    lessonContainer: "lessons_page_skill_container",
  },
  ToolLanding: {
    launchButton: "tool_landing_launch_button",
    viewHistoryButton: "tool_landing_view_history_button",
  },
  HeadZoneTool: {
    circle: "headzone_tool_circle",
    stepOneTitle: "headzone_tool_step_one_title",
    stepTwoText: "headzone_tool_step_two_text",
    nextButton: "headzone_tool_next_button",
    saveButton: "headzone_tool_save_button",
    closeButton: "headzone_tool_close_button",
  },
  HeadZoneToolRoundSlider: {
    zoneText: "headzone_tool_round_slider_zone_text",
    container: "head_zone_tool_round_slider_container",
  },
  ProgressTracker: {
    step: "progress_tracker_step",
    total: "progress_tracker_total",
  },
  HeadzoneChartPanel: {
    chartsContainer: "headzone_chart_panel_container",
  },
  HeadzoneDonutChart: {
    chart: "headzone_donut_chart",
  },
  HeadzoneLineChart: {
    chart: "headzone_line_chart",
  },
  UserProfileMenu: {
    menuButton: "user_profile_menu_button",
    menuItems: {
      getHelp: "profile_menu_get_help",
      profile: "profile_menu_profile",
      notifications: "profile_menu_notifications",
      language: "profile_menu_language",
      customFields: "profile_menu_custom_fields",
      theme: "profile_menu_theme",
      viewIntro: "profile_menu_intro",
      tour: "profile_menu_tour",
      terms: "profile_menu_terms",
      privacyPolicy: "profile_menu_privacy_policy",
      contact: "profile_menu_contact",
      logOut: "profile_menu_log_out",
      menuContainer: "profile_menu_container",
    },
  },
  GetHelpButton: {
    getHelpButton: "get_help_button",
    descriptionOneText: "get_help_decription_1_text",
    descriptionTwoText: "get_help_decription_2_text",
    getHelpText: "get_help_text",
    helpModal: "get_help_modal",
    channellingIcon: "get_help_channeling_icon",
    getHelpImageTwo: "get_help_image_2",
    getHelpImageThree: "get_help_image_3",
    callButtonOne: "get_help_call_button_one",
    callButtonTwo: "get_help_call_button_two",
    callButtonThree: "get_help_call_button_three",
    callNumberOne: "get_help_call_number_one",
    callNumberTwo: "get_help_call_number_two",
    callNumberThree: "get_help_call_number_three",
    smsOne: "get_help_sms_one",
    smsTwo: "get_help_sms_two",
    smsThree: "get_help_sms_three",
    providerImage: "get_help_provider_image",
  },
  GetHelpModal: {
    familyShare: "get_help_modal_family",
  },
  HelpResourceCard: {
    resource: "help_resource_card_",
  },
  LargeModal: {
    closeButton: "large_modal_close_button",
  },
  BlockSectionTitle: {
    title: "block_section_title_text",
  },
  WelcomeModal: {
    modalContent: "welcome_modal_container",
    button: "welcome_modal_animated_button",
    steps: {
      StepVideo: "welcome_modal_step_video",
      StepHeadzone: {
        headzoneCircle: "welcome_modal_headzone_tool_circle",
        zoneText: "welcome_modal_headzone_selected_zone",
      },
    },
  },
  Tour: {
    nextButton: "tour_next_button",
    closeButton: "tour_close_button",
    container: "tour_container",
  },
  ResilienceSkillPanel: {
    container: "resilience_skill_panel_container",
  },
  BadgeModal: {
    achievementContent: "achievement_modal_content",
    achievementModalClose: "achievement_modal_close_button",
    achievementModalBody: "achievement_modal_body",
    achievementModalHeader: "achievement_modal_header",
    achievementModalIcon: "achievement_modal_badge_icon",
  },
  ResilienceSkill20Item: {
    skillCard: "skill20_skill_card",
    title: "skill20_skill_card_title",
    score: "skill20_skill_card_score",
    lockedSkill: "skill20_skill_locked_card",
    lockedScore: "skill20_skill_card_locked_tool_tip",
  },
  ResilienceSkill20Donut: {
    score: "donut_score_text",
  },
  ResilienceSkill20Home: {
    container: "ResilienceSkill20Home_more_for_you_container",
  },
  ResilienceSkill20Panel: {
    nanoBlockContainer: "nano_block_container",
    nanoBlockTitle: "nano_block_title",
    soloBlockContainer: "solo_block_container",
    teamBlockContainer: "team_block_container",
    soloBlocktitle: "solo_block_title",
    allTrainingButton: "nano_block_see_all_skill_button",
    byTheNumbersContainer: "by_the_numbers_container",
    skillStatsContainer: "skill_page_stats_container",
    teamBlocktitle: "team_block_title",
  },
  ResilienceSkill20SmallItem: {
    lockedSkill: "locked_skill20_icon",
    unlockedSkill: "open_skill",
  },
  ResilienceFeedItemBlock20: {
    post: "res_feed_item_block",
  },
  LessonBlock20: {
    lesson: "lesson_block_20",
  },
  NanoPracticeBlock: {
    nanoPracticeBlock: "nano_practice_block",
  },
  TeamTrainingBlock: {
    teamTrainingBlock: "team_training_block",
  },
  HeadScanQuestionModal: {
    modal: "headscan_question_modal",
    nextButton: "headscan_modal_next_button",
    startButton: "headscan_modal_start_button",
  },
  ContentBlock20: {
    title: "content_block_20_title",
    skill: "content_block_20_skill",
    inProgressIndicator: "content_block_20_in_progress_indicator",
    ProgressDoneIndicator: "content_block_20_progress_done_indicator",
    favorite: "content_block_20_favorite",
  },
  LessonPreviewModal: {
    modalBody: "lesson_preview_modal_body",
    launchButton: "lesson_preview_launch_button",
    skill: "lesson_preview_modal_skill",
    lessonTitle: "lesson_preview_modal_title",
  },
  StepCircle: {
    complete: "step_complete",
    incomplete: "step_incomplete",
  },
  ResilienceFeedPostItem: {
    post: "resilience_feed_post",
  },
  ResiliencePostModal: {
    footerCloseButton: "resilience_post_modal_footer_close",
    feedbackButtonContainer: "resilience_post_modal_feedback_buttons_container",
    feedbackTextArea: "resilience_post_modal_feedback_text_area",
    feedbackSaveButton: "resilience_post_modal_feedback_save_button",
  },
  ResilienceFeedPanel: {
    liveSessionsPanel: "resilience_feed_live_sessions_panel",
    blogPanel: "resilience_feed_posts_panel",
  },
  FirstTimeInterstitialModal: {
    modal: "first_time_modal",
  },
  ErrorText: {
    text: "error_text",
  },
  EarnBadgeCard: {
    lastActivity: "last_activity",
    minutesTrained: "minutes_trained",
    practicesCompleted: "practices_completed",
    lessonsCompleted: "lessons_completed",
    postsCompleted: "posts_completed",
    currentLevelHeader: "current_skill_level_header",
    shieldName: "shield_name",
    seeMoreLink: "see_more_link",
    container: "earn_badge_info_container",
    currentLevelByPoints: "points_bar_current_level",
    nextLevelByPoints: "points_bar_next_level",
    earnToastContainer: "earn_toast_container",
  },
  // TEAM
  TeamCommandCard: {
    teamCard: "team_command_card",
    switchTeamButton: "team_command_card_switch_team_button",
  },
  TeamCommandModals: {
    selectTeamModal: "team_command_modal_select",
    teamSelector: "team_command_modal_selector",
    submitButton: "team_command_modal_submit_button",
    newTeamName: "team_command_modal_new_team_name",
    createNewTeam: "team_command_modal_create_new_team",
    joinTeamID: "team_command_modal_join_team_id",
    joinTeam: "team_command_modal_join_team",
    updatedTeamName: "team_command_modal_updated_team_name",
    updateNameButton: "team_command_modal_update_name_button",
  },
  PathAccordion: {
    pathBlock: "path_accordion_block_",
  },
  PathPresentationBlock: {
    presentationButton: "path_pres_block_button_",
    presentationName: "path_pres_block_name",
    presentationState: "path_pres_block_state",
    presentationDuration: "path_pres_block_duration",
  },
  PresentationIntroStep: {
    presTitle: "intro_step_pres_title",
    topButton: "intro_step_top_button",
    bottomButton: "intro_step_bottom_button",
    downloadPDF: "intro_step_download_pdf",
    duration: "intro_step_duration",
    training: "intro_step_training",
    interactive: "intro_step_interactive",
    completedText: "intro_step_completed_text",
    pollText: "intro_step_poll_text",
    participantsText: "intro_step_participants_text",
    viewPoll: "intro_step_view_poll_button",
  },
  PresentationLobby: {
    modal: "presentation_lobby_modal",
  },
  PresentationGoogleSlides: {
    presentation: "presentation_google_slides",
    pollButtons: "presentation_poll_buttons",
  },
  PresentationPresentStep: {
    pollContinueButton: "presentation_poll_continue_button",
    closeButton: "presentation_close_button",
    pollQuestion: "presentation_poll_question",
    rise360Presentation: "presentation_present_rise360",
    continue: "presentation_present_continue",
    cancel: "presentation_present_cancel",
    complete: "presentation_present_complete",
    preview: "presentation_present_preview_text",
  },
  PresentationPreviewModal: {
    pollBackButton: "presentation_preview_poll_back_button",
  },
  ParticipantCatchUpStep: {
    continue: "participant_catch_up_continue",
    cancel: "participant_catch_up_cancel",
    complete: "participant_catch_up_complete",
    pollContinue: "participant_catch_up_poll_continue",
    pollResultsContinue: "participant_catch_up_poll_results_continue",
  },
  TeamActivityNotification: {
    teamPresentationBanner: "team_activity_notification_banner",
    joinPresentationButton: "team_activity_notification_join_button",
  },
  ParticipantProgressStep: {
    pollQuestion: "participant_progress_poll_question",
  },
  PollQuestion: {
    freeText: "poll_question_text_input",
    alternativeButton: "poll_question_alternative_",
    submitButton: "poll_question_submit_button",
  },
  PollResult: {
    alternativeAnswer: "poll_result_alternative_answer",
    freeTextAnswer: "poll_result_text_answer",
    totalVotes: "poll_result_total_votes",
    waitingVotes: "poll_result_waiting_votes_text",
  },
  ManagementPage: {
    createTeam: "management_page_create_team",
  },
  JoinTeamButton: {
    joinTeam: "join_team_button",
  },
  ManagementAccordion: {
    teamButton: "management_accordion_team_",
    totalMembers: "management_accordion_total_members",
    pathsCompleted: "managment_accordion_paths_completed",
    presentationsCompleted: "management_accordion_presentations_completed",
    lastMetDate: "management_accordion_last_met",
    teamInfoButton: "management_accordion_team_info",
    addMemberButton: "management_accordion_add_member",
    editTeamButton: "management_accordion_edit_team",
    leaveTeamButton: "management_accordion_leave_team",
    deleteTeamButton: "management_accordion_delete_team",
  },
  TeamList: {
    memberTable: "team_list_member_table",
    name: "team_list_member_name",
    email: "team_list_member_email",
    deleteButton: "team_list_member_delete",
  },
  AddTeammateModal: {
    teamName: "add_teammate_modal_team_name",
    submit: "add_teammate_modal_submit",
  },
  JoinTEAMQRCard: {
    teamJoinLink: "join_team_qrcard_link",
    teamID: "join_team_qrcard_team_id",
  },
  NotFound: {
    bodyText: "not_found_body_text",
  },
  NanoPracticesPage: {
    featuredContainer: "nano_page_featured_container",
    allPracticesContainer: "nano_page_all_practices_container",
  },
  NanoPracticeModal: {
    modalContainer: "nano_practice_modal_container",
    practiceTitle: "nano_practice_modal_practice_title",
    skillName: "nano_practice_modal_skill_name",
    nextButton: "nano_practice_modal_next_button",
    nextPracticeButton: "nano_practice_next_practice_button",
    closeButton: "nano_practice_modal_close_button",
    barChartContainer: "nano_practice_modal_bar_chart_container",
    OutroTextHeader: "nano_practice_modal_up_next_header",
    outroNextPracticeBlock: "outro_next_practice_block",
    inputContainer: "nano_practice_modal_inputs_container",
    input: "nano_practice_input",
    videoFrame: "nano_practice_video_frame",
    typeName: "nano_practice_modal_type_name",
    categoryName: "nano_practice_modal_category_name",
    favorite: "nano_practice_favorite_",
  },
  NanoPracticeScrollPanel: {
    sayItBackText: "nano_say_it_back_card_text",
  },
  NanoPracticeToolBar: {
    typeSelect: "nano_practice_types_select",
  },
  SkillsByFamily: {
    skillsSectionContainer: "skills_section_container",
  },
  SkillContentPage: {
    toolsContainer: "skills_tool_container",
    resFeedContainer: "skills_res_feed_container",
  },
  ToolList: {
    toolCard: "tool_card",
  },
  Toast: {
    box: "toast_box",
  },
  CustomFieldModal: {
    modal: "custom_field_modal_container",
  },
  CustomFieldSelect: {
    select: "custom_field_select",
    nextButton: "custom_field_next_button",
    completeButton: "custom_field_complete_button",
  },
  ProfilePage: {
    settingsContainer: "profile_page_settings_container",
    languageLink: "profile_page_language_link",
    themeLink: "profile_page_theme_link",
    userInfoLink: "profile_page_user_info_link",
    viewIntroLink: "profile_page_view_intro_link",
    takeTourLink: "profile_page_take_tour_link",
    contactUsLink: "profile_page_contact_us_link",
    logOutLink: "profile_page_log_out_link",
    changePwdLink: "profile_page_change_password_link",
    deleteAccountLink: "profile_page_delete_account_link",
    enableEmailSubscriptionsLink:
      "profile_page_enable_email_subscriptions_link",
  },
  ToolPanel: {
    toolPanelButton: "tool_button_",
  },
  OptimismToolStatCard: {
    optiStat: "optimism_tool_stat_card_stat",
    launchButton: "optimism_tool_stat_card_launch_button",
    viewHistory: "optimism_tool_stat_card_history_button",
  },
  OptimismTool: {
    OptimismToolInput: "optimism_tool_input",
    madePossibleInput: "optimism_tool_made_possible_input",
    goodThingsNext: "optimism_tool_good_things_next_button",
    madePossibleDone: "optimism_tool_made_possible_done_button",
    optiActionButton: "optimism_tool_action_button",
    OptimismToolModal: {
      modal: "optimism_tool_modal",
    },
    steps: {
      intro: {
        plusButton: "optimism_intro_plus_button",
        header: "optimism_intro_header",
      },
      lengthSelection: {
        lengthButton: "optimism_length_button",
        nextButton: "optimism_length_next_button",
      },
      timeSelect: {
        nextButton: "optimism_time_next_button",
      },
      outro: {
        closeButton: "optimism_outro_close_button",
        header: "optimism_outro_header",
      },
      logs: {
        firstTitle: "optimism_log_first_title",
        firstContent: "optimism_log_first_content",
        secondTitle: "optimism_log_second_title",
        secondContent: "optimism_log_second_content",
        thirdTitle: "optimism_log_third_title",
        thirdContent: "optimism_log_third_content",
        selectedDayDate: "optimism_log_selected_day_date",
        logsSelect: "optimism_log_select_dropdown",
      },
    },
  },
  ActionButton: {
    menuItem: "action_button_item",
  },
  ReachGuideCard: {
    card: "guide_card",
    thumbnail: "guide_card_thumbnail",
    title: "guide_card_title",
    desc: "guide_card_desc",
  },
  ReachPromoMaterialCard: {
    card: "promo_card",
    name: "promo_name",
    thumbnail: "promo_thumbnail",
    assetType: "promo_asset_type",
  },
  ReachPromoMaterialModal: {
    modal: "promo_modal",
    assetType: "promo_modal_asset_type",
    description: "promo_modal_asset_desc",
    copyButton: "promo_modal_copy_button",
    linkedIn: "promo_modal_linked_in_button",
    yammer: "promo_modal_yammer_button",
    download: "promo_modal_download_button",
  },
  ReachUsersToolbar: {
    usersSearch: "users_search_input",
    searchButton: "users_search_button",
    clearSearch: "users_clear_search_button",
    dropdown: "users_actions_dropdown",
    upload: "actions_bulk_upload",
    addUser: "users_add_user",
  },
  UserAddModal: {
    modal: "user_add_modal",
    fullName: "user_add_full_name",
    email: "user_add_email",
    role: "user_add_role_select",
    language: "user_add_language_select",
    inviteCode: "user_add_invite_code_select",
    save: "user_add_save_button",
  },
  UserViewEditModal: {
    modal: "user_view_edit_modal",
    profileTab: "user_modal_profile_tab",
    certsTab: "user_modal_certs_tab",
  },
  ProfileTabPanel: {
    createdDate: "profile_tab_created_date",
    activationDate: "profile_tab_activation_date",
    edit: "profile_tab_edit_button",
    actionsDropdown: "profile_tab_actions_dropdown",
    actionsResendActivation: "profile_tab_resend_activation",
    actionsResetResend: "profile_tab_reset_or_resend",
    actionsDelete: "profile_tab_delete_user",
  },
  UserModalViewTabPanel: {
    fullName: "user_modal_view_full_name",
    email: "user_modal_view_email",
    role: "user_modal_view_role",
    language: "user_modal_view_language",
    emailDate: "user_modal_view_email_date",
    inviteCode: "user_modal_view_invite_code",
  },
  CertificationsTabPanel: {
    certRow: "cert_panel_row_",
    certName: "cert_name",
    certAssign: "cert_assigned_date",
    certStart: "cert_started_date",
    certComplete: "cert_completed_date",
    removeButton: "cert_remove_button",
    assignButton: "cert_panel_assign_button",
  },
  UserAssignCertModal: {
    modal: "user_assign_cert_modal",
    select: "user_assign_cert_select",
    assignButton: "cert_assign_button",
  },
  StepUploadType: {
    select: "upload_type_selector",
  },
  UserBulkModal: {
    modal: "bulk_upload_modal",
    continue: "bulk_upload_continue_button",
    skipCerts: "bulk_upload_skip_certs",
  },
  StepInfoErrors: {
    errors: "step_info_file_errors",
    stats: "step_info_file_stats",
    added: "step_info_users_added",
    removed: "step_info_users_removed",
    certs: "step_info_certs_assigned",
  },
  SelfServeUsersList: {
    row: "user_row_",
    fullName: "user_full_name_",
    email: "user_email_",
    role: "user_role_",
    inviteCode: "user_invite_code_",
  },
  SelfServeUserCerts: {
    cert: "certs_",
  },
  ReachHelpResourceItem: {
    item: "reach_help_resource_item_",
  },
  CertBlock: {
    block: "cert_block_",
  },
  CertQuestionnaireModal: {
    modal: "cert_modal",
    title: "cert_modal_title",
    desc: "cert_modal_desc",
    downloadLink: "cert_modal_download",
    shareLink: "cert_modal_share",
  },
  LikeAndDislikeButton: {
    thumbsUpFilled: "rating_button_thumbs_up_filled",
    thumbsUpEmpty: "rating_button_thumbs_up_empty",
    thumbsDownFilled: "rating_button_thumbs_down_filled",
    thumbsDownEmpty: "rating_button_thumbs_down_empty",
  },
  StepSelectGoal: {
    goalName: "step_select_goal_name",
    goalSkill: "step_select_goal_skill_",
  },
  StepStartTraining: {
    practiceCheckbox: "step_continue_practice_checkbox",
    lessonCheckbox: "step_continue_lesson_checkbox",
  },
  GoalBox: {
    goalBox: "goal_box",
  },
  LanguageSelector: {
    dropdown: "language_selector",
  },
};
