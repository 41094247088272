import { useState } from "react";
import { ToolDto } from "@headversity/contract";

export interface IToolProvider {
  tools: ToolDto[];
  setTools: (tools: ToolDto[]) => void;
}
export const useTools = (): IToolProvider => {
  const [tools, setTools] = useState<ToolDto[]>([]);

  return {
    tools,
    setTools,
  };
};
