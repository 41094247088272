import { LargeModal } from "../Common/LargeModal";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import {
  Box,
  Heading,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../Styles/HeadversityStyle";
import { BottomActionBar } from "../Common/BottomActionBar";

interface ReachWelcomeModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ReachWelcomeModal = (props: ReachWelcomeModalProps) => {
  const { open, setOpen } = props;
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const handleButtonClick = () => {
    localStorage.setItem("viewed-reach-welcome-modal", "true");
    setOpen(false);
  };

  return (
    <LargeModal open={open} setOpen={handleButtonClick as any}>
      <Box>
        <Heading
          fontSize={isDesktop ? "2xl" : "lg"}
          fontWeight={"semibold"}
          color={MODAL_PRIMARY_TEXT_COLOR}
          textAlign={"center"}
        >
          {HVLocalizeStrings.REACH_WELCOME_TO}REACH!
        </Heading>
        <VStack gap={5} mb={20} mt={5} align="left">
          <ReachModalText
            html={HVLocalizeStrings.REACH_WELCOME_MODAL_FIRST_PARAGRAPH}
          />
          <ReachModalText
            html={HVLocalizeStrings.REACH_WELCOME_MODAL_SECOND_PARAGRAPH}
          />
          <BottomActionBar
            showOptions={true}
            nextButtonText={HVLocalizeStrings.CONTINUE}
            onNextButtonClick={() => handleButtonClick()}
          />
        </VStack>
      </Box>
    </LargeModal>
  );
};
export default ReachWelcomeModal;

interface ReachModalTextProps {
  html: string;
}

const ReachModalText = (props: ReachModalTextProps) => {
  const { html } = props;
  return (
    <Text
      color={MODAL_PRIMARY_TEXT_COLOR}
      fontSize={"lg"}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
