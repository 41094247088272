import { Flex, ListItem, List } from "@chakra-ui/react";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { CharterActivity } from "../../../../@headversity/contract";

export const CharterTestPage = () => {
  return (
    <Flex
      position={"fixed"}
      zIndex={"21321939012372109378210"}
      h={"100%"}
      w={"100%"}
      left={"0px"}
      top={"0px"}
      background={"white"}
      flexDir={"column"}
      align={"center"}
      justify={"center"}
    >
      <List>
        <ListItem my={"2"}>
          <AnimatedButton
            text={"Team Values"}
            w={"250px"}
            onClick={() => {
              window.parent.postMessage(
                {
                  rise360: "activity",
                  activity: CharterActivity.TeamValues,
                },
                "*"
              );
            }}
          />
        </ListItem>
        <ListItem my={"2"}>
          <AnimatedButton
            text={"Grouping"}
            w={"250px"}
            onClick={() => {
              window.parent.postMessage(
                {
                  rise360: "activity",
                  activity: CharterActivity.Grouping,
                },
                "*"
              );
            }}
          />
        </ListItem>
        <ListItem my={"2"}>
          <AnimatedButton
            text={"Best/Worst Step One"}
            w={"250px"}
            onClick={() => {
              window.parent.postMessage(
                {
                  rise360: "activity",
                  activity: CharterActivity.BestWorstStepOne,
                },
                "*"
              );
            }}
          />
        </ListItem>
        <ListItem my={"2"}>
          <AnimatedButton
            text={"Best/Worst Step Two"}
            w={"250px"}
            onClick={() => {
              window.parent.postMessage(
                {
                  rise360: "activity",
                  activity: CharterActivity.BestWorstStepTwo,
                },
                "*"
              );
            }}
          />
        </ListItem>
        <ListItem my={"2"}>
          <AnimatedButton
            text={"WE Statement Step One"}
            w={"250px"}
            onClick={() => {
              window.parent.postMessage(
                {
                  rise360: "activity",
                  activity: CharterActivity.WeStatementStepOne,
                },
                "*"
              );
            }}
          />
        </ListItem>
        <ListItem my={"2"}>
          <AnimatedButton
            text={"WE Statement Step Two"}
            w={"250px"}
            onClick={() => {
              window.parent.postMessage(
                {
                  rise360: "activity",
                  activity: CharterActivity.WeStatementStepTwo,
                },
                "*"
              );
            }}
          />
        </ListItem>
        <ListItem my={"2"}>
          <AnimatedButton
            text={"Edit Charter"}
            w={"250px"}
            onClick={() => {
              window.parent.postMessage(
                {
                  rise360: "activity",
                  activity: CharterActivity.CharterCreation,
                },
                "*"
              );
            }}
          />
        </ListItem>
        <ListItem my={"2"}>
          <AnimatedButton
            text={"Scenario"}
            w={"250px"}
            onClick={() => {
              window.parent.postMessage(
                {
                  rise360: "activity",
                  activity: CharterActivity.Scenarios,
                },
                "*"
              );
            }}
          />
        </ListItem>
        <ListItem my={"2"}>
          <AnimatedButton
            text={"Pick Ambassador"}
            w={"250px"}
            onClick={() => {
              window.parent.postMessage(
                {
                  rise360: "activity",
                  activity: CharterActivity.SelectingCharterChampions,
                },
                "*"
              );
            }}
          />
        </ListItem>
        <ListItem my={"2"}>
          <AnimatedButton
            text={"Done for participants"}
            w={"250px"}
            onClick={() => {
              window.parent.postMessage(
                {
                  rise360: "activity",
                  activity: CharterActivity.CharterChampionsOnly,
                },
                "*"
              );
            }}
          />
        </ListItem>
        <ListItem my={"2"}>
          <AnimatedButton
            text={"Done"}
            w={"250px"}
            onClick={() => {
              window.parent.postMessage(
                {
                  rise360: "slideProgress",
                  isLessonDone: true,
                },
                "*"
              );
            }}
          />
        </ListItem>
      </List>
    </Flex>
  );
};
