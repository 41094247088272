import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Icon,
  Image as ChakraImage,
  Link,
  ListItem,
  SimpleGrid,
  SlideFade,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import Player from "@vimeo/player";
import { useContext, useEffect, useRef, useState } from "react";
import {
  HVLocalizeStrings,
  HVLocalizeStringsObj,
} from "../../../Localization/HVLocalizeStrings";
import {
  Border_Radius,
  IMAGE_FILTER,
  MODAL_PRIMARY_TEXT_COLOR,
  MODAL_SECONDARY_TEXT_COLOR,
  MODAL_THIRD_TEXT_COLOR,
  Respect_Raspberry,
  SlideFade_Offset,
} from "../../../Styles/HeadversityStyle";
import { AnimatedButton } from "../../Common/AnimatedButton";
import { LargeModal } from "../../Common/LargeModal";
import { StepPanel } from "../../Common/StepPanel";
import { StepHeadzone } from "../../Shared/Welcome/StepHeadzone";
import HeadZoneLineChart from "../Tools/HeadZone/headzoneCharts/HeadZoneLineChart";
import HeadzoneBarChart from "../Tools/HeadZone/headzoneCharts/HeadzoneBarChart";
import { EVENTS, track } from "../../../Utils/Analytics";
import { HvTextInput } from "../../Common/HvTextInput";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
  TrainingType,
} from "../../../State/GlobalContext";
import {
  getPostActivityStep,
  getTotalPoints,
  PostActivityStep,
} from "../../../Utils/SkillsUtil";
import { ShellContext } from "../../../State/ShellContext";
import { isAndroid, isIOS, isWebPlatform } from "../../../Utils/mobileUtils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NanoPracticeHistory } from "./NanoPracticeHistory";
import {
  AiRole,
  Dictionary,
  HeadzoneStepSetting,
  Input,
  InputStepSetting,
  LocalizationText,
  MessageDto,
  MicroLessonDto,
  NanoPracticeCategory,
  NanoPracticeDto,
  NanoPracticeSteps,
  NanoPracticeStepType,
  NanoPracticeType,
  QuestionAnswerDto,
  QuestionType,
  SkillDto,
  VideoStepSetting,
} from "@headversity/contract";
import {
  getHeadZoneNanoPracticeQuestionName,
  isFave,
  isLocalizationTextArray,
  isNanoPracticeDoneTodayOrByStartDateByList,
  NanoPracticeStep,
} from "./NanoPracticeUtils";
import { HVTestId } from "../../../Testing/dataTestIds";
import { HvSelect } from "../../Common/HvSelect";
import { getDaysSinceOrDate } from "../../../Utils/TimeUtils";
import { HvSpinner } from "../../Common/HvSpinner";
import {
  getFeedbackQuestionAnswers,
  getKey,
  sortWitLocaleCompare,
} from "../../../Utils/Helpers";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import ResourceType from "../../../Utils/ResourceType";
import { PracticeReminder } from "../../Shared/PracticeReminder/PracticeReminder";
import AIFollowUpPanel from "./AIFollowUpPanel";
import { generateAiResponse } from "../../../Api/Solo/NanoPracticeApi";
import { NanoPracticeResponseList } from "./NanoPracticeResponseList";
import { FeedbackPanel } from "../../Common/FeedbackPanel";
import IconWithToolTip from "../../Common/IconWithToolTip";
import { FiInfo } from "react-icons/fi";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import { isSafari } from "react-device-detect";
import { PointsButton } from "../Shared/PointsButton";
import { StepStartTraining } from "../../Shared/Welcome/StepStartTraining";
import { PointsMap } from "../Shared/PointsMap";
import { HvSlideFade } from "../../Common/HvSlideFade";
import { StreakInfo } from "../Shared/StreakInfo";
import { WelcomeModalHeader } from "../../Shared/Welcome/WelcomeModalHeader";

interface NanoPracticeModalProps {
  practice: NanoPracticeDto;
  open: boolean;
}

export const NanoPracticeModal = ({
  practice,
  open,
}: NanoPracticeModalProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isLg = useBreakpointValue({ base: false, lg: true });
  const isDesktop = useBreakpointValue({ base: false, sm: true });

  const {
    skills,
    favorites,
    setNanoPracticeModalOpen,
    savePracticeInstanceToServer,
    nanoPracticeInstances,
    userSkillStats,
    currentApp,
    selectedUserLanguage,
    selectedGoal,
    openNextActivity,
    openedActivityFromStartTraining,
    schedulerReminders,
    selectedGoalBgImage,
    activityCompletionDates,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { safeAreaInsets, showToast } = useContext(ShellContext);

  const [step, setStep] = useState(0);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  const [headZoneValue, setHeadZoneValue] = useState(0);

  const [actionButtonFadeIn, setActionButtonFadeIn] = useState(true);

  const [localBackgroundImage, setLocalBackgroundImage] = useState<string>();

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const [showHistory, setShowHistory] = useState<boolean>(false);

  const [hideBackButton, setHideBackButton] = useState<boolean>(false);

  const [instanceId, setInstanceId] = useState<number>();

  const [inputQuestionAnswers, setInputQuestionAnswers] = useState<
    QuestionAnswerDto[]
  >([]);

  const [aiResponse, setAiResponse] = useState<string>("");
  const [ignoreAIResponse, setIgnoreAIResponse] = useState(false);

  const [questionContext, setQuestionContext] = useState<number | undefined>();

  const [showInfinitePrompt, setShowInfinitePrompt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [inputs, setInputs] = useState<Input[]>([]);
  const [generatedInputs, setGeneratedInputs] = useState<
    Record<number, Input[]>
  >({});

  const [initialPoints, setInitialPoints] = useState<number>(0);
  const [currentPoints, setCurrentPoints] = useState<number>(0);

  const [selectedTrainingType, setSelectedTrainingType] =
    useState<TrainingType>(TrainingType.Practice);
  const [selectedPractice, setSelectedPractice] = useState<NanoPracticeDto>();
  const [selectedLesson, setSelectedLesson] = useState<MicroLessonDto>();

  const [showOutro, setShowOutro] = useState(false);

  const [postActivityStep, setPostActivityStep] = useState<PostActivityStep>(
    PostActivityStep.None
  );

  useEffect(() => {
    setInitialPoints(getTotalPoints(userSkillStats));
  }, [practice]);

  useEffect(() => {
    setCurrentPoints(getTotalPoints(userSkillStats));
  }, [userSkillStats]);

  useEffect(() => {
    if (actionButtonFadeIn || postActivityStep === PostActivityStep.Reminder)
      return;

    setTimeout(
      () => {
        setActionButtonFadeIn(true);
      },
      isOnLast ? 5000 : 2000
    );
  }, [actionButtonFadeIn, postActivityStep]);

  useEffect(() => {
    // fade in the next button on the tell-it-back and last step
    if (
      (step === practice.steps.length &&
        practice.nanoPracticeType === NanoPracticeType.ShowAndTell) ||
      step === practice.steps.length + 1
    ) {
      setActionButtonFadeIn(false);
    }

    // set progress
    if (practice.steps.length > 1) {
      setProgressBarValue((step / practice.steps.length) * 100);
    } else {
      setProgressBarValue(0);
    }

    // handle input step prompts
    setShowInfinitePrompt(false);

    const curStep = practice.steps[step - 1] as NanoPracticeStep;

    if (curStep?.type === NanoPracticeStepType.Input) {
      // if we're at the first step and infinite prompts is enabled, show the button
      if (step === 1 && (curStep as InputStepSetting)?.aiPrompt) {
        setShowInfinitePrompt(true);
      }

      // if we already have something generated for this step (back button), use it
      const existingInputs = generatedInputs[step];
      if (existingInputs) {
        setInputs(existingInputs);
      }
      // if we're working with an AI-generated context, generate for this step; otherwise, use defaults
      else if (questionContext) {
        generateAiQuestionsAndResponses(questionContext, true);
      }
      // otherwise, use what's in the step
      else {
        setInputs((curStep as InputStepSetting).input);
      }
    } else if (curStep?.type === NanoPracticeStepType.HeadzoneReflection) {
      setInputs((curStep as InputStepSetting).input);
    }
  }, [step]);

  type StepArrays = Exclude<NanoPracticeSteps, string>;

  useEffect(() => {
    if (open) {
      track(EVENTS.NanoPracticeModalOpened, {
        HV_PracticeId: practice?.id,
        HV_PracticeTitle: practice?.name_loc?.en,
        HV_PracticeType: practice?.nanoPracticeType,
      });
    }
  }, [open]);

  useEffect(() => {
    // clear any previous responses
    const currentSteps = practice.steps as StepArrays;
    currentSteps.forEach((step) => {
      if (
        step.type === NanoPracticeStepType.Input ||
        step.type === NanoPracticeStepType.HeadzoneReflection
      ) {
        const currentStep = step as InputStepSetting;
        currentStep.input.forEach((detail) => {
          detail.value = undefined;
          detail.isOther = false;
        });
      }
    });

    setInputQuestionAnswers([]);
    setInstanceId(undefined);
    setQuestionContext(undefined);
    setAiResponse("");
    setInputs([]);
    setGeneratedInputs({});
    setIgnoreAIResponse(false);
    setShowOutro(false);
    setPostActivityStep(PostActivityStep.None);
    setStep(0);
    setShowHistory(false);
  }, [practice, open]);

  useEffect(() => {
    const isMobile = window.screen.width < 568;
    const outputImage = document.createElement("canvas");
    const baseImage = new Image();

    baseImage.onload = () => {
      outputImage.width = !isMobile ? 600 : 335;
      outputImage.height = !isMobile ? 400 : 600;

      const ctx = outputImage.getContext("2d");
      if (!ctx) return;

      ctx.globalAlpha = !isOnFirstOrLast ? 0.1 : 0.2;
      ctx.filter = "blur(2px)";
      ctx.drawImage(baseImage, 0, 0);

      // slight delay on mobile to allow modal to fly in
      if (!initialLoadComplete && isMobile && isOnFirst) {
        setInitialLoadComplete(true);
        setTimeout(() => {
          setLocalBackgroundImage(outputImage.toDataURL());
        }, 400);
      } else {
        setLocalBackgroundImage(outputImage.toDataURL());
      }
    };

    baseImage.crossOrigin = "anonymous";

    const url = !isMobile
      ? practice.imageUrl
      : practice.imageUrl
          .toString()
          .replace("nano-practices", "nano-practices-portrait");

    baseImage.src = url.toString();
  }, [practice, step]);

  // use effect to handle saving and hiding back arrow for the headzone practice.
  // Kind of hacky to do that, but cleaner than to keep doing weird stuff to the
  // stepHeadzone component. Since we have all the state here....should add to
  // the list of the refactor story for the whole NanoPractice modal.
  useEffect(() => {
    setHideBackButton(false);
    if (
      practice.nanoPracticeType === NanoPracticeType.Headzone ||
      practice.nanoPracticeType === NanoPracticeType.ShowAndTell
    ) {
      const realStepIndex = step - 1;
      if (realStepIndex >= practice.steps.length || realStepIndex < 0) {
        return;
      }

      if (
        (practice.steps[realStepIndex] as any).type ===
          NanoPracticeStepType.HeadzoneFollowup ||
        (practice.steps[realStepIndex] as any).type ===
          NanoPracticeStepType.TellMeBack
      ) {
        setHideBackButton(true);
        if (
          !(practice.steps[realStepIndex] as any).aiPrompt ||
          aiResponse ||
          ignoreAIResponse
        ) {
          doSave(
            (practice.steps[realStepIndex] as any).type ===
              NanoPracticeStepType.HeadzoneFollowup
          );
        }
      }
    }
  }, [practice, step, aiResponse, ignoreAIResponse]);

  useEffect(() => {
    if (open && !searchParams.get("practice")) {
      navigate(window.location.pathname + `?practice=${practice?.id}`, {
        replace: true,
      });
    }

    if (!open) {
      navigate(window.location.pathname, { replace: true });
    }
  }, [practice, open]);

  const onViewHistoryClicked = () => {
    track(EVENTS.NanoPracticeViewHistory, {
      HV_PracticeId: practice.id,
      HV_PracticeTitle: practice.name_loc?.en,
    });

    setShowHistory(true);
    setStep(1);
  };

  const saveInstance = async (questionAnswers: QuestionAnswerDto[]) => {
    const instanceId = await savePracticeInstanceToServer(
      {
        nanoPracticeId: practice.id,
        aiResponse: aiResponse,
      },
      questionAnswers
    );

    setInstanceId(instanceId);
  };

  const gotoNextStep = (fromFeedback?: boolean) => {
    /*
    * SIMULATE
    *
    setStep(practice.steps.length + 1);
    setPostActivityStep(PostActivityStep.Reminder);
    return;
    */

    if (!fromFeedback && isNextButtonDisabled) return;

    // clicked on last step?
    if (step === practice.steps.length) {
      if (practice.nanoPracticeType === NanoPracticeType.Video) {
        // FUTURE: this disabling the button implementation doesn't really work, since we're blocking in the save
        setIsNextButtonDisabled(true);
        doSave();
      }

      setIsNextButtonDisabled(false);
      setActionButtonFadeIn(false);
      setInputQuestionAnswers([]);
      setStep(step + 1);

      setShowOutro(true);

      track(EVENTS.NanoPracticeEndReached, {
        HV_PracticeId: practice.id,
        HV_PracticeTitle: practice.name_loc?.en,
        HV_PracticeType: practice?.nanoPracticeType,
      });

      // on closing?
    } else if (step === practice.steps.length + 1) {
      setShowOutro(false);

      if (
        !selectedGoal ||
        currentApp === AppType.CERT ||
        currentApp === AppType.SPORT
      ) {
        setNanoPracticeModalOpen(false);
        return;
      }

      const curStep = getPostActivityStep(
        postActivityStep,
        initialPoints,
        currentPoints,
        activityCompletionDates,
        schedulerReminders
      );

      setPostActivityStep(curStep);

      if (
        curStep === PostActivityStep.ChooseTraining &&
        !openedActivityFromStartTraining
      ) {
        setNanoPracticeModalOpen(false);
        return;
      }

      if (curStep === PostActivityStep.LaunchTraining) {
        openNextActivity(
          selectedTrainingType,
          selectedPractice,
          selectedLesson
        );
        return;
      }

      navigate(window.location.pathname, { replace: true });

      setActionButtonFadeIn(false);
    }

    // clicked on next mid-practice?
    else {
      track(EVENTS.NanoPracticeNextStep, {
        HV_PracticeId: practice.id,
        HV_PracticeTitle: practice.name_loc?.en,
        HV_PracticeType: practice?.nanoPracticeType,
      });

      setStep(step + 1);
    }
  };

  const doSave = (trackHeadZoneValue: boolean = false) => {
    if (trackHeadZoneValue) {
      track(EVENTS.ToolHeadzoneSelected, {
        HV_HeadzoneValue: headZoneValue,
      });
    }

    track(EVENTS.NanoPracticeSummaryReached);

    saveInstance(inputQuestionAnswers);
  };

  const handleHeadzoneValueChange = (value: number) => {
    const headzoneQuestion = getHeadZoneNanoPracticeQuestionName(practice.id);
    if (headzoneQuestion) {
      const existingHeadzone = inputQuestionAnswers.find(
        (input) => input.question["en"] === headzoneQuestion["en"]
      );
      if (existingHeadzone) {
        // update existing response
        setInputQuestionAnswers(
          inputQuestionAnswers.map((input) =>
            input.question["en"] === headzoneQuestion["en"]
              ? {
                  ...input,
                  responseText: value.toString(),
                }
              : input
          )
        );
      } else {
        // add new response
        setInputQuestionAnswers([
          ...inputQuestionAnswers,
          {
            question: headzoneQuestion,
            responseText: value.toString(),
            questionType: QuestionType.Headzone,
          } as QuestionAnswerDto,
        ]);
      }
    }
    setHeadZoneValue(value);
  };

  const doQuestionContentMarkup = (inputs: Input[]) => {
    let title = "";
    const questions = inputs
      .map((input) => {
        if (input?.headline) {
          title = `<title>${input?.headline}</title>`;
        }
        let responseText = "";
        if (input?.options && input?.options.length > 0) {
          responseText = input?.options
            ?.map((option) => {
              return `<responseText>${option}</responseText>`;
            })
            .join("\n");
        } else if (input?.placeholder) {
          responseText = `<responseText>${input?.placeholder}</responseText>`;
        }
        return `
  <question>
    <questionText>${input?.prompt}</questionText>
    ${responseText}
  </question>`;
      })
      .join("\n");

    return `
  <questionForm>
    ${title}
    <questions>
        ${questions}
    </questions>
  </questionForm>`;
  };

  const generateAiQuestionsAndResponses = (
    context: number,
    resetOnFail?: boolean,
    shouldClear?: boolean
  ) => {
    setIsNextButtonDisabled(true);
    setIsLoading(true);
    const curStep = practice.steps[step - 1] as InputStepSetting;
    if (!curStep) return;

    const messages: MessageDto[] = [];
    messages.push({
      role: AiRole.System,
      content: curStep.aiPrompt,
    });
    const inputsToSubmit: Input[] = [];
    curStep.input.forEach((input) => {
      inputsToSubmit.push(input);
    });
    messages.push({
      role: "user",
      content: doQuestionContentMarkup(inputsToSubmit),
    });

    const practiceDetails = ((practice.name_loc?.en as string) +
      ": " +
      practice.description_loc?.en) as string;

    generateAiResponse(
      getKey(),
      {
        lang: selectedUserLanguage,
        messages: messages,
        contextIndex: context,
        practiceTheme: practiceDetails,
      },
      25000
    )
      .then((result) => {
        const aiMessagesFromServer: MessageDto[] = result.data;
        if (aiMessagesFromServer?.length > 0) {
          const div = document.createElement("div");
          div.innerHTML =
            aiMessagesFromServer[aiMessagesFromServer.length - 1].content;
          const questions = div.getElementsByTagName("question");
          const title = div.getElementsByTagName("title");
          const titleText = title.length > 0 ? title[0].textContent : "";
          if (questions.length > 0) {
            const questionAndAnswerInputs: Input[] = [];
            const aiGeneratedInputsToSet: Dictionary<Input> = {};
            for (
              let i = 0;
              i < questions.length && i < curStep.input.length;
              i++
            ) {
              let responseOptions = null;
              responseOptions =
                questions[i].getElementsByTagName("responseText");

              const questionText =
                questions[i].getElementsByTagName("questionText")[0];

              const questionAnswerInput: Input = {
                guid: curStep.input[i].guid,
                prompt: questionText.textContent as any,
                prompt_loc: {
                  [selectedUserLanguage]: questionText.textContent,
                },
                options: (responseOptions?.length > 1
                  ? Array.from(responseOptions)
                      .map((ro) => ro.textContent)
                      .sort()
                  : null) as any,
                isOther: false,
                placeholder:
                  responseOptions?.length === 1
                    ? (responseOptions[0].textContent as string)
                    : HVLocalizeStrings.ENTER_YOUR_RESPONSE,
                headline:
                  i === 0 && titleText ? titleText : curStep.input[i].headline,
                questionContext: context,
              };
              questionAndAnswerInputs.push(questionAnswerInput);
              aiGeneratedInputsToSet[curStep.input[i].guid] =
                questionAnswerInput;
            }

            // replace the inputs with the AI generated version
            setInputs(questionAndAnswerInputs);

            // store in generatedInputs for back button
            const newGeneratedInputs = shouldClear
              ? {}
              : { ...generatedInputs };
            newGeneratedInputs[step] = questionAndAnswerInputs;
            setGeneratedInputs(newGeneratedInputs);

            track(EVENTS.NanoPracticeRegenerateQuestionsComplete, {
              HV_PracticeId: practice.id,
              HV_PracticeTitle: practice.name_loc?.en,
              HV_PracticeInputs: questionAndAnswerInputs,
            });
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        showToast(false, "error", HVLocalizeStrings.SOLO_AI_ERROR);
        setIsLoading(false);

        if (resetOnFail) {
          setInputs((curStep as InputStepSetting).input);
        }
      });
  };

  const isUserFavorite = isFave(favorites, practice);

  const isOnFirst = step === 0;
  const isOnLast = step === practice?.steps?.length + 1;
  const isOnFirstOrLast = isOnFirst || isOnLast;

  const actionDivRef = useRef<any>();

  const showGoalBgImage = isOnLast && !showOutro && selectedGoal;

  return (
    <LargeModal
      open={open}
      setOpen={setNanoPracticeModalOpen}
      bgImage={
        currentApp === AppType.SPORT
          ? "https://cdn.headversity.com/app/sport/green-main-bg.jpg"
          : // show goal bg when level'ing up or on the last step
          showGoalBgImage
          ? selectedGoalBgImage
          : localBackgroundImage
      }
      forceDarkMode={!!showGoalBgImage}
      noFallback={true}
      dataTestId={HVTestId.NanoPracticeModal.modalContainer}
    >
      {/* first time (points <= 3), we'll wait to show the points button until we get to level up screen */}
      {isOnLast &&
        ((showOutro && getTotalPoints(userSkillStats) > 3) ||
          postActivityStep === PostActivityStep.LevelUp) &&
        selectedGoal && (
          <PointsButton
            points={initialPoints}
            currentPoints={currentPoints}
            top={isWebPlatform() ? "23px" : `${3 + safeAreaInsets.top}px`}
            pos="absolute"
            isInModal={true}
          />
        )}
      <StepPanel
        step={step}
        totalStep={practice?.steps?.length}
        value={progressBarValue}
        hideDivider={true}
        showProgressBar={
          !isOnFirstOrLast &&
          !showHistory &&
          postActivityStep === PostActivityStep.None
        }
        showBackArrow={
          (isOnFirst && openedActivityFromStartTraining) ||
          (!isOnFirstOrLast &&
            !hideBackButton &&
            postActivityStep === PostActivityStep.None)
        }
        onBackClick={() => {
          track(EVENTS.NanoPracticeBackStep, {
            HV_PracticeId: practice.id,
            HV_PracticeTitle: practice.name_loc?.en,
            HV_FromStep: step,
            HV_ToStep: step - 1,
          });

          if (step === 0) {
            navigate(window.location.pathname, { replace: true });
            setPostActivityStep(PostActivityStep.ChooseTraining);
            setStep(practice?.steps?.length + 1);
          } else {
            setStep(step - 1);
            setShowHistory(false);
          }
        }}
        noMarginTop={isOnFirstOrLast}
      >
        <Box
          h={
            !isLg
              ? undefined
              : isOnFirst
              ? "545px"
              : isOnLast
              ? "529px"
              : "515px"
          }
          pb={isDesktop ? 6 : "50px"}
          overflow={showHistory && isDesktop ? "clip" : "auto"}
          color={MODAL_SECONDARY_TEXT_COLOR}
          ref={actionDivRef}
        >
          {isLoading && (
            <Center>
              <HvSpinner withOverlay={true} pos="absolute" />
            </Center>
          )}

          <SlideFade in={isOnFirst} offsetY={SlideFade_Offset}>
            {isOnFirst && (
              <Box
                m={isDesktop ? "40px" : "10px"}
                mt={isWebPlatform() ? "0px" : "40px"}
              >
                <IntroPanel
                  skill={
                    currentApp !== AppType.CERT && currentApp !== AppType.SPORT
                      ? skills.find(
                          (x) => x.id === practice.skillCompetencies[0]?.skillId
                        )
                      : undefined
                  }
                  isFavorite={isUserFavorite}
                  practice={practice}
                  isDesktop={isDesktop}
                  setIsNextButtonDisabled={setIsNextButtonDisabled}
                />
              </Box>
            )}
          </SlideFade>

          {showOutro ? (
            <HvSlideFade fadeIn={true}>
              <Box
                m={isDesktop ? "40px" : "10px"}
                mt={isWebPlatform() ? 4 : "40px"}
              >
                <OutroPanel
                  practice={practice}
                  isUserFavorite={isUserFavorite}
                  instanceId={instanceId!}
                  onComplete={() => {
                    gotoNextStep();
                  }}
                />
              </Box>
            </HvSlideFade>
          ) : postActivityStep === PostActivityStep.LevelUp ? (
            <SlideFade in={true} offsetY={SlideFade_Offset}>
              <Box mt={isWebPlatform() ? 4 : "0px"}>
                <PointsMap fromNewLevel={true} />
              </Box>
            </SlideFade>
          ) : postActivityStep === PostActivityStep.Reminder ? (
            <HvSlideFade fadeIn={true}>
              <PracticeReminder
                isHidingGetRemindersSwitch={true}
                marginAdjust={isDesktop ? -24 : 0}
                onClose={() => {
                  gotoNextStep();
                }}
              />
            </HvSlideFade>
          ) : postActivityStep === PostActivityStep.Streak ? (
            <HvSlideFade fadeIn={true}>
              <StreakInfo />
            </HvSlideFade>
          ) : postActivityStep === PostActivityStep.ChooseTraining ? (
            <HvSlideFade fadeIn={true}>
              <StepStartTraining
                isPostWelcome={true}
                selectedTrainingType={selectedTrainingType}
                setSelectedTrainingType={setSelectedTrainingType}
                selectedPractice={selectedPractice}
                setSelectedPractice={setSelectedPractice}
                selectedLesson={selectedLesson}
                setSelectedLesson={setSelectedLesson}
              />
            </HvSlideFade>
          ) : showHistory ? (
            <SlideFade in={true} offsetY={SlideFade_Offset}>
              <NanoPracticeHistory
                nanoPracticeUserInstances={nanoPracticeInstances[practice.id]}
              />
            </SlideFade>
          ) : (
            <ActionPanel
              practice={practice}
              step={practice.steps[step - 1] as NanoPracticeStep}
              setProgressBarValue={setProgressBarValue}
              setIsNextButtonDisabled={setIsNextButtonDisabled}
              headZoneValue={headZoneValue}
              onHeadzoneValueChange={handleHeadzoneValueChange}
              onActionComplete={() => {
                gotoNextStep();
              }}
              isDesktop={isDesktop}
              showHistory={showHistory}
              setInputQuestionAnswers={setInputQuestionAnswers}
              inputQuestionAnswers={inputQuestionAnswers}
              setInstanceId={setInstanceId}
              setAiResponse={setAiResponse}
              aiResponse={aiResponse}
              setIgnoreAIResponse={setIgnoreAIResponse}
              ignoreAIResponse={ignoreAIResponse}
              inputs={inputs}
              setInputs={setInputs}
            />
          )}
        </Box>
      </StepPanel>
      {!showHistory && (
        <Box
          pos={isDesktop ? "absolute" : "fixed"}
          width={"100%"}
          bottom={0}
          left={0}
          bgColor={`#ffffff${isDesktop ? "aa" : "dd"}`}
          p={"10px"}
          pb={`${10 + safeAreaInsets.bottom}px`}
          display={!actionButtonFadeIn ? "none" : "block"}
          borderBottomRadius={isDesktop ? Border_Radius : undefined}
          zIndex={1}
          className="bottom-action-bar"
        >
          <SlideFade in={actionButtonFadeIn}>
            <Center filter={"drop-shadow(0 0 3px rgb(255, 255, 255));"} gap={4}>
              <AnimatedButton
                disabled={isNextButtonDisabled}
                text={
                  !openedActivityFromStartTraining && isOnLast
                    ? HVLocalizeStrings.CLOSE
                    : HVLocalizeStrings.NEXT
                }
                onClick={() => {
                  gotoNextStep();
                }}
                dataTestId={HVTestId.NanoPracticeModal.nextButton}
              />
            </Center>
            {isOnFirst &&
              practice.nanoPracticeType === NanoPracticeType.ShowAndTell &&
              nanoPracticeInstances &&
              nanoPracticeInstances[practice.id]?.length && (
                <Center mt={3} mb={1}>
                  <Link
                    textDecoration={"underline"}
                    fontSize="14px"
                    onClick={() => {
                      onViewHistoryClicked();
                    }}
                  >
                    {HVLocalizeStrings.VIEW_HISTORY}
                  </Link>
                </Center>
              )}
            {showInfinitePrompt && (
              <Center mt={3} mb={1}>
                <Link
                  textDecoration={"underline"}
                  fontSize="14px"
                  onClick={() => {
                    if (isLoading) return;

                    track(EVENTS.NanoPracticeRegenerateQuestions, {
                      HV_PracticeId: practice.id,
                      HV_PracticeTitle: practice.name_loc?.en,
                    });

                    const context = Math.floor(Math.random() * 1000) % 50;
                    setQuestionContext(context);

                    generateAiQuestionsAndResponses(context, false, true);
                  }}
                >
                  <Flex alignItems={"center"}>
                    <ChakraImage
                      h={"16px"}
                      src={
                        "https://cdn.headversity.com/app/resources/other/sparkle.png"
                      }
                    />

                    {HVLocalizeStrings.NANO_PRACTICE_TRY_DIFFERENT_PROMPTS}
                  </Flex>
                </Link>
              </Center>
            )}
          </SlideFade>
        </Box>
      )}
    </LargeModal>
  );
};

interface IntroPanelProps {
  skill?: SkillDto;
  practice: NanoPracticeDto;
  isDesktop?: boolean;
  isFavorite?: boolean;
  setIsNextButtonDisabled: (disabled: boolean) => void;
}

const IntroPanel = ({
  skill,
  practice,
  isDesktop,
  isFavorite,
  setIsNextButtonDisabled,
}: IntroPanelProps) => {
  const imageFilter = useToken("colors", [IMAGE_FILTER]);

  const {
    currentApp,
    nanoPracticeInstances,
    saveFavoriteToServer,
    favorites,
    removeFavoriteFromServer,
    selectedUserLanguage,
  } = useContext(GlobalContext);

  const { showToast, confirmationDialogRef } = useContext(ShellContext);
  const [showCategory, setShowCategory] = useState(window.innerWidth > 375);

  let type =
    practice.nanoPracticeType === NanoPracticeType.Headzone
      ? HVLocalizeStrings.TRACKING
      : practice.nanoPracticeType === NanoPracticeType.Video
      ? HVLocalizeStrings.VIDEO
      : HVLocalizeStrings.REFLECTION;

  const aiEnabled = (practice.steps[practice.steps.length - 1] as any)
    ?.aiPrompt;

  const type_icon =
    practice.nanoPracticeType === NanoPracticeType.Headzone
      ? HVLocalizeStringsObj.en.TRACKING
      : practice.nanoPracticeType === NanoPracticeType.Video
      ? HVLocalizeStringsObj.en.VIDEO
      : HVLocalizeStringsObj.en.REFLECTION;

  const category =
    practice.nanoPracticeCategory === NanoPracticeCategory.FlexibleThinking
      ? HVLocalizeStrings.NANO_PRACTICE_CATEGORY_FLEXIBLE_THINKING
      : practice.nanoPracticeCategory === NanoPracticeCategory.QuickRelief
      ? HVLocalizeStrings.NANO_PRACTICE_CATEGORY_QUICK_RELIEF
      : practice.nanoPracticeCategory === NanoPracticeCategory.TakingAction
      ? HVLocalizeStrings.NANO_PRACTICE_CATEGORY_TAKING_ACTION
      : HVLocalizeStrings.NANO_PRACTICE_CATEGORY_TAMING_EMOTIONS;

  const category_icon =
    practice.nanoPracticeCategory === NanoPracticeCategory.FlexibleThinking
      ? "flexible-thinking"
      : practice.nanoPracticeCategory === NanoPracticeCategory.QuickRelief
      ? "quick-relief"
      : practice.nanoPracticeCategory === NanoPracticeCategory.TakingAction
      ? "taking-actions"
      : "taming-emotions";

  useEffect(() => {
    setIsNextButtonDisabled(false);
  }, []);

  useEffect(() => {
    // The category text and icon should only display if there's enough room, and this varies by language
    let minWidth = 375;
    if (selectedUserLanguage !== "en") {
      minWidth = 431;
    }
    setShowCategory(window.innerWidth > minWidth);
  }, [window.innerWidth, selectedUserLanguage]);

  const savePracticeAsFavorite = async () => {
    // TODO: disable the button while save is in progress

    const fav = {
      resourceType: ResourceType.nanoPractice,
      resourceId: practice.id,
    };

    await saveFavoriteToServer(fav);
    track(EVENTS.PracticeFavorited, {
      HV_PracticeId: practice.id,
      HV_PracticeTitle: practice.name_loc?.en,
      HV_PracticeType: practice?.nanoPracticeType,
    });

    showToast(
      true,
      "practice-added",
      HVLocalizeStrings.PRACTICE_ADDED_TO_FAVORITES,
      "bottom"
    );
  };

  const removePracticeAsFavorite = async () => {
    (confirmationDialogRef.current as any).confirmation(
      HVLocalizeStrings.PRACTICE_REMOVE_FAVORITE_TITLE,
      HVLocalizeStrings.PRACTICE_REMOVE_FAVORITE,
      ConfirmationButtons.YesNo,
      async () => {
        // find favorite
        const favToRemove = favorites.find(
          (favorite) =>
            favorite.resourceId === practice.id &&
            favorite.resourceType === ResourceType.nanoPractice
        );

        if (favToRemove?.id) {
          await removeFavoriteFromServer(favToRemove.id);
          track(EVENTS.PracticeFavoriteRemoved, {
            HV_PracticeId: practice.id,
            HV_PracticeTitle: practice.name_loc?.en,
            HV_PracticeType: practice?.nanoPracticeType,
          });
        }
      }
    );
  };

  return (
    <>
      <Box mt="30px">
        <Flex alignItems="center" mt={1}>
          <Box w="100%">
            <Text
              fontSize={
                isDesktop
                  ? practice.name.toString().length < 25
                    ? "42px"
                    : "36px"
                  : "3xl"
              }
              fontWeight="600"
              color={MODAL_PRIMARY_TEXT_COLOR}
              lineHeight={isDesktop ? "2.5rem" : "2rem"}
              wordBreak="keep-all"
              data-testid={HVTestId.NanoPracticeModal.practiceTitle}
              mr="50px"
            >
              {practice.name.toString()}
            </Text>
          </Box>

          {currentApp !== AppType.CERT && currentApp !== AppType.SPORT && (
            <HeartButton
              onClick={() => {
                isFavorite
                  ? removePracticeAsFavorite()
                  : savePracticeAsFavorite();
              }}
              isFavorite={isFavorite}
              isDesktop={isDesktop}
            />
          )}
        </Flex>
        <Text
          fontSize={isDesktop ? "20px" : "18px"}
          lineHeight={"1.4rem"}
          mt={2}
        >
          {practice.tagline.toString()}
        </Text>
        {skill && (
          <HStack gap="10px" mt={3}>
            <HStack gap="5px">
              <ChakraImage
                h={"12px"}
                src={skill.imageUrl as string}
                filter={
                  imageFilter[0] === "none" ? "grayscale(1.0);" : imageFilter
                }
              />
              <Text
                fontSize={"13px"}
                ml="0px !important"
                data-testid={HVTestId.NanoPracticeModal.skillName}
              >
                {skill.name as string}
              </Text>
            </HStack>
            {showCategory && (
              <HStack gap="5px">
                <ChakraImage
                  h={"12px"}
                  src={`https://cdn.headversity.com/app/resources/other/${category_icon.toLowerCase()}.svg`}
                  filter={
                    imageFilter[0] === "none" ? "grayscale(1.0);" : imageFilter
                  }
                />
                <Text
                  fontSize={"13px"}
                  ml="0px !important"
                  data-testid={HVTestId.NanoPracticeModal.categoryName}
                >
                  {category}
                </Text>
              </HStack>
            )}
            <HStack gap="3px">
              {aiEnabled ? (
                <ChakraImage
                  h={"13px"}
                  src={
                    "https://cdn.headversity.com/app/resources/other/sparkle.png"
                  }
                  filter={imageFilter}
                  mr="-1px"
                />
              ) : (
                <ChakraImage
                  h={"12px"}
                  src={`https://cdn.headversity.com/app/resources/other/${type_icon.toLowerCase()}-icon.svg`}
                  filter={imageFilter}
                  mr="2px"
                />
              )}
              <Text
                fontSize={"13px"}
                ml="0px !important"
                data-testid={HVTestId.NanoPracticeModal.typeName}
              >
                {type}
              </Text>
              {aiEnabled && (
                <IconWithToolTip
                  size="smallest"
                  text={HVLocalizeStrings.NANO_PRACTICE_AI_NOTICE}
                  icon={FiInfo}
                />
              )}
            </HStack>
          </HStack>
        )}
      </Box>
      <Divider my={5} />
      <Box>
        <Text mt={2} fontSize="15px" lineHeight="1.3">
          {practice.description.toString()}
        </Text>
      </Box>

      {practice.tips && (
        <Box pb={3} mt={5}>
          <Text fontSize={"xl"} mb={1}>
            <b>{HVLocalizeStrings.NANO_PRACTICES_MODAL_TIPS_TITLE}</b>
          </Text>
          <UnorderedList lineHeight={1.3} fontSize="15px">
            {isLocalizationTextArray(practice.tips) &&
              practice.tips?.map((tip, index) => (
                <ListItem mb="8px" key={index}>
                  {tip.toString()}
                </ListItem>
              ))}
          </UnorderedList>
        </Box>
      )}

      {nanoPracticeInstances && nanoPracticeInstances[practice.id]?.length && (
        <>
          <Box lineHeight={"19px"} mt={6}>
            <Text
              as="span"
              mt={
                practice.nanoPracticeType === NanoPracticeType.Headzone ? 4 : 8
              }
              fontSize="15px"
              dangerouslySetInnerHTML={{
                __html: `${HVLocalizeStrings.YOUVE_COMPLETED} <b>${
                  nanoPracticeInstances[practice.id].length
                }</b> ${
                  nanoPracticeInstances[practice.id].length === 1
                    ? HVLocalizeStrings.TOOLS_PAGE_USED_TIME.toLowerCase()
                    : HVLocalizeStrings.TOOLS_PAGE_USED_TIMES.toLowerCase()
                }, ${HVLocalizeStrings.MOST_RECENTLY} <b>${getDaysSinceOrDate(
                  dayjs
                    .utc(
                      nanoPracticeInstances[practice.id][0].createdAt.toString()
                    )
                    .local(),
                  true
                )}</b>.`,
              }}
            />
          </Box>

          {practice.nanoPracticeType === NanoPracticeType.Headzone && (
            <SimpleGrid
              mt={isDesktop ? 6 : 8}
              columns={isDesktop ? 2 : 1}
              spacing={8}
            >
              {isDesktop && (
                <Box data-testid={HVTestId.NanoPracticeModal.barChartContainer}>
                  <HeadzoneBarChart
                    height={175}
                    type={practice.id.toString()}
                  />
                </Box>
              )}
              <Box>
                <HeadZoneLineChart
                  height={175}
                  width={isDesktop ? 400 : window.innerWidth - 80}
                  type={practice.id.toString()}
                />
              </Box>
            </SimpleGrid>
          )}
        </>
      )}
    </>
  );
};

interface HearButtonProps {
  onClick?: () => void;
  isFavorite: boolean | undefined;
  isDesktop: any;
}
const HeartButton = ({ onClick, isFavorite, isDesktop }: HearButtonProps) => {
  const [faveColor] = useToken("colors", ["MODAL_PRIMARY_TEXT_COLOR"]);

  const getMinWidth = () => {
    if (isFavorite) {
      return "25px !important";
    }
    if (!isDesktop) {
      if (isSafari || isIOS()) {
        return "29px !important";
      }
      return "28px !important";
    }

    return undefined;
  };

  return (
    <Button
      className={`button-link ${onClick ? "hover" : ""}`}
      borderRadius="50%"
      onClick={onClick}
      title={
        isFavorite
          ? HVLocalizeStrings.PRACTICE_REMINDER_REMOVE_FAV
          : HVLocalizeStrings.PRACTICE_REMINDER_ADD_FAV
      }
      h={isFavorite ? "25px" : !isDesktop ? "29px" : undefined}
      w={
        isFavorite
          ? "25px !important"
          : !isDesktop
          ? "29px !important"
          : undefined
      }
      minW={getMinWidth()}
      justifySelf={"flex-end"}
      _hover={onClick ? { filter: "brightness(150%)" } : undefined}
      cursor={!onClick ? "default" : undefined}
      data-testid={`${HVTestId.NanoPracticeModal.favorite}${isFavorite}`}
    >
      <HStack
        border={isFavorite ? undefined : `solid 2px ${faveColor}99`}
        borderRadius={"50%"}
        h={"100%"}
      >
        <Icon
          as={isFavorite ? AiFillHeart : AiOutlineHeart}
          w={isDesktop ? "25px" : "17px"}
          h={isDesktop ? "25px" : "17px"}
          ml={!isFavorite ? (isDesktop ? "5.5px" : "3.5px") : undefined}
          color={isFavorite ? Respect_Raspberry : MODAL_PRIMARY_TEXT_COLOR}
        />
      </HStack>
    </Button>
  );
};

interface OutroPanelProps {
  practice: NanoPracticeDto;
  isUserFavorite: boolean;
  instanceId: number;
  onComplete: () => void;
}

const OutroPanel = ({
  practice,
  isUserFavorite,
  instanceId,
  onComplete,
}: OutroPanelProps) => {
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowFeedback(true);
    }, 2000);
  }, []);

  return (
    <>
      <Text
        fontSize={"3xl"}
        lineHeight={"2.1rem"}
        color={MODAL_PRIMARY_TEXT_COLOR}
        data-testid={HVTestId.NanoPracticeModal.OutroTextHeader}
      >
        <Text>
          <b>{`${HVLocalizeStrings.NICE_WORK}. `}</b>
          {`${practice.outroHeadline}`}
        </Text>
      </Text>

      <Text mt={4} lineHeight="1.4rem">
        {practice.outroText.toString()}
      </Text>

      <HvSlideFade fadeIn={showFeedback}>
        <FeedbackContainer
          isFavorite={isUserFavorite}
          practice={practice}
          instanceId={instanceId}
          onComplete={onComplete}
        />
      </HvSlideFade>
    </>
  );
};

interface ActionPanelProps {
  practice: NanoPracticeDto;
  step: NanoPracticeStep;
  setProgressBarValue: (progressBarValue: number) => void;
  setIsNextButtonDisabled: (disabled: boolean) => void;
  headZoneValue: number;
  onHeadzoneValueChange: (headZoneValue: number) => void;
  onActionComplete: () => void;
  isDesktop: any;
  showHistory: boolean;
  setInputQuestionAnswers: (nanoPracticeAnswers: QuestionAnswerDto[]) => void;
  inputQuestionAnswers: QuestionAnswerDto[];
  setInstanceId: (instanceId: number) => void;
  setAiResponse: (aiResponse: string) => void;
  aiResponse: string;
  ignoreAIResponse: boolean;
  setIgnoreAIResponse: (ignore: boolean) => void;
  inputs: Input[];
  setInputs: (inputs: Input[]) => void;
}

const ActionPanel = ({
  practice,
  step,
  setProgressBarValue,
  setIsNextButtonDisabled,
  headZoneValue,
  onHeadzoneValueChange,
  onActionComplete,
  isDesktop,
  setInputQuestionAnswers,
  inputQuestionAnswers,
  setAiResponse,
  aiResponse,
  ignoreAIResponse,
  setIgnoreAIResponse,
  inputs,
  setInputs,
}: ActionPanelProps) => {
  const [headZoneColorValue, setHeadZoneColorValue] = useState<string>("");

  const displayStep = (stepType: NanoPracticeStepType) => {
    switch (stepType) {
      case NanoPracticeStepType.Video:
        return (
          <StepVideo
            videoUrl={(step as VideoStepSetting).video.url.toString()}
            videoUrlPortrait={(
              step as VideoStepSetting
            ).video.portraitUrl.toString()}
            setProgressBarValue={setProgressBarValue}
            setIsNextButtonDisabled={setIsNextButtonDisabled}
            onVideoComplete={onActionComplete}
            isDesktop={isDesktop}
          />
        );
      case NanoPracticeStepType.Input:
        return (
          <StepInput
            step={step as InputStepSetting}
            setIsNextButtonDisabled={setIsNextButtonDisabled}
            onEnterKeyDown={onActionComplete}
            inputs={inputs}
            setInputs={setInputs}
            setInputQuestionAnswers={setInputQuestionAnswers}
            inputQuestionAnswers={inputQuestionAnswers}
          />
        );
      case NanoPracticeStepType.TellMeBack:
        return (
          <StepShowItBack
            inputQuestionAnswers={inputQuestionAnswers}
            setIsNextButtonDisabled={setIsNextButtonDisabled}
            practice={practice}
            aiPrompt={step.aiPrompt}
            setAiResponse={setAiResponse}
            aiResponse={aiResponse}
            ignoreAIResponse={ignoreAIResponse}
            setIgnoreAIResponse={setIgnoreAIResponse}
          />
        );
      case NanoPracticeStepType.Headzone:
      case NanoPracticeStepType.HeadzoneFollowup:
      case NanoPracticeStepType.HeadzoneReflection:
        return (
          <StepHeadzone
            isHeadzoneDirty={
              step.type === NanoPracticeStepType.HeadzoneFollowup ||
              step.type === NanoPracticeStepType.HeadzoneReflection
            }
            setIsNextButtonDisabled={setIsNextButtonDisabled}
            headZoneValue={headZoneValue}
            onHeadzoneValueChange={(val: any) => {
              onHeadzoneValueChange(val);
              setIsNextButtonDisabled(false);
            }}
            headZoneColorValue={headZoneColorValue}
            setHeadZoneColorValue={setHeadZoneColorValue}
            headzoneStepDetails={
              (practice.steps[0] as HeadzoneStepSetting).headzone
            }
            useTheme={true}
            aiPrompt={step.aiPrompt}
            setAiResponse={setAiResponse}
            inputQuestionAnswers={inputQuestionAnswers}
            aiResponse={aiResponse}
            practice={practice}
            ignoreAIResponse={ignoreAIResponse}
            setIgnoreAIResponse={setIgnoreAIResponse}
            stepInputs={
              step.type === NanoPracticeStepType.HeadzoneReflection
                ? {
                    step: step as InputStepSetting,
                    setIsNextButtonDisabled: setIsNextButtonDisabled,
                    onEnterKeyDown: onActionComplete,
                    setInputQuestionAnswers: setInputQuestionAnswers,
                    inputQuestionAnswers: inputQuestionAnswers,
                    inputs: inputs,
                    setInputs: setInputs,
                  }
                : undefined
            }
          />
        );
      default:
        return <></>;
    }
  };

  return displayStep(step?.type);
};

interface StepVideoProps {
  videoUrl?: string;
  videoUrlPortrait?: string;
  setProgressBarValue: (progressBarValue: number) => void;
  setIsNextButtonDisabled: (disabled: boolean) => void;
  onVideoComplete: () => void;
  isDesktop: any;
}

const StepVideo = ({
  videoUrl,
  videoUrlPortrait,
  setProgressBarValue,
  setIsNextButtonDisabled,
  onVideoComplete,
  isDesktop,
}: StepVideoProps) => {
  const [videoSrc, setVideoSrc] = useState("");

  useEffect(() => {
    setIsNextButtonDisabled(true);
  }, []);

  const getVideoSource = () => {
    const src = isDesktop ? videoUrl : videoUrlPortrait;

    return src || "";
  };

  const playVideo = () => {
    const vimeoIframe = document.querySelector(
      ".embed--iframe iframe:not(.examined)"
    );

    // doesn't appear to be possible, but just in case we'll try again
    if (!vimeoIframe) {
      setTimeout(() => playVideo(), 250);
      return;
    }

    // when used with an iframe, Vimeo player doesn't like the iframe video source changing
    // when used with a div, we can better control video source, but Vimeo player doesn't let you control fullscreen :(
    const player = new Player(vimeoIframe as any);

    // keep source from initial orientation
    setVideoSrc(getVideoSource());

    player.on("play", () => {
      console.debug("PLAYBACK STARTED");
    });

    setTimeout(() => {
      player.play();
    }, 1000);

    player.on("timeupdate", (data: any) => {
      const progress = Math.floor((data.seconds / data.duration) * 100);
      console.debug("PLAYBACK PROGRESS", progress);

      if (setProgressBarValue) {
        setProgressBarValue(progress);
      }

      // enable continue button when we get through the video
      if (progress >= 99) {
        setIsNextButtonDisabled(false);
      }

      // TODO: decide on this
      if (progress >= 99 && onVideoComplete) {
        //onVideoComplete();
      }
    });
  };

  useEffect(() => {
    playVideo();
  }, []);

  return (
    <>
      <Center
        filter="drop-shadow(0 0.2rem 0.25rem rgba(128, 128, 128, 0.2));"
        className="embed--iframe"
        mt={!isDesktop ? "-20px" : undefined}
      >
        <iframe
          src={videoSrc ? videoSrc : getVideoSource()}
          width={isDesktop ? "700px" : `${window.innerWidth - 50}px`}
          height={isDesktop ? "450px" : `${window.innerHeight - 200}px`}
          title="video"
          allowFullScreen={
            // fullscreen only on iOS, unless it's iOS Chrome
            // (Android blocks the jog shuttle, and desktop / iOS Chrome doesn't work well)
            (navigator.userAgent.indexOf("iPhone") >= 0 ||
              navigator.userAgent.indexOf("iPad") >= 0) &&
            navigator.userAgent.indexOf("CriOS") === -1
              ? true
              : undefined
          }
          data-testid={HVTestId.NanoPracticeModal.videoFrame}
        ></iframe>
      </Center>
    </>
  );
};

export interface StepInputProps {
  step: InputStepSetting;
  setIsNextButtonDisabled: (disabled: boolean) => void;
  onEnterKeyDown: () => void;
  setInputQuestionAnswers: (inputQuestionAnswers: QuestionAnswerDto[]) => void;
  inputQuestionAnswers: QuestionAnswerDto[];
  inputs: Input[];
  setInputs: (inputs: Input[]) => void;
}

export const StepInput = ({
  step,
  setIsNextButtonDisabled,
  onEnterKeyDown,
  inputs,
  setInputs,
  inputQuestionAnswers,
  setInputQuestionAnswers,
}: StepInputProps) => {
  useEffect(() => {
    setIsNextButtonDisabled(true);
  }, [step]);

  useEffect(() => {
    const updatedQuestionAnswers = inputQuestionAnswers.map((qa) => {
      const input = inputs.find((input) => {
        return qa.guid === input.guid;
      });
      if (input) {
        // Update existing question answer
        return {
          ...qa,
          question: input.prompt_loc as LocalizationText,
          responseText: input.value,
          hint: input.guid,
          context: input.questionContext,
          tellItBack: input.tellItBack_loc as LocalizationText,
        };
      }
      // Keep existing question answer unchanged
      return qa;
    });

    // Append new question answers
    inputs.forEach((input) => {
      const existingQuestionAnswer = updatedQuestionAnswers.find(
        (qa) => qa.guid === input.guid
      );

      if (!existingQuestionAnswer) {
        updatedQuestionAnswers.push({
          question: input.prompt_loc as LocalizationText,
          questionType: QuestionType.FreeText,
          responseText: input.value,
          guid: input.guid,
          hint: input.guid,
          context: input.questionContext,
          tellItBack: input.tellItBack_loc as LocalizationText,
        } as QuestionAnswerDto);
      }
    });

    setInputQuestionAnswers(updatedQuestionAnswers);

    const currentDetails = inputs?.map((a) => a);
    setIsNextButtonDisabled(
      currentDetails?.find((x) => x.placeholder && !x.value) !== undefined
    );
  }, [inputs, step]);

  const handleFormChange = (detail: any, event: any, isOther?: boolean) => {
    // make a copy of current state
    const currentDetails = inputs?.map((a) => a);

    const currentDetail = currentDetails?.find((a) => a.guid === detail.guid);

    if (currentDetail) {
      if (isOther !== undefined) {
        currentDetail.isOther = isOther;
      }

      if (isOther) {
        currentDetail.value = "";
      } else {
        currentDetail.value = event.target.value;
      }
    }

    setInputs(currentDetails);
    setIsNextButtonDisabled(
      currentDetails?.find((x) => x.placeholder && !x.value) !== undefined
    );
  };

  return (
    <Box
      pb={
        isAndroid()
          ? `${window.screen.height / 2}px`
          : step?.aiPrompt
          ? "50px"
          : "20px"
      }
      data-testid={HVTestId.NanoPracticeModal.inputContainer}
    >
      {inputs?.map((detail, idx) => (
        <Box key={idx} mt={1} mb={6}>
          {detail.headline && (
            <Text
              mb={idx === 0 ? 4 : 2}
              fontSize={"2xl"}
              fontWeight="400"
              lineHeight="1.8rem"
            >
              {detail.headline.toString()}
            </Text>
          )}
          {detail.prompt && <Text mb={2}>{detail.prompt.toString()}</Text>}
          {detail.options && (
            <HvSelect
              mb={4}
              value={
                detail.isOther ? HVLocalizeStrings.OTHER : detail.value || ""
              }
              placeholder={HVLocalizeStrings.DROPDOWN_PLACEHOLDER}
              onChange={(event) => {
                handleFormChange(
                  detail,
                  event,
                  event.target.value === HVLocalizeStrings.OTHER
                );
              }}
            >
              {detail.options?.sort(sortWitLocaleCompare).map((item, idx) => (
                <option key={idx}>{item.toString()}</option>
              ))}
              <option>{HVLocalizeStrings.OTHER}</option>
            </HvSelect>
          )}
          {detail.placeholder && (!detail.options || detail.isOther) && (
            <HvTextInput
              placeholder={detail.placeholder.toString()}
              onChange={(event) => handleFormChange(detail, event)}
              onEnterKeyDown={onEnterKeyDown}
              value={detail.value || ""}
              dataTestId={`${HVTestId.NanoPracticeModal.input}_${idx}`}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

interface StepShowItBackProps {
  inputQuestionAnswers?: QuestionAnswerDto[];
  setIsNextButtonDisabled: (disabled: boolean) => void;
  practice: NanoPracticeDto;
  aiPrompt?: string;
  setAiResponse: (aiResponse: string) => void;
  aiResponse?: string;
  ignoreAIResponse: boolean;
  setIgnoreAIResponse: (ignore: boolean) => void;
}

export interface InputQuestionAnswer {
  title: LocalizationText;
  value: string;
  isAiResponse?: boolean;
}

const StepShowItBack = ({
  inputQuestionAnswers,
  setIsNextButtonDisabled,
  aiPrompt,
  setAiResponse,
  aiResponse,
  practice,
  ignoreAIResponse,
  setIgnoreAIResponse,
}: StepShowItBackProps) => {
  const { selectedUserLanguage } = useContext(GlobalContext);
  const [currentInputs, setCurrentInputs] = useState<InputQuestionAnswer[]>([]);

  useEffect(() => {
    const currInputs = inputQuestionAnswers?.map((input) => {
      let questionText: any = "";
      let title = null;
      if (input.tellItBack && !aiPrompt) {
        title = (
          typeof input.tellItBack === "string"
            ? input.tellItBack
            : input.tellItBack[selectedUserLanguage as keyof LocalizationText]
        ) as string;
      }
      if (typeof input.question === "string") {
        questionText = input.question;
      } else {
        questionText = input.question[
          selectedUserLanguage as keyof LocalizationText
        ] as any;
      }

      return {
        title: title ?? questionText,
        value: input.responseText,
      };
    }) as InputQuestionAnswer[];

    setCurrentInputs(currInputs);
    if (!aiPrompt) {
      setIsNextButtonDisabled(currInputs.length > 1);
    } else {
      setIgnoreAIResponse(false);
    }
  }, [inputQuestionAnswers, selectedUserLanguage]);

  useEffect(() => {
    setIsNextButtonDisabled(false);
    setIgnoreAIResponse(false);
  }, []);

  return aiPrompt && !ignoreAIResponse ? (
    <Tabs variant="line">
      <Box mx={2}>
        <TabList w={"100%"}>
          <Tab
            fontSize={"smaller"}
            _selected={{
              color: MODAL_THIRD_TEXT_COLOR,
              borderBottom: "3px solid",
            }}
            px={4}
          >
            {HVLocalizeStrings.SUMMARY}
          </Tab>
          <Tab
            fontSize={"smaller"}
            _selected={{
              color: MODAL_THIRD_TEXT_COLOR,
              borderBottom: "3px solid",
            }}
            px={4}
          >
            {HVLocalizeStrings.RESPONSES}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0} pt={3}>
            <Box h={"100%"} pr="20px" overflow={"auto"}>
              <AIFollowUpPanel
                setAiResponse={setAiResponse}
                setIgnoreAIResponse={setIgnoreAIResponse}
                aiResponse={aiResponse as string}
                inputQuestionAnswers={
                  inputQuestionAnswers as QuestionAnswerDto[]
                }
                aiPrompt={aiPrompt}
                setIsNextButtonDisabled={setIsNextButtonDisabled}
                practice={practice}
              />
            </Box>
          </TabPanel>
          <TabPanel p={0} pt={3}>
            <Box h={"100%"} pr="20px" overflow={"auto"}>
              <NanoPracticeResponseList currentInputs={currentInputs} />
            </Box>
          </TabPanel>
        </TabPanels>
      </Box>
    </Tabs>
  ) : (
    <>
      <WelcomeModalHeader useTheme={true}>
        {HVLocalizeStrings.TAKE_A_MINUTE}
      </WelcomeModalHeader>
      <Box mt="15px">
        <NanoPracticeResponseList currentInputs={currentInputs} />
      </Box>
    </>
  );
};

interface FeedbackContainerProps {
  practice: NanoPracticeDto;
  isFavorite: boolean;
  instanceId: number;
  onComplete: () => void;
}

const FeedbackContainer = ({
  practice,
  instanceId,
  isFavorite,
  onComplete,
}: FeedbackContainerProps) => {
  const [showFavoriteContainer, setShowFavoriteContainer] = useState(false);

  const {
    currentApp,
    saveNanoPracticeQuestionAnswersToServer,
    saveFavoriteToServer,
    nanoPracticeInstances,
  } = useContext(GlobalContext);

  const isDesktop = useBreakpointValue({ base: false, sm: true });

  const items = [
    HVLocalizeStrings.NANO_PRACTICE_FEEDBACK_1,
    HVLocalizeStrings.NANO_PRACTICE_FEEDBACK_2,
  ];

  const items_eng = [
    HVLocalizeStringsObj.en.NANO_PRACTICE_FEEDBACK_1,
    HVLocalizeStringsObj.en.NANO_PRACTICE_FEEDBACK_2,
  ];

  const savePracticeAsFavorite = async () => {
    if (!isFavorite) {
      const fav = {
        resourceType: ResourceType.nanoPractice,
        resourceId: practice.id,
      };

      isFavorite = true;

      await saveFavoriteToServer(fav);

      track(EVENTS.FavoritedSuggestionPracticeFavorited, {
        HV_PracticeId: practice.id,
        HV_PracticeTitle: practice.name_loc?.en,
        HV_PracticeType: practice?.nanoPracticeType,
      });

      setTimeout(async () => {
        onComplete();
      }, 1500);
    }
  };

  // filter out the practice we just did
  const instances = nanoPracticeInstances[practice.id]?.filter(
    (y) => y.id !== instanceId
  );

  const wasCompletedToday =
    isNanoPracticeDoneTodayOrByStartDateByList(instances);

  return (
    <Box>
      <Divider my="30px" />
      {showFavoriteContainer ? (
        <Box textAlign={"center"}>
          <Center mt={8}>
            <Box>
              <Text>
                {isFavorite
                  ? HVLocalizeStrings.PRACTICE_ADDED_TO_FAVORITES
                  : HVLocalizeStrings.PRACTICE_REMINDER_ADD_FAV_HEADLINE}
              </Text>

              <Center mt="5px">
                <HeartButton
                  onClick={
                    isFavorite
                      ? undefined
                      : () => {
                          savePracticeAsFavorite();
                        }
                  }
                  isFavorite={isFavorite}
                  isDesktop={isDesktop}
                />
              </Center>
            </Box>
          </Center>
        </Box>
      ) : !instanceId ? (
        <Center>
          <HvSpinner />
        </Center>
      ) : (
        <FeedbackPanel
          items={items}
          items_eng={items_eng}
          onResponse={(englishText: string, result: string) => {
            // track
            track(EVENTS.NanoPracticeFeedback, {
              HV_Question: englishText,
              HV_PracticeId: practice.id,
              HV_PracticeTitle: practice.name_loc?.en,
              HV_Response: result === "like" ? "up" : "down",
            });

            // save
            saveNanoPracticeQuestionAnswersToServer(
              instanceId,
              getFeedbackQuestionAnswers(englishText, result)
            );
          }}
          onFeedback={(text: string) => {
            // track
            track(EVENTS.NanoPracticeFeedbackComments, {
              HV_PracticeId: practice.id,
              HV_PracticeTitle: practice.name_loc?.en,
            });

            // save
            saveNanoPracticeQuestionAnswersToServer(
              instanceId,
              getFeedbackQuestionAnswers("FeedbackComments", text)
            );
          }}
          onComplete={(wasLiked: boolean) => {
            if (
              wasLiked &&
              !isFavorite &&
              !wasCompletedToday &&
              currentApp !== AppType.CERT &&
              currentApp !== AppType.SPORT
            ) {
              track(EVENTS.FavoriteSuggestionShown);
              setShowFavoriteContainer(true);
            } else {
              setTimeout(async () => {
                onComplete();
              }, 500);
            }
          }}
        />
      )}
    </Box>
  );
};
