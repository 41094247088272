import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Center,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Image,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  AiFillPauseCircle,
  AiFillPlayCircle,
  AiFillSound,
  AiFillCloseCircle,
} from "react-icons/ai";
import { IoPlayBackCircle, IoPlayForwardCircle } from "react-icons/io5";
import dayjs from "dayjs";
import {
  IMindfulnessToolProvider,
  MindfulnessToolContext,
} from "../../../../State/Solo/MindfulnessToolContext";
import {
  Border_Radius,
  CARD_BACKGROUND_COLOR,
  MEDIA_PLAYER_BACKGROUND_COLOR,
  PRIMARY_TEXT_COLOR,
  SKILL_BORDER_GRADIENT,
} from "../../../../Styles/HeadversityStyle";
import { IShellProvider, ShellContext } from "../../../../State/ShellContext";
import { isWebPlatform } from "../../../../Utils/mobileUtils";
import { BOTTOM_MENU_HEIGHT } from "../../../../App";
import { GlobalContext } from "../../../../State/GlobalContext";

interface MindfulnessMediaPlayerProps {
  id: number;
  url: string;
  name: string;
  image: string;
  maxTime: string;
  title: string;
}

export const MindfulnessMediaPlayer = (props: MindfulnessMediaPlayerProps) => {
  const { url, name, image, maxTime, id, title } = props;
  const { saveMindfulnessAudioUsageToServer } =
    useContext<IMindfulnessToolProvider>(MindfulnessToolContext);
  const playerRef = useRef(null);
  const [currentTrackTime, setCurrentTrackTime] = useState<number>(0);
  const [time, setTime] = useState<number>(0);

  const [volume, setVolume] = useState<number>(0.6);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [duration, setDuration] = useState<number>(0);
  const [userFirstClickedOnTrack, setUserFirstClickedOnTrack] =
    useState<boolean>(true);

  const { setAudio, safeAreaInsets } = useContext<IShellProvider>(ShellContext);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const [isMindfulnessListOpen, setIsMindfulnessListOpen] = useState(false);

  useEffect(() => {
    if (playerRef.current) {
      (playerRef?.current as any).volume = volume;
      setIsPlaying(true);
      (playerRef?.current as any)?.play();
    }
  }, [playerRef?.current, url]);

  useEffect(() => {
    setCurrentTrackTime(0);
    setTime(dayjs().unix());
    getDuration(url);
  }, [url]);

  useEffect(() => {
    if (currentTrackTime === duration) setIsPlaying(false);
  }, [currentTrackTime, duration]);

  const saveTimeToServer = useCallback(
    (durationSinceLastSaved: number, id: any) => {
      saveMindfulnessAudioUsageToServer(id, {
        duration: durationSinceLastSaved,
      });
    },
    []
  );

  const handleSlider = (e: number) => {
    (playerRef?.current as any).currentTime = e;
    setCurrentTrackTime(e);
    setTime(dayjs().unix());
    if (isPlaying) {
      (playerRef?.current as any).play();
    }
  };

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);

    if (isPlaying) {
      (playerRef?.current as any)?.pause();
    } else {
      (playerRef?.current as any)?.play();
    }
  };

  const handleForwardBackward = (direction: string) => {
    (playerRef?.current as any).pause();
    let newTime = 0;
    if (direction == "forward") {
      newTime = currentTrackTime + 30;
      if (newTime > parseInt(maxTime) * 60) {
        newTime = parseInt(maxTime) * 60;
      }
    }
    if (direction == "backward") {
      newTime = currentTrackTime - 30;
      if (newTime < 0) {
        newTime = 0;
      }
    }
    (playerRef?.current as any).currentTime = newTime;
    setCurrentTrackTime(newTime);
    setTime(dayjs().unix());
    if (isPlaying) {
      (playerRef?.current as any).play();
    }
  };

  const getDuration = (src: string) => {
    const audio = new Audio();
    audio.addEventListener(
      "loadedmetadata",
      () => setDuration(audio.duration),
      false
    );
    audio.src = src;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsMindfulnessListOpen(
        document.getElementsByClassName("mindfulness-list").length > 0
      );
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      w={"100%"}
      pos={"fixed"}
      bottom={
        !isWebPlatform()
          ? isMindfulnessListOpen
            ? 0
            : `${BOTTOM_MENU_HEIGHT + safeAreaInsets.bottom}px`
          : "0px"
      }
      left={"0"}
      h={`${
        80 +
        (!isWebPlatform() && isMindfulnessListOpen ? safeAreaInsets.bottom : 0)
      }px`}
      bg={MEDIA_PLAYER_BACKGROUND_COLOR}
      zIndex={isMindfulnessListOpen ? 1401 : 1399} // under the chakra overlay, unless mindful modal is open
    >
      <Grid
        mt={2}
        templateColumns={isMobile ? "1fr 120px 40px" : "80px 1fr 2fr 1fr 80px"}
      >
        {!isMobile && (
          <Image
            src={image}
            h={"60px"}
            w={"60px"}
            borderRadius={Border_Radius}
            boxShadow={
              "8px 8px 20px -5px rgba(0, 0, 0, 0.15), -8px -8px 20px -5px rgba(240, 240, 240, 0.5)"
            }
            ml={2}
            alt=""
          />
        )}
        <Flex justify={"center"} flexDir={"column"} ml={2}>
          <Text
            textAlign={"left"}
            fontWeight={"semibold"}
            fontSize={["13px", "md"]}
            color={PRIMARY_TEXT_COLOR}
            w={"max-content"}
          >
            {name}
          </Text>
          <Text
            textAlign={"center"}
            fontWeight={"semibold"}
            fontSize={["12px", "xs"]}
            color={PRIMARY_TEXT_COLOR}
            w={"max-content"}
          >
            {title}
          </Text>
        </Flex>

        <Center mx={4}>
          <Stack w="100%">
            <HStack justify={"center"} mb={"10px"}>
              <IconButton
                cursor={"pointer"}
                as={IoPlayBackCircle}
                aria-label={"rewind"}
                h={"20px"}
                w={"20px"}
                bg={"transparent"}
                top="5px"
                visibility={isMobile ? "hidden" : undefined}
                onClick={() => handleForwardBackward("backward")}
              />
              <IconButton
                cursor={"pointer"}
                as={!isPlaying ? AiFillPlayCircle : AiFillPauseCircle}
                h={"24px"}
                w={"24px"}
                aria-label={"play"}
                bg={"transparent"}
                top="5px"
                onClick={() => handlePlayPause()}
              />
              <IconButton
                cursor={"pointer"}
                as={IoPlayForwardCircle}
                aria-label={"forward"}
                h={"20px"}
                w={"20px"}
                bg={"transparent"}
                top="5px"
                visibility={isMobile ? "hidden" : undefined}
                onClick={() => handleForwardBackward("forward")}
              />
            </HStack>
            <Slider
              max={duration}
              value={currentTrackTime}
              onChange={(e) => handleSlider(e)}
            >
              <SliderTrack
                sx={{
                  "& > div": {
                    background: SKILL_BORDER_GRADIENT,
                  },
                }}
                backgroundColor={CARD_BACKGROUND_COLOR}
              >
                <SliderFilledTrack />
              </SliderTrack>
              {/* <SliderThumb /> FYI: adding a slider thumb causes weird problems with the mindfulness modal */}
            </Slider>
            <Center pos={"relative"}>
              <Text w="100%" fontSize={"xs"}>
                {new Date(currentTrackTime * 1000)
                  .toISOString()
                  .substr(11, 8)
                  .replace("00:", "")}
              </Text>
              <Text w="100%" textAlign={"right"} fontSize={"xs"}>
                {new Date(duration * 1000)
                  .toISOString()
                  .substr(11, 8)
                  .replace("00:", "")}
              </Text>
            </Center>
          </Stack>
        </Center>

        {!isMobile && (
          <HStack
            w={"100%"}
            h={"100%"}
            align={"center"}
            justify={"center"}
            mt="10px"
            mx={8}
          >
            <Icon as={AiFillSound} h={"20px"} w={"20px"}></Icon>
            <Slider
              w={"70%"}
              max={100}
              value={volume * 100}
              onChange={(e) => {
                setVolume(e / 100);
                const audio = playerRef?.current as any;
                if (audio) {
                  audio.volume = e / 100;
                }
              }}
            >
              <SliderTrack
                sx={{
                  "& > div": {
                    background: SKILL_BORDER_GRADIENT,
                  },
                }}
                backgroundColor={CARD_BACKGROUND_COLOR}
              >
                <SliderFilledTrack />
              </SliderTrack>
              {/* <SliderThumb /> FYI: adding a slider thumb causes weird problems with the mindfulness modal */}
            </Slider>
          </HStack>
        )}
        <IconButton
          alignItems={"start"}
          justifyContent={isMobile ? undefined : "right"}
          mt="8px"
          mr={"12px"}
          aria-label={"close"}
          size={"lg"}
          icon={<AiFillCloseCircle />}
          bg={"transparent"}
          onClick={() => {
            setAudio(null);
          }}
        />
      </Grid>
      <Box display={"none"}>
        <audio
          controls={true}
          src={url}
          ref={playerRef}
          onTimeUpdate={(e) => {
            const newCurrentTrackTime = e.currentTarget.currentTime;
            const newTime = dayjs().unix();
            const durationSinceLastSaved = newTime - time;
            setCurrentTrackTime(newCurrentTrackTime);
            if (userFirstClickedOnTrack) {
              setUserFirstClickedOnTrack(false);
              saveTimeToServer(0, id);
            } else if (durationSinceLastSaved >= 60) {
              saveTimeToServer(durationSinceLastSaved, id);
              setTime(newTime);
            }
          }}
          onPlay={(e) => {
            setTime(dayjs().unix());
          }}
        ></audio>
      </Box>
    </Box>
  );
};
