import React from "react";
import { SwiperSlide } from "swiper/react";

// When using this, call it like a function. This is done on purpose because when you
// render this as a component ie <ScrollPanelBlock> SwiperJs will render these element
// outside the wrapper for some reason.  See in the Lesson Page as an example how this is
// called.
export const ScrollPanelBlock = (content: any, width: string, key: any) => {
  return (
    <SwiperSlide key={key} style={{ maxWidth: width }}>
      {content}
    </SwiperSlide>
  );
};

// making an actual component does not work.
// interface ScrollPanelBlockItemProps {
//   children: any;
//   width: string;
// }
// export const ScrollPanelBlockItem = (props: ScrollPanelBlockItemProps) => {
//   const { children, width } = props;
//   return (
//       <SwiperSlide style={{ width: width }}>{children}</SwiperSlide>
//   );
// };
