import React, { useContext, useEffect, useState } from "react";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
} from "../../../State/GlobalContext";
import {
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { JoinTeamQRCard } from "./JoinTeamQRCard";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { Loading } from "../../Common/Loading";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { HVTestId } from "../../../Testing/dataTestIds";
import { TeamLessonDto } from "@headversity/contract";

interface PresentationLobbyViewProps {
  additionalSection: any;
  teamLesson: TeamLessonDto | undefined;
  mode: "Participant" | "Presenter";
  mainTitle?: string;
  hideLoading?: boolean;
  subTitle?: string;
}

export const PresentationLobbyView = (props: PresentationLobbyViewProps) => {
  const {
    additionalSection,
    teamLesson,
    mode,
    mainTitle,
    hideLoading,
    subTitle,
  } = props;
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  const {
    currentTeamLessonUserInstanceId,
    currentTeam,
    getTeamLessonUserInstanceParticipantsFromServer,
    currentParticipantList,
    presenterUsername,
    currentApp,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [joinedNames, setJoinedNames] = useState<string[]>([]);

  const setJoinedNamesFromCurrentParticipantList = () => {
    const names = !currentParticipantList
      ? []
      : currentParticipantList.map((x: any) => x.user.fullName);
    setJoinedNames(names);
  };

  useEffect(() => {
    setJoinedNamesFromCurrentParticipantList();
  }, [currentParticipantList]);

  useEffect(() => {
    if (currentTeamLessonUserInstanceId) {
      getTeamLessonUserInstanceParticipantsFromServer(
        currentTeamLessonUserInstanceId
      );
    }
  }, [currentTeamLessonUserInstanceId]);

  return (
    <>
      <Flex
        flexDir={"column"}
        align={"center"}
        justify={"center"}
        gap={mode === "Presenter" ? "1" : "7"}
        mt={mode === "Presenter" ? "8" : undefined}
        data-testid={HVTestId.PresentationLobby.modal}
      >
        <Flex flexDir={"column"} gap={"5"} align={"center"}>
          <Box mt={mode === "Presenter" ? undefined : "-32px"}>
            <BlockSectionTitle
              primaryTextColor={MODAL_PRIMARY_TEXT_COLOR}
              title={mainTitle ?? HVLocalizeStrings.TEAM_WAITING_TEAM_MATE}
            />
          </Box>

          <Text
            textAlign={"center"}
            color={MODAL_PRIMARY_TEXT_COLOR}
            dangerouslySetInnerHTML={{
              __html: subTitle
                ? subTitle.replace("{0}", presenterUsername)
                : mode === "Presenter"
                ? HVLocalizeStrings.TEAM_WAITING_JOIN_MESSAGE
                : HVLocalizeStrings.TEAM_TEAM_LESSON_WAITING_TO_START.replace(
                    "{0}",
                    presenterUsername
                  ),
            }}
          />

          <Flex flexDir={"column"} align={"center"} gap={"2"}>
            <Flex gap={"1"}>
              <Tooltip label={joinedNames.join(", ")}>
                <Flex>
                  <Text
                    color={MODAL_PRIMARY_TEXT_COLOR}
                    fontWeight={"semibold"}
                    userSelect={"none"}
                  >
                    {HVLocalizeStrings.JOINED}
                  </Text>
                  <Text
                    color={MODAL_PRIMARY_TEXT_COLOR}
                    fontWeight={"semibold"}
                    userSelect={"none"}
                    ml={"4px"}
                    mr={"2px"}
                  >
                    {currentParticipantList?.length
                      ? currentParticipantList?.length
                      : 0}
                  </Text>
                  <Icon
                    as={AiOutlineInfoCircle}
                    mt={"3px"}
                    w={"24px"}
                    color={MODAL_PRIMARY_TEXT_COLOR}
                  />
                </Flex>
              </Tooltip>
            </Flex>
            {!hideLoading && <Loading />}
          </Flex>
        </Flex>

        <Flex
          flexDir={isMobile ? "column" : "row"}
          gap={isMobile ? 2 : 4}
          align={"center"}
          my={"2"}
        >
          {currentApp !== AppType.SPORT && isMobile && (
            <JoinTeamQRCard
              teamCode={currentTeam?.code}
              teamLesson={teamLesson}
            ></JoinTeamQRCard>
          )}
          {currentApp !== AppType.SPORT && !isMobile && (
            <>
              <JoinTeamQRCard
                teamCode={currentTeam?.code}
                teamLesson={teamLesson}
                permanentMode={"QR"}
              ></JoinTeamQRCard>

              <JoinTeamQRCard
                teamCode={currentTeam?.code}
                teamLesson={teamLesson}
                permanentMode={"Info"}
              ></JoinTeamQRCard>
            </>
          )}
        </Flex>

        {additionalSection}
      </Flex>
    </>
  );
};
