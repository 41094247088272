import { useState } from "react";
import {
  Center,
  Flex,
  Link,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import FormInputField from "../Shared/FormInputField";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { ErrorText } from "../Shared/ErrorText";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { createToast } from "../../../Utils/toastUtil";
import { INTRO_STEP, SIGN_IN_STEP } from "./LoginPage";
import { PRIMARY_TEXT_COLOR, Success } from "../../../Styles/HeadversityStyle";
import {
  RegistrationLoginStates,
  RequestPasswordCodeDto,
} from "@headversity/contract";
import { resendValidation } from "../../../Api/People/RegistrationApi";

interface ResendActivationPageProps {
  currentStep: string | null | undefined;
  setCurrentStep: (currentStep: string | null) => void;
}

export const ResendActivationPage = (props: ResendActivationPageProps) => {
  const isMobileView = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });

  const { setCurrentStep } = props;
  const [email, setEmail] = useState<string | null>(null);
  const [isInvalid, setIsInvalid] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error] = useState<string | null>(null);
  const handleChange = (
    stateToUpdate: RegistrationLoginStates,
    stateText: string,
    isValid: boolean
  ) => {
    if (stateToUpdate === RegistrationLoginStates.Email) {
      setEmail(stateText);
      if (isValid) {
        setIsInvalid(false);
      } else {
        setIsInvalid(true);
      }
    }
  };

  const toast = useToast();

  const submitResendActivation = async () => {
    if (!email || email.trim() === "" || isInvalid) {
      return;
    }
    setIsSubmitting(true);
    resendValidation({
      email: email,
    } as RequestPasswordCodeDto)
      .then(
        () => {
          createToast(
            toast,
            Success,
            "resendActivationEmail",
            HVLocalizeStrings.VALIDATION_EMAIL_SENT
          );
          setCurrentStep(SIGN_IN_STEP);
        },
        () => {}
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Flex
        flexDir={"column"}
        gap={"5"}
        color={PRIMARY_TEXT_COLOR}
        mb={8}
        alignItems={isMobileView ? "center" : undefined}
      >
        {error && (
          <Center w={"300px"}>
            <ErrorText message={error} />
          </Center>
        )}
        <>
          <Text fontWeight={"500"} fontSize={"xl"}>
            {HVLocalizeStrings.LOGIN_VALIDATION_PAGE_TITLE}
          </Text>
          <Text
            maxW={"345px"}
            dangerouslySetInnerHTML={{
              __html: HVLocalizeStrings.LOGIN_VALIDATION_PAGE_DESCRIPTION,
            }}
          />
        </>
      </Flex>
      <Flex
        flexDirection={"column"}
        gap={"2"}
        align={"center"}
        p={"20px"}
        pt={0}
      >
        <Flex flexDir={"column"} gap={"2"}>
          <FormInputField
            id={"email"}
            imageSrc={
              "https://cdn.headversity.com/app/resources/login/envelope.png"
            }
            imageW={"18px"}
            imageH={"18px"}
            placeholder={HVLocalizeStrings.EMAIL_PLACEHOLDER}
            handleChange={handleChange}
            stateToUpdate={RegistrationLoginStates.Email}
            type={"email"}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submitResendActivation();
              }
            }}
            autoFocus
          />
        </Flex>
        <>
          {isSubmitting ? (
            <Spinner />
          ) : (
            <>
              <AnimatedButton
                text={HVLocalizeStrings.LOGIN_PAGE_SEND_EMAIL}
                disabled={
                  isSubmitting || !email || email.trim() === "" || isInvalid
                }
                colorSet={AnimatedButtonColorSet.Primary}
                onClick={() => {
                  submitResendActivation();
                }}
                w={300}
              />
              <Link
                textDecoration={"underline"}
                mt={2}
                fontSize={"sm"}
                onClick={() => {
                  setCurrentStep(INTRO_STEP);
                }}
              >
                {HVLocalizeStrings.BACK_TO_LOGIN_SIGNUP}
              </Link>
            </>
          )}
        </>
      </Flex>
    </>
  );
};
