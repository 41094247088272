import { Box, CircularProgress, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { UserSkillV2Dto } from "@headversity/contract";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { getScoreFromHeadscanPercentage } from "../../../Utils/SkillsUtil";
import { HVTestId } from "../../../Testing/dataTestIds";

export enum DonutSize {
  Small,
  Medium,
  Large,
}

export const getWidthFromDonutSize = (size?: DonutSize) => {
  switch (size) {
    case DonutSize.Small:
      return "60px";
    case DonutSize.Medium:
      return "90px";
    case DonutSize.Large:
      return "150px";
    default:
      return "0px";
  }
};

interface ResilienceSkill20DonutProps {
  userSkill: UserSkillV2Dto;
  size: DonutSize;
  isInView: boolean;
  color: string;
  description: string;
}

export const ResilienceSkill20Donut = ({
  userSkill,
  size,
  isInView,
  color,
  description,
}: ResilienceSkill20DonutProps) => {
  const [realScore, setRealScore] = useState<number | undefined>(0);

  useEffect(() => {
    if (isInView) {
      const score = userSkill
        ? getScoreFromHeadscanPercentage(userSkill.headscanPercentage)
        : 0;
      // this handles donut going back to 0 before next value is set
      //  note: this is exploiting the fact that setting to 0 does not animate
      if (score > 0) {
        setRealScore(0);
      }

      setTimeout(() => {
        // some bug with donut not animating to 0...
        if (score === 0) {
          setRealScore(0.001);
        } else if (score < 12) {
          setRealScore(12);
        } else {
          setRealScore(score);
        }
      }, 250);
    }
  }, [isInView, userSkill, userSkill?.headscanPercentage]);

  return (
    <Box
      pos="relative"
      width={getWidthFromDonutSize(size)}
      mx="auto"
      transition={"all .2s ease-in-out"}
    >
      <CircularProgress
        p="1px"
        ml="-1.5px"
        transform={"rotate(-90deg)"}
        value={realScore && realScore / 2}
        color={color}
        filter="url(#shadow)"
        thickness="16px"
        size="100%"
        clipPath="polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);"
        data-testid={`${
          realScore && realScore <= 0.001
            ? `locked_${userSkill?.skillId}`
            : `unlocked_${userSkill?.skillId}`
        }`}
      />
      <Box
        pos="absolute"
        top={
          size === DonutSize.Large
            ? "62px"
            : size === DonutSize.Medium
            ? "34px"
            : "21px"
        }
        textAlign={"center"}
        w="100%"
        transition={"all .2s ease-in-out"}
      >
        <Text
          fontSize={
            size === DonutSize.Large
              ? "13px"
              : size === DonutSize.Medium
              ? "11px"
              : "9px"
          }
          color={MODAL_PRIMARY_TEXT_COLOR}
          data-testid={`${HVTestId.ResilienceSkill20Donut.score}_${userSkill?.skillId}`}
        >
          {description}
        </Text>
      </Box>
    </Box>
  );
};
