import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import {
  FeatureImplementationViewDto,
  MicroLessonDto,
  PathTeamLessonDto,
  UserDto,
} from "@headversity/contract";

export interface IPageProvider {
  getPageTitle: (defaultTitle: string) => string;
}
interface useProfileProps {
  isLessonPreviewOpen: boolean;
  lesson: MicroLessonDto | null;
  presenterModalOpen: boolean;
  presenterPathTeamLesson: PathTeamLessonDto | undefined;
  participantModalOpen: boolean;
  participantPathTeamLesson: PathTeamLessonDto | undefined;
}
export const usePage = (props: useProfileProps): IPageProvider => {
  const {
    isLessonPreviewOpen,
    lesson,
    presenterModalOpen,
    presenterPathTeamLesson,
    participantModalOpen,
    participantPathTeamLesson,
  } = props;

  const getPageTitle = (defaultTitle: string): any => {
    if (isLessonPreviewOpen) {
      return `${lesson?.name} - ${HVLocalizeStrings.HEADVERSITY}`;
    }
    if (presenterModalOpen) {
      return `${presenterPathTeamLesson?.teamLesson?.name} - ${HVLocalizeStrings.TEAM_HOME_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`;
    }
    if (participantModalOpen) {
      return `${participantPathTeamLesson?.teamLesson?.name} - ${HVLocalizeStrings.TEAM_HOME_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`;
    }
    return defaultTitle;
  };
  return { getPageTitle };
};
