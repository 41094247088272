import { HStack, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { BLOCK_SECTION_SECONDARY_TEXT_COLOR } from "../../Styles/HeadversityStyle";
import { AnimatedButton, AnimatedButtonColorSet } from "./AnimatedButton";

export interface PagerState {
  currentPage: number;
  totalPage: number;
  pagePerRow: number;
  showPageInput: boolean;
}

interface PagerProps {
  pagerState: PagerState;
  setPagerState: (val: PagerState) => void;
  dontScroll?: boolean;
  withArrows?: boolean;
  pageTextColor?: string;
  buttonColorSet?: AnimatedButtonColorSet;
}

export const Pager = (props: PagerProps) => {
  const {
    pagerState,
    setPagerState,
    dontScroll,
    withArrows,
    pageTextColor,
    buttonColorSet,
  } = props;

  const handleScroll = () => {
    setTimeout(() => {
      // 230 is top of page after header
      window.scrollTo({ top: 230, behavior: "smooth" });
    }, 100);
  };

  return (
    <HStack spacing={4}>
      <AnimatedButton
        disabled={pagerState.currentPage === 0}
        colorSet={buttonColorSet ?? AnimatedButtonColorSet.Fourth}
        text={withArrows ? "<" : HVLocalizeStrings.PREV}
        onClick={() => {
          if (pagerState.currentPage - 1 < 0) {
            return;
          }
          pagerState.currentPage--;
          setPagerState({ ...props.pagerState });

          if (!dontScroll) {
            handleScroll();
          }
        }}
        w={withArrows ? 70 : 120}
        aria-label={
          pagerState.currentPage === 0
            ? HVLocalizeStrings.PREVIOUS_PAGE
            : HVLocalizeStrings.GO_TO_PAGE + pagerState.currentPage
        }
      />

      <HStack
        spacing={1}
        color={pageTextColor ?? BLOCK_SECTION_SECONDARY_TEXT_COLOR}
      >
        <Text>{HVLocalizeStrings.PAGE}</Text>
        <Text>{pagerState.currentPage + 1}</Text>
        <Text>{HVLocalizeStrings.OF}</Text>
        <Text>{pagerState.totalPage}</Text>
      </HStack>

      <AnimatedButton
        disabled={pagerState.currentPage + 1 >= pagerState.totalPage}
        colorSet={buttonColorSet ?? AnimatedButtonColorSet.Fourth}
        text={withArrows ? ">" : HVLocalizeStrings.NEXT}
        onClick={() => {
          if (pagerState.currentPage + 1 >= pagerState.totalPage) {
            return;
          }
          pagerState.currentPage++;
          setPagerState({ ...pagerState });

          if (!dontScroll) {
            handleScroll();
          }
        }}
        w={withArrows ? 70 : 120}
        aria-label={
          pagerState.currentPage + 1 === pagerState.totalPage
            ? HVLocalizeStrings.NEXT_PAGE
            : HVLocalizeStrings.GO_TO_PAGE + (pagerState.currentPage + 2)
        }
      />
    </HStack>
  );
};
