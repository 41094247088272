import { QuestionTestHarness } from "../Components/Shared/Questionnaire/QuestionTestHarness";
import { StreakInfoTestHarness } from "../Components/Solo/Shared/StreakInfoTestHarness";

export const TestPage = () => {
  return (
    <>
      <QuestionTestHarness />
      <StreakInfoTestHarness />
    </>
  );
};
