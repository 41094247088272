import { ScrollPanelBlock } from "../../Common/ScrollPanelBlock";
import { ScrollPanel } from "../../Common/ScrollPanel";
import { NanoPracticeBlock } from "../NanoPractices/NanoPracticeBlock";
import { useBreakpointValue } from "@chakra-ui/react";
import { NanoPracticeDto } from "@headversity/contract";

interface NanoPracticesScrollPanelProps {
  isDesktop: boolean | undefined;
  startDate?: string;
  practices: NanoPracticeDto[];
  showDescription?: boolean;
  from: string;
  showPoints?: boolean;
  width?: string;
  height?: string;
}

export const NanoPracticesScrollPanel = (
  props: NanoPracticesScrollPanelProps
) => {
  const {
    practices,
    isDesktop,
    showDescription,
    startDate,
    from,
    showPoints,
    width,
    height,
  } = props;

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const widthToUse = width
    ? width
    : `${Math.min(
        window.innerWidth / 2 - (practices.length > 2 ? 32 : 24),
        241
      )}px`;

  return (
    <>
      <ScrollPanel
        spaceBetween={8}
        showChevronContainer={true}
        slidesPerGroup={isDesktop ? 3 : 1}
        slidesPerView={"auto"}
        isPaginationOnContainer={true}
      >
        {practices?.map((practice: any) => {
          return ScrollPanelBlock(
            <NanoPracticeBlock
              from={from}
              practice={practice}
              startDate={startDate}
              width={widthToUse}
              height={height ? height : "175px"}
              isSmall={true}
              isPortrait={isMobile}
              fullDescription={isMobile && showDescription}
              showPoints={showPoints}
            />,
            widthToUse,
            practice?.id
          );
        })}
      </ScrollPanel>
    </>
  );
};
