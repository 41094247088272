import { Box, Center, Text } from "@chakra-ui/react";
import { Respect_Cherry, White } from "../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { useContext } from "react";
import { AppType, GlobalContext } from "../../../State/GlobalContext";

interface ContentBlockPointOverlayProps {
  points: number;
  opacity?: number;
  color?: string;
  bgColor?: string;
  isSmall?: boolean;
}

export const ContentBlockPointOverlay = ({
  points,
  opacity,
  color,
  bgColor,
  isSmall,
}: ContentBlockPointOverlayProps) => {
  const { currentApp } = useContext(GlobalContext);

  const showXP = currentApp === AppType.SOLO;

  return (
    <Box
      pos="absolute"
      bottom={isSmall ? "-8px" : "-18px"}
      zIndex={3}
      right={"-17px"}
      alignItems={"center"}
      color={currentApp === AppType.CERT ? White : color}
      transform="translate(-50%,-50%)"
      opacity={opacity}
      transition="0.2s all ease-in-out"
    >
      <Box border="solid 1px #ffffff44" borderRadius={"50%"}>
        <Box
          border={"4px"}
          borderRadius={"50%"}
          alignItems={"center"}
          height={isSmall ? "36px" : "46px"}
          width={isSmall ? "36px" : "46px"}
          borderColor={
            currentApp === AppType.CERT
              ? Respect_Cherry
              : bgColor
              ? "#00000055"
              : "#00000022"
          }
          bgColor={
            currentApp === AppType.CERT ? "rgba(171, 39, 79, 0.33)" : bgColor
          }
        >
          <Center w="100%" h="100%" mt="-1px">
            <Text
              textAlign={"center"}
              fontWeight={"700"}
              fontSize={isSmall ? "13px" : "14px"}
              lineHeight="1.1"
            >
              {points}

              <Text
                textAlign={"center"}
                fontWeight={"300"}
                fontSize={showXP && !isSmall ? "9px" : "8px"}
                mt={"-1px"}
              >
                {showXP
                  ? "XP"
                  : (points === 1
                      ? HVLocalizeStrings.POINTS.replace("s", "")
                      : HVLocalizeStrings.POINTS
                    ).toUpperCase()}
              </Text>
            </Text>
          </Center>
        </Box>
      </Box>
    </Box>
  );
};
