import { InputQuestionAnswer } from "./NanoPracticeModal";
import { Stack, Text, Box } from "@chakra-ui/react";

export interface NanoPracticeResponseListProps {
  currentInputs: InputQuestionAnswer[];
}
export const NanoPracticeResponseList = ({
  currentInputs,
}: NanoPracticeResponseListProps) => {
  return (
    <Stack w={"100%"} p={4} fontWeight="300" px={"0"} pt={3}>
      {currentInputs.map((ci, index) => (
        <Box py={1} pt={index === 0 ? 0 : 1} key={ci.title as string}>
          <Text fontSize={"18px"} lineHeight="1.2" pb={"1"} fontWeight="500">
            {ci.title as string}
          </Text>
          <Text fontSize="15px">{ci.value}</Text>
        </Box>
      ))}
    </Stack>
  );
};
