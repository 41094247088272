import { StepPanel } from "../../../Common/StepPanel";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { useNavigate } from "react-router-dom";
import {
  BOOST_TEXT_COLOR,
  Border_Radius,
  BUTTON_SECONDARY_BACKGROUND_COLOR,
  BUTTON_SECONDARY_HOVER_COLOR,
  BUTTON_SECONDARY_TEXT_COLOR,
  CONTRAST_TEXT_COLOR,
  DESTRUCTIVE_TEXT_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
  MODAL_SECONDARY_TEXT_COLOR,
  THINKING_TOOL_CHOICE_1_BACKGROUND_COLOR,
} from "../../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";

interface ThinkingToolStepOutroProps {
  setOpen: (open: boolean) => void;
  currentInputForNegativeThought: string;
  currentInputForBalancedThought: string;
}

export const ThinkingToolStepOutro = (props: ThinkingToolStepOutroProps) => {
  const {
    setOpen,
    currentInputForNegativeThought,
    currentInputForBalancedThought,
  } = props;
  const navigate = useNavigate();

  return (
    <>
      <Box color={MODAL_PRIMARY_TEXT_COLOR}>
        <Heading
          fontSize={"2xl"}
          fontWeight={"semibold"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {HVLocalizeStrings.THINKING_TOOLS_GREAT}
        </Heading>
        <Text fontSize={"md"} mt={"4"}>
          {HVLocalizeStrings.THINKING_TOOLS_RESILIENT}
        </Text>
        <Text fontSize={"md"} mt={"4"}>
          {HVLocalizeStrings.THINKING_TOOLS_REMIND}{" "}
          <Text
            as={"span"}
            fontSize={"md"}
            fontWeight={"semibold"}
            display={"inline"}
          >
            {HVLocalizeStrings.THINKING_TOOLS_REMIND_ICE}
          </Text>{" "}
          {HVLocalizeStrings.THINKING_TOOLS_REMIND_WHEN}
        </Text>
      </Box>
      <Divider my={4} />
      <Box display={{ md: "flex" }}>
        <Box w={{ md: "50%" }}>
          <Text color={MODAL_SECONDARY_TEXT_COLOR}>
            {HVLocalizeStrings.THINKING_TOOLS_EXCHANGE}
          </Text>
          <Text
            mt={"2"}
            color={DESTRUCTIVE_TEXT_COLOR}
            fontSize="2xl"
            fontWeight={"semibold"}
          >
            "{currentInputForNegativeThought}"
          </Text>
        </Box>
        <Box mt={{ base: 8, md: 0 }}>
          <Text color={MODAL_PRIMARY_TEXT_COLOR}>
            {" "}
            {HVLocalizeStrings.THINKING_TOOLS_NOW_EXCHANGE_FOR}
          </Text>
          <Text
            mt={"2"}
            color={BOOST_TEXT_COLOR}
            fontSize="2xl"
            fontWeight={"semibold"}
          >
            "{currentInputForBalancedThought}"
          </Text>
        </Box>
      </Box>
      <Divider my={4} />
      <Center>
        <HStack
          mt={"6"}
          w={"470px"}
          minH={"110px"}
          align={"center"}
          justify={"center"}
        >
          <Button
            className="button-link hover-button"
            onClick={() => {
              navigate("/tools/focus");
              setOpen(false);
            }}
            textDecoration={"none"}
            color={CONTRAST_TEXT_COLOR}
          >
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              bg={THINKING_TOOL_CHOICE_1_BACKGROUND_COLOR}
              borderRadius={Border_Radius}
            >
              <Box m={"3"}>
                <Text mb={"2"}>
                  {HVLocalizeStrings.THINKING_TOOLS_REMIND_DOES}
                </Text>
                {HVLocalizeStrings.THINKING_TOOLS_REMIND_DOES_CLICK}
                <Text as={"span"}>
                  {` ${HVLocalizeStrings.THINKING_TOOLS_REMIND_DOES_CREATE}`}
                </Text>
              </Box>
              <Image
                h={"58px"}
                src={
                  "https://cdn.headversity.com/app/resources/toolIcons/focus.svg"
                }
                display={{ base: "none", sm: "inline" }}
                alt=""
              />
            </Flex>
          </Button>
        </HStack>
      </Center>
      <Center mt={8} mb={2}>
        <AnimatedButton
          text={HVLocalizeStrings.CLOSE}
          onClick={() => {
            setOpen(false);
          }}
        />
      </Center>
    </>
  );
};
