import { Box } from "@chakra-ui/react";
import { PollQuestionDto } from "@headversity/contract";
import { PollSettings } from "@headversity/contract/Common/PollSettings";
import { Timer } from "../../../Common/Timer";

interface CharterTimerProps {
  pollQuestion?: PollQuestionDto;
  onTimeChange?: (time: number) => void;
  setDisableSubmit?: (value: boolean) => void;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  position?: string;
}

export const CharterTimer = (props: CharterTimerProps) => {
  const { pollQuestion, onTimeChange, setDisableSubmit, left, right, top, bottom, position } =
    props;
  const seconds =
    (pollQuestion?.pollSettings as PollSettings)?.timerSeconds ?? 180;
     let doTimeChange = onTimeChange;
     if (!doTimeChange) {
       doTimeChange = (time: number) => {
         if (setDisableSubmit && seconds - time > 60) {
           setDisableSubmit(false);
         }
       };
     }

  return (
    <Box
      position={(position ?? "absolute") as any}
      left={left}
      right={right ?? "12"}
      top={top ?? "15px"}
      bottom={bottom}
    >
      <Timer seconds={seconds} onTimeChange={doTimeChange} />
    </Box>
  );
};
