import { useSearchParams } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../State/GlobalContext";
import { UserCertificateDto } from "../../@headversity/contract";
import { Certificate } from "./Certificate";
import { PDFExport } from "@progress/kendo-react-pdf";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../Common/AnimatedButton";
import { Box, Flex, Image } from "@chakra-ui/react";
import { LanguageSelector } from "../Common/LanguageSelector";
import { ErrorPage } from "../Shared/Error/ErrorPage";
import { pdfExport } from "../../Utils/PDFDownloadUtil";
import ReactGA from "react-ga4";
import { PublicSiteLink } from "./Shared/PublicSiteLink";

export const UserCertificatePage = () => {
  const { getUserCertificateFromServer, setSelectedUserLanguage, languages } =
    useContext(GlobalContext);

  const [searchParams] = useSearchParams();
  // state variable for the certificate
  const [certificate, setCertificate] = useState<UserCertificateDto>();
  const pdfRef = useRef<PDFExport | null>(null);
  const [guidNotFound, setGuidNotFound] = useState<boolean>(false);

  useEffect(() => {
    const guid = searchParams.get("guid");
    if (guid) {
      getUserCertificateFromServer(guid).then(
        (cert) => {
          setCertificate(cert);
        },
        () => {
          setGuidNotFound(true);
        }
      );
    }
  }, [searchParams]);

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 300);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  }, []);

  useEffect(() => {
    const navigatorLanguage = navigator.language.slice(0, 2).toLowerCase();
    const match = languages.find((lang: string) => lang === navigatorLanguage);
    if (match) {
      setSelectedUserLanguage(match);
      HVLocalizeStrings.setLanguage(match);
    }
  }, [languages]);

  const doPdfExport = () => {
    if (!certificate) return;
    pdfExport(
      pdfRef,
      undefined,
      `${certificate.certName} ${new Date().getFullYear()}.pdf`
    );
  };

  return (
    <>
      {guidNotFound && <ErrorPage errorCode={404} />}
      {!guidNotFound && certificate && (
        <>
          <div
            style={{
              position: "absolute",
              left: "-5000px",
              top: 0,
            }}
            className={"pdfExport"}
          >
            <PDFExport
              ref={pdfRef}
              paperSize="auto"
              margin={"4"}
              landscape={true}
              fileName={`${certificate.certName} ${new Date().getFullYear()}`}
            >
              <Certificate
                certificate={certificate}
                cssClassName={"downloadable"}
              />
            </PDFExport>
          </div>
          <Flex
            w={"100%"}
            pos={"relative"}
            maxHeight={"100%"}
            flexDir={"column"}
            align={"center"}
            backgroundImage={
              "url(https://cdn.headversity.com/app/resources/modal/modal-bg-light.jpg)"
            }
            backgroundSize={"cover"}
          >
            <Flex
              m={"3"}
              p={"3"}
              align={"center"}
              justify={"flex-end"}
              w={"95%"}
              maxW={"1152px"}
              gap={"5"}
            >
              <Flex align={"center"} flexBasis={"auto"} w={"100%"}>
                <PublicSiteLink
                  url={`https://headversity.com/?utm_source=certificate_page&utm_medium=headversity_logo&utm_campaign=${encodeURIComponent(
                    certificate.certName as string
                  )}`}
                >
                  <Image
                    src={
                      "https://cdn.headversity.com/app/resources/other/blue-main-logo.png"
                    }
                    width={"185px"}
                    alt=""
                  />
                </PublicSiteLink>
              </Flex>
              <Box minW="110px">
                <LanguageSelector
                  onLanguageChanged={(selectedLanguage: string) => {
                    HVLocalizeStrings.setLanguage(selectedLanguage);
                    setSelectedUserLanguage(selectedLanguage);
                  }}
                />
              </Box>

              <Box w="120px" minW={"120px"}>
                <AnimatedButton
                  text={HVLocalizeStrings.CERTIFICATE_DOWNLOAD}
                  colorSet={AnimatedButtonColorSet.Primary}
                  onClick={doPdfExport}
                  w={120}
                  scale={1}
                />
              </Box>
            </Flex>
            <Flex
              w={"95%"}
              px={"10"}
              pb={"10"}
              flexDir={"column"}
              align={"center"}
              justify={"flex-start"}
              className={"certPDFContainer"}
            >
              <Box
                boxShadow={"lg"}
                className={"certPDFContainerBox"}
                transformOrigin={"top"}
                maxHeight={"100%"}
              >
                <Certificate certificate={certificate} />
              </Box>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

const handleResize = () => {
  const container = document.getElementsByClassName("certPDFContainer");
  const element = document.getElementsByClassName("certPDFContainerBox");
  if (container.length > 0 && element.length > 0) {
    const scale = scaleToFitMaxDimensions(container[0].clientWidth, 890);
    (element[0] as any).style.transform = `scale(${scale})`;
  }
};

const scaleToFitMaxDimensions = (
  originalWidth: number,
  originalHeight: number
) => {
  const maxWidth = 1152;
  const maxHeight = 890;
  const widthRatio = originalWidth / maxWidth;
  const heightRatio = originalHeight / maxHeight;
  return Math.min(
    widthRatio > 1 ? 1 : widthRatio,
    heightRatio > 1 ? 1 : heightRatio
  );
};
