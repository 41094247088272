import { Box, Center, Grid, Heading, IconButton } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import Joyride from "react-joyride";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { Border_Radius } from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";
import { AnimatedButton } from "./AnimatedButton";

interface TourProps {
  tourSteps: any[];
  onTourComplete: () => void;
}

export const Tour = ({ tourSteps, onTourComplete }: TourProps) => {
  const TourTooltip = ({
    index,
    step,
    closeProps,
    primaryProps,
    tooltipProps,
  }: any) => (
    <Box
      style={{
        backgroundColor: "white",
        borderRadius: Border_Radius,
        boxSizing: "border-box",
        maxWidth: "350px",
        padding: "20px",
        position: "relative",
        backgroundImage:
          "url(https://cdn.headversity.com/app/resources/modal/modal-bg-light.jpg)",
        backgroundSize: "cover",
        boxShadow: "0px 0px 10px 5px rgba(255,255,255,0.15)",
        fontSize: "15px",
      }}
      {...tooltipProps}
      data-testid={HVTestId.Tour.container}
    >
      <Grid
        templateColumns={"1fr 32px"}
        className="uninterruptible-modal app-tour"
      >
        <Heading fontSize={"xl"}>{step.title}</Heading>

        <IconButton
          as={AiOutlineClose}
          cursor="pointer"
          w={"18px"}
          h={"18px"}
          aria-label={"Close"}
          backgroundColor="white"
          mt="-2px"
          ml="8px"
          onClick={() => {
            onTourComplete();
          }}
          data-testid={HVTestId.Tour.closeButton}
        />
      </Grid>

      <Box mt={4}>{step.content}</Box>
      <Center mt={8}>
        {index < tourSteps.length - 1 && (
          <AnimatedButton
            text={HVLocalizeStrings.NEXT}
            h={35}
            {...primaryProps}
            dataTestId={HVTestId.Tour.nextButton}
          />
        )}
        {index === tourSteps.length - 1 && (
          <AnimatedButton
            text={HVLocalizeStrings.CLOSE}
            h={35}
            {...closeProps}
            m={4}
          />
        )}
      </Center>
    </Box>
  );
  return (
    <Joyride
      steps={tourSteps}
      run={true}
      tooltipComponent={TourTooltip}
      showProgress={true}
      continuous={true}
      scrollOffset={200}
      showSkipButton={true}
      disableOverlayClose={true}
      hideCloseButton={true}
      styles={{
        options: {
          overlayColor: "var(--chakra-colors-OVERLAY_BACKGROUND_COLOR)",
          zIndex: 9999,
        },
      }}
      callback={(x: any) => {
        if (x.action === "reset") {
          onTourComplete();
        }
      }}
    />
  );
};
