import { Heading } from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";

export const WelcomeModalHeader = ({
  mt,
  mb,
  mr,
  mx,
  children,
  isLarge,
  fontSize,
  useTheme,
}: any) => {
  return (
    <Heading
      fontSize={fontSize ? fontSize : isLarge ? "3xl" : "28px"}
      lineHeight={1.2}
      fontWeight="400"
      color={useTheme ? MODAL_PRIMARY_TEXT_COLOR : "white"}
      mt={mt}
      mb={mb}
      mx={mx}
      mr={mr}
    >
      {children}
    </Heading>
  );
};
