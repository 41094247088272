import { Center, Heading, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../../Styles/HeadversityStyle";
import { useContext } from "react";
import PushNotificationAlert from "../../Notifications/PushNotificationAlert";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { isWebPlatform } from "../../../../Utils/mobileUtils";

interface FocusToolStepOutroProps {
  setOpen: (open: boolean) => void;
}

export const FocusToolStepOutro = (props: FocusToolStepOutroProps) => {
  const { pushDisabled } = useContext<IGlobalProvider>(GlobalContext);

  const { setOpen } = props;

  return (
    <>
      {!isWebPlatform() && pushDisabled && <PushNotificationAlert />}
      <Heading
        fontSize={"2xl"}
        fontWeight={"semibold"}
        color={MODAL_PRIMARY_TEXT_COLOR}
        mt={2}
      >
        {HVLocalizeStrings.FOCUS_TOOLS_PLAN_IS_SET}
      </Heading>
      <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
        {HVLocalizeStrings.FOCUS_TOOLS_PLAN_IS_SET_DESCRIPTION}
      </Text>
      <Center mt={8} mb={2}>
        <AnimatedButton
          onClick={() => {
            setOpen(false);
          }}
          text={HVLocalizeStrings.CLOSE}
        />
      </Center>
    </>
  );
};
