export const HeadZoneIcons: any = {
  backArrow:
    "https://cdn.headversity.com/app/resources/headzone/arrow-back.png",
  burnOutBrain:
    "https://cdn.headversity.com/app/resources/headzone/burn-out.svg",
  stressedBrain:
    "https://cdn.headversity.com/app/resources/headzone/stressed.svg",
  okayBrain: "https://cdn.headversity.com/app/resources/headzone/okay.svg",
  greatBrain: "https://cdn.headversity.com/app/resources/headzone/great.svg",
  thrivingBrain:
    "https://cdn.headversity.com/app/resources/headzone/thriving.svg",
};

export const HeadZoneStateRanges = {
  burnOut: 20,
  stressed: 40,
  okay: 60,
  great: 80,
  thriving: 100,
};
