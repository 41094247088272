import {
  Flex,
  FlexProps,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";

export const DiscussText = (props: FlexProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  return (
    <Flex
      alignItems={"center"}
      key={HVSportLocalizeStrings.CHARTER_TAKE_SOME_TIME_TO_DISCUSS}
      fontSize={isMobile ? "14px" : "15px"}
      gap={2}
      {...props}
    >
      <DiscussIcon />
      <Text>{HVSportLocalizeStrings.CHARTER_TAKE_SOME_TIME_TO_DISCUSS}</Text>
    </Flex>
  );
};

export const DiscussIcon = () => {
  return (
    <Flex
      height={"20px"}
      width={"20px"}
      justifyContent={"center"}
      alignItems={"center"}
      mr={1}
    >
      <Image
        src={"https://cdn.headversity.com/app/sport/discussion.svg"}
        boxSize={"100%"}
        alt="Icon"
      />
    </Flex>
  );
};
