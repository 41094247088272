import { isWebPlatform } from "./mobileUtils";

export const toQueryString = (params: string[]) => {
  const firstParam = params[0];
  if (params.length == 1) {
    return `?${firstParam}`;
  }
  params.shift();
  const andParams = params.map((p) => `&${p}`).join("");
  return `?${firstParam}${andParams}`;
};

export const peekRedirectPath = () => {
  const redirectPath = "redirectPath";
  const path = sessionStorage.getItem(redirectPath) ?? "/";
  sessionStorage.removeItem(redirectPath);
  return path;
};

export const loginPath = () => (isWebPlatform() ? "/login" : "/mobileLogin");
