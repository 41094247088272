import { Box, IconButton, IconButtonProps, Image } from "@chakra-ui/react";
import { BUTTON_PRIMARY_BACKGROUND_COLOR } from "../../../Styles/HeadversityStyle";

interface ToggleButtonProps extends IconButtonProps {
  isOpen: boolean;
}

export const ToggleButton = (props: ToggleButtonProps) => {
  const { isOpen, ...iconButtonProps } = props;
  return (
    <IconButton
      position="relative"
      variant="unstyled"
      size="sm"
      color={BUTTON_PRIMARY_BACKGROUND_COLOR}
      zIndex="300"
      icon={<ToggleIcon active={isOpen} />}
      {...iconButtonProps}
    />
  );
};

const ToggleIcon = (props: { active: boolean }) => {
  const { active } = props;
  return (
    <Box
      className="group"
      data-active={active ? "" : undefined}
      as="span"
      display="block"
      pos="relative"
      aria-hidden
      pointerEvents="none"
    >
      <Image
        src={"https://cdn.headversity.com/app/resources/other/hamburger.svg"}
        w="1.25rem"
        h="1.25rem"
        alt=""
      />
    </Box>
  );
};
