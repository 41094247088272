import { LargeModal } from "../../Common/LargeModal";
import React, { useContext } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  useBreakpointValue,
  VStack,
  HStack,
} from "@chakra-ui/react";
import {
  Border_Radius,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { EVENTS, track } from "../../../Utils/Analytics";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { useNavigate } from "react-router-dom";
import { ShellContext } from "../../../State/ShellContext";

interface FirstTimeTeamInterstitialModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const FirstTimeTeamInterstitialModal = (
  props: FirstTimeTeamInterstitialModalProps
) => {
  const { open, setOpen } = props;
  const { selectedTeamLessonUrl } = useContext<IGlobalProvider>(GlobalContext);
  const { safeAreaInsets } = useContext(ShellContext);

  const isDesktop = useBreakpointValue({ base: false, md: true });
  const navigate = useNavigate();

  const handleButtonClick = () => {
    localStorage.setItem("viewed-first-time-team-interstitial-modal", "true");
    setOpen(false);
    navigate(selectedTeamLessonUrl);
    track(EVENTS.FirstTimeTeamInterstitialGoToTeam);
  };

  const handleCloseClick = () => {
    setOpen(false);
  };

  return (
    <LargeModal open={open} setOpen={handleCloseClick as any}>
      <Box mb={"87px"}>
        <Heading
          fontSize={isDesktop ? "2xl" : "lg"}
          color={MODAL_PRIMARY_TEXT_COLOR}
          textAlign={"center"}
          dangerouslySetInnerHTML={{
            __html: `${HVLocalizeStrings.FIRST_TIME_TEAM_INTERSTITIAL_HEADER}`,
          }}
        />
        <VStack gap={4} my={10} mx={2} align={"left"}>
          <FirstTimeTeamInterstitialModalText
            html={
              HVLocalizeStrings.FIRST_TIME_TEAM_INTERSTITIAL_FIRST_PARAGRAPH
            }
            color={MODAL_PRIMARY_TEXT_COLOR}
          />
          <FirstTimeTeamInterstitialModalText
            html={
              HVLocalizeStrings.FIRST_TIME_TEAM_INTERSTITIAL_SECOND_PARAGRAPH
            }
            color={MODAL_PRIMARY_TEXT_COLOR}
          />
          <UnorderedList spacing={2} color={MODAL_PRIMARY_TEXT_COLOR} pl={10}>
            <ListItem>
              <FirstTimeTeamInterstitialModalText
                html={
                  HVLocalizeStrings.FIRST_TIME_TEAM_INTERSTITIAL_FIRST_LIST_ITEM
                }
              />
            </ListItem>
            <ListItem>
              <FirstTimeTeamInterstitialModalText
                html={
                  HVLocalizeStrings.FIRST_TIME_TEAM_INTERSTITIAL_SECOND_LIST_ITEM
                }
              />
            </ListItem>
          </UnorderedList>
          <FirstTimeTeamInterstitialModalText
            html={
              HVLocalizeStrings.FIRST_TIME__TEAM_INTERSTITIAL_BOTTOM_PARAGRAPH
            }
            color={MODAL_PRIMARY_TEXT_COLOR}
          />
        </VStack>
      </Box>
      <Box
        pos={isDesktop ? "absolute" : "fixed"}
        left={0}
        bottom={0}
        width={"100%"}
        bgColor={`#ffffff${isDesktop ? "aa" : "dd"}`}
        borderBottomRadius={isDesktop ? Border_Radius : undefined}
        p={"10px"}
        pb={`${10 + safeAreaInsets.bottom}px`}
        textAlign="center"
      >
        <HStack justify={"center"}>
          <AnimatedButton
            w={150}
            text={HVLocalizeStrings.FIRST_TIME_TEAM_INTERSTITIAL_GO_TO_TEAM}
            onClick={() => handleButtonClick()}
          />
          <AnimatedButton
            w={150}
            text={HVLocalizeStrings.CLOSE}
            onClick={() => handleCloseClick()}
            colorSet={AnimatedButtonColorSet.Third}
          />
        </HStack>
      </Box>
    </LargeModal>
  );
};
export default FirstTimeTeamInterstitialModal;

interface FirstTimeTeamInterstitialModalTextProps {
  html: string;
  pl?: number;
  color?: string;
}

const FirstTimeTeamInterstitialModalText = (
  props: FirstTimeTeamInterstitialModalTextProps
) => {
  const { html, pl, color } = props;
  return (
    <Text
      pl={pl ? pl : undefined}
      fontSize={"lg"}
      color={color ? color : undefined}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
