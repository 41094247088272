import {
  Box,
  Center,
  Heading,
  Text,
} from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { OPTIMISM_THREE_GOOD_THINGS_STEP } from "./OptimismTool";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../../Testing/dataTestIds";
import { HvTimePicker } from "../../../Common/HvDateTimePicker/HvTimePicker";

interface OptimismToolStepTimeSelectionProps {
  changePage: (page: string) => void;
  reminderTime: string;
  setReminderTime: (reminderTime: string) => void;
  disableButton?: boolean;
}

export const OptimismToolStepTimeSelection = (
  props: OptimismToolStepTimeSelectionProps
) => {
  const { changePage, setReminderTime, reminderTime, disableButton } = props;

  const handleTimeUpdate = (value: any) => {
    setReminderTime(value);
  };

  return (
    <>
      <Box>
        <Heading
          text-align={"left"}
          fontSize={"2xl"}
          fontWeight={"semibold"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {HVLocalizeStrings.OPTIMISM_TOOLS_BEST_TIME}
        </Heading>
        <Text mt={"5"} fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
          {HVLocalizeStrings.OPTIMISM_TOOLS_PROVEN}
        </Text>
      </Box>

      <Center>
        <HvTimePicker onChange={handleTimeUpdate} time={reminderTime} />
      </Center>
      <Center mt={10} mb={2}>
        <AnimatedButton
          disabled={!reminderTime || disableButton || false}
          text={HVLocalizeStrings.NEXT}
          onClick={() => changePage(OPTIMISM_THREE_GOOD_THINGS_STEP)}
          dataTestId={HVTestId.OptimismTool.steps.timeSelect.nextButton}
        />
      </Center>
    </>
  );
};
