import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import {
  LocalizationText,
  LessonQuestionAnswerDto,
  MicroLessonUserInstanceDto,
} from "@headversity/contract";

export const saveQuestionAnswers = (
  token: string,
  microLessonId: number,
  lessonQuestionAnswerDto: LessonQuestionAnswerDto
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/microLesson/${microLessonId}/questionsAndAnswers`,
    lessonQuestionAnswerDto,
    getConfig(token)
  );
};

export const saveLessonSlideProgress = (
  token: string,
  lessonCourseUserInstanceId: number,
  lessonCourseUserSlideProgressDto: any
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/lessonCourse/${lessonCourseUserInstanceId}/slideProgress`,
    lessonCourseUserSlideProgressDto,
    getConfig(token)
  );
};

export const getMicroLessonInstance = (
  token: string,
  microLessonId: number
): Promise<AxiosResponse<MicroLessonUserInstanceDto>> => {
  return AxiosClient.post(
    `api/microLesson/${microLessonId}/userInstance`,
    null,
    getConfig(token)
  );
};

export const getLessonLastViewedSlide = (
  token: string,
  lessonCourseUserInstanceId: number
): Promise<AxiosResponse<LocalizationText>> => {
  return AxiosClient.get(
    `api/lessonCourse/${lessonCourseUserInstanceId}/url`,
    getConfig(token)
  );
};

export const getLessonKey = (token: string) => {
  return AxiosClient.get("/api/lessonCourse/key", getConfig(token));
};
