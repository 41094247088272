import { SetPasswordStep } from "../Guard/SetPasswordStep";
import {
  InviteCodeDomainDto,
  PasswordContainerChildComponent,
} from "@headversity/contract";
import { ResetPasswordPage } from "../Login/ResetPasswordPage";
import { RegistrationStep } from "../Login/RegistrationStep";
import { usePasswordValidation } from "../../../Hooks/People/usePasswordValidation";

interface PasswordRequirementsContainerProps {
  childComponent: PasswordContainerChildComponent;
  showTermsAndPolicyModal?: boolean;
  setError?: (error: string | null) => void;
  inviteCodeDomains?: InviteCodeDomainDto[];
}

const PasswordRequirementsContainer = ({
  childComponent,
  showTermsAndPolicyModal,
  setError,
  inviteCodeDomains,
}: PasswordRequirementsContainerProps) => {
  const {
    isValidPassword,
    pwdHasNumber,
    pwdHasLowerCase,
    pwdHasUpperCase,
    pwdHasMinLength,
    validatePassword,
    resetPasswordValidation,
  } = usePasswordValidation();

  switch (childComponent) {
    case PasswordContainerChildComponent.SetPasswordStep:
      return (
        <SetPasswordStep
          showTermsAndPolicyModal={showTermsAndPolicyModal}
          isValidPassword={isValidPassword}
          pwdHasNumber={pwdHasNumber}
          pwdHasLowerCase={pwdHasLowerCase}
          pwdHasUpperCase={pwdHasUpperCase}
          pwdHasMinLength={pwdHasMinLength}
          resetPasswordValidation={resetPasswordValidation}
          validatePassword={validatePassword}
        />
      );
    case PasswordContainerChildComponent.ResetPasswordPage:
      return (
        <ResetPasswordPage
          pwdHasNumber={pwdHasNumber}
          pwdHasLowerCase={pwdHasLowerCase}
          pwdHasUpperCase={pwdHasUpperCase}
          pwdHasMinLength={pwdHasMinLength}
          isValidPassword={isValidPassword}
          validatePassword={validatePassword}
          resetPasswordValidation={resetPasswordValidation}
        />
      );
    case PasswordContainerChildComponent.RegistrationStep:
      if (setError !== undefined && inviteCodeDomains !== undefined) {
        return (
          <RegistrationStep
            setError={setError}
            pwdHasNumber={pwdHasNumber}
            pwdHasLowerCase={pwdHasLowerCase}
            pwdHasUpperCase={pwdHasUpperCase}
            pwdHasMinLength={pwdHasMinLength}
            isValidPassword={isValidPassword}
            validatePassword={validatePassword}
            resetPasswordValidation={resetPasswordValidation}
            inviteCodeDomains={inviteCodeDomains}
          />
        );
      }
      return <></>;
    default:
      return <></>;
  }
};

export default PasswordRequirementsContainer;
