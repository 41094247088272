import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Box, Center, Text, Image, useBreakpointValue } from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { BLOCK_SECTION_SECONDARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { useContext } from "react";
import {
  ReachGoalsContext,
  ReachGoalsScreen,
} from "../../../State/Reach/ReachGoalsContext";

export const ReachGoalsInitialScreen = () => {
  const goalsContext = useContext(ReachGoalsContext);

  const { setScreen } = goalsContext;
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <>
      <Box color={BLOCK_SECTION_SECONDARY_TEXT_COLOR} mb={5}>
        <Text fontSize={!isDesktop ? "lg" : "1.6rem"} fontWeight={600} mb={2}>
          {HVLocalizeStrings.REACH_GOALS_INITIAL_SCREEN_TITLE}
        </Text>
        <Text>{HVLocalizeStrings.REACH_GOALS_INITIAL_SCREEN_DESCRIPTION}</Text>
      </Box>
      <Box>
        <Center>
          <Image
            src={HVLocalizeStrings.REACH_GOALS_INITIAL_SCREEN_IMAGE}
            w={isDesktop ? "66%" : "100%"}
          />
        </Center>
      </Box>
      <Box mt={4}>
        <Center>
          <AnimatedButton
            colorSet={AnimatedButtonColorSet.Fourth}
            text={HVLocalizeStrings.CONTINUE}
            onClick={() => {
              setScreen(ReachGoalsScreen.SELECTION);
            }}
            mr="5px"
            boxShadow={"lg"}
            w={"auto"}
          />
        </Center>
      </Box>
    </>
  );
};
