import React, { useCallback, useContext, useEffect, useState } from "react";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import { Box, Center, Flex, Image, Text, VStack } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Network } from "@capacitor/network";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";

export const NoInternetConnectionError = () => {
  const { isOnline, setIsOnline } = useContext<IGlobalProvider>(GlobalContext);

  useEffect(() => {
    if (!isWebPlatform()) {
      // Listen to the network status
      Network.addListener("networkStatusChange", (status) => {
        setIsOnline(status.connected);
      });

      //For the first time it runs, need to set the network status correctly, since the event listener is only triggered if there is a change
      Network.getStatus().then((status) => {
        setIsOnline(status.connected);
      });

      //Update the network status regularly
      const interval = setInterval(() => {
        Network.getStatus();
        clearInterval(interval);
      }, 1000);

      // Clean up for performance reasons
      return () => {
        Network.removeAllListeners();
      };
    }
  }, []);

  return (
    <>
      {isOnline === false && (
        <Flex
          h={"100%"}
          w={"100%"}
          top={"0px"}
          zIndex={9999}
          align={"center"}
          alignItems={"center"}
          justifyContent={"center"}
          bgColor="white"
          position={"fixed"}
        >
          <Box maxW={300}>
            <Image
              src={
                "https://cdn.headversity.com/app/resources/other/blue-main-logo.png"
              }
              alt=""
            />
            <Text mt={4}>
              {HVLocalizeStrings.SOLO_HOME_INTERNET_CONNECTION_ERROR}
            </Text>
          </Box>
        </Flex>
      )}
    </>
  );
};
