import { createContext, useCallback } from "react";
import {
  deleteFocusPlan,
  saveFocusPlan,
  updateFocusPlan,
} from "../../Api/Solo/FocusApi";
import { getKey } from "../../Utils/Helpers";
import { useFocusToolStat } from "../../Hooks/Solo/useFocusToolStat";
import { FocusToolInfoDto, FocusToolInfoInputDto } from "@headversity/contract";

export interface IFocusToolProvider {
  focusPlanItems: FocusToolInfoDto[] | null;
  getFocusPlanItemsFromServer: () => Promise<void>;
  saveFocusPlanItemsToServer: (
    focusPlan: FocusToolInfoInputDto
  ) => Promise<void>;
  deleteFocusPlanItemsFromServer: (id: number) => Promise<void>;
  focusPlanItemsLoaded: boolean;
  updateFocusPlanToServer: (focusPlan: FocusToolInfoDto) => Promise<void>;
}

export const FocusToolContext = createContext<IFocusToolProvider>({} as any);

export const FocusToolProvider = (props: any) => {
  const {
    focusPlanItems,
    getFocusPlanItemsFromServer,
    focusPlanItemsLoaded,
    setFocusPlanItemsLoaded,
  } = useFocusToolStat();

  const saveFocusPlanItemsToServer = useCallback(
    async (focusPlan: FocusToolInfoInputDto) => {
      return saveFocusPlan(getKey(), focusPlan).then((response) => {
        getFocusPlanItemsFromServer();
      });
    },
    []
  );

  const deleteFocusPlanItemsFromServer = useCallback(async (id: number) => {
    setFocusPlanItemsLoaded(false);
    return deleteFocusPlan(getKey(), id).then((response) => {
      getFocusPlanItemsFromServer();
    });
  }, []);

  const updateFocusPlanToServer = useCallback(
    async (focusPlan: FocusToolInfoDto) => {
      return updateFocusPlan(getKey(), focusPlan).then((response) => {
        getFocusPlanItemsFromServer();
      });
    },
    []
  );

  return (
    <FocusToolContext.Provider
      value={{
        focusPlanItems,
        getFocusPlanItemsFromServer,
        saveFocusPlanItemsToServer,
        deleteFocusPlanItemsFromServer,
        focusPlanItemsLoaded,
        updateFocusPlanToServer,
      }}
    >
      {props.children}
    </FocusToolContext.Provider>
  );
};
