import { Box, Flex, Text, Image } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

export interface QualificationCompleteCardProps {
  title: string;
}

export const QualificationCompleteCard = (
  props: QualificationCompleteCardProps
) => {
  const { title } = props;
  return (
    <Flex
      align={"center"}
      flexDir={"column"}
      gap={2}
      mt={"5px"}
      pb={"20px"}
      fontSize={"md"}
      fontWeight={"600"}
    >
      <Text fontSize={"16px"} align={"center"}>
        {title}
      </Text>

      <Box fontWeight={"normal"} flexDir={"row"}>
        <Text fontSize={"16px"} align={"center"} mt={"5"}>
          {HVLocalizeStrings.CONGRATS_ON_STAYING_QUALIFIED}
        </Text>
        <Text fontSize={"14px"} align={"center"} mt={"3"}>
          {HVLocalizeStrings.GREAT_JOB_STAYING_QUALIFIED}
        </Text>
      </Box>
      <Box alignItems={"center"} alignContent={"center"}>
        <Image
          src={`https://cdn.headversity.com/app/cert/checkmark-450x450.png`}
          w={"150px"}
        />
      </Box>
    </Flex>
  );
};
