import { Flex, Icon, Text } from "@chakra-ui/react";
import { BiWalk } from "react-icons/bi";
import {
  MODAL_PRIMARY_TEXT_COLOR,
  UPNEXT_ICON_COLOR,
} from "../../../Styles/HeadversityStyle";

const withUpNextPresentation = (Component: any) => {
  return (props: any) => {
    const { titleText } = props;
    return (
      <Flex flexDir={"column"} gap={"5"} mt={"5"} w={"100%"}>
        <Flex>
          <Icon as={BiWalk} boxSize={"6"} color={UPNEXT_ICON_COLOR} />
          <Text color={MODAL_PRIMARY_TEXT_COLOR}>{titleText}</Text>
        </Flex>
        <Flex flexDir={"column"} gap={3}>
          <Component {...props} />
        </Flex>
      </Flex>
    );
  };
};

export default withUpNextPresentation;
