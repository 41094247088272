import {
  Box,
  Center,
  Flex,
  Image,
  Text,
  useBreakpointValue,
  VStack,
  Heading,
} from "@chakra-ui/react";
import {
  Border_Radius,
  Equity_Blue,
  Equity_Orange,
} from "../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HVTestId } from "../../../Testing/dataTestIds";
import { isWebPlatform } from "../../../Utils/mobileUtils";

interface LandingContainerProps {
  children: any;
  other?: any;
  showHierarchySelection?: boolean;
}
export const LandingContainer = (props: LandingContainerProps) => {
  const { children, other, showHierarchySelection } = props;

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
  });
  return (
    <Box
      w={"full"}
      h={"full"}
      pos={"fixed"}
      overflow={"auto"}
      backgroundImage={
        isMobile
          ? "https://cdn.headversity.com/app/resources/login/LoginMobileBg.jpg"
          : "https://cdn.headversity.com/app/resources/login/LoginBg.jpg"
      }
      backgroundSize={"cover"}
      top={0}
      left={0}
      zIndex={1000}
    >
      {/*
        login pages run in a browser on iOS/Android app, so we can't use isWebPlatform() and we can't detect the safe area;
        50px handles all possible top insets and looks ok with no inset
      */}
      <Center
        pt={!isMobile ? 3 : "50px"}
        data-testid={HVTestId.LandingContainer.logo}
      >
        <Image
          src={
            "https://cdn.headversity.com/app/resources/other/headversityBlueLogo.png"
          }
          h={"50px"}
          alt={HVLocalizeStrings.HEADVERSITY}
        />
      </Center>

      <Flex
        flexDir={"column"}
        ml={isMobile ? undefined : "10%"}
        mt={isMobile ? "0px" : "60px"}
        mb={"20px"}
        w={isMobile ? "100%" : showHierarchySelection ? "700px" : "450px"}
        px={10}
        py={9}
        background={isMobile ? undefined : "#FFFFFF88"}
        boxShadow={isMobile ? undefined : "lg"}
        borderRadius={Border_Radius}
      >
        {!showHierarchySelection && (
          <VStack mb={isMobile ? 10 : 8}>
            <Heading
              display={"flex"}
              flexDir={"column"}
              alignItems={"center"}
              color={Equity_Blue}
              fontSize={isMobile ? "2.5rem" : "3.5rem"}
              fontWeight={"semibold"}
              lineHeight={isMobile ? "1.2" : "1.15"}
              as={"h1"}
            >
              <Text as={"span"} align={"center"}>
                {HVLocalizeStrings.LOGIN_PAGE_TITLE}
              </Text>
              <Text
                borderBottom={`solid 2px ${Equity_Orange}`}
                width={"max-content"}
                pb={1}
                as={"span"}
              >
                {HVLocalizeStrings.LOGIN_PAGE_TITLE_ADVERSITY}
              </Text>
            </Heading>
          </VStack>
        )}
        <Box w="100%">{children}</Box>
      </Flex>

      {other}
    </Box>
  );
};
