import { Box, Divider } from "@chakra-ui/react";
import { SchedulerDto } from "@headversity/contract";
import dayjs from "dayjs";
import { Equity_Blue } from "../../../Styles/HeadversityStyle";
import { getStreakInfo, StreakDetails } from "../../../Utils/SkillsUtil";
import { BlockSection } from "../../Common/BlockSection";
import { WelcomeModalSubheader } from "../../Shared/Welcome/WelcomeModalSubheader";
import { StreakInfo } from "./StreakInfo";

const dailyReminder = { scheduleExpression: "x ?" } as SchedulerDto;
const weeklyReminder = { scheduleExpression: "x" } as SchedulerDto;
const monthlyReminder = { scheduleExpression: "x ||MONTHLY||" } as SchedulerDto;

const getCompletions = (includeToday: boolean, len: number, period: any) => {
  const today = dayjs.utc();

  const activityCompletions: dayjs.Dayjs[] = [];

  if (includeToday) {
    activityCompletions.push(today);
  }

  for (let i = 1; i <= len; i++) {
    activityCompletions.push(today.subtract(i, period));
  }

  return activityCompletions;
};

export const StreakInfoTestHarness = () => {
  const today = dayjs.utc();

  const noCompletions: dayjs.Dayjs[] = [];

  const result0 = getStreakInfo(noCompletions);

  const result1 = getStreakInfo(getCompletions(true, 0, "day"), [
    dailyReminder,
  ]);

  const result1b = getStreakInfo(getCompletions(false, 1, "day"), [
    dailyReminder,
  ]);

  const result2a = getStreakInfo(getCompletions(false, 2, "day"), [
    dailyReminder,
  ]);

  const result2b = getStreakInfo(getCompletions(true, 2, "day"), [
    dailyReminder,
  ]);

  const result3 = getStreakInfo(getCompletions(true, 4, "day"), [
    dailyReminder,
  ]);

  const result4a = getStreakInfo(getCompletions(true, 6, "day"), [
    dailyReminder,
  ]);

  const result4b = getStreakInfo(getCompletions(true, 17, "day"), [
    dailyReminder,
  ]);

  const result5 = getStreakInfo(noCompletions, [weeklyReminder]);

  const result6 = getStreakInfo([today], [weeklyReminder]);

  const result7a = getStreakInfo(getCompletions(false, 1, "week"), [
    weeklyReminder,
  ]);

  const result7b = getStreakInfo(getCompletions(true, 1, "week"), [
    weeklyReminder,
  ]);

  const result8a = getStreakInfo(getCompletions(false, 3, "week"), [
    weeklyReminder,
  ]);

  const result8b = getStreakInfo(getCompletions(true, 3, "week"), [
    weeklyReminder,
  ]);

  const result9 = getStreakInfo(getCompletions(true, 4, "week"), [
    weeklyReminder,
  ]);

  const result10 = getStreakInfo(getCompletions(true, 11, "week"), [
    weeklyReminder,
  ]);

  const result11 = getStreakInfo(noCompletions, [monthlyReminder]);

  const result12 = getStreakInfo(getCompletions(true, 0, "month"), [
    monthlyReminder,
  ]);

  const result13 = getStreakInfo(getCompletions(false, 1, "month"), [
    monthlyReminder,
  ]);

  const result14 = getStreakInfo(getCompletions(true, 2, "month"), [
    monthlyReminder,
  ]);

  const result15 = getStreakInfo(getCompletions(true, 10, "month"), [
    monthlyReminder,
  ]);

  const result16 = getStreakInfo(getCompletions(true, 11, "month"), [
    monthlyReminder,
  ]);

  const result17 = getStreakInfo(getCompletions(true, 23, "month"), [
    monthlyReminder,
  ]);

  const result18 = getStreakInfo(getCompletions(true, 30, "month"), [
    monthlyReminder,
  ]);

  return (
    <BlockSection fadeIn={true} title={"Streak"} columns={1} rows={1}>
      <Box bgColor={Equity_Blue} p={2}>
        <StreakTestBlock
          description="No challenge, no streak"
          streak={result0}
          reminder={null}
        />
        <StreakTestBlock
          description="Daily challenge, no streak"
          streak={result0}
          reminder={dailyReminder}
        />
        <StreakTestBlock
          description="Daily challenge, 1 day streak"
          streak={result1}
          reminder={dailyReminder}
        />
        <StreakTestBlock
          description="Daily challenge, 2 day incomplete streak"
          streak={result1b}
          reminder={dailyReminder}
        />
        <StreakTestBlock
          description="Daily challenge, 3 day incomplete streak"
          streak={result2a}
          reminder={dailyReminder}
        />
        <StreakTestBlock
          description="Daily challenge, 3 day complete streak"
          streak={result2b}
          reminder={dailyReminder}
        />
        <StreakTestBlock
          description="Daily challenge, 5 day complete streak"
          streak={result3}
          reminder={dailyReminder}
        />
        <StreakTestBlock
          description="Daily challenge, 7 day complete streak"
          streak={result4a}
          reminder={dailyReminder}
        />
        <StreakTestBlock
          description="Daily challenge, 18 day complete streak"
          streak={result4b}
          reminder={dailyReminder}
        />
        <Divider mt={8} />
        <Divider mt={2} mb={8} />
        <StreakTestBlock
          description="Weekly challenge, no streak"
          streak={result5}
          reminder={weeklyReminder}
        />
        <StreakTestBlock
          description="Weekly challenge, 1 week complete streak"
          streak={result6}
          reminder={weeklyReminder}
        />
        <StreakTestBlock
          description="Weekly challenge, 2 week incomplete streak"
          streak={result7a}
          reminder={weeklyReminder}
        />
        <StreakTestBlock
          description="Weekly challenge, 2 week complete streak"
          streak={result7b}
          reminder={weeklyReminder}
        />
        <StreakTestBlock
          description="Weekly challenge, 4 week incomplete streak"
          streak={result8a}
          reminder={weeklyReminder}
        />
        <StreakTestBlock
          description="Weekly challenge, 4 week complete streak"
          streak={result8b}
          reminder={weeklyReminder}
        />
        <StreakTestBlock
          description="Weekly challenge, 5 week complete streak"
          streak={result9}
          reminder={weeklyReminder}
        />
        <StreakTestBlock
          description="Weekly challenge, 12 week complete streak"
          streak={result10}
          reminder={weeklyReminder}
        />
        <Divider mt={8} />
        <Divider mt={2} mb={8} />
        <StreakTestBlock
          description="Monthly challenge, no streak"
          streak={result11}
          reminder={monthlyReminder}
        />
        <StreakTestBlock
          description="Monthly challenge, 1 month complete streak"
          streak={result12}
          reminder={monthlyReminder}
        />
        <StreakTestBlock
          description="Monthly challenge, 2 month incomplete streak"
          streak={result13}
          reminder={monthlyReminder}
        />
        <StreakTestBlock
          description="Monthly challenge, 3 month streak"
          streak={result14}
          reminder={monthlyReminder}
        />
        <StreakTestBlock
          description="Monthly challenge, 11 month streak"
          streak={result15}
          reminder={monthlyReminder}
        />
        <StreakTestBlock
          description="Monthly challenge, 12 month streak"
          streak={result16}
          reminder={monthlyReminder}
        />
        <StreakTestBlock
          description="Monthly challenge, 24 month streak"
          streak={result17}
          reminder={monthlyReminder}
        />
        <StreakTestBlock
          description="Monthly challenge, 31 month streak"
          streak={result18}
          reminder={monthlyReminder}
        />
      </Box>
    </BlockSection>
  );
};

interface StreakTestBlockProps {
  description: string;
  streak: StreakDetails;
  reminder: SchedulerDto | null;
}
const StreakTestBlock = ({
  description,
  streak,
  reminder,
}: StreakTestBlockProps) => {
  return (
    <Box mb="50px">
      <WelcomeModalSubheader>{description}</WelcomeModalSubheader>
      <Divider my={4} mb={10} />
      <StreakInfo streak={streak} reminder={reminder} />
    </Box>
  );
};
