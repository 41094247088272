import React, { useContext, useEffect, useState } from "react";
import { OptimismToolStepIntro } from "./OptimismToolStepIntro";
import { OptimismToolStepLengthSelection } from "./OptimismToolStepLengthSelection";
import { OptimismToolStepTimeSelection } from "./OptimismToolStepTimeSelection";
import { OptimismToolStepThreeGoodThings } from "./OptimismToolStepThreeGoodThings";
import { OptimismToolStepMadePossible } from "./OptimismToolStepMadePossible";
import { OptimismToolStepOutro } from "./OptimismToolStepOutro";
import {
  IOptimismToolProvider,
  OptimismToolContext,
} from "../../../../State/Solo/OptimismToolContext";
import dayjs from "dayjs";
import { OptimismToolStepLogs } from "./OptimismToolStepLogs";
import { getCurrentChallenge, getDayNumber } from "./Utils/OptimismUtils";
import { StepPanel } from "../../../Common/StepPanel";
import { EVENTS, track } from "../../../../Utils/Analytics";
import { isAndroid } from "../../../../Utils/mobileUtils";
import { OptimismPlanDto } from "@headversity/contract/Dto/OptimismPlanDto";
import { OptimismPlanChallengeDto } from "@headversity/contract";

interface OptimismToolProps {
  setOpen: (open: boolean) => void;
  step?: string;
}

export const OPTIMISM_INTRO_STEP = "intro";
export const OPTIMISM_LENGTH_SELECTION_STEP = "challengeLength";
export const OPTIMISM_TIME_SELECTION_STEP = "timeSelect";
export const OPTIMISM_THREE_GOOD_THINGS_STEP = "threeGoodThings";
export const OPTIMISM_MADE_POSSIBLE_STEP = "madePossibleInput";
export const OPTIMISM_OUTRO_STEP = "outro";
export const OPTIMISM_LOGS_STEP = "logs";

const OptimismToolStepsValue: any = {
  [OPTIMISM_INTRO_STEP]: {
    showProgressBar: false,
    step: 0,
    value: 0,
    previousStep: "",
    showBackArrow: false,
  },
  [OPTIMISM_LOGS_STEP]: {
    showProgressBar: false,
    step: 0,
    value: 0,
    previousStep: "",
    showBackArrow: false,
  },
  [OPTIMISM_LENGTH_SELECTION_STEP]: {
    showProgressBar: true,
    step: 1,
    value: 50,
    previousStep: OPTIMISM_INTRO_STEP,
    showBackArrow: true,
  },
  [OPTIMISM_TIME_SELECTION_STEP]: {
    showProgressBar: true,
    step: 2,
    value: 100,
    previousStep: OPTIMISM_LENGTH_SELECTION_STEP,
    showBackArrow: true,
  },
  [OPTIMISM_THREE_GOOD_THINGS_STEP]: {
    showProgressBar: true,
    step: 1,
    value: 50,
    previousStep: "",
    showBackArrow: false,
  },
  [OPTIMISM_MADE_POSSIBLE_STEP]: {
    showProgressBar: true,
    step: 2,
    value: 100,
    previousStep: OPTIMISM_THREE_GOOD_THINGS_STEP,
    showBackArrow: true,
  },
  [OPTIMISM_OUTRO_STEP]: {
    showProgressBar: false,
    step: 0,
    value: 100,
    previousStep: "",
    showBackArrow: false,
  },
};

export const OptimismTool = (props: OptimismToolProps) => {
  const { setOpen, step } = props;
  const [currentPage, setCurrentPage] = useState("");

  const {
    saveOptimismPlanToServer,
    saveOptimismChallengeToServer,
    currentPlannedItem,
  } = useContext<IOptimismToolProvider>(OptimismToolContext);

  const [currentPlan, setCurrentPlan] = useState<OptimismPlanDto | null>(null);
  const [currentChallenge, setCurrentChallenge] =
    useState<OptimismPlanChallengeDto | null>(null);

  const [lengthOfChallenge, setLengthOfChallenge] = useState<number>(0);
  const [reminderTime, setReminderTime] = useState<string>("08:00");
  const [firstGoodThing, setFirstGoodThing] = useState<string>("");
  const [secondGoodThing, setSecondGoodThing] = useState<string>("");
  const [thirdGoodThing, setThirdGoodThing] = useState<string>("");
  const [firstGoodThingCont, setFirstGoodThingCont] = useState<string>("");
  const [secondGoodThingCont, setSecondGoodThingCont] = useState<string>("");
  const [thirdGoodThingCont, setThirdGoodThingCont] = useState<string>("");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (step) {
      setCurrentPage(step);
    }
  }, [step]);

  const changePage = (newPage: string) => {
    track(EVENTS.ToolOptimismReachedStep, { HV_Step: newPage });

    setCurrentPage(newPage);
  };

  const formatHourMinutes = (rawTime: string) => {
    const date = dayjs.utc(rawTime).local();
    const hours = date.hour();
    const minutes = date.minute();
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    setCurrentPlan(currentPlannedItem);
    if (currentPlannedItem) {
      setLengthOfChallenge(currentPlannedItem.numberOfDays);
      setReminderTime(formatHourMinutes(currentPlannedItem.startTime));
      const challenge = getCurrentChallenge(currentPlannedItem, selectedDay);
      if (challenge) {
        setCurrentChallenge(challenge);
      } else {
        const dayNumber = selectedDay ?? getDayNumber(currentPlannedItem);
        setCurrentChallenge({
          optimismPlanId: currentPlannedItem.id,
          dayNumber: dayNumber,
          userInput: {
            firstThing: firstGoodThing,
            firstThingDescription: firstGoodThingCont,
            secondThing: secondGoodThing,
            secondThingDescription: secondGoodThingCont,
            thirdThing: thirdGoodThing,
            thirdThingDescription: thirdGoodThingCont,
          },
        });
      }
    }
  }, [
    selectedDay,
    currentPlannedItem,
    firstGoodThing,
    firstGoodThingCont,
    secondGoodThing,
    secondGoodThingCont,
    thirdGoodThing,
    thirdGoodThingCont,
  ]);

  useEffect(() => {
    if (currentPlan) {
      const now = dayjs();

      setLengthOfChallenge(currentPlan.numberOfDays);
      setReminderTime(formatHourMinutes(currentPlan.startTime));
      for (let i = 0; i < currentPlan?.optimismPlanChallenges?.length; i++) {
        const challengeDate = dayjs.utc(currentPlan?.startTime).local();
        if (
          now.year() === challengeDate.year() &&
          now.month() === challengeDate.month() &&
          now.date() === challengeDate.date()
        ) {
          setCurrentChallenge(getCurrentChallenge(currentPlan));
          break;
        }
      }
    } else {
      setLengthOfChallenge(0);
      setReminderTime("08:00");
    }
  }, [currentPlan]);

  let content = <></>;
  let noMarginTop = false;
  switch (currentPage) {
    default:
    case OPTIMISM_INTRO_STEP:
      content = <OptimismToolStepIntro changePage={changePage} />;
      break;
    case OPTIMISM_LENGTH_SELECTION_STEP:
      content = (
        <OptimismToolStepLengthSelection
          changePage={changePage}
          setLengthOfChallenge={setLengthOfChallenge}
          lengthOfChallenge={lengthOfChallenge}
        />
      );
      break;
    case OPTIMISM_TIME_SELECTION_STEP:
      content = (
        <OptimismToolStepTimeSelection
          reminderTime={reminderTime}
          disableButton={disableButton}
          setReminderTime={setReminderTime}
          changePage={(newPage: any) => {
            let plan: any = {};
            let changingTime = false;

            let submitTime = reminderTime;
            const parts = reminderTime?.split(":");

            if (parts) {
              submitTime = plan.startTime
                ? dayjs
                    .utc(plan.startTime)
                    .local()
                    .set("hour", parseInt(parts[0]))
                    .set("minute", parseInt(parts[1]))
                    .utc()
                    .format("YYYY-MM-DDTHH:mm")
                : dayjs()
                    .set("hour", parseInt(parts[0]))
                    .set("minute", parseInt(parts[1]))
                    .utc()
                    .format("YYYY-MM-DDTHH:mm");
            }

            if (currentPlan !== null) {
              plan = currentPlan;
              plan.time = submitTime;
              changingTime = true;
            } else {
              plan.quantityOfDays = lengthOfChallenge;
              plan.time = submitTime;
              plan.status = "pending";
            }
            setDisableButton(true);
            const endDate = dayjs
              .utc(submitTime)
              .add(lengthOfChallenge, "days");
            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
            saveOptimismPlanToServer({
              startTime: submitTime as string,
              numberOfDays: lengthOfChallenge,
              status: 0,
              timeZone: timeZone,
              scheduler: {
                frequency: 1,
                repeat: true,
                start: submitTime as string,
                end: endDate.format("YYYY-MM-DDTHH:mm"),
                timeZone: timeZone,
              },
            })
              .then(() => {
                if (changingTime) {
                  setOpen(false);
                } else {
                  changePage(newPage);
                }
              })
              .then(() => setDisableButton(false));
          }}
        />
      );
      break;
    case OPTIMISM_THREE_GOOD_THINGS_STEP:
      content = (
        <OptimismToolStepThreeGoodThings
          firstGoodThing={firstGoodThing}
          secondGoodThing={secondGoodThing}
          thirdGoodThing={thirdGoodThing}
          setFirstGoodThing={setFirstGoodThing}
          setSecondGoodThing={setSecondGoodThing}
          setThirdGoodThing={setThirdGoodThing}
          currentDay={
            selectedDay ? selectedDay : currentChallenge?.dayNumber ?? 0
          }
          challengeLength={lengthOfChallenge}
          changePage={changePage}
        />
      );
      break;
    case OPTIMISM_MADE_POSSIBLE_STEP:
      content = (
        <OptimismToolStepMadePossible
          firstGoodThing={firstGoodThing}
          secondGoodThing={secondGoodThing}
          thirdGoodThing={thirdGoodThing}
          firstGoodThingCont={firstGoodThingCont}
          secondGoodThingCont={secondGoodThingCont}
          thirdGoodThingCont={thirdGoodThingCont}
          setFirstGoodThingCont={setFirstGoodThingCont}
          setSecondGoodThingCont={setSecondGoodThingCont}
          setThirdGoodThingCont={setThirdGoodThingCont}
          disableButton={disableButton}
          changePage={(page: any) => {
            setDisableButton(true);
            if (currentChallenge) {
              if (selectedDay) {
                currentChallenge.dayNumber = selectedDay;
              }
              saveOptimismChallengeToServer(currentChallenge)
                .then(() => {
                  setSelectedDay(undefined);
                  changePage(page);
                })
                .then(() => setDisableButton(false));
            }
          }}
          currentDay={
            selectedDay ? selectedDay : currentChallenge?.dayNumber ?? 0
          }
          challengeLength={lengthOfChallenge}
        ></OptimismToolStepMadePossible>
      );
      break;
    case OPTIMISM_OUTRO_STEP:
      content = <OptimismToolStepOutro setOpen={setOpen} />;
      break;
    case OPTIMISM_LOGS_STEP:
      content = (
        <OptimismToolStepLogs
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          changePage={changePage}
          setCurrentChallenge={setCurrentChallenge}
        ></OptimismToolStepLogs>
      );
      noMarginTop = true;
      break;
  }
  return (
    <StepPanel
      value={OptimismToolStepsValue[currentPage]?.value}
      step={OptimismToolStepsValue[currentPage]?.step}
      totalStep={2}
      showProgressBar={OptimismToolStepsValue[currentPage]?.showProgressBar}
      showBackArrow={OptimismToolStepsValue[currentPage]?.showBackArrow}
      onBackClick={() => {
        changePage(OptimismToolStepsValue[currentPage]?.previousStep);
      }}
      noMarginTop={noMarginTop}
      paddingBottom={isAndroid() ? `${window.screen.height / 2}px` : undefined}
    >
      {content}
    </StepPanel>
  );
};
