import { Flex, Spinner, Text } from "@chakra-ui/react";
import { LandingContainer } from "../Shared/LandingContainer";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { unsubscribeEmail } from "../../../Api/People/RegistrationApi";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

interface UnsubscribeEmailPageProps {}

export const UnsubscribeEmailPage = (props: UnsubscribeEmailPageProps) => {
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code") as string;
  const token = searchParams.get("token") as string;
  const notificationId = searchParams.get("notificationId") as string;
  const email = searchParams.get("email") as string;
  const lang = searchParams.get("lang") as string;
  useEffect(() => {
    unsubscribeEmail({ code, token, notificationId, email }).then((res) => {
      setUnsubscribeComplete(true);
    });
    HVLocalizeStrings.setLanguage(lang);
  }, []);

  const [unsubscribeComplete, setUnsubscribeComplete] =
    useState<boolean>(false);
  const descriptionText = HVLocalizeStrings.UNSUBSCRIBE_EMAIL_DESCRIPTION_1;
  return (
    <LandingContainer other={<></>}>
      {!unsubscribeComplete && (
        <Flex height="100%" alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {unsubscribeComplete && (
        <Flex flexDir="column" alignItems={"center"} justifyContent={"center"}>
          <Text mb={2} textAlign={"center"} fontWeight="semibold">
            {HVLocalizeStrings.UNSUBSCRIBE_EMAIL_TITLE}
          </Text>
          <Text
            m={2}
            textAlign={"center"}
            dangerouslySetInnerHTML={{ __html: descriptionText }}
          />
        </Flex>
      )}
    </LandingContainer>
  );
};
