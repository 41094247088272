import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import { ContentDto } from "@headversity/contract";

export const getContent = (
  token: string
): Promise<AxiosResponse<ContentDto>> => {
  return AxiosClient.get("api/content", getConfig(token));
};
