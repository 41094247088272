import {
  Box,
  Flex,
  Heading,
  Text,
  AspectRatio,
  Spacer,
} from "@chakra-ui/react";
import {
  CARD_BACKGROUND_COLOR,
  CONTRAST_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
  BLOCK_TITLE_GRADIENT,
  BUTTON_SECONDARY_BACKGROUND_COLOR,
} from "../../Styles/HeadversityStyle";
import { FiMoreHorizontal } from "react-icons/fi";
import { truncate } from "../../Utils/Helpers";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { getAssetTypeName, getAssetTypeIcon } from "../../Utils/ReachUtils";
import { PromotionalMaterialDto } from "@headversity/contract";
import { HVTestId } from "../../Testing/dataTestIds";

interface ReachPromoItemProps {
  reachPromoItem: PromotionalMaterialDto;
}

export const ReachPromoMaterialCard = (props: ReachPromoItemProps) => {
  const { reachPromoItem } = props;

  return (
    <Box
      bgColor={CARD_BACKGROUND_COLOR}
      borderRadius={"5px"}
      boxShadow={"lg"}
      overflow={"hidden"}
      cursor="pointer"
      data-testid={HVTestId.ReachPromoMaterialCard.card}
    >
      <Box pos={"relative"}>
        <Box
          zIndex={10}
          pos={"absolute"}
          w={"full"}
          h={12}
          bg={
            reachPromoItem.isNew
              ? BLOCK_TITLE_GRADIENT
              : reachPromoItem.custom
              ? BUTTON_SECONDARY_BACKGROUND_COLOR
              : "transparent"
          }
          opacity={reachPromoItem.isNew || reachPromoItem.custom ? 0.85 : 0}
        ></Box>
        <Box
          zIndex={10}
          pos={"absolute"}
          w={"full"}
          h={12}
          textAlign={"center"}
          py={"3"}
          opacity={reachPromoItem.isNew || reachPromoItem.custom ? 1 : 0}
        >
          <Text
            textTransform={"uppercase"}
            color={
              reachPromoItem.isNew ? CONTRAST_TEXT_COLOR : PRIMARY_TEXT_COLOR
            }
            fontWeight={"600"}
            letterSpacing={"1.5px"}
          >
            {reachPromoItem.isNew
              ? HVLocalizeStrings.REACH_NEW
              : reachPromoItem.custom
              ? HVLocalizeStrings.REACH_CUSTOM
              : ""}
          </Text>
        </Box>
        <AspectRatio ratio={16 / 9}>
          <Box
            bgImage={reachPromoItem.imageUrl as string}
            bgSize="cover"
            bgRepeat={"no-repeat"}
            bgPos="top"
            w={"100%"}
            data-testid={HVTestId.ReachPromoMaterialCard.thumbnail}
          ></Box>
        </AspectRatio>
      </Box>
      <Box pt={"4"} px={"4"} pb={"3"}>
        <Heading
          color={PRIMARY_TEXT_COLOR}
          fontSize={"16px"}
          textAlign={"left"}
          whiteSpace={"normal"}
          mb={"1"}
          data-testid={HVTestId.ReachPromoMaterialCard.name}
          as="div"
        >
          {truncate(reachPromoItem.name as string, 25)}
        </Heading>
        <Flex w={"full"} flexDir={"row"} align={"center"}>
          <Box>
            <Text
              fontSize="sm"
              data-testid={HVTestId.ReachPromoMaterialCard.assetType}
            >
              {getAssetTypeName(reachPromoItem.assetType)}
            </Text>
          </Box>
          <Spacer />
          <Box mt={-1} fontSize={20}>
            {getAssetTypeIcon(reachPromoItem.assetType) ?? <FiMoreHorizontal />}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
