import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import {
  Border_Radius,
  CARD_BACKGROUND_COLOR,
} from "../../../Styles/HeadversityStyle";
import SlidingCardHeader from "../../Common/Cards/SlidingCard/SlidingCardHeader";

const SPACING = "6";

interface ToolLandingCardProps {
  toolImage: string;
  toolStatContent: any;
}

export const ToolLandingCard = (props: ToolLandingCardProps) => {
  const { toolImage, toolStatContent } = props;
  return (
    <Flex
      flexDir={"column"}
      borderRadius={Border_Radius}
      boxShadow={"0px 8px 8px 0px rgb(10 50 150 / 15%)"}
      bg={CARD_BACKGROUND_COLOR}
    >
      <SlidingCardHeader bgImage={toolImage} bgPos={"0%-30px"} />
      <Box p={SPACING}>{toolStatContent}</Box>
    </Flex>
  );
};
