import { Center, Flex } from "@chakra-ui/react";
import { HvSpinner } from "../../Common/HvSpinner";

interface InsightsLoadingSpinnerProps {
  containerSize?: string;
}

const InsightsLoadingSpinner = (props: InsightsLoadingSpinnerProps) => {
  const { containerSize } = props;
  return (
    <Flex
      maxW={containerSize ?? "container.xl"}
      height={"50vh"}
      alignContent={"center"}
      justifyContent={"center"}
    >
      <Center>
        <HvSpinner />
      </Center>
    </Flex>
  );
};

export default InsightsLoadingSpinner;
