import { useContext, useEffect } from "react";
import { GlobalContext } from "../../State/GlobalContext";
import { SELECTED_USER_LANGUAGE_KEY } from "../../Utils/LanguageUtil";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { useSearchParams } from "react-router-dom";

export const useLoginLanguageDetector = () => {
  const { languages, setSelectedUserLanguage } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let languageToSet: string | null | undefined =
      searchParams.get("lang") ??
      localStorage.getItem(SELECTED_USER_LANGUAGE_KEY);
    if (!languageToSet) {
      const navigatorLanguage = navigator.language.slice(0, 2).toLowerCase();
      languageToSet = languages.find(
        (lang: string) => lang === navigatorLanguage
      );
    }
    if (languageToSet) {
      setSelectedUserLanguage(languageToSet);
      HVLocalizeStrings.setLanguage(languageToSet);
    }
  }, [languages]);
};
