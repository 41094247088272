import React from "react";
import { Divider, Flex, Text } from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { PollResult } from "./PollResult";
import { LargeModal } from "../../Common/LargeModal";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { PollQuestionUserInstanceDto } from "@headversity/contract";

interface InsightModalProps {
  pollInstances: PollQuestionUserInstanceDto[] | null;
  open: boolean;
  showWaitingOnVotes: boolean;
  setOpen: (open: boolean) => void;
}
export const PollResultModal = (props: InsightModalProps) => {
  const { open, setOpen, pollInstances } = props;

  return (
    <LargeModal
      open={open}
      setOpen={setOpen as any}
      backgroundOverlay={"transparent"}
    >
      <Flex flexDir={"column"} gap={"8"} align={"center"}>
        <BlockSectionTitle
          title={HVLocalizeStrings.TEAM_POLL_RESULT}
          primaryTextColor={MODAL_PRIMARY_TEXT_COLOR}
        />
        {pollInstances?.map((item, index: number) => {
          if (
            item?.questionUserResponses &&
            item?.questionUserResponses?.length < 1
          ) {
            return <></>;
          }
          return (
            <Flex
              flexDir={"column"}
              align={"center"}
              gap={"8"}
              w={"100%"}
              key={item.id}
            >
              <Text
                fontWeight={"semibold"}
                textAlign={"center"}
                color={MODAL_PRIMARY_TEXT_COLOR}
                w={"60%"}
              >
                {item?.pollQuestion?.question?.questionText as string}
              </Text>
              <PollResult
                pollQuestion={item?.pollQuestion}
                pollInstance={item}
                hideVote={true}
                showWaitingOnVotes={true}
              />
              {pollInstances.length - 1 !== index && (
                <Divider borderWidth={"2px"} boxShadow={"lg"} mt={"10"} />
              )}
            </Flex>
          );
        })}
      </Flex>
    </LargeModal>
  );
};
