import { useCallback, useState } from "react";
import { getCalmingToolUsages } from "../../Api/Solo/CalmingApi";
import { getKey } from "../../Utils/Helpers";
import { CalmingToolInfoDto } from "@headversity/contract";

export interface ICalmingToolStatProvider {
  calmingToolUserUsage: CalmingToolInfoDto[];
  getCalmingToolUsageServer: () => void;
}

export const useCalmingToolStat = (): ICalmingToolStatProvider => {
  const [calmingToolUserUsage, setCalmingToolUserUsage] = useState<
    CalmingToolInfoDto[]
  >([]);

  const getCalmingToolUsageServer = useCallback(async () => {
    return getCalmingToolUsages(getKey()).then((response) => {
      setCalmingToolUserUsage(response.data);
    });
  }, []);

  return {
    calmingToolUserUsage,
    getCalmingToolUsageServer,
  };
};
