import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  addUser,
  moveUser,
  deleteUser,
  addNode,
  deleteNode,
  moveNode,
  compareSnapshot,
  getCompanyHierarchy,
  editNode,
  restoreSnapshot,
  createHierarchy,
} from "../../Api/Reach/ReachApi";
import { getKey, createAndDownloadCSV } from "../../Utils/Helpers";
import { NodeType } from "../../Components/Shared/Hierarchy/NodeType";
import {
  HierarchyCompareDto,
  LocalizationText,
  NodeDto,
  UpdateNodeDto,
} from "@headversity/contract";
import { getHierarchyParentPathsMap } from "../../Components/Reach/SelfService/Hierarchy/HierarchyHelpers";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../GlobalContext";
import { IShellProvider, ShellContext } from "../ShellContext";

type MoveUserProps = {
  sourceNodeId: number;
  userId: number;
  targetNodeId: number;
};

type MoveUsersProps = {
  targetNodeId: number;
  selectedNodes: TreeViewDataItem[];
};

type MoveNodeProps = {
  nodeId: number;
  newParentNodeId: number;
  revision: string;
};

type HierarchyState = {
  notifyHierarchyUpdated: number; // used to notify components that hierarchy has been updated
  moveUser: (props: MoveUserProps) => Promise<void>;
  moveUsers: (props: MoveUsersProps) => Promise<void>;
  moveNode: (props: MoveNodeProps) => Promise<void>;

  selectedNodes: TreeViewDataItem[];
  setSelectedNodes: (nodes: TreeViewDataItem[]) => void;

  selectedNodeType?: NodeType;
  onAddUser: (userId: number) => void;
  onAddNode: (name: string) => void;
  onDeleteUser: () => void;
  onDeleteNode: () => void;
  onDownloadCsv: () => Promise<any>;
  onEditNode: (nodeId: number, updates: UpdateNodeDto) => Promise<void>;
  onCreateHierarchy: (name: LocalizationText) => Promise<void>;

  onCompareSnapshot: (snapshotId: number) => void;
  compareDifference?: HierarchyCompareDto;

  revision: string;
  setRevision: (revision: string) => void;

  onRestoreSnapshot: (snapshotId: number) => void;
};

export const HierarchyContext = createContext<HierarchyState>({
  notifyHierarchyUpdated: 0,
  moveUser: async (selectedNodes) => {},
  moveUsers: async () => {},
  moveNode: async () => {},
  selectedNodes: [],
  setSelectedNodes: (nodes: TreeViewDataItem[]) => {},

  onAddUser: (userId: number) => {},
  onAddNode: (name: string) => {},
  onDeleteUser: () => {},
  onDeleteNode: () => {},
  onDownloadCsv: async () => {},
  onEditNode: async (nodeId: number, updates: UpdateNodeDto) => {},
  onCreateHierarchy: async (name: LocalizationText) => {},

  onCompareSnapshot: (snapshotId: number) => {},

  revision: "",
  setRevision: (revision: string) => {},

  onRestoreSnapshot: (snapshotId: number) => {},
});

export interface INode {
  id: number;
  name: string;
  parentId: number;
  children: INode[];
  users: any[];
}

export type TreeViewDataItem = {
  text: string;
  expanded: boolean;
  checked?: boolean;
  selected?: boolean;
  items?: TreeViewDataItem[];
  id: number; // node id
  nodeType: NodeType;
  resourceId: number; // user id?
  parentNodeId?: number;
  notSelectable?: boolean;
  sortOrder?: number;
};

export type HierarchySnapshot = {
  id: number;
  companyId: number;
  nodeId: number;
  jsonDetail: object | string;
  createdAt: Date;
};

export const HierarchyContextProvider: React.FC<{ children: ReactNode }> = (
  props
) => {
  const { getHierarchyAssocationsFromServer } =
    useContext<IGlobalProvider>(GlobalContext);

  const { showToast } = useContext<IShellProvider>(ShellContext);

  const [selectedNodes, setSelectedNodes] = useState<TreeViewDataItem[]>([]);
  const [selectedNodeType, setSelectedNodeType] = useState<
    NodeType | undefined
  >();
  const [notifyHierarchyUpdated, setNotifyHierarchyUpdated] =
    useState<number>(0);
  const [compareDifference, setCompareDifference] =
    useState<HierarchyCompareDto>();

  const notifyRefresh = useCallback(() => {
    setNotifyHierarchyUpdated((prev) => prev + 1);
  }, []);

  const [revision, setRevision] = useState<string>("");

  const handleMoveUser = useCallback(async (props: MoveUserProps) => {
    // move user to a different node
    await moveUser(
      getKey(),
      props.sourceNodeId,
      props.userId,
      props.targetNodeId
    );

    notifyRefresh();
  }, []);

  const handleMoveUsers = async (props: MoveUsersProps) => {
    // move all users to target

    for (let node of props.selectedNodes) {
      await moveUser(
        getKey(),
        node.parentNodeId!,
        node.resourceId,
        props.targetNodeId
      );
    }
    notifyRefresh();
  };

  const handleMoveNode = useCallback(async (props: MoveNodeProps) => {
    if (props.nodeId !== props.newParentNodeId) {
      try {
        await moveNode(
          getKey(),
          props.nodeId,
          props.newParentNodeId,
          props.revision
        );
        notifyRefresh();
      } catch (e) {
        const error = e as { response: { status: number } };
        if (error.response.status === 409) {
          alert(
            "Version mismatch. Please refresh the page for the latest hierarchy"
          );
        }
      }
    }
  }, []);

  const handleAddUser = useCallback(
    async (userId: number) => {
      // add user to first selected node
      if (selectedNodes.length > 0) {
        const selectedNode = selectedNodes[0];
        await addUser(getKey(), selectedNode.id, userId);
      }
      notifyRefresh();
    },
    [selectedNodes]
  );

  const handleAddNode = useCallback(
    async (name: string) => {
      // add node to first selected node
      if (selectedNodes.length > 0) {
        const selectedNode = selectedNodes[0];
        await addNode(getKey(), selectedNode.id, name, revision);
      }
      notifyRefresh();
    },
    [selectedNodes, revision]
  );

  const handleSetSelectedNodes = useCallback((nodes: TreeViewDataItem[]) => {
    setSelectedNodes(nodes);
    if (nodes.length > 0) {
      const selectedNode = nodes[0];
      setSelectedNodeType(selectedNode.nodeType);
    } else {
      setSelectedNodeType(undefined);
    }
  }, []);

  const handleDeleteUser = useCallback(async () => {
    if (selectedNodes.length > 0) {
      for (let node of selectedNodes) {
        await deleteUser(getKey(), node.parentNodeId!, node.resourceId);
      }
      notifyRefresh();
    }
  }, [selectedNodes]);

  const handleDeleteNode = useCallback(async () => {
    if (selectedNodes.length > 0) {
      const nodeIds = selectedNodes.map((node) => Number(node.id));
      await deleteNode(getKey(), nodeIds, revision);
    }
    notifyRefresh();
  }, [selectedNodes, revision]);

  const handleEditNode = useCallback(
    async (nodeId: number, updates: UpdateNodeDto) => {
      await editNode(getKey(), nodeId, updates, revision);
      notifyRefresh();
    },
    []
  );

  const handleCreateHiearchy = useCallback(async (name: LocalizationText) => {
    await createHierarchy(getKey(), name);
    await getHierarchyAssocationsFromServer();
    notifyRefresh();
  }, []);

  const handleDownloadCsv = useCallback(async () => {
    const companyHierarchy = await getCompanyHierarchy(getKey());
    const pathsMap = getHierarchyParentPathsMap(companyHierarchy.data);
    const exportData = companyHierarchy.data.map((node: NodeDto) => ({
      id: node.id,
      name: node.name,
      parentId: node.parentId,
      path: pathsMap.get(node.id) ?? "",
    }));
    createAndDownloadCSV(
      exportData,
      HVLocalizeStrings.REACH_HIERARCHY_DOWNLOAD_CSV_FILE_NAME
    );
  }, []);
  const handleCompareSnapshot = useCallback(async (snapshotId: number) => {
    // compare snapshot with current hierarchy
    const response = await compareSnapshot(getKey(), snapshotId);
    setCompareDifference(response.data);
  }, []);

  const handleRestoreSnapshot = useCallback(async (snapshotId: number) => {
    await restoreSnapshot(getKey(), snapshotId);
    showToast(
      true,
      "self-serve-hierarchy-restore-snashpt-success",
      HVLocalizeStrings.REACH_HIERARCHY_SNAPSHOT_RESTORE_SUCCESS
    );
    await getHierarchyAssocationsFromServer();
    notifyRefresh();
  }, []);

  return (
    <HierarchyContext.Provider
      value={{
        notifyHierarchyUpdated,
        moveUser: handleMoveUser,
        moveUsers: handleMoveUsers,
        moveNode: handleMoveNode,
        onAddUser: handleAddUser,
        onAddNode: handleAddNode,
        onDeleteUser: handleDeleteUser,
        onDeleteNode: handleDeleteNode,
        setSelectedNodes: handleSetSelectedNodes,
        onDownloadCsv: handleDownloadCsv,
        onEditNode: handleEditNode,
        onCreateHierarchy: handleCreateHiearchy,
        selectedNodes,
        selectedNodeType,

        onCompareSnapshot: handleCompareSnapshot,
        compareDifference,

        revision,
        setRevision,
        onRestoreSnapshot: handleRestoreSnapshot,
      }}
    >
      {props.children}
    </HierarchyContext.Provider>
  );
};
