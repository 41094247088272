import { Box, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";

interface EarnPointsToastContentProps {
  header: JSX.Element;
  previousPoints: number;
  newPoints: number;
  headline?: string;
  subHeadline: string;
  bottomText: string;
}

export const EarnPointsToastContent = (props: EarnPointsToastContentProps) => {
  const {
    header,
    previousPoints,
    newPoints,
    headline,
    subHeadline,
    bottomText,
  } = props;

  const points = Math.ceil(newPoints - previousPoints);
  return (
    <Box>
      {header}
      <Text fontSize={"14px"}>
        {headline && <>{headline} </>}
        {subHeadline}{" "}
        {points === 1 ? (
          <span>{HVLocalizeStrings.EARN_TOAST_ANOTHER_POINT}</span>
        ) : (
          <span>
            <b>{points}</b> {HVLocalizeStrings.EARN_TOAST_MORE_POINTS}
          </span>
        )}
        .
      </Text>
      <Text fontSize={"14px"}>{bottomText}</Text>
    </Box>
  );
};
