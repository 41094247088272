import { NodeDto } from "@headversity/contract";
import { useCallback, useState } from "react";
import { getFilterableHierarchy } from "../../../Api/Reach/ReachApi";
import { getKey } from "../../../Utils/Helpers";
import { HvSelectOption } from "../../Common/HvSelect";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

export interface IHierarchyProvider {
  hierarchyNodes: NodeDto[];
  nodesFilter: NodeDto[];
  setNodesFilter: (filter: NodeDto[]) => void;
  handleNodeSelectionsChange: (selected: HvSelectOption[]) => void;
  handleFetchHierarchy: () => void;
}

export const useHierarchy = () => {
  const [hierarchyNodes, setHierarchyNodes] = useState<NodeDto[]>([]);
  const [nodesFilter, setNodesFilter] = useState<NodeDto[]>([]);

  const handleNodeSelectionsChange = useCallback(
    (nodes: HvSelectOption[]) => {
      const nodeIds = nodes.map((node) => node.value);
      const selectedNodes = hierarchyNodes.filter((node) =>
        nodeIds.includes(node.id)
      );
      // create a dummy node filter for the UI display
      if(nodeIds.includes(-1)) {
        selectedNodes.push({id: -1, name: HVLocalizeStrings.REACH_HIERARCHY_UNASSOCIATED });
      }
      setNodesFilter(selectedNodes);
    },
    [hierarchyNodes]
  );

  const handleFetchHierarchy = useCallback(async () => {
    const response = await getFilterableHierarchy(getKey());
    setHierarchyNodes(response.data);
  }, []);

  return {
    hierarchyNodes,
    nodesFilter,
    setNodesFilter,
    handleNodeSelectionsChange,
    handleFetchHierarchy,
  };
};
