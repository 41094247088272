import { Box, Flex, Text } from "@chakra-ui/react";
import { InsightsProgressTracker } from "./InsightsProgressTracker";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Equity_Blue } from "../../../Styles/HeadversityStyle";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { useContext } from "react";
import { isMobile } from "react-device-detect";

export interface PercentProgressDisplayData {
  message: string;
  percentage: number;
}

export interface InsightsPercentProgressDisplayProps {
  data: PercentProgressDisplayData[];
  color?: string;
}

export const InsightsPercentProgressDisplay = (
  props: InsightsPercentProgressDisplayProps
) => {
  const { data, color } = props;
  const { selectedUserLanguage } = useContext<IGlobalProvider>(GlobalContext);

  const getDescription = (message: string) => {
    if (message.indexOf("relevant") > -1) {
      return HVLocalizeStrings.REACH_INSIGHTS_RELEVANT_CONTENT_DESCRIPTION;
    } else if (message.indexOf("meaning") > -1) {
      return HVLocalizeStrings.REACH_INSIGHTS_MEANINGFUL_CONTENT_DESCRIPTION;
    } else {
      return HVLocalizeStrings.REACH_INSIGHTS_MOTIVATED_CONTENT_DESCRIPTION;
    }
  };
  const getGap = () => {
    if (data.length >= 3) {
      if (selectedUserLanguage !== "es") {
        return 5;
      } else {
        return 3;
      }
    }
    return 8;
  };
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      width={"100%"}
      height={"100%"}
      mt={isMobile ? "20px" : undefined}
      my={data.length < 3 ? 8 : undefined}
      gap={getGap()}
      py={4}
      px={6}
      pb={7}
    >
      {data.map((r) => (
        <Box flexDirection="column" key={r.message}>
          <Text pl={"5px"} mb={"5px"}>
            {getDescription(r.message)}
          </Text>
          <InsightsProgressTracker
            percentage={r.percentage}
            color={color}
            showPercentage={true}
            fontColor={Equity_Blue}
          />
        </Box>
      ))}
    </Flex>
  );
};
