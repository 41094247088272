import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { HVTestId } from "../../Testing/dataTestIds";

interface JoinTeamButtonProps {
  setCommandMode: (
    commandMode: "join" | "select" | "add" | "edit" | null
  ) => void;
  onOpenCommand: () => void;
  w: number;
}

export const JoinTeamButton = (props: JoinTeamButtonProps) => {
  const { setCommandMode, onOpenCommand, w } = props;

  return (
    <AnimatedButton
      colorSet={AnimatedButtonColorSet.Third}
      w={w}
      text={HVLocalizeStrings.TEAM_JOIN_ANOTHER_TEAM}
      onClick={() => {
        setCommandMode("join");
        onOpenCommand();
      }}
      dataTestId={HVTestId.JoinTeamButton.joinTeam}
    />
  );
};
