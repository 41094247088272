import { useContext, useEffect } from "react";
import { LargeModal } from "../../../Common/LargeModal";
import {
  THINKING_THINKING_ACCURATELY_STEP,
  ThinkingTool,
} from "./ThinkingTool";

interface ThinkingToolModalProps {
  open: boolean;
  setOpen: (val: boolean) => {};
  step?: string;
}

export const ThinkingToolModal = (props: ThinkingToolModalProps) => {
  const { open, setOpen, step } = props;
  return (
    <>
      <LargeModal open={open} setOpen={setOpen}>
        <ThinkingTool
          setOpen={setOpen}
          step={step ?? THINKING_THINKING_ACCURATELY_STEP}
        />
      </LargeModal>
    </>
  );
};
