import { IInsightsFilter, PsychographicsReportDto, ReportDto } from "@headversity/contract";
import { useCallback, useState } from "react";
import { reportPsychographics } from "../../../Api/Reach/ReachApi";
import {
  Psycho_Neutral_Dark_Purple,
  Psycho_Neutral_Light_Purple,
  Psycho_Risk_Dark_Red,
  Psycho_Risk_Light_Red,
  Psycho_Strength_Dark_Blue,
  Psycho_Strength_Light_Blue,
} from "../../../Styles/HeadversityStyle";
import { getKey } from "../../../Utils/Helpers";
import { PsychographicBarChartDataPoint } from "./PsychographicBarChart";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

export interface IPsychographicsReportProvider {
  fetchReport: (filters?: IInsightsFilter) => Promise<void>;
  responseCounts: number;
  psychographicsStrengths: PsychographicChartData[];
  psychographicsRisks: PsychographicChartData[];
  lowData: boolean;
  isLoading: boolean;
  intervalDays: number;
}

export interface PsychographicBobMessage {
  // Bob message
  nss: number;
  bob_ref_p: number;
  compare_p: number;
  agreement: string;
}

export interface PsychographicChartData {
  id: string;
  message: string;
  responses: number;
  totalResponses: number;
  points: PsychographicBarChartDataPoint[];
  bobMessage?: PsychographicBobMessage;
}

const mapPsychographicsData = (
  dto: ReportDto<PsychographicsReportDto[]>
): PsychographicChartData[] => {
  if (dto.noData) {
    return [];
  }
  const totalResponses = dto.report!.reduce((acc, curr) => {
    return acc + curr.total_responses;
  }, 0);

  return dto.report!.map((psychographics) => {
    let bobData: PsychographicBobMessage | undefined = undefined;
    if (psychographics.strong_p_bob && psychographics.low_p_bob) {
      bobData = {
        nss: psychographics.nss,
        bob_ref_p: Math.round(psychographics.strong_p_bob * 100.0),
        compare_p: Math.round(psychographics.strong_p * 100.0),
        agreement: HVLocalizeStrings.REACH_INSIGHTS_STRONG,
      };
    } else {
      throw new Error("boom");
    }
    const res: PsychographicChartData = {
      message: psychographics.question as string,
      responses: psychographics.total_responses,
      totalResponses: totalResponses,
      // generate unique id from object string
      id: JSON.stringify(psychographics),
      points: [
        {
          name: HVLocalizeStrings.REACH_INSIGHTS_STRONG_AGREEMENT,
          y: Math.round(psychographics.strong_p * 100.0),
          colorStart: Psycho_Strength_Light_Blue,
          colorEnd: Psycho_Strength_Dark_Blue,
        },
        {
          name: HVLocalizeStrings.REACH_INSIGHTS_NEUTRAL_AGREEMENT,
          y: Math.round(psychographics.neutral_p * 100.0),
          colorStart: Psycho_Neutral_Light_Purple,
          colorEnd: Psycho_Neutral_Dark_Purple,
        },
        {
          name: HVLocalizeStrings.REACH_INSIGHTS_LOW_AGREEMENT,
          y: Math.round(psychographics.low_p * 100.0),
          colorStart: Psycho_Risk_Light_Red,
          colorEnd: Psycho_Risk_Dark_Red,
        },
      ],
      bobMessage: bobData,
    };
    return res;
  });
};

export const usePsychographicsReport = (): IPsychographicsReportProvider => {
  const [psychographicsStrengthsData, setPsychographicsStrengthsData] =
    useState<PsychographicChartData[]>([]);
  const [psychographicsResponseCount, setPsychographicsResponseCount] =
    useState<number>(0);

  const [psychographicsRisksData, setPsychographicsRisksData] = useState<
    PsychographicChartData[]
  >([]);
  const [lowData, setLowData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [intervalDays, setIntervalDays] = useState<number>(180);

  const fetchReportHandler = useCallback(async (filters?: IInsightsFilter) => {
    setIsLoading(true);
    const psychographicsReportResp = await reportPsychographics(getKey(), filters);

    const mapped = mapPsychographicsData(
      psychographicsReportResp.data.strengths
    );
    setPsychographicsStrengthsData(mapped);

    const mapped2 = mapPsychographicsData(psychographicsReportResp.data.risks);
    setPsychographicsRisksData(mapped2);

    setPsychographicsResponseCount(
      psychographicsReportResp.data.userCount.report?.distinct_user_count ?? 0
    );
    setLowData(
      (psychographicsReportResp.data.strengths.noData &&
        psychographicsReportResp.data.risks.noData) ||
        psychographicsReportResp.data.userCount.noData
    );
    setIntervalDays(psychographicsReportResp.data.intervalDays);
    setIsLoading(false);
  }, []);

  return {
    fetchReport: fetchReportHandler,
    psychographicsStrengths: psychographicsStrengthsData,
    psychographicsRisks: psychographicsRisksData,
    responseCounts: psychographicsResponseCount,
    lowData: lowData,
    isLoading: isLoading,
    intervalDays,
  };
};
