import Pusher, { Channel } from "pusher-js";

export const onPusherEvent = (
  pusherClient: Pusher,
  eventName: string,
  channels: Channel[],
  handler: (data: any, channelName: string) => void,
  waitingTimeMs: number = 1000
) => {
  let timeOutIds: any = {};
  if (pusherClient) {
    channels.forEach((channel) => {
      channel.bind(eventName, (data: any) => {
        if (timeOutIds[channel.name]) {
          clearTimeout(timeOutIds[channel.name]);
          timeOutIds[channel.name] = null;
        }
        timeOutIds[channel.name] = setTimeout(() => {
          handler(data, channel.name);
          timeOutIds[channel.name] = null;
        }, waitingTimeMs);
      });
    });

    return () => {
      channels.forEach((channel) => {
        if (channel) {
          channel.unbind(eventName);
        }
        if (timeOutIds[channel.name]) {
          clearTimeout(timeOutIds[channel.name]);
          timeOutIds[channel.name] = null;
        }
      });
    };
  }
};
