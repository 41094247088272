import { useEffect, useState } from "react";
import { Box, Text, useBreakpointValue } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { CertDto } from "@headversity/contract";
import { ChakraStylesConfig, Select } from "chakra-react-select";
import {
  darkBluishGray,
  INPUT_BACKGROUND_COLOR,
  INPUT_PRIMARY_TEXT_COLOR,
  SELECT_BORDER,
  SELECT_BORDER_FOCUSED,
  chakraSelectBorderColorFocused,
  White,
  SELECT_VALUE_BACKGROUND,
} from "../../../../Styles/HeadversityStyle";

interface StepAssignCertProps {
  companyCerts: CertDto[];
  handleCertSelections: (certIds: number[]) => void;
}

interface CertOptions {
  value: number;
  label: string;
}

const StepAssignCert = ({
  companyCerts,
  handleCertSelections,
}: StepAssignCertProps) => {
  const [selectedValues, setSelectedValues] = useState<CertOptions[]>([]);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  useEffect(() => {
    const selectedCertIds = selectedValues.map((cert) => cert.value);
    handleCertSelections(selectedCertIds);
  }, [selectedValues]);

  const certOptions: CertOptions[] = companyCerts.map((cert) => {
    return { value: cert.id, label: cert.name as string };
  });

  const chakraStyles: ChakraStylesConfig = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused
        ? `${chakraSelectBorderColorFocused} !important`
        : isSelected
        ? chakraSelectBorderColorFocused
        : darkBluishGray,
      color: White,
      fontWeight: "500",
    }),
    container: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: INPUT_BACKGROUND_COLOR,
      borderRadius: "md",
      border: isFocused ? SELECT_BORDER_FOCUSED : SELECT_BORDER,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: INPUT_BACKGROUND_COLOR,
      borderRadius: "md",
      "&:hover": {
        backgroundColor: INPUT_BACKGROUND_COLOR,
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      backgroundColor: INPUT_BACKGROUND_COLOR,
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: `${darkBluishGray} !important`,
      border: "1px solid black",
      padding: "2px",
      boxShadow: "0 0 0 1px darkgray inset",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 5,
    }),
    downChevron: (provided) => ({
      ...provided,
      color: INPUT_PRIMARY_TEXT_COLOR,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: SELECT_VALUE_BACKGROUND,
      color: INPUT_PRIMARY_TEXT_COLOR,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: INPUT_PRIMARY_TEXT_COLOR,
    }),
  };

  return (
    <>
      <Text fontSize={"xl"} fontWeight={"semibold"}>
        {HVLocalizeStrings.REACH_USERS_UPLOAD_STEP_3_TITLE}
      </Text>
      <Box w={isMobile ? "90%" : "460px"}>
        <Select
          isMulti
          value={selectedValues}
          onChange={(value) => setSelectedValues(value as CertOptions[])}
          variant="filled"
          useBasicStyles
          name="certs"
          options={certOptions}
          placeholder={HVLocalizeStrings.REACH_USERS_CHOOSE_A_CERT}
          closeMenuOnSelect={false}
          chakraStyles={chakraStyles}
          selectedOptionStyle="check"
          hideSelectedOptions={false}
        />
      </Box>
    </>
  );
};
export default StepAssignCert;
