import { ParticipantTeamLessonStepProps } from "./ParticipantModal";
import { PresentationLobbyView } from "../Shared/PresentationLobbyView";
import { Box } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

interface ParticipantIntroStepProps extends ParticipantTeamLessonStepProps {}

export const ParticipantIntroStep = (props: ParticipantIntroStepProps) => {
  const { pathTeamLesson } = props;
  return (
    <>
      <PresentationLobbyView
        mode={"Participant"}
        mainTitle={HVLocalizeStrings.ALL_SET}
        additionalSection={
          <>
            <Box></Box>
          </>
        }
        teamLesson={pathTeamLesson?.teamLesson}
      />
    </>
  );
};
