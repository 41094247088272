import {
  Box,
  Grid,
  GridItem,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useContext, useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Interval180Days,
  ReachInsightsContext,
} from "../../../State/Reach/ReachInsightsContext";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { Equity_Blue } from "../../../Styles/HeadversityStyle";
import { setInsightsChartOptions } from "../../../Utils/ChartsUtil";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import InsightsCard from "../../Common/Cards/InsightsCard";
import InsightsNoData from "./InsightsNoData";
import { InsightsNumberDisplay } from "./InsightsNumberDisplay";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import InsightsLoadingSpinner from "./InsightsLoadingSpinner";

interface InsightsUsersPanelProps {
  pdfExport?: boolean;
  downloadReport?: () => void;
  isFromReachHome?: boolean;
}
const InsightsUsersPanel = (props: InsightsUsersPanelProps) => {
  const { downloadReport, pdfExport, isFromReachHome } = props;
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  const [userEnrollmentSeries, setUserEnrollmentSeries] = useState<any[]>([]);
  const [userEnrollmentDates, setUserEnrollmentDates] = useState<string[]>([]);
  const [userEnrollmentGraphStartDate, setUserEnrollmentGraphStartDate] =
    useState<number>(0);
  const { usersReport } = useContext(ReachInsightsContext);

  let graphDimensionsByBreakpoint = useBreakpointValue({
    base: { width: 300, height: 280 },
    sm: { width: 400, height: 280 },
    md: { width: 580, height: 260 },
    lg: { width: 580, height: 260 },
    xl: { width: 640, height: 260 },
    "2xl": { width: 800, height: 260 },
  }) as { width: number; height: number };
  if (pdfExport) {
    graphDimensionsByBreakpoint = { width: 640, height: 260 }; // use xl size for pdf
  }
  const containerSize =
    pdfExport || isMainContainerXL ? "container.xl" : "container.lg";

  useEffect(() => {
    if (!usersReport.userEnrollmentData) {
      return;
    }
    const series = [
      {
        name: HVLocalizeStrings.REACH_INSIGHTS_TOTAL_ENROLLED_USERS,
        data: usersReport.userEnrollmentData.data,
        zIndex: 1,
        color: Equity_Blue,
        legendSymbol: "rectangle",
        marker: { enabled: false },
      },
    ];

    const graphStartDate = usersReport.userEnrollmentData.graphStartDate;
    const now = new Date();
    const thresholdDate = new Date(now.setMonth(now.getMonth() - 12));
    thresholdDate.setDate(1);
    const thresholdTimestamp = thresholdDate.getTime();
    if (graphStartDate < thresholdTimestamp) {
      const indexes = usersReport.userEnrollmentData.dates
        .map((date, index) =>
          new Date(date).getTime() >= thresholdTimestamp ? index : -1
        )
        .filter((index) => index !== -1);
      const filteredDates = indexes.map(
        (index) => usersReport.userEnrollmentData!.dates[index]
      );
      const filteredSeriesData = indexes.map((index) => series[0].data[index]);
      const filteredSeries = [{ ...series[0], data: filteredSeriesData }];
      setUserEnrollmentGraphStartDate(new Date(filteredDates[0]).getTime());
      setUserEnrollmentDates(filteredDates);
      setUserEnrollmentSeries(filteredSeries);
    } else {
      setUserEnrollmentGraphStartDate(graphStartDate);
      setUserEnrollmentDates(usersReport.userEnrollmentData.dates);
      setUserEnrollmentSeries(series);
    }
  }, [usersReport.userEnrollmentData]);

  const userPanelBody = () => {
    return (
      <>
        {usersReport.lowData ? (
          <InsightsCard
            title={""}
            hideHeader={true}
            content={
              <InsightsNoData
                description={
                  HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                }
              />
            }
          />
        ) : (
          <Grid
            gap={6}
            templateRows={{
              base: pdfExport ? "repeat(2,1fr)" : "repeat(4, 1fr)",
              md: "repeat(2, 1fr)",
            }}
            templateColumns={{
              base: pdfExport ? "repeat(3, 1fr)" : "repeat(1, 1fr)",
              md: "repeat(3, 1fr)",
            }}
          >
            <GridItem rowSpan={1} colSpan={1} gridRow={1}>
              <InsightsCard
                title={HVLocalizeStrings.REACH_INSIGHTS_ENROLLED_USERS}
                subtitle={HVLocalizeStrings.REACH_INSIGHTS_ALL_TIME}
                content={
                  <InsightsNumberDisplay
                    heading={
                      usersReport.userPercentageData!.enrolledUserHeading
                    }
                    headingColor={Equity_Blue}
                    description={
                      usersReport.userPercentageData!.enrolledUsersDescription
                    }
                    fontSize={"48px"}
                  />
                }
                hasTooltip={true}
                tooltipTitle={
                  HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_ENROLLED_USER_TITLE
                }
                tooltipContent={
                  HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_ENROLLED_USER_DESCRIPTION
                }
              />
            </GridItem>
            <GridItem rowSpan={1} colSpan={1} gridRow={2}>
              <InsightsCard
                title={HVLocalizeStrings.REACH_INSIGHTS_ACTIVE_USERS}
                subtitle={
                  usersReport.intervalDays > Interval180Days
                    ? HVLocalizeStrings.REACH_INSIGHTS_ALL_TIME
                    : HVLocalizeStrings.REACH_INSIGHTS_LAST_180_DAYS
                }
                content={
                  <InsightsNumberDisplay
                    heading={usersReport.userPercentageData!.activeUsersHeading}
                    headingColor={Equity_Blue}
                    description={
                      usersReport.userPercentageData!.activeUsersDescription
                    }
                    fontSize={"48px"}
                  />
                }
                hasTooltip={true}
                tooltipTitle={
                  HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_ACTIVE_USER_TITLE
                }
                tooltipContent={
                  HVLocalizeStrings.REACH_INSIGHTS_TOOLTIP_ACTIVE_USER_DESCRIPTION
                }
              />
            </GridItem>
            <GridItem rowSpan={2} colSpan={{ base: pdfExport ? 2 : 1, md: 2 }}>
              {userEnrollmentSeries && (
                <InsightsCard
                  title={HVLocalizeStrings.REACH_INSIGHTS_ENROLMENT_TRENDS}
                  subtitle={
                    usersReport.intervalDays > Interval180Days
                      ? HVLocalizeStrings.REACH_INSIGHTS_LAST_12_MONTHS
                      : HVLocalizeStrings.REACH_INSIGHTS_LAST_180_DAYS
                  }
                  content={
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={setInsightsChartOptions(
                        "line",
                        userEnrollmentSeries,
                        userEnrollmentDates,
                        userEnrollmentGraphStartDate,
                        graphDimensionsByBreakpoint?.width,
                        graphDimensionsByBreakpoint?.height
                      )}
                    />
                  }
                />
              )}
            </GridItem>
          </Grid>
        )}
      </>
    );
  };

  const component = (
    <Box
      maxW={containerSize}
      mx={isFromReachHome ? 1 : "auto"}
      mt={isDesktop ? "40px" : "20px"}
      mb={"20px"}
    >
      <BlockSectionTitle
        title={
          isFromReachHome
            ? HVLocalizeStrings.REACH_INSIGHTS
            : HVLocalizeStrings.REACH_USERS
        }
        description={
          isFromReachHome ? (
            <Text
              as={"span"}
              dangerouslySetInnerHTML={{
                __html: HVLocalizeStrings.REACH_HOME_INSIGHTS_DESCRIPTION,
              }}
            />
          ) : (
            HVLocalizeStrings.REACH_INSIGHTS_USERS_DESCRIPTION
          )
        }
        descriptionMarginTop={isFromReachHome ? 1 : 3}
      >
        {downloadReport && isWebPlatform() && (
          <>
            <Spacer />
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Third}
              text={HVLocalizeStrings.REACH_DOWNLOAD_REPORT}
              onClick={downloadReport}
              w={"auto"}
            />
          </>
        )}
      </BlockSectionTitle>
      {usersReport.isLoading ? (
        <InsightsLoadingSpinner containerSize={containerSize} />
      ) : (
        userPanelBody()
      )}
    </Box>
  );
  return component;
};

export default InsightsUsersPanel;
