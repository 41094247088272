import { Button, Center, Flex, Icon, Text } from "@chakra-ui/react";
import { Mid_Blue } from "../../../Styles/HeadversityStyle";
import { BiCopy } from "react-icons/bi";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

interface ReachCopyToClipboardProps {
  copyToClipboard: () => void;
  fontSize?: string;
}

const ReachCopyToClipboard = (props: ReachCopyToClipboardProps) => {
  const { copyToClipboard, fontSize } = props;
  return (
    <Center>
      <Button
        className="button-link"
        onClick={copyToClipboard}
        textDecorationColor={Mid_Blue}
        overflowWrap={"normal"}
      >
        <Flex alignItems={"center"} gap={2} color={Mid_Blue} maxW={"100%"}>
          <Icon as={BiCopy} boxSize={"24px"} />
          <Text
            whiteSpace={"break-spaces"}
            textAlign={"left"}
            fontSize={fontSize}
          >
            {HVLocalizeStrings.REACH_ORGANIZATIONS_COPY_TO_CLIPBOARD}
          </Text>
        </Flex>
      </Button>
    </Center>
  );
};

export default ReachCopyToClipboard;
