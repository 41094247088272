import {
  Box,
  Button,
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChangeEvent, useCallback, useState } from "react";
import { debounce } from "lodash";
import {
  FiDownload,
  FiEdit,
  FiPlusCircle,
  FiSearch,
  FiTrash,
} from "react-icons/fi";
import { MdNewLabel } from "react-icons/md";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  DESTRUCTIVE_TEXT_COLOR,
  DISABLED_BORDER,
  HVDefaultTheme,
  INPUT_PRIMARY_TEXT_COLOR,
  INPUT_BACKGROUND_COLOR,
  BUTTON_DISABLE_BACKGROUND_COLOR,
  MODAL_CLOSE_BUTTON,
} from "../../../../Styles/HeadversityStyle";
import { HvSelect } from "../../../Common/HvSelect";
import { HvTextInput } from "../../../Common/HvTextInput";
import { NodeType } from "../../../Shared/Hierarchy/NodeType";

interface HierarchyToolbarProps {
  selectedNodeType?: NodeType;
  hasHierarchy?: boolean;
  onSearch: (searchStr: string) => void;
  onDelete?: () => void;
  onAddResource?: (resourceId: string, nodeType: NodeType) => void;
  onDownloadCsv?: () => void;
  onRename?: () => void;
  onCreateHierarchy?: () => void;
  onClearSearch?: () => void;
  showClearSearch?: boolean;
  isRootNodeSelected?: boolean;
  displayAsModal?: boolean;
}

export const HierarchyToolbar = ({
  selectedNodeType,
  hasHierarchy,
  onSearch,
  onDelete,
  onAddResource,
  onDownloadCsv,
  onRename,
  onCreateHierarchy,
  onClearSearch,
  showClearSearch,
  isRootNodeSelected,
  displayAsModal,
}: HierarchyToolbarProps) => {
  const [searchInputText, setSearchInputText] = useState("");
  const [addResourceText, setAddResourceText] = useState("");
  const [inputResourceType, setInputResourceType] = useState<NodeType>(
    NodeType.User
  );

  const handleSearchFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputText(event.target.value);
    debouncedHandleSearch(event);
    // handle search debounced
  };

  const handleAddResourceTextChanged = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setAddResourceText(event.target.value);
  };

  const handleDeleteClicked = () => {
    onDelete!();
  };

  const handleDownloadCsv = () => {
    onDownloadCsv?.();
  };

  const handleRenameNode = () => {
    onRename?.();
  };

  const handleCreateHierarchy = () => {
    onCreateHierarchy?.();
  };

  const handleAddResource = () => {
    // addResource
    if (onAddResource === undefined) return;
    onAddResource(addResourceText, inputResourceType);
  };

  const handleClearSearch = () => {
    onClearSearch?.();
    setSearchInputText("");
  };

  const debouncedHandleSearch = useCallback(
    debounce(
      (event: ChangeEvent<HTMLInputElement>) => {
        onSearch(event.target.value);
      },
      500,
      { leading: false, trailing: true }
    ),
    []
  );

  const handleSearch = () => {
    onSearch(searchInputText);
  };

  const getInputTextCaption = () => {
    if (inputResourceType === NodeType.User) {
      return "User Id Here";
    } else if (inputResourceType === NodeType.Node) {
      return "Node Name Here";
    }
  };

  const isNodeSelected = () => {
    return selectedNodeType === NodeType.Node;
  };

  const isEditorToolbar = onAddResource !== undefined || onDelete !== undefined;

  let editorToolsDirection = useBreakpointValue({
    base: "column",
    sm: "column",
    md: "row",
  });

  const addToolbarControls = () => {
    return (
      <Flex flexDir={editorToolsDirection as any} alignItems="start" gap={2}>
        <HvSelect
          w={"110px"}
          placeholder={HVLocalizeStrings.SELECT}
          value={inputResourceType}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setInputResourceType(parseInt(event.target.value));
          }}
        >
          <option value={NodeType.Node}>Node</option>
          <option value={NodeType.User}>User</option>
        </HvSelect>
        <InputGroup width={"240px"}>
          <InputRightElement>
            <Box pt={2} pr={1} as="button">
              <IconButton
                bg={"transparent"}
                as={FiPlusCircle}
                boxSize={5}
                color={INPUT_PRIMARY_TEXT_COLOR}
                onClick={handleAddResource}
                aria-label={"add resource"}
                cursor={"pointer"}
                _hover={{ bg: "transparent" }}
                disabled={!isNodeSelected()}
              />
            </Box>
          </InputRightElement>
          <HvTextInput
            placeholder={getInputTextCaption()}
            onEnterKeyDown={handleAddResource}
            onChange={handleAddResourceTextChanged}
            value={addResourceText}
            border={DISABLED_BORDER}
            bg={INPUT_BACKGROUND_COLOR}
          />
        </InputGroup>
        <Flex flexDir="row">
          {!hasHierarchy && (
            <IconButton
              bg={"transparent"}
              as={MdNewLabel}
              boxSize={5}
              color={INPUT_PRIMARY_TEXT_COLOR}
              onClick={handleCreateHierarchy}
              aria-label={HVLocalizeStrings.REACH_HIERARCHY_NEW}
              cursor={"pointer"}
              _hover={{ bg: "transparent" }}
            />
          )}

          {onDelete && (
            <IconButton
              bg={"transparent"}
              as={FiTrash}
              boxSize={5}
              color={DESTRUCTIVE_TEXT_COLOR}
              onClick={handleDeleteClicked}
              aria-label={HVLocalizeStrings.DELETE}
              cursor={"pointer"}
              _hover={{ bg: "transparent" }}
              disabled={isRootNodeSelected || selectedNodeType === undefined}
            />
          )}

          <IconButton
            bg={"transparent"}
            as={FiEdit}
            boxSize={5}
            color={INPUT_PRIMARY_TEXT_COLOR}
            onClick={handleRenameNode}
            aria-label={HVLocalizeStrings.REACH_RENAME}
            cursor={"pointer"}
            _hover={{ bg: "transparent" }}
            disabled={!isNodeSelected()}
          />
          <IconButton
            bg={"transparent"}
            as={FiDownload}
            boxSize={5}
            color={INPUT_PRIMARY_TEXT_COLOR}
            onClick={handleDownloadCsv}
            aria-label={HVLocalizeStrings.REACH_HIERARCHY_DOWNLOAD}
            cursor={"pointer"}
            _hover={{ bg: "transparent" }}
          />
        </Flex>
      </Flex>
    );
  };
  const editorToolbar = () => {
    return (
      <Flex flexDir="row" alignItems="center" gap={1}>
        {onAddResource && addToolbarControls()}
      </Flex>
    );
  };

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <Flex gap={2} direction="column">
      <Flex flexWrap="wrap">
        <InputGroup width={isDesktop ? "200px" : "100%"} height={"50px"}>
          <InputRightElement>
            <Box pt={2} pr={1} as="button">
              <IconButton
                bg={"transparent"}
                as={FiSearch}
                boxSize={5}
                color={INPUT_PRIMARY_TEXT_COLOR}
                onClick={handleSearch}
                aria-label={HVLocalizeStrings.PROFILE_ASSOCIATION_SEARCH}
                cursor={"pointer"}
                _hover={{ bg: "transparent" }}
              />
            </Box>
          </InputRightElement>
          <HvTextInput
            placeholder={HVLocalizeStrings.PROFILE_ASSOCIATION_SEARCH}
            onEnterKeyDown={handleSearch}
            onChange={handleSearchFilterChanged}
            value={searchInputText}
            color={
              displayAsModal
                ? INPUT_PRIMARY_TEXT_COLOR
                : HVDefaultTheme.colors[MODAL_CLOSE_BUTTON]
            }
            borderColor={
              displayAsModal
                ? DISABLED_BORDER
                : HVDefaultTheme.colors[BUTTON_DISABLE_BACKGROUND_COLOR]
            }
            bg={
              displayAsModal
                ? INPUT_BACKGROUND_COLOR
                : HVDefaultTheme.colors[INPUT_BACKGROUND_COLOR]
            }
          />
        </InputGroup>
        {showClearSearch && (
          <Button
            color={
              displayAsModal
                ? MODAL_CLOSE_BUTTON
                : HVDefaultTheme.colors[MODAL_CLOSE_BUTTON]
            }
            colorScheme="orange"
            variant="link"
            size="sm"
            onClick={handleClearSearch}
            textDecoration={"underline"}
            m={2}
            fontWeight={"normal"}
          >
            {HVLocalizeStrings.REACH_USERS_CLEAR_SEARCH}
          </Button>
        )}
      </Flex>
      {/* Editing toolbar  */}
      {isEditorToolbar && editorToolbar()}
    </Flex>
  );
};
