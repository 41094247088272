import { Box, Center, Divider, Heading, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../../Styles/HeadversityStyle";
import { HvTextInput } from "../../../Common/HvTextInput";
import React from "react";

interface ThinkingToolStepExchangeProps {
  changePage: (page: string) => void;
  nextStep: string;
  currentInputForNegativeThought: string;
  currentInputForBalancedThought: string;
  handleChangeBalancedThought: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentInputForStepTwoButtons: string;
  whatDoesTheEvidenceSayButtons: string;
  whatWouldYourBestFriendSayButtons: string;
}

export const ThinkingToolStepExchange = (
  props: ThinkingToolStepExchangeProps
) => {
  const {
    changePage,
    handleChangeBalancedThought,
    currentInputForNegativeThought,
    currentInputForBalancedThought,
    currentInputForStepTwoButtons,
    whatDoesTheEvidenceSayButtons,
    whatWouldYourBestFriendSayButtons,
    nextStep,
  } = props;
  return (
    <>
      <Box color={MODAL_PRIMARY_TEXT_COLOR}>
        <Heading
          mt={"5"}
          fontSize={"2xl"}
          fontWeight={"semibold"}
          text-align={"left"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {HVLocalizeStrings.THINKING_TOOLS_STEP_3}
        </Heading>
        <Text mt={"2"} fontSize={"md"}>
          {HVLocalizeStrings.THINKING_TOOLS_NOW_EXCHANGE}
        </Text>
        <Divider my={6} />
        <Text mt={"2"} fontSize={"md"} textAlign="center">
          {`"${currentInputForNegativeThought}"`}
        </Text>

        <Divider my={6} />

        <Text
          mt={"4"}
          fontSize={"md"}
          dangerouslySetInnerHTML={{
            __html:
              currentInputForStepTwoButtons === "THOUGHT"
                ? HVLocalizeStrings.THINKING_TOOLS_LOG_THOUGHT_IS
                : HVLocalizeStrings.THINKING_TOOLS_LOG_THOUGHT_NOT,
          }}
        />

        <Text
          mt={"4"}
          fontSize={"md"}
          dangerouslySetInnerHTML={{
            __html:
              whatDoesTheEvidenceSayButtons === "SUPPORTS"
                ? HVLocalizeStrings.THINKING_TOOLS_LOG_STATEMENT_SUPPORT
                : HVLocalizeStrings.THINKING_TOOLS_LOG_STATEMENT_NOT_SUPPORT,
          }}
        />

        <Text
          mt={"4"}
          fontSize={"md"}
          dangerouslySetInnerHTML={{
            __html:
              whatWouldYourBestFriendSayButtons === "DISAGREE"
                ? HVLocalizeStrings.THINKING_TOOL_LOG_DISAGREE
                : HVLocalizeStrings.THINKING_TOOL_LOG_AGREE,
          }}
        />

        <Divider my={6} />

        <Text mt={"4"} fontSize="md">
          {HVLocalizeStrings.THINKING_TOOLS_YOU_USING_INSIGHTS}
        </Text>
      </Box>
      <HvTextInput
        placeholder={HVLocalizeStrings.THINKING_TOOLS_NOT_EVERYONE}
        onChange={(e) => handleChangeBalancedThought(e)}
        onEnterKeyDown={() => {
          if (currentInputForBalancedThought) {
            changePage(nextStep);
          }
        }}
        autoFocus={window.screen.width >= 568}
      />
      <Center mt={8} mb={2}>
        <AnimatedButton
          disabled={!currentInputForBalancedThought}
          text={HVLocalizeStrings.NEXT}
          onClick={() => changePage(nextStep)}
        />
      </Center>
    </>
  );
};
