import { Divider, Text, Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ImmediateAssistance } from "../../Shared/Welcome/ImmediateAssistance";
import { getMessagesFromAiResponse } from "./NanoPracticeUtils";

export interface SummaryContent {
  title?: string;
  summary: string;
  risk?: boolean;
}

export interface SummaryBlockProps {
  summaryContents: SummaryContent[];
}
export const NanoPracticeSummaryContent = ({
  summaryContents,
}: SummaryBlockProps) => {
  const getHelpSegment = () => {
    return <ImmediateAssistance from="Reflection" />;
  };

  return (
    <Box maxW={"100%"} pt={"10px"}>
      {summaryContents.map((s, index) => (
        <>
          {index > 0 && <Divider my={8} />}
          {s.title && (
            <Text fontSize={"24px"} fontWeight="500">
              {s.title}
            </Text>
          )}
          <Text
            fontSize="17px"
            fontWeight="300"
            lineHeight="1.3"
            mt={3}
            whiteSpace={"pre-line"}
          >
            {s.summary}
            {s.risk && getHelpSegment()}
          </Text>
        </>
      ))}
    </Box>
  );
};

export interface SummaryParserProps {
  aiResponse: string;
  aiResponseTitle: string;
  aiHistoryTitle: string;
  aiNextStepTitle?: string;
  haveHistory?: boolean;
}
export const useSummaryParser = ({
  aiResponse,
  aiResponseTitle,
  aiHistoryTitle,
  aiNextStepTitle,
  haveHistory,
}: SummaryParserProps) => {
  const [summaryContents, setSummaryContents] = useState<SummaryContent[]>([]);

  useEffect(() => {
    const summaryContentsToSet: SummaryContent[] = [];
    if (aiResponse) {
      const response = getMessagesFromAiResponse(aiResponse, "aiResponse");
      const riskDetected = getMessagesFromAiResponse(aiResponse, "risk", true);
      summaryContentsToSet.push({
        title: aiResponseTitle,
        summary: response as string,
        risk: riskDetected ? !!riskDetected : undefined,
      });
      const historyResponse = haveHistory
        ? getMessagesFromAiResponse(aiResponse, "aiResponseHistory", true)
        : null;
      if (historyResponse) {
        summaryContentsToSet.push({
          title: aiHistoryTitle,
          summary: historyResponse,
        });
      }

      const nextStep = getMessagesFromAiResponse(
        aiResponse,
        "aiNextStep",
        true
      );

      if (nextStep) {
        summaryContentsToSet.push({
          title: aiNextStepTitle,
          summary: nextStep,
        });
      }
      setSummaryContents(summaryContentsToSet);
    }
  }, [aiResponse]);

  return { summaryContents };
};
