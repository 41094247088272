import { useCallback, useState } from "react";
import { Deploy } from "cordova-plugin-ionic";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { isSimulator, isWebPlatform } from "../../Utils/mobileUtils";
import { SplashScreen } from "@capacitor/splash-screen";
import {
  getAuthenticatedChannel,
  getDefaultChannel,
} from "../../Api/People/AppChannelApi";
import { getKey } from "../../Utils/Helpers";

export interface ILiveUpdateGlobal {
  updateApp: (timeoutSeconds?: number) => Promise<void>;
  percentageDone: string | null;
}

export const CURRENT_URL_BEFORE_UPDATE = "CURRENT_URL_BEFORE_UPDATE";

export const useLiveUpdateGlobal = (): ILiveUpdateGlobal => {
  const [percentageDone, setPercentageDone] = useState<string | null>(null);

  const updateApp = useCallback(async (timeoutSeconds = 500) => {
    if (!isWebPlatform() && !isSimulator()) {
      const liveChannel = await getLiveUpdateChannel();
      return Deploy.configure({
        channel: liveChannel as any,
      }).then(() => {
        return Deploy.checkForUpdate().then((response) => {
          if (response.available) {
            SplashScreen.show({
              autoHide: false,
            });
            setTimeout(() => {
              // do not remove this console.log.  For some strange reason putting this
              // here forces CapacitorJs to store the value correctly.
              console.log(
                `trying to save: ${window.location.pathname}${window.location.search}`
              );
              // Live update happen at login screen, don't redirect back to it.  Or else
              // it will be stuck there in the mobile app
              if (
                window?.location?.pathname
                  ?.toLocaleLowerCase()
                  .indexOf("mobilelogin") < 0
              ) {
                localStorage.setItem(
                  CURRENT_URL_BEFORE_UPDATE,
                  `${window.location.pathname}${window.location.search}`
                );
              }
              Deploy.sync({ updateMethod: "auto" }, (percentDone) => {
                setPercentageDone(
                  `${HVLocalizeStrings.UPDATING} ${percentDone}%`
                );
              });
            }, timeoutSeconds);

            return Promise.reject();
          } else {
            return Promise.resolve();
          }
        });
      });
    } else {
      return Promise.resolve();
    }
  }, []);

  return { updateApp, percentageDone };
};

const getLiveUpdateChannel = async (): Promise<string | null> => {
  try {
    // get the override live channel, this option should only
    // exist in our staging app
    if (localStorage.getItem("override_live_channel")) {
      return localStorage.getItem("override_live_channel");
    }
    await setAuthenticatedLiveUpdateChannel();
    if (localStorage.getItem("authenticated_live_channel")) {
      return localStorage.getItem("authenticated_live_channel");
    }

    return getDefaultChannel().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      () => {
        return Promise.resolve(process.env.REACT_APP_UPDATE_CHANNEL as any);
      }
    );
  } catch (e) {
    // something screw up, lets eat the exception
    // so the app will still function.
  }

  return process.env.REACT_APP_UPDATE_CHANNEL as any;
};

const setAuthenticatedLiveUpdateChannel = async (): Promise<void> => {
  if (getKey()) {
    return getAuthenticatedChannel(getKey()).then((response) => {
      localStorage.setItem("authenticated_live_channel", response.data);
      return Promise.resolve();
    });
  }
};
