import { NodeDto, NodeDtoLabel } from "@headversity/contract";
import {
  TreeViewCheckDescriptor,
  TreeViewOperationDescriptor,
} from "@progress/kendo-react-treeview";
import { TreeViewDataItem } from "../../../../State/Reach/ReachHierarchyContext";
import { NodeType } from "../../../Shared/Hierarchy/NodeType";

export const indentByParentNodeCount = (nodes: NodeDto[]): NodeDtoLabel[] => {
  const nodesWithLabels = nodes.map((node) => {
    return { ...node, label: node.name as string };
  });

  const map = new Map<number, NodeDtoLabel>();
  nodesWithLabels.forEach((node) => {
    map.set(node.id, node);
  });

  // for each node, count the number of parents, and indent the label using unicode chars
  nodesWithLabels.forEach((node) => {
    let parentId = node.parentId;
    let parentCount = 0;
    while (parentId) {
      parentCount++;
      const parent = map.get(parentId);
      if (parent) {
        parentId = parent.parentId;
      } else {
        parentId = undefined;
      }
    }

    const wideSpace = "\u2003";
    // use non-breaking space to prevent trimming
    const isRoot = parentCount === 0;
    if (!isRoot) {
      let additionalSpacing = parentCount > 1;
      node.label =
        wideSpace.repeat(parentCount - 1) +
        (additionalSpacing ? wideSpace : "") +
        "\u2517" + // ┗
        "\u2501" + // ━
        "\u00A0" + // non-breaking space before text
        node.label;
    }
  });
  return nodesWithLabels;
};

export const preorderSortNodes = (nodes: NodeDto[]): NodeDto[] => {
  if (!nodes || nodes.length === 0) {
    return [];
  }
  // preorder sort nodes by parent id
  const map = new Map<number, NodeDto>();

  nodes.forEach((node) => {
    map.set(node.id, node);
  });

  const kendoTreeViewWithChildren = mapDataToKendoTreeView(nodes);

  // preorder traverse down the tree
  const sortedNodes: NodeDto[] = [];
  const preorderTraverse = (node: TreeViewDataItem) => {
    const nodeDto = map.get(node.id);
    if (nodeDto) {
      sortedNodes.push(nodeDto);
    }
    if (node.items) {
      node.items.forEach((child) => {
        preorderTraverse(child);
      });
    }
  };

  kendoTreeViewWithChildren.forEach((node) => {
    preorderTraverse(node);
  });
  return sortedNodes;
};

export const toggleCheckCustom = (
  checkedIndexes: string[],
  index: string
): string[] => {
  const indexStr = index;
  const indexFound = checkedIndexes.indexOf(indexStr);
  if (indexFound === -1) {
    checkedIndexes.push(indexStr);
  } else {
    checkedIndexes.splice(indexFound, 1);
  }
  // ensure new array returned for state management updates, since
  // we are using push and splice here
  return [...checkedIndexes];
};

export const toggleCheckId = (checkedIds: number[], id: number): number[] => {
  const indexFound = checkedIds.indexOf(id);
  if (indexFound === -1) {
    checkedIds.push(id);
  } else {
    checkedIds.splice(indexFound, 1);
  }
  return [...checkedIds];
};

export const filterTree = (
  tree: TreeViewDataItem[],
  filter: string
): TreeViewDataItem[] => {
  const filteredTree: TreeViewDataItem[] = [];
  for (let node of tree) {
    // drill down the node, only include this if children or node itself has matching text
    const nodeText = node.text.toLowerCase();
    const children = node.items;
    const childrenFiltered = children ? filterTree(children, filter) : [];
    if (
      nodeText.includes(filter.toLowerCase()) ||
      childrenFiltered.length > 0
    ) {
      const nodeCopy = { ...node };
      nodeCopy.items = childrenFiltered;
      filteredTree.push(nodeCopy);
    }
  }
  return filteredTree;
};

const preorderSearchRecurse = (
  node: TreeViewDataItem,
  searchText: string,
  currentIndex: number,
  previousIndex: string
): string[] => {
  let matches: string[] = [];
  const currIndexStr = !previousIndex
    ? currentIndex.toString()
    : previousIndex + "_" + currentIndex.toString();

  if (matchesText(node.text, searchText)) {
    matches.push(currIndexStr);
  }
  if (node.items) {
    for (let i = 0; i < node.items.length; i++) {
      const childMatches = preorderSearchRecurse(
        node.items[i],
        searchText,
        i,
        currIndexStr
      );
      if (childMatches.length > 0) {
        matches.push(...childMatches);
      }
    }
  }
  return matches;
};

export const preorderSearchArrayById = (
  nodes: TreeViewDataItem[],
  nodeType: NodeType,
  nodeId: number
): string[] => {
  let matches: string[] = [];
  for (let i = 0; i < nodes.length; i++) {
    const branchMatches = preorderSearchById(nodes[i], nodeType, nodeId, i, ``);
    matches.push(...branchMatches);
  }
  return matches;
};

export const preorderSearchById = (
  node: TreeViewDataItem,
  nodeType: NodeType,
  nodeId: number,
  currentIndex: number,
  previousIndex: string
): string[] => {
  let matches: string[] = [];
  const currIndexStr = !previousIndex
    ? currentIndex.toString()
    : previousIndex + "_" + currentIndex.toString();

  if (node.id === nodeId && node.nodeType === nodeType) {
    matches.push(currIndexStr);
  }
  if (node.items) {
    for (let i = 0; i < node.items.length; i++) {
      const childMatches = preorderSearchById(
        node.items[i],
        nodeType,
        nodeId,
        i,
        currIndexStr
      );
      if (childMatches.length > 0) {
        matches.push(...childMatches);
      }
    }
  }
  return matches;
};

export const matchesText = (text: string, searchText: string): boolean => {
  return text.toLowerCase().includes(searchText.toLowerCase());
};

export const computeAllAncestorIndexes = (indexes: string[]): string[] => {
  const dict = new Map<string, string>();
  // for all indexes, chop off rightmost segment and ensure exists
  for (let index of indexes) {
    let currIndex = index;
    // for index "0_1_0" we ensure "0", "0_1", "0_1_0" exist
    dict.set(currIndex, currIndex);
    while (currIndex.includes(SEPARATOR)) {
      currIndex = currIndex.substring(0, currIndex.lastIndexOf(SEPARATOR));
      dict.set(currIndex, currIndex);
    }
  }
  return Array.from(dict.values());
};

export const preorderSearchArray = (
  nodes: TreeViewDataItem[],
  searchText: string
): string[] => {
  let matches: string[] = [];
  for (let i = 0; i < nodes.length; i++) {
    const branchMatches = preorderSearch(nodes[i], searchText, i);
    matches.push(...branchMatches);
  }
  return matches;
};

export const preorderSearch = (
  node: TreeViewDataItem,
  searchText: string,
  index: number
): string[] => {
  return preorderSearchRecurse(node, searchText, index, "");
};

const SEPARATOR = "_";

export const findItemAndAncestorsByHierarchicalIndex = (
  data: TreeViewDataItem[],
  index: string
): TreeViewDataItem[] => {
  // returns multiple items, includes item and ancestors
  // index string is like "index1_index2_index3"
  const indices = index.split(SEPARATOR).map((index) => Number(index));
  let startNode: TreeViewDataItem = data[indices[0]];
  let currNode: TreeViewDataItem = startNode;
  let ancestors: TreeViewDataItem[] = [startNode];
  for (let i = 1; i < indices.length; i++) {
    currNode = currNode.items![indices[i]];
    ancestors.push(currNode);
  }
  if (!currNode) {
    throw new Error(`Expected currNode to be found ${index}`);
  }
  return ancestors;
};

export const findItemByHierarchicalIndex = (
  data: TreeViewDataItem[],
  index: string
): TreeViewDataItem => {
  // index string is like "index1_index2_index3"
  const indices = index.split(SEPARATOR).map((index) => Number(index));
  let startNode: TreeViewDataItem = data[indices[0]];
  let currNode: TreeViewDataItem = startNode;
  for (let i = 1; i < indices.length; i++) {
    currNode = currNode.items![indices[i]];
  }
  if (!currNode) {
    throw new Error(`Expected currNode to be found ${index}`);
  }
  return currNode;
};

export const mapFromHierarchyIndexToExpandedItems = (
  kendoTree: TreeViewDataItem[],
  searchResults: string[]
): TreeViewOperationDescriptor => {
  const expandedItemsDict = new Map<number, number>();

  searchResults.forEach((index) => {
    const items = findItemAndAncestorsByHierarchicalIndex(kendoTree, index);
    items.forEach((item) => {
      expandedItemsDict.set(item.id, item.id);
    });
  });
  return {
    ids: Array.from(expandedItemsDict.values()).map((id) => id.toString()),
    idField: "id",
  };
};

export const unionIndexes = (set1: string[], set2: string[]): string[] => {
  const union = new Set([...set1, ...set2]);
  return Array.from(union);
};

export const expandedItemsToHierarchicalIndex = (
  kendoTree: TreeViewDataItem[],
  expandedItems: TreeViewOperationDescriptor
): string[] => {
  if (!expandedItems.ids || expandedItems.ids.length === 0) {
    return [];
  }

  const foundIndexes: string[] = [];
  for (let id of expandedItems.ids) {
    const idNum = Number(id);
    const index = preorderSearchArrayById(kendoTree, NodeType.Node, idNum);
    foundIndexes.push(...index);
  }
  return foundIndexes;
};

// When checking items, filter out items that are not of the same nodeType
// since Nodes are edited separately from users
export const filterCheckToSameType = (
  check: string[] | TreeViewCheckDescriptor,
  nodeType: NodeType,
  tree: TreeViewDataItem[]
): string[] => {
  const asStringIndexes = check as string[];
  const filtered = asStringIndexes.filter((index) => {
    const item = findItemByHierarchicalIndex(tree, index);
    return item.nodeType === nodeType;
  });
  return filtered;
};

const formatNameText = (node: NodeDto, displayMeta: boolean): string => {
  let baseStr = `${node.name}`;
  if (displayMeta && node.details) {
    if (node.details.sortOrder) {
      baseStr += ` (sort:${node.details.sortOrder})`;
    }
    if (node.details.unselectable) {
      baseStr += ` (unselectable)`;
    }
  }
  return baseStr;
};

export const mapDataToKendoTreeView = (
  data: NodeDto[],
  showRoot: boolean = true,
  displayDetails: boolean = false
): TreeViewDataItem[] => {
  const map = new Map<number, TreeViewDataItem>();

  data.forEach((node) => {
    const dataItem: TreeViewDataItem = {
      text: formatNameText(node, displayDetails),
      items: [],
      nodeType: NodeType.Node,
      id: node.id,
      resourceId: node.id,
      parentNodeId: node.parentId,
      expanded: false,
      notSelectable: !!node.details?.unselectable,
      sortOrder: node.details?.sortOrder,
    };
    map.set(node.id, dataItem);
  });

  let rootNodes: TreeViewDataItem[] = [];
  data.forEach((node) => {
    const parentId = node.parentId;
    if (parentId && map.has(parentId)) {
      // identified as a leaf node because it has a parent in the map
      const parent = map.get(parentId)!;
      parent.items!.push(map.get(node.id)!);
    } else {
      // possible root
      const nodeFromMap = map.get(node.id);
      if (nodeFromMap) {
        rootNodes.push(nodeFromMap);
      }
    }
  });

  // sort the children of each node
  data.forEach((node) => {
    const kendoNode = map.get(node.id);
    if (kendoNode) {
      kendoNode.items = kendoNode.items!.sort((a, b) => {
        const aNumber = a.sortOrder !== undefined ? a.sortOrder : Number.MAX_SAFE_INTEGER;
        const bNumber = b.sortOrder !== undefined ? b.sortOrder : Number.MAX_SAFE_INTEGER;
        return aNumber - bNumber;
      });
    }
  });

  // add all users from the tree
  data.forEach((node) => {
    if (node.users && node.users.length > 0) {
      const kendoNode = map.get(node.id);
      if (kendoNode) {
        const mappedUsers = node.users.map((user) => {
          let item: TreeViewDataItem = {
            nodeType: NodeType.User,
            text: `${user.id} ${user.email}`,
            id: user.id,
            parentNodeId: node.id,
            resourceId: user.id,
            expanded: false,
          };
          return item;
        });
        const existingItems = kendoNode.items!;
        kendoNode.items = [...existingItems, ...mappedUsers];
      }
    }
  });
  if (rootNodes.length <= 0) {
    throw new Error("Root node not found");
  }

  // sort root nodes
  rootNodes = rootNodes.sort((a, b) => {
    const aNumber = a.sortOrder !== undefined ? a.sortOrder : Number.MAX_SAFE_INTEGER;
    const bNumber = b.sortOrder !== undefined ? b.sortOrder : Number.MAX_SAFE_INTEGER;
    return aNumber - bNumber;
  });
  if (rootNodes.length == 1) {
    if (showRoot) {
      return rootNodes;
    } else {
      return rootNodes[0].items!;
    }
  }
  // multiple roots
  return rootNodes;
};

export const getHierarchyParentPathsMap = (
  nodes: NodeDto[]
): Map<number, string> => {
  // make a map to speed up lookup
  const nodeMap = new Map<number, NodeDto>();
  for (const node of nodes) {
    nodeMap.set(node.id, node);
  }

  const pathsMap = new Map<number, string>();
  for (const node of nodes) {
    let path: string = node.name as string;
    let currNode = node;
    while (currNode.parentId && nodeMap.has(currNode.parentId)) {
      currNode = nodeMap.get(currNode.parentId) as NodeDto;
      path = `${currNode.name} > ${path}`;
    }
    pathsMap.set(node.id, path);
  }
  return pathsMap;
};
