import {
  Box,
  Text,
} from "@chakra-ui/react";
import {
  ColorPicker,
  ColorPickerChangeEvent,
} from "@progress/kendo-react-inputs";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";

export interface CharterBgColorSelectorProps {
  color: string;
  onBgColorSelect: (event: ColorPickerChangeEvent) => void;
}

export const CharterBgColorSelector = ({
  color,
  onBgColorSelect,
}: CharterBgColorSelectorProps) => {

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      border="1px solid #edf1f4"
      bg={"#edf1f4"}
      borderRadius="md"
      height={"40px"}
      pr={"8px"}
    >
      <Text fontSize={"md"} ml={"10px"} mr={"6px"} fontWeight={"semibold"}>
        {HVSportLocalizeStrings.CHARTER_BACKGROUND_COLOR}
      </Text>
      <Box position="relative">
        <ColorPicker value={color} onChange={onBgColorSelect} view="gradient" />
      </Box>
    </Box>
  );
};
