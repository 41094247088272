import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import {
  PollQuestionDto,
  PollQuestionSessionInputDto,
  PollQuestionUserInstanceDto,
  QuestionUserResponseDto,
  ResponseInputDto,
} from "@headversity/contract";

export const getPolls = (
  teamLessonId: number,
  token: string
): Promise<AxiosResponse<PollQuestionDto[]>> => {
  return AxiosClient.get(
    `api/polls/teamLesson/${teamLessonId}`,
    getConfig(token)
  );
};

const getPollInstancesDebounceObj: { timeOutId?: any; resolve?: any } = {};
export const getPollInstances = (
  token: string
): Promise<AxiosResponse<PollQuestionUserInstanceDto[] | null>> => {
  if (getPollInstancesDebounceObj.timeOutId) {
    clearTimeout(getPollInstancesDebounceObj.timeOutId);
    getPollInstancesDebounceObj.resolve(null);
    getPollInstancesDebounceObj.timeOutId = null;
    getPollInstancesDebounceObj.resolve = null;
  }
  return new Promise((resolve, reject) => {
    getPollInstancesDebounceObj.resolve = resolve;
    getPollInstancesDebounceObj.timeOutId = setTimeout(() => {
      AxiosClient.get(`api/pollInstances`, getConfig(token)).then(
        (result) => {
          getPollInstancesDebounceObj.timeOutId = null;
          getPollInstancesDebounceObj.resolve = null;
          resolve(result);
        },
        (error: Error) => {
          getPollInstancesDebounceObj.timeOutId = null;
          getPollInstancesDebounceObj.resolve = null;
          reject(error);
        }
      );
    }, 1000);
  });
};

export const getPollInstanceResult = (
  pollQuestionUserInstanceId: number,
  token: string
): Promise<AxiosResponse<QuestionUserResponseDto[]>> => {
  return AxiosClient.get(
    `api/pollInstance/${pollQuestionUserInstanceId}/result`,
    getConfig(token)
  );
};

export const getPollInstancesResult = (
  ids: number[],
  token: string
): Promise<AxiosResponse<PollQuestionUserInstanceDto[]>> => {
  return AxiosClient.get(
    `api/pollInstances/result?ids=${ids.join(",")}`,
    getConfig(token)
  );
};

export const setPollInstance = (
  pollQuestionId: number,
  teamLessonUserInstanceId: number,
  token: string
): Promise<AxiosResponse<PollQuestionUserInstanceDto>> => {
  return AxiosClient.post(
    `api/pollInstance/poll/${pollQuestionId}/teamLessonUserInstance/${teamLessonUserInstanceId}`,
    null,
    getConfig(token)
  );
};

export const createPollInstances = (
  pollQuestions: PollQuestionDto[],
  teamLessonUserInstanceId: number,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/pollInstance/teamLessonUserInstance/${teamLessonUserInstanceId}`,
    { pollQuestions },
    getConfig(token)
  );
};

export const setPollInstanceResult = (
  pollInstanceId: number,
  pollQuestionSessionInputDto: PollQuestionSessionInputDto,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/pollInstance/${pollInstanceId}/result`,
    {
      questionAlternativeIds:
        pollQuestionSessionInputDto.questionAlternativeIds,
      questionUserResponseIds:
        pollQuestionSessionInputDto.questionUserResponseIds,
      freeTexts: pollQuestionSessionInputDto.freeTexts,
      feedback: pollQuestionSessionInputDto.feedback,
    },
    getConfig(token)
  );
};

export const setPollInstancesResult = (
  pollQuestionSessionInputDtos: PollQuestionSessionInputDto[],
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/pollInstances/result`,
    pollQuestionSessionInputDtos,
    getConfig(token)
  );
};

// pollInstances/result

export const updatePollUserResponse = (
  questionUserResponseId: number,
  responseInputDto: ResponseInputDto,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.patch(
    `api/pollInstance/questionUserResponse/${questionUserResponseId}`,
    responseInputDto,
    getConfig(token)
  );
};

export const updatePollUserResponses = (
  pollQuestionUserInstanceId: number,
  responseInputDtos: ResponseInputDto[],
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.patch(
    `api/pollInstance/pollQuestionUserInstance/${pollQuestionUserInstanceId}/questionUserResponse`,
    responseInputDtos,
    getConfig(token)
  );
};
