import { useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { LessonsScrollPanel } from "../Shared/LessonsScrollPanel";
import { HVTestId } from "../../../Testing/dataTestIds";
import { ScrollPanelViewMoreLinkButton } from "../../Common/ScrollPanelViewMoreLinkButton";
import { useNavigate } from "react-router-dom";
import { EVENTS, track } from "../../../Utils/Analytics";
import {
  CompletionState,
  GoalDto,
  MicroLessonDto,
} from "../../../@headversity/contract";
import { getSuggestedLessonsForUser } from "../../../Utils/SkillsUtil";
import { getCustomTitle } from "../../../Utils/LessonsUtil";
import { VscBook } from "react-icons/vsc";

interface FeaturedLessonsPanelProps {
  isWide: boolean;
  isHigh: boolean;
  noSeeAll?: boolean;
  from: string;
}

export const FeaturedLessonsPanel = ({
  isWide,
  isHigh,
  noSeeAll,
  from,
}: FeaturedLessonsPanelProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const {
    lessons,
    skills,
    userSkillStats,
    lessonMap,
    getSuggestedLessons,
    company,
    inviteCode,
    selectedGoal,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [featuredLessons, setFeaturedLessons] = useState<MicroLessonDto[]>([]);
  const [customTitle, setCustomTitle] = useState<string | null>(null);
  const [customImage, setCustomImage] = useState<string | null>(null);

  const [lastGoal, setLastGoal] = useState<GoalDto | undefined>();

  useEffect(() => {
    if (lessons && skills && lessonMap && company && inviteCode) {
      if (
        featuredLessons.length === 0 ||
        (selectedGoal && selectedGoal !== lastGoal)
      ) {
        setLastGoal(selectedGoal);

        // always get custom lessons first
        const localFeaturedLessons = lessons.filter((l) => l.custom);

        const customTitle = getCustomTitle(inviteCode, company);

        const customImage =
          inviteCode &&
          inviteCode.customLessonIcon &&
          (inviteCode.customLessonIcon as string).trim().length > 0
            ? (inviteCode.customLessonIcon as string)
            : (company.customLessonIcon as string);

        let take = 5 - localFeaturedLessons.length;
        // check if there is a custom title set, if
        // there is any custom lesson, and we are on
        // the lessons page.  If these two condition
        // is met, show only the custom lessons
        if (customTitle && take < 5 && from === "Lessons") {
          setCustomTitle(customTitle);
          setCustomImage(customImage);
          setFeaturedLessons(localFeaturedLessons);
        } else {
          // we don't have 5 lessons yet, lets get not "done" featured lesson
          if (localFeaturedLessons.length < 5) {
            take = 5 - localFeaturedLessons.length;
            lessons
              .filter((l) => l.featured)
              .splice(0, take)
              .forEach((lesson) => {
                const doneLesson = lesson.microLessonUserInstances?.find(
                  (ml) => ml.state === CompletionState.done
                );

                if (
                  !doneLesson &&
                  !localFeaturedLessons.find((l) => l.id === lesson.id)
                ) {
                  localFeaturedLessons.push(lesson);
                }
              });
          }

          // we don't have 5 lessons yet, lets get suggested lesson
          if (localFeaturedLessons.length < 5) {
            const suggestedLesson = getSuggestedLessonsForUser(
              getSuggestedLessons,
              skills,
              userSkillStats,
              selectedGoal
            );
            suggestedLesson.forEach((sl) => {
              if (
                !localFeaturedLessons.find((l) => l.id === sl.id) &&
                localFeaturedLessons.length < 5
              ) {
                localFeaturedLessons.push(sl);
              }
            });
          }

          setFeaturedLessons(localFeaturedLessons);
        }
      } else {
        // pull latest lesson data, using existing order
        const newLessons: MicroLessonDto[] = [];

        for (const l of featuredLessons) {
          const lesson = lessons.find((x) => x.id === l.id);

          if (lesson) {
            newLessons.push(lesson);
          }
        }

        setFeaturedLessons(newLessons);
      }
    }
  }, [
    lessons,
    userSkillStats,
    skills,
    lessonMap,
    company,
    inviteCode,
    selectedGoal,
  ]);

  const navigate = useNavigate();

  return featuredLessons?.length === 0 ? (
    <> </>
  ) : (
    <>
      <BlockSection
        fadeIn={featuredLessons?.length > 0}
        title={
          <BlockSectionTitle
            title={
              customTitle
                ? customTitle
                : from === "Explore"
                ? HVLocalizeStrings.MICRO_LESSONS
                : HVLocalizeStrings.SOLO_HOME_SUGGESTED_TITLE_LESSON
            }
            description={
              !customTitle
                ? from === "Explore"
                  ? HVLocalizeStrings.SOLO_LEARN_DESCRIPTION + "."
                  : HVLocalizeStrings.SOLO_HOME_SUGGESTED_FOR_YOU_LESSON_DESCRIPTION
                : ""
            }
            image={customImage ? customImage : undefined}
          />
        }
        columns={1}
        gridClassName={"featured-lessons"}
        dataTestId={HVTestId.FeaturedLessonsPanel.container}
      >
        <LessonsScrollPanel
          isDesktop={isDesktop}
          lessonsToShow={featuredLessons}
          isWide={isWide}
          isHigh={isHigh}
          from={from}
          showPoints={true}
        />
        {!noSeeAll && (
          <ScrollPanelViewMoreLinkButton
            onClick={() => {
              navigate("/lessons");
              track(EVENTS.SeeAllLessonsClicked, { HV_From: from });
            }}
            isDesktop={isDesktop}
            iconType={VscBook}
          >
            {HVLocalizeStrings.SEE_ALL_LESSONS}
          </ScrollPanelViewMoreLinkButton>
        )}
      </BlockSection>
    </>
  );
};
