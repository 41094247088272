import { useCallback } from "react";
import { getAppVersion } from "../../Api/AppVersionApi";
import { getKey } from "../../Utils/Helpers";
import { currentAppVersion } from "../../Api/Utils";

export interface IAppVersionProvider {
  getAppVersionFromServer: () => Promise<string | void>;
}
export const useAppVersion = (): IAppVersionProvider => {
  const getAppVersionFromServer = useCallback(async () => {
    return await getAppVersion(getKey()).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        return Promise.resolve(currentAppVersion.value);
      }
    );
  }, []);
  return { getAppVersionFromServer };
};
