import React from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  BLOCK_PRIMARY_TEXT_COLOR,
  BLOCK_TITLE_GRADIENT,
  Border_Radius,
  CONTRAST_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../Testing/dataTestIds";
import { LocalizationText } from "@headversity/contract";
interface BlockContentProps {
  image: string | LocalizationText;
  title: string | LocalizationText;
  titleFontSize: string;
  descriptionFontSize: string;
  description: string;
  contentHeight?: string;
  contentBg?: string | null;
  contentOpacity?: string;
  contentHeadingColor?: string;
  contentDescriptionColor?: string;
  additionalContent?: any;
  icon?: string;
  contentPosition?: string;
  imageHeight?: string;
  clipImageY?: string;
  hoverItem?: any;
  isOver?: boolean;
}

export const PresentationBlockContent = (props: BlockContentProps) => {
  const {
    image,
    title,
    titleFontSize,
    descriptionFontSize,
    description,
    contentHeight,
    additionalContent,
    contentBg,
    icon,
    contentPosition,
    imageHeight,
    clipImageY,
    contentOpacity,
    contentHeadingColor,
    contentDescriptionColor,
    hoverItem,
    isOver,
  } = props;

  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <Box w={"100%"} h={"100%"} pos={"relative"} borderRadius={Border_Radius}>
      {hoverItem && !isMobile && (
        <Box
          color="white"
          p={4}
          pt={"10px"}
          textAlign="left"
          opacity={isOver ? 1 : 0}
          pos="absolute"
          zIndex={2}
          transition="0.2s all ease-in-out"
          userSelect={"none"}
          pointerEvents={"none"}
        >
          {hoverItem}
        </Box>
      )}
      {image && (
        <Image
          pos={"absolute"}
          src={image as string}
          w={"100%"}
          h={imageHeight ?? "100%"}
          borderRadius={"10px"}
          clipPath={
            clipImageY
              ? `polygon(0% 0%, 100% 0%, 100% ${clipImageY}, 0% ${clipImageY})`
              : ""
          }
          alt=""
        />
      )}
      {additionalContent}
      <Box
        bg={contentBg ?? BLOCK_TITLE_GRADIENT}
        pos={"absolute"}
        bottom={contentPosition ?? "0"}
        opacity={contentOpacity ?? 0.7}
        h={contentHeight ?? "25%"}
        w={"100%"}
        borderBottomRadius={Border_Radius}
      ></Box>
      <Flex
        pos={"absolute"}
        bottom={contentPosition ?? "0"}
        h={contentHeight ?? "25%"}
        w={"100%"}
        justify={"center"}
        align={"flex-start"}
        flexDir={"column"}
        borderBottomRadius={Border_Radius}
        px={"12px"}
      >
        <Heading
          color={contentHeadingColor ?? BLOCK_PRIMARY_TEXT_COLOR}
          fontSize={titleFontSize}
          fontWeight={"600"}
          textAlign={"left"}
          mr={"25px"}
          data-testid={HVTestId.BlockContent.titleText}
          lineHeight={"1.2"}
        >
          {title as string}
        </Heading>
        <Text
          color={contentDescriptionColor ?? CONTRAST_TEXT_COLOR}
          fontSize={descriptionFontSize}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
          textAlign={"left"}
          data-testid={HVTestId.BlockContent.descriptionText}
        >
          {icon && (
            <Image
              src={icon}
              display={"inline"}
              h={"24px"}
              w={"auto"}
              ms={"20px"}
              filter={
                "invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)"
              }
              alt=""
            />
          )}
        </Text>
      </Flex>
    </Box>
  );
};
