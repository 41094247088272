import {
  LocalStorageKeys,
  LocalStorageWrapper,
} from "../../../Utils/StorageUtil";
import { PostLoginGuard } from "../Guard/PostLoginGuard";
import { useEffect } from "react";

export const MobilePostLoginPage = () => {
  return (
    <PostLoginGuard>
      <RedirectToMobile />
    </PostLoginGuard>
  );
};

const RedirectToMobile = () => {
  useEffect(() => {
    LocalStorageWrapper.getItem(LocalStorageKeys.RT).then((rt) => {
      window.location.replace(
        `${
          process.env.REACT_APP_DEEP_LINK_URL
        }/waiting?dt=${sessionStorage.getItem("dt")}&rt=${rt}`
      );
    });
  }, []);
  return <></>;
};
