import {
  HelpResourceChannelType,
  HelpResourceDto,
} from "@headversity/contract";
import { useCallback, useState } from "react";
import { getKey } from "../../Utils/Helpers";
import {
  getHelpResourcesForUser,
  trackHelpResourceChannelClick,
  trackHelpResourceView,
} from "../../Api/Solo/GetHelpResourceApi";

export interface IHelpResourceProvider {
  helpResourcesForUser: HelpResourceDto[];
  getHelpResourcesForUserFromServer: () => void;
  trackHelpResourceViewFromServer: (helpResourceId: number) => void;
  trackHelpResourceChannelClickFromServer: (
    helpResourceId: number,
    channelType: HelpResourceChannelType
  ) => void;
}

export const useHelpResource = (): IHelpResourceProvider => {
  const [helpResourcesForUser, setHelpResourcesForUser] = useState<
    HelpResourceDto[]
  >([]);

  const getHelpResourcesForUserFromServer = useCallback(async () => {
    return getHelpResourcesForUser(getKey()).then((response) => {
      setHelpResourcesForUser(response.data);
    });
  }, []);

  const trackHelpResourceViewFromServer = useCallback(
    async (helpResourceId: number) => {
      return await trackHelpResourceView(getKey(), helpResourceId);
    },
    []
  );

  const trackHelpResourceChannelClickFromServer = useCallback(
    async (helpResourceId: number, channelType: HelpResourceChannelType) => {
      return await trackHelpResourceChannelClick(
        getKey(),
        helpResourceId,
        channelType
      );
    },
    []
  );

  return {
    helpResourcesForUser,
    getHelpResourcesForUserFromServer,
    trackHelpResourceViewFromServer,
    trackHelpResourceChannelClickFromServer,
  };
};
