import { LandingContainer } from "../Shared/LandingContainer";
import { useContext, useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { onPusherEvent } from "../../../Utils/PusherChannelNotifications";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { useNavigate } from "react-router";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { TEAMS_REDIRECT_URL_SLUG } from "../../../Utils/LoginUtil";
import { getKey } from "../../../Utils/Helpers";

export const WaitingToLoginPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pusherClient, loginWithMsTeamsTokenToServer, navigateToLogin } =
    useContext<IGlobalProvider>(GlobalContext);
  const [isMsTeam, setIsMsTeam] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (searchParams.get("channelName")) {
      setLoading(false);
      const channelName = searchParams.get("channelName");
      if (channelName && pusherClient) {
        return onPusherEvent(
          pusherClient,
          "passwordCode",
          [pusherClient.subscribe(channelName)],
          (data: any, channelName: string) => {
            if (data.certId) {
              sessionStorage.setItem(
                "resourceLock",
                JSON.stringify({
                  resourceType: "cert",
                  resourceId: parseInt(data.certId),
                })
              );
            }
            if (data.code) {
              navigate(`/validate-login?code=${data.code}&ignorePusher=true`, {
                replace: data.certId ? true : undefined,
              });
            }
          }
        );
      }
    } else if (searchParams.get("isMsTeam")) {
      setLoading(false);
      setIsMsTeam(true);
    }
  }, [searchParams]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.type === TEAMS_REDIRECT_URL_SLUG) {
        const token = event.data.token;
        const redirectUrl = event.data.deepLinkParameter
          ? window.atob(event.data.deepLinkParameter)
          : sessionStorage.getItem("msTeamsLastUrl") ?? "/";
        if (getKey()) {
          sessionStorage.setItem("channel", "ms-teams");
          window.location.href = redirectUrl;
        } else if (token) {
          loginWithMsTeamsTokenToServer(token)
            .then((authTokenDto) => {
              sessionStorage.setItem("channel", "ms-teams");
              sessionStorage.setItem("redirectPath", redirectUrl);
              window.location.href = `/accessToken?dt=${authTokenDto.access_token}&refreshToken=${authTokenDto.refresh_token}`;
            })
            .catch(() => {
              navigateToLogin();
            });
        }
      }
    };

    window.addEventListener("message", handleMessage);
    window.parent.postMessage({ type: "page-ready" }, "*");

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  if (isMsTeam || loading) {
    return <></>;
  }

  return (
    <LandingContainer other={<></>}>
      <Flex
        flexDir={"column"}
        align={"center"}
        gap={"2"}
        color={PRIMARY_TEXT_COLOR}
      >
        <Text
          maxW={"345px"}
          dangerouslySetInnerHTML={{
            __html: HVLocalizeStrings.CHECK_EMAIL_TO_LOGIN.replace(
              "{email}",
              searchParams.get("email") ?? ""
            ),
          }}
        />
      </Flex>
    </LandingContainer>
  );
};
