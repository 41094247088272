import {
  Box,
  Center,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { SHIELD_MODAL_SECONDARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { IconType } from "react-icons/lib";
import {
  FiArrowUpCircle,
  FiClock,
  FiZap,
  FiRss,
  FiCalendar,
} from "react-icons/fi";
import { ScrollPanel } from "../ScrollPanel";
import { ScrollPanelBlock } from "../ScrollPanelBlock";
import { getListingCommaString } from "../../../Utils/StringUtils";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../../../State/GlobalContext";
import { HVTestId } from "../../../Testing/dataTestIds";
import { VscBook } from "react-icons/vsc";

export interface BadgeData {
  /** skillId === -1 means the "show the intro screen"; if this is not a DTO, add a property instead */
  skillId: number;
  iconUrl: string | undefined;
  shieldName: string | undefined;
  shieldColor: string | undefined;
  shieldOutlineUrl?: string;
  levelledUp: boolean;
  previousPointsOnLevel: number;
  newPointsOnLevel: number;
  newLevel: number;
  totalLevels: number;
  lastActivity: string;
  minutesTrained: number;
  lessonsCompleted: number;
  practicesCompleted: number;
  postsCompleted: number;
  lastLevelledUp: string;
}

interface BadgeCardProps {
  badgeData: BadgeData[];
  setCurrentItem?: (item: number) => void;
  isSkillPage?: boolean;
  onClickLink?: () => void;
}

export const BadgeCardScrollPanel = (props: BadgeCardProps) => {
  const { badgeData, setCurrentItem, isSkillPage, onClickLink } = props;

  const { skills } = useContext(GlobalContext);

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const namesBolded = badgeData
    .filter((x) => x.skillId !== -1)
    .map((x) => `<b style="fontWeight: 600">${x.shieldName}</b>`);

  const namesList = getListingCommaString(
    namesBolded,
    HVLocalizeStrings.AND,
    3,
    HVLocalizeStrings.AND_MORE
  );

  return (
    <ScrollPanel
      scrollOnMobileOnly={false}
      showChevronContainer={true}
      invertChevronContainer={true}
      spaceBetween={16}
      slidesPerGroup={1}
      slidesPerView={"auto"}
      onSlideChangeCallBack={(i: number) => {
        if (badgeData?.length > i) {
          setCurrentItem?.(i);
        }
      }}
    >
      {badgeData.map((item: BadgeData) => {
        const slug = skills.find((x) => x.id === item.skillId)?.slug;
        const name = skills.find((x) => x.id === item.skillId)?.name.toString();

        return ScrollPanelBlock(
          <Box
            px={isSkillPage ? undefined : isMobile ? 5 : 10}
            data-testid={`${HVTestId.EarnBadgeCard.container}_${item.skillId}`}
          >
            {item.skillId === -1 ? (
              <>
                <Text
                  textAlign={"center"}
                  mt={4}
                  fontSize={"xl"}
                  fontWeight={"600"}
                >
                  {HVLocalizeStrings.EARN_MODAL_INITIAL_MESSAGE_HEADLINE}
                </Text>
                <Text mt={8} fontSize={"sm"}>
                  {HVLocalizeStrings.EARN_MODAL_INITIAL_MESSAGE_BODY}
                </Text>
                <Text mt={4} fontSize={"sm"}>
                  {HVLocalizeStrings.EARN_MODAL_INITIAL_MESSAGE_CONTINUE_TO_SEE}{" "}
                  <span
                    dangerouslySetInnerHTML={{ __html: namesList ?? "" }}
                  ></span>
                  .
                </Text>
              </>
            ) : (
              <>
                <ShieldStatItem
                  icon={FiCalendar}
                  statName={HVLocalizeStrings.LAST_ACTIVITY}
                  statValue={item.lastActivity}
                  iconColor={item.shieldColor}
                  dataTestId={HVTestId.EarnBadgeCard.lastActivity}
                />
                <ShieldStatItem
                  icon={FiArrowUpCircle}
                  statName={HVLocalizeStrings.SHIELD_LAST_LEVELED_UP}
                  statValue={item.lastLevelledUp}
                  iconColor={item.shieldColor}
                />
                {isSkillPage && (
                  <ShieldStatItem
                    icon={FiClock}
                    statName={HVLocalizeStrings.MINUTES_TRAINED}
                    statValue={item.minutesTrained.toString()}
                    iconColor={item.shieldColor}
                    dataTestId={HVTestId.EarnBadgeCard.minutesTrained}
                  />
                )}
                <ShieldStatItem
                  icon={FiZap}
                  statName={HVLocalizeStrings.STAT_PRACTICES_COMPLETE}
                  statValue={item.practicesCompleted.toString()}
                  iconColor={item.shieldColor}
                  dataTestId={HVTestId.EarnBadgeCard.practicesCompleted}
                />
                <ShieldStatItem
                  icon={VscBook}
                  statName={HVLocalizeStrings.STAT_LESSONS_COMPLETE}
                  statValue={item.lessonsCompleted.toString()}
                  iconColor={item.shieldColor}
                  dataTestId={HVTestId.EarnBadgeCard.lessonsCompleted}
                />
                <ShieldStatItem
                  icon={FiRss}
                  statName={HVLocalizeStrings.STAT_POSTS_VIEWED}
                  statValue={item.postsCompleted.toString()}
                  iconColor={item.shieldColor}
                  dataTestId={HVTestId.EarnBadgeCard.postsCompleted}
                />
                {slug && name && !isSkillPage && (
                  <Center
                    mt={badgeData.length === 1 ? "30px" : "25px"}
                    fontSize="12px"
                    textDecoration={"underline"}
                  >
                    {" "}
                    <Link
                      onClick={onClickLink}
                      to={`/skills/${slug}`}
                      dangerouslySetInnerHTML={{
                        __html: HVLocalizeStrings.SEE_MORE_DETAILS.replace(
                          "{0}",
                          name
                        ),
                      }}
                      data-testid={`${HVTestId.EarnBadgeCard.seeMoreLink}_${item.skillId}`}
                    />
                  </Center>
                )}
              </>
            )}
          </Box>,
          "100%",
          item.skillId
        );
      })}
    </ScrollPanel>
  );
};

interface ShieldStatItemProps {
  icon: IconType;
  statName: string;
  statValue: string;
  iconColor: string | undefined;
  dataTestId?: string;
}

const ShieldStatItem = (props: ShieldStatItemProps) => {
  const { icon, statName, statValue, iconColor, dataTestId } = props;
  return (
    <Flex mt={3} gap={2} fontSize={"14px"}>
      <Icon
        w={"16px"}
        h={"18px"}
        aria-label={"like"}
        color={iconColor}
        as={icon}
      />
      <SimpleGrid columns={2} gap={14}>
        <Text align={"start"} width={"200px"}>
          {statName}
        </Text>
        <Text
          color={SHIELD_MODAL_SECONDARY_TEXT_COLOR}
          align={"end"}
          fontWeight={"600"}
          data-testid={dataTestId}
        >
          {statValue}
        </Text>
      </SimpleGrid>
    </Flex>
  );
};
