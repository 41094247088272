import { Box, Flex, Grid, GridItem, SimpleGrid } from "@chakra-ui/react";
import { AvailableReportsDto } from "@headversity/contract";
import { useContext } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Interval180Days,
  ReachInsightsContext,
} from "../../../State/Reach/ReachInsightsContext";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import {
  Azure,
  Equity_Blue_66,
  Ice_Blue,
  Light_Lavender,
  Light_Blue,
  Mid_Blue,
  Teal_Light,
} from "../../../Styles/HeadversityStyle";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import InsightsCard from "../../Common/Cards/InsightsCard";
import InsightsNoData from "./InsightsNoData";
import { InsightsPercentProgressDisplay } from "./InsightsPercentProgressDisplay";
import { InsightsPieChart } from "./InsightsPieChart";
import { InsightsPopularDisplay } from "./InsightsPopularDisplay";
import InsightsLoadingSpinner from "./InsightsLoadingSpinner";
import SelectedPursuitsBarChart from "./SelectedPursuitsBarChart";

const buildTitle = (availableReports: AvailableReportsDto) => {
  let components = [];
  if (availableReports.solo) {
    components.push(HVLocalizeStrings.APP_GRID_SOLO);
  }
  if (availableReports.team) {
    components.push(HVLocalizeStrings.APP_GRID_TEAM);
  }
  // join components into a string with 'and' between
  return components.join(` ${HVLocalizeStrings.AND} `);
};

interface InsightsSoloAndTeamPanelProps {
  pdfExport?: boolean;
}
const InsightsSoloAndTeamPanel = (props: InsightsSoloAndTeamPanelProps) => {
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);
  const { soloAndTeamReport, availableReports } =
    useContext(ReachInsightsContext);
  const pieChartDimensions = { width: 300, height: 300 };
  const containerSize =
    props.pdfExport || isMainContainerXL ? "container.xl" : "container.lg";
  const numPopularPanels = availableReports.team ? 3 : 2;

  const insightsAndSoloPanelBody = () => {
    const gridTemplateColumns = {
      base: "repeat(1, 1fr)",
      sm: "repeat(1, 1fr)",
      md: "repeat(2, 1fr)",
      lg: "repeat(2, 1fr)",
      xl: "repeat(3, 1fr)",
    };
    const getMinWidth = () => {
      if (soloAndTeamReport.userFeedback.length >= 3) {
        return "275px";
      }
      return undefined;
    };

    return (
      <Flex flexDirection={"column"} gap={6}>
        <Grid
          gap={6}
          templateRows={"repeat(auto-fill, minmax(0, 1fr))"} // Adjusts rows automatically based on content
          templateColumns={gridTemplateColumns}
        >
          <GridItem
            rowSpan={1}
            colSpan={1}
            minWidth={getMinWidth()}
            minHeight={
              isMobile && soloAndTeamReport.userFeedback.length >= 3
                ? "450px"
                : undefined
            }
          >
            <InsightsCard
              title={HVLocalizeStrings.REACH_INSIGHTS_USER_FEEDBACK}
              minH={"450px"}
              subtitle={
                soloAndTeamReport.intervalDays > Interval180Days
                  ? HVLocalizeStrings.REACH_INSIGHTS_ALL_TIME
                  : HVLocalizeStrings.REACH_INSIGHTS_ENROLMENT_TRENDS_LAST_180_DAYS
              }
              content={
                soloAndTeamReport.lowDataSoloActivityUserFeedback ? (
                  <InsightsNoData
                    description={
                      HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                    }
                  />
                ) : (
                  <Flex
                    h={pieChartDimensions.height}
                    flexDir="column"
                    mt={
                      !isMobile && soloAndTeamReport.userFeedback.length >= 3
                        ? "20px"
                        : undefined
                    }
                  >
                    <InsightsPercentProgressDisplay
                      data={soloAndTeamReport.userFeedback}
                      color={Teal_Light}
                    />
                  </Flex>
                )
              }
            />
          </GridItem>
          <GridItem colSpan={1}>
            <InsightsCard
              title={HVLocalizeStrings.REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES}
              minH={"450px"}
              subtitle={
                soloAndTeamReport.intervalDays > Interval180Days
                  ? HVLocalizeStrings.REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES_DESCRIPTION_ALL_TIME
                  : HVLocalizeStrings.REACH_INSIGHTS_BREAKDOWN_OF_ACTIVITIES_DESCRIPTION
              }
              content={
                soloAndTeamReport.lowDataSoloActivityBreakdown ? (
                  <InsightsNoData
                    description={
                      HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                    }
                  />
                ) : (
                  <InsightsPieChart
                    data={soloAndTeamReport.activitiesBreakdown}
                    width={pieChartDimensions.width}
                    height={pieChartDimensions.height}
                  />
                )
              }
            />
          </GridItem>
          <GridItem
            colSpan={1}
            height={{ base: "400px", md: "auto" }}
            minHeight={
              !isMobile && !soloAndTeamReport.lowDataSoloSelectedPursuits
                ? "425px"
                : undefined
            }
          >
            <SelectedPursuitsBarChart
              title={HVLocalizeStrings.REACH_INSIGHTS_SELECTED_PURSUITS}
              subtitle={HVLocalizeStrings.REACH_INSIGHTS_LAST_180_DAYS}
              data={soloAndTeamReport.selectedPursuits}
            />
          </GridItem>
        </Grid>
        <SimpleGrid
          gap={6}
          columns={{
            base: props.pdfExport ? numPopularPanels : 1,
            md: numPopularPanels,
          }}
        >
          <Box>
            <InsightsCard
              title={HVLocalizeStrings.REACH_INSIGHTS_POPULAR_NANO_PRACTICES}
              subtitle={
                soloAndTeamReport.intervalDays > Interval180Days
                  ? HVLocalizeStrings.REACH_INSIGHTS_ALL_TIME
                  : HVLocalizeStrings.REACH_INSIGHTS_ENROLMENT_TRENDS_LAST_180_DAYS
              }
              content={
                soloAndTeamReport.lowDataSoloNanoPracticeCounts ? (
                  <InsightsNoData
                    description={
                      HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                    }
                  />
                ) : (
                  <InsightsPopularDisplay
                    items={soloAndTeamReport.popularNanoPractice}
                    colorBorder={Light_Blue}
                    colorBackground={Ice_Blue}
                  />
                )
              }
            />
          </Box>
          <Box>
            <InsightsCard
              title={HVLocalizeStrings.REACH_INSIGHTS_POPULAR_MICRO_LESSONS}
              subtitle={
                soloAndTeamReport.intervalDays > Interval180Days
                  ? HVLocalizeStrings.REACH_INSIGHTS_ALL_TIME
                  : HVLocalizeStrings.REACH_INSIGHTS_ENROLMENT_TRENDS_LAST_180_DAYS
              }
              content={
                soloAndTeamReport.lowDataSoloMicroLessonCounts ? (
                  <InsightsNoData
                    description={
                      HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                    }
                  />
                ) : (
                  <InsightsPopularDisplay
                    items={soloAndTeamReport.popularMicroLessons}
                    colorBorder={Mid_Blue}
                    colorBackground={Light_Lavender}
                  />
                )
              }
            />
          </Box>
          <Box>
            {availableReports.team && (
              <InsightsCard
                title={
                  HVLocalizeStrings.REACH_INSIGHTS_POPULAR_TEAM_PRESENTATIONS
                }
                subtitle={
                  soloAndTeamReport.intervalDays > Interval180Days
                    ? HVLocalizeStrings.REACH_INSIGHTS_ALL_TIME
                    : HVLocalizeStrings.REACH_INSIGHTS_ENROLMENT_TRENDS_LAST_180_DAYS
                }
                content={
                  soloAndTeamReport.lowDataTeamLessonCount ? (
                    <InsightsNoData
                      description={
                        HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION
                      }
                    />
                  ) : (
                    <InsightsPopularDisplay
                      items={soloAndTeamReport.popularTeamLessons}
                      colorBorder={Equity_Blue_66}
                      colorBackground={Azure}
                    />
                  )
                }
              />
            )}
          </Box>
        </SimpleGrid>
      </Flex>
    );
  };

  return (
    <Box
      maxW={containerSize}
      mx={"auto"}
      mt={isDesktop ? "40px" : "20px"}
      mb={"20px"}
    >
      <BlockSectionTitle
        title={buildTitle(availableReports)}
        description={HVLocalizeStrings.REACH_INSIGHTS_SOLO_AND_TEAM_DESCRIPTION}
        descriptionMarginTop={3}
      ></BlockSectionTitle>
      {soloAndTeamReport.isLoading ? (
        <InsightsLoadingSpinner containerSize={containerSize} />
      ) : (
        insightsAndSoloPanelBody()
      )}
    </Box>
  );
};

export default InsightsSoloAndTeamPanel;
