import { useCallback, useState } from "react";
import {
  getBlogById,
  getBlogs,
  saveBlogQuestionAnswers,
  saveBlogView,
} from "../../Api/Solo/BlogsApi";
import { getKey } from "../../Utils/Helpers";
import {
  BlogPostDto,
  QuestionAnswerDto,
  UserStatsResultsDto,
} from "@headversity/contract";
import { getLocalizedText } from "../../Utils/LanguageUtil";

export interface IBlogPostProvider {
  allResilienceFeedPosts: BlogPostDto[];
  getBlogsFromServer: () => void;
  getBlogByIdFromServer: (blogPostId: number) => Promise<BlogPostDto>;
  saveBlogViewToServer: (blogPostId: number) => Promise<void>;
  saveBlogQuestionAnswersToServer: (
    blogPostId: number,
    questionAnswers: QuestionAnswerDto[]
  ) => void;
  globalBlogPost: BlogPostDto | undefined;
  setGlobalBlogPost: (blogPost?: BlogPostDto) => void;
  setGlobalBlogPostCollection: (items: BlogPostDto[]) => void;
  globalBlogPostCollection: BlogPostDto[];
}

export const useBlogPost = (
  setUserStatsToServer: () => Promise<UserStatsResultsDto>
): IBlogPostProvider => {
  const [globalBlogPost, setGlobalBlogPost] = useState<
    BlogPostDto | undefined
  >();

  const [allResilienceFeedPosts, setAllResilienceFeedPosts] = useState<
    BlogPostDto[]
  >([]);

  const [globalBlogPostCollection, setGlobalBlogPostCollection] = useState<BlogPostDto[]>([]);

  const getBlogsFromServer = useCallback(async () => {
    return getBlogs(getKey()).then((response) => {
      setAllResilienceFeedPosts(
        response.data.filter((blog: BlogPostDto) =>
          getLocalizedText(blog.name_loc)
        )
      );
    });
  }, []);

  const getBlogByIdFromServer = useCallback(
    async (blogPostId: number): Promise<BlogPostDto> => {
      return getBlogById(getKey(), blogPostId).then((response: any) => {
        return response.data;
      });
    },
    []
  );

  const saveBlogViewToServer = useCallback(async (blogPostId: number) => {
    return saveBlogView(getKey(), blogPostId).then((response) => {
      return getBlogsFromServer().then(() => {
        setUserStatsToServer();
      });
    });
  }, []);

  const saveBlogQuestionAnswersToServer = useCallback(
    async (blogPostId: number, questionAnswers: QuestionAnswerDto[]) => {
      return saveBlogQuestionAnswers(getKey(), blogPostId, questionAnswers);
    },
    []
  );

  return {
    allResilienceFeedPosts,
    getBlogsFromServer,
    getBlogByIdFromServer,
    saveBlogViewToServer,
    saveBlogQuestionAnswersToServer,
    globalBlogPost,
    setGlobalBlogPost,
    globalBlogPostCollection,
    setGlobalBlogPostCollection,
  };
};
