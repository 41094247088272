import { drawDOM, DrawOptions, exportPDF } from "@progress/kendo-drawing";
import { isIOS, isSafari } from "react-device-detect";

export const pdfExport = (
  pdfRef: any,
  drawOptions?: DrawOptions | undefined,
  downloadFileName: string = "download.pdf"
) => {
  if (isIOS && isSafari) {
    let element = document.querySelector(".pdfExport") as HTMLElement;
    drawDOM(element, drawOptions)
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        let data =
          "data:application/octet-stream;base64," +
          dataUri.split(";base64,")[1];
        const anchor = document.createElement("a");
        anchor.setAttribute("href", data);
        anchor.setAttribute("download", downloadFileName);
        anchor.click();

        setTimeout(() => {
          URL.revokeObjectURL(anchor.href);
        }, 7000);
      });
  } else if (pdfRef.current) {
    pdfRef.current.save();
  }
};
