import { ReachUsersContextProvider } from "../../../State/Reach/ReachUsersContext";
import { ReachUsersPage } from "../ReachUsersPage";

export const ReachUsersPageContainer = () => {
  return (
    <ReachUsersContextProvider>
      <ReachUsersPage />
    </ReachUsersContextProvider>
  );
};
