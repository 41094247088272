import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import {
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  CONTRAST_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { isWebPlatform } from "../../Utils/mobileUtils";

interface StickyBreadcrumbProps {
  crumbs: any[];
  current: string;
}

export const StickyBreadcrumb = (props: StickyBreadcrumbProps) => {
  const { crumbs, current } = props;

  const [color, setColor] = useState<string>("");
  const [needsPadding, setNeedsPadding] = useState<boolean>(false);

  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);
  const [is1140] = useMediaQuery("(min-width: 1140px)");
  const [is1380] = useMediaQuery("(min-width: 1380px)");

  const [containerScrollPosition, setContainerScrollPosition] =
    useState<number>(0);

  useEffect(() => {
    const hasRoomWithHamburger =
      (isMainContainerXL && is1380) || (!isMainContainerXL && is1140);

    setNeedsPadding(hasRoomWithHamburger);

    setColor(
      containerScrollPosition > 241
        ? CONTRAST_TEXT_COLOR
        : BLOCK_SECTION_PRIMARY_TEXT_COLOR
    );
  }, [containerScrollPosition, is1140, is1380, isMainContainerXL]);

  useEffect(() => {
    const handleScroll = (e: any) => {
      const pos = e.currentTarget.scrollingElement.scrollTop;

      setContainerScrollPosition(pos);
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return !isWebPlatform() ? (
    <></>
  ) : (
    <Box
      className="no-ease"
      maxW={isMainContainerXL ? "container.xl" : "container.lg"}
      mx={"auto"}
      pl={needsPadding ? 0 : 5}
      pb={5}
      paddingLeft={5}
      position={needsPadding ? "sticky" : "relative"}
      top={needsPadding ? "20px" : "auto"}
      zIndex={needsPadding && containerScrollPosition >= 230 ? 101 : "auto"}
      clipPath={"polygon(0px 0px, 500px 0px, 500px 70px, 0px 70px)"}
    >
      <Breadcrumb separator={">"} fontSize={"13px"} color={color}>
        {crumbs.map((item, index) => {
          return (
            <BreadcrumbItem key={index}>
              <Link to={item.url}>
                <u>{item.text}</u>
              </Link>
            </BreadcrumbItem>
          );
        })}

        <BreadcrumbItem>
          <Text>{current}</Text>
        </BreadcrumbItem>
      </Breadcrumb>
    </Box>
  );
};
