import { CharterActivity, PathTeamLessonDto } from "@headversity/contract";
import { ParticipantGroupStep } from "./ParticipantGroupStep";
import { ParticipantWeStatementStepOne } from "./ParticipantWeStatementStepOne";
import { ParticipantWeStatementStepTwo } from "./ParticipantWeStatementStepTwo";
import { ParticipantAmbassador } from "./ParticipantAmbassador";
import { ParticipantBestWorstStepOne } from "./ParticipantBestWorstStepOne";
import { ParticipantBestWorstStepTwo } from "./ParticipantBestWorstStepTwo";
import { ParticipantScenario } from "./ParticipantScenario";
import { ParticipantCharterStep } from "./ParticipantCharterStep";
import { ParticipantTeamValues } from "./ParticipantTeamValues";

interface ViewFactoryProps {
  activity: CharterActivity;
  pathTeamLesson: PathTeamLessonDto;
}
export const ViewFactory = (props: ViewFactoryProps) => {
  const { activity, pathTeamLesson } = props;

  switch (activity) {
    case CharterActivity.Grouping:
      return <ParticipantGroupStep pathTeamLesson={pathTeamLesson} />;
    case CharterActivity.TeamValues:
      return <ParticipantTeamValues />;
    case CharterActivity.BestWorstStepOne:
      return <ParticipantBestWorstStepOne />;
    case CharterActivity.BestWorstStepTwo:
    case CharterActivity.BestWorstStepTwoResults:
      return <ParticipantBestWorstStepTwo />;
    case CharterActivity.WeStatementStepOne:
    case CharterActivity.WeStatementStepOneBreak:
      return <ParticipantWeStatementStepOne pathTeamLesson={pathTeamLesson} />;
    case CharterActivity.WeStatementStepTwo:
      return <ParticipantWeStatementStepTwo />;
    case CharterActivity.CharterCreation:
      return <ParticipantCharterStep />;
    case CharterActivity.Scenarios:
    case CharterActivity.ScenarioReviews:
      return <ParticipantScenario pathTeamLesson={pathTeamLesson} />;
    case CharterActivity.SelectingCharterChampions:
      return <ParticipantAmbassador />;
    default:
      return <></>;
  }
};
