import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
} from "../../../State/GlobalContext";
import {
  BLOCK_PRIMARY_TEXT_COLOR,
  BLOCK_TITLE_GRADIENT,
} from "../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../Utils/Analytics";
import { getLessonCompletionDateAndStatus } from "../../../Utils/LessonsUtil";
import {
  getSkillId,
  getSkillName,
  needsSkillScore,
} from "../../../Utils/SkillsUtil";
import { ContentBlock20 } from "../Shared/ContentBlock20";
import { HVTestId } from "../../../Testing/dataTestIds";
import { CompletionState, MicroLessonDto } from "@headversity/contract";
import { cloneAndTranslate } from "../../../Utils/ObjectUtil";

interface LessonBlock20Props {
  lesson: MicroLessonDto;
  fullDescription?: boolean;
  width?: string;
  height?: string;
  noHover?: boolean;
  isSmall?: boolean;
  overrideCompletionState?: CompletionState | undefined;
  from?: string;
  showPoints?: boolean;
  hideDone?: boolean;
  descriptionColor?: string;
  isPortrait?: boolean;
  onClickOverride?: () => void;
}

export const LessonBlock20 = ({
  lesson,
  fullDescription,
  width,
  height,
  noHover,
  isSmall,
  overrideCompletionState,
  from,
  showPoints,
  hideDone,
  descriptionColor,
  isPortrait,
  onClickOverride,
}: LessonBlock20Props) => {
  let {
    skills,
    currentApp,
    setSelectedSkill,
    setGlobalHeadScanQuestionModalOpen,
    setIsLessonPreviewOpen,
    userSkillStats,
    setGlobalLesson,
    getLessonsFromServer,
    clearGlobalActivities,
    setOpenedActivityFromStartTraining,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [completionState, setCompletionState] = useState<number>(-1);

  useEffect(() => {
    if (lesson) {
      const { state } = getLessonCompletionDateAndStatus(lesson);

      setCompletionState(state);
    }
  }, [lesson]);

  const skillToUse =
    currentApp !== AppType.CERT
      ? skills.find((x: any) => x.id === getSkillId(lesson))
      : undefined;

  return (
    <ContentBlock20
      imageUrl={lesson.imageUrl as string}
      typeTitle={HVLocalizeStrings.MICRO_LESSON}
      hoverText={
        noHover
          ? ""
          : !fullDescription
          ? lesson.description.toString()
          : undefined
      }
      title={lesson.name as string}
      subtitle={
        currentApp !== AppType.CERT
          ? getSkillName(getSkillId(lesson), skills)
          : undefined
      }
      subtitleImageUrl={skillToUse?.imageUrl as string}
      description={fullDescription ? (lesson.description as string) : undefined}
      descriptionColor={descriptionColor}
      isPortrait={isPortrait}
      truncateDescriptionAtSentence={true}
      points={showPoints ? 3 : undefined}
      pointsBgColor={"#354b9999"}
      width={width}
      height={height}
      dataTestId={`${HVTestId.LessonBlock20.lesson}_${lesson.id}`}
      onClick={
        onClickOverride
          ? onClickOverride
          : () => {
              const event = {
                HV_Lesson: lesson.id,
                HV_LessonTitle: lesson.name_loc?.en,
                HV_From: from,
              } as any;
              if (skillToUse) {
                event.HV_SkillId = skillToUse.id;
                event.HV_SkillName = skillToUse.slug;
              }
              track(EVENTS.LessonOpened, event);

              // set global state
              clearGlobalActivities();

              // let the clear happen before setting the new global state so modal can rest (in case user picks same lesson)
              setTimeout(() => {
                setOpenedActivityFromStartTraining(from === "StartTraining");

                // cloneAndTranslate in case we try to load the same item into an open modal
                setGlobalLesson(cloneAndTranslate(lesson));

                setIsLessonPreviewOpen(false);

                // if we're in SOLO and we haven't scored for this skill, open headscan and then load item
                if (
                  currentApp !== AppType.CERT &&
                  currentApp !== AppType.SPORT &&
                  needsSkillScore(userSkillStats, skillToUse?.id)
                ) {
                  // refresh when we open modal to get any new user instance (in case we bail on headscan)
                  getLessonsFromServer(skills);

                  setSelectedSkill(skillToUse);
                  setGlobalHeadScanQuestionModalOpen(true);
                } else {
                  setIsLessonPreviewOpen(true);
                }
              }, 1);
            }
      }
      isSmall={isSmall}
      titleBg={BLOCK_TITLE_GRADIENT}
      doneState={
        hideDone && completionState === CompletionState.done
          ? CompletionState.notStarted
          : overrideCompletionState === undefined
          ? completionState
          : overrideCompletionState
      }
      titleColor={BLOCK_PRIMARY_TEXT_COLOR}
    />
  );
};
