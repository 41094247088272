import {
  CONTRAST_TEXT_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
  THINKING_TOOL_CHOICE_1_BACKGROUND_COLOR,
  THINKING_TOOL_CHOICE_2_BACKGROUND_COLOR,
} from "../../../../Styles/HeadversityStyle";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  SlideFade,
  Text,
} from "@chakra-ui/react";

interface ThinkingToolStepSelectionProps {
  changePage: (page: string) => void;
  nextStep: string;
  handleChangeOne: () => void;
  handleChangeTwo: () => void;
  text: string;
  buttonTextOne: string;
  buttonTextTwo: string;
  title: string;
  instruction: string;
  instruction2?: string;
}

export const ThinkingToolStepSelection = (
  props: ThinkingToolStepSelectionProps
) => {
  const {
    changePage,
    nextStep,
    handleChangeOne,
    handleChangeTwo,
    text,
    buttonTextOne,
    buttonTextTwo,
    title,
    instruction,
    instruction2,
  } = props;

  return (
    <>
      <Box color={MODAL_PRIMARY_TEXT_COLOR}>
        <Heading
          fontSize={"2xl"}
          mt={"5"}
          fontWeight={"semibold"}
          text-align={"left"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {title}
        </Heading>
        <Text my={"2"} fontSize="md">
          {text}
        </Text>
        <Text mt={"8"} fontSize="md" textAlign={"center"}>
          {instruction}
        </Text>

        {instruction2 && (
          <Text mt={"8"} fontSize="md" fontWeight={"500"} textAlign={"center"}>
            {instruction2}
          </Text>
        )}
      </Box>
      <Box w={"100%"} mb={"3"}>
        <SlideFade in={true}>
          <HStack w={"100%"} align={"center"} justify={"center"} spacing={"10"}>
            {/* TODO: figure out the mobile hover state https://github.com/chakra-ui/chakra-ui/issues/6173
            sx={{
                _hover: {},
              }}
              for some reason solves this?? 🤷🏾‍♂️
              */}
            <Button
              className="hover-button"
              borderRadius={"100%"}
              h={["110px", "159px"]}
              w={["110px", "159px"]}
              border="0px"
              fontSize={["12px", "16px"]}
              background={THINKING_TOOL_CHOICE_1_BACKGROUND_COLOR}
              color={CONTRAST_TEXT_COLOR}
              whiteSpace={"normal"}
              onClick={(e) => {
                changePage(nextStep);
                handleChangeOne();
              }}
              sx={{
                _hover: {},
              }}
            >
              {buttonTextOne}
            </Button>
            <Button
              className="hover-button"
              borderRadius={"100%"}
              h={["110px", "159px"]}
              w={["110px", "159px"]}
              border="0px"
              fontSize={["12px", "16px"]}
              background={THINKING_TOOL_CHOICE_2_BACKGROUND_COLOR}
              color={CONTRAST_TEXT_COLOR}
              whiteSpace={"normal"}
              onClick={(e) => {
                changePage(nextStep);
                handleChangeTwo();
              }}
              sx={{
                _hover: {},
              }}
            >
              {buttonTextTwo}
            </Button>
          </HStack>
        </SlideFade>
      </Box>
    </>
  );
};
