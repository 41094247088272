import { Box, Divider, HStack, Stack } from "@chakra-ui/react";
import { useContext } from "react";
import {
  FiHome,
  FiUser,
  FiRss,
  FiTool,
  FiZap,
  FiMap,
  FiGrid,
  FiRadio,
} from "react-icons/fi";
import { TbHierarchy } from "react-icons/tb";
import { FaHeart, FaUsers, FaBullseye } from "react-icons/fa";
import { NavButton } from "./NavButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Link, useLocation } from "react-router-dom";
import { ToggleButton } from "./ToggleButton";
import {
  CONTRAST_TEXT_COLOR,
  NOTIFICATION_BACKGROUND_COLOR,
  SIDEBAR_BACKGROUND_COLOR,
} from "../../../Styles/HeadversityStyle";
import { SiFuturelearn } from "react-icons/si";
import { MdDomain, MdGroups, MdEmojiObjects } from "react-icons/md";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AppGridMenu } from "./AppGridMenu";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
} from "../../../State/GlobalContext";
import { OptimismToolContext } from "../../../State/Solo/OptimismToolContext";
import { FocusToolContext } from "../../../State/Solo/FocusToolContext";
import { IoRocketOutline } from "react-icons/io5";
import { VscBook } from "react-icons/vsc";
import { useSportTeam } from "../../../Hooks/Team/useSportTeam";
import useCompanyReachAdmin from "../../../Hooks/Reach/useCompanyReachAdmin";

interface SidebarProps {
  setUseFunnyBg?: any;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

/**
 * NOTE: there are 3 nav bars
 *  SidebarDesktop.tsx: desktop
 *  SidebarMobileWeb.tsx: mobile web
 *  appBarMenuItems in App.tsx
 *
 */
export const SidebarDesktop = (props: SidebarProps) => {
  const { setUseFunnyBg, isOpen, setIsOpen } = props;

  const {
    currentApp,
    getNotificationCountForTools,
    soloEnabled,
    isReachAdminEnabled,
    selectedUserLanguage,
    isReachHierarchyAdminEnabled,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { isLeader } = useSportTeam();
  const companyReachAdmin = useCompanyReachAdmin();

  const { currentPlannedItem } = useContext(OptimismToolContext);
  const { focusPlanItems } = useContext(FocusToolContext);

  const location = useLocation();

  return (
    <Box
      style={{
        transition: "all 0.25s ease",
      }}
      minW={isOpen ? "240px" : "60px"}
      bg={currentApp === AppType.SPORT ? "#333" : SIDEBAR_BACKGROUND_COLOR}
    >
      <Box pos="fixed" w={isOpen ? "225px" : "60px"} zIndex={101}>
        <HStack mt={"15px"} ml="20px">
          <ToggleButton
            isOpen={false}
            aria-label={HVLocalizeStrings.TOGGLE_NAVIGATION_MENU}
            className="open-menu-button"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />

          <Box
            pos="absolute"
            left="50px"
            transition={`all 0.${isOpen ? 3 : 0}s ease`}
            opacity={isOpen ? 1 : 0}
          >
            <AppGridMenu setIsOpen={() => {}} />
          </Box>
        </HStack>

        <Divider
          opacity={isOpen ? 1 : 0}
          transition={`all 0.${isOpen ? 3 : 0}s ease`}
          mt="25px"
          ml="15px"
          w="190px"
        />

        <Stack mt="15px" justify="space-between" spacing={2} width={"100%"}>
          {currentApp === AppType.SOLO ? (
            <>
              <Link tabIndex={-1} to={"/"}>
                <NavButton
                  toolTipOnly={!isOpen}
                  label={HVLocalizeStrings.SOLO_MENU_HOME}
                  icon={FiHome}
                  selected={
                    location.pathname === "/" || location.pathname === "/solo"
                  }
                />
              </Link>

              <Link tabIndex={-1} to={"/skills"}>
                <NavButton
                  toolTipOnly={!isOpen}
                  label={HVLocalizeStrings.SOLO_MENU_SKILLS}
                  icon={FiGrid}
                  selected={location.pathname?.indexOf("/skills") > -1}
                />
              </Link>
              <Link tabIndex={-1} to={"/explore"}>
                <NavButton
                  toolTipOnly={!isOpen}
                  label={HVLocalizeStrings.EXPLORE}
                  icon={FiMap}
                  reducedWidth={true}
                  selected={
                    location.pathname?.indexOf("/explore") > -1 ||
                    (!isOpen &&
                      location.pathname?.indexOf("/nano-practices") > -1) ||
                    (!isOpen && location.pathname?.indexOf("/lessons") > -1) ||
                    (!isOpen && location.pathname?.indexOf("/tools") > -1) ||
                    (!isOpen &&
                      location.pathname?.indexOf("/live-sessions") > -1) ||
                    (!isOpen &&
                      location.pathname?.indexOf("/resilience-feed") > -1)
                  }
                />
              </Link>
              {isOpen && (
                <>
                  <Link tabIndex={-1} to={"/nano-practices"}>
                    <NavButton
                      isSmall={true}
                      toolTipOnly={!isOpen}
                      label={HVLocalizeStrings.NANO_PRACTICES}
                      icon={FiZap}
                      selected={
                        location.pathname?.indexOf("/nano-practices") > -1
                      }
                    />
                  </Link>
                  <Link tabIndex={-1} to={"/lessons"}>
                    <NavButton
                      isSmall={true}
                      toolTipOnly={!isOpen}
                      label={HVLocalizeStrings.SOLO_MENU_LEARN}
                      icon={VscBook}
                      selected={location.pathname?.indexOf("/lessons") > -1}
                    />
                  </Link>
                  <Link tabIndex={-1} to={"/tools"}>
                    <NavButton
                      isSmall={true}
                      toolTipOnly={!isOpen}
                      label={HVLocalizeStrings.SOLO_MENU_PRACTICE}
                      icon={FiTool}
                      selected={location.pathname?.indexOf("/tools") > -1}
                    />
                  </Link>
                  {selectedUserLanguage === "en" && (
                    <Link tabIndex={-1} to={"/live-sessions"}>
                      <NavButton
                        isSmall={true}
                        toolTipOnly={!isOpen}
                        label={HVLocalizeStrings.SOLO_HOME_LIVE_SESSIONS_TITLE}
                        icon={FiRadio}
                        selected={
                          location.pathname?.indexOf("/live-sessions") > -1
                        }
                      />
                    </Link>
                  )}
                  <Link tabIndex={-1} to={"/resilience-feed"}>
                    <NavButton
                      isSmall={true}
                      toolTipOnly={!isOpen}
                      label={HVLocalizeStrings.SOLO_HOME_RESILIENCE_FEED_TITLE}
                      icon={FiRss}
                      selected={
                        location.pathname?.indexOf("/resilience-feed") > -1
                      }
                    />
                  </Link>
                </>
              )}
              <ProfileLink
                extra={""}
                isOpen={isOpen}
                location={location}
                notificationsUnreadCount={getNotificationCountForTools(
                  currentPlannedItem,
                  focusPlanItems
                )}
              />
            </>
          ) : currentApp === AppType.REACH ? (
            <>
              <>
                <Link tabIndex={-1} to={"/reach"}>
                  <NavButton
                    toolTipOnly={!isOpen}
                    label={HVLocalizeStrings.SOLO_MENU_HOME}
                    icon={FiHome}
                    selected={location.pathname === "/reach"}
                  />
                </Link>
                {isReachAdminEnabled && (
                  <Link tabIndex={-1} to={"/reach/promote"}>
                    <NavButton
                      toolTipOnly={!isOpen}
                      label={HVLocalizeStrings.REACH_PROMOTE}
                      icon={IoRocketOutline}
                      selected={location.pathname === "/reach/promote"}
                    />
                  </Link>
                )}
              </>
              {isReachAdminEnabled && (
                <>
                  {companyReachAdmin.isCompanyReachAdmin && (
                    <Link tabIndex={-1} to={"/reach/organizations"}>
                      <NavButton
                        toolTipOnly={!isOpen}
                        label={HVLocalizeStrings.REACH_MY_ORGANIZATION}
                        icon={MdDomain}
                        selected={location.pathname === "/reach/organizations"}
                      />
                    </Link>
                  )}
                  {isOpen && (
                    <>
                      <Link tabIndex={-1} to={"/reach/organizations/users"}>
                        <NavButton
                          isSmall={companyReachAdmin.isCompanyReachAdmin}
                          toolTipOnly={!isOpen}
                          label={HVLocalizeStrings.REACH_USERS}
                          icon={FaUsers}
                          selected={
                            location.pathname === "/reach/organizations/users"
                          }
                        />
                      </Link>
                      {soloEnabled && companyReachAdmin.isCompanyReachAdmin && (
                        <Link tabIndex={-1} to={"/reach/organizations/goals"}>
                          <NavButton
                            isSmall={true}
                            toolTipOnly={!isOpen}
                            label={HVLocalizeStrings.REACH_GOALS_TITLE}
                            icon={FaBullseye}
                            selected={
                              location.pathname === "/reach/organizations/goals"
                            }
                          />
                        </Link>
                      )}
                      {companyReachAdmin.isCompanyReachAdmin && (
                        <Link
                          tabIndex={-1}
                          to={"/reach/organizations/help-resources"}
                        >
                          <NavButton
                            isSmall={true}
                            toolTipOnly={!isOpen}
                            label={HVLocalizeStrings.REACH_HELP_RESOURCES_TITLE}
                            icon={FaHeart}
                            selected={
                              location.pathname ===
                              "/reach/organizations/help-resources"
                            }
                          />
                        </Link>
                      )}
                      {isReachHierarchyAdminEnabled &&
                        companyReachAdmin.isHvAdmin && (
                          <Link
                            tabIndex={-1}
                            to={"/reach/organizations/hierarchies"}
                          >
                            <NavButton
                              isSmall={true}
                              toolTipOnly={!isOpen}
                              label={HVLocalizeStrings.REACH_HIERARCHIES}
                              icon={TbHierarchy}
                              selected={location.pathname.includes(
                                "/reach/organizations/hierarchies"
                              )}
                            />
                          </Link>
                        )}
                    </>
                  )}
                  <Link tabIndex={-1} to={"/reach/insights"}>
                    <NavButton
                      toolTipOnly={!isOpen}
                      label={HVLocalizeStrings.REACH_INSIGHTS}
                      icon={MdEmojiObjects}
                      selected={location.pathname.includes("/reach/insights")}
                    />
                  </Link>
                </>
              )}
              <ProfileLink
                extra="?app=reach"
                isOpen={isOpen}
                location={location}
                notificationsUnreadCount={getNotificationCountForTools(
                  currentPlannedItem,
                  focusPlanItems
                )}
              />
            </>
          ) : currentApp === AppType.TEAM ? (
            <>
              <Link tabIndex={-1} to={"/team"}>
                <NavButton
                  toolTipOnly={!isOpen}
                  label={HVLocalizeStrings.SOLO_MENU_HOME}
                  icon={FiHome}
                  selected={location.pathname === "/team"}
                />
              </Link>
              <Link tabIndex={-1} to={"/team/paths"}>
                <NavButton
                  toolTipOnly={!isOpen}
                  label={HVLocalizeStrings.TEAM_MENU_PATHS}
                  icon={SiFuturelearn}
                  selected={location.pathname === "/team/paths"}
                />
              </Link>
              <Link tabIndex={-1} to={"/team/management"}>
                <NavButton
                  toolTipOnly={!isOpen}
                  label={HVLocalizeStrings.TEAM_MENU_TEAMS}
                  icon={MdGroups}
                  selected={location.pathname === "/team/management"}
                />
              </Link>
              <Link tabIndex={-1} to={"/team/insights"}>
                <NavButton
                  toolTipOnly={!isOpen}
                  label={HVLocalizeStrings.TEAM_MENU_INSIGHTS}
                  icon={HiOutlineLightBulb}
                  selected={location.pathname === "/team/insights"}
                />
              </Link>
              <ProfileLink
                extra="?app=team"
                isOpen={isOpen}
                location={location}
                notificationsUnreadCount={getNotificationCountForTools(
                  currentPlannedItem,
                  focusPlanItems
                )}
              />
            </>
          ) : currentApp === AppType.CERT ? (
            <>
              <Link tabIndex={-1} to={"/cert"}>
                <NavButton
                  toolTipOnly={!isOpen}
                  label={HVLocalizeStrings.SOLO_MENU_HOME}
                  icon={FiHome}
                  selected={location.pathname.startsWith("/cert")}
                />
              </Link>

              <ProfileLink
                extra="?app=cert"
                isOpen={isOpen}
                location={location}
                notificationsUnreadCount={getNotificationCountForTools(
                  currentPlannedItem,
                  focusPlanItems
                )}
              />
            </>
          ) : currentApp === AppType.SPORT ? (
            <>
              <Link tabIndex={-1} to={"/sport"}>
                <NavButton
                  toolTipOnly={!isOpen}
                  label={HVLocalizeStrings.SPORT_MENU_HOME}
                  icon={FiHome}
                  selected={location.pathname === "/sport"}
                />
              </Link>
              {isLeader && (
                <Link tabIndex={-1} to={"/sport/management"}>
                  <NavButton
                    toolTipOnly={!isOpen}
                    label={HVLocalizeStrings.TEAM_MENU_TEAMS}
                    icon={MdGroups}
                    selected={location.pathname === "/sport/management"}
                  />
                </Link>
              )}
              <Link tabIndex={-1} to={"/sport/resources"}>
                <NavButton
                  toolTipOnly={!isOpen}
                  label={HVLocalizeStrings.SPORT_MENU_RESOURCES}
                  icon={FiGrid}
                  selected={location.pathname === "/sport/resources"}
                />
              </Link>
              <ProfileLink
                extra="?app=sport"
                isOpen={isOpen}
                location={location}
                notificationsUnreadCount={getNotificationCountForTools(
                  currentPlannedItem,
                  focusPlanItems
                )}
              />
            </>
          ) : (
            <></>
          )}
        </Stack>
      </Box>

      <Box
        position={"fixed"}
        bottom="0px"
        w={"20px"}
        h={"20px"}
        zIndex="999"
        onClick={() => {
          setUseFunnyBg(true);
        }}
      />
    </Box>
  );
};

const ProfileLink = ({
  extra,
  isOpen,
  location,
  notificationsUnreadCount,
}: any) => {
  return (
    <Link tabIndex={-1} to={`/profile${extra}`}>
      <NavButton
        toolTipOnly={!isOpen}
        label={HVLocalizeStrings.SOLO_MENU_PROFILE_SETTINGS}
        icon={FiUser}
        selected={
          location.pathname?.indexOf("/profile") > -1 ||
          (!isOpen && location.pathname?.indexOf("/achievements") > -1)
        }
        extra={
          notificationsUnreadCount > 0 && (
            <Box
              pos={"absolute"}
              bottom={"2px"}
              left={"17px"}
              fontSize={"10px"}
              fontWeight={"bold"}
              h={"18px"}
              w={"18px"}
              p={"3px"}
              borderRadius={"100%"}
              bg={NOTIFICATION_BACKGROUND_COLOR}
              color={CONTRAST_TEXT_COLOR}
            >
              {Math.min(notificationsUnreadCount, 99)}
            </Box>
          )
        }
      />
    </Link>
  );
};
