import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { NotificationDto } from "@headversity/contract";
import { AxiosResponse } from "axios";

export const getNotifications = (
  token: string
): Promise<AxiosResponse<NotificationDto[]>> => {
  return AxiosClient.get("api/notifications", getConfig(token));
};

export const markAllNotificationsAsRead = (
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(`api/notifications`, null, getConfig(token));
};

export const markNotificationAsRead = (
  token: string,
  notificationId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/notifications/${notificationId}`,
    null,
    getConfig(token)
  );
};
