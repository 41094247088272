import { useCallback, useState } from "react";
import { HelpChannelDto } from "@headversity/contract";
import { getKey } from "../../Utils/Helpers";
import { getHelpInfo, trackHelpInfoView } from "../../Api/Solo/GetHelpInfoApi";

export interface IHelpInfoProvider {
  helpInfo: HelpChannelDto | undefined;
  getHelpInfoFromServer: () => void;
  trackHelpInfoViewFromServer: (helpChannelId: number) => void;
}
export const useHelpInfo = (): IHelpInfoProvider => {
  const [helpInfo, setHelpInfo] = useState<HelpChannelDto | undefined>();

  const getHelpInfoFromServer = useCallback(async () => {
    return getHelpInfo(getKey()).then((response) => {
      setHelpInfo(response.data);
    });
  }, []);

  const trackHelpInfoViewFromServer = useCallback(
    async (helpChannelId: number) => {
      return trackHelpInfoView(getKey(), helpChannelId).then((response) => {});
    },
    []
  );

  return {
    helpInfo,
    getHelpInfoFromServer,
    trackHelpInfoViewFromServer,
  };
};
