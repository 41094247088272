import {
  IInsightsFilter,
  ReportDto,
  UserCountsReportDto,
  UserEnrollmentReportDto,
} from "@headversity/contract";
import { useCallback, useContext, useState } from "react";
import { reportUsers } from "../../../Api/Reach/ReachApi";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { getKey } from "../../../Utils/Helpers";
import {
  getPointStartFromDate,
  parseDateFromYearMonth,
} from "../../../Utils/ChartsUtil";
import { IGlobalProvider, GlobalContext } from "../../../State/GlobalContext";

export interface IUsersReportProvider {
  fetchReport: (filters?: IInsightsFilter, allNodes?: boolean) => Promise<void>;
  userEnrollmentData?: UserEnrollmentSeriesData;
  userPercentageData: UserPercentageData;
  intervalDays: number;
  lowData: boolean;
  isLoading: boolean;
}

export interface UserEnrollmentSeriesData {
  data: number[];
  dates: string[];
  graphStartDate: number;
}

type UserPercentageData = {
  enrolledUserHeading: string;
  enrolledUsersDescription: string;
  activeUsersHeading: string;
  activeUsersDescription: string;
};

const mapUserCountsData = (
  dto: ReportDto<UserCountsReportDto>,
  isRootAdminHierarchy: boolean
): UserPercentageData => {
  if (dto.noData) {
    return {
      enrolledUserHeading: "0%",
      enrolledUsersDescription: "0",
      activeUsersHeading: "0%",
      activeUsersDescription: "0",
    };
  }

  const report = dto.report!;

  let enrolledUserHeading = `${Math.round(
    report.enrollment_percentage * 100.0
  )}%`;
  let enrolledUsersDescription = `${dto.report!.enrollment_count} ${
    HVLocalizeStrings.REACH_INSIGHTS_OUT_OF
  } ${report.total_licenses} ${
    HVLocalizeStrings.REACH_INSIGHTS_ELIGIBLE_USERS
  }`;
  if(!isRootAdminHierarchy) {
    enrolledUserHeading = `${dto.report!.enrollment_count}`;
    enrolledUsersDescription = ``;
  }
  const activeUsersHeading = `${Math.round(report.active_percentage * 100.0)}%`;
  const activeUsersDescription = `${report.active_users} ${HVLocalizeStrings.REACH_INSIGHTS_OUT_OF} ${report.enrollment_count} ${HVLocalizeStrings.REACH_INSIGHTS_ENROLLED_USERS}`;
  const result: UserPercentageData = {
    enrolledUserHeading,
    enrolledUsersDescription,
    activeUsersHeading,
    activeUsersDescription,
  };
  return result;
};

const mapEnrollmentData = (
  dto: ReportDto<UserEnrollmentReportDto[]>
): UserEnrollmentSeriesData | undefined => {
  if (dto.noData) {
    return undefined;
  }
  const report = dto.report!;
  const mapped = report.map((uer) => Number(uer.running_enrollment_count));
  const dateString = report[0].date;
  const dateUTC = parseDateFromYearMonth(dateString);
  const graphStartDate = getPointStartFromDate(dateUTC);
  return {
    data: mapped,
    dates: report.map((uer) => uer.date),
    graphStartDate,
  };
};

export const useUsersReport = (): IUsersReportProvider => {
  const [userEnrollmentData, setUserEnrollmentData] = useState<
    UserEnrollmentSeriesData | undefined
  >();
  const [userPercentageData, setUserPercentageData] =
    useState<UserPercentageData>({
      enrolledUserHeading: "0%",
      enrolledUsersDescription: "0",
      activeUsersHeading: "0%",
      activeUsersDescription: "0",
    });
  const [lowData, setLowData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [intervalDays, setIntervalDays] = useState<number>(180);
  const { userHierarchyAssociations } =
    useContext<IGlobalProvider>(GlobalContext);

  const fetchReportHandler = useCallback(
    async (filters?: IInsightsFilter, allNodes?: boolean) => {
      setIsLoading(true);
      const reportResp = await reportUsers(getKey(), filters, allNodes);

      let isRootAdminHierarchy = true;
      if(userHierarchyAssociations?.hierarchyId && !userHierarchyAssociations?.isRoot) {
        // there is a hierarchy and the user is not root admin
        isRootAdminHierarchy = false;
      }      
      const mappedPercentData = mapUserCountsData(reportResp.data.userCounts, isRootAdminHierarchy);
      setUserPercentageData(mappedPercentData);
      setUserEnrollmentData(mapEnrollmentData(reportResp.data.userEnrollment));
      setLowData(
        reportResp.data.userCounts.noData ||
          reportResp.data.userEnrollment.noData
      );
      setIntervalDays(reportResp.data.intervalDays);
      setIsLoading(false);
    },
    []
  );
  return {
    fetchReport: fetchReportHandler,
    userEnrollmentData,
    userPercentageData,
    lowData,
    isLoading,
    intervalDays,
  };
};
