import { Box } from "@chakra-ui/react";
import { useContext } from "react";
import { GlobalContext } from "../../State/GlobalContext";
import { getLanguageName } from "../../Utils/Helpers";
import { HvSelect } from "./HvSelect";
import { REACH_USERS_FILTER_BORDER } from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";

interface LanguageSelectorProps {
  onLanguageChanged: any;
  languageList?: string[];
  color?: string;
  height?: string;
  width?: string;
  hasBorder?: boolean;
  backgroundColor?: string;
}

export const LanguageSelector = (props: LanguageSelectorProps) => {
  const {
    onLanguageChanged,
    languageList,
    color,
    height,
    width,
    hasBorder,
    backgroundColor,
  } = props;
  const { languages, selectedUserLanguage } = useContext(GlobalContext);
  const languagesToSelect =
    languageList?.sort((a, b) => {
      return (
        Object.values(languages).indexOf(a) -
        Object.values(languages).indexOf(b)
      );
    }) ?? languages;
  return (
    <Box alignSelf={"center"}>
      <HvSelect
        fontSize={"sm"}
        backgroundColor={backgroundColor ?? "white"}
        color={color ?? "black"}
        onChange={(x) => {
          const newLanguage = x.target.value;

          onLanguageChanged(newLanguage);
        }}
        defaultValue={selectedUserLanguage}
        height={height}
        width={width}
        border={
          hasBorder ? `${REACH_USERS_FILTER_BORDER} !important` : undefined
        }
        data-testid={HVTestId.LanguageSelector.dropdown}
      >
        {languagesToSelect?.map((item) => {
          return (
            <option
              key={item}
              value={item}
              selected={item === selectedUserLanguage} // this shows a React warning but doesn't work right without it
            >
              {getLanguageName(item)}
            </option>
          );
        })}
      </HvSelect>
    </Box>
  );
};
