import { Box, Fade, Image, Text } from "@chakra-ui/react";

export interface AnimationBoxProps {
  useAnimation: boolean;
  useAnimationFade: boolean;
  hasMoreText: boolean;
  isMobile?: boolean;
  currentAnimationUrl?: string;
  moreTextContent: string;
}

export const AnimationBox = (props: AnimationBoxProps) => {
  const {
    hasMoreText,
    isMobile,
    currentAnimationUrl,
    moreTextContent,
    useAnimation,
    useAnimationFade,
  } = props;

  return (
    <Fade in={useAnimation} delay={0.1}>
      <Box textAlign={"center"} display={useAnimationFade ? "none" : undefined}>
        <Box
          pos="absolute"
          height="90px"
          top={hasMoreText ? "-19px" : "27px"}
          left="0"
          transition="all .5s"
          w="100%"
        >
          <Fade in={!hasMoreText}>
            <Text fontSize={"32px"} fontWeight="600">
              {moreTextContent}
            </Text>
          </Fade>

          <Fade in={hasMoreText}>
            <Text fontSize={"28px"} fontWeight="600">
              {moreTextContent}
            </Text>
          </Fade>
        </Box>
        <Image mt={!isMobile ? "-20px" : undefined} src={currentAnimationUrl} />
      </Box>
    </Fade>
  );
};
