import { HVLocalizeStrings } from "../Localization/HVLocalizeStrings";
import {
  FiMail,
  FiMoreHorizontal,
  FiPrinter,
  FiSmartphone,
} from "react-icons/fi";
import {
  LanguageSetting,
  PromotionalMaterialAssetDto,
  ReachAssetType,
} from "@headversity/contract";
import React from "react";

export const getAssetTypeName = (assetType: number) => {
  switch (assetType) {
    case ReachAssetType.Poster:
      return HVLocalizeStrings.REACH_POSTER;
    case ReachAssetType.SocialMedia:
      return HVLocalizeStrings.REACH_SOCIAL_MEDIA_POST;
    case ReachAssetType.Email:
      return HVLocalizeStrings.REACH_EMAIL_TEMPLATE;
    case ReachAssetType.Other:
      return HVLocalizeStrings.OTHER;
    default:
      return null;
  }
};

export const getAssetTypeIcon = (assetType: number) => {
  switch (assetType) {
    case ReachAssetType.Poster:
      return <FiPrinter />;
    case ReachAssetType.SocialMedia:
      return <FiSmartphone />;
    case ReachAssetType.Email:
      return <FiMail />;
    case ReachAssetType.Other:
      return <FiMoreHorizontal />;
    default:
      return null;
  }
};

export const getLanguageNameByEnum = (lang: string) => {
  switch (lang) {
    case "en":
      return HVLocalizeStrings.LANGUAGE_ENGLISH;
    case "fr":
      return HVLocalizeStrings.LANGUAGE_FRENCH;
    case "es":
      return HVLocalizeStrings.LANGUAGE_SPANISH;
    default:
      return null;
  }
};

export const getAssetLanguages = (languages: LanguageSetting) => {
  const writtenLanguages = Object.keys(languages)
    .filter((key) => languages[key as keyof LanguageSetting] === true)
    .map((lang) => getLanguageNameByEnum(lang));

  return writtenLanguages.join("/");
};

export const getFileName = (name: string, url: string) => {
  const extension = url.split(".").pop();
  return `${name}.${extension}`;
};

export const getAssetByLanguage = (
  assets: PromotionalMaterialAssetDto[],
  language: string
): PromotionalMaterialAssetDto => {
  const filteredAsset = assets.filter(
    (a) =>
      (a.languageSetting as LanguageSetting)[
        language as keyof LanguageSetting
      ] === true
  );

  // if one asset is found in the desired language, return it
  if (filteredAsset.length > 0) return filteredAsset[0];

  // if no asset is found in the desired language, return the first asset in english
  return assets.filter(
    (a) => (a.languageSetting as LanguageSetting)["en"] === true
  )[0];
};
