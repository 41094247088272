import {
  Box,
  Center,
  Divider,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { FaCheckCircle } from "react-icons/fa";
import { FiFile } from "react-icons/fi";
import { useS3PresignedURL } from "../../../Hooks/Reach/useS3PresignedURL";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  BUTTON_THIRD_BORDER,
  PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { HvSpinner } from "../../Common/HvSpinner";
import { UploadType } from "@headversity/contract";

interface ImageUploaderProps {
  uploadType: UploadType;
  handleBucketUrlChanged: (url: string) => void;
  handleIsUploading: (isUploading: boolean) => void;
  width?: string;
}

const ImageUploader = ({
  width,
  uploadType,
  handleBucketUrlChanged,
  handleIsUploading,
}: ImageUploaderProps) => {
  const { uploadImageToS3UsingPresignedURL, fileBucketUrl } =
    useS3PresignedURL();

  const MAX_IMAGE_SIZE = 1048576; // 1MB
  const MAX_IMAGE_DIMENSION = 4000; // 4000px
  const fileTypes = ["png", "jpg", "jpeg", "svg", "webp"];

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  useEffect(() => {
    if (selectedImage) {
      setIsUploading(true);
      handleIsUploading(true);
      uploadImageToS3UsingPresignedURL(selectedImage, uploadType).then(() => {
        setIsUploading(false);
      });
    }
  }, [selectedImage]);

  useEffect(() => {
    if (fileBucketUrl) {
      // notify, with a delay, s3 will 403 if we render image right away
      setTimeout(() => {
        handleBucketUrlChanged(fileBucketUrl);
        handleIsUploading(false);
      }, 2000);
    }
  }, [fileBucketUrl]);

  const handleFileChange = (file: File) => {
    const objectUrl = window.URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      let errorString = "";
      if (file.size > MAX_IMAGE_SIZE) {
        errorString += "Image size can't exceed 1MB.\n";
      }
      if (img.width > MAX_IMAGE_DIMENSION || img.height > MAX_IMAGE_DIMENSION) {
        errorString += `Image dimensions must be under ${MAX_IMAGE_DIMENSION}px.`;
      }
      if (errorString.length > 0) {
        alert(errorString);
      } else {
        setSelectedImage(file);
      }
      window.URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
  };

  const getBucketUrlKey = (imageBucketUrl: string): string => {
    const url = new URL(imageBucketUrl);
    const pathSegments = url.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];
    // return last 16 chars of the guid key just to show it exists, and can be replaced
    return `...${lastSegment.slice(-16)}`;
  };

  const handleRemoveImage = () => {
    handleBucketUrlChanged("");
    setSelectedImage(null);
  };

  const truncateName = (name: string) => {
    return name.length > 16 ? name.slice(0, 16) + "..." : name;
  };

  return (
    <Flex flexDirection="column">
      <FileUploader
        handleChange={handleFileChange}
        name="file"
        types={fileTypes}
        children={
          <Box
            bg={"white"}
            w={width || "100%"}
            borderRadius={"10px"}
            cursor={"pointer"}
            border={BUTTON_THIRD_BORDER}
            color={PRIMARY_TEXT_COLOR}
            boxShadow={"lg"}
          >
            <Flex gap={4} p={4}>
              <Icon as={FiFile} boxSize={10} />

              <Box w={isMobile ? "90%" : "380px"}>
                <Text
                  fontSize={"sm"}
                  dangerouslySetInnerHTML={{
                    __html: `${HVLocalizeStrings.REACH_HELP_RESOURCE_DRAG_DROP}`,
                  }}
                />
                {selectedImage && !isUploading && (
                  <>
                    <Divider my={2} />
                    <Flex gap={2} alignItems={"center"}>
                      <FaCheckCircle color={"green"} />
                      <Text fontSize={"sm"}>
                        {truncateName(selectedImage.name)}
                      </Text>
                    </Flex>
                  </>
                )}
                {!selectedImage && fileBucketUrl && !isUploading && (
                  <>
                    <Divider my={2} />
                    <Flex gap={2} alignItems={"center"}>
                      <FaCheckCircle color={"green"} />
                      <Text fontSize={"sm"}>
                        {getBucketUrlKey(fileBucketUrl)}
                      </Text>
                    </Flex>
                  </>
                )}
                {isUploading && (
                  <Center>
                    <HvSpinner />
                  </Center>
                )}
              </Box>
            </Flex>
          </Box>
        }
      />
    </Flex>
  );
};

export default ImageUploader;
