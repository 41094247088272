import { useContext, useEffect, useState } from "react";
import {
  DEFAULT_THEME_NAME,
  IShellProvider,
  ShellContext,
} from "../../../State/ShellContext";
import { Center, Flex, Link, Spinner } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { LandingContainer } from "../Shared/LandingContainer";
import { PEOPLE_EVENTS, track } from "../../../Utils/Analytics";
import { useNavigate, useSearchParams } from "react-router-dom";
import { generateErrorFromBackend } from "../Shared/ErrorCodes";
import { ErrorText } from "../Shared/ErrorText";
import { peekRedirectPath } from "../../../Utils/NavigationUtils";
import { HVTestId } from "../../../Testing/dataTestIds";
import {
  ErrorCodeType,
  RegistrationLoginStates,
  ResetPasswordDto,
  UserAppAccessInputDto,
  UserDto,
} from "@headversity/contract";
import { resetPassword } from "../../../Api/People/PasswordApi";
import PasswordInput from "../Shared/PasswordInput";
import {
  getUserAppInfo,
  setLocalStorageAuthTokens,
} from "../../../Utils/LoginUtil";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import { SELECTED_USER_LANGUAGE_KEY } from "../../../Utils/LanguageUtil";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { useLoginLanguageDetector } from "../../../Hooks/People/useLoginLanguageDetector";

interface ResetPasswordPageProps {
  isValidPassword: boolean;
  pwdHasNumber: boolean;
  pwdHasLowerCase: boolean;
  pwdHasUpperCase: boolean;
  pwdHasMinLength: boolean;
  resetPasswordValidation: () => void;
  validatePassword: (password: string) => void;
}

export const ResetPasswordPage = (props: ResetPasswordPageProps) => {
  const { updateSelectedUserLanguageToServer } =
    useContext<IGlobalProvider>(GlobalContext);
  const {
    isValidPassword,
    pwdHasNumber,
    pwdHasLowerCase,
    pwdHasUpperCase,
    pwdHasMinLength,
    resetPasswordValidation,
    validatePassword,
  } = props;
  const { setThemeHelper } = useContext<IShellProvider>(ShellContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [showForm, setShowForm] = useState(true);
  const [matchingPasswords, setMatchingPasswords] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useLoginLanguageDetector();

  useEffect(() => {
    if (password && verifyPassword) {
      setMatchingPasswords(password === verifyPassword);
    }
  }, [password, verifyPassword]);

  const handleChange = (
    stateToUpdate: RegistrationLoginStates,
    stateText: string
  ) => {
    switch (stateToUpdate) {
      case RegistrationLoginStates.Password:
        setPassword(stateText);
        validatePassword(stateText);
        break;
      case RegistrationLoginStates.PasswordConfirmation:
        setVerifyPassword(stateText);
        break;
    }
  };

  const onSavePasswordHandler = async () => {
    setIsSubmitting(true);
    setError("");
    try {
      if (password !== verifyPassword) {
        setError(HVLocalizeStrings.PASSWORD_MISMATCH);
        return;
      }
      track(PEOPLE_EVENTS.ResetPassword, {});
      const resetPasswordCode = searchParams.get("code");
      if (resetPasswordCode) {
        const useAppInfo: UserAppAccessInputDto = await getUserAppInfo();
        const result = (
          await resetPassword({
            code: resetPasswordCode,
            password: password,
            userAppAccessInput: useAppInfo,
          } as ResetPasswordDto)
        ).data;
        if (result.errorCode !== undefined) {
          setError(generateErrorFromBackend(result?.errorCode, null));
          return;
        }
        if (result.access_token) {
          setError(null);
          resetPasswordValidation();
          updateSelectedUserLanguageToServer(
            result as UserDto,
            localStorage.getItem(SELECTED_USER_LANGUAGE_KEY) ??
              HVLocalizeStrings.getLanguage()
          );
          if (
            window.location.origin ===
            process.env.REACT_APP_MOBILE_RESET_PASSWORD_URL
          ) {
            document.getElementById("headversityDirectClickToApp")?.click();
            setTimeout(() => {
              window.close();
            }, 500);
          } else {
            await setLocalStorageAuthTokens(result);
            const path = peekRedirectPath();
            navigate(path, { replace: true });
          }
        }
      } else {
        setError(HVLocalizeStrings.INVALID_OR_ALREADY_USED_CODE);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const isSavePasswordEnabled = () =>
    matchingPasswords && isValidPassword && !isSubmitting;

  const getResetResult = async () => {
    setError("");
    const resetPasswordCode = searchParams.get("code");
    if (resetPasswordCode) {
      const result = (
        await resetPassword({
          code: resetPasswordCode,
          password: password,
        } as ResetPasswordDto)
      ).data;
      if (result.errorCode === ErrorCodeType.InvalidOrAlreadyUsedCode) {
        setError(generateErrorFromBackend(result.errorCode, null));
        setShowForm(false);
        return;
      }
    }
  };

  useEffect(() => {
    getResetResult();
  }, [searchParams]);

  useEffect(() => {
    setThemeHelper(DEFAULT_THEME_NAME);
  }, []);

  return (
    <LandingContainer other={<></>}>
      <a
        id={"headversityDirectClickToApp"}
        href={`https://${process.env.REACT_APP_ORIGIN}`}
        style={{ position: "absolute", top: "-10000px" }}
      ></a>
      <Flex
        flexDirection={"column"}
        gap={"2"}
        align={"center"}
        p={"20px"}
        pt={0}
      >
        {error && (
          <Center py={"3"}>
            <ErrorText message={error} />
          </Center>
        )}
        {error && !showForm && (
          <Link
            textDecoration={"underline"}
            mt={2}
            fontSize={"sm"}
            onClick={() => {
              if (isWebPlatform()) {
                navigate("/login");
              } else {
                navigate("/mobileLogin", { replace: true });
              }
            }}
          >
            {HVLocalizeStrings.BACK_TO_LOGIN_SIGNUP}
          </Link>
        )}
        {showForm && (
          <Flex flexDir={"column"} w={"300px"} gap={"2"}>
            <PasswordInput
              showVerifyField={true}
              passwordError={null}
              pwdHasNumber={pwdHasNumber}
              pwdHasLowerCase={pwdHasLowerCase}
              pwdHasUpperCase={pwdHasUpperCase}
              pwdHasMinLength={pwdHasMinLength}
              pwdMatch={matchingPasswords}
              handleChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter" && isSavePasswordEnabled()) {
                  onSavePasswordHandler();
                }
              }}
            ></PasswordInput>
          </Flex>
        )}
        {showForm && (
          <>
            {isSubmitting ? (
              <Spinner />
            ) : (
              <>
                <AnimatedButton
                  text={HVLocalizeStrings.LOGIN_SAVE_PASSWORD}
                  disabled={!isSavePasswordEnabled()}
                  colorSet={AnimatedButtonColorSet.Primary}
                  onClick={onSavePasswordHandler}
                  w={300}
                  dataTestId={HVTestId.ResetPassword.submitButton}
                />
                <Link
                  textDecoration={"underline"}
                  mt={2}
                  fontSize={"sm"}
                  onClick={() => {
                    if (isWebPlatform()) {
                      navigate("/login");
                    } else {
                      navigate("/mobileLogin", { replace: true });
                    }
                  }}
                >
                  {HVLocalizeStrings.BACK_TO_LOGIN_SIGNUP}
                </Link>
              </>
            )}
          </>
        )}
      </Flex>
    </LandingContainer>
  );
};
