import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Equity_Blue,
  HVDefaultTheme,
  HVNostalgicTheme,
  Success,
  Error,
} from "../Styles/HeadversityStyle";
import { extendTheme, useToast } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { GlobalContext, IGlobalProvider } from "./GlobalContext";
import { SafeArea } from "capacitor-plugin-safe-area";
import { StatusBar, Style } from "@capacitor/status-bar";
import { isAndroid, isWebPlatform } from "../Utils/mobileUtils";
import { WebViewPlugin } from "../Utils/WebviewPlugin";
import { AudioTrackInformation } from "../Types/HVTypes";
import { createToast } from "../Utils/toastUtil";

export interface IShellProvider {
  theme: any;
  changeTheme: (themeName: string) => void;
  setThemeHelper: (themeName: string) => void;
  isMainContainerXL: boolean;
  setIsMainContainerXL: (isMainContainerXL: boolean) => void;
  mainContainerWidth: number;
  setMainContainerWidth: (mainContainerWidth: number) => void;
  confirmationDialogRef: any;
  audio: AudioTrackInformation | null;
  setAudio: (audio: AudioTrackInformation | null) => void;
  isGetHelpOpen: boolean;
  setGetHelpOpen: (isGetHelpOpen: boolean) => void;
  safeAreaInsets: { top: number; bottom: number };
  showToast: (
    isSuccess: boolean,
    id: string,
    message: any,
    position?: string,
    color?: string,
    border?: string,
    bg?: string,
    noPadding?: boolean,
    extraDelayMs?: number
  ) => void;
  isPriorityModalPending: boolean;
  setIsPriorityModalPending: (isPending: boolean) => void;
  isSideBarOpen: boolean;
  setIsSideBarOpen: (isSideBarOpen: boolean) => void;
  doOSThemeCheck: (onCheckComplete: () => void) => void;
  isFirstTimeUserWelcomeModalOpen: boolean;
  setIsFirstTimeUserWelcomeModalOpen: (
    isFirstTimeUserWelcomeModalOpen: boolean
  ) => void;
  isGoalOverviewModalOpen: boolean;
  setIsGoalOverviewModalOpen: (isGoalOverviewModalOpen: boolean) => void;
  isGoalOverviewSeeAllGoals: boolean;
  setIsGoalOverviewSeeAllGoals: (isGoalOverviewSeeAllGoals: boolean) => void;
  isGoalCheckInModalOpen: boolean;
  setIsGoalCheckInModalOpen: (isGoalCheckInModalOpen: boolean) => void;
  isGoalCheckInNewPursuitModalOpen: boolean;
  setIsGoalCheckInNewPursuitModalOpen: (isOpen: boolean) => void;
  isPracticeReminderModalOpen: boolean;
  setIsPracticeReminderModalOpen: (practiceReminderModalOpen: boolean) => void;
}

export const DEFAULT_THEME_NAME = "default";
export const NOSTALGIC_THEME_NAME = "dark";

const OS_THEME_CHECKED = "os-theme-checked";

export const ShellContext = createContext<IShellProvider>({} as any);

export const ShellProvider = (props: any) => {
  const defaultTheme = extendTheme(HVDefaultTheme);
  const nostalgicTheme = extendTheme(HVNostalgicTheme);

  const [theme, setTheme] = useState<any>();
  const [customHelmetCss, setCustomHelmetCss] = useState<string>("");

  const [isMainContainerXL, setIsMainContainerXL] = useState<boolean>(false);
  const [mainContainerWidth, setMainContainerWidth] = useState<number>(
    window.innerWidth
  );

  const [audio, setAudio] = useState<AudioTrackInformation | null>(null);

  const [isGetHelpOpen, setGetHelpOpen] = useState(false);

  const { self, updateSelectedUserThemeToServer, isUninterruptibleModalOpen } =
    useContext<IGlobalProvider>(GlobalContext);

  const [safeAreaInsets, setSafeAreaInsets] = useState<{
    top: number;
    bottom: number;
  }>({ top: 0, bottom: 0 });

  const [isPriorityModalPending, setIsPriorityModalPending] = useState(false);

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const [isFirstTimeUserWelcomeModalOpen, setIsFirstTimeUserWelcomeModalOpen] =
    useState(false);

  const [isGoalOverviewModalOpen, setIsGoalOverviewModalOpen] =
    useState<boolean>(false);

  const [isGoalOverviewSeeAllGoals, setIsGoalOverviewSeeAllGoals] =
    useState(false);

  const [isGoalCheckInModalOpen, setIsGoalCheckInModalOpen] = useState(false);

  const [
    isGoalCheckInNewPursuitModalOpen,
    setIsGoalCheckInNewPursuitModalOpen,
  ] = useState(false);

  const [isPracticeReminderModalOpen, setIsPracticeReminderModalOpen] =
    useState(false);

  useEffect(() => {
    if (self?.theme) {
      setThemeHelper(self?.theme);
    } else {
      setThemeHelper(DEFAULT_THEME_NAME);
    }
  }, [self]);

  const changeTheme = useCallback((themeName: string) => {
    setThemeHelper(themeName);
    updateSelectedUserThemeToServer(themeName);
  }, []);

  const confirmationDialogRef = useRef();

  const setThemeHelper = useCallback((themeName: string) => {
    switch (themeName) {
      default:
        setTheme({ name: DEFAULT_THEME_NAME, themeInstance: defaultTheme });
        setCustomHelmetCss(``);

        break;
      case NOSTALGIC_THEME_NAME:
        setTheme({ name: NOSTALGIC_THEME_NAME, themeInstance: nostalgicTheme });
        setCustomHelmetCss(`
          body {
            background-color: ${Equity_Blue}
          }
          .swiper-pagination-bullet.on-container:not(.swiper-pagination-bullet-active) {
            border: 5px solid white;
          }
          .k-bottom-nav-flat.k-bottom-nav-flat-primary .k-bottom-nav-item.k-selected {
            color: #E36300 !important;
          }
        `);

        break;
    }

    // CERT and REACH backgrounds are so dark that text has to be white to see it, so we’ll just always use Dark (white font)
    if (!isWebPlatform()) {
      StatusBar.setStyle({ style: Style.Dark });
    }
  }, []);

  const toast = useToast();

  const showToast = useCallback(
    (
      isSuccess: boolean,
      id: string,
      message: any,
      position: string = "top",
      color?: string,
      border?: string,
      bg?: string,
      noPadding?: boolean,
      extraDelayMs?: number
    ) => {
      createToast(
        toast,
        color !== undefined ? color : isSuccess ? Success : Error,
        id,
        message,
        position,
        position === "bottom"
          ? (isWebPlatform() ? 0 : 10) + safeAreaInsets.bottom
          : safeAreaInsets.top,
        border,
        bg,
        noPadding,
        extraDelayMs
      );
    },
    [toast, safeAreaInsets]
  );

  const doOSThemeCheck = useCallback((onCheckComplete: () => void) => {
    // first time here, try to detect OS color preference
    const needOSThemeCheck = localStorage.getItem(OS_THEME_CHECKED) === null;

    if (needOSThemeCheck) {
      localStorage.setItem(OS_THEME_CHECKED, "true");

      if (window.matchMedia) {
        // avoid timing issue with the default theme loading
        setTimeout(() => {
          const match = window.matchMedia("(prefers-color-scheme: dark)");
          if (match.matches) {
            changeTheme(NOSTALGIC_THEME_NAME);
          }

          onCheckComplete();
        }, 500);
        return;
      }
    }

    onCheckComplete();
  }, []);

  useEffect(() => {
    if (!isWebPlatform()) {
      StatusBar.setOverlaysWebView({ overlay: true });
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        // iOS over-reports the space needed for bottom inset
        let bottom = insets.bottom;
        if (!isAndroid() && bottom > 0) {
          bottom -= 10;
        }

        setSafeAreaInsets({ top: insets.top, bottom: bottom });
      });
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (!isWebPlatform()) {
      if (isUninterruptibleModalOpen) {
        // prevent back swipe while modal is open (iOS)
        WebViewPlugin.disableBackForwardGestures();

        StatusBar.hide();
      } else {
        // re-enable back swipe when modal is closed (iOS)
        WebViewPlugin.enableBackForwardGestures();

        if (document.scrollingElement?.scrollTop === 0) {
          StatusBar.show();
        }
      }
    }
  }, [isUninterruptibleModalOpen]);

  useEffect(() => {
    if (!isWebPlatform()) {
      const handleScroll = (e: any) => {
        const pos = e.currentTarget.scrollingElement.scrollTop;

        if (pos === 0 && !isUninterruptibleModalOpen) {
          StatusBar.show();
        } else {
          StatusBar.hide();
        }
      };

      document.addEventListener("scroll", handleScroll);

      return () => {
        document.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <ShellContext.Provider
      value={{
        theme,
        changeTheme,
        isMainContainerXL,
        setIsMainContainerXL,
        mainContainerWidth,
        setMainContainerWidth,
        confirmationDialogRef,
        audio,
        setAudio,
        isGetHelpOpen,
        setGetHelpOpen,
        setThemeHelper,
        safeAreaInsets,
        showToast,
        isPriorityModalPending,
        setIsPriorityModalPending,
        isSideBarOpen,
        setIsSideBarOpen,
        doOSThemeCheck,
        isFirstTimeUserWelcomeModalOpen,
        setIsFirstTimeUserWelcomeModalOpen,
        isGoalOverviewModalOpen,
        setIsGoalOverviewModalOpen,
        isGoalOverviewSeeAllGoals,
        setIsGoalOverviewSeeAllGoals,
        isGoalCheckInModalOpen,
        setIsGoalCheckInModalOpen,
        isGoalCheckInNewPursuitModalOpen,
        setIsGoalCheckInNewPursuitModalOpen,
        isPracticeReminderModalOpen,
        setIsPracticeReminderModalOpen,
      }}
    >
      <Helmet>
        <style id={"slider-js-theme"}>{customHelmetCss}</style>
      </Helmet>
      {props.children}
    </ShellContext.Provider>
  );
};
