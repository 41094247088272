import { useCallback, useState } from "react";
import {
  getCustomQuestionAnswerByType,
  getPrivateCustomQuestions,
  setCustomQuestionAnswer,
} from "../../Api/Solo/CustomQuestionApi";
import { getKey } from "../../Utils/Helpers";
import {
  CustomQuestionDto,
  QuestionAnswerDto,
  QuestionUserResponseDto,
} from "@headversity/contract";
import { AxiosResponse } from "axios";

export interface ICustomFieldProvider {
  customFields: CustomQuestionDto[] | undefined;
  userCustomFieldResponses: QuestionUserResponseDto[] | undefined;
  getPrivateCustomQuestionsFromServer: () => Promise<void>;
  getCustomQuestionAnswerByTypeFromServer: (type: string) => Promise<void>;
  setCustomQuestionAnswerToServer: (
    questionAnswerDto: QuestionAnswerDto
  ) => Promise<void | AxiosResponse<void, any>>;
}

export const useCustomField = () => {
  const [customFields, setCustomFields] = useState<CustomQuestionDto[]>([]);
  const [userCustomFieldResponses, setUserCustomFieldResponses] = useState<
    QuestionUserResponseDto[] | undefined
  >(undefined);
  const getPrivateCustomQuestionsFromServer = useCallback(async () => {
    return getPrivateCustomQuestions(getKey()).then(async (response) => {
      const responseCustomFields = response.data.filter(
        (c) => c.type === "Custom Field"
      );
      if (responseCustomFields?.length > 0) {
        setCustomFields(responseCustomFields);
        await getCustomQuestionAnswerByTypeFromServer("Custom Field");
      }
    });
  }, []);

  const getCustomQuestionAnswerByTypeFromServer = useCallback(
    async (type: string) => {
      return getCustomQuestionAnswerByType(getKey(), type).then((response) => {
        setUserCustomFieldResponses(
          response.data.sort(
            (a, b) =>
              (b.questionAlternative.scale ?? 0) -
              (a.questionAlternative.scale ?? 0)
          )
        );
      });
    },
    []
  );

  const setCustomQuestionAnswerToServer = useCallback(
    async (questionAnswerDto: QuestionAnswerDto) => {
      return setCustomQuestionAnswer(getKey(), questionAnswerDto).then(
        async (response) => {
          await getPrivateCustomQuestionsFromServer();
          return Promise.resolve(response);
        }
      );
    },
    []
  );

  return {
    customFields,
    userCustomFieldResponses,
    getPrivateCustomQuestionsFromServer,
    getCustomQuestionAnswerByTypeFromServer,
    setCustomQuestionAnswerToServer,
  };
};
