import { Box } from "@chakra-ui/react";
import { AvailableReportsDto, CompanyDto } from "@headversity/contract";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useContext } from "react";
import {
  IShellProvider,
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../State/ShellContext";
import { Equity_Blue_80 } from "../../Styles/HeadversityStyle";
import PDFHeader from "../Shared/PDFDownload/PDFHeader";
import PDFView from "../Shared/PDFDownload/PDFView";
import PDFFooter from "../Shared/PDFDownload/PDFFooter";
import InsightsCertPanel from "./Insights/InsightsCertPanel";
import InsightsPsychographicsPanel from "./Insights/InsightsPsychographicsPanel";
import InsightsSoloAndTeamPanel from "./Insights/InsightsSoloAndTeamPanel";
import InsightsUsersPanel from "./Insights/InsightsUsersPanel";
import { GlossarySection } from "./Insights/GlossarySection";

export interface PdfContainerProps {
  pdfRef: React.RefObject<PDFExport>;
  company?: CompanyDto;
  showPsychographics: boolean;
  showSoloAndTeam: boolean;
  availableReports: AvailableReportsDto;
}
export const PDFContainerInsightsPage = ({
  company,
  pdfRef,
  showPsychographics,
  showSoloAndTeam,
  availableReports,
}: PdfContainerProps) => {
  const companyName = company?.name || "";
  const companyLogo = company?.logo || "";

  const { theme } = useContext<IShellProvider>(ShellContext);

  return (
    <PDFView pdfRef={pdfRef}>
      <Box
        bgColor={
          theme.name === NOSTALGIC_THEME_NAME ? Equity_Blue_80 : undefined
        }
        h="3508px"
      >
        <PDFHeader
          skipBreak={true}
          companyName={companyName}
          logo={companyLogo}
        />
        <InsightsUsersPanel pdfExport={true} />
        {showPsychographics && <InsightsPsychographicsPanel pdfExport={true} />}
        <PDFFooter />
        {showSoloAndTeam && (
          <>
            <PDFHeader
              skipBreak={false}
              companyName={companyName}
              logo={companyLogo}
            />
            <InsightsSoloAndTeamPanel pdfExport={true} />
            <PDFFooter />
          </>
        )}

        {/* Cert Section */}
        {availableReports.cert && (
          <>
            <PDFHeader
              skipBreak={false}
              companyName={companyName}
              logo={companyLogo}
            />
            <InsightsCertPanel pdfExport={true} />
            <PDFFooter />
          </>
        )}
        {/* Glossary Section */}
        <PDFHeader
              skipBreak={false}
              companyName={companyName}
              logo={companyLogo}
        />
        <GlossarySection hideTitle={false} showPsychographics={showPsychographics}  />
        <PDFFooter />
      </Box>
    </PDFView>
  );
};
