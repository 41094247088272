import { Box, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import {
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  HEADER_NANOPRACTICE_BACKGROUND,
} from "../../../Styles/HeadversityStyle";
import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { StickyBreadcrumb } from "../../Common/StickyBreadcrumb";
import { InlineBox } from "../Shared/InlineBox";
import { NanoPracticeBlock } from "./NanoPracticeBlock";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import {
  NanoPracticeCategory,
  NanoPracticeDto,
  NanoPracticeType,
} from "@headversity/contract";
import NanoPracticeToolbar from "./NanoPracticeToolbar";
import { useSearchParams } from "react-router-dom";
import { getEnumArray } from "../../../Utils/Helpers";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { isFave } from "./NanoPracticeUtils";

export type SearchFilter = {
  type: NanoPracticeType | undefined;
  favorites: boolean;
};

export const NanoPracticesPage = () => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const {
    getPageTitle,
    coreSkills,
    workSkills,
    homeSkills,
    skills,
    nanoPractices,
    favorites,
  } = useContext(GlobalContext);
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  const [allPractices, setAllPractices] = useState<NanoPracticeDto[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchFilter, setSearchFilter] = useState<SearchFilter>({
    type: undefined,
    favorites: false,
  });
  const categories = useRef<NanoPracticeCategory[]>(
    getEnumArray(NanoPracticeCategory)
  );

  const getCategoryTitle = (category: NanoPracticeCategory) => {
    return (HVLocalizeStrings as any)[
      "NANO_PRACTICE_CATEGORY" +
        NanoPracticeCategory[category].replace(/([A-Z])/g, "_$1").toUpperCase()
    ];
  };

  const getCategoryDescription = (category: NanoPracticeCategory) => {
    return (HVLocalizeStrings as any)[
      "NANO_PRACTICE_CATEGORY" +
        NanoPracticeCategory[category]
          .replace(/([A-Z])/g, "_$1")
          .toUpperCase() +
        "_DESCRIPTION"
    ];
  };

  const filterPractices = (
    allPractices: NanoPracticeDto[],
    category: NanoPracticeCategory
  ) => {
    return allPractices.filter(
      (practice) => practice.nanoPracticeCategory === category
    );
  };

  const renderPractices = () => {
    const allFilteredPractices = categories.current.map((category) =>
      filterPractices(allPractices, category)
    );
    const allEmpty = allFilteredPractices.every(
      (practices) => practices.length === 0
    );

    if (allEmpty) {
      return (
        <Box
          maxW={isMainContainerXL ? "container.xl" : "container.lg"}
          mx={"auto"}
          p={"5"}
          pb={"30px"}
          pt={0}
          className="block_section_container"
        >
          <Box color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.NANO_PRACTICE_NO_RESULTS}
          </Box>
        </Box>
      );
    }

    return categories.current.map((category, index) => {
      const filteredPractices = allFilteredPractices[index];
      if (filteredPractices.length === 0) {
        return null;
      }
      return (
        <BlockSection
          key={category}
          fadeIn={true}
          title={
            <BlockSectionTitle
              title={getCategoryTitle(category)}
              description={getCategoryDescription(category)}
            />
          }
          columns={1}
        >
          <NanoPracticeStrip
            practicesToShow={filteredPractices}
            isDesktop={isDesktop}
            from={getCategoryTitle(category)}
          />
        </BlockSection>
      );
    });
  };

  useEffect(() => {
    if (!isInitialLoad || allPractices.length === 0) return;
    const initialType =
      searchParams.has("type") &&
      Object.values(NanoPracticeType).includes(Number(searchParams.get("type")))
        ? Number(searchParams.get("type"))
        : undefined;
    const initialFavorites = searchParams.get("favorites") === "1";
    setSearchFilter({
      type: initialType,
      favorites: initialFavorites,
    });
    setIsInitialLoad(false);
  }, [allPractices, isInitialLoad]);

  useEffect(() => {
    if (isInitialLoad) return;
    let queryParams = new URLSearchParams();
    if (searchFilter.type !== undefined) {
      queryParams.append("type", searchFilter.type.toString());
    }
    if (searchFilter.favorites) {
      queryParams.append("favorites", "1");
    }
    setSearchParams(queryParams);
  }, [isInitialLoad, searchFilter]);

  useEffect(() => {
    if (
      !skills ||
      !nanoPractices ||
      skills.length === 0 ||
      nanoPractices.length === 0
    )
      return;

    let finalPractices: NanoPracticeDto[] = [];

    const allSkills = coreSkills.concat(workSkills).concat(homeSkills);

    // take all practices
    for (let x = 0; x < allSkills.length; x++) {
      const practices = nanoPractices
        .filter((p) =>
          p.skillCompetencies.some((s) => s.skillId === allSkills[x].id)
        )
        .filter(
          (p) =>
            searchFilter.type === undefined ||
            searchFilter.type === p.nanoPracticeType
        )
        .filter(
          (p) => searchFilter.favorites === false || isFave(favorites, p)
        );

      practices.sort((a, b) =>
        a.name.toString() > b.name.toString() ? 1 : -1
      );

      finalPractices = finalPractices.concat(practices);
    }

    setAllPractices(finalPractices);
  }, [skills, nanoPractices, searchFilter, favorites]);

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.NANO_PRACTICES} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.NANO_PRACTICES}
        description={HVLocalizeStrings.NANO_PRACTICES_HEADER_DESCRIPTION}
        backgroundImage={HEADER_NANOPRACTICE_BACKGROUND}
      />
      <StickyBreadcrumb
        crumbs={[
          { url: "/", text: HVLocalizeStrings.SOLO_MENU_HOME },
          { url: "/explore", text: HVLocalizeStrings.EXPLORE },
        ]}
        current={HVLocalizeStrings.NANO_PRACTICES}
      />
      <FadeAfterDelay noEase={true}>
        <NanoPracticeToolbar
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
        {renderPractices()}
      </FadeAfterDelay>
    </>
  );
};

interface NanoPracticeStripProps {
  practicesToShow: NanoPracticeDto[];
  isDesktop: boolean | undefined;
  from: string;
}

const NanoPracticeStrip = ({
  practicesToShow,
  isDesktop,
  from,
}: NanoPracticeStripProps) => {
  return (
    <Box>
      {practicesToShow.map((practice, idx: number) => {
        return (
          <InlineBox isDesktop={isDesktop} idx={idx} key={practice.id}>
            <NanoPracticeBlock
              practice={practice}
              isSmall={!isDesktop}
              fullDescription={true}
              showPoints={true}
              from={from}
              isPortrait={!isDesktop}
            />
          </InlineBox>
        );
      })}
    </Box>
  );
};
