import { QuestionDto, QuestionType } from "@headversity/contract";
import { cloneDeep } from "lodash";
import { Question } from "./Question";
import {
  HEADER_HOME_BACKGROUND,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { Helmet } from "react-helmet";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { LargeModal } from "../../Common/LargeModal";
import { useState } from "react";
import { AnimatedButton } from "../../Common/AnimatedButton";
import { BlockSection } from "../../Common/BlockSection";

export const QuestionTestHarness = () => {
  const [open, setOpen] = useState(false);

  const question = {
    id: 802,
    questionText_loc: {
      en: "I understand the difference between mental health and mental illness.",
    },
    questionText:
      "I understand the difference between mental health and mental illness.",
    questionType: 6,
    uniqueHash:
      "6ad282298889ac151a7f94588a6aab4790501f1a2daefbf9557fec5d314c4b97",
    createdAt: "2023-11-10T00:43:54.000+00:00",
    updatedAt: "2023-11-10T00:43:54.000+00:00",
    deletedAt: null,
    internalTitle: "MHFFL_PRE_POST_Q_1",
    uniqueHint: "x",
    optional: false,
    questionAlternatives: [
      {
        id: 2288,
        questionId: 802,
        alternativeText_loc: {
          en: "Strongly disagree",
        },
        alternativeText: "Strongly disagree",
        scale: 1,
        grouping: "",
      },
      {
        id: 2289,
        questionId: 802,
        alternativeText_loc: {
          en: "Disagree",
        },
        alternativeText: "Disagree",
        scale: 2,
        grouping: "",
      },
      {
        id: 2290,
        questionId: 802,
        alternativeText_loc: {
          en: "Somewhat disagree",
        },
        alternativeText: "Somewhat disagree",
        scale: 3,
        grouping: "",
      },
      {
        id: 2291,
        questionId: 802,
        alternativeText_loc: {
          en: "Neutral",
        },
        alternativeText: "Neutral",
        scale: 4,
        grouping: "",
      },
      {
        id: 2292,
        questionId: 802,
        alternativeText_loc: {
          en: "Somewhat agree",
        },
        alternativeText: "Somewhat agree",
        scale: 5,
        grouping: "",
      },
      {
        id: 2293,
        questionId: 802,
        alternativeText_loc: {
          en: "Agree",
        },
        alternativeText: "Agree",
        scale: 6,
        grouping: "",
      },
      {
        id: 2294,
        questionId: 802,
        alternativeText_loc: {
          en: "Strongly agree",
        },
        alternativeText: "Strongly agree",
        scale: 7,
        grouping: "",
      },
    ],

    questionUserResponses: [],
    context: "",
  } as QuestionDto;

  const question2 = cloneDeep(question);
  question2.id = 803;
  question2.questionType = QuestionType.Slider11;

  const question3 = cloneDeep(question);
  question3.id = 804;
  question3.questionType = QuestionType.Single;

  const question4 = cloneDeep(question);
  question4.id = 805;
  question4.questionType = QuestionType.FreeText;

  const questions = [question, question2, question3, question4];

  return (
    <>
      <Helmet>
        <title>Test page</title>
      </Helmet>
      <HeaderSetter
        title={"Test page"}
        description={"A page to test things"}
        backgroundImage={HEADER_HOME_BACKGROUND}
        showLogo={true}
      />

      <FadeAfterDelay>
        <BlockSection
          fadeIn={true}
          title={"Questionnaire"}
          columns={1}
          rows={1}
        >
          <AnimatedButton onClick={() => setOpen(true)} text={"Open"} />
          <LargeModal open={open} setOpen={setOpen}>
            {questions.map((question) => (
              <Question
                key={question.id}
                question={question}
                textColor={MODAL_PRIMARY_TEXT_COLOR}
                isDisabled={false}
              />
            ))}
          </LargeModal>
        </BlockSection>
      </FadeAfterDelay>
    </>
  );
};
