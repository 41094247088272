import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalProvider } from "./State/GlobalContext";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { ShellProvider } from "./State/ShellContext";
import { IonApp, setupIonicReact } from "@ionic/react";
import { HeaderProvider } from "./State/HeaderContext";
import { BrowserRouter } from "react-router-dom";
import { HeadZoneStatProvider } from "./State/Solo/HeadZoneStatContext";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import "./index.css";
import "./tailwind_output.css";
import {
  AgentConfiguration,
  NewRelicCapacitorPlugin,
  NREnums,
} from "@newrelic/newrelic-capacitor-plugin";
import { getNewRelicToken, isWebPlatform } from "./Utils/mobileUtils";

if (!isWebPlatform() && getNewRelicToken()) {
  const agentConfig: AgentConfiguration = {
    analyticsEventEnabled: true,
    webViewInstrumentation: true,
    crashReportingEnabled: true,
    interactionTracingEnabled: true,
    networkRequestEnabled: true,
    networkErrorRequestEnabled: true,
    httpResponseBodyCaptureEnabled: true,
    loggingEnabled: true,
    logLevel: NREnums.LogLevel.INFO,
    sendConsoleEvents: true,
  };
  NewRelicCapacitorPlugin.start({
    appKey: getNewRelicToken(),
    agentConfiguration: agentConfig,
  });
}

dayjs.extend(dayjsPluginUTC);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const createRootFunction = () => {
  // AUTH0 pass back the token via hash url.  This need
  // to be capture right here before the SPA starts up
  // or else the url will get rewritten before it hits
  // the authenticatedpage and it can't look for the hash.
  const accessToken = new URLSearchParams(
    window.location.hash.replace("#", "")
  ).get("access_token");
  if (accessToken) {
    sessionStorage.setItem("vendor_access_token", accessToken);
  }

  return ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
};

const root = createRootFunction();

setupIonicReact();

root.render(
  <IonApp>
    <BrowserRouter>
      <GlobalProvider>
        <ShellProvider>
          <HeaderProvider>
            <HeadZoneStatProvider>
              <App />
            </HeadZoneStatProvider>
          </HeaderProvider>
        </ShellProvider>
      </GlobalProvider>
    </BrowserRouter>
  </IonApp>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
