import { Center, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import {
  DONT_HAVE_INVITE_CODE_STEP,
  REGISTER_STEP,
  SIGN_IN_STEP,
  SIGN_IN_WITH_EMAIL_STEP,
} from "./LoginPage";
import { PEOPLE_EVENTS, track } from "../../../Utils/Analytics";
import { HVTestId } from "../../../Testing/dataTestIds";
import { LoginLanguageSelector } from "../Shared/LoginLanguageSelector";
interface IntroStepProps {
  setCurrentStep: (currentStep: string | null) => void;
}

export const IntroStep = (props: IntroStepProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const { setCurrentStep } = props;

  return (
    <>
      <Center flexDir={"column"} gap={"4"}>
        <Text
          fontSize={isMobile ? "md" : "lg"}
          whiteSpace={"pre"}
          dangerouslySetInnerHTML={{
            __html: HVLocalizeStrings.LOGIN_PAGE_FIRST_STEP_INSTRUCTION_HAVE,
          }}
        />
        <Flex flexDir={"column"} alignItems={isMobile ? "center" : undefined}>
          <AnimatedButton
            text={HVLocalizeStrings.LOGIN_PAGE_SIGN_IN}
            colorSet={AnimatedButtonColorSet.Primary}
            onClick={() => {
              track(PEOPLE_EVENTS.IntroSignUpStepClick);
              setCurrentStep(SIGN_IN_STEP);
            }}
            w={300}
            dataTestId={HVTestId.IntroStep.signInButton}
          />
        </Flex>

        {/* hiding magic link button for the time being */}
        {/*<Flex flexDir={"column"} alignItems={isMobile ? "center" : undefined}>*/}
        {/*  <AnimatedButton*/}
        {/*    text={HVLocalizeStrings.LOGIN_PAGE_SIGN_IN_WITH_EMAIL}*/}
        {/*    colorSet={AnimatedButtonColorSet.Primary}*/}
        {/*    onClick={() => {*/}
        {/*      track(PEOPLE_EVENTS.IntroSignUpWithEmailStepClick);*/}
        {/*      setCurrentStep(SIGN_IN_WITH_EMAIL_STEP);*/}
        {/*    }}*/}
        {/*    w={300}*/}
        {/*    dataTestId={HVTestId.IntroStep.signInButton}*/}
        {/*  />*/}
        {/*</Flex>*/}

        <Text fontSize={isMobile ? "md" : "lg"} mt={4}>
          {HVLocalizeStrings.LOGIN_PAGE_FIRST_STEP_INSTRUCTION_DONT}
        </Text>

        <Flex
          flexDir={"column"}
          alignItems={isMobile ? "center" : undefined}
          gap={5}
        >
          <AnimatedButton
            text={HVLocalizeStrings.LOGIN_PAGE_INVITE_CODE_HAVE}
            colorSet={AnimatedButtonColorSet.Third}
            onClick={() => {
              track(PEOPLE_EVENTS.IntroHaveInviteCodeStepClick);
              setCurrentStep(REGISTER_STEP);
            }}
            w={300}
            dataTestId={HVTestId.IntroStep.hasInviteCodeButton}
          />

          <AnimatedButton
            text={HVLocalizeStrings.LOGIN_PAGE_INVITE_CODE_DONT}
            colorSet={AnimatedButtonColorSet.Third}
            onClick={() => {
              track(PEOPLE_EVENTS.IntroNoHaveInviteCodeStepClick);
              setCurrentStep(DONT_HAVE_INVITE_CODE_STEP);
            }}
            w={300}
            dataTestId={HVTestId.IntroStep.noInviteCodeButton}
          />

          <LoginLanguageSelector navigateToLogin={true} />
        </Flex>
      </Center>
    </>
  );
};
