import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../../Styles/HeadversityStyle";
import { HvTextInput } from "../../../Common/HvTextInput";
import React from "react";

interface ThinkingToolStepThinkingAccuratelyProps {
  changePage: (page: string) => void;
  nextStep: string;
  currentInputForNegativeThought: string;
  handleChangeNegativeThought: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ThinkingToolStepThinkingAccurately = (
  props: ThinkingToolStepThinkingAccuratelyProps
) => {
  const {
    changePage,
    handleChangeNegativeThought,
    currentInputForNegativeThought,
    nextStep,
  } = props;
  return (
    <>
      <Box color={MODAL_PRIMARY_TEXT_COLOR}>
        <Heading
          mt={"5"}
          fontSize={"2xl"}
          fontWeight={"semibold"}
          text-align={"left"}
        >
          {HVLocalizeStrings.THINKING_TOOLS_PRACTICE}
        </Heading>
        <Text mt={"2"} fontSize="md">
          {HVLocalizeStrings.THINKING_TOOLS_IDENTIFY}
        </Text>
        <Heading
          mt={"10"}
          fontSize={"2xl"}
          fontWeight={"semibold"}
          text-align={"left"}
        >
          {HVLocalizeStrings.THINKING_TOOLS_STEP_1}
        </Heading>
        <Text mt={"5"} fontSize="md">
          {HVLocalizeStrings.THINKING_TOOLS_CRITICAL}
        </Text>
      </Box>
      <HvTextInput
        onChange={(e) => handleChangeNegativeThought(e)}
        onEnterKeyDown={() => {
          if (currentInputForNegativeThought) {
            changePage(nextStep);
          }
        }}
        placeholder={HVLocalizeStrings.THINKING_TOOLS_PEOPLE_THINK}
        autoFocus={window.screen.width >= 568}
      />
      <Center mt={8} mb={2}>
        <AnimatedButton
          disabled={!currentInputForNegativeThought.trim()}
          text={HVLocalizeStrings.NEXT}
          onClick={() => changePage(nextStep)}
        />
      </Center>
    </>
  );
};
