import { useContext, useEffect, useState } from "react";
import {
  DEFAULT_THEME_NAME,
  IShellProvider,
  ShellContext,
} from "../../../State/ShellContext";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { LandingContainer } from "../Shared/LandingContainer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ErrorText } from "../Shared/ErrorText";
import {
  ErrorCodeType,
  RegistrationValidationDto,
  UserDto,
} from "@headversity/contract";
import { validate } from "../../../Api/People/RegistrationApi";
import { setLocalStorageAuthTokens } from "../../../Utils/LoginUtil";
import { peekRedirectPath } from "../../../Utils/NavigationUtils";
import { SELECTED_USER_LANGUAGE_KEY } from "../../../Utils/LanguageUtil";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { useLoginLanguageDetector } from "../../../Hooks/People/useLoginLanguageDetector";

export const RegistrationValidationPage = () => {
  const { updateSelectedUserLanguageToServer } =
    useContext<IGlobalProvider>(GlobalContext);
  const { setThemeHelper } = useContext<IShellProvider>(ShellContext);
  const [error, setError] = useState<string | null>(null);
  const [isPusher, setIsPusher] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  useLoginLanguageDetector();

  const getGenericErrorMessageForValidateLogin = () => {
    return searchParams.get("fromScorm") === "true"
      ? HVLocalizeStrings.INVALID_GENERIC_LOGIN_CODE
      : HVLocalizeStrings.INVALID_OR_ALREADY_USED_VALIDATION_LOGIN_CODE;
  };
  const getValidateResult = async () => {
    setError("");
    const validationCode = searchParams.get("code");
    const ignorePusher = searchParams.get("ignorePusher") === "true";

    if (validationCode) {
      const result = (
        await validate({
          code: validationCode,
          ignorePusher: ignorePusher,
        } as RegistrationValidationDto)
      ).data;
      if (result.errorCode !== undefined) {
        setError(
          location.pathname === "/validate-login"
            ? result.errorCode === ErrorCodeType.UserScormMatchingInvalid
              ? HVLocalizeStrings.INVALID_SCORM_LOGIN_CODE
              : getGenericErrorMessageForValidateLogin()
            : HVLocalizeStrings.INVALID_OR_ALREADY_USED_VALIDATION_CODE
        );
        return;
      }

      if (result.pusher) {
        setIsPusher(true);
        return;
      }

      await setLocalStorageAuthTokens(result);

      updateSelectedUserLanguageToServer(
        result as UserDto,
        localStorage.getItem(SELECTED_USER_LANGUAGE_KEY) ??
          HVLocalizeStrings.getLanguage()
      );
      const mobileLogin = sessionStorage.getItem("isMobile");
      if (mobileLogin) {
        window.location.replace(
          `${process.env.REACT_APP_DEEP_LINK_URL}/waiting?dt=${result.access_token}&rt=${result.refresh_token}`
        );
      } else {
        const path = peekRedirectPath();
        navigate(path, { replace: true });
      }
    } else {
      setError(
        location.pathname === "/validate-login"
          ? getGenericErrorMessageForValidateLogin()
          : HVLocalizeStrings.INVALID_OR_ALREADY_USED_VALIDATION_CODE
      );
    }
  };

  useEffect(() => {
    getValidateResult();
  }, [searchParams]);

  useEffect(() => {
    setThemeHelper(DEFAULT_THEME_NAME);
  }, []);

  return (
    <LandingContainer other={<></>}>
      {!error && !isPusher && (
        <Flex justify={"center"} p={"40px"} color={PRIMARY_TEXT_COLOR}>
          <Spinner />
        </Flex>
      )}

      {isPusher && (
        <Flex align={"center"} p={"40px"} pt={0} color={PRIMARY_TEXT_COLOR}>
          <Text maxW={"345px"}>{HVLocalizeStrings.CLOSE_THIS_BROWSER}</Text>
        </Flex>
      )}

      {error && (
        <Flex
          align={"center"}
          justifyContent={"center"}
          p={"40px"}
          pt={0}
          color={PRIMARY_TEXT_COLOR}
        >
          <ErrorText message={error} />
        </Flex>
      )}
    </LandingContainer>
  );
};
