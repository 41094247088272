export const translate = (obj: any, lang: string, property: string) => {
  if (!obj || !property) {
    return "";
  }
  if (!lang || lang === "") {
    return obj[`${property}`];
  }
  const capitalizedLang = lang[0].toUpperCase() + lang.slice(1);
  return obj[`${property}${capitalizedLang}`] || obj[`${property}`];
};

export const translateLocalizationText = (obj: any, lang: string) => {
  if (!obj) {
    return "";
  }
  if (!lang || lang === "" || !obj[lang]) {
    if (obj["en"]) {
      return obj[`en`];
    } else if (obj["fr"]) {
      return obj[`fr`];
    } else if (obj["es"]) {
      return obj[`es`];
    }
  }
  return obj[lang];
};
