import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import { BlogPostDto, QuestionAnswerDto } from "@headversity/contract";

export const getBlogs = (
  token: string
): Promise<AxiosResponse<BlogPostDto[]>> => {
  return AxiosClient.get("api/blogs", getConfig(token));
};

export const getBlogById = (
  token: string,
  blogId: number
): Promise<AxiosResponse<BlogPostDto>> => {
  return AxiosClient.get(`api/blogs/${blogId}`, getConfig(token));
};

export const saveBlogView = (token: string, blogId: number) => {
  return AxiosClient.post(`api/blogView/${blogId}`, null, getConfig(token));
};

export const saveBlogQuestionAnswers = (
  token: string,
  blogId: number,
  questionAnswer: QuestionAnswerDto[]
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `/api/blogs/${blogId}/questionAnswers`,
    questionAnswer,
    getConfig(token)
  );
};
