import React, { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { LargeModal } from "../../../Common/LargeModal";
import { MindfulnessAudioList } from "./MindfulnessAudioList";
import { IShellProvider, ShellContext } from "../../../../State/ShellContext";
import { Box } from "@chakra-ui/react";
import { isWebPlatform } from "../../../../Utils/mobileUtils";

interface MindfulnessToolModalProps {
  open: boolean;
  setOpen: (val: boolean) => {};
}

export const MindfulnessToolModal = (props: MindfulnessToolModalProps) => {
  const { open, setOpen } = props;
  const { mindfulnessAudioSeries } = useContext<IGlobalProvider>(GlobalContext);
  const { audio, setAudio, safeAreaInsets } =
    useContext<IShellProvider>(ShellContext);

  return (
    <>
      <LargeModal
        open={open}
        setOpen={setOpen}
        title={HVLocalizeStrings.MINDFULNESS_TOOLS}
      >
        <Box pb={`${!isWebPlatform() ? safeAreaInsets.bottom + 80 : 0}px`}>
          {mindfulnessAudioSeries &&
            mindfulnessAudioSeries.length > 0 &&
            mindfulnessAudioSeries.map((item) => {
              if (item === null) {
                return null;
              }
              return (
                <MindfulnessAudioList
                  key={item.id}
                  title={item.name as string}
                  image={`https://cdn.headversity.com/app/resources/mindfulness/${item.id}.jpg`}
                  description={item.description as string}
                  items={item.audioTracks}
                  mindfulnessAudio={audio}
                  setMindfulnessAudio={setAudio}
                ></MindfulnessAudioList>
              );
            })}
        </Box>
      </LargeModal>
    </>
  );
};
