import { useEffect } from "react";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useNavigate } from "react-router-dom";
import { getSelf } from "./Api/People/UserApi";
import { getKey } from "./Utils/Helpers";
import { useLocation } from "react-router";

export const AppUrlListener = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      if (event?.url) {
        const url = new URL(event.url);
        const deepLinkUrl = new URL(
          process.env.REACT_APP_DEEP_LINK_URL as string
        );
        const navigateUrl = `${url.pathname}${url.search}`;
        if (
          url?.host?.toLocaleLowerCase() ===
          deepLinkUrl?.host?.toLocaleLowerCase()
        ) {
          if (
            location.pathname.toLocaleLowerCase().indexOf("/mobileLogin") > -1
          ) {
            sessionStorage.setItem("app-url-open-link", navigateUrl);
          } else if (
            url.pathname.toLocaleLowerCase().indexOf("/reset-password") > -1
          ) {
            navigate(navigateUrl);
          } else {
            getSelf(getKey()).then(
              (response: any) => {
                navigate(navigateUrl);
              },
              (error: any) => {
                sessionStorage.setItem("app-url-open-link", navigateUrl);
                navigate("/mobileLogin", { replace: true });
              }
            );
          }
        }
      }
    });
    return () => {
      App.removeAllListeners().then();
    };
  }, []);
  return null;
};
