import { AxiosClient } from "../AxiosClient";
import { cachedApiCall, getConfig } from "../Utils";
import {
  InviteCodeForReachDto,
  UserAppAccessInputDto,
  UserStateDto,
  UserThemeInputDto,
} from "@headversity/contract";
import { AxiosResponse } from "axios";

export const getSelf = (token: string) => {
  return AxiosClient.get("api/self", getConfig(token));
};

export const deleteSelf = (token: string, password: string) => {
  return AxiosClient.post(
    "api/self/delete",
    { password: password },
    getConfig(token)
  );
};

export const setUserState = (
  token: string,
  userStateDto: UserStateDto
): Promise<AxiosResponse<void>> => {
  return AxiosClient.put(`api/state`, userStateDto, getConfig(token));
};

export const getRedirectUrl = (
  token: string,
  path: string,
  redirectDomain: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    "api/redirect-url",
    { path: path, redirectDomain: redirectDomain },
    getConfig(token)
  );
};

export const saveSiteLoadedEvent = (
  token: string,
  userAppAccessInputDto: UserAppAccessInputDto
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    "api/self/setUserAppAccess",
    userAppAccessInputDto,
    getConfig(token)
  );
};

export const updateSelectedUserTheme = (
  token: string,
  userThemeInputDto: UserThemeInputDto
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/self/theme`,
    userThemeInputDto,
    getConfig(token)
  );
};

export const enableMigratedToOneSignalFlag = (token: any) => {
  return AxiosClient.get(
    `users/enable-migrated-to-onesignal-flag`,
    getConfig(token)
  );
};

export const getFamilyInviteCode = async (
  token: string
): Promise<AxiosResponse<InviteCodeForReachDto | null>> => {
  return cachedApiCall("familyInviteCodeAPIResponseCache", 5000, async () => {
    return await AxiosClient.get<InviteCodeForReachDto | null>(
      "/api/familyInviteCode",
      getConfig(token)
    );
  });
};

export const resendActivationEmail = (
  token: string,
  userId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/users/${userId}/resendActivationEmail`,
    null,
    getConfig(token)
  );
};
