import { useCallback, useEffect, useState } from "react";
import { getKey } from "../../Utils/Helpers";
import OneSignal from "onesignal-cordova-plugin";
import OneSignalWeb from "react-onesignal";
import { useNavigate } from "react-router-dom";
import { enableMigratedToOneSignalFlag } from "../../Api/People/UserApi";
import { isWebPlatform } from "../../Utils/mobileUtils";
import dayjs from "dayjs";
import {
  CompanyDto,
  GoalDto,
  SchedulerDto,
  SchedulerFrequency,
  UserDto,
} from "@headversity/contract";
import { getFrequencyFromScheduleExpression } from "../../Utils/CronParserUtil";

export interface IPushNotificationGlobal {
  commitUserInformation: (
    user: UserDto,
    company: CompanyDto,
    goal?: GoalDto,
    userLanguage?: string,
    practiceReminder?: SchedulerDto[]
  ) => void;
  promptForPushPermission: () => void;
  pushDisabled: boolean;
  oneSignalInitialized: boolean;
}

export const usePushNotificationGlobal = (): IPushNotificationGlobal => {
  const [pushDisabled, setPushDisabled] = useState(true);
  const [oneSignalInitialized, setOneSignalInitialized] = useState(false);
  const [pushUrl, setPushUrl] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const impersonatingUserId = localStorage.getItem("impersonatingUserId");
    if (impersonatingUserId) {
      return;
    }
    if (process.env.REACT_APP_ONE_SIGNAL_APP_ID as any) {
      if (!isWebPlatform()) {
        OneSignal.initialize(process.env.REACT_APP_ONE_SIGNAL_APP_ID as string);
        OneSignal.Notifications.addEventListener("click", clickEventListener);
        OneSignal.Notifications.addEventListener(
          "permissionChange",
          (permission: boolean) => {
            setPushDisabled(!permission);
          }
        );
        setOneSignalInitialized(true);
        checkDevicePermission();
      } else {
        const initOneSignal = async () => {
          await OneSignalWeb.init({
            appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID as string,
            allowLocalhostAsSecureOrigin: true,
          });
          setOneSignalInitialized(true);
          OneSignalWeb.Notifications.addEventListener(
            "click",
            clickEventListener
          );
          OneSignalWeb.Notifications.addEventListener(
            "permissionChange",
            (permission: boolean) => {
              setPushDisabled(!permission);
            }
          );
          checkDevicePermission();
        };
        initOneSignal();
      }
    }
  }, []);

  const promptForPushPermission = useCallback(() => {
    const impersonatingUserId = localStorage.getItem("impersonatingUserId");
    if (impersonatingUserId) {
      return;
    }
    if (!isWebPlatform()) {
      OneSignal.Notifications.requestPermission(true).then((accepted) => {
        OneSignal.User.pushSubscription.optIn();
        setPushDisabled(!accepted);
      });
    } else {
      OneSignalWeb.Notifications.requestPermission();
    }
  }, []);

  const clickEventListener = (event: any) => {
    // Shared between web and mobile NotificationClickEvent

    let navigateUrl = null;
    if (event?.notification?.additionalData?.url) {
      // url should be stored here for route redirection
      navigateUrl = event.notification.additionalData.url;
    } else if (event?.result?.url) {
      // fallback, this takes the full url
      // get relative path from navigateUrl, including search params
      const url = new URL(event.result.url);
      navigateUrl = `${url.pathname}${url.search}${url.hash}`;
    }

    if (!getKey()) {
      // user not logged in, defer navigate to push url
      setPushUrl(navigateUrl);
    } else {
      // user logged in, navigate imediately
      navigate(navigateUrl);
    }
  };

  const checkDevicePermission = () => {
    const impersonatingUserId = localStorage.getItem("impersonatingUserId");
    if (impersonatingUserId) {
      return;
    }
    if (!isWebPlatform()) {
      setPushDisabled(!OneSignal.Notifications.hasPermission());
    } else {
      setPushDisabled(!OneSignalWeb.Notifications.permission);
    }
  };

  const enableMigratedToOneSignalFlagToServer = useCallback(() => {
    return enableMigratedToOneSignalFlag(getKey());
  }, []);

  const commitUserInformation = (
    user: UserDto,
    company: CompanyDto,
    goal?: GoalDto,
    userLanguage?: string,
    practiceReminder?: SchedulerDto[]
  ) => {
    // done on purpose with + "", for some reason it crashes
    // in ios, even with toString.  Don't think web is needed,
    // but might as well keep it the same.
    const libToUse = !isWebPlatform() ? OneSignal : OneSignalWeb;
    libToUse.login(user.id + "");
    libToUse.User.addEmail(user.email.toLowerCase());
    libToUse.User.addTag("companyId", user.companyId + "");
    libToUse.User.addTag("inviteCodeId", user.inviteCodeId + "");
    libToUse.User.addTag("account_activated", dayjs.utc().unix().toString());
    libToUse.User.addTag("disableSolo", company.disableSolo ? "true" : "false");
    if (goal) {
      // These are only used on the frontend
      libToUse.User.addTag("goalId", goal.id + "");
      libToUse.User.addTag("goalName", goal.name + "");
    }
    if (userLanguage) {
      libToUse.User.addTag("language", userLanguage);
    }
    if (practiceReminder && practiceReminder.length > 0) {
      libToUse.User.addTag(
        "practiceReminder",
        SchedulerFrequency[
          getFrequencyFromScheduleExpression(
            practiceReminder[practiceReminder.length - 1].scheduleExpression
          )
        ] as string
      );
    } else {
      libToUse.User.removeTag("practiceReminder");
    }

    checkDevicePermission();
    if (pushUrl) {
      setPushUrl(null); // clear push url before navigating
      navigate(pushUrl);
    }
  };

  return {
    commitUserInformation,
    promptForPushPermission,
    pushDisabled,
    oneSignalInitialized,
  };
};
