import { GoalWinThemeDto } from "@headversity/contract";
import { createContext, FC, ReactNode, useMemo, useState } from "react";

type ReachGoalsState = {
  goalWinThemes: GoalWinThemeDto[];
  setGoalWinThemes: (goalWinThemes: GoalWinThemeDto[]) => void;
  initialized: boolean;
  setInitialized: (initialized: boolean) => void;
  screen: ReachGoalsScreen;
  setScreen: (screen: ReachGoalsScreen) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};
export enum ReachGoalsScreen {
  INITIAL,
  VIEW,
  SELECTION,
}

export const ReachGoalsContext = createContext<ReachGoalsState>({
  goalWinThemes: [],
  setGoalWinThemes: () => {},
  initialized: false,
  setInitialized: () => {},
  screen: ReachGoalsScreen.INITIAL,
  setScreen: () => {},
  isLoading: false,
  setIsLoading: () => {},
});

export const ReachGoalsContextProvider: FC<{
  children: ReactNode;
}> = (props) => {
  const [goalWinThemes, setGoalWinThemes] = useState<GoalWinThemeDto[]>([]);
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [screen, setScreen] = useState(ReachGoalsScreen.INITIAL);
  const state: ReachGoalsState = useMemo(
    () => ({
      goalWinThemes,
      setGoalWinThemes,
      initialized,
      setInitialized,
      screen,
      setScreen,
      isLoading,
      setIsLoading,
    }),
    [
      goalWinThemes,
      setGoalWinThemes,
      initialized,
      setInitialized,
      screen,
      setScreen,
      isLoading,
      setIsLoading,
    ]
  );
  return (
    <ReachGoalsContext.Provider value={state}>
      {props.children}
    </ReachGoalsContext.Provider>
  );
};
