import { Text } from "@chakra-ui/react";
import { NodeDto } from "@headversity/contract";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { White } from "../../../Styles/HeadversityStyle";
import { getListingCommaAndXMoreString } from "../../../Utils/StringUtils";

interface NodesFilterTagsProps {
  nodesFilter?: NodeDto[];
}

export const NodesFilterTags = (props: NodesFilterTagsProps) => {
  const { nodesFilter } = props;

  if (nodesFilter && nodesFilter.length > 0) {
    const nodeNames = nodesFilter.map((node) => node.name as string);
    const filterTagsString = getListingCommaAndXMoreString(
      nodeNames,
      HVLocalizeStrings.AND,
      2,
      HVLocalizeStrings.AND_X_MORE
    );

    return (
      <Text mt={"-5px"} fontSize={"xl"} color={White}>
        {filterTagsString}
      </Text>
    );
  }
  return <></>;
};
