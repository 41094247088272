import { Box, Center, Link, Text } from "@chakra-ui/react";
import { useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TermsOfUse } from "./TermsOfUse";

export const TermsOfUsePrivacyPolicy: React.FC = (): JSX.Element => {
  const [termsOfUseModalOpen, setTermsOfUseModalOpen] = useState(false);
  const [privatePolicyModalOpen, setPrivatePolicyModalOpen] = useState(false);

  return (
    <>
      <Center flexDir={"column"} gap={"4"}>
        <Box w="275px">
          <Text fontWeight={"500"} fontSize={"sm"} mt={3} textAlign="center">
            {HVLocalizeStrings.PRIVACY_POLICY}
            <Link
              textDecoration={"underline"}
              href="#"
              onClick={(e) => {
                setTermsOfUseModalOpen(true);
                e.preventDefault();
              }}
            >
              {HVLocalizeStrings.SOLO_MENU_TERMS_OF_SERVICE}
            </Link>{" "}
            {HVLocalizeStrings.AND}{" "}
            <Link
              textDecoration={"underline"}
              href="#"
              onClick={(e) => {
                setPrivatePolicyModalOpen(true);
                e.preventDefault();
              }}
            >
              {HVLocalizeStrings.SOLO_MENU_PRIVACY_POLICY}
            </Link>
          </Text>
        </Box>
      </Center>
      <TermsOfUse
        termsOfUseModalOpen={termsOfUseModalOpen}
        openTermsOfUseModal={setTermsOfUseModalOpen}
      />
      <PrivacyPolicy
        privacyPolicyOpen={privatePolicyModalOpen}
        openPrivacyPolicy={setPrivatePolicyModalOpen}
      />
    </>
  );
};
