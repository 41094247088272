import { SlideFade, Center, useBreakpointValue } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { SlideFade_Offset } from "../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../Testing/dataTestIds";
import { WelcomeModalHeader } from "./WelcomeModalHeader";
import { useEffect, useRef } from "react";
import { EVENTS, track } from "../../../Utils/Analytics";
import Player from "@vimeo/player";

interface StepVideoProps {
  videoUrl: string;
  howWorksHeadline?: string;
}

export const StepVideo = ({ videoUrl, howWorksHeadline }: StepVideoProps) => {
  const loggedStart = useRef(false);
  const loggedMidway = useRef(false);
  const loggedComplete = useRef(false);

  const isDesktop = useBreakpointValue({ base: false, md: true });

  const trackVideo = () => {
    const vimeoIframe = document.querySelector(
      ".embed--iframe iframe:not(.examined)"
    );

    // doesn't appear to be possible, but just in case we'll try again
    if (!vimeoIframe) {
      setTimeout(() => trackVideo(), 250);
      return;
    }

    const player = new Player(vimeoIframe as any);

    // attempt to autoplay
    setTimeout(() => {
      player.play();
    }, 1000);

    player.on("play", () => {
      console.debug("PLAYBACK STARTED");
    });

    player.on("timeupdate", (data: any) => {
      const progress = Math.floor((data.seconds / data.duration) * 100);
      console.debug("PLAYBACK PROGRESS", progress);

      if (progress > 0 && !loggedStart.current) {
        loggedStart.current = true;
        track(EVENTS.WelcomeVideoProgressStart);
      }

      if (progress >= 50 && !loggedMidway.current) {
        loggedMidway.current = true;
        track(EVENTS.WelcomeVideoProgressMidway);
      }

      if (progress >= 95 && !loggedComplete.current) {
        loggedComplete.current = true;
        track(EVENTS.WelcomeVideoProgressComplete);
      }
    });
  };

  useEffect(() => {
    trackVideo();
  }, []);

  return (
    <SlideFade in={true} offsetY={SlideFade_Offset}>
      <WelcomeModalHeader>
        {howWorksHeadline ? (
          <>{howWorksHeadline}</>
        ) : (
          <>{HVLocalizeStrings.WELCOME_VIDEO}</>
        )}
      </WelcomeModalHeader>
      <Center
        filter="drop-shadow(0 0.2rem 0.25rem rgba(128, 128, 128, .8));"
        mt="5px"
        className="embed--iframe"
      >
        <iframe
          src={videoUrl}
          width={isDesktop ? "600px" : `${window.innerWidth - 20}px`}
          height={isDesktop ? "400px" : `${(window.innerWidth - 20) / 1.5}px`}
          title="video"
          allowFullScreen={true}
          data-testid={HVTestId.WelcomeModal.steps.StepVideo}
        ></iframe>
      </Center>
    </SlideFade>
  );
};
