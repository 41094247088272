import { useContext } from "react";
import {
  Icon,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  DESTRUCTIVE_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { MdCancel } from "react-icons/md";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
} from "../../../State/GlobalContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import { TeamDto, TeamUserDto, TeamUserRole } from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";
import { FaCheck } from "react-icons/fa";
import { HVSportLocalizeStrings } from "../../../Localization/HVSportLocalizeString";

interface TeamListProps {
  teamMembers: TeamUserDto[];
  team: TeamDto;
  contentAbovePager?: JSX.Element;
  onRowClick?: (member: TeamUserDto) => void;
}

export const TeamList = (props: TeamListProps) => {
  const { teamMembers, team, contentAbovePager } = props;
  const { deleteTeamUserFromServer, currentApp } =
    useContext<IGlobalProvider>(GlobalContext);

  let currentMembers = teamMembers;
  if (currentApp === AppType.SPORT) {
    currentMembers = teamMembers?.filter(
      (tm) => tm.role !== TeamUserRole.Leader
    );
  }

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const { confirmationDialogRef, showToast } =
    useContext<IShellProvider>(ShellContext);

  const handleDeleteTeamUser = (member: TeamUserDto) => {
    (confirmationDialogRef.current as any).confirmation(
      HVLocalizeStrings.TEAM_DELETE_USER,
      `${HVLocalizeStrings.TEAM_USER_CONFIRM_DELETE.replace(
        "{0}",
        member?.user?.fullName
      ).replace("{1}", team?.name)}`,
      ConfirmationButtons.YesNo,
      () => {
        track(TEAM_EVENTS.DeleteTeamUser, {
          HV_Team_Id: team?.id,
          HV_Team_Name: team?.name,
        });
        deleteTeamUserFromServer(team?.id, member?.userId).then(() => {
          showToast(
            true,
            "team-user-delete-success",
            HVLocalizeStrings.TEAM_USER_DELETE_SUCCESS_TITLE
          );
        });
      }
    );
  };

  const handleRowClick = (member: TeamUserDto) => {
    props.onRowClick?.(member);
  };

  const sortedMembers = currentMembers?.sort((a, b) =>
    a?.user?.fullName.localeCompare(b?.user?.fullName)
  );

  return (
    <>
      {currentApp === AppType.SPORT ? (
        <TableContainer w={"100%"}>
          <Table color={PRIMARY_TEXT_COLOR}>
            <Thead>
              <Tr>
                {!isMobile && (
                  <Th
                    fontWeight={"normal"}
                    fontSize={"sm"}
                    w={isMobile ? "80%" : "45%"}
                  >
                    {HVLocalizeStrings.NAME}
                  </Th>
                )}

                <Th fontWeight={"normal"} fontSize={"sm"} w={"45%"}>
                  {HVLocalizeStrings.EMAIL}
                </Th>

                {/* hiding foundational training for now */}
                {/*
                <Th
                  fontWeight={"normal"}
                  fontSize={"sm"}
                  w={isMobile ? "20%" : "10%"}
                  whiteSpace="normal"
                >
                  {HVSportLocalizeStrings.CHARTER_FOUNDATIONAL_TRAINING_COMPLETE}
                </Th>
                */}
              </Tr>
            </Thead>
            <Tbody>
              {sortedMembers?.map((member) => (
                <Tr
                  key={member.id}
                  cursor={"pointer"}
                  onClick={() => handleRowClick(member)}
                >
                  {!isMobile ? (
                    <Td pb={"0"} pl={"0"} pr={"0"} pt={"1"}>
                      <Text data-testid={HVTestId.TeamList.name}>
                        {member?.user?.fullName}
                      </Text>
                    </Td>
                  ) : null}

                  <Td>
                    <Text data-testid={HVTestId.TeamList.email}>
                      {member?.user?.email}
                    </Text>
                  </Td>

                  {/* hiding foundational training for now */}
                  {/*
                  <Td textAlign="left">
                    {member?.insights?.completedFoundationalTraining && (
                      <Icon as={FaCheck} />
                    )}
                  </Td>
                  */}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer w={"100%"}>
          <Table color={PRIMARY_TEXT_COLOR}>
            <Thead>
              <Tr>
                <Th
                  fontWeight={"normal"}
                  fontSize={"sm"}
                  w={isMobile ? "50%" : "33%"}
                >
                  {HVLocalizeStrings.NAME}
                </Th>
                {!isMobile && (
                  <Th fontWeight={"normal"} fontSize={"sm"} w={"33%"}>
                    {HVLocalizeStrings.EMAIL}
                  </Th>
                )}
                <Th
                  fontWeight={"normal"}
                  fontSize={"sm"}
                  w={isMobile ? "50%" : "33%"}
                ></Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedMembers?.map((member) => (
                <Tr key={member.id} onClick={() => handleRowClick(member)}>
                  <Td pb={"0"} pl={"0"} pr={"0"} pt={"1"}>
                    <Text
                      fontWeight={"semibold"}
                      data-testid={HVTestId.TeamList.name}
                    >
                      {member?.user?.fullName}
                    </Text>
                  </Td>
                  {!isMobile ? (
                    <Td>
                      <Text data-testid={HVTestId.TeamList.email}>
                        {member?.user?.email}
                      </Text>
                    </Td>
                  ) : null}

                  <Td textAlign={"right"}>
                    <IconButton
                      aria-label="Delete team-member"
                      bg={"none"}
                      sx={{ _hover: {} }}
                      icon={<MdCancel />}
                      color={DESTRUCTIVE_TEXT_COLOR}
                      onClick={() => handleDeleteTeamUser(member)}
                      data-testid={HVTestId.TeamList.deleteButton}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {contentAbovePager}
    </>
  );
};
