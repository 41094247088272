import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import { CHANNEL_VERSION } from "../../Utils/mobileUtils";

export const getDefaultChannel = (): Promise<AxiosResponse<string>> => {
  // version= need to be updated everytime we
  // release a new native build.
  return AxiosClient.get(`channel?version=${CHANNEL_VERSION}`);
};

export const getAuthenticatedChannel = (
  token: string
): Promise<AxiosResponse<string>> => {
  return AxiosClient.get("api/self/channel", getConfig(token));
};
