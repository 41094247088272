import {
  Button,
  HStack,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { useRef } from "react";
import {
  Border_Radius,
  BUTTON_PRIMARY_TEXT_COLOR,
  DESTRUCTIVE_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";

interface ActionButtonProps {
  image: string;
  imagePadding?: string;
  imageMarginLeft?: string;
  title: string;
  border?: string;
  bgColor?: string;
  color?: string;
  width?: string;
  description: any;
  toolTip?: any;
  onClick?: () => void;
  openMenuOnClick?: boolean;
  actions: string[];
  actionCallBack: (actionItem: string) => void;
  hideBoxShadow?: boolean;
  dataTestId?: string;
}

export const ActionButton = (props: ActionButtonProps) => {
  const {
    image,
    imagePadding,
    imageMarginLeft,
    title,
    description,
    actions,
    actionCallBack,
    onClick,
    bgColor,
    color,
    toolTip,
    border,
    width,
    openMenuOnClick,
    hideBoxShadow,
    dataTestId,
  } = props;

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const buttonRef = useRef(null);

  return (
    <HStack
      border={border ?? ""}
      borderRadius={Border_Radius}
      color={color}
      bg={bgColor}
      cursor={onClick || openMenuOnClick ? "pointer" : "normal"}
      minH={isDesktop ? "90px" : "75px"}
      minW={isDesktop ? "384px" : "300px"}
      w={width}
      boxShadow={hideBoxShadow ? "" : "lg"}
      data-testid={dataTestId}
    >
      <Link
        className={"button-link"}
        width={"100%"}
        cursor={onClick || openMenuOnClick ? "pointer" : "normal"}
        onClick={() => {
          if (onClick) {
            onClick();
          } else if (openMenuOnClick) {
            (buttonRef.current as any)?.click();
          }
        }}
        _hover={{ textDecoration: "none" }}
      >
        <HStack ml={3}>
          {image && (
            <Image
              w={"60px"}
              ml={imageMarginLeft}
              p={imagePadding ?? "2"}
              borderRightRadius={Border_Radius}
              src={image}
              alt=""
            />
          )}
          <HStack flex={"1"}>
            <Tooltip label={toolTip}>
              <VStack flex={"1"} align={"flex-start"} p={3}>
                <Text fontSize={"lg"} fontWeight={"semibold"}>
                  {title}
                </Text>
                <Text fontSize={"md"} mt={"1.5"}>
                  {description}
                </Text>
              </VStack>
            </Tooltip>
            {actions?.length > 0 && (
              <VStack align={"center"} w={"37px"} color={PRIMARY_TEXT_COLOR}>
                <Menu placement={"bottom-end"}>
                  <MenuButton
                    variant="ghost-on-accent"
                    as={Button}
                    bg={"transparent"}
                    color={color}
                    ref={buttonRef}
                  >
                    <Icon as={FiMoreVertical} />
                  </MenuButton>
                  <MenuList zIndex={999}>
                    {actions.map((item) => {
                      return (
                        <MenuItem
                          key={item}
                          onClick={() => {
                            actionCallBack(item);
                          }}
                          sx={{
                            ":hover": {
                              background: `${
                                item
                                  .toLocaleLowerCase()
                                  .includes(HVLocalizeStrings.DELETE)
                                  ? DESTRUCTIVE_TEXT_COLOR
                                  : null
                              }`,
                              color: `${
                                item
                                  .toLocaleLowerCase()
                                  .includes(HVLocalizeStrings.DELETE)
                                  ? BUTTON_PRIMARY_TEXT_COLOR
                                  : null
                              }`,
                            },
                          }}
                          data-testid={`${HVTestId.ActionButton.menuItem}_${item}`}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
              </VStack>
            )}
          </HStack>
        </HStack>
      </Link>
    </HStack>
  );
};
