import { Box, Center, Flex, Heading } from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";

interface QuestionLayoutProps {
  title: string;
  answerAreaContent: any;
  submitAreaContent: any;
  submitAreaContentMt?: any;
  spacing?: string;
  answerAreaContentSpacing?: any;
  textColor?: string;
  useSubmitAreaContent?: boolean;
  useHeading?: boolean;
}

export const QuestionLayout = (props: QuestionLayoutProps) => {
  const {
    title,
    answerAreaContent,
    submitAreaContent,
    submitAreaContentMt,
    spacing,
    answerAreaContentSpacing,
    textColor,
    useSubmitAreaContent = true,
    useHeading = true,
  } = props;
  return (
    <>
      <Flex flexDir={"column"} gap={spacing ?? "10"}>
        {useHeading && (
          <Center>
            <Heading
              fontSize={"22px"}
              lineHeight="1.7rem"
              color={textColor ?? MODAL_PRIMARY_TEXT_COLOR}
              textAlign={{ base: undefined, lg: "center" }}
              fontWeight={500}
              data-testid={HVTestId.QuestionLayout.question}
            >
              {title}
            </Heading>
          </Center>
        )}
        <Flex
          flexDir={"column"}
          align={"center"}
          gap={answerAreaContentSpacing ?? "6"}
        >
          {answerAreaContent}
        </Flex>
      </Flex>
      {useSubmitAreaContent && (
        <Box mt={submitAreaContentMt ? submitAreaContentMt : 10}>
          <Center>{submitAreaContent}</Center>
        </Box>
      )}
    </>
  );
};
