import { Flex, Heading, Text } from "@chakra-ui/react";

export interface InsightsNumberDisplayProps {
  heading: string;
  headingColor?: string;
  description?: string;
  height?: string;
  fontSize?: string;
}

export const InsightsNumberDisplay = (props: InsightsNumberDisplayProps) => {
  const { heading, description, headingColor, height, fontSize } = props;
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      h={height}
    >
      <Heading color={headingColor} fontWeight="semibold" fontSize={fontSize}>
        {heading}
      </Heading>
      {description && <Text fontSize="sm">{description}</Text>}
    </Flex>
  );
};
