import { BoxProps, Divider, Stack, useBreakpointValue } from "@chakra-ui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { StepCircle } from "./StepCircle";

interface StepProps extends BoxProps {
  description: any;
  isComplete: boolean;
  isAllComplete: boolean;
  isActive: boolean;
  isLastStep: boolean;
  isFirstStep: boolean;
  hideCircle?: boolean;
  forceHorizontal?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  isChecked?: boolean;
  isNotChecked?: boolean;
  showCompleteAsCircle?: boolean;
}

export const Step = (props: StepProps) => {
  const {
    isActive,
    isComplete,
    isAllComplete,
    isLastStep,
    isFirstStep,
    description,
    hideCircle,
    forceHorizontal,
    isFirst,
    isLast,
    isChecked,
    isNotChecked,
    showCompleteAsCircle,
    ...stackProps
  } = props;

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const orientation = useBreakpointValue<"horizontal" | "vertical">({
    base: isAllComplete ? "horizontal" : "vertical",
    lg: "horizontal",
  });

  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldAnimate(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Stack
      spacing="4"
      direction={{
        base: isAllComplete || forceHorizontal ? "column" : "row",
        lg: "column",
      }}
      flex="1"
      {...stackProps}
    >
      <Stack
        spacing="0"
        align="center"
        direction={{
          base: isAllComplete || forceHorizontal ? "row" : "column",
          lg: "row",
        }}
      >
        <Divider
          visibility={isFirst ? "hidden" : undefined}
          display={
            isMobile && !isAllComplete && !forceHorizontal ? "none" : undefined
          }
          orientation={orientation}
          borderWidth="1px"
          borderColor={
            isFirstStep
              ? "transparent"
              : isComplete || isActive
              ? "accent"
              : "inherit"
          }
        />
        {!hideCircle && (
          <StepCircle
            isActive={isActive}
            isComplete={(isComplete || !!isChecked) && !isNotChecked}
            showCompleteAsCircle={showCompleteAsCircle}
          />
        )}
        <Divider
          visibility={isLast ? "hidden" : undefined}
          orientation={orientation}
          borderWidth="1px"
          borderColor={
            isComplete ? "accent" : isLastStep ? "transparent" : "inherit"
          }
        />
      </Stack>
      <Stack
        pb={
          isMobile && !isLastStep && !isAllComplete && !forceHorizontal
            ? showCompleteAsCircle
              ? 4
              : "8"
            : "0"
        }
        align={{
          base: isAllComplete || forceHorizontal ? "center" : "start",
          lg: "center",
        }}
        opacity={isComplete && !isNotChecked ? ".6" : undefined}
        style={{
          marginTop:
            isComplete && shouldAnimate && isMobile
              ? "-17px"
              : isMobile
              ? "-7px"
              : isComplete && shouldAnimate
              ? "-8px"
              : "0px",
        }}
        transform={isComplete && shouldAnimate ? "scale(0.8)" : "scale(0.9)"}
        transition="0.2s all ease-in-out"
      >
        {description}
      </Stack>
    </Stack>
  );
};
