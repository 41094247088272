import { ParticipantTeamLessonStepProps } from "./ParticipantModal";
import { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import {
  PresentationPresentGoogleSlideView,
  PresentationPresentRise360View,
} from "../Shared/PresentationPresentView";
import { PollResult } from "../Polls/PollResult";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import { PollQuestion } from "../Polls/PollQuestion";
import { Button, Flex, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import {
  hasPollBeenAnswered,
  hasTeamDonePoll,
  trackTeamLesson,
} from "../../../Utils/TeamUtil";
import { TEAM_EVENTS } from "../../../Utils/Analytics";
import { LessonCourseProvider } from "../../../State/Solo/LessonCourseContext";
import {
  PollQuestionDto,
  PollQuestionUserInstanceDto,
} from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";

interface ParticipantCatchUpStepProps extends ParticipantTeamLessonStepProps {
  teamLessonUserInstanceId?: number;
  catchUpDoneCallBack: () => void;
  closeIntroModalCallback: () => void;
}

export const ParticipantCatchUpStep = (props: ParticipantCatchUpStepProps) => {
  const {
    pathTeamLesson,
    teamLessonUserInstanceId,
    catchUpDoneCallBack,
    closeIntroModalCallback,
  } = props;
  const {
    getPollsFromServer,
    currentTeam,
    pollInstances,
    setParticipateForTeamLessonUserInstanceToServer,
    reloadPathsForAllTeamsFromServer,
    pollQuestions,
    setCloseDialog,
    setIsCatchupDone,
    selectedUserLanguage,
    self,
    pollInstanceResults,
  } = useContext<IGlobalProvider>(GlobalContext);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const { confirmationDialogRef } = useContext<IShellProvider>(ShellContext);

  const [pollInstance, setPollInstance] = useState<
    PollQuestionUserInstanceDto | undefined
  >();
  const [pollQuestion, setPollQuestion] = useState<
    PollQuestionDto | undefined
  >();
  const [viewedPolls, setViewedPolls] = useState<{
    [key: string]: boolean;
  }>({});

  const catchSuccessFullyFinished = () => {
    trackTeamLesson(
      TEAM_EVENTS.EndPresentationCatchUp,
      currentTeam,
      pathTeamLesson
    );
    setParticipateForTeamLessonUserInstanceToServer(
      teamLessonUserInstanceId as number
    ).then(() => {
      reloadPathsForAllTeamsFromServer();
      if (catchUpDoneCallBack) {
        catchUpDoneCallBack();
        setIsCatchupDone(true);
      }
    });
  };

  const teamLessonCatchUpEndCallBack = () => {
    let pollsIncomplete = false;
    for (let i = 0; i < pollQuestions.length; i++) {
      if (
        hasTeamDonePoll(
          pollQuestions[i]?.id,
          pollInstances,
          currentTeam?.id,
          pathTeamLesson
        ) &&
        !hasPollBeenAnswered(
          pollQuestions[i]?.id,
          pollInstances,
          self?.id,
          currentTeam?.id,
          pathTeamLesson,
          pollInstanceResults
        )
      ) {
        pollsIncomplete = true;
        break;
      }
    }
    if (pollsIncomplete && teamLessonUserInstanceId) {
      (confirmationDialogRef.current as any).confirmation(
        HVLocalizeStrings.TEAM_TEAM_LESSON_CATCH_UP_END,
        <Text
          color={MODAL_PRIMARY_TEXT_COLOR}
          dangerouslySetInnerHTML={{
            __html: `${HVLocalizeStrings.TEAM_TEAM_LESSON_INCOMPLETE_END_BLOCK_ONE}`,
          }}
        />,
        ConfirmationButtons.JSX,
        () => {},
        [
          <Flex flexDirection={"column"} gap={"5"} w={"100%"}>
            <Flex
              flexDirection={!isMobile ? "row" : "column"}
              justify={"center"}
              justifyContent={"center"}
              align={"center"}
              gap={"5"}
              w={"100%"}
            >
              <AnimatedButton
                text={HVLocalizeStrings.TEAM_TEAM_LESSON_INCOMPLETE_CONTINUE}
                onClick={() => {
                  setCloseDialog(true);
                }}
                w={250}
                dataTestId={HVTestId.ParticipantCatchUpStep.continue}
              ></AnimatedButton>
              <AnimatedButton
                text={
                  HVLocalizeStrings.TEAM_TEAM_LESSON_INCOMPLETE_RESUME_LATER
                }
                colorSet={AnimatedButtonColorSet.Third}
                onClick={() => {
                  trackTeamLesson(
                    TEAM_EVENTS.EndAndResumeCatchUpLater,
                    currentTeam,
                    pathTeamLesson
                  );
                  setCloseDialog(true);
                  reloadPathsForAllTeamsFromServer();
                  if (catchUpDoneCallBack) {
                    catchUpDoneCallBack();
                    closeIntroModalCallback();
                  }
                }}
                w={250}
                dataTestId={HVTestId.ParticipantCatchUpStep.cancel}
              ></AnimatedButton>
            </Flex>
            {!pathTeamLesson?.teamLesson?.lessonCourse && (
              <Button
                className="button-link"
                color={MODAL_PRIMARY_TEXT_COLOR}
                fontSize={"sm"}
                textDecoration={"underline"}
                onClick={() => {
                  trackTeamLesson(
                    TEAM_EVENTS.EndIncompleteCatchUp,
                    currentTeam,
                    pathTeamLesson
                  );
                  setCloseDialog(true);
                  setParticipateForTeamLessonUserInstanceToServer(
                    teamLessonUserInstanceId
                  ).then(() => {
                    reloadPathsForAllTeamsFromServer();
                    if (catchUpDoneCallBack) {
                      catchUpDoneCallBack();
                      setIsCatchupDone(true);
                    }
                  });
                }}
                data-testid={HVTestId.ParticipantCatchUpStep.complete}
              >
                {HVLocalizeStrings.TEAM_TEAM_LESSON_INCOMPLETE_END_NOW}
              </Button>
            )}
          </Flex>,
        ],
        null,
        "2xl"
      );
    } else if (teamLessonUserInstanceId) {
      confirmationDialogRef.current.confirmation(
        HVLocalizeStrings.TEAM_TEAM_LESSON_CATCH_UP_END,
        HVLocalizeStrings.TEAM_TEAM_LESSON_CATCH_UP_END_MESSAGE,
        ConfirmationButtons.YesNo,
        () => {
          catchSuccessFullyFinished();
        }
      );
    }
  };

  const continueCatchUpAfterPoll = () => {
    trackTeamLesson(
      TEAM_EVENTS.ContinuePresentationFromPoll,
      currentTeam,
      pathTeamLesson
    );
    setPollQuestion(undefined);
    setPollInstance(undefined);
  };

  useEffect(() => {
    if (pathTeamLesson?.teamLesson) {
      getPollsFromServer(pathTeamLesson.teamLesson.id);
    }
  }, [pathTeamLesson, selectedUserLanguage]);

  useEffect(() => {
    if (pollQuestion && pollInstances && currentTeam && pathTeamLesson) {
      const currentPollInstances = pollInstances.filter(
        (item: PollQuestionUserInstanceDto) =>
          item.pollQuestionId === pollQuestion.id &&
          item.teamLessonUserInstance.teamId === currentTeam?.id &&
          pathTeamLesson.lastPresentedDate &&
          item.createdAt > pathTeamLesson.lastPresentedDate
      );
      if (currentPollInstances.length > 0) {
        setPollInstance(currentPollInstances[0]);
      }
    }
  }, [pollQuestion, pollInstances, currentTeam, pathTeamLesson]);

  const pollContentShell = (buttonText: string, onClick: () => void) => {
    let content = <></>;
    if (pollQuestion && pollInstance) {
      content = (
        <>
          <PollQuestion
            pollQuestion={pollQuestion}
            pollInstanceId={pollInstance.id}
          />
          <Link
            color={MODAL_PRIMARY_TEXT_COLOR}
            fontWeight={"semibold"}
            textDecoration={"underline"}
            onClick={() => {
              continueCatchUpAfterPoll();
            }}
            data-testid={HVTestId.ParticipantCatchUpStep.pollContinue}
          >
            {HVLocalizeStrings.CONTINUE}
          </Link>
        </>
      );
      if (
        hasPollBeenAnswered(
          pollQuestion.id,
          pollInstances,
          self?.id,
          currentTeam?.id,
          pathTeamLesson,
          pollInstanceResults
        )
      ) {
        content = (
          <>
            <PollResult
              pollQuestion={pollQuestion}
              pollInstance={pollInstance}
              showWaitingOnVotes={false}
            />
            <AnimatedButton
              text={buttonText}
              w={200}
              onClick={() => {
                if (onClick) {
                  onClick();
                }
              }}
              dataTestId={HVTestId.ParticipantCatchUpStep.pollResultsContinue}
            ></AnimatedButton>
          </>
        );
      }
    }
    return content;
  };

  return pathTeamLesson?.teamLesson?.lessonCourse?.riseUrl ? (
    <>
      {pathTeamLesson?.teamLessonUserInstanceId && (
        <LessonCourseProvider>
          <PresentationPresentRise360View
            isCatchup={true}
            pathTeamLesson={pathTeamLesson}
            pollQuestion={pollQuestion}
            pollInstance={pollInstance}
            viewedPolls={viewedPolls}
            pathId={pathTeamLesson.pathId}
            onLessonComplete={() => {
              catchSuccessFullyFinished();
            }}
            pollContent={pollContentShell(HVLocalizeStrings.CONTINUE, () => {
              continueCatchUpAfterPoll();
            })}
            hasPollStarted={() => {
              return true;
            }}
            showPoll={(pollId: number) => {
              return true;
            }}
            onCurrentPollChanged={(poll: PollQuestionDto) => {
              setViewedPolls((prev) => {
                if (poll) {
                  return { ...prev, [poll.id]: true };
                } else {
                  return prev;
                }
              });
              setPollQuestion(poll);
            }}
            onClose={teamLessonCatchUpEndCallBack}
          />
        </LessonCourseProvider>
      )}
    </>
  ) : (
    <PresentationPresentGoogleSlideView
      pathTeamLesson={pathTeamLesson}
      pollQuestion={pollQuestion}
      pollInstance={pollInstance}
      pathId={pathTeamLesson?.pathId}
      pollContent={pollContentShell(HVLocalizeStrings.CONTINUE, () => {
        trackTeamLesson(
          TEAM_EVENTS.ContinuePresentationFromPoll,
          currentTeam,
          pathTeamLesson
        );
        setPollQuestion(undefined);
        setPollInstance(undefined);
      })}
      showPoll={(pollId: number) => {
        return hasTeamDonePoll(
          pollId,
          pollInstances,
          currentTeam?.id,
          pathTeamLesson
        );
      }}
      hasPollStarted={() => {
        return true;
      }}
      showBoostColor={(pollQuestionId: number) => {
        return hasPollBeenAnswered(
          pollQuestionId,
          pollInstances,
          self?.id,
          currentTeam?.id,
          pathTeamLesson,
          pollInstanceResults
        );
      }}
      onCurrentPollChanged={(poll: PollQuestionDto) => {
        setPollQuestion(poll);
      }}
      onClose={teamLessonCatchUpEndCallBack}
    />
  );
};
