import { Center, Flex, Progress, Text } from "@chakra-ui/react";

export interface InsightsProgressTrackerProps {
  percentage: number;
  color?: string;
  showPercentage?: boolean;
  fontColor?: string;
}

export const InsightsProgressTracker = (
  props: InsightsProgressTrackerProps
) => {
  const { percentage, color, showPercentage, fontColor } = props;
  return (
    <Center position={"relative"}>
      <Progress
        value={percentage}
        borderRadius={"50px"}
        w={"100%"}
        h={showPercentage ? "35px" : "12px"}
        sx={{
          "& > div": {
            background: color,
          },
        }}
      />
      {showPercentage && (
        <Flex
          position={"absolute"}
          top={"20%"}
          left={`${Math.max(percentage - 14, 2)}%`}
        >
          <Text color={fontColor ?? "white"} fontWeight={"bold"}>
            {percentage}%
          </Text>
        </Flex>
      )}
    </Center>
  );
};
