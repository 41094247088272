import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HeaderSetter } from "../../Common/HeaderSetter";
import {
  BG_REACH_BASE,
  HEADER_REACH_HOME_BACKGROUND,
} from "../../../Styles/HeadversityStyle";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { Container, Box, Center, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../../../State/GlobalContext";
import {
  ReachGoalsContext,
  ReachGoalsScreen,
} from "../../../State/Reach/ReachGoalsContext";
import { ShellContext } from "../../../State/ShellContext";
import { ReachGoalsViewScreen } from "./ReachGoalsViewScreen";
import { ReachGoalsInitialScreen } from "./ReachGoalsInitialScreen";
import { ReachGoalsSelectionScreen } from "./ReachGoalsSelectionScreen";
import { BlockSection } from "../../Common/BlockSection";
import { HvSpinner } from "../../Common/HvSpinner";

const ReachGoalsScreenSwitcher = () => {
  const shellContext = useContext(ShellContext);
  const goalsContext = useContext(ReachGoalsContext);

  const { screen } = goalsContext;
  const { isMainContainerXL } = shellContext;

  let content;
  switch (screen) {
    case ReachGoalsScreen.VIEW:
      content = <ReachGoalsViewScreen />;
      break;
    case ReachGoalsScreen.SELECTION:
      content = <ReachGoalsSelectionScreen />;
      break;
    default:
      content = <ReachGoalsInitialScreen />;
  }

  return (
    <Box
      maxW={isMainContainerXL ? "container.xl" : "container.lg"}
      mx={"auto"}
      mt={"20px"}
      mb={"20px"}
    >
      {content}
    </Box>
  );
};

export const ReachGoalsPage = () => {
  const globalContext = useContext(GlobalContext);
  const shellContext = useContext(ShellContext);
  const goalsContext = useContext(ReachGoalsContext);

  const { getPageTitle, content, company } = globalContext;
  const {
    screen,
    isLoading,
    setIsLoading,
    initialized,
    setGoalWinThemes,
    setScreen,
    setInitialized,
  } = goalsContext;
  const { isMainContainerXL } = shellContext;
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    if (initialized) return;
    setIsLoading(true);
    if (content && company && content.winThemes && company.goalWinThemes) {
      setGoalWinThemes(company.goalWinThemes);
      if (company.goalWinThemes.length > 0) {
        setScreen(ReachGoalsScreen.VIEW);
      } else {
        setScreen(ReachGoalsScreen.INITIAL);
      }
      setInitialized(true);
      setIsLoading(false);
    }
  }, [
    content,
    company,
    initialized,
    setInitialized,
    setGoalWinThemes,
    setScreen,
    setIsLoading,
  ]);

  return (
    <>
      <Helmet>{getPageTitle(`${HVLocalizeStrings.REACH_GOALS_TITLE}`)}</Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.REACH_GOALS_TITLE}
        description={HVLocalizeStrings.REACH_GOALS_DESCRIPTION}
        backgroundImage={HEADER_REACH_HOME_BACKGROUND}
        backgroundImageBase={BG_REACH_BASE}
      ></HeaderSetter>
      {isLoading ? (
        <BlockSection fadeIn={true} columns={1}>
          <Center>
            <HvSpinner />
          </Center>
        </BlockSection>
      ) : (
        <FadeAfterDelay noEase={true}>
          <Container maxW={isMainContainerXL ? "container.xl" : "container.lg"}>
            {screen !== ReachGoalsScreen.INITIAL && (
              <Box mx={"auto"} mt={isDesktop ? "40px" : "20px"} mb={"20px"}>
                <BlockSectionTitle
                  title={HVLocalizeStrings.REACH_GOALS_SECTION_TITLE}
                  description={
                    HVLocalizeStrings.REACH_GOALS_SECTION_DESCRIPTION
                  }
                ></BlockSectionTitle>
              </Box>
            )}
            <ReachGoalsScreenSwitcher />
          </Container>
        </FadeAfterDelay>
      )}
    </>
  );
};
