import React, { useContext, useEffect, useState } from "react";
import { LargeModal } from "../../Common/LargeModal";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { AnimatedButton } from "../../Common/AnimatedButton";
import {
  PresentationPresentGoogleSlideView,
  PresentationPresentRise360View,
} from "../Shared/PresentationPresentView";
import { PollQuestion } from "../Polls/PollQuestion";
import { TEAM_EVENTS } from "../../../Utils/Analytics";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  setOpenWrapperForPollModals,
  trackTeamLesson,
} from "../../../Utils/TeamUtil";
import { Box } from "@chakra-ui/react";
import { ShellContext } from "../../../State/ShellContext";
import { PathTeamLessonDto, PollQuestionDto } from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";

interface PresentationPreviewModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  pathTeamLesson: PathTeamLessonDto;
}

export const PresentationPreviewModal = (
  props: PresentationPreviewModalProps
) => {
  const { open, setOpen, pathTeamLesson } = props;
  const [pollQuestion, setPollQuestion] = useState<
    PollQuestionDto | undefined
  >();
  const [viewedPolls, setViewedPolls] = useState<{
    [key: string]: boolean;
  }>({});

  const {
    currentTeam,
    getPollsFromServer,
    selectedUserLanguage,
    clearCurrentPollsAndSessions,
    languages,
    resetTeamSession,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { safeAreaInsets } = useContext(ShellContext);

  useEffect(() => {
    if (pathTeamLesson?.teamLesson) {
      getPollsFromServer(pathTeamLesson.teamLesson.id);
    }
  }, [pathTeamLesson.teamLesson, selectedUserLanguage]);

  const pollContentShell = (buttonText: string, onClick: () => void) => (
    <>
      <PollQuestion
        pollQuestion={pollQuestion}
        isPreview={true}
        pollInstanceId={0}
      />
      <AnimatedButton
        text={buttonText}
        w={200}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        dataTestId={HVTestId.PresentationPreviewModal.pollBackButton}
      ></AnimatedButton>
    </>
  );

  return (
    <>
      <LargeModal
        open={open}
        setOpen={
          ((value: boolean) => {
            setOpenWrapperForPollModals(value, resetTeamSession, setOpen);
          }) as any
        }
        size={"full"}
        hideHeader={true}
        preventClose={true}
      >
        <Box pt={`${safeAreaInsets.top}px`} h={"100%"}>
          {pathTeamLesson?.teamLesson?.lessonCourse?.riseUrl ? (
            /* purposefully not wrapped in <LessonProvider> since partcipants are view-only */
            <PresentationPresentRise360View
              pathTeamLesson={pathTeamLesson}
              pathId={pathTeamLesson.pathId}
              pollQuestion={pollQuestion}
              viewedPolls={viewedPolls}
              onLessonComplete={() => {
                setOpenWrapperForPollModals(false, resetTeamSession, setOpen);
              }}
              pollContent={pollContentShell(
                HVLocalizeStrings.TEAM_BACK_TO_PREVIEW,
                () => {
                  trackTeamLesson(
                    TEAM_EVENTS.BackToPreviewPresentation,
                    currentTeam,
                    pathTeamLesson
                  );
                  setPollQuestion(undefined);
                }
              )}
              hasPollStarted={() => {
                return false;
              }}
              onCurrentPollChanged={(poll: PollQuestionDto) => {
                setViewedPolls((prev) => {
                  return { ...prev, [poll.id]: true };
                });
                setPollQuestion(poll);
              }}
              showPoll={() => {
                return true;
              }}
              onClose={() => {
                setOpenWrapperForPollModals(false, resetTeamSession, setOpen);
              }}
              isPreview
            />
          ) : (
            <PresentationPresentGoogleSlideView
              pathTeamLesson={pathTeamLesson}
              pathId={pathTeamLesson.pathId}
              onClose={() => {
                setOpenWrapperForPollModals(false, resetTeamSession, setOpen);
              }}
              pollQuestion={pollQuestion}
              showPoll={() => {
                return true;
              }}
              pollContent={pollContentShell(
                HVLocalizeStrings.TEAM_BACK_TO_PREVIEW,
                () => {
                  trackTeamLesson(
                    TEAM_EVENTS.BackToPreviewPresentation,
                    currentTeam,
                    pathTeamLesson
                  );
                  setPollQuestion(undefined);
                }
              )}
              onCurrentPollChanged={(poll: PollQuestionDto) => {
                setPollQuestion(poll);
              }}
              hasPollStarted={() => {
                return false;
              }}
              showBoostColor={() => {
                return false;
              }}
              isPreview
            />
          )}
        </Box>
      </LargeModal>
    </>
  );
};
