import {
  Box,
  Flex,
  Icon,
  Image,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Black,
  Border_Radius,
  Mid_Blue,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
  White,
} from "../../Styles/HeadversityStyle";
import BlockSectionTitle from "./BlockSectionTitle";
import { LocalizationText } from "@headversity/contract";
import { MdInfo } from "react-icons/md";

interface PathAccordionButtonBlockProps {
  title: string | LocalizationText;
  content: any;
  description?: string | LocalizationText;
  additionalContent?: any;
  imageUrl?: string;
  backgroundImageUrl?: string;
  imageOverlay?: any;
}

export const BlockAccordionButtonBlock = (
  props: PathAccordionButtonBlockProps
) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const {
    imageUrl,
    title,
    description,
    content,
    additionalContent,
    backgroundImageUrl,
    imageOverlay,
  } = props;

  return (
    <>
      <Flex
        gap={isMobile ? "2" : "8"}
        w={"100%"}
        flexDir={isMobile ? "column" : "row"}
      >
        {isMobile || !imageUrl ? null : (
          <>
            <Box
              w={"150px"}
              h={"150px"}
              overflow="hidden"
              borderRadius={Border_Radius}
              position={"relative"}
            >
              <Image
                src={imageUrl}
                objectFit="cover"
                borderRadius={Border_Radius}
                boxShadow={"lg"}
                backgroundImage={backgroundImageUrl}
                backgroundSize={"contain"}
                alt=""
              />
              {imageOverlay}
            </Box>
          </>
        )}
        <Flex
          flexDir={"column"}
          color={SECONDARY_TEXT_COLOR}
          justify={"center"}
          gap={"5"}
          flex={"1"}
          w={"100%"}
        >
          <Flex flexDir={"row"} alignItems={"top"}>
            <BlockSectionTitle
              primaryTextColor={PRIMARY_TEXT_COLOR}
              title={title}
            />
            {description && (
              <Tooltip
                label={description.toString()}
                bg={White}
                color={Black}
                w={isMobile ? "250px" : "320px"}
                borderRadius={Border_Radius}
                p={3}
              >
                <span>
                  <Icon as={MdInfo} color={Mid_Blue} boxSize={4} ms={2} />
                </span>
              </Tooltip>
            )}
          </Flex>
          <Flex flexDir={"column"} align={"start"} gap={"2"}>
            {typeof content === "function" ? content() : content}
          </Flex>
        </Flex>
        {additionalContent &&
          (typeof additionalContent === "function"
            ? additionalContent()
            : additionalContent)}
      </Flex>
    </>
  );
};
