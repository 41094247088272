import { ProductType } from "@headversity/contract";
import { useState } from "react";

export interface IAdminProductTypeProvider {
  adminProductTypes: ProductType[];
  setAdminProductTypes: (productTypes: ProductType[]) => void;
  adminCertEnabled: boolean;
  setAdminCertEnabled: (enabled: boolean) => void;
}

export const useAdminProductType = () => {
  const [adminCertEnabled, setAdminCertEnabled] = useState<boolean>(false);
  const [adminProductTypes, setAdminProductTypes] = useState<ProductType[]>([]);

  return {
    adminProductTypes,
    setAdminProductTypes,
    adminCertEnabled,
    setAdminCertEnabled,
  };
};
