import {
  Box,
  SlideFade,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import { useContext } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  Border_Radius,
  SlideFade_Offset,
} from "../../../Styles/HeadversityStyle";
import { HeadZoneStateRanges } from "../../../Utils/HeadzoneUtil";
import {
  getSortedSkillsForUser,
  getUserSkillBySkillId,
} from "../../../Utils/SkillsUtil";
import { ResilienceSkill20ItemWithChart } from "../../Solo/Shared/ResilienceSkill20ItemWithChart";
import { ImmediateAssistance } from "./ImmediateAssistance";
import { WelcomeModalHeader } from "./WelcomeModalHeader";
import { WelcomeModalSubheader } from "./WelcomeModalSubheader";

interface StepHeadscanCompleteProps {
  selectedSkillIds: number[];
  useTheme?: boolean;
}

export const StepHeadscanComplete = ({
  selectedSkillIds,
  useTheme,
}: StepHeadscanCompleteProps) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const [fourthTextColor] = useToken("colors", ["FOURTH_TEXT_COLOR"]);

  const { skills, userSkillStats, selectedGoal } =
    useContext<IGlobalProvider>(GlobalContext);

  const selectedSkills = getSortedSkillsForUser(
    skills,
    userSkillStats,
    selectedGoal
  ).filter((x) =>
    selectedSkillIds.length === 0
      ? userSkillStats.some((y) => y.skillId === x.id)
      : selectedSkillIds.includes(x.id)
  );

  const skill = skills.find((x) => x.id === selectedSkillIds[0]);
  const userSkill = getUserSkillBySkillId(userSkillStats, selectedSkillIds[0]);

  return (
    <SlideFade in={true} offsetY={SlideFade_Offset}>
      <Box mb={"75px"} h={isDesktop ? "400px" : undefined}>
        <WelcomeModalHeader useTheme={useTheme}>
          {selectedSkills.length === 1 ? (
            <>
              {HVLocalizeStrings.WELCOME_COMPLETE_SCORE_IS}
              <b style={{ color: fourthTextColor }}>
                {selectedSkills[0].name.toString()}
              </b>
              .
            </>
          ) : (
            <>{HVLocalizeStrings.WELCOME_COMPLETE_SCORES_ARE}</>
          )}{" "}
        </WelcomeModalHeader>

        {userSkill &&
          userSkill.headscanPercentage < HeadZoneStateRanges.burnOut && (
            <WelcomeModalSubheader useTheme={useTheme}>
              <Box fontSize="16px">
                <ImmediateAssistance from="Headscan" />
              </Box>
            </WelcomeModalSubheader>
          )}

        <WelcomeModalSubheader useTheme={useTheme}>
          <Box
            p={useTheme ? undefined : "18px"}
            bgColor={useTheme ? undefined : "#ffffff22"}
            borderRadius={Border_Radius}
            fontSize={"16px"}
          >
            <Text
              dangerouslySetInnerHTML={{
                __html: getScoreDescription(userSkill?.headscanPercentage),
              }}
            />
          </Box>
        </WelcomeModalSubheader>

        {skill && userSkill && (
          <ResilienceSkill20ItemWithChart skill={skill} userSkill={userSkill} />
        )}
      </Box>
    </SlideFade>
  );
};
const getScoreDescription = (headscanPercentage: number | undefined) => {
  if (headscanPercentage === undefined) return "";

  if (headscanPercentage >= 80) {
    return HVLocalizeStrings.SCORE_HIGH;
  } else if (headscanPercentage >= 60) {
    return HVLocalizeStrings.SCORE_MED_HIGH;
  } else if (headscanPercentage >= 40) {
    return HVLocalizeStrings.SCORE_MED;
  } else if (headscanPercentage >= 20) {
    return HVLocalizeStrings.SCORE_MED_LOW;
  } else {
    return HVLocalizeStrings.SCORE_LOW;
  }
};
