import { useState } from "react";

export interface ITourProvider {
  isTourOpen: boolean;
  setIsTourOpen: (isTourOpen: boolean) => void;
}
export const useTour = (): ITourProvider => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  return { isTourOpen, setIsTourOpen };
};
