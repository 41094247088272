import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  Center,
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { SelfServeUserDto } from "@headversity/contract";
import { useContext, useEffect } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  ReachUsersContext,
  UserModalOpen,
} from "../../../State/Reach/ReachUsersContext";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import {
  BLOCK_SECTION_TITLE_BORDER_BOTTOM,
  PRIMARY_TEXT_COLOR,
  THIRD_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { SortCriteriaProps } from "../ReachUsersPage";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";
import { SmallModal } from "../../Common/SmallModal";
import { writeToClipboard } from "../../../Utils/mobileUtils";
import SelfServeUserCerts from "./SelfServeUserCerts";
import { ResponsiveValue } from "@chakra-ui/styled-system";
import { getUserRoleTranslated } from "../../../Utils/Helpers";
import { HVTestId } from "../../../Testing/dataTestIds";

interface SelfServeUsersListProps {
  users: SelfServeUserDto[];
  sortCriteria: SortCriteriaProps;
  setSortCriteria: (sortCriteria: SortCriteriaProps) => void;
}

export const SelfServeUsersList = (props: SelfServeUsersListProps) => {
  const { users, sortCriteria, setSortCriteria } = props;

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const { showToast } = useContext<IShellProvider>(ShellContext);

  const {
    dataRefreshTimestamp,
    isUploadingFile,
    setIsUploadingFile,
    getCertsStatsForUserFromServer,
    setIsUserCertsStatsLoading,
    setUserModalOpen,
    setSelectedUser,
    updatePasswordModal,
    setUpdatePasswordModal,
    passwordResetCodeLink,
    passwordResetUser,
    companyCerts,
  } = useContext(ReachUsersContext);

  useEffect(() => {
    if (isUploadingFile) {
      setIsUploadingFile(false);
      showToast(
        true,
        "self-serve-user-file-process-success",
        HVLocalizeStrings.REACH_USERS_UPLOAD_SUCCESS
      );
    }
  }, [dataRefreshTimestamp]);

  const handleSort = (column: string) => {
    const sortOrder =
      sortCriteria.column === column && sortCriteria.order === "asc"
        ? "desc"
        : "asc";

    setSortCriteria({ column, order: sortOrder });
  };

  const handleUserSelection = (user: SelfServeUserDto) => {
    setIsUserCertsStatsLoading(true);
    setUserModalOpen(UserModalOpen.VIEW_EDIT);
    setSelectedUser(user);
    getCertsStatsForUserFromServer(user.id).then(() =>
      setIsUserCertsStatsLoading(false)
    );
    track(REACH_EVENTS.ReachUsersOpenViewUsersModal);
  };

  return (
    <>
      <TableContainer w={"100%"} pt={3}>
        <Table color={PRIMARY_TEXT_COLOR} variant={"simple"}>
          <Thead>
            <Tr>
              <SortableTableHeader
                column={"full_name"}
                handleSort={handleSort}
                sortCriteria={sortCriteria}
                text={HVLocalizeStrings.REACH_USER}
                headerWidth={!isDesktop ? "100%" : "30%"}
              />
              {isDesktop && (
                <>
                  <SortableTableHeader
                    column={"role"}
                    handleSort={handleSort}
                    sortCriteria={sortCriteria}
                    text={HVLocalizeStrings.REACH_USER_ROLE}
                    headerWidth={"15%"}
                  />
                  <SortableTableHeader
                    column={"invite_code_id"}
                    handleSort={handleSort}
                    sortCriteria={sortCriteria}
                    text={HVLocalizeStrings.REACH_INVITE_CODE}
                    headerWidth={"15%"}
                  />
                  {companyCerts.length > 0 && (
                    <>
                      <SortableTableHeader
                        column={"certs_assigned"}
                        handleSort={handleSort}
                        sortCriteria={sortCriteria}
                        text={HVLocalizeStrings.REACH_USERS_CERTS_ASSIGNED}
                        headerWidth={"15%"}
                      />
                      <SortableTableHeader
                        column={"certs_completed"}
                        handleSort={handleSort}
                        sortCriteria={sortCriteria}
                        text={HVLocalizeStrings.REACH_USERS_CERTS_COMPLETED}
                        headerWidth={"15%"}
                      />
                    </>
                  )}
                </>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {users?.map((user: SelfServeUserDto) => (
              <Tr
                key={user.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleUserSelection(user);
                }}
                data-testid={`${HVTestId.SelfServeUsersList.row}${user.id}`}
              >
                <Td py={2} px={0}>
                  <Flex alignItems={"center"} gap={2}>
                    <Avatar name={user.fullName} size={"md"} />
                    <Flex flexDirection={"column"} gap={0} alignItems={"start"}>
                      <Button
                        color={THIRD_TEXT_COLOR}
                        className="button-link"
                        onClick={() => {
                          handleUserSelection(user);
                        }}
                        fontSize={"md"}
                        fontWeight={"semibold"}
                        textDecoration={"none"}
                        height={"auto"}
                        data-testid={`${HVTestId.SelfServeUsersList.fullName}${user.id}`}
                      >
                        {user.fullName}
                      </Button>
                      <Text
                        fontSize={"sm"}
                        data-testid={`${HVTestId.SelfServeUsersList.email}${user.id}`}
                      >
                        {user.email}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
                {isDesktop ? (
                  <>
                    <Td p={"0"}>
                      <Text
                        fontSize={"sm"}
                        data-testid={`${HVTestId.SelfServeUsersList.role}${user.id}`}
                      >
                        {getUserRoleTranslated(user.role)}
                      </Text>
                    </Td>
                    <Td p={"0"}>
                      <Text
                        fontSize={"sm"}
                        data-testid={`${HVTestId.SelfServeUsersList.inviteCode}${user.id}`}
                      >
                        {user.inviteCode}
                      </Text>
                    </Td>
                    {companyCerts.length > 0 && (
                      <>
                        <Td p={"0"}>
                          <SelfServeUserCerts user={user} type="assigned" />
                        </Td>
                        <Td p={"0"}>
                          <SelfServeUserCerts user={user} type="completed" />
                        </Td>
                      </>
                    )}
                  </>
                ) : null}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <SmallModal
        isOpen={updatePasswordModal}
        onClose={() => {
          setUpdatePasswordModal(false);
        }}
        height="auto"
      >
        <VStack w={"100%"}>
          <Flex justifyContent={"center"} w={!isDesktop ? "80%" : "95%"}>
            <Text
              color={PRIMARY_TEXT_COLOR}
              fontSize={!isDesktop ? "lg" : "1.6rem"}
              borderBottom={BLOCK_SECTION_TITLE_BORDER_BOTTOM}
              fontWeight={600}
              textAlign={"center"}
              display={"inline"}
              lineHeight={!isDesktop ? "auto" : "36px"}
              mb={1}
            >
              {HVLocalizeStrings.REACH_USERS_RESET_PASSWORD_LINK}
            </Text>
          </Flex>
          <Center textAlign={"center"}>
            <Text fontSize={"sm"}>{passwordResetUser}</Text>
          </Center>
          <Center textAlign={"center"}>
            <Link
              onClick={async () => {
                await writeToClipboard(passwordResetCodeLink);
              }}
              fontSize={"sm"}
            >
              {HVLocalizeStrings.REACH_COPY_PASSWORD_RESET_LINK}
            </Link>
          </Center>
        </VStack>
      </SmallModal>
    </>
  );
};

interface SortableHeaderProps {
  column: string;
  handleSort: (column: string) => void;
  sortCriteria: SortCriteriaProps;
  text: string;
  headerWidth: string;
  textAlign?: ResponsiveValue<React.ComponentProps<typeof Text>["TextAlign"]>;
}

const SortableTableHeader = (props: SortableHeaderProps) => {
  const { column, handleSort, sortCriteria, text, headerWidth, textAlign } =
    props;
  return (
    <Th
      p={"0"}
      fontWeight={"normal"}
      fontSize={"xs"}
      w={headerWidth}
      onClick={() => handleSort(column)}
    >
      <Flex alignItems="center" gap={1} pt={1} pb={2}>
        <Text
          wordBreak="break-word"
          overflowWrap="break-word"
          whiteSpace="normal"
          textAlign={textAlign}
        >
          {text}
        </Text>
        {sortCriteria.column === column && sortCriteria.order === "asc" && (
          <TriangleUpIcon />
        )}
        {sortCriteria.column === column && sortCriteria.order === "desc" && (
          <TriangleDownIcon />
        )}
      </Flex>
    </Th>
  );
};
