import { useCallback, useState } from "react";
import {
  getNanoPracticeInstances,
  saveNanoPracticeInstance,
  saveNanoPracticeQuestionAnswers,
} from "../../Api/Solo/NanoPracticeApi";
import { getKey } from "../../Utils/Helpers";
import {
  Dictionary,
  NanoPracticeDto,
  NanoPracticeUserInstanceDto,
  NanoPracticeUserInstanceInputDto,
  QuestionAnswerDto,
  UserStatsResultsDto,
} from "@headversity/contract";

export interface INanoPracticeProvider {
  nanoPracticeModalOpen: boolean;
  setNanoPracticeModalOpen: (open: boolean) => void;
  nanoPracticeModalSetOpen: boolean;
  setNanoPracticeModalSetOpen: (open: boolean) => void;
  nanoPractices: NanoPracticeDto[];
  qualificationPractices: NanoPracticeDto[];
  setQualificationPractices: (
    qualificationPractices: NanoPracticeDto[]
  ) => void;
  nanoPracticeInstances: Dictionary<NanoPracticeUserInstanceDto[]>;
  setNanoPractices: (nanoPractices: NanoPracticeDto[]) => void;
  nanoPracticesWithTrashed: NanoPracticeDto[];
  setNanoPracticesWithTrashed: (nanoPractices: NanoPracticeDto[]) => void;
  getNanoPracticeInstancesFromServer: () => void;
  globalPractice: NanoPracticeDto | undefined;
  setGlobalPractice: (nanoPractice?: NanoPracticeDto) => void;
  savePracticeInstanceToServer: (
    practiceInstance: NanoPracticeUserInstanceInputDto,
    questionAnswers: QuestionAnswerDto[]
  ) => Promise<number>;
  saveNanoPracticeQuestionAnswersToServer: (
    practiceId: number,
    questionAnswers: QuestionAnswerDto[]
  ) => void;
  lastFetchedDate: string;
  setLastFetchedDate: (lastFetchedDate: (prev: any) => string) => void;
}

export const useNanoPractice = (
  setUserStatsToServer: () => Promise<UserStatsResultsDto>
): INanoPracticeProvider => {
  const [nanoPracticeModalOpen, setNanoPracticeModalOpen] =
    useState<boolean>(false);
  const [nanoPracticeModalSetOpen, setNanoPracticeModalSetOpen] =
    useState<boolean>(false);

  const [globalPractice, setGlobalPractice] = useState<
    NanoPracticeDto | undefined
  >();
  const [nanoPractices, setNanoPractices] = useState<NanoPracticeDto[]>([]);
  const [nanoPracticesWithTrashed, setNanoPracticesWithTrashed] = useState<
    NanoPracticeDto[]
  >([]);
  const [qualificationPractices, setQualificationPractices] = useState<
    NanoPracticeDto[]
  >([]);
  const [nanoPracticeInstances, setNanoPracticeInstances] = useState<
    Dictionary<NanoPracticeUserInstanceDto[]>
  >({});

  const [lastFetchedDate, setLastFetchedDate] = useState<string>("");

  const getNanoPracticeInstancesFromServer = useCallback(async () => {
    return getNanoPracticeInstances(getKey()).then((response) => {
      setNanoPracticeInstances(response.data);
    });
  }, []);

  const savePracticeInstanceToServer = useCallback(
    async (
      nanoPracticeInstance: NanoPracticeUserInstanceInputDto,
      questionAnswers: QuestionAnswerDto[]
    ) => {
      return saveNanoPracticeInstance(getKey(), nanoPracticeInstance).then(
        async (res) => {
          const instanceId = res.data.id;

          // keep the client blocked during these calls, since we need the results in the flow
          await saveNanoPracticeQuestionAnswersToServer(
            instanceId,
            questionAnswers
          );

          await setUserStatsToServer();

          await getNanoPracticeInstancesFromServer();

          return instanceId;
        }
      );
    },
    []
  );

  const saveNanoPracticeQuestionAnswersToServer = useCallback(
    async (nanoPracticeId: number, questionAnswers: QuestionAnswerDto[]) => {
      await saveNanoPracticeQuestionAnswers(
        getKey(),
        nanoPracticeId,
        questionAnswers
      );
    },
    []
  );

  return {
    nanoPracticeModalOpen,
    setNanoPracticeModalOpen,
    nanoPracticeModalSetOpen,
    setNanoPracticeModalSetOpen,
    nanoPractices,
    nanoPracticeInstances,
    setNanoPractices,
    nanoPracticesWithTrashed,
    setNanoPracticesWithTrashed,
    getNanoPracticeInstancesFromServer,
    globalPractice,
    setGlobalPractice,
    savePracticeInstanceToServer,
    saveNanoPracticeQuestionAnswersToServer,
    lastFetchedDate,
    setLastFetchedDate,
    qualificationPractices,
    setQualificationPractices,
  };
};
