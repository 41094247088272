import { CharterActivity, PathTeamLessonDto } from "@headversity/contract";
import { PresentationGroupEditor } from "./PresentationGroupEditor";
import { PresentationWeStatementStepOne } from "./PresentationWeStatementStepOne";
import { PresentationWeStatementStepTwo } from "./PresentationWeStatementStepTwo";
import { PresentationAmbassador } from "./PresentationAmbassador";
import { PresentationBestWorstStepOne } from "./PresentationBestWorstStepOne";
import { PresentationBestWorstStepTwo } from "./PresentationBestWorstStepTwo";
import { PresentationCharterEditor } from "./PresentationCharterEditor";
import { PresentationScenario } from "./PresentationScenario";
import { useEffect } from "react";
import { postMessageToIframe } from "../../../../Utils/LessonsUtil";
import { FRAME_ID, GO_FORWARD } from "../../../Shared/Rise360/Rise360Player";
import { PresentationTeamValues } from "./PresentationTeamValues";

interface ViewFactoryProps {
  activity: CharterActivity;
  pathTeamLesson: PathTeamLessonDto;
  setActivity: (activity: CharterActivity | undefined) => void;
}
export const ViewFactory = (props: ViewFactoryProps) => {
  const { activity, setActivity, pathTeamLesson } = props;
  useEffect(() => {
    return () => {
      postMessageToIframe(FRAME_ID, GO_FORWARD);
    };
  }, []);

  switch (activity) {
    case CharterActivity.Grouping:
      return <PresentationGroupEditor setActivity={setActivity} />;
    case CharterActivity.TeamValues:
      return <PresentationTeamValues setActivity={setActivity} />;
    case CharterActivity.BestWorstStepOne:
      return <PresentationBestWorstStepOne setActivity={setActivity} />;
    case CharterActivity.BestWorstStepTwo:
    case CharterActivity.BestWorstStepTwoResults:
      return (
        <PresentationBestWorstStepTwo
          pathTeamLesson={pathTeamLesson}
          setActivity={setActivity}
        />
      );
    case CharterActivity.WeStatementStepOne:
      return <PresentationWeStatementStepOne setActivity={setActivity} />;
    case CharterActivity.WeStatementStepTwo:
      return <PresentationWeStatementStepTwo setActivity={setActivity} />;
    case CharterActivity.CharterCreation:
      return <PresentationCharterEditor setActivity={setActivity} />;
    case CharterActivity.Scenarios:
    case CharterActivity.ScenarioReviews:
      return (
        <PresentationScenario
          pathTeamLesson={pathTeamLesson}
          setActivity={setActivity}
        />
      );
    case CharterActivity.SelectingCharterChampions:
      return <PresentationAmbassador setActivity={setActivity} />;
    default:
      return <></>;
  }
};
