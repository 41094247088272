import { useCallback, useContext, useState } from "react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
// @ts-ignore
import CircularSlider from "@fseehawer/react-circular-slider";

import styled from "styled-components";
import {
  Box,
  Button,
  Flex,
  Icon,
  Stack,
  Switch,
  Text,
  Center,
  useBreakpointValue,
  SlideFade,
} from "@chakra-ui/react";
import {
  CalmingToolContext,
  ICalmingToolProvider,
} from "../../../../State/Solo/CalmingToolContext";
import {
  BUTTON_PRIMARY_TEXT_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { BsFillPlayFill, BsFillPauseFill } from "react-icons/bs";
import { track, EVENTS } from "../../../../Utils/Analytics";

interface HVCalmingToolPulseContainerProps {
  breathInText: string;
  breathOutText: string;
  isMobile: boolean;
}

const HVCalmingToolPulseContainer = styled.div<HVCalmingToolPulseContainerProps>`
  width: ${(props: HVCalmingToolPulseContainerProps) =>
    props.isMobile ? "200px" : "250px"};
  height: ${(props: HVCalmingToolPulseContainerProps) =>
    props.isMobile ? "200px" : "250px"};
  position: relative;
  border-radius: 100%;
  z-index: 10;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .animate {
    animation: pulse 10s infinite;
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.35);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .animateSmall {
    animation: pulseSmall 10s infinite;
    @keyframes pulseSmall {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.32);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .animateText::before {
    content: "${(props: HVCalmingToolPulseContainerProps) =>
      props.breathInText}";
    animation: animateText 10s infinite;
  }

  .animateText {
    animation: animateTextSize 10s infinite;
  }

  @keyframes animateTextSize {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes animateText {
    0% {
      content: "${(props: HVCalmingToolPulseContainerProps) =>
        props.breathInText}";
    }
    50% {
      content: "${(props: HVCalmingToolPulseContainerProps) =>
        props.breathInText}";
    }
    51% {
      content: "${(props: HVCalmingToolPulseContainerProps) =>
        props.breathOutText}";
    }
    100% {
      content: "${(props: HVCalmingToolPulseContainerProps) =>
        props.breathOutText}";
    }
  }
`;

interface HVCalmingToolCircularPanelProps {
  started: boolean;
  isMobile: boolean;
}

const HVCalmingToolCircularPanel = styled.div<HVCalmingToolCircularPanelProps>`
  width: ${(props: HVCalmingToolCircularPanelProps) =>
    props.isMobile ? "220px" : "280px"};
  height: ${(props: HVCalmingToolCircularPanelProps) =>
    props.isMobile ? "220px" : "280px"};
  position: absolute;
  left: ${(props: HVCalmingToolCircularPanelProps) =>
    props.isMobile ? "calc(50% - 110px)" : "calc(50% - 140px)"};
  top: ${(props: HVCalmingToolCircularPanelProps) =>
    props.isMobile ? "calc(50% - 112px)" : "calc(50% - 142px)"};
  z-index: 1;
  circle {
    animation: none !important;
  }
  div {
    transition: ${(props: HVCalmingToolCircularPanelProps) =>
      props.started ? "all 0.5s ease" : "none"};
  }
  .animateTwo {
    animation: pulseTwo 10s infinite;
    @keyframes pulseTwo {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.65);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .animateTwoSmall {
    animation: pulseTwoSmall 10s infinite;
    @keyframes pulseTwoSmall {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.62);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;

const HVCalmingToolPulse = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: rgba(0, 177, 255, 0.35);
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 5s;
`;

const HVCalmingToolPulseOneContainer = styled(HVCalmingToolPulse).attrs({
  className: "flex flex-col justify-center items-center",
})`
  width: 85%;
  height: 85%;
` as any;

const HVCalmingToolPulseTwoContainer = styled(HVCalmingToolPulse)`
  width: 90%;
  height: 90%;
`;

const HVCalmingToolPulseThreeContainer = styled(HVCalmingToolPulse)``;

const numberToMinutes = (seconds: number) => {
  const minute = Math.floor(seconds / 60);
  const remainder = seconds % 60;
  return `${minute}:${remainder < 10 ? "0" : ""}${remainder}`;
};

let interval: any = null;

export const CalmingTool = () => {
  const { saveCalmingToolUsageToServer } =
    useContext<ICalmingToolProvider>(CalmingToolContext);
  const [startForTime, setStartForTime] = useState<boolean>(false);
  const [startForCycle, setStartForCycle] = useState<boolean>(false);
  const [totalTime, setTotalTime] = useState<number>(20);
  const [totalDisplayTime, setTotalDisplayTime] = useState<number>(20 * 15);
  const [totalCycle, setTotalCycle] = useState<number>(10);
  const [mode, setMode] = useState<string>("time");

  const changeMode = useCallback(
    (newMode: string) => {
      if (newMode !== mode) {
        setMode(newMode);
        setStartForTime(false);
        setStartForCycle(false);
      }
    },
    [mode]
  );

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  return (
    <Stack
      pos={"relative"}
      h={startForTime || startForCycle ? (isMobile ? 550 : 620) : 520}
      transition={
        startForTime || startForCycle ? "height 5s ease" : "height .5s ease"
      }
      align={"center"}
      justify={"center"}
    >
      <Box pos={"absolute"} top={-2}>
        <Text
          fontSize={"2xl"}
          fontWeight={"semibold"}
          m={"2"}
          textAlign={"center"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {HVLocalizeStrings.CALMING_INSTRUCTION_BUTTON_TEXT}
        </Text>
        <Text
          m={"2"}
          mb={"0"}
          textAlign={"center"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {HVLocalizeStrings.CALMING_INSTRUCTION_TEXT}
        </Text>
      </Box>
      <Pulse
        start={mode === "time" ? startForTime : startForCycle}
        isMobile={isMobile as any}
      >
        <Text
          mt={6}
          className={startForCycle || startForTime ? "animateText" : ""}
          fontWeight={"bold"}
          color={BUTTON_PRIMARY_TEXT_COLOR}
          textAlign={"center"}
        ></Text>

        <Button
          display={"flex"}
          flexDir={"column"}
          border={"0"}
          _hover={{ background: "transparent" }}
          background={"transparent"}
          aria-label={"PlayAndStop"}
          size={"lg"}
          onClick={() => {
            track(EVENTS.ToolCalmingTriggered);

            clearInterval(interval);
            interval = null;
            if (mode === "time") {
              startForTime ? setStartForTime(false) : setStartForTime(true);
              if (!startForTime) {
                saveCalmingToolUsageToServer({
                  calmingToolType: "time",
                  time: totalTime,
                });
                let time = totalTime;
                let displayTime = totalDisplayTime;
                interval = setInterval(() => {
                  time = (time * 15 - 1) / 15;
                  displayTime--;
                  setTotalTime(time);
                  setTotalDisplayTime(displayTime);
                  if (displayTime === 0) {
                    setStartForTime(false);
                    clearInterval(interval);
                    setTotalTime(20);
                    setTotalDisplayTime(20 * 15);
                  }
                }, 1000);
              }
            } else {
              startForCycle ? setStartForCycle(false) : setStartForCycle(true);
              if (!startForCycle) {
                saveCalmingToolUsageToServer({
                  calmingToolType: "cycle",
                  time: totalCycle,
                });
                let cycle = totalCycle;
                interval = setInterval(() => {
                  cycle--;
                  setTotalCycle(cycle);
                  if (cycle === 0) {
                    setStartForCycle(false);
                    clearInterval(interval);
                    setTotalCycle(10);
                  }
                }, 10000);
              }
            }
          }}
        >
          {(mode === "time" && startForTime) ||
          (mode === "cycle" && startForCycle) ? (
            <Icon
              h={"50px"}
              w={"50px"}
              as={BsFillPauseFill}
              fill={"white"}
            ></Icon>
          ) : (
            <Icon
              h={"50px"}
              w={"50px"}
              as={BsFillPlayFill}
              fill={"white"}
            ></Icon>
          )}

          <Text
            fontWeight={"bold"}
            color={BUTTON_PRIMARY_TEXT_COLOR}
            textAlign={"center"}
            fontSize={"xs"}
          >
            {startForCycle || startForTime
              ? HVLocalizeStrings.CALMING_STOP
              : HVLocalizeStrings.CALMING_START}
          </Text>
        </Button>
      </Pulse>
      {mode === "time" && (
        <HVCalmingToolCircularPanel
          started={startForTime}
          isMobile={isMobile as any}
        >
          <CircularSliderWrapper
            isMobile={isMobile as any}
            start={startForTime}
            index={totalTime}
            draggable={!startForTime}
            max={120}
            setValue={(value: number) => {
              setTotalTime(value);
              if (!startForTime) {
                setTotalDisplayTime(value * 15);
              }
            }}
          />
        </HVCalmingToolCircularPanel>
      )}
      {mode === "cycle" && (
        <HVCalmingToolCircularPanel
          started={startForCycle}
          isMobile={isMobile as any}
        >
          <CircularSliderWrapper
            isMobile={isMobile as any}
            start={startForCycle}
            index={totalCycle}
            draggable={!startForCycle}
            max={20}
            setValue={(value: number) => {
              setTotalCycle(value);
            }}
          />
        </HVCalmingToolCircularPanel>
      )}
      <Box
        pos={"absolute"}
        top={!startForTime && !startForCycle ? 430 : isMobile ? 510 : 570}
        transition={
          startForTime || startForCycle ? "all 5s ease" : "all .5s ease"
        }
      >
        <Center mb={2}>
          <Text fontWeight={"bold"} color={MODAL_PRIMARY_TEXT_COLOR}>
            {mode === "time" ? numberToMinutes(totalDisplayTime) : totalCycle}
          </Text>
        </Center>
        {!startForTime && !startForCycle && (
          <Flex pos="static" justify={"center"} align={"center"} mt={4}>
            <Text color={MODAL_PRIMARY_TEXT_COLOR} fontSize={"lg"}>
              {HVLocalizeStrings.CALMING_MODE_TIME}
            </Text>
            &nbsp;
            <Switch
              size={"lg"}
              isChecked={mode === "time"}
              onChange={() => {
                track(EVENTS.ToolCalmingModeToggled, { HV_ToggleTo: "time" });

                clearInterval(interval);
                interval = null;
                changeMode("time");
              }}
            ></Switch>
            &nbsp; &nbsp;
            <Text color={MODAL_PRIMARY_TEXT_COLOR} fontSize={"lg"}>
              {HVLocalizeStrings.CALMING_MODE_CYCLE}
            </Text>
            &nbsp;
            <Switch
              size={"lg"}
              isChecked={mode === "cycle"}
              onChange={() => {
                track(EVENTS.ToolCalmingModeToggled, { HV_ToggleTo: "cycle" });

                clearInterval(interval);
                interval = null;
                changeMode("cycle");
              }}
            ></Switch>
          </Flex>
        )}
      </Box>
    </Stack>
  );
};

interface PulseProps {
  children: any;
  start: boolean;
  isMobile: boolean;
}

const Pulse = (props: PulseProps) => {
  return (
    <HVCalmingToolPulseContainer
      breathInText={HVLocalizeStrings.CALMING_IN}
      breathOutText={HVLocalizeStrings.CALMING_OUT}
      isMobile={props.isMobile}
    >
      <HVCalmingToolPulseThreeContainer
        className={
          props.start ? (props.isMobile ? "animateSmall" : "animate") : ""
        }
      >
        <HVCalmingToolPulseTwoContainer
          className={
            props.start ? (props.isMobile ? "animateSmall" : "animate") : ""
          }
        >
          <HVCalmingToolPulseOneContainer
            className={
              props.start ? (props.isMobile ? "animateSmall" : "animate") : ""
            }
          ></HVCalmingToolPulseOneContainer>
        </HVCalmingToolPulseTwoContainer>
      </HVCalmingToolPulseThreeContainer>

      <Stack
        pos={"absolute"}
        left={"calc(50% - 60px)"}
        top={"calc(50% - 60px)"}
        align={"center"}
        h={"120px"}
        w={"120px"}
      >
        {props.children}
      </Stack>
    </HVCalmingToolPulseContainer>
  );
};

interface CircularSliderWrapper {
  index: number;
  draggable: boolean;
  max: number;
  setValue: (value: number) => void;
  start: boolean;
  isMobile: boolean;
  data?: any;
}

const CircularSliderWrapper = (props: CircularSliderWrapper) => {
  return (
    <div
      className={
        props.start ? (props.isMobile ? "animateTwoSmall" : "animateTwo") : ""
      }
    >
      {props.isMobile && (
        <CircularSlider
          hideLabelValue={true}
          width={220}
          dataIndex={props.index}
          knobDraggable={props.draggable}
          max={props.max}
          data={props.data}
          onChange={(value: number) => {
            props.setValue(value);
          }}
        />
      )}

      {!props.isMobile && (
        <CircularSlider
          hideLabelValue={true}
          width={280}
          dataIndex={props.index}
          knobDraggable={props.draggable}
          max={props.max}
          data={props.data}
          onChange={(value: number) => {
            props.setValue(value);
          }}
        />
      )}
    </div>
  );
};
