import { StepPanel } from "../../../Common/StepPanel";
import { Center, Heading, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface OptimismToolStepOutroProps {
  setOpen: (open: boolean) => void;
}

export const OptimismToolStepOutro = (props: OptimismToolStepOutroProps) => {
  const { setOpen } = props;
  return (
    <StepPanel value={0} step={0} totalStep={4}>
      <Heading
        mt="-20px"
        fontSize={"2xl"}
        fontWeight={"semibold"}
        color={MODAL_PRIMARY_TEXT_COLOR}
        data-testid={HVTestId.OptimismTool.steps.outro.header}
      >
        {HVLocalizeStrings.OPTIMISM_TOOLS_GOOD_STUFF}
      </Heading>
      <Text fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
        {HVLocalizeStrings.OPTIMISM_TOOLS_GOOD_STUFF_DESCRIPTION}
      </Text>
      <Center mt={8} mb={2}>
        <AnimatedButton
          text={HVLocalizeStrings.CLOSE}
          onClick={() => {
            setOpen(false);
          }}
          dataTestId={HVTestId.OptimismTool.steps.outro.closeButton}
        />
      </Center>
    </StepPanel>
  );
};
