import { AxiosResponse } from "axios";
import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { FavoriteDto } from "@headversity/contract";

const FAVORITE_URL = "/api/favorite";

export const getFavorites = (
  token: string
): Promise<AxiosResponse<FavoriteDto[]>> => {
  return AxiosClient.get<FavoriteDto[]>(FAVORITE_URL, getConfig(token));
};

export const deleteFavorite = async (
  token: string,
  favoriteId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.delete<void>(
    `${FAVORITE_URL}/${favoriteId}`,
    getConfig(token)
  );
};

export const saveFavorite = async (
  token: string,
  favoriteDto?: FavoriteDto
): Promise<AxiosResponse<FavoriteDto>> => {
  return AxiosClient.post<FavoriteDto>(
    FAVORITE_URL,
    favoriteDto,
    getConfig(token)
  );
};
