import {
  Box,
  Button,
  Center,
  Fade,
  Grid,
  Switch,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../State/GlobalContext";
import {
  DEFAULT_THEME_NAME,
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../State/ShellContext";
import { Border_Radius } from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";
import { EVENTS, track } from "../../Utils/Analytics";
import { AnimatedButton, AnimatedButtonColorSet } from "./AnimatedButton";
import { LanguageSelector } from "./LanguageSelector";
import { UserDto } from "@headversity/contract";

interface BottomActionBarInterface {
  nextButtonText: string;
  nextButtonWidth?: string;
  onNextButtonClick: () => void;
  isNextButtonDisabled?: () => boolean;
  showOptions?: boolean;
  actionAreaExtra?: JSX.Element;
  colorSet?: AnimatedButtonColorSet;
  isFixed?: boolean;
  marginAdjust?: number;
  hideTheme?: boolean;
}
export const BottomActionBar = ({
  nextButtonText,
  nextButtonWidth,
  onNextButtonClick,
  isNextButtonDisabled,
  showOptions,
  actionAreaExtra,
  colorSet,
  isFixed,
  marginAdjust,
  hideTheme,
}: BottomActionBarInterface) => {
  const { self, updateSelectedUserLanguageToServer } =
    useContext(GlobalContext);
  const { theme, changeTheme, safeAreaInsets } = useContext(ShellContext);

  const [showOptionsPanel, setShowOptionsPanel] = useState(false);

  const isDesktop = useBreakpointValue({ base: false, md: true });

  const handleLanguageChanged = (selectedLanguage: string) => {
    updateSelectedUserLanguageToServer(self as UserDto, selectedLanguage);

    track(EVENTS.LanguageChanged, { HV_Language: selectedLanguage });
  };

  return (
    <Fade in={true} delay={1}>
      <Box
        pos={isDesktop && !isFixed ? "absolute" : "fixed"}
        left={marginAdjust ? `${0 + marginAdjust}px` : 0}
        bottom={marginAdjust ? `${0 + marginAdjust}px` : 0}
        right={marginAdjust ? `${0 + marginAdjust}px` : 0}
        bgColor={`#ffffff${isDesktop ? "aa" : "cc"}`}
        borderBottomRadius={isDesktop ? Border_Radius : undefined}
        p={"10px"}
        pb={`${10 + safeAreaInsets.bottom}px`}
        zIndex={1}
        className="bottom-action-bar"
      >
        <Grid
          gridTemplateColumns={
            isDesktop && showOptions ? "175px 1fr 175px" : "1fr"
          }
        >
          {showOptions && (
            <>
              {!isDesktop && showOptionsPanel && (
                <Box mb={!isDesktop ? "10px" : undefined}>
                  <LanguageSelector onLanguageChanged={handleLanguageChanged} />
                </Box>
              )}
              {(isDesktop || showOptionsPanel) && (
                <Box
                  alignSelf={"center"}
                  ml={isDesktop ? "5px" : undefined}
                  mb={!isDesktop ? "10px" : undefined}
                  fontSize="sm"
                >
                  {!hideTheme && (
                    <>
                      {
                        HVLocalizeStrings.THEME_SELECT_MODAL_SWITCH_LABEL_DEFAULT
                      }{" "}
                      &nbsp;
                      <Switch
                        isChecked={theme.name === NOSTALGIC_THEME_NAME}
                        onChange={() => {
                          const newTheme =
                            theme.name === NOSTALGIC_THEME_NAME
                              ? DEFAULT_THEME_NAME
                              : NOSTALGIC_THEME_NAME;

                          track(EVENTS.WelcomeThemeChanged, {
                            HV_Theme: newTheme,
                          });
                          changeTheme(newTheme);
                        }}
                      />
                      &nbsp;{" "}
                      {
                        HVLocalizeStrings.THEME_SELECT_MODAL_SWITCH_LABEL_NOSTALGIC
                      }
                    </>
                  )}
                </Box>
              )}
            </>
          )}

          {showOptions && !isDesktop && (
            <Box
              pos="fixed"
              bottom={`${15 + safeAreaInsets.bottom}px`}
              zIndex={1}
            >
              <Button
                className="button-link"
                onClick={() => {
                  setShowOptionsPanel(!showOptionsPanel);
                }}
                fontSize="13px"
                mt={2}
              >
                {HVLocalizeStrings.WELCOME_SHOW_HIDE_OPTIONS}
              </Button>
            </Box>
          )}
          <Center filter={"drop-shadow(0 0 3px rgb(255, 255, 255));"}>
            <AnimatedButton
              disabled={isNextButtonDisabled ? isNextButtonDisabled() : false}
              text={nextButtonText}
              onClick={() => onNextButtonClick()}
              dataTestId={HVTestId.WelcomeModal.button}
              colorSet={colorSet}
              w={nextButtonWidth}
            />
          </Center>

          {actionAreaExtra && (
            <Center mt={3} mb={1}>
              {actionAreaExtra}
            </Center>
          )}

          {isDesktop && showOptions && (
            <LanguageSelector onLanguageChanged={handleLanguageChanged} />
          )}
        </Grid>
      </Box>
    </Fade>
  );
};
