import { createContext, useState } from "react";

export interface IHeaderProvider {
  headerState?: any;
  setHeaderState: (headerState: any) => void;
}

export const HeaderContext = createContext<IHeaderProvider>({} as any);

export const HeaderProvider = (props: any) => {
  const [headerState, setHeaderState] = useState<any>(null);

  return (
    <HeaderContext.Provider
      value={{
        headerState,
        setHeaderState,
      }}
    >
      {props.children}
    </HeaderContext.Provider>
  );
};
