import styled from "styled-components";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { LargeModal } from "../../Common/LargeModal";

const TermsAndPoliciesDiv = styled.div`
  margin-top: 30px;
  padding-top: 10px;
  padding: 10px;

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  background-color: white;
`;

interface TermsOfUseProps {
  termsOfUseModalOpen: boolean;
  openTermsOfUseModal: (open: boolean) => void;
}
export const TermsOfUse: React.FC<TermsOfUseProps> = ({
  termsOfUseModalOpen,
  openTermsOfUseModal,
}) => {
  return (
    <LargeModal open={termsOfUseModalOpen} setOpen={openTermsOfUseModal}>
      <TermsAndPoliciesDiv
        dangerouslySetInnerHTML={{
          __html: HVLocalizeStrings.TERMS_PAGE,
        }}
      ></TermsAndPoliciesDiv>
    </LargeModal>
  );
};
