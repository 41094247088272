import { LargeModal } from "../Common/LargeModal";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../Styles/HeadversityStyle";
import { BottomActionBar } from "../Common/BottomActionBar";
import { useContext, useEffect, useState } from "react";
import { ShellContext } from "../../State/ShellContext";
import { HVTestId } from "../../Testing/dataTestIds";
import { AnimatedButtonColorSet } from "../Common/AnimatedButton";
import { GlobalContext } from "../../State/GlobalContext";
import { FeatureFlags } from "../../Utils/FeatureFlags";
import { getIntroVideoUrl } from "../../Utils/Helpers";
import { UserStateDto, StateType } from "@headversity/contract";
import { isWebPlatform } from "../../Utils/mobileUtils";

export const CERT_INTERSTITIAL = "viewed-cert-interstitial";

interface CertFirstTimeInterstitialModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const CertFirstTimeInterstitialModal = ({
  open,
  setOpen,
}: CertFirstTimeInterstitialModalProps): JSX.Element => {
  const [themeReady, setThemeReady] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [customLogoUrl, setCustomLogoUrl] = useState("");

  const [customWelcomeHeader, setCustomWelcomeHeader] = useState("");
  const [customWelcomeBody, setCustomWelcomeBody] = useState("");

  const {
    selectedUserLanguage,
    getFeatureVersion,
    company,
    setUserStateToServer,
    getSelfFromServer,
    pushDisabled,
    promptForPushPermission,
  } = useContext(GlobalContext);
  const { doOSThemeCheck } = useContext(ShellContext);

  const isDesktop = useBreakpointValue({ base: false, md: true });

  const handleCloseModalClick = async () => {
    const userStateDto = {
      stateTypeId: StateType.CertInterstitial,
      value: "true",
    } as UserStateDto;
    await setUserStateToServer(userStateDto);
    await getSelfFromServer();
    setOpen(false);

    if (pushDisabled && !isWebPlatform()) {
      promptForPushPermission();
    }
  };

  /* duped from WelcomeModal */
  useEffect(() => {
    const featureDetails = getFeatureVersion(FeatureFlags.WELCOME_2023) as any;

    const introVideoUrl = getIntroVideoUrl(
      featureDetails,
      selectedUserLanguage,
      "https://player.vimeo.com/video/912736977?h=4a6978cde2"
    );

    setVideoUrl(introVideoUrl);

    // check if we have a feature override
    if (featureDetails && featureDetails.customLogoUrl !== undefined) {
      setCustomLogoUrl(featureDetails.customLogoUrl);
    }

    if (featureDetails && featureDetails.certWelcomeHeader !== undefined) {
      setCustomWelcomeHeader(featureDetails.certWelcomeHeader);
    }

    if (featureDetails && featureDetails.certWelcomeBody !== undefined) {
      setCustomWelcomeBody(featureDetails.certWelcomeBody);
    }

    // prefer company logo
    if (company?.logo) {
      setCustomLogoUrl(company?.logo);
    }
  }, [selectedUserLanguage]);

  /* duped from WelcomeModal */
  useEffect(() => {
    if (!open) return;

    doOSThemeCheck(() => {
      setThemeReady(true);
    });
  }, [open]);

  const imageFilter = useToken("colors", ["IMAGE_FILTER"]);

  return themeReady ? (
    <LargeModal
      open={open}
      setOpen={handleCloseModalClick}
      dataTestId={HVTestId.FirstTimeInterstitialModal.modal}
      extraPadHeader={true}
    >
      <Box mx={isDesktop ? "40px" : "10px"} mb={"80px"}>
        <Center>
          <Flex
            gap={isDesktop ? 10 : 4}
            flexDir={isDesktop ? "row" : "column"}
            mb="30px"
          >
            <Image
              src={HVLocalizeStrings.CERT_LOGO}
              filter={imageFilter}
              h="80px"
              alt=""
            />
            {customLogoUrl && (
              <Image
                src={customLogoUrl}
                maxH="100px"
                maxW="250px"
                m="auto"
                alt=""
              />
            )}
          </Flex>
        </Center>

        <Box>
          <FirstTimeInterstitialModalText
            isHeadline={true}
            html={
              customWelcomeHeader
                ? customWelcomeHeader
                : HVLocalizeStrings.CERT_WELCOME_SUBHEAD
            }
          />
          <FirstTimeInterstitialModalText
            html={
              customWelcomeBody
                ? customWelcomeBody
                : HVLocalizeStrings.CERT_WELCOME_TEXT
            }
          />
        </Box>

        {videoUrl && (
          <Center my={4}>
            <Box
              width={isDesktop ? "400px" : "360px"}
              height={isDesktop ? "230px" : "210px"}
              boxShadow="lg"
            >
              <iframe
                src={videoUrl}
                width={isDesktop ? "400px" : "360px"}
                height={isDesktop ? "230px" : "210px"}
                title="video"
                allowFullScreen={true}
              ></iframe>
            </Box>
          </Center>
        )}

        {!customWelcomeBody && (
          <>
            <Box mt={6}>
              <FirstTimeInterstitialModalText
                html={HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_THIRD_LIST_ITEM}
              />
            </Box>

            {pushDisabled && !isWebPlatform() && (
              <>
                <Divider mt={8} mb={6} />

                <FirstTimeInterstitialModalText
                  html={
                    HVLocalizeStrings.FIRST_TIME_INTERSTITIAL_PUSH_NOTIFICATIONS
                  }
                />
              </>
            )}
          </>
        )}
      </Box>
      <BottomActionBar
        showOptions={true}
        nextButtonText={HVLocalizeStrings.GET_STARTED}
        colorSet={AnimatedButtonColorSet.Cert}
        onNextButtonClick={handleCloseModalClick}
      />
    </LargeModal>
  ) : (
    <></>
  );
};

interface FirstTimeInterstitialModalTextProps {
  html: string;
  isHeadline?: boolean;
}

const FirstTimeInterstitialModalText = (
  props: FirstTimeInterstitialModalTextProps
) => {
  const { html } = props;
  const isDesktop = useBreakpointValue({ base: false, md: true });
  return (
    <Text
      mt={1}
      mb={props.isHeadline ? 2 : 4}
      fontWeight={props.isHeadline ? "600" : undefined}
      fontSize={props.isHeadline ? "xl" : isDesktop ? "16px" : "15px"}
      lineHeight={"1.3rem"}
      color={MODAL_PRIMARY_TEXT_COLOR}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
