import React, { useContext, useEffect, useState } from "react";
import { HeaderSetter } from "../../../Common/HeaderSetter";
import { BlockSection } from "../../../Common/BlockSection";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { getToolSkillInfo, ToolSkillInfo } from "../../../../Utils/SkillsUtil";
import { OptimismToolLanding } from "./OptimismToolLanding";
import { StickyBreadcrumb } from "../../../Common/StickyBreadcrumb";
import { Helmet } from "react-helmet";
import { FadeAfterDelay } from "../../../Common/FadeAfterDelay";
import { HEADER_TOOL_OPTIMISM_BACKGROUND } from "../../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";

interface OptimismToolPageProps {}

export const OptimismToolPage = (props: OptimismToolPageProps) => {
  const { tools, skills, getPageTitle } =
    useContext<IGlobalProvider>(GlobalContext);
  const [toolSkillInfo, setToolSkillInfo] = useState<ToolSkillInfo | null>(
    null
  );

  useEffect(() => {
    if (tools && skills) {
      const tool = tools.find((item) => item.id === 6);
      if (tool) {
        const info = getToolSkillInfo(tool, skills);
        setToolSkillInfo(info);
      }
    }
  }, [tools, skills]);

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.OPTIMISM_TOOLS} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>
      {toolSkillInfo && (
        <HeaderSetter
          title={toolSkillInfo.name}
          description={toolSkillInfo.description}
          backgroundImage={HEADER_TOOL_OPTIMISM_BACKGROUND}
        />
      )}
      <FadeAfterDelay>
        <StickyBreadcrumb
          crumbs={[
            { url: "/", text: HVLocalizeStrings.SOLO_MENU_HOME },
            { url: "/tools", text: HVLocalizeStrings.SOLO_MENU_PRACTICE },
          ]}
          current={toolSkillInfo?.name as string}
        />
        <BlockSection fadeIn={true} title={""} columns={1}>
          <OptimismToolLanding toolSkillInfo={toolSkillInfo} />
        </BlockSection>
      </FadeAfterDelay>
    </>
  );
};
