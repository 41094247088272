import { useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useSportTeam } from "../../../Hooks/Team/useSportTeam";
import { GlobalContext } from "../../../State/GlobalContext";
import { SPORT_EVENTS } from "../../../Utils/Analytics";
import { LargeModal } from "../../Common/LargeModal";
import { SportTeamEdit } from "./SportTeamEdit";
import { SportWelcome } from "./SportWelcome";

interface SportOnboardingProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  initialStep: OnboardingStep;
}

export enum OnboardingStep {
  Welcome,
  TeamEdit,
  Complete,
}

export const SPORT_ONBOARDING = "viewed-sport-onboarding-p1";

export const SportOnboarding = ({
  open,
  setOpen,
  initialStep,
}: SportOnboardingProps) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const { currentTeam } = useContext(GlobalContext);

  const [onboardingStep, setOnboardingStep] = useState(OnboardingStep.Welcome);
  const { isLeader, trackEvent } = useSportTeam();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
    if (isOpen === false && open === true) {
      if (initialStep === OnboardingStep.Welcome) {
        trackEvent(SPORT_EVENTS.FirstTimeInterstitialShown);
      } else if (initialStep === OnboardingStep.TeamEdit) {
        trackEvent(SPORT_EVENTS.EditTeamShown);
      }
    }
    setOnboardingStep(initialStep);
  }, [open, initialStep]);

  const closeAndComplete = () => {
    setOpen(false);
    setOnboardingStep(OnboardingStep.Complete);
    localStorage.setItem(SPORT_ONBOARDING, "true");
  };

  return (
    <LargeModal
      open={open}
      setOpen={(open: boolean) => {
        if (!open) {
          closeAndComplete();
        }
      }}
      bgImage={`https://cdn.headversity.com/app/sport/${
        isDesktop ? "bg-people-desktop.jpg" : "bg-people.jpg"
      }`}
      bgTransitionSeconds={0.25}
      preventClose={
        isLeader && currentTeam?.name.toLowerCase() === "placeholder"
      }
      forceDarkMode={true}
    >
      <>
        {onboardingStep === OnboardingStep.Welcome && (
          <SportWelcome
            isLeader={isLeader}
            onContinue={() => {
              if (isLeader) {
                setOnboardingStep(OnboardingStep.TeamEdit);
              } else {
                closeAndComplete();
              }
            }}
          />
        )}
        {onboardingStep === OnboardingStep.TeamEdit && (
          <SportTeamEdit
            onContinue={closeAndComplete}
            isOnboarding={initialStep !== OnboardingStep.TeamEdit}
          />
        )}
      </>
    </LargeModal>
  );
};
