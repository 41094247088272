import { Button, Center, HStack, Icon, Text } from "@chakra-ui/react";
import {
  Border_Radius,
  CARD_BACKGROUND_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import {
  RiThumbDownFill,
  RiThumbDownLine,
  RiThumbUpFill,
  RiThumbUpLine,
} from "react-icons/ri";
import React from "react";
import { HVTestId } from "../../Testing/dataTestIds";

interface LikeAndDislikeButtonProps {
  maxW?: string;
  description?: string;
  result: string;
  dataTestId?: string;
  setResult: (ratingResult: string) => void;
  noShadow?: boolean;
}

export const LikeAndDislikeButton = (props: LikeAndDislikeButtonProps) => {
  const { maxW, description, result, setResult, dataTestId, noShadow } = props;
  return (
    <Center
      boxShadow={!noShadow ? "md" : undefined}
      borderRadius={Border_Radius}
      bg={CARD_BACKGROUND_COLOR}
      data-testid={dataTestId}
    >
      <HStack minH={"50px"} maxW={maxW} w={"100%"} color={PRIMARY_TEXT_COLOR}>
        <Text w={"100%"} p={"5"}>
          {description}
        </Text>

        <HStack w={"150px"} justify={"center"} spacing={"0"}>
          <Button
            className="button-link"
            maxW={"30px"}
            onClick={() => {
              setResult("like");
            }}
            disabled={result !== ""}
            opacity={result === "like" ? "1 !important" : undefined}
            _hover={{ filter: !result ? "brightness(250%)" : undefined }}
            data-testid={
              result === "like"
                ? HVTestId.LikeAndDislikeButton.thumbsUpFilled
                : HVTestId.LikeAndDislikeButton.thumbsUpEmpty
            }
          >
            <Icon
              w={"30px"}
              h={"30px"}
              aria-label={"like"}
              as={result === "like" ? RiThumbUpFill : RiThumbUpLine}
            />
          </Button>
          <Button
            className="button-link"
            maxW={"30px"}
            onClick={() => {
              setResult("dislike");
            }}
            disabled={result !== ""}
            opacity={result === "dislike" ? "1 !important" : undefined}
            _hover={{ filter: !result ? "brightness(250%)" : undefined }}
            data-testid={
              result === "dislike"
                ? HVTestId.LikeAndDislikeButton.thumbsDownFilled
                : HVTestId.LikeAndDislikeButton.thumbsDownEmpty
            }
          >
            <Icon
              w={"30px"}
              h={"30px"}
              aria-label={"dislike"}
              as={result === "dislike" ? RiThumbDownFill : RiThumbDownLine}
            />
          </Button>
        </HStack>
      </HStack>
    </Center>
  );
};
