import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import { FeatureImplementationViewDto } from "@headversity/contract";

export const getFeatureImplementations = (
  token: string
): Promise<AxiosResponse<FeatureImplementationViewDto[]>> => {
  const config = getConfig(token);

  return AxiosClient.get("/api/featureFlags/featureImplementations", config);
};
