import { Box, Fade, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SmallModal } from "../SmallModal";
import { AnimationBox } from "./AnimationBox";
import { White } from "../../../Styles/HeadversityStyle";
import { QualificationCompleteCard } from "../../Cert/Shared/QualificationCompleteCard";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

interface CertQualificationDoneModalProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
}

export const CertQualificationDoneModal = (
  props: CertQualificationDoneModalProps
) => {
  const { isOpen, onClose, name } = props;

  const [useAnimation, setUseAnimation] = useState<boolean>(false);
  const [useAnimationFade, setUseAnimationFade] = useState<boolean>(false);

  const currentAnimationUrl =
    "https://cdn.headversity.com/app/cert/CERT_achievement_seal.gif";

  useEffect(() => {
    setUseAnimation(true);

    // fade the animation out
    setTimeout(() => {
      setUseAnimationFade(true);
    }, 7000);

    // fade the stats in
    setTimeout(() => {
      setUseAnimation(false);
    }, 7100);
  }, []);

  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <SmallModal
      isOpen={isOpen}
      onClose={onClose}
      border={`solid ${White} 5px`}
      height="450px"
    >
      <Box mb={3}>
        <AnimationBox
          moreTextContent={HVLocalizeStrings.YOU_ARE_QUALIFIED}
          useAnimation={useAnimation}
          useAnimationFade={useAnimationFade}
          hasMoreText={true}
          isMobile={isMobile}
          currentAnimationUrl={currentAnimationUrl}
        />
        <Fade in={!useAnimation}>
          <Box mt={"20px"}>
            <QualificationCompleteCard title={`${name}`} />
          </Box>
        </Fade>
      </Box>
    </SmallModal>
  );
};
