import {
  Box,
  Button,
  Flex,
  NumberInput,
  NumberInputField,
  Select,
  Text,
} from "@chakra-ui/react";
import { NodeDto } from "@headversity/contract";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { getHierarchySnapshots } from "../../../../Api/Reach/ReachApi";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  HierarchyContext,
  HierarchySnapshot,
} from "../../../../State/Reach/ReachHierarchyContext";
import { getKey } from "../../../../Utils/Helpers";
import { HvSpinner } from "../../../Common/HvSpinner";
import { HierarchyCompare } from "./HierarchyCompare";

export interface HierarchySnapshotResponse {
  meta: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    first_page: number;
    first_page_url: string;
    last_page_url: string;
    next_page_url: string;
    prev_page_url: string;
  };
  data: HierarchySnapshot[];
}

interface HierarchySnapshotsProps {
  hierarchyNodes: NodeDto[];
  //   onSelectSnapshot: (snapshotId: number) => void;
}

export const HierarchySnapshots = (props: HierarchySnapshotsProps) => {
  const [selectedSnapshotId, setSelectedSnapshotId] = useState<
    number | undefined
  >();
  const [hierarchySnapshots, setHierarchySnapshots] =
    useState<HierarchySnapshotResponse>();
  const [currentPage, setCurrentPage] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchSnapshots = useCallback(async () => {
    setLoading(true);
    setSelectedSnapshotId(undefined);
    // fetch snapshots only on tab change
    const snapshotResponse = await getHierarchySnapshots(
      getKey(),
      currentPage ?? 1
    );
    if (snapshotResponse.data && snapshotResponse.data.data.length > 0) {
      setHierarchySnapshots(snapshotResponse.data);
      setCurrentPage(snapshotResponse.data.meta.current_page);
    }
    setLoading(false);
  }, [currentPage]);

  useEffect(() => {
    fetchSnapshots();
  }, []);

  const { onCompareSnapshot, compareDifference, onRestoreSnapshot } =
    useContext(HierarchyContext);

  useEffect(() => {
    if (selectedSnapshotId) {
      onCompareSnapshot(selectedSnapshotId);
    }
  }, [selectedSnapshotId]);

  const onRestore = () => {
    if (selectedSnapshotId && compareDifference) {
      onRestoreSnapshot(selectedSnapshotId);
    }
  };

  const handleSelectSnapshot = (selectedOption: any) => {
    if (selectedOption) setSelectedSnapshotId(Number(selectedOption));
  };

  const handlePageInputChanged = (valueString: string) => {
    const input = Number(valueString);
    setCurrentPage(input);
  };

  const handleLoadSnapshotClicked = () => {
    // reload
    fetchSnapshots();
  };

  return loading ? (
    <HvSpinner />
  ) : (
    <Box p={4} borderWidth={1} borderRadius={8} boxShadow="lg">
      {hierarchySnapshots?.meta && (
        <Box py={2}>
          <Text>`Total snapshots: {hierarchySnapshots.meta.total}</Text>
          <Flex gap={2} alignItems="center">
            <Text>Current Page:</Text>
            <NumberInput
              w={"80px"}
              defaultValue={currentPage}
              min={hierarchySnapshots.meta.first_page}
              max={hierarchySnapshots.meta.last_page}
              clampValueOnBlur={true}
              onChange={handlePageInputChanged}
            >
              <NumberInputField />
            </NumberInput>
            <Text>/ {hierarchySnapshots.meta.last_page}</Text>
            <Button onClick={handleLoadSnapshotClicked}>Load</Button>
          </Flex>
        </Box>
      )}
      {hierarchySnapshots?.data ? (
        <Flex
          flexDir="column"
          flexWrap="wrap"
          overflow={"auto"}
          minHeight={"300px"}
          maxWidth="100%"
          gap={3}
        >
          <Select
            placeholder="Select a snapshot"
            onChange={(event) => handleSelectSnapshot(event.target.value)}
          >
            {hierarchySnapshots?.data.map((snapshot) => (
              <option key={snapshot.id} value={snapshot.id}>
                {`Snapshot ${snapshot.id} - ${dayjs(snapshot.createdAt).format(
                  HVLocalizeStrings.DATE_FORMAT_WITH_TIME
                )}`}
              </option>
            ))}
          </Select>
          {compareDifference && <Button onClick={onRestore}>Restore</Button>}
          <Flex w="100%" flexDir={"column"}>
            {selectedSnapshotId && compareDifference && (
              <HierarchyCompare hierarchy={props.hierarchyNodes} />
            )}
          </Flex>
        </Flex>
      ) : (
        <Text>No snapshots available</Text>
      )}
    </Box>
  );
};
