import React, { useContext, useEffect, useState } from "react";
import {
  IMindfulnessToolProvider,
  MindfulnessToolContext,
} from "../../../../State/Solo/MindfulnessToolContext";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { MindfulnessToolModal } from "./MindfulnessToolModal";
import dayjs from "dayjs";
import { getKey } from "../../../../Utils/Helpers";
import { ToolLanding } from "../ToolLanding";
import {
  MindfulnessToolStat,
  MindfulnessToolStatCard,
} from "./MindfulnessToolStatCard";
import { EVENTS, track } from "../../../../Utils/Analytics";

interface MindfulnessToolLogProps {
  toolSkillInfo: any;
}

export const MindfulnessToolLanding = (props: MindfulnessToolLogProps) => {
  const { toolSkillInfo } = props;
  const { mindfulnessAudioUsage, getMindfulnessAudioUsageFromServer } =
    useContext<IMindfulnessToolProvider>(MindfulnessToolContext);

  const [minutes, setMinutes] = useState<number>(0);
  const [mindfulnessToolOpened, setMindfulnessToolOpened] =
    useState<boolean>(false);

  const [days, setDays] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    if (getKey()) {
      getMindfulnessAudioUsageFromServer();
    }
  }, [getKey()]);

  useEffect(() => {
    if (mindfulnessAudioUsage) {
      let totalMinute = 0;
      const haveDays = [false, false, false, false, false, false, false];
      const currentDate = dayjs.utc();
      let dateArray: any[] = [];
      for (let i = 0; i < 7; i++) {
        dateArray.push(currentDate.add(i * -1, "days").format("YYYY MM DD"));
      }

      dateArray = dateArray.reverse();
      mindfulnessAudioUsage.forEach((item) => {
        if (item.duration >= 60) {
          totalMinute += item.duration / 60;
          const dateStr = dayjs
            .utc(item.createdAt as string)
            .format("YYYY MM DD");
          const index = dateArray.indexOf(dateStr);
          if (index > -1) {
            haveDays[index] = true;
          }
        }
      });

      const streakDays: any[] = [];
      streakDays.push(haveDays[haveDays.length - 1]);
      for (let i = haveDays.length - 2; i > -1; i--) {
        if (haveDays[i]) {
          streakDays.push(true);
        } else {
          break;
        }
      }
      streakDays.reverse();
      setDays(streakDays);

      setMinutes(Math.round(totalMinute));
    }
  }, [mindfulnessAudioUsage]);

  return (
    <>
      <ToolLanding
        showTopBar={false}
        actionBarTitle={HVLocalizeStrings.MINDFULNESS_TOOLS}
        actionBarContent={<></>}
        mobileToolStat={
          <MindfulnessToolStat
            minutes={minutes}
            streaks={days?.filter((i) => i).length}
            isSmall
          />
        }
        toolCard={
          <MindfulnessToolStatCard
            minutes={minutes}
            streaks={days?.filter((i) => i).length}
            setMindfulnessToolOpened={() => {
              track(EVENTS.ToolOpened, { HV_Tool: "Mindfulness" });
              setMindfulnessToolOpened(true);
            }}
          />
        }
        toolInfoOne={HVLocalizeStrings.MINDFULNESS_TOOLS_PAGE_INFO_ONE}
        toolBoost={HVLocalizeStrings.MINDFULNESS_TOOLS_PAGE_BOOST}
        skillInfos={toolSkillInfo}
        mobileLaunchText={HVLocalizeStrings.MINDFULNESS_TOOLS_PAGE_USE_TOOL}
        mobileLaunchFunction={() => {
          track(EVENTS.ToolOpened, { HV_Tool: "Mindfulness" });
          setMindfulnessToolOpened(true);
        }}
      ></ToolLanding>
      <MindfulnessToolModal
        open={mindfulnessToolOpened}
        setOpen={setMindfulnessToolOpened as any}
      ></MindfulnessToolModal>
    </>
  );
};
