import { useContext } from "react";
import {
  Button,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { MdOutlineNotificationAdd } from "react-icons/md";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { isWebPlatform } from "../../../Utils/mobileUtils";

export default function PushNotificationAlert() {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const { promptForPushPermission } =
    useContext<IGlobalProvider>(GlobalContext);

  return (
    <>
      <Button
        onClick={() => {
          promptForPushPermission();
        }}
        w={"100%"}
        bgColor={"lightblue"}
        color={"black"}
        fontWeight={"regular"}
        mt={!isWebPlatform() ? 7 : 0}
      >
        <HStack spacing="3" w={"100%"} align={"center"} justify={"center"}>
          <Icon
            as={MdOutlineNotificationAdd}
            boxSize={"6"}
            display={isDesktop ? "inline" : "none"}
          />
          <Text fontSize={{ base: "xs", sm: "md" }}>
            {HVLocalizeStrings.SOLO_MENU_WEBPUSH}
          </Text>
        </HStack>
      </Button>
    </>
  );
}
