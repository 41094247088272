import React, { useEffect, useState } from "react";
import {
  isWebPlatform,
  checkIfOutdatedVersion,
} from "../../../Utils/mobileUtils";
import { Box, Center, Flex, Image, Text, VStack } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

export const AppVersionError = () => {
  const [showScreen, setShowScreen] = useState<boolean>(false);
  useEffect(() => {
    if (!isWebPlatform()) {
      checkIfOutdatedVersion().then((isOutdatedVersion) => {
        if (isOutdatedVersion) {
          setShowScreen(true);
        }
      });
    }
  }, []);

  return (
    <>
      {showScreen && (
        <Flex
          h={"100%"}
          w={"100%"}
          top={"0px"}
          zIndex={9999}
          align={"center"}
          alignItems={"center"}
          justifyContent={"center"}
          bgColor="white"
          position={"fixed"}
        >
          <Box maxW={300}>
            <Image
              src={
                "https://cdn.headversity.com/app/resources/other/blue-main-logo.png"
              }
              alt=""
            />
            <Text
              mt={4}
              dangerouslySetInnerHTML={{
                __html: `${HVLocalizeStrings.APP_VERSION_OUTDATED_ERROR}`,
              }}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};
