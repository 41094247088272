import React from "react";
import { LargeModal } from "../../../Common/LargeModal";
import { CalmingTool } from "./CalmingTool";

interface CalmingToolModalProps {
  open: boolean;
  setOpen: (val: boolean) => {};
}

export const CalmingToolModal = (props: CalmingToolModalProps) => {
  return (
    <LargeModal open={props.open} setOpen={props.setOpen} title={""}>
      <>
        <CalmingTool></CalmingTool>
      </>
    </LargeModal>
  );
};
