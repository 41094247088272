import React, { useCallback, useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { OptimismToolModal } from "./OptimismToolModal";
import {
  IOptimismToolProvider,
  OptimismToolContext,
} from "../../../../State/Solo/OptimismToolContext";
import { getKey } from "../../../../Utils/Helpers";
import { ToolLanding } from "../ToolLanding";
import { ActionButton } from "../../../Common/ActionButton";
import { OptimismToolStat, OptimismToolStatCard } from "./OptimismToolStatCard";
import {
  OPTIMISM_INTRO_STEP,
  OPTIMISM_LOGS_STEP,
  OPTIMISM_THREE_GOOD_THINGS_STEP,
  OPTIMISM_TIME_SELECTION_STEP,
} from "./OptimismTool";
import { completedToday, getDateRangeString } from "./Utils/OptimismUtils";
import {
  BLOCK_SECTION_TITLE_BORDER_BOTTOM,
  PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { IShellProvider, ShellContext } from "../../../../State/ShellContext";
import { EVENTS, track } from "../../../../Utils/Analytics";
import { ConfirmationButtons } from "../../../Common/ConfirmationDialog";
import { HvSpinner } from "../../../Common/HvSpinner";
import { HVTestId } from "../../../../Testing/dataTestIds";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { OptimismToolStatus } from "@headversity/contract";

interface OptimismToolLogProps {
  toolSkillInfo: any;
}

export const OptimismToolLanding = (props: OptimismToolLogProps) => {
  const { toolSkillInfo } = props;
  const [optimismStep, setOptimismStep] = useState<string>("");
  const [optimismToolOpened, setOptimismToolOpened] = useState<boolean>(false);
  const {
    getOptimismPlanFromServer,
    optimismPlans,
    currentPlannedItem,
    optimismPlanLoaded,
    stopCurrentOptimismChallengesToServer,
  } = useContext<IOptimismToolProvider>(OptimismToolContext);

  const { confirmationDialogRef } = useContext<IShellProvider>(ShellContext);

  const launchTool = useCallback(() => {
    if (currentPlannedItem) {
      if (completedToday(currentPlannedItem)) {
        setOptimismStep(OPTIMISM_LOGS_STEP);
      } else {
        setOptimismStep(OPTIMISM_THREE_GOOD_THINGS_STEP);
      }
    } else {
      setOptimismStep(OPTIMISM_INTRO_STEP);
    }
    setOptimismToolOpened(true);
  }, [currentPlannedItem]);

  const viewLogClick: any =
    optimismPlans && optimismPlans?.length > 0
      ? () => {
          track(EVENTS.ToolHistoryOpened, { HV_Tool: "Optimism" });
          setOptimismStep(OPTIMISM_LOGS_STEP);
          setOptimismToolOpened(true);
        }
      : null;

  return (
    <>
      <ToolLanding
        actionBarTitle={HVLocalizeStrings.OPTIMISM_TOOLS_ACTIVE}
        showTopBar={currentPlannedItem?.status !== OptimismToolStatus.Stopped}
        mobileToolStat={
          <OptimismToolStat
            optimismPlans={optimismPlans ?? []}
            isSmall={true}
          />
        }
        viewLogClick={viewLogClick}
        actionBarContent={
          <>
            {!optimismPlanLoaded && <HvSpinner />}
            {optimismPlanLoaded && currentPlannedItem && (
              <ActionButton
                border={BLOCK_SECTION_TITLE_BORDER_BOTTOM}
                image={
                  completedToday(currentPlannedItem)
                    ? "https://cdn.headversity.com/app/resources/toolIcons/orange_optimism.svg"
                    : "https://cdn.headversity.com/app/resources/toolIcons/orange_exclamation.svg"
                }
                title={`${currentPlannedItem?.numberOfDays} ${HVLocalizeStrings.OPTIMISM_TOOLS_DAYS_CHALLENGE} `}
                description={getDateRangeString(currentPlannedItem)}
                bgColor={"white"}
                color={PRIMARY_TEXT_COLOR}
                openMenuOnClick={true}
                actions={[
                  HVLocalizeStrings.OPTIMISM_TOOLS_CURRENT_MENU_SHOW,
                  HVLocalizeStrings.OPTIMISM_TOOLS_CURRENT_MENU_DONE,
                  HVLocalizeStrings.OPTIMISM_TOOLS_CURRENT_MENU_NEW,
                  HVLocalizeStrings.OPTIMISM_TOOLS_CURRENT_MENU_TIME,
                ]}
                actionCallBack={(item: any) => {
                  track(EVENTS.ToolOptimismActionClicked, { HV_Action: item });

                  switch (item) {
                    case HVLocalizeStrings.OPTIMISM_TOOLS_CURRENT_MENU_DONE:
                      (confirmationDialogRef.current as any).confirmation(
                        HVLocalizeStrings.OPTIMISM_TOOLS_CURRENT_MENU_DONE,
                        HVLocalizeStrings.OPTIMISM_TOOLS_STOP_CONFIRMATION,
                        ConfirmationButtons.YesNo,
                        () => {
                          stopCurrentOptimismChallengesToServer(
                            currentPlannedItem.id
                          );
                        }
                      );
                      break;
                    case HVLocalizeStrings.OPTIMISM_TOOLS_CURRENT_MENU_TIME:
                      setOptimismStep(OPTIMISM_TIME_SELECTION_STEP);
                      setOptimismToolOpened(true);
                      break;
                    case HVLocalizeStrings.OPTIMISM_TOOLS_CURRENT_MENU_NEW:
                      (confirmationDialogRef.current as any).confirmation(
                        HVLocalizeStrings.OPTIMISM_TOOLS_CURRENT_MENU_NEW,
                        HVLocalizeStrings.OPTIMISM_TOOLS_START_NEW_CONFIRMATION,
                        ConfirmationButtons.YesNo,
                        () => {
                          stopCurrentOptimismChallengesToServer(
                            currentPlannedItem.id
                          ).then(() => {
                            setOptimismStep(OPTIMISM_INTRO_STEP);
                            setOptimismToolOpened(true);
                          });
                        }
                      );

                      break;
                    case HVLocalizeStrings.OPTIMISM_TOOLS_CURRENT_MENU_SHOW:
                      launchTool();
                      break;
                  }
                }}
                hideBoxShadow={true}
                dataTestId={HVTestId.OptimismTool.optiActionButton}
              />
            )}
          </>
        }
        toolCard={
          <OptimismToolStatCard
            optimismPlans={optimismPlans ?? []}
            setOptimismToolOpened={() => {
              track(EVENTS.ToolOpened, { HV_Tool: "Optimism" });
              launchTool();
            }}
            viewLogClick={viewLogClick}
          />
        }
        toolInfoOne={HVLocalizeStrings.OPTIMISM_TOOLS_PAGE_INFO_ONE}
        toolBoost={HVLocalizeStrings.OPTIMISM_TOOLS_PAGE_BOOST}
        skillInfos={toolSkillInfo}
        mobileLaunchText={HVLocalizeStrings.OPTIMISM_TOOLS_PAGE_USE_TOOL}
        mobileLaunchFunction={() => {
          track(EVENTS.ToolOpened, { HV_Tool: "Optimism" });
          launchTool();
        }}
      ></ToolLanding>
      {optimismToolOpened && (
        <OptimismToolModal
          open={optimismToolOpened}
          setOpen={setOptimismToolOpened as any}
          step={optimismStep}
        ></OptimismToolModal>
      )}
    </>
  );
};
