import {
  Box,
  SlideFade,
  Image,
  useBreakpointValue,
  Center,
  useToken,
  Flex,
  Text,
} from "@chakra-ui/react";

import { useContext } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import { ShellContext } from "../../../State/ShellContext";
import {
  Invert_Filter,
  SlideFade_Offset,
  White_Stroke_Filter,
} from "../../../Styles/HeadversityStyle";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import { WelcomeModalHeader } from "./WelcomeModalHeader";
import { WelcomeModalSubheader } from "./WelcomeModalSubheader";

interface StepToBeginProps {
  customLogoUrl?: string;
}

export const StepToBegin = ({ customLogoUrl }: StepToBeginProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const [fourthTextColor] = useToken("colors", ["FOURTH_TEXT_COLOR"]);

  const { self, selectedUserLanguage } = useContext(GlobalContext);
  const { safeAreaInsets } = useContext(ShellContext);

  /*
   * simulate company logo
   *
  customLogoUrl =
    "https://cdn.headversity.com/companyLogo/36ba7cee-ed0f-4377-9b61-841c55ed7a64.png";
  */

  return (
    <SlideFade in={true} offsetY={SlideFade_Offset}>
      <Box
        flex="1 100%"
        mt={isDesktop ? (customLogoUrl ? "-20px" : 0) : "10px"}
        mx={isDesktop ? "40px" : "5px"}
      >
        <WelcomeModalHeader isLarge={true}>
          <Center>
            <Flex
              gap={isDesktop ? 10 : 4}
              flexDir={isDesktop ? "row" : "column"}
              mb="20px"
            >
              <Image
                mx={2}
                mb={1}
                display={"inline"}
                src={
                  selectedUserLanguage === "fr"
                    ? "https://cdn.headversity.com/app/resources/other/solo-logo-fr.png"
                    : selectedUserLanguage === "es"
                    ? "https://cdn.headversity.com/app/resources/other/solo-logo-es.png"
                    : "https://cdn.headversity.com/app/resources/other/solo-logo.png"
                }
                h={"90px"}
                filter={Invert_Filter}
                mt={`${isWebPlatform() ? 20 : safeAreaInsets.top}px`}
              />

              {customLogoUrl && (
                <Image
                  src={customLogoUrl}
                  maxH="100px"
                  maxW="250px"
                  m="auto"
                  alt=""
                  filter={White_Stroke_Filter}
                />
              )}
            </Flex>
          </Center>
        </WelcomeModalHeader>

        <WelcomeModalHeader mt="20px">
          <span
            style={{ fontWeight: 500 }}
            dangerouslySetInnerHTML={{
              __html: `${HVLocalizeStrings.HEADER_WELCOME}${
                self && self.fullName && self.fullName.indexOf(" ") > -1
                  ? ", " + self.fullName.split(" ")[0]
                  : ""
              }${HVLocalizeStrings.EXCLAMATION}`,
            }}
          />
        </WelcomeModalHeader>
        <WelcomeModalSubheader>
          <Box mr={isDesktop ? "20px" : undefined}>
            <Text
              as="span"
              dangerouslySetInnerHTML={{
                __html: HVLocalizeStrings.WELCOME_TAKE_CONTROL,
              }}
            />{" "}
            <b style={{ color: fourthTextColor }}>
              {HVLocalizeStrings.WELCOME_ADJ2}
            </b>{" "}
            {HVLocalizeStrings.AND}{" "}
            <b style={{ color: fourthTextColor }}>
              {HVLocalizeStrings.WELCOME_ADJ3}
            </b>
            {HVLocalizeStrings.WELCOME_ADJ_END}.
          </Box>
        </WelcomeModalSubheader>
      </Box>
    </SlideFade>
  );
};
