import {
  Box,
  Center,
  Image,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import { SchedulerDto, SchedulerFrequency } from "@headversity/contract";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import { Border_Radius, Equity_Orange } from "../../../Styles/HeadversityStyle";
import { getFrequencyFromScheduleExpression } from "../../../Utils/CronParserUtil";
import {
  getStreakInfo,
  getStreakNameFromType,
  StreakDetails,
  StreakType,
} from "../../../Utils/SkillsUtil";
import { HvSlideFade } from "../../Common/HvSlideFade";
import { ProgressTrackerSteps } from "../../Common/ProgressTrackerSteps";
import { WelcomeModalHeader } from "../../Shared/Welcome/WelcomeModalHeader";
import { WelcomeModalSubheader } from "../../Shared/Welcome/WelcomeModalSubheader";

const getParentLength = (type: StreakType, length: number) => {
  switch (type) {
    case StreakType.Day:
      return Math.floor(length / 7) + 1;
    case StreakType.Week:
      return Math.floor((length - 1) / 4) + 1;
    case StreakType.Month:
      return Math.floor(length / 12) + 1;
    default:
      return 0;
  }
};

const getStreakLabels = (type: StreakType, length: number) => {
  const today = dayjs().startOf("day").toDate();

  switch (type) {
    case StreakType.Week:
      return HVLocalizeStrings.STREAK_WEEKS;

    case StreakType.Month: {
      const months = HVLocalizeStrings.STREAK_MONTHS;
      if (length >= 12) return months;

      // find out when the streak started
      const monthsAgo = length - 1;

      const streakMonthStart = dayjs(today)
        .subtract(monthsAgo, "month")
        .toDate()
        .getMonth();

      return months
        .slice(streakMonthStart)
        .concat(months.slice(0, streakMonthStart));
    }

    default: {
      const days = [
        HVLocalizeStrings.SUNDAY,
        HVLocalizeStrings.MONDAY,
        HVLocalizeStrings.TUESDAY,
        HVLocalizeStrings.WEDNESDAY,
        HVLocalizeStrings.THURSDAY,
        HVLocalizeStrings.FRIDAY,
        HVLocalizeStrings.SATURDAY,
      ];

      if (length >= 7) return days;

      // find out when the streak started
      const daysAgo = length === 0 ? 0 : length - 1;

      const streakStart = dayjs(today)
        .subtract(daysAgo, "day")
        .toDate()
        .getDay();

      // return the days of the week starts from streakStart day
      return days.slice(streakStart).concat(days.slice(0, streakStart));
    }
  }
};

const getStreakLengthByParentPeriod = (
  type: StreakType,
  length: number,
  isIncomplete: boolean
) => {
  switch (type) {
    case StreakType.Day:
      if (length >= 7) {
        return dayjs().day() + (isIncomplete ? 0 : 1);
      } else {
        return length;
      }
    case StreakType.Week:
      // 4 returns 4, 5 returns 1, etc.
      return length === 0 ? 0 : length % 4 === 0 ? 4 : length % 4;

    case StreakType.Month:
      if (length >= 12) {
        return dayjs().month() + (isIncomplete ? 0 : 1);
      }
      return length % 12;
    default:
      return 0;
  }
};

const getStreakTypeFromFrequency = (freq: SchedulerFrequency) => {
  switch (freq) {
    case SchedulerFrequency.Weekly:
      return StreakType.Week;
    case SchedulerFrequency.Monthly:
      return StreakType.Month;
    default:
      return StreakType.Day;
  }
};

interface StreakInfoProps {
  streak?: StreakDetails;
  reminder?: SchedulerDto | null;
}
export const StreakInfo = ({ streak, reminder }: StreakInfoProps) => {
  const { schedulerReminders, activityCompletionDates } =
    useContext(GlobalContext);

  const [showProgress, setShowProgress] = useState(false);

  const [fourthTextColor] = useToken("colors", ["FOURTH_TEXT_COLOR"]);
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const streakInfo =
    streak ?? getStreakInfo(activityCompletionDates, schedulerReminders);

  const activeReminder: SchedulerDto | null =
    reminder !== undefined
      ? reminder
      : schedulerReminders.length > 0
      ? schedulerReminders[0]
      : null;

  const streakLength =
    streakInfo.isIncomplete && streakInfo.type !== StreakType.None
      ? streakInfo.length - 1
      : streakInfo.length;

  const streakType = getStreakNameFromType(
    streakInfo.type,
    streakInfo.length,
    false
  );

  const streakTypeSinglePhrase = getStreakNameFromType(
    streakInfo.type,
    streakLength,
    true
  );

  const streakTypeSingular = getStreakNameFromType(streakInfo.type, 1, false);

  const parentLength = getParentLength(streakInfo.type, streakInfo.length);

  // in terms of reminder, if any
  let activeStreakType = streakInfo.type;
  if (activeReminder) {
    activeStreakType = getStreakTypeFromFrequency(
      getFrequencyFromScheduleExpression(activeReminder.scheduleExpression)
    );
  }

  const streakLabels = getStreakLabels(activeStreakType, streakInfo.length);

  const streakLengthByParentPeriod = getStreakLengthByParentPeriod(
    activeStreakType,
    streakLength,
    streakInfo.isIncomplete
  );

  useEffect(() => {
    setTimeout(() => {
      setShowProgress(true);
    }, 1000);
  }, []);

  return (
    <Box mt={isDesktop ? "20px" : "40px"} px={{ base: undefined, md: "20px" }}>
      <WelcomeModalHeader isLarge={true}>
        {streakInfo.length === 0
          ? HVLocalizeStrings.START_A_STREAK
          : streakInfo.isIncomplete
          ? HVLocalizeStrings.STREAK_KEEP_GOING
          : streakInfo.type === StreakType.None
          ? HVLocalizeStrings.START_A_STREAK
          : HVLocalizeStrings.YOURE_ON_A_STREAK}
      </WelcomeModalHeader>
      <Center mt={!isDesktop ? 2 : "-75px"}>
        <Image
          mt="8px"
          src="/fire.svg"
          h="80px"
          filter={`drop-shadow(0 0 0.1rem #ffffff) ${
            streakInfo.isIncomplete ? "grayscale(1)" : ""
          }`}
        />
      </Center>

      <WelcomeModalSubheader>
        {streakInfo.type === StreakType.None && !activeReminder ? (
          HVLocalizeStrings.START_STREAK_DESCRIPTION
        ) : streakInfo.type === StreakType.None && activeReminder ? (
          HVLocalizeStrings.START_STREAK_WITH_CHALLENGE_DESCRIPTION
        ) : streakInfo.isIncomplete ? (
          <>
            {HVLocalizeStrings.COMPLETE_ACTIVITY}{" "}
            <b style={{ color: fourthTextColor }}>
              {streakLength} {streakTypeSinglePhrase}
            </b>{" "}
            {HVLocalizeStrings.COMPLETE_ACTIVITY_STREAK_GOING}
            {activeReminder ? " " + HVLocalizeStrings.MEET_YOUR_CHALLENGE : ""}.
          </>
        ) : (
          <>
            {HVLocalizeStrings.COMPLETED_ACTIVITY}{" "}
            <b style={{ color: fourthTextColor }}>
              {streakLength === 1
                ? streakInfo.type === StreakType.Day
                  ? HVLocalizeStrings.TODAY.toLowerCase()
                  : streakInfo.type === StreakType.Week
                  ? HVLocalizeStrings.THIS_WEEK
                  : HVLocalizeStrings.THIS_MONTH
                : streakLength +
                  " " +
                  streakType +
                  " " +
                  HVLocalizeStrings.IN_A_ROW}
            </b>
            .{" "}
            {HVLocalizeStrings.CONTINUE_TRAINING_EACH.replace(
              "{0}",
              streakTypeSingular
            )}{" "}
            {activeReminder
              ? HVLocalizeStrings.MEET_YOUR_CHALLENGE
              : HVLocalizeStrings.KEEP_MOMENTUM}
            .
          </>
        )}
      </WelcomeModalSubheader>

      <HvSlideFade fadeIn={true} delay={1}>
        <Box
          mt={"30px"}
          p="30px"
          bgColor={"#ffffff22"}
          borderRadius={Border_Radius}
        >
          {showProgress && (
            <>
              <ProgressTrackerSteps
                value={streakLengthByParentPeriod}
                color={Equity_Orange}
                totalStep={
                  activeStreakType === StreakType.Week
                    ? 4
                    : activeStreakType === StreakType.Month
                    ? 12
                    : 7
                }
                textToShow={streakLabels}
                partialFillStep={
                  streakInfo.type !== StreakType.None && streakInfo.isIncomplete
                }
              />
              <Text
                mt="-30px"
                mr="-20px"
                color="#ffffffcc"
                fontSize="11px"
                textAlign="right"
              >
                {activeStreakType === StreakType.Week
                  ? HVLocalizeStrings.STREAK_MONTH.toUpperCase()
                  : activeStreakType === StreakType.Month
                  ? HVLocalizeStrings.STREAK_YEAR.toUpperCase()
                  : HVLocalizeStrings.STREAK_WEEK.toUpperCase()}{" "}
                {parentLength + 1}
              </Text>
            </>
          )}
        </Box>
      </HvSlideFade>
    </Box>
  );
};
