import { useEffect, useState } from "react";

export interface IPasswordValidationProvider {
  pwdHasNumber: boolean;
  pwdHasLowerCase: boolean;
  pwdHasUpperCase: boolean;
  pwdHasMinLength: boolean;
  isValidPassword: boolean;
  validatePassword: (password: string) => void;
  resetPasswordValidation: () => void;
}

export const usePasswordValidation = (): IPasswordValidationProvider => {
  const [pwdHasNumber, setPwdHasNumber] = useState(false);
  const [pwdHasLowerCase, setPwdHasLowerCase] = useState(false);
  const [pwdHasUpperCase, setPwdHasUpperCase] = useState(false);
  const [pwdHasMinLength, setPwdHasMinLength] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);

  const PWD_MIN_LENGTH = 8;

  const validatePassword = (password: string) => {
    setPwdHasMinLength(password.length >= PWD_MIN_LENGTH);
    setPwdHasNumber(/(?=.*\d.*)/.exec(password) !== null);
    setPwdHasLowerCase(/(?=.*[a-z].*)/.exec(password) !== null);
    setPwdHasUpperCase(/(?=.*[A-Z].*)/.exec(password) !== null);
  };

  const resetPasswordValidation = () => {
    setPwdHasMinLength(false);
    setPwdHasNumber(false);
    setPwdHasLowerCase(false);
    setPwdHasUpperCase(false);
  };

  useEffect(() => {
    setIsValidPassword(
      pwdHasMinLength && pwdHasNumber && pwdHasLowerCase && pwdHasUpperCase
    );
  }, [pwdHasMinLength, pwdHasNumber, pwdHasLowerCase, pwdHasUpperCase]);

  return {
    pwdHasNumber,
    pwdHasLowerCase,
    pwdHasUpperCase,
    pwdHasMinLength,
    isValidPassword,
    validatePassword,
    resetPasswordValidation,
  };
};
