import { Preferences } from "@capacitor/preferences";
import { isWebPlatform } from "./mobileUtils";

export enum LocalStorageKeys {
  REFRESH_TOKEN = "refresh_token",
  RT = "rt",
}

interface StorageProvider {
  getItem(key: LocalStorageKeys): Promise<string | null>;
  setItem(key: LocalStorageKeys, value: string): Promise<void>;
  removeItem(key: LocalStorageKeys): Promise<void>;
  clear(): Promise<void>;
}

class LocalStorage implements StorageProvider {
  async getItem(key: LocalStorageKeys): Promise<string | null> {
    return await Promise.resolve(localStorage.getItem(key));
  }

  async setItem(key: LocalStorageKeys, value: string): Promise<void> {
    await Promise.resolve(localStorage.setItem(key, value));
  }

  async removeItem(key: LocalStorageKeys): Promise<void> {
    await Promise.resolve(localStorage.removeItem(key));
  }

  async clear(): Promise<void> {
    await Promise.resolve(localStorage.clear());
  }
}

class CapacitorStorage implements StorageProvider {
  async getItem(key: LocalStorageKeys): Promise<string | null> {
    const { value } = await Preferences.get({ key: key });
    return value;
  }

  async setItem(key: LocalStorageKeys, value: string): Promise<void> {
    await Preferences.set({
      key: key,
      value: value,
    });
  }

  async removeItem(key: LocalStorageKeys): Promise<void> {
    await Preferences.remove({ key: key });
  }

  async clear(): Promise<void> {
    await Preferences.clear();
  }
}

class StorageFactory {
  static createStorage(): StorageProvider {
    if (isWebPlatform()) {
      return new LocalStorage();
    } else {
      return new CapacitorStorage();
    }
  }
}

export class LocalStorageWrapper {
  private static storageProvider: StorageProvider =
    StorageFactory.createStorage();

  static async getItem(key: LocalStorageKeys): Promise<string | null> {
    const item = await this.storageProvider.getItem(key);
    return item;
  }

  static async setItem(key: LocalStorageKeys, value: string): Promise<void> {
    await this.storageProvider.setItem(key, value);
  }

  static async removeItem(key: LocalStorageKeys): Promise<void> {
    await this.storageProvider.removeItem(key);
  }

  static async clear(): Promise<void> {
    await this.storageProvider.clear();
  }
}
