import { useCallback, useState } from "react";
import { getFocusPlans } from "../../Api/Solo/FocusApi";
import { getKey } from "../../Utils/Helpers";
import { FocusToolInfoDto } from "@headversity/contract";

export const useFocusToolStat = () => {
  const [focusPlanItems, setFocusPlanItems] = useState<
    FocusToolInfoDto[] | null
  >(null);
  const [focusPlanItemsLoaded, setFocusPlanItemsLoaded] =
    useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const getFocusPlanItemsFromServer = useCallback(async () => {
    return getFocusPlans(getKey()).then((response: any) => {
      const focusPlanItems = response.data as FocusToolInfoDto[];
      setFocusPlanItems(focusPlanItems);
      setFocusPlanItemsLoaded(true);
    });
  }, []);

  return {
    focusPlanItems,
    getFocusPlanItemsFromServer,
    focusPlanItemsLoaded,
    setFocusPlanItemsLoaded,
    isEditMode,
    setIsEditMode,
  };
};
