import { useContext, useEffect, useState } from "react";
import { Box, SimpleGrid, Text, useBreakpointValue } from "@chakra-ui/react";
import { ToolPanel } from "./ToolPanel";
import { MindfulnessToolModal } from "./Mindfulness/MindfulnessToolModal";
import { useNavigate } from "react-router-dom";
import { getToolSkillInfo } from "../../../Utils/SkillsUtil";
import { CalmingToolProvider } from "../../../State/Solo/CalmingToolContext";
import { CalmingToolModal } from "./Calming/CalmingToolModal";
import { FocusToolProvider } from "../../../State/Solo/FocusToolContext";
import { FocusToolModal } from "./Focus/FocusToolModal";
import {
  IOptimismToolProvider,
  OptimismToolContext,
  OptimismToolProvider,
} from "../../../State/Solo/OptimismToolContext";
import { OptimismToolModal } from "./Optimism/OptimismToolModal";
import { ThinkingToolProvider } from "../../../State/Solo/ThinkingToolContext";
import { ThinkingToolModal } from "./Thinking/ThinkingToolModal";
import { getToolInfo } from "../../../Utils/toolUtil";
import { getKey } from "../../../Utils/Helpers";
import { completedToday } from "./Optimism/Utils/OptimismUtils";
import {
  OPTIMISM_INTRO_STEP,
  OPTIMISM_LOGS_STEP,
  OPTIMISM_THREE_GOOD_THINGS_STEP,
} from "./Optimism/OptimismTool";
import { PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../Utils/Analytics";
import { SkillDto, ToolDto } from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";
import { useFocusToolStat } from "../../../Hooks/Solo/useFocusToolStat";
import { GlobalContext } from "../../../State/GlobalContext";

interface Props {
  tools: ToolDto[];
  skills: SkillDto[];
  openToolDirectly?: boolean;
  lessonTools?: any[];
  spacing?: string;
  mobileSpacing?: string;
  skill?: SkillDto | undefined;
  from: string;
}

export const ToolList = (props: Props) => {
  const {
    tools,
    skills,
    openToolDirectly,
    lessonTools,
    spacing,
    mobileSpacing,
    skill,
    from,
  } = props;

  const [mindfulnessToolOpened, setMindfulnessToolOpened] =
    useState<boolean>(false);
  const [calmingToolOpened, setCalmingToolOpened] = useState<boolean>(false);
  const [focusToolOpened, setFocusToolOpened] = useState<boolean>(false);
  const [optimismToolOpened, setOptimismToolOpened] = useState<boolean>(false);
  const [thinkingToolOpened, setThinkingToolOpened] = useState<boolean>(false);

  const navigate = useNavigate();
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const isLg = useBreakpointValue({
    base: false,
    lg: true,
  });

  const { selectedUserLanguage } = useContext(GlobalContext);

  const { setIsEditMode, isEditMode } = useFocusToolStat();

  return (
    <>
      <SimpleGrid
        columns={isLg ? 3 : undefined}
        spacing={`${isMobile ? mobileSpacing ?? "20px" : spacing ?? "20px"}`}
      >
        {tools?.map((item) => {
          if (!item || (item.id === 4 && selectedUserLanguage === "es")) {
            return;
          }
          const toolInfo = getToolInfo(item.id);
          if (!toolInfo) return null;

          const tool: any = null;
          const toolSkillInfo = getToolSkillInfo(item, skills);
          const image = toolInfo.image;

          if (openToolDirectly) {
            toolSkillInfo.buttonText = toolInfo.buttonText.use;
          } else {
            toolSkillInfo.buttonText = toolInfo.buttonText.explore;
          }

          const onClick = async () => {
            const event = {
              HV_Tool: item.name,
              HV_From: from,
            } as any;
            if (skill) {
              event.HV_SkillId = skill.id;
              event.HV_SkillName = skill.slug;
            }
            track(
              openToolDirectly ? EVENTS.ToolOpened : EVENTS.ToolExplored,
              event
            );

            if (openToolDirectly) {
              if (item.id === 2) {
                setCalmingToolOpened(true);
              } else if (item.id === 3) {
                setThinkingToolOpened(true);
              } else if (item.id === 4) {
                setMindfulnessToolOpened(true);
              } else if (item.id === 5) {
                setFocusToolOpened(true);
              } else if (item.id === 6) {
                setOptimismToolOpened(true);
              }
            } else {
              navigate(toolInfo.path);
            }
          };

          return (
            <Box
              key={item.id}
              data-testid={`${HVTestId.ToolList.toolCard}_${item.id}`}
            >
              <LessonToolDescription
                lessonTools={lessonTools as any}
                toolId={item.id}
              ></LessonToolDescription>
              <ToolPanel
                clickHandler={onClick}
                key={item.id}
                backgroundImage={image}
                toolSkillInfo={toolSkillInfo}
                tool={tool}
              ></ToolPanel>
            </Box>
          );
        })}
      </SimpleGrid>
      <CalmingToolProvider>
        <CalmingToolModal
          open={calmingToolOpened}
          setOpen={setCalmingToolOpened as any}
        />
      </CalmingToolProvider>

      <MindfulnessToolModal
        open={mindfulnessToolOpened}
        setOpen={setMindfulnessToolOpened as any}
      />

      {focusToolOpened && (
        <FocusToolModal
          open={focusToolOpened}
          setOpen={setFocusToolOpened as any}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      )}

      {optimismToolOpened && (
        <OptimismToolModalWrapper
          optimismToolOpened={optimismToolOpened}
          setOptimismToolOpened={setOptimismToolOpened}
        />
      )}

      {thinkingToolOpened && (
        <ThinkingToolProvider>
          <ThinkingToolModal
            open={thinkingToolOpened}
            setOpen={setThinkingToolOpened as any}
          />
        </ThinkingToolProvider>
      )}
    </>
  );
};

interface LessonToolDescriptionProps {
  toolId: number;
  lessonTools: any[];
}

const getMessage = (toolId: number, lessonTools: any[]) => {
  const lessonToolResults = lessonTools.filter(
    (item) => item.toolId === toolId
  );
  if (lessonToolResults.length > 0) {
    return lessonToolResults[0].description;
  }
  return "";
};

const LessonToolDescription = (props: LessonToolDescriptionProps) => {
  const { toolId, lessonTools } = props;
  if (!lessonTools) {
    return <></>;
  }

  return (
    <Box gridColumn={"span 8 / span 8"}>
      <Text fontSize={"xl"} color={PRIMARY_TEXT_COLOR} fontWeight={"semibold"}>
        {getMessage(toolId, lessonTools)}
      </Text>
    </Box>
  );
};

interface OptimismToolModalWrapperProps {
  optimismToolOpened: boolean;
  setOptimismToolOpened: (optimismToolOpened: boolean) => void;
}

const OptimismToolModalWrapper = (props: OptimismToolModalWrapperProps) => {
  const { optimismToolOpened, setOptimismToolOpened } = props;
  const [optimismStep, setOptimismStep] = useState<string>("");
  const { getOptimismPlanFromServer, currentPlannedItem } =
    useContext<IOptimismToolProvider>(OptimismToolContext);

  useEffect(() => {
    if (currentPlannedItem) {
      if (completedToday(currentPlannedItem)) {
        setOptimismStep(OPTIMISM_LOGS_STEP);
      } else {
        setOptimismStep(OPTIMISM_THREE_GOOD_THINGS_STEP);
      }
    } else {
      setOptimismStep(OPTIMISM_INTRO_STEP);
    }
  }, [currentPlannedItem]);

  return (
    <>
      {optimismStep && optimismToolOpened && (
        <OptimismToolModal
          open={optimismToolOpened}
          setOpen={setOptimismToolOpened as any}
          step={optimismStep}
        />
      )}
    </>
  );
};
