import { useContext, useEffect, useState } from "react";
import {
  TEAM_LESSON_END_STEP,
  PresentationStepProps,
} from "./PresentationModal";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
} from "../../../State/GlobalContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import dayjs from "dayjs";
import {
  getPathTeamLessonByTeamId,
  trackTeamLesson,
} from "../../../Utils/TeamUtil";
import { PollResult } from "../Polls/PollResult";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import {
  PresentationPresentGoogleSlideView,
  PresentationPresentRise360View,
} from "../Shared/PresentationPresentView";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import {
  Button,
  Flex,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import { LessonCourseProvider } from "../../../State/Solo/LessonCourseContext";
import { HVTestId } from "../../../Testing/dataTestIds";
import { TeamLessonUserInstanceStatus } from "../../../Types/HVTypes";
import {
  LessonType,
  PathTeamLessonDto,
  PollQuestionDto,
  PollQuestionUserInstanceDto,
} from "@headversity/contract";

interface PresentationPresentStepProps extends PresentationStepProps {
  pathTeamLessonId?: number;
  pathId?: number;
  closeFunction?: () => void;
}

export const PresentationPresentStep = (
  props: PresentationPresentStepProps
) => {
  const {
    setCurrentStep,
    teamLesson,
    pathId,
    pathTeamLessonId,
    closeFunction,
  } = props;

  const {
    setTeamLessonUserInstanceEndToServer,
    setTeamLessonUserInstanceForceEndToServer,
    setTeamLessonUserInstanceEndForParticipantsToServer,
    currentTeamLessonUserInstanceId,
    getPollsFromServer,
    currentApp,
    currentTeam,
    pollInstances,
    paths,
    pollQuestions,
    setCloseDialog,
    setTeamLessonInProgressStepToServer,
    setTeamLessonUserInstanceCancelToServer,
    selectedUserLanguage,
    currentParticipantList,
    getTeamLessonUserInstanceParticipantsFromServer,
    presenterPathTeamLesson,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { confirmationDialogRef, safeAreaInsets } =
    useContext<IShellProvider>(ShellContext);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const [presentedUnix, setPresentedUnix] = useState<number | undefined>();
  const [currentPollInstance, setCurrentPollInstance] = useState<
    PollQuestionUserInstanceDto | undefined
  >();
  const [pollQuestion, setPollQuestion] = useState<
    PollQuestionDto | undefined
  >();
  const [pathTeamLesson, setPathTeamLesson] = useState<
    PathTeamLessonDto | undefined
  >();
  const [viewedPolls, setViewedPolls] = useState<{
    [key: string]: boolean;
  }>({});
  const [lessonType, setLessonType] = useState<LessonType>();

  const hasPollStarted = (pollQuestionId: number) => {
    if (pollInstances) {
      const currentPollInstances = pollInstances.filter(
        (item) =>
          item?.pollQuestionId === pollQuestionId &&
          item?.teamLessonUserInstanceId === currentTeamLessonUserInstanceId
      );
      if (currentPollInstances.length > 0) {
        return (
          presentedUnix !== undefined &&
          presentedUnix <
            dayjs.utc(currentPollInstances[0].createdAt.toString()).unix()
        );
      }
    }
    return false;
  };

  const pollsIncomplete = () => {
    const sessionsForTeamLesson = pollInstances.filter((pollInstance) =>
      pollQuestions.some(
        (poll) =>
          poll?.id === pollInstance?.pollQuestionId &&
          pollInstance?.teamLessonUserInstanceId ==
            currentTeamLessonUserInstanceId &&
          (pathTeamLesson?.lastPresentedDate === undefined ||
            dayjs(pollInstance?.createdAt.toString()) >
              dayjs(pathTeamLesson?.lastPresentedDate?.toString()))
      )
    );
    return (
      pollQuestions?.length > 0 &&
      sessionsForTeamLesson?.length < pollQuestions?.length
    );
  };

  const teamLessonCloseCallback = () => {
    if (pollsIncomplete() && currentTeamLessonUserInstanceId) {
      (confirmationDialogRef.current as any).confirmation(
        HVLocalizeStrings.TEAM_TEAM_LESSON_END,
        <Text
          color={MODAL_PRIMARY_TEXT_COLOR}
          dangerouslySetInnerHTML={
            lessonType === LessonType.Charter
              ? {
                  __html: `${HVLocalizeStrings.SPORT_TEAM_LESSON_INCOMPLETE_END_BLOCK_ONE}`,
                }
              : {
                  __html: `${HVLocalizeStrings.TEAM_TEAM_LESSON_INCOMPLETE_END_BLOCK_ONE}`,
                }
          }
        />,
        ConfirmationButtons.JSX,
        () => {},
        [
          <Flex flexDirection={"column"} gap={"5"} w={"100%"}>
            <Flex
              flexDirection={!isMobile ? "row" : "column"}
              justify={"center"}
              justifyContent={"center"}
              align={"center"}
              gap={"5"}
              w={"100%"}
            >
              <AnimatedButton
                text={HVLocalizeStrings.TEAM_TEAM_LESSON_INCOMPLETE_CONTINUE}
                colorSet={
                  lessonType === LessonType.Charter
                    ? AnimatedButtonColorSet.Primary
                    : undefined
                }
                onClick={() => {
                  setCloseDialog(true);
                }}
                w={250}
                dataTestId={HVTestId.PresentationPresentStep.continue}
              ></AnimatedButton>
              <AnimatedButton
                text={
                  HVLocalizeStrings.TEAM_TEAM_LESSON_INCOMPLETE_RESUME_LATER
                }
                colorSet={AnimatedButtonColorSet.Third}
                onClick={() => {
                  trackTeamLesson(
                    TEAM_EVENTS.EndAndResumePresentationLater,
                    currentTeam,
                    pathTeamLesson
                  );
                  setTeamLessonUserInstanceCancelToServer(
                    currentTeamLessonUserInstanceId
                  ).then(() => {
                    if (closeFunction) {
                      closeFunction();
                    }
                    setCloseDialog(true);
                  });
                }}
                w={250}
                dataTestId={HVTestId.PresentationPresentStep.cancel}
              ></AnimatedButton>
            </Flex>
            {!pathTeamLesson?.teamLesson?.lessonCourse?.riseUrl && (
              <Button
                className="button-link"
                color={MODAL_PRIMARY_TEXT_COLOR}
                fontSize={"sm"}
                textDecoration={"underline"}
                onClick={() => {
                  trackTeamLesson(
                    TEAM_EVENTS.EndIncompleteTeamLessonUserInstance,
                    currentTeam,
                    pathTeamLesson
                  );
                  setTeamLessonUserInstanceForceEndToServer(
                    currentTeamLessonUserInstanceId
                  ).then(() => {
                    setCurrentStep(TEAM_LESSON_END_STEP);
                    setCloseDialog(true);
                  });
                }}
                data-testid={HVTestId.PresentationPresentStep.complete}
              >
                {HVLocalizeStrings.TEAM_TEAM_LESSON_INCOMPLETE_END_NOW}
              </Button>
            )}
          </Flex>,
        ],
        null,
        "2xl"
      );
    } else if (currentTeamLessonUserInstanceId) {
      confirmationDialogRef.current.confirmation(
        HVLocalizeStrings.TEAM_TEAM_LESSON_END,
        HVLocalizeStrings.TEAM_TEAM_LESSON_END_MESSAGE,
        ConfirmationButtons.YesNo,
        () => {
          track(TEAM_EVENTS.EndTeamLessonUserInstance, {
            HV_Team_Presentation_Session_Id: currentTeamLessonUserInstanceId,
          });
          setTeamLessonUserInstanceForceEndToServer(
            currentTeamLessonUserInstanceId
          ).then(() => {
            setCurrentStep(TEAM_LESSON_END_STEP);
          });
        }
      );
    }
  };

  const pollContentShell = (
    buttonText: string,
    onClickCallback: () => void
  ) => {
    return (
      <VStack pb={`${safeAreaInsets.bottom}px`} w={"100%"}>
        <PollResult
          pollQuestion={pollQuestion}
          pollInstance={currentPollInstance}
          showWaitingOnVotes={true}
        />
        <AnimatedButton
          text={buttonText}
          w={200}
          onClick={onClickCallback}
          dataTestId={HVTestId.PresentationPresentStep.pollContinueButton}
        ></AnimatedButton>
      </VStack>
    );
  };

  useEffect(() => {
    const lessonType = presenterPathTeamLesson?.teamLesson?.lessonType;

    if (lessonType) {
      setLessonType(lessonType);
    } else {
      setLessonType(LessonType.Team);
    }
  }, [presenterPathTeamLesson]);

  useEffect(() => {
    if (teamLesson) {
      getPollsFromServer(teamLesson.id);
    }
  }, [teamLesson]);

  useEffect(() => {
    if (!currentParticipantList && currentTeamLessonUserInstanceId) {
      getTeamLessonUserInstanceParticipantsFromServer(
        currentTeamLessonUserInstanceId
      );
    }
  }, [currentParticipantList, currentTeamLessonUserInstanceId]);

  useEffect(() => {
    const result = getPathTeamLessonByTeamId(
      paths,
      currentTeam,
      pathTeamLessonId
    );
    if (result) {
      setPathTeamLesson(result);
      if (result.lastPresentedDate) {
        setPresentedUnix(
          dayjs.utc(result.lastPresentedDate?.toString()).unix()
        );
      }
    }
  }, [pathTeamLessonId, currentTeam, paths, selectedUserLanguage]);

  useEffect(() => {
    if (
      pollQuestion &&
      pollInstances &&
      currentTeam &&
      currentTeamLessonUserInstanceId &&
      paths
    ) {
      const currentPollInstances = pollInstances.filter((item) => {
        return (
          item.pollQuestionId === pollQuestion.id &&
          item.teamLessonUserInstanceId === currentTeamLessonUserInstanceId &&
          (presentedUnix === undefined ||
            dayjs.utc(item.createdAt.toString()).unix() >= presentedUnix)
        );
      });
      if (currentPollInstances.length > 0) {
        setCurrentPollInstance(currentPollInstances[0]);
      }
    }
  }, [
    pollQuestion,
    pollInstances,
    currentTeam,
    currentTeamLessonUserInstanceId,
    paths,
    presentedUnix,
  ]);

  const onCurrentPollChanged = (poll: PollQuestionDto) => {
    setViewedPolls((prev) => {
      return { ...prev, [poll.id]: true };
    });
    setPollQuestion(poll);
    setTeamLessonInProgressStepToServer(
      currentTeamLessonUserInstanceId as number,
      poll === null ? "" : `POLL_${poll.id}`
    );
  };

  return pathTeamLesson?.teamLesson?.lessonCourse?.riseUrl ? (
    <>
      {pathTeamLesson?.teamLessonUserInstanceId && (
        <LessonCourseProvider>
          <PresentationPresentRise360View
            pathTeamLesson={pathTeamLesson}
            pollQuestion={pollQuestion}
            pollInstance={currentPollInstance}
            viewedPolls={viewedPolls}
            pathId={pathId}
            onLessonComplete={() => {
              trackTeamLesson(
                TEAM_EVENTS.CompleteTeamLessonUserInstance,
                currentTeam,
                pathTeamLesson,
                undefined,
                {
                  HV_Team_Presentation_Session_Id:
                    currentTeamLessonUserInstanceId,
                  HV_App: currentApp as AppType,
                }
              );
              setTeamLessonUserInstanceForceEndToServer(
                currentTeamLessonUserInstanceId as number
              ).then(() => {
                setCurrentStep(TEAM_LESSON_END_STEP);
              });
            }}
            onLessonCompleteForParticpants={() => {
              trackTeamLesson(
                TEAM_EVENTS.CompleteTeamLessonUserInstanceForParticipants,
                currentTeam,
                pathTeamLesson,
                undefined,
                {
                  HV_Team_Presentation_Session_Id:
                    currentTeamLessonUserInstanceId,
                }
              );
              setTeamLessonUserInstanceEndForParticipantsToServer(
                currentTeamLessonUserInstanceId as number
              );
            }}
            pollContent={pollContentShell(HVLocalizeStrings.CONTINUE, () => {
              trackTeamLesson(
                TEAM_EVENTS.ContinuePresentationFromPoll,
                currentTeam,
                pathTeamLesson
              );
              setTeamLessonInProgressStepToServer(
                currentTeamLessonUserInstanceId as number,
                ""
              );

              setPollQuestion(undefined);
              setCurrentPollInstance(undefined);
            })}
            hasPollStarted={hasPollStarted}
            showPoll={() => {
              return true;
            }}
            onCurrentPollChanged={onCurrentPollChanged}
            onClose={() => {
              teamLessonCloseCallback();
            }}
          />
        </LessonCourseProvider>
      )}
    </>
  ) : (
    <PresentationPresentGoogleSlideView
      pathTeamLesson={pathTeamLesson}
      pollQuestion={pollQuestion}
      pollInstance={currentPollInstance}
      pathId={pathId}
      showPoll={() => {
        return true;
      }}
      pollContent={pollContentShell(HVLocalizeStrings.CONTINUE, () => {
        trackTeamLesson(
          TEAM_EVENTS.ContinuePresentationFromPoll,
          currentTeam,
          pathTeamLesson
        );
        setPollQuestion(undefined);
        setCurrentPollInstance(undefined);
        if (
          !pollsIncomplete() &&
          pathTeamLesson?.teamLessonInstanceStatus !==
            TeamLessonUserInstanceStatus.Complete
        ) {
          setTeamLessonUserInstanceEndToServer(
            currentTeamLessonUserInstanceId as number
          );
        }
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId as number,
          ""
        );
      })}
      hasPollStarted={hasPollStarted}
      showBoostColor={hasPollStarted}
      onCurrentPollChanged={onCurrentPollChanged}
      onClose={() => {
        teamLessonCloseCallback();
      }}
    />
  );
};
