import { Box, Center, Heading, Stack, Text } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { OPTIMISM_TIME_SELECTION_STEP } from "./OptimismTool";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface OptimismToolLengthSelectionProps {
  changePage: (page: string) => void;
  lengthOfChallenge: number;
  setLengthOfChallenge: (lengthOfChallenge: number) => void;
}

export const OptimismToolStepLengthSelection = (
  props: OptimismToolLengthSelectionProps
) => {
  const { changePage, setLengthOfChallenge, lengthOfChallenge } = props;

  const handleLengthClick = (value: number) => {
    setLengthOfChallenge(value);
  };

  const challengesLengths = [3, 7, 15, 30];

  return (
    <>
      <Box>
        <Heading
          text-align={"left"}
          fontSize={"2xl"}
          fontWeight={"semibold"}
          color={MODAL_PRIMARY_TEXT_COLOR}
        >
          {HVLocalizeStrings.OPTIMISM_TOOLS_WELCOME}
        </Heading>
        <Text mt={"5"} fontSize="md" color={MODAL_PRIMARY_TEXT_COLOR}>
          {HVLocalizeStrings.OPTIMISM_TOOLS_LENGTH_OF_CHALLENGE}
        </Text>
      </Box>
      <Center>
        <Stack spacing={"1"}>
          {challengesLengths.map((lgt: number) => (
            <LengthButton
              key={lgt}
              setLength={handleLengthClick}
              length={lgt}
              selected={lengthOfChallenge === lgt}
            />
          ))}
        </Stack>
      </Center>
      <Center mt={8} mb={2}>
        <AnimatedButton
          disabled={lengthOfChallenge < 1}
          text={HVLocalizeStrings.NEXT}
          onClick={() => changePage(OPTIMISM_TIME_SELECTION_STEP)}
          dataTestId={HVTestId.OptimismTool.steps.lengthSelection.nextButton}
        />
      </Center>
    </>
  );
};

interface LengthButtonProps {
  length: number;
  setLength: (length: number) => void;
  selected: boolean;
}

const LengthButton = ({ length, setLength, selected }: LengthButtonProps) => {
  return (
    <AnimatedButton
      colorSet={AnimatedButtonColorSet.Third}
      w={106}
      text={length + ` ${HVLocalizeStrings.DAYS}`}
      onClick={() => setLength(length)}
      selected={selected}
      dataTestId={`${HVTestId.OptimismTool.steps.lengthSelection.lengthButton}_${length}`}
    ></AnimatedButton>
  );
};
