import { NodeDto } from "@headversity/contract";
import { useReachGlobal } from "../../../Hooks/Reach/useReachGlobal";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HvSelect, HvSelectOption } from "../../Common/HvSelect";
import { Box } from "@chakra-ui/react";
import useCompanyReachAdmin from "../../../Hooks/Reach/useCompanyReachAdmin";

interface ReachSearchByHierarchyProps {
  readonly nodes: NodeDto[];
  readonly nodesFilter: NodeDto[];
  readonly handleSelections: (selected: HvSelectOption[]) => void;
}

interface ReachSearchSelectProps {
  readonly items: HvSelectOption[];
  readonly initialItems: HvSelectOption[];
  readonly handleSelections: (selected: HvSelectOption[]) => void;
  readonly placeholder: string;
}

export function ReachSearchSelect({
  items,
  initialItems,
  handleSelections,
  placeholder,
}: ReachSearchSelectProps) {
  return (
    <Box>
      <HvSelect
        isMultiSelection={true}
        options={items}
        selectedValues={initialItems}
        onMultiSelectChange={(value) =>
          handleSelections(value as HvSelectOption[])
        }
        placeHolder={placeholder}
        hasBlackBorder={true}
        height={"auto"}
      />
    </Box>
  );
}

export const ReachSearchByHierarchy = (props: ReachSearchByHierarchyProps) => {
  const { getHierarchyOptions } = useReachGlobal();
  const companyReachAdmin = useCompanyReachAdmin();
  const hierarchyOptions = getHierarchyOptions(props.nodes, companyReachAdmin.isCompanyReachAdmin);
  const initialHierarchyOptions = getHierarchyOptions(props.nodesFilter, false);

  return (
    <ReachSearchSelect
      items={hierarchyOptions}
      initialItems={initialHierarchyOptions}
      handleSelections={props.handleSelections}
      placeholder={HVLocalizeStrings.REACH_NODE_SELECT}
    />
  );
};
