import { CharterDisplay } from "../CharterEditor/CharterDisplay";
import React, { useContext } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  ParticipantActivityLabel,
  ParticipantContainer,
  ParticipantGroupLabel,
} from "../../../Shared/Container/ParticipantContainer";

export const ParticipantCharterStep = () => {
  const { currentTeamLessonUserInstanceId } =
    useContext<IGlobalProvider>(GlobalContext);

  return (
    <ParticipantContainer
      leftContent={<ParticipantActivityLabel groupActivity={true} />}
      rightContent={<ParticipantGroupLabel />}
    >
      {currentTeamLessonUserInstanceId && (
        <CharterDisplay
          isEditor={false}
          teamLessonUserInstanceId={currentTeamLessonUserInstanceId}
        />
      )}
    </ParticipantContainer>
  );
};
