import { createContext, useCallback } from "react";
import { saveCalmingToolUsage } from "../../Api/Solo/CalmingApi";
import { getKey } from "../../Utils/Helpers";
import { useCalmingToolStat } from "../../Hooks/Solo/useCalmingToolStat";
import { CalmingToolInfoDto, CalmingToolInput } from "@headversity/contract";

export interface ICalmingToolProvider {
  calmingToolUserUsage: CalmingToolInfoDto[];
  getCalmingToolUsageServer: () => void;
  saveCalmingToolUsageToServer: (input: CalmingToolInput) => void;
}

export const CalmingToolContext = createContext<ICalmingToolProvider>(
  {} as any
);

export const CalmingToolProvider = (props: any) => {
  const { getCalmingToolUsageServer, calmingToolUserUsage } =
    useCalmingToolStat();

  const saveCalmingToolUsageToServer = useCallback(
    async (input: CalmingToolInput) => {
      return saveCalmingToolUsage(getKey(), input).then((response) => {
        getCalmingToolUsageServer();
      });
    },
    []
  );

  return (
    <CalmingToolContext.Provider
      value={{
        calmingToolUserUsage,
        getCalmingToolUsageServer,
        saveCalmingToolUsageToServer,
      }}
    >
      {props.children}
    </CalmingToolContext.Provider>
  );
};
