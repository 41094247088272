import { Box, Text, Image, useDisclosure, Flex } from "@chakra-ui/react";
import { useContext } from "react";
import { HVSportLocalizeStrings } from "../../../Localization/HVSportLocalizeString";
import { GlobalContext } from "../../../State/GlobalContext";
import { Border_Radius } from "../../../Styles/HeadversityStyle";
import { TeamCommandModals } from "../../Team/TeamManagement/TeamCommandModals";

export const SportTeamInfo = () => {
  const { currentTeam, teams } = useContext(GlobalContext);

  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <>
      <Box>
        {!currentTeam ? (
          <></>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            borderColor="gray.300"
            border={"1px solid #ccc"}
            p={5}
            borderRadius={Border_Radius}
            overflow="hidden"
            background="white"
            cursor={teams.length > 1 ? "pointer" : undefined}
            onClick={() => {
              if (teams.length > 1) {
                onOpen();
              }
            }}
          >
            {/* Background logo image */}
            {currentTeam.logo && (
              <Box
                opacity={0.2} // Makes the logo faint, like a watermark; on a box instead of image due to iOS rendering issue
              >
                <Image
                  src={currentTeam.logo}
                  alt="Team logo"
                  position="absolute"
                  top="0"
                  right={teams.length > 1 ? "20px" : "0px"}
                  width="200px"
                  height="100%"
                  objectFit="cover"
                />
              </Box>
            )}

            {/* Team name */}
            <Flex
              width="100%"
              direction={{ base: "column", md: "row" }}
              gap={{ base: "0px", md: "2px" }}
            >
              <Text
                textAlign="left"
                zIndex={1}
                fontSize={{ base: "15px", md: "16px" }}
              >
                {HVSportLocalizeStrings.CHARTER_YOUR_TEAM}
              </Text>
              <Text>
                <b>{currentTeam.name}</b>
              </Text>
            </Flex>
            {teams.length > 1 && (
              <Box pos="absolute" top="-5px" right="10px" fontSize={"24px"}>
                ...
              </Box>
            )}
          </Box>
        )}
      </Box>

      {isOpen && (
        <TeamCommandModals open={isOpen} close={onClose} type={"select"} />
      )}
    </>
  );
};
