import { useState } from "react";

export interface INetworkingProvider {
  isOnline: boolean | undefined;
  setIsOnline: (isOnline: boolean | undefined) => void;
}
export const useNetworking = (): INetworkingProvider => {
  const [isOnline, setIsOnline] = useState<boolean | undefined>();
  return { isOnline, setIsOnline };
};
