import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import {
  AudioSeriesDto,
  AudioTrackVersionUsageDto,
  AudioTrackVersionUsageInputDto,
} from "@headversity/contract";

export const getMindfulnessAudioSeries = (
  token: string
): Promise<AxiosResponse<AudioSeriesDto[]>> => {
  return AxiosClient.get("api/audioSeries/mindfulness", getConfig(token));
};

export const getAudioTrackUsages = (
  token: string
): Promise<AxiosResponse<AudioTrackVersionUsageDto[]>> => {
  return AxiosClient.get("api/audioTrackVersions/usage", getConfig(token));
};

export const saveAudioTrackUsage = (
  token: string,
  audioTrackVersionId: number,
  inputDto: AudioTrackVersionUsageInputDto
) => {
  return AxiosClient.post(
    `api/audioTrackVersion/${audioTrackVersionId}/usage`,
    inputDto,
    getConfig(token)
  );
};
