import {
  CertDto,
  GetSelfServeUsersDto,
  InviteCodeForReachDto,
  SelfServeUserDto,
  UpdateSelfServeUserDto,
  UserCertStatsDto,
  CompletionState,
  NodeDto,
} from "@headversity/contract";
import { EligibilityFileJobDto } from "@headversity/contract/Dto/EligibilityFileJobDto";
import { DateTime } from "luxon";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  assignCertToUser,
  confirmEligibilityFileJob,
  deleteReachUser,
  getCertsStatsForUser,
  getCompanyCertsForReach,
  getCompanyInviteCodesForReach,
  getEligibilityJobs,
  getFilterableHierarchy,
  getReachUsers,
  unAssignCertFromUser,
  uploadEligibilityFileCsv,
  upsertReachUser,
} from "../../Api/Reach/ReachApi";
import { getKey } from "../../Utils/Helpers";
import { onPusherEvent } from "../../Utils/PusherChannelNotifications";
import { GlobalContext, IGlobalProvider } from "../GlobalContext";
import { REACH_EVENTS, track } from "../../Utils/Analytics";
import { HvSelectOption } from "../../Components/Common/HvSelect";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";

export enum EligibilityJobResult {
  SUCCESS,
  ERROR,
}

type EligibilityJobsState = {
  jobs: EligibilityFileJobDto[];
  searchFilter: string;

  reachUsersIsLoading: boolean;
  reachUsers: GetSelfServeUsersDto;

  upsertReachUserToServer: (
    updateSelfServeUserDto: UpdateSelfServeUserDto,
    certAssignments?: CertDto[]
  ) => Promise<SelfServeUserDto>;
  deleteReachUserFromServer: (userId: number) => Promise<void>;

  getReachUsersAndCompanyInviteCodesFromServer: (
    usersPerPage: number,
    currentPage: number,
    sortBy?: string,
    sortDir?: string,
    filter?: string,
    certsFilter?: CertDto[],
    completionStatusesFilter?: CompletionState[],
    nodesFilter?: NodeDto[]
  ) => Promise<void>;

  inviteCodesForReach: InviteCodeForReachDto[];

  setSearchFilter: (filter: string) => void;
  fetchJobs: () => Promise<void>;
  triggerDataRefresh: () => void;
  dataRefreshTimestamp: string;
  uploadEligibilityFile: (
    file: any,
    importType: EligibilityImportType,
    certAssignments: number[],
    timezone: string
  ) => Promise<any>;
  isUploadingFile: boolean;
  setIsUploadingFile: (val: boolean) => void;
  confirmEligibilityFileJob: (jobId: number) => Promise<void>;
  pendingJob?: EligibilityFileJobDto;
  clearBulkModal: () => void;

  jobResult?: EligibilityJobResult;
  fetchCompanyCerts: () => Promise<void>;
  companyCerts: CertDto[];
  fetchHierarchyNodes: () => Promise<void>;
  hierarchyNodes: NodeDto[];
  hierarchyIdNodeMap: Map<number, NodeDto>;
  userCertsStats: UserCertStatsDto[];
  getCertsStatsForUserFromServer: (userId: number) => Promise<void>;
  setUserCertsStats: (stats: UserCertStatsDto[]) => void;
  unAssignCertFromUserFromServer: (
    userId: number,
    certId: number
  ) => Promise<void>;
  assignCertToUserToServer: (userId: number, certId: number) => Promise<void>;
  isUserCertsStatsLoading: boolean;
  setIsUserCertsStatsLoading: (val: boolean) => void;
  userModalOpen: UserModalOpen;
  setUserModalOpen: (val: UserModalOpen) => void;
  selectedUser?: SelfServeUserDto;
  setSelectedUser: (user: SelfServeUserDto | undefined) => void;

  downloadCSVUsers: (
    usersPerPage: number,
    currentPage: number,
    sortBy?: string,
    sortDir?: string,
    searchFilter?: string,
    certsFilter?: CertDto[],
    completionStatusesFilter?: CompletionState[],
    hierarchyOnly?: boolean,
    nodesFilter?: NodeDto[]
  ) => Promise<GetSelfServeUsersDto>;
  certsFilter: CertDto[];
  nodesFilter: NodeDto[];
  completionStatusFilter: CompletionState[];
  setCertsFilter: (filter: CertDto[]) => void;
  setNodesFilter: (filter: NodeDto[]) => void;
  setCompletionStatusFilter: (filter: CompletionState[]) => void;
  handleCertSelectionsChange: (certs: HvSelectOption[]) => void;
  handleNodeSelectionsChange: (nodes: HvSelectOption[]) => void;
  handleCompletionStatusSelectionsChange: (
    completionStatuses: HvSelectOption[]
  ) => void;
  updatePasswordModal: boolean;
  setUpdatePasswordModal: (val: boolean) => void;
  passwordResetCodeLink: string;
  setPasswordResetCodeLink: (val: string) => void;
  passwordResetUser: string;
  setPasswordResetUser: (val: string) => void;
  hierarchyOnly: boolean;
  setHierarchyOnly: (val: boolean) => void;
};

export enum EligibilityImportType {
  CREATE_ONLY,
  UPDATE_EXISTING,
  CREATE_OR_UPDATE,
  CHANGE_FILE,
  FULL_LIST,
}

export enum UserModalOpen {
  NONE,
  ADD,
  VIEW_EDIT,
  BULK,
}

export const ReachUsersContext = React.createContext<EligibilityJobsState>({
  jobs: [],
  fetchJobs: async () => {},
  searchFilter: "",
  setSearchFilter: (filter: string) => {},
  triggerDataRefresh: () => {},
  dataRefreshTimestamp: "",
  uploadEligibilityFile: async (
    file: any,
    importType: EligibilityImportType,
    timezone: string
  ) => {},
  reachUsersIsLoading: false,
  reachUsers: {
    data: [],
    meta: {
      total: 0,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      first_page: 1,
    },
  },
  getReachUsersAndCompanyInviteCodesFromServer: async (
    usersPerPage: number,
    currentPage: number,
    sortBy?: string,
    sortDir?: string,
    filter?: string,
    certsFilter?: CertDto[],
    completionStatusesFilter?: CompletionState[],
    hierarchyOnly?: boolean,
    nodesFilter?: NodeDto[]
  ) => {},
  inviteCodesForReach: [],
  upsertReachUserToServer: async (
    updateSelfServeUserDto: UpdateSelfServeUserDto
  ) => {},
  deleteReachUserFromServer: async (userId: number) => {
    return;
  },
  confirmEligibilityFileJob: async (jobId: number) => {},
  clearModal: () => {},
  fetchCompanyCerts: async () => {},
  companyCerts: [],
  fetchHierarchyNodes: async () => {},
  hierarchyNodes: [],
  hierarchyIdNodeMap: new Map(),
  userCertsStats: [],
  getCertsStatsForUserFromServer: async (userId: number) => {},
  setUserCertsStats: (stats: UserCertStatsDto[]) => {},
  unAssignCertFromUserFromServer: async (userId: number, certId: number) => {},
  assignCertToUserToServer: async (userId: number, certId: number) => {},
  isUserCertsStatsLoading: false,
  setIsUserCertsStatsLoading: (val: boolean) => {},
  userModalOpen: UserModalOpen.NONE,
  setUserModalOpen: (val: UserModalOpen) => {},
  selectedUser: undefined,
  setSelectedUser: (user: SelfServeUserDto | undefined) => {},

  downloadCSVUsers: (
    usersPerPage: number,
    currentPage: number,
    sortBy?: string,
    sortDir?: string,
    searchFilter?: string,
    certsFilter?: CertDto[],
    completionStatusesFilter?: CompletionState[],
    hierarchyOnly?: boolean,
    nodesFilter?: NodeDto[]
  ) => {},
  certsFilter: [],
  completionStatusFilter: [],
  setCertsFilter: (filter: CertDto[]) => {},
  setCompletionStatusFilter: (filter: CompletionState[]) => {},
} as any);

export const ReachUsersContextProvider: React.FC<{
  children: ReactNode;
}> = (props) => {
  const [reachUsersIsLoading, setReachUsersIsLoading] =
    useState<boolean>(false);
  const [inviteCodesForReach, setInviteCodesForReach] = useState<
    InviteCodeForReachDto[]
  >([]);

  const [jobResult, setJobResult] = useState<EligibilityJobResult>();
  const [searchFilterStore, setSearchFilterStore] = useState<string>("");
  const [certsFilter, setCertsFilter] = useState<CertDto[]>([]);
  const [nodesFilter, setNodesFilter] = useState<NodeDto[]>([]);
  const [completionStatusFilter, setCompletionStatusFilter] = useState<
    CompletionState[]
  >([]);
  const [jobsStore, setJobsStore] = useState<EligibilityFileJobDto[]>([]);
  const { self, pusherClient } = useContext<IGlobalProvider>(GlobalContext);
  const [refreshData, setRefreshData] = useState("");
  const [pendingJob, setPendingJob] = useState<
    EligibilityFileJobDto | undefined
  >();
  const [reachUsers, setReachUsers] = useState<GetSelfServeUsersDto>({
    data: [],
    meta: {
      total: 0,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      first_page: 1,
    },
  });
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [companyCerts, setCompanyCerts] = useState<CertDto[]>([]);
  const [hierarchyNodes, setHierarchyNodes] = useState<NodeDto[]>([]);
  const [hierarchyIdNodeMap, setHierarchyIdNodeMap] = useState<
    Map<number, NodeDto>
  >(new Map());
  const [userCertsStats, setUserCertsStats] = useState<UserCertStatsDto[]>([]);
  const [isUserCertsStatsLoading, setIsUserCertsStatsLoading] =
    useState<boolean>(false);
  const [userModalOpen, setUserModalOpen] = useState<UserModalOpen>(
    UserModalOpen.NONE
  );
  const [selectedUser, setSelectedUser] = useState<
    SelfServeUserDto | undefined
  >(undefined);

  const [updatePasswordModal, setUpdatePasswordModal] =
    useState<boolean>(false);
  const [passwordResetCodeLink, setPasswordResetCodeLink] =
    useState<string>("");
  const [passwordResetUser, setPasswordResetUser] = useState<string>("");
  const [hierarchyOnly, setHierarchyOnly] = useState<boolean>(false);

  const triggerDataRefresh = () => {
    setRefreshData(DateTime.now().toString());
  };
  useEffect(() => {
    // subscribe
    if (!!pusherClient && !!self) {
      const channelName = `company-${self.companyId}`;
      const eventName = "reach-users-updated";
      return onPusherEvent(
        pusherClient,
        eventName,
        [pusherClient.subscribe(channelName)],
        (data: any, channelName: string) => {
          if (data.job?.errors?.length > 0) {
            setJobResult(EligibilityJobResult.ERROR);
          } else if (data.job?.isFinished) {
            setJobResult(EligibilityJobResult.SUCCESS);
          }
          triggerDataRefresh();
        }
      );
    }
  }, [pusherClient, self]);

  const fetchJobsHandler = useCallback(async () => {
    const response = await getEligibilityJobs(getKey());
    setJobsStore(response.data.data);
  }, []);

  const uploadEligibilityFileHandler = useCallback(
    async (
      file: any,
      importType: EligibilityImportType,
      certAssignments: number[],
      timezone: string
    ): Promise<any> => {
      const response = await uploadEligibilityFileCsv(
        file,
        importType,
        timezone,
        certAssignments,
        getKey()
      );
      setPendingJob(response.data);
      return response.data;
    },
    []
  );

  const confirmEligibilityFileJobHandler = useCallback(
    async (jobId: number): Promise<void> => {
      setJobResult(undefined);
      await confirmEligibilityFileJob(jobId, getKey());
    },
    []
  );

  const upsertReachUserToServer = useCallback(
    async (
      updateSelfServeUserDto: UpdateSelfServeUserDto,
      certAssignments?: CertDto[]
    ): Promise<SelfServeUserDto> => {
      const response = await upsertReachUser(getKey(), updateSelfServeUserDto);
      setSelectedUser(response.data);
      if (certAssignments) {
        certAssignments.forEach((cert) => {
          assignCertToUserToServer(response.data.id, cert.id);
        });
      }
      triggerDataRefresh();
      return response.data;
    },
    []
  );

  const deleteReachUserFromServer = useCallback(
    async (userId: number): Promise<void> => {
      const response = await deleteReachUser(getKey(), userId);
      return response.data;
    },
    []
  );

  const clearBulkModalHandler = useCallback(() => {
    setPendingJob(undefined);
    setIsUploadingFile(false);
  }, []);

  const fetchUsersAndInviteCodes = useCallback(
    async (
      usersPerPage: number,
      currentPage: number,
      sortBy?: string,
      sortDir?: string,
      searchFilter?: string,
      certsFilter?: CertDto[],
      completionStatusesFilter?: CompletionState[],
      nodesFilter?: NodeDto[]
    ): Promise<void> => {
      setReachUsersIsLoading(true);
      const filters = {
        token: getKey(),
        usersPerPage: usersPerPage,
        page: currentPage,
        sortBy: sortBy,
        sortDir: sortDir,
        searchFilter: searchFilter,
        certsFilter: certsFilter?.map((cert) => cert.id),
        completionStatusesFilter: completionStatusesFilter,
        unassociatedUsers: nodesFilter?.some((node) => node.id === -1),
        nodesFilter: nodesFilter
          ?.map((node) => node.id)
          .filter((id) => id !== -1),
      };
      const res = await getReachUsers(filters);
      const paginatedUsers = res.data;
      const inviteCodesResponse = await getCompanyInviteCodesForReach(getKey());
      const inviteCodes = inviteCodesResponse.data;
      paginatedUsers.data.forEach((user) => {
        user.inviteCode = inviteCodes.find((ic) => ic.id === user.inviteCodeId)
          ?.code as string;
      });
      setInviteCodesForReach(inviteCodes);
      setReachUsers(paginatedUsers);
      setReachUsersIsLoading(false);
    },
    []
  );

  const setSearchFilterHandler = (filter: string) => {
    setSearchFilterStore(filter);
  };

  const fetchCompanyCerts = useCallback(async () => {
    if (!self) return;
    const response = await getCompanyCertsForReach(self.companyId, getKey());
    setCompanyCerts(response.data);
  }, []);

  const fetchHierarchyNodes = useCallback(async () => {
    if (!self) return;
    const response = await getFilterableHierarchy(getKey());
    setHierarchyNodes(response.data);

    const map = new Map<number, NodeDto>();
    response.data.forEach((node) => {
      map.set(node.id, node);
    });
    setHierarchyIdNodeMap(map);
  }, []);

  const getCertsStatsForUserFromServer = useCallback(async (userId: number) => {
    const response = await getCertsStatsForUser(userId, getKey());
    setUserCertsStats(response.data);
  }, []);

  const unAssignCertFromUserFromServer = useCallback(
    async (userId: number, certId: number) => {
      await unAssignCertFromUser(userId, certId, getKey());
      await getCertsStatsForUserFromServer(userId);
    },
    []
  );

  const assignCertToUserToServer = useCallback(
    async (userId: number, certId: number) => {
      await assignCertToUser(userId, certId, getKey());
      await getCertsStatsForUserFromServer(userId);
    },
    []
  );

  const downloadCSVUsers = useCallback(
    async (
      usersPerPage: number,
      currentPage: number,
      sortBy?: string,
      sortDir?: string,
      searchFilter?: string,
      certsFilter?: CertDto[],
      completionStatusesFilter?: CompletionState[],
      hierarchyOnly?: boolean,
      nodesFilter?: NodeDto[]
    ): Promise<GetSelfServeUsersDto> => {
      setReachUsersIsLoading(true);
      const filters = {
        token: getKey(),
        usersPerPage: usersPerPage,
        page: currentPage,
        sortBy: sortBy,
        sortDir: sortDir,
        searchFilter: searchFilter,
        certsFilter: certsFilter?.map((cert) => cert.id),
        completionStatusesFilter: completionStatusesFilter,
        fetchForCsv: true,
        hierarchyOnly: hierarchyOnly,
        nodesFilter: nodesFilter?.map((node) => node.id),
      };
      const res = await getReachUsers(filters);
      const users = res.data;
      setReachUsersIsLoading(false);
      return Promise.resolve(users);
    },
    []
  );

  const handleCertSelectionsChange = useCallback(
    (certs: HvSelectOption[]) => {
      const certIds = certs.map((cert) => cert.value);
      const selectedCerts = companyCerts.filter((cert) =>
        certIds.includes(cert.id)
      );
      setCertsFilter(selectedCerts);
      track(REACH_EVENTS.ReachUsersSearchUsers);
    },
    [companyCerts]
  );

  const handleNodeSelectionsChange = useCallback(
    (nodes: HvSelectOption[]) => {
      const nodeIds = nodes.map((node) => node.value);
      const selectedNodes = hierarchyNodes.filter((node) =>
        nodeIds.includes(node.id)
      );
      // create a dummy node filter
      if (nodeIds.includes(-1)) {
        selectedNodes.push({ id: -1, name: HVLocalizeStrings.REACH_HIERARCHY_UNASSOCIATED });
      }
      setNodesFilter(selectedNodes);
      track(REACH_EVENTS.ReachUsersSearchUsers);
    },
    [hierarchyNodes]
  );

  const handleCompletionStatusSelectionsChange = useCallback(
    (completionStatuses: HvSelectOption[]) => {
      const statusIds = completionStatuses.map(
        (completionStatus) => completionStatus.value
      );
      const allStatuses = Object.keys(CompletionState)
        .filter((key) => isNaN(Number(key)))
        .map((key) => CompletionState[key as keyof typeof CompletionState]);
      const selectedStatuses = allStatuses.filter((status) =>
        statusIds.includes(status)
      );
      setCompletionStatusFilter(selectedStatuses);
      track(REACH_EVENTS.ReachUsersSearchUsers);
    },
    []
  );

  const jobsState: EligibilityJobsState = {
    jobs: jobsStore,
    fetchJobs: fetchJobsHandler,
    dataRefreshTimestamp: refreshData,
    triggerDataRefresh: triggerDataRefresh,
    setSearchFilter: setSearchFilterHandler,
    searchFilter: searchFilterStore,
    uploadEligibilityFile: uploadEligibilityFileHandler,
    reachUsersIsLoading: reachUsersIsLoading,
    reachUsers: reachUsers,
    getReachUsersAndCompanyInviteCodesFromServer: fetchUsersAndInviteCodes,
    inviteCodesForReach: inviteCodesForReach,
    upsertReachUserToServer: upsertReachUserToServer,
    deleteReachUserFromServer: deleteReachUserFromServer,
    isUploadingFile: isUploadingFile,
    setIsUploadingFile: setIsUploadingFile,
    confirmEligibilityFileJob: confirmEligibilityFileJobHandler,
    pendingJob,
    clearBulkModal: clearBulkModalHandler,
    jobResult,
    fetchCompanyCerts,
    companyCerts,
    fetchHierarchyNodes,
    hierarchyNodes,
    hierarchyIdNodeMap,
    userCertsStats,
    getCertsStatsForUserFromServer,
    setUserCertsStats,
    unAssignCertFromUserFromServer,
    assignCertToUserToServer,
    isUserCertsStatsLoading,
    setIsUserCertsStatsLoading,
    userModalOpen,
    setUserModalOpen,
    selectedUser,
    setSelectedUser,
    downloadCSVUsers: downloadCSVUsers,
    certsFilter: certsFilter,
    nodesFilter: nodesFilter,
    completionStatusFilter: completionStatusFilter,
    setCertsFilter: setCertsFilter,
    setNodesFilter: setNodesFilter,
    setCompletionStatusFilter: setCompletionStatusFilter,
    handleCertSelectionsChange: handleCertSelectionsChange,
    handleNodeSelectionsChange: handleNodeSelectionsChange,
    handleCompletionStatusSelectionsChange:
      handleCompletionStatusSelectionsChange,
    updatePasswordModal,
    setUpdatePasswordModal,
    passwordResetCodeLink,
    setPasswordResetCodeLink,
    passwordResetUser,
    setPasswordResetUser,
    hierarchyOnly,
    setHierarchyOnly,
  };
  return (
    <ReachUsersContext.Provider value={jobsState}>
      {props.children}
    </ReachUsersContext.Provider>
  );
};
