import axios, { AxiosInstance } from "axios";

import { translateArray, translateObj } from "../Utils/ObjectUtil";
import { getRefreshToken } from "./People/AccessTokenApi";
import {
  setLocalStorageAuthTokens,
  isRefreshTokenInCookie,
} from "../Utils/LoginUtil";
import { loginPath } from "../Utils/NavigationUtils";
import { LocalStorageKeys, LocalStorageWrapper } from "../Utils/StorageUtil";

export const AxiosClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

AxiosClient.interceptors.request.use(function (config) {
  if (process.env.REACT_APP_DEBUG) {
    console.debug(
      new Date(),
      config.method?.toUpperCase(),
      config.url,
      config.data
    );
  }
  return config;
});

AxiosClient.interceptors.response.use(
  function (response) {
    if (response.data) {
      const data = response.data;
      if (typeof data === "object") {
        translateObj(data);
      } else if (Array.isArray(data)) {
        translateArray(data);
      }
    }
    return response;
  },
  async function (error) {
    let originalRequest = error.config;
    if (error.response?.data?.message === "Invalid refresh token") {
      /* A new refresh token could have been generated in another API call just before this (race condition),
        which caused getting the token by refresh token to fail, so ignore the refresh error.
        We can handle getting the new refresh token from local storage below when we retry the original request
          (this request we are ignoring was to get a new access token, but we already did that in another call which is why this refresh token was invalid).
      */

      return Promise.resolve("Invalid refresh token");
    }
    if (!originalRequest._retry) {
      let dt: string | null | undefined =
        originalRequest?.headers?.Authorization?.replace("Bearer ", "");
      const refreshToken = await LocalStorageWrapper.getItem(
        LocalStorageKeys.REFRESH_TOKEN
      );
      if (
        error.response?.status === 401 &&
        dt &&
        (isRefreshTokenInCookie() || refreshToken)
      ) {
        // Retry if there is a 401 error when already logged in, so the user can get a new access token if it expired
        originalRequest._retry = true;
        originalRequest = await getNewToken(
          originalRequest,
          error,
          refreshToken,
          dt
        );
        return AxiosClient(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

const getNewToken = async (
  originalRequest: any,
  error: any,
  refreshToken: string | null,
  badToken: string
): Promise<any> => {
  let dt: string | null | undefined = localStorage.getItem("dt");
  if ((isRefreshTokenInCookie() || refreshToken) && dt) {
    const result = await getRefreshToken({
      refresh_token: refreshToken as string,
      access_token: dt,
    });
    if (typeof result !== "string") {
      if (result?.data?.access_token) {
        dt = result.data.access_token;
        await setLocalStorageAuthTokens(result.data);
        if (originalRequest?.headers?.Authorization) {
          originalRequest.headers.Authorization = `Bearer ${dt}`;
        }
      }
      if (result.data.errorCode) {
        window.onbeforeunload = () => {};
        await setLocalStorageAuthTokens();
        window.location.replace(loginPath());
        return Promise.reject(error);
      }
    } else if (result === "Invalid refresh token") {
      // Refresh token failed on server side, high chance
      // that new token is on the way back.  Let's keep
      // checking for 9 seconds to see if the access token
      // changed.  This should check 90 times, 100 milli
      // in between
      let dt = localStorage.getItem("dt");
      let counter = 0;
      while (dt === badToken && counter < 90) {
        await delay(100);
        dt = localStorage.getItem("dt");
        counter++;
      }
      originalRequest.headers.Authorization = `Bearer ${dt}`;
    }
    return originalRequest;
  } else {
    window.onbeforeunload = () => {};
    await setLocalStorageAuthTokens();
    window.location.replace(loginPath());
    return Promise.reject(error);
  }
};

const delay = (millis: number) => {
  return new Promise((resolve) => setTimeout(resolve, millis));
};
