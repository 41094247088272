import { HVLocalizeStrings } from "../Localization/HVLocalizeStrings";

interface IToolObj {
  image: string;
  icon: string;
  path: string;
  buttonText: { use: string; explore: string };
}

export enum ToolType {
  Calming = 2,
  Thinking = 3,
  Mindfulness = 4,
  Focus = 5,
  Optimism = 6,
}

export const getToolInfo = (toolId: ToolType): IToolObj | undefined => {
  switch (toolId) {
    case ToolType.Calming:
      return {
        image:
          "https://cdn.headversity.com/app/resources/headerImages/tool-header-calming.jpg",
        icon: "https://cdn.headversity.com/app/resources/toolIcons/calming.svg",
        path: "/tools/calming",
        buttonText: {
          use: HVLocalizeStrings.USE_CALMING_TOOL,
          explore: HVLocalizeStrings.EXPLORE_CALMING_TOOL,
        },
      };
    case ToolType.Thinking:
      return {
        image:
          "https://cdn.headversity.com/app/resources/headerImages/tool-header-thinking.jpg",
        icon: "https://cdn.headversity.com/app/resources/toolIcons/thinking.svg",
        path: "/tools/thinking",
        buttonText: {
          use: HVLocalizeStrings.USE_THINKING_TOOL,
          explore: HVLocalizeStrings.EXPLORE_THINKING_TOOL,
        },
      };
    case ToolType.Mindfulness:
      return {
        image:
          "https://cdn.headversity.com/app/resources/headerImages/tool-header-mindfulness.jpg",
        icon: "https://cdn.headversity.com/app/resources/toolIcons/mindfulness.svg",
        path: "/tools/mindfulness",
        buttonText: {
          use: HVLocalizeStrings.USE_MINDFULNESS_TOOL,
          explore: HVLocalizeStrings.EXPLORE_MINDFULNESS_TOOL,
        },
      };
    case ToolType.Focus:
      return {
        image:
          "https://cdn.headversity.com/app/resources/headerImages/tool-header-focus.jpg",
        icon: "https://cdn.headversity.com/app/resources/toolIcons/focus.svg",
        path: "/tools/focus",
        buttonText: {
          use: HVLocalizeStrings.USE_FOCUS_TOOL,
          explore: HVLocalizeStrings.EXPLORE_FOCUS_TOOL,
        },
      };
    case ToolType.Optimism:
      return {
        image:
          "https://cdn.headversity.com/app/resources/headerImages/tool-header-optimism.jpg",
        icon: "https://cdn.headversity.com/app/resources/toolIcons/optimism.svg",
        path: "/tools/optimism",
        buttonText: {
          use: HVLocalizeStrings.USE_OPTIMISM_TOOL,
          explore: HVLocalizeStrings.EXPLORE_OPTIMISM_TOOL,
        },
      };
  }

  return undefined;
};
