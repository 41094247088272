import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  SlideFade,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { MicroLessonDto, NanoPracticeDto } from "@headversity/contract";
import { GoalDto } from "@headversity/contract/Dto/GoalDto";
import { useContext, useEffect, useState } from "react";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, TrainingType } from "../../../State/GlobalContext";
import {
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../../State/ShellContext";
import { Border_Radius } from "../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../Utils/Analytics";
import { getFirstNameFromSelf } from "../../../Utils/LoginUtil";
import { getBgImageSource, getStreakInfo } from "../../../Utils/SkillsUtil";
import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { LargeModal } from "../../Common/LargeModal";
import { ActivityHistoryModal } from "../../Shared/ActivitiyHistory/ActivityHistoryModal";
import { StepStartTraining } from "../../Shared/Welcome/StepStartTraining";
import { HVTestId } from "../../../Testing/dataTestIds";

export const GoalBox = () => {
  const {
    self,
    goals,
    selectedGoal,
    openNextActivity,
    nanoPracticeModalOpen,
    isLessonPreviewOpen,
    isUninterruptibleModalOpen,
    activityCompletionDates,
    schedulerReminders,
    userSkillStats,
  } = useContext(GlobalContext);
  const { theme, setIsGoalCheckInNewPursuitModalOpen } =
    useContext(ShellContext);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const [goalToShow, setGoalToShow] = useState<GoalDto>();
  const [fadeIn, setFadeIn] = useState(false);

  const [showStartTraining, setShowStartTraining] = useState(false);
  const [selectedTrainingType, setSelectedTrainingType] =
    useState<TrainingType>(TrainingType.Practice);
  const [selectedPractice, setSelectedPractice] = useState<NanoPracticeDto>();
  const [selectedLesson, setSelectedLesson] = useState<MicroLessonDto>();

  const [activityHistoryModalOpen, setActivityHistoryModalOpen] =
    useState(false);

  useEffect(() => {
    setFadeIn(false);
    setTimeout(() => {
      setFadeIn(true);
      setGoalToShow(selectedGoal);
    }, 400);
  }, [selectedGoal]);

  useEffect(() => {
    if (nanoPracticeModalOpen || isLessonPreviewOpen) {
      setShowStartTraining(false);
    }
  }, [nanoPracticeModalOpen, isLessonPreviewOpen]);

  const goalToUse = goalToShow ? goalToShow : goals[0];

  const streakInfo = getStreakInfo(activityCompletionDates, schedulerReminders);

  const hasDoneSomethingInGoal =
    selectedGoal &&
    userSkillStats.some(
      (x) => selectedGoal.skillIds.includes(x.skillId) && x.points > 0
    );

  return (
    <>
      <BlockSection
        fadeIn={true}
        columns={1}
        title={<BlockSectionTitle title={HVLocalizeStrings.YOUR_PURSUIT} />}
      >
        <Box>
          <SlideFade className="goal-box" in={fadeIn}>
            <Button
              className="button-block"
              boxShadow={"lg"}
              onClick={() => {
                if (!goalToShow) {
                  setIsGoalCheckInNewPursuitModalOpen(true);
                } else {
                  track(EVENTS.StartTrainingShown, {
                    HV_From: "GoalBox",
                  });
                  setShowStartTraining(true);
                }
              }}
              data-testid={HVTestId.GoalBox.goalBox}
            >
              {theme.name !== NOSTALGIC_THEME_NAME && (
                <Box
                  pos="absolute"
                  width="100%"
                  height="100%"
                  bgColor={"#ffffff33"}
                  left="0"
                  top="0"
                />
              )}
              <Box
                background={`url(${getBgImageSource(
                  goalToUse.imageUrl.toString(),
                  !isDesktop // actually want the inverse here, since desktop is super-wide
                )})`}
                bgSize={"cover"}
                py={isDesktop ? "20px" : "30px"}
                px={"20px"}
                borderRadius={Border_Radius}
                textAlign="left"
                color={"white"}
              >
                {!selectedGoal && (
                  <Box pos="absolute" top="0" right="15px" fontSize={"24px"}>
                    ...
                  </Box>
                )}

                <Flex flex="75px 0" gap="15px" alignItems="center">
                  <Box>
                    <Image
                      src={`${getBgImageSource(
                        goalToUse.imageUrl.toString(),
                        true,
                        true
                      )}`}
                      boxShadow={"lg"}
                      border="solid 1px #ffffff66"
                      h={"75px"}
                      w={"75px"}
                      borderRadius="50%"
                      animation={
                        isUninterruptibleModalOpen
                          ? undefined
                          : "brightness 8s infinite"
                      }
                      filter={
                        isUninterruptibleModalOpen
                          ? "brightness(1.5)"
                          : undefined
                      }
                      opacity=".9"
                    />
                  </Box>

                  <Box flex="1">
                    {goalToShow ? (
                      <Flex alignItems={"center"} gap="5px">
                        <Box flex="1" width="100%" mr="5px">
                          <Text
                            lineHeight="1.2"
                            fontSize={"20px"}
                            fontWeight="600"
                            mb={window.innerWidth >= 568 ? 4 : 0}
                          >
                            {streakInfo.length > 0 && streakInfo.isIncomplete
                              ? HVLocalizeStrings.STREAK_KEEP_GOING
                              : window.innerWidth < 568
                              ? `${
                                  HVLocalizeStrings.WELCOME_BACK
                                }${getFirstNameFromSelf(self)}!`
                              : HVLocalizeStrings.PURSUIT_CONTINUE}
                          </Text>
                          {isDesktop && (
                            <Text lineHeight="1.2" fontSize={"16px"} mt={1}>
                              {HVLocalizeStrings.PURSUIT_PICKUP}{" "}
                              <b>{selectedGoal?.name.toString()}</b>.
                            </Text>
                          )}
                        </Box>
                        {isDesktop && (
                          <Box opacity={0.8} height={"100%"} width={"32px"}>
                            <Icon
                              boxSize={"32px"}
                              as={LiaLongArrowAltRightSolid}
                            />
                          </Box>
                        )}
                      </Flex>
                    ) : (
                      <>
                        <Text lineHeight="1.2" fontSize={"16px"}>
                          <b style={{ fontWeight: 400 }}>
                            {HVLocalizeStrings.PURSUIT_SELECT}
                          </b>
                        </Text>
                        <Text lineHeight="1.2" mt={1} fontSize={"20px"}>
                          <b style={{ fontWeight: 600 }}>
                            {HVLocalizeStrings.PURSUIT_FOCUS}
                          </b>
                        </Text>
                      </>
                    )}
                  </Box>
                </Flex>
                {!isDesktop && (
                  <Flex mt={4}>
                    <Text
                      lineHeight="1.2"
                      fontSize={"15px"}
                      mt={1}
                      mr={4}
                      w="100%"
                    >
                      {HVLocalizeStrings.PURSUIT_PICKUP}
                      <br />
                      <b>{selectedGoal?.name.toString()}</b>.
                    </Text>

                    <Flex textAlign="right" mb="-10px" w="50px">
                      <Box
                        ml="auto"
                        opacity={0.8}
                        height={"100%"}
                        width={"38px"}
                      >
                        <Icon boxSize={"38px"} as={LiaLongArrowAltRightSolid} />
                      </Box>
                    </Flex>
                  </Flex>
                )}
              </Box>
            </Button>
          </SlideFade>
        </Box>
      </BlockSection>

      {showStartTraining && selectedGoal && (
        <LargeModal
          open={showStartTraining}
          setOpen={() => {
            setShowStartTraining(false);
          }}
          forceDarkMode={true}
          bgImage={getBgImageSource(
            selectedGoal?.imageUrl.toString(),
            isDesktop
          )}
        >
          <Box h="575px">
            <StepStartTraining
              selectedTrainingType={selectedTrainingType}
              setSelectedTrainingType={setSelectedTrainingType}
              selectedPractice={selectedPractice}
              setSelectedPractice={setSelectedPractice}
              selectedLesson={selectedLesson}
              setSelectedLesson={setSelectedLesson}
              isPostWelcome={true}
            />
          </Box>

          <BottomActionBar
            nextButtonText={HVLocalizeStrings.START_TRAINING}
            onNextButtonClick={() => {
              openNextActivity(
                selectedTrainingType,
                selectedPractice,
                selectedLesson
              );
              setShowStartTraining(false);
            }}
            actionAreaExtra={
              hasDoneSomethingInGoal ? (
                <Link
                  textDecoration={"underline"}
                  fontSize="14px"
                  onClick={(e) => {
                    track(EVENTS.ActivityHistoryOpened, {
                      HV_From: "GoalBox",
                    });

                    setActivityHistoryModalOpen(true);
                  }}
                >
                  {HVLocalizeStrings.VIEW_HISTORY}
                </Link>
              ) : undefined
            }
          />
        </LargeModal>
      )}

      {activityHistoryModalOpen && (
        <ActivityHistoryModal
          open={activityHistoryModalOpen}
          setOpen={setActivityHistoryModalOpen}
        />
      )}
    </>
  );
};
