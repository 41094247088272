import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import {
  Border_Radius,
  CALENDAR_BACKGROUND_COLOR,
  CARD_BACKGROUND_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";

interface CalendarProps {
  year?: number;
  month?: number;
  width?: string;
  height?: string;
  dateRenderCallBack?: (calendarItem: any) => JSX.Element;
  getBackgroundColor?: (weekday: string) => string;
}

export const Calendar = (props: CalendarProps) => {
  const { year, month, width, height, getBackgroundColor, dateRenderCallBack } =
    props;
  const [currentYearMonth, setCurrentYearMonth] = useState<any>({
    year: dayjs().year(),
    month: dayjs().month(),
  });

  const [dateString, setDateString] = useState<string>("s");

  const [dates, setDates] = useState<any[]>([]);
  const weekdays = [
    HVLocalizeStrings.SUNDAY,
    HVLocalizeStrings.MONDAY,
    HVLocalizeStrings.TUESDAY,
    HVLocalizeStrings.WEDNESDAY,
    HVLocalizeStrings.THURSDAY,
    HVLocalizeStrings.FRIDAY,
    HVLocalizeStrings.SATURDAY,
  ];

  useEffect(() => {
    if (year && month) {
      setCurrentYearMonth({ year: year, month: month });
    }
  }, [year, month]);

  useEffect(() => {
    let date = dayjs(new Date(currentYearMonth.year, currentYearMonth.month));
    setDateString(date.format("MMMM, YYYY"));
    while (date.day() > 0) {
      date = date.add(-1, "day");
    }
    const dateArr: any[] = [];
    for (let i = 0; i < 35; i++) {
      dateArr.push({
        date: date.date(),
        month: date.month(),
        year: date.year(),
        key: date.toISOString(),
        dayJsObj: date,
      });
      date = date.add(1, "day");
    }
    setDates(dateArr);
  }, [currentYearMonth]);

  return (
    <SimpleGrid
      bg={CARD_BACKGROUND_COLOR}
      w={width ?? "100%"}
      h={height ?? "100%"}
      borderRadius={Border_Radius}
      columns={7}
      m={"3"}
      py={"3"}
      boxShadow={"lg"}
    >
      <Stack
        align={"center"}
        justify={"center"}
        minH={"60px"}
        gridColumn={"span 1 / span 1"}
      >
        <Button
          onClick={() => {
            let monthResult = currentYearMonth.month - 1;
            let yearResult = currentYearMonth.year;
            if (monthResult < 0) {
              monthResult = 11;
              yearResult = yearResult - 1;
            }
            setCurrentYearMonth({ year: yearResult, month: monthResult });
          }}
          className="button-link"
        >
          <Icon h={"30px"} w={"30px"} as={IoChevronBackOutline} />
        </Button>
      </Stack>
      <Stack
        align={"center"}
        justify={"center"}
        h={"60px"}
        gridColumn={"span 5 / span 5"}
      >
        <Heading fontSize={"lg"} fontWeight={"semibold"}>
          {dateString}
        </Heading>
      </Stack>
      <Stack
        align={"center"}
        justify={"center"}
        h={"60px"}
        gridColumn={"span 1 / span 1"}
      >
        <Button
          onClick={() => {
            let monthResult = currentYearMonth.month + 1;
            let yearResult = currentYearMonth.year;
            if (monthResult > 11) {
              monthResult = 0;
              yearResult = yearResult + 1;
            }
            setCurrentYearMonth({ year: yearResult, month: monthResult });
          }}
          className="button-link"
        >
          <Icon h={"30px"} w={"30px"} as={IoChevronForwardOutline} />
        </Button>
      </Stack>
      {weekdays.map((item) => {
        return (
          <Box
            key={item}
            p={"2"}
            gridColumn={"span 1 / span 1"}
            mb={"1"}
            minW={"60px"}
            textAlign={"center"}
          >
            {item}
          </Box>
        );
      })}

      {dates?.map((item) => {
        return (
          <Box
            key={item.key}
            gridColumn={"span 1 / span 1"}
            minH={"20px"}
            minW={"50px"}
            bg={getBackgroundColor ? getBackgroundColor(item) : ""}
          >
            {(dateRenderCallBack && dateRenderCallBack(item)) ?? (
              <Button className="button-link" textDecoration={"none"}>
                <Box w={"100%"} h={"100%"} border={"solid 1px black"}>
                  {item.date}
                </Box>
              </Button>
            )}
          </Box>
        );
      })}
    </SimpleGrid>
  );
};
