import {
  Border_Radius,
  DISABLED_BORDER,
} from "../../../Styles/HeadversityStyle";
import { Box, Divider, Flex, useBreakpointValue } from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";

interface MyOrganizationSectionPreviewProps {
  title: string;
  body: JSX.Element;
  buttonText: string;
  buttonClick: () => void;
}

const MyOrganizationSectionPreview = (
  props: MyOrganizationSectionPreviewProps
) => {
  const { title, body, buttonText, buttonClick } = props;
  const isDesktop = useBreakpointValue({ base: false, lg: true }) ?? false;
  return (
    <Box
      bgColor={"white"}
      w={"100%"}
      border={DISABLED_BORDER}
      borderRadius={Border_Radius}
      boxShadow={"lg"}
      mb={6}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Box fontWeight={"semibold"} p={4}>
          {title}
        </Box>

        {isDesktop && (
          <AnimatedButton
            colorSet={AnimatedButtonColorSet.Third}
            text={buttonText}
            onClick={buttonClick}
            boxShadow={undefined}
            w={222}
            m={4}
          />
        )}
      </Flex>
      {body && (
        <>
          <Divider></Divider>
          <Box m={4} bgColor={"white"} borderRadius={Border_Radius}>
            {body}
          </Box>
        </>
      )}
      {!isDesktop && (
        <Flex justifyContent="center">
          <AnimatedButton
            colorSet={AnimatedButtonColorSet.Third}
            text={buttonText}
            onClick={buttonClick}
            boxShadow={undefined}
            w={222}
            mt={4}
            mb={!isDesktop ? 4 : 0}
          />
        </Flex>
      )}
    </Box>
  );
};

export default MyOrganizationSectionPreview;
