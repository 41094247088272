import { Box, Button, Flex, Text } from "@chakra-ui/react";
import {
  Error,
  SECONDARY_TEXT_COLOR,
  Success,
} from "../../Styles/HeadversityStyle";
import { MdCancel, MdDone } from "react-icons/md";
import { HVTestId } from "../../Testing/dataTestIds";
import { useState } from "react";

interface ToastProps {
  id: string;
  message: any;
  color?: string;
  border?: string;
  bg?: string;
  noPadding?: boolean;
}

export const Toast = (props: ToastProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  return (
    <Box
      filter="drop-shadow(2px 4px 4px #00000044);"
      p={2}
      pt={3}
      width={"100%"}
      bg={props.bg}
      bgColor={"white"}
      bgSize="cover"
      borderRadius="5px"
      border={props.border ?? `1px solid #ccc`}
      borderLeft={
        props.border
          ? undefined
          : props.color
          ? `15px solid ${props.color}`
          : `1px solid #ccc`
      }
      data-testid={HVTestId.Toast.box}
      display={isHidden ? "none" : undefined}
      pos="relative"
    >
      <Box pos="absolute" right="1" top="1">
        <Button
          bg={"none"}
          onClick={() => {
            setIsHidden(true);
          }}
          size="sm"
        >
          X
        </Button>
      </Box>
      <Flex alignItems={"center"}>
        {(props.color === Error || props.color === Success) && (
          <Box>
            {props.color === Error && (
              <MdCancel color={props.color ?? Error} size={"32px"} />
            )}
            {props.color === Success && (
              <MdDone color={props.color ?? Success} size={"32px"} />
            )}
          </Box>
        )}
        <Box
          mx="5px"
          my="10px"
          color={SECONDARY_TEXT_COLOR}
          fontSize="14px"
          p={props.noPadding ? 0 : 3}
          pt={3} // always need room for close button
        >
          {/* allow caller to pass in html string or react fragment */}
          {typeof props.message === "string" ? (
            <Text
              dangerouslySetInnerHTML={{
                __html: props.message,
              }}
            />
          ) : (
            <>{props.message}</>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
