import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import { buildDateTimeLabelFormats } from "../../../Utils/ChartsUtil";
import { Box, Link, useBreakpointValue } from "@chakra-ui/react";
import { GoalDto, SkillDto, UserScoreDto } from "@headversity/contract";
import _ from "lodash";
import { GlobalContext } from "../../../State/GlobalContext";
import { Border_Radius } from "../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

interface Props {
  goal: GoalDto;
  scoresBySkillId: _.Dictionary<UserScoreDto[]>;
  onToggleRange?: (isAllTime: boolean) => void;
}

export const GoalCheckInChart = ({
  goal,
  scoresBySkillId,
  onToggleRange,
}: Props) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const { skills } = useContext(GlobalContext);
  const [lineChartOptions, setLineChartOptions] = useState<any>({});
  const [isViewOverTime, setIsViewOverTime] = useState(false);
  const [isAllTime, setIsAllTime] = useState(true);
  const [reload, setReload] = useState(false);
  const [lastParams, setLastParams] = useState<any>();

  const isChartDataUnchanged = () => {
    let isSame = true;

    // check that each item in scoresBySkillId is that same as in lastParams
    for (const sId in scoresBySkillId) {
      if (
        !lastParams.scoresBySkillId[sId] ||
        scoresBySkillId[sId].length !== lastParams.scoresBySkillId[sId].length
      ) {
        isSame = false;
        break;
      }

      for (let i = 0; i < scoresBySkillId[sId].length; i++) {
        if (
          scoresBySkillId[sId][i].points !==
            lastParams.scoresBySkillId[sId][i].points ||
          scoresBySkillId[sId][i].day !== lastParams.scoresBySkillId[sId][i].day
        ) {
          isSame = false;
          break;
        }
      }
    }

    return isSame;
  };

  const handleLegendItemClick = (e: any) => {
    const series = e.target.chart.series;

    // first-time singling something out? hide the others
    if (series.every((s: any) => s.visible)) {
      for (const s of series) {
        if (e.target.name !== s.name) {
          s.setVisible(false);
        }
      }
    }
    // toggling the last one? show all
    else if (
      series.filter((s: any) => s.visible).length === 1 &&
      e.target.visible
    ) {
      for (const s of series) {
        s.setVisible(true);
      }
    }
    // toggling partial selection?
    else {
      e.target.setVisible(!e.target.visible);
    }

    e.preventDefault();
  };

  useEffect(() => {
    if (!scoresBySkillId) return;

    // debounce to avoid re-animating chart
    if (
      lastParams &&
      isDesktop === lastParams.isDesktop &&
      isViewOverTime === lastParams.isViewOverTime
    ) {
      if (isChartDataUnchanged()) return;
    }

    setLastParams({ scoresBySkillId, isDesktop, isViewOverTime });

    const skillsByContext = _.groupBy(skills, (s) => s.context);

    const skillsPlotted: SkillDto[] = [];

    // map to series
    const series: any = [];
    const skillNames = [];
    let index = -1;

    for (const sId of goal.skillIds) {
      index++;

      const skill = skills.find((s) => s.id === sId);
      if (!skill) continue;

      // handle case of color for multiple skills in the same context
      const contextUsed =
        skillsPlotted.filter((x) => x.context === skill.context).length > 0;

      const skillToUse =
        skillsByContext[skill.context][
          !contextUsed ? 0 : skillsByContext[skill.context].length - 1
        ];

      const color = `${skillToUse.color}99`;

      skillsPlotted.push(skill);

      // get sum of points
      let data = [];
      if (isViewOverTime) {
        if (scoresBySkillId[sId]) {
          data = scoresBySkillId[sId].map((dp) => [
            dayjs(dp.day).unix() * 1000,
            dp.points,
          ]);
        } else {
          data = [0];
        }
      } else if (scoresBySkillId[sId]) {
        const sum = scoresBySkillId[sId].reduce(
          (acc, dp) => acc + dp.points,
          0
        );

        // when viewing by skill, we need to pad the data array for each of the other skills with 0s
        data.push(0);
        data.push(0);
        data.push(0);
        data.push(0);

        data[index] = sum;
      }

      skillNames.push(skill.name.toString());

      series.push({
        name: skill.name.toString(),
        label: skill.name.toString(),
        data: data,
        color: color,
      });
    }

    setLineChartOptions({
      chart: {
        type: "column",
        height: 260,
        style: {
          fontFamily: "Barlow",
        },
      },
      tooltip: {
        xDateFormat: HVLocalizeStrings.DATE_FORMAT_WEEKDAY,
      },
      xAxis: {
        categories: isViewOverTime ? undefined : skillNames,
        type: isViewOverTime ? "datetime" : undefined,
        dateTimeLabelFormats: buildDateTimeLabelFormats(),
        labels: {
          style: {
            fontSize: "12px",
          },
        },
        tickPixelInterval: isDesktop ? 200 : 100,
      },
      yAxis: {
        title: {
          text: isDesktop ? HVLocalizeStrings.POINTS : undefined,
          margin: isDesktop ? 25 : 15,
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      series: series,
      legend: {
        enabled: isViewOverTime,
        itemStyle: {
          fontSize: "10px",
        },
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
          },
        },
        series: {
          events: {
            legendItemClick: (e: any) => {
              handleLegendItemClick(e);
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
    });
  }, [scoresBySkillId, isDesktop, isViewOverTime]);

  // get the oldest date in scoresBySkillId
  const oldestDate = _.min(
    Object.values(scoresBySkillId).map((x) =>
      _.min(x.map((dp) => dayjs(dp.day)))
    )
  );

  // get the newest date in scoresBySkillId
  const newestDate = _.max(
    Object.values(scoresBySkillId).map((x) =>
      _.max(x.map((dp) => dayjs(dp.day)))
    )
  );

  return reload ? (
    <></>
  ) : (
    <Box bg="white" pt="10px" borderRadius={Border_Radius} pos="relative">
      <Box my="20px" ml="5px" mr={isDesktop ? "10px" : "0px"}>
        <HighchartsReact highcharts={Highcharts} options={lineChartOptions} />
      </Box>
      <Box pos="absolute" top="2" right={isDesktop ? "22px" : "10px"}>
        <Link
          textDecoration={"underline"}
          fontSize={isDesktop ? "12px" : "11px"}
          color="black"
          onClick={() => {
            setIsViewOverTime(!isViewOverTime);

            setReload(true);
            setTimeout(() => {
              setReload(false);
            }, 1);
          }}
        >
          {isViewOverTime
            ? HVLocalizeStrings.VIEW_BY_SKILL
            : HVLocalizeStrings.VIEW_OVER_TIME}
        </Link>

        {(!isAllTime ||
          (oldestDate &&
            oldestDate < dayjs().subtract(30, "days") &&
            newestDate &&
            newestDate > dayjs().subtract(30, "days"))) && (
          <Link
            ml={2}
            textDecoration={"underline"}
            fontSize={isDesktop ? "12px" : "11px"}
            color="black"
            onClick={() => {
              const newVal = !isAllTime;
              setIsAllTime(newVal);
              if (onToggleRange) onToggleRange(newVal);
            }}
          >
            {isAllTime
              ? HVLocalizeStrings.VIEW_LAST_30
              : HVLocalizeStrings.VIEW_ALLTIME}
          </Link>
        )}
      </Box>
    </Box>
  );
};
