export enum TeamLessonUserInstanceStatus {
  Initialized,
  InProgress,
  ActivityInProgress,
  Complete,
  Cancelled,
  ForcedComplete,
  Catchup,
  Incomplete,
  UpNext,
  Scheduled
}

export interface AudioTrackInformation {
  image: string;
  name: string;
  url: string;
  time: string;
  title: string;
  id: number;
}

export enum HttpErrorCodes {
  Forbidden,
  InternalServer,
  NotFound,
  Unauthorized,
}
