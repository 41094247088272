import {
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Icon,
  Image,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HeaderSetter } from "../../Common/HeaderSetter";
import {
  BG_TEAM_BASE,
  BLOCK_SECTION_DIVIDER_BORDER,
  Border_Radius,
  HEADER_TEAM_INSIGHTS_BACKGROUND,
  Light_Blue,
  Mid_Blue,
  PRIMARY_TEXT_COLOR,
  THIRD_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import TeamCommandCard from "../TeamCommandCard";
import { BlockSection } from "../../Common/BlockSection";
import { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { getLearnerInsight } from "../../../Api/Team/LearnerInsightApi";
import { getKey } from "../../../Utils/Helpers";
import { MdGroups } from "react-icons/md";
import { LearnerInsightDto } from "@headversity/contract";

export const InsightsPage = () => {
  const { currentTeam, teamInitializing, getPageTitle } =
    useContext<IGlobalProvider>(GlobalContext);

  const [learnerInsightResult, setLearnerInsightResult] =
    useState<LearnerInsightDto | null>(null);

  const [userCompleted1LessonValue, setUserCompleted1LessonValue] =
    useState<number>(0);

  useEffect(() => {
    if (!learnerInsightResult) {
      return;
    }
    setTimeout(() => {
      setUserCompleted1LessonValue(
        learnerInsightResult.percentageOfUsersThatDidOneLesson
      );
    }, 500);
  }, [learnerInsightResult]);

  useEffect(() => {
    if (currentTeam) {
      getLearnerInsight(currentTeam.id, getKey()).then((response) => {
        setLearnerInsightResult(response.data);
      });
    }
  }, [currentTeam]);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.TEAM_INSIGHT_TITLE} - ${HVLocalizeStrings.TEAM_HOME_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>

      <HeaderSetter
        title={<>{HVLocalizeStrings.TEAM_INSIGHT_TITLE}</>}
        description={HVLocalizeStrings.TEAM_INSIGHT_DESCRIPTION}
        backgroundImage={HEADER_TEAM_INSIGHTS_BACKGROUND}
        backgroundImageBase={BG_TEAM_BASE}
      />
      {teamInitializing ? (
        <Flex justify={"center"}>
          <Spinner />
        </Flex>
      ) : (
        <FadeAfterDelay noEase={true}>
          <BlockSection fadeIn={true} title={""} columns={1}>
            <Flex flexDir={"column"} gap={8} minH={"500px"}>
              <TeamCommandCard />
              {learnerInsightResult && (
                <>
                  <Flex
                    bg={"white"}
                    borderRadius={Border_Radius}
                    boxShadow={"lg"}
                    w={"100%"}
                    justify={"center"}
                    flexDir={isMobile ? "column" : undefined}
                  >
                    {(() => {
                      if (isMobile) {
                        return (
                          <>
                            <Flex w={"100%"}>
                              <ScoreCard
                                image={
                                  "https://cdn.headversity.com/app/resources/team/teamInsightClock.svg"
                                }
                                title={
                                  HVLocalizeStrings.TEAM_LESSON_MINUTES_SPEND
                                }
                                value={learnerInsightResult.totalTimeSpent?.toString()}
                                showRightBorder={true}
                                showLeftBorder={true}
                                showTopBorder={true}
                              />
                              <ScoreCard
                                image={
                                  "https://cdn.headversity.com/app/resources/team/pollEngagement.svg"
                                }
                                title={HVLocalizeStrings.TEAM_POLL_ENGAGEMENT}
                                value={`${learnerInsightResult.pollsCompletePercentage}%`}
                                showRightBorder={true}
                                showTopBorder={true}
                              />
                            </Flex>
                            <Flex w={"100%"}>
                              <ScoreCard
                                image={
                                  "https://cdn.headversity.com/app/resources/team/presentationComplete.svg"
                                }
                                title={
                                  HVLocalizeStrings.TEAM_TOTAL_TEAM_LESSONS
                                }
                                value={learnerInsightResult.teamLessonsCompleted?.toString()}
                                showRightBorder={true}
                                showLeftBorder={true}
                                showTopBorder={true}
                                showBottomBorder={true}
                              />
                              <ScoreCard
                                image={
                                  "https://cdn.headversity.com/app/resources/team/mostTeammates.svg"
                                }
                                title={
                                  HVLocalizeStrings.TEAM_TEAMMATES_IN_ONE_SESSION
                                }
                                value={learnerInsightResult.mostParticipantsInTeamLessonUserInstance?.toString()}
                                showRightBorder={true}
                                showTopBorder={true}
                                showBottomBorder={true}
                              />
                            </Flex>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <ScoreCard
                              image={
                                "https://cdn.headversity.com/app/resources/team/teamInsightClock.svg"
                              }
                              title={
                                HVLocalizeStrings.TEAM_LESSON_MINUTES_SPEND
                              }
                              value={learnerInsightResult.totalTimeSpent?.toString()}
                              showRightBorder={true}
                            />
                            <ScoreCard
                              image={
                                "https://cdn.headversity.com/app/resources/team/pollEngagement.svg"
                              }
                              title={HVLocalizeStrings.TEAM_POLL_ENGAGEMENT}
                              value={`${learnerInsightResult.pollsCompletePercentage}%`}
                              showRightBorder={true}
                            />
                            <ScoreCard
                              image={
                                "https://cdn.headversity.com/app/resources/team/presentationComplete.svg"
                              }
                              title={HVLocalizeStrings.TEAM_TOTAL_TEAM_LESSONS}
                              value={learnerInsightResult.teamLessonsCompleted?.toString()}
                              showRightBorder={true}
                            />
                            <ScoreCard
                              image={
                                "https://cdn.headversity.com/app/resources/team/mostTeammates.svg"
                              }
                              title={
                                HVLocalizeStrings.TEAM_TEAMMATES_IN_ONE_SESSION
                              }
                              value={learnerInsightResult.mostParticipantsInTeamLessonUserInstance?.toString()}
                            />
                          </>
                        );
                      }
                    })()}
                  </Flex>

                  <Flex
                    bg={"white"}
                    borderRadius={Border_Radius}
                    boxShadow={"lg"}
                    w={"100%"}
                    flexDir={"column"}
                    align={"center"}
                    p={isMobile ? "5" : undefined}
                  >
                    <Text
                      mt={isMobile ? undefined : "5"}
                      fontWeight={"bold"}
                      fontSize={"lg"}
                    >
                      {HVLocalizeStrings.TEAM_USER_INTERACTION}
                    </Text>
                    <Flex
                      minH={"300px"}
                      justify={"center"}
                      align={"center"}
                      flexDir={isMobile ? "column-reverse" : undefined}
                    >
                      <Flex flexDir={"column"} gap={"5"}>
                        <ScoreLabel
                          scoreText={`${learnerInsightResult.totalLessonParticipation} `}
                          color={Light_Blue}
                          descriptionText={
                            HVLocalizeStrings.TEAM_TOTAL_LESSON_PARTICIPATION
                          }
                        />
                        <ScoreLabel
                          scoreText={`${learnerInsightResult.percentageOfUsersThatDidOneLesson}%`}
                          descriptionText={
                            HVLocalizeStrings.TEAM_COMPLETE_MORE_THAN_ONE
                          }
                        />
                      </Flex>
                      <Flex>
                        <CircularProgress
                          mx={"auto"}
                          mb={"5px"}
                          value={userCompleted1LessonValue}
                          size={isMobile ? 180 : 250}
                          color={THIRD_TEXT_COLOR}
                          thickness={6}
                        >
                          <CircularProgressLabel>
                            <Icon as={MdGroups} boxSize={"20"} />
                          </CircularProgressLabel>
                        </CircularProgress>
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
          </BlockSection>
        </FadeAfterDelay>
      )}
    </>
  );
};

interface ScoreCardProps {
  image: string;
  title: string;
  value: string;
  showRightBorder?: boolean;
  showTopBorder?: boolean;
  showBottomBorder?: boolean;
  showLeftBorder?: boolean;
}

const ScoreCard = (props: ScoreCardProps) => {
  const {
    image,
    title,
    value,
    showRightBorder,
    showTopBorder,
    showBottomBorder,
    showLeftBorder,
  } = props;

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  return (
    <Flex
      h={"150px"}
      w={isMobile ? "50%" : "25%"}
      borderRight={showRightBorder ? BLOCK_SECTION_DIVIDER_BORDER : undefined}
      borderTop={showTopBorder ? BLOCK_SECTION_DIVIDER_BORDER : undefined}
      borderLeft={showLeftBorder ? BLOCK_SECTION_DIVIDER_BORDER : undefined}
      borderBottom={showBottomBorder ? BLOCK_SECTION_DIVIDER_BORDER : undefined}
    >
      <Flex
        flexDir={"column"}
        align={"center"}
        justify={"center"}
        h={"100%"}
        w={isMobile ? "60px" : "110px"}
      >
        <Image
          src={image}
          w={isMobile ? "60px" : "80px"}
          h={isMobile ? "60px" : "80px"}
          alt=""
        />
      </Flex>
      <Flex
        flexDir={"column"}
        h={"100%"}
        align={isMobile ? "start" : "center"}
        justify={"center"}
        flex={"1"}
      >
        <Flex flexDir={"column"}>
          <Text
            color={PRIMARY_TEXT_COLOR}
            fontSize={isMobile ? "xs" : "sm"}
            fontWeight={"bold"}
            textAlign={"left"}
            maxW={isMobile ? "100%" : "80%"}
          >
            {title}
          </Text>
          <Text color={Mid_Blue} fontWeight={"bold"} fontSize={"3xl"}>
            {value}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

interface ScoreLabelProps {
  scoreText: string;
  descriptionText: string;
  color?: string;
}

const ScoreLabel = (props: ScoreLabelProps) => {
  const { scoreText, descriptionText, color } = props;
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  return (
    <Flex justify={"center"} align={"center"} gap={"2"}>
      <Text
        color={color ?? Mid_Blue}
        fontSize={isMobile ? "2xl" : "4xl"}
        fontWeight={"bold"}
        w={"100px"}
        textAlign={"center"}
      >
        {scoreText}
      </Text>
      <Text
        w={isMobile ? "200px" : "250px"}
        fontSize={isMobile ? "sm" : undefined}
        fontWeight={"semibold"}
      >
        {descriptionText}
      </Text>
    </Flex>
  );
};
