import { useContext, useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  AiFillPlayCircle,
  AiOutlineClockCircle,
  AiOutlineDownCircle,
  AiOutlineUpCircle,
} from "react-icons/ai";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  Border_Radius,
  BUTTON_THIRD_BACKGROUND_COLOR,
  Mid_Blue,
  MINDFULNESS_AUDIO_LIST_BORDER_COLOR,
  MINDFULNESS_TH_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { IShellProvider, ShellContext } from "../../../../State/ShellContext";
import { EVENTS, track } from "../../../../Utils/Analytics";
import { AudioTrackDto, AudioTrackVersionDto } from "@headversity/contract";
import { AudioTrackInformation } from "../../../../Types/HVTypes";

interface MindfulnessAudioListProps {
  title: string;
  description: string;
  image: string;
  items: AudioTrackDto[];
  mindfulnessAudio: AudioTrackInformation | null;
  setMindfulnessAudio: (mindfulnessAudio: AudioTrackInformation | null) => void;
}

export const MindfulnessAudioList = (props: MindfulnessAudioListProps) => {
  const {
    title,
    description,
    items,
    image,
    mindfulnessAudio,
    setMindfulnessAudio,
  } = props;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  const { setAudio } = useContext<IShellProvider>(ShellContext);
  const albumRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (isCollapsed && albumRef.current) {
      setTimeout(() => {
        albumRef.current?.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    }
  }, [isCollapsed]);

  const handleClick = (
    item: AudioTrackDto | null,
    vItem: AudioTrackVersionDto
  ) => {
    track(EVENTS.MindfulnessTrackSelected, {
      HV_Mindfulness_Track_Played: item?.name_loc?.en,
    });
    if (!item) {
      setMindfulnessAudio(null);
      setAudio(null);
    } else {
      setMindfulnessAudio({
        image: image,
        name: item?.name as string,
        url: vItem?.url as string,
        time: vItem?.duration as string,
        title: title,
        id: item?.id,
      });
    }
  };

  return (
    <Accordion allowToggle ref={albumRef}>
      <AccordionItem border={0}>
        <AccordionButton onClick={() => setIsCollapsed(!isCollapsed)}>
          <HStack
            w={"100%"}
            spacing={"25px"}
            p={"5"}
            color={MODAL_PRIMARY_TEXT_COLOR}
            className="mindfulness-list"
          >
            <Image
              src={image}
              h={"100px"}
              w={"100px"}
              borderRadius={Border_Radius}
              // Not able to grab this from the theme object ie..   boxShadow={"Tools.mindfulnessImageBoxShadow"}
              boxShadow={
                "8px 8px 20px -5px rgba(0, 0, 0, 0.15), -8px -8px 20px -5px rgba(240, 240, 240, 0.5)"
              }
              alt=""
            ></Image>
            <Flex flex={"1"} flexDir="column" gap="10px" lineHeight={"24px"}>
              <Text
                fontSize={["lg", "lg", "xl"]}
                fontWeight={"semibold"}
                color={MODAL_PRIMARY_TEXT_COLOR}
                align="left"
              >
                {title}
              </Text>

              <HStack w={"100%"}>
                {!isMobile && (
                  <Text w={"90%"} align="left">
                    {description}
                  </Text>
                )}
              </HStack>
            </Flex>
            {!isMobile &&
              (isCollapsed ? (
                <Icon as={AiOutlineUpCircle} w={"32px"} h={"32px"}></Icon>
              ) : (
                <Icon as={AiOutlineDownCircle} w={"32px"} h={"32px"}></Icon>
              ))}
          </HStack>
        </AccordionButton>
        <AccordionPanel>
          {isMobile && (
            <Text w={"90%"} color={MODAL_PRIMARY_TEXT_COLOR}>
              {description}
            </Text>
          )}
          <TableContainer>
            <Table boxShadow={"lg"} mt={4} size={"md"}>
              <Tbody>
                {!isMobile ? (
                  <Tr bgColor={"white"}>
                    <Td w={"20px"} color={MINDFULNESS_TH_COLOR}></Td>
                    <Th color={MINDFULNESS_TH_COLOR} fontWeight={"400"}>
                      {HVLocalizeStrings.MINDFULNESS_TOOLS_TITLE}
                    </Th>
                    {!isMobile ? (
                      <Th textAlign={"left"}>
                        <Icon
                          as={AiOutlineClockCircle}
                          w={"17px"}
                          h={"17px"}
                          color={MINDFULNESS_TH_COLOR}
                        ></Icon>
                      </Th>
                    ) : null}
                  </Tr>
                ) : null}
                <Tr
                  borderTop={`1px solid`}
                  borderTopColor={MINDFULNESS_AUDIO_LIST_BORDER_COLOR}
                ></Tr>
                {items?.map((item, index) => {
                  const hasMultipleVersions =
                    item.audioTrackVersions.length > 1;
                  return item.audioTrackVersions.map((vItem) => {
                    let color;
                    let playing = false;
                    if (
                      mindfulnessAudio &&
                      (mindfulnessAudio.url as string) ===
                        (vItem?.url as string)
                    ) {
                      color = Mid_Blue;
                      playing = true;
                    } else {
                    }
                    return (
                      <Tr
                        key={vItem.id}
                        backgroundColor={BUTTON_THIRD_BACKGROUND_COLOR}
                        _hover={{ bgColor: "#dfebff" }}
                        onClick={() => {
                          handleClick(playing ? null : item, vItem);
                        }}
                        cursor={"pointer"}
                      >
                        {!isMobile ? (
                          <Td fontSize={"md"} fontWeight={"semibold"}>
                            {playing ? (
                              <IconButton
                                aria-label="Stop Track"
                                icon={
                                  <Image
                                    width="18px"
                                    src="https://cdn.headversity.com/app/resources/toolIcons/stopicon.svg"
                                    alt=""
                                  />
                                }
                                fontSize={"xl"}
                                bg={"transparent"}
                                marginLeft={"-15px"}
                              />
                            ) : (
                              <IconButton
                                aria-label="Play Track"
                                icon={<AiFillPlayCircle />}
                                fontSize={"xl"}
                                bg={"transparent"}
                                _hover={{ color: MODAL_PRIMARY_TEXT_COLOR }}
                                color={MINDFULNESS_TH_COLOR}
                                marginLeft={"-15px"}
                              />
                            )}
                          </Td>
                        ) : null}
                        <Td>
                          <Text color={color}>
                            {hasMultipleVersions
                              ? `${item?.name as string} (${
                                  vItem?.duration as string
                                }-${HVLocalizeStrings.MINUTE_VERSION})`
                              : (item.name as string)}
                          </Text>
                        </Td>
                        {!isMobile ? (
                          <Td textAlign={"left"}>
                            {vItem?.duration as string}:00
                          </Td>
                        ) : null}
                      </Tr>
                    );
                  });
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
