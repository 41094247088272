export default class ResourceType {
  public static skillCompetencyForeignKeyType: string =
    "skill_competency_relations.resource_type";
  public static companyForeignKeyType: string =
    "company_relations.resource_type";
  public static inviteCodeForeignKeyType: string =
    "invite_code_relations.resource_type";
  public static lessonCourseForeignKeyType: string =
    "lesson_courses.lesson_type";
  public static questionUserResponseForeignKeyType: string =
    "question_user_responses.resource_type";
  public static schedulerForeignKeyType: string = "schedulers.resource_type";
  public static surveyForeignKeyType: string = "survey_relations.resource_type";
  public static certForeignKeyType: string = "cert_relations.resource_type";
  public static surveyInstanceForeignKeyType: string =
    "survey_instances.resource_type";

  public static featureImplementation: string = "fi";
  public static promotionalMaterial: string = "pm";
  public static microLesson: string = "ml";
  public static nanoPractice: string = "practice";
  public static practiceReminder: string = "pr_rmd";
  public static skill: string = "skill";
  public static teamLesson: string = "tl";
  public static blogPost: string = "bp";
  public static path: string = "path";
  public static tool: string = "tool";
  public static helpChannel: string = "hc";
  public static customQuestion: string = "cq";
  public static optimismTool: string = "optimism";
  public static focusTool: string = "focus";

  public static customQuestionResponse: string = "cqr";
  public static microLessonQuestionResponse: string = "mlg";
  public static headscanQuestionResponse: string = "hqr";
  public static nanoPracticeQuestionResponse: string = "npqr";
  public static surveyQuestionResponse: string = "sqr";

  public static microLessonUserInstanceQuestionResponse: string = "mluiqr";
  public static pollQuestionUserInstanceResponse: string = "pquir";

  public static cert: string = "cert";
  public static certSection: string = "cert_sect";
  public static certInstance: string = "cert_ui";
  public static certSectionInstance: string = "cert_sect_ui";
  public static certSectionQuestionResponse: string = "csqr";
}
