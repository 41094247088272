import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../State/GlobalContext";
import {
  BG_CERT_BASE,
  CERT_HOME_BACKGROUND,
} from "../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../Utils/Analytics";
import { FadeAfterDelay } from "../Common/FadeAfterDelay";
import { HeaderSetter } from "../Common/HeaderSetter";
import {
  CertFirstTimeInterstitialModal,
  CERT_INTERSTITIAL,
} from "./CertFirstTimeInterstitialModal";
import { CertHome } from "./CertHome";
import { HeaderWelcomeText } from "../Common/HeaderWelcomeText";
import { UserStateDto, StateType } from "@headversity/contract";

export const CertPage = () => {
  const {
    setUserStateToServer,
    getSelfFromServer,
    self,
    isHierarchyAssociationStepComplete,
  } = useContext(GlobalContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const setModalStatus = async () => {
      if (localStorage.getItem(CERT_INTERSTITIAL) !== null) {
        const userStateDto = {
          stateTypeId: StateType.CertInterstitial,
          value: "true",
        } as UserStateDto;
        await setUserStateToServer(userStateDto);
        await getSelfFromServer();
        localStorage.removeItem(CERT_INTERSTITIAL);
        return;
      }
      if (self?.userStates) {
        const userStateDto = self.userStates.find(
          (state) => state.stateTypeId === StateType.CertInterstitial
        );
        if (!userStateDto) {
          // modal has not been shown, only show it if hierarchy association step is complete
          setIsModalOpen(isHierarchyAssociationStepComplete);
        }
      }
    };
    setModalStatus();
  }, [isHierarchyAssociationStepComplete]);

  return (
    <>
      <Helmet>
        <title>{`CERT - ${HVLocalizeStrings.HEADVERSITY}`}</title>
      </Helmet>
      <HeaderSetter
        title={<HeaderWelcomeText onInfoClick={() => setIsModalOpen(true)} />}
        description={HVLocalizeStrings.CERT_TAGLINE}
        backgroundImage={CERT_HOME_BACKGROUND}
        backgroundImageBase={BG_CERT_BASE}
        showLogo={true}
      />
      <FadeAfterDelay>
        <CertHome />
      </FadeAfterDelay>
      <CertFirstTimeInterstitialModal
        open={isModalOpen}
        setOpen={(val: any) => {
          setIsModalOpen(val);
          if (!val) {
            track(EVENTS.CertFirstTimeInterstitialShown);
          }
        }}
      />
    </>
  );
};
