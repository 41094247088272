import {
  Box,
  Flex,
  Progress,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  BLOCK_TITLE_GRADIENT,
  Border_Radius,
  BUTTON_FOUR_BACKGROUND_COLOR,
  LINEAR_PROGRESS_BAR,
  PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { PathTeamLessonBlock } from "./PathTeamLessonBlock";
import dayjs from "dayjs";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { useSearchParams } from "react-router-dom";
import { BlockAccordion } from "../../Common/BlockAccordion";
import { BlockAccordionButtonBlock } from "../../Common/BlockAccordionButtonBlock";
import { MdCheckCircleOutline } from "react-icons/md";
import { isTeamLessonDone } from "../../../Utils/TeamUtil";
import { HVTestId } from "../../../Testing/dataTestIds";
import { PathDto } from "@headversity/contract";
import { addSearchParams } from "../../../Utils/Helpers";
interface IPathAccordionProps {
  path: PathDto;
  expandable?: boolean;
}

export const PathAccordion = ({ path }: IPathAccordionProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const { pathTeamLessons } = path;
  const { currentTeam, selectedUserLanguage } =
    useContext<IGlobalProvider>(GlobalContext);
  const [timeTotal, setTimeTotal] = useState(0);
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const [presentedDateString, setPresentedDateString] = useState(
    HVLocalizeStrings.TEAM_NOT_YET_PRESENTED
  );
  const [onExpandUrl, setOnExpandUrl] = useState<string>(
    `/team/paths?${addSearchParams({ pathId: path?.id.toString() }, true)}`
  );

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setTimeout(() => {
      if (
        path?.pathTeamLessons.some(
          (pathTeamLesson) => pathTeamLesson.lastPresentedDate
        )
      ) {
        let lastPresentedDate = null;
        let lastPresentedDateUnix = -1;
        path?.pathTeamLessons?.forEach((item) => {
          if (
            item?.lastPresentedDate &&
            dayjs.utc(`${item?.lastPresentedDate}`).unix() >
              lastPresentedDateUnix
          ) {
            lastPresentedDate = dayjs.utc(`${item?.lastPresentedDate}`);
            lastPresentedDateUnix = lastPresentedDate.unix();
          }
        });
        if (lastPresentedDate) {
          setPresentedDateString(
            HVLocalizeStrings.TEAM_LAST_PRESENTED +
              " <span style='font-weight:600'>" +
              dayjs
                .utc(`${lastPresentedDate}`)
                .local()
                .format(HVLocalizeStrings.DATE_FORMAT) +
              "</span>"
          );
        }
      }
    }, 500);
  }, [path, selectedUserLanguage]);

  useEffect(() => {
    let time = 0;
    let completed = 0;
    pathTeamLessons.map((item) => {
      completed += isTeamLessonDone(item) ? 1 : 0;
      time += item.teamLesson.duration ?? 0;
    });
    setTimeTotal(time);
    setCompletedPercentage(
      pathTeamLessons && pathTeamLessons.length > 0
        ? (completed / pathTeamLessons.length) * 100
        : 0
    );
  }, [pathTeamLessons]);

  useEffect(() => {
    const pathId = searchParams.get("pathId");
    const teamLessonId = searchParams.get("teamLessonId");
    let url = `/team/paths?pathId=${path?.id}`;
    if (pathId == path.id.toString()) {
      // only way to get this to work. defaultIndex does not seem to work either
      // for the accordion, have to pass in a
      setTimeout(() => {
        const elements = document.getElementsByClassName(
          `Path_Button_${path.id}`
        );
        if (elements && elements.length > 0) {
          (elements.item(0) as any).click();
        }
      }, 500);
      if (teamLessonId) {
        let searchParams = addSearchParams(
          {
            pathId: path?.id.toString(),
            teamLessonId: teamLessonId.toString(),
          },
          true
        );
        url = `/team/paths?${searchParams.toString()}`;
      }
      setOnExpandUrl(url);
    }
  }, []);

  return (
    <BlockAccordion
      yOffsetScrollTo={-100}
      bg={"white"}
      accordionButtonGap={"8"}
      accordionButtonPadding={"28px"}
      buttonClassName={`Path_Button_${path.id}`}
      onExpandUrl={onExpandUrl}
      onCollapseUrl={"/team/paths"}
      data-testid={`${HVTestId.PathAccordion.pathBlock}${path.id}`}
      buttonContent={
        <BlockAccordionButtonBlock
          imageOverlay={
            completedPercentage !== 100 ? null : (
              <Flex
                position={"absolute"}
                top={"0"}
                left={"0"}
                borderRadius={Border_Radius}
                alignItems={"center"}
                justifyContent={"center"}
                background={BLOCK_TITLE_GRADIENT}
                h={"100%"}
                w={"100%"}
                opacity={0.8}
                color="white"
                fontSize={"44px"}
              >
                <MdCheckCircleOutline />
              </Flex>
            )
          }
          backgroundImageUrl={
            "https://cdn.headversity.com/app/resources/team/teamHomeBg.jpg"
          }
          title={path.name}
          description={path.description}
          content={
            <>
              {isMobile || !currentTeam ? null : (
                <>
                  <Progress
                    value={completedPercentage}
                    borderRadius={"50px"}
                    w={"102%"}
                    h={"2"}
                    sx={{
                      "& > div": {
                        background: LINEAR_PROGRESS_BAR,
                      },
                    }}
                  />
                  <Text
                    fontSize={"sm"}
                    color={PRIMARY_TEXT_COLOR}
                    dangerouslySetInnerHTML={{ __html: presentedDateString }}
                  />
                </>
              )}
            </>
          }
          additionalContent={
            <Flex
              fontSize={["sm", "md"]}
              flexDir={"column"}
              textAlign={"left"}
              alignSelf={isMobile ? "flex-start" : "center"}
              color={PRIMARY_TEXT_COLOR}
              w={isMobile ? "100%" : "30%"}
              mt={"-15px"}
            >
              <Text>
                <b style={{ fontWeight: "600" }}>
                  {`${pathTeamLessons?.length}`}
                </b>{" "}
                <span>{`${
                  pathTeamLessons?.length != 1
                    ? HVLocalizeStrings.TEAM_LESSONS
                    : HVLocalizeStrings.TEAM_LESSON
                }`}</span>
              </Text>
              <Text>
                <b style={{ fontWeight: "600" }}>{`${timeTotal}`}</b>{" "}
                <span>
                  {`${
                    timeTotal != 1
                      ? HVLocalizeStrings.MINUTES
                      : HVLocalizeStrings.MINUTE
                  }`}
                </span>
              </Text>
              {currentTeam && (
                <Text>
                  <b style={{ fontWeight: "600" }}>{`${Math.round(
                    completedPercentage
                  )}%`}</b>{" "}
                  <span>{`${HVLocalizeStrings.COMPLETE}`}</span>
                </Text>
              )}
              {isMobile || !currentTeam ? (
                <Progress
                  value={completedPercentage}
                  borderRadius={"50px"}
                  w={"105%"}
                  mt={4}
                  h={2}
                  sx={{
                    "& > div": {
                      background: BUTTON_FOUR_BACKGROUND_COLOR,
                    },
                  }}
                />
              ) : null}
            </Flex>
          }
        />
      }
      panelContent={
        <>
          <Flex flexDir={"column"} gap={3} px={isMobile ? 0 : 3}>
            {pathTeamLessons.map((item) => {
              return (
                <Box mt={2} key={item.id}>
                  <PathTeamLessonBlock
                    pathTeamLesson={item}
                    trackingOrigin={"Path Accordion"}
                  />
                </Box>
              );
            })}
          </Flex>
        </>
      }
    />
  );
};
