import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import { HelpChannelDto } from "@headversity/contract";

export const getHelpInfo = (
  token: string
): Promise<AxiosResponse<HelpChannelDto>> => {
  return AxiosClient.get("api/helpInfo", getConfig(token));
};

export const trackHelpInfoView = (
  token: string,
  helpChannelId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/helpInfo/${helpChannelId}`,
    null,
    getConfig(token)
  );
};
