import { Flex, FormLabel, Heading, Switch, Text } from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";

interface Props {
  title: string;
  description: string;
  onChange?: () => void;
  isChecked?: boolean;
}
export default function ThemeSwitch({
  title,
  description,
  onChange,
  isChecked,
}: Props) {
  return (
    <Flex
      className="theme_select_switch_container"
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Flex flexDir={"column"} gap={"4px"}>
        <FormLabel htmlFor={title}>
          <Heading
            fontSize={"22px"}
            color={MODAL_PRIMARY_TEXT_COLOR}
            fontWeight={"600"}
          >
            {title}
          </Heading>
        </FormLabel>
        <Text fontSize={"16px"} color={MODAL_PRIMARY_TEXT_COLOR} mr={8}>
          {description}
        </Text>
      </Flex>
      <Switch
        size={"lg"}
        isChecked={isChecked}
        onChange={onChange}
        id={title}
      />
    </Flex>
  );
}
