import { useContext, useEffect, useState } from "react";
import { LessonFeedback } from "./LessonFeedback";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
  TrainingType,
} from "../../../State/GlobalContext";
import {
  Box,
  Fade,
  ModalOverlay,
  SlideFade,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { HVTestId } from "../../../Testing/dataTestIds";
import Rise360Player, {
  FRAME_ID,
  GO_BACK,
} from "../../Shared/Rise360/Rise360Player";
import BackButton from "../../Common/BackButton";
import {
  getLessonCompletionDateAndStatus,
  postMessageToIframe,
} from "../../../Utils/LessonsUtil";
import { isAndroid, isWebPlatform } from "../../../Utils/mobileUtils";
import {
  ILessonCourseProvider,
  LessonCourseContext,
} from "../../../State/Solo/LessonCourseContext";
import {
  MicroLessonDto,
  LessonQuestionAnswerDto,
  MicroLessonUserInstanceDto,
  LessonCourseSlideInputDto,
  NanoPracticeDto,
} from "@headversity/contract";
import { Rise360LessonViewModel } from "../../Shared/Rise360/Rise360LessonViewModel";
import { LargeModal } from "../../Common/LargeModal";
import {
  getPostActivityStep,
  getSkillId,
  getTotalPoints,
  PostActivityStep,
} from "../../../Utils/SkillsUtil";
import { StepStartTraining } from "../../Shared/Welcome/StepStartTraining";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { PointsButton } from "../Shared/PointsButton";
import { PointsMap } from "../Shared/PointsMap";
import { HvSlideFade } from "../../Common/HvSlideFade";
import { PracticeReminder } from "../../Shared/PracticeReminder/PracticeReminder";
import { StreakInfo } from "../Shared/StreakInfo";
import { LessonPreview } from "./LessonPreview";

interface LessonModalProps {
  title: any;
  lesson: MicroLessonDto;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const LessonModal = (props: LessonModalProps) => {
  const { open, setOpen, title, lesson } = props;

  const {
    getOrCreateMicroLessonInstanceFromServer,
    setUserStatsToServer,
    currentApp,
    getLessonsFromServer,
    skills,
    selectedGoal,
    openNextActivity,
    userSkillStats,
    selectedGoalBgImage,
    schedulerReminders,
    activityCompletionDates,
    openedActivityFromStartTraining,
  } = useContext<IGlobalProvider>(GlobalContext);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const { theme, safeAreaInsets } = useContext<IShellProvider>(ShellContext);
  const { saveQuestionAnswersToServer, saveLessonCompletedToServer } =
    useContext<ILessonCourseProvider>(LessonCourseContext);

  const [paddingTop, setPaddingTop] = useState(0);
  const [step, setStep] = useState<number>(0);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [currentMicroLessonUserInstance, setCurrentMicroLessonUserInstance] =
    useState<MicroLessonUserInstanceDto | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [rise360Lesson, setRise360Lesson] = useState<
    Rise360LessonViewModel | undefined
  >();
  const [queryStringParams] = useState([{ name: "mode", value: theme.name }]);
  const [showNextUp, setShowNextUp] = useState(false);
  const [selectedTrainingType, setSelectedTrainingType] =
    useState<TrainingType>(TrainingType.Lesson);
  const [selectedPractice, setSelectedPractice] = useState<NanoPracticeDto>();
  const [selectedLesson, setSelectedLesson] = useState<MicroLessonDto>();

  /* same as NanoPracticeModal */
  const [actionButtonFadeIn, setActionButtonFadeIn] = useState(true);
  const [initialPoints, setInitialPoints] = useState<number>(0);
  const [currentPoints, setCurrentPoints] = useState<number>(0);
  const [postActivityStep, setPostActivityStep] = useState<PostActivityStep>(
    PostActivityStep.None
  );
  /* end same */

  const navigate = useNavigate();

  /* same as NanoPracticeModal */
  useEffect(() => {
    setInitialPoints(getTotalPoints(userSkillStats));
  }, [lesson]);

  useEffect(() => {
    setCurrentPoints(getTotalPoints(userSkillStats));
  }, [userSkillStats]);

  useEffect(() => {
    if (actionButtonFadeIn || postActivityStep === PostActivityStep.Reminder)
      return;

    setTimeout(() => {
      setActionButtonFadeIn(true);
    }, 2000);
  }, [actionButtonFadeIn, postActivityStep]);
  /* end same */

  useEffect(() => {
    if (step === 1) {
      navigate(window.location.pathname + `?lesson=${lesson?.id}`, {
        replace: true,
      });
    }
  }, [step]);

  useEffect(() => {
    if (lesson && step === 1) {
      getOrCreateMicroLessonInstanceFromServer(lesson.id).then(
        (response: any) => {
          setCurrentMicroLessonUserInstance(response.data);
          setRise360Lesson({
            lessonCourseUserInstanceId:
              response.data.lessonCourseUserInstanceId,
            startingUrl: lesson.lessonCourse.startingUrl,
          });
        }
      );
    }

    // re-fetch data to get completion status
    if (step === 2) {
      getLessonsFromServer(skills);
    }
  }, [step, theme]);

  /*
   * simulate lesson completion
   *
  useEffect(() => {
    if (!currentMicroLessonUserInstance) return;

    handleLessonComplete();
  }, [currentMicroLessonUserInstance]);
  */

  const handleLessonComplete = () => {
    setIsSaving(true);

    saveLessonCompletedToServer(
      currentMicroLessonUserInstance?.id as number
    ).then(() => {
      setIsSaving(false);

      setStep(2);
      setUserStatsToServer();
    });
  };

  /* when in full screen (step === 1), pad based on header size */
  useEffect(() => {
    const i = setInterval(() => {
      setPaddingTop(0);

      const header = document.querySelector(".large-modal-header");
      if (step === 1 && header) {
        setPaddingTop(header.getBoundingClientRect().height);
      }
    }, 100);

    return () => {
      clearInterval(i);
    };
  }, [isDesktop, step]);

  useEffect(() => {
    // reset state
    setStep(0);
    setRise360Lesson(undefined);
    setShowFeedback(false);
    setShowNextUp(false);
    setPostActivityStep(0);
  }, [lesson]);

  /* same as NanoPracticeModal */
  const gotoNextStep = () => {
    if (step === 0) {
      setStep(1);
      return;
    }

    setShowNextUp(true);

    if (!selectedGoal || currentApp === AppType.CERT) {
      setOpen(false);
      return;
    }

    const curStep = getPostActivityStep(
      postActivityStep,
      initialPoints,
      currentPoints,
      activityCompletionDates,
      schedulerReminders
    );

    setPostActivityStep(curStep);

    if (
      curStep === PostActivityStep.ChooseTraining &&
      !openedActivityFromStartTraining
    ) {
      setOpen(false);
      return;
    }

    if (curStep === PostActivityStep.LaunchTraining) {
      openNextActivity(selectedTrainingType, selectedPractice, selectedLesson);
      return;
    }

    navigate(window.location.pathname, { replace: true });

    setActionButtonFadeIn(false);
  };
  /* end same */

  const { state, updatedAt } = getLessonCompletionDateAndStatus(lesson);

  const skill =
    currentApp === AppType.CERT
      ? undefined
      : skills.find((x) => x.id === getSkillId(lesson));

  return (
    <LargeModal
      title={step >= 1 && !showNextUp ? title : undefined}
      open={open}
      setOpen={setOpen}
      forceDarkMode={showNextUp && selectedGoal ? true : false}
      bgImage={showNextUp && selectedGoal ? selectedGoalBgImage : undefined}
      size={rise360Lesson ? "full" : undefined}
      fullScreen={!!rise360Lesson}
      leftHeader={
        showBackButton ? (
          <BackButton
            isStaticPosition={true}
            testId={HVTestId.LessonModal.backButton}
            // TODO: post message should really be at the Rise360Player layer (see comments in PresentationPresentView.tsx)

            onButtonClick={() => postMessageToIframe(FRAME_ID, GO_BACK)}
          />
        ) : !showNextUp && step === 2 && selectedGoal ? (
          <PointsButton
            points={initialPoints}
            currentPoints={currentPoints}
            top={isWebPlatform() ? "22px" : `${3 + safeAreaInsets.top}px`}
            pos="absolute"
            isInModal={true}
          />
        ) : undefined
      }
    >
      <Box
        minH="540px"
        h={isAndroid() ? "100%" : undefined}
        pt={!showNextUp ? `${paddingTop}px` : undefined}
      >
        {/* same as nanopractice modal */}
        {postActivityStep === PostActivityStep.LevelUp && (
          <HvSlideFade fadeIn={true}>
            <Box mt={isWebPlatform() ? 4 : "40px"}>
              <PointsMap fromNewLevel={true} />
            </Box>
          </HvSlideFade>
        )}

        {postActivityStep === PostActivityStep.Reminder && (
          <HvSlideFade fadeIn={true}>
            <PracticeReminder
              isHidingGetRemindersSwitch={true}
              onClose={() => {
                gotoNextStep();
              }}
            />
          </HvSlideFade>
        )}

        {postActivityStep === PostActivityStep.Streak && (
          <HvSlideFade fadeIn={true}>
            <StreakInfo />
          </HvSlideFade>
        )}

        {postActivityStep === PostActivityStep.ChooseTraining && (
          <StepStartTraining
            isPostWelcome={true}
            selectedTrainingType={selectedTrainingType}
            selectedPractice={selectedPractice}
            setSelectedPractice={setSelectedPractice}
            selectedLesson={selectedLesson}
            setSelectedLesson={setSelectedLesson}
            setSelectedTrainingType={setSelectedTrainingType}
          />
        )}
        {/* end same */}

        {step === 0 && (
          <LessonPreview
            lesson={lesson}
            lessonDate={updatedAt}
            completionState={state}
            skill={skill}
            showBackArrow={openedActivityFromStartTraining}
            onBackClick={() => {
              navigate(window.location.pathname, { replace: true });
              setStep(2);
              setShowNextUp(true);
              setPostActivityStep(PostActivityStep.ChooseTraining);
            }}
          />
        )}

        {rise360Lesson && (
          <Fade
            in={step === 1}
            onAnimationComplete={(definition) => {
              if (definition === "enter") return;

              setTimeout(() => {
                setShowFeedback(true);
                setRise360Lesson(undefined);
              }, 250);
            }}
            style={{
              height: isAndroid()
                ? `calc(100% - ${safeAreaInsets.bottom}px` // pad for 3-button nav bar
                : `calc(100vh - ${paddingTop + safeAreaInsets.bottom}px)`, // height of title
            }}
          >
            {isSaving && <ModalOverlay />}
            <Rise360Player
              rise360Lesson={rise360Lesson}
              queryStringParams={queryStringParams}
              lessonTitle={lesson.name_loc?.en}
              onShouldShowBackButton={setShowBackButton}
              onSlideProgress={(
                lessonCourseSlideInputDto: LessonCourseSlideInputDto
              ) => {
                if (
                  (lessonCourseSlideInputDto.index === 3 ||
                    lessonCourseSlideInputDto.index === 1) &&
                  lessonCourseSlideInputDto.progress === 100
                ) {
                  setUserStatsToServer();
                }
              }}
              onLessonComplete={() => {
                handleLessonComplete();
              }}
              onQuestionAnswer={(obj, slideIndex) => {
                if (currentMicroLessonUserInstance) {
                  saveQuestionAnswersToServer(
                    currentMicroLessonUserInstance?.id,
                    {
                      slideIndex,
                      questionAnswerDtos: obj,
                    } as LessonQuestionAnswerDto
                  );
                }
              }}
            />
          </Fade>
        )}

        {showFeedback &&
          !showNextUp &&
          step === 2 &&
          currentMicroLessonUserInstance && (
            <SlideFade in={showFeedback} style={{ height: "100%" }}>
              <LessonFeedback
                lesson={lesson}
                microLessonUserInstance={currentMicroLessonUserInstance}
                onComplete={() => {
                  gotoNextStep();
                }}
              />
            </SlideFade>
          )}
      </Box>

      {(step === 0 || step === 2) && (
        <Box display={!actionButtonFadeIn ? "none" : "block"}>
          <BottomActionBar
            nextButtonText={
              step === 0
                ? state === 1
                  ? HVLocalizeStrings.LESSON_RESTART
                  : state === 0
                  ? HVLocalizeStrings.LESSON_RESUME
                  : HVLocalizeStrings.LESSON_START
                : !openedActivityFromStartTraining && step === 2
                ? HVLocalizeStrings.CLOSE
                : HVLocalizeStrings.NEXT
            }
            onNextButtonClick={() => {
              gotoNextStep();
            }}
          />
        </Box>
      )}
    </LargeModal>
  );
};
