import React, { useState } from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import {
  INPUT_BACKGROUND_COLOR,
  INPUT_PRIMARY_TEXT_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { PagerState } from "../../Common/Pager";
import { StepPanel } from "../../Common/StepPanel";
import { HvSelect } from "../../Common/HvSelect";
import {
  Dictionary,
  LocalizationText,
  QuestionAlternativeDto,
} from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";

interface CustomFieldSelectProps {
  title: string | LocalizationText;
  ungroupedQuestionAlternatives: QuestionAlternativeDto[] | undefined;
  groupedQuestionAlternatives: Dictionary<QuestionAlternativeDto[]> | undefined;
  groupings: string[] | undefined;
  isLastCustomField: boolean;
  defaultSelectedQuestionAlternativeId: number | undefined;
  pager: PagerState;
  backClick: () => void;
  saveSelection: (selectedQuestionAlternativeId: number) => void;
  isSaving: boolean;
}

export const CustomFieldSelect = (props: CustomFieldSelectProps) => {
  const {
    title,
    ungroupedQuestionAlternatives,
    groupedQuestionAlternatives,
    groupings,
    isLastCustomField,
    defaultSelectedQuestionAlternativeId,
    pager,
    backClick,
    saveSelection,
    isSaving,
  } = props;
  const [selectedQuestionAlternativeId, setSelectedQuestionAlternativeId] =
    useState<number>(defaultSelectedQuestionAlternativeId ?? -1);
  const [backClicked, setBackClicked] = useState<boolean>(false);
  const [
    previousSelectedQuestionAlternativeId,
    setPreviousSelectedQuestionAlternativeId,
  ] = useState<number | undefined>(undefined);
  const handleSelectChange = (e: any) => {
    setBackClicked(false);
    setSelectedQuestionAlternativeId(e.target.value);
  };

  return (
    <>
      <StepPanel
        value={((pager.currentPage + 1) / pager.totalPage) * 100}
        step={pager.currentPage + 1}
        totalStep={pager.totalPage}
        showProgressBar={true}
        showBackArrow={pager.currentPage > 0}
        onBackClick={() => {
          setBackClicked(true);
          backClick();
          if (previousSelectedQuestionAlternativeId) {
            setSelectedQuestionAlternativeId(
              previousSelectedQuestionAlternativeId
            );
          }
        }}
      >
        <Box>
          <Heading
            text-align={"left"}
            fontSize={"xl"}
            fontWeight="500"
            color={MODAL_PRIMARY_TEXT_COLOR}
            mt={1}
          >
            {HVLocalizeStrings.CUSTOM_FIELDS_SUBTITLE}
          </Heading>
          <Text
            fontSize={"md"}
            color={MODAL_PRIMARY_TEXT_COLOR}
            mt={"5"}
            mb={"3"}
          >
            {title as string}
          </Text>
          <Box
            w={"100%"}
            className="custom_fields_select_container"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <HvSelect
              size={"lg"}
              id={title as string}
              placeholder={HVLocalizeStrings.CUSTOM_FIELDS_SELECT_PLACEHOLDER}
              backgroundColor={INPUT_BACKGROUND_COLOR}
              color={INPUT_PRIMARY_TEXT_COLOR}
              onChange={handleSelectChange}
              defaultValue={defaultSelectedQuestionAlternativeId}
              key={defaultSelectedQuestionAlternativeId}
              data-testid={HVTestId.CustomFieldSelect.select}
            >
              {ungroupedQuestionAlternatives?.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    {(item.grouping == undefined ||
                      item.grouping == null ||
                      (item.grouping as string) == "") && (
                      <option value={item.id}>
                        {item.alternativeText as string}
                      </option>
                    )}
                  </React.Fragment>
                );
              })}
              {groupedQuestionAlternatives !== undefined &&
                groupings?.map((grouping) => {
                  return (
                    <React.Fragment key={grouping}>
                      <optgroup label={grouping} />
                      {groupedQuestionAlternatives[grouping]?.map(
                        (groupedQuestionAlternative) => {
                          return (
                            <option
                              value={groupedQuestionAlternative.id}
                              key={groupedQuestionAlternative.id}
                            >
                              {
                                groupedQuestionAlternative.alternativeText as string
                              }
                            </option>
                          );
                        }
                      )}
                    </React.Fragment>
                  );
                })}
            </HvSelect>
          </Box>
          <Flex
            className="custom_fields_select_save_button_container"
            justifyContent={"center"}
            alignItems={"center"}
            mt={"10"}
          >
            <AnimatedButton
              disabled={
                isSaving || (selectedQuestionAlternativeId <= 0 && !backClicked)
              }
              key={defaultSelectedQuestionAlternativeId}
              opacity={"1"}
              colorSet={AnimatedButtonColorSet.Secondary}
              text={
                isLastCustomField
                  ? HVLocalizeStrings.COMPLETE
                  : HVLocalizeStrings.NEXT
              }
              onClick={() => {
                setBackClicked(false);
                saveSelection(selectedQuestionAlternativeId);
                setPreviousSelectedQuestionAlternativeId(
                  selectedQuestionAlternativeId
                );
                setSelectedQuestionAlternativeId(-1);
              }}
              dataTestId={`${
                isLastCustomField
                  ? HVTestId.CustomFieldSelect.completeButton
                  : HVTestId.CustomFieldSelect.nextButton
              }`}
            ></AnimatedButton>
          </Flex>
        </Box>
      </StepPanel>
    </>
  );
};
