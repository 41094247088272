import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { ThinkingToolInfoDto } from "@headversity/contract";
import { AxiosResponse } from "axios";

export const getThinkingPlanItems = (
  token: string
): Promise<AxiosResponse<ThinkingToolInfoDto[]>> => {
  return AxiosClient.get("api/thinkingTool/usage", getConfig(token));
};

export const saveThinkingPlanItems = (
  thinkingPlan: ThinkingToolInfoDto,
  token: string
): Promise<AxiosResponse<ThinkingToolInfoDto>> => {
  return AxiosClient.post(
    "api/thinkingTool/usage",
    thinkingPlan,
    getConfig(token)
  );
};
