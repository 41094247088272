import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import { SidebarMobileWeb } from "./SidebarMobileWeb";
import { ToggleButton } from "./ToggleButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

interface NavbarProps {
  setUseFunnyBg: any;
}

export const Navbar = ({ setUseFunnyBg }: NavbarProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <Box
      width="50px"
      py="14px"
      px={{ base: "4", md: "6" }}
      bg="transparent"
      pos={"fixed"}
      zIndex={200}
    >
      <Flex justify="space-between">
        <ToggleButton
          isOpen={isOpen}
          aria-label={HVLocalizeStrings.TOGGLE_NAVIGATION_MENU}
          className="open-menu-button"
          onClick={onToggle}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          isFullHeight
          preserveScrollBarGap
          trapFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent>
            <SidebarMobileWeb
              isOpen={true}
              closeOnClick={true}
              showToggle={true}
              setUseFunnyBg={setUseFunnyBg}
              setIsOpen={() => {
                onClose();
              }}
            />
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
};
