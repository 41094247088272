import {
  CompanyDto,
  CompanyQuickStartDto,
  CreateHelpResourceDto,
  HelpResourceDto,
  SectorDto,
  UpdateCompanyDto,
} from "@headversity/contract";

import { AxiosResponse } from "axios";
import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";

export const createGetHelpResource = async (
  token: string,
  createHelpResource: CreateHelpResourceDto
): Promise<AxiosResponse<HelpResourceDto>> => {
  return AxiosClient.post<HelpResourceDto>(
    `/api/currentCompany/helpResources`,
    createHelpResource,
    getConfig(token)
  );
};

export const saveGetHelpResource = async (
  token: string,
  helpResourceId: number,
  helpResource: CreateHelpResourceDto
): Promise<AxiosResponse<HelpResourceDto>> => {
  return AxiosClient.put<HelpResourceDto>(
    `/api/currentCompany/helpResources/${helpResourceId}`,
    helpResource,
    getConfig(token)
  );
};

export const getHelpResources = async (
  token: string,
  inviteCodeId: number
): Promise<AxiosResponse<HelpResourceDto[]>> => {
  return AxiosClient.get<HelpResourceDto[]>(
    `api/currentCompany/helpResources?inviteCodeId=${inviteCodeId}`,
    getConfig(token)
  );
};

export const deleteHelpResource = async (
  token: string,
  helpResourceId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.delete<void>(
    `/api/currentCompany/helpResources/${helpResourceId}`,
    getConfig(token)
  );
};

export const updateCompany = async (
  token: string,
  company: UpdateCompanyDto
): Promise<AxiosResponse<CompanyDto>> => {
  return AxiosClient.put<CompanyDto>(
    `/api/currentCompany`,
    company,
    getConfig(token)
  );
};

export const getSectors = async (
  token: string
): Promise<AxiosResponse<SectorDto[]>> => {
  return AxiosClient.get<SectorDto[]>(`/api/sectors`, getConfig(token));
};

export const getUsersCount = async (
  token: string
): Promise<AxiosResponse<number>> => {
  return AxiosClient.get<number>(`/api/users/count`, getConfig(token));
};

export const getQuickStartProgress = async (
  token: string
): Promise<AxiosResponse<CompanyQuickStartDto>> => {
  return AxiosClient.get<CompanyQuickStartDto>(
    `/api/currentCompany/quickStart`,
    getConfig(token)
  );
};

export const getHelpResourcesCount = async (
  token: string
): Promise<AxiosResponse<number>> => {
  return AxiosClient.get<number>(
    `/api/currentCompany/helpResources/count`,
    getConfig(token)
  );
};
