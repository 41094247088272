import { PDFExport } from "@progress/kendo-react-pdf";
import PDFView from "../../../Shared/PDFDownload/PDFView";
import { CharterPanel } from "./CharterPanel";
import { CharterDataDto, QuestionUserResponseDto } from "@headversity/contract";

export interface PdfCharterProps {
  pdfRef: React.RefObject<PDFExport>;
  statements: QuestionUserResponseDto[];
  charterData: CharterDataDto;
}

export const PdfCharter = ({
  pdfRef,
  statements,
  charterData,
}: PdfCharterProps) => {
  return (
    <PDFView landscape={true} pdfRef={pdfRef}>
      <CharterPanel
        pdfExport={true}
        statements={statements}
        charterData={charterData}
      />
    </PDFView>
  );
};
