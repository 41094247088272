import React, { useContext } from "react";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { Helmet } from "react-helmet";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { HEADER_SKILLS_BACKGROUND } from "../../../Styles/HeadversityStyle";
import { SkillsByFamily } from "./SkillsByFamily";
import { ErrorPage } from "../../Shared/Error/ErrorPage";

export const SkillsPage = () => {
  const { lessonNotFound, getPageTitle } =
    useContext<IGlobalProvider>(GlobalContext);

  return (
    <>
      {!lessonNotFound ? (
        <>
          <Helmet>
            <title>
              {getPageTitle(
                `${HVLocalizeStrings.SOLO_SKILL_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`
              )}
            </title>
          </Helmet>
          <HeaderSetter
            title={<>{HVLocalizeStrings.SOLO_SKILL_TITLE}</>}
            description={HVLocalizeStrings.SOLO_SKILL_DESCRIPTION}
            backgroundImage={HEADER_SKILLS_BACKGROUND}
          />
          <FadeAfterDelay>
            <SkillsByFamily />
          </FadeAfterDelay>
        </>
      ) : (
        <ErrorPage />
      )}
    </>
  );
};
