import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { PathTeamLessonBlock } from "../Path/PathTeamLessonBlock";
import withUpNextPresentation from "../Shared/withUpNextPresentation";
import {
  HVLocalizeStrings,
  HVLocalizeStringsObj,
} from "../../../Localization/HVLocalizeStrings";
import {
  Box,
  Center,
  Divider,
  Flex,
  SlideFade,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Border_Radius,
  MODAL_PRIMARY_TEXT_COLOR,
  MODAL_SECONDARY_TEXT_COLOR,
  SlideFade_Offset,
} from "../../../Styles/HeadversityStyle";
import { TeamLessonUserInstanceStatus } from "../../../Types/HVTypes";
import { PathDto, PathTeamLessonDto } from "@headversity/contract";
import { FeedbackPanel } from "../../Common/FeedbackPanel";
import { getFeedbackQuestionAnswers } from "../../../Utils/Helpers";
import { LessonType, TeamLessonDto } from "../../../@headversity/contract";
import {
  HVSportLocalizeStrings,
  HVSportsLocalizeStringsObj,
} from "../../../Localization/HVSportLocalizeString";
import { AnimatedButton } from "../../Common/AnimatedButton";
import { ShellContext } from "../../../State/ShellContext";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";

interface FeedbackStepProps {
  closeFunction: () => void;
  pathId?: string | null;
  isPresenter?: boolean;
}

export const FeedbackStep = (props: FeedbackStepProps) => {
  const { closeFunction, pathId, isPresenter } = props;

  const {
    resetTeamSession,
    currentTeamLessonUserInstanceId,
    currentTeam,
    currentApp,
    paths,
    teamLessonFeedbackObtained,
    presenterPathTeamLesson,
    participantPathTeamLesson,
    submitTeamLessonUserInstanceRatingToServer,
    saveTeamLessonQuestionAnswersToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { safeAreaInsets } = useContext(ShellContext);

  const [teamLesson, setTeamLesson] = useState<TeamLessonDto>();
  const [copy, setCopy] = useState({
    title: HVLocalizeStrings.HOWD_IT_GO,
    description: HVLocalizeStrings.LET_US_KNOW,
    relevantQuestionOne: "", // Will be set based on lessonType
    motivatingQuestionTwo: "", // Will be set based on lessonType
    relevantQuestionOneEn: "", // Will be set based on lessonType
    motivatingQuestionTwoEn: "", // Will be set based on lessonType
  });
  const [feedback, setFeedback] = useState({
    relevantRating: 0,
    motivatingRating: 0,
  });

  const isDesktop = useBreakpointValue({ base: false, md: true });

  const saveFeedback = (
    teamLessonUserInstanceParticipantId: number,
    englishText: string,
    result: string
  ) => {
    if (!teamLessonUserInstanceParticipantId) return;

    setFeedback((prevFeedback) => {
      const updatedFeedback = {
        ...prevFeedback,
        relevantRating:
          englishText === copy.relevantQuestionOneEn
            ? result === "like"
              ? 100
              : 0
            : prevFeedback.relevantRating,
        motivatingRating:
          englishText === copy.motivatingQuestionTwoEn
            ? result === "like"
              ? 100
              : 0
            : prevFeedback.motivatingRating,
      };

      submitTeamLessonUserInstanceRatingToServer(
        teamLessonUserInstanceParticipantId,
        updatedFeedback.motivatingRating,
        updatedFeedback.relevantRating
      );

      return updatedFeedback;
    });
  };

  useEffect(() => {
    if (isPresenter) {
      setTeamLesson(presenterPathTeamLesson?.teamLesson);
    } else {
      setTeamLesson(participantPathTeamLesson?.teamLesson);
    }
  }, [presenterPathTeamLesson, participantPathTeamLesson, isPresenter]);

  useEffect(() => {
    let lessonSpecificCopy = {
      relevantQuestionOne: HVLocalizeStrings.TEAM_LESSON_SUCCESS_RELEVANT,
      motivatingQuestionTwo: HVLocalizeStrings.TEAM_LESSON_SUCCESS_POSITIVELY,
      relevantQuestionOneEn:
        HVLocalizeStringsObj.en.TEAM_LESSON_SUCCESS_RELEVANT,
      motivatingQuestionTwoEn:
        HVLocalizeStringsObj.en.TEAM_LESSON_SUCCESS_POSITIVELY,
    };

    if (teamLesson?.lessonType === LessonType.Charter) {
      lessonSpecificCopy = {
        relevantQuestionOne: HVSportLocalizeStrings.CHARTER_BETTER_TEAM,
        motivatingQuestionTwo: HVSportLocalizeStrings.CHARTER_RECOMMEND,
        relevantQuestionOneEn:
          HVSportsLocalizeStringsObj.en.CHARTER_BETTER_TEAM,
        motivatingQuestionTwoEn:
          HVSportsLocalizeStringsObj.en.CHARTER_RECOMMEND,
      };
    }

    setCopy((prevCopy) => ({
      ...prevCopy,
      ...lessonSpecificCopy,
    }));
  }, [teamLesson?.lessonType]);

  const currentPath = currentTeam
    ? paths[currentTeam.id]?.find(
        (path: PathDto) => path.id === parseInt(pathId as any)
      )
    : undefined;
  const upNextPathTeamLesson = currentPath?.pathTeamLessons.find(
    (pathTeamLesson: PathTeamLessonDto) =>
      pathTeamLesson.teamLessonInstanceStatus ===
      TeamLessonUserInstanceStatus.UpNext
  );

  const UpNextTeamLessonBlock = withUpNextPresentation(PathTeamLessonBlock);

  useEffect(() => {
    resetTeamSession();
  }, []);

  let content = (
    <Flex
      flexDir={"column"}
      align={"start"}
      minH={"500px"}
      m={isDesktop ? "40px" : "10px"}
      mt={isWebPlatform() ? 4 : "40px"}
      color={MODAL_SECONDARY_TEXT_COLOR}
    >
      <BlockSectionTitle title={copy?.title} />
      <Text color={MODAL_PRIMARY_TEXT_COLOR} mt={4}>
        {copy?.description}
      </Text>
      <Divider my="30px" />
      <Box width={"100%"}>
        <FeedbackPanel
          items={[copy.relevantQuestionOne, copy.motivatingQuestionTwo]}
          items_eng={[copy.relevantQuestionOneEn, copy.motivatingQuestionTwoEn]}
          onResponse={(englishText: string, result: string) => {
            // track
            track(TEAM_EVENTS.TeamLessonFeedback, {
              HV_Question: englishText,
              HV_PracticeId: teamLesson?.id,
              HV_PracticeTitle: teamLesson?.name_loc?.en,
              HV_Response: result === "like" ? "up" : "down",
              HV_App: currentApp,
            });

            saveFeedback(
              currentTeamLessonUserInstanceId as number,
              englishText,
              result
            );
          }}
          onFeedback={(text: string) => {
            // track
            track(TEAM_EVENTS.TeamLessonFeedbackComments, {
              HV_PracticeId: teamLesson?.id,
              HV_PracticeTitle: teamLesson?.name_loc?.en,
              HV_Response: text,
              HV_App: currentApp,
            });

            saveTeamLessonQuestionAnswersToServer(
              currentTeamLessonUserInstanceId as number,
              getFeedbackQuestionAnswers("FeedbackComments", text)
            );
          }}
          onComplete={() => {}}
        />
      </Box>
      <Box
        pos={isDesktop ? "absolute" : "fixed"}
        width={"100%"}
        bottom={0}
        left={0}
        bgColor={`#ffffff${isDesktop ? "aa" : "dd"}`}
        p={"10px"}
        pb={`${10 + safeAreaInsets.bottom}px`}
        display={"block"}
        borderBottomRadius={isDesktop ? Border_Radius : undefined}
        zIndex={1}
        className="bottom-action-bar"
      >
        <SlideFade in={true}>
          <Center filter={"drop-shadow(0 0 3px rgb(255, 255, 255));"} gap={4}>
            <AnimatedButton
              text={HVLocalizeStrings.CLOSE}
              onClick={() => {
                closeFunction();
              }}
              // dataTestId={HVTestId.NanoPracticeModal.nextButton}
            />
          </Center>
        </SlideFade>
      </Box>
      {isPresenter && !teamLessonFeedbackObtained && upNextPathTeamLesson ? (
        <Box w={"100%"}>
          <SlideFade in={true} offsetY={SlideFade_Offset}>
            <UpNextTeamLessonBlock
              pathTeamLesson={upNextPathTeamLesson}
              selectedTeam={currentTeam}
              titleText={HVLocalizeStrings.TEAM_HOME_UP_NEXT}
              customAsyncCallback={() => closeFunction()}
              trackingOrigin={"Presenter Post Presentation Feedback UpNext"}
            />
          </SlideFade>
        </Box>
      ) : null}
    </Flex>
  );

  return <>{content}</>;
};
