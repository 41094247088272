import {
  OptimismPlanInputDto,
  OptimismPlanChallengeDto,
  OptimismPlanDto,
} from "@headversity/contract";
import { AxiosResponse } from "axios";
import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";

export const saveOptimismPlan = (
  optimismPlan: OptimismPlanInputDto,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    "api/optimismTool/saveOptimismPlan",
    optimismPlan,
    getConfig(token)
  );
};

export const saveOptimismChallenge = (
  optimismPlanChallenge: OptimismPlanChallengeDto,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    "api/optimismTool/saveOptimismPlanChallenge",
    optimismPlanChallenge,
    getConfig(token)
  );
};

export const stopCurrentOptimismChallenges = (
  token: string,
  id: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.delete(
    `api/optimismTool/deleteOptimismPlan/${id}`,
    getConfig(token)
  );
};

export const getOptimismPlan = (
  token: string
): Promise<AxiosResponse<OptimismPlanDto[]>> => {
  return AxiosClient.get("api/optimismTool/getOptimismPlan", getConfig(token));
};
