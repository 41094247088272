import { useCallback, useState } from "react";
import { getContent } from "../../Api/Solo/ContentApi";
import { getKey } from "../../Utils/Helpers";
import { ContentDto, NanoPracticeDto } from "@headversity/contract";

export interface IContentProvider {
  content: ContentDto | undefined;
  getContentFromServer: (isAiEnabled: boolean) => Promise<ContentDto>;
}

// Filter nano practice steps based on feature flags
export const filterNanoPracticeSteps = (
  nanoPractices: NanoPracticeDto[],
  isAiEnabled: boolean
) => {
  nanoPractices.forEach((nanoPractice) => {
    if (
      isAiEnabled &&
      nanoPractice.aiSteps &&
      nanoPractice.aiSteps.length > 0
    ) {
      nanoPractice.steps = nanoPractice.aiSteps;
      delete nanoPractice.aiSteps;
    } else if (nanoPractice.aiSteps) {
      delete nanoPractice.aiSteps;
    }
  });
};

export const useContent = (): IContentProvider => {
  const [content, setContent] = useState<ContentDto>();

  const getContentFromServer = useCallback(async (isAiEnabled: boolean) => {
    return getContent(getKey()).then(
      (response) => {
        const content = response.data as ContentDto;
        filterNanoPracticeSteps(content.nanoPractices, isAiEnabled);
        setContent(content);
        return content;
      },
      (error) => {}
    ) as Promise<ContentDto>;
  }, []);

  return {
    content,
    getContentFromServer,
  };
};
