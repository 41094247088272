import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import {
  CustomQuestionDto,
  QuestionAnswerDto,
  QuestionUserResponseDto,
} from "@headversity/contract";
import { AxiosResponse } from "axios";

export const getPrivateCustomQuestions = (
  token: string
): Promise<AxiosResponse<CustomQuestionDto[]>> => {
  return AxiosClient.get("api/privateCustomQuestions", getConfig(token));
};

export const getCustomQuestionAnswerByType = (
  token: string,
  type: string
): Promise<AxiosResponse<QuestionUserResponseDto[]>> => {
  return AxiosClient.get(
    `api/customQuestion/answers?type=${type}`,
    getConfig(token)
  );
};

export const setCustomQuestionAnswer = (
  token: string,
  questionAnswerDto: QuestionAnswerDto
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/customQuestion`,
    questionAnswerDto,
    getConfig(token)
  );
};
