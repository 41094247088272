import { Flex, FormControl, IconButton, Image, Input } from "@chakra-ui/react";
import {
  AUTH0_HIDDEN_PASSWORD_ICON_COLOR,
  AUTH0_SHOW_PASSWORD_ICON_COLOR,
  Border_Radius,
  FORM_HEADER_BACKGROUND_COLOR,
  INPUT_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";

import { HiEye } from "react-icons/hi";
import { ErrorText } from "./ErrorText";
import React from "react";
import { RegistrationLoginStates } from "@headversity/contract";

type ChangeHandlerWithoutIsValid = (
  stateToUpdate: RegistrationLoginStates,
  stateText: string
) => void;

type ChangeHandlerWithIsValid = (
  stateToUpdate: RegistrationLoginStates,
  stateText: string,
  isValid: boolean
) => void;

interface FormInputFieldProps {
  id: string;
  imageSrc: string;
  imageW: string;
  imageH: string;
  placeholder: string;
  handleChange: ChangeHandlerWithoutIsValid | ChangeHandlerWithIsValid;
  stateToUpdate: RegistrationLoginStates;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  type: string;
  setShowPassword?: (showPassword: boolean) => void;
  showPassword?: boolean;
  value?: string;
  errorMessage?: string;
  dataTestId?: string;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  border?: string;
  width?: string;
}

const FormInputField = (props: FormInputFieldProps) => {
  const {
    id,
    imageSrc,
    imageH,
    imageW,
    placeholder,
    handleChange,
    stateToUpdate,
    onKeyDown,
    type,
    setShowPassword,
    showPassword,
    value,
    errorMessage,
    dataTestId,
    autoFocus,
    onFocus: handleFocus,
    onBlur: handleBlur,
    border,
    width,
  } = props;
  return (
    <>
      <FormControl
        display={"flex"}
        w={width ?? "100%"}
        mx={"auto"}
        border={border ?? undefined}
        borderRadius={Border_Radius}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          w={"45px"}
          bgColor={FORM_HEADER_BACKGROUND_COLOR}
          borderLeftRadius={"5px"}
        >
          <Image src={imageSrc} w={imageW} h={imageH} alt="" />
        </Flex>
        {type === "password" ? (
          <>
            <Input
              id={id}
              background={"white"}
              border={"none"}
              borderRadius={"none"}
              placeholder={placeholder}
              type={showPassword ? "text" : "password"}
              autoFocus={autoFocus ? true : undefined}
              onChange={(e) => {
                handleChange(
                  stateToUpdate,
                  e.currentTarget.value,
                  e.currentTarget.validity.valid
                );
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              fontSize={"14px"}
              color={INPUT_TEXT_COLOR}
              fontWeight={400}
              h="40px"
              w="207px"
              onKeyDown={(e) => {
                if (onKeyDown) {
                  onKeyDown(e);
                } else {
                  return undefined;
                }
              }}
              data-testid={dataTestId}
            />
            <IconButton
              borderLeftRadius={"none"}
              background="white"
              variant="link"
              aria-label={showPassword ? "Mask password" : "Reveal password"}
              icon={<HiEye />}
              onClick={() => {
                if (setShowPassword) setShowPassword(!showPassword);
              }}
              color={
                showPassword
                  ? AUTH0_SHOW_PASSWORD_ICON_COLOR
                  : AUTH0_HIDDEN_PASSWORD_ICON_COLOR
              }
              size={"lg"}
            />
          </>
        ) : (
          <Input
            id={id}
            background={"white"}
            border={"none"}
            borderLeftRadius={"none"}
            placeholder={placeholder}
            type={type}
            autoFocus={autoFocus ? true : undefined}
            onChange={(e) => {
              handleChange(
                stateToUpdate,
                e.currentTarget.value,
                e.currentTarget.validity.valid
              );
            }}
            fontSize={"14px"}
            color={INPUT_TEXT_COLOR}
            fontWeight={400}
            value={value}
            h="40px"
            w="255px"
            onKeyDown={(e) => {
              if (onKeyDown) {
                onKeyDown(e);
              } else {
                return undefined;
              }
            }}
            data-testid={dataTestId}
          />
        )}
      </FormControl>
      <ErrorText message={errorMessage}></ErrorText>
    </>
  );
};

export default FormInputField;
