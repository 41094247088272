import {
  CertDto,
  CertQualificationDto,
  CompletionState,
  SurveyType,
} from "../@headversity/contract";
import { CertViewModel } from "../Components/Cert/CertViewModels";
import { HVLocalizeStrings } from "../Localization/HVLocalizeStrings";

export const isTimeForQualification = (
  certQualification: CertQualificationDto | undefined
): boolean => {
  return (
    certQualification !== undefined &&
    certQualification.pointsEarned < certQualification.pointsNeeded
  );
};

export const getCertViewModelFromCert = (c: CertDto) => {
  const cert = {} as CertViewModel;

  cert.certification = c;
  cert.steps = [];

  const instance = c.certInstances.length > 0 ? c.certInstances[0] : undefined;

  // flatten the cert model into steps
  const pre = c.surveys.find((x) => x.type === SurveyType.Pre);

  // pre
  if (pre) {
    const preSurveyInstance = instance
      ? instance.surveyInstances.find((x) => x.surveyId === pre?.id)
      : undefined;

    cert.steps.push({
      preInstructions: pre.preInstructions as string,
      postInstructions: pre.postInstructionsPass as string,
      preTitle: cert.certification.name as string,
      postTitle:
        (cert.certification.name as string) +
        ` - ${HVLocalizeStrings.CERT_SECTION} 1`,
      description: HVLocalizeStrings.CERT_GETTING_STARTED,
      surveyInstance: preSurveyInstance,
      isStarted: preSurveyInstance?.state !== CompletionState.notStarted,
      isDone: preSurveyInstance?.state === CompletionState.done,
    });
  }

  // sections
  c.certSections.forEach((s, idx) => {
    const sectionInstance = instance
      ? instance.certSectionInstances.find((x) => x.certSectionId === s.id)
      : undefined;

    cert.steps.push({
      section: s,
      sectionInstance: sectionInstance,
      preTitle: s.name as string,
      description: `${HVLocalizeStrings.CERT_SECTION} ${idx + 1}`,
      isStarted: sectionInstance?.state !== CompletionState.notStarted,
      isDone: sectionInstance?.state === CompletionState.done,
    });

    const exam = s.surveys.find((x) => x.type === SurveyType.SectionExam);
    const examInstance = sectionInstance
      ? sectionInstance.surveyInstances[0]
      : undefined;

    if (exam) {
      cert.steps.push({
        preInstructions: exam.preInstructions as string,
        postInstructions: exam.postInstructionsPass as string,
        preTitle: s.name as string,
        postTitle: s.name + ` ${HVLocalizeStrings.COMPLETE}`,
        description: HVLocalizeStrings.CERT_CHECKPOINT,
        surveyInstance: examInstance,
        isStarted: examInstance?.state !== CompletionState.notStarted,
        isDone: examInstance?.state === CompletionState.done,
      });
    }
  });

  // post
  const post = c.surveys.find((x) => x.type === SurveyType.Post);

  if (post) {
    const postSurveyInstance = instance
      ? instance.surveyInstances.find((x) => x.surveyId === post?.id)
      : undefined;

    cert.steps.push({
      preInstructions: post.preInstructions as string,
      postInstructions: post.postInstructionsPass as string,
      preTitle:
        (cert.certification.name as string) +
        ` - ${HVLocalizeStrings.CERT_REFLECTIONS}`,
      postTitle: " ", // no header when complete
      description: HVLocalizeStrings.CERT_REFLECTIONS,
      surveyInstance: postSurveyInstance,
      isStarted: postSurveyInstance?.state !== CompletionState.notStarted,
      isDone: postSurveyInstance?.state === CompletionState.done,
    });
  }

  return cert;
};
