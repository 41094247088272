import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";
import {
  DayInputDto,
  UserScoreDto,
  UserStatsResultsDto,
} from "@headversity/contract";

export const setUserSkills = (
  token: string,
  day: DayInputDto
): Promise<AxiosResponse<UserStatsResultsDto>> => {
  return AxiosClient.post(
    "api/skills/userStatsWithQuals",
    day,
    getConfig(token)
  );
};

export const getSkillsHistory = (
  token: string
): Promise<AxiosResponse<UserScoreDto[]>> => {
  return AxiosClient.get("api/skills/history", getConfig(token));
};
