import { useCallback, useState } from "react";
import { getFeatureImplementations } from "../../Api/Solo/FeatureFlagApi";
import { getKey, parseJSON } from "../../Utils/Helpers";
import { FeatureImplementationViewDto } from "@headversity/contract";

export interface IFeatureImplementationsProvider {
  featureImplementations: FeatureImplementationViewDto[] | null;
  getFeatureImplementationsFromServer: () => Promise<
    FeatureImplementationViewDto[]
  >;
  getFeatureVersion: (
    featureCode: string,
    features?: FeatureImplementationViewDto[]
  ) => object | string | null | undefined;

  hasFeature: (
    featureCode: string,
    features?: FeatureImplementationViewDto[]
  ) => boolean;
}

export const useFeatureImplementations = () => {
  const [featureImplementations, setFeatureImplementations] = useState<
    FeatureImplementationViewDto[] | null
  >(null);

  const getFeatureImplementationsFromServer = useCallback(async () => {
    return getFeatureImplementations(getKey()).then((response) => {
      const features = response.data;
      setFeatureImplementations(features);
      return features;
    }) as Promise<FeatureImplementationViewDto[]>;
  }, []);

  /*
   * Returns:
   * - undefined if the feature is not enabled.
   * - null if the feature is enabled w/ no overrides,
   * - the json details of the overrides if the feature is enabled w/ overrides.
   */
  const getFeatureVersion = (
    featureCode: string,
    features?: FeatureImplementationViewDto[]
  ): object | string | null | undefined => {
    let feature: FeatureImplementationViewDto | null | undefined = null;

    // check for override in local storage
    // if none, use version from backend
    const item = localStorage && localStorage.getItem(`feature-${featureCode}`);
    if (item != null) {
      feature = parseJSON(item);
    } else if (features) {
      feature = features.find((x: any) => x.featureCode === featureCode);
    } else if (featureImplementations) {
      feature = featureImplementations.find(
        (x: any) => x.featureCode === featureCode
      );
    }

    return feature && feature.details !== undefined
      ? feature.details
      : feature
      ? null
      : undefined;
  };

  const hasFeature = (
    featureCode: string,
    features?: FeatureImplementationViewDto[]
  ): boolean => {
    return getFeatureVersion(featureCode, features) !== undefined;
  };

  return {
    getFeatureImplementationsFromServer,
    featureImplementations,
    getFeatureVersion,
    hasFeature,
  };
};
