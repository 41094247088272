import { useContext, useState } from "react";
import { LargeModal } from "../../Common/LargeModal";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Flex, Heading, Spinner, Text, useToast } from "@chakra-ui/react";
import {
  DESTRUCTIVE_SECONDARY_TEXT_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
  Success,
} from "../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../Utils/Analytics";
import { HvTextInput } from "../../Common/HvTextInput";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { createToast } from "../../../Utils/toastUtil";

interface UpdateNameModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const UpdateNameModal = (props: UpdateNameModalProps) => {
  const { self, updateFullNameToServer } =
    useContext<IGlobalProvider>(GlobalContext);

  const { open, setOpen } = props;

  const [currentFullName, setCurrentFullName] = useState<string | undefined>(
    self?.fullName
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toast = useToast();
  const isValidFullName = () => {
    return (
      !isSubmitting &&
      currentFullName &&
      currentFullName !== self?.fullName &&
      currentFullName.trim().includes(" ")
    );
  };
  const showFullNameError = () => {
    return (
      currentFullName !== self?.fullName && !isSubmitting && !isValidFullName()
    );
  };

  const handleSaveFullNameClick = () => {
    if (!isValidFullName()) {
      return;
    }
    setIsSubmitting(true);
    updateFullNameToServer(currentFullName ?? "")
      .then(() => {
        createToast(
          toast,
          Success,
          "updateFullName",
          HVLocalizeStrings.NAME_UPDATED
        );
        track(EVENTS.FullNameChanged);
      })
      .finally(() => {
        setOpen(false);
        setIsSubmitting(false);
      });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCurrentFullName(event.target.value);
  };

  return (
    <LargeModal
      open={open}
      setOpen={setOpen as any}
      onCloseTasks={() => {
        setCurrentFullName(self?.fullName);
      }}
    >
      <Flex flexDir={"column"} alignItems={"center"}>
        <Heading
          fontSize={"23px"}
          color={MODAL_PRIMARY_TEXT_COLOR}
          fontWeight={"600"}
        >
          {HVLocalizeStrings.PROFILE_UPDATE_NAME}
        </Heading>
      </Flex>
      <Flex
        w={"100%"}
        flexDir={"column"}
        alignItems={"center"}
        mt={10}
        mb="50px"
      >
        <HvTextInput
          maxW={"70%"}
          value={currentFullName}
          onChange={(event) => handleChange(event)}
          onEnterKeyDown={handleSaveFullNameClick}
          mb={!showFullNameError() ? 10 : 1}
          disabled={isSubmitting}
        />
        {showFullNameError() && (
          <Text
            fontSize={"xs"}
            color={DESTRUCTIVE_SECONDARY_TEXT_COLOR}
            mb={10}
          >
            {HVLocalizeStrings.FULL_NAME_NEEDS_CONTAIN_FIRST_LAST_NAME}
          </Text>
        )}
        {isSubmitting ? (
          <Spinner />
        ) : (
          <AnimatedButton
            text={HVLocalizeStrings.SAVE}
            disabled={!isValidFullName()}
            colorSet={AnimatedButtonColorSet.Secondary}
            onClick={() => {
              handleSaveFullNameClick();
            }}
            w={300}
          />
        )}
      </Flex>
    </LargeModal>
  );
};
