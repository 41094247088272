import React, { useEffect, useState } from "react";
import { Center, Image, Box, Text, useBreakpointValue } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../../Styles/HeadversityStyle";
import { EVENTS, track } from "../../../../Utils/Analytics";
import { HVTestId } from "../../../../Testing/dataTestIds";
import { isWebPlatform } from "../../../../Utils/mobileUtils";

interface RoundSliderProps {
  getBrainImage: (percentage: number) => string;
  getHeadZoneStateText: (headZoneState: number) => string;
  setHeadZoneValue: (headZoneValue: number) => void;
  headZoneColorValue: any;
  readOnly?: boolean;
  value?: number;
  dataTestId?: string;
  practiceId?: number;
  useTheme?: boolean;
}

const DEFAULT_OPACITY = 0.6;
const DEFAULT_OPACITY_AFTER_SELECTION = 0.3;

export const HeadZoneToolRoundSlider = (props: RoundSliderProps) => {
  // NOTE: these variables will need some work for a different # of total circles
  const TOTAL_CIRCLES = 50;
  const TOTAL_CIRCLES_VISIBLE = 32;

  const [supportKeyboard, setSupportKeyboard] = useState(false);
  const [radius, setRadius] = useState(0);

  const [over, setOver] = useState(false);
  const [down, setDown] = useState(false);
  const [downOnItem, setDownOnItem] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [dragStartPercent, setDragStartPercent] = useState<number>();
  const [allowOutsideDrag, setAllowOutsideDrag] = useState(true);

  const [isReady, setIsReady] = useState(false);
  const [origin, setOrigin] = useState({ x: 0, y: 0 });
  const [opacities, setOpacities] = useState<any[]>([]);
  const [percent, setPercent] = useState<number>(
    (props.value ?? undefined) as any
  );
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const [hoverItem, setHoverItem] = useState<number>(-1);

  const isDesktop = useBreakpointValue({ base: false, sm: true });

  // generally consistent, but a few not
  // #ee414b => #c5bdcc => #00b1ff
  const colors = [
    "#e3372a",
    "#df3a30",
    "#db3e35",
    "#d64540",

    "#c65047",
    "#c6544c",
    "#c5605b",
    "#c47a7a",

    "#c4878a",
    "#c4949a",
    "#c3a0a9",
    "#c3a5af",

    "#c9a4af",
    "#c8abb7",
    "#c6b2be",
    "#c4b9c6",

    "#c0bdcc",
    "#b9bccf",
    "#b1bbd2",
    "#a9bad5",

    "#a2b9d8",
    "#9bb8db",
    "#93b7de",
    "#8cb6e0",

    "#84b5e4",
    "#7db4e6",
    "#75b3e9",
    "#6fb2ec",

    "#67b1ef",
    "#5fb0f2",
    "#59b0f5",
    "#4faef8",
  ];

  // build percents list (i.e. 0.025, 0.05, 0.075, 0.1, 0.125, etc.)
  const percents: any[] = [];
  for (let x = 1; x <= TOTAL_CIRCLES_VISIBLE; x++) {
    percents.push(x / TOTAL_CIRCLES_VISIBLE);
  }

  const getColor = (item: number) => {
    return colors[item - 1];
  };

  const applyDeltaFromDrag = (e: any) => {
    if (!percent || !dragStartPercent || origin.x === 0 || origin.y === 0)
      return;

    const deltaX = (e.clientX - origin.x) / 10;
    const deltaY = (e.clientY - origin.y) / 10;

    // left/right drag can always decrease/increase,
    //  but y axis changes based on if we're on the left or right of the screen
    const deltaToUse =
      deltaX * 2.5 + (percent < 25 ? -deltaY : percent > 75 ? deltaY : 0);
    const p = dragStartPercent + deltaToUse;

    applyPercent(p);
  };

  const applyPercent = (p: any, temporary = false) => {
    if (!temporary) {
      const val = Math.min(100, p);
      setPercent(val);
      props.setHeadZoneValue(Math.round(val));
    }

    //console.log('applyPercent', p, temporary, percent)

    let maxShow = -1;
    const opacities: any[] = [];
    percents.forEach((item, idx) => {
      const show = p >= item * 100;

      if (show) {
        maxShow = idx;
      }

      if (!temporary) {
        if (maxShow === -1) {
          setSelectedIndex(-1);
        } else {
          setSelectedIndex(maxShow + 1);
        }
      }

      opacities.push(
        show
          ? !percent || (temporary && item * 100 > percent)
            ? DEFAULT_OPACITY
            : 0.9
          : DEFAULT_OPACITY_AFTER_SELECTION
      );
    });

    setOpacities(opacities);
  };

  const handleCircleClick = (item: any, temporary = false) => {
    const n = Number((item / percents.length).toFixed(5));
    const p = n * 100;

    applyPercent(p, temporary);

    return p;
  };

  useEffect(() => {
    let radius = 180;
    if (!isDesktop) {
      // we want 135 at 375px
      radius = 135;

      // plus...
      // 415 => 150
      // 375 => 135
      const extra = window.innerWidth - 375;
      if (extra > 0) {
        radius += extra / 3;
      }

      radius = Math.min(radius, 180);
    }
    setRadius(radius);
  }, [isDesktop]);

  useEffect(() => {
    const opacities: any[] = [];
    percents.forEach(() => {
      opacities.push(0.8);
    });
    setOpacities(opacities);

    // we'll add keyboard support only after the user hits 'Tab' key, so we don't have to show focused element
    document.addEventListener(
      "keydown",
      (e: any) => {
        if (e.key === "Tab") {
          setSupportKeyboard(true);
        }
      },
      false
    );

    setTimeout(() => {
      const opacities: any[] = [];
      percents.forEach(() => {
        opacities.push(DEFAULT_OPACITY_AFTER_SELECTION);
      });
      setOpacities(opacities);
      setIsReady(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (props.value !== undefined) {
      let item = props.value * 0.01 * 32;
      item = Math.ceil(item);
      if (item > 32) {
        item = 32;
      }
      handleCircleClick(item);
    }
  }, [props.value]);

  return (
    <Box
      pointerEvents={isReady ? undefined : "none"}
      cursor={dragging ? "col-resize" : "default"}
      position="relative"
      style={{
        touchAction: "none",
      }}
      onTouchStart={(e) => {
        if (e.touches.length === 0) return;

        const container = document.querySelector(".slider-container");
        if (!container) return;

        // scale to (0, 0) based on screen position of the dial
        // TODO: is this y consistent?
        const x = e.touches[0].clientX - 50;
        const y =
          e.touches[0].clientY - (container.getBoundingClientRect().top + 50);

        //console.log("touch start", x, y, downOnItem);

        if (downOnItem) return;

        if (x < 0 || x > 275 || y < 0) return;

        // select the closest circle
        let index;

        // upper middle
        if (x > 110 && x < 175 && y < 80) {
          index = 18;
        } else if (y > 100) {
          if (x < 125) {
            // lower left
            index = 4;
          } else {
            // lower right
            index = 30;
          }
        } else {
          if (x < 125) {
            // upper left
            index = 12;
          } else {
            // upper right
            index = 22;
          }
        }

        const p = handleCircleClick(index);
        setDragStartPercent(p);

        setDragging(true);
      }}
      onPointerDown={(e) => {
        //console.log('down container')
        if (props?.readOnly) {
          return;
        }
        setDown(true);
        setOrigin({ x: e.clientX, y: e.clientY });
      }}
      onPointerMove={(e) => {
        //console.log('onPointerMove')
        if (props?.readOnly) {
          return;
        }
        if (dragging && allowOutsideDrag) {
          applyDeltaFromDrag(e);
        }
      }}
      onPointerUp={(e) => {
        //console.log("up container");
        if (props?.readOnly) {
          return;
        }
        setDown(false);
        setDownOnItem(false);
        if (dragging) {
          setDragging(false);
        }
        // down and up in same spot = not over
        if (origin.x === e.clientX && origin.y === e.clientY) {
          setOver(false);
        }
        track(
          EVENTS.ToolHeadzoneOptionSelected,
          props.practiceId
            ? {
                HV_HeadzoneValue: Math.round(percent),
                HV_PracticeId: props.practiceId,
              }
            : {
                HV_HeadzoneValue: Math.round(percent),
              }
        );
      }}
      onPointerLeave={(e) => {
        //console.log('out container')
        if (props?.readOnly) {
          return;
        }
        setDragging(false);
        setDown(false);
        setDownOnItem(false);
      }}
    >
      <Center
        className="slider-container"
        data-testid={HVTestId.HeadZoneToolRoundSlider.container}
      >
        <Box
          pos={"absolute"}
          top={isDesktop ? "100px" : "80px"}
          pointerEvents="none"
          userSelect="none"
        >
          <Image
            w={isDesktop ? "90px" : "75px"}
            src={props.getBrainImage(percent || 50)}
            alt=""
            m="auto"
            filter={
              !props.useTheme
                ? "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4))"
                : undefined
            }
          />
        </Box>

        <Box
          pos={"absolute"}
          bottom={isDesktop ? "20px" : "65px"}
          pointerEvents="none"
          userSelect="none"
        >
          {percent > 0 && (
            <Box>
              <Text
                maxW="230px"
                textAlign={"center"}
                color={`${props.headZoneColorValue}`}
                fontSize={isDesktop ? "2xl" : "xl"}
                lineHeight="1.2"
                transition="all 0.2s ease"
                filter={
                  !props.useTheme
                    ? "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4))"
                    : undefined
                }
                data-testid={HVTestId.HeadZoneToolRoundSlider.zoneText}
                dangerouslySetInnerHTML={{
                  __html: props.getHeadZoneStateText(percent),
                }}
              />
            </Box>
          )}
          {!props.readOnly && !percent && (
            <Text
              mt={8}
              opacity={dragging || opacities.length === 0 ? 0 : 1}
              fontSize={"sm"}
              textAlign="center"
              transition="all 0.2s ease"
              color={props.useTheme ? MODAL_PRIMARY_TEXT_COLOR : "white"}
            >
              {isDesktop
                ? HVLocalizeStrings.HEAD_ZONE_TOOLS_HELP_TEXT
                : HVLocalizeStrings.HEAD_ZONE_TOOLS_HELP_TEXT_MOBILE}
            </Text>
          )}
        </Box>
        <svg width={isDesktop ? 395 : window.outerWidth - 60} height={300}>
          {Array.from(Array(TOTAL_CIRCLES_VISIBLE).keys()).map((arrIndex) => {
            const item = arrIndex + 1;

            const theta = (Math.PI * 2) / TOTAL_CIRCLES;
            const angle =
              theta * (item + TOTAL_CIRCLES / (isDesktop ? 2.38 : 2.32)); // shifts to where we want to start

            const x = radius * Math.cos(angle);
            const y = radius * Math.sin(angle) + 5;

            const color = getColor(item);

            return (
              <circle
                tabIndex={supportKeyboard ? 0 : undefined}
                role={supportKeyboard ? "button" : undefined}
                key={item}
                cursor={
                  dragging
                    ? "col-resize"
                    : props?.readOnly
                    ? "default"
                    : "pointer"
                }
                fill={color}
                opacity={
                  !isDesktop && arrIndex % 2 === 1
                    ? 0
                    : selectedIndex === item
                    ? 1
                    : opacities[item - 1]
                }
                stroke={selectedIndex === item ? "white" : `#ffffff66`}
                filter={
                  isWebPlatform() && selectedIndex === item
                    ? "drop-shadow(0px 0px 2px #4444dd)"
                    : undefined
                }
                r={
                  selectedIndex === item || hoverItem === item
                    ? isDesktop
                      ? 16
                      : 20
                    : selectedIndex === item - 1 ||
                      selectedIndex === item + 1 ||
                      hoverItem === item - 1 ||
                      hoverItem === item + 1
                    ? isDesktop
                      ? 13
                      : 19
                    : isDesktop
                    ? 11
                    : 17
                }
                cx={x + radius * 1.12 + (isDesktop ? -5 : 5)}
                cy={y + radius * 1.12}
                style={{
                  transition: "all 0.5s ease",
                  touchAction: "none",
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleCircleClick(item);

                    track(EVENTS.ToolHeadzoneOptionSelected, {
                      HV_HeadzoneValue: Math.round(percent),
                    });
                  }
                }}
                onPointerOver={(evt) => {
                  if (props?.readOnly) {
                    return;
                  }
                  //console.log('pointer over', item, dragging)
                  setOver(true);
                  if (dragging) return;
                  setHoverItem(item);
                  handleCircleClick(item, true);
                  /*
                  if (!dragging) return;

                  setAllowOutsideDrag(false);
                  handleCircleClick(item);
                  */
                }}
                onPointerOut={(evt) => {
                  if (props?.readOnly) {
                    return;
                  }

                  setOver(false);
                  setHoverItem(-1);
                  applyPercent(percent);
                  if (!dragging) return;

                  setAllowOutsideDrag(true);
                }}
                onPointerDown={(evt) => {
                  if (props?.readOnly) {
                    return;
                  }
                  //console.log('down item', item)
                  //setDragging(true);
                  setDownOnItem(true);

                  const p = handleCircleClick(item);
                  setDragStartPercent(p);
                }}
                onPointerMove={(evt) => {
                  if (props?.readOnly) {
                    return;
                  }
                  //console.log('move item', item)
                  if (down) {
                    setHoverItem(-1);
                    setDragging(true);
                  }
                }}
                /* only valid for desktop */
                onMouseMove={(evt) => {
                  /*
                  console.log('move item', item)
                  if (!dragging) return;

                  //handleCircleClick(item);
                  */
                }}
                /* only valid for desktop */
                onMouseUp={(evt) => {
                  if (props?.readOnly) {
                    return;
                  }
                  //console.log('up item', item, over)
                  if (!over) {
                    handleCircleClick(item);
                  }
                }}
                data-testid={props.dataTestId}
              />
            );
          })}
        </svg>
      </Center>
    </Box>
  );
};
