import React, { useCallback, useContext, useEffect, useState } from "react";
import { IGlobalProvider, GlobalContext } from "../../../State/GlobalContext";
import { LargeModal } from "../../Common/LargeModal";
import { PagerState } from "../../Common/Pager";
import { isWaitingForCustomFieldResponses } from "../../../Utils/CustomQuestionUtil";
import {
  Dictionary,
  LocalizationText,
  QuestionAlternativeDto,
} from "@headversity/contract";
import { CustomFieldSelect } from "./CustomFieldSelect";
import { HVTestId } from "../../../Testing/dataTestIds";
import _ from "lodash";

interface CustomFieldModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  preventClose: boolean;
}

export const CustomFieldModal = (props: CustomFieldModalProps) => {
  const {
    customFields,
    userCustomFieldResponses,
    setCustomQuestionAnswerToServer,
  } = useContext<IGlobalProvider>(GlobalContext);
  const { open, setOpen, preventClose } = props;

  const [customFieldPager, setCustomFieldPager] = useState<PagerState>({
    currentPage: 0,
    totalPage: customFields ? customFields?.length : 0,
    pagePerRow: 1,
    showPageInput: false,
  });

  const [ungroupedQuestionAlternatives, setUngroupedQuestionAlternatives] =
    useState<QuestionAlternativeDto[]>();
  const [groupedQuestionAlternatives, setGroupedQuestionAlternatives] =
    useState<Dictionary<QuestionAlternativeDto[]> | undefined>(undefined);
  const [groupings, setGroupings] = useState<string[] | undefined>(undefined);
  const [
    defaultSelectedQuestionAlternativeId,
    setDefaultSelectedQuestionAlternativeId,
  ] = useState<number | undefined>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (customFields && customFields?.length > 0) {
      setCustomFieldPager({
        ...customFieldPager,
        totalPage: customFields.length,
      });
    }
  }, [customFields]);

  useEffect(() => {
    if (userCustomFieldResponses && customFields && customFields?.length > 0) {
      if (customFieldPager.currentPage === customFields?.length) {
        setCustomFieldPager({ ...customFieldPager, currentPage: 0 });
        setOpen(false);
      } else {
        setCurrentCustomField(customFieldPager.currentPage);
      }
    }
  }, [customFields, userCustomFieldResponses]);

  const setCurrentCustomField = (currentPage: number) => {
    if (customFields) {
      const newCustomField = customFields[currentPage];
      if (
        newCustomField?.question?.questionText &&
        newCustomField.question.questionAlternatives?.length > 0
      ) {
        const questionAlternatives =
          newCustomField.question.questionAlternatives.sort(
            (a, b) => (a.scale ?? 0) - (b.scale ?? 0)
          );

        const groupedObj = _.groupBy(questionAlternatives, (item) => {
          return item.grouping ?? "none";
        });
        const groupKeys = Object.keys(groupedObj).sort();
        if (groupKeys.find((group) => group !== "none")) {
          const currentGroupedQuestionAlternativeDict = {} as Dictionary<
            QuestionAlternativeDto[]
          >;
          const currentGroupings = Object.keys(groupedObj).filter(
            (grouping) => grouping !== "none"
          );

          currentGroupings.forEach((grouping) => {
            currentGroupedQuestionAlternativeDict[grouping] =
              groupedObj[grouping];
          });

          setGroupings(currentGroupings);
          setGroupedQuestionAlternatives(currentGroupedQuestionAlternativeDict);
        } else {
          setGroupedQuestionAlternatives(undefined);
          setGroupings(undefined);
        }

        setUngroupedQuestionAlternatives(groupedObj["none"] ?? []);
        if (userCustomFieldResponses) {
          const currentSavedUserCustomField = userCustomFieldResponses
            .sort((a, b) => b.id - a.id)
            .find(
              (item) => item.questionId === customFields[currentPage].questionId
            );
          setDefaultSelectedQuestionAlternativeId(
            currentSavedUserCustomField?.questionAlternativeId
          );
        } else {
          setDefaultSelectedQuestionAlternativeId(undefined);
        }
      } else {
        setDefaultSelectedQuestionAlternativeId(undefined);
        setUngroupedQuestionAlternatives(undefined);
        setGroupedQuestionAlternatives(undefined);
        setGroupings(undefined);
      }
    }
  };

  const saveSelection = useCallback(
    (selectedQuestionAlternativeId: number) => {
      setIsSaving(true);

      if (!customFields) {
        return;
      }

      const question = customFields[customFieldPager.currentPage]?.question;

      if (!question) {
        return;
      }

      let questionAlternativeText = question.questionAlternatives.find(
        (questionAlternative) =>
          questionAlternative.id.toString() ===
          selectedQuestionAlternativeId.toString()
      )?.alternativeText_loc;

      if (!questionAlternativeText) {
        return;
      }

      setCustomQuestionAnswerToServer({
        question: question.questionText_loc as LocalizationText,
        hint: question.uniqueHint,
        questionAlternatives: [questionAlternativeText as LocalizationText],
        questionType:
          customFields[customFieldPager.currentPage].question.questionType,
        responses: [{ response: questionAlternativeText }],
        type: "Custom Field",
      }).then(() => {
        setIsSaving(false);
        let newCurrentPage = customFieldPager.currentPage + 1;
        if (customFieldPager.currentPage + 1 === customFields?.length) {
          newCurrentPage = 0;
          setDefaultSelectedQuestionAlternativeId(-1);
          setOpen(false);
        }
        setCustomFieldPager({
          ...customFieldPager,
          currentPage: newCurrentPage,
        });
      });
    },
    [customFields, customFieldPager]
  );

  const backClick = useCallback(() => {
    setCustomFieldPager({
      ...customFieldPager,
      currentPage: customFieldPager.currentPage - 1,
    });
    setCurrentCustomField(customFieldPager.currentPage - 1);
  }, [customFields, customFieldPager]);

  return (
    <>
      <LargeModal
        open={open}
        setOpen={setOpen as any}
        preventClose={preventClose}
        dataTestId={HVTestId.CustomFieldModal.modal}
      >
        {customFields &&
          customFields[customFieldPager.currentPage]?.question?.questionText !==
            undefined &&
          ((ungroupedQuestionAlternatives !== undefined &&
            ungroupedQuestionAlternatives?.length) > 0 ||
            groupedQuestionAlternatives !== undefined) && (
            <CustomFieldSelect
              title={
                customFields[customFieldPager.currentPage]?.question
                  ?.questionText as string
              }
              ungroupedQuestionAlternatives={ungroupedQuestionAlternatives}
              groupedQuestionAlternatives={groupedQuestionAlternatives}
              groupings={groupings}
              defaultSelectedQuestionAlternativeId={
                defaultSelectedQuestionAlternativeId
              }
              isLastCustomField={
                customFieldPager.currentPage + 1 === customFields?.length
              }
              pager={customFieldPager}
              backClick={backClick}
              saveSelection={saveSelection}
              isSaving={isSaving}
            />
          )}
      </LargeModal>
    </>
  );
};
