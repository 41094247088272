import { Box, Flex, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  Psycho_Neutral_Light_Purple,
  Psycho_Risk_Light_Red,
  Psycho_Strength_Light_Blue,
  REACH_INSIGHTS_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { isMobile } from "react-device-detect";

interface InsightsPsychographicsLegendProps {
  pdfExport?: boolean;
}

interface LegendItemProps {
  bg: string;
  mx: number;
  text: string;
  smallMode: boolean;
}

const LegendItem = (props: LegendItemProps) => {
  return (
    <Flex alignItems="center" mx={props.mx}>
      <Box
        width="20px"
        height="20px"
        bg={props.bg}
        borderRadius="50%"
        mr={props.smallMode ? 1.5 : 2}
      />
      <Text color={REACH_INSIGHTS_TEXT_COLOR}>{props.text}</Text>
    </Flex>
  );
};

const InsightsPsychographicsLegend = (
  props: InsightsPsychographicsLegendProps
) => {
  const { selectedUserLanguage } = useContext<IGlobalProvider>(GlobalContext);
  const smallMode = isMobile && selectedUserLanguage !== "en";

  return (
    <Flex
      justifyContent={props.pdfExport ? "flex-start" : "center"}
      mt={props.pdfExport ? undefined : "20px"}
      mb={props.pdfExport ? "10px" : undefined}
      fontSize={smallMode ? "xs" : undefined}
    >
      {props.pdfExport && (
        <Text color={REACH_INSIGHTS_TEXT_COLOR} mr={3}>
          {HVLocalizeStrings.REACH_INSIGHTS_PSYCHOGRAPHICS_TITLE}:
        </Text>
      )}
      <LegendItem
        bg={Psycho_Strength_Light_Blue}
        mx={smallMode ? 1 : 3}
        text={HVLocalizeStrings.REACH_INSIGHTS_LEGEND_AGREE}
        smallMode={smallMode}
      />
      <LegendItem
        bg={Psycho_Neutral_Light_Purple}
        mx={smallMode ? 1 : 3}
        text={HVLocalizeStrings.REACH_INSIGHTS_LEGEND_NEUTRAL}
        smallMode={smallMode}
      />
      <LegendItem
        bg={Psycho_Risk_Light_Red}
        mx={smallMode ? 1 : 4}
        text={HVLocalizeStrings.REACH_INSIGHTS_LEGEND_DISAGREE}
        smallMode={smallMode}
      />
    </Flex>
  );
};

export default InsightsPsychographicsLegend;
