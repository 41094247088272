import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface CrossFadeImageProps {
  src: string;
  baseImage?: string;
  containerPos?: any;
  width?: string;
  height?: string;
  bgRepeat?: string;
  bgSize?: string;
  bgPos?: string;
  transitionSeconds?: number;
  borderRadius?: string;
}

export const CrossFadeImage = ({
  src,
  baseImage,
  containerPos,
  width,
  height,
  bgRepeat,
  bgSize,
  bgPos,
  transitionSeconds,
  borderRadius,
}: CrossFadeImageProps) => {
  const [fadeDir, setFadeDir] = useState(false);
  const [imgA, setImgA] = useState<string>();
  const [imgB, setImgB] = useState<string>();

  useEffect(() => {
    // load the new image in the container that is hidden
    if (fadeDir) {
      setImgB(src);
    } else {
      setImgA(src);
    }

    // flip toggle to start the transition
    setFadeDir(!fadeDir);
  }, [src]);

  return (
    <Box
      pos={containerPos}
      height={height ?? "100%"}
      width={width ?? "100%"}
      bgImage={baseImage}
      bgRepeat={bgRepeat ?? "no-repeat"}
      bgSize={bgSize ?? "cover"}
      bgPos={bgPos ?? undefined}
    >
      <Box
        pos={"absolute"}
        width="100%"
        height="100%"
        opacity={fadeDir ? 1 : 0}
        bgImage={imgA}
        bgRepeat={bgRepeat ?? "no-repeat"}
        bgSize={bgSize ?? "cover"}
        bgPos={bgPos ?? undefined}
        transition={`opacity ${transitionSeconds ?? 0.5}s ease-in-out`}
        borderRadius={borderRadius}
      />

      <Box
        pos={"absolute"}
        width="100%"
        height="100%"
        opacity={fadeDir ? 0 : 1}
        bgImage={imgB}
        bgRepeat={bgRepeat ?? "no-repeat"}
        bgSize={bgSize ?? "cover"}
        bgPos={bgPos ?? undefined}
        transition={`opacity ${transitionSeconds ?? 0.5}s ease-in-out`}
        borderRadius={borderRadius}
      />
    </Box>
  );
};
