import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  BADGE_MODAL_BACKGROUND,
  Border_Radius,
  BUTTON_THIRD_BACKGROUND_COLOR,
  CLOSE_BUTTON_BORDER,
  OVERLAY_BACKGROUND_COLOR,
  SHIELD_MODAL_PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";

interface SmallModalProps {
  children: any;
  isOpen: boolean;
  onClose: () => void;
  border?: string;
  height?: string;
  bgImage?: string;
  isUninterruptible?: boolean;
}
export const SmallModal = ({
  children,
  isOpen,
  onClose,
  border,
  height = "390px",
  bgImage,
  isUninterruptible,
}: SmallModalProps) => {
  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <div className={isOpen && isUninterruptible ? "uninterruptible-modal" : ""}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        data-testid="achievement_modal"
      >
        <ModalOverlay bg={OVERLAY_BACKGROUND_COLOR} />
        <ModalContent
          border={border}
          borderRadius={Border_Radius}
          pb={2}
          zIndex={"9"}
          data-testid={HVTestId.BadgeModal.achievementContent}
          bg={bgImage ? `url("${bgImage}")` : BADGE_MODAL_BACKGROUND}
          bgColor={"white"}
          bgSize={"cover"}
          w="calc(100% - 40px)"
          h={height}
        >
          <ModalCloseButton
            data-testid={HVTestId.BadgeModal.achievementModalClose}
            border={CLOSE_BUTTON_BORDER}
            borderRadius={"50%"}
            bgColor={BUTTON_THIRD_BACKGROUND_COLOR}
            right={"-15px"}
            top={"-15px"}
            w={"40px"}
            h={"40px"}
            zIndex={9}
            sx={{ _hover: {} }}
          />

          <ModalBody
            data-testid={HVTestId.BadgeModal.achievementModalBody}
            position={"relative"}
            textAlign={"left"}
            color={SHIELD_MODAL_PRIMARY_TEXT_COLOR}
            px={isMobile ? 0 : 3}
            pt={8}
          >
            {children}

            {/* need some UI element for the modal to close on Esc press */}
            <Button
              h={1}
              style={{ visibility: "hidden", position: "absolute" }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
