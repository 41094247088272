import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import {
  Dictionary,
  NanoPracticeUserInstanceInputDto,
  QuestionAnswerDto,
  NanoPracticeUserInstanceDto,
  AIRequestDto,
  MessageDto,
} from "@headversity/contract";
import { AxiosResponse } from "axios";

export const getNanoPracticeInstances = (
  token: string
): Promise<AxiosResponse<Dictionary<NanoPracticeUserInstanceDto[]>>> => {
  return AxiosClient.get("/api/practiceInstances", getConfig(token));
};

export const saveNanoPracticeInstance = (
  token: string,
  nanoPracticeInstance: NanoPracticeUserInstanceInputDto
): Promise<AxiosResponse<NanoPracticeUserInstanceDto>> => {
  return AxiosClient.post(
    "/api/practiceInstances",
    nanoPracticeInstance,
    getConfig(token)
  );
};

export const saveNanoPracticeQuestionAnswers = (
  token: string,
  nanoPracticeUserInstanceId: number,
  questionAnswer: QuestionAnswerDto[]
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `/api/nanoPractice/${nanoPracticeUserInstanceId}/questionAnswers`,
    questionAnswer,
    getConfig(token)
  );
};

export const generateAiResponse = (
  token: string,
  aiPrompt: AIRequestDto,
  timeout?: number
): Promise<AxiosResponse<MessageDto[]>> => {
  let config = getConfig(token);
  if (timeout !== undefined) {
    config = { ...config, timeout };
  }
  return AxiosClient.post(`/api/ai/generate`, aiPrompt, config);
};
