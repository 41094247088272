import { useCallback, useState } from "react";
import {
  getPromoMaterials,
  getPromoMaterialAssets,
} from "../../Api/Reach/ReachApi";
import { getKey } from "../../Utils/Helpers";
import dayjs from "dayjs";
import { AxiosResponse } from "axios";
import {
  PromotionalMaterialAssetDto,
  PromotionalMaterialDto,
  CertDto,
  CompletionState,
  GoalWinThemeDto,
  InviteCodeForReachDto,
  UserRole,
  ProductType,
  NodeDto,
} from "@headversity/contract";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { HvSelectOption } from "../../Components/Common/HvSelect";
import { indentByParentNodeCount, preorderSortNodes } from "../../Components/Reach/SelfService/Hierarchy/HierarchyHelpers";
import { getLocalizedText } from "../../Utils/LanguageUtil";

export interface IReachProvider {
  promoMaterials: PromotionalMaterialDto[];
  getPromoMaterialsFromServer: () => Promise<void>;
  saveGoalWinThemes: (goalWinThemes: GoalWinThemeDto[]) => Promise<void>;
  promoMaterialsLoading: boolean;
  promoMaterialModalOpen: boolean;
  promoMaterialModalChangeUrl: boolean;
  setPromoMaterialModalOpen: (promoMaterialModalOpen: boolean) => void;
  setPromoMaterialModalChangeUrl: (
    promoMaterialModalChangeUrl: boolean
  ) => void;
  selectedPromoMaterial: PromotionalMaterialDto | null;
  setSelectedPromoMaterial: (
    selectedPromoMaterial: PromotionalMaterialDto | null
  ) => void;
  setPromoMaterialAssets: (
    promoMaterialAssets: PromotionalMaterialAssetDto[]
  ) => void;
  promoMaterialAssets: PromotionalMaterialAssetDto[];
  getPromoMaterialAssetsFromServer: (promoMaterialId: number) => Promise<void>;
  getCertOptions: (companyCerts: CertDto[]) => HvSelectOption[];
  getHierarchyOptions: (hierarchies: NodeDto[], addUnassociated: boolean) => HvSelectOption[];
  getCompletionStatusOptions: (
    completionStatuses: CompletionState[]
  ) => HvSelectOption[];
  reformatUsersDataForCSV: (
    usersData: any[],
    inviteCodes: InviteCodeForReachDto[],
    selectedUserLanguage: string
  ) => any[];
  getAllCompletionStatusOptions: (
    completionStatuses: CompletionState[]
  ) => HvSelectOption[];
  getProductTypeOptions: (productTypes: ProductType[]) => HvSelectOption[];
  getCompanyProductTypes: (
    promoMaterials: PromotionalMaterialDto[]
  ) => ProductType[];
  isPromoMaterialNotFound?: boolean;
  setIsPromoMaterialNotFound: (
    isPromoMaterialNotFound: boolean | undefined
  ) => void;
  certsWithScorms: CertDto[];
  setCertsWithScorms: (certs: CertDto[]) => void;
}

export interface ReformattedUser {
  [key: string]: string;
}

export const useReachGlobal = () => {
  const [promoMaterials, setPromoMaterials] = useState<
    PromotionalMaterialDto[]
  >([]);

  const [promoMaterialsLoading, setPromoMaterialsLoading] =
    useState<boolean>(false);
  const [promoMaterialModalOpen, setPromoMaterialModalOpen] =
    useState<boolean>(false);
  const [promoMaterialModalChangeUrl, setPromoMaterialModalChangeUrl] =
    useState<boolean>(true);
  const [selectedPromoMaterial, setSelectedPromoMaterial] =
    useState<PromotionalMaterialDto | null>(null);
  const [promoMaterialAssets, setPromoMaterialAssets] = useState<
    PromotionalMaterialAssetDto[]
  >([]);
  const [certsWithScorms, setCertsWithScorms] = useState<CertDto[]>([]);
  const [isPromoMaterialNotFound, setIsPromoMaterialNotFound] = useState<
    boolean | undefined
  >();

  const getPromoMaterialsFromServer = useCallback(async (): Promise<void> => {
    setPromoMaterialsLoading(true);

    return getPromoMaterials(getKey()).then(
      (response: AxiosResponse<PromotionalMaterialDto[]>) => {
        setPromoMaterialsLoading(false);
        const promoMaterials = response.data
          .filter((pm) => getLocalizedText(pm.name_loc))
          .sort((a, b) => b.id - a.id);

        const today = dayjs();
        // flag promo materials as new and get skill ids
        promoMaterials.forEach((pm) => {
          pm.isNew =
            pm.startDate != null &&
            pm.promotionalMaterialAssetDownloaded.length === 0 &&
            today.diff(dayjs.utc(pm.startDate.toString()).local(), "day") < 30;
          pm.skills = pm.skillCompetencies.map((sc) => sc.skillId);
        });
        setPromoMaterials(promoMaterials);
      }
    );
  }, []);

  const getPromoMaterialAssetsFromServer = useCallback(
    async (promoMaterialId: number): Promise<void> => {
      return getPromoMaterialAssets(promoMaterialId, getKey()).then(
        (response: AxiosResponse<PromotionalMaterialAssetDto[]>) => {
          setPromoMaterialAssets(response.data);
          setIsPromoMaterialNotFound(false);
        },
        (error) => {
          if (error.response.status === 404) {
            setIsPromoMaterialNotFound(true);
          }
        }
      );
    },
    []
  );

  const getCertOptions = useCallback(
    (companyCerts: CertDto[]): HvSelectOption[] => {
      return companyCerts.map((cert) => ({
        value: cert.id,
        label: cert.name + (cert.isTrial ? HVLocalizeStrings.REACH_TRIAL : ""),
      }));
    },
    []
  );

  const getHierarchyOptions = useCallback(
    (hierarchies: NodeDto[], addUnassociated: boolean): HvSelectOption[] => {
      const indentedHierarchyNodes = indentByParentNodeCount(preorderSortNodes(hierarchies));
      const mappedOptions = indentedHierarchyNodes.map((hierarchy) => ({
        value: hierarchy.id,
        label: hierarchy.label,
      }));
      if (addUnassociated) {
        // add extra node for unassociated
        mappedOptions.unshift({
          value: -1,
          label: HVLocalizeStrings.REACH_HIERARCHY_UNASSOCIATED,
        });
      }
      return mappedOptions;
    },
    []
  );

  const getLabelByCompletionStatus = (status: CompletionState): string => {
    switch (status) {
      case CompletionState.notStarted:
        return HVLocalizeStrings.REACH_CERT_STATUS_NOT_STARTED;
      case CompletionState.inProgress:
        return HVLocalizeStrings.REACH_CERT_STATUS_IN_PROGRESS;
      case CompletionState.done:
        return HVLocalizeStrings.REACH_CERT_STATUS_DONE;
      default:
        return HVLocalizeStrings.REACH_CERT_STATUS_NOT_ASSIGNED;
    }
  };

  const getCompletionStatusOptions = useCallback(
    (completionStatuses: CompletionState[] = []): HvSelectOption[] => {
      return completionStatuses.map((status) => ({
        value: status,
        label: getLabelByCompletionStatus(status),
      }));
    },
    []
  );

  const getAllCompletionStatusOptions = useCallback((): HvSelectOption[] => {
    const allStatuses = Object.keys(CompletionState)
      .filter((key) => isNaN(Number(key)))
      .map((key) => CompletionState[key as keyof typeof CompletionState]);

    return allStatuses.map((status) => ({
      value: status,
      label: getLabelByCompletionStatus(status),
    }));
  }, []);

  const getLabelByProductType = (productType: ProductType): string => {
    return ProductType[productType];
  };

  const getProductTypeOptions = useCallback(
    (productTypes: ProductType[] = []): HvSelectOption[] => {
      return productTypes
        .map((productType) => ({
          value: productType,
          label: getLabelByProductType(productType),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    []
  );

  const getCompanyProductTypes = useCallback(
    (promoMaterials: PromotionalMaterialDto[]): ProductType[] => {
      return promoMaterials
        .map((promoMaterial) => promoMaterial.productTypes ?? [])
        .reduce((acc, val) => acc.concat(val), [])
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    []
  );

  const getInviteCodeFromId = (
    inviteCodes: InviteCodeForReachDto[],
    inviteCodeId: number
  ) => {
    return inviteCodes.filter((ic) => ic.id === inviteCodeId)[0]?.code;
  };

  const reformatUsersDataForCSV = useCallback(
    (
      usersData: any[],
      inviteCodes: InviteCodeForReachDto[],
      selectedUserLanguage: string
    ): any[] => {
      let customColumns: string[] = [];
      usersData.forEach((user) => {
        Object.keys(user.customInfo).forEach((key) => {
          if (!customColumns.includes(key)) {
            customColumns.push(key);
          }
        });
      });

      return usersData.map((user) => {
        let reformattedUser: { [key: string]: string } = {
          [HVLocalizeStrings.FULL_NAME]: user.fullName,
          [HVLocalizeStrings.EMAIL]: user.email,
          [HVLocalizeStrings.REACH_USERS_ACTIVATION_DATE]: user.firstAccessDate
            ? dayjs
                .utc(user.firstAccessDate.toString())
                .local()
                .format("YYYY-MM-DD")
            : undefined,
          [HVLocalizeStrings.REACH_USER_ROLE]:
            user.role === UserRole.Admin ? "Admin" : "Learner",
          [HVLocalizeStrings.REACH_INVITE_CODE]: getInviteCodeFromId(
            inviteCodes,
            user.inviteCodeId
          ),
          [HVLocalizeStrings.LANGUAGE_TITLE]: user.language,
        };
        // custom fields
        for (const customCol of customColumns) {
          reformattedUser[customCol] = user.customInfo[customCol] ?? "";
        }

        user.certs.forEach((cert: any) => {
          const certName = cert.name[selectedUserLanguage];
          switch (cert.state) {
            case CompletionState.done:
              reformattedUser[certName] =
                HVLocalizeStrings.REACH_CERT_STATUS_DONE;
              break;
            case CompletionState.inProgress:
              reformattedUser[certName] =
                HVLocalizeStrings.REACH_CERT_STATUS_IN_PROGRESS;
              break;
            case CompletionState.notStarted:
              reformattedUser[certName] =
                HVLocalizeStrings.REACH_CERT_STATUS_NOT_STARTED;
              break;
            default:
              reformattedUser[certName] =
                HVLocalizeStrings.REACH_CERT_STATUS_NOT_ASSIGNED;
              break;
          }

          // completion date
          reformattedUser[
            `${certName} ${HVLocalizeStrings.REACH_CERT_COMPLETION_DATE}`
          ] = cert.completedDate
            ? dayjs
                .utc(cert.completedDate.toString())
                .local()
                .format("YYYY-MM-DD")
            : "";
        });

        return reformattedUser;
      });
    },
    []
  );

  return {
    promoMaterials,
    getPromoMaterialsFromServer,
    promoMaterialsLoading,
    promoMaterialModalOpen,
    promoMaterialModalChangeUrl,
    setPromoMaterialModalOpen,
    setPromoMaterialModalChangeUrl,
    selectedPromoMaterial,
    setSelectedPromoMaterial,
    setPromoMaterialAssets,
    promoMaterialAssets,
    getPromoMaterialAssetsFromServer,
    getCertOptions,
    getHierarchyOptions,
    getCompletionStatusOptions,
    reformatUsersDataForCSV,
    getAllCompletionStatusOptions,
    getProductTypeOptions,
    getCompanyProductTypes,
    certsWithScorms,
    setCertsWithScorms,
    isPromoMaterialNotFound,
    setIsPromoMaterialNotFound,
  };
};
