import { ToolLandingCard } from "../ToolLandingCard";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { Flex, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import React from "react";
import {
  STAT_CARD_SECONDARY_COLOR,
  SECONDARY_TEXT_COLOR,
  THIRD_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../../Testing/dataTestIds";
import { OptimismPlanDto } from "@headversity/contract";
import { getNumberOfCompletedChallenges } from "./Utils/OptimismUtils";

interface OptimismStatCardProps {
  optimismPlans: OptimismPlanDto[];
  setOptimismToolOpened: (optimismToolOpened: boolean) => void;
  viewLogClick?: () => void;
}

export const OptimismToolStatCard = (props: OptimismStatCardProps) => {
  const { optimismPlans, setOptimismToolOpened, viewLogClick } = props;
  return (
    <ToolLandingCard
      toolImage={
        "https://cdn.headversity.com/app/resources/headerImages/tool-header-optimism.jpg"
      }
      toolStatContent={
        <>
          <OptimismToolStat optimismPlans={optimismPlans} />
          <Flex flexDir={"column"} align={"center"} justify={"center"} gap={4}>
            <AnimatedButton
              w={330}
              text={HVLocalizeStrings.OPTIMISM_TOOLS_PAGE_USE_TOOL}
              onClick={() => {
                setOptimismToolOpened(true);
              }}
              dataTestId={HVTestId.OptimismToolStatCard.launchButton}
            />
            {viewLogClick && (
              <AnimatedButton
                text={HVLocalizeStrings.TOOLS_VIEW_LOGS}
                colorSet={AnimatedButtonColorSet.Third}
                w={330}
                onClick={() => {
                  if (viewLogClick) {
                    viewLogClick();
                  }
                }}
                dataTestId={HVTestId.OptimismToolStatCard.viewHistory}
              />
            )}
          </Flex>
        </>
      }
    />
  );
};

interface OptimismToolStatProps {
  optimismPlans: OptimismPlanDto[];
  isSmall?: boolean;
}

export const OptimismToolStat = (props: OptimismToolStatProps) => {
  const { optimismPlans, isSmall } = props;
  const numberOfChallenges = getNumberOfCompletedChallenges(optimismPlans);
  return (
    <Table mt={isSmall ? 8 : 4} mb={isSmall ? "5" : "10"}>
      <Tbody>
        <Tr>
          <Td p={"0"} border={"0"}>
            <Text
              fontSize={isSmall ? "lg" : "xl"}
              color={isSmall ? STAT_CARD_SECONDARY_COLOR : SECONDARY_TEXT_COLOR}
            >
              {HVLocalizeStrings.OPTIMISM_TOOLS_PAGE_USED}
            </Text>
          </Td>
          <Td p={"0"} border={"0"} textAlign={"right"}>
            <Text
              fontWeight={"semibold"}
              fontSize={isSmall ? "lg" : "xl"}
              color={THIRD_TEXT_COLOR}
              data-testid={HVTestId.OptimismToolStatCard.optiStat}
            >
              {numberOfChallenges}
            </Text>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
