import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { ShellContext } from "../../State/ShellContext";
import {
  CONTRAST_TEXT_COLOR,
  FULL_SCREEN_MODAL_HEADER_BACKGROUND_COLOR,
  MODAL_BACKGROUND_COLOR,
  MODAL_PROGRESS_TEXT_COLOR,
  MODAL_SECONDARY_BACKGROUND_COLOR,
} from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";
import { isAndroid, isWebPlatform } from "../../Utils/mobileUtils";

interface ModalProps {
  title: any;
  headerImage?: string;
  children: any;
  open: boolean;
  setOpen: (val: boolean) => void;
  bodyMarginTop?: string;
  leftHeader?: any;
  titleBg?: string;
  titleBgSize?: string;
  titlePaddingLeft?: string;
}

export const FullScreenModalDialog = (props: ModalProps) => {
  const { open, setOpen, title, titleBg, titleBgSize, titlePaddingLeft } =
    props;

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { safeAreaInsets } = useContext(ShellContext);
  const { setIsUninterruptibleModalOpen } =
    useContext<IGlobalProvider>(GlobalContext);

  useEffect(() => {
    if (open) {
      setIsUninterruptibleModalOpen(true);
    }
  }, [open]);

  /* duped in LargeModal */
  useEffect(() => {
    if (open && isWebPlatform() && window.screen.width < 568) {
      // properly handle iOS Safari hiding address bar
      // https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
      // https://lukechannings.com/blog/2021-06-09-does-safari-15-fix-the-vh-bug/
      const interval = setInterval(() => {
        const container = document.querySelector(
          ".chakra-modal__content-container"
        );
        if (container) {
          let height = window.innerHeight;
          (container as any).style.height = height + "px";
        }
      }, 500);

      return () => clearInterval(interval);
    }
  }, [open]);

  return (
    <div className={open ? "uninterruptible-modal" : ""}>
      <Modal
        isOpen={open}
        size={"full"}
        onClose={() => {
          setOpen(false);
        }}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent
          h={"100%"}
          overflowX={"hidden"}
          borderRadius={"0"}
          m={"0"}
          p={"0"}
          bgColor={MODAL_BACKGROUND_COLOR}
          data-testid={HVTestId.FullScreenModalDialog.content}
        >
          {window.screen.height <= 450 ? (
            <ModalCloseButton
              pos="fixed"
              color={MODAL_PROGRESS_TEXT_COLOR}
              w={"40px"}
              h={"40px"}
              fontSize={"md"}
              fontWeight={"bold"}
              right={isDesktop ? "12px" : "8px"}
              top={`${safeAreaInsets.top + 8}px`}
              data-testid={HVTestId.FullScreenModalDialog.closeButton}
            />
          ) : (
            <ModalHeader
              display={
                isAndroid() && window.screen.width >= 568 ? "none" : undefined
              }
              p={"0"}
              position={"sticky"}
              w={"100%"}
              top={0}
              zIndex={"300"}
            >
              <Flex
                top={"0px"}
                bg={titleBg ?? FULL_SCREEN_MODAL_HEADER_BACKGROUND_COLOR}
                bgSize={titleBgSize}
                align={"center"}
                justify={isDesktop ? "center" : "left"}
                py={4}
                px={isDesktop ? 12 : 4}
                pt={isWebPlatform() ? "4" : `${safeAreaInsets.top}px`}
                borderBottom={"1px solid #ccc"}
              >
                {props.leftHeader}
                <Heading
                  color={CONTRAST_TEXT_COLOR}
                  fontSize={isDesktop ? "3xl" : "xl"}
                  fontWeight={"semibold"}
                  mr={isDesktop ? 0 : "80px"}
                  pl={titlePaddingLeft ?? 1}
                  data-testid={HVTestId.FullScreenModalDialog.headingTitle}
                >
                  {title}
                </Heading>
                <ModalCloseButton
                  color={MODAL_SECONDARY_BACKGROUND_COLOR}
                  w={"40px"}
                  h={"40px"}
                  fontSize={"md"}
                  fontWeight={"bold"}
                  right={isDesktop ? "12px" : "8px"}
                  top={`calc(50% - 20px) + ${safeAreaInsets.top}px`}
                  data-testid={HVTestId.FullScreenModalDialog.closeButton}
                />
              </Flex>
            </ModalHeader>
          )}
          <ModalBody p={0} data-testid={HVTestId.FullScreenModalDialog.body}>
            {props.children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
