import { useContext, useEffect, useState } from "react";
import { ThinkingToolStepSelection } from "./ThinkingToolStepSelection";
import { ThinkingToolStepThinkingAccurately } from "./ThinkingToolStepThinkingAccurately";
import { ThinkingToolStepExchange } from "./ThinkingToolStepExchange";
import { ThinkingToolStepExchangeBalanced } from "./ThinkingToolStepExchangeBalanced";
import { ThinkingToolStepOutro } from "./ThinkingToolStepOutro";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { ThinkingToolStepLogs } from "./ThinkingToolStepLogs";
import {
  IThinkingToolProvider,
  ThinkingToolContext,
} from "../../../../State/Solo/ThinkingToolContext";
import { StepPanel } from "../../../Common/StepPanel";
import { EVENTS, track } from "../../../../Utils/Analytics";
import { isAndroid } from "../../../../Utils/mobileUtils";

interface ThinkingToolProps {
  setOpen: (open: boolean) => void;
  step: string;
}

export const THINKING_LANDING_STEP = "ThinkingToolStepLanding";
export const THINKING_THINKING_ACCURATELY_STEP =
  "ThinkingToolStepThinkingAccurately";
export const THINKING_PART_ONE_STEP = "ThinkingToolStepSelectionPartOne";
export const THINKING_PART_TWO_STEP = "ThinkingToolStepSelectionPartTwo";
export const THINKING_PART_THREE_STEP = "ThinkingToolStepSelectionPartThree";
export const THINKING_EXCHANGE_STEP = "ThinkingToolStepExchange";
export const THINKING_EXCHANGE_BALANCED_STEP =
  "ThinkingToolStepExchangeBalanced";
export const THINKING_OUTRO_STEP = "ThinkingToolStepOutro";

const ThinkingToolSteps = [
  THINKING_LANDING_STEP,
  THINKING_THINKING_ACCURATELY_STEP,
  THINKING_PART_ONE_STEP,
  THINKING_PART_TWO_STEP,
  THINKING_PART_THREE_STEP,
  THINKING_EXCHANGE_STEP,
  THINKING_EXCHANGE_BALANCED_STEP,
  THINKING_OUTRO_STEP,
];

export const ThinkingTool = (props: ThinkingToolProps) => {
  const { setOpen, step } = props;

  const { saveThinkingPlanItemsToServer } =
    useContext<IThinkingToolProvider>(ThinkingToolContext);
  const [currentPage, setCurrentPage] = useState(ThinkingToolSteps[0]);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [currentInputForNegativeThought, setInputForNegativeThought] =
    useState<string>("");
  const [currentInputForStepTwoButtons, setInputForStepTwoButtons] =
    useState("");
  const [
    whatDoesTheEvidenceSayButtons,
    setInputForWhatDoesTheEvidenceSayButtons,
  ] = useState<string>("");
  const [
    whatWouldYourBestFriendSayButtons,
    setInputForWhatWouldYourBestFriendSayButtons,
  ] = useState<string>("");
  const [currentInputForBalancedThought, setInputForBalancedThought] =
    useState<string>("");
  const [steps, setSteps] = useState<number>(0);
  const [totalSteps, setTotalSteps] = useState<number>(0);
  const [value, setValue] = useState<number>(0);
  const [showBackArrow, setShowBackArrow] = useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);

  useEffect(() => {
    setSteps(ThinkingToolSteps.indexOf(currentPage));
    setValue(
      (ThinkingToolSteps.indexOf(currentPage) * 100) /
        (ThinkingToolSteps.length - 2)
    );
    setShowBackArrow(
      ThinkingToolSteps.indexOf(currentPage) > 1 &&
        ThinkingToolSteps.indexOf(currentPage) <= ThinkingToolSteps.length - 2
    );
    setShowProgress(
      ThinkingToolSteps.indexOf(currentPage) > 0 &&
        ThinkingToolSteps.indexOf(currentPage) <= ThinkingToolSteps.length - 2
    );
    setTotalSteps(ThinkingToolSteps.length - 2);
  }, [currentPage]);

  useEffect(() => {
    if (step) {
      setCurrentPage(step);
    }
  }, [step]);

  const handleChangeNegativeThought = (e: any) => {
    setInputForNegativeThought(e.target.value);
  };
  const handleChangeStepTwoButtons = (e: any) => {
    setInputForStepTwoButtons(e);
  };
  const handleChangeEvidenceButtons = (e: any) => {
    setInputForWhatDoesTheEvidenceSayButtons(e);
  };
  const handleChangeBestFriendButtons = (e: any) => {
    setInputForWhatWouldYourBestFriendSayButtons(e);
  };
  const handleChangeBalancedThought = (e: any) => {
    setInputForBalancedThought(e.target.value);
  };

  const changePage = (newPage: string) => {
    track(EVENTS.ToolThinkingReachedStep, { HV_Step: newPage });

    setCurrentPage(newPage);
  };

  let stepComponent;

  switch (currentPage) {
    case THINKING_THINKING_ACCURATELY_STEP:
      stepComponent = (
        <ThinkingToolStepThinkingAccurately
          changePage={changePage}
          nextStep={ThinkingToolSteps[2]}
          currentInputForNegativeThought={currentInputForNegativeThought}
          handleChangeNegativeThought={handleChangeNegativeThought}
        />
      );
      break;
    case THINKING_PART_ONE_STEP:
      stepComponent = (
        <ThinkingToolStepSelection
          changePage={changePage}
          nextStep={ThinkingToolSteps[3]}
          handleChangeOne={() => {
            handleChangeStepTwoButtons("THOUGHT");
          }}
          handleChangeTwo={() => {
            handleChangeStepTwoButtons("FACT");
          }}
          text={HVLocalizeStrings.THINKING_TOOLS_STEP_2_INSTRUCTIONS}
          title={HVLocalizeStrings.THINKING_TOOLS_STEP_2}
          instruction={`"${currentInputForNegativeThought}"`}
          instruction2={HVLocalizeStrings.THINKING_TOOLS_TAP}
          buttonTextOne={HVLocalizeStrings.THINKING_TOOLS_THOUGHT}
          buttonTextTwo={HVLocalizeStrings.THINKING_TOOLS_FACT}
        />
      );
      break;
    case THINKING_PART_TWO_STEP:
      stepComponent = (
        <ThinkingToolStepSelection
          changePage={changePage}
          nextStep={ThinkingToolSteps[4]}
          handleChangeOne={() => {
            handleChangeEvidenceButtons("REJECTS");
          }}
          handleChangeTwo={() => {
            handleChangeEvidenceButtons("SUPPORTS");
          }}
          text={HVLocalizeStrings.THINKING_TOOLS_STEP_2_INSTRUCTIONS}
          title={HVLocalizeStrings.THINKING_TOOLS_STEP_2}
          instruction={`"${currentInputForNegativeThought}"`}
          instruction2={HVLocalizeStrings.THINKING_TOOLS_WHAT_DOES}
          buttonTextOne={HVLocalizeStrings.THINKING_TOOLS_REJECTS_STATEMENT}
          buttonTextTwo={HVLocalizeStrings.THINKING_TOOLS_SUPPORTS_STATEMENT}
        />
      );
      break;
    case THINKING_PART_THREE_STEP:
      stepComponent = (
        <ThinkingToolStepSelection
          changePage={changePage}
          nextStep={ThinkingToolSteps[5]}
          handleChangeOne={() => {
            handleChangeBestFriendButtons("DISAGREE");
          }}
          handleChangeTwo={() => {
            handleChangeBestFriendButtons("AGREE");
          }}
          text={HVLocalizeStrings.THINKING_TOOLS_STEP_2_INSTRUCTIONS}
          title={HVLocalizeStrings.THINKING_TOOLS_STEP_2}
          instruction={`"${currentInputForNegativeThought}"`}
          instruction2={HVLocalizeStrings.THINKING_TOOLS_WHAT_WOULD}
          buttonTextOne={HVLocalizeStrings.THINKING_TOOLS_MY_BEST_DISAGREE}
          buttonTextTwo={HVLocalizeStrings.THINKING_TOOLS_MY_BEST_AGREE}
        />
      );
      break;
    case THINKING_EXCHANGE_STEP:
      stepComponent = (
        <ThinkingToolStepExchange
          changePage={changePage}
          nextStep={ThinkingToolSteps[6]}
          currentInputForNegativeThought={currentInputForNegativeThought}
          currentInputForBalancedThought={currentInputForBalancedThought}
          handleChangeBalancedThought={handleChangeBalancedThought}
          currentInputForStepTwoButtons={currentInputForStepTwoButtons}
          whatDoesTheEvidenceSayButtons={whatDoesTheEvidenceSayButtons}
          whatWouldYourBestFriendSayButtons={whatWouldYourBestFriendSayButtons}
        />
      );
      break;
    case THINKING_EXCHANGE_BALANCED_STEP:
      stepComponent = (
        <ThinkingToolStepExchangeBalanced
          currentInputForNegativeThought={currentInputForNegativeThought}
          currentInputForBalancedThought={currentInputForBalancedThought}
          disableButton={disableButton}
          changePage={(page: any) => {
            setDisableButton(true);

            saveThinkingPlanItemsToServer({
              identify: currentInputForNegativeThought,
              thoughtOrFact: currentInputForStepTwoButtons.toLowerCase(),
              evidence: whatDoesTheEvidenceSayButtons.toLowerCase(),
              bestFriendSay: whatWouldYourBestFriendSayButtons.toLowerCase(),
              statement: currentInputForBalancedThought,
            }).then(() => {
              changePage(page);
            });
          }}
          nextStep={ThinkingToolSteps[7]}
        />
      );
      break;
    case THINKING_OUTRO_STEP:
      stepComponent = (
        <ThinkingToolStepOutro
          setOpen={setOpen}
          currentInputForNegativeThought={currentInputForNegativeThought}
          currentInputForBalancedThought={currentInputForBalancedThought}
        />
      );
      break;
    case THINKING_LANDING_STEP:
      stepComponent = <ThinkingToolStepLogs changePage={changePage} />;
  }

  return (
    <>
      {currentPage !== THINKING_LANDING_STEP && (
        <StepPanel
          step={steps}
          totalStep={totalSteps}
          value={value}
          showProgressBar={showProgress}
          showBackArrow={showBackArrow}
          onBackClick={() => {
            changePage(
              ThinkingToolSteps[ThinkingToolSteps.indexOf(currentPage) - 1]
            );
          }}
          paddingBottom={
            isAndroid() ? `${window.screen.height / 2}px` : undefined
          }
        >
          {stepComponent}
        </StepPanel>
      )}
      {currentPage === THINKING_LANDING_STEP && (
        <ThinkingToolStepLogs changePage={changePage} />
      )}
    </>
  );
};
