import { Text, useBreakpointValue } from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";

export const WelcomeModalSubheader = ({
  mr,
  mx,
  mt,
  useTheme,
  children,
}: any) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Text
      mt={mt ?? 4}
      mr={mr}
      mx={mx}
      fontSize={isDesktop ? "xl" : "lg"}
      lineHeight={"1.3"}
      fontWeight="300"
      color={useTheme ? MODAL_PRIMARY_TEXT_COLOR : "white"}
    >
      {children}
    </Text>
  );
};
