import { LargeModal } from "../../Common/LargeModal";
import EligibilityFileJobList from "./EligibilityFileJobList";

interface EligibilityFileJobsViewModalProps {
  isOpen: boolean;
  setIsViewOpen: (isOpen: boolean) => void;
  closeModal?: () => Promise<void>;
}

const EligibilityFileJobsViewModal = (
  props: EligibilityFileJobsViewModalProps
) => {
  return (
    <LargeModal
      open={props.isOpen}
      setOpen={props.setIsViewOpen}
      onCloseTasks={props.closeModal}
    >
      <EligibilityFileJobList></EligibilityFileJobList>
    </LargeModal>
  );
};

export default EligibilityFileJobsViewModal;
