import React, { useContext, useEffect, useState } from "react";
import { FocusToolStepIntro } from "./FocusToolStepIntro";
import { FocusToolStepTitleAndSituation } from "./FocusToolStepTitleAndSituation";
import { FocusToolStepGeneralInput } from "./FocusToolStepGeneralInput";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { FocusToolStepTimeSelection } from "./FocusToolStepTimeSelection";
import { FocusToolStepOutro } from "./FocusToolStepOutro";
import { FocusToolStepLogs } from "./FocusToolStepLogs";
import {
  FocusToolContext,
  IFocusToolProvider,
} from "../../../../State/Solo/FocusToolContext";
import dayjs from "dayjs";
import { StepPanel } from "../../../Common/StepPanel";
import { EVENTS, track } from "../../../../Utils/Analytics";
import {
  FocusToolInfoDto,
  FocusToolInfoInputDto,
  FocusToolPlanInput,
  SchedulerFrequency,
} from "@headversity/contract";
import { FocusSteps } from "./FocusSteps";

const FocusToolSteps: any = {
  [FocusSteps.FocusIntroStep]: {
    showProgressBar: false,
    step: 0,
    value: 0,
    previousStep: "",
    showBackArrow: false,
  },
  [FocusSteps.FocusSituationStep]: {
    showProgressBar: true,
    step: 1,
    value: 25,
    previousStep: FocusSteps.FocusIntroStep,
    showBackArrow: true,
  },
  [FocusSteps.FocusTodoStep]: {
    showProgressBar: true,
    step: 2,
    value: 50,
    previousStep: FocusSteps.FocusSituationStep,
    showBackArrow: true,
  },
  [FocusSteps.FocusFeelStep]: {
    showProgressBar: true,
    step: 3,
    value: 75,
    previousStep: FocusSteps.FocusTodoStep,
    showBackArrow: true,
  },
  [FocusSteps.FocusTimeStep]: {
    showProgressBar: true,
    step: 4,
    value: 100,
    previousStep: FocusSteps.FocusFeelStep,
    showBackArrow: true,
  },
  [FocusSteps.FocusOutroStep]: {
    showProgressBar: false,
    step: 0,
    value: 0,
    previousStep: "",
    showBackArrow: false,
  },
  [FocusSteps.FocusLogStep]: {
    showProgressBar: false,
    step: 0,
    value: 0,
    previousStep: "",
    showBackArrow: false,
  },
};

interface FocusToolProps {
  setOpen: (open: boolean) => void;
  step?: string;
  plan?: FocusToolInfoDto;
  isEditMode: boolean;
  setIsEditMode: (val: boolean) => void;
}

export const FocusTool = (props: FocusToolProps): JSX.Element => {
  const { saveFocusPlanItemsToServer, updateFocusPlanToServer } =
    useContext<IFocusToolProvider>(FocusToolContext);
  const [currentPlan, setCurrentPlan] = useState<
    FocusToolInfoDto | FocusToolInfoInputDto | null
  >(null);
  const [currentPage, setCurrentPage] = useState("default");
  const [planTitle, setPlanTitle] = useState<string>("");
  const [planSituation, setPlanSituation] = useState<string>("");
  const [haveToDo, setHaveToDo] = useState<string>("");
  const [haveToFeel, setHaveToFeel] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [timeZone, setTimeZone] = useState<string>("");
  const [schedulerFrequency, setSchedulerFrequency] =
    useState<SchedulerFrequency>(SchedulerFrequency.DoNotRepeat);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const { setOpen, step, plan, isEditMode, setIsEditMode } = props;

  const changePage = (newPage: string) => {
    track(EVENTS.ToolFocusReachedStep, { HV_Step: newPage });

    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (currentPlan) {
      setPlanSituation((currentPlan.userInput as FocusToolPlanInput).situation);
      setPlanTitle((currentPlan.userInput as FocusToolPlanInput).title);
      setHaveToDo((currentPlan.userInput as FocusToolPlanInput).toDo);
      setHaveToFeel((currentPlan.userInput as FocusToolPlanInput).feel);
      setStartDate(currentPlan.nextOccurrence.toString());
      setTimeZone(currentPlan.timeZone);
      setSchedulerFrequency(currentPlan.recurrenceType);
    }
  }, [currentPlan]);

  useEffect(() => {
    if (plan) setCurrentPlan(plan);
  }, [plan]);

  useEffect(() => {
    if (step) {
      setCurrentPage(step);
    }
  }, [step]);

  let content = <></>;
  let noMarginTop = false;
  switch (currentPage) {
    default:
    case FocusSteps.FocusIntroStep:
      content = <FocusToolStepIntro changePage={changePage} />;
      break;
    case FocusSteps.FocusSituationStep:
      content = (
        <FocusToolStepTitleAndSituation
          setPlanSituation={setPlanSituation}
          setPlanTitle={setPlanTitle}
          planTitle={planTitle}
          planSituation={planSituation}
          changePage={changePage}
        ></FocusToolStepTitleAndSituation>
      );
      break;
    case FocusSteps.FocusTodoStep:
      content = (
        <FocusToolStepGeneralInput
          title={HVLocalizeStrings.FOCUS_TOOLS_PLAN_HAVE_TO_DO}
          description={
            HVLocalizeStrings.FOCUS_TOOLS_PLAN_HAVE_TO_DO_DESCRIPTION
          }
          inputValue={haveToDo}
          setInputValue={setHaveToDo}
          changePage={changePage}
          nextStep={FocusSteps.FocusFeelStep}
        />
      );
      break;
    case FocusSteps.FocusFeelStep:
      content = (
        <FocusToolStepGeneralInput
          title={HVLocalizeStrings.FOCUS_TOOLS_PLAN_HAVE_TO_FEEL}
          description={
            HVLocalizeStrings.FOCUS_TOOLS_PLAN_HAVE_TO_FEEL_DESCRIPTION
          }
          inputValue={haveToFeel}
          setInputValue={setHaveToFeel}
          changePage={changePage}
          nextStep={FocusSteps.FocusTimeStep}
        />
      );
      break;
    case FocusSteps.FocusTimeStep:
      content = (
        <FocusToolStepTimeSelection
          startDate={
            startDate
              ? dayjs.utc(startDate).local().format("YYYY-MM-DDTHH:mm")
              : ""
          }
          setDateTimeInfo={(startDate: string, timeZone: string) => {
            setStartDate(startDate);
            setTimeZone(timeZone);
          }}
          repeat={schedulerFrequency}
          setRepeat={setSchedulerFrequency}
          disableButton={disableButton}
          changePage={(page: string) => {
            setDisableButton(true);

            const planInput = {
              title: planTitle,
              situation: planSituation,
              toDo: haveToDo,
              feel: haveToFeel,
            };

            isEditMode
              ? updateFocusPlanToServer({
                  id: (currentPlan as FocusToolInfoDto).id,
                  userInput: planInput,
                  nextOccurrence: dayjs(startDate)
                    .utc()
                    .format("YYYY-MM-DDTHH:mm"),
                  timeZone: timeZone,
                  recurrenceType: schedulerFrequency,
                  scheduler: {
                    start: dayjs(startDate).utc().format("YYYY-MM-DDTHH:mm"),
                    frequency: schedulerFrequency,
                    repeat:
                      schedulerFrequency !== SchedulerFrequency.DoNotRepeat,
                    timeZone: timeZone,
                  },
                  completed: false,
                }).then(() => {
                  changePage(page);
                  setIsEditMode(false);
                })
              : saveFocusPlanItemsToServer({
                  userInput: planInput,
                  nextOccurrence: dayjs(startDate)
                    .utc()
                    .format("YYYY-MM-DDTHH:mm"),
                  timeZone: timeZone,
                  recurrenceType: schedulerFrequency,
                  scheduler: {
                    start: dayjs(startDate).utc().format("YYYY-MM-DDTHH:mm"),
                    frequency: schedulerFrequency,
                    repeat:
                      schedulerFrequency !== SchedulerFrequency.DoNotRepeat,
                    timeZone: timeZone,
                  },
                }).then(() => {
                  changePage(page);
                });
          }}
        />
      );
      break;
    case FocusSteps.FocusOutroStep:
      content = <FocusToolStepOutro setOpen={setOpen} />;
      break;
    case FocusSteps.FocusLogStep:
      content = (
        <FocusToolStepLogs
          setCurrentPlan={setCurrentPlan}
          changePage={changePage}
        />
      );
      noMarginTop = true;
      break;
  }

  return (
    <StepPanel
      value={FocusToolSteps[currentPage]?.value}
      step={FocusToolSteps[currentPage]?.step}
      totalStep={4}
      showProgressBar={FocusToolSteps[currentPage]?.showProgressBar}
      showBackArrow={FocusToolSteps[currentPage]?.showBackArrow}
      onBackClick={() => {
        changePage(FocusToolSteps[currentPage]?.previousStep);
      }}
      noMarginTop={noMarginTop}
    >
      {content}
    </StepPanel>
  );
};
