import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import HierarchyModal from "../../Shared/Hierarchy/HierarchyModal";

interface HierarchySelectContainerProps {
  onHierarchySelectComplete?: () => void;
}

export const HierarchySelectContainer = ({
  onHierarchySelectComplete,
}: HierarchySelectContainerProps) => {
  const [isReady, setIsReady] = useState(false);
  const [isHierarchyAssociationRequested, setIsHierarchyAssociationRequested] =
    useState<boolean>(false);

  const [hierarchyId, setHierarchyId] = useState<number | undefined>(undefined);

  const { getHierarchyAssocationsFromServer } =
    useContext<IGlobalProvider>(GlobalContext);

  useEffect(() => {
    getHierarchyAssocationsFromServer()
      .then((result) => {
        setIsHierarchyAssociationRequested(
          result.requiresAssociation && !result.hasRelations
        );
        setHierarchyId(result.hierarchyId);
      })
      .finally(() => {
        setIsReady(true);
      });
  }, []);

  const handleSetOpen = useCallback((open: boolean) => {
    setIsHierarchyAssociationRequested(false);
    onHierarchySelectComplete?.();
  }, []);

  let content = <></>;
  if (isHierarchyAssociationRequested) {
    content = (
      <HierarchyModal
        hierarchyId={hierarchyId!}
        isChangeHierarchy={false}
        open={isHierarchyAssociationRequested}
        setOpen={handleSetOpen}
      />
    );
  }
  return <>{isReady && content}</>;
};
