import { createContext, useCallback } from "react";
import { saveThinkingPlanItems } from "../../Api/Solo/ThinkingApi";
import { getKey } from "../../Utils/Helpers";
import { useThinkingToolStat } from "../../Hooks/Solo/useThinkingToolStat";
import { ThinkingToolInfoDto } from "@headversity/contract";

export interface IThinkingToolProvider {
  thinkingPlanItems: ThinkingToolInfoDto[] | undefined;
  getThinkingPlanItemsFromServer: () => Promise<void>;
  saveThinkingPlanItemsToServer: (thinkingPlan: any) => Promise<void>;
}

export const ThinkingToolContext = createContext<IThinkingToolProvider>(
  {} as any
);

export const ThinkingToolProvider = (props: any) => {
  const { thinkingPlanItems, getThinkingPlanItemsFromServer } =
    useThinkingToolStat();

  const saveThinkingPlanItemsToServer = useCallback(
    async (thinkingPlan: any) => {
      return saveThinkingPlanItems(thinkingPlan, getKey()).then((response) => {
        getThinkingPlanItemsFromServer();
      });
    },
    []
  );

  return (
    <ThinkingToolContext.Provider
      value={{
        thinkingPlanItems,
        getThinkingPlanItemsFromServer,
        saveThinkingPlanItemsToServer,
      }}
    >
      {props.children}
    </ThinkingToolContext.Provider>
  );
};
