import {
  ActivityBreakdownReportDto,
  ActivityUserFeedbackReportDto,
  GoalDto,
  IInsightsFilter,
  MicroLessonCountsReportDto,
  MicroLessonDto,
  NanoPracticeCountsReportDto,
  NanoPracticeDto,
  PathDto,
  ReportDto,
  SelectedPursuitsReportDto,
  TeamLessonCountsReportDto,
  TeamLessonDto,
} from "@headversity/contract";
import { useCallback, useContext, useState } from "react";
import { reportSoloTeam } from "../../../Api/Reach/ReachApi";
import {
  Light_Blue,
  Mid_Blue,
  Moonstone,
} from "../../../Styles/HeadversityStyle";
import { getKey } from "../../../Utils/Helpers";
import { PercentProgressDisplayData } from "./InsightsPercentProgressDisplay";
import { PieChartDataPoint } from "./InsightsPieChart";
import {
  PercentDisplayData,
  PopularDisplayData,
} from "./InsightsPopularDisplay";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import { Interval180Days } from "../../../State/Reach/ReachInsightsContext";

export interface ISoloAndTeamReportProvider {
  fetchReport: (filters?: IInsightsFilter) => Promise<void>;
  popularNanoPractice: PopularDisplayData[];
  popularTeamLessons: PopularDisplayData[];
  popularMicroLessons: PopularDisplayData[];
  userFeedback: PercentProgressDisplayData[];
  selectedPursuits: PercentDisplayData[];
  activitiesBreakdown: PieChartDataPoint[];
  lowDataSoloActivityUserFeedback: boolean;
  lowDataSoloActivityBreakdown: boolean;
  lowDataTeamLessonCount: boolean;
  lowDataSoloMicroLessonCounts: boolean;
  lowDataSoloNanoPracticeCounts: boolean;
  lowDataSoloSelectedPursuits: boolean;
  isLoading: boolean;
  intervalDays: number;
}

const mapPopularTeamLessonData = (
  dto: ReportDto<TeamLessonCountsReportDto[]>,
  teamLessons: TeamLessonDto[]
): PopularDisplayData[] => {
  if (dto.noData) {
    return [];
  }
  const totalCount = dto.report!.reduce(
    (acc, tl) => acc + tl.team_lesson_count,
    0
  );

  // map and return only top 3 team lessons
  const mapped: PopularDisplayData[] = [];
  dto.report!.forEach((tl) => {
    const teamLesson = teamLessons.find((m) => m.id === tl.team_lesson_id);
    if (teamLesson) {
      mapped.push({
        name: teamLesson.name as string,
        description: teamLesson!.shortDescription as string,
        percentage: Math.round((tl.team_lesson_count / totalCount) * 100.0),
      });
    }
  });

  return mapped.slice(0, 3);
};

const mapPopularMicroLessonData = (
  dto: ReportDto<MicroLessonCountsReportDto[]>,
  microLessons: MicroLessonDto[]
): PopularDisplayData[] => {
  if (dto.noData) {
    return [];
  }
  const totalMicroLesson = dto.report!.reduce(
    (acc, ml) => acc + ml.micro_lesson_count,
    0
  );

  // map and return only top 3 micro lessons
  const mapped: PopularDisplayData[] = [];
  dto.report!.forEach((ml) => {
    const microLesson = microLessons.find((m) => m.id === ml.micro_lesson_id);
    if (microLesson) {
      mapped.push({
        name: microLesson.name as string,
        description: microLesson!.description as string,
        percentage: Math.round(
          (ml.micro_lesson_count / totalMicroLesson) * 100.0
        ),
      });
    }
  });

  return mapped.slice(0, 3);
};

const mapPopularNanoPracticeData = (
  dto: ReportDto<NanoPracticeCountsReportDto[]>,
  nanoPractices: NanoPracticeDto[]
): PopularDisplayData[] => {
  if (dto.noData) {
    return [];
  }
  const totalNanoPractice = dto.report!.reduce(
    (acc, nanoPractice) => acc + nanoPractice.nano_practice_count,
    0
  );

  // map and return only top 3 nano practices
  const mapped: PopularDisplayData[] = [];
  dto.report!.forEach((nanoPractice) => {
    const np = nanoPractices.find(
      (np) => np.id === nanoPractice.nano_practice_id
    );
    if (np) {
      mapped.push({
        name: np.name as string,
        description: np.description as string,
        percentage: Math.round(
          (nanoPractice.nano_practice_count / totalNanoPractice) * 100.0
        ),
      });
    }
  });

  return mapped.slice(0, 3);
};

const mapUserFeedbackData = (
  dto: ReportDto<ActivityUserFeedbackReportDto[]>
): PercentProgressDisplayData[] => {
  if (dto.noData) {
    return [];
  }
  const mapped = dto.report!.map((feedback) => {
    return {
      message: feedback.question,
      percentage: Math.round(feedback.like_ratio * 100.0),
    } as PercentProgressDisplayData;
  });
  return mapped;
};

const mapSelectedPursuitsData = (
  dto: ReportDto<SelectedPursuitsReportDto[]>,
  goals: GoalDto[] = []
): PercentDisplayData[] => {
  if (!dto || dto.noData) {
    return [];
  }
  const totalGoals = dto.report!.reduce(
    (acc, goal) => acc + goal.selected_pursuit_count,
    0
  );

  // map and return pursuits
  const mapped: PercentDisplayData[] = [];
  dto.report!.forEach((goal) => {
    const currentGoal = goals.find((g) => g.id === goal.goal_id);
    if (currentGoal) {
      mapped.push({
        name: currentGoal.name as string,
        percentage: Math.round(
          (goal.selected_pursuit_count / totalGoals) * 100.0
        ),
      });
    }
  });

  return mapped.slice(0, 5);
};

const mapActivityBreakdownData = (
  activityBreakdownReport: ReportDto<ActivityBreakdownReportDto[]>
) => {
  if (activityBreakdownReport.noData) {
    return [];
  }
  const colors = [Mid_Blue, Light_Blue, Moonstone];
  const assignColor = (index: number, colors: string[]) => {
    return colors[index % colors.length];
  };

  const total = activityBreakdownReport.report!.reduce(
    (acc, activity) => acc + activity.interactions,
    0
  );
  const getActivityLocalizedName = (activity: string) => {
    switch (activity) {
      case "Lesson":
        return HVLocalizeStrings.REACH_INSIGHTS_LESSON;
      case "Practice":
        return HVLocalizeStrings.REACH_INSIGHTS_PRACTICE;
      case "TEAM":
        return HVLocalizeStrings.REACH_INSIGHTS_TEAM;
      default:
        return activity;
    }
  };
  const formattedActiveCertsData = activityBreakdownReport.report!.map(
    (activity, index) => {
      const percentage = (activity.interactions / total) * 100;
      return {
        name: getActivityLocalizedName(activity.activity),
        y: parseFloat(percentage.toFixed(0)),
        color: assignColor(index, colors),
      } as PieChartDataPoint;
    }
  );
  return formattedActiveCertsData;
};

const extractUniqueTeamLessons = (paths: PathDto[]): TeamLessonDto[] => {
  const uniqueTeamLessons: { [key: number]: TeamLessonDto } = {}; // Using an object as a hash map to ensure uniqueness
  paths.forEach((path) => {
    path.pathTeamLessons.forEach((pathTeamLesson) => {
      const teamLessonId = pathTeamLesson.teamLessonId;
      // Add team lesson to the uniqueTeamLessons object
      uniqueTeamLessons[teamLessonId] = pathTeamLesson.teamLesson;
    });
  });
  // Convert the uniqueTeamLessons object back to an array
  return Object.values(uniqueTeamLessons);
};

export const useSoloAndTeamReport = (): ISoloAndTeamReportProvider => {
  const { lessons, nanoPractices, getPathsFromServer, goals } =
    useContext(GlobalContext);
  const [popularNanoPracticeData, setPopularNanoPracticeData] = useState<
    PopularDisplayData[]
  >([]);
  const [popularMicroLessonData, setPopularMicroLessonData] = useState<
    PopularDisplayData[]
  >([]);
  const [popularTeamLessonData, setPopularTeamLessonData] = useState<
    PopularDisplayData[]
  >([]);
  const [userFeedbackData, setUserFeedbackData] = useState<
    PercentProgressDisplayData[]
  >([]);
  const [selectedPursuitsData, setSelectedPursuitsData] = useState<
    PercentDisplayData[]
  >([]);
  const [activitiesBreakdown, setActivitiesBreakdown] = useState<
    PieChartDataPoint[]
  >([]);

  const [lowDataSoloActivityUserFeedback, setLowDataSoloActivityUserFeedback] =
    useState<boolean>(false);
  const [lowDataSoloActivityBreakdown, setLowDataSoloActivityBreakdown] =
    useState<boolean>(false);
  const [lowDataTeamLessonCounts, setLowDataTeamLessonCounts] =
    useState<boolean>(false);
  const [lowDataSoloMicroLessonCounts, setLowDataSoloMicroLessonCounts] =
    useState<boolean>(false);
  const [lowDataSoloNanoPracticeCounts, setLowDataSoloNanoPracticeCounts] =
    useState<boolean>(false);
  const [lowDataSoloSelectedPursuits, setLowDataSoloSelectedPursuits] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [intervalDays, setIntervalDays] = useState<number>(Interval180Days);

  const fetchReportHandler = useCallback(async (filters?: IInsightsFilter) => {
    setIsLoading(true);
    const paths = await getPathsFromServer();
    let teamLessons: TeamLessonDto[] = [];
    if (paths && Object.keys(paths).length > 0) {
      teamLessons = extractUniqueTeamLessons(paths[Object.keys(paths)[0]]);
    }

    const soloTeamReportResp = await reportSoloTeam(getKey(), filters);

    const mappedPopularNanoPracticeData = mapPopularNanoPracticeData(
      soloTeamReportResp.data.nanoPracticeCounts,
      nanoPractices
    );
    setPopularNanoPracticeData(mappedPopularNanoPracticeData);

    const mappedPopularMicroLessonData = mapPopularMicroLessonData(
      soloTeamReportResp.data.microLessonCounts,
      lessons
    );
    setPopularMicroLessonData(mappedPopularMicroLessonData);
    const mappedPopularTeamLessonData = mapPopularTeamLessonData(
      soloTeamReportResp.data.teamLessonCounts,
      teamLessons
    );
    setPopularTeamLessonData(mappedPopularTeamLessonData);

    const mappedUserFeedback = mapUserFeedbackData(
      soloTeamReportResp.data.activityUserFeedback
    );
    setUserFeedbackData(mappedUserFeedback);

    const mappedSelectedPursuits = mapSelectedPursuitsData(
      soloTeamReportResp.data.selectedPursuits,
      goals
    );
    setSelectedPursuitsData(mappedSelectedPursuits);

    const mappedActivityBreakdown = mapActivityBreakdownData(
      soloTeamReportResp.data.activityBreakdown
    );
    setActivitiesBreakdown(mappedActivityBreakdown);

    setLowDataTeamLessonCounts(soloTeamReportResp.data.teamLessonCounts.noData);
    setLowDataSoloMicroLessonCounts(
      soloTeamReportResp.data.microLessonCounts.noData
    );
    setLowDataSoloNanoPracticeCounts(
      soloTeamReportResp.data.nanoPracticeCounts.noData
    );
    setLowDataSoloActivityUserFeedback(
      soloTeamReportResp.data.activityUserFeedback.noData
    );
    setLowDataSoloActivityBreakdown(
      soloTeamReportResp.data.activityBreakdown.noData
    );
    setLowDataSoloSelectedPursuits(
      soloTeamReportResp.data.selectedPursuits.noData
    );
    setIntervalDays(soloTeamReportResp.data.intervalDays);
    setIsLoading(false);
  }, []);

  return {
    fetchReport: fetchReportHandler,
    popularNanoPractice: popularNanoPracticeData,
    popularMicroLessons: popularMicroLessonData,
    popularTeamLessons: popularTeamLessonData,
    userFeedback: userFeedbackData,
    selectedPursuits: selectedPursuitsData,
    activitiesBreakdown: activitiesBreakdown,
    lowDataSoloActivityUserFeedback: lowDataSoloActivityUserFeedback,
    lowDataSoloActivityBreakdown: lowDataSoloActivityBreakdown,
    lowDataTeamLessonCount: lowDataTeamLessonCounts,
    lowDataSoloMicroLessonCounts: lowDataSoloMicroLessonCounts,
    lowDataSoloNanoPracticeCounts: lowDataSoloNanoPracticeCounts,
    lowDataSoloSelectedPursuits: lowDataSoloSelectedPursuits,
    isLoading: isLoading,
    intervalDays,
  };
};
