import { HStack, Text } from "@chakra-ui/react";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../../Styles/HeadversityStyle";
import { HvTextInput } from "../../../Common/HvTextInput";
import { HVTestId } from "../../../../Testing/dataTestIds";

interface OptimismToolInputItemProps {
  listNumber: number;
  example: string;
  defaultValue?: string | null;
  onEnterKeyDown?: () => void;
  autoFocus?: boolean;
  handleChange: (value: string, index: number) => void;
  dataTestId: string;
}

export const OptimismToolInputItem = (props: OptimismToolInputItemProps) => {
  const {
    autoFocus,
    example,
    onEnterKeyDown,
    defaultValue,
    listNumber,
    handleChange,
  } = props;
  return (
    <HStack spacing={"18px"}>
      <Text
        fontSize={"23px"}
        fontWeight={"semibold"}
        color={MODAL_PRIMARY_TEXT_COLOR}
        width={"20px"}
      >
        {listNumber}.
      </Text>
      <HvTextInput
        placeholder={example}
        defaultValue={defaultValue ? defaultValue : ""}
        onChange={(e) => handleChange(e.target.value, listNumber - 1)}
        onEnterKeyDown={onEnterKeyDown}
        autoFocus={autoFocus}
        isRequired
        dataTestId={`${HVTestId.OptimismTool.OptimismToolInput}_${props.dataTestId}`}
      />
    </HStack>
  );
};
