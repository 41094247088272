import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  Respect_Maroon,
  completeGreen,
  Equity_Black,
} from "../../../Styles/HeadversityStyle";
import { InsightsProgressTracker } from "./InsightsProgressTracker";
import { CertSurveyGrowthData } from "./useCertReport";

export interface InsightsCertSurveyGrowthProps {
  data: CertSurveyGrowthData;
}

export const InsightsCertSurveyGrowth = (
  props: InsightsCertSurveyGrowthProps
) => {
  const { data } = props;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const changeColor = data.changePercentage > 0 ? completeGreen : Equity_Black;

  const textMargin = 2;
  return (
    <Flex
      flexDir={isMobile ? "column" : "row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
      p={4}
    >
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        width={isMobile ? "95%" : "50%"}
        gap={6}
        py={textMargin}
        pl={isMobile ? 1 : 6}
      >
        <Text fontSize="lg">{data.question}</Text>
        <Text fontSize="md">
          {Number(data.changePercentage) === 0 ? (
            HVLocalizeStrings.REACH_INSIGHTS_NO_CHANGE_AFTER_COMPLETING_COURSE
          ) : (
            <>
              <Text fontWeight="semibold" as="span" color={changeColor}>
                {Math.abs(data.changePercentage)}%{" "}
              </Text>
              {data.changePercentage > 0
                ? HVLocalizeStrings.REACH_INSIGHTS_INCREASE
                : HVLocalizeStrings.REACH_INSIGHTS_DECREASE}{" "}
              {HVLocalizeStrings.REACH_INSIGHTS_IN_AGREEMENT_AFTER_COURSE}.
            </>
          )}
        </Text>
      </Flex>
      <Box
        width={isMobile ? "95%" : "30%"}
        py={textMargin}
        mr={!isMobile ? 6 : 0}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          height={"100%"}
        >
          <Flex justifyContent={"space-between"}>
            <Text fontSize="xs">
              {HVLocalizeStrings.REACH_INSIGHTS_BEFORE_CERTIFICATION}
            </Text>
            <Text fontSize="sm" me={2} fontWeight="semibold">
              {`${data.beforePercentage}%`}
            </Text>
          </Flex>
          <InsightsProgressTracker
            percentage={data.beforePercentage}
            color={Respect_Maroon}
          />
          <Flex justifyContent={"space-between"} mt={textMargin * 3}>
            <Text fontSize="xs">
              {HVLocalizeStrings.REACH_INSIGHTS_AFTER_CERTIFICATION}
            </Text>
            <Text fontSize="sm" me={2} fontWeight="semibold">
              {`${data.afterPercentage}%`}
            </Text>
          </Flex>
          <InsightsProgressTracker
            percentage={data.afterPercentage}
            color={Respect_Maroon}
          />
        </Flex>
      </Box>
    </Flex>
  );
};
