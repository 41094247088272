import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../State/GlobalContext";

const assignCertParamKey = "assignCertParam";
export const useCertAssign = () => {
  const navigate = useNavigate();
  const { assignCertsToUser, self } = useContext(GlobalContext);

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let assignCertParam = queryParams.get("cert");
    const sessionStorageCertParam = sessionStorage.getItem(assignCertParamKey);

    // this is called when the app mounts, if user is logged in we can call the api to assign the cert

    // 1. if sessionStorage holds the cert param, use it
    if (sessionStorageCertParam) {
      assignCertParam = sessionStorageCertParam;
    }
    // 2. if self is not ready (means user has not logged in), store the cert param in sessionStorage
    // until we have logged in
    if (assignCertParam && !self) {
      sessionStorage.setItem(assignCertParamKey, assignCertParam);
    } else if (assignCertParam && self) {
      // 3. if user logged in, assign the cert to the user
      assignCertsToUser(assignCertParam)
        .then((_) => {
          sessionStorage.removeItem(assignCertParamKey);
          window.location.replace("/cert");
        })
        .catch((error) => {
          sessionStorage.removeItem(assignCertParamKey);
          // if error, navigate to the current page (removing the query param)
          navigate(location.pathname);
        });
    }
  }, [location, assignCertsToUser, self, navigate]);
};
