import { ToolLandingCard } from "../ToolLandingCard";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { AnimatedButton } from "../../../Common/AnimatedButton";
import {
  STAT_CARD_SECONDARY_COLOR,
  SECONDARY_TEXT_COLOR,
  THIRD_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";

interface MindfulnessToolStatCardProps {
  minutes: number;
  streaks: number;
  setMindfulnessToolOpened: (mindfulnessToolOpen: boolean) => void;
}

export const MindfulnessToolStatCard = (
  props: MindfulnessToolStatCardProps
) => {
  const { minutes, streaks, setMindfulnessToolOpened } = props;
  return (
    <ToolLandingCard
      toolImage={
        "https://cdn.headversity.com/app/resources/headerImages/tool-header-mindfulness.jpg"
      }
      toolStatContent={
        <>
          <MindfulnessToolStat minutes={minutes} streaks={streaks} />
          <AnimatedButton
            w={330}
            text={HVLocalizeStrings.MINDFULNESS_TOOLS_PAGE_USE_TOOL}
            onClick={() => {
              setMindfulnessToolOpened(true);
            }}
          />
        </>
      }
    />
  );
};

interface MindfulnessToolStatProps {
  minutes: number;
  streaks: number;
  isSmall?: boolean;
}

export const MindfulnessToolStat = (props: MindfulnessToolStatProps) => {
  const { minutes, streaks, isSmall } = props;
  return (
    <Table mt={isSmall ? 8 : 4} mb={isSmall ? "5" : "10"}>
      <Tbody>
        <Tr>
          <Td p={"0"} border={"0"}>
            <Text
              fontSize={isSmall ? "lg" : "xl"}
              color={isSmall ? STAT_CARD_SECONDARY_COLOR : SECONDARY_TEXT_COLOR}
            >
              {HVLocalizeStrings.MINDFULNESS_TOOLS_PAGE_TOTAL_MINUTES}
            </Text>
          </Td>
          <Td p={"0"} border={"0"} textAlign={"right"}>
            <Text
              fontWeight={"semibold"}
              fontSize={isSmall ? "lg" : "xl"}
              color={THIRD_TEXT_COLOR}
            >
              {minutes}
            </Text>
          </Td>
        </Tr>
        <Tr>
          <Td px={"0"} py={"2"} border={"0"}>
            <Text
              fontSize={isSmall ? "lg" : "xl"}
              color={isSmall ? STAT_CARD_SECONDARY_COLOR : SECONDARY_TEXT_COLOR}
            >
              {HVLocalizeStrings.MINDFULNESS_TOOLS_PAGE_STREAK}
            </Text>
          </Td>
          <Td p={"0"} border={"0"} textAlign={"right"}>
            <Text
              fontWeight={"semibold"}
              fontSize={isSmall ? "lg" : "xl"}
              color={THIRD_TEXT_COLOR}
            >
              {streaks}
            </Text>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
