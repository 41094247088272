import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useContext } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { ReachInsightsContext } from "../../../State/Reach/ReachInsightsContext";
import InsightsCard from "../../Common/Cards/InsightsCard";
import InsightsNoData from "./InsightsNoData";
import { PercentDisplayData } from "./InsightsPopularDisplay";

export interface SelectedPursuitsBarChartProps {
  data: PercentDisplayData[];
  title: string;
  subtitle: string;
}

const SelectedPursuitsBarChart = (props: SelectedPursuitsBarChartProps) => {
  const { soloAndTeamReport } = useContext(ReachInsightsContext);
  const { data, title, subtitle } = props;
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  let textMarginTop = 5;
  let textMarginBottom = 5;
  switch (data.length) {
    case 2:
      textMarginTop = 14;
      textMarginBottom = 28;
      break;
    case 3:
      textMarginTop = 7;
      textMarginBottom = 16;
      break;
    case 4:
      textMarginBottom = 9;
      break;
    case 5:
      textMarginBottom = 5;
      break;
    default:
      textMarginBottom = 5;
  }

  const width = 200;
  // get max of value of percentage of data array
  const maxPercentage = data.reduce((max, current) => {
    return current.percentage > max ? current.percentage : max;
  }, 0);

  const options = {
    title: {
      text: "",
    },
    chart: {
      type: "bar",
      reflow: true,
      width: width,
      height: 300,
    },
    plotOptions: {
      series: {
        borderRadiusTopLeft: "5px",
        borderRadiusTopRight: "5px",
        borderColor: "#0A1B57",
        borderWidth: 1,
      },
    },
    legend: { enabled: false },
    xAxis: {
      tickLength: 0,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      minorTickColor: "#000000",
      title: {
        text: null,
      },
      min: 0,
      max: maxPercentage,
      tickLength: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
    },
    series: [
      {
        showInLegend: false,
        data: data.map((dataPoint) => ({
          name: dataPoint.name,
          y: dataPoint.percentage,
          color: "#CED1DD",
        })),
        dataLabels: {
          enabled: true,
          format: "{y}%",
        },
      },
    ],
    tooltip: {
      formatter: function (this: any) {
        return this.point.name + ": <b>" + this.y + "%</b>";
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <InsightsCard
      title={title}
      subtitle={subtitle}
      hideHeader={false}
      showFooter={false}
      content={
        soloAndTeamReport.lowDataSoloSelectedPursuits ? (
          <InsightsNoData
            description={HVLocalizeStrings.REACH_INSIGHTS_NO_DATA_DESCRIPTION}
          />
        ) : (
          <Flex
            flexDir="column"
            alignItems="flex-start"
            bgColor="white"
            h={isMobile ? "320px" : "362px"}
          >
            <Flex pl={1} h={"200px"} width="100%">
              <Box ml={5} mt={textMarginTop} width="30%">
                {data.map((dataPoint, index) => (
                  <Text
                    key={index}
                    mt={data.length === 1 ? "100px" : undefined}
                    mb={
                      index !== data.length - 1 ? textMarginBottom : undefined
                    }
                    fontSize="sm"
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {dataPoint.name}
                  </Text>
                ))}
              </Box>
              <Box width="80%">
                <HighchartsReact highcharts={Highcharts} options={options} />
              </Box>
            </Flex>
          </Flex>
        )
      }
    />
  );
};

export default SelectedPursuitsBarChart;
