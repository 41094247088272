import { useCallback, useState } from "react";
import { getMindfulnessAudioSeries } from "../../Api/Solo/MindfulnessApi";
import { getKey } from "../../Utils/Helpers";
import { AudioSeriesDto } from "@headversity/contract";

export interface IAudioLibraryProvider {
  mindfulnessAudioSeries: AudioSeriesDto[];
  getMindfulnessAudioSeriesFromServer: () => void;
}

export const useAudioLibrary = (): IAudioLibraryProvider => {
  const [mindfulnessAudioSeries, setMindfulnessAudioSeries] = useState<
    AudioSeriesDto[]
  >([]);

  const getMindfulnessAudioSeriesFromServer = useCallback(async () => {
    return getMindfulnessAudioSeries(getKey()).then((response) => {
      setMindfulnessAudioSeries(response.data);
    });
  }, []);

  return {
    mindfulnessAudioSeries,
    getMindfulnessAudioSeriesFromServer,
  };
};
